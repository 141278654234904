import React, { useState } from "react";
import TreeMenu from "./MenuManagement/TreeMenu";
import MenuDetail from "./MenuManagement/MenuDetails";
import AuthGroup from "./AuthGroupTab/index.js";
import Wrapper from "./Styled";
import { PageTemplate, Header, Footer, Tabs } from "components";
import { useIntl } from 'react-intl';

/**
 * 관리자 > 메뉴 관리
 * 
 */
const { TabPane } = Tabs;

const AdminMenu = () => {
  const { formatMessage } = useIntl();

  const [language, setLanguage] = useState('');
  const [keyVal, setKeyVal] = useState(-1);
  const [parentKeyVal, setParentKeyVal] = useState(0);
  const [flatAllClassList, setFlatAllClassList] = useState([]);
  const [childCheck, setChildCheck] = useState(0);
  const [renderList, setRednerClassList] = useState([]);

  const getData = (key, check, flatAllClassList) => {
    setChildCheck(check);
    //하위 노드 추가
    if (check === 1) {
      setParentKeyVal(key);
      setKeyVal(0);
      //상위 노드 추가
    } else if (check === 0) {
      setParentKeyVal(null);
      setKeyVal(key);
    } else if (check === 2) {
      setKeyVal(key);
    }
    setFlatAllClassList(flatAllClassList);
  };

  const renderData = (allClassList) => {
    setRednerClassList(allClassList);
    setKeyVal(-1);
  };
  const cancelKey = () => {
    setKeyVal(-1);
  };
  const menuDetailCheck = () => {
    //하위
    if (keyVal !== -1 && childCheck === 1) {
      return (
        <MenuDetail
          parentKeyVal={parentKeyVal}
          keyVal={keyVal}
          childCheck={childCheck}
          flatAllClassList={flatAllClassList}
          renderData={renderData}
          cancelKey={cancelKey}
          language={language}
        />
      );
    }
    //상위
    else if (keyVal !== -1 && childCheck === 0) {
      return (
        <MenuDetail
          keyVal={keyVal}
          childCheck={childCheck}
          flatAllClassList={flatAllClassList}
          renderData={renderData}
          cancelKey={cancelKey}
          language={language}
        />
      );
    }
    //수정
    else if (keyVal !== -1 && childCheck === 2) {
      return (
        <MenuDetail
          keyVal={keyVal}
          childCheck={childCheck}
          flatAllClassList={flatAllClassList}
          renderData={renderData}
          cancelKey={cancelKey}
          language={language}
        />
      );
    }
  };

  const onChangeLanguage = (value) => {
    setLanguage(value);
    setKeyVal(-1);
  }

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
      <Wrapper>
        <Tabs
          className="menuTab"
          type="card"
          custom={false}
        >
          <TabPane tab={formatMessage({ id: "T0662" })} key="menudetail">{/* 메뉴 관리 */}
            <div className="org_body__container">
              <TreeMenu
                getData={getData}
                renderList={renderList}
                language={language}
                onChangeLanguage={onChangeLanguage}
              />
              {menuDetailCheck()}
            </div>
          </TabPane>
          <TabPane tab={formatMessage({ id: "T0663" })} key="authgroup">{/* 권한 그룹 관리 */}
            <AuthGroup />
          </TabPane>
        </Tabs>

      </Wrapper>
    </PageTemplate>
  );
};

export default AdminMenu;
