import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import styled from "styled-components";
import './common.css';
import { Modal, Table, Button, InputSearch, Organization } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import { ShrinkOutlined, ExpandOutlined } from '@ant-design/icons';

/**
 * Filename: LogModal.js 
 * Description: 로그 결과를 나타내는 창
 */

const Wrapper = styled((props) => <Modal {...props} />)`  
.ant-table-wrapper {
padding: 10px 40px;
}
.primary__button {
  width: 200px;
  height: 40px;
}
.default__button {
  height: 40px;
}
.ant-modal-footer button + button {
  margin-left: 0;
}
.ant-modal-footer {
  display: flex;
  justify-content: end;
  gap: 15px;
}
.ant-table-title {
  display: none !important;
} 
.ant-modal-header {
  border-top: 7px solid #ff7b00;
} 
`;

const LogModal = ({
  visible,
  onOk = () => { },
  onCancel = () => { },
  title = "",
  recordData,
  devicePool,
  ...args
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const profile = useSelector((state) => state.get("auth").get("profile"));

  const [dataSource, setDataSource] = useState('');
  const [orgVisible, setOrgVisible] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [loading, setLoading] = useState(false);

  const [isMaximized, setIsMaximized] = useState(false);

  const [scrollHeight, setScrollHeight] = useState('330px');

  const onClickOrg = () => {
    setOrgVisible(true);
  }

  const onOkOrg = (data) => {
    if (data.length === 0) {
      setOrgData({});
    }
    setOrgData({ ...data[0] });
    setOrgVisible(false);
  }

  const onOkModal = async () => {
    if (!orgData.empNum) return alert(formatMessage({ id: 'T0117' })); // 사용자를 선택해주세요
    const result = await fetchAbsolute('get', `/common/memberNoList?empNo=${orgData.empNum}`);
    if (result.error) {
      return alert(formatMessage({ id: 'T0118' })); // 사용자 정보를 가져오는데 실패하였습니다.
    }

    let tableHtml = `<table border='1' cellspacing='0'>
    <colgroup>
      <col width='150'>
      <col width='200'>
      <col width='170'>
      <col width='120'>
      <col>
    </colgroup>
    <thead>
      <tr>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage({ id: 'T0354' })}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage({ id: 'T0002' })}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage({ id: 'T0355' })}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage({ id: 'T0350' })}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage({ id: 'T0100' })}</th>
      </tr>
    </thead>
    <tbody>`;

    dataSource.forEach(data => {
      tableHtml += `<tr>
          <td style="text-align: center; font-size: 12px;">${moment(data.timeStamp).format('YYYY-MM-DD HH:mm:ss') || ''}</td>
          <td style="font-size: 12px;">${data.taskName || ''}</td>
          <td style="text-align: center; font-size: 12px;">${data.hostName || ''}</td>
          <td style="text-align: center; font-size: 12px;">${data.bizStatus || ''}</td>
          <td style="font-size: 12px;">${data.description || ''}</td>
        </tr>`
    });

    tableHtml += `</tbody></table>`;

    let taskTitle = recordData.taskNm ? recordData.taskNm : recordData.processNm;
    let taskId = recordData.taskId ? recordData.taskId : recordData.processId;

    const title = `${taskTitle}(${taskId}) ${formatMessage({ id: "T0574" })}`;

    const content =
      `${fmMessage({ id: 'T0356', values: { name: taskTitle, id: taskId } })}
    ${tableHtml}`;
    // `<p style="font-size: 12px;">안녕하십니까.</p>
    // <p style="font-size: 12px;">RPAgo2.0입니다.</p>
    // <p style="font-size: 12px;">${taskTitle}(${taskId})과제의 과제 실행 Log 전달드립니다.</p>
    // <p style="font-size: 12px;">감사합니다.</p>

    const sendResult = await fetchAbsolute('post', `/mail/sendSimpleText`, {
      data: {
        sendFrom: profile.EMAIL,
        sendTo: [
          result.data.mdFPAList[0].emailAddr
        ],
        sendCc: [],
        sendBcc: [],
        shareGroupId: [],
        mailSubject: title,
        mailText: content
      }
    });

    if (sendResult.error || sendResult.data.result === 'Fail') {
      return alert(formatMessage({ id: 'T0119' })); // 메일 전송을 실패하였습니다
    }

    alert(formatMessage({ id: 'T0120' })); // 메일 전송을 완료했습니다.
    setOrgData({});
    onOk();
  }

  const onClickMaximize = () => {
    setIsMaximized(true);
    setScrollHeight('calc(100vh - 256px)');
    document.getElementsByClassName('log_modal')[0].setAttribute("style", "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;");
    document.getElementsByClassName('log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:calc(100vh - 123px);");
  }

  const onClickMinimize = () => {
    setIsMaximized(false);
    setScrollHeight('330px');
    document.getElementsByClassName('log_modal')[0].setAttribute("style", "width:1400px;");
    document.getElementsByClassName('log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:460px;");
  }

  const columns = [
    // {
    //   key: 0,
    //   title: "id",
    //   dataIndex: "idx",
    //   width: 150,
    //   // defaultSortOrder: 'descend',
    // },
    {
      key: 1,
      title: <FormattedMessage id="T0354" />, // 등록 시간
      dataIndex: "timeStamp",
      width: 150,
      //defaultSortOrder: 'descend',
      // sorter: (a, b) => +(moment(a.timeStamp).format('YYYYMMDDHHmmss')) - +(moment(b.timeStamp).format('YYYYMMDDHHmmss')),
      sorter: (a, b) => moment(a.timeStamp) - moment(b.timeStamp),
      render: (record) => {
        return (
          moment(record).format("YYYY-MM-DD HH:mm:ss")
        )
      },
    },
    {
      key: 2,
      title: <FormattedMessage id="T0002" />, // 과제명
      dataIndex: "taskName",
      width: 150,
    },
    {
      key: 3,
      title: <FormattedMessage id="T0355" />, // Device
      dataIndex: "hostName",
      width: 100,
    },
    {
      key: 4,
      title: <FormattedMessage id="T0350" />, // 실행상태
      dataIndex: "bizStatus",
      width: 130,
    },
    {
      key: 5,
      title: <FormattedMessage id="T0100" />, // 설명
      dataIndex: "description",
      width: 473,
    },
  ];

  /* onCancel 눌렀을 때 작은 화면으로 돌아가도록 */
  useEffect(() => {
    if (!document.getElementsByClassName('sc-fzokOt')[0]) return;
    if (!visible) {
      setIsMaximized(false);
      setScrollHeight('330px');
      document.getElementsByClassName('sc-fzokOt')[0].setAttribute("style", "width:1400px;");
      document.getElementsByClassName('sc-fzokOt')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:460px;");
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) return;
    const fetchLogResult = async () => {
      setLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/log/botLog/getBotFrameworkLog?crEnv=${devicePool[0]?.crEnv || devicePool}&workItemId=${recordData.id || recordData.workItemId}&taskId=${recordData.processId || recordData.taskId}`
      );
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setLoading(false);
      setDataSource([...result.data.botList].sort((a, b) => b.idx - a.idx));
      setDataSource([...result.data.botList]);
    };
    if (recordData.id || recordData.workItemId) {
      fetchLogResult();
    }
  }, [recordData, devicePool, visible]);

  return (
    <>
      <Wrapper
        className='log_modal'
        modalRender={false}
        title={
          <div className="modal_title">
            {title}
            {
              !isMaximized ?
                <ExpandOutlined
                  className="title_expand_icon"
                  onClick={onClickMaximize}
                  id='max_btn'
                />
                :
                <ShrinkOutlined
                  className="title_expand_icon"
                  onClick={onClickMinimize}
                  id='min_btn'
                />
            }
          </div>
        }
        visible={visible}
        okText={formatMessage({ id: 'T1211' })} // Log 공유하기
        onCancel={onCancel}
        bodyStyle={{ height: 460 }}
        footer={[
          <InputSearch
            placeholder={formatMessage({ id: "T0026" })} // 사용자를 선택하세요
            onClick={onClickOrg}
            value={orgData.empNam}
          />,
          <Button
            key="submit"
            type="primary"
            onClick={onOkModal}
            className="primary__button"
          >
            {formatMessage({ id: 'T0069' })}{/* 공유하기 */}
          </Button>,
          <Button key="back" className="default__button" onClick={onCancel}>
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>,
        ]}
        {...args}
      >
        <Table
          columns={columns}
          locale={{ emptyText: `${formatMessage({ id: 'T1215' })}` }} // 로그 내역이 없습니다.
          dataSource={dataSource}
          // title={onChangeData}
          loading={loading}
          rowKey={(item) => item.idx}
          scroll={{ y: `${scrollHeight}` }}
        />
      </Wrapper>
      <Organization
        visible={orgVisible}
        onCancel={() => setOrgVisible(false)}
        onOk={onOkOrg}
        max={1}
        defaultData={[orgData]}
      />
    </>
  );
};

export default LogModal;
