import React, { useState, useEffect } from 'react';
import { Text, LinkModal } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { empPoint } from "utils/savePoint";
import { useIntl } from 'react-intl';

const SharedApproval = ({ match: { params } }) => {
    const { formatMessage } = useIntl();

    const { taskId, seq } = params;

    const [requestResult, setRequestResult] = useState({});

    useEffect(() => {
        const fetchSharedRequest = async () => {
            const aResult = await fetchAbsolute('post', `/task/oracle/approvalSharedTask?taskId=${taskId}&seq=${seq}`);
            if (!aResult.error) {
                const pResult = await fetchAbsolute('get', `/task/oracle/getTask/${taskId}`);
                if (!pResult.error && pResult.data.taskType === 'DIY') {
                    const managerEmp = pResult.data.taskMemberList.find(v => v.mgrDevGbn === 'M' && v.deputyHeadYn === 'N').empNo;
                    await empPoint('PT07', managerEmp);
                };
            };

            setRequestResult({ ...aResult });
        };
        fetchSharedRequest();
    }, [taskId]);

    if (Object.keys(requestResult).length === 0) {
        return (
            <></>
        )
    } else {
        return (
            <LinkModal
                visible={true}
                title=""
                width={540}
                closable={false}
                footer={[]}
                titleDisabled
                footerDisabled
            >
                <div className='request_box'>
                    <Text className='request_box__text'>
                        {
                            !requestResult.error ? `${formatMessage({ id: 'T0986' })}` // 과제 공유 승인을 완료했습니다.
                                : requestResult.data?.message || `${formatMessage({ id: 'T0987' })}` // 과제 공유 승인을 실패하였습니다.
                        }
                    </Text>
                </div>
            </LinkModal>
        )
    };
};

export default SharedApproval;