import styled from "styled-components";

const Wrapper = styled.div`
  background: f6f6f6;

  /* Tab 부분 */ 
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav-list {
    display: flex;
    gap: 45px;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    color: #333;
    font-size: 16px;
  }
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
  }
  .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
    color: #333;
    font-weight: normal;
  }
  // 우리카드
  /* .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 3px;
    background: #22e3a6;
  } */
  // 기존
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 3px;
    background: #ff7a00;
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    margin: 0;
  }
  .ant-tabs-tab-active.ant-tabs-tab {
    font-weight: bold;
  }
  .ant-tabs-tab > span {
    font-size: inherit;
    font-weight: inherit;
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    border: 0;
  }
  /* disabled 가능한 개발환경, 운영현황 tab */
  .ant-tabs-tab span {
    font-size: 16px;
  }
  .assign_lookup__disabled_tab {
    opacity: 0.3;
    cursor: default;
    font-size: 16px;
  }
  .font_14 {
    font-size: 14px;
  }
  .font_12 {
    font-size: 12px;
  }
  .lookup_body__box {
    background: #fff;
    padding: 30px 45px;
    border: 2px solid #e8e8e8;
  }
  .basic_info_box {
    gap: 0 60px;
  }
  /* 스텝 부분 */
  .stepper__step {
    display: flex;
    gap: 0 10px;
    height: 70px;
  }
  .stepper__circle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #fff;
  }
  .stepper__circle .stepper__check {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    background: #ef502f;
    /* background: #2b64ce; */
    width: 22px;
    height: 22px;
    border: none;
  }
  .stepper__circle:first-child .stepper__check {
    background: inherite;
  }
  .stepper__step:not(:last-child) .stepper__circle::before {
    content: "";
    position: absolute;
    display: block;
    top: 22px;
    left: 50%;
    width: 2px;
    height: 48px;
    transform: translateX(-50%);
    background: #ef502f;
    /* background: #2b64ce; */
    z-index: 1;
  }
  .stepper__text > span {
    display: block;
  }
  .stepper__text_title {
    font-size: 14px;
    color: #000;
  }
  .stepper__text_des {
    font-size: 12px;
    color: #888;
  }
  .stepper__step.stepper_check_point ~ .stepper__step .stepper__circle {
    border: 2px solid #b0b1b5;
  }
  .stepper__step.stepper_check_point
    ~ .stepper__step
    .stepper__circle
    .stepper__check {
    display: none;
  }
  .stepper__step.stepper_check_point .stepper__circle::before,
  .stepper__step.stepper_check_point ~ .stepper__step .stepper__circle::before {
    background: #b0b1b5;
  }
  .stepper__step.stepper_check_point ~ .stepper__step .stepper__circle::before {
    top: 20px;
  }
  .stepper__step.stepper_check_point ~ .stepper__step .stepper__text > span {
    color: #ccc;
  }
  .stepper__step.stepper_check_point .stepper__circle .stepper__check {
    background: #fff;
    border: 2px solid #ef502f;
    color: #ef502f;
    /* border: 2px solid #2b64ce; */
    /* color: #2b64ce; */
  }


  // 중단시 stepper 흑백

  .stepper__circle .stepper__check.check_stop {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    background: #b0b1b5;
    width: 22px;
    height: 22px;
    border: none;
  }
  .stepper__step.stepper_check_stop .stepper__circle {
    border: 2px solid #b0b1b5;
  }

  .stepper__step.stepper_check_stop ~ .stepper__step .stepper__circle {
    border: 2px solid #b0b1b5;
  }
  .stepper__step.stepper_check_stop
    ~ .stepper__step
    .stepper__circle
    .stepper__check {
    display: none;
  }
  .stepper__step.stepper_check_stop .stepper__circle::before,
  .stepper__step.stepper_check_stop ~ .stepper__step .stepper__circle::before {
    background: #b0b1b5;
  }
  .stepper__step.stepper_check_stop ~ .stepper__step .stepper__circle::before {
    top: 20px;
  }
  .stepper__step.stepper_check_stop ~ .stepper__step .stepper__text > span {
    color: #ccc;
  }
  .stepper__step.stepper_check_stop .stepper__text > span {
    color: #ccc;
  }
  
  .stepper__step.stepper_check_stop .stepper__circle .stepper__check {
    background: #b0b1b5;
    border: 2px solid #b0b1b5;
    color: #ccc;
  }
  /* 기본정보 박스 부분 */
  .basic_box__wrap {
    width: 50%;
    flex: 1;
  }
  .basic_box__wrap .basic_box {
    margin-bottom: 30px;
  }
  .basic_box__wrap .basic_box:last-child {
    margin-bottom: 0;
  }
  .basic_box .basic_box__title {
    font-size: 18px;
    font-weight: bold;
  }
  .basic_box .basic_box__info {
    margin-top: 8px;
    padding: 20px 50px;
    border: 1px solid #000;
  }
  .basic_row__content {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .reference__file {
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
  }
  .reference__file .file__list {
    display: flex;
  }
  .file__list a {
    font-size: 12px;
  }
  .receipt__button_group {
    display: flex;
    justify-content: end;
    padding-top: 25px;
    gap: 0px 10px;
  }
  /* 비활성화 된 userTag & upload & boxes */ 
  .status__disabled span::after{
    color: #d0d1d6;
  }
  .status__disabled .user_thumbnail {
    opacity: 0.5;
  }
  .status__disabled span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
    opacity: 0.5;
  }
  .status__disabled .user_tag_text {
    color: #d0d1d6;
  }
  .status__disabled .user_tag_text > .underline {
    border-bottom: none;
  }
  .status__disabled .user_name_tag:hover {
    color: #d0d1d6;
  }
  .status__disabled .ant-upload {
    background: #fff;
    color: #d0d1d6;
  }
  .status__disabled .sub_box__title {
    color: #cdcdcd;
  }
  .status__disabled .basic_box__info {
    padding: 20px 50px;
    border: 1px solid #d0d1d6;
  }
  .status__disabled .basic_row__title {
    color: #d0d1d6;
    font-size: 14px;
    font-weight: bold;
  }
  .status__disabled .basic_row__content {
    color: #d0d1d6;
  }
  .status__disabled .progress_text {
    padding-bottom: 15px;
    font-size: 14px;
    color: #d0d1d6;
  }
  .status__disabled .block__button {
    background: white;
    color: #d0d1d6;
  }
  .status__disabled .work_box .work_box__title {
    color: #d0d1d6;
  }
  .status__disabled .work_box .work_box__value {
    color: #d0d1d6;
  }
  .status__disabled .work_system {
    color: #d0d1d6;
  }
  .status__disabled .ant-table-content {
    color: #d0d1d6;
  }
  .status__disabled .ant-table-cell {
    color: #d0d1d6;
  }
  .status__disabled .reference__file {
    color: #d0d1d6;
  }

  .env_box__title.status__disabled.sub_box__title {
    color: #d0d1d6;
  }
  .env_box .status__disabled .sub_box__title {
    color: #d0d1d6;
  }
  .env_box__info.flex.align-center.justify-between.status__disabled {
    border: 1px solid #d0d1d6;
  }
  .env__credential_select {
    width: 200px;
    height: 32px;
  }
  .status__disabled .block__button:hover {
    border: 1px solid #d0d1d6;
  }

  .basic_row__title {
    font-size: 14px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.6);
  }
  .work_box__wrap {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #eaeaea;
    padding: 14px 0;
  }
  .work_box__wrap .work_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px 0;
    flex: 1;
  }
  .work_box .work_box__title {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.6);
  }
  .work_box .work_box__value {
    font-size: 14px;
    font-weight: bold;
    color: #707070;
  }
  .work_system {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  .work_system .work_system__icon {
    display: inline-block;
    padding: 0 8px;
    border-radius: 20px;
    color: #fff;
  }
  .work_system .work_system__icon.inside {
    background: #0E5A8C;
  }
  .work_system .work_system__icon.outside {
    background: #440FA1;
  }
  .work_system a {
    font-size: 12px;
    color: rgba(0,0,0,0.87) !important;
    text-decoration: none !important;
  }
  .basic_box__info .ant-input {
    min-height: unset;
  }

  /* 접수 기본 정보 - Dev 비활성화 테이블 */
  .status__disabled
    .table_disabled
    th.ant-table-align-center.ant-table-row-cell-break-word {
    color: #d0d1d6;
  }

  /* 공통 테이블 마지막 셀 */
  .ant-table-thead > tr:first-child > th:last-child {
    text-align: center; 
  }
  .ant-table-thead > tr > th {
    position: relative;
    text-align: center;
  }

  /* [개발] 분석/설계 부분 */
  .statement__box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 5px;
  }
  .statement__box .flex button {
    height: 30px;
  }
  .statement__box_link {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
  .statement__box_link > a {
    font-size: 14px;
  }
  .statement__box_text {
    padding: 5px 0;
    text-align: center;
  }
  .statement__box_text > span {
    font-size: 14px;
    color: #c4c3c3;
  }
  .analytical__text {
    font-size: 14px;
  }
  .file_name__copy {
    padding-left: 12px;
  }
  .download__form_container {
    padding-top: 20px;
    color: #adadad;
  }

  .download__form {
    font-weight: bold;
    font-size: 14px;
    color: #adadad !important;
  }
  .analytical_edit__button_group {
    display: flex;
    gap: 0 10px;
  }
  .work_desc__container {
    display: flex;
    gap: 0 60px;
  }
  .file__container {
    display: flex;
    gap: 0 53px;
  }

  /* [개발] 개발 진행 부분 */
  .progress_text {
    padding-bottom: 15px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.89);
  }
  .progress_text:nth-of-type(2) {
    padding-bottom: 0;
  }
  /* 개발 환경 탭 부분 */
  .env_box__wrap .ant-table-thead > tr > th,
  .env_box__wrap .ant-table-tbody > tr > td {
    height: 38px;
    padding: 0 9px;
    color: #000;
  }
  .env_box__wrap .ant-table-tbody > tr > td {
    color: #707070;
  }
  .env_box .env_box__title {
    padding-top: 20px;
    padding-bottom: 5px;
  }
  .env_box .env_box__title > span {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  .env_box .env_box__info {
    padding: 30px 50px;
    border: 1px solid #000;
  }
  .env_box_release__info {
    padding: 50px 50px;
    border: 1px solid #000;
    text-align: center;
  }
  .env_row__title {
    font-size: 14px;
    color: #000;
    font-weight: bold;
  }
  .env_row__text {
    font-size: 14px;
    display: flex;
    gap: 10px;
  }
  .env_row__uri{
    color:#40a9ff;
    font-size: 14px;
    border-bottom: 1px solid #40a9ff;
  }
  .env_row__uri:hover{
    color:#40a9ff;
    font-size: 14px;
    cursor: pointer;
  }
  .env_row__text.error__text {
    color: #bbb;
  }
  .env_row__dev_cr {
    display: flex;
    gap: 0 15px;
    align-items: center;
  }
  .env_box__info .release_button__area {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 20px;
  } 
  .env_box__info .oper__buttons {
    display: flex;
    gap: 20px;
  }
  .env_box__info .go_to_link {
    color: #e10013 !important;
    font-size: 12px;
    font-weight: bold;
  }
  /* 개발환경 > 운영 배포 현황 */
  .situation_text {
    font-size: 16px;
    font-weight: bold;
  }
  .disabeld__button {
    background: white;
    color: #d0d1d6;
  }
  .disabeld__button:hover {
    border: 1px solid #d0d1d6;
  }
  .sub_box__title td.ant-table-cell {
    font-size: 14px;
  }
  .sub_box__title th.ant-table-cell {
    text-align: center;
  }
  .sub_box__title tr.ant-table-placeholder {
    height: 150px;
  }
  .deploy_table__status {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
  } 
  .env_row__progress_folder {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  /* 운영 현황 부분 */
  .status_box .ant-table-thead > tr > th,
  .status_box .ant-table-tbody > tr > td {
    height: 38px;
    padding: 0 9px;
    color: #000;
  }
  .status_box .ant-table-tbody > tr > td {
    color: #707070;
  }
  .status_box__title {
    padding-bottom: 5px;
  }
  .status_box:not(:first-child) .status_box__title {
    padding-top: 20px;
  }
  .status_box__title > span {
    font-size: 18px;
    font-weight: bold;
  }
  .status_box .status_box__info {
    padding: 30px 50px;
    border: 1px solid #000;
  }
  .status_row__schedule_kind {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
  }
  .status_box__select {
    width: 270px;
    height: 32px;
  }
  .status_box__cycle {
    width: 70px;
    margin-right:10px;
    //margin-left:10px;
  }
  .custom_radio {
    font-size: 14px;
  }
  .status_box__schedule_kind {
    display: flex;
    padding: 13px 20px;
    background: rgba(234, 234, 237, 0.35);
  }
  .status_box__schedule_kind.schedule_repeat {
    width: 100%;
    justify-content: space-between;
  }
  .schedule_kind__onetime {
    display: flex;
    padding: 0 45px;
    align-items: center;
    gap: 0 20px;
  }
  .schedule_onetime__date {
    width: 227px;
    height: 32px;
  }
  .schedule_onetime__time {
    width: 170px;
    height: 32px;
  }

  .schedule_radio_text{
    margin-right:10px;
  }
  /* 운영 현황 -> 스케줄 유형 -> 반복 실행 부분 */
  .schedule_kind__cycle {
    width: 400px;
    height: 132px;
    padding: 0 25px;
    background: rgba(0, 0, 0, 0.09);
  }
  .schedule_kind__cycle .schedule_kind__cycle_checkbox .ant-checkbox + span {
    font-size: 12px;
    padding-left: 7px;
    padding-right: 0;
  }
  .schedule_kind__cycle > span {
    padding: 0 7px;
    font-size: 12px;
  }
  /* 반복 실행 -> 일 부분 */
  .schedule_kind__cycle.cycle_daily {
    display: flex;
    align-items: center;
    jsutify-content: flex-start;
    gap: 0 5px;
    height: 70px;
    min-height: fit-content;
  }
  .schedule_kind__cycle.cycle_daily .schedule_kind__daily_select {
    width: 89px;
    height: 32px;
  }

  /* 반복 실행 -> 주 부분 */
  .schedule_kind__cycle.cycle_weekly {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 10px 0;
  }
  .schedule_kind__cycle.cycle_weekly .schedule_kind__weekly_box:first-child {
    display: flex;
    align-items: center;
    jsutify-content: flex-start;
    gap: 0 5px;
  }
  .schedule_kind__cycle.cycle_weekly
    .schedule_kind__weekly_box:first-child
    span {
    font-size: 12px;
  }
  .schedule_kind__cycle.cycle_weekly
    .schedule_kind__weekly_box:first-child
    .schedule_kind__weekly_select {
    width: 89px;
    height: 32px;
  }
  .schedule_kind__cycle.cycle_weekly .schedule_kind__weekly_box:last-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  /* 반복 실행 -> 월 부분 */
  .schedule_kind__cycle.cycle_monthly {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .schedule_kind__cycle.cycle_monthly .schedule_kind__monthly_box:first-child {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  .schedule_kind__cycle.cycle_monthly
    .schedule_kind__monthly_box:first-child
    .ant-checkbox-wrapper {
    margin: 0;
  }
  .schedule_kind__cycle.cycle_monthly
    .schedule_kind__monthly_box:last-child
    .monthly_box__radio_wrap {
    display: flex;
    justify-content: space-between;
  }
  .schedule_kind__cycle.cycle_monthly
    .schedule_kind__monthly_box:last-child
    .monthly_box__radio_wrap
    .custom_radio__wrap {
    display: flex;
    align-items: center;
    gap: 0 4px;
  }
  .schedule_kind__cycle.cycle_monthly
    .schedule_kind__monthly_box:last-child
    .monthly_box__radio_wrap
    .custom_radio {
    margin: 0;
    width: 25px;
    height: 25px;
  }
  .monthly_box__radio_wrap .monthly_box__day_select {
    width: 65px;
    height: 32px;
  }
  .monthly_box__radio_wrap .monthly_box__turn_select {
    width: 120px;
    height: 32px;
  }
  .monthly_box__radio_wrap .monthly_box__month_select {
    width: 65px;
    height: 32px;
  }

  /* 반복 실행 -> 시작날짜, 종료날짜 부분 */
  .schedule_kind__date_range {
    display: flex;
    gap: 0 20px;
  }
  .schedule_kind__date_range .schedule_kind__date {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
  }
  .schedule_kind__date_range .schedule_kind__date > span {
    font-size: 14px;
  }
  .schedule_kind__date_range .schedule_kind__date .ant-picker {
    width: 200px;
    height: 32px;
  }

  /* 반복 실행 -> 반복 설정 부분 */
  .schedule_kind__repeat {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .schedule_kind__repeat_box {
    display: flex;
    align-items: center;
    gap: 0 10px;
    padding: 23px 27px;
    background: rgba(0, 0, 0, 0.09);
  }
  .schedule_kind__repeat_box > span {
    font-size: 12px;
  }
  .schedule_kind__repeat_number {
    width: 88px;
    height: 32px;
  }
  .schedule_kind__repeat_time {
    width: 89px;
    height: 32px;
  }

  /* 운영현황 -> 스케줄 현황 */
  .schedule_status__button_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .status_box__info th.ant-table-cell {
    text-align: center;
  }
  .schedule_status__button_wrap button {
    font-size: 12px;
  }

  /* 운영현황 -> 운영현황 */
  .schedule_prop_status__result_wrap {
    display: inline-flex;
    gap: 3px;
    align-items: center; 
  }
  .schedule_prop_status__result_wrap span {
    font-size: 12px;
    color: #707070;
  }
  .schedule_prop_status__manage_wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .schedule_prop_status__manage_wrap .disabled {
    opacity: 0.3;
  }

  /* 운영 현황 > 운영 현황 > abled 아이콘 css */
  .abled_icons path.monitoring1,
  .abled_icons path.monitoring2,
  .abled_icons path#패스_10258,
  .abled_icons path#패스_10255,
  .abled_icons path#패스_10113,
  .abled_icons path#패스_10114,
  .abled_icons line#선_491,
  .abled_icons line#선_492,
  .abled_icons path#패스_10112,
  .abled_icons rect#사각형_5861 {
    stroke: #000;
  }
  .abled_icons path.monitoring2 {
    fill: #000;
  }
  .status_box__info .top__container {
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .status_box__info .top__container .left {
    display: flex;
    gap: 20px;
  }
  .btn__company {
    display: flex;
    gap: 10px;
  }
  /* 히스토리 부분 */
  .history_wrap {
    display: flex;
    flex-direction: column;
    gap: 7px 0;
  }
  .history_wrap .history_box {
    position: relative;
    display: flex;
    align-items: baseline;
    gap: 0 16px;
  }
  .history_box .history_box__sub {
    position: relative;
    display: flex;
  }
  .history_box .history_date {
    font-size: 14px;
  }
  .history_box .history_check {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 19px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: #ef502f;
    /* background: #2b64ce; */
    border: none;
    color: #fff;
  }
  .history_box:first-child .history_check {
    background: #fff;
    border: solid 2px #ef502f;
    color: #ef502f;
    /* border: solid 2px #2b64ce; */
    /* color: #2b64ce; */
  }
  .history_box:last-child .history_tail {
    display: none;
  }
  .history_box .history_tail {
    display: block;
    position: absolute;
    left: 11px;
    top: 43px;
    transform: translateX(-50%);
    width: 2px;
    height: calc(100% - 19px);
    background: #ef502f;
    /* background: #2b64ce; */
  }
  .history_box .history_content {
    position: relative;
    display: flex;
    align-items: baseline;
    width: 1030px;
    min-height: 60px;
    margin-left: 25px;
    padding: 0 32px;
    border: 1px solid #d0d1d6;
    background: #f8f8f9;
    padding: 20px 20px;
  }
  .history_box .history_content::before {
    content: "";
    position: absolute;
    display: block;
    left: -15px;
    top: 31px;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    background: #d0d1d6;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
  }
  .history_box .history_content::after {
    content: "";
    position: absolute;
    display: block;
    left: -13px;
    top: 31px;
    transform: translateY(-50%);
    width: 13px;
    height: 13px;
    background: #f8f8f9;
    clip-path: polygon(0 50%, 100% 100%, 100% 0);
  }
  .history_content .history_content__title {
    font-size: 14px;
    font-weight: bold;
    flex: 1 1 1%;
  }
  .history_content .history_content__user {
    flex: 2.5;
    align-self: start;
  }
  .history_content .history_content__text {
    font-size: 14px;
    color: rgba(112, 112, 112, 0.87);
    flex: 6;
  }

  /* 테스트 */
  .the_end_comment__from_dev {
    font-size: 16px;
    font-weight: bold;
    padding-bottom: 20px;
    display: flex;  
  } 
  .the_end_comment__from_dev .user_tag_text { 
    white-space: pre-wrap;
    width: 170px;
  }
  .the_end_comment__from_dev .user_info {
    flex: 1;
  }
  .user_info .ant-tag { 
    align-items: start;
  }
  .the_end_comment__from_dev span.ant-avatar.ant-avatar-circle.ant-avatar-icon {
    align-self: baseline;
  }
  .the_end_comment__from_dev .user_info span.ant-typography{
    width: 170px;
    white-space: pre-wrap;
  }
  .the_end_comment__from_dev .user_info .user_thumbnail {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #333;
    align-self: baseline;
  }
  .the_end_comment__from_dev .user_comment {
    flex: 2;
  }
  .the_end_comment__from_dev .comment_time { 
    display: flex;
    gap: 10px;
    align-items: baseline;
    width: 195px;
  }

  
  /* 테스트 개발 검수 */
  span.ant-typography {
    overflow-wrap: anywhere;
  } 
  .continue_comment__container {
    display: flex;
    width: 850px;
    justify-content: space-between;
  }
  .continue_comment__container .user_info {
    flex: 1;
  }
  .continue_comment__container .user_info span.ant-typography{
    width: 170px;
    white-space: pre-wrap;
  }
  .continue_comment__container .user_info .user_thumbnail {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #333;
    align-self: baseline;
  }
  .continue_comment__container .user_comment {
    flex: 2;
  }
  .continue_comment__container .comment_time {
    flex: 1;
    display: flex;
    gap: 10px;
    align-items: baseline;
  }
  .content_text{
    font-size:11px;
  }
  .email_trigger_name{
    align-self:center;
    margin-right:20px;
  }
  
  .bpd_list {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export default Wrapper;