import React, { useState } from "react";
import { Tabs } from "components";
import Container from "containers/Container";
import Information from './Information';
import Station from './Station/index.js';
import Wrapper from "./Styled";
import { useIntl } from 'react-intl';
import { PageTemplate, Header, Footer } from "components";

const { TabPane } = Tabs;

const JediKnightAcademy = () => {
  const { formatMessage } = useIntl();

  const [activeKey, setActiveKey] = useState('introKnight');

  const onChnageTab = (tabKey) => {
    setActiveKey(tabKey);
  }

  return (
    <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false} bodyColor='#000' footerColor="#000">
      <Wrapper>
        <Container>
          <Tabs activeKey={activeKey} className="stationTab" type="card" custom={false} header onChange={onChnageTab}>
            <TabPane tab={formatMessage({ id: 'T0906' })} key="introKnight">{/* 소개 */}
            </TabPane>
            <TabPane tab={formatMessage({ id: 'T0953' })} key="knightStation" >{/* Jedi Knight 주둔지 */}
            </TabPane>
          </Tabs>
        </Container>
      </Wrapper>
      {
        activeKey === 'introKnight' &&
        <Information />
      }
      {
        activeKey === 'knightStation' &&
        <Station />
      }
    </PageTemplate>
  );
};

export default JediKnightAcademy;
