import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;

    .input__area {
        display: flex;
        gap: 10px;
        padding: 5px 0;
        align-items: center;
      }
    .admin_email__button_group {
        display: flex; 
        justify-content: flex-end;
        gap: 0 15px ;
        margin-top: 20px;
    }
    .admin_trigger_filter_button{
        width:40px;
    }
    .admin_trigger_filter_input{
        margin-right:10px;
        width:320px;
    }
    .admin_trigger_select{
        width:365px;
    }

`;

export default Wrapper;