import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { withRouter } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import {
  Text,
  Table,
  Button,
  Modal,
  LogModal,
  Organization,
  PermissionComponent,
  RangePicker,
  RealtimeModal,
  RedoModal
} from "components";
import {
  ProcessingIcon,
  SuccessIcon,
  FailedIcon,
  MonitoringIcon,
  LogIcon,
  RestartIcon,
  PopupWaitIcon,
  RunningTaskRunAgainIcon,
  PauseRunningTaskIcon,
  StopRunningTaskIcon,
  RetryBizIcon,
  PauseForResultIcon,
  // StopForResultIcon
} from "icons";
import { Input as AntInput, Tooltip, Select } from "antd";
import moment from "moment";
import { SyncOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";

const { TextArea } = AntInput;
const { Option } = Select;

/**
 * 과제 조회 > 운영 현황 > 운영 현황
 */

const initialInputValues = {
  status: "",
  regFr: moment().format("YYYY-MM-DD"),
  regTo: moment().format("YYYY-MM-DD"),
};

const initialPageInfo = {
  total: 0,
  pageSize: 10,
  currentPage: 1,
};

const initialPoolPageInfo = {
  sort: "crCode",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

const LookupScheduleStatus = ({ dataSource, setDataSource }) => {
  const { formatMessage } = useIntl();

  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const dateRangeRequired = formatMessage({ id: "T0188" }); // 검색하시는 날짜의 범위를 지정해주세요
  const searchFail = formatMessage({ id: "T0189" }); // 검색하는 데 실패하였습니다.
  const recordUpdateErr = formatMessage({ id: "T0191" }); // 레코드 데이터를 수정 하는 중 오류가 발생하였습니다.
  const scheduleReSuccess = formatMessage({ id: "T0192" }); // 스케줄 재실행이 완료되었습니다.
  const scheduleReFail = formatMessage({ id: "T0193" }); // 스케줄 재실행에 실패하였습니다.

  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const assignLookup = useSelector((state) =>
    state.get(getUniqueKey("assignLookup"))
  );

  const crCodeInfo = crInfo.find(
    (v) =>
      v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD"
  )?.crCd;

  const refreshTimeout = useRef(null);
  const modalTitle = `${assignLookup.taskData?.taskId} ${formatMessage({
    id: "T0599", // 과제 재실행
  })}`;
  const searchValues = useRef({ ...initialInputValues });
  const selectOption = formatMessage({ id: "T0024" }); // 선택

  const [redoVisible, setRedoVisible] = useState(false);
  const [restoreVisible, setRestoreVisible] = useState(false);
  const [spinReload, setSpinReload] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [logVisible, setLogVisible] = useState(false);
  const [orgVisible, setOrgVisible] = useState(false);
  const [replayVisible, setReplayVisible] = useState(false);
  const [preRecordData,setPreRecordData] = useState({});
  const [recordData, setRecordData] = useState([]);
  const [rowInfo, setRowInfo] = useState({});
  // const [personInfo, setPersonInfo] = useState({});
  const [devicePool, setDevicePool] = useState([]);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [poolPageInfo, setPoolPageInfo] = useState({ ...initialPoolPageInfo });

  const [replayData, setReplayData] = useState({
    taskId: "",
    workItemId: "",
    cr: "",
  });

  const statusResult = [
    { key: 1, value: "Complete", title: formatMessage({ id: "T0403" }) }, // 성공
    { key: 2, value: "Failed", title: formatMessage({ id: "T0404" }) }, // 실패
    { key: 3, value: "WIP", title: formatMessage({ id: "T0568" }) }, // 진행중,
    { key: 4, value: "New", title: formatMessage({ id: "T1667" }) }, //준비중
    { key: 5, value: "RetryBiz", title: formatMessage({ id: "T1669" }) }, //재시도
    { key: 6, value: "Pause", title: formatMessage({ id: "T1668" }) }, //일시중지
    { key: 7, value: "Stop", title: formatMessage({ id: "T0643" }) },  //중지
    { key: 8, value: "Done", title: "Done" },
    { key: 9, value: "Retry", title: "Retry" },
  ];

  const onOkLog = () => setLogVisible(false);
  const onCancelLog = () => setLogVisible(false);
  const onCancelRedo = () => setRedoVisible(false);
  const onCancelRestore = () => setRestoreVisible(false);

  const onChangeTableData = (data) => {
    setPageInfo({ ...pageInfo, currentPage: data.current });
  };

  const onClickRestore = () => {
    setRedoVisible(false);
    setRestoreVisible(true);
  };

  const onOkRestore = () => {
    setRestoreVisible(false);
    setRedoVisible(true);
    const fetchRestore = async () => {
      const result = await fetchAbsolute(
        "get",
        `/hwlm/WorkItemInit?workItemId=${rowInfo.id}&xEnv=PRD&processId=${rowInfo.processId}`
      );
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      const tmp = result.data.resultData.initRecordDataMap;
      const arr = Object.entries(tmp).map((v) => ({
        key: v[0],
        value: v[1],
      }));
      setRecordData([...arr]);
    };
    fetchRestore();
  };

  const onOkOrg = (data) => {
    // setPersonInfo(data[0]);
    setOrgVisible(false);
    setLogVisible(true);
  };
  const onClickLog = (data) => {
    setRowInfo(data);
    setLogVisible(true);
  };

  /* 초기화 버튼 */
  const onClickResetCondition = () => {
    setInputValues({ ...initialInputValues });
    setPageInfo({ ...initialPageInfo });
    originList(initialInputValues, initialPageInfo);
  };

  const onChangeStatus = (value) => {
    setInputValues({ ...inputValues, status: value });
  };

  /* 검색 버튼 */
  const onClickSearchWorkItem = async () => {
    if (inputValues.regFr === undefined || inputValues.regTo === undefined) {
      return alert(dateRangeRequired);
    }

    searchValues.current = { ...inputValues };
    const tmpPageInfo = {
      ...pageInfo,
      currentPage: 1,
    };
    if (pageInfo.currentPage === 1) {
      originList(searchValues.current, tmpPageInfo);
    }
    setPageInfo({ ...tmpPageInfo });
  };

  const getDevicePool = async (page) => {
    if (page === undefined) {
      page = { ...poolPageInfo }
    }
    const result = await fetchAbsolute(
      "get",
      `/admin/devicePool/getDevicePoolList?crType=${assignLookup.taskData?.taskType}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    setDevicePool(result.data.lists);
    setPoolPageInfo({ ...page, total: +result.data.totalItemsCount });
  };

  const originList = async (
    searchData = searchValues.current,
    pageData = pageInfo
  ) => {

    const type = crInfo.find(
      (v) =>
        v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD"
    ).crXEnv;

    let filterCondition = {};
    let datesRange = [];
    let fieldId = [];
    let statusResult = [];

    if (searchData.regFr || searchData.regTo) {
      datesRange = [
        {
          operator: "ge",
          field: "startDateTime",
          value: searchData.regFr,
        },
        {
          operator: "le",
          field: "startDateTime",
          value: searchData.regTo,
        },
      ];
    }

    if (searchData.status) {
      statusResult = [
        {
          operator: "eq",
          field: "status",
          value: searchData.status,
        },
      ];
    }

    if (assignLookup.taskData?.taskId) {
      fieldId = [
        {
          operator: "eq",
          field: "processId",
          value: assignLookup.taskData?.taskId,
        },
      ];
    }

    filterCondition = {
      filter: { operands: [...fieldId, ...datesRange, ...statusResult] },
    };

    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/hwlm/WorkItem?x-environment=${type}`,
      {
        data: {
          page: {
            offset: (pageData.currentPage - 1) * pageData.pageSize,
            length: pageData.pageSize,
          },
          sort: [
            {
              field: "id",
              direction: "desc",
            },
          ],
          ...filterCondition,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setTableLoading(false);
    if (result.error) {
      return alert(searchFail);
    }
    setDataSource(result.data?.list);
    setPageInfo({ ...pageInfo, total: result.data.page.total });
    // getDevicePool();
  };

  /* 버튼 돌아가는 시간 지정*/
  const onClickReload = () => {
    clearTimeout(refreshTimeout.current);
    setSpinReload(true);
    refreshTimeout.current = setTimeout(() => {
      setSpinReload(false);
    }, 2000);

    searchValues.current = { ...inputValues };
    const tmpPageInfo = {
      ...pageInfo,
      currentPage: 1,
    };
    if (pageInfo.currentPage === 1) {
      originList(searchValues.current, tmpPageInfo);
    }
    setPageInfo({ ...tmpPageInfo });
  };

  const onClickRedo = (data) => {
    const arr = Object.entries(data.recordDataMap).map((v) => ({
      key: v[0],
      value: v[1],
    }));
    setRowInfo(data);
    setRedoVisible(true);
    setRecordData([...arr]);
    setPreRecordData({...data.recordDataMap});
  };

  const onClickAdd = () => {
    const tmpArr = [...recordData];
    tmpArr.push({
      key: "",
      value: "",
    });
    setRecordData([...tmpArr]);
  };

  const onClickDelete = (e, index) => {
    const tmpArr = [...recordData];
    const survivedArr = tmpArr.filter(v => v !== tmpArr[index]);
    if (survivedArr.length === 0) {
      return alert(formatMessage({ id: 'T0124' })); // 한 칸 이하일 수는 없습니다.
    }
    setRecordData([...survivedArr]);
  }

  /* 재실행 모달에서 onChange [key, value] */
  const onChangeRecordKey = (e) => {
    const { index } = e.target.dataset;
    const tmpArr = [...recordData];
    tmpArr[index].key = e.target.value;
    setRecordData([...tmpArr]);
  };
  const onChangeRecordValue = (e) => {
    const { index } = e.target.dataset;
    const tmpArr = [...recordData];
    tmpArr[index].value = e.target.value;
    setRecordData([...tmpArr]);
  };
  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDataRange = ([regFr, regTo]) => {
    const tmp = { ...inputValues, regFr, regTo };
    setInputValues(tmp);
  };
  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDataRange(range);
  };

  // 과제 재요청
  const onOkRedo = () => {
    setRedoVisible(false);

    const type = crInfo.find(
      (v) =>
        v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD"
    ).crXEnv;

    let poolName = devicePool.find(
      (v) => v.devicePoolId === rowInfo.devicePoolId.toString()
    )?.devicePoolName;
    
    let tmp;
    if (recordData[0].key !== "") {
      tmp = recordData.reduce(
        (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
        {}
      );
    }
    
    const fetchEdit = async () => {

      let parameter = {
        processId: assignLookup.taskData?.taskId,
        recordData: {
          ...tmp,
        },
        preRecordData: {
          ...preRecordData,
        },
      };

      const result = await fetchAbsolute(
        "put",
        `/hwlm/WorkItem?x-environment=${type}&workItemId=${rowInfo.id}`,
        {
          data: { ...parameter },
          headers: { "Content-Type": "application/json" },
        }
      );
      if (result.error) return alert(recordUpdateErr);
      onClickReload();
    };

    let parameter = {
      taskId: rowInfo.processId,
      cr: crCodeInfo,
      poolName,
      automationPriority: "PRIORITY_HIGH",
      scheduleType: "INSTANT",
      timeZone: "Asia/Seoul",
      retryWorkItemIds: [rowInfo.id],
      botType: "Child",
      userInput: {...tmp}
    };

    const fetchRequest = async () => {
      try {
        const result = await fetchAbsolute(
          "post",
          `/task/oracle/addTaskSchedule?crType=${type}`,
          {
            data: { ...parameter },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.data.result === "fail") {
          const confirmResult = window.confirm(
            `${result.data?.message} \n${formatMessage({ id: "T1301" })}` // 대기 시간 이후에 자동으로 실행할까요?
          );
          if (confirmResult) {
            const waiting = async () => {
              const result = await fetchAbsolute(
                "post",
                "/task/oracle/waitInstantAction",
                {
                  data: { ...parameter },
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
            };
            waiting();
          }
        } else if (result.status === 200 || result.status === 201) {
          alert(scheduleReSuccess);
        } else {
          throw new Error(result.status);
        }
      } catch (e) {
        return alert(scheduleReFail);
      }
    };
    fetchEdit();
    fetchRequest();
  };

  const onClickReplay = (record) => {
    setReplayData({
      taskId: record.processId,
      workItemId: record.id,
      cr: crInfo.find(
        (v) =>
          v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD"
      ),
    });
    setReplayVisible(true);
  };

  const onOkReplay = () => {
    setReplayVisible(false);
  };

  const onCancelReplay = () => {
    setReplayVisible(false);
  };

  const columns = [
    {
      title: <FormattedMessage id="T1108" />, // WorkItem ID
      dataIndex: "id",
      align: "center",
      key: "id",
    },
    {
      title: <FormattedMessage id="T1106" />, // Biz Key
      dataIndex: "bizKey",
      align: "center",
      key: 2,
    },
    {
      title: <FormattedMessage id="T1105" />, // Biz SubKey
      dataIndex: "bizSubKey",
      align: "center",
      key: 3,
    },
    {
      title: <FormattedMessage id="T1109" />, // Biz Step
      dataIndex: "bizStep",
      align: "center",
      key: 4,
    },
    {
      title: <FormattedMessage id="T1110" />, // Priority
      dataIndex: "priority",
      align: "center",
      key: 5,
    },
    {
      title: <FormattedMessage id="T1104" />, // Start Date
      dataIndex: "startDateTime",
      align: "center",
      key: 6,
      render: (text) => {
        return !text ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss");
      },
      sorter: (a, b) => a.startDateTime.localeCompare(b.startDateTime),
    },
    {
      title: <FormattedMessage id="T1103" />, // End Date
      dataIndex: "endDateTime",
      align: "center",
      key: 7,
      render: (text, data) => {
        return (!text||data.status==="New"||data.status==="WIP"||data.status==="Pause")
                ? "" 
                :moment(text).format("YYYY-MM-DD HH:mm:ss");
      },
      sorter: (a, b) => a.endDateTime.localeCompare(b.endDateTime),
    },
    {
      title: <FormattedMessage id="T1102" />, // Result
      dataIndex: "status",
      width: 120,
      align: "center",
      key: 9,
      render: (text) => (
        <div className="schedule_prop_status__result_wrap">
          { text === "New" ? (
             //준비중
            <>
              <PopupWaitIcon /><Text>{formatMessage({ id: "T1667" })}</Text> 
            </>
          ) :
            text === "WIP" ? (
            <>
              <ProcessingIcon />
              <Text>{formatMessage({ id: "T0568" })}</Text> {/* 진행중 */}
            </>
          ) : text === "Failed" ? (
            <>
              <FailedIcon /> <Text>{formatMessage({ id: "T0404" })}</Text>  {/* 실패 */}
            </>
          ) : text === "Complete" ? (
            <>
              <SuccessIcon /><Text>{formatMessage({ id: "T0403" })}</Text>  {/* 성공 */}
            </>
          ) : text === "RetryBiz" ? (
            <>
              <RetryBizIcon /><Text style={{ fontSize: 12 }}>{formatMessage({ id: "T1669" })}</Text> {/* 재시도 */}
            </>            
          ) : text === "Pause" ? (
            <>
              <PauseForResultIcon /><Text style={{ fontSize: 12 }}>{formatMessage({ id: "T1668" })}</Text> {/* 일시중지 */}
            </>
          ) : text === "Stop" ? (
            <>
              <StopRunningTaskIcon/><Text style={{ fontSize: 12 }}>{formatMessage({ id: "T0643" })}</Text> {/* 중지 */}
            </>
          ) : <Text style={{ fontSize: 12 }}>{text}</Text>
        }
        </div>
      ),
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: (
        //새로고침
        <Tooltip title={formatMessage({ id: "T0569" })}>
          <SyncOutlined
            onClick={() => onClickReload()}
            style={{ fontSize: "14px", fontWeight: "bold" }}
            spin={spinReload}
          />
        </Tooltip>
      ),
      width: 136,
      render: (data, record, index) => (
        //Replay
        <div key={index} className="schedule_prop_status__manage_wrap">
          <Tooltip title={formatMessage({ id: "T1167" })}>
            <MonitoringIcon
              onClick={() => onClickReplay(data)}
              className="abled_icons"
            />
          </Tooltip>
          { data.status === "Failed" ? (
            // T0570 : 로그 기록, T0571 : 재실행
            <>
              <Tooltip title={formatMessage({ id: "T0570" })}>
                <LogIcon
                  onClick={() => onClickLog(data)}
                  className="abled_icons"
                />
              </Tooltip>
              <PermissionComponent fnCode="FN24">
                <Tooltip title={formatMessage({ id: "T0571" })}>
                  <RestartIcon
                    onClick={() => onClickRedo(data)}
                    className="abled_icons"
                  />
                </Tooltip>
              </PermissionComponent>
            </>
          ) : data.status === "WIP" ? (  // result가 진행중(WIP)일때
            // T1668 : 일시중지, T0643: 중지
            <>
              <Tooltip title={formatMessage({ id: "T1668" })}>
                <PauseRunningTaskIcon 
                  onClick={()=>onClickRunningTask(data,"Pause")}
                  // onClick={()=>onClickRunningTaskTest(data, "Pause")}
                />
              </Tooltip>
              <PermissionComponent fnCode="FN24">
                <Tooltip title={formatMessage({ id: "T0643" })}>
                  <StopRunningTaskIcon 
                    onClick={()=>onClickRunningTask(data, "Stop")}
                  />
                </Tooltip>
              </PermissionComponent>
            </>
          ) : data.status === "Pause" ? ( //result가 일시중지(Pause)일때
          // T1670 : 다시 시작, T0643: 중지
            <>
              <Tooltip title={formatMessage({ id: "T1670" })}>
                <RunningTaskRunAgainIcon
                  onClick={() => onClickRunningTask(data,"RunAgain")}
                  className="abled_icons"
                />
              </Tooltip>
              <PermissionComponent fnCode="FN24">
                <Tooltip title={formatMessage({ id: "T0643" })}>
                  <StopRunningTaskIcon
                    onClick={() => onClickRunningTask(data, "Stop")}
                    className="abled_icons"
                  />
                </Tooltip>
              </PermissionComponent>
            </>
          ) : (
            // T0570 : 로그 기록, T0571 : 재실행
            <>
              <Tooltip title={formatMessage({ id: "T0570" })}>
                <LogIcon
                  onClick={() => onClickLog(data)}
                  className="abled_icons"
                />
              </Tooltip>
              <PermissionComponent fnCode="FN24">
                <Tooltip title={formatMessage({ id: "T0571" })}>
                  <RestartIcon />
                </Tooltip>
              </PermissionComponent>
            </>
          )}
        </div>
      ),
    },
  ];

  // 실행 중 일시중지, 중지, 다시시작 모달 관련 state
  const [runningProcessVisible, setRunningProcessVisible] = useState(false);
  const [runningTaskStatus,setRunningTaskStatus] = useState();

  const onClickRunningTask = (data,name) => {
    setRunningProcessVisible(true);
    setRunningTaskStatus(name);
    setRowInfo(data);
  }

  const onOKRunningTaskModal = async () => {
    let path = '';
    if(runningTaskStatus === "Pause") {
      path = '/hwlm/pauseRunningTask';
    } else if(runningTaskStatus === "Stop") {
      path = '/hwlm/stopRunningTask';
    } else if(runningTaskStatus==="RunAgain") {
      path = '/hwlm/pauseRunningTaskRunAgain';
    }
    const type = crInfo.find(
      (v) =>
        v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD"
    ).crXEnv; 

    const workItemId = String(rowInfo.id);

    const result = await fetchAbsolute(
      "get",
      `${path}?x-environment=${type}&taskId=${rowInfo.processId}&workitemId=${workItemId}`
    );

    if(result.data.resultCode==="S") {
      setRunningProcessVisible(false);
      setRunningTaskStatus();
      setRowInfo();
      onClickReload();
    } else {
      return alert(formatMessage({ id: "T0133" })); // 작업을 실패하였습니다.
    }
  };

  const onCancelRunningTaskModal = () => {
    setRunningProcessVisible(false);
    setRunningTaskStatus();
    setRowInfo();
  }

  useEffect(() => {
    if (!assignLookup.taskData?.taskType) return;
    originList();
  }, [pageInfo.currentPage, assignLookup.taskData?.taskType]);

  return (
    <>
      <div className="status_box">
        <div className="status_box__title">
          <Text>{formatMessage({ id: "T0386" })}</Text>{/* 운영 현황 */}
        </div>
        <div className="status_box__info">
          <Table
            columns={columns}
            loading={tableLoading}
            dataSource={dataSource}
            defaultPageSize={pageInfo.pageSize}
            pagination={true}
            pageSizeVisible={false}
            onChange={(data) => onChangeTableData(data)}
            locale={{ emptyText: `${formatMessage({ id: "T0572" })}` }} // 기간 내 운영 내역이 없습니다.
            rowKey={(item) => item.bizKey + item.creationDate}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.pageSize,
            }}
            title={() => {
              return (
                <div className="top__container">
                  <div className="left">
                    <div>
                      <Text>
                        <FormattedMessage id="T1104" />{/* Start Date */}
                      </Text>
                      &nbsp;
                      <RangePicker
                        onChangeDate={onChangeDates}
                        onCalendarChange={onChangeDates}
                        format="YYYY-MM-DD"
                        value={[
                          inputValues.regFr && inputValues.regFr.length > 0
                            ? moment(inputValues.regFr)
                            : undefined,
                          inputValues.regTo && inputValues.regTo.length > 0
                            ? moment(inputValues.regTo)
                            : undefined,
                        ]}
                        defaultValue={[undefined, undefined]}
                        disabledDate={(current) => {
                          return (current < moment().subtract(1, "month")) ||
                            (current > moment().add(0, "day"))
                        }}
                        allowClear={true}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <Text style={{ alignSelf: "center" }}>
                        <FormattedMessage id="T1102" />{/* Result */}
                      </Text>
                      &nbsp;
                      <Select
                        onSelect={onChangeStatus}
                        style={{ width: 200 }}
                        defaultValue=""
                        value={inputValues.status}
                      >
                        <Option value="">{selectOption}</Option>
                        {statusResult.map((v) => (
                          <Option value={v.value} key={v.key}>
                            {v.title}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <div className="btn__company">
                    <Button type="primary" onClick={onClickSearchWorkItem}>
                      <FormattedMessage id="T0039" />{/* 검색 */}
                    </Button>
                    <Button type="default" onClick={onClickResetCondition}>
                      <FormattedMessage id="T0037" />{/* 초기화 */}
                    </Button>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
      <RealtimeModal
        visible={replayVisible}
        onOk={onOkReplay}
        onCancel={onCancelReplay}
        taskId={replayData.taskId}
        workItemId={replayData.workItemId}
        cr={replayData.cr}
      />
      <Modal
        centered
        visible={runningProcessVisible}
        width={500}
        title={runningTaskStatus==="Pause"
            ? <FormattedMessage id="T1668" /> // 일시중지
            : runningTaskStatus==="Stop"
              ? <FormattedMessage id="T0643" /> // 중지
              : <FormattedMessage id="T1670" />  // 다시시작
          }
        okText={<FormattedMessage id="T0064" />} //확인
        onOk={onOKRunningTaskModal}
        onCancel={onCancelRunningTaskModal}
      >
        <Text>
            {runningTaskStatus==="Pause"
              ? <>{formatMessage({ id: "T1671" })}</> //실행 중인 과제를 일시중지 하시겠습니까?
              : runningTaskStatus==="Stop"
                ? <>{formatMessage({ id: "T1672" })}</> // 실행 중인 과제를 중지 하시겠습니까?
                : <>{formatMessage({ id: "T1673" })}</>  //일시중지된 과제를 다시 시작 하시겠습니까?
            }
          {/* {assignLookup.taskData?.taskNm}({assignLookup.taskData?.taskId})&nbsp;
          {formatMessage({ id: "T0476" })}&nbsp;과제를 즉시 실행하시겠습니까? */}
        </Text>
      </Modal>
      <Modal
        centered
        visible={restoreVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0082" })} // 초기 데이터 원복
        onOk={onOkRestore}
        onCancel={onCancelRestore}
      >
        <Text>{formatMessage({ id: "T0573" })}</Text>{/* 초기 데이터를 불러오시겠습니까? */}
      </Modal>
      <LogModal
        title={formatMessage({ id: "T0574" })} // 과제 실행 log
        visible={logVisible}
        onOk={onOkLog}
        onCancel={onCancelLog}
        recordData={{
          ...rowInfo,
          taskId: assignLookup.taskData?.taskId,
          taskNm: assignLookup.taskData?.taskNm,
        }}
        devicePool={
          crInfo.find(
            (v) =>
              v.crType === assignLookup.taskData?.taskType &&
              v.crEnvGubun === "PRD"
          )?.crXEnv
        }
      />
      <Organization
        visible={orgVisible}
        onCancel={() => setOrgVisible(false)}
        onOk={onOkOrg}
        max={1}
        defaultData={[]}
      />
      <RedoModal
        redoVisible={redoVisible}
        onOkRedo={onOkRedo}
        onCancelRedo={onCancelRedo}
        onClickRestore={onClickRestore}
        onChangeRecordKey={onChangeRecordKey}
        onChangeRecordValue={onChangeRecordValue}
        recordData={recordData}
        onClickAdd={onClickAdd}
        onClickDelete={onClickDelete}
      />
    </>
  );
};

export default withRouter(LookupScheduleStatus);
