import { getUniqueKey } from 'utils/Str';

export const SET_HFWORKFLOWSCHEDULE_VALUE = getUniqueKey('SET_HFWORKFLOWSCHEDULE_VALUE');
export const SET_HFWORKFLOWSCHEDULE_SPREAD = getUniqueKey('SET_HFWORKFLOWSCHEDULE_SPREAD');
export const RESET_HFWORKFLOWSCHEDULE_VALUE = getUniqueKey('RESET_HFWORKFLOWSCHEDULE_VALUE');
export const SET_HFWORKFLOWSCHEDULE_FILTERID = getUniqueKey('SET_HFWORKFLOWSCHEDULE_FILTERID');

export function setHFWorkFlowScheduleValue(key, value) {
    return {
        type: SET_HFWORKFLOWSCHEDULE_VALUE,
        key,
        value
    };
}

export function setHFWorkFlowScheduleSpread(data) {
    return {
        type: SET_HFWORKFLOWSCHEDULE_SPREAD,
        data
    }
}

export function resetHFWorkFlowScheduleValue() {
    return {
        type: RESET_HFWORKFLOWSCHEDULE_VALUE
    }
}

export function setHFWorkFlowScheduleFilterId(filterId) {
    return {
        type: SET_HFWORKFLOWSCHEDULE_FILTERID,
        filterId
    }
}
