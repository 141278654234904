import React from "react";
import { Text, WebEditor } from "components";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";


/**
 *  관리자 > 게시판 관리 > 게시판 수정/ 등록 > 내용 Template 설정
 * 
 */
const BoardEditor = ({ defaultValue, onChangeEditor, onMouseLeave }) => {
  const { formatMessage } = useIntl();
  return (
    <Wrapper>
      <div className="basic__wrapper">
        <Text className="col__title">{formatMessage({ id: "T0639" })}</Text>{/* 내용 Template 설정 */}
        <div className='template__container' onMouseLeave={onMouseLeave}>
          <WebEditor
            defaultValue={defaultValue}
            onChange={onChangeEditor}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default BoardEditor;
