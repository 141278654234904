import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { Text, Table, Button, Input } from "components";
import { Select } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import Wrapper from "../Styled";
const { Option } = Select;

const RankingStandard = () => {

  const { formatMessage } = useIntl();

  const firstBlankNotAllowed = formatMessage({ id: 'T0230' }); // 해당 항목에는 첫 글자 공백을 사용할 수 없습니다.
  const [rankingStandard, setRankingStandard] = useState([]);
  const [rankingColumn, setRankingColumn] = useState([]);
  const [language, setLanguage] = useState('');

  const columns = [
    {
      key: "cd",
      title: formatMessage({ id: 'T0810' }), // 랭킹 기준 코드
      dataIndex: "cd",
      width: 100,
      align: "center",
    },
    {
      key: "cdNm",
      title: formatMessage({ id: 'T0811' }), // 랭킹 기준 설명
      dataIndex: "cdNm",
    },
    {
      key: "attrCd1",
      title: formatMessage({ id: 'T0812' }), // Tab명
      dataIndex: "attrCd1",
      width: 200,
      render: (text, record) => {
        return (
          <Input
            value={text}
            onChange={(e) =>
              onChangeInput(record.cd, "attrCd1", e.target.value)
            }
            name={record.cd}
          />
        );
      },
    },
    {
      key: "attrCd2",
      title: formatMessage({ id: 'T0813' }), // 노출 컬럼 1
      dataIndex: "attrCd2",
      render: (value, record, index) => {
        return (
          <Select
            style={{
              width: 120
            }}
            value={value}
            onChange={(data) => onChangeColumn(index, data, "attrCd2")}
          >
            {
              rankingColumn.map(column => (
                <Option style={{ fontSize: 12 }} value={column.colCd} key={column.colCd}>{column.colNm}</Option>
              ))
            }
          </Select>
        )
      }
    },
    {
      key: "attrCd3",
      title: formatMessage({ id: 'T0815' }), // 노출 컬럼 2
      dataIndex: "attrCd3",
      render: (value, record, index) => {
        return (
          <Select
            style={{
              width: 120
            }}
            value={value}
            onChange={(data) => onChangeColumn(index, data, "attrCd3")}
          >
            <Option style={{ fontSize: 12 }} value={null}>{formatMessage({ id: 'T0814' })}</Option>{/* 선택안함 */}
            {
              rankingColumn.map(column => (
                <Option style={{ fontSize: 12 }} value={column.colCd} key={column.colCd}>{column.colNm}</Option>
              ))
            }
          </Select>
        )
      }
    },
    {
      key: "attrCd4",
      title: formatMessage({ id: 'T0816' }), // 노출 컬럼 3
      dataIndex: "attrCd4",
      render: (value, record, index) => {
        return (
          <Select
            style={{
              width: 120
            }}
            value={value}
            onChange={(data) => onChangeColumn(index, data, "attrCd4")}
          >
            <Option style={{ fontSize: 12 }} value={null}>{formatMessage({ id: 'T0814' })}</Option>{/* 선택안함 */}
            {
              rankingColumn.map(column => (
                <Option style={{ fontSize: 12 }} value={column.colCd} key={column.colCd}>{column.colNm}</Option>
              ))
            }
          </Select>
        )
      }
    },
    {
      key: "attrCd5",
      title: formatMessage({ id: 'T0817' }), // 노출 컬럼 4
      dataIndex: "attrCd5",
      render: (value, record, index) => {
        return (
          <Select
            style={{
              width: 120
            }}
            value={value}
            onChange={(data) => onChangeColumn(index, data, "attrCd5")}
          >
            <Option style={{ fontSize: 12 }} value={null}>{formatMessage({ id: 'T0814' })}</Option>{/* 선택안함 */}
            {
              rankingColumn.map(column => (
                <Option style={{ fontSize: 12 }} value={column.colCd} key={column.colCd}>{column.colNm}</Option>
              ))
            }
          </Select>
        )
      }
    },
    {
      key: "sort",
      title: formatMessage({ id: 'T0818' }), // 노출순서
      dataIndex: "sort",
      width: 80,
      render: (text, record) => {
        return (
          <Input
            value={text}
            name={record.cd}
            onChange={(e) => onChangeInput(record.cd, "sort", e.target.value)}
          />
        );
      },
    },
    {
      key: "useYn",
      title: formatMessage({ id: 'T0819' }), // 노출여부
      dataIndex: "useYn",
      width: 80,
      render: (select, record) =>
        rankingStandard.length >= 1 ? (
          <Select
            defaultValue="Y"
            value={select}
            onChange={(e) => onChangeInput(record.cd, "useYn", e)}
            style={{ width: "100%", fontSize: 12 }}
          >
            <Option value="Y">Y</Option>
            <Option value="N">N</Option>
          </Select>
        ) : null,
    },
  ];

  const onChangeColumn = (index, data, key) => {
    if (!rankingStandard[index]) return;
    const tmpArr = [...rankingStandard];
    tmpArr[index][key] = data;
    setRankingStandard([...tmpArr]);
  }

  const onChangeInput = (cd, label, value) => {
    const tmpArr = [...rankingStandard];
    const index = tmpArr.findIndex((v) => v.cd === cd);
    if (index === -1) return;
    if (value === " ") {
      alert(firstBlankNotAllowed);
      return;
    }
    tmpArr[index][label] = value;
    setRankingStandard([...tmpArr]);
  };


  /* 수정 */
  const onClickEditStandard = async () => {
    const parameter = [];

    let emptyFlag = true;
    //tab명 공백 입력 체크
    rankingStandard.forEach((e) => {
      if (e.attrCd1 === null || e.attrCd1 === "" || e.attrCd1.length < 1) {
        emptyFlag = false;
      }
    });
    if (!emptyFlag) {
      alert(formatMessage({ id: 'T0239' }));
      //공백은 입력할 수 없습니다.
      return;
    }


    rankingStandard.map((v) => {
      return parameter.push({
        categoryId: "RANK_STD",
        cd: v.cd,
        cdNm: v.cdNm,
        useYn: v.useYn,
        attrCd1: v.attrCd1,
        attrCd2: v.attrCd2,
        attrCd3: v.attrCd3,
        attrCd4: v.attrCd4,
        attrCd5: v.attrCd5,
        sort: v.sort,
        locale: language
      });
    });

    const modifyCd = await fetchAbsolute("post", "/common/commonCd/addList", {
      data: [...parameter],
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (modifyCd.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    else {
      alert(formatMessage({ id: 'T0164' }));
      //수정 완료되었습니다.
    }
  };

  /* 초기화 */
  const onClickReset = async () => {
    const result = await fetchAbsolute(
      "get",
      `/common/commonCd/getCateIdAndUseYn/RANK_STD?sortVal=asc`
    );
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setRankingStandard([...result.data]);
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
    const data = result.data.filter(v => v.useYn === 'Y');
    setLanguage(data[0].cd);
  }

  /* CommonCd 랭킹 리스트, 랭킹 컬럼 리스트 가져오기 */
  useEffect(() => {
    const getRanking = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/RANK_STD?sortVal=asc`
      );
      const getCol = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/RANK_COLUMN?sortVal=asc`
      );
      if (result.error || getCol.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다. 

      const tmp = getCol.data.map(v => ({ colCd: v.cd, colId: v.categoryId, colNm: v.cdNm }))
      setRankingStandard([...result.data]);
      setRankingColumn([...tmp]);
    };
    getRanking();
    fetchLanguageList();
  }, []);

  return (
    <Wrapper>
      <div className="common__container">
        <Text className="common__title">{formatMessage({ id: 'T0798' })}</Text>{/* 랭킹 기준 설정 */}
        <div className="ranking_table__container" style={{ paddingTop: 10.8 }}>
          <Table
            columns={columns}
            dataSource={rankingStandard}
            rowKey={(e) => e.cd}
          />
        </div>
        <div className="submit_button__section">
          <Button
            type="primary"
            className="submit_button"
            onClick={onClickEditStandard}
          >
            <FormattedMessage id="T0054" />{/* 수정 */}
          </Button>
          <Button
            type="default"
            className="submit_button"
            onClick={onClickReset}
          >
            <FormattedMessage id="T0037" />{/* 초기화 */}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};
export default RankingStandard;