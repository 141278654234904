import React, { useEffect, useState } from 'react';
import { Loading } from 'components';
import { useSelector } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import SignIn from './SignIn';
import ResetPassword from './ResetPassword';
import RequestPassword from './RequestPassword';
import { setCookie } from 'utils/cookie';

const RestrictedRoute = ({ component: Component, location }) => {
    const loading = useSelector(state => state.get('auth').get('loading'));
    const isLoggedIn = useSelector(state => !!(state.get('auth').get('profile').EMP_NO));
    const profile = useSelector(state => state.get('auth').get('profile'));
    const loginResult = useSelector(state => state.get('auth').get('apiResult'));

    const [browserLocale, setBrowserLocale] = useState('');

    useEffect(() => {
        if (Object.keys(loginResult).length === 0 || loginResult.size === 0) return;
        setCookie('auth', JSON.stringify(loginResult), {
            path: '/'
        });
    }, [loginResult]);

    if (loading) {
        return <Loading visible={true} />
    }
    return (
        <Switch>
            <Route exact path='/'>
                <Redirect to='/home' />
            </Route>
            {/* <Route exact path='/signin' component={(props) => <SignIn locale={browserLocale} {...props} />} /> */}
            <Route exact path='/signin' component={SignIn} />
            <Route exact path='/requestpassword' component={RequestPassword} />
            <Route exact path='/resetpassword' component={ResetPassword} />
            <Route
                render={
                    props => (
                        isLoggedIn ? (
                            <Component
                                {...props}
                                profile={profile}
                            />
                        ) : (
                            !['/signin', '/requestpassword', '/resetpassword'].includes(location.pathname) &&
                            <Redirect
                                to={{
                                    pathname: '/signin',
                                    state: { from: location },
                                }}
                            />
                        )
                    )
                }
            />
        </Switch>
    );
};

export default withRouter(RestrictedRoute);
