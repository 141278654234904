// import React from "react";
import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import { ShrinkOutlined, ExpandOutlined } from '@ant-design/icons';
import { Modal, Table, Button, InputSearch, Organization } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import { useSelector } from 'react-redux';

/**
 * workflow > 운영현황 > log 내역
 */

const Wrapper = styled((props) => <Modal {...props} />)`  
  .ant-table-wrapper {
    padding: 10px 40px;
  }
  .primary__button {
    width: 200px;
    height: 40px;
  }
  .default__button {
    height: 40px;
  }
  .ant-modal-footer button + button {
    margin-left: 0;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    gap: 15px;
  }
  .ant-table-title {
    display: none !important;
  } 
  .ant-modal-header {
    border-top: 7px solid #ff7b00;
  } 
`;

const ScheduleServiceLog = ({
  visible,
  onOk = () => { },
  onCancel = () => { },
  title = "Log Hisotry",
  type = "",
  recordData,
  ...args
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const onClickOrg = () => {
    setOrgVisible(true);
  }
  const onOkOrg = (data) => {
    if (data.length === 0) {
      setOrgData({});
    }
    setOrgData({ ...data[0] });
    setOrgVisible(false);
  }
  const profile = useSelector((state) => state.get("auth").get("profile"));

  const [dataSource, setDataSource] = useState('');
  const [orgVisible, setOrgVisible] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [loading, setLoading] = useState(false);
  const [originDataSource, setOriginDataSource] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [filterList, setFilterList] = useState([]);
  const [isMaximized, setIsMaximized] = useState(false);

  const onOkModal = async () => {
    if (!orgData.empNum) return alert(formatMessage({ id: 'T0117' })); // 사용자를 선택해주세요
    const result = await fetchAbsolute('get', `/common/memberNoList?empNo=${orgData.empNum}`);
    if (result.error) {
      return alert(formatMessage({ id: 'T0118' })); // 사용자 정보를 가져오는데 실패하였습니다.
    }
    let tableHtml = `<table border='1' cellspacing='0'>
  <colgroup>
    <col width='150'>
    <col width='150'>
    <col width='150'>
    <col width='150'>
    <col width='150'>
  </colgroup>
  <thead>
    <tr>
      <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${'Id'}</th>
      <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${'Task Type'}</th>
      <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${'Status'}</th>
      <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${'Task'}</th>
      <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${'Start Date'}</th>
      <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${'End Date'}</th>

    </tr>
  </thead>
  <tbody>`;
    dataSource.forEach(data => {
      tableHtml += `<tr>    
    <td style="font-size: 12px;">${data.id || ''}</td>
    <td style="text-align: center; font-size: 12px;">${data.actType || ''}</td>
    <td style="text-align: center; font-size: 12px;">${data.result || ''}</td>
    <td style="font-size: 12px;">${data.name || ''}</td>
    <td style="text-align: center; font-size: 12px;">${moment(data.startDate).format('YYYY-MM-DD HH:mm:ss') || ''}</td>
    <td style="text-align: center; font-size: 12px;">${moment(data.endDate).format('YYYY-MM-DD HH:mm:ss') || ''}</td>
      </tr>`
    });
    tableHtml += `</tbody>
  </table>`;

    const title = `${recordData.workflowNm}(${recordData.workflowId}) ${formatMessage({ id: "T1628" })}`; // 업무 실행 log
    const content =
      `${fmMessage({ id: 'T1655', values: { name: recordData.workflowNm, id: recordData.workflowId } })}
  ${tableHtml}`;
    // `<p style="font-size: 12px;">안녕하십니까.</p>
    // <p style="font-size: 12px;">RPAgo2.0입니다.</p>
    // <p style="font-size: 12px;">${recordData.workflowNm}(${recordData.workflowId})업무의 실행 Log 전달드립니다.</p>
    // <p style="font-size: 12px;">감사합니다.</p>

    const sendResult = await fetchAbsolute('post', `/mail/sendSimpleText`, {
      data: {
        sendFrom: profile.EMAIL,
        sendTo: [
          result.data.mdFPAList[0].emailAddr
        ],
        sendCc: [],
        sendBcc: [],
        shareGroupId: [],
        mailSubject: title,
        mailText: content
      }
    });

    if (sendResult.error || sendResult.data.result === 'Fail') {
      return alert(formatMessage({ id: 'T0119' })); // 메일 전송을 실패하였습니다
    }

    setOrgData({});
    alert(formatMessage({ id: 'T0120' })); // 메일 전송을 완료했습니다.
    onOk();
  }
  const onClickResetFilter = () => {
    setFilterInfo({});
    setDataSource(originDataSource);
  };

  const onChangeTable = (pagination, filters) => {
    setFilterInfo({ ...filters });
  };

  const onClickMaximize = () => {
    setIsMaximized(true);
    document
      .getElementsByClassName("service_log_modal")[0]
      .setAttribute(
        "style",
        "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;"
      );
    document
      .getElementsByClassName("service_log_modal")[0]
      .getElementsByClassName("ant-modal-body")[0]
      .setAttribute("style", "height:calc(100vh - 123px);");
  };

  const onClickMinimize = () => {
    setIsMaximized(false);
    document
      .getElementsByClassName("service_log_modal")[0]
      .setAttribute("style", "width:1400px;");
    document
      .getElementsByClassName("service_log_modal")[0]
      .getElementsByClassName("ant-modal-body")[0]
      .setAttribute("style", "height:460px;");
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'center',
      width: 150,
      filters: filterList[0]?.filters,
      filteredValue: filterInfo.id || null,
      onFilter: (value, record) => record.id.includes(value),
      sorter: (a, b) => a.id.localeCompare(b.id),
      // ...getColumnSearchProps("id"),
    },
    {
      title: 'Task Type',
      key: 'actType',
      dataIndex: "actType",
      align: 'center',
      width: 150,
      sorter: (a, b) => a.actType.localeCompare(b.actType),
      filters: filterList[1]?.filters,
      filteredValue: filterInfo.actType || null,
      onFilter: (value, record) => record.actType.includes(value),
    },
    {
      title: 'Status',
      key: 'result',
      dataIndex: "result",
      align: 'center',
      width: 100,
      sorter: (a, b) => a.result.localeCompare(b.result),
      filters: filterList[2]?.filters,
      filteredValue: filterInfo.result || null,
      onFilter: (value, record) => record.result.includes(value),

    },
    {
      title: 'Task',
      key: 'name',
      dataIndex: "name",
      align: 'center',
      width: 200,
      sorter: (a, b) => a.name.localeCompare(b.name),
      filters: filterList[3]?.filters,
      filteredValue: filterInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
    },

    {
      title: 'Create Date',
      dataIndex: 'createTime',
      key: 'createTime',
      align: 'center',
      width: 200,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createTime.localeCompare(b.createTime),
      render: (data) => moment(data).format('YYYY-MM-DD HH:mm:ss'),
      filteredValue: filterInfo.createTime || null,
      filters: filterList[4]?.filters,
      onFilter: (value, record) => record.createTime.includes(value),
    },
    {
      title: 'End Date',
      dataIndex: 'endTime',
      key: 'endTime',
      align: 'center',
      width: 200,
      sorter: (a, b) => a.endTime.localeCompare(b.endTime),
      render: (data) => data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '',
      filteredValue: filterInfo.endTime || null,
      filters: filterList[5]?.filters,
      onFilter: (value, record) => record.endTime.includes(value),
      // ...getColumnSearchProps("endTime"),
    },
  ];

  /* onCancel 눌렀을 때 작은 화면으로 돌아가도록 */
  useEffect(() => {
    if (!document.getElementsByClassName("service_log_modal")[0]) return;

    if (!visible) {
      setIsMaximized(false);
      document
        .getElementsByClassName("service_log_modal")[0]
        .setAttribute("style", "width:1400px;");
      document
        .getElementsByClassName("service_log_modal")[0]
        .getElementsByClassName("ant-modal-body")[0]
        .setAttribute("style", "height:460px;");
    }
  }, [visible]);

  useEffect(() => {
    const fetchLogResult = async () => {
      let id = "";
      if (type === "prod") {
        id = recordData?.processInstanceId ? recordData.processInstanceId : recordData?.id;
      } else {
        id = recordData?.processInstanceIds;
      }
      let data = [];
      if (!id) return;
      setLoading(true);
      // const id = recordData.id.split(',');
      const result = await fetchAbsolute(
        "get",
        `/workflow/workflowOperationStatusDetailHF?processInstanceId=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      data.push(
        ...result.data?.dataList.map((v) => ({
          ...v,
          createTime: moment(v.createTime).format("YYYY-MM-DD HH:mm:ss"),
          endTime: moment(v.endTime).format("YYYY-MM-DD HH:mm:ss") || "",
        }))
      );
      setLoading(false);
      const newColumn = columns.map((v) => {
        const groupArray = new Set(
          data.map((d) => {
            if (v.dataIndex === "createTime" || v.dataIndex === "endTime") {
              return moment(d[v.dataIndex]).format("YYYY-MM-DD");
            }
            return d[v.dataIndex] || " ";
          })
        );
        const filters = [...groupArray].map((d) => ({ text: d, value: d }));
        return {
          ...v,
          filters,
        };
      });
      setDataSource([...data].sort((a, b) => b.idx - a.idx));
      setOriginDataSource([...data].sort((a, b) => b.idx - a.idx));
      setFilterList(newColumn);
    };
    setDataSource([]);
    fetchLogResult();
  }, [recordData]);

  return (
    <>
      <Wrapper
        className='service_log_modal'
        modalRender={false}
        title={
          <div className="modal_title">
            {title}
            {
              !isMaximized ?
                <ExpandOutlined
                  className="title_expand_icon"
                  onClick={onClickMaximize}
                  id='max_btn'
                />
                :
                <ShrinkOutlined
                  className="title_expand_icon"
                  onClick={onClickMinimize}
                  id='min_btn'
                />
            }
          </div>
        }
        visible={visible}
        okText={formatMessage({ id: "T1211" })} // Log 공유하기
        onCancel={onCancel}
        bodyStyle={{ height: 460 }}
        footer={[
          <Button
            className="default__button"
            type="white"
            onClick={onClickResetFilter}
          >
            {formatMessage({ id: "T0068" })}{/* 필터 초기화 */}
          </Button>,
          <InputSearch
            placeholder={formatMessage({ id: "T0026" })} // 사용자를 선택하세요
            onClick={onClickOrg}
            value={orgData.empNam}
          />,
          <Button
            key="submit"
            type="primary"
            onClick={onOkModal}
            className="primary__button"
          >
            {formatMessage({ id: "T0069" })}
            {/* 공유하기 */}
          </Button>,
          <Button key="back" className="default__button" onClick={onCancel}>
            <FormattedMessage id="T0051" />
            {/* 취소 */}
          </Button>,
        ]}
        {...args}
      >
        <Table
          columns={columns}
          locale={{ emptyText: `${formatMessage({ id: 'T1215' })}` }} // 로그 내역이 없습니다.
          dataSource={dataSource}
          rowKey={(item) => item.id}
          scroll={{ y: 330 }}
          onChange={onChangeTable}
          loading={loading}

        />
      </Wrapper>
      <Organization
        visible={orgVisible}
        onCancel={() => setOrgVisible(false)}
        onOk={onOkOrg}
        max={1}
        defaultData={[orgData]}
      />
    </>
  );
};

export default ScheduleServiceLog;