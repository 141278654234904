import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff; 
    .member_edit__top {
        padding: 0 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .member_edit__top .member_edit__top_avatar {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 0 40px; 
        sposition: relative;
        height: 210px;
    }
    .member_edit__top .member_edit__top_avatar .member_edit__top_text {
        font-size: 18px;
        font-weight: bold;
        text-align:center;
    }
    .member_edit__top .member_edit__top_avatar .member_edit__top_text > span {
        font-weight: normal;
        
    }
    .file_list {
        display: flex;
        justify-content: center;
    }
    .file_list span {
        color: #5096ff;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }
    .member_edit__top .member_edit__top_table {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 1100px;
    }
    .member_edit__top table th {
        font-weight: bold;
    }

    .member_edit__bottom {
        padding: 30px 30px 0 30px;
    }
    .member_edit__bottom .ant-tabs-nav-scroll {
        margin: 0 100px;
    }
    .member_edit__bottom .ant-tabs-nav-scroll .ant-tabs-ink-bar {
        border-color: #FF7A00 !important;
        bottom: 0;
    }
    .member_edit__bottom .ant-tabs-nav-wrap {
        border-width: 1px;
        padding: 0 20px;
    }

    .member__point_tab .point_tab__point {
        padding: 30px;
        border-radius: 10px;
        border: 1px solid #d2d2d2;
    }
    .member__point_tab .point_tab__point .point_tab__point_search {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        padding-bottom: 20px;
    }
    .point_tab__point .point_tab__radio_wrap {
        display: flex;
    }
    .point_tab__point .point_tab__picker_wrap {
        display: flex;
        gap: 5px;
        align-items: center;
    } 

    .member__button_wrap {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 20px;
        padding: 0 30px;
    }
    .camera_icon {
        position: relative;
        top: -105px; 
        right: -50px;
    }
    .id_photo_part {
        width: 130px;
        max-width: 130px;
        min-height: 130px;
        height: 130px;
    }
`;

export default Wrapper;