import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import Wrapper from './Styled';
import { fetchAuthSignIn, setAuth } from './action';
import { Input, Button, Checkbox, Text } from 'components';
import { A_WEEK } from "../constant/signIn";
import bgImg from './images/loginBg.png';
import dwpImg from './images/dwpBg.png';
import logoImg from './images/logoText.png';
// import bgImg from './images/woori_bg.jpg';
// import dwpImg from './images/woori_left.jpg';

const SignIn = ({ location, history, locale }) => {
    const dispatch = useDispatch();

    const isFetch = useSelector(state => state.get('auth').loading);

    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [idMessage, setIdMessage] = useState('');
    const [isRemember, setIsRemember] = useState(false);
    const [resultError, setResultError] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['rememberId', 'auth']);

    /* LOGIN 로그인 버튼 기능 */
    const onSubmitForm = (e) => {
        e.preventDefault();

        if (isFetch) return;

        const url = location.state ? location.state.from.pathname : '/';
        const search = location.state ? location.state.from.search : '';

        dispatch(fetchAuthSignIn({ empNo: id, empPw: pw, url: url + search }, history.push, setCookie));

        if (isRemember) {
            setCookie('rememberId', id, { maxAge: A_WEEK }); // 7일
        } else {
            removeCookie('rememberId');
        }
    }

    const onClickPasswordReset = () => {
        // 이메일 확인창으로 이동
        history.push('/requestpassword');
    }

    const onChangeId = (e) => {
        const idRegex =
            /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        const { value } = e.target;
        if (value.length > 0 && !idRegex.test(value)) {
            setIdMessage('이메일 형식을 확인해주세요.');
        } else if (value.length > 100) {
            setIdMessage('아이디는 100자를 넘을 수 없습니다.');
            return;
        } else {
            setIdMessage('');
        }
        setId(value);
    }

    const onChangePw = (e) => {
        const { value } = e.target;
        setPw(value);
    }

    const rememberId = (e) => {
        setIsRemember(e.target.checked);
    }

    /* 이 부분이 있어야 로그인 한 후 새로고침해도 리셋되지 않음 */
    useEffect(() => {
        if (resultError) return;
        const setSession = async () => {
            const url = location.state ? location.state.from.pathname : '/';
            const search = location.state ? location.state.from.search : '';
            /* '아이디 기억하기' 쿠키 저장하는 부분 */
            if (cookies.rememberId !== undefined) {
                setId(cookies.rememberId);
                setIsRemember(true);
            }
            /* Token 부분 */
            const auth = cookies?.auth;
            if (auth && Object.keys(auth)?.length > 0) {
                try {
                    setResultError(false);
                    dispatch(setAuth(auth));
                    // 새로고침해도 있던 곳으로
                    history.push(url + search);
                } catch {
                    removeCookie('auth');
                    history.push('/signin');
                    return;
                }
            }
        }
        setSession();


        //  console.log((getCookie('miipResult')));
    }, [location]);

    return (
        <Wrapper style={{
            backgroundImage: `url(${bgImg})`,
            alt: 'background'
        }}>
            <form onSubmit={onSubmitForm} className='form'>
                <div className='dwp_logo__background' style={{ backgroundImage: `url(${dwpImg})`, alt: 'logo background' }}>
                <img style={{ paddingTop: 79, paddingLeft: 69 }} src={logoImg} alt='logo' />
                    <div className="contact">
                        This is a solution for RPA Bot lifecycle management,
                        If you need to get further information, contact<br />
                        <a href="mailto:dreago_rpa@sk.com">dreago_rpa@sk.com</a>
                    </div>
                </div>
                <div className='login__background'>
                    <div className='login__section'>
                        <div className='input__section'>
                            {window.location.href.includes('dev.') &&
                                <Text className='dev'>DEV</Text>
                            }
                            <Text className='title'>User ID</Text>
                            <Input
                                value={id}
                                placeholder='이메일 아이디를 입력해주세요'
                                // placeholder={fmMessage({ id: 'T1259', values: { field: fmMessage({ id: 'T0602' }) } })}// '이메일 입력'
                                onChange={onChangeId}
                            />
                            <Text style={{ color: 'red' }}>{idMessage}</Text>
                            <Checkbox
                                onChange={rememberId}
                                checked={isRemember}
                            >
                                {/* {fmMessage({id: 'T1291'})} */}
                                아이디 기억하기
                            </Checkbox>
                        </div>
                        <div className='input__section'>
                            <Text className='title'>Password</Text>
                            <Input
                                value={pw}
                                placeholder='비밀번호를 입력해주세요'
                                // placeholder={fmMessage({ id: 'T1259', values: { field: fmMessage({ id: 'T1271' }) } })}// '비밀번호 입력'
                                type='password'
                                onChange={onChangePw}
                            />
                        </div>
                        <div className='btn__section'>
                            <Button className='form_btn' type={!isFetch ? 'primary' : 'disabled'} disabled={isFetch}>
                                LOGIN
                            </Button>
                            <Button
                                className='form_reset_btn'
                                type='default'
                                onClick={onClickPasswordReset}
                            >
                                {/* {fmMessage({id: 'T1290'})} */}
                                비밀번호 초기화
                            </Button>
                        </div>
                    </div>
                </div>
            </form>
        </Wrapper >
    )
}

export default SignIn;