import React from 'react';
import { PageTemplate, Header, Footer, Counter, Option, Button } from 'components';

/**
 * Redux + Redux-saga 실습 예제 Page Component
 * 
 * Page Component는 Template Component로 감싸 구현하시는 것을 권장드립니다.
 * 
 * @class
 */
const Sample = () => {
  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
    >
      <div style={{ padding: '30px'}}>
        <h1>Redux와 Redux-saga의 실습 예제</h1>
        <Counter />
        <Option />
        <Button />
      </div>
    </PageTemplate>
  );
};

export default Sample;