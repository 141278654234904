import React, { useState } from "react";
import { Tabs } from "components";
import Container from "containers/Container";
import Youngling from './Youngling/index.js'
import Station from "./Station/index.js"
import Wrapper from "./Styled";
import { PageTemplate, Header, Footer } from "components";
import { useIntl } from 'react-intl';

const { TabPane } = Tabs;

const YounglingAcademy = () => {
  const { formatMessage } = useIntl();

  const [activeKey, setActiveKey] = useState('introYoungling');

  const onChnageTab = (tabKey) => {
    setActiveKey(tabKey);
  }
  return (
    <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false} bodyColor='#000' footerColor="#000">
      <Wrapper>
        <Container>
          <Tabs activeKey={activeKey} className="stationTab" type="card" custom={false} header onChange={onChnageTab}>
            <TabPane tab={formatMessage({ id: 'T0906' })} key="introYoungling">{/* 소개 */}
            </TabPane>
            <TabPane tab={formatMessage({ id: 'T0907' })} key="younglingStation" >
            </TabPane>
          </Tabs>
        </Container>
      </Wrapper>
      {
        activeKey === 'introYoungling' &&
        <Youngling />
      }
      {
        activeKey === 'younglingStation' &&
        <Station />
      }
    </PageTemplate>
  );
};

export default YounglingAcademy;
