import React, { useState, useRef, useEffect } from "react";
import { Text } from 'components';
import { Tabs, Tooltip } from 'antd';
import WorkflowBasic from './Basic';
import WorkflowHistory from "./History";
import WorkflowProdStatus from './ProdStatus';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import Wrapper from "../Styled";
import Container from "containers/Container";
import { withRouter } from 'react-router-dom';
import { getUniqueKey } from 'utils/Str';

/**
 * Filename: WorkflowLookupBody/index.js
 * Description: Workflow 조회 페이지 하단에 탭 부분
 *              Tab의 Header와 Body를 분리하여 Header에서 넘겨주는 activeTab 값을 받아와 그 값에 해당하는 화면을 보여줌
 * TabKey: basic  - 기본정보 탭(접수, 개발시작, 개발진행, 담당자확인)
 *         status - 운영현황 탭(스케줄 등록, 스케줄 현황, 운영 현황)
 */

// 진행상태 코드
// 접수    WS10  - 접수완료 WS11
// 개발    WS20  - 개발시작 WS21, 수정요청 WS22, 수정시작 WS23
// 테스트  WS30  - 테스트중 WS31
// 운영    WS40  - 완료 WS41, 스케줄등록 WS42, 스케줄수정 WS43, 스케줄삭제 WS44

const { TabPane } = Tabs;

const WorkflowLookupBody = ({
    params,
    history
}) => {
    const { formatMessage } = useIntl();
    const profile = useSelector(state => state.get('auth').get('profile'));
    const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

    const [activeTab, setActiveTab] = useState(params.tabValue || 'basic');
    const [statusVisible, setStatusVisible] = useState(false);
    const statusTimeout = useRef(null);

    const onChangeTabs = (tab) => {
        if (tab === "status" && hfWorkflowLookup.workflowData?.statusCd !== "WS40") {
            clearTimeout(statusTimeout.current);
            setStatusVisible(true);
            statusTimeout.current = setTimeout(() => {
                setStatusVisible(false);
            }, 3000);
            return;
        }
        history.push(`/bizflow/hfworkflow/lookup/${params.workflowId}/${tab}`);
        setActiveTab(tab);
    };

    return (
        <Wrapper>
            <Container style={{ marginTop: 40 }}>
                <Tabs
                    onChange={onChangeTabs}
                    activeKey={activeTab}
                >
                    <TabPane tab={formatMessage({ id: 'T0480' })} key="basic">
                        {activeTab === "basic" && (
                            <WorkflowBasic
                                profile={profile}
                                params={params}
                            />
                        )}
                    </TabPane>
                    <TabPane
                        key="status"
                        tab={
                            <Tooltip
                                title={formatMessage({ id: 'T0484' })} //접근할 수 없습니다.
                                open={statusVisible}
                                trigger={[]}
                            >
                                <Text className={hfWorkflowLookup.workflowData?.statusCd!=="WS40"&&"workflow_lookup__disabled_tab"}>
                                    {formatMessage({ id: 'T0482' })}
                                </Text>{/* 운영현황 */}
                            </Tooltip>
                        }
                    >
                        {activeTab === "status" && (
                            <WorkflowProdStatus />
                        )}
                    </TabPane>
                    <TabPane tab={formatMessage({ id: 'T0483' })} key='history'>
                        {
                            activeTab === 'history' && 
                            <WorkflowHistory/>
                        }
                    </TabPane>
                </Tabs>
            </Container>
        </Wrapper>
    );
}
export default withRouter(WorkflowLookupBody);