import fetchAbsolute from "utils/fetchAbsolute";
import { 
    SET_HEADER_NOTILIST, 
    SET_HEADER_NOTILIST_WHTH_PERIOD,
    SET_SIDE_NOTILIST,
    SET_SIDE_NOTILIST_WHTH_PERIOD,
    loadHeaderNotiList,
    loadSideNotiList
 } from "./action";
import { put, select, takeEvery } from "redux-saga/effects";

function* getHeaderNotiList(action) {
    const profile = yield select(state => state.get('auth').get('profile'));
    try {
        let hResult;

        if(action.type === SET_HEADER_NOTILIST) {
            // ntType=T : 헤더 영역
            hResult = yield fetchAbsolute(
                'get',`/common/notiDwpList?empNo=${profile.EMP_NO}&ntType=T`
            );
        } else {
            // ntType=T : 헤더 영역
            hResult = yield fetchAbsolute(
                'get',`/common/notiDwpList?empNo=${profile.EMP_NO}&ntType=T&searchPeriod=7`
            );
        }
        yield put(loadHeaderNotiList(hResult.data));
    } catch (err) { }
}

function* getSideNotiList(action) {
    const profile = yield select(state => state.get('auth').get('profile'));
    try {
        let pResult;

        if(action.type === SET_SIDE_NOTILIST) {
            // ntType=M : 개인화 영역 
            pResult = yield fetchAbsolute(
                "get",
                `/common/notiDwpList?empNo=${profile.EMP_NO}&ntType=M`
            );
        } else {
            // ntType=M : 개인화 영역 
            pResult = yield fetchAbsolute(
                "get",
                `/common/notiDwpList?empNo=${profile.EMP_NO}&ntType=M&searchPeriod=7`
            );
        }
        yield put(loadSideNotiList(pResult.data));
    } catch (err) { }
}

function* rootSaga() {
    yield takeEvery([SET_HEADER_NOTILIST, SET_HEADER_NOTILIST_WHTH_PERIOD], getHeaderNotiList);
    yield takeEvery([SET_SIDE_NOTILIST, SET_SIDE_NOTILIST_WHTH_PERIOD], getSideNotiList);
};

export default rootSaga;