import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  PageTemplate,
  Header,
  Footer,
  AssignEnterBox,
  HFBpdEnterBasicBox,
  Button,
  BusinessClass,
  HFBpdIframe,
  Modal,
  Text,
  Organization,
  HFBpdIframeModal
} from "components";
import Container from "containers/Container";
import fetchAbsolute from "utils/fetchAbsolute";
import { setVisible } from "store/loading/action";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

/**
 * Filename: HFBpdEnter/index.js
 * Description: Process Design 업무 등록 컴포넌트
 * Business Process Design 등록
 * Business Process Design 수정
 */

const HFBpdEnter = ({
  profile,
  history,
  type = "enter",
  match: {
    params, params: { bpdId: mbpdId },
  },
}) => {
  const tmpFile = useRef([]);
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const dispatch = useDispatch();
  const [visibleOrgan, setVisibleOrgan] = useState(false);
  const [fixedMain, setFixedMain] = useState(false);
  const [organData, setOrganData] = useState([]);
  const [defaultFiles, setDefaultFiles] = useState([]);

  const [requireFields, setRequireFields] = useState({
    businessClass: {
      ref: useRef(null),
      message: formatMessage({ id: "T0008" }), // 업무 분류
      use: true,
    },
    taskName: {
      ref: useRef(null),
      message: formatMessage({ id: "T1237" }), // 업무명
      use: true,
    },
    taskDesc: {
      ref: useRef(null),
      message: formatMessage({ id: "T1236" }), // 업무 설명
      use: true,
    },
    workManager: {
      ref: useRef(null),
      message: formatMessage({ id: "T0006" }), // 업무 담당자
      use: true,
    },
  });

  const [basicDatas, setBasicDatas] = useState({
    businessClass: {},
    taskName: "",
    relatedDepartment: "",
    department: [],
    totalManager: profile,
    workManager: [],
    taskDesc: "",
    fileId: "",
  });

  const [lookupBpdInfo, setLookupBpdInfo] = useState({});
  const [fileList, setFileList] = useState([]);
  const [visibleBusiness, setVisibleBusiness] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [bpdType, setBpdType] = useState(
    type === "enter" ? "insert" : type === "modify"
  );
  const [isSavedBasicInfo, setIsSavedBasicInfo] = useState(false);
  const [bPdData, setBpdData] = useState({});
  const [deleteFiles, setDeleteFiles] = useState([]);

  const setLoading = async (cb) => {
    dispatch(setVisible(true));
    await cb();
    dispatch(setVisible(false));
  };

  const onClickModalButton = () => {
    setConfirmVisible(true);
  };

  const onCancelModal = () => {
    setConfirmVisible(false);
  };

  const onOkModal = () => {
    setConfirmVisible(false);
    if (bpdType === 'modify') onClickModify();
    else onClickSubmit();
  };

  //첨부파일
  const onBeforeupload = (inFile) => {
    if (fileList.findIndex((file) => file.name === inFile.name) !== -1) {
      alert(
        fmMessage({
          id: "T1287",
          values: { field: formatMessage({ id: "T1288" }) },
        })
      );
      // 같은 이름의 파일은/을 업로드 할 수 없습니다.
      return false;
    }

    if (inFile.size > 1024 * 1024 * 100) {
      return alert(
        fmMessage({
          id: "T1571",
          values: { field: Math.round((inFile.size / 1024 / 1024) * 100) / 100 },
        })
      );
      // 100MB 이하 파일만 등록할 수 있습니다.\n\n "현재파일 용량 : {field}MB
    }
    tmpFile.current.push(inFile);
    setFileList([...tmpFile.current]);
    return false;
  };

  const removeUploadedFile = (seq) => {
    setDeleteFiles(deleteFiles.concat(seq));
  };

  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) removeUploadedFile(rmFile.seq);
    tmpFile.current = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpFile.current]);
  };

  const onChangeBasicDatas = async (name, value) => {
    setBasicDatas({ ...basicDatas, [name]: value });
  };

  const onOkBusiness = (data) => {
    setVisibleBusiness(false);
    setBasicDatas({
      ...basicDatas,
      businessClass: data.length > 0 ? data[0] : {},
    });
  };

  // Modal 창들 Cancel 시 발생 이벤트
  const onCancelBusiness = () => setVisibleBusiness(false);
  const onClickBusinessOpen = () => setVisibleBusiness(true);

  // 목록 삭제 이벤트
  const onClickDeleteBasic = (name, index) => {
    const tmpArr = [...basicDatas.workManager];
    tmpArr.splice(index, 1);
    setBasicDatas({ ...basicDatas, workManager: tmpArr });
  };

  const onClickCancelButton = () => {
    if (type === "enter") {
      const path = "/bizflow/hfbpd/list";
      history.push(path);
    } else {
      const path = `/bizflow/hfbpd/lookup/${mbpdId}`;
      history.push(path);
    }
  };

  // Modal Open 이벤트
  const onOkOrgan = async (data) => {
    setVisibleOrgan(false);
    setBasicDatas({ ...basicDatas, workManager: [...data] });
  };

  const onCancelOrgan = () => setVisibleOrgan(false);

  const onClickOrga = () => {
    if (type !== "modify") {
      setFixedMain(false);
    } else {
      setFixedMain(true);
    }
    setVisibleOrgan(true);
    setOrganData(basicDatas.workManager);
  };

  // Request Parameter 생성 함수
  const setRequestParameter = (data) => {
    const parameter = {
      bpdId: data.bpdId,
      bpdNm: data.taskName,
      bpdDesc: data.taskDesc,
      workId: data.businessClass.cd,
      processId: data.processId,
      useYn: "Y",
      bpdMemberHFList: data.workManager,
      fileId: data.fileId,
      bpdOrgCd: data.relatedDepartment.codeId,
    }
    if (type === 'enter') {
      delete parameter.bpdId;
      delete parameter.bpdOrgCd;
    }
    if (fileList.length === 0) {
      delete parameter.fileId;
    }
    return parameter;
  }

  /* 기본 정보 저장 */
  const onClickTempSave = async (data) => {
    let parameter = {};

    // 수정화면에 들어왔을 때
    if (type === 'modify') {
      parameter = {
        bpdNm: data.bpdNm,
        processId: data.processId,
        bpdId: data.bpdId,
      }
      if (data.bpdNm === '') return alert(
        fmMessage({ id: "T0128", values: { field: formatMessage({ id: "T1237" }) } }) // 업무명은 항목은 필수 입력입니다.
      );
    } else {
      // 처음 필수 입력 항목 체크
      for (const key in requireFields) {
        const field = requireFields[key];
        if (!field.use) continue;
        if (
          (key !== "ruleAcquaint" && Object.keys(data[key]).length <= 0) ||
          (key === "ruleAcquaint" && !data[key])
        ) {
          field.ref.current.focus();
          return alert(
            fmMessage({ id: "T0128", values: { field: field.message } })
          );
          //`${field.message} 항목은 필수 입력입니다.`);
        }
      }
      parameter = { bpdNm: data.taskName }
    }
    if (data.taskName === '') return alert(
      fmMessage({ id: "T0128", values: { field: formatMessage({ id: "T1237" }) } }) // 업무명은 항목은 필수 입력입니다.
    );

    // file 첨부했을 경우
    const formData = new FormData();
    fileList
      .filter((file) => !file.uploaded)
      .forEach((file) => formData.append("files", file));

    const fResult = await fetchAbsolute(
      "post",
      "/file/uploadMultipleFiles",
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const result = await fetchAbsolute("post", "/bpd/tempSaveBpdHF", {
      data: { ...parameter },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.error) {
      return alert(formatMessage({ id: "T0235" }));
      //저장에 실패하였습니다.
    }

    setIsSavedBasicInfo(true);
    const { data: { processId } } = result;
    setBpdData({ ...bPdData, processId });
    if (fileList.length > 0) { // 첨부 파일이 있을 때
      setBasicDatas({ ...data, fileId: fResult.data[0]?.fileId || data.fileId });
    }

    if (type !== 'modify') return alert(formatMessage({ id: "T0249" })); //저장에 되었습니다.
  };

  const onClickSubmit = async () => {
    // 필수 입력 항목 체크
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!field.use) continue;
      if (
        (key !== "ruleAcquaint" && Object.keys(basicDatas[key]).length <= 0) ||
        (key === "ruleAcquaint" && !basicDatas[key])
      ) {
        field.ref.current.focus();
        return alert(
          fmMessage({ id: "T0128", values: { field: field.message } })
        );
        //`${field.message} 항목은 필수 입력입니다.`);
      }
    }
    // file 첨부했을 경우
    const formData = new FormData();
    fileList
      .filter((file) => !file.uploaded)
      .forEach((file) => formData.append("files", file));

    formData.append("fileId", basicDatas.fileId);

    const fResult = await fetchAbsolute(
      "post",
      "/file/uploadMultipleFiles",
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    let fileId = lookupBpdInfo.fileId;

    // 첨부된 파일이 있으면 fileId를 받은 다음에 진행 
    if (fResult.data.length > 0) {
      fileId = fResult.data[0].fileId;
    }

    // 담당자 관련
    const workManager = [
      ...basicDatas.workManager.map((v, index) => ({
        mgrDevGbn: "M",
        empNo: v.empNum,
        deputyHeadYn: index === 0 ? "N" : "Y",
      }))
    ];
    const mergeData = { ...basicDatas, ...bPdData, workManager, fileId }
    const parameter = setRequestParameter(mergeData);

    const result = await fetchAbsolute("post", "/bpd/saveBpdHF", {
      data: { ...parameter },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.error) {
      return alert(formatMessage({ id: "T0235" }));
      //저장에 실패하였습니다.
    } else {
      alert(formatMessage({ id: "T0249" })); //저장에 되었습니다.
      const path = "/bizflow/hfbpd/list";
      history.push(path);
      return
    }
  };

  const onClickModify = async () => {
    const fetchData = async () => {
      //필수 입력 항목 체크
      for (const key in requireFields) {
        const field = requireFields[key];
        if (!field.use) continue;
        if (
          (key !== "ruleAcquaint" && Object.keys(basicDatas[key]).length <= 0) ||
          (key === "ruleAcquaint" && !basicDatas[key])
        ) {
          field.ref.current.focus();
          return alert(
            fmMessage({ id: "T0128", values: { field: field.message } })
          );
          //`${field.message} 항목은 필수 입력입니다.`);
        }
      }

      if (deleteFiles.length !== 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          const seq = deleteFiles[i];
          await fetchAbsolute("post", `/file/deleteFile/${seq}`);
        }
      }

      // file 첨부했을 경우
      const formData = new FormData();
      fileList
        .filter((file) => !file.uploaded)
        .forEach((file) => formData.append("files", file));

      formData.append("fileId", basicDatas.fileId);

      const fResult = await fetchAbsolute(
        "post",
        "/file/uploadMultipleFiles",
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let fileId = lookupBpdInfo.fileId;

      // 첨부된 파일이 있으면 fileId를 받은 다음에 modify 진행
      if (fResult.data.length > 0) {
        fileId = fResult.data[0].fileId;
      }

      // 담당자 관련
      const workManager = [
        ...basicDatas.workManager.map((v, index) => ({
          mgrDevGbn: "M",
          empNo: v.empNum,
          deputyHeadYn: index === 0 ? "N" : "Y",
        }))
      ];

      const mergeData = { ...lookupBpdInfo, ...basicDatas, ...bPdData, workManager, fileId }
      const parameter = setRequestParameter(mergeData);

      const result = await fetchAbsolute("post", `/bpd/saveBpdHF`, {
        data: { ...parameter },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (result.error) {
        return alert(formatMessage({ id: "T0235" }));
        //저장에 실패하였습니다.
      } else {
        alert(formatMessage({ id: "T0249" })); //저장에 되었습니다.
        const path = `/bizflow/hfbpd/lookup/${mbpdId}`;
        history.push(path);
        return;
      }
    };
    setLoading(fetchData);
  };

  //수정시
  useEffect(() => {
    if (!mbpdId) return;
    const fetchTaskInfo = async () => {
      const result = await fetchAbsolute("get", `/bpd/bpdInfoHF?bpdId=${mbpdId}`);
      const { data } = result;
      let fileData = [];

      if (result.redirectURL) {
        return history.replace(result.redirectURL);
      }
      if (Object.keys(data).length === 0) {
        return history.push("/error/notfound");
      }
      if (data.fileList !== undefined) {
        fileData = data.fileList.map((file) => ({
          name: file.fileName,
          uploaded: true,
          seq: file.seq,
          uid: file.seq,
        }));
        setFileList([...fileData]);
        setDefaultFiles([...fileData]);
        setLookupBpdInfo({ ...data, fileData });
      } else {
        setLookupBpdInfo({ ...data });
      }

      setRequireFields({ ...requireFields });

      const workManager = data.bpdMemberHFList.map(v => ({
        ...v,
        empNam: v.empNm,
        empNum: v.empNo,
        orgNam: v.orgNm
      }))

      const modifyData = {
        businessClass: { cd: data.workId, cdPathNm: data.workNm },
        taskName: data.bpdNm,
        relatedDepartment: { codeId: data.bpdOrgCd, codeNm: data.orgPathNm },
        totalManager: {
          NAME_KOR: data.regEmpNam,
          EMP_NO: data.regId,
          DEPT_NAME_KOR: data.regEmpOrgNam,
        },
        taskDesc: data.bpdDesc,
        workManager,
        fileId: data.fileId,
        bpdNm: data.bpdNm,
        processId: data.processId,
        bpdId: data.bpdId,
      }

      setBasicDatas({ ...modifyData });
      setBpdType("modify");
      setBpdData(data);
      // 수정화면에서 check-out을 위해 tempSave 호출
      onClickTempSave(modifyData);
    };
    setLoading(fetchTaskInfo);
    setIsSavedBasicInfo(true);
  }, [mbpdId]);

  useEffect(() => {
    if (defaultFiles.length > 0) tmpFile.current = [...defaultFiles];
  }, [defaultFiles]);


  const [bpdIframeVisible, setBpdIframeVisible] = useState(false);
  const [doubleCheckVisible, setDoubleCheckVisible] = useState(false);
  const onModifyBpdIframe = () => setBpdIframeVisible(true);
  const onOkBpdIframe = () => setDoubleCheckVisible(true);
  const onCancelBpdIframe = () => setBpdIframeVisible(false);
  const onOkDoubleCheck = () => {
    setBpdIframeVisible(false);
    setDoubleCheckVisible(false);
  }
  const onCancelDoubleCheck = () => setDoubleCheckVisible(false);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Container
        className="process_design_basic_info"
        style={{ display: "flex", flexDirection: "column", gap: "30px 0" }}
      >
        {/* ------ 기본 정보 부분 ------ */}
        <HFBpdEnterBasicBox
          profile={profile}
          insertDatas={basicDatas}
          onChangeDatas={onChangeBasicDatas}
          onClickBusinessOpen={onClickBusinessOpen}
          onClickDelete={onClickDeleteBasic}
          requireFields={requireFields}
          type={type}
          onBeforeupload={onBeforeupload}
          onRemoveFile={onRemoveFile}
          fileList={fileList}
          onClickOrga={onClickOrga}
          params={params}
          // bPdData={bPdData}
          isSavedBasicInfo={isSavedBasicInfo}
          onModifyBpdIframe={onModifyBpdIframe}
        />
        {!mbpdId &&
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              type={isSavedBasicInfo ? "disabled" : "primary"}
              onClick={() => onClickTempSave(basicDatas)}
              disabled={isSavedBasicInfo}
            >
              <FormattedMessage id="T1560" />{/* 기본 정보 저장 */}
              {/* 신규 등록인 경우에만 노출 */}
            </Button>
          </div>
        }
        {/* ------ HandyFlow ProcessDesign 부분 ------ */}
        {
          // (isSavedBasicInfo && bpdType ==="lookup") && (
          //   <AssignEnterBox title="Process Design">
          //     <HFBpdIframe
          //       profile={profile}
          //       type={bpdType}
          //       data={bPdData}
          //       params={params}
          //     />
          //   </AssignEnterBox>
          // )
        }
        <div className="flex justify-end">
          {type === "enter" ? (
            <Button
              type={!isSavedBasicInfo ? "disabled" : "primary"}
              style={{ width: 150, height: 40 }}
              onClick={onClickModalButton}
              disabled={!isSavedBasicInfo}
            >
              <FormattedMessage id="T0050" />
              {/* 등록 */}
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ width: 150, height: 40 }}
              onClick={onClickModalButton}
            >
              {type === "modify" && <FormattedMessage id="T0054" />}
              {/* 수정 */}
            </Button>
          )}
          <Button
            style={{ width: 150, height: 40, marginLeft: 30 }}
            onClick={onClickCancelButton}
          >
            <FormattedMessage id="T0051" />
            {/* 취소 */}
          </Button>
        </div>
      </Container>
      {/* 업무 분류 */}
      <BusinessClass
        visible={visibleBusiness}
        title={formatMessage({ id: "T1119" })} // 업무 분류 검색
        onOk={onOkBusiness}
        onCancel={onCancelBusiness}
        defaultData={
          Object.keys(basicDatas.businessClass).length > 0
            ? [basicDatas.businessClass]
            : []
        }
        max={1}
      />
      <Modal
        centered={true}
        title={formatMessage({ id: "T1577" })} // 등록(수정) 전 내용 확인 필요
        visible={confirmVisible}
        onOk={onOkModal}
        onCancel={onCancelModal}
        width="420px"
      >
        <Text style={{
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center'
        }}>
          {formatMessage({ id: "T1578" })}<br /><br />
          <div style={{ color: 'red' }}>
            {formatMessage({ id: "T1579" })
              .split("\n")
              .map((data) => {
                return (
                  <span>
                    {data}
                    <br />
                  </span>
                );
              })}
          </div>
        </Text>
        {/*
          상단 Process Design 영역의 저장 버튼을 클릭하셨나요? 
          *저장 버튼을 클릭하지 않고 등록(수정)하실 경우
          구성한 Process Design이 저장되지 않습니다.
        */}
      </Modal>
      <Organization
        visible={visibleOrgan}
        title={formatMessage({ id: "T1141" })} // 업무 담당자 등록 
        onOk={onOkOrgan}
        onCancel={onCancelOrgan}
        defaultData={organData}
        msColumn={true}
      />
      <HFBpdIframeModal
        type={type}
        // data={insertDatas}
        data={bPdData}
        params={params}
        visible={bpdIframeVisible}
        doubleCheckVisible={doubleCheckVisible}
        onModifyBpdIframe={onModifyBpdIframe}
        onOkBpdIframe={onOkBpdIframe}
        onCancelBpdIframe={onCancelBpdIframe}
        onOkDoubleCheck={onOkDoubleCheck}
        onCancelDoubleCheck={onCancelDoubleCheck}
      />
    </PageTemplate>
  );
};

export default HFBpdEnter;
