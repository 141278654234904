import { useSelector } from 'react-redux';
import { getUniqueKey } from 'utils/Str';

const PermissionComponent = ({ type='task', fnCode, fnType, children }) => {
    const permission = useSelector(state => state.get(getUniqueKey('permission')));
    if(type === 'task' && ((Array.isArray(fnCode) && fnCode.filter(v => permission.task.includes(v)).length > 0) || permission.task.includes(fnCode))) {
        return (
            children
        );
    } else if (type === 'user' && !fnType && permission.user.findIndex(v => v.fnCode === fnCode) !== -1 ) {
        return (
            children
        )
    } else if (type === 'user' && permission.user.findIndex(v => v.taskType === fnType && v.fnCode === fnCode) !== -1) {
        return (
            children
        )
    }
    return null;
}

export default PermissionComponent;