import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { TimeTable } from "components";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import { Badge } from "antd";

const AssignNewScheduleWeekly = ({
  date,
  onChangeDate,
  scheduleDatas,
  calInfo,
}) => {
  const badgeColor = {
    WIP: "processing",
    COMPLETED: "success",
    RUN_FAILED: "error",
    Scheduled: "default",
  };
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      key: 0,
      title: <FormattedMessage id="T1060" />, // devicePool
      dataIndex: "poolName",
      fixed: true,
      width: 55,
      render: (value, record, index) => {
        console.log("value,value",value);
        console.log("record",record);
        const len = dataSource?.filter((v) => v.poolName === value).length;
        const idx = dataSource?.findIndex((v) => v.poolName === value);
        const obj = {
          children: record.visible ? value : "",
          props: {
            style: {
              fontSize: 14,
              color: "#000",
            },
          },
        };
        if (index === idx) {
          obj.props.rowSpan = len;
        } else {
          obj.props.rowSpan = 0;
        }
        if (!dataSource[dataSource.findIndex((v) => v.id === record.id) + 1]?.visible) {
          obj.props.style.borderBottom = "none";
        }

        return obj;
      },
    },
  ];

  // 컬럼 세팅
  for (let i = 0; i < 7; i++) {
    const obj = {
      key: i,
      title: `${moment(date).day(i).format("YYYY-MM-DD")} (${moment(date)
        .day(i)
        .format("ddd")})`,
      dataIndex: moment(date).day(i).format("YYYYMMDD"),
      width: 115,
      align: "center",
    };
    // Row의 첫 번째 시간 컬럼을 한 줄로 만듬
    obj.render = (value, record) => {
      if (value) {
        return (
          <div
            className="schedule_weekly__name_box"
            key={record.taskId}
            style={{
              zIndex: 0,
              display: "flex",
              color: "#000",
            }}
            onClick={() => onClickDate(i)}
          >
            <Badge
              status={badgeColor[value]}
            />
            <span style={{ fontSize: 12, marginLeft: 8 }}>{record.taskNm}({record.taskId})</span>
          </div>
        );
      }
    };
    columns.push({ ...obj });
  }

  const onClickDate = (index) => {
    const yyyymm = moment(date).day(index).format("YYYYMM");
    const dd = moment(date).day(index).format("DD");

    onChangeDate({
      yyyymm: yyyymm,
      dd: dd,
      calendar: "daily",
    });
  };

  const getDatas = async () => {
    let prevData = -1;
    let visible = false;

    if (scheduleDatas[date]?.length === 0) {
      setDataSource([]);
    } else {
      setDataSource(
        Object.values(scheduleDatas)
          ?.map((data, index) => {
            if (prevData !== +data?.poolId[0]) {
              prevData = +data?.poolId[0];
              visible = true;
            }

            return {
              ...data,
              visible,
              key: index,
              devicePool: data?.poolId[0],
            };
          })
          .sort((a, b) => a.poolId[0] - b.poolId[0])
      );
    };
  }

  useEffect(() => {
    if (Object.keys(scheduleDatas).length <= 0 || date.length <= 0 || calInfo.calendar !== 'weekly') return;
    getDatas();
  }, [date, scheduleDatas, calInfo.calendar]);

  return (
    <div className="assign_schedule_weekly">
      <TimeTable
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default withRouter(AssignNewScheduleWeekly);
