import fetchAbsolute from "utils/fetchAbsolute";
import { takeEvery, put, select } from 'redux-saga/effects';
import { LOAD_USER_PERMISSION, LOAD_TASK_PERMISSION, loadPermissionFail, loadPermissionSuccess, startLoadData } from './action';

function* getUserPermissionData() {
    yield put(startLoadData('user'));
    const profile = yield select(state => state.get('auth').get('profile'));
    try {
        const { data } = yield fetchAbsolute('get', `/auth/userAuthLisst?empCd=${profile.EMP_NO}`);
        yield put(loadPermissionSuccess('user', data));
    } catch(err) {
        yield put(loadPermissionFail('user', err));
    };
};

function* getTaskPermissionData({taskInfo: { taskId, taskType }}) {
    yield put(startLoadData('task'));
    const profile = yield select(state => state.get('auth').get('profile'));
    try {
        const { data } = yield fetchAbsolute('get', `/auth/userTaskAuthList?empCd=${profile.EMP_NO}&processId=${taskId}&taskType=${taskType}`);
        yield put(loadPermissionSuccess('task', data));
    } catch(err) {
        yield put(loadPermissionFail('task', err));
    };
};

function* rootSaga() {
    yield takeEvery(LOAD_USER_PERMISSION, getUserPermissionData);
    yield takeEvery(LOAD_TASK_PERMISSION, getTaskPermissionData);
};

export default rootSaga;