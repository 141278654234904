import React, { useState, useRef, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { PageTemplate, Header, Footer, Button, Modal, Text } from "components";
import Wrapper from "./Styled";
import FilterArea from "./FilterArea";
import HostArea from "./HostArea";
import fetchAbsolute from "utils/fetchAbsolute";
import useFormatMessage from "hooks/useFormatMessage";
import AssignmentArea from "./AssignmentArea";

/**
 * Filename: AdminEmailTriggerEnter/index.js
 * Description: admin Email Trigger  등록 , 수정
 */

const initSenderInputValues = [{ filterType: "Sender", keyWord: "" }];
const initSubjectValues = [
  { filterType: "Subject", keyWord: "", operator: "Include" },
];
const initAttatchValues = [{ filterType: "FileName", keyWord: "" }];

const AdminEmailTriggerEnter = ({
  history,
  type,
  match: {
    params: { emailInfoSeq: memailInfoSeq },
  },
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [requireFields, setRequireFields] = useState({
    name: {
      ref: useRef(null),
      message: "Trigger Name",
      // use: true,
    },
    url: {
      ref: useRef(null),
      message: "URL",
      // use: true,
    },
    port: {
      ref: useRef(null),
      message: "Port",
      // use: true,
    },
    cycle: {
      ref: useRef(null),
      message: "Cycle",
      // use: true,
    },
    // password: {
    //     ref: useRef(null),
    //     message: "Password",
    //     // use: true,
    // },
    folder: {
      ref: useRef(null),
      message: `Folder`,
      // use: true,
    },
    protocol: {
      ref: useRef(null),
      message: `Protocol`,
      // use: true,
    },
    email: {
      ref: useRef(null),
      message: `email`,
      // use: true,
    },
    // senderList: {
    //     ref: useRef(null),
    //     message: `sender`,
    //     // use: true,
    // },
    subjectrList: {
      ref: useRef(null),
      message: `subject`,
      // use: true,
    },
  });

  const [senderValues, setSenderValues] = useState([...initSenderInputValues]);
  const [subjectValues, setSubjecValues] = useState([...initSubjectValues]);
  const [attatchValues, setAttatchValues] = useState([...initAttatchValues]);

  const [hostDatas, setHostDatas] = useState({
    name: "",
    url: "",
    port: "",
    cycle: "",
    email: "",
    password: "",
    folder: "INBOX",
  });

  const [tlsYn, setTlsYn] = useState("N");
  const [triggerYn, setTriggerYn] = useState("deactivate");
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [protocol, setProtocol] = useState("imaps");

  const onChangeInclude = (e, index) => {
    const tmpArr = [...subjectValues];
    tmpArr[index].operator = e;
    setSubjecValues([...tmpArr]);
  };

  const onChangeTls = (e) => {
    setTlsYn(e);
  };

  const onChangeTriggerYn = (e) => {
    setTriggerYn(e);
  };

  const onChangeProtocol = (e) => {
    setProtocol(e);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setHostDatas({ ...hostDatas, [name]: value });
  };

  const onChangeSender = (e) => {
    const { value } = e.target;
    const { index } = e.target.dataset;
    const tmpArr = [...senderValues];
    tmpArr[index].keyWord = value;
    setSenderValues([...tmpArr]);
  };
  const onChangeSubject = (e) => {
    const { value } = e.target;
    const { index } = e.target.dataset;
    const tmpArr = [...subjectValues];
    tmpArr[index].keyWord = value;
    setSubjecValues([...tmpArr]);
  };

  const onChangeAttatch = (e) => {
    const { value } = e.target;
    const { index } = e.target.dataset;
    const tmpArr = [...attatchValues];
    tmpArr[index].keyWord = value;
    setAttatchValues([...tmpArr]);
  };

  const onClickDeleteSender = (e, index) => {
    const tmpArr = [...senderValues];
    const delArr = tmpArr.filter((v) => v !== tmpArr[index]);
    setSenderValues([...delArr]);
  };

  const onClickAddSender = () => {
    const tmpArr = [...senderValues];
    tmpArr.push({
      filterType: "Sender",
      keyWord: "",
    });
    setSenderValues(tmpArr);
  };

  const onClickDeleteSubject = (e, index) => {
    const tmpArr = [...subjectValues];
    const delArr = tmpArr.filter((v) => v !== tmpArr[index]);
    setSubjecValues([...delArr]);
  };

  const onClickAddSubject = () => {
    const tmpArr = [...subjectValues];
    tmpArr.push({
      filterType: "Subject",
      keyWord: "",
      operator: "Include",
    });
    setSubjecValues(tmpArr);
  };

  const onClickDeleteAttatch = (e, index) => {
    const tmpArr = [...attatchValues];
    const delArr = tmpArr.filter((v) => v !== tmpArr[index]);
    setAttatchValues([...delArr]);
  };

  const onClickAddAttatch = () => {
    const tmpArr = [...attatchValues];
    tmpArr.push({
      filterType: "FileName",
      keyWord: "",
    });
    setAttatchValues(tmpArr);
  };

  const onClickAddTrigger = async () => {
    const mergeData = {
      ...hostDatas,
      activate: triggerYn,
      tlsYn: tlsYn,
      protocol: protocol,
      senderList: senderValues,
      subjectrList: subjectValues,
      fileNameList: attatchValues,
    };
    // const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    // if (!mergeData.url.match(expression)) {
    //     return alert(formatMessage({ id: 'T0158' }));
    // }

    if (Number(mergeData.cycle) < 60) {
      return alert(formatMessage({ id: "T1310" })); // *10초 이상 입력해주세요.
    }

    // if (senderValues.length < 1 || senderValues[0].keyWord.length < 1) {
    //     return alert("Sender 1개 이상 입력해주세요.");
    // }
    else if (subjectValues.length < 1 || subjectValues[0].keyWord.length < 1) {
      return alert(formatMessage({ id: "T1631" })); // Subject 1개 이상 입력해주세요.
    }

    for (const key in requireFields) {
      const field = requireFields[key];

      if (!mergeData[key] && Object.keys(mergeData[key].length <= 0)) {
        field.ref.current.focus();
        //`${field.message} 항목은 필수 입력입니다.`);
        return alert(
          fmMessage({ id: "T0128", values: { field: field.message } })
        );
      }
    }

    const TriggerAdd = await fetchAbsolute(
      "post",
      "/admin/trigger/email/save",
      {
        data: mergeData,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (TriggerAdd.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    } else {
      alert(formatMessage({ id: "T0249" })); // 저장 되었습니다.
      return history.push("/admin/emailtrigger");
    }
  };

  const onClickDelTrigger = async () => {
    setDeleteVisible(true);
  };

  const onOkDeleteTrigger = async () => {
    setDeleteVisible(false);
    const deleteComment = await fetchAbsolute(
      "delete",
      `/admin/trigger/email/delete/${memailInfoSeq}`
    );
    if (deleteComment.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    // alert(`${formatMessage({ id: 'T0284' })}`); //삭제 되었습니다.
    return history.push("/admin/emailtrigger");
  };

  const onCancelDelete = () => {
    setDeleteVisible(false);
  };

  const onClickModifyTrigger = async () => {
    setUpdateVisible(true);
  };
  const onCancelModify = () => {
    setUpdateVisible(false);
  };
  const onOkModifyTrigger = () => {
    onClickAddTrigger();
  };

  useEffect(() => {
    if (!memailInfoSeq) return;

    const fetchTriggerInfo = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/trigger/email/info?emailInfoSeq=${memailInfoSeq}`
      );
      const { data } = result;

      if (result.redirectURL) {
        return history.replace(result.redirectURL);
      }
      if (Object.keys(data).length === 0) {
        return history.push("/error/notfound");
      }

      setRequireFields({
        ...requireFields,
      });
      if (data.senderList?.length > 0) {
        const senderData = data.senderList?.map((element) => ({
          emailFilterSeq: element.emailFilterSeq,
          emailInfoSeq: element.emailInfoSeq,
          filterType: element.filterType,
          keyWord: element.keyWord,
        }));
        setSenderValues([...senderData]);
      }

      if (data.subjectrList?.length > 0) {
        const subjectData = data.subjectrList?.map((element) => ({
          emailFilterSeq: element.emailFilterSeq,
          emailInfoSeq: element.emailInfoSeq,
          filterType: element.filterType,
          keyWord: element.keyWord,
          operator: element.operator,
        }));
        setSubjecValues([...subjectData]);
      }
      if (data.fileNameList?.length > 0) {
        const fileNameListData = data.fileNameList?.map((element) => ({
          emailFilterSeq: element.emailFilterSeq,
          emailInfoSeq: element.emailInfoSeq,
          filterType: element.filterType,
          keyWord: element.keyWord,
        }));
        setAttatchValues([...fileNameListData]);
      }

      setHostDatas({
        emailInfoSeq: data.emailInfoSeq,
        name: data.name,
        url: data.url,
        port: data.port,
        cycle: data.cycle,
        email: data.email,
        password: data.password,
        protocol: data.protocol,
        folder: data.folder,
      });
      setTlsYn(data.tlsYn);
      setTriggerYn(data.activate);
      setProtocol(data.protocol);
    };
    fetchTriggerInfo();
  }, [memailInfoSeq]);

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      menuTitle={
        type === "modify"
          ? `Email Trigger ${formatMessage({ id: "T0054" })}` // 수정
          : `Email Trigger ${formatMessage({ id: "T0049" })}` // 추가
      }
      depthList={[
        `${formatMessage({ id: "T0881" })}`, // 관리자
        type === "modify"
          ? `Email Trigger ${formatMessage({ id: "T0054" })}` // 수정
          : `Email Trigger ${formatMessage({ id: "T0049" })}`, // 추가
      ]}
      headerColor="none"
    >
      <Wrapper
        style={{
          padding: "30px 50px",
        }}
      >
        <HostArea
          requireFields={requireFields}
          onChangeProtocol={onChangeProtocol}
          protocol={protocol}
          tlsYn={tlsYn}
          triggerYn={triggerYn}
          hostDatas={hostDatas}
          onChangeTls={onChangeTls}
          onChangeTriggerYn={onChangeTriggerYn}
          onChangeInput={onChangeInput}
        />
        <br />
        <FilterArea
          requireFields={requireFields}
          onChangeInclude={onChangeInclude}
          senderValues={senderValues}
          attatchValues={attatchValues}
          subjectValues={subjectValues}
          onChangeSubject={onChangeSubject}
          onChangeAttatch={onChangeAttatch}
          onClickAddSender={onClickAddSender}
          onClickAddAttatch={onClickAddAttatch}
          onClickDeleteAttatch={onClickDeleteAttatch}
          onClickAddSubject={onClickAddSubject}
          onClickDeleteSubject={onClickDeleteSubject}
          onClickDeleteSender={onClickDeleteSender}
          onChangeSender={onChangeSender}
        />
        <br />
        {type === "modify" && <AssignmentArea memailInfoSeq={memailInfoSeq} />}
        {type !== "modify" ? (
          <div className="admin_email__button_group">
            <Button
              type="primary"
              width="150"
              height="40"
              onClick={onClickAddTrigger}
            >
              <FormattedMessage id="T0064" />{/* 확인 */}
            </Button>
            <Button
              type="default"
              width="150"
              height="40"
              onClick={() => history.goBack()}
            >
              <FormattedMessage id="T0051" />{/* 취소 */}
            </Button>
          </div>
        ) : (
          <div className="admin_email__button_group">
            <Button
              type="primary"
              width="150"
              height="40"
              onClick={onClickModifyTrigger}
            >
              <FormattedMessage id="T0054" />{/* 수정 */}
            </Button>
            <Button
              type="primary"
              width="150"
              height="40"
              onClick={onClickDelTrigger}
            >
              <FormattedMessage id="T0072" />{/* 삭제 */}
            </Button>
            <Button
              type="default"
              width="150"
              height="40"
              onClick={() => history.goBack()}
            >
              <FormattedMessage id="T0051" />{/* 취소 */}
            </Button>
          </div>
        )}
      </Wrapper>
      <Modal
        centered
        visible={updateVisible}
        width={500}
        okText={formatMessage({ id: "T0064" })} // 확인
        title={`Email Trigger ${formatMessage({ id: "T0054" })}`} // Email Trigger 수정
        onOk={onOkModifyTrigger}
        onCancel={onCancelModify}
      >
        <div>
          <Text>{formatMessage({ id: "T0517" })}</Text>{/* 수정 하시겠습니까? */}
        </div>
      </Modal>
      <Modal
        centered
        visible={deleteVisible}
        width={500}
        okText={formatMessage({ id: "T0064" })} // 확인
        title={"Email Trigger Delete"}
        onOk={onOkDeleteTrigger}
        onCancel={onCancelDelete}
      >
        <div>
          <Text>{formatMessage({ id: "T1234" })}</Text>{/* 정말로 삭제 하시겠습니까? */}
        </div>
      </Modal>
    </PageTemplate>
  );
};

export default AdminEmailTriggerEnter;
