import React from "react";
import { Select } from "antd";
import { Input, FormTable } from "components";
import { CloseCircleFilled, CloseOutlined } from "@ant-design/icons";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";

const { Option } = Select;
/**
 * 검색 or 필터 저장할 경우에 나타나는 부분
 */
const AssignScheduleShow = ({
  beforeSearch,
  scheFilterList,
  onSelectMyFilter,
  onClickFilterDelete,
  filterIndex,
  onClickSearchDelete,
  onClickRecentSearch
}) => {
  const { formatMessage } = useIntl();

  const recentSearch = formatMessage({ id: "T0031" }); // 최근 검색
  const myFilter = formatMessage({ id: "T0032" }); // 내 필터
  const selectFilter = formatMessage({ id: "T0033" }); // 필터 선택

  const suffix =
    beforeSearch.length > 0 ? (
      <CloseOutlined onClick={onClickSearchDelete} />
    ) : (
      <span />
    );

  return (
    <Wrapper className="show__container">
      <FormTable pointBorder={false} size="sm">
        {beforeSearch.length > 0 && (
          <>
            <th>{recentSearch}</th>
            <td>
              <Input
                autoFocus
                style={{
                  width: 200,
                  height: 32,
                }}
                value={beforeSearch}
                suffix={suffix}
                onClick={onClickRecentSearch}
              />
            </td>
          </>
        )}
        {scheFilterList.length > 0 && (
          <>
            <th>{myFilter}</th>
            <td>
              <Select
                size="default"
                defaultValue="none"
                value={filterIndex}
                style={{
                  width: 200,
                  fontSize: 14
                }}
                onSelect={onSelectMyFilter}
              >
                <Option value="none">{selectFilter}</Option>
                {scheFilterList.map((filter, index) => {
                  return (
                    <Option key={index} value={index}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          sytle={{
                            width: 80,
                          }}
                        >
                          {filter.filterNm}
                        </div>
                        <div
                          sytle={{
                            width: 20,
                          }}
                        >
                          {
                            <CloseCircleFilled
                              onClick={onClickFilterDelete}
                              data-index={index}
                            />
                          }
                        </div>
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </td>
          </>
        )}
      </FormTable>
    </Wrapper>
  );
};
export default AssignScheduleShow;
