import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import { Loading, Empty } from 'components';
import { useIntl } from "react-intl";
import { Line } from '@ant-design/plots';
import fetchAbsolute from "utils/fetchAbsolute";

const { Title } = Typography;

/**
 * 대시보드 > ROI 현황 > 전사 ROI
 */

const WholeCompanyROI = ({ standardDate, conditions }) => {
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [wholeList, setWholeList] = useState([]);

    const configAll = {
        xField: 'monthly',
        yField: 'ROI',
        label: {
            position: 'outside',
            content: (item) => {
                return item.ROI.toFixed(3);
            },
            style: {
                textAlign: "center",
                fontWeight: 500,
            },
        },
        color: '#ff7a00',
        point: {
            size: 5,
            shape: 'circle',
            style: {
                stroke: '#ff7a00',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    };
    const configDiy = {
        xField: 'monthly',
        yField: 'ROI',
        label: {
            position: 'outside',
            content: (item) => {
                return item.ROI.toFixed(3);
            },
            style: {
                textAlign: "center",
                fontWeight: 500,
            },
        },
        color: '#ff7a00',
        point: {
            size: 5,
            shape: 'circle',
            style: {
                stroke: '#ff7a00',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    };
    const configCoe = {
        xField: 'monthly',
        yField: 'ROI',
        label: {
            position: 'outside',
            content: (item) => {
                return item.ROI.toFixed(3);
            },
            style: {
                textAlign: "center",
                fontWeight: 500,
            },
        },
        color: '#ff7a00',
        point: {
            size: 5,
            shape: 'circle',
            style: {
                stroke: '#ff7a00',
                lineWidth: 2,
            },
        },
        tooltip: {
            showMarkers: false,
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    };

    const getAllCompanyRoiList = async () => {
        if (!standardDate) return;
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/RoiStatusDesign/AllCompanyRoiList`, {
            data: {
                taskId: conditions.taskId,
                deployDate: standardDate,
                orgCd: conditions.companyCd,
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    monthly: data[i].monthly,
                    key: data[i].monthly,
                    taskType: data[i].taskType,
                    ROI: +data[i].roi || 0
                }
            )
        }
        setWholeList([...tmpArr]);
    }

    useEffect(() => {
        getAllCompanyRoiList();
    }, [standardDate, conditions.taskId, conditions.orgCd]);

    if (isLoading) {
        return <Loading visible={isLoading} />
    }
    return (
        <div className='box__container whole'>
            {/* 전사 ROI */}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1523" })}
            </Title>
            <Divider />
            <div className="dashboard_graph">
                <div className="whole_company_roi">
                    <div className="line_section">
                        <div className="line_title">ALL</div>
                        {
                            wholeList.filter(list => list.taskType === 'ALL').length > 0 ?
                                <Line
                                    {...configAll}
                                    data={wholeList.filter(list => list.taskType === 'ALL')}
                                />
                                :
                                <Empty />
                        }
                    </div>
                    <div className="line_section">
                        <div className="line_title">DIY</div>
                        {
                            wholeList.filter(list => list.taskType === 'DIY').length > 0 ?
                                <Line
                                    {...configDiy}
                                    data={wholeList.filter(list => list.taskType === 'DIY')}
                                />
                                :
                                <Empty />
                        }
                    </div>
                    <div className="line_section">
                        <div className="line_title">COE</div>
                        {
                            wholeList.filter(list => list.taskType === 'COE').length > 0 ?
                                <Line
                                    {...configCoe}
                                    data={wholeList.filter(list => list.taskType === 'COE')}
                                />
                                :
                                <Empty />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}


export default WholeCompanyROI