import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import { setVisible } from "store/loading/action";
import { setTaskData, taskInstantStart } from "store/assignLookup/action";
import { AddCredentialModal, DeleteCredentialModal, AddAttributeModal, ModifyAttributeModal, DeleteAttributeModal, DevicePool, InputSearch, Text, Table, Button, Modal, PermissionComponent } from "components";
import { Row, Col, Select, Input as AntInput } from "antd";
import { BlackSuccessIcon, BlackFailedIcon, BlackRequestIcon, BlackRejectIcon } from "icons";
import { DeleteOutlined, FileAddOutlined, EditOutlined, SyncOutlined } from "@ant-design/icons";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

const { Option } = Select;
const { TextArea } = AntInput;

/**
 * Filename: AssignLookupEnvironment.js
 * Description: 과제 조회 페이지 하단에 개발환경 탭 컴포넌트
 */

const AssignLookupEnvironment = () => {
  const profile = useSelector(state => state.get('auth').get('profile'));
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const assignLookup = useSelector(state => state.get(getUniqueKey('assignLookup')));

  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const askReleaseText = formatMessage({ id: 'T0063' }); // 운영 배포 요청
  const confirmationText = formatMessage({ id: 'T0064' }); // 확인
  const selectDevicePool = formatMessage({ id: 'T0146' }); // Device Pool을 선택하세요
  const attrListTakeFail = formatMessage({ id: 'T0168' }); // Attribute 목록을 가져오는데 실패하였습니다.
  const deployReason = formatMessage({ id: 'T0169' }); // 배포 요청 사유를 입력해주세요.
  const deployFail = formatMessage({ id: 'T0170' }); // 배포 요청 과정에서 오류가 발생하였습니다.
  const deployApproveInput = formatMessage({ id: 'T0171' }); // 배포 승인 사유를 입력해주세요.
  const prodDeploy = formatMessage({ id: 'T0172' }); // 운영 배포 승인에 실패하였습니다.
  const deployReturnReasonInput = formatMessage({ id: 'T0173' }); // 배포 반려 사유를 입력해주세요.
  const prodDeployReturnFail = formatMessage({ id: 'T0174' }); // 운영 배포 반려에 실패하였습니다.
  const directDeployReasonInput = formatMessage({ id: 'T0175' }); // 직접 배포 사유를 입력해주세요.
  const directDeployFail = formatMessage({ id: 'T0176' }); // 직접 배포에 실패하였습니다.
  const licenseGrantFail = formatMessage({ id: 'T0178' }); // License 부여를 실패했습니다.
  const prodDeployListFail = formatMessage({ id: 'T0178' }); // 운영 배포 현황 목록을 가져오는데 실패했습니다
  const dispatch = useDispatch();

  const [datas, setDatas] = useState([]);
  const [credentialList, setCredentialList] = useState([]);
  const [credentialName, setCredentialName] = useState("");
  const [diyCredentialName, setDiyCredentialName] = useState("");
  const [devicePool, setDevicePool] = useState({});
  const [requestReason, setRequestReason] = useState("");
  const [attributeList, setAttributeList] = useState({});
  const [selectAttribute, setSelectAttribute] = useState({});
  const [addAttrVisible, setAddAttrVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [approvalReason, setApprovalReason] = useState("");
  const [refuseReason, setRefuseReason] = useState("");
  const [selfRequestReason, setSelfRequestReason] = useState("");
  const [approvalVisible, setApprovalVisible] = useState(false);
  const [refuseVisible, setRefuseVisible] = useState(false);
  const [requestVisible, setRequestVisible] = useState(false);
  const [selfRequestVisible, setSelfRequestVisible] = useState(false);
  const [addCredentialVisible, setAddCredentialVisible] = useState(false);
  const [deleteCredentialVisible, setDeleteCredentialVisible] = useState(false);
  const [devicePoolVisible, setDevicePoolVisible] = useState(false);
  const [userAuth, setUserAuth] = useState("");
  const [isTaskFolder, setIsTaskFolder] = useState(false);
  const [spinReload, setSpinReload] = useState(false);
  const [credentialLoading, setCredentialLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({
    sort: "deploySeq,desc",
    page: 1,
    size: 5,
    total: 0,
  });

  const deployInterval = useRef(null);
  const refreshTimeout = useRef(null);

  const selectCr = crInfo.find(v => v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === 'DEV') || {};

  const goToDiyPrd = crInfo.find(v => v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === 'PRD') || {};

  // CR에 폴더 생성됐는지 확인
  const fetchBlmStatus = async () => {
    const result = await fetchAbsolute("get", `/blm/taskStatus/${assignLookup.taskData?.taskId}`);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

    if (result.data.resultCode === "S") {
      setIsTaskFolder(true);
    } else {
      setIsTaskFolder(false);
    }
  };

  const onClickProgress = () => {
    clearTimeout(refreshTimeout.current);
    setSpinReload(true);
    refreshTimeout.current = setTimeout(() => {
      setSpinReload(false);
    }, 2000);
    fetchBlmStatus();
  };

  // Credential 상세 조회 요청 함수
  const fetchAttributeList = async () => {
    setCredentialLoading(true);
    const result = await fetchAbsolute("get", `/hwlm/readCredential?name=${credentialName}&crType=${assignLookup.taskData?.taskType}`);
    setCredentialLoading(false);
    if (result.error) {
      setCredentialName("");
      return alert(attrListTakeFail);
    }
    setAttributeList({ ...result.data });
  };

  // Credential 목록 조회 요청 함수
  const fetchCredentials = async () => {
    try {
      const result = await fetchAbsolute("post", `/hwlm/readCredential`, {
        data: {
          sort: [
            {
              field: "id",
              direction: "asc",
            },
          ],
          crType: assignLookup.taskData?.taskType === "COE" ? "COE" : "DIY",
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      const { list } = result.data;

      setCredentialList([
        ...list.map((v) => ({
          id: v.id,
          name: v.name,
        })),
      ]);
    } catch { }
  };
  const onOkAddAttrVisible = () => {
    setAddAttrVisible(false);
    fetchAttributeList();
  };
  const onOkDeleteAttr = () => {
    setDeleteVisible(false);
    fetchAttributeList();
  };
  const onOkEditAttr = () => {
    setEditVisible(false);
    fetchAttributeList();
  };
  const onOkDeleteCredential = () => {
    setCredentialName("");
    setDeleteCredentialVisible(false);
    fetchCredentials();
  };
  const onOkAddCredential = () => {
    setAddCredentialVisible(false);
    fetchCredentials();
  };
  const onClickApproval = () => {
    setApprovalReason("");
    setApprovalVisible(true);
  };
  const onClickRefuse = () => {
    setRefuseReason("");
    setRefuseVisible(true);
  };
  const onClickRequestModal = () => {
    setRequestReason("");
    setRequestVisible(true);
  };
  const onClickSelfRequest = () => {
    setSelfRequestReason("");
    setSelfRequestVisible(true);
  };
  // 배포 승인 사유 TextArea 변경 함수
  const onChangeApproval = (e) => {
    setApprovalReason(e.target.value);
  };
  // 배포 반려 사유 TextArea 변경 함수
  const onChangeRefuse = (e) => {
    setRefuseReason(e.target.value);
  };
  // 배포 요청 사유 TextArea 변경 함수
  const onChangeRequest = (e) => {
    setRequestReason(e.target.value);
  };
  // 즉시 배포 사유 TextArea 변경 함수
  const onChangeSelfRequest = (e) => {
    setSelfRequestReason(e.target.value);
  };
  // 배포 이력 조회 함수 - interval
  const fetchDeployListwithInterval = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    };
    deployInterval.current = true;
    if (deployInterval.current) {
      let interval = setInterval(async () => {
      const lookUpResult = await fetchAbsolute("post", `/task/oracle/getTaskDeployList?taskId=${assignLookup.taskData?.taskId}&page=${page.page - 1}&size=${page.size}&sort=${page.sort}`);
      
      //에러가 발생하면 api호출을 멈추고, alert 메시지 띄워주기
      if(lookUpResult.error) {
        clearInterval(interval);
        deployInterval.current = false
        alert(prodDeployListFail);
        return;
      } 
        
      setDatas([...lookUpResult.data.taskDeployList]);
      if (assignLookup.taskData?.statusCd !== "T40" && assignLookup.taskData?.detailStatusCd !== "T41" && lookUpResult.data.taskDeployList[0]?.deployStatusCd === "T41") {
        const result = await fetchAbsolute("get", `/task/oracle/getTask/${assignLookup.taskData?.taskId}`);
        dispatch(setTaskData({ ...result.data }));
      }
      if (lookUpResult.data.taskDeployList[0]?.deployStatusCd !== "A15") {
        clearInterval(interval);
        deployInterval.current = false
        return;
      }
        
      }, 3000);

      // 5분뒤에 멈춤
      setTimeout(()=>{
        clearTimeout(interval)
      },300000)
    }
    clearInterval(deployInterval.current);
  };

  // 배포 이력 조회 함수 - interval없이
  const fetchDeployList = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    };

    const lookUpResult = await fetchAbsolute("post", `/task/oracle/getTaskDeployList?taskId=${assignLookup.taskData?.taskId}&page=${page.page - 1}&size=${page.size}&sort=${page.sort}`);
    
    if(lookUpResult.error) {
      alert(prodDeployListFail);
      return;
    } 
    
    setDatas([...lookUpResult.data.taskDeployList]);
    setPageInfo({ ...pageInfo, total: lookUpResult.data.totalItemsCount, page: lookUpResult.data.activePage + 1 });

    if (assignLookup.taskData?.statusCd !== "T40" && assignLookup.taskData?.detailStatusCd !== "T41" && lookUpResult.data.taskDeployList[0]?.deployStatusCd === "T41") {
      const result = await fetchAbsolute("get", `/task/oracle/getTask/${assignLookup.taskData?.taskId}`);
        dispatch(setTaskData({ ...result.data }));
    }
  }

  // 배포 요청 모달 확인 버튼 클릭 시
  const onOkRequest = async () => {
    if (requestReason.trim().length === 0) {
      return alert(deployReason);
    };
    dispatch(setVisible(true));
    const result = await fetchAbsolute("post", `/task/oracle/reqTaskDeploy?taskId=${assignLookup.taskData?.taskId}&requestReason=${requestReason}`);
    dispatch(setVisible(false));
    if (result.error) {
      return alert(deployFail);
    };

    // 요청 한 뒤 이력 조회
    fetchDeployList();
    setRequestVisible(false);
  };

  // 배포 승인 모달 확인 버튼 클릭 시
  const onOkApproval = async () => {
    if (approvalReason.trim().length === 0) {
      return alert(deployApproveInput);
    }

    dispatch(setVisible(true));
    const dResult = await fetchAbsolute("post",
      `/task/oracle/approvalTaskDeploy?taskId=${assignLookup.taskData?.taskId}&deploySeq=${datas[0].deploySeq}&approvalReason=${approvalReason}`);
    dispatch(setVisible(false));
    if (dResult.error) {
      return alert(prodDeploy);
    }

    // 요청 한 뒤 이력 조회
    fetchDeployListwithInterval();
    setApprovalReason("");
    setApprovalVisible(false);
  };
  // 배포 반려 모달 확인 버튼 클릭 시
  const onOkRefuse = async () => {
    if (refuseReason.trim().length === 0) {
      return alert(deployReturnReasonInput);
    }

    dispatch(setVisible(true));
    const dResult = await fetchAbsolute("post", `/task/oracle/refuseTaskDeploy?taskId=${assignLookup.taskData?.taskId}&deploySeq=${datas[0].deploySeq}&refuseReason=${refuseReason}`);
    if (dResult.error) {
      dispatch(setVisible(false));
      return alert(prodDeployReturnFail);
    }

    fetchDeployList();

    // const result = await fetchAbsolute("get", `/task/oracle/getTask/${assignLookup.taskData?.taskId}`);
    // dispatch(setVisible(false));

    // // 요청 한 뒤 이력 조회
  
    // dispatch(setTaskData({ ...result.data }));
    setRefuseReason("");
    setRefuseVisible(false);
  };
  // 직접 배포 요청 모달 확인 버튼 클릭 시
  const onOkSelfRequest = async () => {
    if (selfRequestReason.trim().length === 0) {
      return alert(directDeployReasonInput);
    }
    const confirmResult = window.confirm(formatMessage({ id: 'T0320' })); // 직접 배포를 하시겠습니까?
    if (!confirmResult) return;
    dispatch(setVisible(true));
    const dResult = await fetchAbsolute("post", `/task/oracle/addTaskDeploy`, {
      data: {
        taskId: assignLookup.taskData?.taskId,
        deployReason: selfRequestReason,
      },
    });
    dispatch(setVisible(false));
    if (dResult.error) {
      return alert(directDeployFail);
    }

    // 요청 한 뒤 이력 조회
    fetchDeployListwithInterval();
    setSelfRequestReason("");
    setSelfRequestVisible(false);
  };
  // 배포 승인 모달 Cancel 이벤트
  const onCancelApproval = () => {
    setApprovalVisible(false);
  };
  // 배포 반려 모달 Cancel 이벤트
  const onCancelRefuse = () => {
    setRefuseVisible(false);
  };
  // 직접 배포 요청 모달 Cancel 이벤트
  const onCancelSelfRequest = () => {
    setSelfRequestVisible(false);
  };
  // 운영 배포 요청 모달 Cancel 이벤트
  const onCancelRequest = () => {
    setRequestVisible(false);
  };

  const onClickEditAttrButton = (record) => {
    setSelectAttribute({ ...record });
    setEditVisible(true);
  };

  const onClickDeleteAttrButton = (record) => {
    setSelectAttribute({ ...record });
    setDeleteVisible(true);
  };

  const onChangeCredentialName = (value) => {
    setCredentialName(value);
  };

  const onClickDevicePoolModal = () => {
    setDevicePoolVisible(true);
  };
  const onOkDevicePoolModal = (data) => {
    setDevicePool({ ...data });
    setDevicePoolVisible(false);
  };
  const onCancelDevicePoolModal = () => {
    setDevicePoolVisible(false);
  };

  const onClickRunNowButton = () => {
    dispatch(taskInstantStart({
      poolName: devicePool.devicePool || devicePool.dpNm,
      cr: selectCr.crCd,
      chooseDevice: formatMessage({ id: "T0146" }), // Device Pool을 선택하세요
      successMsg: formatMessage({ id: "T0147" }), // 즉시 실행이 시작되었습니다.
      failedMsg: formatMessage({ id: "T0148" }), // 즉시 실행 오류가 발생하였습니다.
      crType: selectCr.crXEnv,
      directionMsg: formatMessage({ id: "T1301" }), // 대기 시간 이후에 자동으로 실행할까요?
    }));
  };

  const onClickGotoDev = async (e) => {
    e.preventDefault();
    const result = await fetchAbsolute("get", `/admin/license/addLicense?empNum=${profile.EMP_NO}`);
    const UserAuthNm = await fetchAbsolute("get", `/common/commonCd/findByCategoryIdAndCd?categoryId=AUTH_GROUP&cd=${userAuth}`);
    if (result.error || result.data.resultCode !== "S") {
      return alert(result.data.message + `\n\n(${fmMessage({ id: 'T0177', values: { permission: UserAuthNm.data.cdNm } })})` || licenseGrantFail);
    }
    /* 개발 CR이 없는 경우 운영 CR로 이동 */
    if (Object.keys(selectCr).length === 0) {
      return window.open(goToDiyPrd.crUri);
    };
    window.open(selectCr.crUri);
  };

  const onClickCoeDev = () => {
    if (Object.keys(selectCr).length === 0) {
      return window.open(goToDiyPrd.crUri);
    }
    window.open(selectCr.crUri);
  }

  const onClickDiyCredential = () => {
    setCredentialName(diyCredentialName);
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'deploySeq';
    // workGbnNm이 정렬할 때는 workPathNm로 사용됨
    if (sorter.field === 'workGbnNm') order = sorter.columnKey
    let orderBy = "";
    if (!sorter.order) {
      order = "deploySeq"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";

    const searchObj = {
      ...pageInfo,
      sort: order + orderBy,
      page: pagination.current
    };

    fetchDeployList({ ...searchObj })
  }

  const releaseColumns = [
    {
      key: 1,
      title: formatMessage({ id: "T1059" }), // NO
      width: 60,
      render: (text, record, index) =>
        pageInfo.total - (pageInfo.page - 1) * pageInfo.size - index,
      align: "center",
    },
    {
      key: 2,
      title: () => {
        if(assignLookup.taskData.taskType ==="DIY")
          {
            return formatMessage({ id: "T0523" })  // 배포자
          } else {
            return `${formatMessage({ id: "T0523" })}(${formatMessage({ id: "T1666" })})` // 배포자(요청자)
          }
        }, 
      width: 140,
      dataIndex: "deployAcceptEmpNm",
      render: (text,record,index) => {
        if(record.deployCr==="DIY") {
          return text;
        } else if(record.deployCr==="COE") {
          // 배포자가 없으면 "admin"으로 나옴
          if(record.deployAcceptEmpNm==="admin") {
            return `- (${record.deployReqEmpNm})`;
          } else {
            return `${text}(${record.deployReqEmpNm})`;
          }
          
        }
      },
      align: "center",
    },
    {
      key: 3,
      title: formatMessage({ id: "T0524" }), // 배포 CR
      width: 100,
      dataIndex: "deployCr",
      align: "center",
    },
    {
      key: 4,
      title: formatMessage({ id: "T0525" }), // 배포 일자
      align: "center",
      width: 180,
      dataIndex: "deployReqTimestamp",
      render: (date) => {
        return moment(date).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      key: 5,
      title: formatMessage({ id: "T0526" }), // 배포 사유
      width: 524,
      dataIndex: "deployReason",
    },
    {
      key: 6,
      title: formatMessage({ id: "T0527" }), // 배포 결과
      dataIndex: "deployStatusNm",
      width: 200,
      align: "center",
      render: (text, record) => {
        if (record.deployStatusCd === "A15") {
          return (
            <div className="deploy_table__status">
              <SyncOutlined spin={true} style={{ fontSize: 17 }} />
              <span>{text}</span>
            </div>
          );
        } else if (record.deployStatusCd === "A16") {
          return (
            <div className="deploy_table__status">
              <BlackFailedIcon />
              <span>{text}</span>
            </div>
          );
        } else if (record.deployStatusCd === "A11") {
          return (
            <div className="deploy_table__status">
              <BlackRejectIcon />
              <span>{text}</span>
            </div>
          );
        } else if (record.deployStatusCd === "A09") {
          return (
            <div className="deploy_table__status">
              <BlackRequestIcon />
              <span>{text}</span>
            </div>
          );
        } else {
          return (
            <div className="deploy_table__status">
              <BlackSuccessIcon />
              <span>{text}</span>
            </div>
          );
        }
      },
    },
  ];

  const devColumns = [
    {
      title: formatMessage({ id: 'T1059' }), // NO
      render: (text, record, index) => index + 1,
      align: "center",
      key: "no",
    },
    {
      key: "name",
      title: formatMessage({ id: 'T1134' }), // Attribute
      dataIndex: "name",
    },
    {
      key: "value",
      title: formatMessage({ id: 'T1135' }), // Value
      dataIndex: "value",
      render: (text, record) => {
        return record.passwordFlag ? "********" : text;
      },
    },
    {
      key: "passwordFlag",
      title: formatMessage({ id: 'T1136' }), // Password
      dataIndex: "passwordFlag",
      render: (value) => (value ? "Y" : "N"),
    },
    {
      key: "description",
      title: formatMessage({ id: 'T0100' }), // 설명
      dataIndex: "description",
    },
    {
      title: formatMessage({ id: "T0528" }), // 기능
      dataIndex: "empNm",
      key: "empNm",
      render: (text, record) => {
        return (
          <Text>
            <PermissionComponent fnCode="FN17">
              <EditOutlined
                style={{ paddingRight: 20 }}
                onClick={() => onClickEditAttrButton(record)}
              />
            </PermissionComponent>
            <PermissionComponent fnCode="FN18">
              <DeleteOutlined onClick={() => onClickDeleteAttrButton(record)} />
            </PermissionComponent>
          </Text>
        );
      },
    },
  ];

  useEffect(() => {
    if (!assignLookup.taskData?.taskId) {
      return;
    }
    const fetchUserAuth = async () => {
      const result = await fetchAbsolute("get", `/common/memberNoList?empNo=${profile.EMP_NO}`);
      setUserAuth(result.data.mdFPAList[0].dwpUserLevel);
    };

    fetchUserAuth();
    if (assignLookup.taskData?.taskId) {
      if (assignLookup.taskData?.taskType === "COE") {
        fetchCredentials();
      }
      fetchDeployList();
      fetchBlmStatus();
      if (assignLookup.taskData?.taskType !== "COE") {
        const mainMgrEmpNo = assignLookup.taskData?.taskMemberList?.find((v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N").empNo;
        setDiyCredentialName(mainMgrEmpNo);
      }
    }
  }, [assignLookup.taskData?.taskId, profile.EMP_NO]);

  useEffect(() => {
    if (credentialName.length <= 0) {
      setAttributeList({});
    } else {
      fetchAttributeList();
    }
  }, [credentialName]);


  useEffect(() => {
    // 컴포넌트 Unmount 시 interval Clear
    return () => {
      clearInterval(deployInterval.current);
    }
  }, []);

  return (
    <div className="env_box__wrap lookup_body__box">
      <Modal
        width={540}
        visible={approvalVisible}
        title={formatMessage({ id: "T0536" })} // 배포 승인 사유 입력
        okText={confirmationText}
        onCancel={onCancelApproval}
        onOk={onOkApproval}
      >
        <Text
          style={{
            fontSize: 14,
            marginTop: 20,
          }}
        >
          {formatMessage({ id: "T0537" })}{/* 배포 승인 사유 */}
        </Text>
        <TextArea
          style={{ marginTop: 10 }}
          placeholder={deployApproveInput}
          rows={4}
          onChange={onChangeApproval}
          value={approvalReason}
          maxLength={66}
        />
      </Modal>
      <Modal
        width={540}
        visible={refuseVisible}
        title={formatMessage({ id: "T0538" })} // 배포 반려 사유 입력
        okText={confirmationText}
        onCancel={onCancelRefuse}
        onOk={onOkRefuse}
      >
        <Text
          style={{
            fontSize: 14,
            marginTop: 20,
          }}
        >
          <FormattedMessage id="T1210" />{/* 배포 반려 사유 */}
        </Text>
        <TextArea
          style={{ marginTop: 10 }}
          placeholder={deployReturnReasonInput}
          rows={4}
          onChange={onChangeRefuse}
          value={refuseReason}
          maxLength={66}
        />
      </Modal>
      <Modal
        width={540}
        visible={requestVisible}
        title={askReleaseText}
        okText={confirmationText}
        onCancel={onCancelRequest}
        onOk={onOkRequest}
      >
        <Text
          style={{
            fontSize: 14,
            marginTop: 20,
          }}
        >
          {formatMessage({ id: "T0539" })}{/* 배포 요청 사유 */}
        </Text>
        <TextArea
          style={{ marginTop: 10 }}
          placeholder={deployReason}
          rows={4}
          onChange={onChangeRequest}
          value={requestReason}
          maxLength={66}
        />
      </Modal>
      <Modal
        width={540}
        visible={selfRequestVisible}
        title={formatMessage({ id: "T0080" })} // 직접 운영 배포
        okText={confirmationText}
        onCancel={onCancelSelfRequest}
        onOk={onOkSelfRequest}
      >
        <Text
          style={{
            fontSize: 14,
            marginTop: 20,
          }}
        >
          {formatMessage({ id: "T0539" })}{/* 배포 요청 사유 */}
        </Text>
        <TextArea
          style={{ marginTop: 10 }}
          placeholder={deployReason}
          rows={4}
          onChange={onChangeSelfRequest}
          value={selfRequestReason}
          maxLength={66}
        />
      </Modal>
      <PermissionComponent fnCode={["FN28", "FN15", "FN26", "FN25"]}>
        <div className="env_box">
          {/* selectCr이 없으면 보이면 안돼 */}
          {Object.keys(selectCr).length !== 0 && ((assignLookup.taskData?.statusCd === "T40" ||
            (assignLookup.taskData?.statusCd === "T30" &&
              assignLookup.taskData?.detailStatusCd === "T32"))) && (
              <>
                <div className="status_box__title">
                  <Text>{formatMessage({ id: "T0529" })}</Text>{/* 운영 배포 현황 */}
                </div>
                <div className="env_box__info">
                  {datas[0]?.deployStatusCd !== "A15" && (
                    <div className="release_button__area">
                      {datas[0]?.deployStatusCd === "A09" ? (
                        assignLookup.taskData?.taskType === "COE" &&
                        userAuth === "UA06" && (
                          <>
                            <div className="oper__buttons">
                              <Button
                                type="default"
                                width="144"
                                height="40"
                                onClick={onClickApproval}
                              >
                                {formatMessage({ id: "T0078" })}{/* 운영 배포 승인 */}
                              </Button>
                              <Button
                                type="default"
                                width="144"
                                height="40"
                                onClick={onClickRefuse}
                              >
                                {formatMessage({ id: "T0079" })}{/* 운영 배포 반려 */}
                              </Button>
                            </div>
                          </>
                        )
                      ) : (
                        <>
                          {assignLookup.taskData?.taskType === "DIY" && (
                            <div
                              style={{
                                display: "flex",
                                flex: "auto",
                                alignSelf: "flex-end",
                                gap: 5,
                                alignItems: "center",
                              }}
                            >
                              {/* <InfoIcon />
                              <Text
                                className="go_to_link"
                                type="link"
                                link="#!"
                              >
                                <FormattedMessage id="T1073" /> 운영 Runner Bot 환경구성 확인
                              </Text> */}
                            </div>
                          )}
                          <div className="oper__buttons">
                            <PermissionComponent fnCode="FN28">
                              <Button
                                type="primary"
                                width="144"
                                height="40"
                                onClick={onClickSelfRequest}
                              >
                                {formatMessage({ id: "T0080" })}{/* 직접 운영 배포 */}
                              </Button>
                            </PermissionComponent>
                            <PermissionComponent fnCode="FN15">
                              <Button
                                type="primary"
                                width="144"
                                height="40"
                                onClick={onClickRequestModal}
                              >
                                <FormattedMessage id="T0063" />{/* 운영 배포 요청 */}
                              </Button>
                            </PermissionComponent>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  <div className="sub_box__title">
                    <Table
                      defaultPageSize={pageInfo.size}
                      pagination={true}
                      pageSizeVisible={false}
                      columns={releaseColumns}
                      dataSource={datas}
                      rowKey={(item) => item.deploySeq}
                      locale={{ emptyText: `${formatMessage({ id: "T0530" })}` }} // 운영 배포 내역이 없습니다.
                      onChange={onChangeTableColumns}
                      pageOptions={{
                        total: +pageInfo.total,
                        current: +pageInfo.page,
                        pageSize: +pageInfo.size,
                      }}
                    />
                  </div>
                </div>
              </>
            )}
        </div>
      </PermissionComponent>
      <div className="env_box">
        <div className="env_box__title">
          <Text>{formatMessage({ id: "T0531" })}</Text>{/* 개발 환경 */}
        </div>
        <div className="env_box__info">
          <Row>
            <Col span={3}>
              <Text className="env_row__title">
                {formatMessage({ id: "T0532" })}{/* 업무 부서 */}
              </Text>
            </Col>
            <Col span={9}>
              <Text className="env_row__text">
                {
                  assignLookup.taskData?.taskMemberList?.find(
                    (v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N"
                  ).orgNm
                }
              </Text>
            </Col>
            <Col span={3}>
              <Text className="env_row__title">
                {
                  formatMessage({ id: "T0508" }) // 개발 CR 
                }
              </Text>
            </Col>
            <Col span={9} className="env_row__dev_cr">
              {assignLookup.taskData?.taskType === "COE" ? (
                <>
                  <Text
                    className="env_row__text"
                    onClick={onClickCoeDev}
                  >
                    {Object.keys(selectCr).length !== 0 ? selectCr.crUri : goToDiyPrd.crUri}
                  </Text>
                  <Button className="env_row__button" onClick={onClickCoeDev}>
                    <FormattedMessage id="T0058" />{/* 이동 */}
                  </Button>
                </>
              ) : (
                <>
                  <Text
                    className="env_row__uri"
                    onClick={onClickGotoDev}
                  >
                    {
                      Object.keys(selectCr).length !== 0 ?
                        selectCr.crUri : goToDiyPrd.crUri
                    }
                  </Text>
                  <Button className="env_row__button" onClick={onClickGotoDev}>
                    <FormattedMessage id="T0058" />{/* 이동 */}
                  </Button>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col span={3}>
              <Text className="env_row__title">
                {formatMessage({ id: "T0533" })}{/* 작업 폴더 */}
              </Text>
            </Col>
            <Col
              span={9}
              style={{
                display: "flex",
                gap: 5,
                alignItems: 'flex-start',
              }}
            >
              <Text
                className={`env_row__text ${!isTaskFolder && "error__text"}`}
              >
                {`Bots > ${assignLookup.taskData?.folderPath} > ${assignLookup.taskData?.taskId}`}
              </Text>
              {!isTaskFolder && (
                <div className="env_row__progress_folder">
                  <Text>{formatMessage({ id: "T0534" })}</Text>{/* (생성중) */}
                  <SyncOutlined
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onClickProgress();
                    }}
                    spin={spinReload}
                  />
                </div>
              )}
            </Col>
            <PermissionComponent fnCode="FN21">
              {Object.keys(selectCr).length !== 0 && assignLookup.taskData?.taskType === "COE" && (
                <>
                  <Col span={3}>
                    <Text className="env_row__title">
                      {formatMessage({ id: "T0397" })}{/* 테스트 */}
                    </Text>
                  </Col>
                  <Col span={9}>
                    <InputSearch
                      placeholder={selectDevicePool}
                      width={200}
                      height={32}
                      data-label="devicePool"
                      value={devicePool.dpNm || ""}
                      onClick={onClickDevicePoolModal}
                      style={{ paddingRight: "15px" }}
                    />
                    <Button onClick={onClickRunNowButton}>
                      <FormattedMessage id="T0059" />{/* 즉시 실행 */}
                    </Button>
                  </Col>
                </>
              )}
            </PermissionComponent>
          </Row>
          <PermissionComponent fnCode="FN27">
            <Row>
              <Col span={3}>
                <Text className="env_row__title">Credential</Text>
              </Col>
              {assignLookup.taskData?.taskType === "COE" ? (
                <Col
                  span={9}
                  className="flex align-center"
                  style={{ gap: "0 7px" }}
                >
                  <Select
                    className="env__credential_select"
                    name="credential"
                    value={credentialName}
                    defaultValue=""
                    onChange={onChangeCredentialName}
                  >
                    <Option value="">{formatMessage({ id: "T0535" })}</Option>{/* Credential을 선택하세요 */}
                    {credentialList.map((v) => (
                      <Option key={v.id} value={v.name}>
                        {v.name}
                      </Option>
                    ))}
                  </Select>
                  <div className="flex" style={{ gap: "0 7px" }}>
                    {credentialName.length > 0 && (
                      <PermissionComponent fnCode="FN20">
                        <DeleteOutlined
                          style={{ fontSize: 20 }}
                          onClick={() => setDeleteCredentialVisible(true)}
                        />
                      </PermissionComponent>
                    )}
                    <PermissionComponent fnCode="FN19">
                      <FileAddOutlined
                        style={{ fontSize: 20 }}
                        onClick={() => setAddCredentialVisible(true)}
                      />
                    </PermissionComponent>
                  </div>
                </Col>
              ) : (
                <Col
                  span={9}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                  }}
                >
                  <Text>{diyCredentialName}</Text>
                  <Button type="primary" onClick={onClickDiyCredential}>
                    {formatMessage({ id: "T0081" })}{/* 조회 */}
                  </Button>
                </Col>
              )}
              <Col offset={9} span={3} className="flex justify-end">
                {credentialName.length > 0 && (
                  <PermissionComponent fnCode="FN16">
                    <Button
                      type="default"
                      width="140"
                      height="32"
                      onClick={() => setAddAttrVisible(true)}
                    >
                      {formatMessage({ id: "T0065" })}{/* ATTRIBUTE 추가 */}
                    </Button>
                  </PermissionComponent>
                )}
              </Col>
            </Row>
            <Table
              columns={devColumns}
              loading={credentialLoading}
              dataSource={attributeList.attributes}
              rowKey={(item) => item.id}
            />
          </PermissionComponent>
        </div>
      </div>
      <DevicePool
        visible={devicePoolVisible}
        onOk={onOkDevicePoolModal}
        onCancel={onCancelDevicePoolModal}
        crCode={selectCr.crCd}
        defaultData={[devicePool]}
      />
      <AddAttributeModal
        visible={addAttrVisible}
        onCancel={() => setAddAttrVisible(false)}
        onOk={onOkAddAttrVisible}
        credentialName={credentialName}
        crType={
          assignLookup.taskData?.taskType?.toUpperCase() === "COE"
            ? "COE"
            : "DIY"
        }
      />
      <ModifyAttributeModal
        visible={editVisible}
        onCancel={() => setEditVisible(false)}
        onOk={onOkEditAttr}
        credentialName={credentialName}
        attribute={selectAttribute}
        crType={
          assignLookup.taskData?.taskType?.toUpperCase() === "COE"
            ? "COE"
            : "DIY"
        }
      />
      <DeleteCredentialModal
        visible={deleteCredentialVisible}
        onCancel={() => setDeleteCredentialVisible(false)}
        onOk={onOkDeleteCredential}
        credentialName={credentialName}
        crType={
          assignLookup.taskData?.taskType?.toUpperCase() === "COE"
            ? "COE"
            : "DIY"
        }
      />
      <DeleteAttributeModal
        visible={deleteVisible}
        onCancel={() => setDeleteVisible(false)}
        onOk={onOkDeleteAttr}
        credentialName={credentialName}
        attribute={selectAttribute}
        crType={
          assignLookup.taskData?.taskType?.toUpperCase() === "COE"
            ? "COE"
            : "DIY"
        }
      />
      <AddCredentialModal
        visible={addCredentialVisible}
        onOk={onOkAddCredential}
        onCancel={() => setAddCredentialVisible(false)}
        crType={
          assignLookup.taskData?.taskType?.toUpperCase() === "COE"
            ? "COE"
            : "DIY"
        }
      />
    </div>
  );
};

export default AssignLookupEnvironment;