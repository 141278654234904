import React, { useState } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Collapse,
  AdminDeviceUpdateBox,
  Text,
} from "components";
import Wrapper from "./Styled";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl, FormattedMessage } from "react-intl";

const DevicePoolUpdate = ({ match: { params: { crType, devicePoolName, crCode } }, history }) => {
  const { formatMessage } = useIntl();

  const [openCollapse, setOpenCollapse] = useState(false);
  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  return (
    <PageTemplate
      header={<Header />} footer={<Footer />} headerColor='none'
      menuTitle={`${formatMessage({ id: 'T1071' })}`} // Device Pool 수정
      depthList={[`${formatMessage({ id: 'T0881' })}`, // 관리자
      `${formatMessage({ id: 'T1071' })}`]} // Device Pool 수정
    >
      <Wrapper
        style={{
          padding: "30px 50px",
        }}
      >
        <Text style={{ fontSize: 20, fontWeight: "bold" }}><FormattedMessage id="T1071" /></Text>{/* Device Pool 수정 */}
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          defaultActiveKey={["1"]}
        >
          <AdminDeviceUpdateBox crType={crType} devicePoolName={devicePoolName} crCode={crCode} history={history} />
        </Collapse>
      </Wrapper>
    </PageTemplate>
  );
};

export default DevicePoolUpdate;
