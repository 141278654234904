import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { getUniqueKey } from 'utils/Str';
import { withRouter } from "react-router-dom";
import LookupReceipt from "./LookupReceipt";
import LookupAnalytical from "./LookupAnalytical/LookupAnalytical";
import LookupProgress from "./LookupProgress";
import LookupInspection from "./LookupInspection";
import Stepper from "../AssignLookupStepper";

/**
 * Filename: LookupBasic.js
 * Description: 과제 조회 페이지 하단에 기본 정보 탭 전체 컴포넌트
 */

const LookupBasic = ({ taskData = {}, setActiveTab }) => {

    const assignLookup = useSelector(state => state.get(getUniqueKey('assignLookup')));

    const [checkPoint, setCheckPoint] = useState("");

    useEffect(() => {
        if (assignLookup.taskData?.statusCd === "T10" && assignLookup.taskData?.detailStatusCd === "T11") {
            setCheckPoint(1);
            //개발 반려
        } else if (assignLookup.taskData?.statusCd === "T50" && assignLookup.taskData?.detailStatusCd === "T13") {
            setCheckPoint(1);
            //중단 
        } else if (assignLookup.taskData?.statusCd === "T50") {
            setCheckPoint(6);
        } else if (assignLookup.taskData?.statusCd === "T10" && assignLookup.taskData?.detailStatusCd === "T12") {
            setCheckPoint(2);
        } else if (assignLookup.taskData?.statusCd === "T20") {
            setCheckPoint(3);
        } else if (assignLookup.taskData?.detailStatusCd === "T32") {
            setCheckPoint(5);
        } else if (assignLookup.taskData?.statusCd === "T30") {
            setCheckPoint(4);
        } else {
            setCheckPoint(5);
        }

    }, [assignLookup.taskData?.statusCd, assignLookup.taskData?.detailStatusCd]);



    return (
        <div className="flex basic_info_box lookup_body__box">
            <Stepper checkPoint={checkPoint} />
            <div className="basic_box__wrap">
                <LookupReceipt />
                <LookupAnalytical />
                <LookupProgress setActiveTab={setActiveTab} />
                <LookupInspection />
            </div>
        </div>
    );
};

export default withRouter(LookupBasic);