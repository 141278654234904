import React, {useEffect} from "react";
import { Button, Text, CustomTable } from "components";
import { Row, Col,Input as AntInput } from "antd";
import {
  PaperClipOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from "react-intl";
import { getUniqueKey } from 'utils/Str';
import { useSelector } from "react-redux";

const ProgressModification = ({
    progressCondition,
    operRequestCondition,
    operModiCondition,
    requestCondition,
    profileStatusDev,
    onClickFinishDevButton,
    editList,
    errorFile,
    onClickRestart,
    deactivateCondition
}) => {

    const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

    return (
        <div style={{paddingTop:"20px"}}>
            <Row style={{paddingTop:"20px"}}>
                <Col>
                <Text className={deactivateCondition?"basic_row__title status__disabled":"basic_row__title"}>
                        <FormattedMessage id="T1636" />{/* 개발 내역 */}
                    </Text>
                </Col>
            </Row>

            {
            editList?.length ===0
            ?
            <div>
                <Row style={{alignItems:"center"}}>
                    <Col span={24}>
                    <div className={deactivateCondition?"basic_box__content status__disabled":"basic_box__content"}>
                        <div className={deactivateCondition?"progress_text status__disabled":"progress_text"}>
                                <FormattedMessage id="T1603" /> {/* 수정 내역이 없습니다. */}
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                (progressCondition||operModiCondition)&&profileStatusDev
                &&
                <Row>
                    <Col offset={21} span={3}>
                        <Button
                            type="primary"
                            height="40"
                            width="130"
                            onClick={onClickFinishDevButton}
                            >
                            <FormattedMessage id="T0061" />{/* 개발 완료 */}
                        </Button>
                    </Col>
                </Row>}
            </div>          
            : 
                <div>
                {
                hfWorkflowLookup.workflowData.editList?.map((val,index)=>{
                    return(
                <CustomTable index={index}>
                    <colgroup>
                    <col width="13%" />
                    <col width="16%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="12%" />
                    <col width="35%" />
                    </colgroup>
                        <tbody>
                        <tr>
                            <th>
                                <Text>
                                    <FormattedMessage id="T1642" />{/* 구분 */}
                                </Text>
                            </th>
                            <td>
                                <div style={{display:"flex", alignItems:"center"}}>
                                    {
                                        hfWorkflowLookup.workflowData.editList.length-index-1===0
                                        ? <Text className="editlist__round" style={{whiteSpace: "nowrap"}}>
                                            <FormattedMessage id="T1641" /> {/* 최초 개발 */}
                                            </Text>
                                        : <Text className="editlist__round" style={{whiteSpace: "nowrap"}}>
                                            수정 {hfWorkflowLookup.workflowData.editList.length-index-1}
                                            <FormattedMessage id="T0835" /> {/* 회차 */}
                                            </Text>
                                    }
                                    {
                                    // 개발완료 아닐때만 수정시작 버튼 보여주기
                                    val.devCompleteYn === "N"
                                    && (requestCondition || operRequestCondition) 
                                    && profileStatusDev
                                    &&
                                        <Button
                                        width="100%"
                                        type="default"
                                        onClick={onClickRestart}
                                        style={{fontSize:"11px", whiteSpace: "nowrap"}}
                                    >
                                        <FormattedMessage id="T1605" />{/* 수정 시작 */}
                                    </Button>
                                    }
                                </div>
                            </td>
                            
                            {hfWorkflowLookup.workflowData.editList.length-1!==index
                            &&
                            <th>
                                <Text>
                                    <FormattedMessage id="T1606" />{/* 시정 요청일 */}
                                </Text>
                            </th>
                            }
                             {hfWorkflowLookup.workflowData.editList.length-1!==index
                            &&
                            <td>
                                <Text>{moment(val.inputTimestamp).format("YYYY-MM-DD")}</Text>&nbsp;
                            </td>
                            }
                            {hfWorkflowLookup.workflowData.editList.length-1!==index
                            ?
                                <th>
                                    <Text>
                                        <FormattedMessage id="T1607" />{/* 요청 처리일 */}
                                    </Text>
                                </th>
                            :
                                <th>
                                    <Text>
                                        <FormattedMessage id="T1637" />{/* 소요 개발일 */}
                                    </Text>
                                </th>
                            }
                            <td colspan={14}>
                            { 
                                val.realStartDt
                                &&<Text>{val.realStartDt} ~ {val.realEndDt}&nbsp;</Text>
                            }
                            </td>
                        </tr>
                        {
                            hfWorkflowLookup.workflowData.editList.length-1!==index
                            &&
                            <tr>
                            <th>
                                <Text>
                                    <FormattedMessage id="T1608" />{/* 오류 사항 */}
                                </Text>
                            </th>
                            <td colSpan={20}>
                                <Text>
                                {
                                    // 엔터(\n)있으면 줄바꿈 해주기
                                    val.mgeComment?.includes('\n')
                                    ?  val.mgeComment?.split('\n').map(data =>
                                        <div>{data}</div>
                                        )
                                    :  val.mgeComment  
                                    }
                                </Text>
                                <div className="file__list" style={{display:"flex", flexDirection:"column"}}>
                                {
                                    errorFile.length>0
                                    && 
                                    errorFile.map((value,index)=>{
                                    if(val.fildId===value.fileId) 
                                        return(
                                        <Text
                                            key={index}
                                            type="download"
                                            download={value.down}
                                            fileName={value.fileName}
                                        >
                                        <PaperClipOutlined />
                                            {value.fileName}
                                        </Text>)
                                    })
                                }
                                </div>
                            </td>
                        </tr>
                        }
                        <tr>
                            <th>
                                <Text>
                                    <FormattedMessage id="T1609" />{/* 상세 처리 내용 */}
                                </Text>
                            </th>
                            <td colSpan={20}>
                                <Text>
                                    {
                                        // 엔터(\n)있으면 줄바꿈 해주기
                                        val.devComment?.includes('\n')
                                        ?  val.devComment?.split('\n').map(data =>
                                            <div>{data}</div>
                                            )
                                        :  val.devComment&&val.devComment
                                    }
                                </Text>    
                            </td>
                        </tr>
                        </tbody>
                </CustomTable>
                )
                })
                }
                </div>
            }

            {
            ((progressCondition||operModiCondition)&&profileStatusDev&&editList?.length > 0)
            ? <Row style={{marginTop:"10px"}}>
                <Col offset={21} span={3} style={{alignItems:"center"}}>
                    <div style={{display:"flex", justifyContent: "flex-end"}}>
                    <Button
                        type="primary"
                        height="40"
                        width="130"
                        onClick={onClickFinishDevButton}
                        >
                        <FormattedMessage id="T0061" />{/* 개발 완료 */}
                    </Button>
                    </div>
                </Col>
            </Row>
            : <span></span>
            }
        </div>
    )
}

export default withRouter(ProgressModification);
