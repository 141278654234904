import styled from 'styled-components';
import { Modal } from 'components';

const Wrapper = styled(Modal)`
    .ant-modal-footer {
        justify-content: center;
    }
    .active_modal__button {
        // display: flex;
        justify-content: center;
    }
    .modal_slider {
        justify-content: center;
        width:84%;
        margin: 0 auto;
    }
    .modal_slider_display{
        display: flex;
        margin: 0 auto;
        width: 100%;
        flex-direction:column;
    }
    .active_modal__display {
        display: flex;
        margin: 0 auto;
        width: 100%;
        height: 650px;
        padding-bottom:10px;
    }

    .active_modal__display img {
        width: 100%;
        max-height: 663px;
        object-fit: contain;
    }
    .replay_playTime{
      margin-left:110px;
    }
    .replay_playTime_Text{
        font-weight:bold;
    }
    .active_modal__title {
        font-size: 20px;
    }
    .active_modal__title.bold {
        font-weight: bold;
    }

    .active_modal__search_wrap {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
    }
    .active_modal__search_box {
        display: flex;
        align-items: center;
        gap: 5px;
    }
    .active_modal__picker_box {
        display: flex;
        align-items: center;
        gap: 3px;
    }
`;

export default Wrapper;