import React from 'react';
import styled from 'styled-components';
import {Input, Button} from 'components';
import {SearchOutlined} from '@ant-design/icons';

/**
 * Filename: InputSearch.js
 * Description: Input 옆에 검색 버튼이 함께 있는 컴포넌트
 */

const Wrapper = styled.div `
    display: inline-flex;
    .search__input {
        border-radius: initial;
    }
    .search__button {
        width: ${props => props.height}px;
        height: ${props => props.height}px;
        background-color: white; 
        border-color: #d9d9d9;
        border-left: none;
        font-size: large;
        color: #b1b1b1
    } 
`;

const InputSearch = ({ disabled, placeholder = '', value = '', onChange = () => {}, onClick = () => {}, children = React.Fragment, width = 200, height = 40, innerRef, ...args }) => {
    const onClickButton = (e) => {
        onClick(e.currentTarget.parentElement);
    }
    return (
        <Wrapper width={width} height={height} disabled {...args}>
            <Input
                readOnly
                style={{
                    width: width - height,
                    height: height
                }}
                className='search__input'
                placeholder={placeholder}
                value={value}
                onChange={onChange} 
                disabled={disabled}
                innerRef={innerRef}
            />
            <Button className='search__button' disabled={disabled} onClick={onClickButton}>
                <SearchOutlined /> 
            </Button>
        </Wrapper>
    )
}

export default InputSearch;