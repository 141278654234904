import React from "react";
import { Calendar } from "antd";
import styled from "styled-components";

/* test
 */
const CalendarStyled = styled(Calendar)`
  .ant-picker-body {
    text-align: left;
    border: 1px solid rgb(220, 221, 225);
    border-radius: 20px;
  }
  .ant-picker-calendar-header {
    display: none;
  }
  .ant-picker-calendar-date-value {
    padding-left: 5px;
  }
  .ant-picker-month-panel,
  .ant-picker-date-panel {
    width: auto;
    padding-bottom: 1%;
  } 
  .ant-picker-panel .ant-picker-body {
    padding: 20px 0;
  }

  /* sunday */
  table.ant-picker-content > thead > tr th:nth-child(1) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(1):after {
    visibility: visible;
    content: "Sun";
    font-weight: normal;
  }

  /* monday */
  table.ant-picker-content > thead > tr th:nth-child(2) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(2):after {
    visibility: visible;
    content: "Mon";
    font-weight: normal;
  }

  /* tuesday */
  table.ant-picker-content > thead > tr th:nth-child(3) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(3):after {
    visibility: visible;
    content: "Tue";
    font-weight: normal;
  }

  /* wednesday */
  table.ant-picker-content > thead > tr th:nth-child(4) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(4):after {
    visibility: visible;
    content: "Wed";
    font-weight: normal;
  }

  /* thursday */
  table.ant-picker-content > thead > tr th:nth-child(5) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(5):after {
    visibility: visible;
    content: "Thu";
    font-weight: normal;
  }

  /* friday */
  table.ant-picker-content > thead > tr th:nth-child(6) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(6):after {
    visibility: visible;
    content: "Fri";
    font-weight: normal;
  }

  /* saturday */
  table.ant-picker-content > thead > tr th:nth-child(7) {
    visibility: hidden;
  }
  table.ant-picker-content > thead > tr th:nth-child(7):after {
    visibility: visible;
    content: "Sat";
    font-weight: normal;
  }

  .ant-fullcalendar-calendar-body {
    border: 1px solid rgb(220, 221, 225);
    border-radius: 20px;
  }
  .ant-fullcalendar-header {
    display: none;
  }
  .ant-fullcalendar-column-header-inner {
    text-align: left;
    font-weight: bold;
    font-size: 15px;
  }
`;

const MonthlyCalendar = ({ locale = {}, children, ...args }) => {
  return <CalendarStyled {...args}>{children}</CalendarStyled>;
};

export default MonthlyCalendar;
