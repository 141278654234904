import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Button, Text, PermissionComponent, Modal } from "components";
import { Row, Col } from "antd";
import { getUniqueKey } from 'utils/Str';
import sendNoti from "utils/sendNoti";
import fetchAbsolute from "utils/fetchAbsolute";
import { changeHistoryData } from "store/assignLookup/action";
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
/**
 * Filename: LookupProgress.js
 * Description: 과제 조회 페이지 하단에 기본 정보 탭 중 3. [개발] 개발 진행 컴포넌트
 * statusCd === "T20", detailStatusCd === "T21"
 */
const FirstForm = ({ onClickFinishDevButton, onClickCheckDevEnvButton, isFolder }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="basic_box">
      <div className="basic_box__title">
        <Text>
          <FormattedMessage id="T0518" />{/* 3. [개발] 개발 진행 */}
        </Text>
      </div>
      <div className="basic_box__info">
        <div className="progress_text">
          {formatMessage({ id: "T0519" })}{/* 개발 환경 정보를 확인한 후, 개발을 진행합니다. */}
        </div>
        <div className="progress_text">{formatMessage({ id: "T0520" })}</div>{/* 개발 완료 후에는 개발 완료 버튼을 클릭하여 테스트를 진행합니다. */}
        <br />
        {/* <div><Button onClick={onClickBoxwoodWfOpen}>Boxwood</Button></div> */}

        <Row>
          <Col span={6} offset={18} className="flex justify-between">
            <Button
              type="default"
              width="120"
              height="32"
              onClick={onClickCheckDevEnvButton}
            >
              <FormattedMessage id="T0060" />{/* 개발 환경 확인 */}
            </Button>
            <PermissionComponent fnCode="FN12">
              <Button
                type={isFolder ? "primary" : "disabled"}
                disabled={!isFolder}
                width="120"
                height="32"
                onClick={onClickFinishDevButton}
              >
                <FormattedMessage id="T0061" />{/* 개발 완료 */}
              </Button>
            </PermissionComponent>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const DisabledForm = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="basic_box status__disabled">
      <div className="basic_box__title">
        <Text className="sub_box__title">{formatMessage({ id: 'T0518' })}</Text>{/* 3. [개발] 개발 진행 */}
      </div>
      <div className="basic_box__info">
        <div className="progress_text">
          {formatMessage({ id: "T0519" })}{/* 개발 환경 정보를 확인한 후, 개발을 진행합니다. */}
        </div>
        <div className="progress_text">{formatMessage({ id: "T0520" })}</div>{/* 개발 완료 후에는 개발 완료 버튼을 클릭하여 테스트를 진행합니다. */}
        <Row>
          <Col span={6} offset={18} className="flex justify-between">
            <Button
              type="default"
              width="120"
              height="32"
              className="block__button"
              disabled
            >
              <FormattedMessage id="T0060" />{/* 개발 환경 확인 */}
            </Button>
            <PermissionComponent fnCode="FN12">
              <Button
                type="default"
                width="120"
                height="32"
                className="block__button"
                disabled
              >
                <FormattedMessage id="T0061" />{/* 개발 완료 */}
              </Button>
            </PermissionComponent>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ThirdForm = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="basic_box">
      <div className="basic_box__title">
        <Text>
          <FormattedMessage id="T0518" />{/* 3. [개발] 개발 진행 */}
        </Text>
      </div>
      <div className="basic_box__info">
        <div className="progress_text">
          {formatMessage({ id: "T0519" })}{/* 개발 환경 정보를 확인한 후, 개발을 진행합니다. */}
        </div>
        <div className="progress_text">{formatMessage({ id: "T0520" })}</div>{/* 개발 완료 후에는 개발 완료 버튼을 클릭하여 테스트를 진행합니다. */}
        <br />
        {/* <div><Button onClick={onClickBoxwoodWfOpen}>Boxwood</Button></div> */}

      </div>
    </div>
  );
};
const LookupProgress = ({ history, location, setActiveTab }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const errorOccur = formatMessage({ id: "T0153" }); // 오류가 발생하였습니다.

  const profile = useSelector(state => state.get('auth').get('profile'));
  const assignLookup = useSelector((state) =>
    state.get(getUniqueKey("assignLookup"))
  );

  const [isFolder, setIsFolder] = useState(false);
  const [finishDevVisible, setFinishDevVisible] = useState(false);


  // CR에 폴더 생성됐는지 확인
  const fetchBlmStatus = async () => {
    const result = await fetchAbsolute(
      "get",
      `/blm/taskStatus/${assignLookup.taskData?.taskId}`
    );
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

    if (result.data.resultCode === "S") {
      setIsFolder(true);
    } else {
      setIsFolder(false);
    }
  };

  const onClickFinishDevButton = () => setFinishDevVisible(true);

  // 개발 완료 모달 OK 함수
  const onOkFinishDevButton = async () => {
    const result = await fetchAbsolute("post", "/task/oracle/chgTask", {
      data: {
        taskId: assignLookup.taskData?.taskId,
        chgCd: "T30",
        chgCdDetail: "T31",
        chgEmpNo: profile.EMP_NO,
        chgDetailDesc: formatMessage({ id: 'T0061' }), // 개발 완료 
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) {
      return alert(errorOccur);
    }
    sendNoti("TP08", assignLookup.taskData?.taskId);
    dispatch(changeHistoryData(result.data));
    setFinishDevVisible(false);
  };

  const onCancelFinishtDev = () => setFinishDevVisible(false);

  // 개발 환경 확인 버튼
  const onClickCheckDevEnvButton = () => {
    history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/env`);
    setActiveTab('env');
  };

  useEffect(() => {
    if (assignLookup.taskData?.statusCd === "T20") {
      fetchBlmStatus();
    }
  }, [assignLookup.taskData?.statusCd]);

  return (
    <>
      <Modal
        centered
        visible={finishDevVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: 'T0485' })} // 개발 완료 확인
        onOk={onOkFinishDevButton}
        onCancel={onCancelFinishtDev}
      >
        <Text>{formatMessage({ id: 'T0486' })}</Text>{/* 개발을 완료하시겠습니까? */}
        <br />
        <br />
        <Text style={{ color: "#0000FF" }}>
          {formatMessage({ id: 'T0487' })}{/* ※ 모든 Task가 Check-in 되어야 운영 배포가 가능합니다. */}
        </Text>
        <br />
        <Text style={{ color: "#0000FF" }}>
          {formatMessage({ id: 'T0488' })}{/* Task Check-in 여부를 확인 후 완료해주세요. */}
        </Text>
      </Modal>

      {assignLookup.taskData?.statusCd === "T20" ? (
        <FirstForm
          onClickFinishDevButton={onClickFinishDevButton}
          isFolder={isFolder}
          onClickCheckDevEnvButton={onClickCheckDevEnvButton}
          profile={profile}
        />
      ) : assignLookup.taskData?.statusCd === "T10" ||
        assignLookup.taskData?.detailStatusCd === "T13" ||
        assignLookup.taskData?.statusCd === "T50" ? (
        <DisabledForm />
      ) : (
        <ThirdForm profile={profile} />
      )}
    </>
  );
};

export default withRouter(LookupProgress);