import React from 'react';
import { Text, LinkModal } from 'components';

const ReadyForService = () => {
    return (
        <LinkModal
            visible={true}
            title=""
            width={540}
            closable={false}
            footer={[]}
            titleDisabled
            footerDisabled
        >
            <div className='request_box'>
                <Text className='request_box__text'>
                    <b>서비스 준비중입니다.</b>
                </Text>
            </div>
        </LinkModal>
    )
}

export default ReadyForService;