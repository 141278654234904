import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
background: #fff;
.admin_coemanage__table_wrap {
    margin-top: 30px;
}
tr {
    cursor: pointer
}
.ant-row {
    display: block; !important
}
thead[class*="ant-table-thead"] th{
    font-weight: bold;
  }
.admin_coemanage_addButton{
    display: flex;
    justify-content: flex-end;
}
.CoE_manage__top{
display: flex;
justify-content: space-between;
padding-top: 20px;
}
.ant-table {
    width: 100%;
  }
  .ant-table-thead > tr > th {q
    height: 50px;
    padding: 16px 16px 15px 20.3px;
    color: #000;
  }
  .ant-table-tbody td {
    height: 50px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  .ant-table-tbody td > span {
      font-size: inherit;
      color: inherit;
  }
  .ant-table-pagination.ant-pagination { 
    padding-bottom: 84px;
  }
`;

export default Wrapper;
