import { Badge, Radio } from "antd";
import {
  DoubleLeftOutlined,
  LeftOutlined,
  DoubleRightOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Text,
  Button,
  RadioButton,
  AssignNewScheduleCalendar,
  AssignNewScheduleDaily,
  AssignNewScheduleWeekly,
} from "components";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import XLSX from "xlsx-js-style";

const AssignNewScheduleBottom = ({
  replaceSearch,
  searchQuery,
  scheduleDatas,
  initialSearchQuery,
  calInfo
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const inputValues = useSelector((state) => state.get(getUniqueKey("assignSchedule")).inputValues);

  const barColor = {
    WIP: "#2b65d9",
    COMPLETED: "#3dba61",
    RUN_FAILED: "#e10013",
    Scheduled: "#c1c1c1", // weekly
    undefined: "#c1c1c1",
    New: "#c1c1c1", // weekly
  };

  const onChangeCalendarRadio = ({ target: { value } }) => {
    const tmpObj = { ...calInfo, ...searchQuery, calendar: value };
    replaceSearch({ ...tmpObj });
  };

  const onClickDateButton = (e) => {
    const { step, matic } = e.currentTarget.dataset;
    const date = moment(`${calInfo.yyyymm}${calInfo.dd}`, "YYYY-MM-DD");
    let calDate = moment();

    if (matic === "minus") {
      calDate = date.subtract(1, step);
    } else if (matic === "plus") {
      calDate = date.add(1, step);
    }

    onChangeDate({
      yyyymm: calDate.format("YYYYMM"),
      dd: calDate.format("DD"),
      specificDay: calDate.format("YYYYMMDD"),
    });
  };

  const onChangeDate = (obj) => {
    const tmpObj = {
      ...calInfo,
      ...searchQuery,
      ...obj,
    };
    replaceSearch(tmpObj);
  };

  const onClickExcelButton = async () => {
    let devicePoolNm = "";
    let new_workbook = XLSX.utils.book_new();
    let fileName = '';

    // if(input) 선택 안했을 경우에 제외
    if (inputValues.taskType.cd === "COE" && inputValues?.dpId !== "") {
      devicePoolNm = sessionStorage.getItem("COE");
    } else {
      sessionStorage.removeItem("COE");
    }
    if (
      inputValues.taskType.cd === "DIY" &&
      Object.keys(inputValues).length > 0
    ) {
      devicePoolNm = sessionStorage.getItem("DIY");
    } else {
      sessionStorage.removeItem("DIY");
    }
    if (calInfo.calendar === "monthly") {
      const tableWrap = document.getElementById('tableWrap')
      const infoTable = document.getElementById('table');
      const scheTable = document.querySelector(".ant-picker-content");
      const scheTableBody = document.querySelector(".ant-picker-content tbody");

      tableWrap.append(infoTable);

      infoTable.innerHTML = `  
        <tbody> 
          <tr>
            <th rowspan="10" style="background-color: #FF7B00; text-align: center; font-weight: bold; color: #FFF;"> 검색결과 <td>
          </tr>
          <tr> 
            <th>${formatMessage({ id: "T0001" })}</th>
            <td>${inputValues.taskId || ""}</td>
          </tr>
          <tr>
            <th>${formatMessage({ id: "T0002" })}</th>
            <td>${inputValues.taskNm || ""}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0003" })}</th>
            <td>${inputValues.taskType.cdNm || ""} - ${inputValues.taskType.cd === "DIY"
          ? "DIY"
          : inputValues.taskType.cd || ""
        }</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0008" })}</th>
            <td>${inputValues.workId.map((c) => c.cdNm).join(", ")}</td>
          </tr>
          <tr> 
            <th>${formatMessage({ id: "T0005" })}</th>
            <td>${inputValues.orgCd.map((c) => c.codeNm).join(", ")}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0006" })}</th>
            <td>${inputValues.mgrEmpNo.map((c) => c.empNam).join(", ")}</td>
            </tr>
            <tr>
            <th>Device Pool</th>
            <td>${inputValues.taskType.cd === "COE"
          ? inputValues.devicePool.map((d) => d.dpNm).join(", ")
          : inputValues.devicePool.map((d) => d.description).join(", ")}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0010" })}</th>
            <td>${typeof inputValues.scheduleType === "string"
          ? inputValues.scheduleType
          : inputValues.scheduleType.map((c) => c).join(", ")}</td>
          </tr>
        </tbody>  
        <div></div> 
      `;

      /* 테이블이 변경되지 않도록 */
      const clone = document.importNode(scheTable, true);
      const cloneTbody = document.importNode(scheTableBody, true);

      clone.innerHTML = ` 
                    <thead> 
                        <tr></tr>
                        <tr><th></th></tr>
                        <tr> 
                            <th> ${calInfo.yyyymm.replace(
        /(\d{4})(\d{2})/,
        "$1.$2"
      )} 
                            </th>
                            </tr>
                        <tr><th></th></tr>
                        <tr>
                            <th>Sun</th>
                            <th>Mon</th>
                            <th>Tue</th>
                            <th>Wed</th>
                            <th>Thu</th>
                            <th>Fri</th>
                            <th>Sat</th>
                        </tr>
                    </thead>
                `;
      clone.append(cloneTbody);
      infoTable.after(clone);

      fileName = `${moment(calInfo.yyyymm).format("YYYY-MM")}(${searchQuery.taskType.cd === "DIY"
        ? "DIY"
        : searchQuery.taskType.cd
        }).xlsx`;

      /* 테이블 2개 합치기 */
      let config = { raw: true, type: 'string', header: 1 };
      let ws1 = XLSX.utils.table_to_sheet(infoTable, config);
      let ws2 = XLSX.utils.table_to_sheet(clone, config);
      let a = XLSX.utils.sheet_to_json(ws1, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      let b = XLSX.utils.sheet_to_json(ws2, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      a = a.concat(['']).concat(b);
      let ws = XLSX.utils.json_to_sheet(a, { skipHeader: true })
      XLSX.utils.book_append_sheet(new_workbook, ws, "Sheet1");


      /* css */
      for (let i in ws) {
        if (typeof (ws[i]) !== "object") continue;

        ws[i].v = ws[i]['v'].split(')').join(')\n');
        ws[i].s = {
          alignment: {
            wrapText: true,
            vertical: "top",
          }
        }
        if (i.includes('15') && i.length === 3) {
          ws[i].s = {
            font: {
              name: 'Malgun Gothic',
              sz: 10,
              bold: true
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            }
          }
        }
        for (let num = 2; num < 10; num++) {
          if (i.includes('B' + num) && i.length === 2) {
            ws[i].s = {
              font: {
                name: 'Malgun Gothic',
                sz: 10,
                bold: true
              },
              alignment: {
                wrapText: true,
                vertical: "center",
                horizontal: "center",
              }
            }
          }
        }
      }

      /* 날짜랑 데이터 줄 나누기 */
      for (let char = 65; char < 72; char++) {
        const alpahbet = String.fromCharCode(char)
        for (let num = 16; num < 22; num++) {
          if (typeof (ws[alpahbet + num]) !== "object") continue;
          ws[alpahbet + num].v = ws[alpahbet + num].v.substring(0, 2) + '\n\n'
            + ws[alpahbet + num].v.substring(2);
        }
      }

      let merge = { s: { r: 12, c: 0 }, e: { r: 12, c: 6 } };
      let merge2 = { s: { r: 0, c: 0 }, e: { r: 9, c: 0 } };

      /* 월 7칸 병합 */
      if (!ws['!merges']) ws['!merges'] = [];
      ws['!merges'].push(merge);
      ws['!merges'].push(merge2);
      if (!ws['!cols']) ws['!cols'] = [];
      let cellsWidth = Array.from({ length: 7 }, () => ({ width: 30 }));
      ws['!cols'].push(...cellsWidth);
      if (!ws['!rows']) ws['!rows'] = [];
      let cellsRows = Array.from({ length: Object.keys(ws).length }, () => ({ hpt: 'fit-content' }))
      ws['!rows'].push(...cellsRows);
      /* 특정 셀에 적용 */
      ws['A1'].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 10,
          bold: true,
          color: "#FFF"
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: 'FF7B00' },
          fgColor: { rgb: 'FF7B00' }
        },
      }
      ws['A13'].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 12,
          bold: true,
          color: "#FFF"
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: 'FF7B00' },
          fgColor: { rgb: 'FF7B00' }
        },
      }
    } else if (calInfo.calendar === 'weekly') {
      const infoTable = document.getElementById('table');
      const scheTableHead = document.querySelector(".ant-table-content thead");
      const scheTableBody = document.querySelector(".ant-table-content tbody");

      /* 테이블이 변경되지 않도록 */
      const clone = document.importNode(infoTable, true);
      const cloneHead = document.importNode(scheTableHead, true);
      const cloneBody = document.importNode(scheTableBody, true);

      infoTable.innerHTML = `  
        <tbody> 
          <tr>
            <th rowspan="10" style="background-color: #FF7B00; text-align: center; font-weight: bold; color: #FFF;"> 검색결과 <td>
          </tr>
          <tr> 
            <th>${formatMessage({ id: "T0001" })}</th>
            <td>${inputValues.taskId || ""}</td>
          </tr>
          <tr>
            <th>${formatMessage({ id: "T0002" })}</th>
            <td>${inputValues.taskNm || ""}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0003" })}</th>
            <td>${inputValues.taskType.cdNm || ""} - ${inputValues.taskType.cd === "DIY"
          ? "DIY"
          : inputValues.taskType.cd || ""
        }</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0008" })}</th>
            <td>${inputValues.workId.map((c) => c.cdNm).join(", ")}</td>
          </tr>
          <tr> 
            <th>${formatMessage({ id: "T0005" })}</th>
            <td>${inputValues.orgCd.map((c) => c.codeNm).join(", ")}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0006" })}</th>
            <td>${inputValues.mgrEmpNo.map((c) => c.empNam).join(", ")}</td>
            </tr>
            <tr>
            <th>Device Pool</th>
            <td>${inputValues.taskType.cd === "COE"
          ? inputValues.devicePool.map((d) => d.dpNm).join(", ")
          : inputValues.devicePool.map((d) => d.description).join(", ")}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0010" })}</th>
            <td>${typeof inputValues.scheduleType === "string"
          ? inputValues.scheduleType
          : inputValues.scheduleType.map((c) => c).join(", ")}</td>
          </tr>
        </tbody>  
        <div></div> 
      `;

      clone.innerHTML = ` 
                    <thead>  
                        <tr></tr>
                        <tr><th></th></tr>
                        <tr> 
                            <th> ${moment(calInfo.specificDay)
          .day(0)
          .format("YYYY-MM-DD")} ~ ${moment(calInfo.specificDay)
            .day(6)
            .format("YYYY-MM-DD")} </th>
                        </tr> 
                        <tr></tr>
                    </thead>`;

      fileName = `${moment(calInfo.specificDay)
        .day(0)
        .format("YYYY-MM-DD")} ~ ${moment(calInfo.specificDay)
          .day(6)
          .format("YYYY-MM-DD")}.xlsx`;

      clone.append(cloneHead);
      cloneHead.append(cloneBody);
      infoTable.after(clone);

      /* 테이블 2개 합치기 */
      let config = { raw: true, type: 'string', header: 1 };
      let ws1 = XLSX.utils.table_to_sheet(infoTable, config);
      let ws2 = XLSX.utils.table_to_sheet(clone, config);
      let a = XLSX.utils.sheet_to_json(ws1, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      let b = XLSX.utils.sheet_to_json(ws2, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      a = a.concat(['']).concat(b);
      let ws = XLSX.utils.json_to_sheet(a, { skipHeader: true })
      XLSX.utils.book_append_sheet(new_workbook, ws, "Sheet1");

      /* css */
      for (let i in ws) {
        if (typeof (ws[i]) !== "object") continue;
        ws[i].s = {
          font: {
            name: 'Malgun Gothic',
            sz: 10,
          },
          alignment: {
            wrapText: true,
            vertical: "top",
          }
        }
        if (i.includes('15') && i.length === 3) {
          ws[i].s = {
            font: {
              name: 'Malgun Gothic',
              sz: 10,
              bold: true
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            }
          }
        }
        for (let num = 2; num < 10; num++) {
          if (i.includes('B' + num) && i.length === 2) {
            ws[i].s = {
              font: {
                name: 'Malgun Gothic',
                sz: 10,
                bold: true
              },
              alignment: {
                wrapText: true,
                vertical: "center",
                horizontal: "center",
              }
            }
          }
        }
      }

      const merge = { s: { r: 12, c: 0 }, e: { r: 12, c: 7 } };
      const merge2 = { s: { r: 0, c: 0 }, e: { r: 9, c: 0 } };

      /* 월 7칸 병합 */
      if (!ws['!merges']) ws['!merges'] = [];
      ws['!merges'].push(merge);
      ws['!merges'].push(merge2);
      if (!ws['!cols']) ws['!cols'] = [];
      const cellsWidth = Array.from({ length: 8 }, () => ({ width: 30 }));
      ws['!cols'].push(...cellsWidth);
      if (!ws['!rows']) ws['!rows'] = [];
      const cellsRows = Array.from({ length: Object.keys(ws).length }, () => ({ hpt: 'fit-content' }))
      ws['!rows'].push(...cellsRows);

      /* 특정 셀에 적용 */
      ws['A1'].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 10,
          bold: true,
          color: "#FFF"
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: 'FF7B00' },
          fgColor: { rgb: 'FF7B00' }
        },
      }
      ws['A13'].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 12,
          bold: true,
          color: "#FFF"
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: 'FF7B00' },
          fgColor: { rgb: 'FF7B00' }
        },
      };

    } else {
      const infoTable = document.getElementById('table');

      /* 테이블이 변경되지 않도록 */
      const clone = document.importNode(infoTable, true);

      infoTable.innerHTML = `  
        <tbody> 
          <tr>
            <th rowspan="10" style="background-color: #FF7B00; text-align: center; font-weight: bold; color: #FFF;"> 검색결과 <td>
          </tr>
          <tr> 
            <th>${formatMessage({ id: "T0001" })}</th>
            <td>${inputValues.taskId || ""}</td>
          </tr>
          <tr>
            <th>${formatMessage({ id: "T0002" })}</th>
            <td>${inputValues.taskNm || ""}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0003" })}</th>
            <td>${inputValues.taskType.cdNm || ""} - ${inputValues.taskType.cd === "DIY"
          ? "DIY"
          : inputValues.taskType.cd || ""
        }</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0008" })}</th>
            <td>${inputValues.workId.map((c) => c.cdNm).join(", ")}</td>
          </tr>
          <tr> 
            <th>${formatMessage({ id: "T0005" })}</th>
            <td>${inputValues.orgCd.map((c) => c.codeNm).join(", ")}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0006" })}</th>
            <td>${inputValues.mgrEmpNo.map((c) => c.empNam).join(", ")}</td>
            </tr>
            <tr>
            <th>Device Pool</th>
            <td>${inputValues.taskType.cd === "COE"
          ? inputValues.devicePool.map((d) => d.dpNm).join(", ")
          : inputValues.devicePool.map((d) => d.description).join(", ")}</td>
            </tr>
            <tr>
            <th>${formatMessage({ id: "T0010" })}</th>
            <td>${typeof inputValues.scheduleType === "string"
          ? inputValues.scheduleType
          : inputValues.scheduleType.map((c) => c).join(", ")}</td>
          </tr>
        </tbody>  
        <div></div> 
      `;

      clone.innerHTML = ` 
                    <thead>  
                        <tr></tr>
                        <tr><th></th></tr>
                        <tr> 
                            <th>${moment(calInfo.yyyymm + calInfo.dd).format("YYYY-MM-DD")}</th>
                        </tr> 
                        <tr></tr>
                    </thead>`;

      fileName = `${moment(calInfo.yyyymm + calInfo.dd).format("YYYY-MM-DD")}.xlsx`;

      /* 시간 순서대로 정렬해야해 */
      const compareTime = Object.values(scheduleDatas)[0].map(time => {
        return ({
          ...time,
          startTime: time.startTime.replace(":", "")
        })
      }).sort((a, b) => a.startTime - b.startTime);

      const rows = compareTime.map((data) => {
        return ({
          'Start Time': data.startTime.substring(0, 2) + ':' + data.startTime.substring(2),
          'Task Name': `${data.taskNm}(${data.taskId})`
        })
      });

      infoTable.after(clone);

      /* 테이블 2개 합치기 */
      let config = { raw: true, type: 'string', header: 1 };
      let ws1 = XLSX.utils.table_to_sheet(infoTable, config);
      let ws2 = XLSX.utils.table_to_sheet(clone, config);
      let ws3 = XLSX.utils.json_to_sheet(rows);
      let a = XLSX.utils.sheet_to_json(ws1, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      let b = XLSX.utils.sheet_to_json(ws2, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      let c = XLSX.utils.sheet_to_json(ws3, { header: 1, type: 'binary', cellDates: true, bookSST: true, cellStyles: true });
      a = a.concat(['']).concat(b).concat(c);
      let ws = XLSX.utils.json_to_sheet(a, { skipHeader: true });
      XLSX.utils.book_append_sheet(new_workbook, ws, "Sheet1");

      /* css */
      for (let i in ws) {
        if (typeof (ws[i]) !== "object") continue;
        ws[i].s = {
          font: {
            name: 'Malgun Gothic',
            sz: 10,
          },
          alignment: {
            wrapText: true,
            vertical: "top",
          }
        }
        if (i.includes('15') && i.length === 3) {
          ws[i].s = {
            font: {
              name: 'Malgun Gothic',
              sz: 10,
              bold: true
            },
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            }
          }
        }
        for (let num = 2; num < 10; num++) {
          if (i.includes('B' + num) && i.length === 2) {
            ws[i].s = {
              font: {
                name: 'Malgun Gothic',
                sz: 10,
                bold: true
              },
              alignment: {
                wrapText: true,
                vertical: "center",
                horizontal: "center",
              }
            }
          }
        }
      }

      const merge = { s: { r: 12, c: 0 }, e: { r: 12, c: 1 } };
      const merge2 = { s: { r: 0, c: 0 }, e: { r: 9, c: 0 } };

      // /* 월 3칸 병합 */
      if (!ws['!merges']) ws['!merges'] = [];
      ws['!merges'].push(merge);
      ws['!merges'].push(merge2);
      if (!ws['!cols']) ws['!cols'] = [];
      const cellsWidth = Array.from({ length: 3 }, () => ({ width: 30 }));
      ws['!cols'].push(...cellsWidth);
      if (!ws['!rows']) ws['!rows'] = [];
      const cellsRows = Array.from({ length: Object.keys(ws).length }, () => ({ hpt: 'fit-content' }))
      ws['!rows'].push(...cellsRows);

      // /* 특정 셀에 적용 */
      ws['A1'].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 10,
          bold: true,
          color: "#FFF"
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: 'FF7B00' },
          fgColor: { rgb: 'FF7B00' }
        },
      }
      ws['A13'].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 12,
          bold: true,
          color: "#FFF"
        },
        alignment: {
          wrapText: true,
          vertical: "center",
          horizontal: "center",
        },
        fill: {
          patternType: "solid",
          bgColor: { rgb: 'FF7B00' },
          fgColor: { rgb: 'FF7B00' }
        },
      };
    }
    XLSX.writeFile(new_workbook, fileName);
  };

  return (
    <div className="schedule__bottom">
      <div className="schedule__tooltip">
        <Radio.Group
          className="calendar_radio"
          value={calInfo.calendar}
          onChange={onChangeCalendarRadio}
        >
          <RadioButton value="monthly" width="88" fontSize="16">
            Month
          </RadioButton>
          <RadioButton value="weekly" width="66" fontSize="16">
            Week
          </RadioButton>
          <RadioButton value="daily" width="66" fontSize="16">
            Day
          </RadioButton>
        </Radio.Group>
        <div className="calendar_date">
          <div className="calendar_date__wrap">
            <div className="calendar_date__btn_wrap">
              <DoubleLeftOutlined
                className="calendar_date__btn"
                style={{
                  fontSize: 18,
                }}
                onClick={onClickDateButton}
                data-step={calInfo.calendar === "monthly" ? "years" : "months"}
                data-matic="minus"
              />
              <LeftOutlined
                className="calendar_date__btn"
                style={{
                  fontSize: 16,
                  color: "#8f9094",
                }}
                onClick={onClickDateButton}
                data-step={
                  calInfo.calendar === "monthly"
                    ? "months"
                    : calInfo.calendar === "daily"
                      ? "days"
                      : "weeks"
                }
                data-matic="minus"
              />
            </div>
            <Text className="calendar_date__text">
              {calInfo.calendar === "weekly"
                ? `${moment(calInfo.specificDay)
                  .day(0)
                  .format("YYYY-MM-DD")} ~ ${moment(calInfo.specificDay)
                    .day(6)
                    .format("YYYY-MM-DD")}`
                : moment(calInfo.yyyymm + calInfo.dd).format(
                  calInfo.calendar === "monthly" ? "YYYY-MM" : "YYYY-MM-DD"
                )}
            </Text>
            <div className="calendar_date__btn_wrap">
              <RightOutlined
                className="calendar_date__btn"
                style={{
                  fontSize: 16,
                  color: "#8f9094",
                }}
                onClick={onClickDateButton}
                data-step={
                  calInfo.calendar === "monthly"
                    ? "months"
                    : calInfo.calendar === "daily"
                      ? "days"
                      : "weeks"
                }
                data-matic="plus"
              />
              <DoubleRightOutlined
                className="calendar_date__btn"
                style={{
                  fontSize: 18,
                }}
                onClick={onClickDateButton}
                data-step={calInfo.calendar === "monthly" ? "years" : "months"}
                data-matic="plus"
              />
            </div>
          </div>
        </div>
        <div style={{ display: "flex", placeContent: "end" }}>
          <div className="badge_status_info">
            <Badge status="success" text={<FormattedMessage id="T0403" />} />
            {/* 성공 */}
            <Badge status="error" text={<FormattedMessage id="T0404" />} />
            {/* 실패 */}
            <Badge status="processing" text={<FormattedMessage id="T0568" />} />
            {/* 진행중 */}
            <Badge
              status="default"
              text={fmMessage({
                id: "T1293",
                values: { field: formatMessage({ id: "T1294" }) },
              })}
            />
            {/* 이력 없음 */}
          </div>
          <Button
            style={{
              width: 150,
              height: 40,
              marginLeft: 25,
            }}
            onClick={onClickExcelButton}
          >
            <FormattedMessage id="T0041" />
            <table style={{ display: 'none' }} id='table' />
            <div style={{ display: 'none' }} id='tableWrap' />
            {/* 엑셀 다운로드 */}
          </Button>
        </div>
      </div>
      {calInfo.calendar === "monthly" ? (
        <AssignNewScheduleCalendar
          date={calInfo.yyyymm}
          calInfo={calInfo}
          onChangeDate={onChangeDate}
          scheduleDatas={scheduleDatas}
          barColor={barColor}
        />
      ) : calInfo.calendar === "daily" ? (
        <AssignNewScheduleDaily
          date={`${calInfo.yyyymm}${calInfo.dd}`}
          scheduleDatas={scheduleDatas}
          barColor={barColor}
          calInfo={calInfo}
        />
      ) : (
        <AssignNewScheduleWeekly
          date={calInfo.specificDay}
          scheduleDatas={scheduleDatas}
          onChangeDate={onChangeDate}
          barColor={barColor}
          calInfo={calInfo}
        />
      )}
    </div>
  );
};

export default AssignNewScheduleBottom;