import { getUniqueKey } from 'utils/Str';

export const SET_HEADER_NOTILIST = getUniqueKey('SET_HEADER_NOTILIST');
export const SET_HEADER_NOTILIST_WHTH_PERIOD = getUniqueKey('SET_HEADER_NOTILIST_WHTH_PERIOD');
export const SET_SIDE_NOTILIST = getUniqueKey('SET_SIDE_NOTILIST');
export const SET_SIDE_NOTILIST_WHTH_PERIOD = getUniqueKey('SET_SIDE_NOTILIST_WHTH_PERIOD');

export const LOAD_HEADER_NOTILIST = getUniqueKey('LOAD_HEADER_NOTILIST');
export const LOAD_SIDE_NOTILIST = getUniqueKey('LOAD_SIDE_NOTILIST');

export function setHeaderNotiList() {
    return {
        type: SET_HEADER_NOTILIST
    }
}

export function setHeaderNotiListWithPeriod() {
    return {
        type: SET_HEADER_NOTILIST_WHTH_PERIOD
    }
}

export function setSideNotiList() {
    return {
        type: SET_SIDE_NOTILIST
    }
}

export function setSideNotiListWithPeriod() {
    return {
        type: SET_SIDE_NOTILIST_WHTH_PERIOD
    }
}

export function loadHeaderNotiList(headerNoti) {
    return {
        type: LOAD_HEADER_NOTILIST,
        headerNoti
    }
}

export function loadSideNotiList(personalAreaNoti) {
    return {
        type: LOAD_SIDE_NOTILIST,
        personalAreaNoti
    }
}