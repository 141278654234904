import { SET_VISIBLE } from './action';
import { fromJS } from 'immutable';

/**
 * Loading 화면을 위한 State
 * 
 */
const loadingInitialState = fromJS({
    visible: false
});
 
const bread = (state = loadingInitialState, action) => {
    switch(action.type) {
        case SET_VISIBLE:
            return state.set('visible', action.visible);
        default: 
            return state;
    };
};
   
export default bread;
