import { getUniqueKey } from 'utils/Str';

export const SET_ASSIGNSCHEDULE_VALUE = getUniqueKey('SET_ASSIGNSCHEDULE_VALUE');
export const SET_ASSIGNSCHEDULE_SPREAD = getUniqueKey('SET_ASSIGNSCHEDULE_SPREAD');
export const RESET_ASSIGNSCHEDULE_VALUE = getUniqueKey('RESET_ASSIGNSCHEDULE_VALUE');
export const SET_ASSIGNSCHEDULE_FILTERID = getUniqueKey('SET_ASSIGNSCHEDULE_FILTERID');

export function setAssignScheduleValue(key, value) {
    return {
        type: SET_ASSIGNSCHEDULE_VALUE,
        key,
        value
    };
}

export function setAssignScheduleSpread(data) {
    return {
        type: SET_ASSIGNSCHEDULE_SPREAD,
        data
    }
}

export function resetAssignScheduleValue() {
    return {
        type: RESET_ASSIGNSCHEDULE_VALUE
    }
}

export function setAssignScheduleFilterId(filterId) {
    return {
        type: SET_ASSIGNSCHEDULE_FILTERID,
        filterId
    }
}