import React, { useEffect, useState } from 'react';
import { Modal, Text, Input } from 'components';
import { Tree, List } from 'antd';
import styled from 'styled-components';
import { CloseCircleFilled } from '@ant-design/icons';
import fetchAbsolute from 'utils/fetchAbsolute';
import { useIntl } from "react-intl";
/**
 * Filename: BusinessClass.js
 * Description: 업무 분류 선택 공통 컴포넌트
 */

const Wrapper = styled(props => <Modal {...props} />)`
/* 우리카드 */
    /* .ant-table-thead th {
        height: 34px;
        padding: 0px 16px;
        font-size: 12px;
    }
    .ant-table-tbody td {
        font-size: 12px;
    } 
    .ant-input:hover {
        border-color: #0067ac; 
    }
    input.ant-input:focus, ant-input-focused {
      border-color: #0067ac !important;
    }  
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: rgb(0, 103, 172, 0.2);
    }
    .system_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 520px;
    }
    .system_wrap .system__header {
        width: 100%; 
        margin-bottom: 19px;
    }
    .system_wrap .system_content__title {
        font-size: 14px;
        font-weight: bold;
    }
    .system_wrap > .system_box {
        width: 100%
    }
    .system_wrap > .system_box:nth-of-type(1) .system_box__content {
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        border: 1px solid #e8e8e8;
        padding: 15px;
    }
    .system_wrap .system_box .system_box__title {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .system_wrap .system_box .system_box__content {
        height: 507px;
    }
    .system_content__header {
        position: relative;
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
    }
    .system_content__header .input_clear {
        position: absolute;
        top: 50%;
        right: 11px;
        font-size: 12px;
        color: #00000040;
        cursor: pointer;
        transform: translateY(-50%);
    }
    .system_content__tree {
        width: 100%;
        height: 414px;
        border: 1px solid #ccccd4;
        overflow-y: scroll;
        padding: 0 12px;
    }
    .system_content__list {
        cursor: pointer;
    }
    .system_content__select_list {
        background-color: #ffe1bf;
    } */

    /* 기존 */
    .ant-table-thead th {
        height: 34px;
        padding: 0px 16px;
        font-size: 12px;
    }
    .ant-table-tbody td {
        font-size: 12px;
    } 
    .ant-input:hover {
        border-color: #ff7b00; 
    }
    input.ant-input:focus, ant-input-focused {
      border-color: #ff7b00 !important;
    }  
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: #ffe1bf;
    }
    .system_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 520px;
    }
    .system_wrap .system__header {
        width: 100%; 
        margin-bottom: 19px;
    }
    .system_wrap .system_content__title {
        font-size: 14px;
        font-weight: bold;
    }
    .system_wrap > .system_box {
        width: 100%
    }
    .system_wrap > .system_box:nth-of-type(1) .system_box__content {
        display: flex;
        flex-direction: column;
        gap: 8px 0;
        border: 1px solid #e8e8e8;
        padding: 15px;
    }
    .system_wrap .system_box .system_box__title {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .system_wrap .system_box .system_box__content {
        height: 507px;
    }
    .system_content__header {
        position: relative;
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
    }
    .system_content__header .input_clear {
        position: absolute;
        top: 50%;
        right: 11px;
        font-size: 12px;
        color: #00000040;
        cursor: pointer;
        transform: translateY(-50%);
    }
    .system_content__tree {
        width: 100%;
        height: 414px;
        border: 1px solid #ccccd4;
        overflow-y: scroll;
        padding: 0 12px;
    }
    .system_content__list {
        cursor: pointer;
    }
    .system_content__select_list {
        background-color: #ffe1bf;
    }
`;

const BusinessClass = ({ visible, title, onOk = () => { }, onCancel = () => { }, defaultData = [], max = -1, mode = '' }) => {
    const { formatMessage } = useIntl();

    const [allClassList, setAllClassList] = useState([]);
    const [treeList, setTreeList] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [expand, setExpand] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMultiple, setIsMultiple] = useState(false);

    const getParnet = (pworkId) => {
        const a = allClassList.find(v => v.cd === pworkId);
        return a !== undefined ? a.cdNm + ',' + getParnet(a.upCd) : '';
    }

    const onChangeSearchKeyword = (e) => {
        const { value } = e.target;
        setSearchKeyword(value);
    }

    const onClickOk = () => {
        if (selectedKeys.length === 0) {
            return onOk([]);
        }
        if (mode) {
            let tmpArr = [];
            for (let i in selectedKeys) {
                const result = allClassList.filter(data => data.cd === selectedKeys[i]);
                tmpArr.push(...result)
            }
            onOk(tmpArr.length !== 0 ? tmpArr : []);
        } else {
            const selectObj = allClassList.find(v => v.cd === selectedKeys[0]);

            if (allClassList.findIndex(v => v.upCd === selectObj.cd) !== -1) {
                return alert(formatMessage({ id: 'T0116' })); // 최하위 레벨의 업무만 선택할 수 있습니다.
            }
            onOk([selectObj]);
        }
    }

    const onSelectTree = (key) => {
        setSelectedKeys(key);
    }

    const onSelectList = (cd) => {
        if (mode) {
            let arr = [...selectedKeys].concat(cd);
            /* 2번 같은 걸 눌렀다는 건 선택 해제 */
            let count = 0;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === cd[0]) {
                    count++;
                }
            }
            if (count === 2) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === cd[0]) {
                        arr.splice(i, 1);
                        i--;
                    }
                }
            }
            setSelectedKeys([...arr]);
        } else {
            if (selectedKeys.length > 0 && selectedKeys[0] === cd[0]) {
                setSelectedKeys([]);
            } else {
                setSelectedKeys(cd);
            }
        }
    }

    const onKeyPressKeyword = (e) => {
        const filterList = allClassList.filter(cls => cls.upCd !== "0000" && cls.cdNm?.toLowerCase().includes(searchKeyword.toLowerCase()));
        setIsSearch(true);
        setSearchList([...filterList]);
    }

    const onClickSearchReset = () => {
        setSearchKeyword('');
        setIsSearch(false);
        setSelectedKeys([]);
        setSearchList([]);
    }

    const getWorkList = async () => {
        if (!visible) return;
        setLoading(true);
        const result = await fetchAbsolute('get', `/common/workClassificationList`);
        setLoading(false);
        setAllClassList(result?.data);
    }

    useEffect(() => {
        const tmpDatas = [];
        let lastArray;
        // 레벨에 맞는 배열 가져오기 위한 재귀함수
        const getClassArray = (array, level, count = 1) => +level === count ? array : getClassArray(array[array.length - 1].children, level, count + 1);
        allClassList.forEach((value) => {
            lastArray = getClassArray(tmpDatas, value.level);
            const data = {
                title: value.cdNm,
                key: value.cd,
                level: value.level,
                children: []
            };
            if (!lastArray.children) lastArray.push(data)
            else lastArray.children.push(data);
        });
        setTreeList(tmpDatas);
    }, [allClassList]);

    useEffect(() => {
        if (!visible) return;
        setSearchKeyword('');
        setIsSearch(false);
        getWorkList();
        // 초기화 눌렀을 때 자료 초기화되게
        if (defaultData.length < 1) {
            setSelectedKeys([]);
            setSearchList([]);
        } else {
            const keys = defaultData.map(c => c.cd)
            setSelectedKeys([...keys]);
            // tree가 안열려서 자식일 때 부모가 열려 있어야 한다.
            const parent = defaultData.filter(c => c.level !== 1).map(c => c.upCd);
            setExpand(parent);
        }
    }, [visible, defaultData]);

    /* 과제 목록 복수 검색  */
    useEffect(() => {
        if (!mode) return;
        setIsMultiple(true);
    }, [mode]);

    return (
        <Wrapper visible={visible} title={title} width={600} onOk={onClickOk} onCancel={onCancel}>
            <div className='system_wrap'>
                <div className='system_box'>
                    <div className='system_box__content'>
                        <div className='system_content__header'>
                            <Input
                                placeholder={formatMessage({ id: 'T0339' })}//업무 분류로 검색하세요
                                style={{ height: 32 }}
                                onChange={onChangeSearchKeyword}
                                value={searchKeyword}
                                onPressEnter={onKeyPressKeyword}
                            />
                            <CloseCircleFilled
                                className='input_clear'
                                onClick={onClickSearchReset}
                            />
                        </div>
                        <Text className='system_content__title'>{formatMessage({ id: 'T0340' })}</Text>{/* 업무분류 */}
                        <div className='system_content__tree'>
                            {
                                isSearch ?
                                    <List
                                        dataSource={searchList}
                                        renderItem={v => { /* 선택된 아이템 css 수정 */
                                            let matchCondition = ''
                                            const checkCode = selectedKeys.find(key => key === v.cd);
                                            if (checkCode) {
                                                matchCondition = 'system_content__select_list'
                                            };
                                            return (
                                                <List.Item
                                                    className={'system_content__list ' + matchCondition}
                                                    onClick={() => onSelectList([v.cd])}
                                                    key={v.cd}
                                                >
                                                    {v.cdNm}
                                                </List.Item>
                                            )
                                        }}
                                    />
                                    :
                                    <Tree
                                        defaultExpandedKeys={expand}
                                        expandedKeys={expand}
                                        // autoExpandParent={true}
                                        selectedKeys={selectedKeys}
                                        onSelect={onSelectTree}
                                        onExpand={(e) => setExpand(e)}
                                        multiple={isMultiple}
                                        treeData={treeList}
                                    />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}
export default BusinessClass;
