import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import moment from "moment";
import { Select } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import pdfDownload from "utils/pdfDownload";
import { PageTemplate, Header, Footer, Text, TypePicker, Button } from "components";
import Wrapper from "./Styled";
import DIYLicenseStatus from "./DIYLicenseStatus";
import LicenseMaxUsage from "./LicenseMaxUsage";
import LoginCount from "./LoginCount";
import OrgSatisfaction from "./OrgSatisfaction ";
import DailyRunnerUsage from "./DailyRunnerUsage";
import DailyRunnerPerformance from "./DailyRunnerPerformance";
import OperSuccessRate from "./OperSuccessRate";
import TaskSuccessRate from "./TaskSuccessRate";

const { Option } = Select;

/**
 * 대시보드 > 운영 Insight
 *
 */

const DashboardInsight = () => {
  const { formatMessage } = useIntl();
  const [taskTypeList, setTaskTypeList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [standardDate, setStandardDate] = useState({
    regFr: moment().subtract(1, "months"),
    regTo: moment(),
  });
  const [runnerUsage, setRunnerUsage] = useState([]);
  const [dailySuccess, setDailySuccess] = useState([]);
  const [operSuccess, setOperSuccess] = useState([]);
  const [dailyOperSuccess, setDailyOperSuccess] = useState([]);
  const [conditions, setConditions] = useState({
    taskType: [],
    org: [],
  });

  const onChangeFrDate = (date) => {
    setStandardDate({
      ...standardDate,
      regFr: date,
    });
  };

  const onChangeToDate = (date) => {
    setStandardDate({
      ...standardDate,
      regTo: date,
    });
  };

  /* 과제 유형 */
  const onChangeType = (e) => {
    setConditions({
      ...conditions,
      taskType: [...e],
    });
  };

  /* 조직별 */
  const onChangeOrg = (e) => {
    /* 배열 */
    setConditions({
      ...conditions,
      org: [...e],
    });
  };

  /* 하단 부분 4곳 정보 세팅 */
  useEffect(() => {
    // if (conditions.taskType.length === 0) return;
    /* 일별 Runner 사용률 */
    const fetchRunnerUsage = async () => {
      const result = await fetchAbsolute(
        "post",
        `/dashboard/OperInsight/DailyRunnerUtilizationList`,
        {
          data: {
            runDateFrom: standardDate.regFr.format("YYYY-MM-DD"),
            runDateTo: standardDate.regTo.format("YYYY-MM-DD"),
            orgCd: conditions.org,
            taskType: conditions.taskType,
          },
        }
      );
      const tmpArr = [];
      const { data } = result;
      for (let i in data) {
        tmpArr.push({
          date: data[i].date,
          key: data[i].date,
          runnerNm: data[i].runnerNm,
          runnerUsage: +data[i].runnerUsage,
        });
      }
      setRunnerUsage([...tmpArr]);
    };
    /* 일별 Runner 수행 과제수 */
    const fetchRunnerOper = async () => {
      const result = await fetchAbsolute(
        "post",
        `/dashboard/OperInsight/NumOfRunnerTasksPerDayList`,
        {
          data: {
            fromDate: standardDate.regFr.format("YYYY-MM-DD"),
            toDate: standardDate.regTo.format("YYYY-MM-DD"),
            companyCd: conditions.org,
            taskType: conditions.taskType,
          },
        }
      );
      const tmpArr = [];
      const { data } = result;
      for (let i in data) {
        tmpArr.push({
          rundate: data[i].rundate,
          key: data[i].rundate,
          devicename: data[i].devicename,
          finishedTaskCnt: +data[i].finishedTaskCnt,
        });
      }
      setDailySuccess([...tmpArr]);
    };
    /* 운영 과제 성공률 */
    const fetchTaskSuccess = async () => {
      const result = await fetchAbsolute(
        "post",
        `/dashboard/OperInsight/OperTaskSucRateList`,
        {
          data: {
            fromDate: standardDate.regFr.format("YYYY-MM-DD"),
            toDate: standardDate.regTo.format("YYYY-MM-DD"),
            companyCd: conditions.org,
            taskType: conditions.taskType,
          },
        }
      );
      const tmpArr = [];
      const { data } = result;
      for (let i in data) {
        tmpArr.push({
          key: data[i].companyCd,
          companyNm: data[i].companyNm,
          taskNm: data[i].taskNm,
          x: +data[i].totalCnt,
          y: +data[i].totalSec,
          size: +data[i].success,
          processId: data[i].processId,
        });
      }
      setOperSuccess([...tmpArr]);
    };
    /* 과제별 운영 성공률 */
    const fetchOperSuccess = async () => {
      const result = await fetchAbsolute(
        "post",
        `/dashboard/OperInsight/OperSucRateByTaskList`,
        {
          data: {
            runDateFrom: standardDate.regFr.format("YYYY-MM-DD"),
            runDateTo: standardDate.regTo.format("YYYY-MM-DD"),
            orgCd: conditions.org,
            taskType: conditions.taskType,
          },
        }
      );
      const tmpArr = [];
      const { data } = result;
      for (let i in data) {
        tmpArr.push(
          {
            value: +data[i].performCnt,
            key: data[i].taskId,
            taskId: data[i].taskId,
            taskNm: data[i].taskNm,
            rate: +data[i].executionRate,
            type: formatMessage({ id: "T1552" }), //'수행횟수'
          },
          {
            value: +data[i].completePerformCnt,
            key: data[i].taskId,
            taskId: data[i].taskId,
            taskNm: data[i].taskNm,
            rate: +data[i].executionRate,
            type: "Complete" + formatMessage({ id: "T1552" }), // 'Complete수행횟수'
          }
        );
      }
      setDailyOperSuccess([...tmpArr]);
    };
    fetchTaskSuccess();
    fetchOperSuccess();
    fetchRunnerUsage();
    fetchRunnerOper();
  }, [standardDate, conditions]);

  useEffect(() => {
    if (taskTypeList.length === 0) return;
    const tmpArr = [];
    for (let i in taskTypeList) {
      tmpArr.push(taskTypeList[i].cd)
    }
    setConditions({
      ...conditions,
      taskType: [...tmpArr]// default
    });
  }, [taskTypeList]);

  useEffect(() => {
    // taskType 가져오는 함수
    const fetchTaskType = async () => {
      try {
        const result = await fetchAbsolute(
          "get",
          "/common/commonCd/getCategoryId/OPER_TYPE"
        );
        setTaskTypeList([...result.data?.filter((v) => v.useYn === "Y")]);
      } catch (err) {
        return alert(err);
      }
    };
    /* 조직별 리스트 가져오기 */
    const getCompanyList = async () => {
      const result = await fetchAbsolute('get', `/admin/commonMaster/getKpiStandardCompanyList`);
      setCompanyList([...result.data]);
    };
    fetchTaskType();
    getCompanyList();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper>
        <div className="time_saving_container">
          <div className="standard_conditions">
            <Text className="">from</Text>
            <TypePicker
              format="YYYY-MM-DD"
              value={standardDate.regFr}
              width="150"
              height="32"
              data-name="regFr"
              onChange={onChangeFrDate}
              allowClear={false}
            />
            <Text className="">to</Text>
            <TypePicker
              format="YYYY-MM-DD"
              value={standardDate.regTo}
              width="150"
              height="32"
              data-name="regTo"
              onChange={onChangeToDate}
              allowClear={false}
            />            <div>
              <Button onClick={() => pdfDownload('Dashboard_Insight.pdf')} >{formatMessage({ id: 'T1317' })} </Button>{/* PDF 다운로드 */}
            </div>

          </div>
          <div className="first__section">
            <div className="diy_licnse_status">
              <DIYLicenseStatus />
            </div>
            <div className="license_max_usage">
              <LicenseMaxUsage
                regDateFrom={standardDate.regFr.format("YYYY-MM-DD")}
                regDateTo={standardDate.regTo.format("YYYY-MM-DD")}
              />
            </div>
          </div>
          <div className="second__section">
            <div className="login_count">
              {/* 로그인 카운트 (기간 중 로그인 비율) */}
              <LoginCount
                regDateFrom={standardDate.regFr.format("YYYY-MM-DD")}
                regDateTo={standardDate.regTo.format("YYYY-MM-DD")}
              />
            </div>
            <div className="org_satisfaction">
              {/* 조직별 만족도 */}
              {/* 얘는 날짜 형식이 YYYYMMDD */}
              <OrgSatisfaction
                rechDateFrom={standardDate.regFr.format("YYYYMMDD")}
                rechDateTo={standardDate.regTo.format("YYYYMMDD")}
                companyList={companyList}
              />
            </div>
          </div>
          <div className="standard_conditions">
            <div>{formatMessage({ id: "T1516" })}</div>
            {/* 조직별 */}
            <div>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                style={{
                  width: 220,
                }}
                onChange={onChangeOrg}
                value={conditions.org}
                placeholder={formatMessage({ id: "T0391" })} // 전체
              >
                {companyList.map((list) => {
                  return (
                    <Option key={list.companyCd} value={list.companyCd}>
                      {list.companyNm}
                    </Option>
                  );
                })}
              </Select>
            </div>
            <div>{formatMessage({ id: "T0740" })}</div>
            {/* 과제유형 */}
            <div>
              <Select
                style={{
                  minWidth: 130,
                  width: "fit-content",
                }}
                name="taskType"
                mode="multiple"
                value={conditions.taskType}
                onChange={onChangeType}
                filterOption={taskTypeList
                  .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                  .map((item) => item.cd)}
              >
                {taskTypeList.map((type) => {
                  return (
                    <Option key={type.cd} value={type.cd}>
                      {type.cd}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>
          <div className="third__section">
            <div className="daily_runner_usage">
              <DailyRunnerUsage runnerUsage={runnerUsage} />
            </div>
            <div className="daily_runner_performance">
              <DailyRunnerPerformance dailySuccess={dailySuccess} />
            </div>
          </div>
          <div className="fourth__section">
            <div className="oper_success_rate">
              <OperSuccessRate operSuccess={operSuccess} />
            </div>
            <div className="task_success_rate">
              <TaskSuccessRate dailyOperSuccess={dailyOperSuccess} />
            </div>
          </div>
        </div>
      </Wrapper>
    </PageTemplate>
  );
};

export default DashboardInsight;
