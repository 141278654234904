import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  PageTemplate, Header, Footer, Input, Button, Table, Radio, Text, RangePicker, Collapse, FormTable,
} from 'components';
import { PopupProgressIcon, PopupWaitIcon, PopupStopIcon, CloseCollapseIcon, OpenCollapseIcon } from 'icons';
import { Select, Collapse as AntCollapse, Row, Col } from "antd";
import Wrapper from './Styled';
import fetchAbsolute from "utils/fetchAbsolute";
import moment from 'moment';
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;
const { Panel } = AntCollapse;

const initialPageInfo = {
  sort: "noticeDtFr",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

/**
 * Filename: AdminPopup/index.js
 * Description: Admin -> 공지 팝업 관리 컴포넌트
 */
const initialInputValues = {
  noticeSeq: "",
  noticeTitle: "",
  noticeDtFr: "",
  noticeDtTo: "",
};

const AdminPopup = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [showYn, setShowYn] = useState("Y");
  const [dataSource, setDataSource] = useState([]);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const pageSize = [20, 50, 70];
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const selectRowPerPage = formatMessage({ id: "T0036" }); // 페이지당 행
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [sortedInfo, setSortedInfo] = useState({});
  // const onSelectOption = (e) => {
  //   setPageInfo({ ...pageInfo, pageSize: +e });
  // };

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const onChangeTableData = (pagination, filters, sorter) => {
    const order = sorter.field ? sorter.field : 'noticeDtFr';
    let orderBy = "";
    if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (!sorter.order || sorter.order?.includes("asc")) orderBy = ",asc";

    const searchObj = {
      ...pageInfo,
      order: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    getDataSource(inputValues, searchObj);
  };

  /* 페이지당 행 */
  const onChangePageSize = (e) => {
    getDataSource(inputValues, { ...pageInfo, rowPerPage: +e, currentPage: 1 });
  };

  // const replaceSearch = async (searchValues, searchPageInfo) => {
  //   const tmpObj = { ...searchPageInfo, ...searchValues };

  //   // Object.keys(tmpObj).forEach((item) => {
  //   //   const data = tmpObj[item];

  //   //   if (item === "noticeDtFr") {
  //   //     const noticeSeq = data.noticeSeq;
  //   //     tmpObj[item] = noticeSeq;
  //   //   }

  //   // }); 

  //   const searchString = queryString.stringify(tmpObj);
  //   getDataSource(tmpObj, { ...pageInfo, ...searchPageInfo });
  //   history.push({ search: searchString });
  // };

  const columns = [
    {
      title: formatMessage({ id: "T1202" }), // Notice Title
      dataIndex: "noticeTitle",
      key: "noticeTitle",
      width: "30%",
      //sorter: (a, b) => a.noticeTitle.localeCompare(b.noticeTitle),
      render: (title, record) => {
        return (
          <Link
            to={`/admin/popup/modify/${record.noticeSeq}`}
            className="popup_title"
          >
            <Text>{title}</Text>
          </Link>
        );
      },
      onFilter: (record) => record.noticeTitle.includes("test")
    },
    {
      title: formatMessage({ id: "T1111" }), // Status
      render: (record) => {
        const dateFr = +moment(record.noticeDateFr).format("YYYYMMDDHHmm");
        const dateTo = +moment(record.noticeDateTo).format("YYYYMMDDHHmm");
        const now = +moment().format("YYYYMMDDHHmm");
        if (record.showYn === "Y" && now < dateFr) {
          return (
            <div className="popup_status popup_status__waiting">
              <PopupWaitIcon />
              <Text>{formatMessage({ id: "T0642" })}</Text>{/* 대기 */}
            </div>
          );
        } else if (record.showYn === "N" || now < dateFr || now > dateTo) {
          return (
            <div className="popup_status popup_status__stop">
              <PopupStopIcon />
              <Text>{formatMessage({ id: "T0643" })}</Text>{/* 중지 */}
            </div>
          );
        } else {
          return (
            <div className="popup_status popup_status__progress">
              <PopupProgressIcon />
              <Text>{formatMessage({ id: "T0478" })}</Text>{/* 진행 */}
            </div>
          );
        }
      },
    },
    {
      title: formatMessage({ id: "T0644" }), // 팝업 기간
      render: (record) => {
        return `${moment(record.noticeDateFr).format("YYYY-MM-DD HH:mm")} 
          ~ ${moment(record.noticeDateTo).format("YYYY-MM-DD HH:mm")}`;
      },
      sorter: (a, b) => a.noticeDateFr.localeCompare(b.noticeDateFr),
    },
    {
      title: formatMessage({ id: "T0394" }), // 등록일
      dataIndex: "regTimestamp",
      key: "regTimestamp",
      render: (date) => {
        return moment(date).format("YYYY-MM-DD HH:mm");
      },
    },
    {
      title: formatMessage({ id: "T0645" }), // 진행 여부
      dataIndex: "showYn",
      key: "showYn",
      render: (value, record, index) => {
        return (
          <Radio
            defaultValue={value}
            value={value}
            onChange={(e) => onChangeShowYn(e, record.noticeSeq, index)}
            renderItem={[
              {
                value: "Y",
                text: "On",
              },
              {
                value: "N",
                text: "Off",
              },
            ]}
          />
        );
      },
    },
  ];

  const onChangeStatus = async (e) => {
    let statusList = e
    if (typeof e === "object") {
      statusList = e.target.value;
    } else {
      statusList = e;
      setInputValues({ ...inputValues, status: statusList })
    }
  }
  const onChangeShowYn = async (e, noticeSeq, index) => {
    let showYnList = e
    if (typeof e === "object") {
      showYnList = e.target.value;
    } else {
      showYnList = e;
      setInputValues({ ...inputValues, showYn: showYnList })
    }
    const result = await fetchAbsolute('post', `/admin/notice/noticeShowYnSave?noticeSeq=${noticeSeq}&showYn=${showYnList}`);
    // setShowYnList([...result.data?.filter((v) => v.showYn === "Y")])
    if (!result.error) {
      const tmpArr = [...dataSource];
      tmpArr[index].showYn = showYnList;
      setDataSource([...tmpArr]);
    }
    setShowYn(showYnList);
  }
  const getDataSource = async (searchValues, page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    if (searchValues === undefined) {
      searchValues = { ...inputValues };
    }
    const result = await fetchAbsolute(
      "get",
      `/admin/notice/noticeAllList?noticeTitle=${searchValues.noticeTitle}&status=${searchValues.status}&showYn=${searchValues.showYn}&noticeDtFr=${searchValues.noticeDtFr}&noticeDtTo=${searchValues.noticeDtTo}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setPageInfo({ ...page, total: +result.data.totalItemsCount });
    setDataSource([...result.data.noticeList]);
  };

  const onClickSearch = async () => {
    getDataSource(inputValues, initialPageInfo);
  };

  const onClickReset = () => {
    setInputValues({ ...initialInputValues });
    getDataSource({ ...initialInputValues });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };

  const onChangeTitle = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    })
  };

  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDataRange(range);
  };

  const onChangeDataRange = ([noticeDtFr, noticeDtTo]) => {
    setInputValues({ ...inputValues, noticeDtFr, noticeDtTo });
  };

  useEffect(() => {
    getDataSource();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      <Wrapper style={{ padding: "30px 0" }}>
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <FormTable pointBorder={false} size="sm">
              <colgroup>
                <col width="10.375%" />
                <col width="13.625%" />
                <col width="10.375%" />
                <col width="13.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ borderRadius: "14px 0 0 0" }}>
                    <Text>{formatMessage({ id: "T0623" })}</Text>{/* 상태 */}
                  </th>
                  <td>
                    <Select
                      placeholder={formatMessage({ id: "T0623" })} // 상태
                      name='status'
                      style={{ width: 300 }}
                      onChange={onChangeStatus}
                      value={inputValues.status || undefined}
                    // defaultValue={"P"}
                    >
                      <Option value="P">{formatMessage({ id: "T0478" })}</Option>{/* 진행 */}
                      <Option value="W">{formatMessage({ id: "T0642" })}</Option>{/* 대기 */}
                      <Option value="S">{formatMessage({ id: "T0643" })}</Option>{/* 중지 */}
                    </Select>
                  </td>
                  <th><Text>{formatMessage({ id: "T0017" })}</Text></th>{/* 제목 */}
                  <td>
                    <Input
                      placeholder={formatMessage({ id: "T0017" })} // 제목
                      name='noticeTitle'
                      onKeyPress={onKeyPress}
                      value={inputValues.noticeTitle}
                      onChange={onChangeTitle}
                      style={{ width: 300 }}
                    />
                  </td>
                </tr>
                <tr>
                  <th><Text>{formatMessage({ id: "T0824" })}</Text></th>{/* 기간 */}
                  <td>
                    <RangePicker
                      style={{ width: 300 }}
                      onChangeDate={onChangeDates}
                      onCalendarChange={onChangeDates}
                      value={[
                        inputValues.noticeDtFr && inputValues.noticeDtFr.length > 0
                          ? moment(inputValues.noticeDtFr)
                          : undefined,
                        inputValues.noticeDtTo && inputValues.noticeDtTo.length > 0
                          ? moment(inputValues.noticeDtTo)
                          : undefined,
                      ]}
                      defaultValue={[undefined, undefined]}
                      allowClear={true}
                    />
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <Row style={{ padding: "0 13px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0 25px",
                  padding: "10px 0",
                }}
              >
                <Button width="120" height="40" onClick={onClickReset}>
                  <FormattedMessage id="T0037" />{/* 초기화 */}
                </Button>
                <Button
                  width="120"
                  height="40"
                  type="primary"
                  onClick={onClickSearch}
                >
                  <FormattedMessage id="T0039" />{/* 검색 */}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div className="popup_manage__top">
          <div style={{ alignSelf: "center" }}>
            <span>
              {selectRowPerPage} &emsp;
              <Select
                style={{
                  width: 89,
                  height: 32,
                }}
                name="pagination"
                onSelect={onChangePageSize}
                value={pageInfo.rowPerPage}
              >
                {pageSize.map((v, index) => (
                  <Option key={index} value={v}>
                    {fmMessage({ id: "T1228", values: { number: v } })}
                  </Option>
                ))}
              </Select>
            </span>
          </div>
          <Link to="/admin/popup/create">
            <Button type="primary" width="150" height="32" margin="5">
              +<FormattedMessage id="T0108" />{/* 팝업 생성 */}
            </Button>
          </Link>
        </div>
        <div className="popup_manage__bottom">
          <Table
            pagination={true}
            onChange={onChangeTableData}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.rowPerPage,
            }}
            loading={tableLoading}
            rowKey={(item) => item.noticeSeq}
            columns={columns}
            dataSource={dataSource}
          />
        </div>
      </Wrapper>
    </PageTemplate>
  );
}

export default AdminPopup;
