import styled from 'styled-components';
import Modal from '../Modal';

const Wrapper = styled(Modal)` 
    .orga_wrapper {
        width: 550px;
        margin: 0 auto;
    }
    .orga_section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 12px;
        border: 1px solid #d2d3d7;
    }
    .orga_box {
        width: 100%;
        border: 1px solid #ccccd4;
        height: 400px;
    }
    .orga_search__wrap {
        position: relative;
        width: 100%;
    }
    .orga_search__wrap .orga_search {
        width: 100%;
    }
    .orga_search__wrap .input_clear {
        position: absolute;
        top: 50%;
        right: 11px;
        font-size: 12px;
        color: #00000040;
        cursor: pointer;
        transform: translateY(-50%);
    }
    .orga_box .orga_content {
        padding: 0 12px;
        overflow-y: scroll;
        height: calc(100% - 40px);
    }
    .orga_content .orga_content__list {
        cursor: pointer;
    }
    .orga_content .orga_content__select_list {
        background-color: #ffe1bf;
    } 
    .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: #ffe1bf;
    } 
    /* .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
        background-color: rgb(0, 103, 172, 0.2);
    }  */
`

export default Wrapper;