import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { PageTemplate, Header, Footer, Tabs, DevicePool } from 'components';
import fetchAbsolute from 'utils/fetchAbsolute';
import Wrapper from './Styled';
import { getUniqueKey } from 'utils/Str';
import RealtimeActive from './ReatimeTabs/Active';
import RealtimeInActive from './ReatimeTabs/InActive';
import RealtimeDisconnected from './ReatimeTabs/Disconnected';

const { TabPane } = Tabs;

/**
 * Filename: MonitoringRealtime/index.js
 * Description: 모니터링 -> 운영 Bot 실시간 모니터링 컴포넌트
 */

// filter로 데이터를 세팅한 거라, 정렬기능이 안먹힘
const initialTableInfo = {
    sort: "hostName",
    direction: ",asc",
    rowPerPage: 20,
    currentPage: 1,
    total: 0,
}

const MonitoringRealtime = () => {
    //전체 crInfo - 객체배열 형식
    const crInfoRedux = useSelector(state => state.get(getUniqueKey('crInfo')));

    const dataInterval = useRef(null);
    const licentList = useRef([]);

    // 가공된(사용할) crInfo
    const [crInfo, setCrInfo] = useState([]);
    // deviceList, lisenceList api 결과값을 가공해서 최종 세팅될 deviceList
    const [deviceList, setDeviceList] = useState([]);
    // 선택된 cr
    const [selectCr, setSelectCr] = useState({});
    const [devicePool, setDevicePool] = useState([]);
    // const [diyDeviceList, setDiyDeviceList] = useState([]);
    const [devicePoolVisible, setDevicePoolVisible] = useState(false);
    const [activeData, setActiveData] = useState({
        total: 0,
        data: []
    });
    const [inActiveData, setInActiveData] = useState({
        total: 0,
        data: []
    });
    const [disconnectedData, setDisconnectedData] = useState({
        total: 0,
        data: []
    });

    const [tableInfo, setTableInfo] = useState({ ...initialTableInfo });

    // InActive, Disconneted Table 변화에 사용하는 함수들
    const onSelectPageSize = (rowPerPage) => {
        setTableInfo({ ...tableInfo, rowPerPage: +rowPerPage });
    }

    const onChangeCrSelect = (crCd) => {
        const cr = crInfo.find(cr => cr.crCd === crCd) || {};
        setSelectCr({ ...cr });

        setDevicePool([]);
    }

    const onClickDevicePoolButton = () => {
        setDevicePoolVisible(true);
    }

    const onOkDevicePool = (data) => {
        setDevicePool([...data]);
        setDevicePoolVisible(false);
    }

    const onCancelDevicePool = () => {
        setDevicePoolVisible(false);
    }

    /* 검색 버튼 기능 */
    const onClickSearchButton = async () => {
        licentList.current = [];
        fetchData(selectCr);
        clearInterval(dataInterval.current);
        dataInterval.current = setInterval(() => fetchData(selectCr), 3000);
    }

    /* 초기화 버튼 */
    const onClickResetButton = () => {
        setDevicePool([]);
        setSelectCr({ ...crInfo[0] })
        clearInterval(dataInterval.current);
        dataInterval.current = setInterval(() => fetchData({ ...crInfo[0] },"reset"), 3000);
    }

    // const onChangeDiyDevice = (pool) => {
    //     let tmpArr = [];
    //     let tmpDevicePoolId = [];
    //     for (let i in pool) {
    //         const diyDevicePool = diyDeviceList.find((v) => v.devicePoolId === pool[i]);
    //         tmpArr.push(diyDevicePool)
    //         tmpDevicePoolId.push(diyDevicePool.devicePoolId);
    //     };
    //     setDevicePool([...tmpArr]);
    // }

    // api 호출 후 데이터 가공해서 세팅
    const processDeviceLicenseList = async (selectCrInfo) => {
        /* Device Pool 선택 초기화 기능 때문에 */
        let tmpDevicePool = [...devicePool];

        // deviceList 가져오기
        const { data: deviceData } = await fetchAbsolute('post', `/admin/devicePool/getDeviceList?crCode=${selectCrInfo.crCd || ''}`);
        if (licentList.current.length === 0) {
            const { data: licenseData } = await fetchAbsolute('get', `/admin/license/licenseList?crCd=${selectCrInfo.crCd}&userType=Bot runner&userStatus=ALL`);
            licentList.current = [...licenseData];
        }

        if (!deviceData) return;
        const tmpDeviceList = [];

        // Device List와 license List를 비교
        if (tmpDevicePool?.length > 0) {
            for (let i in tmpDevicePool) {
                deviceData.filter(v => v.poolName === tmpDevicePool[i].dpNm).forEach((device) => {
                    //device pool data에서 defaultuser 모두 걸러냄
                    const defaultUserList = device.defaultUsers.map(v => +v.id);
                    const resultArr = defaultUserList.map(v => {
                        const index = licentList.current.findIndex(l => +l.id === +v);
                        //찾는 값이 없을 때
                        if (!~index) {
                            return false;
                        }
                        return licentList.current[index].licenseFeatures?.includes('RUNTIME');
                    });

                    if (resultArr.includes(true)) {
                        tmpDeviceList.push({ ...device });
                    };
                })
            }
        } else {
            deviceData.forEach((device) => {
                //device pool data에서 defaultuser 모두 걸러냄 
                const defaultUserList = device.defaultUsers.map(v => +v.id);
                const resultArr = defaultUserList.map(v => {
                    const index = licentList.current.findIndex(l => +l.id === +v);
                    //찾는 값이 없을 때
                    if (!~index) {
                        return false;
                    }
                    return licentList.current[index].licenseFeatures?.includes('RUNTIME');
                });

                if (resultArr.includes(true)) {
                    tmpDeviceList.push({ ...device });
                };
            })
        }
        setDeviceList([...tmpDeviceList]);        
    }

    const fetchData = async (selectCrInfo, type) => {
        // /* Device pool 데이터 가공 */
        let tmpDevicePoolIdArr = [];
        if(type) {
            tmpDevicePoolIdArr = [];
        } else if(devicePool.length>0) {
            for (let i in devicePool) {
                tmpDevicePoolIdArr.push(devicePool[i].dpId);
            }
        } else {
            tmpDevicePoolIdArr = [];
        }
        // runnerAgent list 가져오기 
        const { data: { runnerAgentList } } = await fetchAbsolute('post', `/log/botLog/getRunnerAgentListWithPagingMulVal?page=${tableInfo.currentPage - 1}&size=${tableInfo.rowPerPage}&sort=${tableInfo.sort}${tableInfo.direction}`, {
            data: {
                devicePoolId: tmpDevicePoolIdArr.flat(),
                crType: selectCrInfo.crXEnv || '',
                crUrl: selectCrInfo.crUri || '',
                crCode: selectCrInfo.crCd
            }
        });

        if (!runnerAgentList) return;

        const activeResult = [];
        const inActiveResult = [];
        const disconnResult = [];

        // active, inactive, diconnected 데이터 구분
        deviceList.forEach((device) => {
            const mappingData = runnerAgentList.find(v => v.hostName === device.hostName) || {};
            if (device.status === 'DISCONNECTED' || !mappingData.runnerAgentStatus) {
                // disonnected 데이터 세팅
                disconnResult.push({ ...device, ...mappingData });
            } else if (device.status === 'CONNECTED') {
                if (mappingData.runnerAgentStatus !== "INACTIVE") {
                    activeResult.push({ ...device, ...mappingData, imageTimeStamp: new Date().getTime() });
                } else if (mappingData.runnerAgentStatus === "INACTIVE") {
                    inActiveResult.push({ ...device, ...mappingData });
                }
            } else {
                disconnResult.push({ ...device, ...mappingData });
            }
        });
        setActiveData({
            total: activeResult.length,
            data: [...activeResult]
        });
        setInActiveData({
            total: inActiveResult.length,
            data: [...inActiveResult]
        });
        setDisconnectedData({
            total: disconnResult.length,
            data: [...disconnResult]
        });
    };

    const onChangeTabs = (key) => {
        // 탭 변경할 때 마다 테이블 페이징,정렬 초기화
        setTableInfo({ ...initialTableInfo });
    }

    useEffect(() => {
        if (crInfo.length === 0)  return;
            // processDeviceLicenseList({ ...crInfo[0] });
            fetchData({ ...crInfo[0] });
            setSelectCr({ ...crInfo[0] });

            clearInterval(dataInterval.current);
            dataInterval.current = setInterval(() => fetchData({...crInfo[0]}), 3000);
    }, [deviceList, crInfo, tableInfo]);

    useEffect(() => {
        if (crInfoRedux.length === 0) return;
            setCrInfo([...crInfoRedux.filter(v => v.crEnvGubun === 'PRD')])
            let tmpCrInfo = [...crInfoRedux.filter(v => v.crEnvGubun === 'PRD')];
            processDeviceLicenseList({ ...tmpCrInfo[0] });
    }, [crInfoRedux]);

    useEffect(() => {
        return () => {
            clearInterval(dataInterval.current);
        }
    }, []);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <DevicePool
                visible={devicePoolVisible}
                onOk={onOkDevicePool}
                onCancel={onCancelDevicePool}
                crCode={selectCr.crCd}
                defaultData={devicePool}
                mode='multiple'
            />
            <Wrapper>
                <Tabs type='card' custom={false} defaultActiveKey='1' onChange={onChangeTabs}>
                    <TabPane tab={`Active (${activeData.total})`} key='1'>
                        <RealtimeActive
                            crInfo={crInfo}
                            selectCr={selectCr}
                            onChangeCrSelect={onChangeCrSelect}
                            devicePool={devicePool}
                            onClickDevicePoolButton={onClickDevicePoolButton}
                            activeData={activeData}
                            onClickSearchButton={onClickSearchButton}
                            onClickResetButton={onClickResetButton}
                            // diyDeviceList={diyDeviceList}
                            // onChangeDiyDevice={onChangeDiyDevice}
                        />
                    </TabPane>
                    <TabPane tab={`Inactive (${inActiveData.total})`} key='2'>
                        <RealtimeInActive
                            crInfo={crInfo}
                            selectCr={selectCr}
                            onChangeCrSelect={onChangeCrSelect}
                            devicePool={devicePool}
                            onClickDevicePoolButton={onClickDevicePoolButton}
                            inActiveData={inActiveData}
                            onClickSearchButton={onClickSearchButton}
                            onClickResetButton={onClickResetButton}
                            // diyDeviceList={diyDeviceList}
                            // onChangeDiyDevice={onChangeDiyDevice}
                            onSelectPageSize={onSelectPageSize}
                            tableInfo={tableInfo}
                            setTableInfo={setTableInfo}
                        />
                    </TabPane>
                    <TabPane tab={`Disconnected (${disconnectedData.total})`} key='3'>
                        <RealtimeDisconnected
                            crInfo={crInfo}
                            selectCr={selectCr}
                            onChangeCrSelect={onChangeCrSelect}
                            devicePool={devicePool}
                            onClickDevicePoolButton={onClickDevicePoolButton}
                            disconnectedData={disconnectedData}
                            onClickSearchButton={onClickSearchButton}
                            onClickResetButton={onClickResetButton}
                            // diyDeviceList={diyDeviceList}
                            // onChangeDiyDevice={onChangeDiyDevice}
                            onSelectPageSize={onSelectPageSize}
                            tableInfo={tableInfo}
                            setTableInfo={setTableInfo}
                        />
                    </TabPane>
                </Tabs>
            </Wrapper>
        </PageTemplate>
    )
}

export default MonitoringRealtime;