import styled from "styled-components";

const Wrapper = styled.div` 
  /* 공통 */  
  .common__container {
    border: 1px solid #d0d1d6;
    padding: 20px 21px;
    background: #fff;
    height: 294px;
  } 
  .common__div {
    display: flex;
    justify-content: space-between;
  }
  .common__title {
    display: flex;
    font-size: 20px;
    font-weight: bold;
  }
  .ant-divider-horizontal { 
    margin-bottom: 5px !important; 
    border: 1px solid #d0d1d6;
    width: 225.63px;
    align-self: center;
    border-bottom-style: hidden;
  }
  .common__button__link {
    display: flex;
    justify-content: flex-end;
    margin-right: -16px;
  }
  .common__button {
    background: inherit;
    border: none;
    color: #979797;
    margin-top: -10px;
    font-size: 12px;
    align-items: center;
    height: fit-content;
    display: flex;
    place-content: flex-end;
    cursor: pointer;
  } 
  .main_basic__layers {
    padding-top: 20px;
  }
  /* oper table area */
  .oper_table_area {
    border-top: 1px solid #e2e2e2;
  }
  /* 공통 > table */ 
  .ant-table table {
    table-layout: fixed !important;
  }
  .ant-table-thead {
    height: 36px;
  }
  .ant-table-thead > tr > th {
    background: #fff !important;
    color: #c1c1c1;
    font-weight: bold;
    height: 10px;
    padding: 5px 7px;
  }
  .ant-table-tbody > tr > td {
    height: 29px;
    padding: 4px;
    color: rgb(0 0 0 / 87%);
  }
  .ant-table-tbody > tr > td span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  } 
  .recommend__container .ant-tabs-nav-container .ant-tabs-nav-wrap {
    margin: 0 0 14px 0;
  } 
  .recommend__container .ant-tabs-bar.ant-tabs-top-bar {
    border-bottom: none;
  }

  // 우리은행 수정사항
  /* .ant-tabs-bar {
    margin: 0;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    pointer-events: none;
    border-top: none;
    border-bottom: 4px solid #22e3a6 !important;
    background: none;
    width: 52px;
  }
  .ant-tabs-nav {
    font-size: 12px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #000;
    font-weight: bold;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #e2e2e2;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 4px 0;
  }
  .ant-tabs-nav.ant-tabs-nav-animated {
    height: 40px;
  }
  .ant-tabs-line {
    margin-top: -6px !important;
  } */

  /* 기존 드리고 */
  /* 공통 > tabs */
  .ant-tabs-bar {
    margin: 0;
  }
  .ant-tabs-ink-bar {
    position: absolute;
    pointer-events: none;
    border-top: none;
    border-bottom: 4px solid #ff7a00 !important;
    background: none;
    width: 52px;
  }
  .ant-tabs-nav {
    font-size: 12px;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #000;
    font-weight: bold;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #e2e2e2;
  }
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 4px 0;
  }
  .ant-tabs-nav.ant-tabs-nav-animated {
    height: 40px;
  }
  .ant-tabs-line {
    margin-top: -6px !important;
  }
  
  .float_display__button {
    background: #fff;
    border: none;
    color: #979797;
    font-size: 12px;
    text-align: end;
  }
  /* 단계별 가이드 제공 */
  .steps__recommend {
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    padding: 0px 5px;
  }
  span.steps__span {
    padding-bottom: 11px;
  }
  /* 추천과제 recommend */
  .recommend__container {
    border: 1px solid #d0d1d6;
    padding: 17px 29px 36px 26.8px;
    background: #fff;
    height: 305px;
    width: 1072px;
  } 
  .recommend__text {
    color: rgb(0 0 0 / 60%);
    font-size: 12px;
    padding-bottom: 10px;
    display: block;
  }
  .recommend_boxes__container {
    display: flex;
    width: 100%;
    margin: 0; 
    width: calc(100%/3);
    gap: 20px;
  } 
  .recommend_boxes__none {
    width: 100%;
    font-size: 12px;
    text-align: center;
    top: 40%;
    position: absolute;
  }
  .recommend_boxes__middle {
    display: flex;
    flex-direction: column;
    gap: 9px;
  }
  .recommend_boxes__table {
    display: flex;
    height: 17px;
    width: 150px;
  }
  .recommend_boxes__table #title {
    border: 1px solid #d0d1d6;
    font-size: 11px;
    padding: 1px 8px;
    align-self: self-start;
    color:white;
    background:#59617F;
    border-radius: 20px;
  }
  .recommend_boxes__table #number {
    font-weight: bold;
    font-size: 12px;
    align-self: center;
    margin-left: auto;
    width: 30px;
    color:#E54156;
  }
  .recommend_boxes__table #number_fte {
    font-weight: bold;
    font-size: 12px;
    align-self: center;
    margin-left: auto;
    width: 30px;
    color:#1B7BB3;
  }
  .recommendbox__title {
    display: flex;
    gap: 10px;
    font-size: 16px;
    height: 29px;
    align-items: center;
  }
  .recommendbox__text {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    align-self: end;
    width: 152px;
  }
  .recommend__container .ant-tabs {
    padding: 0 18.6px;
    border-bottom: none;
  }
  /* 추천 과제 > 캐러셀 */
  #carousel__box { 
    position: relative;
    height: 179px;
    overflow: hidden;
    display: inline-block;
    width: 100%;
  } 
  button#prev {
    background: transparent; 
    left: 36px;
    position: absolute;
    transform: translateX(-100%);
    top: 62%;
    border: none;
    cursor: pointer;
  }
  button#next {
    background: transparent; 
    margin: 0;
    padding: 0;
    right: 28px;
    position: absolute;
    transform: translateX(+100%);
    top: 62%;
    border: none;
    cursor: pointer;
  }
  .carousel_dot__bottom {
    position: absolute;
    left: 520px;
    display: flex;
    gap: 8px;
    margin-top: -25px;
    
  }
  /* KPI 현황 */
  .kpi__title {
    display: flex;
    font-size: 20px;
    font-weight: bold; 
    padding-bottom: 10px;
  }
  .kpi__container {
    border: 1px solid #d0d1d6;
    width: 1072px; 
    background: #fff;
    padding: 22px 32px 0 32px;
    height: 275px;
  } 
  /* myRpa 현황*/
  .box_area {
    display: flex;
    gap: 10px;
  }
  /* 운영 현황 */
  .operation_table__content {
    display: flex;
  }
  .operation_table__content svg {
    width: 14px;
  }
  .operation_numbers {
    display: flex;
    font-size: 12px;
    padding: 7px 0;
    font-weight: bold;
    gap: 15px;
  }
  .operation_table__content span {
    font-size: 12px;
  } 
  .ant-table-tbody > tr > td .ranking_column_userTag  span{
    display: flex !important;
  }
  .ranking_column_userTag .user_thumbnail {
    width: 20px !important;
    height: 20px !important;
    display: flex;
  }
`;

export default Wrapper;