import React from 'react';
import styled from 'styled-components';

/**
 * Filename: Button.js
 * Description: 버튼 공통 컴포넌트
 * TypeCode: default  - 배경색이 흰색에 가까운 일반적인 버튼
 *           primary  - 배경색이 메인색상인 버튼
 *           text     - 배경과 width, height가 없이 일반적인 텍스트처럼 보이는 버튼
 */

const ButtonWrap = styled.button`
  transition: 0.5s;
  font-size: 12px;
  cursor: pointer;
  ${props => props.type !== 'text' && `
      width: ${props.width}px;
      height: ${props.height}px;
    `
  }
  ${props => props.type === 'default' && `
    background: #fff;
    border: 1px solid #ff7a00;
    color: #ff7a00de;
    // border: 1px solid #0067ac;
    // color: #0067ac;

    &[disabled] {
      border-color: #d0d1d6;
      color: #d0d1d6;
      pointer-events: none; 
    }
    &:hover {
      border: 1px solid #FF7A00;
      color: #FF7A00;
      background: #FFF3E8;
      // 우리
      // border: 1px solid #0067ac;
      // color: #0067ac;
      // background: rgb(0, 103, 172, 0.15)
      // opacity: 0.15
    }
    &:active {
      background: #FF6600;
      // background: #2b64ce;
      color: #fff;
    }
  `}
  ${props => props.type === 'primary' && `
    // background: #0067ac;
    background: #FF7B00;
    border: none;
    color:#FFF;
    &:hover {
      // background: #004981;
      background: #FF6600;
    }
    &:active {
      background: #FF7700;
    }
  `}
  ${props => props.type === 'text' && `
    background: none;
    border: none;
    color: #acacac;
    padding: 0;
  `} 
  ${props => props.type === 'white' && `
    background: #fff;
    border: 1px solid #d0d1d6;
    &:hover {
      border-color: #ff7a00;
      color: #ff7a00;
    }
  `}
  ${props => props.type === 'popover' && `
    // 우리카드
    // background: #2b64ce;
    background: #FE9823;
    border: none;
    color: #FFF; 
  `}
  ${props => props.type === 'disabled' && `
    cursor: no-drop;
    background: #FFF;
    border: 1px solid #e6e6e6;
    color: #e6e6e6;
  `}
`

const Button = ({ style = {}, children, type = 'default', onClick = () => { }, width = 100, height = 32, innerRef, disabled = false, ...args }) => {
  const onClickButton = (e) => {
    if (disabled) {
      e.preventDefault();
    } else {
      onClick(e);
    }
  }
  return (
    <ButtonWrap width={width} height={height} style={{ ...style }} onClick={onClickButton} type={type} disabled={disabled} {...args} ref={innerRef}>
      {children}
    </ButtonWrap>
  );
};

export default Button;