import React, { useState, useEffect } from 'react';
import { Text, LinkModal } from 'components';
import { useDispatch } from 'react-redux';
import { setVisible } from 'store/loading/action';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from 'react-intl';

const TransferRefuse = ({ match: { params } }) => {
    const { formatMessage } = useIntl();

    const { taskId, seq } = params;

    const dispatch = useDispatch();

    const [requestResult, setRequestResult] = useState({});

    useEffect(() => {
        const fetchSharedRequest = async () => {
            dispatch(setVisible(true));
            const result = await fetchAbsolute('post', `/task/oracle/refuseTransferTask?taskId=${taskId}&reqSeq=${seq}`);
            dispatch(setVisible(false));

            setRequestResult({ ...result });
        };
        fetchSharedRequest();
    }, [taskId]);

    if (Object.keys(requestResult).length === 0) {
        return (
            <></>
        )
    } else {
        return (
            <LinkModal
                visible={true}
                title=""
                width={540}
                closable={false}
                footer={[]}
                titleDisabled
                footerDisabled
            >
                <div className='request_box'>
                    <Text className='request_box__text'>
                        {!requestResult.error ? `${formatMessage({ id: 'T0992' })}`
                            // 업무 이관 반려를 완료했습니다.
                            : requestResult.data?.message || `${formatMessage({ id: 'T0993' })
                            // 업무 이관 반려를 실패하였습니다.
                            }`}
                    </Text>
                </div>
            </LinkModal>
        )
    }
}

export default TransferRefuse;