import fetchAbsolute from "utils/fetchAbsolute";
import { takeEvery, put, select } from 'redux-saga/effects';
import { LOAD_WORKFLOW_DATA, loadWorkflowDataFail, loadWorkflowDataSuccess, WORKFLOW_INSTANT_START } from './action';
import { setVisible } from '../loading/action';
import { getUniqueKey } from 'utils/Str';

function* getData({ workflowId }) {
    yield put(setVisible(true));
    const result = yield fetchAbsolute('get', `/workflow/workflowInfoHF?workflowId=${workflowId}`);
    if (result.error) {
        yield put(loadWorkflowDataFail());
    } else {
        yield put(loadWorkflowDataSuccess({ ...result.data }));

    };
    yield put(setVisible(false));
}
function* instantStart(param) {
    const workflowLookup = yield select(state => state.get(getUniqueKey('workflowLookup')));

    const { inputValues, successMsg, failedMsg } = param.data;

    let tmpUserInput = {};

    if (inputValues?.length > 0 && !!inputValues[0].key) {
        tmpUserInput = inputValues.reduce(
            (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
            {}
        );
    };

    const parameter = {
        workflowId: workflowLookup.workflowData.workflowId,
        scheduleType: "INSTANT",
        userInput: {
            ...tmpUserInput,
        },
    };

    const result = yield fetchAbsolute("post", `/boxwood/addBoxwoodSchedule`, {
        data: { ...parameter },
        headers: {
            "Content-Type": "application/json",
        },
    }
    );

    if (!result.error) {
        alert(successMsg);
        param.cb();
    } else {
        alert(failedMsg);
    };
}

function* rootSaga() {
    yield takeEvery(LOAD_WORKFLOW_DATA, getData);
    yield takeEvery(WORKFLOW_INSTANT_START, instantStart);
};

export default rootSaga;