import React, { useEffect, useRef } from 'react';
import Wrapper from "./Styled";
import { PageTemplate, Header, Footer, Modal, Text, } from "components";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import fetchAbsolute from "utils/fetchAbsolute";
import TreeList from "./Management/TreeList";
import OrganizationDetails from "./Management/OrganizationDetails";
import moment from 'moment';

const initialChangeInput = {
  companyCd: '',
  codeId: '',
  orgStartDt: '',
  orgEndDt: '',
  codeNm: '',
  codeLevel: '',
  codeIdUpper: '',
  orgSortNum: 1,
}

/**
 * 그룹 조직을 추가 삭제 할 수 있는 메뉴
 * 관리자 > 조직 관리
 */
const AdminOrganization = () => {
  const { formatMessage } = useIntl();

  const [keyVal, setKeyVal] = useState(-1);
  const [levelCheck, setLevelCheck] = useState(0);
  const [childCheck, setChildCheck] = useState(0);
  const [departmentList, setDepartmentList] = useState([]);
  const [flatDepartmentList, setFlatDepartmentList] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [selectedKey, setSelectedKey] = useState('');
  const [selectedDatas, setSelectedDatas] = useState({});
  const [isCompanyCdDisabled, setIsCompanyCdDisabled] = useState(false);
  const [isOrgCdDisabled, setIsOrgCdDisabled] = useState(false);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);


  const requireFields = {
    companyCd: {
      ref: useRef(null),
      message: formatMessage({ id: 'T1242' }),  // 회사코드
    },
    codeId: {
      ref: useRef(null),
      message: formatMessage({ id: 'T1243' }), // 조직코드
    },
    orgStartDt: {
      ref: useRef(null),
      message: formatMessage({ id: 'T1244' }), // 조직 시작일 
    },
    orgEndDt: {
      ref: useRef(null),
      message: formatMessage({ id: 'T1245' }), // 조직 종료일 
    },
    codeNm: {
      ref: useRef(null),
      message: formatMessage({ id: 'T0014' }), // 조직명 
    },
  };

  const cancelKey = () => {
    setKeyVal(-1);
  };

  /* orgCd: 조직코드, level: 레벨  */
  const getData = (orgCd, level, children, organizationList, isNew) => {
    if (isNew === 1) {
      setIsCompanyCdDisabled(true);
      setIsOrgCdDisabled(false);
    }
    else if (isNew === -1) {
      setIsCompanyCdDisabled(false);
      setIsOrgCdDisabled(false);
    } else {
      setIsCompanyCdDisabled(true);
      setIsOrgCdDisabled(true);
    }
    setKeyVal(orgCd);
    setLevelCheck(+level);
    setChildCheck(children);
  };

  const organizationDetailsCheck = () => {
    // 선택되지 않은 빈 상태
    if (keyVal !== -1 && levelCheck > 0) {
      return (
        <OrganizationDetails
          keyVal={keyVal}
          treeData={treeData}
          cancelKey={cancelKey}
          selectedDatas={selectedDatas}
          departmentList={departmentList}
          initialChangeInput={initialChangeInput}
          isCompanyCdDisabled={isCompanyCdDisabled}
          isOrgCdDisabled={isOrgCdDisabled}
          flatDepartmentList={flatDepartmentList}
          requireFields={requireFields}
          getDepartmentList={getDepartmentList}
        />
      );
    }
    // 선택된 상태
    else if (keyVal !== -1 && levelCheck === 0) {
      return (
        <OrganizationDetails
          keyVal={keyVal}
          treeData={treeData}
          cancelKey={cancelKey}
          selectedDatas={selectedDatas}
          departmentList={departmentList}
          flatDepartmentList={flatDepartmentList}
          initialChangeInput={initialChangeInput}
          isCompanyCdDisabled={isCompanyCdDisabled}
          isOrgCdDisabled={isOrgCdDisabled}
          requireFields={requireFields}
          getDepartmentList={getDepartmentList}
        />
      );
    }
  };

  const onCancelModal = () => {
    setIsDeleteVisible(false);
  };

  // 조직 삭제 버튼
  const onClickDelete = () => {
    setIsDeleteVisible(true);
  }

  const onOkDelete = async () => {
    if (Object.keys(selectedDatas).length === 0) {
      setIsDeleteVisible(false);
      return alert(formatMessage({ id: 'T1246' })); //선택된 조직이 없습니다.
    }
    if (childCheck !== 0) {
      setIsDeleteVisible(false);
      return alert(formatMessage({ id: 'T0238' })); //하위 항목을 먼저 삭제해주십시오.
    }
    const result = await fetchAbsolute('delete', `/org/orgListDelete`, {
      data: [{
        "companyCd": selectedDatas.companyCd, // 회사코드
        "orgCd": selectedDatas.codeId, // 조직코드
      }],
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.status === 200 || result.status === 201) {
      alert(formatMessage({ id: 'T0281' })); //삭제되었습니다.
      setSelectedDatas({ ...initialChangeInput });
      setSelectedKey('');
    } else {
      throw new Error(result.status);
    }
    setIsDeleteVisible(false);
  }

  /* Tree 리스트 저장 및 조직 선택시 정보 저장 */
  const getDepartmentList = async (selectedKey) => {
    if (!selectedKey) {
      const result = await fetchAbsolute('get', `/common/departmentList`);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const { mdList } = result?.data
      setFlatDepartmentList([...mdList]);
      setDepartmentList([...mdList]);
    } else {
      const result = await fetchAbsolute('get', `/common/departmentList?orgCd=${selectedKey}`);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const { mdList } = result?.data;
      const tmpList = mdList.map(v => ({
        ...v,
        orgStartDt: moment(v.orgStartDt).format('YYYY-MM-DD'),
        orgEndDt: moment(v.orgEndDt).format('YYYY-MM-DD'),
      }));
      setSelectedDatas(tmpList[0]);
    }
  };


  useEffect(() => {
    const tmpDatas = [];
    let lastArray;
    // 레벨에 맞는 배열 가져오기 위한 재귀함수
    const getLevelArray = (array, level, count = 1) => +level === count ? array : getLevelArray(array[array.length - 1].children, level, count + 1);
    departmentList.forEach((value) => {
      lastArray = getLevelArray(tmpDatas, +value.level);
      const data = {
        title: value.codeNm,
        key: value.codeId,
        level: +value.level,
        orgSortNum: +value.orgSortNum,
        children: []
      };
      if (!lastArray.children) lastArray.push(data);
      else lastArray.children.push(data);
    });
    tmpDatas.map((v) => {
      v.children?.sort((a, b) => a.orgSortNum - b.orgSortNum)
    });
    setTreeData(tmpDatas);
  }, [departmentList]);

  useEffect(() => {
    getDepartmentList(selectedKey);
  }, [selectedKey]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
      <Wrapper>
        <div className="org_container">
          <TreeList
            treeData={treeData}
            setSelectedKey={setSelectedKey}
            getData={getData}
            flatDepartmentList={flatDepartmentList}
            selectedKey={selectedKey}
            onClickDelete={onClickDelete}
            setChildCheck={setChildCheck}
            childCheck={childCheck}
            selectedDatas={selectedDatas}
            setSelectedDatas={setSelectedDatas}
            initialChangeInput={initialChangeInput}
            setIsOrgCdDisabled={setIsOrgCdDisabled}
          />
          {organizationDetailsCheck()}
        </div>
      </Wrapper>
      <Modal
        width={450}
        visible={isDeleteVisible}
        title={<FormattedMessage id="T1247" />}// 조직 삭제
        okText={<FormattedMessage id="T0072" />} //삭제
        onCancel={onCancelModal}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "flex",
            fontSize: 14,
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          {formatMessage({ id: "T1248" })
            .split("\n")
            .map((data, index) => {
              return (
                <span key={index}>
                  {data}
                  <br />
                </span>
              );
            })}
          {/* 
            정말로 선택된 조직을 삭제하시겠습니까?<br />
            하위 조직이 있을 경우, 하위 조직을 먼저 삭제해주세요. 
          */}
        </Text>
      </Modal>
    </PageTemplate>
  );
};

export default AdminOrganization; 