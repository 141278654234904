import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Breadcrumb } from "antd";
import { getUniqueKey } from "utils/Str";
import Container from "containers/Container";
import { HomeFilled } from "@ant-design/icons";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";
import "../common.css";

const { Title } = Typography;

const PageHeader = ({ headerColor, depthList = [], menuTitle = "" }) => {
  const { formatMessage } = useIntl();

  const { path, menuFlatList } = useSelector((state) =>
    state.get(getUniqueKey("menuList"))
  );

  const [title, setTitle] = useState("");
  const [titleList, setTitleList] = useState([]);

  const styles = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    boxSizing: "border-box",
    paddingTop: 10,
    marginBottom: -1,
    background: headerColor,
  };

  useEffect(() => {
    if (menuFlatList.length === 0) return;
    if (depthList.length > 0 && !!menuTitle) {
      setTitle(menuTitle);
      setTitleList([...depthList]);
    } else {
      const list = [];
      let findObj = {};

      if (path.includes("/assign/list")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl.includes("/assign/list")
          ),
        };
      } else if (path.includes("/assign/lookup")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl.includes("/assign/list")
          ),
          menuNm: formatMessage({ id: "T1231" }), // 과제 조회
        };
        findObj.menuNm = formatMessage({ id: "T1231" }); // 과제 조회
      }
      /* --------- HF Bizflow --------- */
      else if (path.includes("/bizflow/hfbpd/list")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfbpd/")
          ),
          menuNm: "Business Process Design",
        }; //
      } else if (path.includes("/bizflow/hfbpd/")) {
        if (path.includes("/bizflow/hfbpd/enter")) {
          findObj = {
            ...menuFlatList.find(
              (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfbpd/enter")
            ),
            menuNm: formatMessage({ id: "T1566" }), // Business Process Design 등록
            parentId: 10001,
          }; //
        } else if (path.includes("/bizflow/hfbpd/modify")) {
          findObj = {
            ...menuFlatList.find(
              (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfbpd/modify")
            ),
            menuNm: formatMessage({ id: "T1567" }), // Business Process Design 수정
            parentId: 10001,
          }; //
        } else {
          findObj = {
            ...menuFlatList.find(
              (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfbpd/lookup")
            ),
            menuNm: formatMessage({ id: "T1565" }), // Business Process Design 조회
            parentId: 10001,
          }; //
        }
      } else if (path.includes("/bizflow/hfworkflow/list")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfworkflow/")
          ),
          menuNm: "Workflow",
        };
      } else if (path.includes("/bizflow/hfworkflow/")) {
        if (path.includes("/bizflow/hfworkflow/enter")) {
          findObj = {
            ...menuFlatList.find(
              (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfworkflow/enter")
            ),
            menuNm: formatMessage({ id: "T1569" }), // Workflow 등록
            parentId: 11001,
          }; //
        } else if (path.includes("/bizflow/hfworkflow/modify")) {
          findObj = {
            ...menuFlatList.find(
              (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfworkflow/modify")
            ),
            menuNm: formatMessage({ id: "T1570" }), // Workflow 수정 
            parentId: 11001,
          }; //
        } else {
          findObj = {
            ...menuFlatList.find(
              (v) => v.menuUrl && v.menuUrl.includes("/bizflow/hfworkflow/lookup")
            ),
            menuNm: formatMessage({ id: "T1568" }), // Workflow 조회 
            parentId: 11001,
          }; //
        }
      }
      else if (path.includes("/admin/commoncd")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl.includes("/admin/commoncd")
          ),
        };
      } else if (path.includes("/admin/common")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl === "/admin/common"
          ),
        };
      } else if (path.includes("/admin/jediitem")) {
        findObj = {
          ...menuFlatList.find(
            (v) => v.menuUrl && v.menuUrl.includes("/admin/jediitem")
          ),
        };
      } else {
        findObj = {
          ...menuFlatList.find((v) => v.menuUrl && v.menuUrl.includes(path)),
        };
      }

      if (!findObj) return;
      list.push(findObj.menuNm);
      if (findObj.parentId) {
        const parentObj = {
          ...menuFlatList.find((v) => v.menuId === findObj.parentId),
        };
        list.unshift(parentObj.menuNm);
      }
      setTitle(findObj.menuNm);
      setTitleList([...list]);
    }
  }, [path, menuFlatList]);

  return (
    <Wrapper headerColor={headerColor}>
      <Container style={{ ...styles }}>
        <div className="page_header__container">
          <Title level={3} className="page_header__title">
            {title}
          </Title>
        </div>
        <Breadcrumb
          separator=">"
          style={{ paddingBottom: 20, fontSize: 12 }}
          className="page_header__bread"
        >
          <Breadcrumb.Item>
            <HomeFilled />
          </Breadcrumb.Item>
          {titleList.map((page, index) => {
            return <Breadcrumb.Item key={index}>{page}</Breadcrumb.Item>;
          })}
        </Breadcrumb>
      </Container>
    </Wrapper>
  );
};

export default PageHeader;
