import React from 'react'; 
import { PageTemplate, Header, Footer, AdminBoardManage } from 'components';  

/**
 * Filename: AdminBoard/index.js
 * Description: 관리자 탭 > 게시판 컴포넌트
 */

const AdminBoard = ({ profile }) => { 
    
    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <AdminBoardManage profile={profile}/> 
        </PageTemplate >
    );
}

export default AdminBoard;