import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import fetchAbsolute from "utils/fetchAbsolute";
import { setListUrl } from "store/listUrl/action";
import { Collapse as AntCollapse } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl } from "react-intl";
import {
  PageTemplate,
  Header,
  Footer,
  HFWorkFlowScheduleTop,
  HFWorkFlowScheduleBottom,
  Collapse,
  Loading,
} from "components";
import Wrapper from "./Styled";
import moment from "moment";
import queryString from "query-string";

const { Panel } = AntCollapse;

//moment라이브러리에서 moment()는 현재 시간임
const initialSearchQuery = {
  yyyymm: moment().format("YYYYMM"),
  dd: moment().format("DD"),
  specificDay: moment().format("YYYYMMDD"),
  calendar: "monthly",
};

/**
  workflowId: Workflow Id
  workflowNm: 업무명
  workId: 업무 분류
  scheduleType: 스케줄 타입
  workflowOrgCd: 담당 부서
  regId: 등록자 
 */
const initialInputValues = {
  workflowId: '',
  workflowNm: '',
  scheduleType: [],
  workId: [],
  workflowOrgCd: [],
  regId: [],
};

const initialObj = {
  ...initialSearchQuery,
  ...initialInputValues,
};

const HFWorkflowSchedule = ({ location: { search }, history }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState({
    ...initialSearchQuery,
    ...initialInputValues,
  });

  // const inputValues = useSelector(
  //   (state) => state.get(getUniqueKey("hfWorkflowSchedule")).inputValues
  // );
  const [calInfo, setCalInfo] = useState({});

  const [openCollapse, setOpenCollapse] = useState(true);
  const [loading, setLoading] = useState(false);

  const [scheduleDatas, setScheduleDatas] = useState([]);

  // scheduleSearch 텍스트
  const [scheduleSearch, setScheduleSearch] = useState("");

  const onChangeCollapse = () =>
    setOpenCollapse((openCollapse) => !openCollapse);

  const getScheduleData = async (searchObj) => {
    setLoading(true);

    const result = await fetchAbsolute("post", '/workflowSchedule/scheduleList', {
      data: {
        calendar: searchObj.calendar,
        dd: searchObj.dd,
        yyyymm: searchObj.yyyymm,
        workflowId: searchObj.workflowId || '',
        workflowNm: searchObj.workflowNm || '',
        workflowOrgCd: typeof searchObj.workflowOrgCd === "string" ? [searchObj.workflowOrgCd] : searchObj.workflowOrgCd || [],
        regId: typeof searchObj.regId === "string" ? [searchObj.regId] : searchObj.regId || [],
        workId: typeof searchObj.workId === "string" ? [searchObj.workId] : searchObj.workId || [],
        scheduleType: searchObj.scheduleType || [],
        specificDay: searchObj.specificDay
      }
    });
    setLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0185' })); // 스케줄 정보 가져오는 도중 에러가 발생했습니다.
    setScheduleDatas({ ...result.data }); // monthly, weekly, daily 스케줄 정보
  }

  const replaceSearch = async (searchObject) => {
    const beforeSearch = [];
    const beforeObject = {};
    const initialKeys = Object.keys(initialInputValues);
    const searchObj = { ...initialObj, ...searchObject };

    const beforeKeys = Object.keys(searchObj).filter(key =>
      initialKeys.includes(key)
    );

    // 검색해야 할 내용들 정리
    Object.keys(searchObj).forEach((key) => {
      const data = searchObj[key];
      if (!beforeKeys.includes(key)) {
        return;
      }

      if (Object.keys(data).length <= 0) {
        delete searchObj[key];
        return;
      }

      beforeObject[key] = data;

      if (['regId'].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          beforeSearch.push(data[i].empNam);
          tmpArr.push(data[i].empNum);
        }
        searchObj[key] = [...tmpArr];

      } else if (['workflowOrgCd'].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          beforeSearch.push(data[i].codeNm);
          tmpArr.push(data[i].codeId);
        }
        searchObj[key] = [...tmpArr];

      } else if (['workId'].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          beforeSearch.push(data[i].cdNm);
          tmpArr.push(data[i].cd);
        }
        searchObj[key] = [...tmpArr];

      } else if (['workflowWorkId'].includes(key)) {
        beforeSearch.push(data.cdNm);
        searchObj[key] = typeof data === 'object' ? data.cd : data;

      } else if (['scheduleType'].includes(key)) {
        if (typeof data === "string") {
          beforeSearch.push(data);
          searchObj[key] = [data];
        } else {
          const tmpArr = [];
          for (let i in data) {
            beforeSearch.push(data[i]);
            tmpArr.push(data[i]);
          }
          searchObj[key] = [...tmpArr];
        }
      } else if (beforeKeys.includes(key)) {
        if (typeof data === "string") {
          beforeSearch.push(data);
        } else {
          for (let i in data) {
            beforeSearch.push(data[i]);
          }
        }
      }
    });
    setCalInfo({ ...searchObj });
    getScheduleData({ ...searchObj });

    const beforeText = beforeSearch.slice(0, 2).join(", ");
    if (beforeSearch.length > 0) {
      localStorage.setItem("beforeSearchHFWorkflowSchedule", beforeText);
      localStorage.setItem(
        "beforeObjectHFWorkflowSchedule",
        JSON.stringify(beforeObject)
      );
      setScheduleSearch(beforeText);
    }


    const searchString = queryString.stringify(searchObj);
    if ("?" + searchString === search) {
      return;
    }
    dispatch(setListUrl(searchString));
    history.replace({ search: searchString });
  };

  // query String 변경 될 때마다
  useEffect(() => {
    const searchObject = queryString.parse(search);

    /* 시작하는 첫 화면 초기 세팅 부분 */
    if (search.length <= 0) {
      replaceSearch({ ...initialObj });
      return;
    }
    else if (Object.keys(history.location).includes("state")) {
      if (searchObject.calendar === "monthly") {
        replaceSearch({ ...initialObj });
        return;
      } else if (searchObject.calendar === "weekly") {
        replaceSearch({ ...searchObject, calendar: "weekly" });
        return;
      } else if (searchObject.calendar === "daily") {
        replaceSearch({ ...searchObject, calendar: "daily" });
        return;
      }
    }

    // search query로 데이터 세팅하는 함수
    const setDatas = async () => {
      //const searchObject = queryString.parse(search);
      const { workId, workflowOrgCd, regId, scheduleType } = searchObject;
      // 부서 정보 세팅
      if (workflowOrgCd) {
        try {
          const tmpArr = [];
          if (typeof workflowOrgCd === "string") {
            const { data } = await fetchAbsolute(
              "get",
              `/common/departmentList?orgCd=${workflowOrgCd}`
            );
            tmpArr.push(data.mdList[0]);
          } else {
            for (let i in workflowOrgCd) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/departmentList?orgCd=${workflowOrgCd[i]}`
              );
              tmpArr.push(data.mdList[0]);
            }
          }
          searchObject.workflowOrgCd = [...tmpArr];
        } catch {
          delete searchObject.workflowOrgCd;
        }
      }

      // 등록자 정보 세팅
      if (regId) {
        try {
          const tmpArr = [];
          if (typeof regId === "string") {
            const { data } = await fetchAbsolute(
              "get",
              `/common/memberNoList?empNo=${regId}`
            );
            tmpArr.push(data.mdFPAList[0]);
          } else {
            for (let i in regId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${regId[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
          }
          searchObject.regId = [...tmpArr];
        } catch {
          delete searchObject.regId;
        }
      }

      if (workId) {
        try {
          const tmpArr = [];
          if (typeof workId === "string") {
            const { data } = await fetchAbsolute(
              "get",
              `/common/workClassification?cd=${workId}`
            );
            tmpArr.push(data);
          } else {
            for (let i in workId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/workClassification?cd=${workId[i]}`
              );
              tmpArr.push(data);
            }
          }
          searchObject.workId = [...tmpArr];
        } catch {
          delete searchObject.workId
        }
      }

      if (scheduleType) {
        const tmpArr = [];
        if (typeof scheduleType === "string") {
          tmpArr.push(scheduleType);
        } else {
          for (let i in scheduleType) {
            tmpArr.push(scheduleType[i]);
          }
        }
        searchObject.scheduleType = [...tmpArr];
      }

      const tmpObj = {
        ...initialObj,
        ...searchObject,
      };
      setSearchQuery({ ...tmpObj });
      // getScheduleData({ ...tmpObj });
    }

    setDatas();
  }, [search]);

  useEffect(() => {
    return () => {
      setLoading(false);
    }
  }, [])

  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      {
        <Wrapper>
          <Collapse
            expandIconPosition="end"
            onChange={onChangeCollapse}
            expandIcon={() =>
              !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
            }
            activeKey={openCollapse ? ["1"] : []}
            defaultActiveKey={openCollapse ? ["1"] : []}
          >
            <Panel header={formatMessage({ id: 'T1194' })} key='1'>{/* 상세검색 */}
              <HFWorkFlowScheduleTop
                replaceSearch={replaceSearch}
                searchQuery={searchQuery}
                scheduleSearch={scheduleSearch}
                setScheduleSearch={setScheduleSearch}
              />
            </Panel>
          </Collapse>
          {
            loading ? <Loading visible={loading} /> :
              <HFWorkFlowScheduleBottom
                replaceSearch={replaceSearch}
                searchQuery={searchQuery}
                scheduleDatas={scheduleDatas}
                calInfo={calInfo}
              />
          }
        </Wrapper>
      }
    </PageTemplate>
  );
};

export default HFWorkflowSchedule;