import { useSelector } from 'react-redux';
import { getUniqueKey } from 'utils/Str';
import {useIntl} from "react-intl";

const PermissionRoute = ({ fnCode, children, history, type = 'task' }) => {
    const { formatMessage } = useIntl();
    const accessNotAllowed = formatMessage({ id: 'T0210' });
    const { task, user, taskLoading, userLoading } = useSelector(state => state.get(getUniqueKey('permission')));

    if(type === 'user') {
        if(userLoading) {
            return null;
        }
        if(user.findIndex(v => v.fnCode === fnCode) === -1) {
            alert(accessNotAllowed);
            return history.push('/home');
        }
    }
    if(type === 'task') {
        if(!taskLoading) {
            if(Array.isArray(fnCode)) {
                const flag = fnCode.find(v => task.includes(v));
                if(!flag) {
                    alert(accessNotAllowed);
                    return history.push('/home');
                }
            } else if(!task.includes(fnCode)) {
                alert(accessNotAllowed);
                return history.push('/home');
            }
        }
    }
    return (
        children
    );
}

export default PermissionRoute;