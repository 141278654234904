import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { Button, Table } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment';
import useFormatMessage from "hooks/useFormatMessage";
import { Select } from "antd";
/**
 * 관리자 > 만족도 조사 관리 > 만족도 조사 관리 페이지
 * 
 */

const initialPageInfo = {
    sort: "researchFrom",
    direction: ",desc",
    total: 0,
    currentPage: 1,
    pageSize: 10,
};

const { Option } = Select;

const SatisfactionManage = ({ history }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
    const pageSize = [10, 20, 50];
    const onSelectPage = (value) => {
        const searchObj = {
            ...pageInfo,
            currentPage: 1,
            pageSize: +value,
        };
        setPageInfo({ ...searchObj });
    }

    const onChangeTableColumns = (pagination, filters, sorter) => {
        setPageInfo({ ...pageInfo, currentPage: +pagination.current });
    };

    const [dataList, setDataList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const columns = [{
        key: 1,
        dataIndex: 'title',
        title: formatMessage({ id: 'T0845' }), // 만족도 조사 제목
        render: (text, record) => (
            <Link to={`/admin/satisfaction/modify/${record.seqR}`}>{text}</Link>
        )
    }, {
        key: 2,
        title: formatMessage({ id: 'T0824' }), // 기간
        render: (record) => {
            return <span>{moment(record.researchFrom).format('YYYY-MM-DD')} ~ {moment(record.researchTo).format('YYYY-MM-DD')}</span>
        },
    }, {
        key: 3,
        dataIndex: 'useYn',
        title: formatMessage({ id: 'T0846' }) // 노출 여부
    }];

    const onClickAdd = () => {
        history.push('/admin/satisfaction/create');
    }

    const fetchDataList = async () => {
        setTableLoading(true);
        const result = await fetchAbsolute('get', `/admin/research/researchList`);
        setTableLoading(false);
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
        setDataList([...result.data]);
    }

    useEffect(() => {
        fetchDataList();
    }, []);

    return (
        <div className='satisfaction_box manage_box'>
            <div className='manage_box__top'>
                <div className="left">
                    <span>
                        {/* 페이지당 행 */}
                        {formatMessage({ id: "T0036" })} &emsp;
                        <Select
                            name="pagination"
                            defaultValue={pageSize[0]}
                            value={+pageInfo.pageSize}
                            onSelect={onSelectPage}
                        >
                            {pageSize.map((v, index) => (
                                <Option key={index} value={v}>
                                    {fmMessage({ id: "T1228", values: { number: v } })}
                                </Option>
                            ))}{/* {number} 개 */}
                        </Select>
                    </span>
                </div>
                <div>
                    <Button
                        type='primary'
                        onClick={onClickAdd}
                        style={{ width: 150, height: 32 }}
                    >
                        <FormattedMessage id="T0049" />{/* 추가 */}
                    </Button>
                </div>
            </div>
            <div>
                <Table
                    columns={columns}
                    dataSource={dataList}
                    loading={tableLoading}
                    rowKey={(e) => e.seqR}
                    showSorterTooltip={true}
                    pagination={true}
                    onChange={onChangeTableColumns}
                    pageOptions={{
                        total: +pageInfo.total,
                        current: +pageInfo.currentPage,
                        pageSize: +pageInfo.pageSize,
                    }}
                />
            </div>
        </div>
    )
}

export default withRouter(SatisfactionManage);