import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    padding: 30px 50px;
    .title_name {
        margin-bottom:1em;
        font-size:18; 
        font-weight:bold; 
        lineheight: 1.38;
        margin-top:1em; 
    }
    .credential_top__wrap {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
    }
    .credential_top__input_wrap {
        display: flex;
        gap: 0 30px;
    }
    .credential_top__input_wrap .credential_top__input_box {
        display: flex;
        gap: 0 18px;
        align-items: center;
    }
    .credential_top__input_wrap .credential_top__input_box.table_pagination {
        gap: 0 10px;
    }
    .credential_top__input_box .credential_top__input_text {
        font-size: 14px;
        font-weight: bold;
        color: #000;
    }
    .credential_top__input_box .credential_top__input {
        width: 200px;
        height: 32px;
    }

    .credential_top__input_box.table_pagination .credential_top__input_text {
        font-size: 12px;
        font-weight: normal;
        color: #000;
    }
    .credential_top__input_box.table_pagination .credential_top__input {
        width: 89px;
        height: 32px;
    }
    .credential_top__input_box .credential_top__button_box {
        display: flex;
        gap: 0 8px;
    }
    .credential_top__input_box .credential_top__button_box .credential_top__button {
        font-size: 19px;
        cursor: pointer;
    }
    .credential_bottom__wrap {
        margin-top: 24px;
    }
`;

export default Wrapper;