import React, { useState } from "react";
import { Text, Button, Table } from "components";
import Wrapper from "../Styled";
import { useIntl, FormattedMessage } from "react-intl";
import { Select } from "antd";
import useFormatMessage from "hooks/useFormatMessage";

/**
 * 관리자 > Agent 버전 관리 > Agent 버전 관리 탭
 * 
 */

const initialPageInfo = {
  total: 0,
  currentPage: 1,
  pageSize: 10,
};

const { Option } = Select;

const AgentVerManage = ({ agentTypeList, onChangeSelectType, onClickAddAgent, onClickEdit }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const pageSize = [10, 20, 50];
  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      currentPage: 1,
      pageSize: +value,
    };
    setPageInfo({ ...searchObj });
  }

  const onChangeTableColumns = (pagination, filters, sorter) => {
    setPageInfo({ ...pageInfo, currentPage: +pagination.current });
  };

  const columns = [
    {
      key: "type",
      dataIndex: "type",
      title: <FormattedMessage id="T1081" />, // Agent Type
      width: 200,
      render: (text, record, index) => {
        return (
          <div
            key={index}
            className='version__type'
            onClick={() => onClickEdit(record)}
          >
            {
              text === 'PC' ? `${formatMessage({ id: 'T1079' })}` // Agent Runner(RN)
                : `${formatMessage({ id: 'T1080' })}` // Dreago Agent(PC)
            }
          </div>
        );
      }
    },
    {
      key: "ver",
      dataIndex: "ver",
      title: <FormattedMessage id="T1085" />, // Version
    },
    {
      key: "fileName",
      dataIndex: "fileName",
      title: <FormattedMessage id="T1084" />, // Update Files
      render: (text, record, index) => {
        return (
          <div key={index}>
            <Text
              type="download"
              download={record.down}
              fileName={text}
            >
              {text}
            </Text>
          </div>
        );
      }
    },
    {
      key: "empNum",
      dataIndex: "empNum",
      title: <FormattedMessage id="T0456" />, // 담당자
      render: (text, record) => {
        return (
          <>
            {record.empNam}({text})
          </>
        );
      }
    },
    {
      key: "updateDt",
      dataIndex: "updateDt",
      title: <FormattedMessage id="T1082" />, // Update Date
    },
  ];

  return (
    <Wrapper>
      <div className="common__container">
        <div className="ver_manage__top" style={{ marginBottom: "20px" }}>
          <div className="left">
            <span>
              {/* 페이지당 행 */}
              {formatMessage({ id: "T0036" })} &emsp;
              <Select
                name="pagination"
                defaultValue={pageSize[0]}
                value={+pageInfo.pageSize}
                onSelect={onSelectPage}
              >
                {pageSize.map((v, index) => (
                  <Option key={index} value={v}>
                    {fmMessage({ id: "T1228", values: { number: v } })}
                  </Option>
                ))}{/* {number} 개 */}
              </Select>
            </span>
          </div>
          <div>
            <Button
              type='primary'
              style={{ width: 150, height: 32 }}
              onClick={onClickAddAgent}
            >
              <FormattedMessage id="T0049" />
            </Button>{/* 추가 */}
          </div>
        </div>
        <Table
          defaultPageSize={+pageInfo.pageSize}
          columns={columns}
          dataSource={agentTypeList}
          rowKey={(item) => item.seq + item.ver}
          pagination={true}
          onChange={onChangeTableColumns}
          pageOptions={{
            total: +pageInfo.total,
            current: +pageInfo.currentPage,
            pageSize: +pageInfo.pageSize,
          }}
        />
      </div>
    </Wrapper>
  );
};

export default AgentVerManage;