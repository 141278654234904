import { fromJS } from 'immutable';
import { SET_AUTH_LOADING, AUTH_SIGNIN_SUCCESS, AUTH_SIGNIN_RESET } from './constants';

const initialState = fromJS({
  profile: {},
  token: {},
  apiResult: {},
  loading: false
});

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_LOADING:
      return state.set('loading', action.payload.loading);
    case AUTH_SIGNIN_SUCCESS:
      return state
        .set('profile', {
          'EMP_NO': action.payload.empNum,
          'NAME_KOR': action.payload.empNam,
          'DEPT_NAME_KOR': action.payload.orgNam,
          'EMAIL': action.payload.emailAddr,
          'FILE_ID': action.payload.fileId,
          'PHONE_NUMBER': action.payload.cphoneNum,
          'DWP_USER_LEVEL': action.payload.dwpUserLevel,
          'AB_LEVEL': action.payload.abLevel,
          'COMP_CD': action.payload.companyCd,
          'locale': action.payload.locale,
        })
        .set(
          'token', {
          accessToken: action.payload.accessToken,
          refreshToken: action.payload.refreshToken,
          refreshTokenExpirationTime: action.payload.refreshTokenExpirationTime,
        }
        )
        .set(
          'apiResult', {
          ...action.payload
        }
        );
    case AUTH_SIGNIN_RESET:
      return initialState;
    default:
      return state;
  }
};

export default authReducer;