import React from 'react';
import { AssignEnterBox, Text, Input, FormTable } from 'components';
import { DeleteIcon } from 'icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Select } from "antd";
import { useIntl } from "react-intl";

const { Option } = Select;

/**
 * Filename: AdminEmail FilterArea/index.js
 * Description: admin Email Trigger HostArea 
 */



const FilterArea = ({ profile = {},
    onChangeSubject, onClickAddSender, senderValues, onChangeSender,
    onClickDeleteSender, onClickAddSubject, subjectValues, onClickDeleteSubject,
    onClickAddAttatch, attatchValues, onChangeAttatch, onClickDeleteAttatch, requireFields, onChangeInclude }) => {
    const { formatMessage } = useIntl();

    return (
        <AssignEnterBox title="Filter Area" >
            <FormTable>
                <colgroup>
                    <col width='15%' />
                    <col width='35%' />
                    <col width='15%' />
                    <col width='35%' />
                </colgroup>
                <tbody>
                    <tr>
                        <th colSpan={1}>
                            <Text required={true} >Subject</Text>
                        </th>
                        <td colSpan={4}>
                            {subjectValues.map((e, index) =>
                                <div className='input__area'>
                                    <Select
                                        value={e.operator || "Include"}
                                        defaultValue="Include"
                                        onChange={(e) => {
                                            onChangeInclude(e, index)
                                        }}
                                        style={{ width: 120 }}
                                        name='operator'
                                        data-index={index}
                                        placeholder={formatMessage({ id: 'T0688' })} // 선택해주세요
                                    >
                                        <Option value="Include">Include</Option>
                                        <Option value="Not Include">Not Include</Option>
                                    </Select>
                                    <Input
                                        className="admin_trigger_filter_input"
                                        innerRef={requireFields.subjectrList.ref}
                                        name='subject'
                                        value={e.keyWord}
                                        data-index={index}
                                        onChange={onChangeSubject}
                                    />
                                    <PlusCircleOutlined onClick={onClickAddSubject} />

                                    {subjectValues.length > 1 &&
                                        <DeleteIcon
                                            onClick={(e) => onClickDeleteSubject(e, index)}
                                        />
                                    }
                                </div>
                            )}

                        </td>
                    </tr>

                    <tr>
                        <th span={2}>
                            <Text >Sender</Text>
                        </th>
                        <td span={3}>

                            {senderValues.map((e, index) =>
                                <div className='input__area'>
                                    <Input
                                        className="admin_trigger_filter_input"
                                        // innerRef={requireFields.senderList.ref}
                                        name='sender'
                                        value={e.keyWord}
                                        data-index={index}
                                        onChange={onChangeSender}
                                    />
                                    <PlusCircleOutlined onClick={onClickAddSender} />
                                    {senderValues.length > 1 &&
                                        <DeleteIcon
                                            onClick={(e) => onClickDeleteSender(e, index)}
                                        />
                                    }
                                </div>
                            )}
                        </td>

                        <th>
                            <Text >Attatch File Name</Text>
                        </th>
                        <td>
                            {attatchValues.map((e, index) =>
                                <div className='input__area'>
                                    <Input
                                        className="admin_trigger_filter_input"
                                        name='attatch'
                                        value={e.keyWord}
                                        data-index={index}
                                        onChange={onChangeAttatch}
                                    />
                                    <PlusCircleOutlined onClick={onClickAddAttatch} />
                                    {attatchValues.length > 1 &&
                                        <DeleteIcon
                                            onClick={(e) => onClickDeleteAttatch(e, index)}
                                        />
                                    }
                                </div>
                            )}
                        </td>
                    </tr>


                </tbody>
            </FormTable>
        </AssignEnterBox>
    )
};

export default FilterArea;