import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import { Select, Divider } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { ReplyIcon } from "icons";
import moment from "moment";
import queryString from "query-string";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import useFormatMessage from 'hooks/useFormatMessage';
import { setCommunityUrl } from "store/communityUrl/action";
import { 
  setCommunityValue, 
  setCommunitySpread, 
  resetCommunityValue, 
  setCommunityCondition,
  resetCommunityCondition } from 'store/community/action';
import { Table, Button, Input, RangePicker, Text, UserTag, Organization, InputSearch } from "components";
import Wrapper from "./Styled";

const { Option } = Select;

const initialInputValues = {
  title: "",
  regFr: "",
  regTo: "",
  writer: [],
};

const initialPageInfo = {
  sort: "regTimestamp",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

const CommunityList = ({
  location: { search },
  match: { params },
  history,
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const profile = useSelector((state) => state.get("auth").get("profile"));
  const inputValues = useSelector(state => state.get(getUniqueKey('community')).inputValues);
  const boardMaCondition = useSelector(state => state.get(getUniqueKey('community')).boardMaCondition);

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [sortedInfo, setSortedInfo] = useState({});

  const [useColumnList, setUseColumnList] = useState([]);
  const [list, setList] = useState([]);
  
  const [tableLoading, setTableLoading] = useState(false);
  const [isWriterVisible, setIsWriterVisible] = useState(false);
  const [userAuth, setUserAuth] = useState("");

  const pageSize = [20, 50, 70];

  const widthSetting = {
    BC01: 80,
    BC02: '100%',
    BC03: 135,
    BC04: 115,
    BC05: 100,
    BC06: 90,
    BC07: 90,
  };

  const columnsArr = useColumnList
  .map((v) => {
    return {
      key: v.useColumn,
      title: v.useColumnNm,
      width: widthSetting[v.useColumn],
      dataIndex: v.useColumn,
      sortOrder: sortedInfo.columnKey === v.useColumn && sortedInfo.order,
      sorter: v.useColumn === "BC01" ? "" : (a, b) => a.useColumn - b.useColumn,
      render: (text, record, index) => {
        // flag는 글 조회 가능 여부
        let flag = false;
        if (record.secretYn === "N") {
          flag = true;
        } else if (record.secretYn === "Y") {
          if (
            userAuth === "UA06" ||
            userAuth === "UA05" ||
            profile.EMP_NO === record.regId
          ) {
            flag = true;
          } else if (+record.pboardSeq !== 0) {
            flag = record.pRegId === profile.EMP_NO;
          }
        }
        switch (v.useColumn) {
          case "BC01":
            return text;
          case "BC02":
            return (
                <div className="title_section">
                  {
                    record.delYn === "Y"
                    ? <Text style={{color:"#c4c4c4"}}>
                        <FormattedMessage id="T1677" /> {/* 삭제된 게시물 입니다. */}
                      </Text>
                    : 
                      record.secretYn === "Y" ? (
                        <Link
                          to={ flag && `/community/${params.boardId}/${record.seq}/lookup`}
                          onClick={() => onClickGoToSeeLookUp(flag)}
                        >
                          <Text className="table_title">
                            {record.pboardSeq !== 0 && (
                              <ReplyIcon style={{ paddingRight: 5 }} />
                            )}
                            {flag ? `${fmMessage({ id: 'T0875', values: { text: text } })}` //[비밀글] {text}
                              : `${formatMessage({ id: 'T0876' })}`}{/* 비밀글입니다. */}
                          </Text>
                        </Link>
                      ) : (
                        <Link
                        to={ flag && `/community/${params.boardId}/${record.seq}/lookup`}
                        onClick={() => onClickGoToSeeLookUp(flag)}
                        >
                        <Text className="table_title">
                          {record.pboardSeq !== 0 && (
                            <ReplyIcon style={{ paddingRight: 5 }} />
                          )}
                          {text}
                        </Text>
                      </Link>
                    )
                  }
                  &nbsp;
                  {
                    record.delYn === "N"&&record.fileId.length > 0 &&
                    <Text className="comment_cnt">
                      <PaperClipOutlined />
                    </Text>
                  }
                  {
                    record.delYn === "N"&&boardMaCondition.commentYn === "Y" && (
                    <Text className="comment_cnt">
                      [{record.commentcount}]
                    </Text>
                  )}
                </div>
              // </Link>
            ); // 제목
          case "BC03":
            return (
              <div style={{ display: 'flex' }}>
                <UserTag
                  profile={{
                    NAME_KOR: record.regEmpNam,
                    EMP_NO: record.regId,
                    DEPT_NAME_KOR: record.regEmpNam,
                    FILE_ID: record.regFileId,
                  }}
                  contextVisible={true}
                  orgVisible={false}
                >
                  <Text type="context" style={{ fontSize: 12 }}>
                    {text}
                  </Text>
                </UserTag >
              </div>
            ); // 작성자
          case "BC04":
            return text; //사번
          case "BC05":
            return text// 작성일
          case "BC06":
            return <div className="read">{text}</div>; //조회수
          case "BC07":
            return <div className="recm">{text}</div>; //추천수
          default:
            break;
        }
      },
    };
  });


  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    setIsWriterVisible(false);
    dispatch(setCommunityValue('writer', data));
  }

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setIsWriterVisible(false);
  }

  const onClickWriter = () => {
    setIsWriterVisible(true);
  }

  const onChangeTableColumns = (pagination, filters, sorter) => {
    const orderObj = {
      BC01: "",
      BC02: "boardTitle", // 제목
      BC03: "regEmpNam", // 작성자
      BC04: "regId", //사번
      BC05: "regTimestamp", // 작성일
      BC06: "readcount", // 조회수
      BC07: "rcmdcount", // 추천수
    }

    setSortedInfo(sorter)

    let order = sorter.field ? orderObj[sorter.field] : "regTimestamp";

    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",desc"
    } else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    getTableDatas(searchObj);
  };

  const getTableDatas = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    };

    setTableLoading(true);

    const writer = inputValues.writer.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum)
      return tmpArr;
    })

    const result = await fetchAbsolute(
      "post",
      `/board/boardListMulVal?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
      ,
      {
        data: {
          boardId: params.boardId,
          empNo: writer.flat(),
          title: inputValues.title,
          regFr: inputValues.regFr,
          regTo: inputValues.regTo,
          slocale: ""
        }
      }
    );
    setTableLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

    const { totalItemsCount, boardList  } = result.data;

    const listArr = boardList.map((v, index) => {
      const row = {
        /* 조건 땜에 필요한 데이터 넣기 seq, commentcount, secretYn, regEmpNam, regId, pboardSeq */
        seq: v.boardSeq, // 키
        secretYn: v.secretYn,
        commentcount: v.commentcount,
        regEmpNam: v.regEmpNam,
        regId: v.regId,
        pboardSeq: v.pboardSeq,
        fileId: v.fileId,
        regFileId: v.regFileId,
        delYn: v.delYn,
        BC01:
          totalItemsCount -
          (+page.currentPage - 1) * +page.rowPerPage -
          index, // 번호
        BC02: v.boardTitle, // 제목
        BC03: v.regEmpNam, // 작성자
        BC04: v.regId, //사번
        BC05: moment(v.regTimestamp).format("YYYY.MM.DD"), // 작성일
        BC06: v.readcount || 0, // 조회수
        BC07: v.rcmdcount || 0, // 추천수
      };
      if (v.replyLevel >= 1) {
        if (boardList.length > 1) {
          const parentObject = boardList.find((l) => l.boardSeq === v.pboardSeq);
          row.pRegId = parentObject?.regId;
        }
      }
      return { ...row };
    });
    setList([...listArr]);
    setPageInfo({ ...page, total: totalItemsCount });
  };

  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDateRange = ([regFr, regTo]) => {
    dispatch(setCommunitySpread({
      regFr: regFr || '',
      regTo: regTo || ''
    }));
  };

  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDateRange(range);
  };

  const onPressComment = (e) => {
    if (e.key.toUpperCase() === "ENTER") {
      onClickSearchBoard();
    }
  };

  const onSelectOption = (pageSize) => {
    setPageInfo({ ...pageInfo, currentPage: 1, rowPerPage: pageSize });
  };

  const onChangeTitle = (e) => {
    onChangeValues("title", e.target.value);
  }

  const onChangeValues = (label, value) => {
    dispatch(setCommunityValue(label, value))
  };

  // 초기화 버튼 클릭 이벤트
  const onClickReset = () => {
    dispatch(resetCommunityValue());
    setPageInfo({
      ...initialPageInfo
    });
    replaceSearch({ ...initialInputValues });
    setSortedInfo({});
    // getTableDatas();
  };

  // 검색 버튼 클릭 이벤트
  const onClickSearchBoard = () => {
    // useEffect dependency에 search가 있기 때문에 getTableDate함수를 따로 쓸 필요가 없음
    setPageInfo({ ...initialPageInfo });
    replaceSearch({ ...inputValues });
  };

  const onClickGoToSeeLookUp = (flag) => {
    if (!flag) {
      alert(formatMessage({ id: 'T0319' })); //접근 권한이 없습니다
    }
  };

  const replaceSearch = (searchObject) => {
    const searchObj = { ...initialInputValues, ...searchObject };
    Object.keys(searchObj).forEach(key => {

      const data = searchObj[key];

      if (['writer'].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          tmpArr.push(data[i].empNum)
        }
        searchObj[key] = tmpArr;
      }
    })

    const searchString = queryString.stringify(searchObj);

    if ("?" + searchString === search) return;
    dispatch(setCommunityUrl("community", searchString));
    history.push({ search: searchString });
  }

  const getConditions = async () => {
    const result = await fetchAbsolute(
      "get",
      `/board/boardMaInfo?boardId=${params.boardId}`
    );

    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

    setUseColumnList([...result.data.boardUseColumnList]);
    dispatch(setCommunityCondition({ ...result.data.boardMa }));
    // setBoardMaCondition({ ...result.data.boardMa });
  }

  /* 권한 가져오기 */
  useEffect(() => {
    if(!profile.EMP_NO) return;
    setUserAuth(profile.DWP_USER_LEVEL);
    // fetchUserAuth();
    getConditions();
    onClickReset();
  }, [params.boardId]);
  
  useEffect(() => {
    if (userAuth.length === 0 || !search) return;
    // 맨처음 접속할때, page, 페이지사이즈 달라질때마다, 쿼리스트링 달라질때마다 data 불러옴
    getTableDatas();
    //pageInfo.total가 dependency에 들어갈 필요가 있나..?
  }, [search, pageInfo.currentPage, pageInfo.rowPerPage, userAuth]);

  return (
    <Wrapper>
      <div className="board_manage__top_right">
        {
          columnsArr.find(v => v.key === 'BC03') && // writer
          <InputSearch
            onClick={onClickWriter}
            width={200}
            height={32}
            placeholder={formatMessage({ id: "T0026" })} // 사용자를 선택하세요 
            data-label='writer'
            value={inputValues.writer.map(c => c.empNam).join(', ')}
          />
        }
        {
          columnsArr.find(v => v.key === 'BC02') && // title
          <Input
            placeholder={formatMessage({ id: "T0646" })} // 제목을 입력하세요
            style={{ width: 200, height: 32 }}
            value={inputValues.title}
            data-label='title'
            onChange={onChangeTitle}
            onPressEnter={onPressComment}
          />
        }
        {
          columnsArr.find((v) => v.key === "BC05") && (
            <RangePicker
              style={{ width: 420, height: 32 }}
              onCalendarChange={onChangeDates}
              onChange={onChangeDates}
              value={[
                inputValues.regFr && inputValues.regFr.length > 0
                  ? moment(inputValues.regFr)
                  : undefined,
                inputValues.regTo && inputValues.regTo.length > 0
                  ? moment(inputValues.regTo)
                  : undefined,
              ]}
              defaultValue={[undefined, undefined]}
            />
          )
        }
        <Button
          type="default"
          width="100"
          height="32"
          onClick={onClickSearchBoard}
        >
          <FormattedMessage id="T0039" />{/* 검색 */}
        </Button>
        <Button type="default" width="100" height="32" onClick={onClickReset}>
          <FormattedMessage id="T0037" />{/* 초기화 */}
        </Button>
      </div>
      <Divider />
      <div className="board_manage__top">
        <div style={{ alignSelf: "flex-end" }}>
          <span>
            {formatMessage({ id: "T0036" })} &emsp; {/* 페이지당 행 */}
            <Select
              name="pagination"
              defaultValue={pageSize[0]}
              style={{ width: 89, height: 32, fontSize: 12 }}
              onSelect={onSelectOption}
              value={+pageInfo.rowPerPage}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                </Option>
              ))}
            </Select>
          </span>
        </div>
        <div className="bottom_buttons__container">
          {(boardMaCondition.writeYn === "Y" ||
            userAuth === "UA06" ||
            userAuth === "UA05") && (
              <Link
                to={`/community/${params.boardId}/enter`}
              >
                <Button type="primary" className="bottom_buttons">
                  {formatMessage({ id: "T0093" })}{/* 작성하기 */}
                </Button>
              </Link>
            )}
        </div>
      </div>
      <Table
        rowKey={(item) => item.seq}
        columns={columnsArr}
        dataSource={list}
        pagination={true}
        pageSizeVisible={false}
        loading={tableLoading}
        //defaultPageSize={+inputValues.pageSize}
        onChange={onChangeTableColumns}
        pageOptions={{
          total: +pageInfo.total,
          current: +pageInfo.currentPage,
          pageSize: +pageInfo.rowPerPage,
        }}
        locale={{ emptyText: `${formatMessage({ id: "T0877" })}` }} // 게시글이 없습니다.
      />
      <Organization
        visible={isWriterVisible}
        companyCd={profile.COMP_CD}
        title={formatMessage({ id: "T0018" })} // 작성자
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues['writer']}
      />
    </Wrapper>
  );
};
export default withRouter(CommunityList);