import React, { useState } from "react";
import { Image } from 'components';
import academyImage from "./AcademyImg/main.png";
import MasterLine from "./AcademyImg/master_line.png";
import KnightLine from "./AcademyImg/knight_line.png";
import PadawanLine from "./AcademyImg/padawan_line.png";
import YounglingLine from "./AcademyImg/youngling_line.png";
import jediMaster from "./AcademyImg/section2_jedimaster_character.png";
import jediKnight from "./AcademyImg/section2_jediKnight.png";
import padawan from "./AcademyImg/section2_padawan.png";
import youngling from "./AcademyImg/section2_youngling.png";
import jedimasterPyramid from "./AcademyImg/Jedi Masters_Pyramid.png";
import jediknightPyramid from "./AcademyImg/Jedi Knight_Pyramid.png";
import padawanPyramid from "./AcademyImg/Padawan_Pyramid.png";
import younglingPyramid from "./AcademyImg/Pyramid_Youngling.png";
import section3 from "./AcademyImg/section3.png";
import section5 from "./AcademyImg/section5.png";
import returnToTop from "./AcademyImg/Return to top.png";
import section6 from "./AcademyImg/section6.png";
import Benefit_Image_1 from "./AcademyImg/Benefit_Image_1.png";
import Benefit_Image_2 from "./AcademyImg/Benefit_Image_2.png";
import Benefit_Image_3 from "./AcademyImg/Benefit_Image_3.png";
import { Button, Text } from "components";
import { useIntl } from "react-intl";

const JediIntro = () => {
  const { formatMessage } = useIntl();

  const [linkpage, setLinkChange] = useState("/jediacademy/master");
  const [charcterTitle, setCharacterTitle] = useState("");
  const [charcterText, setCharacterText] = useState("");
  const [src, setSrc] = useState("");
  const [lineSrc, setLineSrc] = useState("");

  const onClickReturntoTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const onClickJediMaster = () => {
    setSrc(jediMaster);
    setLineSrc(MasterLine);
    document.getElementById("jediknight").style.opacity = 0.5;
    document.getElementById("padawan").style.opacity = 0.5;
    document.getElementById("youngling").style.opacity = 0.5;
    document.getElementById("jedimaster").style.opacity = 1;

    document.getElementById("section2_character_line").style.left = "39%";
    document.getElementById("section2_character_img").style.top = "10%";
    setCharacterTitle("Jedi Master");
    setCharacterText(`${formatMessage({ id: "T0887" })}`);
    // Jedi Master는 아무나 될 수 없습니다.
    // Jedi Master들은 뛰어난 활동을 보이는 Jedi Knight를 
    // 선정하여 Jedi Master가 될 수 있는 자격을 부여하게 됩니다. 
    // Jedi Master는 RPA 전문가로써 사내강사 및 RPA 컨설턴트로 활동을 하며,
    // RPA로 모든 과제를 구현 할 수 있을 만큼의 역량을 가지고 있습니다
    setLinkChange("/jediacademy/master");
    document.getElementById("section2_character_line").style.top = "20%";
  };

  const onClickJediKnight = () => {
    setSrc(jediKnight);
    setLineSrc(KnightLine);
    document.getElementById("jedimaster").style.opacity = 0.5;
    document.getElementById("padawan").style.opacity = 0.5;
    document.getElementById("youngling").style.opacity = 0.5;
    document.getElementById("jediknight").style.opacity = 1;

    document.getElementById("section2_character_img").style.top = "12%";
    document.getElementById("section2_character_img").style.left = "64.5%";
    document.getElementById("section2_character_img").style.clipPath =
      "inset(0% 0% 50% 0%)";
    setCharacterTitle("Jedi Knight");
    document.getElementById("section2_character_line").style.top = "39%";

    setCharacterText(`${formatMessage({ id: "T0888" })}`);
    // Jedi Knight은 RPA 전문가 양성 과정을 이수한 RPA 고수입니다. 
    // 실력이 출중한 Padawan들은 전문가 양성 과정을 신청하여 Jedi Knight로 승급 할 수 있습니다.
    // Jedi Knight는 RPA를 사용한 과제 개발의 경험이 많아 과제의 프로세스를 
    // 정의하는데 능숙하고 고급 커맨드를 사용한 자동화 업무 개발이 가능합니다.
    setLinkChange("/jediacademy/jediknight");
  };
  const onClickPadawan = () => {
    setSrc(padawan);
    setLineSrc(PadawanLine);
    document.getElementById("jedimaster").style.opacity = 0.5;
    document.getElementById("jediknight").style.opacity = 0.5;
    document.getElementById("youngling").style.opacity = 0.5;
    document.getElementById("padawan").style.opacity = 1;

    document.getElementById("section2_character_img").style.left = "66%";
    document.getElementById("section2_character_img").style.top = "0%";
    setCharacterTitle("Padawan");
    document.getElementById("section2_character_line").style.top = "33%";
    setCharacterText(`${formatMessage({ id: "T0889" })}`);
    // Padawan은 RPA 중급 교육을 이수한 구성원들에게 주어지는 계급으로,
    // RPA를 이해하고 주요 커맨드들을 사용하여 여러 방법을 시도하고,
    // 여러가지 어플리케이션을 활용한 심화된 자동화 업무를 개발할 수 있는 역량을 가지고 있습니다.
    setLinkChange("/jediacademy/padawan");
  };
  const onClickYoungling = () => {
    setSrc(youngling);
    setLineSrc(YounglingLine);
    document.getElementById("section2_character_img").src = youngling;
    document.getElementById("jedimaster").style.opacity = 0.5;
    document.getElementById("jediknight").style.opacity = 0.5;
    document.getElementById("padawan").style.opacity = 0.5;
    document.getElementById("youngling").style.opacity = 1;

    document.getElementById("section2_character_img").style.top = "0%";
    document.getElementById("section2_character_img").style.left = "66%";
    document.getElementById("section2_character_img").style.clipPath =
      "inset(0% 0% 45% 0%)";
    setCharacterTitle("Youngling");
    setCharacterText(`${formatMessage({ id: "T0890" })}`);
    // Youngling은  RPA 초급 교육을 이수한 Dreago의 신규 구성원 입니다. 
    // RPA 초급 교육을 희망하는 구성원은 누구나 신청하여 교육을 받을 수 있으며,
    // 초급 교육 완료 시 구성원은 Automation Anywhere 및 Dreago에 대한 기본적인
    // 정보와 간단한 Command를 활용하여 자동화 업무를 구현 할 수 있습니다.
    setLinkChange("/jediacademy/youngling");
    document.getElementById("section2_character_line").style.top = "38%";
  };

  React.useEffect(() => {
    onClickJediMaster();
  }, []);

  return (
    <div>
      <div className="academy_section">
        <div>
          <Image
            alt=""
            className="display_img"
            src={academyImage}
            style={{ width: "100%" }}
          />
        </div>
        <div>
          <Text
            style={{
              position: "absolute",
              color: "white",
              transform: "translate(-50%)",
              top: "30%",
              letterSpacing: -1,
              lineHeight: "20px",
              fontSize: "16px",
            }}
          >
            {formatMessage({ id: "T0891" })
              .split("\n")
              .map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* "서기 2021년,

 파도같이 밀려오는 악성업무 군단의 악행에 희생되어



해가 한참 지고난 뒤에도 사무실에서 고생하는



구성원들의 눈가에는 검은 그림자가 드리우기 시작했다.

더 이상 참을 수 없었던 구성원들은

RPA의 힘을 계승하고 칼퇴신의 가호를 받아

악성 업무 군단에 맞서기 시작하니,

이것이 Dreago Academy 전설의 시작이었다"

-  '업무혁신록' 中 */}
          </Text>
        </div>
        <Button
          type="primary"
          style={{
            width: 400,
            height: 40,
            position: "absolute",
            top: 760,
            left: "35.5%",
          }}
          onClick={() => window.open("http://skhu.skhynix.com/")}
        >
          <Text className="academy_section4_text">
            {formatMessage({ id: "T0109" })}{/* Dreago Academy의 일원이 되고 싶습니다. */}
          </Text>
        </Button>
      </div>

      <div className="academy_section2">
        <div style={{ position: "relative", flex: 1.7 }}>
          <Text
            className="section_title"
            style={{
              top: "10%",
              transform: "translate(40%)",
            }}
          >
            {formatMessage({ id: "T0892" })}{/* Dreago Academy는 누구에게나 열려 있습니다. */}
          </Text>
          <Text
            className="academy_section2_content"
            style={{
              top: "30%",
              left: 160,
              width: 600,
            }}
          >
            {formatMessage({ id: "T0893" })
              .split("\n")
              .map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* "RPA에 관심 있는 구성원 누구나 초급 교육을 신청하여 Dreago Academy의
일원이 될 수 있습니다. 개발 역량에 따라 총 4개의 Jedi Level이
부여되며, 심화 교육 및 Dreago Portal 활동을 통해 Level 승급이
가능합니다.
RPA로 구현이 가능한 업무는 구성원 여러분의 일상에 많이 숨어있습니다.
지금 Dreago Academy의 일원이 되어 비효율적인 업무를 정의하고, DIY
개발을 통해 업무의 효율성을 올리고 퇴근시간을 앞당기세요!" */}
          </Text>
        </div>
        <div style={{ position: "relative", flex: 1 }}>
          <Image
            alt=""
            style={{ width: "70%", position: "absolute", top: 20 }}
            src={section3}
          />
        </div>
      </div>
      <div className="title_box">
        <Text
          style={{
            color: "white",
            fontSize: 24,
            fontWeight: "bold",
            paddingLeft: 30,
          }}
        >
          {formatMessage({ id: "T0894" })}{/* Dreago Academy는 개발 역량에 따른 계급이 존재합니다 */}
        </Text>
      </div>
      <div className="academy_pyramid_section">
        <div className="academy_pyramid">
          <a onClick={onClickJediMaster} href="#!">
            <Image
              alt=""
              id="jedimaster"
              className="pyramid_img"
              src={jedimasterPyramid}
              style={{
                position: "absolute",
                top: "12%",
                left: "14.7%",
                opacity: 0.5,
                zIndex: 1,
              }}
            />
            <Text
              style={{
                position: "absolute",
                top: "17%",
                left: "15.9%",
                color: "white",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "center",
                zIndex: 2000,
              }}
            >
              Jedi Master
              <br />
              (Lv5)
            </Text>
          </a>
          <a onClick={onClickJediKnight} href="#!">
            <Image
              alt=""
              id="jediknight"
              className="pyramid_img"
              src={jediknightPyramid}
              style={{
                position: "absolute",
                top: "31%",
                left: "10.3%",
                opacity: 0.5,
                zIndex: 1,
              }}
            />
            <Text
              style={{
                position: "absolute",
                top: "35%",
                left: "15.9%",
                color: "white",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "center",
                zIndex: 2000,
              }}
            >
              Jedi Knight
              <br />
              (Lv4)
            </Text>
          </a>
          <a onClick={onClickPadawan} href="#!">
            <Image
              alt=""
              id="padawan"
              className="pyramid_img"
              src={padawanPyramid}
              style={{
                position: "absolute",
                top: "50%",
                left: "5.4%",
                opacity: 0.5,
                zIndex: 1,
              }}
            />
            <Text
              style={{
                position: "absolute",
                top: "54%",
                left: "16.7%",
                color: "white",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "center",
                zIndex: 2000,
              }}
            >
              PadaWan
              <br />
              (Lv3)
            </Text>
          </a>
          <a onClick={onClickYoungling} href="#!">
            <Image
              alt=""
              className="pyramid_img"
              id="youngling"
              src={younglingPyramid}
              style={{
                position: "absolute",
                top: "69%",
                left: "0.5%",
                opacity: 0.5,
                zIndex: 1,
              }}
            />
            <Text
              style={{
                position: "absolute",
                top: "73%",
                left: "16.4%",
                color: "white",
                fontWeight: "bold",
                fontSize: "23px",
                textAlign: "center",
                zIndex: 2000,
              }}
            >
              Youngling
              <br />
              (Lv2)
            </Text>
          </a>
        </div>

        <div className="academy_pyramid_character">
          <a href="#!">
            {src === "" ? (
              <div>
                <Text
                  id="section2_character_img"
                  style={{
                    color: "white",
                    fontSize: "23px",
                    top: "300px",
                    left: "",
                    clipPath: "",
                    trasform: "translate(50%)",
                    position: "absolute",
                    textAlign: "center",
                  }}
                >
                  {formatMessage({ id: "T0895" })}{/* ← Click 하여 확인 */}
                </Text>
                <Image
                  alt=""
                  style={{
                    position: "absolute",
                    top: "1%",
                    left: "1%",
                  }}
                  id="section2_character_line"
                />
              </div>
            ) : (
              lineSrc !== "" && (
                <div>
                  <img
                    alt=""
                    id="section2_character_line"
                    src={lineSrc}
                    style={{
                      position: "absolute",
                      top: "21%",
                      left: "42%",
                    }}
                  />
                  <img
                    alt=""
                    id="section2_character_img"
                    className="pyramid_character"
                    src={src}
                    style={{
                      position: "absolute",
                      top: "10%",
                      left: "66%",
                      clipPath: "inset(0% 0% 45% 0%)",
                    }}
                  />
                </div>
              )
            )}
          </a>
          <div
            style={{
              position: "absolute",
              top: 370,
              right: 200,
            }}
          >
            &nbsp;
            <Text id="character_title" className="academy_section4_title">
              {charcterTitle}
            </Text>
          </div>

          <div
            style={{
              position: "absolute",
              width: "50%",
              textAlign: "center",
              top: 430,
              transform: "translate(-25%)",
            }}
          >
            <Text id="character_text" className="academy_section4_text">
              {charcterText}
            </Text>
          </div>
        </div>
      </div>

      <div className="academy_section4">
        <div>
          <Text
            className="section_title"
            style={{
              top: 10,
              transform: "translate(55%)",
            }}
          >
            {formatMessage({ id: "T0896" })}{/* Jedi는 Dreago Academy에서 다음과 같은 특권을 누릴 수 있습니다 */}
          </Text>
        </div>
        <div className="academy_section4_child">
          <div>
            <Image
              alt=""
              className=""
              src={Benefit_Image_1}
              style={{ position: "absolute", right: 40 }}
            />
          </div>
          <div style={{ position: "absolute", top: 350, left: 90 }}>
            <Text className="academy_section4_title">
              {formatMessage({ id: "T0897" })}{/* 개발 완료된 과제 공유 */}
            </Text>
          </div>

          <div style={{ position: "absolute", top: 420 }}>
            <Text className="academy_section4_text">
              {formatMessage({ id: "T0898" })
                .split("\n")
                .map((data, index) => {
                  return (
                    <span key={index}>
                      {data}
                      <br />
                    </span>
                  );
                })}{/* Jedi 계급에 상관없이 Dreago Academy 소속
누구나 자신이 개발한 과제를 공유할 수 있습니다.
Jedi들은 공유된 과제를 통해 RPA개발방식과
팁에 대해 추가적으로 학습이 가능하며,
공유된 과제는 자신의 용도에 맞게 간단히
수정하여 사용할 수 있습니다. */}
            </Text>
          </div>
        </div>
        <div className="academy_section4_child">
          <Image
            alt=""
            className=""
            src={Benefit_Image_2}
            style={{ position: "absolute", top: 30, left: -30 }}
          />

          <div style={{ position: "absolute", top: 350, left: 120 }}>
            &nbsp;
            <Text className="academy_section4_title">
              {formatMessage({ id: "T1074" })}{/* 과제 개발 의뢰 */}
            </Text>
          </div>
          <div style={{ position: "absolute", top: 420 }}>
            &nbsp;
            <Text className="academy_section4_text">
              {formatMessage({ id: "T0899" })}{/* 자동화 하고자 하는 업무의 난이도가 높을 시,
집합소에서 동료 Jedi 혹은 상급 Jedi에게 직접
연락을 취해 도움을 요청할 수 있습니다. */}
            </Text>
          </div>
        </div>
        <div className="academy_section4_child">
          <Image
            alt=""
            className=""
            src={Benefit_Image_3}
            style={{ position: "absolute", right: 50 }}
          />
          <div style={{ position: "absolute", top: 350, left: 120 }}>
            <Text className="academy_section4_title">
              &nbsp;
              {formatMessage({ id: "T0900" })}{/* 과제 공유 보상 */}
            </Text>
          </div>
          <div style={{ position: "absolute", top: 420 }}>
            <Text className="academy_section4_text">
              {formatMessage({ id: "T0901" })}{/* Dreago Academy에서는 과제 공유에 대한 포인트
제도가 운영됩니다. 과제 최초 공유 시 포인트가
지급되며, 그 과제를 다른 Jedi들이 사용하거나,
'좋아요'를 누를시 추가적으로 포인트가 지급되며,
누적된 포인트로 Item Shop에서 경품을 구매
할 수 있습니다. */}
            </Text>
          </div>
        </div>
      </div>
      <div className="academy_section5">
        <div style={{ flex: 1, paddingLeft: 180, position: "relative" }}>
          <Text
            className="section_title"
            style={{
              top: "10%",
            }}
          >
            {formatMessage({ id: "T0902" })}{/* Jedi는 Dreago Academy의 5계명을 준수합니다 */}
          </Text>
          <Text
            style={{
              color: "white",
              position: "absolute",
              top: "35%",
              fontSize: 16,
              lineHeight: "50px",
            }}
          >
            {formatMessage({ id: "T0903" })
              .split("\\n")
              .map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 1. 비효율 업무에 시달리는 구성원은 우리가 앞장서서 구한다\n2. 오늘 개발에 투자한 시간은 내일 나의 퇴근시간을 앞당긴다
\n3. 좋은것을 나누면 내게 배로 돌아온다\n
4. 모든 구성원이 사용하는 데이터 저장소는 접근하지 않는다\n
5. 무엇이든 '자동화가 가능한가?' 라는 질문을 해라 */}
          </Text>
        </div>
        <div style={{ flex: 1, position: "relative" }}>
          <Image
            alt=""
            className=""
            src={section5}
            style={{ position: "absolute", left: 30 }}
          />
        </div>
      </div>

      <div className="sord_section">
        <div>
          <Text
            style={{
              color: "white",
              textAlign: "center",
              fontSize: 20,
            }}
          >
            {formatMessage({ id: "T0904" })
              .split("\n")
              .map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 지금 이 순간에도
 악성업무의 파도는 밀려오고 있으며,
당신 앞의 광선검은 주인을 기다립니다

지켜만 보실 생각이십니까? */}
          </Text>
        </div>
        <div>
          <Image
            alt=""
            style={{
              position: "absolute",
              top: 90,
              transform: "translate(-50%)",
            }}
            className=""
            src={section6}
          />
        </div>

        <Button
          type="primary"
          style={{
            width: 400,
            height: 40,
            position: "absolute",
            top: 380,
            left: "36.5%",
          }}
          onClick={() => window.open("http://skhu.skhynix.com/")}
        >
          <Text className="academy_section4_text">
            {formatMessage({ id: "T0109" })}{/* Dreago Academy의 일원이 되고 싶습니다. */}
          </Text>
        </Button>
        {/* <div style={{textAlign:"center"}}>
          <a onClick={onClickReturntoTop} href="#!">
            <Image
              alt=""
              className="returnToTop"
              src={returnToTop}
              style={{
                marginTop: "420px",
                marginBottom: "120px",
                zIndex: 200,
              }}
            />
            <Text className="academy_return_to_top">
              {formatMessage({ id: "T0905" })}
            </Text>
          </a>
        </div> */}

        <div className="section5">
          <a onClick={onClickReturntoTop} href="#!">
            <Image alt='go to top' src={returnToTop} />
            <Text className="academy_return_to_top">{formatMessage({ id: 'T0905' })}</Text>{/* 위로가기 */}
          </a>
        </div>
      </div>
    </div>
  );
};

export default JediIntro;
