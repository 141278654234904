import React from 'react';
import { Select } from 'antd';
import { Text, Button, InputSearch } from 'components';
import RealtimeTable from './RealtimeTable';
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;

/**
 * Filename: MonitoringRealtime/Disconnected.js
 * Description: 모니터링 -> 실시간 모니터링 컴포넌트 -> Disconnected 탭 컴포넌트
 */


const Disconnected = ({ 
    crInfo, 
    selectCr, 
    onChangeCrSelect, 
    disconnectedData, 
    devicePool, 
    onClickDevicePoolButton, 
    onClickSearchButton, 
    onSelectPageSize, 
    tableInfo, 
    setTableInfo, 
    onClickResetButton }) => {
    const { formatMessage } = useIntl();

    const searchPlaceholder = formatMessage({ id: "T0039" });// 검색 

    return (
        <div className='realtime_box'>
            <div className='realtime__top_menu'>
                <div className='top_menu__box'>
                    <Text className='top_menu__text'><FormattedMessage id="T1036" /></Text>{/* CR */}
                    <Select
                        size="default"
                        placeholder={formatMessage({ id: 'T0391' })} // 전체
                        defaultValue=""
                        style={{
                            width: 200,
                            height: 32
                        }}
                        value={selectCr.crCd}
                        onChange={onChangeCrSelect}
                    >
                        {crInfo.map(v => (
                            <Option value={v.crCd} key={v.crCd}>
                                {/* {v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + " (운영)" : v.crTypeDesc + " (개발)"} */}
                                {v.crDesc}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='top_menu__box'>
                    <Text className='top_menu__text'><FormattedMessage id="T1060" /></Text>{/* Device Pool */}
                    <InputSearch
                        placeholder={searchPlaceholder}
                        width={200}
                        height={32}
                        value={devicePool.map(d => d.dpNm).join(', ')}
                        onClick={onClickDevicePoolButton}
                    />
                </div>
                <div className="btn__section">
                    <Button className='top_menu__button' type='primary' onClick={onClickSearchButton}><FormattedMessage id="T0039" /></Button>{/* 검색 */}
                    <Button className='top_menu__button' onClick={onClickResetButton}><FormattedMessage id="T0037" /></Button>{/* 초기화 */}
                </div>
            </div>
            <div className='realtime__content_box'>
                <RealtimeTable
                    dataSource={disconnectedData.data}
                    rowKey='hostName'
                    total={+disconnectedData.total}
                    currentPage={+tableInfo.currentPage}
                    rowPerPage={+tableInfo.rowPerPage}
                    onSelectPageSize={onSelectPageSize}
                    tableInfo={tableInfo}
                    setTableInfo={setTableInfo}
                />
            </div>
        </div>
    );
}

export default Disconnected;