import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Wrapper from './Styled';
import Container from 'containers/Container';
import { getUniqueKey } from "utils/Str";
import { useIntl } from 'react-intl';
import { Text } from 'components';
import { Tabs, Tooltip } from 'antd';
import LookupBasic from './Basic';
import LookupEnvironment from './Environment';
import LookupProdStatus from './ProdStatus';
import AssginLookupHistory from './History';

const { TabPane } = Tabs;

/**
 * Filename: AssignLookupBody.js
 * Description: 과제 조회 페이지 하단에 탭 부분
 *              Tab의 Header와 Body를 분리하여 Header에서 넘겨주는 activeTab 값을 받아와 그 값에 해당하는 화면을 보여줌
 * TabKey: basic   - 기본정보 탭
 *         env     - 개발환경 탭
 *         status  - 운영현황 탭
 *         history - 히스토리 탭 
 */

const AssignLookupBody = ({
    match: { params },
    history
}) => {
    const { formatMessage } = useIntl();

    const assignLookup = useSelector(state => state.get(getUniqueKey('assignLookup')));

    const [activeTab, setActiveTab] = useState(params.tabValue || 'basic');

    const [envVisible, setEnvVisible] = useState(false);
    const [statusVisible, setStatusVisible] = useState(false);

    const envTimeout = useRef(null);
    const statusTimeout = useRef(null);

    const onChangeTabs = (tab) => {
        const { statusCd } = assignLookup.taskData;
        if (tab === "env" && !["T20", "T30", "T40"].includes(statusCd)) {
            clearTimeout(envTimeout.current);
            setEnvVisible(true);
            envTimeout.current = setTimeout(() => {
                setEnvVisible(false);
            }, 3000);
            return;
        } else if (tab === "status" && statusCd !== "T40") {
            clearTimeout(statusTimeout.current);
            setStatusVisible(true);
            statusTimeout.current = setTimeout(() => {
                setStatusVisible(false);
            }, 3000);
            return;
        }
        history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/${tab}`);
        setActiveTab(tab);
    };

    return (
        <Wrapper>
            <Container style={{ marginTop: 40 }}>
                <Tabs
                    onChange={onChangeTabs}
                    activeKey={activeTab}
                >
                    <TabPane tab={formatMessage({ id: 'T0480' })} key='basic'>{/* 기본정보 */}
                        {
                            activeTab === 'basic' &&
                            <LookupBasic setActiveTab={setActiveTab} />
                        }
                    </TabPane>
                    <TabPane
                        tab={
                            <Tooltip
                                title={formatMessage({ id: 'T0484' })} // 접근할 수 없습니다.
                                open={envVisible}
                                trigger={[]}
                            >
                                <Text className={!["T20", "T30", "T40"].includes(assignLookup.taskData?.statusCd) ? 'assign_lookup__disabled_tab' : ''}>
                                    {formatMessage({ id: 'T0481' })}
                                </Text>{/* 개발환경 */}
                            </Tooltip>
                        }
                        key="env"
                    >
                        {
                            activeTab === 'env' &&
                            <LookupEnvironment />
                        }
                    </TabPane>
                    <TabPane
                        tab={
                            <Tooltip
                                title={formatMessage({ id: 'T0484' })} //접근할 수 없습니다.
                                open={statusVisible}
                                trigger={[]}
                            >
                                <Text className={assignLookup.taskData?.statusCd !== "T40" ? 'assign_lookup__disabled_tab' : ''}>
                                    {formatMessage({ id: 'T0482' })}{/* 운영현황 */}
                                </Text>
                            </Tooltip>
                        }
                        key="status"
                    >
                        {
                            activeTab === 'status' &&
                            <LookupProdStatus />
                        }
                    </TabPane>
                    <TabPane tab={formatMessage({ id: 'T0483' })} key='history'>{/* 히스토리 */}
                        {
                            activeTab === 'history' &&
                            <AssginLookupHistory />
                        }
                    </TabPane>
                </Tabs>
            </Container>
        </Wrapper>
    )
}

export default withRouter(AssignLookupBody);