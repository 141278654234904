import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.table`
    width: 100%;
    ${props => props.size === 'md' && `
        tr {
            height: 70px;
        }
        th, td {
            padding: 10px 17px;
        }
    `}
    ${props => props.disabled && `
    th,tr,td {
        border: 1px solid #d0d1d6;
    }
    th > span,
    td > span,
    td > div > span {
        color: #d0d1d6;
    }
    `}
    ${props => props.size === 'sm' && `
        tr {
            height: 50px;
        }
        th, td {
            padding: 5px 17px;
        }
        th > span,
        td > span {
            font-size: 12px;
        }
    `}
    th {
        background: #f6f6f6;
        font-weight: normal;
    }
    tr {
        border-bottom: 1px solid #cecece;
    }
    ${props => props.pointBorder && `   
        // 우리카드
        // tr:first-of-type {
        //     border-top: 2px solid #0067ac;
        // }
        tr:first-of-type {
            border-top: 2px solid #de3d0f;
        }
        tr:last-of-type {
            border-bottom: 2px solid #888888;
        }
    `}
    ${props => props.border && `
        tr:first-of-type {
            border-top: 2px solid #888888;
        }
        tr:last-of-type {
            border-bottom: 2px solid #888888;
        }
    `}
`;

const FormTable = ({ pointBorder = true, border = false, size = 'md', children, ...args }) => {
    return (
        <Wrapper pointBorder={pointBorder} border={border} size={size} {...args}>
            {children}
        </Wrapper>
    );
};

export default FormTable;