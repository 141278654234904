import { SET_ITERNAL_SYSTEM_VALUE, SET_ITERNAL_SYSTEM_SPREAD, RESET_ITERNAL_SYSTEM_VALUE } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const iternalSystemInitialState = {
    inputValues: {
        systemCd: [],
        category: [],
        systemName: '',
        systemTask: [],
        systemUser: []
    }, 
    pageInfo: {
        total: 0,
        pageSize: 20,
        currentPage: 1,
    }, 
};

const adminIternalSystem = (state = iternalSystemInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_ITERNAL_SYSTEM_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_ITERNAL_SYSTEM_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_ITERNAL_SYSTEM_VALUE: {
            const inputValues = {
                ...iternalSystemInitialState.inputValues
            };
            return {
                ...state,
                inputValues
            }
        } 
        default:
            return state;

    }
}

export default adminIternalSystem;
