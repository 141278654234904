import { SET_CR_INFO } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const crInfoInitialState = [];

const crInfo = (state = crInfoInitialState, action) => {
    switch(action.type) {
        case SET_CR_INFO:
            return [...action.data];
        default: 
            return state;
        
    };
};
   
export default crInfo;