import React, { useState, useEffect } from "react";
import fetchAbsolute from 'utils/fetchAbsolute';
import { Image } from 'components';
import Wrapper from "../Styled";
import { Avatar as AntAvatar } from 'antd';
import { useIntl } from "react-intl";

const UserIcon = ({ profile = {} }) => {
  const [mgrList, setMgrList] = useState([]);
  const { formatMessage } = useIntl();

  useEffect(() => {
    const getMgrEmpNoList = async () => {
      /* 사번에 있는 파일 id */
      const result = await fetchAbsolute(
        'get',
        `/main/manager/getMgrEmpNoList`
      );
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

      for (let i = 0; i < result.data.length; i++) {
        if (result.data[i]) {
          const fileResult = await fetchAbsolute('get', `/file/listFile/${result.data[i]}`);
          if (fileResult.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
          } else {
            const tmpArr = mgrList;
            /* fileId가 null 인 경우 */
            if (fileResult.data[0].fileId === 'null') {
              tmpArr.push('');
            } else {
              tmpArr.push(fileResult.data[0]?.link);
            }
            setMgrList([...tmpArr])
          }
        }
      }
    }
    getMgrEmpNoList();
  }, []);

  if (Object.keys(profile).length === 0) {
    return null;
  } else {
    return (
      <Wrapper>
        <div>
          <AntAvatar.Group>
            {
              mgrList.map((link, index) => {
                return (
                  <Image type='avatar' size={25} src={link} key={index} />
                )
              })
            }
          </AntAvatar.Group>
        </div>
      </Wrapper>
    );
  }
};
export default UserIcon;