import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { useIntl, FormattedMessage } from "react-intl";
import {
  PageTemplate,
  Header,
  Footer,
  Table,
  Input,
  Button,
  RangePicker,
  Text,
  LogModal,
  Modal,
  RecordDataModal,
  RealtimeModal,
  FormTable,
  Collapse,
  RedoModal
} from "components";
import Wrapper from "./Styled";
import moment from "moment";
import fetchAbsolute from "utils/fetchAbsolute";
import { Tooltip, Select, Input as AntInput, Collapse as AntCollapse, Row, Col } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import {
  ProcessingIcon,
  SuccessIcon,
  FailedIcon,
  MonitoringIcon,
  LogIcon,
  RestartIcon,
  LookCloserIcon,
  PopupWaitIcon,
  RunningTaskRunAgainIcon,
  PauseRunningTaskIcon,
  StopRunningTaskIcon,
  RetryBizIcon,
  PauseForResultIcon,
} from "icons";
import { SyncOutlined } from "@ant-design/icons";

const { Option } = Select;
const { TextArea } = AntInput;
const { Panel } = AntCollapse;

/**
 * Filename: AdminWorkItem/index.js
 * cr별 workitem 관리
 * 관리자 > Workitem 통합 관리
 */

const initialInputValues = {
  cr: "",
  processId: "",
  regFr: "",
  regTo: "",
};

const initialPageInfo = {
  total: 0,
  pageSize: 20,
  currentPage: 1,
};

const initialPoolPageInfo = {
  sort: "crCode",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

const AdminWorkItem = () => {
  const { formatMessage } = useIntl();

  const refreshTimeout = useRef(null);
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const assignLookup = useSelector((state) => state.get(getUniqueKey("assignLookup")));
  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const searchFail = formatMessage({ id: "T0189" }); // 검색하는 데 실패하였습니다.
  const recordUpdateErr = formatMessage({ id: "T0191" }); // 레코드 데이터를 수정 하는 중 오류가 발생하였습니다.
  const scheduleReSuccess = formatMessage({ id: "T0192" }); // 스케줄 재실행이 완료되었습니다.
  const scheduleReFail = formatMessage({ id: "T0193" }); //스케줄 재실행에 실패하였습니다.

  const [spinReload, setSpinReload] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [recordDataVisible, setRecordDataVisible] = useState(false);
  const [redoVisible, setRedoVisible] = useState(false);
  const [logCrEnv, setLogCrEnv] = useState("");
  const [workItemList, setWorkItemList] = useState([]);
  const [preRecordData,setPreRecordData] = useState({});
  const [recordData, setRecordData] = useState([]);
  const [devicePool, setDevicePool] = useState([]);
  const [rowInfo, setRowInfo] = useState({});
  const [replayVisible, setReplayVisible] = useState(false);
  const [logVisible, setLogVisible] = useState(false);
  const [restoreVisible, setRestoreVisible] = useState(false);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [poolPageInfo, setPoolPageInfo] = useState({ ...initialPoolPageInfo });
  const [replayData, setReplayData] = useState({
    taskId: "",
    workItemId: "",
    cr: "",
  });

  const onChangeCollapse = () => { setOpenCollapse((openCollapse) => !openCollapse); };
  const onOkLog = () => setLogVisible(false);
  const onCancelLog = () => setLogVisible(false);
  const onCancelRedo = () => setRedoVisible(false);
  const onCancelRestore = () => setRestoreVisible(false);
  const onCancelRecordModal = () => setRecordDataVisible(false);

  /* 이 아이콘만 tooltip이 사라지지 않아서 */
  const onVisibleChange = (visible, index) => {
    const tmp = [...tooltipVisible];
    tmp[index] = visible;
    setTooltipVisible([...tmp]);
    clearTimeout(refreshTimeout.current);
    refreshTimeout.current = setTimeout(() => {
      tmp[index] = !visible;
      setTooltipVisible([...tmp]);
    }, 800);
  };

  const onClickRecordData = (record) => {
    const arr = Object.entries(record.recordDataMap).map((v) => ({
      key: v[0],
      value: v[1],
    }));
    setRecordData([...arr]);
    setPreRecordData({...record.recordDataMap})
    setRecordDataVisible(true);
  };

  const onOkRestore = () => {
    setRestoreVisible(false);
    setRedoVisible(true);
    const fetchRestore = async () => {
      const result = await fetchAbsolute(
        "get",
        `/hwlm/WorkItemInit?workItemId=${rowInfo.id}&xEnv=${logCrEnv}&processId=${rowInfo.processId}`
      );
      const tmp = result.data.resultData.initRecordDataMap;
      const arr = Object.entries(tmp).map((v) => ({
        key: v[0],
        value: v[1],
      }));
      setRecordData([...arr]);
    };
    fetchRestore();
  };

  const onClickRestore = () => {
    setRedoVisible(false);
    setRestoreVisible(true);
  };

  /* 재실행 모달에서 onChange [key, value] */
  const onChangeRecordKey = (e) => {
    const { index } = e.target.dataset;
    const tmpArr = [...recordData];
    tmpArr[index].key = e.target.value;
    setRecordData([...tmpArr]);
  };

  const onChangeRecordValue = (e) => {
    const { index } = e.target.dataset;
    const tmpArr = [...recordData];
    tmpArr[index].value = e.target.value;
    setRecordData([...tmpArr]);
  };

  const onChangeTableData = (data) => {
    fetchWorkItemList({ ...inputValues, ...pageInfo, currentPage: data.current });
  };

  const onClickRedo = async (record) => {
    const selectCr = crInfo.find(
      (v) => v.crCd.toUpperCase() === inputValues.cr.toUpperCase()
    );
    const taskType = record.processId.includes("d".toUpperCase())
      ? "DIY"
      : "COE";
    const arr = Object.entries(record.recordDataMap).map((v) => ({
      key: v[0],
      value: v[1],
    }));
    setLogCrEnv(selectCr.crXEnv);
    setRowInfo({ ...record, taskType });
    setRecordData([...arr]);
    setPreRecordData({...record.recordDataMap});
    setRedoVisible(true);
  };

  const onClickAdd = () => {
    const tmpArr = [...recordData];
    tmpArr.push({
      key: "",
      value: "",
    });
    setRecordData([...tmpArr]);
  };

  const onClickDelete = (e, index) => {
    const tmpArr = [...recordData];
    const survivedArr = tmpArr.filter(v => v !== tmpArr[index]);
    if (survivedArr.length === 0) {
      return alert(formatMessage({ id: 'T0124' })); // 한 칸 이하일 수는 없습니다.
    }
    setRecordData([...survivedArr]);
  }

  const onClickLog = (data) => {
    const taskType = data.processId.includes("d".toUpperCase()) ? "DIY" : "COE";
    setRowInfo({ ...data, taskType });
    setLogVisible(true);
  };

  const onOkRecordModal = () => {
    setRecordDataVisible(false);
  };

  /* 버튼 돌아가는 시간 지정*/
  const onClickReload = () => {
    if (!inputValues.cr) return alert(formatMessage({ id: "T0299" })); // CR을 선택해주세요
    clearTimeout(refreshTimeout.current);
    setSpinReload(true);
    refreshTimeout.current = setTimeout(() => {
      setSpinReload(false);
    }, 2000);
    onClickSearch();
  };

  const onChangeCr = (value) => {
    const selectCr = crInfo.find(
      (v) => v.crCd.toUpperCase() === value.toUpperCase()
    );
    setInputValues({ ...inputValues, cr: value.toUpperCase() });
    setLogCrEnv(selectCr?.crXEnv);
  };

  const onChangeProcessId = (e) => {
    setInputValues({ ...inputValues, processId: e.target.value });
  };

  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDataRange = ([regFr, regTo]) => {
    setInputValues({ ...inputValues, regFr, regTo });
  };

  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDataRange(range);
  };

  /* 재실행 ok 버튼 */
  const onOkRedo = async () => {
    setRedoVisible(false);
    const selectCr = crInfo.find(
      (v) => v.crCd.toUpperCase() === inputValues.cr.toUpperCase()
    );

    // DIY COE로 표기됨
    let poolName = devicePool.lists.find(
      (v) => v.devicePoolId === rowInfo.devicePoolId.toString()
    )?.devicePoolName;

    let tmp;
    if (recordData[0].key !== "") {
      tmp = recordData.reduce(
        (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
        {}
      );
    }

    const fetchEdit = async () => {
      let parameter = {
        processId: rowInfo.processId,
        recordData: {
          ...tmp,
        },
        preRecordData: {
          ...preRecordData,
        },
      };

      /* workItem 수정 api */
      const result = await fetchAbsolute(
        "put",
        `/hwlm/WorkItem?x-environment=${selectCr?.crXEnv}&workItemId=${rowInfo.id}`,
        {
          data: { ...parameter },
          headers: { "Content-Type": "application/json" },
        }
      );
      if (result.error) {
        return alert(recordUpdateErr);
      }
    };

    let parameter = {
      taskId: rowInfo.processId,
      cr: inputValues.cr || "PRD",
      poolName,
      automationPriority: "PRIORITY_HIGH",
      scheduleType: "INSTANT",
      timeZone: "Asia/Seoul",
      retryWorkItemIds: [rowInfo.id],
      botType: "Child",
      userInput: {...tmp}
    };

    const fetchRequest = async () => {
      try {
        const result = await fetchAbsolute(
          "post",
          `/task/oracle/addTaskSchedule?crType=${selectCr?.crXEnv}`,
          {
            data: { ...parameter },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.data.result === "fail") {
          const confirmResult = window.confirm(
            `${result.data?.message} \n${formatMessage({ id: "T1301" })}`
          ); // 대기 시간 이후에 자동으로 실행할까요?
          if (confirmResult) {
            const waiting = async () => {
              const result = await fetchAbsolute(
                "post",
                "/task/oracle/waitInstantAction",
                {
                  data: { ...parameter },
                  headers: {
                    "Content-Type": "application/json",
                  },
                }
              );
              if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
            };
            waiting();
          }
        } else if (result.status === 200 || result.status === 201) {
          alert(scheduleReSuccess);
        } else {
          throw new Error(result.status);
        }
      } catch (e) {
        return alert(scheduleReFail);
      }
    };
    fetchEdit();
    fetchRequest();
    fetchEdit();
  };


  /* 초기화 버튼 */
  const onClickReset = () => {
    setInputValues({ ...initialInputValues, cr: crInfo[0].crCd });
    fetchWorkItemList({ ...initialInputValues, cr: crInfo[0].crCd, ...initialPageInfo });
  };

  /* 검색 버튼 */
  const onClickSearch = async () => {
    const tmpPageInfo = {
      ...pageInfo,
      currentPage: 1,
    };
    setPageInfo({ ...tmpPageInfo });
    fetchWorkItemList({ ...inputValues, ...tmpPageInfo });
  };

  const fetchWorkItemList = async (data) => {
    const cr = crInfo.find(
      (v) => v.crCd.toUpperCase() === data.cr.toUpperCase()
    ).crXEnv;

    let filterCondition = {};
    let datesRange = [];
    let fieldId = [];

    if (data.regFr || data.regTo) {
      datesRange = [
        {
          operator: "ge",
          field: "startDateTime",
          value: data.regFr,
        },
        {
          operator: "le",
          field: "startDateTime",
          value: data.regTo,
        },
      ];
    }

    if (data.processId) {
      fieldId = [
        {
          operator: "eq",
          field: "processId",
          value: data.processId,
        },
      ];
    }

    filterCondition = {
      filter: {
        operands: [
          ...fieldId,
          ...datesRange,
          {
            operator: "startsWith",
            field: "processId",
            value: data.cr.substring(0, 1),
          },
        ],
      },
    };

    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/hwlm/readWorkItemWithProcessNm?x-environment=${cr}`,
      {
        data: {
          page: {
            offset: (data.currentPage - 1) * data.pageSize,
            length: data.pageSize,
          },
          sort: [
            {
              field: "creationDate",
              direction: "desc",
            },
          ],
          ...filterCondition,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setTableLoading(false);
    if (result.error) {
      return alert(searchFail);
    }
    setWorkItemList(result.data?.list);
    setPageInfo({ ...pageInfo, total: result.data.page.total, currentPage: data.currentPage });
  };

  const onClickReplay = (record) => {
    const cr = crInfo.find((v) => v.crCd === inputValues.cr) || {};
    setReplayData({
      taskId: record.processId,
      workItemId: record.id,
      cr: { ...cr },
    });
    setReplayVisible(true);
  };

  const onOkReplay = () => {
    setReplayVisible(false);
  };
  // 업무 ID나 업무명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const onCancelReplay = () => {
    setReplayVisible(false);
  };

  // 실행 중 일시중지, 중지, 다시시작 모달 관련 state
  const [runningProcessVisible, setRunningProcessVisible] = useState(false);
  const [runningTaskStatus,setRunningTaskStatus] = useState();

  const onClickRunningTask = (data,name) => {
    setRunningProcessVisible(true);
    setRunningTaskStatus(name);
    const taskType = data.processId.includes("d".toUpperCase()) ? "DIY" : "COE";
    setRowInfo({ ...data, taskType });
  }

  const onOKRunningTaskModal = async () => {
    let path = '';
    if(runningTaskStatus === "Pause") {
      path = '/hwlm/pauseRunningTask';
    } else if(runningTaskStatus === "Stop") {
      path = '/hwlm/stopRunningTask';
    } else if(runningTaskStatus==="RunAgain") {
      path = '/hwlm/pauseRunningTaskRunAgain';
    }
    // const type = crInfo.find(
    //   (v) =>
    //     v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD"
    // ).crXEnv; 
    // const type = logCrEnv;

    const workItemId = String(rowInfo.id);

    const result = await fetchAbsolute(
      "get",
      `${path}?x-environment=${logCrEnv}&taskId=${rowInfo.processId}&workitemId=${workItemId}`
    );

    if(result.data.resultCode==="S") {
      setRunningProcessVisible(false);
      setRunningTaskStatus();
      // setRowInfo();
      onClickReload();
    } else {
      return alert(formatMessage({ id: "T0133" })); // 작업을 실패하였습니다.
    }
  };

  const onCancelRunningTaskModal = () => {
    setRunningProcessVisible(false);
    setRunningTaskStatus();
    // setRowInfo();
  }

  const columns = [
    {
      title: <FormattedMessage id="T1108" />, // WorkItem ID
      dataIndex: "id",
      align: "center",
      key: "id",
      width: 110,
    },
    {
      title: <FormattedMessage id="T1037" />, // Process ID
      dataIndex: "processId",
      align: "center",
      key: "processId",
      width: 95,
      render: (text) => (
        <Link
          to={`/assign/lookup/${text}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: <FormattedMessage id="T1107" />, // Process Name
      dataIndex: "processNm",
      width: 200,
      key: 1,
      render: (text, record) => (
        <Link
          to={`/assign/lookup/${record.processId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Tooltip title={text}>
            <span>{text}</span>
          </Tooltip>
        </Link>
      ),
    },
    {
      title: <FormattedMessage id="T1106" />, // Biz Key
      dataIndex: "bizKey",
      align: "center",
      key: 2,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: <FormattedMessage id="T1105" />, // Biz SubKey
      dataIndex: "bizSubKey",
      align: "center",
      key: 3,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: <FormattedMessage id="T1104" />, // Start Date
      dataIndex: "startDateTime",
      align: "center",
      width: 144,
      key: 6,
      render: (date) => {
        return !date ? "" : moment(date).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: <FormattedMessage id="T1103" />, // End Date
      dataIndex: "endDateTime",
      align: "center",
      width: 144,
      key: 7,
      render: (date) => {
        return !date ? "" : moment(date).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: <FormattedMessage id="T1102" />, // Result
      key: 8,
      dataIndex: "status",
      width: 120,
      align: "center",
      render: (text, index) => (
        <div className="work_item__result_wrap" key={index}>
          {
            text === "New" ? (
              //준비중
              <>
                <PopupWaitIcon /><Text>{formatMessage({ id: "T1667" })}</Text> 
              </>
            ) :
              text === "WIP" ? (
              <>
                <ProcessingIcon />
                <Text>{formatMessage({ id: "T0568" })}</Text> {/* 진행중 */}
              </>
            ) : text === "Failed" ? (
              <>
                <FailedIcon /> <Text>{formatMessage({ id: "T0404" })}</Text>  {/* 실패 */}
              </>
            ) : text === "Complete" ? (
              <>
                <SuccessIcon /><Text>{formatMessage({ id: "T0403" })}</Text>  {/* 성공 */}
              </>
            ) : text === "RetryBiz" ? (
              <>
                <RetryBizIcon /><Text style={{ fontSize: 12 }}>{formatMessage({ id: "T1669" })}</Text> {/* 재시도 */}
              </>            
            ) : text === "Pause" ? (
              <>
                <PauseForResultIcon /><Text style={{ fontSize: 12 }}>{formatMessage({ id: "T1668" })}</Text> {/* 일시중지 */}
              </>
            ) : text === "Stop" ? (
              <>
                <StopRunningTaskIcon/><Text style={{ fontSize: 12 }}>{formatMessage({ id: "T0643" })}</Text> {/* 중지 */}
              </>
            ) : <Text style={{ fontSize: 12 }}>{text}</Text>
          }
        </div>
      ),
    },
    {
      title: (
        <Tooltip title={formatMessage({ id: "T0569" })}>{/* 새로고침 */}
          <SyncOutlined
            onClick={() => onClickReload()}
            style={{ fontSize: "14px", fontWeight: "bold" }}
            spin={spinReload}
          />
        </Tooltip>
      ),
      width: 136,
      key: 9,
      render: (data, record, index) => (
        <div className="work_item__stauts_wrap" key={index}>
          <Tooltip
            title={formatMessage({ id: "T1207" })} // Record Data
            onVisibleChange={(visible) => onVisibleChange(visible, index)}
            visible={tooltipVisible[index]}
          >
            <LookCloserIcon
              onClick={() => onClickRecordData(data)}
              className="abled_icons"
            />
          </Tooltip>
          <Tooltip
            title={formatMessage({ id: "T1167" })} // Replay
          >
            <MonitoringIcon
              onClick={() => onClickReplay(record)}
              className="abled_icons"
            />
          </Tooltip>
          {
            data.status === "Failed" ? (
              // T0570 : 로그 기록, T0571 : 재실행
              <>
                <Tooltip title={formatMessage({ id: "T0570" })}>
                  <LogIcon
                    onClick={() => onClickLog(data)}
                    className="abled_icons"
                  />
                </Tooltip>
                <Tooltip title={formatMessage({ id: "T0571" })}>
                  <RestartIcon
                    onClick={() => onClickRedo(data)}
                    className="abled_icons"
                  />
                </Tooltip>
              </>
            ) : data.status === "WIP" ? (  // result가 진행중(WIP)일때
              // T1668 : 일시중지, T0643: 중지
              <>
                <Tooltip title={formatMessage({ id: "T1668" })}>
                  <PauseRunningTaskIcon 
                    onClick={()=>onClickRunningTask(data,"Pause")}
                    // onClick={()=>onClickRunningTaskTest(data, "Pause")}
                  />
                </Tooltip>
                <Tooltip title={formatMessage({ id: "T0643" })}>
                  <StopRunningTaskIcon 
                    onClick={()=>onClickRunningTask(data, "Stop")}
                  />
                </Tooltip>
              </>
            ) : data.status === "Pause" ? ( //result가 일시중지(Pause)일때
            // T1670 : 다시 시작, T0643: 중지
              <>
                <Tooltip title={formatMessage({ id: "T1670" })}>
                  <RunningTaskRunAgainIcon
                    onClick={() => onClickRunningTask(data,"RunAgain")}
                    className="abled_icons"
                  />
                </Tooltip>
                <Tooltip title={formatMessage({ id: "T0643" })}>
                  <StopRunningTaskIcon
                    onClick={() => onClickRunningTask(data, "Stop")}
                    className="abled_icons"
                  />
                </Tooltip>
              </>
            ) : (
              // T0570 : 로그 기록, T0571 : 재실행
              <>
                <Tooltip title={formatMessage({ id: "T0570" })}>
                  <LogIcon
                    onClick={() => onClickLog(data)}
                    className="abled_icons"
                  />
                </Tooltip>
                <Tooltip title={formatMessage({ id: "T0571" })}>
                  <RestartIcon />
                </Tooltip>
              </>
            )            
          }
        </div>
      ),
    },
  ];

  /* 재실행 때 poolname 필요 */
  useEffect(() => {
    if (!rowInfo.taskType) return;
    const fetchDevicePool = async (page) => {
      if (page === undefined) {
        page = { ...poolPageInfo }
      }
      const result = await fetchAbsolute(
        "get",
        `/admin/devicePool/getDevicePoolList?crType=${rowInfo.taskType}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
      );
      setDevicePool(result.data);
      setPoolPageInfo({ ...page, total: +result.data.totalItemsCount });
    };
    fetchDevicePool();
  }, [rowInfo]);

  useEffect(() => {
    if (crInfo.length === 0) return;
    const tmpInputValues = {
      ...initialInputValues,
      cr: crInfo[0].crCd,
    };
    setInputValues({ ...tmpInputValues });
    fetchWorkItemList({ ...tmpInputValues, ...initialPageInfo });
  }, [crInfo]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper style={{ padding: "30px 100px" }}>
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <FormTable pointBorder={false} size="sm">
              <colgroup>
                <col width="10.375%" />
                <col width="13.625%" />
                <col width="10.375%" />
                <col width="13.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ borderRadius: "14px 0 0 0" }}>
                    <Text>{formatMessage({ id: "T1036" })}</Text>{/* CR */}
                  </th>
                  <td>
                    <Select
                      style={{ width: 250 }}
                      onChange={onChangeCr}
                      value={inputValues.cr}
                    >
                      {crInfo.map((v) => (
                        <Option value={v.crCd} key={v.crCd}>
                          {v.crDesc}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <th><Text>{formatMessage({ id: "T1037" })}</Text></th>{/* Process ID */}
                  <td>
                    <Input
                      style={{ width: 250 }}
                      onChange={onChangeProcessId}
                      value={inputValues.processId}
                      placeholder={formatMessage({ id: "T1037" })} // Process ID
                      onKeyPress={onKeyPress}
                    />
                  </td>
                </tr>
                <tr>
                  <th><Text>{formatMessage({ id: "T1104" })}</Text></th>{/* Start Date */}
                  <td>
                    <RangePicker
                      style={{ width: 250 }}
                      onChangeDate={onChangeDates}
                      onCalendarChange={onChangeDates}
                      value={[
                        inputValues.regFr && inputValues.regFr.length > 0
                          ? moment(inputValues.regFr)
                          : undefined,
                        inputValues.regTo && inputValues.regTo.length > 0
                          ? moment(inputValues.regTo)
                          : undefined,
                      ]}
                      defaultValue={[undefined, undefined]}
                      allowClear={true}
                    />
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <Row style={{ padding: "0 13px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0 25px",
                  padding: "10px 0",
                }}
              >
                <Button width="120" height="40" onClick={onClickReset}>
                  <FormattedMessage id="T0037" />{/* 초기화 */}
                </Button>
                <Button
                  width="120"
                  height="40"
                  type="primary"
                  onClick={onClickSearch}
                >
                  <FormattedMessage id="T0039" />{/* 검색 */}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div style={{ paddingTop: 20 }}>
          <Table
            pagination={true}
            onChange={(data) => onChangeTableData(data)}
            rowKey={(record) => record.id}
            loading={tableLoading}
            columns={columns}
            dataSource={workItemList}
            locale={{ emptyText: `${formatMessage({ id: "T0842" })}` }} // 데이터가 존재하지 않습니다
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.pageSize,
            }}
          />
        </div>
      </Wrapper>
      <LogModal
        title={formatMessage({ id: "T0574" })} // 과제 실행 log
        visible={logVisible}
        onOk={onOkLog}
        onCancel={onCancelLog}
        recordData={rowInfo}
        devicePool={logCrEnv}
      />
      <RealtimeModal
        visible={replayVisible}
        onOk={onOkReplay}
        onCancel={onCancelReplay}
        taskId={replayData.taskId}
        workItemId={replayData.workItemId}
        cr={replayData.cr}
      />
      <RedoModal
        redoVisible={redoVisible}
        onOkRedo={onOkRedo}
        onCancelRedo={onCancelRedo}
        onClickRestore={onClickRestore}
        onChangeRecordKey={onChangeRecordKey}
        onChangeRecordValue={onChangeRecordValue}
        recordData={recordData}
        onClickAdd={onClickAdd}
        onClickDelete={onClickDelete}
      />
      <Modal
        centered
        visible={restoreVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0082" })} // 초기 데이터 원복
        onOk={onOkRestore}
        onCancel={onCancelRestore}
      >
        <Text>{formatMessage({ id: "T0573" })}</Text>{/* 초기 데이터를 불러오시겠습니까? */}
      </Modal>
      <RecordDataModal
        centered
        recordData={recordData}
        visible={recordDataVisible}
        onOk={onOkRecordModal}
        onCancel={onCancelRecordModal}
      />
      <Modal
        centered
        visible={runningProcessVisible}
        width={500}
        title={runningTaskStatus==="Pause"
            ? <FormattedMessage id="T1668" /> // 일시중지
            : runningTaskStatus==="Stop"
              ? <FormattedMessage id="T0643" /> // 중지
              : <FormattedMessage id="T1670" />  // 다시시작
          }
        okText={<FormattedMessage id="T0064" />} //확인
        onOk={onOKRunningTaskModal}
        onCancel={onCancelRunningTaskModal}
      >
        <Text>
            {runningTaskStatus==="Pause"
              ? <>{formatMessage({ id: "T1671" })}</> //실행 중인 과제를 일시중지 하시겠습니까?
              : runningTaskStatus==="Stop"
                ? <>{formatMessage({ id: "T1672" })}</> // 실행 중인 과제를 중지 하시겠습니까?
                : <>{formatMessage({ id: "T1673" })}</>  //일시중지된 과제를 다시 시작 하시겠습니까?
            }
          {/* {assignLookup.taskData?.taskNm}({assignLookup.taskData?.taskId})&nbsp;
          {formatMessage({ id: "T0476" })}&nbsp;과제를 즉시 실행하시겠습니까? */}
        </Text>
      </Modal>
    </PageTemplate>
  );
};

export default AdminWorkItem;
