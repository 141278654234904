import { SET_ASSIGNLIST_VALUE, SET_ASSIGNLIST_SPREAD, RESET_ASSIGNLIST_VALUE, SET_ASSIGNLIST_FILTERID } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const assignListInitialState = {
    inputValues: {
        taskId: '',
        taskNm: '',
        taskType: [],
        orgCds: [],
        mgrIds: [],
        devIds: [],
        statusCd: [],
        workId: [],
        regFr: '',
        regTo: '',
        regType: ''
    },
    filterId: '',
    pageInfo: {
        sort: "regTimestamp,desc",
        page: 0,
        size: 20,
        tab: "all",
        total: 0
        // order: 'regTimestamp',
        // direction: 'desc',
        // currentPage: 1,
        // rowPerPage: 20,
        // tab: 'all'
    },
    tableData: [],
    taskCount: {
        totalCount: 0,
        myTaskCount: 0,
        interestTaskCount: 0,
    },
};

const assignList = (state = assignListInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_ASSIGNLIST_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_ASSIGNLIST_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_ASSIGNLIST_VALUE: {
            const inputValues = {
                ...assignListInitialState.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        case SET_ASSIGNLIST_FILTERID: {
            return {
                ...state,
                filterId: action.filterId
            }
        }
        default:
            return state;

    }
}

export default assignList;