import React from 'react';
import { Text } from 'components';
import { CheckOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl";

const AssignLookupStepper = ({ checkPoint }) => {
    const { formatMessage } = useIntl();

    const steps = [

        {
            key: 1,
            title: formatMessage({ id: 'T0395' }), // 접수
            des: formatMessage({ id: "T0424" }) // 기본 정보
        },
        {
            key: 2,
            title: formatMessage({ id: 'T0396' }), // 개발
            des: formatMessage({ id: "T0576" }) // 분석/설계
        },
        {
            key: 3,
            title: formatMessage({ id: 'T0396' }), // 개발
            des: formatMessage({ id: 'T0055' }) // 개발 진행
        },
        {
            key: 4,
            title: formatMessage({ id: 'T0397' }), // 테스트
            des: formatMessage({ id: "T0577" }) // 개발 검수
        }
    ]

    return (
        <div className='assign_lookup__stepper'>
            {
                steps.map((step) => (
                    <div key={step.key} className={`stepper__step ${step.key === checkPoint ? 'stepper_check_point' : checkPoint === 6 ? 'stepper_check_stop' : null}`}>
                        <div className='stepper__circle'>
                            {checkPoint === 6 ? null : <CheckOutlined className={`stepper__check ${checkPoint === 6 && 'check_stop'}`} />}
                        </div>
                        <div className='stepper__text'>
                            <Text className='stepper__text_title'>{step.key}.&nbsp;{step.title}</Text>
                            <Text className='stepper__text_des'>{step.des}</Text>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default AssignLookupStepper;