import axios from "axios";
import moment from "moment";
import queryString from 'query-string';
import { setCookie, getCookie, removeCookie } from 'utils/cookie'; 

const END_POINT = process.env.REACT_APP_API_URI;

const refresh = async (config) => {  
  /* 비밀번호 초기화 이메일 링크 누르면 토큰 없이 바로 페이지로 이동하면서 api 호출 하기 때문에 여기서 걸림 */
  if(getCookie('auth') === undefined) {
    return;
  };

  const refreshToken = getCookie('auth').refreshToken;
  const expireAt = getCookie('auth').expireAt;
  let accessToken = getCookie('auth').accessToken;
  
  // 토큰이 만료되었고, refreshToken 이 저장되어 있을 때 
  if (moment(expireAt).diff(moment()) < 0 && refreshToken) {   
    // 토큰 갱신 서버통신 
    const pathnameArr = `/login/reissue`.split('?');
    const originPathname = pathnameArr.splice(0,1)[0];
    const queryObj = queryString.parse(pathnameArr.join(''));
    const options = {
      data: {
        "accessToken": accessToken,
        "refreshToken": refreshToken
      },
      headers: {
        "Content-Type": "application/json",
      },
    };
    queryObj.locale = options.locale || localStorage.getItem('locale') || 'ko'; 
    const method = `post`;
    const config1 = {
      method,
      url: END_POINT + originPathname + '?' + queryString.stringify(queryObj)
    };
    try {
        const result = await axios({...config1, ...options}).then(v => v.response || v).catch(err =>{
          console.log(err)
          console.log(result)
          if(err.response.data.status === 403){
              removeCookie('auth'); 
              return document.location.href = `/`;
          };
        });  
        if(result.status >= 500) {
            throw new Error('/error/server');
        } else if (result.status >= 400) {  
            throw new Error('/error/client');
        };
        accessToken = result.data.data.accessToken;
        setCookie('auth', JSON.stringify(result.data.data), {
          path: '/'
        });  
        axios.defaults.headers.common['Authorization'] = accessToken;
    } catch(err) {
        return {
            error: true,
            redirectURL: err.message
        };
    };
  } else {
    axios.defaults.headers.common['Authorization'] = accessToken;
  };
  return config;
};

const refreshErrorHandle = (err) => {
  removeCookie('auth');
};

export { refresh, refreshErrorHandle };