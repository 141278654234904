import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    margin-bottom: 40px !important;
    .submit__button__area {
        display: flex;
        justify-content: flex-end;
        gap: 20px;
        padding: 0 77.5px 33px 0;
    }
    .submit__button {
        width: 150px;
        height: 40px;
    }
    button:focus {
      outline: 0;
    } 
`;

export default Wrapper;