import { getUniqueKey } from "utils/Str";

export const SET_HANDYFLOWBPDLIST_VALUE = getUniqueKey(
  "SET_HANDYFLOWBPDLIST_VALUE"
);
export const SET_HANDYFLOWBPDLIST_SPREAD = getUniqueKey(
  "SET_HANDYFLOWBPDLIST_SPREAD"
);
export const RESET_HANDYFLOWBPDLIST_VALUE = getUniqueKey(
  "RESET_HANDYFLOWBPDLIST_VALUE"
);
export const SET_HANDYFLOWBPDLIST_FILTERID = getUniqueKey(
  "SET_HANDYFLOWBPDLIST_FILTERID"
);

export function setHandyFlowBpdListValue(key, value) {
  return {
    type: SET_HANDYFLOWBPDLIST_VALUE,
    key,
    value,
  };
}

export function setHandyFlowBpdListSpread(data) {
  return {
    type: SET_HANDYFLOWBPDLIST_SPREAD,
    data,
  };
}

export function resetHandyFlowBpdListValue() {
  return {
    type: RESET_HANDYFLOWBPDLIST_VALUE,
  };
}

export function setHandyFlowBpdListFilterId(filterId) {
  return {
    type: SET_HANDYFLOWBPDLIST_FILTERID,
    filterId,
  };
}
