import React, {useState, useEffect, useRef}from "react";
import styled from "styled-components";
import { getCookie } from 'utils/cookie';
import { Modal, Text, Image } from "components";
import { FormattedMessage } from 'react-intl';

const END_POINT = process.env.REACT_APP_API_URI;

const Wrapper = styled(Modal)`
  .ant-modal-footer {
    display: none;
    justify-content: center;
  }
  .active_modal__display {
    display: flex;
    margin: 0 auto;
    width: 1100px;
    min-height: 600px;
  }
  .active_modal__display img {
    width: 100%;
    max-height: 663px;
    object-fit: contain;
  }
  .active_modal__title {
    font-size: 20px;
  }
  .active_modal__title.bold {
    font-weight: bold;
  }
`;

const ActiveModal = ({ visible, title, onCancel, data }) => {
  // const [nowDateTime, setNowDateTime] = useState(new Date().getTime());

  // let interval = useRef(null);

  const [blobSrc, setBlobSrc] = useState('');
  const accessToken = getCookie('auth')?.accessToken;

  useEffect(() => {
    if(title === undefined) return;

    let imgSrc = `${END_POINT}/monitoring/runner/currentFile?hostName=${title}`;
    const inputImageInfo = async (src) => {
        const headers = new Headers();
        headers.append('Authorization', accessToken);

        /* 메인화면처럼 src가 빈 경우에 */
        if (src) {
            await fetch(src, { headers })
                .then(response => response.blob())
                .then(blobby => {
                    let objectUrl = window.URL.createObjectURL(blobby);
                    setBlobSrc(objectUrl);
                });
        }
    }

    if (visible) inputImageInfo(imgSrc);

    return () => {
        window.URL.revokeObjectURL(blobSrc);
    }
}, [visible, data]);
  // useEffect(() => {
  //   if (visible) {
  //     interval.current = setInterval(() => {
  //       setNowDateTime(new Date().getTime());
  //     }, 2000);
  //     setImageData(data?.hostName);
  //   } else {
  //     setImageData();
  //     clearInterval(interval.current);
  //   }
  // }, [visible, data]);

  return (
    <Wrapper
      style={{
        top: 20,
      }}
      onCancel={onCancel}
      visible={visible}
      title={
        <>
          <Text className="active_modal__title bold"><FormattedMessage id="T1165" /> : </Text>{/* Host Name */}
          <Text className="active_modal__title">{title}</Text>
        </>
      }
      footer={[]}
    >
      <div className="active_modal__display">
        <img src={blobSrc} alt='monitoring_img'/>
        {/* <Image src={`https://dev-api.dreago.net/atman-dwp-api/monitoring/runner/currentFile?hostName=${imageData}`} 
          alt='monitoring_img'/> */}
      </div>
    </Wrapper>
  );
};

export default ActiveModal;