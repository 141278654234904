import styled from 'styled-components';

/**
 * PageHeader에 적용할 Style Component
 */
const Header = styled.div`
    width: 100%;
    background: ${props=>props.headerColor};
    .page_header__container {
        display: flex;
        gap: 15px;
    }
    .page_header__container > .page_header__rgo_icon > svg {
        width: 100px;
        height: 66.4px;
    }
    // 우리카드 수정사항
    /* .page_header__title {
        font-size: 26px;
        color: #0067ac;
        align-self: end;
        padding-bottom: 13px;
    }
    .page_header__bread span {
        color: #0067ac;
    } */

    //기존
    .page_header__title {
        font-size: 26px;
        color: #ff6600;
        align-self: end;
        padding-bottom: 13px;
    }
    .page_header__bread span {
        color: #ff6600;
    }
`;

export default Header;
