import React, { useState, useEffect } from "react";
import { Table, Text, Modal, Button } from "components";
import { Select, Input } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;

/**
 * Filename: AdminCommonDeviceGroup/index.js
 * Description: device pool Group
 */

const AdminCommonDevice = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const selectRowPerPage = formatMessage({ id: "T0036" }); // 페이지당 행
  const firstBlankNotAllowed = formatMessage({ id: "T0230" }); // 해당 항목에는 첫 글자 공백을 사용할 수 없습니다.

  const pageSize = [20, 50, 70];

  const [PoolVisible, setPoolVisible] = useState(false);
  const [checkedDatas, setCheckedDatas] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [originDataSource, setOriginDataSource] = useState([]);
  const [sortValue, setSortValue] = useState(0);
  const [poolPage, setPoolPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [language, setLanguage] = useState("");

  const columns = [
    {
      key: 1,
      title: <FormattedMessage id="T0735" />, // 코드
      dataIndex: "cd",
      sorter: (a, b) => a.cd.localeCompare(b.cd),
      width: "15%",
      align: "center",
      render: (value, row, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => onChangeInput(index, "cd", e.target.value)}
            readOnly={!row.inputFlag}
          />
        );
      },
    },
    {
      key: 2,
      title: <FormattedMessage id="T1064" />, // Device Pool Group Name
      dataIndex: "cdNm",
      align: "center",
      sorter: (a, b) => a.cdNm.localeCompare(b.cdNm),
      width: "50%",
      render: (value, row, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => onChangeInput(index, "cdNm", e.target.value)}
          />
        );
      },
    },
    {
      key: 3,
      title: <FormattedMessage id="T0736" />, // 정렬순서
      dataIndex: "sort",
      sorter: (a, b) => a.sort - b.sort,
      align: "center",
      width: "15%",
      render: (value, row, index) => {
        return (
          <Input
            type="number"
            value={value}
            onChange={(e) => onChangeInput(index, "sort", e.target.value)}
          />
        );
      },
    },
    {
      key: 4,
      title: <FormattedMessage id="T0737" />, // 사용여부
      dataIndex: "useYn",
      align: "center",
      sorter: (a, b) => a.useYn.localeCompare(b.useYn),
      width: "15%",
      render: (select, row, index) =>
        dataSource.length >= 1 ? (
          <Select
            defaultValue="N"
            value={select}
            style={{ width: "80%" }}
            onChange={(e) => onChangeInput(index, "useYn", e)}
          >
            <Option value="Y">Y</Option>
            <Option value="N">N</Option>
          </Select>
        ) : null,
    },
  ];

  const onSelectOption = (value) => {
    setRowPerPage(+value);
  };

  const onChangeInput = (index, label, value) => {
    const tmpArr = [...dataSource];
    if (index === -1) return;
    if (value === " ") {
      alert(firstBlankNotAllowed);
      return;
    }
    tmpArr[index][label] = value;
    setDataSource([...tmpArr]);
  };

  const onCancelDelete = () => setPoolVisible(false);

  const onClickAdd = () => {
    const tmpArr = [...dataSource];
    let indexArr = [];

    tmpArr.push({
      categoryId: "DEVICE_POOL",
      cd: "",
      cdNm: "",
      sort: sortValue + 1,
      useYn: "N",
      inputFlag: true,
      key: tmpArr[tmpArr.length - 1].key + 1,
      locale: language,
    });

    setSortValue(sortValue + 1);
    setDataSource([...tmpArr]);
    indexArr = JSON.parse(JSON.stringify(tmpArr));
    for (let i = 0; i < indexArr.length; i++) {
      delete indexArr[i].key;
    }
  };

  const onClickReset = () => {
    fetchPoolList();
  };

  const onClickSave = async (row) => {
    const tmpArr = [...dataSource];

    let emptyFlag = true;
    tmpArr.forEach((e) => {
      if (
        e.cdNm === null ||
        e.cdNm === "" ||
        e.sort === null ||
        e.sort === "" ||
        e.cd === "" ||
        e.cd === null
      ) {
        emptyFlag = false;
      }
    });
    if (!emptyFlag) {
      alert(formatMessage({ id: "T0239" }));
      //공백은 입력 할수 없습니다.
      return;
    }
    let fetchFlag = true;
    try {
      const saveDevicePoolGroup = await fetchAbsolute(
        "post",
        `/common/commonCd/addList`,
        {
          data: tmpArr,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        saveDevicePoolGroup.status === 200 ||
        saveDevicePoolGroup.status === 201
      ) {
        alert(formatMessage({ id: "T0249" }));
        //저장 되었습니다.
      } else {
        throw new Error(saveDevicePoolGroup.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0250" }));
        //Device Pool Group 저장에 실패하였습니다);
      }
    }

    //insert 후 다시 API 조회하기
    fetchPoolList();
    setPoolVisible(false);
  };

  const fetchPoolList = async () => {
    const result = await fetchAbsolute(
      "get",
      `/common/commonCd/getCateIdAndUseYn/${"DEVICE_POOL"}?sortVal=asc`
    );
    setDataSource([...result.data.map((v, index) => ({ ...v, key: index }))]);
    setOriginDataSource([
      ...result.data.map((v, index) => ({ ...v, key: index })),
    ]);
    setSortValue(result.data[result.data.length - 1].sort);
  };

  const onOkDelete = async () => {
    //delete 할 데이터 array 반복문 돌려서 삭제하기
    const delData = checkedDatas.map((ele) =>
      originDataSource.find((e) => e.key === ele)
    );
    let fetchFlag = true;
    try {
      const deleteTaskStopReason = await fetchAbsolute(
        "delete",
        `/common/commonCd/deleteList`,
        {
          data: delData,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        deleteTaskStopReason.status === 200 ||
        deleteTaskStopReason.status === 201
      ) {
        alert(formatMessage({ id: "T0251" }));
        //Device Pool Group 삭제 완료 되었습니다.
      } else {
        throw new Error(deleteTaskStopReason.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0252" }));
        // `Device Pool Group 생성에 실패하였습니다.`
      }
    }
    //삭제 후 다시 API 조회하기

    fetchPoolList();
    setPoolVisible(false);
    setCheckedDatas([]);
  };
  const onClickDeleteButton = (e) => {
    if (checkedDatas.length === 0) {
      return alert(formatMessage({ id: "T0253" }));
      //  "삭제할 데이터를 선택해주세요. ")
    }
    if (originDataSource.length < dataSource.length) {
      const val = formatMessage({ id: "T0245" })
        .split("\n")
        .map((data) => {
          return data;
        });
      return alert(val.join("\n"));
    }
    setPoolVisible(true);
  };

  const onChangePage = (currentPage) => {
    setPoolPage(currentPage);
  };

  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCheckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCheckedDatas(selectedRowKeys);
    },
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute(
      "get",
      "/common/commonCd/getCategoryId/LOCALE"
    );
    const data = result.data.filter((v) => v.useYn === "Y");
    setLanguage(data[0].cd);
  };

  useEffect(() => { }, [dataSource]);

  useEffect(() => {
    fetchPoolList();
    fetchLanguageList();
  }, []);

  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <div className="realtime__table_header">
          <Text>{selectRowPerPage}</Text>
          <Select
            style={{
              width: 89,
              height: 32,
            }}
            name="pagination"
            onChange={onSelectOption}
            className="realtime__table_page"
            defaultValue={pageSize[0]}
          >
            {pageSize.map((v, index) => (
              <Option key={index} value={v}>
                {fmMessage({ id: "T1228", values: { number: v } })}
              </Option>
            ))}
          </Select>
        </div>
        <Table
          className="realtime__table_content"
          columns={columns}
          dataSource={dataSource}
          pagination={true}
          defaultPageSize={+rowPerPage}
          pageSizeVisible={false}
          showSorterTooltip={true}
          rowSelection={rowSelection}
          rowKey="key"
        // pageOptions={{
        //   total: dataSource.length,
        //   current: poolPage,
        //   pageSize: rowPerPage,
        //   onChange: onChangePage,
        // }}
        />
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 20,
            }}
          >
            <Button width="144" height="40" onClick={onClickReset}>
              <FormattedMessage id="T0037" />
              {/* 초기화 */}
            </Button>
            &nbsp; &nbsp;
            <Button width="144" height="40" type="primary" onClick={onClickAdd}>
              <FormattedMessage id="T0049" />
              {/* 추가 */}
            </Button>
            &nbsp; &nbsp;
            <Button
              width="144"
              height="40"
              type="primary"
              onClick={onClickSave}
            >
              {formatMessage({ id: "T0073" })}
              {/* 저장 */}
            </Button>
            &nbsp; &nbsp;
            <Button width="144" height="40" onClick={onClickDeleteButton}>
              {formatMessage({ id: "T0072" })}
              {/* 삭제 */}
            </Button>
          </div>
        </div>
        <Modal
          width={540}
          visible={PoolVisible}
          title={formatMessage({ id: "T0072" })} //삭제
          okText={formatMessage({ id: "T0072" })} //삭제
          onCancel={onCancelDelete}
          onOk={onOkDelete}
        >
          <Text
            style={{
              display: "block",
              fontSize: 14,
            }}
          >
            {formatMessage({ id: "T0733" })}{/* 정말로 선택된 분류를 삭제 하시겠습니까? */}
          </Text>
        </Modal>
      </div>
    </div>
  );
};

export default AdminCommonDevice;
