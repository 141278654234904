import styled from "styled-components";

const Wrapper = styled.div`
  /* home component */
  .home_component__container {
    display: flex; 
    margin: 0 auto; 
    justify-content: center;
    width: 1903px;
  }
  .main_left {
    min-height: 500px;
    width: 1104px;
    padding: 15.9px 33.2px 0 0;
    border-right: 1px solid #d0d1d6;
  }
  .main_right {
    width: 494px;
    padding: 35.1px 17px;
    padding-right: 0;
    background: #fff;
  } 
  /* float 영역 */
  .float__area {
    padding: 20px 0;
    background: inherit; 
    min-height: 510px;
  }  
`;

export default Wrapper;