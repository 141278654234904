import styled from "styled-components";

const Wrapper = styled.div`
  width: 1400px; 
  margin: 0px auto;  
  .ant-tabs-content-top { 
    border: 1px solid rgb(208,209,214);
    border-top: 1px solid #fff;
    padding: 30.1px 30px;
    background: #fff;
  }
  .org_body__container {
    display: flex;
    gap: 0 50px;
  }
  .org_common__box {
    display: flex; 
    border: 1px solid #000;
    flex-direction: column;
    margin-top: 10px; 
  }
  .org_common__box .ant-tree.ant-tree-icon-hide {
    width: 100%;
  }
  .org_common_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
  }
  .org_common_content {
    font-size: 18px;
    font-weight: bold;
  }
  /* 차트 부분 */
  .org_chart__container {
    width: 338px;
    padding-top: 20px;
    padding-bottom: 10px; 
  }  
  /* 차트 부분 > 테이블 */  
  .ant-table-content table > thead > tr { 
    height: 40px;   
  }
  /* 상세 부분 */
  .org_details__container {
    flex:2;
  }
  .ant-table-thead th {
    height: 34px;
    padding: 0px 16px;
    font-size: 12px;
}
  th {
    text-align: center;
  }
  .org_button_area {
    padding-bottom: 10px;
  }
  .org_detail__button {
    display: flex;
    align-self: flex-end;
    padding-bottom: 20px; 
    padding-right: 20px; 
    gap: 20px;
  }
  .ant-checkbox-group-item {
    display: flex;
    margin-right: 0;
    padding-bottom:6px;
}
}
.system_section_check {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left :20px;
  padding-right:20px;
  float:left;
  border-radius: 6px 6px 6px 6px;
}
.system_section_tree
 {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-left :50px;
  padding-right:20px;
  float:left;
  border-radius: 6px 6px 6px 6px;
}

.checkAll_checkbox{
  padding-bottom:6px;
  padding-top:7px;
}
.button_event{
  margin-right:10px
}
/* 권한 그룹 관리 부분 */ 
.org_group__container {
  width: 570px;
  padding-top: 20px;
  padding-bottom: 10px; 
}  
/* 탭 */
.ant-tabs-nav .ant-tabs-tab {
  width: 180px;
  text-align: center;
  background: #eaeaed;
}
.ant-tabs-nav .ant-tabs-tab:hover {
  color: #000;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  height: 40px;
  color: #000;
  background: #fff;
  border-bottom: none;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
  margin: 0px 10px 0 0;
  background: #eaeaed;
  border: 1px solid #d0d1d6;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}
.ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.ant-tabs-nav-list {
  gap: 10px;
}
.ant-tabs-nav-list .ant-tabs-tab {
  width: 180px;
  text-align: center;
  background: #eaeaed;
}
.ant-tabs-nav-list .ant-tabs-tab:hover {
  color: #000;
}
.ant-tabs-nav-list .ant-tabs-tab-active {
  background: #fff;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 6px 6px 0 0;
  border: 1px solid #d0d1d6;
  font-size: 16px;
}

.ant-tabs-tab-active ant-tabs-tab{
  background:white; !important;
}
.ant-tabs-nav .ant-tabs-tab-active{
  background:white !important; 
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  background:white;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
  
} 
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #ffe1bf !important;
} 
.ant-tree.ant-tree-icon-hide.draggable-tree .ant-tree-list-holder-inner{
  padding: 3px 0;
}
`;

export default Wrapper;
