import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  /* 공통 */
  .common__container {
    background: #fff;
    padding: 30.1px 30px;
    border: 1px solid rgb(208, 209, 214);
    margin-bottom: 30px;
    border-top: none;
  }
  /* tabs */
  .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  .ant-tabs-nav-list {
    gap: 10px;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    width: 180px;
    text-align: center;
    background: #eaeaed;
  }
  .ant-tabs-nav-list .ant-tabs-tab:hover {
    color: #000;
  }
  .ant-tabs-nav-list .ant-tabs-tab-active {
    background: #fff !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0;
    border: 1px solid #d0d1d6;
    font-size: 16px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-tab-active ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #000;
    border-bottom: none !important;
    background: #fff !important;
    border-bottom: 1px solid #fff !important;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0px 10px 0 0;
    background: #eaeaed;
    border: 1px solid #d0d1d6;
    border-radius: 10px 10px 0 0;
    border-bottom: none !important;
  }
  .ant-tabs-tab:hover {
    color: #ff7a00;
  }
  .ant-input:hover {
    border-color: #ff7b00;
  }
  /* Agent 버전 관리 */
  .ver_manage__top {
    display: flex;
    justify-content: space-between;
  }
  .ver_manage__top .left {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .version__type {
    cursor: pointer;
  }
  .delete__file {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  /* 버전 추가, 삭제 */
  .agent__section {
    display: flex;
    flex-direction: column;
    gap: 10.8px;
  }
  .agent__title {
    font-size: 20px;
    font-weight: bold;
  }
  .agent__box {
    border: 1px solid #e1e1e6;
  }
  .btn__section {
    display: flex;
    gap: 10px;
    align-self: flex-end;
  }
  .ant-select-selection {
    height: 40px;
    width: 150px;
    padding-top: 3px;
  }
  /* agent 업데이트 이력 */
  .update__top {
    display: flex;
    padding-bottom: 10.8px;
    align-items: end;
    gap: 20px;
  }
  span.ant-calendar-picker-input.ant-input {
    height: 32px;
  }
  .update__top .ant-select-selection {
    height: 32px;
    width: 200px;
    display: flex;
    align-items: flex-end;
  }
  .search_condition__container {
    display: flex;
    gap: 20px;
  }
  .search_condition__section {
    width: min-content;
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .search_condition__rangerpicker {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  /* Runnder Agent 인증서*/
  .certification_period_section {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 8px;
  }
`;

export default Wrapper;
