import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { PageTemplate, Header, Footer, Tabs } from "components";
import Wrapper from "./Styled";
import ShopClosed from "./ItemShopTab/ShopClosed";
import ShopOpen from "./ItemShopTab/ShopOpen";
import PurchaseHistory from "./ItemShopTab/PurchaseHistory";
import moment from "moment";
import fetchAbsolute from "utils/fetchAbsolute";
import { setVisible } from "store/loading/action";
import { useIntl } from 'react-intl';

/**
 * Academy > Item Shop
 */

const { TabPane } = Tabs;

const JediItemShop = ({
  profile,
  match: { params },
  history,
}) => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const [tabsValue, setTabsValue] = useState("");
  const [shopStatus, setShopStatus] = useState();

  const onChangeTab = (key) => {
    history.push(`/jediacademy/itemshop/${key}`);
  };

  useEffect(() => {
    if (!params.tabValue) {
      history.replace(`/jediacademy/itemshop/shop`);
    }
    setTabsValue(params.tabValue);
  }, [params.tabValue]);

  useEffect(() => {
    const fetchItemList = async () => {
      const today = moment().format("YYYY-MM-DD");
      dispatch(setVisible(true));
      const result = await fetchAbsolute(
        "get",
        `/academy/getProductWithPointInfo?empNo=${profile.EMP_NO}&curDate=${today}`
      );
      dispatch(setVisible(false));
      if (result.data.userProductList !== undefined) {
        setShopStatus(true);
      } else {
        setShopStatus(false);
      }
    };
    fetchItemList();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false} footerColor="#000">
      <div style={{ background: "black", paddingBottom: 100 }}>
        <Wrapper>
          {tabsValue !== "" && shopStatus !== undefined && (
            <Tabs
              className="stationTab"
              type="card"
              custom={false}
              activeKey={tabsValue}
              defaultActiveKey={tabsValue}
              onChange={onChangeTab}
            >
              <TabPane tab="Shop" key="shop">
                {shopStatus ? (
                  <ShopOpen profile={profile} />
                ) : (
                  <ShopClosed profile={profile} />
                )}
              </TabPane>
              <TabPane tab={formatMessage({ id: 'T0979' })} key="purchasehistory">{/* 상품 구매 이력 */}
                <PurchaseHistory profile={profile} />
              </TabPane>
            </Tabs>
          )}
        </Wrapper>
      </div>
    </PageTemplate>
  );
};

export default JediItemShop;
