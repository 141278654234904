import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PageTemplate, Header, Footer, Tabs, DepartmentList } from 'components';
import Wrapper from './Styled';
import HFWorkflowOperation from './HFWorkflowOperation';
import { getUniqueKey } from 'utils/Str';
import { useIntl } from 'react-intl';

const { TabPane } = Tabs;

/**
 * Filename: HFMonitoringStatus/index.js
 * Description: BizFlow > WorkFlow 운영 현황 모니터링  
 */

const HFMonitoringStatus = ({ profile }) => {
    const { formatMessage } = useIntl();

    const permission = useSelector(state => state.get(getUniqueKey('permission')));

    const [departmentVisible, setDepartmentVisible] = useState(false);
    const [department, setDepartment] = useState([]);

    // 검색할 때 쓸 department
    const [searchDepartment, setSearchDepartment] = useState([]);

    // 과제별 수행현황 탭 권한 체크
    const [performPermission, setPerformPermission] = useState(false);
    const [performLoading, setPerformLoading] = useState(false);

    const onReset = () => {
        setDepartment([]);
        setSearchDepartment([]);
    }

    const onOpenDepartment = () => {
        setDepartmentVisible(true);
    };
    const onOkDepartment = (data) => {
        setDepartmentVisible(false);
        setDepartment([...data]);
    };
    const onCancelDepartment = () => {
        setDepartmentVisible(false);
    };
    const onClickSearchButton = () => {
        setSearchDepartment(department);
    }

    useEffect(() => {
        if (permission.userLoading) return;
        const flag = permission.user.findIndex(v => v.fnCode === 'FN29') !== -1;
        setPerformPermission(flag);
        setPerformLoading(true);
    }, [permission.user]);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            {/* 부서 조회 */}
            <DepartmentList
                visible={departmentVisible}
                companyCd={profile.COMP_CD}
                onOk={onOkDepartment}
                onCancel={onCancelDepartment}
                mode='multiple'
                defaultData={department}
            />
            {
                performLoading &&
                <Wrapper>
                    <HFWorkflowOperation
                        department={department}
                        onOpenDepartment={onOpenDepartment}
                        onClickSearchButton={onClickSearchButton}
                        searchDepartment={searchDepartment}
                        performPermission={performPermission}
                        performLoading={performLoading}
                        profile={profile}
                        onReset={onReset}
                    />
                </Wrapper>
            }
        </PageTemplate>
    );
}

export default HFMonitoringStatus;