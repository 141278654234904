import React, { useEffect, useState } from 'react';
import { UserContext, Image } from 'components';
import { Tag, Typography } from 'antd';
import styled from 'styled-components';
import { ProfileIcon } from 'icons';
import fetchAbsolute from 'utils/fetchAbsolute';
import { useIntl } from "react-intl";

const { Text } = Typography;

const TagWrap = styled(Tag)`
    display: flex;
    align-items: flex-end;
    border: none;
    background:transparent;
    .user_tag_text {
        position: relative;
        font-size: 14px;
        margin-left:5px;
        color:#000;
        align-self: center;
        cursor: pointer;
    }
    .user_tag_text > .underline {
        border-bottom: 1px solid #000;
    }
    .user_name_tag:hover {
        color: #4747b3;
    }
    .user_name_tag.board.ant-dropdown-trigger {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.87);
    }
    .user_thumbnail {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #333;
    }
    .user_thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .avatar_profile {
        width: 25px;
        height: 25px;
        border-radius: 50px; 
    }
    /* ${props => props.disabled && `
        .user_tag_text {
            pointer-events:none;
            // color: #d0d1d6;
        }
    `} */
`;

const UserTag = ({ profile = {}, key, orgVisible = true, contextVisible = false, type = "", ...args }) => {
    const { formatMessage } = useIntl();

    const [idPhoto, setIdPhoto] = useState({});

    useEffect(() => {
        if (!profile.FILE_ID&&!profile.FILE_LINK) return;

        if(profile.FILE_ID) {
            const getUserFile = async () => {
                const result = await fetchAbsolute('get', `/file/listFile/${profile.FILE_ID}`);
                if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다. 
                setIdPhoto(result.data[0] || '');
            }
            getUserFile();
        }

        if(profile.FILE_LINK) {
            setIdPhoto({link: profile.FILE_LINK});
        }
        
    }, [profile.FILE_ID, key]);

    if (Object.keys(profile).length === 0) {
        return null
    } else {
        return (
            <TagWrap {...args}>
                {
                    idPhoto.link ?
                        <Image
                            className='avatar_profile'
                            src={idPhoto.link}
                            width='30'
                            height='30'
                        />
                        :
                        <ProfileIcon
                            style={{ width: 25, height: 25, fontSize: 20 }}
                        />
                }
                <Text className='user_tag_text'>
                    {
                        contextVisible ?
                            <UserContext profile={profile}>
                                {
                                    !orgVisible ?
                                        <span className='user_name_tag board'>{`${profile.NAME_KOR || ''}`}</span>
                                        :
                                        <span className='underline user_name_tag'>{`${profile.NAME_KOR || ''}(${profile.EMP_NO || ''})`}</span>
                                }
                            </UserContext>
                            :
                            <span className='underline user_name_tag'>{`${profile.NAME_KOR || ''}(${profile.EMP_NO || ''})`}</span>
                    }
                    {
                        orgVisible &&
                        <>
                            &nbsp;|&nbsp;{`${profile.DEPT_NAME_KOR || ''}`}
                        </>
                    }
                </Text>
            </TagWrap>
        )
    }
}

export default UserTag;
