import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  .academy_section {
    min-height: 800px;
    text-align: center;
    position:relative;
  }
  .academy_section2 {
    min-height:400px;
    margin: 100px auto;
    display:flex;
  }
  .academy_section2_content {
    color: white;
    position: absolute;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -1;
    word-break:keep-all;
  }
  .academy_section4{
    min-height:700px;
    position:relative;
    display:flex;
    margin : 100px auto;
    gap:100px;
  }
  .academy_section4_child{
    flex: 1;
    position:relative;
    margin-top : 130px;
  }
  .academy_section4_title{
    font-size:20px;
    font-weight:bold;
    color:white;
    text-align:center;
  }
  .academy_section4_text{
    font-size:16px;
    font-weight:bold;
    letter-spacing:-1;
    color:white;
    white-space: pre-line;
  }
  .academy_section5 {
    min-height:400px;
    margin:100px auto;
    position:relative;
    display:flex;
  }
 
  /* section 3 피라미드 */
  .academy_pyramid_section {
    min-height: 600px;
    position:relative;
    display:flex;
    width:100%;
    justify-content:center;
    align-items:center;
  }
  .academy_pyramid{
    width: 40%;
    float: right;
  }
  .display_img {
    position:absolute;
    max-width:100%; max-height:100%;
    width:auto; height:auto;
    margin:auto;
    top:0; bottom:0; left:0; right:0;
  }
  .sord_section{
    min-height:523px;
    margin : 100px auto 0 auto;
    text-align: center;
    vertical-align:middle;
    position:relative;
  }
  .section_title{
    
    color: white;
    font-size: 24px;
    font-weight: bold;
    position: absolute
  }
  .title_box{
    text-align: center;
  }
  // .academy_return_to_top{
  //   top:180px;
  //   right:36px;
  //   color:#FF6600;
  //   font-size:18px;
  //   z-index: 1,
  // }

  .section5 {
    padding: 450px 0px 150px;
    text-align:center;
}
.section5 img {
    display: block;
    margin: 0 auto;
    cursor: pointer;
    z-index: 2000,
}
.academy_return_to_top{
    color:#FF6600 !important ;
    font-size:18px;
    z-index: 1,
}

`;

export default Wrapper;
