import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'components';
import { useIntl, FormattedMessage } from "react-intl";
import styled from 'styled-components';
import { ShrinkOutlined, ExpandOutlined } from '@ant-design/icons';


const Wrapper = styled(props => <Modal {...props} />)`
    .ant-popover-inner-content {
        overflow-y: unset !important;
        font-size: 11px;
    }
    .ant-popover > .ant-popover-content {
        width: auto !important;
    }
`

const ProgressModal = ({ 
    visible = false, 
    onCancel = () => { },
    data = '',
    title = "Progress Status"
}) => {
    const { formatMessage } = useIntl();
    // const fmMessage = useFormatMessage();
    const [iframe, setIframe] = useState({
        key: 0, // 새로고침을 위한 
        src: ''
    });

    const [isMaximized, setIsMaximized] = useState(false);

    const onOkRefresh = () => {
        let id = data.id ? data.id : data.processInstanceIds
        setIframe({ 
            key: iframe.key + 1, 
            src: `https://dreago.net:8070/ibpm-web/openapi/bpm/popup/processPopUpMain?LegacyAuthCheckFilterUser=dreago_admin&deployId=${id}&viewDetailPage=false` });
    }

    useEffect(() => {
        if (!visible) return;
        
        setIsMaximized(false);
        onOkRefresh();

        document.getElementsByClassName('progress_log_modal')[0].setAttribute("style", "width: 800px;transform-origin: 876.4px 459px;");
        document.getElementsByClassName("progress_log_modal")[0].getElementsByClassName("ant-modal-body")[0].setAttribute("style", "height:480px;");
        
    }, [visible]);

    const onClickMaximize = () => {
        setIsMaximized(true);

        document.getElementsByClassName('progress_log_modal')[0].setAttribute("style", "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;");
        document.getElementsByClassName('progress_log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:calc(100vh - 123px);");
    }

    const onClickMinimize = () => {
        setIsMaximized(false);

        document.getElementsByClassName('progress_log_modal')[0].setAttribute("style", "width: 800px;transform-origin: 876.4px 459px;");
        document.getElementsByClassName("progress_log_modal")[0].getElementsByClassName("ant-modal-body")[0].setAttribute("style", "height:480px;");
    }

    return (
        <Wrapper
            width={800}
            className='progress_log_modal'
            visible={visible}
            modalRender={false}
            // title={formatMessage({ id: 'T1592' })} // 진행 상태
            title={
                <div className="modal_title">
                  {title}
                  {
                    !isMaximized ?
                      <ExpandOutlined
                        className="title_expand_icon"
                        onClick={onClickMaximize}
                        id='max_btn'
                      />
                      :
                      <ShrinkOutlined
                        className="title_expand_icon"
                        onClick={onClickMinimize}
                        id='min_btn'
                      />
                  }
                </div> 
              }
            okText={formatMessage({ id: 'T0569' })} // 새로고침
            onCancel={onCancel}
            onOk={onOkRefresh}
        >
            <iframe
                style={{
                    border: 0,
                    margin: "0 auto",
                    display: "block",
                }}
                width="100%"
                title='progress'
                height='100%'
                src={iframe.src}
                key={iframe.key}
            />
        </Wrapper>
    )
}

export default ProgressModal
