import React from "react";
import { Divider, Typography, } from 'antd';
import { Loading, Empty } from 'components';
import { useIntl } from "react-intl";
import { Column } from '@ant-design/plots';

const { Title } = Typography;

/**
 * 대시보드 > ROI 현황 > 조직별 ROI
 */

const OrgROI = ({ byOrgList, isLoading, orgConfig }) => {
    const { formatMessage } = useIntl();

    if (isLoading) {
        return <Loading visible={isLoading} />
    }
    return (
        <div className='box__container' >
            {/* 조직별 ROI */}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1524" })} {/* 조직별 ROI */}
            </Title>
            <Divider />
            {
                byOrgList.length > 0 ?
                    <Column data={byOrgList} {...orgConfig} className="dashboard_graph" style={{ height: 300, width: `calc(100px * ${byOrgList.length})`, minWidth: '100%' }} /> : <Empty />
            }
        </div >
    )
}
export default OrgROI