import React, { useState, useEffect } from 'react';
import { Collapse as AntCollapse } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { setListUrl } from 'store/listUrl/action';
import { PageTemplate, Header, Footer, HFWorkflowListTop, HFWorkflowListBottom, Collapse } from "components";
import Container from "containers/Container";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl } from 'react-intl';
import fetchAbsolute from "utils/fetchAbsolute";
import queryString from 'query-string';
import moment from 'moment';
import { getUniqueKey } from 'utils/Str';
import { resetHandyFlowWorkflowListValue } from 'store/hfworkflowList/action';


/**
 * Filename: HFWorkflowList/index.js
 * BizFlow > HFworkflow list > List
 * Description: HFworkflow list 컴포넌트
 */

const { Panel } = AntCollapse;

const initialSearchQuery = {
    sort: 'regTimestamp,desc',
    page: 0,
    size: 20,
    tab: 'all',
    total: 0,
};

const initialInputValues = {
    workflowId: '',
    workflowNm: '',
    workflowOrgCd: [],
    workId: [],
    mgrIds: [],
    devIds: [],
    devEmpNm: [],
    regFr: '',
    regTo: '',
    regType: '',
    statusCd: []
}

const HFWorkflowList = ({ location: { search }, history }) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();

    const inputValues = useSelector(
        (state) => state.get(getUniqueKey("hfworkflowList")).inputValues
    );

    const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
    const [sortedInfo, setSortedInfo] = useState({})
    const [beforeSearch, setBeforeSearch] = useState('');
    const [openCollapse, setOpenCollapse] = useState(true);
    const [tabsValue, setTabsValue] = useState('');
    const [tableData, setTableData] = useState([]);
    const [excelBody, setExcelBody] = useState([]);
    const [tableTotal, setTableTotal] = useState({
        all: 0,
        myTask: 0,
        interest: 0
    });

    const [tableLoading, setTableLoading] = useState(false);
    //데이트 타입 변경
    const convertTypeDate = (type) => {
        const dateObj = {
            regFr: '',
            regTo: moment().format('YYYYMMDD'),
        };
        switch (type) {
            case "today":
                dateObj.regFr = moment().add(0, "d").format('YYYYMMDD');
                break;
            case "week":
                dateObj.regFr = moment().subtract(6, "d").format('YYYYMMDD');
                break;
            case "month":
                dateObj.regFr = moment().subtract(1, "months").format('YYYYMMDD');
                break;
            default:
        }
        return dateObj;
    }
    //검색단 접기 
    const onChangeCollapse = () => setOpenCollapse((openCollapse) => !openCollapse);

    const convertData = (searchObj, type) => {
        /* 데이터 가공해주기 */
        Object.keys(searchObj).forEach((key) => {
            const data = searchObj[key];
            
            if (["mgrIds", "devIds"].includes(key)) {
                searchObj[key] = data.map(item => item.empNum);
            } else if (["workflowOrgCd"].includes(key)) {
                searchObj[key] = data.map(item => item.codeId);
            } else if (["workId","statusCd"].includes(key)) {
                searchObj[key] = data.map(item => item.cd);
            } else if (["regFr", "regTo"].includes(key)) {
                searchObj[key] = data.replace(/-/g, "");
            } else if (key === "regType") {
                if (["today", "week", "month"].includes(data)) {
                    const dateObj = convertTypeDate(data);
                    searchObj.regFr = dateObj.regFr;
                    searchObj.regTo = dateObj.regTo;
                } else if (['custom'].includes(data[0])) {
                    searchObj.regFr = moment(searchObj.regFr).format("YYYYMMDD");
                    searchObj.regTo = moment(searchObj.regTo).format("YYYYMMDD");
                }
            }
        });

        if(type==="replaceSearch") {
            Object.keys(searchObj).forEach((key) => {
                const data = searchObj[key];
                if (Object.keys(data).length <= 0 && typeof data !== "number") {
                delete searchObj[key];
                }
            })
    
            return searchObj;
        } else {
            const parameter = {
                //workflowId를 api parameter에 넘겨줄때 배열로 변환해서 넘겨줘야함
                workflowId: (searchObj.workflowId===null||searchObj.workflowId==="")
                            ?[]:
                            [searchObj.workflowId],
                workflowNm: searchObj.workflowNm,
                workId: searchObj.workId?.flat(),
                workflowOrgCd: searchObj.workflowOrgCd?.flat(),
                devIds: searchObj.devIds?.flat(),
                mgrIds: searchObj.mgrIds?.flat(),
                statusCd: searchObj.statusCd?.flat(), // 배열
                regFr: searchObj.regFr,
                regTo: searchObj.regTo,
                //sessionId가 뭔지 모르겠음
                sessionId: searchObj.sessionId,
            };
            return parameter;
        }
    }

    const replaceSearch = (searchObject) => {
        // const initialKeys = Object.keys(initialInputValues);
        const initialSearchObj = { ...searchQuery, ...searchObject };
        // const beforeKeys = Object.keys(searchObj).filter(key => initialKeys.includes(key));

        // 검색할 데이터 가공해주기
        const searchObj = convertData(initialSearchObj,"replaceSearch");
        const searchString = queryString.stringify(searchObj);

        if ('?' + searchString === search) return;
        dispatch(setListUrl('hfWorkflowList', searchString));
        history.push({ search: searchString });
    }

    const getTableDatas = async (searchObject) => {
        const searchObj = { ...inputValues, ...searchObject }
        let path = '';
        switch (searchObject.tab) {
            case 'all':
                path = 'workflowListHF';
                break;
            case 'myTask':
                path = 'workflowMyListHF';
                break;
            case 'interest':
                path = 'workflowInterestListHF';
                break;
            default: path = 'workflowListHF'
        }
        // 데이터 가공해주기
        const parameter = convertData(searchObj,"getTableData");
        setTableLoading(true);
        const result = await fetchAbsolute("post", `/workflow/${path}${search}`, {
            data: {
                ...parameter
            }
        });
        setTableLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        /* 엑셀을 위한 */
        setExcelBody({ ...parameter });

        const { totalCount, myTaskCount, interestTaskCount, workflows } = result.data;

        setTableTotal({
            all: totalCount || 0,
            myTask: myTaskCount || 0,
            interest: interestTaskCount || 0,
        });
        const datas = workflows.map((v) => ({
            ...v,
            regTimestamp: moment(v.regTimestamp).format("YYYY-MM-DD"),
        }));
        setTableData([...datas]);

        // workflowHF 최근검색
        const beforeArray = [];
        const beforeObject = {};

        const initialKeys = Object.keys(initialInputValues);
        const beforeKeys = Object.keys(searchObject).filter(key => initialKeys.includes(key));

        Object.keys(searchObject).forEach(key => {
            const data = searchObject[key];

            if (!beforeKeys.includes(key)) {
                return;
            }

            beforeObject[key] = data;
            if (["devIds"].includes(key)) {
                for (let i in data) {
                    beforeArray.push(data[i].empNam);
                }
            } else if (["workId"].includes(key)) {
                for (let i in data) {
                    beforeArray.push(data[i].cdNm);
                }
            } else if (["mgrIds"].includes(key)) {
                for (let i in data) {
                    beforeArray.push(data[i].empNum);
                }
            } else if (["statusCd"].includes(key)) {
                for (let i in data) {
                    beforeArray.push(data[i].cdNm);
                }
              }else if (["workflowOrgCd"].includes(key)) {
                for (let i in data) {
                    beforeArray.push(data[i].codeNm);
                }
            } else if (['regFr', 'regTo'].includes(key)) {
                beforeArray.push(data);
            } else if (beforeKeys.includes(key)) {
                beforeArray.push(data);
            }
        });
        if (beforeArray.length > 0) {
            const beforeText = beforeArray.slice(0, 2).join(', ');
            localStorage.setItem('beforeHFWorkflowSearch', beforeText);
            localStorage.setItem('beforeHFWorkflowObject', JSON.stringify(beforeObject));
            setBeforeSearch(beforeText)
        }
    };

    const toggleHeart = () => {
        getTableDatas({ ...searchQuery });
    };

    const convertWholeData = (search) => {
        const searchObject = queryString.parse(search);
    
        const { regFr, regTo, regType } = searchObject;
    
        // 부서 정보 세팅
        if (inputValues.workflowOrgCd) {
          try {
            searchObject.workflowOrgCd = [...inputValues.workflowOrgCd];
          } catch {
            delete searchObject.workflowOrgCd;
          }
        }
        // 매니저 정보 세팅 mgrIds 
        if (inputValues.mgrIds) {
          try {
            searchObject.mgrIds = [...inputValues.mgrIds];
            // if (typeof mgrIds === 'string') {
            //   const { data: { mdFPAList } } = await fetchAbsolute(
            //     "get",
            //     `/common/memberNoList?empNo=${mgrIds}`
            //   );
            //   searchObject.mgrIds = [...mdFPAList];
            // } else {
            //   searchObject.mgrIds = [...inputValues.mgrIds];
            // }
          } catch {
            delete searchObject.mgrIds;
          }
        }
    
        // 개발자 정보 세팅
        if (inputValues.devIds) {
          try {
            // if (typeof devIds === 'string') {
            //   const { data: { mdFPAList } } = await fetchAbsolute(
            //     "get",
            //     `/common/memberNoList?empNo=${devIds}`
            //   );
            //   searchObject.devIds = [...mdFPAList];
            // } else {
              searchObject.devIds = [...inputValues.devIds];
            // }
          } catch {
            delete searchObject.devIds;
          }
        }
    
        // 과제 상태 세팅
        if (inputValues.statusCd) {
          try {
            searchObject.statusCd = [...inputValues.statusCd];
          } catch {
            delete searchObject.statusCd;
          }
        }
        //업무분류 세팅
        if (inputValues.workId) {
          try {
            searchObject.workId = [...inputValues.workId];
          } catch {
            delete searchObject.workId;
          }
        }
        if (inputValues.regFr) {
          const date = moment(inputValues.regFr).format('YYYY-MM-DD');
          if (date === 'Invalid date') {
            delete searchObject.regFr;
          } else {
            searchObject.regFr = date;
          }
        }
        if (inputValues.regTo) {
          const date = moment(inputValues.regTo).format('YYYY-MM-DD');
          if (date === 'Invalid date') {
            delete searchObject.regTo;
          } else {
            searchObject.regTo = date;
          }
        }
        if (regType && !['custom', ''].includes(regType)) {
          // 원래 세팅돼있는 값과 다르면
          const dateObj = convertTypeDate(regType);
          if (dateObj.regFr !== regFr || dateObj.regTo !== regTo) {
            searchObject.regFr = dateObj.regFr;
            searchObject.regTo = dateObj.regTo;
          }
        }
        const tmpObj = {
          ...initialSearchQuery,
          ...searchObject
        }
    
        return {...tmpObj}
      }

    // query String 변경 될 때마다
    useEffect(() => {
        const preSearchObject = queryString.parse(search);
        if (search.length <= 0) {
            replaceSearch(searchQuery);
            return;
        } else if (
            !preSearchObject.page ||
            !preSearchObject.size ||
            !preSearchObject.sort ||
            !preSearchObject.tab
        ) {
            replaceSearch({
                ...searchQuery,
                ...preSearchObject
            });
            return;
        };

        const { regFr, regTo, regType } = preSearchObject;

        let isReplace = false;
        if (regType && !['custom', ''].includes(regType)) {
          // 원래 세팅돼있는 값과 다르면
            const dateObj = convertTypeDate(regType);
            if (dateObj.regFr !== regFr || dateObj.regTo !== regTo) {
                isReplace = true;
            }
        }
        const fetchData = async () => {
            const tmpObj = await convertWholeData(search);
            if (isReplace) {
                replaceSearch({ ...tmpObj });
            } else {
                setSearchQuery({ ...tmpObj });
                setTabsValue(tmpObj.tab);
                getTableDatas({ ...tmpObj });
            }
        }
        fetchData();
    }, [search]);

    useEffect(() => {
        return () => {
            dispatch(resetHandyFlowWorkflowListValue());
            setTableLoading(false);
        };
    }, []);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} bodyColor='#fff'>
            <Container
                style={{
                    padding: "30px 0",
                    background: "#fff",
                }}
            >
                <Collapse
                    expandIconPosition="end"
                    onChange={onChangeCollapse}
                    expandIcon={() =>
                        !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
                    }
                    activeKey={openCollapse ? ["1"] : []}
                    defaultActiveKey={openCollapse ? ["1"] : []}
                >
                    <Panel header={formatMessage({ id: "T1194" })} key='1'>{/* 상세검색 */}
                        <HFWorkflowListTop
                            searchQuery={searchQuery}
                            replaceSearch={replaceSearch}
                            beforeSearch={beforeSearch}
                            setBeforeSearch={setBeforeSearch}
                            initialSearchQuery={initialSearchQuery}
                            setSearchQuery={setSearchQuery}
                            setSortedInfo={setSortedInfo}
                        />
                    </Panel>
                </Collapse>
                <HFWorkflowListBottom
                tableTotal={tableTotal}
                    tabsValue={tabsValue}
                    tableData={tableData}
                    replaceSearch={replaceSearch}
                    searchQuery={searchQuery}
                    toggleHeart={toggleHeart}
                    tableLoading={tableLoading}
                    sortedInfo={sortedInfo}
                    setSortedInfo={setSortedInfo}
                    excelBody={excelBody}
                />
            </Container>
        </PageTemplate>
    );
}

export default HFWorkflowList;