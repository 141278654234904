import React from "react";
import styled from "styled-components";
import { Modal, Button } from "components";
import { Input as AntInput } from "antd";
import { DeleteIcon } from 'icons';
import { useIntl } from "react-intl";
/**
 * Filename: ScheduleUserInputModal
 * DIY 스케줄 즉시 실행 버튼을 눌렀을 때
 * 이 모달이 팝업되면서 사용자는 userInput을 입력할 수 있음
 */

const { TextArea } = AntInput;

const Wrapper = styled((props) => <Modal {...props} />)`
  .input__container {
    overflow-y: auto;
    min-height: fit-content;
    max-height: 250px;
  }
  .input__area {
    display: flex;
    gap: 10px;
    padding: 5px 0;
    align-items: center;
  }
  .add_button__area {
    display: flex;
    padding-bottom: 5px;
    justify-content: end;
  }
  .add_button__area button {
    width: 70px;
    height: 30px;
  }
  .primary__button {
    width: 200px;
    height: 40px;
  }
  .default__button {
    height: 40px;
  } 
`;

const ScheduleUserInputModal = ({
  visible,
  onOk = () => { },
  onCancel = () => { },
  title = "",
  userInputValues,
  setUserInputValues,
}) => {
  const { formatMessage } = useIntl();
  const onChangeRecordKey = (e) => {
    const { value } = e.target;
    const { index } = e.target.dataset;
    const tmpArr = [...userInputValues];
    tmpArr[index].key = value;
    setUserInputValues([...tmpArr]);
  };

  const onChangeRecordValue = (e) => {
    const { value } = e.target;
    const { index } = e.target.dataset;
    const tmpArr = [...userInputValues];
    tmpArr[index].value = value;
    setUserInputValues([...tmpArr]);
  };

  const onClickDelete = (e, index) => {
    const tmpArr = [...userInputValues];
    const survivedArr = tmpArr.filter(v => v !== tmpArr[index]);
    if (survivedArr.length === 0) {
      return alert(formatMessage({ id: 'T0124' })); // 한 칸 이하일 수는 없습니다.
    }
    setUserInputValues([...survivedArr]);
  }

  const onClickAdd = () => {
    const tmpArr = [...userInputValues];
    tmpArr.push({
      key: "",
      value: "",
    });
    setUserInputValues(tmpArr);
  };

  return (
    <Wrapper
      width={800}
      visible={visible}
      title="User Input"
      okText={formatMessage({ id: 'T0064' })} // 확인
      onCancel={onCancel}
      onOk={onOk}
    >
      <div className="container">
        <div className="add_button__area">
          <Button type="primary" onClick={onClickAdd}>
            {formatMessage({ id: 'T0049' })}{/* 추가 */}
          </Button>
        </div>
        <div className="input__container">
          {userInputValues.map((u, index) => (
            <div className="input__area" key={index}>
              <TextArea
                style={{ width: "20%", resize: "none", height: "fit-content" }}
                rows={2}
                value={u.key}
                placeholder="key"
                data-index={index}
                onChange={onChangeRecordKey}
              />
              <TextArea
                style={{ width: "80%", resize: "none", height: "fit-content" }}
                rows={2}
                value={u.value}
                placeholder="value"
                data-index={index}
                onChange={onChangeRecordValue}
              />
              <DeleteIcon
                onClick={(e) => onClickDelete(e, index)}
              />
            </div>
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default ScheduleUserInputModal;