import React, { useEffect, useState, useRef } from 'react';
import { useIntl } from "react-intl";
import moment from "moment";
import { Select } from 'antd';
import fetchAbsolute from 'utils/fetchAbsolute';
import pdfDownload from 'utils/pdfDownload';
import {
    PageTemplate,
    Header,
    Footer,
    Text,
    TypePicker,
    Button
} from 'components';
import Wrapper from './Styled';
import ProgressStatus from './ProgressStatus';
import OrgOperation from './OrgOperation';
import OrgTAT from './OrgTAT';
import TaskStageTAT from './TaskStageTAT';

const { Option } = Select;

/**
 * 대시보드 > 과제 현황
 *  
 */

const DashboardTaskState = () => {
    const contentRef = useRef();

    const { formatMessage } = useIntl();
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [standardDate, setStandardDate] = useState(moment());
    const [taskType, setTaskType] = useState([]);
    const [defaultTaskType, setDefaultTaskType] = useState([]);

    const onChangeDate = (date) => {
        setStandardDate(date);
    };

    /* 과제 유형 */
    const onChangeType = (e) => {
        setTaskType([...e]);
    };

    useEffect(() => {
        if (taskTypeList.length === 0) return;
        const tmpArr = [];
        for (let i in taskTypeList) {
            tmpArr.push(taskTypeList[i].cd)
        }
        setTaskType([...tmpArr]);
        setDefaultTaskType([...tmpArr]);
    }, [taskTypeList]);

    useEffect(() => {
        /* taskType 가져오는 함수 */
        const fetchTaskType = async () => {
            try {
                const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/OPER_TYPE');
                setTaskTypeList([...result.data?.filter(v => v.useYn === 'Y')]);
            } catch (err) {
                return alert(err);
            }
        }
        /* 조직별 리스트 가져오기 */
        const getCompanyList = async () => {
            const result = await fetchAbsolute('get', `/admin/commonMaster/getKpiStandardCompanyList`);
            setCompanyList([...result.data]);
        };
        getCompanyList();
        fetchTaskType();
    }, []);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <Wrapper>
                <div className='task_state__container' ref={contentRef}>
                    <div className="standard_time">
                        <Text className="">{formatMessage({ id: "T1512" })}</Text>{/* 기준일자 */}
                        <TypePicker
                            placeholder={moment().format("YYYY.MM.DD")}
                            defaultValue={moment()}
                            value={standardDate}
                            width="170"
                            height="32"
                            onChange={onChangeDate}
                            allowClear={false}
                        />
                        <div>{formatMessage({ id: "T0740" })}</div>{/* 과제유형 */}
                        <div>
                            <Select
                                style={{
                                    width: 150
                                }}
                                mode="multiple"
                                name="taskType"
                                value={taskType}
                                placeholder={formatMessage({ id: 'T0391' })} // 전체
                                onChange={onChangeType}
                                filterOption={taskTypeList
                                    .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                                    .map((item) => item.cd)}
                            >
                                {
                                    taskTypeList.map(type => {
                                        return (
                                            <Option key={type.cd} value={type.cd}>{type.cd}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div>
                            <Button onClick={() => pdfDownload('Dashboard_Task_State.pdf')} >{formatMessage({ id: 'T1317' })} </Button>{/* PDF 다운로드 */}
                        </div>
                    </div>
                    <div className='first__section'>
                        <div className='progress_status'>
                            <ProgressStatus
                                standardDate={standardDate.format('YYYY-MM-DD')}
                                taskType={taskType}
                                defaultTaskType={defaultTaskType}
                            />
                        </div>
                        <div className='org_operation'>
                            <OrgOperation
                                companyList={companyList}
                                standardDate={standardDate.format('YYYY-MM-DD')}
                                taskType={taskType}
                                defaultTaskType={defaultTaskType}
                            />
                        </div>
                    </div>
                    <div className='second__section'>
                        <div className='org_tat'>
                            <OrgTAT
                                standardDate={standardDate.format('YYYY-MM-DD')}
                                taskType={taskType}
                                companyList={companyList}
                                defaultTaskType={defaultTaskType}
                            />
                        </div>
                        <div className='task_stage_tat'>
                            <TaskStageTAT
                                standardDate={standardDate.format('YYYY-MM-DD')}
                                taskType={taskType}
                                companyList={companyList}
                                defaultTaskType={defaultTaskType}
                            />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </PageTemplate>
    )
}

export default DashboardTaskState;