import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Text, Modal, RangePicker } from "components";
import { Input as AntInput } from "antd";
import { getUniqueKey } from 'utils/Str';
import { setVisible } from "store/loading/action";
import fetchAbsolute from "utils/fetchAbsolute";
import { setWorkflowData, loadWorkflowData } from 'store/hfWorkflowLookup/action';
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from "react-router-dom";
import ProgressModification from './ProgressModification';
import ProgressDevelopment from "./ProgressDevelopment";
import moment from "moment";

/**
 * Filename: WorkflowLookupProgress.js
 * Description: workflow 조회 페이지 하단에 기본 정보 탭 중 3. [개발] 개발 진행 컴포넌트
 */

const { TextArea } = AntInput;

const WorkflowLookupProgress = ({
  params
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const errorOccur = formatMessage({ id: "T0153" }); // 오류가 발생했습니다

  // const [fetchedData, setFetchedData] = useState();
  // const [isEdited,setIsEdited] = useState(false);
  const [comment, setComment] = useState('');
  const [commentList, setCommentList] = useState([]);
  const [editList, setEditList] = useState([]);
  const [completeDev, setCompleteDev] = useState({});
  const [errorFile, setErrorFile] = useState([]);
  const [finishDevVisible, setFinishDevVisible] = useState(false);

  const onClickFinishDevButton = () => setFinishDevVisible(true);
  const onCancelFinishtDev = () => setFinishDevVisible(false);

  const [commentDelVisible, setCommentDelVisible] = useState(false);
  const [commentDelData, setCommentDelData] = useState();

  const onOpenCommentDel  = (index) => {
    setCommentDelData(index);
    setCommentDelVisible(true);
  }
    const onOKCommentDel = () => {
      // commentDelData
      onClickDeleteComment(commentDelData);
    setCommentDelVisible(false);
  }
  const onCancelCommentDel = () => setCommentDelVisible(false);

  //코멘트 저장
  const onClickComment = async () => {
    if (comment.trim().length === 0) return alert(formatMessage({ id: 'T1598' })); //  내용을 입력해주세요
    const result = await fetchAbsolute("post",
      '/workflow/saveDevComment', {
      data: {
        workflowId: hfWorkflowLookup.workflowData.workflowId,
        comment: comment
      }
    });

    setCommentData(result.data);
    if (result.error) {
      return alert(formatMessage({ id: "T0235" }));
    }
    //TextArea 부분 비워주기
    setComment('');
  }

  //코멘트 쓴 본인일 때만 코멘트 삭제
  const onClickDeleteComment = async (commentDelData) => {
    const index = commentDelData;
    const result = await fetchAbsolute("get",
      `/workflow/delDevComment?idx=${index}`);
    if (result.data === "success") {
      //setCommentData하기
      const fecthData = async () => {
        try {
          const { data } = await fetchAbsolute("get",
            `/workflow/workflowInfoHF?workflowId=${params.workflowId}`);
          // setFetchedData(data);
          setCommentData(data);
        } catch { }
      };
      fecthData();
    } else {
      return alert("삭제되지 않았습니다.");
    }
  }

  //수정 시작 버튼
  const onClickRestart = async () => {
    //  개발(WS20), 수정 시작(WS23)으로 바뀜
    let result;
    if (hfWorkflowLookup.workflowData.statusCd === "WS20") {
      result = await fetchAbsolute("get",
        `/workflow/reStartDev?workflowId=${params.workflowId}`);
    }
    if (hfWorkflowLookup.workflowData.statusCd === "WS40") {
      result = await fetchAbsolute("get",
        `/workflow/initiateEditsDuringOperation?workflowId=${params.workflowId}`);
    }

    if (result.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    }
    dispatch(setWorkflowData(result.data));
  }

  //날짜 바꾸기
  const onChangeDates = (data) => {
    // const range = data.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    setCompleteDev({ ...completeDev, schedule: data })
  }
  //코멘트 달기
  const onAddComment = (data) => {
    setCompleteDev({ ...completeDev, comment: data.target.value })
  }

  const setCommentData = async (inputData) => {
    try {
      let tmpArr = [];
      if (!inputData.devCommentList) {
        const { data } = await fetchAbsolute(
          "get",
          `/common/memberNoList?empNo=${inputData.regId}`
        );
        let tmpData = {
          ...data.mdFPAList[0],
          idx: inputData.idx,
          comment: inputData.comment,
          regTimestamp: inputData.regTimestamp
        }

        setCommentList([tmpData, ...commentList]);

      } else if (inputData.devCommentList?.length > 0) {
        for (let i in inputData.devCommentList) {
          const { data } = await fetchAbsolute(
            "get",
            `/common/memberNoList?empNo=${inputData.devCommentList[i].regId}`
          );
          let tmpData = {
            ...data.mdFPAList[0],
            idx: inputData.devCommentList[i].idx,
            comment: inputData.devCommentList[i].comment,
            regTimestamp: inputData.devCommentList[i].regTimestamp
          }
          tmpArr.push(tmpData);
        }
        setCommentList([...tmpArr]);
      }
    } catch { }
  }

  // 개발 완료 모달 OK 함수 + 업무 담당자에게 알림 발송
  const onOkFinishDevButton = async () => {
    if (Object.keys(completeDev).length !== 2
      || completeDev === undefined
      || completeDev.schedule?.length === 0
      || completeDev.comment?.trim().length === 0)
      return alert(formatMessage({ id: 'T1598' })); //  내용을 입력해주세요
    dispatch(setVisible(true));
    const result = await fetchAbsolute("post", "/workflow/completeDev", {
      data: {
        "workflowId": params.workflowId,
        "startDt": completeDev.schedule[0].format("YYYY-MM-DD"),
        "endDt": completeDev.schedule[1].format("YYYY-MM-DD"),
        "comment": completeDev.comment
      }
    });
    if (result.error) {
      return alert(errorOccur);
    }

    // setFetchedData(result.data);
    setEditList(result.data.editList);
    dispatch(setWorkflowData(result.data));
    setFinishDevVisible(false);

    alert(formatMessage({ id: 'T1635' })); //개발이 완료되었습니다.
    dispatch(setVisible(false));

    //모달창 개발일정, 개발 내용 부분 지워주기
    setCompleteDev({});
  };

  //오류 사항 첨부파일 세팅
  const getErrorFileList = async () => {
    const fileIdList = editList?.map(val => val.fildId);
    const length = fileIdList?.length;
    let tmpArr = [];
    for (let i = 0; i < length; i++) {
      if (fileIdList[i]) {
        const { data } = await fetchAbsolute('get',
          `/file/listFile/${fileIdList[i]}`)
        tmpArr = [...tmpArr, ...data];
      }
    }

    setErrorFile(tmpArr);
  }
  
 //개발(WS20) & (개발 시작(WS21) || 수정 시작(WS23))일때
  const progressCondition = hfWorkflowLookup.workflowData?.statusCd === "WS20"
    && (hfWorkflowLookup.workflowData?.detailStatusCd === "WS21" || hfWorkflowLookup.workflowData?.detailStatusCd === "WS23");

  // 운영(WS40)& 수정 시작(WS55)일때
  const operModiCondition = hfWorkflowLookup.workflowData.statusCd === "WS40" && hfWorkflowLookup.workflowData.detailStatusCd === "WS55";

  // 개발(WS20) && 수정 요청(WS22)
  const requestCondition = hfWorkflowLookup.workflowData?.statusCd === "WS20" && hfWorkflowLookup.workflowData?.detailStatusCd === "WS22";

  //운영(WS40) && (운영 중 수정 요청(WS54) ||재수정 요청(WS57))일때
  const operRequestCondition = hfWorkflowLookup.workflowData.statusCd === "WS40"
    && (hfWorkflowLookup.workflowData.detailStatusCd === "WS54" || hfWorkflowLookup.workflowData.detailStatusCd === "WS57");

  //로그인한 사용자가 developer 일때
  const profileStatusDev = hfWorkflowLookup.workflowData.empLevGbns?.includes('D') || hfWorkflowLookup.workflowData.empLevGbns?.includes('A');

  //WS10 & WS11 (접수 & 접수완료)일때, 개발진행 영역 비활성화하기 위한 비활성화 조건
  const deactivateCondition = hfWorkflowLookup.workflowData.statusCd === "WS10" && hfWorkflowLookup.workflowData.detailStatusCd === "WS11";

  //맨 처음 접속했을때 세팅
  useEffect(() => {
    if(!hfWorkflowLookup.workflowData?.workflowId) return;

    let tempDate = [];
    const fromDate = moment();
    const toDate = moment().add(1, "months");
    tempDate.push(fromDate, toDate);

    // const range = data.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    setCompleteDev({ ...completeDev, schedule: tempDate });

    setCommentData(hfWorkflowLookup?.workflowData); //함수
    setEditList(hfWorkflowLookup?.workflowData.editList); //state

    //오류사항 파일리스트 세팅
    getErrorFileList();
  }, [hfWorkflowLookup.workflowData?.workflowId]);


  useEffect(() => {
    setEditList(hfWorkflowLookup.workflowData.editList);
  }, [hfWorkflowLookup.workflowData.editList]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    //첫 렌더링 일때는 getErrorFileList호출안하기
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    getErrorFileList();
  }, [editList]);

  useEffect(() => {
    //상태값이 바꼈을때, 개발완료 모달창 디폴트 날짜 세팅
    if ((progressCondition || operModiCondition) && profileStatusDev) {
      let tempDate = [];
      const fromDate = moment();
      const toDate = moment().add(1, "months");
      tempDate.push(fromDate, toDate);

      setCompleteDev({ ...completeDev, schedule: tempDate });
    }
  }, [progressCondition, operModiCondition])


  return (
    <>
      <Modal
        centered
        visible={commentDelVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T1660" })} // 문의사항 삭제
        onOk={onOKCommentDel}
        onCancel={onCancelCommentDel}
      >
        <Text>
          {/* 작성한 문의사항을 삭제하시겠습니까? */}
          {formatMessage({ id: 'T1659' })}
        </Text> 
      </Modal>
      <Modal
        centered
        visible={finishDevVisible}
        width={450}
        okText={confirmationText}
        title={formatMessage({ id: 'T0485' })} // 개발완료 확인
        onOk={onOkFinishDevButton}
        onCancel={onCancelFinishtDev}
      >
        <div style={{ textAlign: "center" }}>
          <Text>
            <FormattedMessage id="T1612" />
            {/* Workflow 영역의 저장 버튼을 클릭하셨나요? */}
          </Text>
          <br />
          <br />
          <Text style={{ color: "#e54156" }}>
            <FormattedMessage id="T1613" />
            {/* * 저장 버튼을 클릭하지 않고 개발 완료 하실 경우
            구성한 Workflow가 저장되지 않습니다. */}
          </Text>
          {/* <br />
          <Text style={{ color: "#e54156"}}>
            구성한 Workflow가 저장되지 않습니다.
          </Text> */}
        </div>
        <br />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <tbody>
            <tr>
              <th>
                <Text className="basic_row__title" required>
                  <FormattedMessage id="T1610" />{/* 소요 개발 일정 */}
                </Text>
              </th>
              <td>
                <RangePicker
                  value={completeDev?.schedule || []}
                  format="YYYY-MM-DD"
                  onChange={onChangeDates}
                />
              </td>
            </tr>
            <tr>
              <th>
                <Text className="basic_row__title" required>
                  <FormattedMessage id="T1611" />{/* 개발 내용 */}
                </Text>
              </th>
              <td>
                <TextArea
                  onChange={onAddComment}
                  value={completeDev.comment}
                />
              </td>
            </tr>
          </tbody>
        </div>
      </Modal>
      <div className={deactivateCondition ? "basic_box status__disabled" : "basic_box"}>
        <div className={deactivateCondition ? "basic_box__title status__disabled" : "basic_box__title"}>
          <Text className={deactivateCondition ? "sub_box__title status__disabled" : "sub_box__title"}>
            <FormattedMessage id="T0518" />{/* 3. [개발] 개발 진행 */}
          </Text>
        </div>
        <div className={deactivateCondition ? "basic_box__info status__disabled" : "basic_box__info"}>
          {/* workflow, 문의사항 부분 */}
          {/* 상세 진행 상태: WS21 or WS23일때 활성화*/}
          <ProgressDevelopment
            comment={comment}
            setComment={setComment}
            commentList={commentList}
            onOpenCommentDel={onOpenCommentDel}
            setCommentList={setCommentList}
            onClickComment={onClickComment}
            onClickDeleteComment={onClickDeleteComment}
            progressCondition={progressCondition}
            operModiCondition={operModiCondition}
            profileStatusDev={profileStatusDev}
            requestCondition={requestCondition}
            deactivateCondition={deactivateCondition}
          />
          {/* 수정사항 부분 */}
          {/* 상세 진행 상태: 수정요청(WS22인 경우) 활성화*/}
          <ProgressModification
            params={params}
            progressCondition={progressCondition}
            operRequestCondition={operRequestCondition}
            operModiCondition={operModiCondition}
            profileStatusDev={profileStatusDev}
            requestCondition={requestCondition}
            onClickFinishDevButton={onClickFinishDevButton}
            onCancelFinishtDev={onCancelFinishtDev}
            editList={editList}
            errorFile={errorFile}
            onClickRestart={onClickRestart}
            deactivateCondition={deactivateCondition}
            completeDev={completeDev}
            setCompleteDev={setCompleteDev}
          />
        </div>
      </div>
    </>
  );
};

export default withRouter(WorkflowLookupProgress);
