import React, { useEffect, useState } from "react";
import { Text, Input, Button, FormTable, RangePicker, Modal } from "components";
import { Input as AntInput, Select } from "antd";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { createBrowserHistory } from "history";
import { useIntl } from "react-intl";

const { TextArea } = AntInput;
const { Title } = Typography;
const { Option } = Select;

/**
 * Filename: RoundUpdate/index.js
 * Description:RoundUpdate
 * Item Shop 관리 > 회차 관리 > 회차 수정
 */

const RoundUpdate = ({ roundSeq }) => {
  const { formatMessage } = useIntl();
  const history = createBrowserHistory({ forceRefresh: true });

  const [Visible, setVisible] = useState(false);
  const [showYn, setShowYn] = useState("");
  const [disableDate, setDisableDate] = useState([]);
  const [selDateStatus, setSelDateStatus] = useState(true);
  const [betweenDate, setBetweenDate] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [date, setDate] = useState({
    roundFr: "",
    roundTo: "",
  });
  const [inputValues, setInputValues] = useState({
    roundSeq: "",
    round: "",
    note: "",
  });

  const onOkDelete = async () => {
    let fetchFlag = true;
    try {
      const deleteRound = await fetchAbsolute(
        "delete",
        `/academy/manageRoundsDel/${roundSeq}`
      );
      if (deleteRound.status === 200 || deleteRound.status === 204) {
        alert(formatMessage({ id: "T0312" })); // 회차가 삭제되었습니다.
        history.push("/admin/jediitem/roundlist");
        return;
      } else {
        throw new Error(deleteRound.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0313" })); // 회차 삭제에 실패하였습니다.
      }
    }
    setVisible(false);
  };

  const onClickDelete = () => {
    setVisible(true);
  };

  const onCancelDelete = () => setVisible(false);

  const onClickAdd = async () => {
    if (selDateStatus) {
      const saveList = await fetchAbsolute(
        "post",
        `/academy/manageRoundsSave`,
        {
          data: {
            roundSeq: roundSeq,
            round: inputValues.round,
            roundFr: date.roundFr,
            roundTo: date.roundTo,
            showYn: showYn,
            note: inputValues.note,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (saveList.error) {
        return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
      }
      alert(formatMessage({ id: "T0234" })); // 저장되었습니다.

      history.push("/admin/jediitem/roundlist");
    } else {
      return alert(formatMessage({ id: "T0311" })); // 이미 등록된 회차 날짜가 존재 합니다
    }
  };

  const onChangeShowYn = (e) => {
    setSelDateStatus(true);
    setShowYn(e);
  };

  const disabledDate = (current) => {
    let index = disableDate.findIndex(
      (date) => date === moment(current).format("YYYY-MM-DD")
    );
    //db에 있는 날짜가 일치 하는게 없다면 false
    return index === -1 ? false : true;
  };

  // Date는 두 개의 키 값을 변경하기 때문에 충돌 방지
  const onChangeDateRange = ([roundFr, roundTo]) => {
    setDate({
      roundFr: roundFr || "",
      roundTo: roundTo || "",
    });
  };
  const onChangeDates = (val) => {
    const range = val.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));

    if (range.length === 2) {
      const dateArray = [];
      let currentDate = moment(range[0]);
      let endDate = moment(range[1]);
      while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      let intersection = disableDate.findIndex((x) => dateArray.includes(x));
      //겹치는 날짜가 없을때
      if (intersection === -1) {
        onChangeDateRange(range);
        setSelDateStatus(true);
      }
      //겹치는 날짜가 있을때
      else {
        setSelDateStatus(false);
      }
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setSelDateStatus(true);
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const getDates = (dates) => {
    const dateArray = [];
    let currentDate;
    let endDate;
    dates.forEach((e) => {
      currentDate = moment(e.roundFr);
      endDate = moment(e.roundTo);
      while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
      setDisableDate(dateArray);
      return;
    });
  };

  useEffect(() => {
    const fetchRoundList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/academy/manageRoundsInfo?roundSeq=${roundSeq}`
      );
      const dateList = await fetchAbsolute(
        "get",
        "/academy/manageRoundsViewList"
      );
      setBetweenDate(
        [...dateList.data.manageRoundList].filter(
          (e) => e.roundSeq !== result.data.roundSeq
        )
      );

      setDataSource(result.data);
      setInputValues({
        roundSeq: result.data.roundSeq,
        round: result.data.round,
        note: result.data.note,
      });
      setDate({
        roundFr: result.data.roundFr,
        roundTo: result.data.roundTo,
      });
      setShowYn(result.data.showYn);
    };
    fetchRoundList();
  }, [roundSeq]);

  useEffect(() => {
    getDates(betweenDate);
  }, [betweenDate]);

  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <Title className="title_name" level={4}>
          {formatMessage({ id: "T0828" })}&nbsp;{/* 회차 수정 */}
        </Title>
        <div className="update_content">
          <FormTable border={true}>
            <colgroup>
              <col width="15%" />
              <col width="35%" />
              <col width="15%" />
              <col width="35%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0823" })}</Text>{/* 회차명 */}
                </th>
                <td colSpan={3}>
                  <div className="flex align-center">
                    <Input
                      maxLength={6}
                      name="round"
                      value={inputValues.round || ""}
                      onChange={onChangeInput}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0100" })}</Text>{/* 설명 */}
                </th>
                <td colSpan={3}>
                  <TextArea
                    name="note"
                    style={{ resize: "none" }}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    value={inputValues.note || ""}
                    onChange={onChangeInput}
                  />
                </td>
              </tr>

              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0826" })}</Text>{/* 기간 설정 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <RangePicker
                      size="large"
                      format="YYYY-MM-DD"
                      value={[
                        date.roundFr && date.roundFr.length > 0
                          ? moment(date.roundFr)
                          : undefined,
                        date.roundTo && date.roundTo.length > 0
                          ? moment(date.roundTo)
                          : undefined,
                      ]}
                      name="dates"
                      defaultValue={[undefined, undefined]}
                      disabledDate={disabledDate}
                      onCalendarChange={onChangeDates}
                      onChange={onChangeDates}
                    />
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0827" })}</Text>{/* 노출 여부 입력 */}
                </th>
                <td>
                  <div>
                    <Select
                      id="showYn"
                      style={{
                        width: "30%",
                      }}
                      value={showYn || ""}
                      onChange={onChangeShowYn}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 20,
        }}
      >
        <Button width="144" height="40" type="primary" onClick={onClickAdd}>
          {formatMessage({ id: "T0054" })}{/* 수정 */}
        </Button>
        &nbsp;&nbsp;
        <Button width="144" height="40" type="primary" onClick={onClickDelete}>
          {formatMessage({ id: "T0072" })}{/* 삭제 */}
        </Button>
        &nbsp;&nbsp;
        <Link to="/admin/jediitem/roundlist">
          <Button width="144" height="40">
            {formatMessage({ id: "T0051" })}{/* 취소 */}
          </Button>
        </Link>
      </div>
      <Modal
        width={540}
        visible={Visible}
        title={formatMessage({ id: "T0072" })} //삭제
        okText={formatMessage({ id: "T0072" })} //삭제
        onCancel={onCancelDelete}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0829" })}{/* 정말로 선택된 회차를 삭제하시겠습니까? */}
        </Text>
      </Modal>
    </div>
  );
};

export default RoundUpdate;
