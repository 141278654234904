import React, { useEffect, useRef, useState } from 'react'
import fetchAbsolute from "utils/fetchAbsolute";
import { PageTemplate, Header, Footer } from 'components';
import Wrapper from './Styled';
import MyPwdCheck from './MyPwdCheck';
import EditMyInfo from './EditMyInfo';
import MyInfo from './MyInfo';
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import { useSelector } from 'react-redux';

const initialInputValues = {
    orgCds: {},
    prePassword: '',
    password: '',
    rePassword: '',
}
/**
 * MyInformation page
 * Header.js에 프로필 사진 설정 > 내 설정
 * 자기 정보 확인하고 수정하는 페이지
 */
const MyInformation = ({ history, type }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const profile = useSelector(state => state.get('auth').get('profile'));

    const [isVisiblePwdPage, setIsVisiblePwdPage] = useState(false);
    const [inputValues, setInputValues] = useState({ ...initialInputValues });
    const [fileList, setFileList] = useState([]);
    const [pwCheck, setPwCheck] = useState('');
    const [password, setPassword] = useState('');
    const [previewSrc, setPreviewSrc] = useState('');

    const requireFields = {
        email: {
            ref: useRef(null),
            message: formatMessage({ id: 'T0602' }), // 이메일
            use: type === 'register',
        },
        name: {
            ref: useRef(null),
            message: formatMessage({ id: 'T0012' }), // 이름
            use: type === 'register',
        },
        phone: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1255' }), // 전화번호
            use: type === 'register',
        },
        prePassword: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1257' }), // 기존 비밀번호
            use: type === 'myinfo',
        },
        password: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1258' }), // 새 비밀번호
            use: true,
        },
        rePassword: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1260' }), // 새 비밀번호 확인
            use: true,
        },
    };

    const onClickGoCheckPwd = () => {
        setIsVisiblePwdPage(true);
    }

    const onClickGoBack = () => {
        setIsVisiblePwdPage(false);
        history.goBack();
    };

    const onChangeCheckPwd = (e) => {
        const { value } = e.target;
        setPassword(value);
    };

    const onBeforeupload = (inFile) => {
        const tmpArr = [inFile];
        setFileList([...tmpArr]);
        return false;
    };

    const onRemoveFile = (rmFile) => {
        const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
        setFileList([...tmpArr]);
    };

    const onClickConfirmPwd = async () => {
        if (password.length < 1) return alert(fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1257' }) } })); // 기존 비밀번호 입력
        const result = await fetchAbsolute('get', `/auth/checkPwd?password=${password}`);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        if (result.data.result === 'fail') return alert(result.data.message);
        if (result.data === 'success') {
            setIsVisiblePwdPage(false);
            history.push(`/edit/myaccount/modify/${profile.EMP_NO}`);
        }
    };

    const onChangePhone = (e) => {
        // 숫자만 입력하도록 정규화 검사 
        const { value } = e.target;
        // - 하이픈 보이게
        const onlyNumber = value.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
        setInputValues({ ...inputValues, cphoneNum: onlyNumber });
    }

    const onClickSubmit = async () => {
        if (inputValues.password !== inputValues.rePassword) {
            return alert(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // '비밀번호가 일치하지 않습니다'
        };

        const formData = new FormData();
        fileList?.forEach((file) => formData.append("file", file));

        const tmp = inputValues.cphoneNum;
        const saveOnlyNumber = tmp && tmp.replace(/\-/g, '');

        /*
            password : 기존 비밀번호
            newPassword : 새로운 비밀번호
            cphoneNum : 전화번호
        */
        formData.append('password', inputValues.prePassword);
        formData.append('newPassword', inputValues.password);
        formData.append('cphoneNum', saveOnlyNumber);

        const result = await fetchAbsolute('post', `/auth/userAdInfoOneselfUpdate`, {
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        if (result.error) return alert(result.data.message);
        else if (result.data.result === 'fail') {
            return alert(result.data?.message);
        } else {
            alert('내 정보가 수정되었습니다');
            return history.push('/');
        }
    }

    const onChangePassword = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    }

    const onChangeRePassword = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
        if (inputValues.password && inputValues.password !== value) {
            setPwCheck(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // 비밀번호가 일치하지 않습니다.
        } else {
            setPwCheck('')
        }
    }

    /* 이미지 미리보기 할 때 사용 */
    const onChangeImg = (e) => {
        if (e.fileList.length === 0) return;
        /* 업로드 칸에서 지울 경우에 */
        if (e.file.status === 'removed') {
            setPreviewSrc('');
        }
        setPreviewSrc(URL.createObjectURL(e.file));
    }

    useEffect(() => {
        setPreviewSrc('');
    }, [fileList]);

    return (
        <PageTemplate
            header={<Header />}
            footer={<Footer />}
            headerColor='none'
            menuTitle={
                type === 'myinfo' ? `${formatMessage({ id: 'T1267' })}` // 내 정보
                    : `${fmMessage({ id: 'T1264', values: { name: formatMessage({ id: 'T1267' }) } })}` // 내 정보 수정
            }
            depthList={
                [
                    `${formatMessage({ id: 'T1267' })}`, // 내 정보
                    type === 'modify' ? `${formatMessage({ id: 'T0054' })}`  // 수정
                        : `${formatMessage({ id: 'T0081' })}`  // 조회 
                ]
            }
        >
            <Wrapper>
                {
                    isVisiblePwdPage ?
                        <MyPwdCheck history={history} profile={profile} onClickGoBack={onClickGoBack}
                            onChangeCheckPwd={onChangeCheckPwd}
                            onClickConfirmPwd={onClickConfirmPwd}
                            password={password}
                        />
                        :
                        // 내 정보 수정할 때가 아니면 MemeberRegister로 이동
                        type === 'modify' ?
                            <EditMyInfo history={history} inputValues={inputValues} onChangePassword={onChangePassword} onChangeRePassword={onChangeRePassword} pwCheck={pwCheck} onChangePhone={onChangePhone} onClickSubmit={onClickSubmit} fileList={fileList} onRemoveFile={onRemoveFile} onBeforeupload={onBeforeupload} requireFields={requireFields} profile={profile} setInputValues={setInputValues} initialInputValues={initialInputValues}
                                onChangeImg={onChangeImg}
                                previewSrc={previewSrc} />
                            :
                            <MyInfo history={history} onClickSubmit={onClickSubmit} profile={profile} onClickGoCheckPwd={onClickGoCheckPwd} setIsVisiblePwdPage={setIsVisiblePwdPage}
                            />
                }
            </Wrapper>
        </PageTemplate >
    )
}

export default MyInformation;