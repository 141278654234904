import styled from "styled-components";

const Wrapper = styled.div`
  /* 공통 */
  .common__container {
    padding: 20px 27px 20px 27px;
    background: #fff;
  }
  .common__div {
    display: flex;
    justify-content: space-between;
  }
  .common__title {
    display: flex;
    font-size: 20px;
    font-weight: bold;
  }
  .common__button {
    background: inherit;
    border: none;
    place-self: flex-start;
    margin-left: -12px;
    color: #999999;
  }
  .main_basic__layers {
    padding-top: 20px;
  }
  /* Main 화면 설정 */
  .main_top__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 13.5px;
    background: #f8f8f9;
  }
  .main_top__left {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .main_top__display {
    display: flex;
    gap: 12px;
  }
  .user_thumbnail {
    display: flex;
  }
  .user_img__section span.anticon {
    display: flex;
  }
  .user_img__section:nth-child(2) {
    margin-left: -10px;
    z-index: 9;
  }
  .user_img__section:last-child {
    z-index: 10;
    margin-left: -10px;
  }
  .user_img__section span .ant-avatar .ant-avatar-circle .ant-avatar-icon { 
    display: flex;
  }

  /* Welcome 영역 - Carousel */
  .ant-carousel .slick-prev,
    .ant-carousel .slick-prev:hover {
      left: 10px;
      z-index: 2;
  }

  .ant-carousel .slick-next,
  .ant-carousel .slick-next:hover {
    right: 10px;
    z-index: 2;
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 10px;
  }

  .slick-dots li {
    height:10px;
    width:10px;
  }    

  .slick-dots li.slick-active {
    height:10px;
    width:10px;
  }
  
  .slick-dots li button {
    width: 9px;
    height: 9px;
    border-radius: 100%;
  }

  .slick-dots li.slick-active button {
    width: 9px;
    height: 9px;
    border-radius: 100%;
  }

  /* 개인화 영역*/
  .personal_area_whole_container {
    position: relative;
    border: 4px solid #d9d9d9;
    border-radius: 16px;
    padding: 10px 10px;
  }
  .personal_area_whole_container:before {
    content: "";
    position: absolute;
    bottom: -31px;
    left: 152px;
    width: 0;
    height: 0;
    border: 82px solid transparent;
    border-top-color: #d9d9d9;
    border-bottom: 0;
    border-right: 0;
    margin-left: -24.5px;
    margin-bottom: -51px;
  }
  .personal_area_whole_container:after {
    content: "";
    position: absolute;
    bottom: -17px;
    left: 36%;
    width: 0;
    height: 0;
    border: 85px solid transparent;
    border-top-color: #ffffff;
    border-bottom: 0;
    border-right: 0;
    margin-left: -13.5px;
    margin-bottom: -51px;
  }
  .personal_area__container {
    margin-bottom: 50px;
  }
  .personal_area {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .personal_box__container {
    border: 1px solid #d9d9d9;
    padding: 15px 15px;
    background: #f8f8f9;
  }
  .personal__title {
    font-size: 20px;
    font-weight: bold;
  }
  /* 개인화 영역 - personal box */
  .personal_box__title {
    font-weight: bold;
    font-size: 17px;
  }
  .box_title_button__container {
    display: flex;
    justify-content: space-between;
  }
  .personal_box__button {
    background: inherit;
    border: none;
    color: #b3b3b3;
    width: 40%;
  }
  .personal_box__content {
    font-size: 16px;
  }
  .challenging__container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }
  .challenging_content {
    display: flex;
  }
  .personal_box_leture__button {
    background: inherit;
    border: none;
    color: #b3b3b3;
    font-weight: bold;
    font-size: 16px;
  }
  /* 공통 > tabs */
  .ant-tabs-ink-bar {
    position: absolute;
    pointer-events: none;
    border-top: none;
    border-bottom: 5px solid #ff7a00 !important;
    background: none;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #ff7a00;
    font-weight: 500;
  }
  .ant-tabs-nav-wrap {
    border-bottom: 2px solid #e2e2e2;
  }
`;

export default Wrapper;