import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  width: 1600px;
  .popup_manage__top {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  .license__search_box {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .license__bottom {
    margin-top: 25px;
  }
  .license__info_box {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 10px;
  }
  .license__bottom td,
  .license__bottom th {
    height: 49px;
    padding-top: 0;
    padding-bottom: 0;
  }

  /* 테이블 영역 */
  .ant-spin-container {
    gap: 30px 0;
  }
  .ant-table {
    width: 100%;
  }
  .ant-table-thead > tr > th {
    height: 50px;
    padding: 16px 16px 15px 20.3px;
    color: #000;
  }
  .ant-table-tbody td {
    height: 50px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.87);
  }
  .ant-table-tbody td > span {
    font-size: inherit;
    color: inherit;
  }
  .ant-table-pagination.ant-pagination {
    padding-bottom: 30px;
  }

  .ant-switch-checked {
    background-color: #ff7b00;
  }
  // 검색 부분
  .search__container {
    padding: 0px;
    border: 2px solid rgb(208, 209, 214);
    border-radius: 14px;
  }
`;

export default Wrapper;
