import { getUniqueKey } from 'utils/Str';

export const SET_COMMUNITY_URL = getUniqueKey('SET_COMMUNITY_URL');
export const RESET_COMMUNITY_URL = getUniqueKey('RESET_COMMUNITY_URL');

export function setCommunityUrl(key, search) {
    return {
        type: SET_COMMUNITY_URL,
        key,
        search
    };
};

export function resetCommunityUrl() {
    return {
        type: RESET_COMMUNITY_URL
    };
};