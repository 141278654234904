import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import {
  Text,
  UserTag,
  AssignEnterBox,
  HFBpdIframe,
  HFBpdIframeModal,
  Button
} from "components";
import { Row, Select, Col, Input as AntInput } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import fetchAbsolute from "utils/fetchAbsolute";
import { getUniqueKey } from "utils/Str";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
const { Option } = Select;
//1. [접수] 기본 정보

const WorkflowLookupReceipt = ({
  profile
}) => {
  const { formatMessage } = useIntl();

  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));
  const taskMgrId = formatMessage({ id: "T0006" }); // 업무 담당자

  // 담당자, 등록자 프로필
  const [mainProfile, setMainProfile] = useState({});
  const [subProfiles, setSubProfiles] = useState([]);
  const [regProfile, setRegProfile] = useState({});

  //bpdData
  const [bpdData, setBpdData] = useState([]);
  const [selectedBpdId, setSelectedBpdId] = useState();
  const [selectedBpd, setSelectedBpd] = useState([]);

  const onSelectBpdData = (e) => {
    setSelectedBpdId(e);
    const selectedData = bpdData.filter(data => data.bpdId === e);
    setSelectedBpd(selectedData[0]);
  }

  // bpd 크게보기 모달 추가
  const [visible, setVisble] = useState(false);
  // 모달 열기
  const onOpenBpdIframe = () => setVisble(true);

  // 모달에서 확인, 취소 눌렀을때 이벤트
  const onOkBpdIframe = () => setVisble(false);
  const onCancelBpdIframe = () => setVisble(false);

  
  //hfWorkflowLookup.workflowData.workflowId 바뀔때마다 기본정보 세팅
  useEffect(() => {
    if(!hfWorkflowLookup.workflowData?.workflowId) return;

    const fetchUser = (empNum) => {
      return fetchAbsolute("get", `/common/memberNoList?empNo=${empNum}`);
    };

    // 담당자 프로필 세팅
    const setMainInfo = async () => {
      const mainInfo = hfWorkflowLookup.workflowData.wfMemberHFList?.find(
        (member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "N"
      );
      const subList = hfWorkflowLookup.workflowData.wfMemberHFList?.filter(
        (member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "Y"
      );

      //메인 담당자는 1명
      setMainProfile({
        NAME_KOR: mainInfo?.empNm,
        EMP_NO: mainInfo?.empNo,
        DEPT_NAME_KOR: mainInfo?.orgNm,
        FILE_ID: mainInfo?.empFileId,
      });
      
      //서브 담당자 여러명
      const tmpSubList =  subList.length >0 
        ? [
          ...subList?.map((v) => ({
            NAME_KOR: v.empNm,
            EMP_NO: v.empNo,
            DEPT_NAME_KOR: v.orgNm,
            FILE_ID: v.empFileId,
          }))
        ]
        : []

      setSubProfiles([...tmpSubList]);
    };

    // 등록자 프로필 세팅
    const setRegInfo = async () => {
      const regResult = await fetchUser(hfWorkflowLookup.workflowData.regId);
      const regInfo = regResult.data.mdFPAList[0];

      if (!regInfo) {
        return;
      }

      setRegProfile({
        NAME_KOR: regInfo.empNam,
        EMP_NO: regInfo.empNum,
        DEPT_NAME_KOR: regInfo.orgNam,
        FILE_ID: regInfo.fileId,
      });
    };

    setMainInfo();
    setRegInfo();

    //bpd데이터 세팅
    let tempBpdArr = [];
    hfWorkflowLookup.workflowData.bpdInfoList === undefined
    ? tempBpdArr = [] : tempBpdArr.push(...hfWorkflowLookup.workflowData.bpdInfoList);

    setBpdData(tempBpdArr);

    tempBpdArr.length >0 
    ? setSelectedBpdId(tempBpdArr[0].bpdId)
    : setSelectedBpdId('');

    setSelectedBpd(tempBpdArr[0]);
  }, [hfWorkflowLookup.workflowData.workflowId]);

  return (
    <>
      <HFBpdIframeModal
          type="lookup"
          data={selectedBpd}
          params={selectedBpd}
          // bpdId={selectedBpdId}
          visible={visible}
          onOkBpdIframe={onOkBpdIframe}
          onCancelBpdIframe={onCancelBpdIframe}
      />
        <div className="basic_box">
          <div className="basic_box__title">
            <Text>{formatMessage({ id: "T0491" })}</Text> {/* 1. [접수] 기본 정보 */}
          </div>
          <div className="basic_box__info">
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">{taskMgrId}(Main)</Text>
              </Col>
              <Col span={19}>
                <UserTag profile={mainProfile} contextVisible={true} />
              </Col>
            </Row>

            <Row>
              <Col span={5}>
                <Text className="basic_row__title">{taskMgrId}(Sub)</Text>
              </Col>
              <Col span={19} className="basic_row__content">
                {
                  subProfiles.length>0
                    &&subProfiles.map((member, index) => (
                    <UserTag profile={{ ...member }} key={index} />
                  ))
                }
              </Col>
            </Row>

            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0429" })} {/* 등록자 */}
                </Text>
              </Col>
              <Col span={19}>
                <UserTag profile={regProfile}/>
              </Col>
            </Row>

            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0513" })} {/* 첨부 파일 */}
                </Text>
              </Col>
              <Col span={19}>
                {
                  hfWorkflowLookup.workflowData.fileId
                  ? hfWorkflowLookup.workflowData.fileList?.map((file, index) => (
                    <div className="file__list">
                      <Text
                        key={index}
                        type="download"
                        download={file.down}
                        fileName={file.fileName}
                      >
                        <PaperClipOutlined />
                        {file.fileName}
                      </Text>
                    </div>
                  ))
                  : `${formatMessage({ id: "T0513" })} 없음 `
                }
              </Col>
            </Row>

            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  Process Design
                </Text>
              </Col>
              { 
                bpdData?.length > 0
                ? <Col 
                    span={19}
                    style={{display:"flex", alignContent:"center"}}
                  >
                  <Select
                    size="default"
                    style={{ width: 300, marginRight:"12px" }}
                    value={selectedBpdId===undefined?selectedBpd.bpdId:selectedBpdId}
                    onSelect={onSelectBpdData}
                  >  
                    {
                      bpdData.map((data,idx)=>{
                        return (
                          <Option value={data.bpdId} key={data.bpdId}>
                            {data.bpdNm}
                          </Option>)
                      })
                    }
                  </Select>
                  <Button
                      type="primary"
                      style={{ borderRadius: "4px"}}
                      onClick={onOpenBpdIframe}
                  ><FormattedMessage id="T1650" />{/* 크게 보기 */}
                  </Button>
                </Col>
                : 
                <Col span={19}>
                  <Text>{formatMessage({ id: "T1626" })} {/* 없음 */}</Text>
                </Col>
              }
            </Row>
            <Row>
                {
                  bpdData?.length > 0
                  &&
                  <AssignEnterBox title="Business Process Design">
                    <HFBpdIframe
                        profile={profile}
                        type='lookup'
                        // bpdList
                        data={selectedBpd}
                        // bpdId만 XX
                        params={selectedBpd}
                    />
                  </AssignEnterBox>
                }
            </Row>
          </div>
        </div>
    </>
  );
};

export default withRouter(WorkflowLookupReceipt);