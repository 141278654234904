import React, { useState, useEffect } from "react";
import { useIntl } from "react-intl";
import { Divider, Typography } from 'antd';
import { Pie } from '@ant-design/plots';
import fetchAbsolute from "utils/fetchAbsolute";
import { Empty } from 'components';

const { Title } = Typography;

/**
 * 대시보드 > 과제 현황 > 상태별 진척 현황
 * 
 */
const ProgressStatus = ({ standardDate, taskType, defaultTaskType }) => {
    const { formatMessage } = useIntl();
    // 상태별 진척 현황 데이터
    const [progressStatusData, setProgressStatusData] = useState([]);
    const [summaryData, setSummaryData] = useState({});
    const [progressStatus, setProgressStatus] = useState(false);

    const config = {
        appendPadding: 10,
        progressStatusData,
        angleField: 'value',
        colorField: 'type',
        radius: 1,
        innerRadius: 0.6,
        label: {
            type: 'inner',
            offset: '-50%',
            autoRotate: false,
            content: function content(_ref) {
                const percent = _ref.percent;
                return ""
                    .concat(_ref.value)
                    .concat(formatMessage({ id: 'T1537' })) // 건
                    .concat("\n")
                    .concat("(")
                    .concat((percent * 100).toFixed(1))
                    .concat("%")
                    .concat(")");
            },
            style: {
                fontSize: 12,
                textAlign: 'center',
            },
            labelLine: false,
        },
        legend: {
            position: "left",
            // layout: "horizontal", 
            itemSpacing: 10
        },
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
        ],
        statistic: {
            title: false,
            content: {
                content: ""
                    .concat(formatMessage({ id: 'T1225' })) // 총
                    .concat("&#58; ") // :
                    .concat(summaryData.totalCnt)
                    .concat(formatMessage({ id: 'T1537' })) // 건
                    .concat("\n")
                    .concat("\n")
                    .concat(formatMessage({ id: 'T0398' })) // 운영
                    .concat("&#58; ") // :
                    .concat(summaryData.operCnt)
                    .concat(formatMessage({ id: 'T1537' })), // 건
                style: {
                    whiteSpace: 'pre-wrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: 15,
                },
            },
        },
    };

    const fetchProgressStatus = async () => {
        // if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        // if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        //processType을 빈값 []로 남겨놓으면 빈 데이터 결과
        const result = await fetchAbsolute('post', `/dashboard/taskState/ProgressByState`, {
            data: {
                dataCollectionDate: standardDate,
                processType: tmpTaskType
            }
        });

        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        tmpArr.push({
            type: `${formatMessage({ id: 'T0050' })}`, // 등록
            value: + result.data.regCnt
        });
        tmpArr.push({
            type: `${formatMessage({ id: 'T0396' })}`, // 개발
            value: + result.data.devCnt
        });
        tmpArr.push({
            type: `${formatMessage({ id: 'T0397' })}`, // 테스트
            value: + result.data.testCnt
        });
        tmpArr.push({
            type: `${formatMessage({ id: 'T0398' })}`, // 운영
            value: + result.data.operCnt
        });
        tmpArr.push({
            type: `${formatMessage({ id: 'T0643' })}`, // 중지
            value: + result.data.stoppedCnt
        });

        setProgressStatusData([...tmpArr]);
        setSummaryData({
            totalCnt: result.data.totalCnt,
            operCnt: result.data.operCnt,
        });
    };

    //pie chart 데이터 없음 보여주는 로직 
    useEffect(() => {
        const temp = progressStatusData.map(e => e.value > 0).findIndex(v => v === true);
        if (temp === -1) {
            setProgressStatus(true);
        } else {
            setProgressStatus(false);
        }
    }, [progressStatusData]);

    useEffect(() => {
        fetchProgressStatus();
    }, [standardDate, taskType]);

    return (
        <div className='box__container'>
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1511" })}{/* 상태별 진척 현황 */}
            </Title>
            <Divider />
            {progressStatusData.length > 0 && (
                !progressStatus ?
                    <Pie {...config}
                        className="dashboard_graph" data={progressStatusData} style={{ height: "300px" }} /> : <Empty />
            )}
        </div>
    );
};
export default ProgressStatus;