import React from 'react';
import { Table as AntTable } from 'antd';
import styled from 'styled-components';
import { Spin } from 'components';
import './common.css';

const TableStyled = styled(AntTable)`
    table {
        table-layout: fixed;
    }
    .ant-table-thead>tr>th {
        position: relative;
        background: #eaeaed;
        border-bottom: 2px solid #e1e1e6;
    }
    th, td {
        height: 50px;
        font-size: 12px;
    }

    .ant-table {
        width: 100%;
        table-layout: fixed;
    }
    .ant-table-thead>tr>th:not(:last-child)::before {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: 1px;
        height: calc(100% - 16px);
        background-color: #ccccd4;
        transform: translateY(-50%);
        transition: background-color .3s;
    }
    .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on,
    .ant-table-thead>tr>th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on {
        color: rgb(255,123,0);
    }
    /* pagination 부분 */
    .ant-pagination-item-active { 
        border-color: #ff7b00;
        /* border-color: #0067ac;   */
    }  
    .ant-pagination-item:hover { 
        border-color: #ff7b00 !important;
        /* border-color: #0067ac !important */
    }
    &:hover {
        border-color: #ff7b00 !important;
        /* border-color: #0067ac !important */
    }
    .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
        color: #ff7b00;
        border-color: #ff7b00;
        /* color: #0067ac; */
        /* border-color: #0067ac; */
    }
    /* 컬럼에 체크 박스 있을 경우 */
    .ant-checkbox-indeterminate .ant-checkbox-inner::after { 
        background-color: #ff7b00; 
        /* background-color: #0067ac;  */
    }
    .ant-table-tbody > tr.ant-table-row-selected > td {
        background: #ffe1bf;
        /* background: rgb(0, 103, 172, 0.2); */
    }
    /* 필터 눌렀을 때 */
    .ant-table-filter-trigger.active {
        color: #ff7b00 !important;
        /* color: #0067ac !important; */
    }
    ${
        props => props.pagination && `
            .ant-spin-container {
                display: flex;
                flex-direction: column;
                align-items: ${props.align === 'center' ? 'center' : props.align === 'left' ? 'flex-start' : 'flex-end'};
                gap: 8px 0;
            }
            .ant-table-pagination.ant-pagination {
                float: unset;
                margin: 0;
            }
            .ant-spin-container .ant-table-fixed-header.ant-table {
                width: 100%;
            }
        `
    }
    ${
        props => !props.pageSizeVisible && `
            .ant-pagination-options {
                display: none;
            }
        `
    }
    
`;

const Table = ({ children, pagination=false, defaultPageSize=20, pageOptions={}, pageSizeVisible=true, align='center', ...args }) => {
    return (
        <TableStyled
            pagination={pagination ? {
                defaultPageSize: defaultPageSize ? defaultPageSize : 20,
                ...pageOptions
            } : false}
            align={align}
            pageSizeVisible={pageSizeVisible}
            {...args}
            loading={{ indicator: <div><Spin tip="Loading..." /></div>, spinning: !!args.loading}}
        >
            { children }
        </TableStyled>
    )
}

export default Table;