import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from "react-intl";
import { getCookie } from 'utils/cookie';
import { Select } from 'antd';
import { Text, Button, InputSearch, Image } from 'components';
import ActiveModal from './ActiveModal';

const END_POINT = process.env.REACT_APP_API_URI;

const { Option } = Select;

/**
 * Filename: MonitoringRealtime/Active.js
 * Description: 모니터링 -> 실시간 모니터링 컴포넌트 -> Active 탭 컴포넌트
 */

const Active = ({ 
    crInfo, 
    selectCr, 
    onChangeCrSelect, 
    activeData, 
    devicePool, 
    onClickResetButton, 
    onClickDevicePoolButton, 
    onClickSearchButton, 
    // diyDeviceList, 
    // onChangeDiyDevice 
}) => {
    const { formatMessage } = useIntl();
    const searchPlaceholder = formatMessage({ id: "T0039" });// 검색

    const [visibleMonitor, setVisibleMonitor] = useState(false);
    const [monitorTitle, setMonitorTitle] = useState('');

    const onCancelActiveModal = () => {
        setVisibleMonitor(false);
    }
    const onClickActiveBox = (data) => {
        setMonitorTitle(data.hostName);
        // setMonitorData({ ...data });
        setVisibleMonitor(true);
    }

    const [blobSrc, setBlobSrc] = useState('');
    const accessToken = getCookie('auth')?.accessToken;

    useEffect(() => {
        if(activeData?.data[0]?.hostName === undefined) return;

        let imgSrc = `${END_POINT}/monitoring/runner/currentFile?hostName=${activeData?.data[0]?.hostName}`;
        const inputImageInfo = async (src) => {
            const headers = new Headers();
            headers.append('Authorization', accessToken);

            /* 메인화면처럼 src가 빈 경우에 */
            if (src) {
                await fetch(src, { headers })
                    .then(response => response.blob())
                    .then(blobby => {
                        let objectUrl = window.URL.createObjectURL(blobby);
                        setBlobSrc(objectUrl);
                    });
            }
        }
        inputImageInfo(imgSrc);

        return () => {
            window.URL.revokeObjectURL(blobSrc);
        }
    }, [activeData]);

    return (
        <div className='realtime_box'>
            <ActiveModal
                visible={visibleMonitor}
                title={monitorTitle}
                onCancel={onCancelActiveModal}
                data={activeData}
            />
            <div className='realtime__top_menu'>
                <div className='top_menu__box'>
                    <Text className='top_menu__text'><FormattedMessage id="T1036" /></Text>{/* CR */}
                    <Select
                        size="default"
                        placeholder={formatMessage({ id: 'T0391' })} // 전체
                        defaultValue=""
                        style={{
                            width: 200,
                            height: 32
                        }}
                        value={selectCr.crCd}
                        onChange={onChangeCrSelect}
                    >
                        {crInfo.map(v => (
                            <Option value={v.crCd} key={v.crCd}>
                                {/* {v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + " (운영)" : v.crTypeDesc + " (개발)"} */}
                                {v.crDesc}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='top_menu__box'>
                    <Text className='top_menu__text'><FormattedMessage id="T1060" /></Text>{/* Device Pool */}
                    <InputSearch
                        placeholder={searchPlaceholder}
                        width={200}
                        height={32}
                        value={devicePool.map(d => d.dpNm).join(', ')}
                        onClick={onClickDevicePoolButton}
                    />
                </div>
                <div className="btn__section">
                    <Button className='top_menu__button' type='primary' onClick={onClickSearchButton}><FormattedMessage id="T0039" /></Button>{/* 검색 */}
                    <Button className='top_menu__button' onClick={onClickResetButton}><FormattedMessage id="T0037" /></Button>{/* 초기화 */}
                </div>
            </div>
            <div className='realtime__content_box content__active'>
                {
                    activeData.data.map((data, index) => (
                        <div className='active_box' onClick={() => onClickActiveBox(data)} key={index}>
                            <div className='active_box__top'>
                                <img src={blobSrc} alt='img_time_stamp'/>
                                {/* <Image src={`https://dev-api.dreago.net/atman-dwp-api/monitoring/runner/currentFile?hostName=${data.hostName}`} 
                                    alt='img_time_stamp'/> */}
                            </div>
                            <div className='active_box__bottom'>
                                <div className='active_box__bottom_row host_name'>
                                    <Text className='bottom_row__title'><FormattedMessage id="T1165" /> :</Text>{/* Host Name */}
                                    <Text className='bottom_row__content'>{data.hostName}</Text>
                                </div>
                                <div className='active_box__bottom_row task_name'>
                                    <Text className='bottom_row__title'>Task Name :</Text>
                                    <Text className='bottom_row__content'>{`${data.taskNm || ''}(${data.taskName.split('_')[0]})`}</Text>
                                </div>
                                <div className='active_box__bottom_row'>
                                    <Text className='bottom_row__title'>CPU :</Text>
                                    <Text className='bottom_row__content'>{data.cpuUse}%</Text>
                                </div>
                                <div className='active_box__bottom_row'>
                                    <Text className='bottom_row__title'>Disk :</Text>
                                    <Text className='bottom_row__content'>{Math.round(data.diskUse / 1024 / 1024 / 1024 * 100) / 100}GB / {Math.round(data.diskMax / 1024 / 1024 / 1024 * 100) / 100}GB ({Math.round(data.diskUse / data.diskMax * 100 * 100) / 100}%)</Text>
                                </div>
                                <div className='active_box__bottom_row'>
                                    <Text className='bottom_row__title'>Memory :</Text>
                                    <Text className='bottom_row__content'>{Math.round(data.memUse / 1024 * 100) / 100}GB / {Math.round(data.memMax / 1024 * 100) / 100}GB ({Math.round(data.memUse / data.memMax * 100 * 100) / 100}%)</Text>
                                </div>
                                <div className='active_box__bottom_row'>
                                    <Text className='bottom_row__title'>Resolution :</Text>
                                    <Text className='bottom_row__content'>{data.width} X {data.height}</Text>
                                </div>
                                <div className='active_box__bottom_row agnt_status agnt_started'>
                                    <Text className='bottom_row__title'>Agnt Status :</Text>
                                    <Text className='bottom_row__content'>{data.runnerAgentStatus}</Text>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    );
}

export default Active;