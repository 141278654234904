import React, { useEffect, useState } from "react";
import moment from "moment";
import { MonthlyCalendar, Loading, Text } from "components";
import { Badge } from "antd";

const AssignNewScheduleCalendar = ({
  date,
  onChangeDate,
  scheduleDatas,
  loading
}) => {
  const badgeColor = {
    WIP: "processing",
    COMPLETED: "success",
    RUN_FAILED: "error",
    Scheduled: "default",
  };

  const onSelectCalendar = (changeDate) => {
    /* 제일 먼저 날짜가 바뀌는 곳 */
    onChangeDate({
      yyyymm: changeDate.format("YYYYMM"),
      dd: changeDate.format("DD"),
      calendar: "daily",
      specificDay: changeDate.format("YYYYMMDD"),
    });
  };

  const getListData = (value) => {
    const dateString = value.format("YYYYMMDD");
    return scheduleDatas[dateString];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    return (
      <ul className="calendar__schedule_list">
        {listData?.map((item, index) => {
          return (
            <li key={item.id}>
              <Text className="calendar__schedule_name" key={index}>
                <Badge
                  status={badgeColor[item.runningStatus]}
                  text={`${item.taskNm}(${item.taskId})`}
                />
              </Text>
            </li>
          );
        })}
      </ul>
    );
  };

  /* datas 있어야 달력에 뿌릴 수 있으므로 */
  if (Object.keys(scheduleDatas).length === 0) {
    return <Loading visible={loading} />;
  }
  return (
    <div>
      <div>
        <MonthlyCalendar
          dateCellRender={dateCellRender}
          value={moment(date)}
          onSelect={onSelectCalendar}
        />
      </div>
    </div>
  );
};

export default AssignNewScheduleCalendar;
