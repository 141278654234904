import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { TimeTable } from "components";
import { FormattedMessage } from "react-intl";

const AssignNewScheduleDaily = ({
  location: { search },
  history,
  scheduleDatas,
  date = "",
  barColor,
  calInfo
}) => {
  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      key: 0,
      title: <FormattedMessage id="T1060" />, // Device Pool
      dataIndex: "poolName",
      fixed: true,
      width: 130,
      render: (data, record,index) => {
        const len = dataSource?.filter((v) => v.poolName === data).length;
        const idx = dataSource?.findIndex((v) => v.poolName === data);
        const obj = {
          children: record.visible ? data : "",
          props: {
            style: {
              fontSize: 14,
              color: "#000",
            },
          },
        };
        if (index === idx) {
          obj.props.rowSpan = len;
        } else {
          obj.props.rowSpan = 0;
        }
        if (!dataSource[dataSource.findIndex((v) => v.id === record.id) + 1]?.visible) {
          obj.props.style.borderBottom = "none";
        }

        return obj;
      },
    },
  ];

  // 컬럼 세팅
  for (let i = 0; i < 24; i++) {
    const obj = {
      key: i + 1,
      title: i.toString().length < 2 ? `0${i}:00` : `${i}:00`,
      dataIndex: "taskNm",
      width: 120,
      align: "center",
    };

    obj.render = (text, record, index) => {
      if (i === +record.startTime.split(":")[0]) {
        // const dateArr = record.startTime.split(":");
        // // const hourToMin = +dateArr[0] * 60;
        // // const totalMin = hourToMin + +dateArr[1];

        return {
          children: (
            <div
              className="schedule_daily__name_box"
              key={index}
              title={`${text}(${record.taskId})\nMin Running Time : ${record.minTime
                } sec\nMax Running Time : ${record.maxTime} sec\nRunningTime : ${record.runningTime || 0
                } sec`}
              style={{
                zIndex: 0,
                background: barColor[record.runningStatus] || "#fff3e8",
                color: "#000",
                width: "33px"
              }}
              onClick={() => onClickDailyName(record.taskId)}
            >
              {text}({record.taskId})
            </div>
          ),
          props: {
            style: {
              position: "unset",
            },
          },
        };
      } else {
        return {
          children: (
            <div className="schedule_daily__name_box">
            </div>),
          props: {
            style: {
              position: "unset",
            },
          },
        }
      }
    };
    columns.push({ ...obj });
  }

  const onClickDailyName = (taskId) => {
    history.push(`/assign/lookup/${taskId}/status`);
  };

  const getDatas = async () => {
    let prevData = -1;
    let visible = false;

    if (scheduleDatas[date]?.length === 0) {
      setDataSource([]);
    } else {
      setDataSource(
        Object.values(scheduleDatas)[0]?.map((data) => {
          if (prevData !== +data.poolId[0]) {
            prevData = +data.poolId[0];
            visible = true;
          }
          return {
            ...data,
            visible,
            devicePool: data.poolId[0],
          };
        })
      );
    }
  };

  useEffect(() => {
    if (Object.keys(scheduleDatas).length <= 0 || date.length <= 0 || calInfo.calendar !== 'daily') return;
    getDatas();
  }, [scheduleDatas, date, calInfo.calendar]);

  return (
    <div>
      <TimeTable
        bordered
        className="schedule_daily__table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      // scroll={{ x: 2000, y: 500 }}
      />
    </div>
  );
};

export default withRouter(AssignNewScheduleDaily);