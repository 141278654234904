import React, { useState, useEffect } from 'react';
import { Text, LinkModal } from 'components';
import { useDispatch } from 'react-redux';
import { setVisible } from 'store/loading/action';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from 'react-intl';

const TransformApproval = ({ match: { params } }) => {
    const { formatMessage } = useIntl();

    const { seq } = params;

    const dispatch = useDispatch();

    const [requestResult, setRequestResult] = useState({});

    useEffect(() => {
        const fetchSharedRequest = async () => {
            dispatch(setVisible(true));
            const result = await fetchAbsolute('get', `/handyflow/handyflowBpdToWf?reqSeq=${seq}`);
            dispatch(setVisible(false));
            setRequestResult({ ...result });
            /**
             * {
                "headers": {},
                "body": "Success!!!",
                "statusCodeValue": 200,
                "statusCode": "OK"
                }
             */
            if (result.data.statusCodeValue === 400) {
                alert(formatMessage({ id: 'T1638' })); // 이미 승인(반려) 되었습니다.
                return window.open('about:blank', '_self').self.close();
            }
            else return alert(formatMessage({ id: 'T0162' })); // 작업을 완료하였습니다.   
        }
        fetchSharedRequest();
    }, [seq]);

    if (Object.keys(requestResult).length === 0) {
        return (
            <></>
        )
    } else {
        return (
            <LinkModal
                visible={true}
                title=""
                width={540}
                closable={false}
                footer={[]}
                titleDisabled
                footerDisabled
            >
                <div className='request_box'>
                    <Text className='request_box__text'>
                        {
                            !requestResult.error ?
                                `${formatMessage({ id: 'T1590' })}`
                                //  `변환 승인 요청을 완료하였습니다.\n(변환 승인 작업에 1분 정도 시간이 소요됩니다.)`
                                :
                                requestResult.data?.message || `${formatMessage({ id: 'T0133' })
                                // 작업을 실패하였습니다.
                                }`
                        }
                    </Text>
                </div>
            </LinkModal>
        );
    }
}

export default TransformApproval;