import React from 'react';
import { useSelector } from 'react-redux';
import { Layout } from 'antd';
import { PageHeader, CommonStyled, Loading } from 'components';
import { getUniqueKey } from 'utils/Str';

const { Header, Footer, Content } = Layout;

/**
 * Application의 Layout을 지정하는 Template Component 예제
 * 
 * @class
 */
const PageTemplate = props => {
  const { header, footer, children, isPageHeader = true, isFooter = true, headerColor = '#fff', bodyColor = 'none', footerColor = 'none', depthList = [], menuTitle = '' } = props;
  const loading = useSelector(state => state.get(getUniqueKey('loading'))).get('visible');
  const { userLoading, taskLoading } = useSelector(state => state.get(getUniqueKey('permission')));

  return (
    <Layout className="layout" style={{ minHeight: "100vh", background: '#f9f9f9' }}>
      <CommonStyled>
        <Header style={{ position: 'relative', background: '#fff', padding: 0, height: 60 }}>{header}</Header>
        <Content style={{ minHeight: 'calc(100vh - 60px - 50px)', background: bodyColor }} id='section_for_pdf'>
          {
            isPageHeader && <PageHeader headerColor={headerColor} depthList={depthList} menuTitle={menuTitle} />
          }
          {
            (loading || userLoading || taskLoading) &&
            <Loading visible={true} />
          }
          <>
            {children}
          </>
        </Content>
        {
          isFooter && <Footer style={{ padding: 0, background: footerColor }}>{footer}</Footer>
        }
      </CommonStyled>
    </Layout>
  );
};

export default PageTemplate;