import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setVisible } from 'store/loading/action';
import { Text, Input, Modal } from "components";
import { Input as AntInput } from "antd";
import styled from 'styled-components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
const { TextArea } = AntInput;

/**
 * 사용자 소통 기능
 * 메세지 보내기
 */


const ModalStyled = styled(Modal)`
 .ant-modal-header {
     border-top: 7px solid #ff7b00;
 }
 .ant-modal-title {
     font-weight: bold;
 }
 .message__box {
   display: flex;
   align-items: baseline;
 }
 .message__box {
   margin-bottom: 10px;
 }
 .message__box:last-child {
   margin-bottom: 0;
 }
 .message__text {
   flex: 1;
   font-size: 14px;
 }
 .message__input {
   flex: 7;
 }
`


const SendMessage = ({ visible = false, onOk = () => { }, onCancel = () => { }, width = 500, toEmpNo = '', ...args }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const profile = useSelector((state) => state.get("auth").get("profile"));

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');

  const onChangeTitle = (e) => {
    const { value } = e.target;
    if (value.length > 255) return;
    setTitle(value);
  }

  const onChangeContent = (e) => {
    const { value } = e.target;
    setContent(value);
  }

  const onOkModal = async () => {
    if (title.trim().length === 0) return alert(formatMessage({ id: 'T0125' })) // 제목은 필수 입력입니다
    dispatch(setVisible(true));
    const { EMAIL: frEmail } = profile;
    const result = await fetchAbsolute('get', `/common/memberNoList?empNo=${toEmpNo}`);
    if (result.error) {
      alert(formatMessage({ id: 'T0118' })); // 사용자 정보를 가져오는데 실패하였습니다.
      return dispatch(setVisible(false));
    }
    const sendResult = await fetchAbsolute('post', `/mail/sendSimpleText`, {
      data: {
        sendFrom: frEmail,
        sendTo: [
          result.data.mdFPAList[0].emailAddr
        ],
        sendCc: [],
        sendBcc: [],
        shareGroupId: [],
        mailSubject: title,
        mailText: content
      }
    });

    if (sendResult.error || sendResult.data.result === 'Fail') {
      return alert(formatMessage({ id: 'T0119' })); // 메일 전송을 실패하였습니다
    }
    alert(formatMessage({ id: 'T0120' })); // 메일 전송을 완료했습니다.
    dispatch(setVisible(false));
    onOk();
  }

  useEffect(() => {
    if (visible) {
      setTitle('');
      setContent('');
    }
  }, [visible]);

  return (
    <ModalStyled
      width={width}
      visible={visible}
      title={formatMessage({ id: 'T0328' })} // 메일 보내기
      onOk={onOkModal}
      onCancel={onCancel}
      okText={formatMessage({ id: 'T1195' })} // 전송
      {...args}
    >
      <div className='message__box'>
        <Text className='message__text'>
          {formatMessage({ id: "T0017" })}{/* 제목 */}
        </Text>
        <Input
          className='message__input'
          value={title}
          onChange={onChangeTitle}
        />
      </div>
      <div className='message__box'>
        <Text className='message__text'>
          {formatMessage({ id: 'T0361' })}{/* 내용 */}
        </Text>
        <TextArea
          className='message__input'
          rows={10}
          value={content}
          onChange={onChangeContent}
        />
      </div>
    </ModalStyled>
  );
};

export default SendMessage;