import { SET_BOXWOODCONNECTOR_VALUE, SET_BOXWOODCONNECTOR_SPREAD, RESET_BOXWOODCONNECTOR_VALUE, SET_BOXWOODCONNECTOR_PAGEINFO } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */
const boxwoodConnectorInit = {
    inputValues: {
        connectorNameLike: '',
        activeState: 'ALL',
        categories: []
    },
    pageInfo: {
        field: 'updatedAt',
        direction: 'desc',
        startAt: 0,
        filteredLength: 20,
        total: 0,
    }
};

const boxwoodConnector = (state = boxwoodConnectorInit, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_BOXWOODCONNECTOR_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_BOXWOODCONNECTOR_PAGEINFO: {
            const pageInfo = {
                ...state.pageInfo,
                ...action.data
            };
            return {
                ...state,
                pageInfo
            }
        }
        case SET_BOXWOODCONNECTOR_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_BOXWOODCONNECTOR_VALUE: {
            const inputValues = {
                ...boxwoodConnectorInit.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        default:
            return state;

    }
}

export default boxwoodConnector;
