import { getUniqueKey } from 'utils/Str';

export const LOAD_TASK_DATA = getUniqueKey('LOAD_TASK_DATA');
export const LOAD_TASK_DATA_SUCCESS = getUniqueKey('LOAD_TASK_DATA_SUCCESS');
export const LOAD_TASK_DATA_FAIL = getUniqueKey('LOAD_TASK_DATA_FAIL');
export const SET_TASK_DATA = getUniqueKey('SET_TASK_DATA');
export const CHANGE_HISTORY_DATA = getUniqueKey('CHANGE_HISTORY_DATA');
export const TASK_INSTANT_START = getUniqueKey('TASK_INSTANT_START');
export const CHANGE_TASK_DATA = getUniqueKey('CHANGE_TASK_DATA');
export const CHANGE_SHCEDULE_STATUS = getUniqueKey('CHANGE_SHCEDULE_STATUS');
export const RESET_TASK_DATA = getUniqueKey('RESET_TASK_DATA');

export function loadTaskData(taskId) {
    return {
        type: LOAD_TASK_DATA,
        taskId
    }
}
export function loadTaskDataSuccess(taskData) {
    return {
        type: LOAD_TASK_DATA_SUCCESS,
        taskData
    }
}
export function loadTaskDataFail(error) {
    return {
        type: LOAD_TASK_DATA_FAIL,
        error
    }
}
export function setTaskData(data) {
    return {
        type: SET_TASK_DATA,
        data
    }
}
export function changeHistoryData(history, moreInfo = {}) {
    return {
        type: CHANGE_HISTORY_DATA,
        history,
        moreInfo
    }
}
export function taskInstantStart(data, cb = () => { }) {
    return {
        type: TASK_INSTANT_START,
        data,
        cb
    }
}
export function changeScheduleStatus(bool) {
    return {
        type: CHANGE_SHCEDULE_STATUS,
        bool
    }
}

export function resetTaskData() {
    return {
        type: RESET_TASK_DATA
    }
}