import React from 'react';
import { Select } from 'antd';
import { PlusCircleFilled, MinusCircleFilled } from '@ant-design/icons';
import { Text, Button } from 'components';
import { useIntl } from "react-intl";

const { Option } = Select;

/**
 * Filename: AdminCredential/CredentialTop.js
 * Description: Admin -> Credential 관리 -> 상단 컴포넌트
 */

const CredentialTop = ({ credentialName, credentialList, crType, crInfo, onChangeCredentialName, onChangeCrType, onClickAddAttribute, onClickAddCredential, onClickDeleteCredential }) => {
    const { formatMessage } = useIntl();

    return (
        <div className='credential_top__wrap'>
            <div className='credential_top__input_wrap'>
                <div className='credential_top__input_box'>
                    <Text className='credential_top__input_text'>{formatMessage({ id: "T1036" })}</Text>{/* CR */}
                    <Select
                        className='credential_top__input'
                        name='cr'
                        defaultValue='COE'
                        value={crType.crCd}
                        onChange={onChangeCrType}
                    >
                        {crInfo.map(v => (
                            <Option value={v.crCd} key={v.crCd}>
                                {/* {v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + " (운영)" : v.crTypeDesc + " (개발)"} */}
                                {v.crDesc}
                            </Option>
                        ))}
                    </Select>
                </div>
                <div className='credential_top__input_box'>
                    <Text className='credential_top__input_text'>Credential</Text>
                    <Select
                        showSearch
                        className='credential_top__input'
                        name='credential'
                        value={credentialName}
                        defaultValue=''
                        onChange={onChangeCredentialName}
                    >
                        <Option value=''>{formatMessage({ id: "T0535" })}</Option>{/* Credential을 선택하세요 */}
                        {
                            credentialList.map(v => (
                                <Option key={v.id} value={v.name}>{v.name}</Option>
                            ))
                        }
                    </Select>
                    {/* DIY 일떄 크리덴셜 추가 삭제 수정 불가 아이콘 안보이게 변경 */}
                    {!crType.crCd.includes("DIY") &&
                        <div className='credential_top__button_box'>
                            <PlusCircleFilled
                                className='credential_top__button'
                                style={{
                                    color: '#707070'
                                }}
                                onClick={onClickAddCredential}
                            />
                            <MinusCircleFilled
                                className='credential_top__button'
                                style={{
                                    color: '#707070'
                                }}
                                onClick={onClickDeleteCredential}
                            />
                        </div>
                    }
                </div>
            </div>
            {
                credentialName.length > 0 &&
                <Button
                    className='credential_top__button'
                    width='150'
                    height='40'
                    type='primary'
                    onClick={onClickAddAttribute}
                >
                    {formatMessage({ id: 'T0065' })}{/* ATTRIBUTE 추가 */}
                </Button>
            }
        </div>
    );
}

export default CredentialTop;