import React, { useEffect, useState } from "react";
import { SettingFilled } from "@ant-design/icons";
import { Text } from "components";
import Wrapper from "./Styled";
import UserIcon from "./UserIcon";
import MainGuideStep from "../GuideStep/MainGuideStep";
import Welcome from "./Welcome";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * 메인 화면 설정
 *
 */
const MainTop = ({ onClickSettingButton, profile }) => {
  const { formatMessage } = useIntl();
  const [peopleCnt, setPeopleCnt] = useState("");

  useEffect(() => {
    const getMgrCnt = async () => {
      const result = await fetchAbsolute("get", `/main/manager/getMgrCnt`);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setPeopleCnt(result.data.mgrCnt);
    };
    getMgrCnt();
  }, []);

  return (
    <Wrapper style={{ background: "#f0f2f5" }}>
      <div className="main_top__wrapper">
        <div className="main_top__left">
          <UserIcon profile={profile} />
          <Text style={{ fontSize: 12 }}>
            <FormattedMessage
              id="T0413" // {number}명이 myRPA를 업무시간에 활용해 시간을 절약하고 있어요.
              values={{
                number: peopleCnt,
              }}
            />
          </Text>
        </div>
        <div>
          <SettingFilled
            onClick={onClickSettingButton}
            style={{ fontSize: 18, paddingRight: 10 }}
          />
          <Text style={{ fontSize: 12 }}>{formatMessage({ id: "T0389" })}</Text>
          {/* Main 화면 설정 */}
        </div>
      </div>
      <div className="main_top__display">
        <Welcome />
        <MainGuideStep profile={profile} />
      </div>
    </Wrapper>
  );
};

export default MainTop;
