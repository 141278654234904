import React from "react";
import { Divider, Typography } from 'antd';
import { Empty } from 'components';
import { useIntl } from "react-intl";
import { Column } from '@ant-design/plots';

const { Title } = Typography;

/**
 * 대시보드 > Insight > 일별 Runner 사용률
 * 
 */


const DailyRunnerUsage = ({ runnerUsage }) => {
    const { formatMessage } = useIntl();
    const config = {
        isGroup: true,
        xField: 'date',
        yField: 'runnerUsage',
        seriesField: 'runnerNm',
        dodgePadding: 2,
        intervalPadding: 10,
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position', },
                { type: 'interval-hide-overlap', },
                { type: 'adjust-color', },
            ],
            style: {
                fontWeight: 500
            },
        },
        xAxis: {
            label: {
                autoRotate: false,
            },
        },
    }

    return (
        <div className='box__container'>
            {/* 일별 Runner 사용 횟수 */}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1530" })}
            </Title>
            <Divider />
            {
                runnerUsage.length > 0 ?
                    <Column {...config}
                        className="dashboard_graph"
                        data={runnerUsage}
                        style={{ height: 300, width: `calc(100px * ${runnerUsage.length})` }}
                    />
                    : <Empty />
            }
        </div>
    )
}
export default DailyRunnerUsage