import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    .ant-tabs-card .ant-tabs-content {
        margin-top: -17px;
    }
    .ant-tabs-nav-list {
        gap: 10px; 
    }
    .ant-tabs-nav-list .ant-tabs-tab,
    .ant-tabs-nav .ant-tabs-tab {
        width: 180px;
        text-align: center;
        background: #eaeaed;
    }
    .ant-tabs-nav-list .ant-tabs-tab:hover,
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #000;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
        background: #fff !important;
    }  
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, 
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
        border-radius: 6px 6px 0 0;
        border: 1px solid #d0d1d6;
        font-size: 16px;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        background: white !important;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000; 
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
        height: 40px;
        color: #000;
        background: #fff;
        border-bottom: none !important;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        margin: 0px 10px 0 0;
        background: #eaeaed;
        border: 1px solid #d0d1d6;
        border-radius: 10px 10px 0 0;
    }



    .satisfaction_box {
        background: #fff;
        min-height: 400px;
        border: 1px solid #d0d1d6;
        padding: 30px 60px;
    }
    .manage_box .manage_box__top {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;
    }

    .history_box .history_box__top {
        display: flex; 
        align-items: center;
        gap: 20px;
        padding-bottom: 20px;
    }
    .history_box .history_box__top .history_box__search_box {
        display: flex;
        align-items: center;
        gap: 15px;
    }
    .history_box__search_box .history_box__search_text {
        font-size: 12px;
        width: fit-content;
    }
    .history_box__search_box .history_box__date_box {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

export default Wrapper;