import React from "react";
import styled from "styled-components";
import { Modal, Text, Button, Spin } from "components";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * Filename: StatusInactive.js
 * Description: Inactive 러너 환경 보여주는 Modal
 */

const Wrapper = styled((props) => <Modal {...props} />)`
  .ant-table-wrapper {
    padding: 10px 40px;
  }
  .primary__button {
    width: 200px;
    height: 40px;
  }
  .default__button {
    height: 40px;
  }
  .ant-modal-footer button + button {
    margin-left: 0;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    gap: 15px;
  }
  .ant-table-title {
    display: none !important;
  }

  .inactive_box {
    padding: 9px 14px;
    width: 100%;
    min-height: 430px;
    border: 1px solid #d0d1d6;
    background: #fff;
    transition: 0.4s;
}
.inactive_box__top {
    width: 100%;
    overflow: hidden;
}
.inactive_box__top img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.inactive_box__bottom {
    display: flex;
    flex-direction: column;
    gap: 4px 0;
    padding-top: 10px;
}
.inactive_box__bottom_row span {
    font-size: 15px;
    word-break: break-all;
}
  .inactive_box__bottom_row.host_name .bottom_row__title {
    font-weight: bold;
    color: #ff6600;
  }
    .inactive_box__bottom_row.task_name .bottom_row__title {
        font-weight: bold;
        color: #ff7a00;
    }
    .inactive_box__bottom_row.agnt_status .bottom_row__title {
        font-weight: bold;
    }
    .inactive_box__bottom_row.agnt_status.agnt_started .bottom_row__content {
        color: #3dba61;
    }
    .inactive_box__bottom_row.agnt_status.agnt_empty .bottom_row__content {
        color: #2b65d9;
    }
    .inactive_box__bottom_row.agnt_status.agnt_failed.bottom_row__content {
        color: #ff0000;
    }

`;

const StatusInActiveModal = ({
    visible,
    onOk = () => { },
    onCancel = () => { },
    title = "",
    imgInfo = {},
    botStatusInfo = {},
}) => {
    const { formatMessage } = useIntl();

    return (
        <Wrapper
            width={1200}
            visible={visible}
            title={title}
            okText={formatMessage({ id: 'T1211' })} // Log 공유하기
            onCancel={onCancel}
            footer={[
                <Button key="back" className="default__button" onClick={onCancel}>
                    <FormattedMessage id="T1307" />{/* 닫기 */}
                </Button>,
            ]}
        >

            {/* {JSON.stringify(botStatusInfo) !== '{}' && loading  */}
            <div className='realtime__content_box content__inactive'>
                {JSON.stringify(imgInfo).length > 2 ?
                    <div className='inactive_box' >
                        <div className='inactive_box__top'>
                            <img src={`${imgInfo.fileName}`} alt='img_time_stamp' style={{ width: 1150 }} />
                        </div>
                        <div className='inactive_box__bottom'>
                            <div className='inactive_box__bottom_row host_name'>
                                <Text className='bottom_row__title'><FormattedMessage id="T1165" /> :</Text>{/* Host Name */}
                                <Text className='bottom_row__content'>{botStatusInfo.hostName}</Text>
                            </div>
                            <div className='inactive_box__bottom_row'>
                                <Text className='bottom_row__title'>CPU :</Text>
                                <Text className='bottom_row__content'>{botStatusInfo.cpuUse}%</Text>
                            </div>
                            <div className='inactive_box__bottom_row'>
                                <Text className='bottom_row__title'>Disk :</Text>
                                <Text className='bottom_row__content'>{Math.round(botStatusInfo.diskUse / 1024 / 1024 / 1024 * 100) / 100}GB / {Math.round(botStatusInfo.diskMax / 1024 / 1024 / 1024 * 100) / 100}GB ({Math.round(botStatusInfo.diskUse / botStatusInfo.diskMax * 100 * 100) / 100}%)</Text>
                            </div>
                            <div className='inactive_box__bottom_row'>
                                <Text className='bottom_row__title'>Memory :</Text>
                                <Text className='bottom_row__content'>{Math.round(botStatusInfo.memUse / 1024 * 100) / 100}GB / {Math.round(botStatusInfo.memMax / 1024 * 100) / 100}GB ({Math.round(botStatusInfo.memUse / botStatusInfo.memMax * 100 * 100) / 100}%)</Text>
                            </div>
                            <div className='inactive_box__bottom_row'>
                                <Text className='bottom_row__title'>Resolution :</Text>
                                <Text className='bottom_row__content'>{botStatusInfo.width} X {botStatusInfo.height}</Text>
                            </div>
                            <div className='inactive_box__bottom_row'>
                                <Text className='bottom_row__title'>Time :</Text>
                                <Text className='bottom_row__content'>{imgInfo.lastModifiedTimestamp}</Text>
                            </div>
                            <div className='inactive_box__bottom_row agnt_status agnt_started'>
                                <Text className='bottom_row__title'>Agent Status :</Text>
                                <Text className='bottom_row__content'>{botStatusInfo.runnerAgentStatus}</Text>
                            </div>
                        </div>
                    </div>
                    : <Spin tip='Loading...' />
                }
            </div>

        </Wrapper>
    );
};

export default StatusInActiveModal;