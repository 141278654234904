import { SET_BOXWOODWORKFLOWlIST_VALUE, SET_BOXWOODWORKFLOWLIST_SPREAD, RESET_BOXWOODWORKFLOWLIST_VALUE, SET_BOXWOODWORKFLOWLIST_FILTERID } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */
const boxwoodWorkflowListInitial = {
    inputValues: {
        bpdId: '',
        bpdNm: '',
        bpdOrgCd: '',
        workId: '',
        regId: '',
        regFr: '',
        regTo: '',
        regType: ''
    },
    filterId: '',
    pageInfo: {
        order: 'regTimestamp desc',
        currentPage: 1,
        rowPerPage: 20,
        tab: 'all'
    },
    tableData: [],
    taskCount: {
        totalCount: 0,
        myTaskCount: 0,
        interestTaskCount: 0,
    },
};

const boxwoodAssignList = (state = boxwoodWorkflowListInitial, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_BOXWOODWORKFLOWlIST_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            };
        }
        case SET_BOXWOODWORKFLOWLIST_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            };
        }
        case RESET_BOXWOODWORKFLOWLIST_VALUE: {
            const inputValues = {
                ...boxwoodWorkflowListInitial.inputValues
            };
            return {
                ...state,
                inputValues
            };
        }
        case SET_BOXWOODWORKFLOWLIST_FILTERID: {
            return {
                ...state,
                filterId: action.filterId
            };
        }
        default:
            return state;

    };
};

export default boxwoodAssignList;
