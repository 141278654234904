import React, { useState, useEffect, useReducer, useRef } from "react";
import { Pie } from "@ant-design/charts";
import { Link, withRouter } from "react-router-dom";
import {
  Modal,
  Text,
  TypePicker,
  InputSearch,
  Button,
  Table,
  LogModal,
  FailedLogModal,
  Organization,
  RealtimeModal,
  Empty,
  Loading,
  RedoModal
} from "components";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { Select, Tooltip, Input as AntdInput } from "antd";
import {
  ProcessingIcon,
  SuccessIcon,
  FailedIcon,
  MonitoringIcon,
  LogIcon,
  RestartIcon,
  PopupWaitIcon,
  RunningTaskRunAgainIcon,
  PauseRunningTaskIcon,
  StopRunningTaskIcon,
  RetryBizIcon,
  PauseForResultIcon,
  // StopForResultIcon
} from "icons";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;
const { TextArea } = AntdInput;

/**
 * Filename: AssignOperation.js
 * Description: 모니터링 > 운영 현황 모니터링 > 과제 운영 모니터링
 */


const AssignOperation = ({
  onReset,
  onOpenDepartment,
  department,
  onClickSearchButton,
  searchDepartment,
  performPermission,
  profile,
  performLoading,
}) => {
  const { formatMessage } = useIntl();
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const permission = useSelector((state) => state.get(getUniqueKey("permission")));

  // 실패 사례 분석 Select 데이터들
  const latelyTypeList = [
    {
      text: formatMessage({ id: "T1292" }), //전일
      value: "yesterday",
    },
    {
      text: formatMessage({ id: "T0045" }), // 일주일
      value: "week",
    },
    {
      text: formatMessage({ id: "T0584" }), // 한달
      value: "month",
    },
    {
      text: formatMessage({ id: "T0585" }), // 년
      value: "year",
    },
  ];

  const [config, setConfig] = useState({});
  // 검색 조건
  const [selectDate, setSelectDate] = useState(moment());
  const [searchDate, setSearchDate] = useState(moment().format("YYYYMMDD"));
  const [orgaData, setOrgaData] = useState([]);
  const [searchOrga, setSearchOrga] = useState([]);
  const [latelyType, setLatelyType] = useState(latelyTypeList[0]);
  const [performPieStatus, setPerformPieStatus] = useState(false);
  // 데이터들
  // Bot 수행 상태 데이터
  const [performStatusData, setPerformStatusData] = useState([]);
  // 실패 사례 분석 데이터
  const [failCaseData, setFailCaseData] = useState([]);
  // Bot 수행 내역 데이터
  const [botPerformData, setBotPerformData] = useState([]);
  // Bot 수행 내역 ROW 데이터
  const [botPerformRowInfo, setBotPerformRowInfo] = useState([]);
  const [devicePool, setDevicePool] = useState([]);
  const [preRecordData,setPreRecordData] = useState({});
  const [recordData, setRecordData] = useState([]);
  const [workItemList, setWorkItemList] = useState([]);
  // 모달
  const [logVisible, setLogVisible] = useState(false);
  const [failLogVisible, setFailLogVisible] = useState(false);
  const [redoVisible, setRedoVisible] = useState(false);
  const [restoreVisible, setRestoreVisible] = useState(false);
  const [orgaVisible, setOrgaVisible] = useState(false);
  const [replayVisible, setReplayVisible] = useState(false);
  // 각각 로딩
  const [performPieStatusLoading, setPerformPieStatusLoading] = useState(false)
  const [failCaseLoading, setFailLoading] = useState(false)
  const [botPerformDataLoading, setBotPerformLoading] = useState(false);
  const [orgaDataLoading, setOrgaDataLoading] = useState(false);
  const [logCrEnv, setLogCrEnv] = useState("");
  const [replayData, setReplayData] = useState({
    taskId: "",
    workItemId: "",
    cr: "",
  });

  const taskId = formatMessage({ id: "T0001" }); // 과제 ID
  const taskNm = formatMessage({ id: "T0002" }); // 과제명
  const taskMgrId = formatMessage({ id: "T0006" }); // 업무 담당자
  const operDate = formatMessage({ id: "T0011" }); // 수행일
  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const recordUpdateErr = formatMessage({ id: "T0191" }); // 레코드 데이터를 수정 하는 중 오류가 발생하였습니다.
  const scheduleReSuccess = formatMessage({ id: "T0192" }); // 스케줄 재실행이 완료되었습니다.
  const scheduleReFail = formatMessage({ id: "T0193" }); // 스케줄 재실행에 실패하였습니다.

  const modalTitle = `${botPerformRowInfo.taskId} ${formatMessage({
    id: "T0599",  // 과제 재실행
  })}`;

  const onCancelRestore = () => {
    setRestoreVisible(false);
  };

  const onClickRestore = () => {
    setRedoVisible(false);
    setRestoreVisible(true);
  };

  const onOkRestore = () => {
    setRestoreVisible(false);
    setRedoVisible(true);
    const fetchRestore = async () => {
      const cr = crInfo.find(
        (v) => v.crType === botPerformRowInfo.taskType && v.crEnvGubun === "PRD"
      );
      const result = await fetchAbsolute(
        "get",
        `/hwlm/WorkItemInit?workItemId=${botPerformRowInfo.workItemId}&xEnv=${cr.crXEnv}&processId=${botPerformRowInfo.taskId}`
      );
      const tmp = result.data.resultData.initRecordDataMap;
      const arr = Object.entries(tmp).map((v) => ({
        key: v[0],
        value: v[1],
      }));
      setRecordData([...arr]);
    };
    fetchRestore();
  };

  const onOkOrga = (data) => {
    if (data.length > 0) {
      setOrgaData([...data]);
    } else {
      setOrgaData([]);
    }
    setOrgaVisible(false);
  };

  const onCancelOrga = () => {
    setOrgaVisible(false);
  };

  const onOpenOrga = () => {
    setOrgaVisible(true);
  };

  const fetchWorkItemList = async (taskId, xEnv) => {
    const result = await fetchAbsolute(
      "post",
      `/hwlm/WorkItem?x-environment=${xEnv}`,
      {
        data: {
          page: {
            offset: 0,
            length: 1000,
          },
          sort: [
            {
              field: "creationDate",
              direction: "desc",
            },
          ],
          filter: {
            operands: [
              {
                operator: "eq",
                field: "processId",
                value: taskId,
              },
            ],
          },
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return result;
  };

  /* 데이터 가공해주기 */
  const orgCd = department.map((data) => {
    const tmpArr = [];
    tmpArr.push(data.codeId)
    return tmpArr;
  });

  const empNo = orgaData.map((data) => {
    const tmpArr = [];
    tmpArr.push(data.empNum);
    return tmpArr;
  });

  // 업무 수행 내역
  const getBotResult = async () => {
    setBotPerformLoading(true);
    const botResult = await fetchAbsolute(
      "post",
      `/monitor/taskOperation/getBotPerformListMulVal`,
      {
        data: {
          orgCd: orgCd.flat(),
          empNo: empNo.flat(),
          performDate: selectDate.format("YYYYMMDD"),
        },
      }
    );

    setBotPerformLoading(false);
    setBotPerformData([...botResult.data]);
  };

  //실패 사례 분석
  const getFailedResult = async () => {
    setFailLoading(true);

    const failedResult = await fetchAbsolute(
      "post",
      `/monitor/taskOperation/getFailCaseAnalysisListMulVal`,
      {
        data: {
          orgCd: orgCd.flat(),
          empNo: empNo.flat(),
          performDate: selectDate.format("YYYYMMDD"),
          dateType: latelyType.value,
        },
      }
    );

    setFailLoading(false);
    setFailCaseData([...failedResult.data]);
  }

  // 업무 수행 상태
  const getStatusResult = async () => {
    setPerformPieStatusLoading(true);
    const statusResult = await fetchAbsolute(
      "post",
      `/monitor/taskOperation/getBotPerformStatusMulVal`,
      {
        data: {
          orgCd: orgCd.flat(),
          empNo: empNo.flat(),
          performDate: selectDate.format("YYYYMMDD"),
        },
      }
    );
    setPerformPieStatusLoading(false);
    const tmpArr = [];
    tmpArr.push({
      type: formatMessage({ id: "T0403" }), // 성공
      value: +statusResult.data.doneStatusCnt,
    });
    tmpArr.push({
      type: formatMessage({ id: "T0404" }),  // 실패
      value: +statusResult.data.failedStatusCnt,
    });
    setPerformStatusData([...tmpArr]);

    setConfig({
      angleField: "value",
      data: tmpArr,
      colorField: "type",
      radius: 0.8,
      label: {
        type: "inner",
        offset: "-30%",
        autoRotate: false,
        content: function content(_ref) {
          const percent = _ref.percent;
          return ""
            .concat(_ref.value)
            .concat("\n")
            .concat((percent * 100).toFixed(1))
            .concat("%");
        },
        style: {
          fontSize: 15,
          textAlign: "center",
        },
      },
      legend: {
        position: "left-bottom",
        layout: "vertical",
      },

      color: (_ref) => {
        const { type } = _ref;
        switch (type) {
          case `${formatMessage({ id: "T0403" })}`: // 성공
            return "#ff9a26";
          case `${formatMessage({ id: "T0404" })}`: // 실패
            return "#E54156";
          case `${formatMessage({ id: "T0580" })}`: // 예약
            return "#0E5A8C";
          default:
        }
      },
      interactions: [{ type: "element-active" }],
    })
  }

  const onChangeDate = (date) => {
    setSelectDate(date);
  };

  const onChangeLatelyType = (type) => {
    const tmpObj = latelyTypeList.find((v) => v.value === type);
    setLatelyType({ ...tmpObj });
  };

  const onClickSearch = () => {
    const dateString = selectDate.format("YYYYMMDD");
    setSearchDate(dateString);
    setSearchOrga([...orgaData]);
    onClickSearchButton();
  };

  /* 검색 초기화 기능 */
  const onClickSearchReset = () => {
    //권한이 없으면 true 있으면 false
    const flag = permission.user.findIndex((v) => v.fnCode === "FN29") !== -1;

    // department 초기화
    onReset();
    // 수행일 초기화
    setSelectDate(moment());
    setSearchDate(moment().format("YYYYMMDD"));

    // 실폐 사례 분석 type 리스트 디플트값으로 초기화
    setLatelyType(latelyTypeList[0]);

    // 업무담당자 초기화
    if (flag) {
      setOrgaData([]);
      setSearchOrga([]);
    }
  };

  const onClickLog = (data) => {
    setBotPerformRowInfo(data);
    setLogCrEnv(
      crInfo.find((v) => v.crType === data.taskType && v.crEnvGubun === "PRD")
        ?.crXEnv
    );
    setLogVisible(true);
  };
  const onClickFailLog = (data) => {
    setBotPerformRowInfo(data);
    setLogCrEnv(
      crInfo.find((v) => v.crType === data.taskType && v.crEnvGubun === "PRD")
        ?.crXEnv
    );
    setFailLogVisible(true);
  };
  const onOkFailLog = () => setFailLogVisible(false);
  const onOkLog = () => setLogVisible(false);
  const onCancelLog = () => setLogVisible(false);
  const onCancelFailLog = () => setFailLogVisible(false);

  const onCancelRedo = () => setRedoVisible(false);

  const onClickRedo = async (record) => {
    const { taskId, taskType } = record;
    const result = await fetchAbsolute(
      "get",
      `/auth/userTaskAuthList?empCd=${profile.EMP_NO}&processId=${taskId}&taskType=${taskType}`
    );
    if (result.error) {
      return alert(formatMessage({ id: "T0318" }));
      //해당 과제의 재요청 권한이 없습니다
    }
    if (!result.data.includes("FN24")) {
      return alert(formatMessage({ id: "T0318" }));
      //해당 과제의 재요청 권한이 없습니다
    }

    const xEnv = crInfo.find(
      (v) => v.crType === taskType && v.crEnvGubun === "PRD"
    )?.crXEnv;

    const { data: { list: workItemList } } 
      = await fetchWorkItemList(record.taskId, xEnv);

    const tmp = workItemList.find((v) => v.id.toString() === record.workItemId);
    const arr = Object.entries(tmp.recordDataMap).map((v) => ({
      key: v[0],
      value: v[1],
    }));

    setBotPerformRowInfo(record);
    setWorkItemList(tmp);
    setPreRecordData({...tmp.recordDataMap})
    setRecordData([...arr]);
    setRedoVisible(true);
  };

  const onClickAdd = () => {
    const tmpArr = [...recordData];
    tmpArr.push({
      key: "",
      value: "",
    });
    setRecordData([...tmpArr]);
  };

  const onClickDelete = (e, index) => {
    const tmpArr = [...recordData];
    const survivedArr = tmpArr.filter(v => v !== tmpArr[index]);
    if (survivedArr.length === 0) {
      return alert(formatMessage({ id: 'T0124' })); // 한 칸 이하일 수는 없습니다.
    }
    setRecordData([...survivedArr]);
  }
  
  const onClickReplay = (record) => {
    setReplayData({
      taskId: record.taskId,
      workItemId: record.workItemId,
      cr: crInfo.find(
        (v) => v.crType === record.taskType && v.crEnvGubun === "PRD"
      ),
    });
    setReplayVisible(true);
  };

  const onOkReplay = () => {
    setReplayVisible(false);
  };
  const onCancelReplay = () => {
    setReplayVisible(false);
  };

  /* 재실행 모달에서 onChange [key, value] */
  const onChangeRecordKey = (e) => {
    const { index } = e.target.dataset;
    const tmpArr = [...recordData];
    tmpArr[index].key = e.target.value;
    setRecordData([...tmpArr]);
  };
  const onChangeRecordValue = (e) => {
    const { index } = e.target.dataset;
    const tmpArr = [...recordData];
    tmpArr[index].value = e.target.value;
    setRecordData([...tmpArr]);
  };

  const onOkRedo = () => {
    setRedoVisible(false);

    const poolName = devicePool.find(
      (v) => v.devicePoolId === workItemList.devicePoolId?.toString()
    ).devicePoolName;
    const cr = crInfo.find(
      (v) => v.crType === botPerformRowInfo.taskType && v.crEnvGubun === "PRD"
    );

    let tmp;
    if (recordData[0].key !== "") {
      tmp = recordData.reduce(
        (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
        {}
      );
    }
    
    const fetchEdit = async () => {
      const parameter = {
        processId: workItemList.processId,
        recordData: { ...tmp },
        preRecordData: { ...preRecordData },
      };

      const result = await fetchAbsolute(
        "put",
        `/hwlm/WorkItem?x-environment=${cr.crXEnv}&workItemId=${workItemList.id}`,
        {
          data: { ...parameter },
          headers: { "Content-Type": "application/json" },
        }
      );
      if (result.error) {
        return alert(recordUpdateErr);
      }
    };

    const fetchRequest = async () => {
      const parameter = {
        taskId: workItemList.processId,
        cr: cr.crCd,
        poolName,
        automationPriority: "PRIORITY_HIGH",
        scheduleType: "INSTANT",
        timeZone: "Asia/Seoul",
        retryWorkItemIds: [workItemList.id],
        botType: "Child",
        userInput: {...tmp}
      };

      const result = await fetchAbsolute(
        "post",
        `/task/oracle/addTaskSchedule?crType=${logCrEnv}`,
        {
          data: { ...parameter },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.data.result === "fail") {
        const confirmResult = window.confirm(
          `${result.data?.message} \n${formatMessage({ id: "T1301" })}`
        ); // 대기 시간 동안 기다리시겠습니까?
        if (confirmResult) {
          const waiting = async () => {
            const result = await fetchAbsolute(
              "post",
              "/task/oracle/waitInstantAction",
              {
                data: { ...parameter },
                header: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
          };
          waiting();
        }
      } else if (result.error) {
        return alert(scheduleReFail);
      } else {
        alert(scheduleReSuccess);
      }
    };

    fetchEdit();
    fetchRequest();
    fetchEdit();
  };

  const caseColumns = [
    {
      title: taskId,
      dataIndex: "taskId",
      key: "taskId",
      width: 80,
      render: (taskId, record) => {
        return (
          <Link to={`/assign/lookup/${taskId}`} target="_blank"
            rel="noopener noreferrer">
            <div>{taskId}</div>
          </Link>
        );
      },
    },
    {
      title: taskNm,
      dataIndex: "taskNm",
      key: "taskNm",
      width: 320,
      render: (taskNm, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`} target="_blank"
            rel="noopener noreferrer">
            <Tooltip title={taskNm}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  width: 320,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {taskNm}
              </div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: formatMessage({ id: "T0581" }), // 실패횟수
      dataIndex: "failCnt",
      key: "failCnt",
      width: 75,
    },
    {
      title: formatMessage({ id: "T1036" }), // CR 
      width: 55,
      dataIndex: "taskType",
      key: "taskType",
      render: (data, record, index) => (data === "DIY" ? "DIY" : data),
    },
    {
      title: formatMessage({ id: "T1075" }), // runner  
      dataIndex: "runner",
      key: "runner",
    },
    {
      title: formatMessage({ id: "T0582" }), // 비고
      width: 50,
      render: (data, record, index) => (
        <div key={index}>
          <LogIcon
            className="abled_icons"
            onClick={() => onClickFailLog(data)}
          />
        </div>
      ),
    },
  ];
  const runColumns = [
    {
      title: taskId,
      dataIndex: "taskId",
      key: "taskId",
      width: 80,
      render: (taskId, record) => {
        return (
          <Link to={`/assign/lookup/${taskId}`} target="_blank"
            rel="noopener noreferrer">
            <div>{taskId}</div>
          </Link>
        );
      },
    },
    {
      title: taskNm,
      dataIndex: "taskNm",
      key: "taskNm",
      width: 360,
      render: (taskNm, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`} target="_blank"
            rel="noopener noreferrer">
            <Tooltip title={taskNm}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  width: 360,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {taskNm}
              </div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: formatMessage({ id: "T0400" }), // 시작시간
      dataIndex: "botStartDate",
      key: "botStartDate",
      width: 140,
      render: (text) =>
        !text ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: formatMessage({ id: "T0401" }), // 종료시간
      dataIndex: "botEndDate",
      key: "botEndDate",
      width: 140,
      render: (text, data) => (!text||data.status==="New"||data.status==="WIP"||data.status==="Pause")
                ? "" 
                :moment(text).format("YYYY-MM-DD HH:mm:ss")
    
    },
    {
      title: formatMessage({ id: "T0583" }), // 소요시간(분)
      dataIndex: "time",
      key: "time",
      align: "center",
      width: 110,
    },
    {
      title: formatMessage({ id: "T0350" }), // 실행상태
      key: "status",
      align: "center",
      dataIndex: "status",
      render: (text) => (
        <div className="operation_botstatus__result_wrap">
          { 
            text === "New" ? (
            <>
              <PopupWaitIcon />
              <Text>{formatMessage({ id: "T1667" })}</Text>  {/* 준비중 */}
            </>
          ) :
            text === "WIP" ? (
            <>
              <ProcessingIcon />
              <Text>{formatMessage({ id: "T0568" })}</Text> {/* 진행중 */}
            </>
          ) : 
          text === "Failed" ? (
            <>
              <FailedIcon />
              <Text>{formatMessage({ id: "T0404" })}</Text>  {/* 실패 */}
            </>
          ) : text === "Complete" ? (
            <>
              <SuccessIcon />
              <Text>{formatMessage({ id: "T0403" })}</Text>  {/* 성공 */}
            </>
          ) : text === "RetryBiz" ? (
            <>
              <RetryBizIcon />
              <Text style={{ fontSize: 12 }}>{formatMessage({ id: "T1669" })}</Text> {/* 재시도 */}
            </>            
          ) : text === "Pause" ? (
            <>
              <PauseForResultIcon />
              <Text style={{ fontSize: 12 }}>{formatMessage({ id: "T1668" })}</Text> {/* 일시중지 */}
            </>
          ) : text === "Stop" ? (
            <>
              <StopRunningTaskIcon/>
              <Text style={{ fontSize: 12 }}>{formatMessage({ id: "T0053" })}</Text> {/* 중단 */}
            </>
          ) : <Text style={{ fontSize: 12 }}>{text}</Text>
        }
      </div>
      )
    },
    {
      title: formatMessage({ id: "T1036" }), // CR
 
      dataIndex: "taskType",
      key: "taskType",
      render: (data, record, index) => (data === "DIY" ? "DIY" : data),
    },
    {
      title: formatMessage({ id: "T1075" }), // Runner 
      dataIndex: "runner",
      key: "runner",
    },
    {
      title: formatMessage({ id: "T0582" }), // 비고
      width: 105,
      align: "center",
      dataIndex: "status",
      render: (data, record, index) => (
        <div className="operation_note__box" key={index}>
          <Tooltip title={formatMessage({ id: "T1167" })}> {/* Replay */}
            <MonitoringIcon
              onClick={() => onClickReplay(record)}
              className="abled_icons"
            />
          </Tooltip>
          { record.status === "Failed" ? (
            // T0570 : 로그 기록, T0571 : 재실행
            <>
              <Tooltip title={formatMessage({ id: "T0570" })}>
                <LogIcon
                  onClick={() => onClickLog(record)}
                  className="abled_icons"
                />
              </Tooltip>
              <Tooltip title={formatMessage({ id: "T0571" })}>
                <RestartIcon
                  onClick={() => onClickRedo(record)}
                  className="abled_icons"
                />
              </Tooltip>
            </>
          ) : record.status === "WIP" ? ( // result가 진행중(WIP)일때
            // T1668 : 일시중지, T0643: 중지
            <>
              <Tooltip title={formatMessage({ id: "T1668" })}>
                <PauseRunningTaskIcon 
                  onClick={()=>onClickRunningTask(record,"Pause")}
                />
              </Tooltip>
              <Tooltip title={formatMessage({ id: "T0643" })}>
                <StopRunningTaskIcon 
                  onClick={() => onClickRunningTask(record,"Stop")}
                />
              </Tooltip>
            </>
          ) : record.status === "Pause" ? ( //result가 일시중지(Pause)일때
            // T1670 : 다시 시작, T0643: 중지
            <>
              <Tooltip title={formatMessage({ id: "T1670" })}>
                <RunningTaskRunAgainIcon
                  onClick={() => onClickRunningTask(record,"RunAgain")}
                  className="abled_icons"
                />
              </Tooltip>
                <Tooltip title={formatMessage({ id: "T0643" })}>
                  <StopRunningTaskIcon
                    onClick={() => onClickRunningTask(record,"Stop")}
                    className="abled_icons"
                  />
                </Tooltip>
            </>
          ) : (
            // T0570 : 로그 기록, T0571 : 재실행
            <>
              <Tooltip title={formatMessage({ id: "T0570" })}> 
                <LogIcon
                  onClick={() => onClickLog(record)}
                  className="abled_icons"
                />
              </Tooltip>
                <Tooltip title={formatMessage({ id: "T0571" })}>
                  <RestartIcon />
                </Tooltip>
            </>
          )}
        </div>
      ),
    },
  ];

    // 실행 중 일시중지, 중지, 다시시작 모달 관련 state
    const [runningProcessVisible, setRunningProcessVisible] = useState(false);
    const [runningTaskStatus,setRunningTaskStatus] = useState();
  
    const onClickRunningTask = (data,name) => {
      setRunningProcessVisible(true);
      setRunningTaskStatus(name);
      setBotPerformRowInfo(data);
    }
  
    const onOKRunningTaskModal = async () => {
      let path = '';
      if(runningTaskStatus === "Pause") {
        path = '/hwlm/pauseRunningTask';
      } else if(runningTaskStatus === "Stop") {
        path = '/hwlm/stopRunningTask';
      } else if(runningTaskStatus==="RunAgain") {
        path = '/hwlm/pauseRunningTaskRunAgain';
      }

      const type = crInfo.find(
        (v) =>
          v.crType === botPerformRowInfo.taskType && v.crEnvGubun === "PRD"
      ).crXEnv; 

      const workItemId = String(botPerformRowInfo.workItemId);

      const result = await fetchAbsolute(
        "get",
        `${path}?x-environment=${type}&taskId=${botPerformRowInfo.taskId}&workitemId=${workItemId}`
      );
  
      if(result.data.resultCode==="S") {
        setRunningProcessVisible(false);
        setRunningTaskStatus();
        onClickSearchReset(); // 업뎃된 데이터 바로 반영하기
        // setBotPerformRowInfo();
        // getBotResult(); // 업뎃된 데이터 바로 반영하기
      } else {
        return alert(formatMessage({ id: "T0133" })); // 작업을 실패하였습니다.
      }
    };
  
    const onCancelRunningTaskModal = () => {
      setRunningProcessVisible(false);
      setRunningTaskStatus();
      // setBotPerformRowInfo();
    }

  const [orgaDataState, dispatchOrgaData] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_DATA':
        return {
          ...state,
          orgaData: action.payload,
          searchData: action.payload,
          orgaDataLoading: true,
        };
      case 'CLEAR_DATA':
        return {
          ...state,
          orgaData: [],
          searchData: [],
          orgaDataLoading: true,
        };
      default:
        return state;
    }
  }, {
    orgaData: [],
    searchData: [],
    orgaDataLoading: false,
  });
  
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!searchDate) return;
    getFailedResult();
    getBotResult();
    getStatusResult();
  }, [searchDate, searchDepartment, searchOrga]);

  useEffect(() => {
    if (isFirstRender.current) {
      //첫번째 렌더링 후에 false로 업데이트
      isFirstRender.current = false;
      //첫번째 렌더링은 skip (다른 useEffect에서 이미 함수 호출함)
      return;
    }
    getFailedResult();
  }, [latelyType]);

  useEffect(() => {
    const fetchUser = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${profile.EMP_NO}`
      );
      if (result.error || result.data.mdFPAList.length === 0) {
        return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      }
      dispatchOrgaData({ type: 'SET_DATA', payload: result.data.mdFPAList });
      // setOrgaData([...result.data.mdFPAList]);
      // setSearchOrga([...result.data.mdFPAList]);
      // setOrgaDataLoading(true);
    };
    if (!performLoading) return;
    if (!performPermission) {
      fetchUser();
    } else {
      dispatchOrgaData({ type: 'CLEAR_DATA' });
      // setOrgaData([]);
      // setSearchOrga([]);
      // setOrgaDataLoading(true);
    }
  }, [performPermission, performLoading]);

  /* 재실행 때 poolname 필요 */
  useEffect(() => {
    const fetchDevicePool = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/devicePool/getDevicePoolList?crType=${botPerformRowInfo.taskType}`
      );
      setDevicePool(result.data.lists);
    };
    fetchDevicePool();
  }, [botPerformRowInfo, workItemList]);

  //pie chart 데이터 없음 보여주는 로직
  useEffect(() => {
    const temp = performStatusData
      .map((e) => e.value > 0)
      .findIndex((v) => v === true);
    if (temp === -1) {
      setPerformPieStatus(true);
    } else {
      setPerformPieStatus(false);
    }
  }, [performStatusData]);

  return (
    <div className="operation_box">
      <div className="operation__top_menu">
        <div className="top_menu__box">
          <Text className="top_menu__text">{operDate}</Text>
          {/* 수행일 */}
          <TypePicker
            placeholder={moment().format("YYYY.MM.DD")}
            defaultValue={moment()}
            value={selectDate}
            width={170}
            height={32}
            onChange={onChangeDate}
            allowClear={false}
          />
        </div>
        {performPermission && (
          <div className="top_menu__box">
            <Text className="top_menu__text">
              {formatMessage({ id: "T0005" })}
            </Text>
            {/* 담당부서 */}
            <InputSearch
              placeholder={formatMessage({ id: "T0029" })} // 담당 부서 검색
              width={200}
              height={32}
              onClick={onOpenDepartment}
              value={department.map((c) => c.codeNm).join(", ")}
            />
          </div>
        )}
        <div className="top_menu__box">
          <Text className="top_menu__text">{taskMgrId}</Text>
          <InputSearch
            placeholder={formatMessage({ id: "T0586" })} // 업무 담당자 검색
            width={200}
            height={32}
            onClick={performPermission ? onOpenOrga : () => { }}
            value={orgaData.map((c) => c.empNam).join(", ")}
          />
        </div>
        <Button
          className="top_menu__button"
          type="primary"
          onClick={onClickSearch}
        >
          <FormattedMessage id="T0039" />
          {/* 검색 */}
        </Button>
        <Button className="top_menu__button" onClick={onClickSearchReset}>
          <FormattedMessage id="T0037" />
          {/* 초기화 */}
        </Button>
      </div>
      <div className="operation__content">
        <div className="operation__content_box content__chart_box content__bot_status">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0588" })}</Text>
            {/* 과제 수행 상태 */}
          </div>
          <div className="content__main">
            <div>
              {performPieStatusLoading ? (
                <Loading visible={performPieStatusLoading} height={17} minHeight={10} />
              ) : (
                Object.keys(config).length > 0 &&
                !performPieStatusLoading &&
                performStatusData.length > 0 &&
                (!performPieStatus ? (
                  <Pie
                    {...config}
                    style={{ height: "400px" }}
                  />
                ) : (
                  <Empty />
                ))
              )}
            </div>
          </div>
        </div>
        <div className="operation__content_box content__fail_case">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0589" })}</Text>
            {/* 실패 사례 분석 (최근 */}
            <Select
              style={{
                width: 90,
                fontSize: 12,
                margin: "0 10px",
                alignSelf: "normal",
              }}
              defaultValue={latelyTypeList[0].value}
              value={latelyType.value}
              onChange={onChangeLatelyType}
            >
              {latelyTypeList.map((type) => (
                <Option value={type.value} key={type.value}>
                  {type.text}
                </Option>
              ))}
            </Select>
            <Text>)</Text>
          </div>
          <div className="content__main">
            <Table
              className="failed__table"
              columns={caseColumns}
              dataSource={failCaseData}
              style={{ width: "100%" }}
              rowKey={(record) => record.taskId + record.failCnt}
              loading={failCaseLoading}
            />
          </div>
        </div>
        <div className="operation__content_box content__bot_run">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0590" })}</Text>
            {/* Bot 수행 내역 */}
          </div>
          <div className="content__main">
            <Table
              className="bot_perform__table"
              rowKey={(record) =>
                record.taskId + record.botStartDate + record.botEndDate
              }
              columns={runColumns}
              dataSource={botPerformData}
              loading={botPerformDataLoading}
            />
          </div>
        </div>
      </div>
      <Modal
        centered
        visible={runningProcessVisible}
        width={500}
        title={runningTaskStatus==="Pause"
            ? <FormattedMessage id="T1668" /> // 일시중지
            : runningTaskStatus==="Stop"
              ? <FormattedMessage id="T0643" /> // 중지
              : <FormattedMessage id="T1670" />  // 다시시작
          }
        okText={<FormattedMessage id="T0064" />} //확인
        onOk={onOKRunningTaskModal}
        onCancel={onCancelRunningTaskModal}
      >
        <Text>
            {runningTaskStatus==="Pause"
              ? <>{formatMessage({ id: "T1671" })}</> //실행 중인 과제를 일시중지 하시겠습니까?
              : runningTaskStatus==="Stop"
                ? <>{formatMessage({ id: "T1672" })}</> // 실행 중인 과제를 중지 하시겠습니까?
                : <>{formatMessage({ id: "T1673" })}</>  //일시중지된 과제를 다시 시작 하시겠습니까?
            }
        </Text>
      </Modal>
      <Organization
        visible={orgaVisible}
        onOk={onOkOrga}
        onCancel={onCancelOrga}
        defaultData={[orgaData]}
        title={formatMessage({ id: "T0586" })} // 업무 담당자 검색
      />
      <RealtimeModal
        visible={replayVisible}
        onOk={onOkReplay}
        onCancel={onCancelReplay}
        taskId={replayData.taskId}
        workItemId={replayData.workItemId}
        cr={replayData.cr}
      />
      <Modal
        centered
        visible={restoreVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0082" })} // 초기 데이터 원복
        onOk={onOkRestore}
        onCancel={onCancelRestore}
      >
        <Text>{formatMessage({ id: "T0573" })}</Text> {/* 초기 데이터를 불러오시겠습니까? */}
        {/* 초기 데이터를 불러오시겠습니까? */}
      </Modal>
      <RedoModal
        redoVisible={redoVisible}
        onOkRedo={onOkRedo}
        onCancelRedo={onCancelRedo}
        onClickRestore={onClickRestore}
        onChangeRecordKey={onChangeRecordKey}
        onChangeRecordValue={onChangeRecordValue}
        recordData={recordData}
        onClickAdd={onClickAdd}
        onClickDelete={onClickDelete}
      />
      <LogModal
        title={formatMessage({ id: "T0574" })} // 과제 실행 log
        visible={logVisible}
        onCancel={onCancelLog}
        onOk={onOkLog}
        recordData={botPerformRowInfo}
        devicePool={logCrEnv}
      />
      <FailedLogModal
        title={formatMessage({ id: "T0587" })} // 과제 Fail Log
        visible={failLogVisible}
        onOk={onOkFailLog}
        onCancel={onCancelFailLog}
        recordData={botPerformRowInfo}
        devicePool={logCrEnv}
      />
    </div>
  );
};

export default withRouter(AssignOperation);
