import React, { useEffect } from "react";
import { AssignEnterBox, Text } from "components";
// import { useSelector } from 'react-redux';
// import { getUniqueKey } from 'utils/Str';
// import { useIntl, FormattedMessage } from "react-intl";

import "./common.css";
/**
 * Filename: AssignProcessDesign/index.js
 * Description: 과제 등록 -> 박스우드 Process Design
 */

const AssignBoxwoodProcessDesign = ({
  profile,
  type,
  onClickCheck,
  checked,
  boxwoodPDInfo = {},
  customHeight = "700",
}) => {
  let url = "https://dreago.net:8080/Boxwood_portal/embed/";

  useEffect(() => {
    if (type === "insert") {
      document.boxwood_process_design.location =
        url + `designer/direct/new?userId=${profile.EMP_NO}`;
    }
    if (boxwoodPDInfo.processCd !== undefined) {
      if (type === "modify") {
        document.boxwood_process_design.location =
          url +
          `designer/direct/detail?userId=/detail?userId=${profile.EMP_NO}&processCd=${boxwoodPDInfo.processCd}`;
      } else if (type === "lookup") {
        document.boxwood_process_design.location =
          url +
          `designer/direct/detail?userId=/detail?userId=${profile.EMP_NO}&processCd=${boxwoodPDInfo.processCd}&mode=read`;
      }
    }
  }, [type, boxwoodPDInfo?.processCd]);

  return (
    <iframe
      style={{
        border: 0,
        margin: "0 auto",
        display: "block",
      }}
      width="100%"
      height={customHeight}
      id="boxwood_process_design"
      name="boxwood_process_design"
      title="boxwood_process_design"
    />
  );

  return (
    <>
      {type === "insert" ? (
        <AssignEnterBox
          title="Process Design"
          useCheck={true}
          onClickCheck={onClickCheck}
          checked={checked}
        >
          {checked && <div></div>}
        </AssignEnterBox>
      ) : (
        <div className="basic_box status__disabled">
          <div className="basic_box__title">
            <Text>
              <b>Boxwood Process Design</b>
            </Text>
          </div>

          <iframe
            style={{
              border: 0,
              margin: "0 auto",
              display: "block",
            }}
            width="100%"
            height="1600"
            id="process_lookup"
            name="process_lookup"
            title="process_lookup"
          />
        </div>
      )}
    </>
  );
};

export default AssignBoxwoodProcessDesign;
