import React, { useState } from 'react';
import { Text, Table, Input, Button } from 'components';
import { MinusCircleFilled } from '@ant-design/icons';
import { useIntl, FormattedMessage } from "react-intl";

/**
 * 관리자 > 네부 시스템 메뉴 관리 > 내부 시스템 메뉴 등록 / 수정 페이지
 * Bottom 부분
 * 
 */

const initialMenuInsertInfo = {
  level1: "",
  level2: "",
  level3: "",
  level4: "",
  level5: "",
};

const MenuInsert = ({ datas, onChangeMenuInsert }) => {
  const { formatMessage } = useIntl();

  return (
    <div className='system_content__section' style={{ padding: 21 }}>
      <div>
        <Text style={{ fontSize: 16 }}>
          Level 1
        </Text>
        <Input
          style={{
            height: 32,
            marginTop: 10,
            marginBottom: 20
          }}
          name='level1'
          value={datas.level1}
          onChange={onChangeMenuInsert}
          placeholder={formatMessage({ id: 'T0375' })} // Level 1 메뉴명
        />
      </div>
      <div>
        <Text style={{ fontSize: 16 }}>
          Level 2
        </Text>
        <Input
          style={{
            height: 32,
            marginTop: 10,
            marginBottom: 20
          }}
          name='level2'
          value={datas.level2}
          onChange={onChangeMenuInsert}
          placeholder={formatMessage({ id: 'T0376' })} // Level 2 메뉴명
        />
      </div>
      <div>
        <Text style={{ fontSize: 16 }}>
          Level 3
        </Text>
        <Input
          style={{
            height: 32,
            marginTop: 10,
            marginBottom: 20
          }}
          name='level3'
          value={datas.level3}
          onChange={onChangeMenuInsert}
          placeholder={formatMessage({ id: 'T0377' })} // Level 3 메뉴명
        />
      </div>
      <div>
        <Text style={{ fontSize: 16 }}>
          Level 4
        </Text>
        <Input
          style={{
            height: 32,
            marginTop: 10,
            marginBottom: 20
          }}
          name='level4'
          value={datas.level4}
          onChange={onChangeMenuInsert}
          placeholder={formatMessage({ id: 'T0378' })} // Level 4 메뉴명
        />
      </div>
      <div>
        <Text style={{ fontSize: 16 }}>
          Level 5
        </Text>
        <Input
          style={{
            height: 32,
            marginTop: 10,
            marginBottom: 20
          }}
          name='level5'
          value={datas.level5}
          onChange={onChangeMenuInsert}
          placeholder={formatMessage({ id: 'T0379' })} // Level 5 메뉴명
        />
      </div>
    </div>
  )
}

const UseIternalMenu = ({ selectDatas, setSelectDatas, systemCd }) => {
  const { formatMessage } = useIntl();
  const [menuInsertInfo, setMenuInsertInfo] = useState({ ...initialMenuInsertInfo });

  const notAllowedMenuInfo = formatMessage({ id: 'T0127' }); // 유효하지 않은 메뉴 정보입니다.

  const onClickSelectButton = () => {
    const tmpArr = [...selectDatas];
    const insertData = { ...menuInsertInfo };
    let valid = true;
    Object.keys(insertData).sort((a, b) => a < b ? -1 : a > b ? 1 : 0).map(v => insertData[v].trim()).forEach((cur, index, arr) => {
      if (index === 0) return;
      if (cur.trim() !== '' && arr[index - 1].trim() === '') {
        valid = false;
      }
    });
    if (!valid) return alert(notAllowedMenuInfo);
    const newObj = { ...menuInsertInfo, key: tmpArr.length, systemCd };
    tmpArr.push({ ...newObj });
    setMenuInsertInfo({ ...initialMenuInsertInfo });
    setSelectDatas([...tmpArr]);
  };

  const onChangeMenuInsert = (e) => {
    const { value, name } = e.target;
    if (value.length > 200) return;
    setMenuInsertInfo({
      ...menuInsertInfo,
      [name]: value
    })
  };

  const onDeleteSelectData = (key) => {
    setSelectDatas([...selectDatas.filter(data => data.key !== key)]);
  };

  const onClickResetButton = () => {
    setSelectDatas([]);
  };

  return (
    <div className='system_wrap'>
      <div className='system_box'>
        <div className='system_box__content'>
          <div className='system_content__header'>
            <Text>{formatMessage({ id: 'T0382' })}</Text>{/* 메뉴 입력 */}
          </div>
          <div className=''>
            <MenuInsert
              datas={menuInsertInfo}
              onChangeMenuInsert={onChangeMenuInsert}
            /></div>
        </div>
      </div>
      <div>
        <Button className='system_select_button' type='primary'
          onClick={onClickSelectButton}
        >&gt;</Button>
      </div>
      <div className='system_box'>
        <div className='system_box__content'>
          <div className='system_content__header'>
            <Text>{formatMessage({ id: 'T0338' })}</Text>{/* 선택 항목 */}
            <Button
              className='select_reset_button'
              onClick={onClickResetButton}
            >
              <FormattedMessage id="T0037" /></Button>{/* 초기화 */}
          </div>
          <div className='system_content__section'>
            <Table
              columns={[{
                title: 'Level 1',
                dataIndex: 'level1',
              }, {
                title: 'Level 2',
                dataIndex: 'level2',
              }, {
                title: 'Level 3',
                dataIndex: 'level3',
              }, {
                title: 'Level 4',
                dataIndex: 'level4',
              }, {
                title: 'Level 5',
                dataIndex: 'level5',
              }, {
                title: '',
                key: 'delete',
                width: 40,
                align: 'center',
                render: (e) => <MinusCircleFilled
                  onClick={() => onDeleteSelectData(e.key)}
                />
              }]}
              dataSource={selectDatas}
              pagination={false}
              locale={{ emptyText: ' ' }}
              scroll={{ y: 429 }}
            />
          </div>
        </div>
      </div>
    </div>
  )
};
export default UseIternalMenu;