import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { PageTemplate, Header, Footer, Tabs, DepartmentList } from 'components';
import Wrapper from './Styled';
import AssignOperation from './AssignOperation';
import AssignPerform from './AssignPerform';
import { getUniqueKey } from 'utils/Str';

const { TabPane } = Tabs;

/**
 * Filename: MonitoringStatus/index.js
 * Description: 운영 현황 모니터링
 */

const MonitoringStatus = ({ profile }) => {
    const { formatMessage } = useIntl();

    const permission = useSelector(state => state.get(getUniqueKey('permission')));

    const [departmentVisible, setDepartmentVisible] = useState(false);
    const [department, setDepartment] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState('1');
    // 검색할 때 쓸 department
    const [searchDepartment, setSearchDepartment] = useState([]);

    // 과제별 수행현황 탭 권한 체크
    const [performPermission, setPerformPermission] = useState(false);
    const [performLoading, setPerformLoading] = useState(false);

    const onReset = () => {
        setDepartment([]);
        setSearchDepartment([]);
    }
    const onChangeTabs = (activeKey) => {
        setActiveTabKey(activeKey);
    };

    const onOpenDepartment = () => {
        setDepartmentVisible(true);
    };
    const onOkDepartment = (data) => {
        setDepartmentVisible(false);
        setDepartment([...data]);
    };
    const onCancelDepartment = () => {
        setDepartmentVisible(false);
    };
    const onClickSearchButton = () => {
        setSearchDepartment(department);
    }

    useEffect(() => {
        if (permission.userLoading) return;
        const flag = permission.user.findIndex(v => v.fnCode === 'FN29') !== -1;
        setPerformPermission(flag);
        setPerformLoading(true);
    }, [permission.user]);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            {/* 부서 조회 */}
            <DepartmentList
                visible={departmentVisible}
                companyCd={profile.COMP_CD}
                onOk={onOkDepartment}
                onCancel={onCancelDepartment}
                mode='multiple'
                defaultData={department}
            />
            {
                performLoading &&
                <Wrapper>
                    <Tabs type='card' custom={false} activeKey={activeTabKey} onChange={onChangeTabs} defaultActiveKey='1'>
                        {/* 과제 운영 모니터링 */}
                        <TabPane tab={formatMessage({ id: "T0578" })} key='1'>{/* 과제 운영 모니터링 */}
                            <AssignOperation
                                department={department}
                                onOpenDepartment={onOpenDepartment}
                                onClickSearchButton={onClickSearchButton}
                                searchDepartment={searchDepartment}
                                performPermission={performPermission}
                                performLoading={performLoading}
                                profile={profile}
                                onReset={onReset}

                            />
                        </TabPane>
                        {/* 과제별 수행 현황 */}
                        {
                            performPermission &&
                            <TabPane tab={formatMessage({ id: "T0579" })} key='2'>{/* 과제별 수행 현황 */}
                                <AssignPerform
                                    department={department}
                                    onOpenDepartment={onOpenDepartment}
                                    onClickSearchButton={onClickSearchButton}
                                    searchDepartment={searchDepartment}
                                />
                            </TabPane>
                        }
                    </Tabs>
                </Wrapper>
            }
        </PageTemplate>
    );
}

export default MonitoringStatus;