import React, { Fragment } from "react";
import { withRouter } from 'react-router-dom';
import Routes from 'routes';
import RestrictedRoute from 'restrict';

function App() {
  const NonBlockApp = withRouter(Routes);
  return (
    <Fragment>
      <RestrictedRoute component={NonBlockApp} />
    </Fragment>
  );
}

export default App;
