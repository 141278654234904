import { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import { Bar } from '@ant-design/charts';
import { Divider, Typography, Select, } from 'antd';
import fetchAbsolute from 'utils/fetchAbsolute';
import { Loading, Radio } from "components";


const { Option } = Select;
const { Title } = Typography;

/**
 * 대시보드 > 과제 현황 > 조직별 운영건수 비교
 * 
 */
const OrgOperation = ({ standardDate, companyList, taskType, defaultTaskType }) => {
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [operData, setOperData] = useState([]);
    const [conditions, setConditions] = useState({
        org: [],
        category: 'company'
    });

    const config = {
        isGroup: true,
        xField: 'value',
        yField: 'label',
        yAxis: {
            label: {
                autoRotate: false, // scroll
            },
        },
        // scrollbar: {
        //     type: 'vertical',
        // },
        legend: {
            layout: 'vertical',
            position: 'right-top',
            offsetY: 5,
        },
        offsetY: 6,
        color: ['#bebebe', '#fc5310'],
        seriesField: 'type',
        marginRatio: 0,
        barWidthRatio: 0.8,
        label: {
            position: 'right',
            offset: 4,
            offsetY: 2,
            content: function content(_ref) {
                return ""
                    .concat(_ref.value)
                    .concat(formatMessage({ id: 'T1537' })) // 건
            },
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'adjust-color' },
            ],
        },
    };

    const fetchOrgOperation = async () => {
        if (conditions.category === 'depart') return;
        // if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        // if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/taskState/NumOfOperByOrgList`, {
            data: {
                dataCollectionDate: standardDate,
                companyCd: conditions.org || [],
                processType: tmpTaskType || []
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    label: data[i].companyNm,
                    type: formatMessage({ id: "T1546" }), // '과제건수'
                    key: data[i].companyCd,
                    value: +data[i].companyTaskCnt || 0
                },
                {
                    label: data[i].companyNm,
                    type: formatMessage({ id: "T1554" }), // '운영건수' 
                    key: data[i].companyCd,
                    value: +data[i].companyOperCnt || 0
                },
            )
        }
        setOperData([...tmpArr]);
    };

    const fetchDepartmentList = async () => {
        if (conditions.category === 'company') return;
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        /* 목록에 회사명 추가 */
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/taskState/NumOfOperByOrgList`, {
            data: {
                dataCollectionDate: standardDate,
                companyCd: conditions.org || [],
                processType: tmpTaskType || []
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const departList = result.data.map(list => list.departList).flat();
        const list = departList.map(c => ({
            ...c,
            companyNm: companyList.find(d => d.companyCd === c.companyCd).companyNm
        }));
        const tmpArr = [];
        for (let i in list) {
            tmpArr.push(
                {
                    label: list[i]?.companyNm + "\n" + list[i].departNm,
                    type: formatMessage({ id: "T1546" }), // '과제건수'
                    key: list[i].departCd,
                    value: +list[i].taskCnt || 0
                },
                {
                    label: list[i]?.companyNm + "\n" + list[i].departNm,
                    type: formatMessage({ id: "T1554" }), // '운영건수' 
                    key: list[i].departCd,
                    value: +list[i].operCnt || 0
                },
            )
        };
        setOperData([...tmpArr]);
    };

    /* 조직별 */
    const onChangeOrg = (e) => {
        /* 배열 */
        setConditions({
            ...conditions,
            org: [...e],
        })
    };

    /* 회사별 조직별 선택 라디오 기능 */
    const onChangeCategory = (e) => {
        const { target: { value } } = e;
        setConditions({
            ...conditions,
            category: value,
            org: conditions.org
        });
    };

    useEffect(() => {
        if (conditions.category !== 'depart') {
            fetchOrgOperation();
        } else {
            fetchDepartmentList();
        }
    }, [conditions, standardDate, taskType])

    return (
        <div className='box__container'>
            <div className='bot__title'>
                <Title
                    className='main__title'
                    level={4}>
                    {formatMessage({ id: "T1515" })}{/* 조직별 운영건수 비교 */}
                </Title>
                <div className='condition org_operation__container'>
                    <div>{formatMessage({ id: "T1516" })}</div>{/* 조직별 */}
                    <div>
                        <Select
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{
                                width: 220
                            }}
                            onChange={onChangeOrg}
                            value={conditions.org}
                            placeholder={formatMessage({ id: "T0391" })}  // 전체
                        >
                            {
                                companyList.map(list => {
                                    return (
                                        <Option key={list.companyCd} value={list.companyCd}>{list.companyNm}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div style={{ width: 75 }}>
                        <Radio
                            className='selectRadioCategory'
                            value={conditions.category}
                            onChange={onChangeCategory}
                            renderItem={[
                                {
                                    value: "company",
                                    text: `${formatMessage({ id: "T1542" })}`, // 회사별
                                },
                                {
                                    value: "depart",
                                    text: `${formatMessage({ id: "T1516" })}`, // 조직별 
                                }
                            ]}
                        />
                    </div>
                </div>
            </div>
            <Divider />
            {
                operData.length > 0 ?
                    <Bar {...config}
                        className="dashboard_graph" data={operData} style={{ height: `calc(30px * ${operData.length})`, minHeight: 270 }} /> : <Loading visible={isLoading} height='30' minHeight='10' />
            }
        </div >
    );
};
export default OrgOperation;
