import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Organization, Modal, Table, Button, InputSearch } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import { ShrinkOutlined, ExpandOutlined } from "@ant-design/icons";

/**
 * Filename: FailedLogModal.js
 * Description: 모니터링 > 실패 로그 결과를 나타내는 창
 */

const Wrapper = styled((props) => <Modal {...props} />)`
  .ant-table-wrapper {
    padding: 10px 40px;
  }
  .primary__button {
    width: 200px;
    height: 40px;
  }
  .default__button {
    height: 40px;
  }
  .ant-modal-footer button + button {
    margin-left: 0;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    gap: 15px;
  }
  .ant-table-title {
    display: none !important;
  }
  .ant-modal-header {
    border-top: 7px solid #ff7b00;
  }
`;

const HFFailedLogModal = ({
  visible,
  onOk = () => { },
  onCancel = () => { },
  title = "",
  recordData,
  devicePool,
  ...args
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const profile = useSelector((state) => state.get("auth").get("profile"));

  const [dataSource, setDataSource] = useState([]);
  const [originDataSource, setOriginDataSource] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [orgVisible, setOrgVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgData, setOrgData] = useState({});

  const [isMaximized, setIsMaximized] = useState(false);

  const [scrollHeight, setScrollHeight] = useState("330px");

  const onClickResetFilter = () => {
    setFilterInfo({});
    setDataSource(originDataSource);
  };

  const onClickOrg = () => {
    setOrgVisible(true);
  };
  const onOkOrg = (data) => {
    if (data.length === 0) {
      setOrgData({});
    }
    setOrgData({ ...data[0] });
    setOrgVisible(false);
  };

  const onChangeTable = (pagination, filters) => {
    setFilterInfo({ ...filters });
  };

  const onOkModal = async () => {
    if (!orgData.empNum) return alert(formatMessage({ id: "T0117" })); // 사용자를 선택해주세요
    const result = await fetchAbsolute(
      "get",
      `/common/memberNoList?empNo=${orgData.empNum}`
    );
    if (result.error) {
      return alert(formatMessage({ id: "T0118" })); // 사용자 정보를 가져오는데 실패하였습니다.
    }
    let tableHtml = `<table border='1' cellspacing='0'>
    <colgroup> 
      <col width='170'>
      <col width='170'> 
      <col width='170'>
      <col width='170'> 
      <col width='170'> 
    </colgroup>
    <thead>
      <tr>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">Id</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">Act Type</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">Result</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">Task</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">Create Time</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">End Time</th>
      </tr>
    </thead>
    <tbody>`;
    dataSource.forEach((data) => {
      tableHtml += `<tr>
          <td style="text-align: center; font-size: 12px;">${data.processInstanceId || ""
        }</td>
        <td style="text-align: center; font-size: 12px;">${data.actType || ""}</td>
        <td style="text-align: center; font-size: 12px;">${data.result || ""
        }</td>
      <td style="text-align: center; font-size: 12px;">${data.name || ""
        }</td> 
    <td style="text-align: center; font-size: 12px;">${moment(data.createTime).format("YYYY-MM-DD HH:mm:ss") || ""
        }</td>
    <td style="text-align: center; font-size: 12px;">${moment(data.endTime).format("YYYY-MM-DD HH:mm:ss") || ""
        }</td>
    </tr>`;
    });
    tableHtml += `</tbody>
    </table>`;

    const title = `${recordData.workflowNm}(${recordData.workflowId}) Fail Log`;
    const content = `${fmMessage({
      id: "T1657",
      values: { name: recordData.workflowNm, id: recordData.workflowId },
    })}
    ${tableHtml}`;  
    /**
     *   <p style="font-size: 12px;">안녕하십니까.</p>
    <p style="font-size: 12px;">RPAgo2.0입니다.</p>
    <p style="font-size: 12px;">${recordData.workflowNm}(${recordData.workflowId})과제의 Fail Log 전달드립니다.</p>
    <p style="font-size: 12px;">감사합니다.</p>
     */

    const sendResult = await fetchAbsolute("post", `/mail/sendSimpleText`, {
      data: {
        sendFrom: profile.EMAIL,
        sendTo: [result.data.mdFPAList[0].emailAddr],
        sendCc: [],
        sendBcc: [],
        shareGroupId: [],
        mailSubject: title,
        mailText: content,
      },
    });

    if (sendResult.error || sendResult.data.result === "Fail") {
      return alert(formatMessage({ id: "T0119" })); // 메일 전송을 실패하였습니다
    }

    alert(formatMessage({ id: "T0120" })); // 메일 전송을 완료했습니다.
    
    setOrgData({});
    onOk();
  };

  const onClickMaximize = () => {
    setIsMaximized(true);
    setScrollHeight("calc(100vh - 256px)");
    document.getElementsByClassName('log_modal')[0].setAttribute("style", "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;");
    document.getElementsByClassName('log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:calc(100vh - 123px);");
  };

  const onClickMinimize = () => {
    setIsMaximized(false);
    setScrollHeight("330px");
    document.getElementsByClassName('log_modal')[0].setAttribute("style", "width:1400px;");
    document.getElementsByClassName('log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:460px;");
  };

  const columns = [
    {
      key: "processInstanceId",
      title: 'Id',
      dataIndex: "processInstanceId",
      width: 180,
      filters: filterList[0]?.filters,
      filteredValue: filterInfo.processInstanceId || null,
      onFilter: (value, record) => record.processInstanceId.includes(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.processInstanceId - b.processInstanceId,
    },
    {
      key: "actType",
      title: 'Act Type',
      dataIndex: "actType",
      width: 180,
      filters: filterList[1]?.filters,
      filteredValue: filterInfo.actType || null,
      onFilter: (value, record) => record.actType.includes(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.actType - b.actType,
    },
    {
      key: "result",
      title: 'Result',
      dataIndex: "result",
      width: 180,
      filters: filterList[2]?.filters,
      filteredValue: filterInfo.result || null,
      onFilter: (value, record) => record.result.includes(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.result - b.result,
    },
    {
      key: "name",
      title: 'Task',
      dataIndex: "name",
      width: 180,
      filters: filterList[3]?.filters,
      filteredValue: filterInfo.name || null,
      onFilter: (value, record) => record.name.includes(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.name - b.name,
    },
    {
      key: "createTime",
      title: "Create Time",
      dataIndex: "createTime",
      width: 165,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        +moment(a.createTime).format("YYYYMMDDHHmmss") -
        +moment(b.createTime).format("YYYYMMDDHHmmss"),
      filteredValue: filterInfo.createTime || null,
      filters: filterList[4]?.filters,
      onFilter: (value, record) => record.createTime.includes(value),
    },
    {
      key: "endTime",
      title: "End Time",
      dataIndex: "endTime",
      width: 165,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        +moment(a.endTime).format("YYYYMMDDHHmmss") -
        +moment(b.endTime).format("YYYYMMDDHHmmss"),
      filteredValue: filterInfo.endTime || null,
      filters: filterList[5]?.filters,
      onFilter: (value, record) => record.endTime.includes(value),
    },
  ];

  /* onCancel 눌렀을 때 작은 화면으로 돌아가도록 */
  useEffect(() => {
    if (!document.getElementsByClassName("log_modal")[0]) return;

    if (!visible) {
      setIsMaximized(false);
      setScrollHeight("330px");
      document
        .getElementsByClassName("log_modal")[0]
        .setAttribute("style", "width:1400px;");
      document
        .getElementsByClassName("log_modal")[0]
        .getElementsByClassName("ant-modal-body")[0]
        .setAttribute("style", "height:460px;");
    }
  }, [visible]);

  useEffect(() => {
    const fetchLogResult = async () => {
      let data = [];
      if (!recordData.processInstanceIds) return;
      setLoading(true);
      // api 변경 processInstanceIds -> 배열로 
      const processInstanceIds = recordData.processInstanceIds.split(',');
      const result = await fetchAbsolute(
        "post",
        `/workflow/workflowOperationStatusDetailHFList`,
        {
          data: {
            processInstanceIds,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      data.push(
        ...result.data?.dataList.map((v) => ({
          ...v,
          createTime: moment(v.createTime).format("YYYY-MM-DD HH:mm:ss"),
        }))
      );
      setLoading(false);
      const newColumn = columns.map((v) => {
        const groupArray = new Set(
          data.map((d) => {
            if (v.dataIndex === "createTime" || v.dataIndex === "endTime") {
              return moment(d[v.dataIndex]).format("YYYY-MM-DD");
            }
            return d[v.dataIndex] || " ";
          })
        );
        const filters = [...groupArray].map((d) => ({ text: d, value: d }));
        return {
          ...v,
          filters,
        };
      });
      setDataSource([...data].sort((a, b) => b.id - a.id));
      setOriginDataSource([...data].sort((a, b) => b.id - a.id));
      setFilterList(newColumn);
    };
    setDataSource([]);
    fetchLogResult();
  }, [recordData, devicePool]);

  return (
    <>
      <Wrapper
        className="log_modal"
        title={
          <div className="modal_title">
            {title}
            {!isMaximized ? (
              <ExpandOutlined
                className="title_expand_icon"
                onClick={onClickMaximize}
                id="max_btn"
              />
            ) : (
              <ShrinkOutlined
                className="title_expand_icon"
                onClick={onClickMinimize}
                id="min_btn"
              />
            )}
          </div>
        }
        visible={visible}
        okText={formatMessage({ id: "T1211" })} // Log 공유하기
        onCancel={onCancel}
        modalRender={false}
        bodyStyle={{ height: 460 }}
        footer={[
          <Button
            className="default__button"
            type="white"
            onClick={onClickResetFilter}
          >
            {formatMessage({ id: "T0068" })}{/* 필터 초기화 */}
          </Button>,
          <InputSearch
            placeholder={formatMessage({ id: "T0026" })} // 사용자를 선택하세요
            onClick={onClickOrg}
            value={orgData.empNam}
          />,
          <Button
            key="submit"
            type="primary"
            onClick={onOkModal}
            className="primary__button"
          >
            {formatMessage({ id: "T0069" })}
            {/* 공유하기 */}
          </Button>,
          <Button key="back" className="default__button" onClick={onCancel}>
            <FormattedMessage id="T0051" />
            {/* 취소 */}
          </Button>,
        ]}
        {...args}
      >
        <Table
          columns={columns}
          locale={{ emptyText: `${formatMessage({ id: "T1215" })}` }} // 로그 내역이 없습니다.
          dataSource={dataSource}
          onChange={onChangeTable}
          rowKey={(item) => item.id}
          scroll={{ y: `${scrollHeight}` }}
          loading={loading}
        />
      </Wrapper>
      <Organization
        visible={orgVisible}
        onCancel={() => setOrgVisible(false)}
        onOk={onOkOrg}
        max={1}
        defaultData={[orgData]}
      />
    </>
  );
};

export default HFFailedLogModal;
