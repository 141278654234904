import React, { useState, useEffect, useRef } from "react";
import { Text, Button, Modal, Table, Upload, ScheduleServiceLog } from "components";
import { Row, Col, Input as AntInput, Tooltip } from "antd";
import { SyncOutlined } from "@ant-design/icons";
import {
  MonitoringIcon,
  LogIcon,
} from "icons";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import fetchAbsolute from "utils/fetchAbsolute";
import { getUniqueKey } from "utils/Str";
import useFormatMessage from "hooks/useFormatMessage";
import { TestStartWorkflow, setWorkflowData } from "store/hfWorkflowLookup/action";
import ProgressModal from "components/atoms/ProgressModal";

const { TextArea } = AntInput;

/**
 * Filename: LookupInspection.js
 * Description: 과제 조회 페이지 하단에 기본 정보 탭 중 4. [테스트] 개발 검수 컴포넌트
 */
const END_POINT = process.env.REACT_APP_API_URI;


const WorkflowLookupInspection = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const sameNameNotUpload = formatMessage({ id: "T0145" });  // 같은 이름의 파일은 업로드 할 수 없습니다.
  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

  const dispatch = useDispatch();

  const refreshTimeout = useRef(null);
  const [spinReload, setSpinReload] = useState(false);

  const onClickReload = () => {
    clearTimeout(refreshTimeout.current);
    setSpinReload(true);
    refreshTimeout.current = setTimeout(() => {
      setSpinReload(false);
    }, 2000);

    fetchTestData();
  };

  const [progressVisible, setProgressVisible] = useState(false);
  const [progressData, setProgressData] = useState();

  //테스트 진행상태 팝업 - iframe
  const onClickStatus = (record) => {
    setProgressData(record);
    setProgressVisible(true);
  }

  const onCancelStatus = () => {
    setProgressVisible(false);
    setModalMaximization(false);
  }

  const [logInfo, setLogInfo] = useState();
  const [logVisible, setLogVisible] = useState(false);

  const onClickLog = async (data, record) => {
    setLogInfo({
      ...record, workflowNm: hfWorkflowLookup.workflowData.workflowNm, workflowId: hfWorkflowLookup.workflowData.workflowId
    });
    setLogVisible(true);
  };
  const onCancelLog = () => setLogVisible(false)

  const onOkLog = () => setLogVisible(false)

  const [testModifyVisible, setTestModifyVisible] = useState(false);
  const [testPopupData, setTestPopupData] = useState();

  //테스트 버튼들
  const [testPopup, setTestPopup] = useState(false);

  const tmpFile = useRef([]);
  const [deleteFiles, setDeleteFiles] = useState([]);

  const [errorComment, setErrorComment] = useState('');
  const [fileList, setFileList] = useState([]);
  // const [inputValues, setInputValues] = useState({ ...initialInputValues });

  const removeUploadedFile = (seq) => {
    setDeleteFiles(deleteFiles.concat(seq));
  };

  const [filterCondition,setFilterCondition] = useState([]);

  //테스트진행 버튼
  const onClickTestProgress = () => {
    // 테스트진행 버튼 누를때마다 1페이지로 세팅
    setPageOptions({current:1, pageSize:5});
    fetchTestData();
    setTestPopup(true);
  }

  //테스트완료 버튼
  const onClickTestConfirm = async () => {
    const result = await fetchAbsolute(
      "get",
      `/workflow/testConfirmation?workflowId=${hfWorkflowLookup.workflowData.workflowId}`
    );
    if (result.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    }
    alert(formatMessage({ id: "T1620" })) // 테스트가 완료 되었습니다.
    const newData = await fetchAbsolute(
      "get",
      `/workflow/workflowInfoHF?workflowId=${hfWorkflowLookup.workflowData.workflowId}`
    );
    if (newData.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    }
    dispatch(setWorkflowData(newData.data));
  }

  //수정요청 버튼
  const onClickTestModify = () => {
    setTestModifyVisible(true);
  }
    // const onClickReset = async () => {
  //   setInputValues({ ...initialInputValues});
  // };

  const onBeforeupload = (inFile) => {
    if (fileList.findIndex((file) => file.name === inFile.name) !== -1) {
      alert(
        fmMessage({
          id: "T1287",   // {field}은/을 업로드 할 수 없습니다.
          values: { field: formatMessage({ id: "T1288" }) },  // 같은 이름의 파일
        })
      );
      // 같은 이름의 파일은/을 업로드 할 수 없습니다.
      return false;
    }
    if (inFile.size > 1024 * 1024 * 100) {
      return alert(
        fmMessage({
          id: "T1571",
          values: { field: Math.round((inFile.size / 1024 / 1024) * 100) / 100 },
        })
      );
      // 100MB 이하 파일만 등록할 수 있습니다.\n\n "현재파일 용량 : {field}MB
    }

    let tmpFile = [];
    if (fileList.length === 0) {
      tmpFile.push(inFile);
    } else {
      tmpFile = [...fileList];
      tmpFile.push(inFile);
    }
    // tmpFile.current.push(inFile);
    setFileList([...tmpFile]);
    // setFileList([...tmpFile.current]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) removeUploadedFile(rmFile.seq);
    // tmpFile.current = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    const tmpFile = fileList.filter((file) => file.uid !== rmFile.uid);
    setFileList([...tmpFile]);
  };

  const [pageOptions,setPageOptions] = useState({});

  const onChangePage = (page, filter) => {
    setPageOptions(page);
    setFilterInfo(filter);
  }

  const [filterInfo, setFilterInfo] = useState({});
  const [filterValue, setFilterValue] = useState([]);

  const fetchTestData = async () => {
    const result = await fetchAbsolute("post",
      `/workflow/workflowOperationStatusHF`, {
      data: {
        "processId": hfWorkflowLookup.workflowData.processId,
        "fromDate": "",
        "toDate": ""
      }
    });

    if (result.error) {
      alert(formatMessage({ id: 'T1622' })); //데이터를 불러오지 못했습니다.
    }
    setTestPopupData([...result.data]);
        
    // version 필터 세팅
    const version = result.data.map(val => val.version);
    const uniqueVer = version.filter((el,index)=> version.indexOf(el)===index)?.map(val => {return {text:val, value:val}});
    setFilterCondition(uniqueVer); // 필터 기준 배열

    //디폴트 version 필터 세팅
    const changeToNum = version.map(val => +val);
    const defaultValue = Math.max(...changeToNum).toString();
    setFilterValue([defaultValue]); // 선택된 필터값
  }

  const onOkTestProgress = () => {
    dispatch(TestStartWorkflow({
      successMsg: formatMessage({ id: "T0147" }), // 즉시 실행이 시작되었습니다.
      failedMsg: formatMessage({ id: "T0148" }) // 즉시 실행 오류가 발생하였습니다.
    }));

    setTestPopup(false);
  }
  const onCancelTestProgress = () => {
    setTestPopup(false);
    setFilterInfo({});
  }

  //수정요청 api 호출
  const onOkTestModify = () => {
    if (errorComment.trim().length === 0) return alert(formatMessage({ id: 'T1598' })); //  내용을 입력해주세요
    const formData = new FormData();
    // fileList?.filter((file) => !file.uploaded)?.forEach((file) => formData.append("files", file))
    fileList.length > 0
      && fileList.forEach((file) => formData.append("files", file))

    formData.append("workflowId", hfWorkflowLookup.workflowData?.workflowId);
    formData.append("comment", errorComment);

    const requestModification = async () => {
      const fResult = await fetchAbsolute(
        "post",
        "/workflow/testCancel",
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (fResult.error) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }

      alert(formatMessage({ id: "T1621" })); //수정 요청이 완료 되었습니다.
      dispatch(setWorkflowData(fResult.data));
    };

    requestModification();

    setErrorComment('');
    setFileList([]);
    setTestModifyVisible(false);
  };

  const onCancelTestModify = () => {
    setErrorComment('');
    setFileList([]);
    setTestModifyVisible(false);
    setModalMaximization(false);
  }

  const onChangeError = (e) => setErrorComment(e.target.value);

  // 모달창 최대/최소화
  const [modalMaximization,setModalMaximization] = useState(false);
  const onMaximizeModal = () => setModalMaximization(true);
  const onMinimizeModal = () => setModalMaximization(false);

  const columns = [
    {
      title: "ID", // WorkItem ID
      dataIndex: "id",
      align: "center",
      key: "id",
    },
    {
      title: "Result",
      dataIndex: "result",
      align: "center",
      key: "result",
    },
    {
      title: "Version",
      dataIndex: "version",
      align: "center",
      key: "version",
      filters:filterCondition,
      filteredValue: filterInfo.version && filterInfo.version !== null 
                      ? filterInfo.version
                      : filterInfo.version === null
                        ? null
                        : filterValue,
      onFilter: (value, record) => record.version.indexOf(value) === 0
    },
    {
      title: "StartTime",
      dataIndex: "startDate",
      align: "center",
      key: "startDate",
    },
    {
      title: "EndTime",
      dataIndex: "endDate",
      align: "center",
      key: "endDate",
    },
    {
      title: (
        <Tooltip title={formatMessage({ id: "T0569" })}> {/* 새로고침 */}
          <SyncOutlined
            onClick={() => onClickReload()}
            style={{ fontSize: "14px", fontWeight: "bold" }}

            spin={spinReload}
          />
        </Tooltip>
      ),
      // key: "id",
      align: "center",
      render: (data, record, index) => (
        //Replay
        <div key={index} className="prod_icons">
          <>
            {
              // 상태가 완료인 경우에만 버튼 숨김 처리
              data.result !== "완료"
              &&
              <Tooltip title={formatMessage({ id: "T1167" })}>
                <MonitoringIcon
                  onClick={() => onClickStatus(data)}
                  className="abled_icons"
                  style={{ marginRight: "5px" }}
                />
              </Tooltip>
            }
            <Tooltip title={formatMessage({ id: "T0570" })}>
              <LogIcon
                onClick={() => onClickLog(data, record)}
                className="abled_icons"
              />
            </Tooltip>
          </>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (Object.keys(hfWorkflowLookup.workflowData).length === 0) return;
    fetchTestData();
  }, [hfWorkflowLookup.workflowData.workflowId]);

  const testCondition = hfWorkflowLookup.workflowData.statusCd === "WS30"
    && hfWorkflowLookup.workflowData.detailStatusCd === "WS31"
    && (hfWorkflowLookup.workflowData.empLevGbns.includes('M') || hfWorkflowLookup.workflowData.empLevGbns.includes('A'))

  const operTestCondition = hfWorkflowLookup.workflowData.statusCd === "WS40"
    && hfWorkflowLookup.workflowData.detailStatusCd === "WS56"
    &&  (hfWorkflowLookup.workflowData.empLevGbns.includes('M') || hfWorkflowLookup.workflowData.empLevGbns.includes('A'))

  const activateCondition = hfWorkflowLookup.workflowData.statusCd === "WS30" || hfWorkflowLookup.workflowData.statusCd === "WS40";

  return (
    <>
      <ScheduleServiceLog
        title={formatMessage({ id: "T1600" })} // 업무수행내역
        type="prod"
        recordData={logInfo}
        visible={logVisible}
        onOk={onOkLog}
        onCancel={onCancelLog}
      />
      <ProgressModal
        // visible={true} 
        visible={progressVisible}
        data={progressData}
        onCancel={onCancelStatus}
        modalMaximization={modalMaximization}
        setModalMaximization={setModalMaximization}
        onMaximizeModal={onMaximizeModal}
        onMinimizeModal={onMinimizeModal}
        overlap="true"
      />
      <Modal
        width={540}
        visible={testModifyVisible}
        title={formatMessage({ id: "T1617" })} //오류 사항 입력
        okText={formatMessage({ id: "T0066" })} // 입력
        onCancel={onCancelTestModify}
        onOk={onOkTestModify}
      >
        <Row>
          <Col span={5}>
            <Text className="basic_row__title" required>
              <FormattedMessage id="T1608" />{/* 오류 사항 */}
            </Text>
          </Col>
          <Col span={19}>
            <TextArea
              onChange={onChangeError}
              value={errorComment}
              maxLength={500}
              style={{ height: "100px" }}
              placeholder={formatMessage({ id: "T1646" })}  // 수정 사항 입력
            />
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Text className="basic_row__title">
              <FormattedMessage id="T0513" />{/* 첨부파일 */}
            </Text>
          </Col>
          <Col span={19}>
            {/* <Text className="basic_row__title"> */}
            <Upload
              action={`${END_POINT}/file/uploadMultipleFiles`}
              multiple={true}
              beforeUpload={onBeforeupload}
              onRemove={onRemoveFile}
              fileList={fileList}
            />
            {/* </Text> */}
          </Col>
        </Row>
      </Modal>
      <Modal
        width={700}
        visible={testPopup}
        title={formatMessage({ id: "T0397" })} // 테스트
        okText={formatMessage({ id: "T1618" })} //테스트 실행
        cancelText={formatMessage({ id: "T1307" })} // 닫기
        onCancel={onCancelTestProgress}
        onOk={onOkTestProgress}
      >
        <div className="status_box__info">
          {testPopupData?.length > 0
            ?
            <Table
              columns={columns}
              rowKey={record => record.id + record.procStatus}
              dataSource={testPopupData}
              size="small"
              pagination={true}
              showSorterTooltip={true}
              onChange={onChangePage}
              pageOptions={{
                current: pageOptions.current,
                pageSize: 5
              }}
            />
            :
            <div>
              <FormattedMessage id="T1625" />
              {/* 테스트 현황 데이터가 없습니다. */}
            </div>
          }
        </div>
      </Modal>

      <div className={activateCondition ? "basic_box" : "basic_box status__disabled"}>
        <div className={activateCondition ? "basic_box__title" : "basic_box__title status__disabled"}>
          <Text className={activateCondition ? "sub_box__title" : "sub_box__title status__disabled"}>
            <FormattedMessage id="T0521" />{/* 4. [테스트] 개발 검수 */}
          </Text>
        </div>
        <div className="basic_box__info" style={{ alignItems: "end" }}>
          <Row>
            <Col className={activateCondition ? "inspection_text" : "inspection_text status__disabled"}>
              {formatMessage({ id: "T1623" })
                .split("\n")
                .map((data) => {
                  return (
                    <span>
                      {data}
                      <br />
                    </span>
                  );
                })}
            </Col>
          </Row>
          {
            (testCondition || operTestCondition)
            &&
            <Row>
              <Col offset={15} span={9} style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={onClickTestProgress}
                  // disabled={!editCondition}
                  style={{ width: "120px", height: "35px" }}

                // style={{marginRight:"3px"}}
                >
                  <FormattedMessage id="T1619" />{/* 테스트 진행 */}
                </Button>
                <Button
                  onClick={onClickTestConfirm}
                  style={{ width: "120px", height: "35px" }}
                >
                  <FormattedMessage id="T0062" />{/* 테스트 완료 */}
                </Button>
                <Button
                  type="primary"
                  onClick={onClickTestModify}
                  style={{ width: "120px", height: "35px" }}
                >
                  수정 요청
                </Button>
              </Col>
            </Row>
          }
        </div>
      </div>
    </>
  );
};

export default WorkflowLookupInspection;
