import React from "react"; 
import { Input, Button, Text } from "components";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

/**
 * MemberInfo > MyPwdCheck
 * 직접 정보 수정 하기 전 view에서 넘어가는 페이지
 * 내 정보 수정 > 수정 > 기존 비밀번호 확인 페이지 
 */

 const MyPwdCheck = ({ onClickGoBack, onChangeCheckPwd, onClickConfirmPwd, password }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    return (
        <div className="pwd_check_container">
            <div className="pwd_check_box">
                <div className='pwd_check_notice'>
                    <Text>
                        {formatMessage({ id: "T1269" })
                            .split("\n")
                            .map((data, index) => {
                                return (
                                    <span key={index}>
                                        {data}
                                        <br />
                                    </span>
                                );
                            })}
                        {/* 고객님의 안전한 정보보호를 위하여 비밀번호를 다시 한 번 확인합니다.  */}
                        <br />
                    </Text>
                </div>
                <div>
                    <Input
                        placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1257' }) } })} // 기존 비밀번호 입력
                        value={password}
                        onChange={onChangeCheckPwd}
                        type='password'
                        style={{width: 400}}
                    />
                </div> 
            </div> 
            <div className='pwd_check_btn_section'>
                <Button
                    type='primary'
                    width='150'
                    height='40'
                    onClick={onClickConfirmPwd}
                >
                    <FormattedMessage id="T0064" />{/* 확인 */}
                </Button>
                <Button
                    type='default'
                    width='150'
                    height='40'
                    onClick={onClickGoBack}
                >
                    <FormattedMessage id="T0051" />{/* 취소 */}
                </Button>
            </div>
        </div>  
      
    )
}

export default MyPwdCheck;