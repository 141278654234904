import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Wrapper from "./Styled";
import { Link, withRouter } from "react-router-dom";
import { Table, Text, Button } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import { SuccessIcon, FailedIcon } from "icons";
import { Tooltip } from "antd";
import moment from "moment";
import { RightOutlined } from "@ant-design/icons";
import { getUniqueKey } from "utils/Str";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * 메인 home > 운영 현황
 *
 */

const OperSituation = () => {
  const { formatMessage } = useIntl();

  const profile = useSelector((state) => state.get("auth").get("profile"));
  const permission = useSelector((state) =>
    state.get(getUniqueKey("permission"))
  );

  const taskNm = formatMessage({ id: "T0002" }); // 과제명

  const [datas, setDatas] = useState([]);
  const [performStatusData, setPerformStatusData] = useState("");

  const columns = [
    {
      key: "taskNm",
      title: taskNm,
      dataIndex: "taskNm",
      render: (text, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <Tooltip title={text}>
              <span>{text}</span>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      key: "botStartDate",
      title: formatMessage({ id: "T0400" }), // 시작시간
      dataIndex: "botStartDate",
      width: 130,
      render: (text, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            {moment(text).format("YYYY-MM-DD HH:mm:ss")}
          </Link>
        );
      },
    },
    {
      key: "botEndDate",
      title: formatMessage({ id: "T0401" }), // 종료시간
      dataIndex: "botEndDate",
      width: 130,
      render: (text, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            {moment(text).format("YYYY-MM-DD HH:mm:ss")}
          </Link>
        );
      },
    },
    {
      key: "status",
      title: formatMessage({ id: "T0402" }), // 결과
      dataIndex: "status",
      width: 60,
      render: (text, record) =>
        text === "Complete" ? (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <div className="operation_table__content">
              <SuccessIcon style={{ color: "green" }} />
              &nbsp;
              <Text>{formatMessage({ id: "T0403" })}</Text>{/* 성공 */}
            </div>
          </Link>
        ) : (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <div className="operation_table__content">
              <FailedIcon style={{ color: "red" }} />
              &nbsp;
              <Text>{formatMessage({ id: "T0404" })}</Text>{/* 실패 */}
            </div>
          </Link>
        ),
    },
  ];

  const fetchBotPerformData = async (today, empNo) => {
    const result = await fetchAbsolute(
      "get",
      `/monitor/taskOperation/getBotPerformList?performDate=${today}&empNo=${empNo}`
    );
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    const tmpData = result.data.filter(
      (v) => v.status === "Complete" || v.status === "Failed"
    );
    setDatas(tmpData.slice(0, 5));
  };

  const fetchPerformStatus = async (today, empNo) => {
    const result = await fetchAbsolute(
      "get",
      `/monitor/taskOperation/getBotPerformStatus?performDate=${today}&empNo=${empNo}`
    );
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    setPerformStatusData(result.data);
  };

  useEffect(() => {
    if (permission.userLoading || !profile.EMP_NO) return;
    const flag = permission.user.findIndex((v) => v.fnCode === "FN29") !== -1;
    const today = moment().format("YYYYMMDD");
    fetchBotPerformData(today, flag ? "" : profile.EMP_NO);
    fetchPerformStatus(today, flag ? "" : profile.EMP_NO);
  }, [permission.user, profile.EMP_NO]);

  return (
    <Wrapper>
      <div className="common__container">
        <div className="common__div">
          <div className="common__title">{formatMessage({ id: "T1505" })}</div>
          {/* 운영 현황(당일) */}
          <Link to="/monitoring/status">
            <Button className="float_display__button">
              {formatMessage({ id: "T0096" })}{/* 더보기 */}
              <RightOutlined />
            </Button>
          </Link>
        </div>
        <div className="oper__info">
          <div className="operation_numbers">
            <div>
              <FormattedMessage
                id="T0405" // 운영중{number}건
                values={{
                  number:
                    +performStatusData.doneStatusCnt || 0 +
                    +performStatusData.failedStatusCnt || 0,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="T0406" // 성공{number}건
                values={{
                  number: performStatusData.doneStatusCnt || 0,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="T0407" // 실패{number}건
                values={{
                  number: performStatusData.failedStatusCnt || 0,
                }}
              />
              <Text style={{ fontSize: 9, fontWeight: "normal" }}>
                ({parseFloat(performStatusData.failedRatio || 0).toFixed(1)}%)
              </Text>
            </div>
          </div>
        </div>
        <div className="oper_table_area">
          <Table
            columns={columns}
            dataSource={datas}
            paginaton="false"
            defaultPageSize={5}
            rowKey={(item) => item.workItemId}
            locale={{ emptyText: `${formatMessage({ id: "T0408" })}` }} // 현재 운영 중인 과제가 없습니다.
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(OperSituation);
