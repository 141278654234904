import React from 'react';
import { Text } from 'components';
import { CheckOutlined } from '@ant-design/icons';
import { useIntl } from "react-intl";

const WorkflowStepper = ({ checkPoint }) => {
    const { formatMessage } = useIntl();
    const processing = formatMessage({ id: 'T0055' }); // 개발 진행

    const steps = [

        {
            key: 1,
            title: `${formatMessage({ id: 'T0395' })}`,
            des: `${formatMessage({ id: "T0424" })}`
        },
        {
            key: 2,
            title: `${formatMessage({ id: 'T0396' })}`,
        des: `${formatMessage({ id: "T0576" })}`
        },
        {
            key: 3,
            title: `${formatMessage({ id: 'T0396' })}`,
            des: processing
        },
        {
            key: 4,
            title: `${formatMessage({ id: 'T0397' })}`,
            des: `${formatMessage({ id: "T0577" })}`
        }
    ]

    return (
        <div className='assign_lookup__stepper'>
            {
                steps.map((step) => (
                    <div key={step.key} className={
                        `stepper__step 
                        ${step.key === checkPoint ? 'stepper_check_point' : checkPoint === 6 ? 'stepper_check_stop' : null}`
                    }>
                        <div className='stepper__circle'>
                        {checkPoint === 6 ? null : <CheckOutlined className={`stepper__check ${checkPoint === 6 && 'check_stop'}`} />}
                        </div>
                        <div className='stepper__text'>
                            <Text className='stepper__text_title'>{step.key}.&nbsp;{step.title}</Text>
                            <Text className='stepper__text_des'>{step.des}</Text>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default WorkflowStepper;