import fetchAbsolute from './fetchAbsolute';

export const managerPoint = async (pointType, taskId) => {
    const data = {
        pointType
    };
    const result = await fetchAbsolute('get', `/task/oracle/getTask/${taskId}`);
    if (!result.error) {
        const managerEmp = result.data.taskMemberList.find(v => v.mgrDevGbn === 'M' && v.deputyHeadYn === 'N').empNo;
        data.empNo = managerEmp;
    };
    return await fetchAbsolute('post', `/academy/pointSave`, {
        data
    });
};

export const empPoint = async (pointType, empNo) => {
    const data = {
        pointType,
        empNo
    };
    return await fetchAbsolute('post', `/academy/pointSave`, {
        data
    });
};