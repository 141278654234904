import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff; 
  /* 게시판 기본 정보 */ 
  .basic__title {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
  }  
  .table__flex_box {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .basic__wrapper {
    display: flex;
    flex-direction: column;
    gap: 16.3px;
    padding: 30px 39px;
  }
  .board_id__checking {
    display: flex;
    gap: 10px;
  }
  .board_id__checking button {
    height: 40px; 
  }
  .info_table__td span {
    font-size: 14px;
  }
  .ant-radio-wrapper {
    align-items: flex-end;
  }
  .info_table__input {
    width: 440px;
    height: 40px;
  }
  /* 게시판 컬럼 설정 */ 
  .col__title {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
    padding-bottom: 12.5px;
    border-bottom: 2px solid #de3d0f;
  }  
  .setting_col__wrapper {
    display: flex;
    flex-direction: column; 
    padding: 30px 39px;
  }
  .setting_col__container {
    align-self: center;
  }
  .tables__container {
    display: flex; 
    height: 325px; 
  }
  .reset__button__area {
    padding: 23.5px 0 11px 0; 
    text-align: end;
  }
  .reset__button {
    font-size: 12px;
    width: 80px;
    height: 24px;
  }
  /* 게시판 컬럼 설정 > 테이블 2개 */
  .ant-table-wrapper {
    width: 500px;
    height: 325px;
    font-size: 12px;
  }
  .table_button__company {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 117px 10px 151px 10px;
  }
  .table_button__company .buttons {
    width: 26px;
  }
  .col_bottom__part {
    padding-top: 24.5px;
    border-bottom: 2px solid #888888;
  }
  .ant-table-placeholder{
    min-height: 291px;
  }
  /* 게시판 컬럼 설정 > 테이블 자체 속성 */
  .ant-table-thead > tr > th {
    font-size: 12px;
  }
  .ant-table-thead > tr > th {
    height: 34px;
    padding: 8px 0 8px 22px;
  }
  .ant-table-tbody > tr > td {
    text-align: left !important;
    height: 30px; 
    padding: 6px 0 6px 22px;
  }  
  .ant-table {
    width: 500px;
    max-height: 325px;
  } 
  /* 첫번째 테이블 */
  .col_table_list__wrapper .ant-table-selection-col {
    width: 40px !important;
  }
  .col_table_list__wrapper .ant-table-thead>tr>th:not(:last-child)::before {
    display: none;
  }
`;

export default Wrapper;
