import { getUniqueKey } from 'utils/Str';

export const SET_BOXWOODWORKFLOWlIST_VALUE = getUniqueKey('SET_BOXWOODWORKFLOWlIST_VALUE');
export const SET_BOXWOODWORKFLOWLIST_SPREAD = getUniqueKey('SET_BOXWOODWORKFLOWLIST_SPREAD');
export const RESET_BOXWOODWORKFLOWLIST_VALUE = getUniqueKey('RESET_BOXWOODWORKFLOWLIST_VALUE');
export const SET_BOXWOODWORKFLOWLIST_FILTERID = getUniqueKey('SET_BOXWOODWORKFLOWLIST_FILTERID');

export function setBoxwoodWorkflowListValue(key, value) {
    return {
        type: SET_BOXWOODWORKFLOWlIST_VALUE,
        key,
        value
    };
}

export function setBoxwoodWorkflowListSpread(data) {
    return {
        type: SET_BOXWOODWORKFLOWLIST_SPREAD,
        data
    }
}

export function resetBoxwoodWorkflowListValue() {
    return {
        type: RESET_BOXWOODWORKFLOWLIST_VALUE
    }
}

export function setBoxwoodWorkflowListFilterId(filterId) {
    return {
        type: SET_BOXWOODWORKFLOWLIST_FILTERID,
        filterId
    }
}