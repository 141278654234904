import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import { Text, Loading } from 'components';
import styled from "styled-components";
import fetchAbsolute from "utils/fetchAbsolute";
import { EqualIcon, FallingIcon, RisingIcon } from "icons";
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

const { Title } = Typography;

/**
 * 대시보드 > ROI 현황 > 예측 ROI
 * SKC와 구분! DIY > PBL
 */

/* 박스 컴포넌트 */
const BoxWrapper = styled.div`
  border: 1px solid #d0d1d6;
  background: #fff; 
  width: 100%;
  height: 250px;
  text-align: center;
  .top {
    background: #f8f8f9;
    padding: 15px;
  }
  .top .main {
    font-weight: bold;
    font-size: 15px;
  }
  .top .sub {
    color: #888888;
    font-size: 11px;
  }
  .bottom {
    display: flex;
    justify-content: center;
    gap: 50px;
    align-items: center;
    height: 70%;
  }
  .bottom.next {
    flex-direction: column;
  }
  .bottom .this_month span {
    color: #ff7a00;
    font-weight: bold;
    font-size: 60px;
  }
  .bottom .this_month {
    color: #ff7a00;
    align-self: center;
  }
  .bottom .status_cnt {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 30px;
    padding: 0 15px;
    border-radius: 10px;
    height: fit-content; 
    justify-content: center;
  }
  .bottom .status_cnt span {
    font-size: 25px;
  }
  .bottom .status_cnt span svg {
    width: 20px;
    height: 20px;
  }
  .bottom .status_cnt.positive,
  .bottom .status_cnt.positive span {
    background: #ffe6ea;
    color: #e54156;
  }
  .bottom .status_cnt.negative,
  .bottom .status_cnt.negative span {
    background: #bbe3fb;
    color: #1b7bb3;
  }
  .bottom .status_cnt.equal, .bottom .status_cnt {
    background: #c9ecd5;
    color: #488a5e;
    min-width: 100px;
    min-height: 47px;
  }
  .font_18 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
`;

const Box = ({ title, rateOfChange, thisMonth, lastMonth }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const [checkNumber, setCheckNumber] = useState("");
    const [len, setLen] = useState(false);

    useEffect(() => {
        const isPositive = (number) => {
            return number >= 0;
        };
        if (isPositive(rateOfChange)) {
            if (rateOfChange === 0 || rateOfChange === undefined) {
                setCheckNumber("equal");
            } else if (rateOfChange > 0) {
                setCheckNumber("positive");
            } else {
                setCheckNumber("negative");
            }
        }
        //100581274
        if (rateOfChange >= 1000000 || rateOfChange >= 1000000) {
            setLen(true);
        }
    }, [rateOfChange]);

    return (
        <BoxWrapper>
            <div className="top">
                <div className="main">{title}</div>
            </div>
            <div className={`bottom ${len && "next"}`}>
                <div className="this_month">
                    {
                        <>
                            <Text>{parseFloat(thisMonth || 0).toFixed(2)}</Text>
                        </>
                    }
                </div>
                <div className="right__section">
                    <div className="last_month">
                        {fmMessage({ id: 'T1539', values: { number: parseFloat(lastMonth || 0).toFixed(2) } })}{/* 전월 : {number} */}
                    </div>
                    <div className={`status_cnt ${checkNumber}`}>
                        {checkNumber === "positive" ? "+" : ""}
                        {
                            <>
                                <Text>{parseFloat(rateOfChange || 0).toFixed(2)}</Text>
                            </>
                        }
                        {checkNumber === "positive" ? (
                            <RisingIcon />
                        ) : checkNumber === "negative" ? (
                            <FallingIcon />
                        ) : (
                            <EqualIcon />
                        )}
                    </div>
                    <div className="chage_rate">
                        {/* 전월 대비 증감률 */}
                        <Text>{formatMessage({ id: "T1538" })}</Text>
                    </div>
                </div>
            </div>
        </BoxWrapper>
    );
};

const PredictionROI = ({ standardDate, conditions }) => {
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [predictionAll, setPredictionAll] = useState({});
    const [predictionDiy, setPredictionDiy] = useState({});
    const [predictionCoe, setPredictionCoe] = useState({});

    const getPredictionList = async () => {
        setIsLoading(true);
        const result = await fetchAbsolute("post", `/dashboard/RoiStatusDesign/PredictionRoiList`, {
            data: {
                deployDate: standardDate,
                orgCd: conditions.companyCd,
                taskId: conditions.taskId
            }
        }
        );
        if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.  
        setPredictionAll(result.data.find(data => data.taskType === 'ALL'));
        setPredictionCoe(result.data.find(data => data.taskType === 'COE'));
        setPredictionDiy(result.data.find(data => data.taskType === 'DIY'));
        setIsLoading(false);

    };

    useEffect(() => {
        getPredictionList();
    }, [standardDate, conditions.companyCd, conditions.taskId]);

    if (isLoading) {
        return <Loading visible={isLoading} />
    }
    return (
        <div className='box__container roi'>
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1522" })}{/* 예측 ROI */}
            </Title>
            <Divider />
            <div className="dashboard_graph">
                <div className="box_area">
                    {
                        predictionAll === undefined ?
                            <Box
                                title="ALL"
                            />
                            :
                            <Box
                                title="ALL"
                                thisMonth={+predictionAll.thisMonth || 0}
                                lastMonth={+predictionAll.lastMonth || 0}
                                rateOfChange={+predictionAll.rateOfChange || 0}
                            />
                    }{
                        predictionDiy === undefined ?
                            <Box
                                title="DIY"
                            />
                            :
                            <Box
                                title="DIY"
                                thisMonth={+predictionDiy.thisMonth || 0}
                                lastMonth={+predictionDiy.lastMonth || 0}
                                rateOfChange={+predictionDiy.rateOfChange || 0}
                            />
                    }{
                        predictionDiy === undefined ?
                            <Box
                                title="COE"
                            />
                            :
                            <Box
                                title="COE"
                                thisMonth={+predictionCoe.thisMonth || 0}
                                lastMonth={+predictionCoe.lastMonth || 0}
                                rateOfChange={+predictionCoe.rateOfChange || 0}
                            />
                    }
                </div>
            </div>
        </div>
    )
}

export default PredictionROI