import React, { useState, useEffect } from "react";
import { Text, Input, Modal, Checkbox } from "components";
import styled from 'styled-components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
/**
 * 사용자 소통 기능
 * 메세지 보내기
 */


const ModalStyled = styled(Modal)`
 .ant-modal-header {
     border-top: 7px solid #ff7b00;
 }
 .ant-modal-title {
     font-weight: bold;
 }
 .message__box {
   display: flex;
   align-items: baseline;
 }
 .message__box {
   margin-bottom: 10px;
 }
 .message__box:last-child {
   margin-bottom: 0;
 }
 .message__text {
   flex: 1;
   font-size: 14px;
 }
 .message__input {
   flex: 7;
 }
`


const SendPoint = ({ visible = false, onOk = () => { }, onCancel = () => { }, width = 500, empNumList = [], ...args }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();
    const pointGive = formatMessage({ id: "T0043" });  // 포인트 지급

    const [point, setPoint] = useState(0);
    const [minusChecked, setMinusChecked] = useState(false);

    const onChangeMinus = (e) => {
        const { checked } = e.target;
        setMinusChecked(checked);
    }

    const onChangePoint = (e) => {
        const { value } = e.target;
        if (isNaN(+value)) {
            return;
        }
        setPoint(+value);
    }

    const onOkModal = async () => {
        if (point === 0) return alert(formatMessage({ id: 'T0278' }));
        //포인트를 입력해주세요.

        const pointStatus = {
            fail: 0,
            success: 0
        };

        for (let i = 0; i < empNumList.length; i++) {
            const sendResult = await fetchAbsolute('post', `/academy/pointSaveAdimin?empNum=${empNumList[i]}&point=${minusChecked ? point * -1 : point}`);
            if (sendResult.error) {
                pointStatus.fail += 1;
            } else {
                pointStatus.success += 1;
            }
        }

        alert(fmMessage({ id: 'T0279', values: { s_number: pointStatus.success, f_number: pointStatus.fail } }));
        //`포인트 지급을 완료했습니다.\n성공: ${pointStatus.success}건 실패: ${pointStatus.fail}건`);

        onOk();
    }

    useEffect(() => {
        if (visible) {
            setPoint(0);
            setMinusChecked(false);
        }
    }, [visible]);

    return (
        <ModalStyled
            width={width}
            visible={visible}
            title={pointGive}
            onOk={onOkModal}
            onCancel={onCancel}
            okText={formatMessage({ id: "T1196" })} // 지급
            {...args}
        >
            <div className='message__box'>
                <Text className='message__text'>
                    {formatMessage({ id: "T0603" })}{/* 지급할 포인트를 입력해주세요. */}
                </Text>
                <Checkbox
                    checked={minusChecked}
                    onChange={onChangeMinus}
                >
                    {formatMessage({ id: "T0604" })}{/* 포인트 차감 */}
                </Checkbox>
            </div>
            <div className='message__box'>
                <Input
                    className='message__input'
                    value={point}
                    onChange={onChangePoint}
                    defaultValue={0}
                />
            </div>
        </ModalStyled>
    );
};

export default SendPoint;