import React, { useEffect, useState } from "react";
import "./common.css";

/**
 * Filename: HFBpdIframe/index.js
 * Description: 과제 등록 -> Process Design Iframe
 */

const HFBpdIframe = ({ type, data = {}, customHeight = "700", params }) => {
  const url = "https://dreago.net:8070/ibpm-web";
  const [src, setSrc] = useState("");

  /* 데이터가 안넘어와서 여기서  BPD 조회 API 호출하기 */
  useEffect(() => {
    /* bpd 아이디가 필요해서 params */
    if (!type||Object.keys(data).length===0) return;

    let bpdProcessId = data.processId;

    //EXECUTEFLAG=N
    if (type === "insert") {
      setSrc(
        url +
        `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${bpdProcessId}?locale=ko&EXECUTEFLAG=N&processVersion=EDIT`
      );
    } else if (type === "modify") {
      setSrc(
        url +
        `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${bpdProcessId}?locale=ko&EXECUTEFLAG=N&processVersion=EDIT`
      );
    } else if (type === "lookup") {
      setSrc(
        url +
        `/openapi/bpm/popup/processPopUpMain?processid=${bpdProcessId}&viewDetailPage=false&LegacyAuthCheckFilterUser=dreago_admin`
      );
    }
  }, [type, params, data]);

  return (
    <iframe
      style={{
        border: 0,
        margin: "0 auto",
        display: "block",
      }}
      width="100%"
      height={customHeight}
      title="bpd"
      id="bpd"
      className="bpd"
      src={src}
    />
  );
};

export default HFBpdIframe;
