import camelCase from 'lodash/camelCase';
import { getUniqueKey } from 'utils/Str';

/**
 * Application 내 모든 Saga 들을 수집하는 기능을 담당합니다.
 * 
 * 해당 파일 수정 금지
 */
const sagas = {};
const req = require.context('.', true, /\.\/.+\/saga\.js$/);
req.keys().forEach(key => {
  const sagaName = getUniqueKey(camelCase(key.replace(/\.\/(.+)\/.+$/, '$1')));
  sagas[sagaName] = req(key).default;
});

export default sagas;
