import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { PageTemplate, Header, Footer, AddAttributeModal, ModifyAttributeModal, DeleteCredentialModal, DeleteAttributeModal, AddCredentialModal } from 'components';
import CredentialTop from './CredentialTop.js';
import CredentialBottom from './CredentialBottom.js';
import fetchAbsolute from "utils/fetchAbsolute";
import { setVisible } from 'store/loading/action';
import Wrapper from './Styled';
import { useIntl } from "react-intl";
/**
 * Filename: AdminCredential/index.js
 * Description: Admin -> Credential 관리 컴포넌트
 */

const AdminCredential = ({ history }) => {
    const { formatMessage } = useIntl();

    const crInfo = useSelector(state => state.get(getUniqueKey('crInfo'))).filter(v => v.crType !== 'EDU');

    const dispatch = useDispatch();

    const [credentialList, setCredentialList] = useState([]);
    const [credentialName, setCredentialName] = useState('');
    const [crType, setCrType] = useState(crInfo[0] || {});
    const [attributeData, setAttributeData] = useState({});
    const [tableLoading, setTableLoading] = useState(false);

    // 모달 Visible
    const [addAttrVisible, setAddAttrVisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [deleteCredentialVisible, setDeleteCredentialVisible] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [addCredentialVisible, setAddCredentialVisible] = useState(false);

    // 선택한 Attribute 데이터
    const [selectAttribute, setSelectAttribute] = useState({});

    // Credential 목록 가져오는 함수
    const fetchCredentials = async () => {
        dispatch(setVisible(true));
        const result = await fetchAbsolute('post', `/hwlm/getCredentailList?crCode=${crType.crCd}`, {
            data: {
                page: {
                    offset: 0,
                    length: 10000
                },
                sort: [
                    {
                        field: "id",
                        direction: "asc"
                    }
                ]
            },
            headers: {
                'Content-Type': 'application/json'
            },
        });
        dispatch(setVisible(false));
        if (result.error) {
            alert(formatMessage({ id: 'T0256' })); // Credential 목록을 가져오는 도중 에러가 발생했습니다.
            return history.push(result.redirectURL || '/error/client');
        }

        const { list } = result.data;
        setCredentialList([...list.map(v => ({
            id: v.id,
            name: v.name
        }))]);
    }

    // attribute 목록 가져오기
    const fetchAttributeData = async () => {
        setTableLoading(true);
        const result = await fetchAbsolute('get', `/hwlm/getCredentail?crCode=${crType.crCd}&name=${credentialName}`);
        setTableLoading(false);
        if (result.error && Math.floor(result.status / 100) !== 4) {
            return alert(formatMessage({ id: 'T0257' })); // Attribute 목록을 가져오는 도중 에러가 발생했습니다
        }
        setAttributeData({ ...result.data });
    }

    const onChangeCredentialName = (value) => {
        setCredentialName(value);
    }

    const onChangeCrType = (value) => {
        const cr = crInfo.find(v => v.crCd === value);
        setCrType({ ...cr });
    }

    // onOk 함수들
    const onOkAddAttrVisible = () => {
        setAddAttrVisible(false);
        fetchAttributeData();
    };
    const onOkDeleteAttr = () => {
        setDeleteVisible(false);
        fetchAttributeData();
    };
    const onOkEditAttr = () => {
        setEditVisible(false);
        fetchAttributeData();
    };
    const onOkDeleteCredential = () => {
        setCredentialName("");
        setDeleteCredentialVisible(false);
        fetchCredentials();
    };
    const onOkAddCredential = () => {
        setAddCredentialVisible(false);
        fetchCredentials();
    };

    /**
     * Modal 띄우는 함수들
     */
    const onClickAddCredential = () => {
        setAddCredentialVisible(true);
    }
    const onClickDeleteCredential = () => {
        setDeleteCredentialVisible(true);
    }

    const onClickAddAttribute = () => {
        setAddAttrVisible(true);
    }
    const onClickDeleteAttribute = (record) => {
        setSelectAttribute({ ...record });
        setDeleteVisible(true);
    }
    const onClickEditAttribute = (record) => {
        setSelectAttribute({ ...record });
        setEditVisible(true);
    }

    useEffect(() => {
        fetchCredentials();
        setCredentialName('');
    }, [crType]);

    useEffect(() => {
        if (credentialName.length <= 0) {
            setAttributeData({});
        } else {
            fetchAttributeData();
        }
    }, [credentialName]);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <AddAttributeModal
                visible={addAttrVisible}
                onCancel={() => setAddAttrVisible(false)}
                onOk={onOkAddAttrVisible}
                credentialName={credentialName}
                crType={crType.crType}
            />
            <ModifyAttributeModal
                visible={editVisible}
                onCancel={() => setEditVisible(false)}
                onOk={onOkEditAttr}
                credentialName={credentialName}
                attribute={selectAttribute}
                crType={crType.crType}
            />
            <DeleteCredentialModal
                visible={deleteCredentialVisible}
                onCancel={() => setDeleteCredentialVisible(false)}
                onOk={onOkDeleteCredential}
                credentialName={credentialName}
                crType={crType.crType}
            />
            <DeleteAttributeModal
                visible={deleteVisible}
                onCancel={() => setDeleteVisible(false)}
                onOk={onOkDeleteAttr}
                credentialName={credentialName}
                attribute={selectAttribute}
                crType={crType.crType}
            />
            <AddCredentialModal
                visible={addCredentialVisible}
                onOk={onOkAddCredential}
                onCancel={() => setAddCredentialVisible(false)}
                crType={crType.crType}
            />
            <Wrapper>
                <CredentialTop
                    credentialList={credentialList}
                    credentialName={credentialName}
                    crType={crType}
                    crInfo={crInfo}
                    onChangeCredentialName={onChangeCredentialName}
                    onChangeCrType={onChangeCrType}
                    onClickAddAttribute={onClickAddAttribute}
                    onClickAddCredential={onClickAddCredential}
                    onClickDeleteCredential={onClickDeleteCredential}
                />
                <CredentialBottom
                    attributeData={attributeData}
                    tableLoading={tableLoading}
                    onClickDeleteAttribute={onClickDeleteAttribute}
                    onClickEditAttribute={onClickEditAttribute}
                />
            </Wrapper>
        </PageTemplate >
    );
}

export default AdminCredential;