import React, { useState, useEffect, useRef } from 'react';
import { PageTemplate, Header, Footer } from 'components';
import { withRouter } from 'react-router-dom';
import RegisterMemberInfo from './RegisterMemberInfo';
import Wrapper from './Styled';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

/**
 * 총괄적으로 회원 정보 관리하는 화면
 * 관리자 > 회원 관리 > 회원 등록 / 수정, 개인정보수정 페이지
 * Type 종류 : register(회원 등록), modify(관리자의 정보 수정), myinfo(내 정보 수정)
 */

const initialInputValues = {
    orgCds: {},
    prePassword: '',
    password: '',
    rePassword: '',
}

const MemberInfo = ({ history, type, match: { params } }) => {
    const fmMessage = useFormatMessage();
    const { formatMessage } = useIntl();

    const [memberInfo, setMemberInfo] = useState({});
    const [pwCheck, setPwCheck] = useState('');
    const [inputValues, setInputValues] = useState({ ...initialInputValues });

    const requireFields = {
        email: {
            ref: useRef(null),
            message: formatMessage({ id: 'T0602' }), // 이메일
            use: type === 'register',
        },
        name: {
            ref: useRef(null),
            message: formatMessage({ id: 'T0012' }), // 이름
            use: type === 'register',
        },
        phone: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1255' }), // 전화번호
            use: type === 'register',
        },
        prePassword: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1257' }), // 기존 비밀번호
            use: type === 'myinfo',
        },
        password: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1258' }), // 새 비밀번호
            use: true,
        },
        rePassword: {
            ref: useRef(null),
            message: formatMessage({ id: 'T1260' }), // 새 비밀번호 확인
            use: true,
        },
    };

    const onChangePassword = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
    }

    const onChangeRePassword = (e) => {
        const { name, value } = e.target;
        setInputValues({ ...inputValues, [name]: value });
        if (inputValues.password && inputValues.password !== value) {
            setPwCheck(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // 비밀번호가 일치하지 않습니다.
        } else {
            setPwCheck('')
        }
    }

    useEffect(() => {
        if (!params.empNum) return;
        const fetchMemberInfo = async () => {
            const result = await fetchAbsolute('get', `/common/memberInfo?empNum=${params.empNum}`);
            if (result.error) {
                return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            }
            // 전화번호는 DB에 하이픈 없이 저장되기 때문에
            setMemberInfo({ ...result.data, cphoneNum: result.data.cphoneNum?.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "") || "" });
        }
        fetchMemberInfo();
    }, [params.empNum]);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none' {...{
            ...(
                // 회원 정보 등록
                // T1265 : {name} 등록 , T1266 : 회원 정보
                {
                    menuTitle: `${fmMessage({ id: 'T1265', values: { name: formatMessage({ id: 'T1266' }) } })}`,
                    depthList: [`${fmMessage({ id: 'T1265', values: { name: formatMessage({ id: 'T1266' }) } })}`]
                }
            )
        }}
        >
            <Wrapper>
                <RegisterMemberInfo history={history} type={type} memberInfo={memberInfo} inputValues={inputValues} onChangePassword={onChangePassword} onChangeRePassword={onChangeRePassword} requireFields={requireFields} />
            </Wrapper>
        </PageTemplate>
    )
}

export default withRouter(MemberInfo);