import React, { useState, useEffect } from "react";
import { PageTemplate, Header, Footer, Tabs } from "components";
import Wrapper from "./Styled";
import AssignClassification from "./AdminCommonTabs/AssignClassification";
import DevicePoolGroup from "./AdminCommonTabs/DevicePoolGroup";
import AssignmentPause from "./AdminCommonTabs/AssignmentPause";
import CrInfo from "./AdminCommonTabs/CrInfo";
import KPIInfo from "./AdminCommonTabs/KPIInfo";
import { useIntl } from 'react-intl';

const { TabPane } = Tabs;

const AdminCommon = ({
  history,
  match: { params },
}) => {
  const { formatMessage } = useIntl();

  const [tabsValue, setTabsValue] = useState("");
  const [checked, setChecked] = useState(false);

  const onChangeTab = (key) => {
    history.push(`/admin/common/${key}`);
  };

  useEffect(() => {
    if (!params.tabValue) {
      history.replace(`/admin/common/work`);
    }
    setTabsValue(params.tabValue);
  }, [params.tabValue]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <div>
        <Wrapper>
          {tabsValue !== "" && (
            <Tabs
              type="card"
              custom={false}
              activeKey={tabsValue}
              defaultActiveKey={tabsValue}
              onChange={onChangeTab}
            >
              <TabPane tab={formatMessage({ id: "T0726" })} key="work">{/* 과제 업무 분류 */}
                <AssignClassification />
              </TabPane>
              <TabPane tab={formatMessage({ id: "T0474" })} key="pause">{/* 과제 중단 사유 */}
                <AssignmentPause />
              </TabPane>
              <TabPane tab={formatMessage({ id: "T0727" })} key="pool">{/* Device Pool Group */}
                <DevicePoolGroup />
              </TabPane>
              <TabPane tab={formatMessage({ id: "T0728" })} key="crinfo">{/* CR 정보 */}
                {checked === true && (
                  <CrInfo history={history} />
                )}
                {checked === false && (
                  <CrInfo history={history} />
                )}
              </TabPane>
              <TabPane tab={formatMessage({ id: "T0729" })} key="kpi">{/* KPI 기본 기준 정보 */}
                <KPIInfo />
              </TabPane>
            </Tabs>
          )}
        </Wrapper>
      </div>
    </PageTemplate>
  );
};

export default AdminCommon;