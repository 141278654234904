import React, { Children, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import changeLocale from "./actions";
import moment from "moment";

import enUS from "antd/es/locale/en_US";
import koKR from "antd/es/locale/ko_KR";
import zhCN from "antd/es/locale/zh_CN";

import "moment/locale/ko";
import "moment/locale/en-au";
import "moment/locale/zh-cn";

const antdLocale = {
  ko: koKR,
  en: enUS,
  zh: zhCN,
};

const momentLocale = {
  ko: "ko",
  en: "en-au",
  zh: "zh-cn",
};

const LanguageProvider = (props) => {
  const dispatch = useDispatch();
  const { children } = props;
  const [messages, setMessages] = useState(props.messages || {});
  const profile = useSelector((state) => state.get("auth").get("profile"));
  const locale = useSelector(
    (state) => state.get("auth").get("profile").locale
  );

  useEffect(() => {
    // if (!profile.EMP_NO || !token.accessToken) return;
    if (!profile.EMP_NO) return;
    moment.locale(momentLocale[locale]);
    localStorage.setItem("locale", locale || "ko");
    const getLanguageMessage = async () => {
      const userLocale = await fetchAbsolute(
        "get",
        "/common/getLocaleUseUserAdInfo"
      );

      if (userLocale.data !== locale) {
        dispatch(changeLocale(userLocale.data));
        return;
      }
      const result = await fetchAbsolute("get", `/localeMsg/selectLocaleMsg`);
      const obj = {};
      result.data.forEach((v) => {
        obj[v.messagekey] = v.messagecontent;
      });
      setMessages({ ...obj });
    };
    getLanguageMessage();
  }, [locale, profile]);

  if ((!messages || Object.keys(messages).length === 0) && profile.EMP_NO)
    return null;
  return (
    <ConfigProvider locale={antdLocale[locale]}>
      <IntlProvider locale={locale} key={locale} messages={messages}>
        {Children.only(children)}
      </IntlProvider>
    </ConfigProvider>
  );
};

export default LanguageProvider;
