import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .wrap{
        display:flex;
    }
    .admin_member__button_group {
        display: flex;
        justify-content: flex-end;
        gap: 0 25px;
    }
    .title_name {
        margin-bottom:1em;
        font-size:18; 
        font-weight:bold; 
        lineheight: 1.38;
        margin-top:1em; 
    }
    .realtime_content_box {
        padding: 28px 25px;
        border :1px solid #d0d1d6;
        background: white;
      }
    .update_content{
        border: 1px solid #d0d1d6;
        border-bottom: none;
    }
`;
 
export default Wrapper;