import React, { useEffect, useRef } from "react";
import {
  AssignEnterBox,
  Text,
  Upload,
  Table,
  Button,
  NameTag,
  FormTable,
} from "components";
import { MinusCircleFilled } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const AssignEnterSystemBox = ({
  insertDatas,
  onChangeDatas,
  onClickSystemOpen,
  onClickMenuOpen,
  removeUploadedFile,
  onClickDelete,
  defaultFiles = [],
}) => {
  const END_POINT = process.env.REACT_APP_API_URI;
  const tmpFile = useRef([]);
  const columns = [
    {
      title: <FormattedMessage id="T1120" />, // 시스템
      dataIndex: "systemName",
      key: "systemName",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.1",
      dataIndex: "level1",
      key: "level1",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.2",
      dataIndex: "level2",
      key: "level2",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.3",
      dataIndex: "level3",
      key: "level3",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.4",
      dataIndex: "level4",
      key: "level4",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.5",
      dataIndex: "level5",
      key: "level5",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "",
      key: "delete",
      width: 30,
      render: (e, item, index) => (
        <MinusCircleFilled onClick={() => onClickDeleteTable(index)} />
      ),
    },
  ];

  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const sameNameNotUpload = formatMessage({ id: "T0145" }); // 같은 이름의 파일은 업로드 할 수 없습니다.

  const onClickDeleteButton = (e, { label, index }) => {
    onClickDelete(label, index);
  };
  const onClickDeleteTable = (index) => {
    onClickDelete("usingMenu", index);
  };
  const onBeforeupload = (inFile) => {
    if (
      insertDatas.fileList.findIndex((file) => file.name === inFile.name) !== -1
    ) {
      alert(sameNameNotUpload);
      return false;
    }

    if (inFile.size > 1024 * 1024 * 100) {
      return alert(
        fmMessage({
          id: "T1571",
          values: { field: Math.round((inFile.size / 1024 / 1024) * 100) / 100 },
        })
      );
      // 100MB 이하 파일만 등록할 수 있습니다.\n\n "현재파일 용량 : {field}MB
    }
    tmpFile.current.push(inFile);
    onChangeDatas("fileList", [...tmpFile.current]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) removeUploadedFile(rmFile.seq);
    tmpFile.current = [
      ...insertDatas.fileList.filter((file) => file.uid !== rmFile.uid),
    ];
    onChangeDatas("fileList", [...tmpFile.current]);
  };

  useEffect(() => {
    if (defaultFiles.length > 0) tmpFile.current = [...defaultFiles];
  }, [defaultFiles]);

  return (
    <AssignEnterBox title={formatMessage({ id: "T0446" })}>{/* 업무 유관 시스템 정보 */}
      <FormTable>
        <colgroup>
          <col width="20.833333333333336%" />
          <col width="79.16666666666667%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T0447" })}</Text>
              {/* 업무 수행 시 사용 시스템 */}
            </th>
            <td>
              <Button onClick={onClickSystemOpen}>
                <FormattedMessage id="T0049" />
                {/* 추가 */}
              </Button>
              <div
                className="flex flex-wrap-wrap"
                style={{ gap: "10px 20px", marginTop: 8 }}
              >
                {insertDatas.dependencySystem.map((data, index) => {
                  return (
                    <NameTag
                      key={data.systemCd || data.appId}
                      text={data.systemName || data.appNm}
                      isDelete={true}
                      label="dependencySystem"
                      index={index}
                      onDelete={onClickDeleteButton}
                      systemIO={
                        data.systemKind === "I"
                          ? `${formatMessage({ id: "T0497" })}` // 내부
                          : `${formatMessage({ id: "T0498" })}` // 외부
                      }
                    />
                  );
                })}
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T0448" })}</Text>
              {/* 업무 수행 시 사용 메뉴 */}
            </th>
            <td>
              <div className="flex flex-wrap-wrap" style={{ gap: "10px 0" }}>
                <Button onClick={onClickMenuOpen}>
                  <FormattedMessage id="T0049" />
                  {/* 추가 */}
                </Button>
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={insertDatas.usingMenu}
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T0449" })}</Text>
              {/* 참고자료 */}
            </th>
            <td>
              <Upload
                action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                multiple={true}
                beforeUpload={onBeforeupload}
                onRemove={onRemoveFile}
                fileList={insertDatas.fileList}
              />
            </td>
          </tr>
        </tbody>
      </FormTable>
    </AssignEnterBox>
  );
};

export default AssignEnterSystemBox;
