import React, { useEffect, useState } from "react";
import { Text, Input, Button, FormTable, Table, Modal } from "components";
import { Select, Typography } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "../Styled";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;
const { Title } = Typography;

/**
 * Filename: CrInfoInsert/index.js
 * Description:CrInfoInsert
 */

const arr = [
  (new Date().getFullYear() + 1).toString(),
  new Date().getFullYear().toString(),
];

const initialInputValues = {
  FTE: "",
  avgSalary: "",
  investCost: "",
  investInfraUB: "",
  investInfraCreator: "",
  investInfraCostAB: "",
  investInfraHW: "",
  prodCostInner: "",
  prodCostExternal: "",
  prodInfraCreator: "",
  prodInfraUB: "",
  prodInfraAB: "",
  prodInfraHW: "",
};

const KPIBasicInfo = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [yearArr, setYearArr] = useState([...arr]);
  const [companyCd, setCompanyCd] = useState("");
  const [dataMax, setdataMax] = useState(0);
  const [dataCheck, setDataCheck] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [descVisible, setDescVisible] = useState(false);
  const [selectedYear, setSelectedYear] = useState(0);
  const [yyyy, setYyyy] = useState([...arr]);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [PoolVisible, setPoolVisible] = useState(false);

  const onSelectYear = (e) => {
    setSelectedYear(e);
  };
  const onCancelDelete = () => setPoolVisible(false);

  const onClickDeleteButton = (e) => {
    setPoolVisible(true);
  };

  const onChangeInputVal = (e) => {
    const { name, value } = e.target;
    const num = onlyNum(value);
    setInputValues({ ...inputValues, [name]: num });
  };

  const onlyNum = (data) => {
    return data.replace(/[^0-9]/g, "");
  };

  const onOkDelete = async () => {
    setPoolVisible(false);
    const deleteKPI = await fetchAbsolute(
      "post",
      `/admin/commonMaster/deleteKpiStandard`,
      {
        data: {
          companyCd: companyCd,
          byear: +selectedYear,
        },

        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (deleteKPI.error) {
      return alert(formatMessage({ id: "T0247" }));
      //삭제에 실패하였습니다.
    }
    alert(formatMessage({ id: "T0254" }));
    //"KPI 정보가 삭제되었습니다."); yyyyy
    setInputValues({ ...initialInputValues });
    return;
  };

  // 정규식을 이용한 숫자만 추출
  const uncomma = (str) => {
    if (str.length > 0) {
      return Number(str.replace(/,/gi, ""));
    }
  };

  // 저장할 때 빈 칸이 오는 지 확인
  const getKeyByValue = (obj, value) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };

  const fetchYear = async () => {
    const year = await fetchAbsolute(
      "get",
      "/admin/commonMaster/getKpiStandardYearList"
    );

    if (year.data?.length === 0) {
      setYyyy([new Date().getFullYear(), new Date().getFullYear() + 1]);
    } else {
      // setdataMax(Math.max(...year.data).toString());
      setdataMax(new Date().getFullYear());
      setDataCheck([...year.data]);
      setYyyy(Array.from(new Set([...yearArr, ...year.data])));
    }
  };
  const fetchCompany = async () => {
    const result = await fetchAbsolute(
      "get",
      "/admin/commonMaster/getKpiStandardCompanyList"
    );
    if (result.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    }
    setCompanyInfo([...result.data]);
    setCompanyCd([...result.data][0].companyCd);
  };

  const onClickUpdateButton = async () => {
    let fetchFlag = true;

    if (getKeyByValue(inputValues, "") || !companyCd || !selectedYear) {
      alert(formatMessage({ id: "T0239" }));
      //공백은 입력할 수 없습니다.
      return;
    }

    try {
      const saveList = await fetchAbsolute(
        "post",
        `/admin/commonMaster/saveKpiStandard`,
        {
          data: {
            companyCd,
            byear: selectedYear,
            bfte: uncomma(inputValues.FTE),
            avgSalary: uncomma(inputValues.avgSalary),
            biicCt: uncomma(inputValues.investInfraCreator),
            biicUb: uncomma(inputValues.investInfraUB),
            biicAb: uncomma(inputValues.investInfraCostAB),
            biicHw: uncomma(inputValues.investInfraHW),
            bilcO: uncomma(inputValues.investCost),
            bmicAb: uncomma(inputValues.prodInfraAB),
            bmicHw: uncomma(inputValues.prodInfraHW),
            bmlcI: uncomma(inputValues.prodCostInner),
            bmlcO: uncomma(inputValues.prodCostExternal),
            bmicCt: uncomma(inputValues.prodInfraCreator),
            bmicUb: uncomma(inputValues.prodInfraUB),
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (saveList.status === 200 || saveList.status === 204) {
        alert(formatMessage({ id: "T0249" }));
        //"저장 되었습니다."
        // fetchYear();

        const fetchKPI = async () => {
          const result = await fetchAbsolute(
            "post",
            `/admin/commonMaster/getKpiStandardList`,
            {
              data: {
                companyCd: companyCd,
                byear: +selectedYear,
              },
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          setInputValues({
            FTE: result.data.bfte.toLocaleString(),
            avgSalary: result.data.avgSalary.toLocaleString(),
            investCost: result.data.bilcO.toLocaleString(),
            investInfraUB: result.data.biicUb.toLocaleString(),
            investInfraCreator: result.data.biicCt.toLocaleString(),
            investInfraCostAB: result.data.biicAb.toLocaleString(),
            investInfraHW: result.data.biicHw.toLocaleString(),
            prodCostInner: result.data.bmlcI.toLocaleString(),
            prodCostExternal: result.data.bmlcO.toLocaleString(),
            prodInfraCreator: result.data.bmicCt.toLocaleString(),
            prodInfraUB: result.data.bmicUb.toLocaleString(),
            prodInfraAB: result.data.bmicAb.toLocaleString(),
            prodInfraHW: result.data.bmicHw.toLocaleString(),
          });
        };
        fetchKPI();
      } else {
        throw new Error(saveList.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0235" }));
        //저장에 실패하였습니다.
      }
    }
  };

  const onCancleDesc = () => setDescVisible(false);

  const onClickDescButton = (e) => {
    setDescVisible(true);
  };

  const onSelectComCd = (e) => {
    setCompanyCd(e);
  };

  const columns = [
    {
      key: 1,
      title: "v",
      dataIndex: "v",
    },
    {
      key: 2,
      title: <FormattedMessage id="T1097" />, // contents
      dataIndex: "contents",
    },
    {
      key: 3,
      title: <FormattedMessage id="T0746" />, // 시간 산출
      dataIndex: "timeCal",
    },
    {
      key: 4,
      title: <FormattedMessage id="T0742" />, // CR 접속 URL
      dataIndex: "crConn",
    },
  ];

  const datas = [
    {
      v: "A",
      title: formatMessage({ id: "T0747" }), // 총 일수
      timeCal: "365",
      crConn: "",
    },
    {
      v: "B",
      title: formatMessage({ id: "T0748" }), // 연평균 토/일요일
      timeCal: "104",
      crConn: "",
    },
    {
      v: "C",
      title: formatMessage({ id: "T0749" }), // 국경일,공휴일
      timeCal: "17",
      crConn: formatMessage({ id: "T0750" }), // 2020년 기준 법정 공휴일 총 17일(토요일3일,일요일 2일 포함)따라서 실질적 공휴일 12일
    },
    {
      v: "D",
      contents: formatMessage({ id: "T0751" }), // 국경일,공휴일 중 토/일요일 중복일
      timeCal: "5",
      crConn: "",
    },
    {
      v: "E",
      contents: formatMessage({ id: "T0752" }), // 연간 총 비 근무일 수
      timeCal: "41",
      crConn: formatMessage({ id: "T0753" }), // 회사별 상이(당사기준12) RPA 특성상 선임급 Level
    },
    {
      v: "F=A-B-C+D+E",
      contents: formatMessage({ id: "T0754" }), // 연간 총 근무일 수
      timeCal: "208",
      crConn: "",
    },
    {
      v: "G",
      contents: formatMessage({ id: "T0755" }), // 연간 실제 근무 시간
      timeCal: "1664",
      crConn: formatMessage({ id: "T0756" }), // 1일 법정 근로시간 8시간
    },
    {
      v: "H",
      contents: formatMessage({ id: "T0757" }), // 연간 O/T 평균 시간
      timeCal: "209",
      crConn: formatMessage({ id: "T0758" }), // 2020년 기준 통계청 '고용형태별 근로 실태 조사' 참조
    },
    {
      v: "I = G",
      contents: formatMessage({ id: "T0759" }), // 연간 총 근무시간
      timeCal: "1664",
      crConn: formatMessage({ id: "T0760" }), // I = G+H에서 O/T 시간 제외함
    },
    {
      v: "J",
      contents: formatMessage({ id: "T0761" }), // 여유율
      timeCal: "0.66",
      crConn: formatMessage({ id: "T0762" }), // 생산율
    },
    {
      v: "K = I*J",
      contents: formatMessage({ id: "T0763" }), // 연간 표준근무가능시간
      timeCal: "1098",
      crConn: formatMessage({ id: "T1098" }), // = 1 FTE
    },
  ];

  useEffect(() => {
    if (yyyy.length > 0) {
      setSelectedYear(dataMax);
    }
  }, [yyyy]);

  useEffect(() => {
    if (dataCheck.findIndex((v) => v === selectedYear) === -1) {
      setInputValues({ ...initialInputValues });
    }

    if (!companyCd) return;

    if (selectedYear !== undefined) {
      const fetchKPI = async () => {
        const result = await fetchAbsolute(
          "post",
          `/admin/commonMaster/getKpiStandardList`,
          {
            data: {
              companyCd: companyCd,
              byear: +selectedYear,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (Object.keys(result.data).length === 0) {
          setInputValues({ ...initialInputValues });
        } else {
          setInputValues({
            FTE: result.data.bfte.toLocaleString(),
            avgSalary: result.data.avgSalary.toLocaleString(),
            investCost: result.data.bilcO.toLocaleString(),
            investInfraUB: result.data.biicUb.toLocaleString(),
            investInfraCreator: result.data.biicCt.toLocaleString(),
            investInfraCostAB: result.data.biicAb.toLocaleString(),
            investInfraHW: result.data.biicHw.toLocaleString(),
            prodCostInner: result.data.bmlcI.toLocaleString(),
            prodCostExternal: result.data.bmlcO.toLocaleString(),
            prodInfraCreator: result.data.bmicCt.toLocaleString(),
            prodInfraUB: result.data.bmicUb.toLocaleString(),
            prodInfraAB: result.data.bmicAb.toLocaleString(),
            prodInfraHW: result.data.bmicHw.toLocaleString(),
          });
        }
      };
      fetchKPI();
    }
  }, [selectedYear, companyCd]);

  useEffect(() => {
    fetchYear();
    fetchCompany();
  }, []);

  return (
    <Wrapper>
      <div className="realtime__content_box">
        <div className="top__section">
          {selectedYear?.length > 0 ? (
            <Title level={4}>
              {formatMessage({ id: "T0764" })}{/* 기준년도 */}
              <Select
                style={{ width: 140 }}
                defaultValue={dataMax || new Date().getFullYear() + 1}
                onSelect={onSelectYear}
                value={fmMessage({
                  id: "T1503", // {number}년
                  values: { number: selectedYear },
                })}
              >
                {yyyy.map((e, index) => (
                  <Option value={e} key={index}>
                    {fmMessage({ id: "T1503", values: { number: e } })}
                    {/* 년 */}
                  </Option>
                ))}
              </Select>
            </Title>
          ) : (
            <Title level={4}>
              {formatMessage({ id: "T0764" })}{/* 기준년도 */}
              <Select
                style={{ width: 140 }}
                defaultValue={dataMax || new Date().getFullYear()}
                onSelect={onSelectYear}
                value={fmMessage({
                  id: "T1503",
                  values: { number: selectedYear },
                })}
              >
                {yyyy.map((e, index) => (
                  <Option value={e} key={index}>
                    {fmMessage({ id: "T1503", values: { number: e } })}
                    {/* 년 */}
                  </Option>
                ))}
              </Select>
            </Title>
          )}
          <Title level={4} style={{ marginTop: 0 }}>
            <FormattedMessage id="T1500" /> {/* 회사명 */}
            <Select
              style={{ width: 140 }}
              onSelect={onSelectComCd}
              value={companyCd}
            >
              {companyInfo.map((c) => (
                <Option value={c.companyCd} key={c.companyCd}>
                  {c.companyNm}
                </Option>
              ))}
            </Select>
          </Title>
        </div>
        <Title className="title_name" level={4}>
          {formatMessage({ id: "T0765" })}{/* FTE 계산 기준 정보 */}
        </Title>
        <div className="update_content">
          <FormTable>
            <colgroup>
              <col width="22%" />
              <col width="28%" />
              <col width="22%" />
              <col width="28%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0766" })}</Text>{/* 연간 표준근무가능시간 (1 FTE 기준 ) */}
                </th>
                <td colSpan={3}>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name={formatMessage({ id: "T1099" })} // FTE
                      value={inputValues.FTE}
                      onChange={onChangeInputVal}
                    ></Input>
                    <Button onClick={onClickDescButton}>
                      {formatMessage({ id: "T0100" })}{/* 설명 */}
                    </Button>
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>
        <Title className="title_name" level={4}>
          {formatMessage({ id: "T1502" })} {/* 평균 연봉 */}
        </Title>
        <div className="update_content">
          <FormTable>
            <colgroup>
              <col width="22%" />
              <col width="28%" />
              <col width="22%" />
              <col width="28%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>
                    {formatMessage({ id: "T1502" })} {/* 평균 연봉 */}
                  </Text>
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="avgSalary"
                      value={inputValues.avgSalary}
                      onChange={onChangeInputVal}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>

        <Title className="title_name" level={4}>
          {formatMessage({ id: "T0767" })}{/* 투자비 정보 */}
        </Title>
        <div className="update_content">
          <FormTable>
            <colgroup>
              <col width="22%" />
              <col width="28%" />
              <col width="22%" />
              <col width="28%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0768" })}</Text>{/* 투자 인건비(외부) */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="investCost"
                      value={inputValues.investCost}
                      onChange={onChangeInputVal}
                    ></Input>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0769" })}</Text>{/* 투자 인프라 비용(Creator 라이선스) */}
                </th>
                <td>
                  <Input
                    style={{
                      width: 320,
                      marginRight: 20,
                    }}
                    name="investInfraCreator"
                    value={inputValues.investInfraCreator}
                    onChange={onChangeInputVal}
                  ></Input>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0770" })}</Text>{/* 투자 인프라 비용(U/B 라이선스) */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="investInfraUB"
                      value={inputValues.investInfraUB}
                      onChange={onChangeInputVal}
                    ></Input>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0771" })}</Text>{/* 투자 인프라 비용(A/B 라이선스) */}
                </th>
                <td>
                  <Input
                    style={{
                      width: 320,
                      marginRight: 20,
                    }}
                    name="investInfraCostAB"
                    value={inputValues.investInfraCostAB}
                    onChange={onChangeInputVal}
                  ></Input>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0772" })}</Text>{/* 투자 인프라 비용(하드웨어) */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="investInfraHW"
                      value={inputValues.investInfraHW}
                      onChange={onChangeInputVal}
                    ></Input>
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>
        <div className="update_content">
          <Title className="title_name" level={4}>
            {formatMessage({ id: "T0773" })}{/* 운영비 정보 */}
          </Title>
          <FormTable>
            <colgroup>
              <col width="22%" />
              <col width="28%" />
              <col width="22%" />
              <col width="28%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0774" })}</Text>{/* 운영 인건비(내부) */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="prodCostInner"
                      value={inputValues.prodCostInner}
                      onChange={onChangeInputVal}
                    ></Input>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0775" })}</Text>{/* 운영 인건비(외부) */}
                </th>
                <td>
                  <Input
                    style={{
                      width: 320,
                      marginRight: 20,
                    }}
                    name="prodCostExternal"
                    value={inputValues.prodCostExternal}
                    onChange={onChangeInputVal}
                  ></Input>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0776" })}</Text>{/* 운영 인프라 비용(Creator 라이선스) */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="prodInfraCreator"
                      value={inputValues.prodInfraCreator}
                      onChange={onChangeInputVal}
                    ></Input>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0777" })}</Text>{/* 운영 인프라 비용(U/B 라이선스) */}
                </th>
                <td>
                  <Input
                    style={{
                      width: 320,
                      marginRight: 20,
                    }}
                    name="prodInfraUB"
                    value={inputValues.prodInfraUB}
                    onChange={onChangeInputVal}
                  ></Input>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0778" })}</Text>{/* 운영 인프라 비용(A/B 라이선스) */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      name="prodInfraAB"
                      value={inputValues.prodInfraAB}
                      onChange={onChangeInputVal}
                    ></Input>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0779" })}</Text>{/* 운영 인프라 비용(하드웨어) */}
                </th>
                <td>
                  <Input
                    style={{
                      width: 320,
                      marginRight: 20,
                    }}
                    name="prodInfraHW"
                    value={inputValues.prodInfraHW}
                    onChange={onChangeInputVal}
                  ></Input>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingTop: 10,
          }}
        >
          <Button
            width="144"
            height="40"
            type="primary"
            onClick={onClickUpdateButton}
          >
            <FormattedMessage id="T0054" />
            {/* 수정 */}
          </Button>
          &nbsp;&nbsp;
          <Button width="144" height="40" onClick={onClickDeleteButton}>
            {formatMessage({ id: "T0072" })}
            {/* 삭제 */}
          </Button>
        </div>

        <Modal
          width={540}
          visible={PoolVisible}
          title={formatMessage({ id: "T0780" })} // KPI 기본 기준 정보 삭제
          okText={formatMessage({ id: "T0072" })} // 삭제
          onCancel={onCancelDelete}
          onOk={onOkDelete}
        >
          <Text
            style={{
              display: "block",
              fontSize: 14,
            }}
          >
            {formatMessage({ id: "T0781" })}{/* 정말로 해당 KPI 기본 기준 정보를 삭제하시겠습니까? */}
          </Text>
        </Modal>

        <Modal
          visible={descVisible}
          title={formatMessage({ id: "T0782" })} // 연간 표준 근무가능시간 계산법 예시
          onCancel={onCancleDesc}
          footer={false}
        >
          <div>
            <Text
              className="orange_text"
              style={{ fontSize: 14, fontWeight: "bold", color: "orange" }}
            >
              {formatMessage({ id: "T0783" })}{/* *연간표준근무가능시간 계산법 예시 */}
            </Text>
            <Table
              className="comment"
              columns={columns}
              dataSource={datas}
              rowKey={(item) => item.v}
            />
            <Text className="comment">
              <br />
              {formatMessage({ id: "T0784" })}{/* [주석]  */}
              <br />
              {formatMessage({ id: "T0785" })}{/* 1) 연 평균 토/일요일 수 = 52(일요일)+52(토요일)=104일 */}
              <br />
              {formatMessage({ id: "T0786" })}{/* 2) 국경일 공휴일 = 0000년 기준 법정 공휴일 */}
              <br />
              {formatMessage({ id: "T0787" })}{/* 3) 연간 총 비 근무일 수 = 1인당 평균 연차 */}
              {formatMessage({ id: "T0788" })}{/* 사용일수,휴가(춘계,하계,추계휴가)=9일~12일 */}
              <br />
              {formatMessage({ id: "T0789" })}{/* 4) 연간 O/T 평균 시간 = 0000년 연간 총 초과근무시간 / 인원수 */}
              <br />
              {formatMessage({ id: "T0790" })}{/* 5) 연간 총 근무시간 = 연간 실제 근무일 수 + 법정근로시간 (8시간)+ */}
              {formatMessage({ id: "T0791" })}{/* 연간 O/T 평균시간 */}
              <br />
              {formatMessage({ id: "T0792" })}{/* 6) 업무 수행상 발생하는 여유율은 인적행위(피로회복이나 생리적 */}
              {formatMessage({ id: "T0793" })}{/* 욕구에 의거하는 것) 10%, 관리 행위(사무용구의 */}
              {formatMessage({ id: "T0794" })}{/* 준비,교육강의,출장,결제 대기 및 업무대기 시간) 10%를 반영한 것으로 */}
              {formatMessage({ id: "T0795" })}{/* 국제 노사정 기구인 ILO에서도 최소한 여유율에 따름 */}
              <br />
              {formatMessage({ id: "T0796" })}{/* 7) 연간 표준근무가능시간 (인당 실제 근무시간) = 연간 총 근무시간 *여유율(1-0.20) */}
            </Text>
          </div>
        </Modal>
      </div>
    </Wrapper>
  );
};

export default KPIBasicInfo;
