import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { Switch } from "antd";
import { PageTemplate, Header, Footer } from "components";
import Wrapper from "./Styled";
import moment from "moment";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import XLSX from "xlsx-js-style";
import AdminLicenseTop from "./AdminLicenseTop";
import AdminLicenseBottom from "./AdminLicenseBottom";

/**
 * 관리자 > 라이선스 관리
 *
 */

const initialInputValues = {
  selectCr: {},
  selectUserType: [],
  selectUserStatus: [],
  inputUserName: "",
  inputFirstName: "",
  inputSearchEmail: "",
};

const AdminLicense = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));

  const currentPage = useRef(1);

  const userTypeList = ["Admin", "Bot creator", "Bot runner", "Other"];

  const userStatusList = ["Enabled", "Disabled"];

  const [listLoading, setListLoading] = useState(false);
  const [licenseList, setLicenseList] = useState([]);
  const [licenseCount, setLicenseCount] = useState({
    availableCount: 0,
    totalCount: 0,
    usedCount: 0,
  });

  const [inputValues, setInputValues] = useState({ ...initialInputValues });

  const fetchLicenseList = async (values) => {
    setListLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/admin/license/licenseListMulVal`,
      {
        data: {
          crCd: values.selectCr.crCd,
          userType: values.selectUserType,
          userStatus: values.selectUserStatus,
          userName: values.inputUserName,
          firstName: values.inputFirstName,
          email: values.inputSearchEmail,
        },
      }
    );
    setListLoading(false);
    if (result.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    }
    setLicenseList([...result.data]);
  };

  const fetchLicenseCount = async (xEnv) => {
    const result = await fetchAbsolute(
      "get",
      `/admin/license/botCreatorCount?x-environment=${xEnv}`
    );
    setLicenseCount({ ...result.data });
  };

  const onChangeSelectCr = (e) => {
    const tmpObj = crInfo.find((v) => v.crCd === e);
    onChangeSearchInput("selectCr", tmpObj);
  };

  const onChangeUserType = (e) => {
    onChangeSearchInput("selectUserType", e);
  };

  const onChangeStatus = (e) => {
    onChangeSearchInput("selectUserStatus", e);
  };

  const onChangeValues = (e) => {
    const { name, value } = e.target;
    onChangeSearchInput(name, value);
  };

  //  select 부분에선 e.target.value 등이 안먹혀서 둘 만 따로 'e'로 관리
  const onChangeSearchInput = (label, value) => {
    setInputValues({
      ...inputValues,
      [label]: value,
    });
  };

  /* 초기화 버튼 */
  const onClickReset = () => {
    const initialCrInfo = { ...crInfo[0] };
    const fetchObj = {
      ...initialInputValues,
      selectCr: initialCrInfo,
      selectUserType: userTypeList,
      selectUserStatus: userStatusList,
    };
    setInputValues({ ...initialInputValues, selectCr: initialCrInfo });
    fetchLicenseList({ ...fetchObj });
    fetchLicenseCount(initialCrInfo.crXEnv);
  };

  const onChangeStatusSwitch = async (value, record, index) => {
    const confirmResult = window.confirm(
      fmMessage({
        id: "T1226", // 해당 사용자를 {value} 하시겠습니까?
        values: {
          value: value
            ? `${formatMessage({ id: "T1229" })}` // 활성화
            : `${formatMessage({ id: "T1230" })}`, // 비활성화 
        },
      })
    );
    if (!confirmResult) {
      return;
    }
    let url = "";
    const data = {
      crUris: [inputValues.selectCr.crUri],
      userIds: [record.username],
    };

    if (value) {
      url = "/users/enableUsers";
    } else {
      url = "/users/disableUsers";
    }
    setListLoading(true);
    const result = await fetchAbsolute(
      "post",
      `${url}/${inputValues.selectCr.crXEnv}`,
      {
        data,
      }
    );
    setListLoading(false);
    if (result.error) {
      return alert(
        result.data.trace ||
        fmMessage({
          id: "T1227",
          values: {
            value: value
              ? `${formatMessage({ id: "T1229" })}`
              : `${formatMessage({ id: "T1230" })}`, // T1229 활성화  T1230 비활성화
          },
        })
      );
      // `사용자를 ${value ? '활성화' : '비활성화'}하는 도중 에러가 발생했습니다.`);
    }
    const tmpArr = [...licenseList];
    const arrIndex = index + (currentPage.current - 1) * 20;
    tmpArr[arrIndex].disabled = !value;
    setLicenseList([...tmpArr]);
  };

  const onChangeTable = (page) => {
    const { current } = page;
    currentPage.current = current;
  };

  const onClickDownloadExcel = async () => {
    /* flatten objects */
    const rows = licenseList.map((license) => {
      return {
        "User Type": license.userType,
        "User Name": license.username,
        "First Name": license.firstName,
        "Last Name": license.lastName,
        Description: license.description,
        Roles: license.roles.map((v) => v.name).join(", "),
        "Device License": license.licenseFeatures.join(", "),
        "Last Login": moment(license.lastLoginTime).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
        "User Status": license.disabled ? "Disabled" : "Enabled",
        "License Status": JSON.stringify(license.emailVerified).includes("true")
          ? "Verified"
          : "N/A",
        Email: license.email,
        "Auto Login": license.enableAutoLogin ? "O" : "X",
        "Last Modify": moment(license.updatedOn).format("YYYY-MM-DD HH:mm:ss"),
      };
    });

    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* css */
    for (let i in worksheet) {
      if (typeof worksheet[i] !== "object") continue;
      /* header만 굵은 글씨 & 가운데 정렬 */
      worksheet[i].s = {
        font: {
          name: "Malgun Gothic",
          sz: 11,
        },
      };
      if (i.includes("1") && i.length === 2) {
        worksheet[i].s = {
          font: {
            name: "Malgun Gothic",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }

    if (!worksheet["!cols"]) worksheet["!cols"] = [];
    let cellsWidth = Array.from({ length: 13 }, () => ({ width: 20 })); // length는 header 컬럼 만큼
    worksheet["!cols"].push(...cellsWidth);

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, "License List.xlsx", { compression: true });
  };

  const columns = [
    {
      title: <FormattedMessage id="T1180" />, //User Type
      dataIndex: "userType",
      key: "userType",
      width: 120,
    },
    {
      title: <FormattedMessage id="T1181" />, //User Name
      dataIndex: "username",
      key: "username",
      width: 150,
    },
    {
      title: <FormattedMessage id="T1182" />, //First Name
      dataIndex: "firstName",
      key: "firstName",
      width: 120,
    },
    {
      title: <FormattedMessage id="T1183" />, //Last Name
      dataIndex: "lastName",
      key: "lastName",
      width: 120,
    },
    {
      title: <FormattedMessage id="T1117" />, //Description
      dataIndex: "description",
      key: "description",
      width: 180,
    },
    {
      title: <FormattedMessage id="T1191" />, //Roles
      dataIndex: "roles",
      key: "roles",
      width: 200,
      render: (list) => {
        return list.map((v) => v.name).join(", ");
      },
    },
    {
      title: <FormattedMessage id="T1185" />, //Device License
      dataIndex: "licenseFeatures",
      key: "licenseFeatures",
      width: 120,
      render: (list) => {
        return list.join(", ");
      },
    },
    {
      title: <FormattedMessage id="T1186" />, //Last Login
      dataIndex: "lastLoginTime",
      key: "lastLoginTime",
      width: 160,
      render: (value) => {
        return moment(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: <FormattedMessage id="T1112" />, // User Status
      dataIndex: "disabled",
      key: "disabled",
      width: 100,
      render: (disabled, record, index) => {
        return (
          <Switch
            defaultChecked={!disabled}
            checked={!disabled}
            onChange={(value) => onChangeStatusSwitch(value, record, index)}
          />
        );
      },
    },
    {
      title: <FormattedMessage id="T1187" />, //User Status
      dataIndex: "emailVerified",
      key: "emailVerified",
      render: (value) => {
        let flag;
        if (!value) {
          flag = false;
        } else {
          if (typeof value === "boolean") {
            flag = value;
          } else {
            flag = JSON.parse(value);
          }
        }
        return flag ? "Verified" : "N/A";
      },
    },
    {
      title: <FormattedMessage id="T1188" />, //Email
      dataIndex: "email",
      key: "email",
      width: 220,
    },
    {
      title: <FormattedMessage id="T1189" />, //Auto Login
      dataIndex: "enableAutoLogin",
      key: "enableAutoLogin",
      width: 110,
      render: (value) => {
        return value ? "O" : "X";
      },
    },
    {
      title: <FormattedMessage id="T1190" />, //Last Modified
      dataIndex: "updatedOn",
      key: "updatedOn",
      width: 160,
      render: (value) => {
        return moment(value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  /* 초기 정보 세팅 */
  useEffect(() => {
    if (crInfo.length === 0) return;
    onClickReset();
  }, [crInfo]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      <Wrapper style={{ padding: "30px 0" }}>
        <AdminLicenseTop
          onChangeValues={onChangeValues}
          inputValues={inputValues}
          fetchLicenseList={fetchLicenseList}
          fetchLicenseCount={fetchLicenseCount}
          crInfo={crInfo}
          userTypeList={userTypeList}
          userStatusList={userStatusList}
          onClickReset={onClickReset}
          onChangeSelectCr={onChangeSelectCr}
          onChangeUserType={onChangeUserType}
          onChangeStatus={onChangeStatus}
        />
        <AdminLicenseBottom
          licenseCount={licenseCount}
          onClickDownloadExcel={onClickDownloadExcel}
          columns={columns}
          licenseList={licenseList}
          listLoading={listLoading}
          onChangeTable={onChangeTable}
        />
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminLicense;
