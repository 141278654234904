import { useIntl } from "react-intl";
import { Bar } from '@ant-design/charts';
import { Divider, Typography, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Loading } from "components";
import fetchAbsolute from 'utils/fetchAbsolute';

const { Option } = Select;
const { Title } = Typography;

/**
 * 대시보드 > 과제 현황 > 과제 단계별 TAT
 * 
 */
const TaskStageTAT = ({ standardDate, companyList, taskType, defaultTaskType }) => {
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [statusCdList, setStatusCdList] = useState([]);
    const [taskStepData, setTaskStepData] = useState([]);
    const [conditions, setConditions] = useState({
        org: [],
        statusCd: '',
        category: 'depart', // 부서 조직별 기본
    });

    const config = {
        yField: 'label',
        xField: 'value',
        legend: false,
        yAxis: {
            label: {
                autoRotate: false,
            },
        },
        // scrollbar: {
        //     type: 'vertical',
        // },
        offsetY: 6,
        color: '#ff7b00',
        label: {
            position: 'right',
            offset: 4,
            labelLine: true,
            offsetX: 10, // test
            content: (item) => {
                return item.value.toFixed(1)
                    .concat('\n')
                    .concat('days')
            },
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'adjust-color' },
            ]
        },
    };

    /* 과제단계 */
    const onChangeStatus = (e) => {
        setConditions({
            ...conditions,
            statusCd: e
        })
    };

    /* 조직별 */
    const onChangeOrg = (e) => {
        /* 배열 */
        setConditions({
            ...conditions,
            org: [...e],
        })
    };

    /* 회사별 */
    const fetchOrgOperation = async () => {
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/taskState/TatByTaskLevelList`, {
            data: {
                dataCollectionDate: standardDate,
                companyCd: conditions.org || [],
                processType: tmpTaskType || [],
                lifeCycleStatus: conditions.statusCd,
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    label: data[i].companyNm,
                    key: data[i].processId,
                    value: +data[i].orgTotalTat || 0,
                    category: conditions.category
                }
            )
        }
        setTaskStepData([...tmpArr]);
    };

    /* 부서 조직별 */
    const fetchDepartOperation = async () => {
        if (conditions.category === 'company') return;
        // if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        // if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/taskState/TatByTaskLevelList`, {
            data: {
                dataCollectionDate: standardDate,
                companyCd: conditions.org || [],
                processType: tmpTaskType || [],
                lifeCycleStatus: conditions.statusCd,
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const taskList = result.data.map(list => list.taskList).flat();

        const tmpArr = [];

        // 조직이 선택 됐는지, 아닌지
        // 선택됐을 땐 조직명이 보여야 해서
        const isCheck = Object.keys(conditions.org).length === 0;

        for (let i in taskList) {
            tmpArr.push(
                {
                    label: isCheck ? taskList[i]?.companyNm + "\n" + taskList[i].processId : taskList[i]?.companyNm + "\n" + taskList[i]?.orgNam + "\n" + taskList[i].processId,
                    key: taskList[i].processId,
                    value: +taskList[i].processTat || 0,
                    category: conditions.category
                },
            )
        };
        setTaskStepData([...tmpArr]);
    };

    /* 회사별 조직별 선택 라디오 기능 */
    const onChangeCategory = (e) => {
        const { target: { value } } = e;
        setConditions({
            ...conditions,
            category: value,
        });
    };

    useEffect(() => {
        fetchDepartOperation();
    }, [conditions, standardDate, taskType]);

    useEffect(() => {
        // task Status Code 가져오는 함수
        const fetchStatusCd = async () => {
            const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/TASK_STATUS');
            setStatusCdList([...result.data?.filter(v => v.upCd === '0' && v.useYn === 'Y' && v.cd !== 'T50')]);
        }
        fetchStatusCd();
    }, [])

    if (isLoading) {
        return <Loading visible={isLoading} />
    }
    return (
        <div className='box__container'>
            <div className='bot__title'>
                <Title
                    className='main__title'
                    level={4}>{formatMessage({ id: "T1514" })}</Title>{/* 과제 단게별 TAT */}
                <div className='condition org_operation__container'>
                    <div>
                        <div>{formatMessage({ id: "T1516" })}</div>{/* 조직별 */}
                        <Select
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{
                                width: 220
                            }}
                            onChange={onChangeOrg}
                            value={conditions.org}
                            placeholder={formatMessage({ id: "T0391" })}  // 전체
                        >
                            {
                                companyList.map(list => {
                                    return (
                                        <Option key={list.companyCd} value={list.companyCd}>{list.companyNm}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div>
                        <div>{formatMessage({ id: "T1543" })}</div>{/* 과제단계 */}
                        <div>
                            <Select
                                style={{
                                    width: 130
                                }}
                                placeholder={formatMessage({ id: "T0391" })} // 전체
                                name="statusCd"
                                value={conditions.statusCd}
                                onChange={onChangeStatus}
                                filterOption={statusCdList
                                    .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                                    .map((item) => item.cd)}
                            >
                                {/* 전체 */}
                                <Option key='' value=''>{formatMessage({ id: "T0391" })}</Option>
                                {
                                    statusCdList.map(status => {
                                        return (
                                            <Option key={status.cd} value={status.cd}>{status.cdNm}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div style={{
                display: 'flex',
                justifyContent: 'right',
                paddingTop: 10
            }}>
                <Radio
                    className='selectRadioCategory'
                    value={conditions.category}
                    onChange={onChangeCategory}
                    renderItem={[
                        {
                            value: "company",
                            text: formatMessage({ id: "T1542" }), // 회사별
                        },
                        {
                            value: "depart",
                            text: formatMessage({ id: "T1516" }), // 조직별 
                        }
                    ]}
                />
            </div> */}
            <Divider />
            {
                taskStepData.length > 0 ?
                    <Bar
                        className="dashboard_graph"{...config} 
                        data={taskStepData} 
                        style={{ height: `calc(30px * ${taskStepData.length})`, 
                        minHeight: 270, 
                        overflow: 'visible' }} 
                        /> : <Loading visible={isLoading} 
                        height='30' 
                        minHeight='10' />
            }
        </div >
    );
};
export default TaskStageTAT;