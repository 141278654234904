import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    /* Index & 공통 */ 
    .box__container {
        width: 100%;
        padding: 20px 20px;
        border:1px solid #d0d1d6;
        border-radius: 10px;
        background: #fff;
        height: 415px;
        max-height: 455px;
        overflow: auto;
    } 
    .box__container.whole {
        min-height: 540px !important;
    }
    .main__title {
        margin-bottom: 0.5em;
        font-size: 18;
        font-weight: bold;
        line-height: 1.38;
    }
    .bot__title { 
        display: flex;
        justify-content: space-between;
    }
    .condition {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .condition .company_name {
        font-weight: bold;
    }
    .roi_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .standard_conditions {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: right;
    }
    .first__section, .second__section, .third__section {
        display: flex;
        gap: 10px;
        height: 100%;
    } 
    .prediction_roi {
        width: 100%; 
    } 
    .org_roi {
        width: 50%; 
    }
    .task_roi {
        width: 50%; 
    }
    /* 예측 ROI */
    .box__container.roi { 
        height: 100% !important;
        max-height: 455px !important;
        overflow: auto !important;
    }
    .box_area {
        display: flex;   
        width: 1330px;
        gap: 10px;
        justify-content: space-between;
    }
    .right__section {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 5px;
    }
    .chage_rate span {
        font-size: 16px;
        font-weight: bold;
        color: red;
    }
    .right__section .last_month {
        font-size: 28px;
        font-weight: bold;
        color: #666666;
        padding-bottom: 5px;
    }
    /* 전사 ROI */
    .whole_company_roi {
        display: flex;
        gap: 10px;
        width: 100%;
        justify-content: space-between;
    }
    .line_section {
        width: 100%;
    }
    .line_title {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    padding: 10px 0px;
    }
`;

export default Wrapper;