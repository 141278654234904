import React, { useState, useRef, useEffect } from "react";
import { Button, EmailTriggerModal } from 'components';
import { useSelector, useDispatch } from "react-redux";
import { setVisible } from "store/loading/action";
import { InstantStartWorkflow } from "store/hfWorkflowLookup/action";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import moment from "moment";
import ScheduleEnter from "./ScheduleEnter";
import ScheduleStatus from "./ScheduleStatus";
import ScheduleProdStatus from "./ScheduleProdStatus";
import { useHistory } from 'react-router-dom';

/**
 * workflow 조회 > 스케줄 등록 및 관리
 * Filename: WorkflowSchedule.js
 * Description: workflow 상세조회 하단 
 */

const WorkflowProdStatus = () => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();
    const history = useHistory();
    const workflowData = useSelector(state => state.get(getUniqueKey('hfWorkflowLookup')).workflowData);
    const listSearch = useSelector(state => state.get(getUniqueKey('listUrl')).hfWorkflowList);

    const dispatch = useDispatch();

    const [editable, setEditable] = useState(false);
    const [disabledBtn, setDisabledBtn] = useState(false);
    const [scheduleList, setScheduleList] = useState([]);
    const [scheduleInfo, setScheduleInfo] = useState({});
    const [executeButton, setExecuteButton] = useState(false);
    const [operData, setOperData] = useState([]);
    /* 스케줄 등록 > 스케줄 유형 > 반복 실행 > 일, 주, 월 */
    const [operValues, setOperValues] = useState({
        radioScheduleType: 'immediately',
        scheduleCycle: "DAILY",
    });
    const [rDaily, setRDaily] = useState({ dailyInterval: 1 });
    const [rWeekly, setRWeekly] = useState({ weeklyInterval: 1, weeklyDay: "" });
    const [rMonthly, setRMonthly] = useState({
        month: "",
        scheduleType: "DAILY",
        date: 1,
        week: "FIRST",
        day: "MON",
    });
    /* 스케줄 등록 > 스케줄 유형 공통 */
    const [commonSchedules, setCommonSchedules] = useState({
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        repeatCheck: false,
        repeatTime: 1,
        repeatCycle: "MINUTES",
    });

    const [emailTriggerStatus, setEmailTriggerStatus] = useState(false);
    const [emailTriggerList, setEmailTriggerList] = useState([]);
    const [emailTriggerVal, setEmailTriggerVal] = useState("");
    const [emailTriggerVisible, setEmailTriggerVisible] = useState(false);
    const [chatbotStatus, setChatbotStatus] = useState("deactivate");
    const [tableLoading, setTableLoading] = useState(false);

    const radioRepeatField = {
        endDate: {
            ref: useRef(null),
            message: formatMessage({ id: "T0540" }), // 종료 날짜
        }
    }
    const checkboxRepeatField = {
        endTime: {
            ref: useRef(null),
            message: formatMessage({ id: "T0541" }), // 종료 시간 
        }
    }
    const requireFields = {

        startDate: {
            ref: useRef(null),
            message: formatMessage({ id: "T0542" }), // 시작 날짜 
        },
        startTime: {
            ref: useRef(null),
            message: formatMessage({ id: "T0543" }), // 시작 시간  
        }
    };

    const onClickCancel = () => {
        // history.goBack();
        history.push(`/bizflow/hfworkflow/list${listSearch.trim().length ? "?" + listSearch : ""}`)
    };
    const onChangeCycleDaily = (e) => setRDaily({ dailyInterval: e });

    const onSelectMonthlyDatas = (label, value) => {
        setRMonthly({
            ...rMonthly,
            [label]: value,
        });
    };

    const onChangeReapeatWeekly = (label, value) => {
        setRWeekly({
            ...rWeekly,
            [label]: value,
        });
    };

    const onChangeCommonDatas = (label, value) => {
        setCommonSchedules({
            ...commonSchedules,
            [label]: value,
        });
    };

    const onChangeOperValues = (label, value) => {
        setOperValues({
            ...operValues,
            [label]: value,
        });
    };

    /* 초기화 */
    const onClickResetSchedule = () => {
        setOperValues({
            scheduleCycle: "DAILY",
            radioScheduleType: "immediately",
        });
        setRDaily({ dailyInterval: 1 });
        setRWeekly({ weeklyInterval: 1, weeklyDay: "" });
        setRMonthly({
            month: "",
            scheduleType: "DAILY",
            date: 1,
            week: "FIRST",
            day: "MON",
        });
        /* 스케줄 등록 > 스케줄 유형 공통 */
        setCommonSchedules({
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            repeatCheck: false,
            repeatTime: 1,
            repeatCycle: "HOURS",
        });
        setDisabledBtn(false);
    };

    // Request Parameter 생성 함수
    const setRequestParameter = (data) => {
        let basicParam;
        let addParam;
        let repeatParam;
        let automationName = { automationName: data.name };

        if (data.radioScheduleType === "repeat") {
            basicParam = {
                workflowId: workflowData.workflowId,
                scheduleType: data.scheduleCycle,
                startDate: data.startDate?.format("YYYY-MM-DD"),
                endDate: data.endDate?.format("YYYY-MM-DD"),
                startTime: data.startTime?.format("HH:mm"),
                repeatEnabled: data.repeatCheck,
                emailScheduleSeq: data.emailScheduleSeq,

            };
        } else if (data.radioScheduleType === "TRIGGER_CHATBOT" || data.radioScheduleType === "TRIGGER_EMAIL") {
            basicParam = {
                workflowId: workflowData.workflowId,
                scheduleType: data.radioScheduleType,
                emailScheduleSeq: data.emailScheduleSeq,
            };
            if (data.radioScheduleType === "TRIGGER_CHATBOT")
                basicParam = {
                    ...basicParam,
                    activate: chatbotStatus,
                };
            else {
                basicParam = {
                    ...basicParam,
                    emailInfoSeq: emailTriggerVal,
                };
            }
        }
        else {
            basicParam = {
                workflowId: workflowData.workflowId,
                scheduleType: "NONE",
                startDate: moment(data.startDate)?.format("YYYY-MM-DD"),
                endDate: moment(data.startDate)?.format("YYYY-MM-DD"),
                startTime: moment(data.startTime, "HH:mm").format("HH:mm"),
                repeatEnabled: data.repeatCheck,
                emailScheduleSeq: data.emailScheduleSeq,
            };
        }

        if (data.repeatCheck) {
            repeatParam = {
                repeatOccurrence: {
                    runEvery: data.repeatTime,
                    timeUnit: data.repeatCycle,
                    endTime: moment(data.endTime).format("HH:mm"),
                },
            };
        }

        if (data.radioScheduleType === "repeat") {
            if (data.scheduleCycle === "DAILY") {
                addParam = {
                    dailyRecurrence: {
                        interval: data.dailyInterval,
                    },
                };
            } else if (data.scheduleCycle === "WEEKLY") {
                addParam = {
                    weeklyRecurrence: {
                        interval: data.weeklyInterval,
                        daysOfWeek: data.weeklyDay, //[MON,TUE]
                    },
                };
            } else if (data.scheduleCycle === "MONTHLY") {
                if (data.scheduleType === "DAILY") {
                    addParam = {
                        monthlyDateRecurrence: {
                            dateOfMonth: data.date,
                            monthsOfYear: data.month,
                        },
                    };
                } else {
                    addParam = {
                        monthlyWeekDayRecurrence: {
                            weekOfMonth: data.week,
                            dayOfWeek: data.day,
                            monthsOfYear: data.month,
                        },
                    };
                }
            }
        }

        let parameter = Object.assign(
            {},
            basicParam,
            repeatParam,
            addParam,
            automationName
        );

        return parameter;
    };

    /* 스케줄 등록 > 등록 기능 */
    const onClickRegSchedule = async () => {
        setDisabledBtn(true);
        const scheduleDatas = {
            ...operValues,
            ...rDaily,
            ...rWeekly,
            ...rMonthly,
            ...commonSchedules,
        };

        // 기본
        for (const key in requireFields) {
            const field = requireFields[key];
            if ((!scheduleDatas[key] || Object.keys(scheduleDatas[key]).length <= 0) && operValues.radioScheduleType === "oneTime") {
                try {
                    setDisabledBtn(false);
                    field.ref.current?.focus();
                    return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                    //`${field.message} 항목은 필수 입력입니다.`);
                } catch (e) {
                    setDisabledBtn(false);
                    return alert(formatMessage({ id: 'T0181' }));  // 스케줄 등록에 실패하였습니다.

                }
            } else if ((!scheduleDatas[key] || Object.keys(scheduleDatas[key]).length <= 0) && operValues.radioScheduleType === "repeat") {
                try {
                    setDisabledBtn(false);
                    field.ref.current?.focus();
                    return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                    //`${field.message} 항목은 필수 입력입니다.`);
                } catch (e) {
                    setDisabledBtn(false);
                    return alert(formatMessage({ id: 'T0181' }));  // 스케줄 등록에 실패하였습니다.

                }
            }
        }

        // 반복 실행
        if (operValues.radioScheduleType === "repeat") {
            for (const key in radioRepeatField) {
                const field = radioRepeatField[key];
                if (Object.keys(scheduleDatas[key]).length <= 0) {
                    try {
                        setDisabledBtn(false);
                        field.ref.current.focus();
                        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                        //`${field.message} 항목은 필수 입력입니다.`);
                    } catch (e) {
                        setDisabledBtn(false);
                        return formatMessage({ id: 'T0181' }); // 스케줄 등록에 실패하였습니다.
                    }
                }
            }
        }

        // 반복
        if (commonSchedules.repeatCheck) {
            for (const key in checkboxRepeatField) {
                const field = checkboxRepeatField[key];
                if (Object.keys(scheduleDatas[key]).length <= 0) {
                    try {
                        setDisabledBtn(false);
                        field.ref.current.focus();
                        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                        //`${field.message} 항목은 필수 입력입니다.`);
                    } catch (e) {
                        setDisabledBtn(false);
                        return formatMessage({ id: 'T0181' });// 스케줄 등록에 실패하였습니다.
                    }
                }
            }
        }

        let parameter;

        parameter = setRequestParameter(scheduleDatas);

        if (operValues.radioScheduleType === "immediately") {
            dispatch(InstantStartWorkflow({
                successMsg: formatMessage({ id: "T0147" }), // 즉시 실행이 시작되었습니다.
                failedMsg: formatMessage({ id: "T0148" }) // 즉시 실행 오류가 발생하였습니다.
            }))
            onClickResetSchedule();
        } else {
            dispatch(setVisible(true));
            const result = await fetchAbsolute("post", `/workflowSchedule/addSchedule`, {
                data: { ...parameter },
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (result.error) {
                setDisabledBtn(false);
                const val = formatMessage({ id: "T0179" }).split("\n").map(data => {
                    return data
                });
                // 스케줄 조건을 다시 한 번 확인해주세요. 예) 반복 실행에 속한 단위가 시작날짜와 종료날짜 사이에 있어야 합니다.
                alert(val.join('\n'));
                return dispatch(setVisible(false));
            }
            setDisabledBtn(false);
            alert(formatMessage({ id: 'T0180' })); // 스케줄 등록이 완료되었습니다.
            onClickResetSchedule();
            fetchScheduleList();

        }
        dispatch(setVisible(false));
    };
    /* 스케줄 삭제 버튼 기능 */
    const schduleDeleteBtn = async (data) => {
        const result = window.confirm(formatMessage({ id: 'T0321' }));
        //해당 과제의 스케줄을 삭제하시겠습니까?
        if (result) {
            dispatch(setVisible(true));
            const deleteSchedule = await fetchAbsolute(
                "delete",
                `/workflowSchedule/removeSchedule?workflowId=${workflowData?.workflowId}&automationNm=${data.name}`
            );
            if (deleteSchedule.error) {
                alert(formatMessage({ id: 'T0184' })); // 스케줄 삭제에 실패하였습니다.
                return dispatch(setVisible(false));

            }
            alert(formatMessage({ id: 'T0183' })); // 스케줄이 삭제되었습니다.
            fetchScheduleList();
        }
        setExecuteButton(true);
        dispatch(setVisible(false));
        setEditable(false);
        onClickResetSchedule();
    };

    /* 스케줄 현황 > 수정 버튼 : 등록에 버튼 변경, AA 정보 셋팅 */
    const onClickEditStatusBtn = async (data) => {
        setExecuteButton(false);
        setEditable(true);
        try {
            const dataInfo = await fetchAbsolute(
                "get",
                `/workflowSchedule/schedule?workflowId=${workflowData?.workflowId}&automationName=${data.name}`
            );

            setScheduleInfo({
                ...dataInfo.data.list[0]
            })

            if (dataInfo.data.list[0]?.scheduleType === "TRIGGER_EMAIL") {

                setOperValues({
                    radioScheduleType: dataInfo.data.list[0]?.scheduleType,
                    scheduleCycle: dataInfo.data.list[0]?.scheduleType === "TRIGGER_EMAIL" ? "DAILY" : dataInfo.data.list[0]?.scheduleType,
                    name: dataInfo.data.list[0]?.name,
                    emailScheduleSeq: dataInfo.data.list[0]?.emailScheduleSeq
                });
                onChangeEmailTrigger(dataInfo.data.list[0]?.emailInfoSeq)
            }
            else if (dataInfo.data.list[0]?.scheduleType === "TRIGGER_CHATBOT") {
                setOperValues({
                    radioScheduleType: dataInfo.data.list[0]?.scheduleType,
                    scheduleCycle: dataInfo.data.list[0]?.scheduleType === "TRIGGER_CHATBOT" ? "DAILY" : dataInfo.data.list[0]?.scheduleType,
                    name: dataInfo.data.list[0]?.name,
                    emailScheduleSeq: dataInfo.data.list[0]?.emailScheduleSeq
                });
                setChatbotStatus(dataInfo.data.list[0]?.activate);
            } else {

                setOperValues({
                    radioScheduleType: dataInfo.data.list[0]?.scheduleType === "NONE" ? "oneTime" : "repeat",
                    scheduleCycle: dataInfo.data.list[0]?.scheduleType === "NONE" ? "DAILY" : dataInfo.data.list[0]?.scheduleType,
                });

                setRDaily({ dailyInterval: dataInfo.data.list[0].dailyRecurrence?.interval ? dataInfo.data.list[0].dailyRecurrence?.interval : 1 });

                setRWeekly({
                    weeklyInterval: dataInfo.data.list[0].weeklyRecurrence?.interval ? dataInfo.data.list[0].weeklyRecurrence?.interval : 1,
                    weeklyDay: dataInfo.data.list[0].weeklyRecurrence?.daysOfWeek,
                });

                setRMonthly({
                    month: dataInfo.data.list[0].monthlyDateRecurrence ? dataInfo.data.list[0]?.monthlyDateRecurrence?.monthsOfYear : dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.monthsOfYear,
                    scheduleType: dataInfo.data.list[0]?.scheduleType,
                    date: dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dateOfMonth ? dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dateOfMonth : 1,
                    week: dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.weekOfMonth ? dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.weekOfMonth : 'FIRST',
                    day: dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dayOfWeek ? dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dayOfWeek : 'MON',
                });

                setCommonSchedules({
                    startDate: moment(dataInfo.data.list[0]?.startDate),
                    startTime: moment(dataInfo.data.list[0]?.startTime, "HH:mm"),
                    endDate: moment(dataInfo.data.list[0]?.endDate),
                    endTime: dataInfo.data.list[0]?.repeatOccurrence?.endTime ? moment(dataInfo.data.list[0]?.repeatOccurrence?.endTime, "HH:mm") : moment('00:00', 'HH:mm'),
                    repeatCheck: JSON.parse(dataInfo.data.list[0]?.repeatEnabled),
                    repeatTime: dataInfo.data.list[0]?.repeatOccurrence?.runEvery ? dataInfo.data.list[0]?.repeatOccurrence?.runEvery : 1,
                    repeatCycle: dataInfo.data.list[0]?.repeatOccurrence?.timeUnit ? dataInfo.data.list[0]?.repeatOccurrence?.timeUnit : "MINUTES",
                });
            }
        } catch (err) {
            return alert(formatMessage({ id: 'T0185' }));// 스케줄 정보 가져오는 도중 에러가 발생했습니다.
        }
    };

    /* 스케줄 수정 > 수정(실제 수정되는 버튼) */
    const onClickModifySchedule = async () => {
        setDisabledBtn(true);
        const scheduleDatas = {
            ...scheduleInfo,
            ...rDaily,
            ...rWeekly,
            ...rMonthly,
            ...commonSchedules,
            ...operValues,
        };

        const parameter = setRequestParameter(scheduleDatas);

        const result = await fetchAbsolute("put", `/workflowSchedule/modifySchedule`, {
            data: {
                ...parameter,
            },
            headers: {
                "Content-Type": "application/json",
            },
        }
        );
        setDisabledBtn(false);
        if (result.error) {
            const val = formatMessage({ id: "T0179" }).split("\n").map(data => {
                return data
            });
            // 스케줄 조건을 다시 한 번 확인해주세요. 예) 반복 실행에 속한 단위가 시작날짜와 종료날짜 사이에 있어야 합니다.
            return alert(val.join('\n'));
        }
        alert(formatMessage({ id: 'T0186' })); // 스케줄 수정이 완료되었습니다.
        setEditable(false);
        onClickResetSchedule();
        setExecuteButton(true);
        fetchScheduleList(); // 수정되고 즉시 변경되야 함
    };


    /* 스케줄 등록 취소 버튼 */
    const onCancelModifySchedule = () => {
        onClickResetSchedule();
        setEditable(false);
        setExecuteButton(true);
    };

    /* 스케줄 관리 > 과제 스케줄 현황 */
    const fetchScheduleList = async () => {
        setTableLoading(true);
        const result = await fetchAbsolute("get", `/workflowSchedule/schedules?workflowId=${workflowData?.workflowId}`);
        setTableLoading(false);
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
        const datas = result.data.list?.map((v) => ({
            ...v,
            createdOn: moment(v.createdOn).format("YYYY-MM-DD"),
        }));
        setScheduleList([...datas]);
    }

    const onChangeEmailTrigger = (e) => {
        setEmailTriggerVal(e);
    }

    const onChangeChatTrigger = (e) => {
        setChatbotStatus(e.target.value);
    }


    const onCancleEmailTrigModal = () => {
        setEmailTriggerVisible(false);
    }


    const onClickEmailTriggerInfo = () => {
        if (emailTriggerVal.length < 1) return alert(`Email Trigger Name${formatMessage({ id: 'T1311' })}`); // 을 선택해주세요
        setEmailTriggerVisible(true);
    }

    const fetchEmailTriggerList = async () => {
        const TriggerResult = await fetchAbsolute("get", `/admin/trigger/email/list`);
        if (TriggerResult.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
        setEmailTriggerList([...TriggerResult.data.lists]);
    }

    useEffect(() => {
        fetchEmailTriggerList();
    }, [emailTriggerStatus, emailTriggerVal]);


    useEffect(() => {
        if (!workflowData?.workflowId) return;
        fetchScheduleList();
    }, [workflowData?.workflowId]);

    return (
        <div className="status_box__wrap lookup_body__box">
            {
                (workflowData.empLevGbns?.includes('M') 
                || workflowData.empLevGbns?.includes('A'))
                &&
                <ScheduleEnter
                operValues={operValues}
                commonSchedules={commonSchedules}
                rDaily={rDaily}
                rWeekly={rWeekly}
                rMonthly={rMonthly}
                onChangeCycleDaily={onChangeCycleDaily}
                onChangeReapeatWeekly={onChangeReapeatWeekly}
                onChangeCommonDatas={onChangeCommonDatas}
                onChangeOperValues={onChangeOperValues}
                onSelectMonthlyDatas={onSelectMonthlyDatas}
                requireFields={requireFields}
                radioRepeatField={radioRepeatField}
                checkboxRepeatField={checkboxRepeatField}
                editable={editable}
                onClickRegSchedule={onClickRegSchedule}
                onClickModifySchedule={onClickModifySchedule}
                disabledBtn={disabledBtn}
                onClickResetSchedule={onClickResetSchedule}
                onCancelModifySchedule={onCancelModifySchedule}
                scheduleInfo={scheduleInfo}
                executeButton={executeButton}
                setEmailTriggerStatus={setEmailTriggerStatus}
                emailTriggerList={emailTriggerList}
                onChangeEmailTrigger={onChangeEmailTrigger}
                onChangeChatTrigger={onChangeChatTrigger}
                onClickEmailTriggerInfo={onClickEmailTriggerInfo}
                chatbotStatus={chatbotStatus}
                emailInfoSeq={emailTriggerVal}
            />
            }
            <ScheduleStatus
                onClickEditStatusBtn={onClickEditStatusBtn}
                scheduleList={scheduleList}
                schduleDeleteBtn={schduleDeleteBtn}
                tableLoading={tableLoading}
            />
            <ScheduleProdStatus
                workflowData={workflowData}
                operData={operData}
                setOperData={setOperData}
            />
            {/* <div className='flex justify-end' style={{ marginTop: 20 }}>
                <Button style={{ width: 150, marginLeft: 30, height: 40 }} onClick={onClickCancel}>
                    {formatMessage({ id: 'T1233' })} */}
                    {/* 목록 */}
                {/* </Button>
            </div> */}
            <EmailTriggerModal onCancel={onCancleEmailTrigModal} visible={emailTriggerVisible} emailInfoSeq={emailTriggerVal} />
        </div>
    );
}

export default WorkflowProdStatus;