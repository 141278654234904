import React, { useState } from "react";
import { Button, Text, AssignEnterBox, HFWorkflowIframe, UserTag, HFWorkflowIframeModal } from "components";
import { Row, Col, Input as AntInput } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import { withRouter } from 'react-router-dom';
import moment from "moment";
import { getUniqueKey } from 'utils/Str';
import { useSelector } from "react-redux";
import { DeleteOutlined } from "@ant-design/icons";
const { TextArea } = AntInput;

const ProgressDevelopment = ({
    comment,
    setComment,
    onOpenCommentDel,
    commentList,
    onClickComment,
    onClickDeleteComment,
    progressCondition,
    operModiCondition,
    profileStatusDev,
    reStartCondition,
    deactivateCondition,
}) => {
    const { formatMessage } = useIntl();

    const profile = useSelector(state => state.get('auth').get('profile'));
    const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

    const [iframeModalVisible, setIframeModalVisible] = useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const onMaximizeIframe = () => setIframeModalVisible(true);

    const onOkIframe = () => {
        setConfirmModalVisible(false);
        setIframeModalVisible(false);
    }
    const onCancelIframe = () => setIframeModalVisible(false);

    const onOkConfirmButton = () => setConfirmModalVisible(true);
    const onCancelConfirmButton = () => setConfirmModalVisible(false);

    return (       
        <div>
            {
                (progressCondition || operModiCondition) && profileStatusDev
                ?
                //  detailStatusCd: WS21 or WS23일때, 수정화면(modify)
                <HFWorkflowIframeModal
                    type='modify'
                    visible={iframeModalVisible}
                    confirmModalVisible={confirmModalVisible}
                    data={hfWorkflowLookup.workflowData}
                    onCancel={onCancelIframe}
                    onOkConfirmButton={onOkConfirmButton}
                    onCancelConfirmButton={onCancelConfirmButton}
                    onOkIframe={onOkIframe}
                />
                :
                // 그외: view
                <HFWorkflowIframeModal
                    type='lookup'
                    visible={iframeModalVisible}
                    confirmModalVisible={confirmModalVisible}
                    data={hfWorkflowLookup.workflowData}
                    onCancel={onCancelIframe}
                    onOkConfirmButton={onOkConfirmButton}
                    onCancelConfirmButton={onCancelConfirmButton}
                    onOkIframe={onOkIframe}
                />
            }
            <Row>
                <Col>
                    <Text 
                        className={deactivateCondition ? "basic_row__title status__disabled" : "basic_row__title"}
                        style={{paddingRight:"30px"}}
                    >
                        Workflow
                    </Text>
                    {
                        (progressCondition || operModiCondition) && profileStatusDev && !iframeModalVisible
                        ?
                        <Button
                            type="primary"
                            style={{ borderRadius: "4px"}}
                            onClick={onMaximizeIframe}
                        ><FormattedMessage id="T1652" />{/* 수정하러 가기 */}
                        </Button>
                        : (!deactivateCondition 
                            && !((progressCondition || operModiCondition) 
                            && profileStatusDev 
                            && !iframeModalVisible)
                        )
                            &&
                            <Button
                                type="primary"
                                style={{ marginBottom:"5px", borderRadius: "4px"}}
                                onClick={onMaximizeIframe}
                            ><FormattedMessage id="T1650" />{/* 크게 보기 */}
                            </Button>
                    }
                </Col>
            </Row>
            {
                // statusCd:WS10일때는 준비중 텍스트 출력
                deactivateCondition
                    ?
                    <Row>
                        <Col span={24}>
                            <div className="basic_box__content status__disabled">
                                <div className="progress_text status__disabled">
                                    <FormattedMessage id="T0144" />{/* 준비중입니다. */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                    
                    :
                    // 개발시작, 수정시작(restart)를 눌렀을때만 iframe이 보임
                    // 이는 각각 detailStatusCd: 개발 시작(WS21), 수정 시작(WS23)일때임
                    !((progressCondition || operModiCondition) && profileStatusDev && !iframeModalVisible)
                    &&   
                        <Row>
                            <Col span={24} style={{textAlign:"left"}}>
                                <AssignEnterBox>
                                    <HFWorkflowIframe
                                        type='lookup'
                                        data={hfWorkflowLookup.workflowData}
                                    />
                                </AssignEnterBox>
                            </Col>
                        </Row>
            }

            {/* 문의 사항 부분 */}
            {/* 수정요청 후, detailStatusCd:WS22일때, disabled상태로 만들기 */}
            <Row style={{ paddingTop: "20px" }}>
                <Col>
                    <Text className={deactivateCondition ? "basic_row__title status__disabled" : "basic_row__title"}>
                        <FormattedMessage id="T1614" />{/* 문의 사항 */}
                    </Text>
                </Col>
            </Row>
            {
                (progressCondition || operModiCondition)
                &&
                <Row style={{ display: "flex", justifyContent: "space-between", paddingBottom:"5px"}}>
                    <Col span={19} className="basic_row__content">
                        <TextArea
                            onChange={(e) => setComment(e.target.value)}
                            value={comment}
                            placeholder={formatMessage({ id: 'T1615' })} // 문의 사항을 입력해주세요
                            maxLength={2000}
                            style={{
                                height: 65,
                            }}
                            showCount
                        // disabled={!editCondition}
                        />
                    </Col>
                    <Col span={4} className="flex justify-end">
                        <Button
                            height="65"
                            width="260"
                            className="block__button"
                            onClick={onClickComment}
                        // disabled={hfWorkflowLookup.workflowData.detailStatusCd === "WS22"?true:false}
                        >
                            <FormattedMessage id="T0050" />{/* 등록 */}
                        </Button>
                    </Col>
                </Row>
            }

            {
                commentList?.length > 0
                    ?
                    <div
                        className="comment_list"
                        style={{ maxHeight: '120px', overflowY: 'scroll', marginBottom: "25px" }}>
                        {
                            commentList.map((val, index) => {
                                return (
                                    <Row key={index} className="continue_comment__container">
                                        <Col span={5} className="user_info">
                                            <UserTag
                                                profile={{
                                                    NAME_KOR: val.empNam,
                                                    EMP_NO: val.empNum,
                                                    DEPT_NAME_KOR: val.orgNam,
                                                    FILE_ID: val.fileId,
                                                }}
                                                contextVisible={true}
                                                key={val.empNam+index}
                                                // disabled={hfWorkflowLookup.workflowData?.statusCd === "WS20" ? false : true}
                                            />
                                        </Col>
                                        <Col span={14} className="user_comment">
                                            <Text>
                                                {
                                                    // 엔터(\n)있으면 줄바꿈 해주기
                                                    val.comment.includes('\n')
                                                ?  val.comment.split('\n').map(data =>
                                                    <div>
                                                        <span>{data}</span>
                                                        <br/>
                                                    </div>
                                                    )
                                                :  val.comment  
                                                }
                                            </Text>
                                            {/* <Text>{val.comment}</Text> */}
                                        </Col>
                                        <Col span={3} className="comment_time">
                                            <Text>{moment(val.regTimestamp).format("YYYY-MM-DD hh:mm:ss")}</Text>
                                        </Col>
                                        <Col span={1} className="comment_remove">
                                            {
                                            ((progressCondition || operModiCondition) && profile?.EMP_NO === val.empNum)
                                            && 
                                                <div>
                                                    <DeleteOutlined
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=>onOpenCommentDel(val.idx)}/>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                )
                            })}
                    </div>
                    :
                    <Row style={{ alignItems: "center" }}>
                        <Col span={24}>
                            <div className={deactivateCondition ? "basic_box__content status__disabled" : "basic_box__content"}>
                                <div className={deactivateCondition ? "progress_text status__disabled" : "progress_text"}>
                                    <FormattedMessage id="T1616" />{/* 문의 사항이 없습니다 */}
                                </div>
                            </div>
                        </Col>
                    </Row>
            }
        </div>
    )
}

export default withRouter(ProgressDevelopment); 