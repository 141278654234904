import { getUniqueKey } from "utils/Str";

export const SET_HANDYFLOWWORKFLOWLIST_VALUE = getUniqueKey(
  "SET_HANDYFLOWWORKFLOWLIST_VALUE"
);
export const SET_HANDYFLOWWORKFLOWLIST_SPREAD = getUniqueKey(
  "SET_HANDYFLOWWORKFLOWLIST_SPREAD"
);
export const RESET_HANDYFLOWWORKFLOWLIST_VALUE = getUniqueKey(
  "RESET_HANDYFLOWWORKFLOWLIST_VALUE"
);
export const SET_HANDYFLOWWORKFLOWLIST_FILTERID = getUniqueKey(
  "SET_HANDYFLOWWORKFLOWLIST_FILTERID"
);

export function setHandyFlowWorkflowListValue(key, value) {
  return {
    type: SET_HANDYFLOWWORKFLOWLIST_VALUE,
    key,
    value,
  };
}

export function setHandyFlowWorkflowListSpread(data) {
  return {
    type: SET_HANDYFLOWWORKFLOWLIST_SPREAD,
    data,
  };
}

export function resetHandyFlowWorkflowListValue() {
  return {
    type: RESET_HANDYFLOWWORKFLOWLIST_VALUE,
  };
}

export function setHandyFlowWorkflowListFilterId(filterId) {
  return {
    type: SET_HANDYFLOWWORKFLOWLIST_FILTERID,
    filterId,
  };
}
