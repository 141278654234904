import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled.js";
import { Row, Col, Select } from "antd";
import {
  Input,
  Button,
  InputSearch,
  FormTable,
  Text,
  DepartmentList,
  Organization,
  BusinessClass,
  Modal,
} from "components";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  setWorkFlowScheduleValue,
  setWorkFlowScheduleSpread,
  resetWorkFlowScheduleValue,
  setWorkFlowScheduleFilterId,
} from "store/workflowSchedule/action";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

/**
 * Filename: WorkFlowScheduleTop/index.js
 * BizFlow > boxwood 스케줄 Schedule
 */

const WorkFlowScheduleTop = ({
  replaceSearch,
  searchQuery,
  scheduleSearch,
  setScheduleSearch,
}) => {
  const { formatMessage } = useIntl();

  const initialInputValues = {
    workflowId: "",
    workflowNm: "",
    scheduleType: "",
    workId: {},
    workflowOrgCd: {},
    regId: {},
  };

  const scheduleTypeList = [
    {
      text: `${formatMessage({ id: "T1150" })}`,
      value: "NONE",
    },
    {
      text: `${formatMessage({ id: "T1151" })}`,
      value: "DAILY",
    },
    {
      text: `${formatMessage({ id: "T1152" })}`,
      value: "WEEKLY",
    },
    {
      text: `${formatMessage({ id: "T1153" })}`,
      value: "MONTHLY",
    },
  ];

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.get("auth").get("profile"));
  const inputValues = useSelector(
    (state) => state.get(getUniqueKey("workflowSchedule")).inputValues
  );
  const filterId = useSelector(
    (state) => state.get(getUniqueKey("workflowSchedule")).filterId
  );

  const isDelete = useRef(false);

  const selectPlaceholder = formatMessage({ id: "T0024" }); // 선택
  const scheduleType = formatMessage({ id: "T0010" }); // 스케줄 유형
  const searchPlaceholder = formatMessage({ id: "T0025" }); // 검색어를 입력하세요
  const mgrEmpNoPlaceholder = formatMessage({ id: "T0026" }); // 사용자를 선택하세요
  const recentSearch = formatMessage({ id: "T0031" }); // 최근 검색
  const selectFilter = formatMessage({ id: "T0033" }); // 필터 선택
  const saveFilter = formatMessage({ id: "T0034" }); // 필터 저장
  const filterNm = formatMessage({ id: "T0035" }); // 필터명
  const inputfilter = formatMessage({ id: "T0150" });
  const filterLimited = formatMessage({ id: "T0152" });

  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    workId: false,
    regId: false,
    workflowOrgCd: false,
    filter: false,
  });
  // 저장 필터 이름
  const [saveFilterName, setSaveFilterName] = useState("");
  // filter List State
  const [filterList, setFilterList] = useState([]);

  //  select 부분에선 e.target.value 등이 안먹혀서 둘 만 따로 'e'로 관리
  const onChangeSearchInput = (label, value) => {
    dispatch(setWorkFlowScheduleValue(label, value));
  };

  // shceduleType 변경 함수
  const onChangeScheduleType = (e) => {
    const value = scheduleTypeList.find((type) => type.value === e);
    onChangeSearchInput("scheduleType", { ...value });
  };

  // input 부분 나머지 search와 select는 위에 따로 빼줌
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    onChangeSearchInput(name, value);
  };

  // 저장할 필터 이름 변경 이벤트
  const onChangeSaveFilterName = (e) => {
    // 필터 이름 15글자 제한
    if (e.target.value.length > 15) return;
    setSaveFilterName(e.target.value);
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true,
    });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    dispatch(setWorkFlowScheduleValue(modalLabel, data));
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  const onOkFilterModal = async () => {
    setModalVisible({
      ...modalVisible,
      filter: false,
    });

    if (saveFilterName.trim().length === 0) {
      alert(inputfilter);
      return;
    }
    // 내 필터 5개 제한
    if (filterList.length > 4) {
      alert(filterLimited);
      return;
    }
    const saveInputValues = inputValues;
    const addFilter = await fetchAbsolute(
      "post",
      `/common/commonFilter/addNew`,
      {
        data: {
          ...setFilterObject(saveInputValues, saveFilterName),
          filterGbn: "w",
        },
        header: {
          "Content-Type": "application/json",
        },
      }
    );
    setSaveFilterName("");
    setFilterList([
      ...filterList,
      {
        ...saveInputValues,
        filterId: addFilter.data.filterId,
        filterNm: saveFilterName,
      },
    ]);
  };

  // 필터 저장할 때 값 세팅
  const setFilterObject = (values, filterName) => {
    const scheduleType = values.scheduleType.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.value);
      return tmpArr;
    });
    const workId = values.workId.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.cd);
      return tmpArr;
    });
    const workflowOrgCd = values.workflowOrgCd.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.codeId);
      return tmpArr;
    });
    const regId = values.regId.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum);
      return tmpArr;
    });
    const convertObject = {
      empNo: profile.EMP_NO,
      filterNm: filterName,
      filterList: [
        {
          filterNum: "filter1", //workflowId
          attrList: [values.workflowId] || [],
        },
        {
          filterNum: "filter2", //workflowNm
          attrList: [values.workflowNm] || [],
        },
        {
          filterNum: "filter3", //scheduleType
          attrList: scheduleType.flat() || [],
        },
        {
          filterNum: "filter4", //workId
          attrList: workId.flat() || [],
        },
        {
          filterNum: "filter5", //workflowOrgCd
          attrList: workflowOrgCd.flat() || [],
        },
        {
          filterNum: "filter6", //regId
          attrList: regId.flat() || [],
        },
      ],
    };
    return convertObject;
  };

  // 초기화 버튼 클릭 이벤트
  const onClickResetButton = () => {
    dispatch(resetWorkFlowScheduleValue());
    dispatch(setWorkFlowScheduleFilterId(""));
    replaceSearch({ ...initialInputValues });
    sessionStorage.clear();
  };

  // 검색 버튼 클릭 이벤트
  const onClickSearchButton = () => {
    dispatch(setWorkFlowScheduleFilterId(""));
    replaceSearch({ ...inputValues });
  };

  const onClickScheduleSearch = () => {
    const searchObj = JSON.parse(
      localStorage.getItem("beforeObjectWorkflowSchedule") || {}
    );
    dispatch(
      setWorkFlowScheduleSpread({ ...initialInputValues, ...searchObj })
    );
    replaceSearch(searchObj);
  };

  // 최근 검색 삭제 클릭 이벤트
  const onClickScheduleSearchDelete = () => {
    setScheduleSearch("");
    localStorage.setItem("beforeSearchWorkflowSchedule", "");
    localStorage.setItem("beforeObjectWorkflowSchedule", "");
  };

  // 필터 리스트 변경 이벤트
  const onSelectFilterList = (value) => {
    // 삭제 버튼 눌렀을 때 filterId 변경되지 않게 세팅
    if (isDelete.current) {
      isDelete.current = false;
      return;
    }
    dispatch(setWorkFlowScheduleFilterId(value));
  };

  // 필터 삭제 버튼 클릭 이벤트
  const onClickFilterDelete = async (e) => {
    isDelete.current = true;
    const value = e.currentTarget.dataset.filterid;
    const deleteFilterList = await fetchAbsolute(
      "delete",
      `/common/commonFilter/deleteNew`,
      {
        data: {
          empNo: profile.EMP_NO,
          filterId: value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (deleteFilterList.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    const tmpArr = [...filterList];
    if (filterId === value) {
      dispatch(setWorkFlowScheduleFilterId(""));
    }
    setFilterList([...tmpArr.filter((filter) => filter.filterId !== value)]);
  };

  useEffect(() => {
    dispatch(
      setWorkFlowScheduleSpread({
        ...searchQuery,
      })
    );
  }, [searchQuery]);

  useEffect(() => {
    if (filterId === "" || filterList.length === 0) {
      return;
    }
    const filterObject = {
      ...(filterList.find((v) => v.filterId === filterId) || {}),
    };
    if (filterObject) {
      delete filterObject.filterNm;
      delete filterObject.filterId;

      const setDatas = async () => {
        dispatch(
          setWorkFlowScheduleSpread({ ...filterObject, ...searchQuery })
        );
        replaceSearch(filterObject);
      };
      setDatas();
    }
  }, [filterId, filterList]);

  useEffect(() => {
    const fetchFilterList = async () => {
      try {
        const result = await fetchAbsolute(
          "get",
          `/common/commonFilter/findByEmpNoAndFilterGbnNew?empNo=${profile.EMP_NO}&filterGbn=w`
        );
        const lists = result.data.commonFilters?.map((filter) => ({
          filterId: filter.filterId,
          filterNm: filter.filterNm,
          workflowId: filter.filter1?.map((data) => data.filterAttr)[0] || "",
          workflowNm: filter.filter2?.map((data) => data.filterAttr)[0] || "",
          scheduleType: filter.filter3?.map((data) => data.filterAttr) || [],
          workId: filter.filter4?.map((data) => data.filterAttr) || [],
          workflowOrgCd: filter.filter5?.map((data) => data.filterAttr) || [],
          regId: filter.filter6?.map((data) => data.filterAttr) || [],
        }));
        setFilterList([...lists]);
      } catch { }
    };
    setScheduleSearch(
      localStorage.getItem("beforeSearchWorkflowSchedule") || ""
    );
    fetchFilterList();
  }, []);

  return (
    <Wrapper>
      <DepartmentList // 업무 분류
        visible={modalVisible.workflowOrgCd}
        onOk={onOkModal}
        onCancel={onCancelModal}
      />
      <Organization // 조직도(등록자)
        visible={modalVisible.regId}
        title={formatMessage({ id: "T0465" })}
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={[inputValues[modalLabel]]}
        max={1}
      />
      <BusinessClass // 담당부서
        title={formatMessage({ id: "T1119" })}
        visible={modalVisible.workId}
        onOk={onOkModal}
        onCancel={onCancelModal}
        max={1}
      />
      <Modal
        centered="centered"
        title={saveFilter}
        visible={modalVisible.filter}
        onOk={onOkFilterModal}
        onCancel={onCancelModal}
        width="500px"
      >
        <div id="modalForm">
          <label for="filterNm">{filterNm}</label>
          <Input
            id="filterId"
            name="filterNm"
            type="text"
            value={saveFilterName}
            mexLength={50}
            onChange={onChangeSaveFilterName}
          />
        </div>
      </Modal>
      <FormTable
        pointBorder={false}
        size="sm"
        className="assign_list_schedule_top"
      >
        <colgroup>
          <col width="9.375%" />
          <col width="15.625%" />
          <col width="9.375%" />
          <col width="15.625%" />
          <col width="9.375%" />
          <col width="15.625%" />
        </colgroup>
        <tbody>
          <tr gutter={[24, 16]}>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text>{formatMessage({ id: "T1504" })}</Text>
              {/* Workflow Id */}
            </th>
            <td>
              <Input
                placeholder={formatMessage({ id: "T0025" })} // 검색어를 입력하세요
                style={{
                  width: 200,
                  height: 32,
                }}
                name="workflowId"
                value={inputValues.workflowId}
                onChange={onChangeValues}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T1237" })}</Text>
              {/* 업무명 */}
            </th>
            <td>
              <Input
                placeholder={formatMessage({ id: "T0025" })} // 검색어를 입력하세요
                style={{
                  width: 200,
                  height: 32,
                }}
                name="workflowNm"
                value={inputValues.workflowNm}
                onChange={onChangeValues}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T0008" })}</Text>
              {/* 업무 분류 */}
            </th>
            <td>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="workId"
                placeholder={searchPlaceholder}
                value={inputValues.workId?.cdNm}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T0005" })}</Text>
              {/* 담당부서 */}
            </th>
            <td>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="workflowOrgCd"
                placeholder={searchPlaceholder}
                value={inputValues.workflowOrgCd?.codeNm || ""}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T0429" })}</Text>
              {/* 등록자 */}
            </th>
            <td>
              <div>
                <InputSearch
                  onClick={onClickModalButton}
                  width={200}
                  height={32}
                  placeholder={mgrEmpNoPlaceholder}
                  data-label="regId"
                  value={inputValues.regId?.empNam || ""}
                />
              </div>
            </td>
            <th>
              <Text>{scheduleType}</Text> {/* 스케줄유형 */}
            </th>
            <td>
              <Select
                size="default"
                style={{ width: 200 }}
                name="scheduleType"
                defaultValue=""
                placeholder={selectPlaceholder}
                value={inputValues.scheduleType?.value || ""}
                onChange={onChangeScheduleType}
              >
                <Option value="">{selectPlaceholder}</Option>
                {scheduleTypeList.map((v) => {
                  return (
                    <Option key={v.value} value={v.value}>
                      {v.text === "NONE" ? "1회 실행" : v.text}
                    </Option>
                  );
                })}
              </Select>
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Row style={{ padding: "0 13px" }}>
        <Col
          span={16}
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {scheduleSearch.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text>{recentSearch}</Text>
              <div style={{ width: 200 }}>
                <div className="before__input">
                  <Input
                    style={{
                      width: 200,
                      height: 32,
                    }}
                    readOnly
                    autoFocus
                    value={scheduleSearch}
                    onClick={onClickScheduleSearch}
                  />
                  <CloseCircleFilled
                    className="before__clear"
                    onClick={onClickScheduleSearchDelete}
                  />
                </div>
              </div>
            </div>
          )}
          {filterList.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text>{formatMessage({ id: "T0032" })}</Text>
              {/* 내 필터 */}
              <div>
                <Select
                  size="default"
                  defaultValue="none"
                  value={filterId}
                  style={{
                    width: 200,
                    fontSize: 14,
                  }}
                  onSelect={onSelectFilterList}
                >
                  <Option value="">{selectFilter}</Option>
                  {filterList.map((filter) => {
                    return (
                      <Option key={filter.filterId} value={filter.filterId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            sytle={{
                              width: 80,
                            }}
                          >
                            {filter.filterNm}
                          </div>
                          <div
                            sytle={{
                              width: 20,
                            }}
                          >
                            {
                              <CloseCircleFilled
                                onClick={onClickFilterDelete}
                                data-filterid={filter.filterId}
                              />
                            }
                          </div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0 10px",
            padding: "10px 0",
          }}
        >
          <Button width="100" height="32" onClick={onClickResetButton}>
            <FormattedMessage id="T0037" />
            {/* 초기화 */}
          </Button>
          <Button
            width="100"
            height="32"
            data-label="filter"
            onClick={(e) => onClickModalButton(e.target)}
          >
            <FormattedMessage id="T0038" />
            {/* 필터저장 */}
          </Button>
          <Button
            onClick={onClickSearchButton}
            width="100"
            height="32"
            type="primary"
          >
            <FormattedMessage id="T0039" />
            {/* 검색 */}
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default WorkFlowScheduleTop;
