import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import {
    Text, Button, Organization, Modal, PageTemplate, Header, Footer
} from 'components';
import Wrapper from "./Styled";
import useFormatMessage from "hooks/useFormatMessage";
import { Divider } from 'antd';
import fetchAbsolute from "utils/fetchAbsolute";
import UseIternalSystem from "./UseIternalSystem";
import UseIternalMenu from "./UseIternalMenu";

const initialInputValues = {
    systemCd: 0,
    category: '',
    systemName: "",
    systemTask: [],
    summary: "",
    mgrIds: [],
};

const AdminIternalSystemEnter = ({ history, type = "enter", match: { params: { systemCd } } }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const [inputValues, setInputValues] = useState({ ...initialInputValues });
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [classificationList, setClassificationList] = useState([]);
    const [selectDatas, setSelectDatas] = useState([]);
    // Modal 라벨 state
    const [modalLabel, setModalLabel] = useState("");
    // Modal visible state
    const [modalVisible, setModalVisible] = useState({
        devIds: false,
    });

    const requireFields = {
        category: {
            ref: useRef(null),
            message: formatMessage({ id: "T0362" }), // 분류
        },
        systemName: {
            ref: useRef(null),
            message: formatMessage({ id: "T0363" }), // 시스템 명
        },
        mgrIds: {
            ref: useRef(null),
            message: formatMessage({ id: "T0365" }), // 시스템 담당자
        },
    };

    const onClickCancel = () => {
        history.push(`/admin/iternalsystem/list`);
    };

    const onClickCancelSystem = () => {
        setDeleteVisible(false);
    };

    const onClickDeleteSystem = () => {
        setDeleteVisible(true);
    };

    /* 시스템 삭제 */
    const onOkDeleteSystem = async () => {
        setDeleteVisible(false);
        const result = await fetchAbsolute(
            "delete",
            `/admin/system/systemDelete?systemCd=${systemCd}`
        );
        if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
        alert(formatMessage({ id: "T0284" })); //삭제 되었습니다. 
        return history.push('/admin/iternalsystem/list');
    };

    /* 내부 시스템 메뉴 추가 및 수정 */
    const onClickSubmit = async () => {
        /* 빈칸 검사 */
        for (const key in requireFields) {
            const field = requireFields[key];
            if (!inputValues[key] || Object.keys(inputValues[key]).length <= 0) {
                field.ref.current.focus();
                //`${field.message} 항목은 필수 입력입니다.`);
                return alert(
                    fmMessage({ id: "T0128", values: { field: field.message } })
                );
            }
        }
        const { category, systemCd, systemName, summary, mgrIds } = inputValues;

        let parameter = {
            category,
            systemCd,
            systemName,
            summary,
            systemUser: mgrIds[0].empNum,
            menuList: [...selectDatas],
        };

        /* 첫 등록일 때는 systemCd가 들어가면 안된다. */
        if (systemCd === 0) {
            delete parameter.systemCd;
        }

        const result = await fetchAbsolute("post", `/admin/system/systemRegister`, {
            data: { ...parameter }
        });

        if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
        if (type === 'enter') {
            alert(formatMessage({ id: "T0224" })); // 추가되었습니다.
        } else {
            alert(formatMessage({ id: "T0164" })); // 수정 완료되었습니다.
        }
        return history.push('/admin/iternalsystem/list');
    };

    // Modal Open 버튼 클릭 이벤트
    const onClickModalButton = (e) => {
        const { label } = e.dataset;
        setModalLabel(label);
        setModalVisible({
            ...modalVisible,
            [label]: true,
        });
    };

    const onOkModal = (data) => {
        if (modalLabel === 'mgrIds') {
            setInputValues({ ...inputValues, [modalLabel]: data });
        }
        setModalVisible({
            ...modalVisible,
            [modalLabel]: false,
        });
    };

    // Modal 닫기 이벤트
    const onCancelModal = () => {
        setModalVisible({ ...modalVisible, [modalLabel]: false });
    };

    // input 부분 나머지 search와 select는 위에 따로 빼줌
    const onChangeValues = (e) => {
        const { name, value } = e.target;
        onChangeSearchInput(name, value);
    };

    const onChangeSearchInput = (label, value) => {
        setInputValues({ ...inputValues, [label]: value });
    };

    /* 분류 Select */
    const onChangeClassification = (e) => {
        setInputValues({
            ...inputValues,
            category: e
        });
    };

    /* 선택항목에 저장된 거 뿌려주기 */
    useEffect(() => {
        if (!systemCd) return;
        const getDetails = async () => {
            const result = await fetchAbsolute('get', `/admin/system/systemDetails?systemCd=${systemCd}`);
            const { data, data: { menuList } } = result;
            const tmpArr = [...selectDatas];
            for (let i = 0; i < menuList?.length; i++) {
                tmpArr.push({ ...menuList[i], key: tmpArr.length });
            };
            setSelectDatas([...tmpArr]);
            setInputValues({
                systemCd: data.systemCd,
                category: data.category,
                systemName: data.systemName,
                systemTask: data.systemTask,
                summary: data.summary,
                mgrIds: [data.systemUserInfo],
            });
        };
        getDetails();
    }, [systemCd]);

    /* System Manage 기본 정보 셋팅 */
    useEffect(() => {
        const getClassification = async () => {
            const result = await fetchAbsolute(
                "get",
                `/common/commonCd/getCategoryId/USESYS_TYPE`
            );
            if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
            setClassificationList([...result.data]);
        };
        getClassification();
    }, []);

    return (
        <PageTemplate
            header={<Header />}
            footer={<Footer />}
            headerColor="none"
            {...{
                ...(type === "enter"
                    ? {
                        menuTitle: `${fmMessage({ id: 'T1265', values: { name: formatMessage({ id: 'T1302' }) } })}`,  // {name} 수정
                        depthList: [
                            `${formatMessage({ id: "T1302" })}`, // 내부 시스템 메뉴
                            `${fmMessage({ id: 'T1265', values: { name: formatMessage({ id: 'T1302' }) } })}` // {name} 수정
                        ],
                    }
                    : {
                        menuTitle: `${fmMessage({ id: 'T1264', values: { name: formatMessage({ id: 'T1302' }) } })}`,   // {name} 수정
                        depthList: [
                            `${formatMessage({ id: "T1302" })}`, // 내부 시스템 메뉴
                            `${fmMessage({ id: 'T1264', values: { name: formatMessage({ id: 'T1302' }) } })}` // {name} 수정
                        ],
                    }),
            }}
        >
            <Wrapper>
                <div className="common__container">
                    <div className='component__section'>
                        <div>
                            <UseIternalSystem
                                inputValues={inputValues}
                                onChangeValues={onChangeValues}
                                classificationList={classificationList}
                                onChangeClassification={onChangeClassification}
                                requireFields={requireFields}
                                onClickModalButton={onClickModalButton}
                            />
                        </div>
                        <Divider />
                        <div>
                            <UseIternalMenu
                                selectDatas={selectDatas}
                                setSelectDatas={setSelectDatas}
                                systemCd={systemCd}
                            />
                        </div>
                    </div>
                    <div className="button_company">
                        {type === 'enter' ? (
                            <Button type="primary" onClick={onClickSubmit}>
                                {formatMessage({ id: 'T0049' })}{/* 추가 */}
                            </Button>
                        ) : (
                            <>
                                <Button type="primary" onClick={onClickSubmit}>
                                    {formatMessage({ id: 'T0054' })}{/* 수정 */}
                                </Button>
                                <Button type="default" onClick={onClickDeleteSystem}>
                                    {formatMessage({ id: 'T0072' })}{/* 삭제 */}
                                </Button>
                            </>
                        )
                        }

                        <Button
                            type="default"
                            onClick={onClickCancel}
                        >
                            {formatMessage({ id: "T0051" })}{/* 취소 */}
                        </Button>
                    </div>
                </div>
                <Modal
                    centered
                    visible={deleteVisible}
                    width={500}
                    okText={formatMessage({ id: "T0064" })} // 확인
                    title={formatMessage({ id: "T0072" })} // 삭제
                    onOk={onOkDeleteSystem}
                    onCancel={onClickCancelSystem}
                >
                    <div style={{ textAlign: "center" }}>
                        <Text>{formatMessage({ id: "T1234" })}</Text>
                        {/* 정말로 삭제 하시겠습니까? */}
                    </div>
                </Modal>
                <Organization
                    visible={modalVisible.mgrIds}
                    title={formatMessage({ id: "T0465" })} // 담당자 검색
                    onOk={onOkModal}
                    onCancel={onCancelModal}
                    defaultData={inputValues['mgrIds']}
                    max={1}
                />
            </Wrapper>
        </PageTemplate>
    )
}
export default AdminIternalSystemEnter;