import React from 'react';
import { Input as AntInput, Typography } from 'antd';

const { Text } = Typography;

/**
 * Filename: Input.js
 * Description: 값을 입력하는 Input 공통 컴포넌트.
 * label Props의 유무로 Input 오르쪽에 라벨을 생성할 수 있다.
 */

const Input = ({ value='', onChange=() => {}, style={}, label='', labelSize=12, innerRef, autoSize, ...args}) => {
    return (
        <>
            <AntInput style={{ height:32, ...style }} value={value} onChange={onChange} ref={innerRef} {...args} />
            {
                label.length > 0 &&
                <Text style={{ marginLeft:10, color:'#707070', fontSize: labelSize}}>
                    {label}
                </Text>
            }
        </>
    )
}

export default Input;