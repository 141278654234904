import React, { useState, useEffect } from "react";
import { Radio } from "antd";
import { Button, RadioButton, TypePicker, Table } from "components";
import moment from "moment";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";

/**
 *  관리자 > 회원 관리 > 회원 조회 > 포인트 조회 탭
 */

const initialPageInfo = {
  sort: "createDate",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

const PointTab = ({ empNum }) => {
  const { formatMessage } = useIntl();

  const [pointLoading, setPointLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const [regType, setRegType] = useState("today");
  const [regDate, setRegDate] = useState({
    fromDate: moment().format("YYYY-MM-DD"),
    toDate: moment().format("YYYY-MM-DD"),
  });

  const [pointList, setPointList] = useState([]);

  const fetchPointList = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    };
    setPointLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/academy/pointList?empNo=${empNum}&history=Y&regFr=${regDate.fromDate}&regTo=${regDate.toDate}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setPointLoading(false);
    if (result.error) {
      return alert(formatMessage({ id: "T0274" })); // 포인트 조회를 실패하였습니다.
    }
    setPointList([...result.data.pointList]);
    setPageInfo({ ...page, total: result.data.totalItemsCount });
  };

  const onChangeDateRadio = (e) => {
    const { value } = e.target;
    setRegType(value);
    const toDate = moment().format("YYYY-MM-DD");
    switch (value) {
      case "today":
        setRegDate({
          fromDate: toDate,
          toDate,
        });
        break;
      case "week":
        setRegDate({
          fromDate: moment().subtract(7, "d").format("YYYY-MM-DD"),
          toDate,
        });
        break;
      case "month":
        setRegDate({
          fromDate: moment().subtract(1, "months").format("YYYY-MM-DD"),
          toDate,
        });
        break;
      default:
    }
  };

  const onChangeFromDate = (e) => {
    setRegDate({
      ...regDate,
      fromDate: e.format("YYYY-MM-DD"),
    });
  };

  const onChangeToDate = (e) => {
    setRegDate({
      ...regDate,
      toDate: e.format("YYYY-MM-DD"),
    });
  };

  const onClickSearch = () => {
    fetchPointList();
  };


  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'createDate';
    let orderBy = "";
    if (!sorter.order) {
      order = "createDate"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    fetchPointList(searchObj);
  };

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: "T0608" }), // 포인트 적립(차감) 사유
      dataIndex: "pointTypeNm",
      width: 300,
    },
    {
      key: 2,
      title: formatMessage({ id: "T0609" }), // 포인트
      dataIndex: "point",
      width: 100,
    },
    {
      key: 3,
      title: formatMessage({ id: "T0610" }), // 누적 포인트
      dataIndex: "curPoint",
      width: 100,
    },
    {
      key: 4,
      title: formatMessage({ id: "T0611" }), // 일자
      dataIndex: "createDate",
      width: 100,
      render: (date) => moment(date).format("YYYY-MM-DD HH:mm"),
    },
  ];

  useEffect(() => {
    fetchPointList();
    return () => {
      setPointLoading(false);
    };
  }, []);

  return (
    <div className="member__point_tab">
      <div className="point_tab__point">
        <div className="point_tab__point_search">
          <Radio.Group
            className="point_tab__radio_wrap"
            onChange={onChangeDateRadio}
            defaultValue="today"
            value={regType}
          >
            <RadioButton value="today" className="date__btn">
              <FormattedMessage id="T0044" />
              {/* 오늘 */}
            </RadioButton>
            <RadioButton value="week" className="date__btn">
              <FormattedMessage id="T0045" />
              {/* 일주일 */}
            </RadioButton>
            <RadioButton value="month" className="date__btn">
              <FormattedMessage id="T0046" />
              {/* 1개월 */}
            </RadioButton>
            <RadioButton value="custom" className="date__btn">
              <FormattedMessage id="T0047" />
              {/* 직접입력 */}
            </RadioButton>
          </Radio.Group>
          <div className="point_tab__picker_wrap">
            <TypePicker
              popupStyle={{
                ".ant-picker-cell-inner": {
                  background: "#000",
                },
              }}
              style={{
                width: 200,
                height: 32,
                alignItems: "center",
              }}
              size="middle"
              format="YYYY-MM-DD"
              value={moment(regDate.fromDate, "YYYY-MM-DD")}
              name="fromDate"
              disabled={!(regType === "custom")}
              onChange={onChangeFromDate}
            />
            ~
            <TypePicker
              popupStyle={{
                ".ant-picker-cell-inner": {
                  background: "#000",
                },
              }}
              style={{
                width: 200,
                height: 32,
                alignItems: "center",
              }}
              size="middle"
              format="YYYY-MM-DD"
              value={moment(regDate.toDate, "YYYY-MM-DD")}
              name="toDate"
              defaultValue={undefined}
              disabled={!(regType === "custom")}
              onChange={onChangeToDate}
            />
          </div>
          <Button onClick={onClickSearch}>
            {formatMessage({ id: "T0081" })}{/* 조회 */}
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={pointList}
          loading={pointLoading}
          pagination={true}
          rowKey={(e) => e.seq}
          onChange={onChangeTableColumns}
          pageOptions={{
            total: +pageInfo.total,
            current: +pageInfo.currentPage,
            pageSize: +pageInfo.rowPerPage,
          }}
        />
      </div>
    </div>
  );
};

export default PointTab;
