import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  Button,
  Tabs,
  Table,
  PermissionComponent,
  UserContext,
  Text,
  Modal
} from "components";
import { Select, Row, Col, Tabs as AntTabs, Spin, Tooltip } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import XLSX from "xlsx-js-style";
import moment from "moment";
import { HeartFilled, PlayCircleFilled } from "@ant-design/icons";
import { InstantStartWorkflow, loadWorkflowData } from "store/hfWorkflowLookup/action";

/**
 * Filename: HFWorkflowListBottom/index.js
 * Description: Handy Flow Workflow list Bottom 컴포넌트
 */

const { TabPane } = AntTabs;
const { Option } = Select;

const initialPageInfo = {
  sort: "regTimestamp,desc",
  page: 1,
  size: 20,
  total: 0,
  tab: "all",
};


const Wrapper = styled.div`
  .ant-tabs-ink-bar {
    bottom: 0;
    background: none;
  }
  .ant-table table {
    table-layout: fixed !important;
  }
  .people_section {
    display: flex;
  }
  .people_section .main {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .orgNm_section {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const HFWorkflowListBottom = ({
  tableTotal,
  tabsValue,
  tableData,
  searchQuery,
  replaceSearch,
  toggleHeart,
  tableLoading,
  sortedInfo,
  setSortedInfo,
  excelBody,
  location: { search }
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const dispatch = useDispatch();
  const history = useHistory();
  const pageSize = [20, 50, 70];
  const selectRowPerPage = formatMessage({ id: "T0036" }); // 페이지당 행 
  const devEmpNm = formatMessage({ id: "T0007" }); // 개발자 
  const statusNm = formatMessage({ id: "T0457" }); // 과제상태
  const companyNm = formatMessage({ id: "T1500" }); // 회사명

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [runInfo, setRunInfo] = useState({});
  const [asapConfirmVisible, setAsapConfirmVisible] = useState(false);

  const onOkAsapRun = () => {
    setAsapConfirmVisible(false);
    dispatch(InstantStartWorkflow({
      successMsg: formatMessage({ id: "T0147" }), // 즉시 실행이 시작되었습니다.
      failedMsg: formatMessage({ id: "T0148" }) // 즉시 실행 오류가 발생하였습니다.
    }))
    return history.push(`/bizflow/hfworkflow/lookup/${runInfo.workflowId}/status`);
  };
  const onCancelAsapRun = () => setAsapConfirmVisible(false);

  const onClickExcelButton = async () => {
    let path = "";
    switch (tabsValue) {
      case "all":
        path = "workflowListHFExcel";
        break;
      case "myTask":
        path = "workflowMyListHFExcel";
        break;
      case "interest":
        path = "workflowInterestListHFExcel";
        break;
      default:
        path = "workflowListHFExcel";
    }

    setButtonDisabled(true);
    const excelResult = await fetchAbsolute('post', `/workflow/${path}${search}`, {
      data: {
        ...excelBody
      }
    })
    setButtonDisabled(false);
    if (excelResult.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    const exceldatas = excelResult.data.map((v) => ({
      ...v,
      regTimestamp: moment(v.regTimestamp).format("YYYY-MM-DD"),
    }));

    /* flatten objects */
    const rows = exceldatas.map((data) => {
      const workNm = formatMessage({ id: 'T0340' }); // 업무분류
      const workflowId = formatMessage({ id: 'T1316' }); // 업무 ID
      const workflowNm = formatMessage({ id: 'T1237' }); // 업무명
      const mainOrgNm = formatMessage({ id: 'T1261' }); // 관련 부서
      const mainEmpNm = formatMessage({ id: 'T0456' }); // 개발자
      const regTimestamp = formatMessage({ id: 'T0394' }); // 등록일

      return ({
        [workNm]: data.workNm,
        [workflowId]: data.workflowId,
        [workflowNm]: data.workflowNm,
        [companyNm]: data.companyNm,
        [mainOrgNm]: data.mainOrgNm,
        [mainEmpNm]: data.mainEmpNms,
        [devEmpNm]: data.devEmpNms,
        [statusNm]: data.statusNm,
        [regTimestamp]: data.regTimestamp,




      })
    });
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* css */
    for (let i in worksheet) {
      if (typeof (worksheet[i]) !== "object") continue;
      /* header만 굵은 글씨 & 가운데 정렬 */
      worksheet[i].s = {
        font: {
          name: 'Malgun Gothic',
          sz: 11,
        }
      }
      if (i.includes('1') && i.length === 2) {
        worksheet[i].s = {
          font: {
            name: 'Malgun Gothic',
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          }
        }
      }
    }

    if (!worksheet['!cols']) worksheet['!cols'] = [];
    let cellsWidth = Array.from({ length: 6 }, () => ({ width: 30 })); // length는 header 컬럼 만큼
    worksheet['!cols'].push(...cellsWidth);

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, "Workflow List.xlsx", { compression: true });
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'regTimestamp';
    // workGbnNm이 정렬할 때는 workPathNm로 사용됨
    if (sorter.field === 'workGbnNm') order = sorter.columnKey
    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order + orderBy,
      page: pagination.current - 1
    };
    setSortedInfo(sorter);
    replaceSearch(searchObj);
  };

  const onChangeTab = (tabValue) => {
    const searchObj = {
      ...pageInfo,
      tab: tabValue,
      page: 0,
    };
    replaceSearch(searchObj);
  };

  const onSelectOption = (value) => {
    const searchObj = {
      ...pageInfo,
      size: value,
    };
    replaceSearch(searchObj);
  };
  const onClickOperation = async (record) => {
    setRunInfo(record);
    setAsapConfirmVisible(true);
  };

  const onChangePage = (currentPage) => {
    const searchObj = {
      ...pageInfo,
      currentPage,
    };
    replaceSearch(searchObj);
  };

  // 관심 과제 추가 및 삭제
  const onClickHeartButton = async (record) => {
    const { workflowId, interestGbn } = record;

    const taskYn = interestGbn === "Y" ? "N" : "Y";
    // /${taskYn === "Y" ? "add" : "delete"}

    if (taskYn === 'Y') {
      const addInterestPD = await fetchAbsolute(
        "get",
        `/workflow/boardRcmdcountSaveHF?workflowId=${workflowId}`,

      );
      if (addInterestPD.status !== 200) {

        alert(formatMessage({ id: 'T1235' })) //관심 저장 실패
      }
      toggleHeart();
    } else if (taskYn === 'N') {
      const delInterestPD = await fetchAbsolute(
        "get",
        `/workflow/boardRcmdcountDelHF?workflowId=${workflowId}`,
      );
      if (delInterestPD.status !== 200) {
        alert(formatMessage({ id: "T1241" })); // 관심 삭제 실패 
      }
      toggleHeart();
    }

  };

  const columns = [
    // 하트 -> 관심업무
    {
      key: 'interestGbn',
      title: "",
      width: 35,
      dataIndex: "interestGbn",
      render: (interestGbn, record) => {
        return (
          <span>
            {
              <HeartFilled
                onClick={() => onClickHeartButton(record)}
                data-workflowId={record.workflowId}
                style={{
                  color: interestGbn === "Y" ? "#ff7a00" : "#eaeaed",
                }}
              />
            }
          </span>
        );
      },
    },
    {
      key: 'workNm',
      title: formatMessage({ id: "T0340" }), // 업무분류
      dataIndex: "workNm",
      dataSort: "workNm",
      width: 100,
      sorter: (record1) => {
        return record1.workNm;
      },
      sortOrder: sortedInfo.columnKey === 'workNm' && sortedInfo.order,
      render: (workNm, record) => {
        return (
          <Tooltip title={record.workPathNm}>
            <Link to={`/bizflow/hfworkflow/lookup/${record.workflowId}`}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {workNm}
              </div>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      key: 'workflowId',
      title: formatMessage({ id: "T1316" }), // 업무 ID
      dataIndex: "workflowId",
      dataSort: "workflowId",
      width: 100,
      sorter: (record1) => {
        return record1.workflowId;
      },
      sortOrder: sortedInfo.columnKey === 'workflowId' && sortedInfo.order,
      render: (workflowId, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to={`/bizflow/hfworkflow/lookup/${record.workflowId}`}>
              <div
                style={{
                  width: 356,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {workflowId}
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      key: 'workflowNm',
      title: formatMessage({ id: "T1237" }), // 업무명
      dataIndex: "workflowNm",
      dataSort: "workflowNm",
      width: 200,
      sorter: (record1) => {
        return record1.workflowNm;
      },
      sortOrder: sortedInfo.columnKey === 'workflowNm' && sortedInfo.order,
      render:
        (workflowNm, record) => {
          return (
            <Tooltip title={workflowNm}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Link
                  to={`/bizflow/hfworkflow/lookup/${record.workflowId}`}
                >
                  <div
                    style={{
                      width: 200,
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {workflowNm}
                  </div>
                </Link>
                <div style={{ width: 20 }}>
                  {/* prod */}
                  {record.mgrOpYn === "Y" && (
                    <PlayCircleFilled
                      style={{
                        color: "#ff7a00",
                        fontSize: 20,
                      }}
                      onClick={() => onClickOperation(record)}
                    />
                  )}
                </div>
              </div>
            </Tooltip>
          );
        },
    },
    {
      key: 'companyNm',
      title: formatMessage({ id: "T1500" }), //회사명 
      width: 100,
      dataIndex: "companyNm",
      dataSort: "companyNm",
      sorter: (list) => {
        return list.companyNm;
      },
      sortOrder: sortedInfo.columnKey === 'companyNm' && sortedInfo.order,
      render: (companyNm, record) => {
        return (
          <Tooltip title={companyNm}>
            <Link to={`/bizflow/hfworkflow/lookup/lookup/${record.taskId}`}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {companyNm}
              </div>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      key: 'mainOrgNm',
      title: formatMessage({ id: "T1261" }), //관련 부서
      dataIndex: "mainOrgNm",
      dataSort: "mainOrgNm",
      width: 100,
      sorter: (list) => {
        return list.mainOrgNm;
      },
      sortOrder: sortedInfo.columnKey === 'mainOrgNm' && sortedInfo.order,
      render: (mainOrgNm, record) => {
        return (
          <Tooltip title={mainOrgNm}>
            <Link to={`/bizflow/hfworkflow/lookup/lookup/${record.taskId}`}>
              <div className="orgNm_section">{mainOrgNm}</div>
            </Link>
          </Tooltip>
        );
      },
    },

    {
      key: 'mainEmpNm',
      title: formatMessage({ id: "T0456" }), //담당자
      dataIndex: "mainEmpNm",
      dataSort: "mainEmpNm",
      width: 80,
      sorter: (list) => {
        return list.mainEmpNm;
      },
      sortOrder: sortedInfo.columnKey === 'mainEmpNm' && sortedInfo.order,
      render: (mainEmpNm, list) => {
        const tmpSpan = document.createElement("span");
        tmpSpan.innerText = mainEmpNm;
        tmpSpan.style.fontSize = 12;
        document.body.appendChild(tmpSpan);
        const width = tmpSpan.offsetWidth;
        const style = {};
        if (width > 50) {
          style.width = 48;
          style.paddingRight = 2;
        }
        tmpSpan.remove();
        return (
          <Tooltip title={list.mainEmpNms}>
            <div className="people_section">
              <div className="main" style={{ ...style }}>
                <UserContext
                  profile={{
                    NAME_KOR: mainEmpNm,
                    EMP_NO: list.mainEmpNo,
                    DEPT_NAME_KOR: list.mainOrgNm,
                  }}
                  type="mgr"
                  contextVisible={true}
                >
                  <Text type="context" style={{ fontSize: 12 }}>
                    {mainEmpNm}
                  </Text>
                </UserContext>
              </div>
              {+list.mainEmpNmsCnt !== 0 && (
                <Text style={{ fontSize: 12 }}>({+list.mainEmpNmsCnt})</Text>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    //개발자
    {
      key: 'devEmpNm',
      title: devEmpNm,
      dataIndex: "devEmpNm",
      dataSort: "devEmpNm",
      width: 80,
      sorter: (list) => {
        return list.devEmpNm;
      },
      sortOrder: sortedInfo.columnKey === 'devEmpNm' && sortedInfo.order,
      render: (devEmpNm, list) => {
        const tmpSpan = document.createElement("span");
        tmpSpan.innerText = devEmpNm;
        tmpSpan.style.fontSize = 12;
        document.body.appendChild(tmpSpan);
        const width = tmpSpan.offsetWidth;
        const style = {};
        if (width > 50) {
          style.width = 48;
        } else {
          style.paddingRight = 2;
        }
        tmpSpan.remove();
        return (
          <Tooltip title={list.devEmpNms}>
            <div className="people_section">
              <div className="main" style={{ ...style }}>
                <UserContext
                  profile={{
                    NAME_KOR: devEmpNm,
                    EMP_NO: list.devEmpNo,
                    DEPT_NAME_KOR: list.devOrgNm,
                  }}
                  type="dev"
                  contextVisible={true}
                >
                  <Text type="context" style={{ fontSize: 12 }}>
                    {devEmpNm}
                  </Text>
                </UserContext>
              </div>
              {+list.devEmpNmsCnt !== 0 && list.devEmpNmsCnt && (
                <Text style={{ fontSize: 12 }}>({list.devEmpNmsCnt})</Text>
              )}
            </div>
          </Tooltip>
        );
      },
    },

    {
      key: 'statusNm',
      title: formatMessage({ id: "T1584" }), //업무상태
      width: 95,
      dataIndex: "statusNm",
      dataSort: "statusNm",
      sorter: (list) => {
        return list.statusNm;
      },
      sortOrder: sortedInfo.columnKey === 'statusNm' && sortedInfo.order,
      render: (statusNm, record) => {
        return (
          <Link to={`/bizflow/hfworkflow/lookup/lookup/${record.taskId}`}>
            <div>{statusNm}</div>
          </Link>
        );
      },
    },
    {
      key: 'regTimestamp',
      title: formatMessage({ id: "T0394" }), // 등록일
      width: 100,
      dataIndex: "regTimestamp",
      dataSort: "regTimestamp",
      sorter: (record1) => {
        return record1.regTimestamp;
      },
      sortOrder: sortedInfo.columnKey === 'regTimestamp' && sortedInfo.order,
      render: (regTimestamp, record) => {
        return (
          <Link to={`/bizflow/hfworkflow/lookup/${record.workflowId}`}>
            <div>{regTimestamp}</div>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    const keys = Object.keys(searchQuery);
    const initialKeys = Object.keys(initialPageInfo);
    const pageObj = { ...searchQuery };

    keys.forEach((key) => {
      if (!initialKeys.includes(key)) {
        delete pageObj[key];
      }
    });
    setPageInfo({
      ...pageObj,
    });
  }, [searchQuery]);

  useEffect(() => {
    if (Object.keys(runInfo).length === 0) return;
    dispatch(loadWorkflowData(runInfo.workflowId));
  }, [runInfo])

  if (tabsValue) {
    return (
      <Wrapper>
        <Tabs
          defaultActiveKey={tabsValue}
          activeKey={tabsValue}
          onChange={onChangeTab}
        >
          <TabPane
            tab={`${formatMessage({ id: 'T1238' })} (${tableTotal.all || 0})`} // 전체 업무
            key="all"
          />
          <TabPane
            tab={`${formatMessage({ id: 'T1239' })} (${tableTotal.myTask || 0
              })`} // 내 업무
            key="myTask"
          />
          <TabPane
            tab={`${formatMessage({ id: 'T1240' })} (${tableTotal.interest || 0
              })`} // 관심 업무
            key="interest"
          />

        </Tabs>
        <Row gutter={[8, 24]}>
          <Col span={8}>
            <span>
              {selectRowPerPage} &emsp;
              <Select
                name="pagination"
                defaultValue={pageSize[0]}
                onSelect={onSelectOption}
                value={+searchQuery.size}
                style={{
                  width: 90,
                }}
              >
                {
                  pageSize.map((v, index) => (
                    <Option key={index} value={v}>{fmMessage({ id: 'T1228', values: { number: v } })}</Option>
                  ))
                }
              </Select>
            </span>
          </Col>
          <Col span={8}></Col>
          <Col
            span={8}
            style={{
              textAlign: "right",
            }}
          >
            <PermissionComponent type="user" fnCode="FN01">
              <Link to="/bizflow/hfworkflow/enter">
                <Button
                  style={{
                    width: 150,
                    height: 40,
                  }}
                  type="primary"
                >
                  <FormattedMessage id="T0050" />{/* 등록 */}
                </Button>
              </Link>
            </PermissionComponent>
            <Button
              style={{
                width: 150,
                height: 40,
                marginLeft: 25,
              }}
              onClick={onClickExcelButton}
              disabled={buttonDisabled}
            >
              <FormattedMessage id="T0041" />{/* 엑셀 다운로드 */}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Table
              rowKey={(item) => item.workflowId}
              columns={columns}
              dataSource={tableData}
              pagination={true}
              defaultPageSize={20}
              pageSizeVisible={false}
              onChange={onChangeTableColumns}
              showSorterTooltip={true}
              loading={tableLoading}
              pageOptions={{
                total: tableTotal[tabsValue],
                current: +searchQuery.page + 1,
                pageSize: +searchQuery.size,
              }}
            />
          </Col>
        </Row>
        <Modal
          centered
          visible={asapConfirmVisible}
          width={500}
          okText={formatMessage({ id: "T1125" })} //실행
          title={formatMessage({ id: "T0458" })} //과제 즉시 실행
          onOk={onOkAsapRun}
          onCancel={() => setAsapConfirmVisible(false)}
        >
          <Text>
            <FormattedMessage
              id="T1593"
              values={{
                workflowName: runInfo.workflowNm,
                workflowId: runInfo.workflowId,
              }}
            />
            {/* 과제를 즉시 실행하시겠습니까? */}
          </Text>
        </Modal>
      </Wrapper>
    );
  } else {
    return <Spin />;
  }
};

export default withRouter(HFWorkflowListBottom);