import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import { Table, Tabs, Button } from "components";
import { Tabs as AntTabs, Tooltip } from "antd";
import { RightOutlined } from "@ant-design/icons";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";

const { TabPane } = AntTabs;

/**
 * 메인화면 > 커뮤니티
 *
 */

const initialPageInfo = {
  sort: "regTimestamp",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 5,
  total: 0
};

const MainCommunity = ({ history }) => {
  const { formatMessage } = useIntl();

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const columns = [
    {
      key: 'boardTitle',
      title: formatMessage({ id: "T0017" }),  // 제목
      dataIndex: "boardTitle",
      width: 200,
      render: (text, value) => {
        return (
          <Link
            to={`/community/${tabsValue}/${value.boardSeq}/lookup`}
          >
            <Tooltip title={text}>
              <span>{text}</span>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      key: 'regEmpNam',
      title: formatMessage({ id: "T0018" }),  // 작성자
      dataIndex: "regEmpNam",
      width: 50,
      render: (text, value) => {
        return (
          <Link
            to={`/community/${tabsValue}/${value.boardSeq}/lookup`}
          >
            <div>{text}</div>
          </Link>
        );
      },
    },
    {
      key: 'regTimestamp',
      title: formatMessage({ id: 'T0409' }), // 작성일
      dataIndex: "regTimestamp",
      width: 50,
      render: (text, value) => {
        return (
          <Link
            to={`/community/${tabsValue}/${value.boardSeq}/lookup`}
          >
            <div>{moment(text).format("YYYY-MM-DD")}</div>
          </Link>
        );
      },
    },
  ];

  const [datas, setDatas] = useState([]);
  const [tabsValue, setTabsValue] = useState(1);
  const [showBoardList, setShowBoardList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const onChangeTab = (tabsValue) => setTabsValue(tabsValue);

  // 더보기 기능
  const onClickMoreButton = () => {
    history.push(`/community/${tabsValue}/list`);
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'regTimestamp';
    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    getBoardList(searchObj);
  };

  const getBoardList = async (page) => {
    let boardId = tabsValue;
    if (page === undefined) {
      page = { ...pageInfo }
    };
    if (tabsValue === 1) boardId = showBoardList[0]?.boardId;
    else boardId = tabsValue;
    setTableLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/board/boardList?boardId=${boardId}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setTableLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다. 
    setDatas([...result.data.boardList]);
  };

  useEffect(() => {
    let condition;
    const getBoardMaList = async () => {
      setTableLoading(true);
      // 전체 목록이라
      const result = await fetchAbsolute("get", `/board/boardMaList?&page=0&size=&sort=`);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      condition = result.data.boardMaList.filter((v) => v.showMainYn === "Y" && v.useYn === "Y").sort((a, b) => a.menuOrder - b.menuOrder);
      setTableLoading(false);
      setShowBoardList([...condition]);
      setTabsValue(condition[0]?.boardId);
    };
    getBoardMaList();
  }, []);

  useEffect(() => {
    if(tabsValue===1) return;
    getBoardList();
  }, [tabsValue]);

  return (
    <Wrapper>
      <div className="common__container">
        <div className="common__div">
          <div className="common__title">{formatMessage({ id: 'T0387' })}</div>{/* 커뮤니티 */}
          <Button className="float_display__button" onClick={onClickMoreButton}>
            {formatMessage({ id: 'T0096' })}{/* 더보기 */}
            <RightOutlined />
          </Button>
        </div>
        <Tabs
          defaultActiveKey={
            tabsValue === 1 ? showBoardList[0]?.boardId : tabsValue
          }
          activeKey={tabsValue}
          onChange={onChangeTab}
        >
          {showBoardList.map((item) => {
            return (
              <TabPane
                tab={item.boardNm + `(${item.boardCnt})`}
                key={item.boardId}
              />
            );
          })}
        </Tabs>
        <Table
          columns={columns}
          dataSource={datas}
          paginaton="false"
          defaultPageSize={5}
          rowKey={(item) => item.boardSeq}
          locale={{ emptyText: `${formatMessage({ id: 'T0410' })}` }} // 아직 등록된 글이 없습니다.
          onChange={onChangeTableColumns}
          loading={tableLoading}
          pageOptions={{
            total: +pageInfo.total,
            current: +pageInfo.currentPage,
            pageSize: +pageInfo.rowPerPage,
          }}
        />
      </div>
    </Wrapper>
  );
};

export default withRouter(MainCommunity);