import React from 'react'
import { useIntl, FormattedMessage } from "react-intl";
import { Text, Button } from 'components';
import UseIternalMenu from './UseIternalMenu';
import UseIternalSystem from './UseIternalSystem';
import useFormatMessage from 'hooks/useFormatMessage';
import { Divider } from 'antd';

/**
 * 관리자 > 네부 시스템 메뉴 관리 > 내부 시스템 메뉴 등록 / 수정 페이지
 * 
 */
const IternalSystemManage = ({ addSystemView, onClickCancelButton, onClickSubmit, inputValues, setInputValues, onChangeValues, onClickDeleteSystem, menuInsertInfo, setMenuInsertInfo, classificationList, onChangeClassification, requireFields, rowData, selectDatas, setSelectDatas }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  return (
    <div className="common__container">
      <div className=''>
        {
          addSystemView ?
            <Text className="iternal_system__title">
              {fmMessage({ id: 'T1265', values: { name: formatMessage({ id: 'T1302' }) } })} {/* 내부 시스템 메뉴 등록 */}
            </Text>
            :
            <Text className="iternal_system__title">
              {fmMessage({ id: 'T1264', values: { name: formatMessage({ id: 'T1302' }) } })} {/* 내부 시스템 메뉴 수정 */}
            </Text>
        }
      </div>
      <div className='component__section'>
        <div>
          <UseIternalSystem
            inputValues={inputValues}
            setInputValues={setInputValues}
            onChangeValues={onChangeValues}
            classificationList={classificationList}
            onChangeClassification={onChangeClassification}
            requireFields={requireFields}
          />
        </div>
        <Divider />
        <div>
          <UseIternalMenu
            menuInsertInfo={menuInsertInfo}
            setMenuInsertInfo={setMenuInsertInfo}
            rowData={rowData}
            selectDatas={selectDatas}
            setSelectDatas={setSelectDatas}
          />
        </div>
      </div>
      <div className="button_company">
        {addSystemView ? (
          <Button type="primary" onClick={onClickSubmit}>
            <FormattedMessage id="T0049" />{/* 추가 */}
          </Button>
        ) : (
          <>
            <Button type="primary" onClick={onClickSubmit}>
              <FormattedMessage id="T0054" />{/* 수정 */}
            </Button>
            <Button type="default" onClick={onClickDeleteSystem}>
              <FormattedMessage id="T0072" />
              {/* 삭제 */}
            </Button>
          </>
        )
        }

        <Button
          type="default"
          onClick={onClickCancelButton}
        >
          {formatMessage({ id: "T0051" })}{/* 취소 */}
        </Button>
      </div>
    </div>
  )
}
export default IternalSystemManage;