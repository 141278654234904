import React from "react";
import { useIntl } from "react-intl";
import { Divider, Typography, Select } from 'antd';
import { Bar } from '@ant-design/charts';
import useFormatMessage from "hooks/useFormatMessage";
import { Empty } from 'components';

const { Option } = Select;

const { Title } = Typography;

/**
 *  대시보드 > 절감시간현황 > 과제별 절감 시간
 * 
 */

const TimeSavingTask = ({ taskConfig, taskData, pageSize, savingTaskCondition, onSelectTop }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    return (
        <div className='box__container'>
            <div className='bot__title'>
                <Title
                    className='main__title'
                    level={4}
                >
                    {formatMessage({ id: "T1518" })}{/* 과제별 절감 시간 */}
                </Title>
                <div className="condition">
                    <div>{formatMessage({ id: "T1520" })}</div>{/* 절감시간TOP */}
                    <div>
                        <Select
                            name="savingTimeTop"
                            defaultValue={savingTaskCondition.rowPerPage}
                            style={{ width: 89, height: 32, fontSize: 12 }}
                            onSelect={onSelectTop}
                        >
                            {pageSize.map((v, index) => (
                                <Option key={index} value={v}>
                                    {fmMessage({ id: "T1228", values: { number: v } })}   {/* number(개) */}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            </div>
            <Divider />
            {
                taskData.length > 0 ?
                    <Bar {...taskConfig} className="dashboard_graph" data={taskData} style={{ height: "300px" }} /> : <Empty />
            }
        </div>
    )
}

export default TimeSavingTask