import React from 'react';
import styled from 'styled-components';
import { DatePicker, TimePicker } from 'antd';
import './common.css';

/**
 * Filename: TypePicker.js
 * Description: Date, Time Picker 공통 컴포넌트
 */

const DateWrapper = styled(DatePicker)`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;
const TimeWrapper = styled(TimePicker)`
    width: ${props => props.width}px;
    height: ${props => props.height}px;
`;

const TypePicker = ({ defaultValue, width = 200, height = 40, value, type='date', onChange=()=>{}, format='', innerRef, ...args }) => {
    if(type === 'date') {
        return (
            <DateWrapper 
                width={width}
                height={height}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                format={format}
                ref={innerRef}
                {...args}
            />
        );
    } else if (type === 'time') {
        return (
            <TimeWrapper 
                width={width}
                height={height}
                defaultValue={defaultValue}
                value={value}
                onChange={onChange}
                format={format}
                ref={innerRef}
                {...args}
            />
        );
    }
};

export default TypePicker;