import React from "react";
import { Divider, Typography, Badge } from 'antd';
import { Empty } from 'components';
import { useIntl } from "react-intl";
import { Column } from '@ant-design/plots';

const { Title } = Typography;

/**
 * 대시보드 > 절감 시간 현황 > 조직별 비교
 */

const OrgComparison = ({ orgData, orgConfig }) => {
    const { formatMessage } = useIntl();

    return (
        <div className='box__container'>
            <div className='bot__title'>
                {/* 조직별 비교 */}
                <Title
                    className='main__title'
                    level={4}
                >
                    {formatMessage({ id: "T1519" })}
                </Title>
                <div className='condition'>
                    <div><Badge color="#ff6600" text={`${formatMessage({ id: "T1521" })}(hr)`} /></div>{/* 절감시간 */}
                    <div><Badge color="#ebebeb" text={`${formatMessage({ id: "T1546" })}(${formatMessage({ id: "T1537" })})`} /></div>{/* 과제건수(건) */}
                </div>
            </div>
            <Divider />
            {
                orgData.length > 0 ?
                    <Column data={orgData} {...orgConfig} className="dashboard_graph" style={{ height: 300, width: `calc(100px * ${orgData.length})`, minWidth: '100%' }} /> : <Empty />
            }
        </div>
    )
}

export default OrgComparison