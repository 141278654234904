import React, { useState, useEffect } from "react";
import { Col, Row, Select } from "antd";
import { Table, Button } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

/**
 * Filename: RoundList/index.js 
 * Description:RoundList
 * Item Shop 관리 > 회차 관리 목록
 */

const initialPageInfo = {
  sort: "roundFr",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 10,
  total: 0
};

const { Option } = Select;

const RoundList = ({ history }) => {
  const { formatMessage } = useIntl();

  const fmMessage = useFormatMessage();

  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const onClickAdd = () => {
    return history.push(`/admin/jediitem/roundcreate`);
  };

  const onRow = (record) => {
    return {
      onClick: () => {
        history.push({
          pathname: `/admin/jediitem/roundupdate/${record.roundSeq}`,
        });
      },
    };
  };

  //MJ 추가
  const pageSize = [10, 20, 50, 70];

  const onSelectOption = (value) => {
    const searchObj = {
      ...pageInfo,
      page: 1,
      rowPerPage: +value,
    };
    fetchRoundList({ ...searchObj });
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'roundFr';
    let orderBy = "";
    if (!sorter.order) {
      order = "roundFr"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    fetchRoundList(searchObj);
  };

  const fetchRoundList = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    setTableLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/academy/manageRoundsViewList?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setTableLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setDataSource([...result.data.manageRoundList]);
    setPageInfo({ ...page, total: result.data.totalItemsCount });
  };

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: 'T0823' }), // 회차명
      dataIndex: "round",
      sorter: (a, b) => a.round,
      width: 100,
      align: "center",
    },
    {
      key: 2,
      title: formatMessage({ id: 'T0100' }), // 설명
      dataIndex: "note",
      align: "center",
      sorter: (a, b) => a.note,
      width: 150,
    },
    {
      key: 3,
      title: formatMessage({ id: 'T0824' }), // 기간
      dataIndex: "roundFr",
      sorter: (a, b) => a.roundFr,
      align: "center",
      width: 100,
      render: (value, row, index) => (
        <>
          <div>
            {moment(value).format("YYYY-MM-DD")} ~&nbsp;
            {moment(row.roundTo).format("YYYY-MM-DD")}
          </div>
        </>
      ),
    },
    {
      key: 4,
      title: formatMessage({ id: 'T0819' }), // 노출여부
      dataIndex: "showYn",
      align: "center",
      sorter: (a, b) => a.showYn,
      width: 70,
    },
  ];

  useEffect(() => {
    fetchRoundList();
  }, []);

  return (
    <div className="realtime__content_box">
      <Row gutter={[8, 24]} className="align-end">
        <Col span={6}>
          <span>
            {/*  페이지당 행 */}
            {formatMessage({ id: "T0036" })} &emsp;
            <Select
              name="pagination"
              defaultValue={pageSize[0]}
              value={+pageInfo.rowPerPage}
              onSelect={onSelectOption}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                </Option>
              ))}
            </Select>
          </span>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {/* <div className="realtime__table_wrap"> */}
          <Table
            className="table_content"
            columns={columns}
            dataSource={dataSource}
            pagination={true}
            showSorterTooltip={true}
            onRow={onRow}
            rowKey={(item) => item.roundSeq}
            onChange={onChangeTableColumns}
            loading={tableLoading}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.rowPerPage,
            }}
          />
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 20,
              }}
            >
              <Button width="144" height="40" type="primary" onClick={onClickAdd}>
                {formatMessage({ id: 'T0049' })}{/* 추가 */}
              </Button>
            </div>
          </div>
          {/* </div> */}
        </Col>
      </Row>
    </div>
  );
};

export default RoundList;
