import React, { useState, useEffect } from "react";
import { PageTemplate, Header, Footer, Tabs } from "components";
import { useIntl } from "react-intl";
import Wrapper from "./Styled";
import RoundList from "./JediAdminItemShopTabMenu/RoundList";
import RoundItemManagement from "./JediAdminItemShopTabMenu/RoundItemManagement";
import RoundPurchaseHistory from "./JediAdminItemShopTabMenu/RoundPurchaseHistory";
import RoundListCreate from "./JediAdminItemShopTabMenu/RoundListCreate";
import RoundListUpdate from "./JediAdminItemShopTabMenu/RoundListUpdate";
import RoundItemUpdate from "./JediAdminItemShopTabMenu/RoundItemUpdate";
import RoundItemAdd from "./JediAdminItemShopTabMenu/RoundItemAdd";

const { TabPane } = Tabs;

const JediAdminItemShop = ({
  history,
  location: { pathname },
  match: { params },
}) => {
  const { formatMessage } = useIntl();

  const [tabsValue, setTabsValue] = useState("");

  const onChangeTab = (key) => {

    history.push(`/admin/jediitem/${key}`);
  };

  useEffect(() => {
    if (!params.tabValue) {
      history.replace(`/admin/jediitem/roundlist`);
    }
    switch (params.tabValue) {
      case "roundcreate":
      case "roundupdate":
        setTabsValue("roundlist");
        break;
      case "rounditemupdate":
      case "rounditemadd":
        setTabsValue("rounditemmanage");
        break;
      default:
        setTabsValue(params.tabValue);
    }
  }, [params.tabValue]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
        <Wrapper>
          {tabsValue !== "" && (
            <Tabs
              type="card"
              custom={false}
              activeKey={tabsValue}
              defaultActiveKey={tabsValue}
              onChange={onChangeTab}
            >
              <TabPane tab={formatMessage({ id: 'T0820' })} key="roundlist">
                {params.tabValue === "roundlist" ? (
                  <RoundList history={history} pathname={pathname} />
                ) : params.tabValue === "roundcreate" ? (
                  <RoundListCreate history={history} pathname={pathname} />
                ) : (
                  params.tabValue === `roundupdate` && (
                    <RoundListUpdate
                      history={history}
                      pathname={pathname}
                      roundSeq={params.roundSeq}
                    />
                  )
                )}
              </TabPane>
              <TabPane tab={formatMessage({ id: 'T0821' })} key="rounditemmanage">

                {params.tabValue === 'rounditemmanage' ?
                  <RoundItemManagement history={history} pathname={pathname} />
                  : params.tabValue === 'rounditemadd' ?
                    <RoundItemAdd history={history} pathname={pathname} />
                    : params.tabValue === `rounditemupdate` &&
                    <RoundItemUpdate history={history} pathname={pathname} seq={params.roundSeq} />
                }
              </TabPane>
              <TabPane tab={formatMessage({ id: 'T0822' })} key="roundhistory">
                <RoundPurchaseHistory />
              </TabPane>

            </Tabs>
          )}
        </Wrapper>
    </PageTemplate>
  );
};

export default JediAdminItemShop;
