import React from "react";
import { DatePicker } from "antd";
import styled from "styled-components";
import './common.css';

const { RangePicker } = DatePicker;

const PickerStyled = styled(RangePicker)`
   /* 우리카드 */
  /* .ant-picker-cell.ant-picker-cell-range-start .ant-picker-cell-inner {
      background: #0067ac;
  } */

  /* 기존 */
  .ant-picker-cell.ant-picker-cell-range-start .ant-picker-cell-inner {
      background: #ff7a00;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
    border-radius: 2px 0 0 2px;
  } 
  .ant-picker-input > input {
    font-size: 14px;  
  } 
`;

const RPicker = ({ children, innerRef, ...args }) => {
  return <PickerStyled ref={innerRef} {...args}>{children}</PickerStyled>;
};

export default RPicker;
