import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { CommentIcon, DeleteIcon, EditIcon } from "icons";
import { Button, UserTag, Text } from "components";
import { Input as AntInput } from "antd";
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';


const { TextArea } = AntInput;

const CommunityBottom = ({
  profile,
  onChangeComment,
  onClickCancelEdit,
  onClickEditIcon,
  onClickAddComment,
  onClickDelComment,
  onClickEditComment,
  onChangeEditComment,
  isEdit,
  commentList,
  commentInfo,
  userAuth,
  originByte,
  commentByte
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  return (
    <div className="bottom_container">
      <div className="top_comment_section">
        <div className="comment">
          <TextArea
            placeholder={formatMessage({ id: 'T0882' })} // 댓글을 입력하세요.
            style={{ width: "100%" }}
            allowClear
            value={commentInfo.content}
            onChange={onChangeComment}
            rows={4}
          />
          <div className="comment_btn_company">
            <Button
              type="primary"
              className="comment_btn"
              onClick={onClickAddComment}
            >
              <CommentIcon />
              {formatMessage({ id: 'T0088' })}{/* 댓글 등록 */}
            </Button>
            <div>
            [{<span id="nowByte" style={{ color: '#000000DE' }}>{commentByte}</span>}/<Text>2000] byte</Text>
            </div>
          </div>
        </div>
      </div>
      {commentList.length > 0 && (
        <>
          <div className="bottom_top_section">
            <div className="comment_alert">
              {/* 댓글 {commentList.length}개 */}
              {fmMessage({ id: 'T0883', values: { number: commentList.length } })}

              <CommentIcon
                className="comment_mini"
                style={{ fill: "#fff", strokeWidth: "1px" }}
              />
            </div>
          </div>
          <div className="comment_container">
            {commentList.map((v, index) => (
              <div className="comment__info" key={v.commentSeq}>
                <div className="comment_first_row">
                  <UserTag
                    style={{ alignItem: "center" }}
                    contextMenu={false}
                    profile={{
                      NAME_KOR: v.regEmpNam,
                      EMP_NO: v.regId,
                      DEPT_NAME_KOR: v.regOrgNam,
                      FILE_ID: v.regFileId,
                    }}
                  />
                  <Text
                    style={{
                      color: "rgba(0, 0, 0, 0.3)",
                      fontSize: 12,
                      alignSelf: "unset",
                    }}
                  >
                    {moment(v.modTimestamp).format("YYYY.MM.DD HH:mm")}
                  </Text>
                  {(v.regId === profile.EMP_NO ||
                    userAuth === "UA06" ||
                    userAuth === "UA05") && (
                      <div className="icons">
                        <DeleteIcon
                          onClick={() => onClickDelComment(v.commentSeq)}
                          style={{ paddingTop: 2 }}
                        />
                        <EditIcon onClick={() => onClickEditIcon(index)} />
                      </div>
                    )}
                </div>
                <div className="comment_second_row">
                  {isEdit[index] ? (
                    <div className="edit__version">
                      <TextArea
                        style={{ width: 1000 }}
                        value={v.commentContents}
                        onChange={(e) => onChangeEditComment(e, v.commentSeq)}
                        rows={4}
                      />
                      <div className="edit_comment_btn__section">
                        <div className="btns">
                          <Button
                            onClick={() =>
                              onClickEditComment(v.commentSeq, index)
                            }
                          >
                            {formatMessage({ id: 'T0073' })}{/* 저장 */}
                          </Button>
                          <Button onClick={() => onClickCancelEdit(index)}>
                            {formatMessage({ id: 'T0051' })}{/* 취소 */}
                          </Button>
                        </div>
                        <div>
                          [{<span id="commentByte" style={{ color: '#000000DE' }}>{originByte}</span>}/<Text>2000] byte</Text>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Text>
                      {v.commentContents?.replace(/ /g, "\u00a0").split("\n").map((line) => {
                        return (
                          <>
                            {line}
                          </>
                        );
                      })}
                    </Text>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default withRouter(CommunityBottom);