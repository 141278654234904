import React, { useState } from 'react';
import { Table, Text, Spin } from 'components';
import { Select } from 'antd';
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import fetchAbsolute from "utils/fetchAbsolute";
import StatusInActive from './StatusInActive';
import moment from "moment";
const { Option } = Select;


const RealtimeTable = ({ dataSource = [], total = 0, currentPage = 1, rowPerPage = 20, onSelectPageSize, tableInfo, setTableInfo, tableType }) => {
    const { formatMessage } = useIntl();

    const fmMessage = useFormatMessage();
    const currentDate = moment().format("YYYYMMDD");
    const selectRowPerPage = formatMessage({ id: "T0036" });  // 페이지당 행  

    const [loading, setLoading] = useState(false)
    const [visible, setVisible] = useState(false);
    const [botStatusInfo, setBotStatusInfo] = useState([]);
    const [imgInfo, setImgInfo] = useState([]);

    const fetchExcute = async (record) => {
        const result = await fetchAbsolute('get', `/agent/sendAgentSocket?hostName=${record}`);
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
    }

    const fetchData = async (record) => {
        const result = await fetchAbsolute('post', `/log/botLog/saveRunnerAgentStateList?hostName=${record}`);
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
        setBotStatusInfo(result.data[0]);
    }
    const fetchImg = async (record) => {

        const result = await fetchAbsolute('get', `/monitoring/currentFileList?hostName=${record}&strDate=${currentDate}`);
        if (result.data.resultCode === "F") {
            setVisible(false);
            return alert("이미지를 가져오는데 실패하였습니다.");
        }
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }

        setImgInfo(result.data.fileList[result.data?.fileList.length - 1]);
    }

    const onCancelStatusInActive = () => {
        setBotStatusInfo([]);
        setImgInfo([]);
        setVisible(false);
    }

    const onClickHostname = async (record) => {
        setLoading(true);
        fetchExcute(record);
        setTimeout(function () {
            fetchData(record);
            fetchImg(record);
            setLoading(false);
            setVisible(true);
        }, 5000);

    }


    // const onRow = (record, rowIndex) => {
    //     return {
    //         onClick: () => {
    //             //fetchExcute(record);
    //             fetchData(record);
    //             fetchImg(record);
    //             setVisible(true);
    //         },
    //     };
    // };

    const onChangePage = (pagination, filter, sorter) => {
        let order = sorter.field ? sorter.field : 'hostName';
        let orderBy = "";
        if (!sorter.order) {
            order = "hostName"
            orderBy = ",asc"
        }
        else if (sorter.order?.includes("desc")) orderBy = ",desc";
        else if (sorter.order?.includes("asc")) orderBy = ",asc";
        const searchObj = {
            ...tableInfo,
            sort: order,
            direction: orderBy,
            currentPage: pagination.current
        };
        setTableInfo({ ...tableInfo, ...searchObj });
    }

    const pageSize = [20, 50, 70];

    const columns = [{
        title: 'Host Name',
        dataIndex: 'hostName',
        dataSort: 'hostName',
        key: 'hostName',
        sorter: (a, b) => {
            return a.hostName?.localeCompare(b.hostName)
        },
        render: (record, index) => {
            // if (tableType === "inactive") {
            //     return (
            //         <a
            //             href="#!"
            //             key={index}
            //             className='hostName'
            //             onClick={() => onClickHostname(record)}
            //         >
            //             {record}
            //         </a >
            //     )
            // } else {
            return (
                <div
                    key={index}
                    className='hostName'
                >
                    {record}
                </div>
            )
            // }
        },
    },
    {
        title: 'User Name',
        dataIndex: 'updatedBy',
        dataSort: 'updatedBy',
        key: 'updatedBy',
        sorter: (a, b) => {
            return a.updatedBy?.localeCompare(b.updatedBy)
        }
    },
    {
        title: "CR",
        dataIndex: 'crUrl',
        dataSort: 'crUrl',
        key: 'crUrl',
        sorter: (a, b) => {
            return a.crUrl?.localeCompare(b.crUrl)
        }
    },
    {
        title: "Device Pool",
        dataIndex: 'poolName',
        dataSort: 'poolName',
        key: 'poolName',
        sorter: (a, b) => {
            return a.poolName?.localeCompare(b.poolName)
        }
    }];

    return (
        <div className='realtime__table_wrap'>
            <div className='realtime__table_header'>
                <Text>{selectRowPerPage}</Text>
                <Select
                    style={{
                        width: 89,
                        height: 32,
                    }}
                    className='realtime__table_page'
                    defaultValue={pageSize[0]}
                    value={rowPerPage}
                    onSelect={onSelectPageSize}
                >
                    {
                        pageSize.map((v, index) => (
                            <Option key={index} value={v}>{fmMessage({ id: 'T1228', values: { number: v } })}</Option>
                        ))
                    }
                </Select>
            </div>
            {!!loading && <Spin style={{ position: "absolte", top: "-400px" }} tip='Loading...' />
            }
            {tableType === "inactive" ?
                <Table
                    className='realtime__table_content'
                    columns={columns}
                    dataSource={dataSource}
                    pagination={true}
                    defaultPageSize={20}
                    pageSizeVisible={false}
                    pageOptions={{
                        total: total,
                        current: currentPage,
                        pageSize: rowPerPage,
                    }}
                    onChange={onChangePage}
                //onRow={onRow}
                /> :
                <Table
                    className='realtime__table_content'
                    columns={columns}
                    dataSource={dataSource}
                    pagination={true}
                    defaultPageSize={20}
                    pageSizeVisible={false}
                    pageOptions={{
                        total: +total,
                        current: +currentPage,
                        pageSize: +rowPerPage,
                    }}
                    onChange={onChangePage}

                />
            }
            <StatusInActive botStatusInfo={botStatusInfo} loading={loading} imgInfo={imgInfo} title={botStatusInfo.hostName} visible={visible} onCancel={onCancelStatusInActive} />
        </div>
    );
}

export default RealtimeTable;