import React, { useState } from "react";
import { Row, Col, Select, Checkbox } from "antd";
import {
    Organization,
    Text,
    Button,
    RangePicker,
    Upload,
    Input,
    PermissionComponent
} from "components";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "moment/locale/ko";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import NameTagGroup from "components/atoms/NameTagGroup";

const { Option } = Select;

const END_POINT = process.env.REACT_APP_API_URI;

//수정 가능한 화면
const EditableForm = ({
    onChangeEditAnalyDatas,
    onClickStartDevButton,
    onClickEditDevButton,
    onBeforeupload,
    onRemoveFile,
    editable,
    onClickCancelEditButton,
    editAnalyticalDatas,
    analyticalRequire,
    onAddStatementURL,
    onClickDeleteStatementURL,
    onClickDeleteButton,
    selectOption,
    selectCr,
    goToDiyPrd,
}) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const taskData = useSelector((state) =>state.get(getUniqueKey("assignLookup")).taskData);

    const taskDevId = formatMessage({ id: "T0007" }); // 개발자
    const correctUrl = formatMessage({ id: "T0158" }); // 3. [개발] 개발 진행
    const existUrl = formatMessage({ id: "T0159" }); // 개발 환경 정보를 확인한 후, 개발을 진행합니다.

    const [statementURL, setStatementURL] = useState("");
    const [visibleOrgan, setVisibleOrgan] = useState(false);

    const onChangeCrInfo = (e) => { };
    const onChangeStatementURL = (e) => {
        const { value } = e.target;
        const reg = /[ㄱ-ㅎㅏ-ㅣ가-힣]/g;
        if (reg.test(value)) return alert(fmMessage({ id: 'T1285', values: { field: formatMessage({ id: 'T1286' }) } }));
        // 한글은/을 입력할 수 없습니다.

        setStatementURL(value);
    };
        
    const onPressStatementURL = (e) => {
        if (e.key.toUpperCase() === "ENTER") {
            onClickURLButton();
        }
    };

        const onClickURLButton = () => {
        if (statementURL.length > 200) return;
        const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        if (!statementURL.match(expression)) {
            return alert(correctUrl);
        }
        if (
            editAnalyticalDatas.urlList.findIndex((v) => v.name === statementURL) !== -1
        ) {
            return alert(existUrl);
        }
        setStatementURL("");
        onAddStatementURL(statementURL);
    };
    
    const onChangeSchedule = (e) => onChangeEditAnalyDatas("devSchedule", e);
    
    const onChangeBotFwYn = (e) => {
        let botFwYn = e.target.checked ? "Y" : "N";
        onChangeEditAnalyDatas("botFwYn",botFwYn);
    }
    
    const onClickOrgaButton = () =>  setVisibleOrgan(true);

    
    const onOkOrgan = (data) => {
        const tmpArr = [...data];
        onChangeEditAnalyDatas("developers", tmpArr);
        setVisibleOrgan(false);
    };
    
    const onCancelOrgan = () => setVisibleOrgan(false);

    return (
        <div className="basic_box">
            <div className="basic_box__title">
            <Text>{formatMessage({ id: "T0505" })}</Text>{/* 2. [개발] 분석/설계 */}
            </div>
            {Object.keys(editAnalyticalDatas).length > 0 && (
            <div className="basic_box__info"
            
            >
                <Row>
                <Col span={5}>
                    <Text className="basic_row__title" required>
                    {taskDevId}
                    </Text>
                </Col>
                <Col span={19} className="basic_row__content">
                    <Button
                    innerRef={analyticalRequire.developers.ref}
                    data-label="developers"
                    onClick={onClickOrgaButton}
                    >
                    <FormattedMessage id="T0049" />{/* 추가 */}
                    </Button>
                    <div
                    className="flex flex-wrap-wrap"
                    style={{ marginTop: 8, gap: "8px 22px" }}
                    >
                    {NameTagGroup(
                        editAnalyticalDatas.developers.map((v, index) => ({
                        ...v,
                        isMain: index === 0,
                        })),
                        "developers",
                        onClickDeleteButton
                    )}
                    </div>
                </Col>
                </Row>
                <Row>
                    <Col span={5}>
                        <Text className="basic_row__title">
                            {formatMessage({ id: "T1678" })}{/* Bot F/W 기능 선택 */}
                        </Text>
                        &nbsp;
                        <InfoCircleOutlined style={{color:"red", cursor:"pointer"}}/>
                    </Col>
                    <Col span={19}>
                        {editable
                            ?
                                taskData.botFwYn === "Y"
                                ? 
                                <span>{formatMessage({ id: "T1680" })}</span> // Bot 업무량 분산을 위한 병렬 처리 On
                                :
                                <span>{formatMessage({ id: "T1681" })}</span> // Bot 업무량 분산을 위한 병렬 처리 Off
                            :
                            <>
                                <Checkbox 
                                    onChange={(e)=>onChangeBotFwYn(e)}
                                /> &nbsp;&nbsp;
                                <span>{formatMessage({ id: "T1679" })}</span> {/* Bot 업무량 분산을 위한 병렬 처리 */}
                            </>
                        }
                    </Col>
                </Row>
                <Row>
                <Col span={5}>
                    <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0507" })}{/* 개발 일정 */}
                    </Text>
                </Col>
                <Col span={19}>
                    <RangePicker
                    innerRef={analyticalRequire.devSchedule.ref}
                    style={{ width: 280 }}
                    format="YYYY-MM-DD"
                    name="devSchedule"
                    value={[...(editAnalyticalDatas.devSchedule || [])]}
                    onChange={onChangeSchedule}
                    />
                </Col>
                </Row>
                <Row>
                <Col span={5}>
                    <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0508" })}{/* 개발 CR */}
                    </Text>
                </Col>
                <Col span={19}>
                    <Select
                    style={{ width: 280 }}
                    size="default"
                    value=""
                    defaultValue=""
                    onChange={onChangeCrInfo}
                    placeholder={`${selectOption}`}
                    >
                    {
                        Object.keys(selectCr).length > 0 ?
                        selectCr.map((v, index) => {
                            return (
                            <Option key={index} value="">
                                {v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + "(운영)" : v.crTypeDesc + "(개발)"}
                            </Option>
                            );
                        })
                        :
                        goToDiyPrd.map((v, index) => {
                            return (
                            <Option key={index} value="">
                                {v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + "(운영)" : v.crTypeDesc + "(개발)"}
                            </Option>
                            );
                        })
                    }
                    </Select>
                </Col>
                </Row>
                <Row>
                <Col span={5}>
                    <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0509" })} {/* 업무 기술서 */}
                    </Text>
                </Col>
                <Col span={19}>
                    <div className="statement__box">
                    <div className="flex">
                        <Input
                        value={statementURL}
                        style={{ height: 30 }}
                        onChange={onChangeStatementURL}
                        onKeyPress={onPressStatementURL}
                        innerRef={analyticalRequire.fileList.ref}
                        maxLength={199} // varchar(200) 200까지 가능
                        placeholder={formatMessage({ id: "T0511" })} //URL을 입력하세요
                        />
                        <Button type="primary" onClick={onClickURLButton}>
                        <FormattedMessage id="T0050" />{/* 등록 */}
                        </Button>
                    </div>
                    <div className="statement__box_list">
                        {editAnalyticalDatas.urlList?.map((v, index) => (
                        <div key={index} className="statement__box_link">
                            <Text type="link" link={v.name}>
                            {v.name}
                            </Text>
                            <DeleteOutlined
                            onClick={() => onClickDeleteStatementURL(v.name)}
                            />
                        </div>
                        ))}
                    </div>
                    </div>
                    <div className="statement__box_text">
                    <Text>{formatMessage({ id: "T0512" })}</Text>{/* 또는 */}
                    </div>
                    <Upload
                    action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                    multiple={true}
                    beforeUpload={onBeforeupload}
                    onRemove={onRemoveFile}
                    fileList={editAnalyticalDatas.fileList}
                    />
                </Col>
                </Row>
                <Row>
                <Col span={6} offset={18} className="flex justify-end">
                    {editable ? (
                    <div className="analytical_edit__button_group">
                        <PermissionComponent fnCode="FN02">
                        <Button
                            type="primary"
                            width="120"
                            onClick={onClickEditDevButton}
                        >
                            <FormattedMessage id="T0054" />{/* 수정 */}
                        </Button>
                        </PermissionComponent>
                        <Button
                        type="default"
                        width="120"
                        onClick={onClickCancelEditButton}
                        >
                        <FormattedMessage id="T0051" />{/* 취소 */}
                        </Button>
                    </div>
                    ) : (
                    <PermissionComponent fnCode="FN10">
                        <Button
                        type="primary"
                        width="120"
                        onClick={onClickStartDevButton}
                        >
                        <FormattedMessage id="T0057" />{/* 개발 시작 */}
                        </Button>
                    </PermissionComponent>
                    )}
                </Col>
                </Row>
            </div>
            )}
            <Organization
            visible={visibleOrgan}
            title={formatMessage({ id: "T0510" })} // 개발자 등록
            onOk={onOkOrgan}
            onCancel={onCancelOrgan}
            defaultData={[...(editAnalyticalDatas.developers || [])]}
            msColumn={true}
            />
        </div>
    );
};

export default EditableForm;