import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  .bold {
    font-weight: bold;
  }
  .color_light {
    color: #707070;
  }
  .font_20 {
    font-size: 20px;
  }
  .font_16 {
    font-size: 16px;
  }
  .font_14 {
    font-size: 14px;
  }
  .assign_lookup__header {
    display: flex;
    justify-content: space-between;
  }
  .assign_lookup__header > div {
    width: 48%;
  }
  .assign_lookup__header > .assign_lookup__task_info {
    display: flex;
    flex-direction: column;
    width: 48%;
    gap: 10px 0;
  }
  .assign_lookup__task_info .task_info__box {
    display: flex;
    flex-wrap: wrap;
  }
  .ellipsis_box {
    white-space: pre-line;
    width: 100%;
  }
  .ellipsis_box .ellipsis_text {
    white-space: pre-line;
    overflow-wrap: anywhere;
    width: 89%;
  }
  .ellipsis_button_box {
    position: relative;
  }
  .ellipsis_box .ellipsis_more {
    cursor: pointer;
    width:60px;
    font-weight: bold;
    padding-left: 5px;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-bottom: 1em; 
  }
  /* 과제명, 관심과제 정보 부분 */
  .task_info__box.task_info__top {
    gap: 0 20px;
  }
  .task_info__box.task_info__top .task_info__task_name {
    gap: 0 18px;
    flex: 1;
  }
  .task_info__task_name > span:nth-child(1) {
    flex: 1;
  }
  .task_info__task_name > span:nth-child(2) {
    overflow-wrap: anywhere;
    flex: 6;
  }
  .task_info__task_name > span:nth-child(3) {
    padding-top: 10px;
  }
  .task_info__box.task_info__top .task_info__task_name .task_name__copy {
    transform: scale(1.35);
  } 
  .task_info__tool_box {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0 10px;
  }
  .task_info__top_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: -34px;
  }
  .task_info__tool_box > div {
    display: flex;
    align-items: center;
    padding: 0 11px;
    height: 28px;
    border-radius: 14px;
    gap: 0 5px;
    font-size: 20px;
    transition: 0.4s;
  }
  .icon__group {
    display: flex;
    gap: 30px;
    justify-content: space-between;
    align-items: center;
  }
  .readcnt_box .readcnt_count,
  .interest_box .interest_count {
    font-size: 14px;
  }
  .readcnt_box {
    background: #eee;
  }
  .interest_box:hover {
    transform: scale(1.1);
  }
  .interest_box {
    background: #fff3e8;
  }
  .interest_box .interest_box__icon,
  .interest_empty_box__icon {
    color: #ff7a00;
  }
  .interest_box .interest_count {
    font-size: 14px;
  }
  .readcnt_box {
    background: #eee;
  }
  .interest_box:hover {
    transform: scale(1.1);
  }
  .interest_box {
    background: #fff3e8;
  }
  .interest_box .interest_box__icon,
  .interest_empty_box__icon {
    color: #ff7a00;
  }

  /* 과제 ID, 업무 분류, 과제 유형 부분 */
  .task_info__box.task_info__basic {
    gap: 0 18px;
  }
  .task_info__box.task_info__basic > div span:last-child {
    padding-left: 10px;
  }

  /* 평가 결과 평가 리포트 부분 */
  .task_info__button_group {
    gap: 0 13px;
  }
  .task_info__button_group .button_bar {
    height: 60%;
    width: 1px;
    background: #acacac;
  }

  /* 적용 효과 박스 부분 */
  .task_info__box.task_info__effect {
    height: 40px;
    border: 1px solid #b7b7b7;
    gap: 0 30px;
  }
  .task_info__box.task_info__effect > div > span:last-child {
    padding-left: 8px;
  }
  /* task info 평가 결과 부분 */
  .task_info_table_task_result {
    display: flex;
    gap: 20px;
    padding: 10px 10px;
  }
  .task_info_table_task_result .container {
    position: relative;
    display: flex;
    gap: 0 5px;
    align-items: self-end;
  }
  .task_info_table_task_result .container:first-child::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -10px;
    width: 1px;
    height: 100%;
    background: #0000004d;
  }
  .task_info_table_task_result .container .title {
    color: #acacac;
    font-size: 14px;
  }
  .task_info_table_task_result .container .content {
    color: #E54156;
    display: table-footer-group;
    font-weight: bold;
    font-size: 16px;
  }
  .task_info_table_task_result .container .content_fte {
    color: #1B7BB3;
    display: table-footer-group;
    font-weight: bold;
    font-size: 16px;
  }
  .task_info_table_task_result .report {
    display: flex;
    align-items: flex-end;
  }
  .task_info_table_task_result .report_container {
    background: #eeeeee;
    padding: 5px 16px;
  }
  .task_info_table_task_result .report_container span {
    font-weight: bold;
    font-size: 12px;
    color: #515151;
  }
  /* 테이블 부분 */
  tbody > tr {
    height: 20px;
  }
  tbody > tr > th {
    padding: 10px 16px;
    font-weight: bold;
  }
  tbody > tr > td {
    padding: 0px 10px;
    max-width: 100px;
  }
  /* task status 부분 */
  .assign_lookup__task_status {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .assign_lookup__task_status .task_status__box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;
    padding-right: 45px;
  }
  .task_status__box .task_status__status {
    flex: 0.4 !important;
    padding-left: 10px;
  }
  .task_status__box .task_status__status .task_status__step {
    position: absolute;
    top: 50%;
    left: -5px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #ff6600;
    transform: translateY(-50%);
  }
  .task_status__box:not(:first-child)
    .task_status__status
    .task_status__step::before {
    content: "";
    display: block;
    position: absolute;
    bottom: calc(50% + 5px);
    left: 50%;
    width: 2px;
    height: 19.5px;
    background: #ff6600;
    transform: translateX(-50%);
  }
  .task_status__box:not(:last-child)
    .task_status__status
    .task_status__step::after {
    content: "";
    display: block;
    position: absolute;
    top: calc(50% + 5px);
    left: 50%;
    width: 2px;
    height: 19.5px;
    background: #ff6600;
    transform: translateX(-50%);
  }
  .task_status__box.status_check_point
    .task_status__status
    .task_status__step::after {
    background: #707070;
  }
  .task_status__box.status_check_point
    ~ .task_status__box
    .task_status__status
    .task_status__step::before {
    background: #707070;
  }
  .task_status__box.status_check_point
    ~ .task_status__box
    .task_status__status
    .task_status__step::after {
    background: #707070;
  }
  .task_status__box .task_status__manager {
    display: flex;
    align-items: center;
    flex: 0 0 56%;
  }
  .task_status__box .task_status__manager span.ant-typography.user_tag_text {
    width: 230px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .task_status__box.status_check_point {
    background: #efefef;
  }

  .task_status__box.status_check_point
    ~ .task_status__box
    .task_status__status {
    color: #d0d1d6;
    font-weight: normal;
  }
  .task_status__box.status_check_point
    ~ .task_status__box
    .task_status__status
    .task_status__step {
    background: #fff;
    border: 2px solid #ff6600;
  }
  .task_status__box.status_check_point .task_status__status .task_status__step {
    /* 현재 과제 상태 */
    background: #fff;
    border: 2px solid #ff6600;
  }
  .assign_lookup__task_status .discontinue__container {
    display: flex;
    gap: 10px;
  }
  .discontinue__icon {
    justify-content: space-between;
    color: red;
    font-size: 30px;
  }
  .discontinue__box {
    display: flex;
    flex-direction: column;
    width: 600px;
    gap: 30px;
  }
  .discontinue__box .discontinue__text {
    border: 1px solid #ff0000;
    height: 124px;
    padding: 10px 18px;
    overflow-y: scroll;
  }
  .discontinue__text::-webkit-scrollbar {
    width: 4px;
  }
  .discontinue__text::-webkit-scrollbar-track {
    background: #fff;
  }
  .discontinue__text::-webkit-scrollbar-thumb {
    background: #a8a9ad;
    border-radius: 2px;
  }
  .assign_lookup__task_status .task_status__button_group {
    display: flex;
    justify-content: end;
    gap: 0px 10px;
  }
  .task_status__button_group button {
    width: 30%;
    height: 40px;
  }
  .task_status__notice {
    font-weight: bold;
    align-items: center;
    flex: 44%;
  }
  .task_status__notice_schedule {
    // 스케줄 등록 필요
    font-weight: bold;
    align-items: center;
    padding-left: 10px; 
    flex: 35%;
  }
  .task_status__notice_with_btn {
    // 운영배포 필요
    font-weight: bold;
    align-items: center;
    flex: 49%;
    padding-left: 10px; 
  }
  .task_status__notice_finished {
    font-weight: bold;
    align-items: center;
    flex: 49%;
    padding-left: 10px;
  }
  .task_status_notice__today {
    font-weight: bold;
    align-items: center;
    flex: 22%;
  }
  .task_status_notice__result {
    display: flex;
    gap: 10px;
    font-weight: bold;
  }

  /* Tab 부분 */
  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-nav .ant-tabs-tab {
    margin: 0;
    color: #333;
    font-size: 16px;
  }
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
  }
  .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
    color: #333;
    font-weight: normal;
  }
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    height: 3px;
    background: #ff7a00;
  }
  .transfer_modal__charge {
    display: flex;
    align-items: baseline;
    background: #000;
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    margin: 0;
  }
  .test {
    font-size: 30px;
    background: #000;
  }
  .assign_lookup__disabled_tab {
    opacity: 0.3;
    cursor: default;
  }
`;

export default Wrapper;