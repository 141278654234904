import camelCase from 'lodash/camelCase';
import { getUniqueKey } from 'utils/Str';

/**
 * Application 내 모든 Reducer 들을 수집하는 기능을 담당합니다.
 * 
 * 해당 파일 수정 금지
 */
const configureReducer = () => {
  const reducers = {};
  const req = require.context('.', true, /\.\/.+\/reducer\.js$/);
  req.keys().forEach(key => {
    const reducerName = getUniqueKey(camelCase(key.replace(/\.\/(.+)\/.+$/, '$1')));
    reducers[reducerName] = req(key).default;
  });
  
  return reducers;
};

export default configureReducer;
