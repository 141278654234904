import React, { useEffect, useState } from 'react';
import { Image, FormTable, Button, Text, UserThumbnail } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * 내 정보 뷰 view 화면
 * Header > Avatar > 내 설정
 */

const MyInfo = ({ history, profile, onClickGoCheckPwd }) => {
    const { formatMessage } = useIntl();

    const [idPhoto, setIdPhoto] = useState({});
    const [phoneNumber, setPhoneNumber] = useState('');
    const [loading, setLoading] = useState(false);

    const onClickCancel = () => {
        history.push('/');
    };

    /* 프로필 사진 가지고 오기 */
    useEffect(() => {
        if (!profile.FILE_ID) return;
        const getUserFile = async () => {
            setLoading(true);
            const result = await fetchAbsolute('get', `/file/listFile/${profile.FILE_ID}`);
            setLoading(false);
            if (result.error) {
                return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            }
            setIdPhoto(result.data[0])
        }
        getUserFile();
    }, [profile.FILE_ID]);

    /* 휴대폰 정보 셋팅 */
    useEffect(() => {
        if (!profile.PHONE_NUMBER) return;
        const onlyNumber = profile.PHONE_NUMBER.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
        setPhoneNumber(onlyNumber);
    }, [profile.PHONE_NUMBER]);

    return (
        <div className='common_container_info'>
            <div className='first_section'>
                <FormTable>
                    <colgroup>
                        <col width='13.5%' />
                        <col width='36.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <div className='photo_section'>
                                    <div className='id_photo_part'>
                                        {
                                            Object.keys(idPhoto).length > 0 ?
                                                <Image
                                                    loading='lazy'
                                                    src={idPhoto.link}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: 150
                                                    }}
                                                />
                                                :
                                                <UserThumbnail width={250} />
                                        }
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </FormTable></div>
            <div className='second_section'>
                <FormTable>
                    <colgroup>
                        <col width='16.5%' />
                        <col width='33.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Text>
                                    <FormattedMessage id="T0602" />{/* 이메일 */}
                                </Text>
                            </th>
                            <td>
                                <Text>{profile.EMAIL}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T0012" />{/* 이름 */}</Text>
                            </th>
                            <td>
                                <Text>{profile.NAME_KOR}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T1255" />{/* 전화번호 */}</Text>
                            </th>
                            <td>
                                <Text>{phoneNumber}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T0455" />{/* 부서 */}</Text>
                            </th>
                            <td>
                                <Text>{profile.DEPT_NAME_KOR}</Text>
                            </td>
                        </tr>
                    </tbody>
                </FormTable>
                <div className='admin_manage_btn_wrap'>
                    <Button
                        type='primary'
                        width='150'
                        height='40'
                        onClick={onClickGoCheckPwd}
                    >
                        <FormattedMessage id="T0054" />{/* 수정 */}
                    </Button>
                    <Button
                        type='default'
                        width='150'
                        height='40'
                        onClick={onClickCancel}
                    >
                        <FormattedMessage id="T0051" />{/* 취소 */}
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default MyInfo;