import { SET_CONNECTOR_INFO } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const connectorInfoInitialState = {};

const connectorCateList = (state = connectorInfoInitialState, action) => {
    switch (action.type) {
        case SET_CONNECTOR_INFO:
            return { ...action.data };
        default:
            return state;

    };
};

export default connectorCateList;