import React from "react";
import { Avatar } from "antd";
import { ProfileIcon } from 'icons';
import styled from "styled-components";

const Wrapper = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  overflow: hidden;
  border-radius: 50%; 
  display: flex;
  .user__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: flex; 
  }
`;

const UserThumbnail = ({ width = 25 }) => {
  return (
    <Wrapper width={width}>
      <Avatar
        icon={<ProfileIcon style={{ width, height: width }} />}
        style={{ width, height: width }}
      />
    </Wrapper>
  );
};

export default UserThumbnail;
