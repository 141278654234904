import React, { useEffect, useState } from 'react';
import { Modal, Text, Table, Input, Button, Loading } from 'components';
import { Select, Tabs } from 'antd';
import styled from 'styled-components';
import { MinusCircleFilled, EditFilled } from '@ant-design/icons';
import fetchAbsolute from 'utils/fetchAbsolute';
import { useIntl, FormattedMessage } from "react-intl";

const { TabPane } = Tabs;
const { Option } = Select;

/**
 * 과제 등록 > 업무 유관 시스템 정보 > 업무 수행 시 사용 메뉴 > 업무 수행시 사용 메뉴 등록
 */

const Wrapper = styled(Modal)`
    .ant-table-thead th {
        height: 34px;
        padding: 0px 16px;
        font-size: 12px;
    }
    .ant-table-tbody td {
        font-size: 12px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ff8d23 !important; 
    }
    .system_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        height: 666px;
    }
    .system_wrap .system__header {
        width: 100%; 
        margin-bottom: 19px;
    }
    .system_wrap .system__header .system__header_title {
        display: block;
        margin-bottom: 10px;
        font-size: 12px;
    }
    .system_select_button {
        width: 26px;
        height: 26px;
    }
    .system_wrap > .system_box:nth-of-type(2) {
        width: 55%;
        height: 100%;
    }
    .system_wrap .system_box:last-of-type {
        width: 40%;
        height: 100%;
    }
    .system_wrap .system_box .system_box__title {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .system_wrap .system_box .system_box__content {
        height: 634px;
    }
    .system_wrap .system_box .system_box__content .ant-tabs-tab {
        font-size: 12px;
        height: 32px;
        line-height: 30px;
    }
    .system_wrap .system_box .system_box__content .ant-tabs-nav-container{
        height: 32px;
    }
    .system_wrap .system_box .system_box__content .ant-tabs-card .ant-tabs-content {
        margin-top: -16px;
    }

    .system_content__header {
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
    }
    .system_content__header .select_reset_button {
        width: 68px;
        height: 24px;
        font-size: 12px;
    }
    .system_content__section {
        height: 574px;
        border: 1px solid #e8e8e8;
    }
    .system_box__tab .system_content__section {
        border-top: 0;
    }
    .system_section__table {
        margin-top: 10px;
    }
    .icons__section {
        display: flex; 
        gap: 10px;
        place-items: center;
    }
`;

const initialMenuInsertInfo = {
    level1: '',
    level2: '',
    level3: '',
    level4: '',
    level5: ''
}

const MenuSearch = ({ rowSelection, datas, searchKeyword, onChangeSearchKeyword }) => {
    const { formatMessage } = useIntl();
    const columns = React.useRef([
        {
            title: 'Level 1',
            dataIndex: 'level1',
            key: 'level1',
            width: 150
        },
        {
            title: 'Level 2',
            dataIndex: 'level2',
            key: 'level2',
            width: 130
        },
        {
            title: 'Level 3',
            dataIndex: 'level3',
            key: 'level3',
            width: 140
        },
        {
            title: 'Level 4',
            dataIndex: 'level4',
            key: 'level4',
            width: 140
        },
        {
            title: 'Level 5',
            dataIndex: 'level5',
            key: 'level5'
        }
    ]);
    return (
        <div className='system_content__section' style={{ padding: 8 }}>
            <Input
                placeholder={formatMessage({ id: 'T0374' })} // 검색할 메뉴를 입력하세요
                style={{ height: 32 }}
                onChange={onChangeSearchKeyword}
                value={searchKeyword}
            />
            <div className='system_section__table'>
                <Table
                    columns={[...columns.current]}
                    pagination={true}
                    align='center'
                    dataSource={datas}
                    rowSelection={rowSelection}
                    locale={{ emptyText: ' ' }}
                    scroll={{ y: 441 }}
                />
            </div>
        </div>
    )
}
const MenuInsert = ({ datas, onChangeMenuInsert }) => {
    const { formatMessage } = useIntl();
    return (
        <div className='system_content__section' style={{ padding: 21 }}>
            <div>
                <Text style={{ fontSize: 12 }}>
                    Level 1
                </Text>
                <Input
                    style={{
                        height: 32,
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    name='level1'
                    value={datas.level1}
                    onChange={onChangeMenuInsert}
                    placeholder={formatMessage({ id: 'T0375' })} // Level 1 메뉴명
                />
            </div>
            <div>
                <Text style={{ fontSize: 12 }}>
                    Level 2
                </Text>
                <Input
                    style={{
                        height: 32,
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    name='level2'
                    value={datas.level2}
                    onChange={onChangeMenuInsert}
                    placeholder={formatMessage({ id: 'T0376' })} // Level 2 메뉴명
                />
            </div>
            <div>
                <Text style={{ fontSize: 12 }}>
                    Level 3
                </Text>
                <Input
                    style={{
                        height: 32,
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    name='level3'
                    value={datas.level3}
                    onChange={onChangeMenuInsert}
                    placeholder={formatMessage({ id: 'T0377' })} // Level 3 메뉴명
                />
            </div>
            <div>
                <Text style={{ fontSize: 12 }}>
                    Level 4
                </Text>
                <Input
                    style={{
                        height: 32,
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    name='level4'
                    value={datas.level4}
                    onChange={onChangeMenuInsert}
                    placeholder={formatMessage({ id: 'T0378' })} // Level 4 메뉴명
                />
            </div>
            <div>
                <Text style={{ fontSize: 12 }}>
                    Level 5
                </Text>
                <Input
                    style={{
                        height: 32,
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    name='level5'
                    value={datas.level5}
                    onChange={onChangeMenuInsert}
                    placeholder={formatMessage({ id: 'T0379' })} // Level 5 메뉴명
                />
            </div>
        </div>
    )
}

const UsingMenu = ({ visible, title, onOk = () => { }, onCancel = () => { }, defaultData = [], dependencySystem = [] }) => {
    const { formatMessage } = useIntl();
    const selectSysCode = formatMessage({ id: 'T0126' }); // 시스템 코드를 선택하세요
    const notAllowedMenuInfo = formatMessage({ id: 'T0127' }); // 유효하지 않은 메뉴 정보입니다.

    const [systemCodeList, setSystemCodeList] = useState([]);
    const [systemCode, setSystemCode] = useState({});
    const [allMenuList, setAllMenuList] = useState([]);
    const [menuList, setMenuList] = useState([]);
    const [checkedDatas, setCheckedDatas] = useState([]);
    const [selectDatas, setSelectDatas] = useState([]);
    const [systemKind, setSystemKind] = useState('insert');
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [menuInsertInfo, setMenuInsertInfo] = useState({ ...initialMenuInsertInfo })

    const rowSelection = {
        selectedRowKeys: checkedDatas,
        onSelectAll: (selected, selectedRows) => {
            if (selectedRows.length === 0) {
                setCheckedDatas([]);
            }
        },
        onChange: (selectedRowKeys) => {
            setCheckedDatas(selectedRowKeys);
        }
    };

    /* 등록한 사용 시스템 수정 */
    const onChangeSystemSelect = async (_, e) => {
        const { value } = e.props;
        if (value === 'none') {
            setSystemCode({});
            return;
        }
        const matchSystemInfo = systemCodeList.filter(system => system.systemCd === value)[0];
        if (value.toString().includes('O')) {
            setSystemKind('insert');
        } else {
            const { data: { menuList: getSystemDetail } } = await fetchAbsolute('get', `/admin/system/systemDetails?systemCd=${value}`);
            if (matchSystemInfo.systemKind === "I" && getSystemDetail.length > 0) {
                setSystemKind('search');
            } else {
                setSystemKind('insert');
            }
        }
        setSystemCode(...systemCodeList.filter(system => system.systemCd === value));
        setSearchKeyword('');
        /* 변경될 때 마다 input 창 비우기 */
        setMenuInsertInfo({ ...initialMenuInsertInfo });
    }

    const onChangeSearchKeyword = (e) => {
        const { value } = e.target;
        setSearchKeyword(value);
    }

    const onClickSelectButton = () => {
        if (!systemCode.systemCd) {
            return alert(selectSysCode);
        }
        let tmpArr = [...selectDatas];
        let insertData = { ...menuInsertInfo };

        if (systemKind === 'search') {
            checkedDatas.forEach(key => {
                const newObj = { ...allMenuList[key] };
                //중복되지 않게 넣어야댐
                if (tmpArr.findIndex(v => v.systemCd === newObj.systemCd && v.systemMenuCd === newObj.systemMenuCd) === -1) {
                    newObj.key = tmpArr.length;
                    newObj.systemName = systemCode.systemName;
                    tmpArr.push({ ...newObj });
                }
            });
        } else if (systemKind === 'insert') {
            let valid = true;

            /* [1] 수정하는 데이터인 경우 level1~5 말고 여러가지 키가 있어서 삭제 */
            if (insertData.systemCd) {
                Object.keys(insertData).sort((a, b) => a < b ? -1 : a > b ? 1 : 0).map(v => initialMenuInsertInfo[v]?.trim()).forEach((cur, index, arr) => {
                    if (index === 0 || cur === undefined) return;
                    if (cur.trim() !== '' && arr[index - 1].trim() === '') {
                        valid = false;
                    }
                });
                /* 수정해서 넣으면 선택 항목에 있던 원본이 지워져야함 */
                tmpArr = selectDatas.filter(data => (data.systemCd !== insertData.systemCd) && (data.systemName !== insertData.systemName));
                tmpArr.push(insertData);
            } else {
                /* [2] 새로 메뉴를 넣는 경우 */
                Object.keys(insertData).sort((a, b) => a < b ? -1 : a > b ? 1 : 0).map(v => insertData[v].trim()).forEach((cur, index, arr) => {
                    if (index === 0) return;
                    if (cur.trim() !== '' && arr[index - 1].trim() === '') {
                        valid = false;
                    }
                });
                const newObj = { ...menuInsertInfo, systemKind, key: tmpArr.length, systemName: systemCode.systemName, systemCd: systemCode.systemCd };
                tmpArr.push({ ...newObj });
            }
            if (!valid) return alert(notAllowedMenuInfo);
            setMenuInsertInfo({ ...initialMenuInsertInfo });
        }
        setSelectDatas([...tmpArr]);
        setCheckedDatas([]); // 버튼 누르면 선택된 해제
    }

    const onClickResetButton = () => {
        setSelectDatas([]);
    }

    /* 레벨 삭제 기능 버튼 */
    const onClickDelSelectedData = (key) => {
        setSelectDatas([...selectDatas.filter(data => data.key !== key)]);
    }

    /* 레벨 수정 기능 버튼 */
    const onClickEditSelectedData = (key) => {
        const data = selectDatas.find(data => data.key === key);
        /* 메뉴 입력 부분 레벨 변경  */
        setMenuInsertInfo({ ...data });
        /* 등록한 사용 시스템 값 변경 */
        setSystemCode(...systemCodeList.filter(system => system.systemCd === data.systemCd));
    }

    const onChangeMenuInsert = (e) => {
        const { value, name } = e.target;
        if (value.length > 200) return;
        setMenuInsertInfo({
            ...menuInsertInfo,
            [name]: value
        })
    }


    const onChangeMenu = (e) => {
        setSystemKind(e);
    }

    const onClickOk = () => {
        onOk(selectDatas);
    }

    useEffect(() => {
        setSystemCodeList([...dependencySystem]);
    }, [dependencySystem]);

    useEffect(() => {
        const fetchMenuDatas = async () => {
            if (!systemCode.systemCd) return;
            try {
                const result = await fetchAbsolute('get', `/admin/system/systemDetails?systemCd=${systemCode.systemCd}`);
                setLoading(false);
                setAllMenuList([...result.data.menuList.map((data, index) => ({ ...data, key: index }))]);
                setLoading(true);
            } catch (e) {
                setAllMenuList([]);
            }
        }
        if (systemCode.systemCd && systemCode.systemKind === 'I')
            fetchMenuDatas();
        else
            setAllMenuList([]);
    }, [systemCode]);

    useEffect(() => {
        setMenuList([...allMenuList]);
    }, [allMenuList]);

    useEffect(() => {
        const filterDatas = [...allMenuList?.filter(data => {
            return data.level1?.toLowerCase().includes(searchKeyword.toLowerCase()) || data.level2?.toLowerCase().includes(searchKeyword.toLowerCase()) || data.level3?.toLowerCase().includes(searchKeyword.toLowerCase()) || data.level4?.toLowerCase().includes(searchKeyword.toLowerCase()) || data.level5.toLowerCase().includes(searchKeyword.toLowerCase());
        })];

        setMenuList([...filterDatas]);
    }, [searchKeyword]);

    useEffect(() => {
        setLoading(true);
        if (!visible) return;
        const tmpArr = defaultData.map((v, index) => ({
            ...v,
            key: index
        }));
        setSelectDatas([...tmpArr]);
    }, [defaultData, visible]);

    useEffect(() => {
        setSystemCode({});
        setAllMenuList([]);
        setMenuList([]);
        setCheckedDatas([]);
        setMenuInsertInfo({ ...initialMenuInsertInfo })
    }, [visible]);

    if (!loading) {
        return <Loading visible={true} />
    }

    return (
        <Wrapper visible={visible} title={title} onCancel={onCancel} onOk={onClickOk}>
            <div className='system_wrap'>
                <div className='system__header'>
                    <Text className='system__header_title'>{formatMessage({ id: 'T0380' })}</Text>{/* 등록한 사용 시스템 */}
                    <Select
                        showSearch
                        defaultValue='none'
                        value={systemCode.systemCd ? systemCode.systemCd : 'none'}
                        placeholder='Select System'
                        optionFilterProp='children'
                        onChange={onChangeSystemSelect}
                        style={{ width: 204, height: 32 }}
                    >
                        <Option value='none'>Select System Code</Option>
                        {
                            systemCodeList.map(system => {
                                return (
                                    <Option key={system.systemCd} value={system.systemCd}>{system.systemName}</Option>
                                )
                            })
                        }
                    </Select>
                </div>
                <div className='system_box'>
                    <div className='system_box__content system_box__tab'>
                        <Tabs type='card' tabBarStyle={{ height: 32 }} onChange={onChangeMenu}>
                            {
                                allMenuList.length > 0 &&
                                <TabPane tab={formatMessage({ id: 'T0381' })} key='search'>{/* 메뉴 검색 */}
                                    <MenuSearch rowSelection={rowSelection} datas={menuList} searchKeyword={searchKeyword} onChangeSearchKeyword={onChangeSearchKeyword} />
                                </TabPane>
                            }
                            <TabPane tab={formatMessage({ id: 'T0382' })} key='insert'>{/* 메뉴 입력 */}
                                <MenuInsert datas={menuInsertInfo} onChangeMenuInsert={onChangeMenuInsert} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <div>
                    <Button className='system_select_button' type='primary' onClick={onClickSelectButton}>&gt;</Button>
                </div>
                <div className='system_box'>
                    <div className='system_box__content'>
                        <div className='system_content__header'>
                            <Text style={{ fontSize: 12 }}>{formatMessage({ id: 'T0338' })}</Text> {/* 선택 항목 */}
                            <Button className='select_reset_button' onClick={onClickResetButton}><FormattedMessage id="T0037" /></Button>{/* 초기화 */}
                        </div>
                        <div className='system_content__section'>
                            <Table
                                columns={[{
                                    key: 'systemName',
                                    title: formatMessage({ id: 'T0363' }), // 시스템 명
                                    width: 90,
                                    dataIndex: 'systemName'
                                }, {
                                    key: 'level1',
                                    title: 'Level 1',
                                    dataIndex: 'level1',
                                }, {
                                    key: 'level2',
                                    title: 'Level 2',
                                    dataIndex: 'level2',
                                }, {
                                    key: 'level3',
                                    title: 'Level 3',
                                    dataIndex: 'level3',
                                }, {
                                    key: 'level4',
                                    title: 'Level 4',
                                    dataIndex: 'level4',
                                }, {
                                    key: 'level5',
                                    title: 'Level 5',
                                    dataIndex: 'level5',
                                }, {
                                    key: 'delete',
                                    title: '',
                                    width: 75,
                                    align: 'center',
                                    render: (e) => {
                                        return (
                                            <div className='icons__section'>
                                                <EditFilled onClick={() => onClickEditSelectedData(e.key)} />
                                                &#124;
                                                <MinusCircleFilled onClick={() => onClickDelSelectedData(e.key)} />
                                            </div>
                                        )
                                    }
                                },
                                ]}
                                dataSource={selectDatas}
                                pagination={false}
                                locale={{ emptyText: ' ' }}
                                scroll={{ y: 503 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper >
    )
}

export default UsingMenu;