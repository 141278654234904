import styled from "styled-components";

const Wrapper = styled.div`
  /* 가이드 스텝 */
  .steps_info {
    display: flex;
    flex-direction: column;
    padding: 16px 22px 18px 23px;
    width: 360px;
    height: 179px;
    background-repeat: no-repeat;
    gap: 20px;
    justify-content: space-around;
  }
  .steps_img {
    width: 360px;
    height: 179px;
    position: absolute;
  }
  .guide_step__title {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .guide_step__detail {
    font-size: 14px;
    color: #fff;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .merged_dwp__div {
    display: flex;
    justify-content: space-between;
    margin-top: 39px;
    padding: 0px 10px;
  }
  .take_a_look {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
  .btn_style {
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid #8f9094;
    width: 92px;
    height: 32px;
  }
`;

export default Wrapper;
