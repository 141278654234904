import React from "react";
import { Modal, Upload } from "components";
import styled from 'styled-components';
import { FormattedMessage } from "react-intl";

/**
 * 관리자 > 회원 조회 > 회원 정보 수정 > 이미지 변경 모달
 * 
 */

const ModalStyled = styled(Modal)`
    .ant-modal-footer { 
        justify-content: center; 
    }
`


const UploadImg = ({ visible = false, onChange = () => { }, onOk = () => { }, onCancel = () => { }, width = 350, fileList, setFileList, ...args }) => {
    const END_POINT = process.env.REACT_APP_API_URI;
    const onBeforeupload = (inFile) => {
        const tmpArr = [inFile];
        setFileList([...tmpArr]);
        return false;
    };

    const onRemoveFile = (rmFile) => {
        const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
        setFileList([...tmpArr]);
    };

    const onOkModal = () => onOk(fileList);

    return (
        <ModalStyled
            width={width}
            visible={visible}
            title={<FormattedMessage id='T1630' />} // 이미지 변경
            onOk={onOkModal}
            onCancel={onCancel}
            onChange={onChange}
            okText={''}
            {...args}
        >
            <div className='message__box'>
                <Upload
                    action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                    multiple={false}
                    maxCount={1}
                    beforeUpload={onBeforeupload}
                    onRemove={onRemoveFile}
                    fileList={fileList}
                    onChange={onChange}
                    accept={'.png, .jpg, .jpeg, .gif'}
                />
            </div>
        </ModalStyled>
    );
};

export default UploadImg;