import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
    padding-top: 35px;
    .section {
        width: 100%;
        position: relative;
    }
    .section p,
    .section span {
        color: #fff;
        letter-spacing: -1px;
    }
    .section .jedi_knight__title {
        font-size: 23px;
        font-weight: bold;
    }
    .section .point_color {
        color: #91CE9D;
    }

    .section1 .section_background {
        width: 100%;
    }
    .section1 .section_box {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 70px;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1200px;
    }
    .section1 .section_text__title {
        margin-bottom: 20px;
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
    }
    .section1 .section_text__content {
        font-size: 15px;
        word-break: keep-all;
    }
    .section1 .section_text__content.section_text__list {
        padding-left: 20px;
    }
    .section1 .section_text__content.section_text__list ul {
        line-height: 23px;
    }
    .section1 .section_text__content li {
        position: relative;
    }
    .section1 .section_text__content li::before {
        content: '';
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 8px;
        background: #fff;
        border-radius: 50%;
    }
    .section1 .section__button {
        width: 400px;
        height: 40px;
        margin-top: 66px;
        font-size: 16px;
        font-weight: bold;
    }

    .section2 {
        text-align: center;
    }
    .section2 .section_text__box {
        margin-top: 25px;
    }
    .section2 .section_text__title {
        font-size: 20px;
        font-weight: bold;
    }
    .section2 .section_text__content {
        font-size: 16px;
    }

    .section3 {
        margin-top: 60px;
        text-align: center;
    }
    .section3 .section_banner__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 50px;
    }
    .section3 .section_banner__box {
        position: relative;
        width: 235.66px;
        height: 172.5px;
    }
    .section3 .section_banner__image {
        position: absolute;
        z-index: 0;
    }
    .section3 .section_banner__content {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        margin: 0 auto;
        margin-top: 10px; 
        background: #000;
        color: #fff;
        font-size: 17px;
        z-index: 1;
    }
    .section3 .section_banner__box:nth-of-type(1) .section_banner__image {
        left: -26px;
        top: -51px;
    }
    .section3 .section_banner__box:nth-of-type(2) .section_banner__image {
        left: 0;
        top: 0;
    }
    .section3 .section_banner__box:nth-of-type(3) .section_banner__image {
        left: -23px;
        top: 0;
    }
    .section3 .section_banner__box:nth-of-type(4) .section_banner__image {
        left: -47px;
        top: 0;
    }

    .section4 {
        text-align: center;
        padding-top: 66px;
    }
    .section4 .section_text__content {
        font-size: 18px;
    }
    .section4 .section__button {
        width: 400px;
        height: 40px;
        margin-top: 25px;
        font-size: 16px;
        font-weight: bold;
    }

    .section5 {
        padding: 140px 0;
        text-align:center;
    }
    .section5 img {
        display: block;
        margin: 0 auto;
        cursor: pointer;
        z-index: 2000,
    }
    .academy_return_to_top{
        color:#FF6600 !important ;
        font-size:18px;
        z-index: 1,
    }
   
`;

export default Wrapper;
