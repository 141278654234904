import React, { useState, useEffect } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { Link, withRouter } from "react-router-dom";
import Wrapper from "./Styled";
import { Tabs, Button, Text, Spin } from "components";
import { ArrowNextIcon, ArrowPrevIcon, SlideDotIcon } from "icons";
import { Tabs as AntTabs, Divider, Tooltip } from "antd";
import { CoeIcon, DiyIcon } from "icons";
import { RightOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

const { TabPane } = AntTabs;

/**
 * main > 추천 과제
 * lv별로 조회 -> 과제 선정 기준
 * 1. 관심 과제 등록 횟수
 * 2. 과제 조회수(조회수는 인당 1회제한)
 * 관리자가 직접 선정(Admin)
 * -> 과제명과 같이 노출될 관련 KPI 정의 필요
 *
 * tab, carosel
 */

const RecommendTask = () => {
  const { formatMessage } = useIntl();

  const [cateForTab, setCateForTab] = useState([]);
  const [recommendTasks, setRecommendTasks] = useState([]);
  const [totalDatas, setTotalDatas] = useState([]);
  const [tabsValue, setTabsValue] = useState("");
  const [slideCnt, setSlideCnt] = useState(0);
  const [slideLength, setSlideLength] = useState(0);
  const [loading, setLoading] = useState(false);

  const boxes = {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #d0d1d6',
    padding: ' 18.5px 15.7px',
    gap: '10px',
    height: '152px',
    width: `calc((100% - 60px) / 4)`,
  }

  const boxesContainer = {
    display: 'flex',
    margin: 0,
    width: `calc(100% / ${slideLength})`,
    gap: '20px',
  }

  const carouselContainer = {
    display: 'flex',
    width: `calc(100% * ${slideLength})`,
    height: '100%',
  }

  const prev = () => {
    setSlideCnt(slideCnt <= 0 ? slideLength - 1 : slideCnt - 1);
  };
  const next = () => {
    setSlideCnt(slideCnt >= slideLength - 1 ? 0 : slideCnt + 1);
  };

  const onClickSlideDot = (e) => {
    const parent = e.currentTarget.parentElement;
    const index = Array.from(parent.children).indexOf(e.currentTarget);
    setSlideCnt(index);
  };

  const onChangeTabs = (tabValue) => {
    setTabsValue(tabValue);
    const rankResult = [...totalDatas].sort((a, b) => {
      return a.totalRank - b.totalRank;
    });
    const tmpArr = rankResult
      .filter((v) => tabValue === "all" || v.workIdUpCd === tabValue)
      .splice(0, 12);
    setSlideLength(Math.ceil(tmpArr.length / 4));
    setRecommendTasks([...tmpArr]);
  };

  useEffect(() => {
    const fetchWorkList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/workClassificationList`
      );
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setCateForTab(result.data?.filter((v) => v.level === 1));
    };

    const fetchRecommendList = async () => {
      setLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/main/recommend/getRecommendTaskRankList`
      );
      setLoading(false);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setTotalDatas([...result.data]);
      const rankResult = [...result.data].sort((a, b) => {
        return a.totalRank - b.totalRank;
      });
      const tmpArr = rankResult.splice(0, 12);
      setRecommendTasks(tmpArr);
      setSlideLength(Math.ceil(tmpArr.length / 4));
    };
    fetchWorkList();
    fetchRecommendList();
  }, []);

  useEffect(() => {
    setSlideCnt(0);
  }, [tabsValue]);

  useEffect(() => {
    const target = document.querySelector(".carousel__container");
    if (!target) return;
    target.style.marginLeft = slideCnt * -100 + "%";
  }, [slideCnt]);

  return (
    <Wrapper>
      <div className="recommend__container">
        <div className="common__title">
          <span>{formatMessage({ id: 'T0383' })}</span>{/* 추천과제 */}
        </div>
        <Text className="recommend__text">
          {formatMessage({ id: 'T0390' })}
        </Text>
        {/* Tab 부분 시작 */}
        <Tabs
          defaultActiveKey={tabsValue}
          onChange={onChangeTabs}
          activeKey={tabsValue}
        >
          <TabPane tab={formatMessage({ id: 'T0391' })} key="all" />{/* 전체 */}
          {cateForTab.map((list) => (
            <TabPane tab={list.cdNm} key={list.cd} />
          ))}
        </Tabs>
        {/* Tab 부분 끝 */}
        <button id="prev" onClick={prev}>
          <ArrowPrevIcon />
        </button>
        <button id="next" onClick={next}>
          <ArrowNextIcon />
        </button>
        <div id="carousel__box">
          {recommendTasks.length > 0 ? (
            <div className="carousel__container" style={carouselContainer}>
              {recommendTasks
                .reduce((acc, cur) => {
                  const arr = [...(acc[acc.length - 1] || [])];
                  if (arr.length >= 4) {
                    const newArr = [];
                    newArr.push(cur);
                    acc.push(newArr);
                    return acc;
                  } else {
                    arr.push(cur);
                    acc[acc.length === 0 ? 0 : acc.length - 1] = [...arr];
                    return acc;
                  }
                }, [])
                .map((arr, index) => {
                  return (
                    <div className="recommend_boxes__container" key={index} style={boxesContainer}>
                      {arr.map((v, idx) => {
                        return (
                          <div className="recommend_boxes" style={boxes} key={idx}>
                            <div className="recommendbox__title">
                              {v.taskType === "COE" ? <CoeIcon /> : <DiyIcon />}
                              <Tooltip title={v.taskNm}>
                                <Text className="recommendbox__text">
                                  {v.taskNm}
                                </Text>
                              </Tooltip>
                            </div>
                            <div className="recommend_boxes__middle">
                              <div className="recommend_boxes__table">
                                <div id="title">{formatMessage({ id: 'T0392' })}</div>{/* 연간 절감시간 */}
                                <div id="number">{v.savingTime}H</div>
                              </div>
                              <div className="recommend_boxes__table">
                                <div id="title">{formatMessage({ id: 'T1099' })}</div>{/* FTE */}
                                <div id="number_fte">
                                  {Number(v.fte)?.toLocaleString()}
                                </div>
                              </div>
                            </div>
                            <Divider />
                            <Link
                              to={`/assign/lookup/${v.taskId}`}
                              className="common__button__link"
                            >
                              <Button className="common__button">
                                {formatMessage({ id: 'T0097' })} <RightOutlined />{/* 상세보기 */}
                              </Button>
                            </Link>
                          </div>
                        );
                      })}
                    </div>
                  );
                })
              }
            </div>
          ) : (
            <div className="recommend_boxes__none">
              {
                !!loading ?
                  <Spin tip="Loading..." />
                  :
                  `${formatMessage({ id: 'T0393' })}` // 해당하는 과제가 없습니다.
              }
            </div>
          )}
        </div>
        <div className="carousel_dot__bottom">
          {Array.from({ length: slideLength }).map((v, index) => (
            <SlideDotIcon
              key={index}
              onClick={onClickSlideDot}
              style={{ fill: index === slideCnt ? "#8f9094" : "#d0d1d6" }}
            />
          ))}
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(RecommendTask);