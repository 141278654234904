import React from 'react';
import { Checkbox as antCheckbox } from 'antd';
import styled from 'styled-components';

/**
 * Filename: Checkbox.js
 * Description: 체크 박스의 색상 변경이 필요하여 사용하는 체크 박스 공통 컴포넌트
 */

const CheckWrap = styled(antCheckbox)`
    .ant-checkbox-inner {
        width: ${props=>props.size}px;
        height: ${props => props.size}px;
        border: 1px solid #ff6600;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
        /* background: #0067ac; */
        background: #ff6600;
    }
    span {
        font-size: 12px;
    }
`

const Checkbox = ({ children, innerRef, size=16, ...args }) => {
    return (
        <CheckWrap ref={innerRef} size={size} {...args}>
            { children }
        </CheckWrap>
    )
}

export default Checkbox;