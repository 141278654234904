import React, { useState, useEffect} from 'react'
import { Modal, Text, Button } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import styled from 'styled-components';
import { useIntl } from "react-intl";

const Wrapper = styled(props => <Modal {...props} />)`
    .ant-popover-inner-content {
        overflow-y: unset !important;
        font-size: 11px;
    }
    .ant-popover > .ant-popover-content {
        width: auto !important;
    }
`

const HFBpdIframeModal = ({ 
    visible = false,
    type, 
    data = {},
    bpdId,
    customHeight = "700",
    params,
    onOkBpdIframe,
    doubleCheckVisible,
    onCancelBpdIframe,
    onOkDoubleCheck,
    onCancelDoubleCheck,
}) => {
    const { formatMessage } = useIntl();
    const url = "https://dreago.net:8070/ibpm-web";
    const [src, setSrc] = useState("");

    /* 데이터가 안넘어와서 여기서  BPD 조회 API 호출하기 */
    useEffect(() => {
        // let order = type === "enter" ? 2 : 0;
      /* bpd 아이디가 필요해서 params */
      if (!type) return;
      const getBpdInfo = async () => {
        const result = await fetchAbsolute(
            "get",
            `/bpd/bpdInfoHF?bpdId=${params?.bpdId}`
          );
          if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
          const {
            data: { processId },
          } = result;
    
          let bpdProcessId = processId;
          if (processId === undefined) {
            bpdProcessId = data.processId
          };
    
        //EXECUTEFLAG=N
        if (type === "enter") {
          setSrc(
            url +
            `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${bpdProcessId}_EDIT?locale=ko&EXECUTEFLAG=N&processVersion=EDIT`
          );
        } else if (type === "modify") {
          setSrc(
            url +
            `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${bpdProcessId}_EDIT?locale=ko&EXECUTEFLAG=N&processVersion=EDIT`
          );
        } else if (type === "lookup") {
          setSrc(
            url +
            `/openapi/bpm/popup/processPopUpMain?processid=${bpdProcessId}&viewDetailPage=false&LegacyAuthCheckFilterUser=dreago_admin`
          );
        }
      };
      getBpdInfo();

      let height = (type === "lookup" ? 'calc(100vh - 62px)' : 'calc(100vh - 115px)');
        document.getElementsByClassName('bpd_iframe_modal')[0]?.setAttribute("style", "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;");
        document.getElementsByClassName('bpd_iframe_modal')[0]?.getElementsByClassName('ant-modal-body')[0]?.setAttribute("style", `height:${height};`);
    }, [visible]);

    return (
        <>
        {   
            (type==="modify" || type==="enter")
            &&
            <Modal
                    centered
                    visible={doubleCheckVisible}
                    width={500}
                    okText={formatMessage({ id: 'T0064' })} // 확인
                    title={formatMessage({ id: 'T1647' })} // 저장 확인
                    onOk={onOkDoubleCheck}
                    onCancel={onCancelDoubleCheck}
                >
                    <div style={{ textAlign: "center" }}>
                    <Text>
                        {formatMessage({ id: 'T1648' })}
                        {/* 저장 버튼을 클릭하셨나요? */}
                    </Text>
                    <br />
                    <br />
                    <Text style={{ color: "#e54156" }}>
                        {formatMessage({ id: 'T1653' })
                            .split('\n')
                            .map((data,index)=>{
                            return (
                                <span key={index}>
                                    {data}
                                    <br />
                                </span>
                            )
                        })}
                        {/* * 저장 하지 않고 확인 버튼을 클릭하실 경우
                        수정한 Workflow가 저장되지 않습니다. */}
                    </Text>
                    </div>
            </Modal>
        }
        <Wrapper
            visible={visible}
            className='bpd_iframe_modal'
            title='Process Design'
            okText={formatMessage({ id: 'T0064' })} // 확인
            onOk={onOkBpdIframe}
            onCancel={onCancelBpdIframe}
            footer={type!=="lookup"
            ?
              [ <Button type="primary" onClick={onOkBpdIframe}>
                  {formatMessage({ id: 'T0064' })}{/* 확인 */}
              </Button>,
              <Button onClick={onCancelBpdIframe}>
                  {formatMessage({ id: 'T0051' })}{/* 취소 */}
              </Button>]
            : null
            }
        >
                <iframe
                style={{
                    border: 0,
                    margin: "0 auto",
                    display: "block",
                }}
                width="100%"
                height='100%'
                title="bpd"
                id="bpd"
                className="bpd"
                src={src}
            />
        </Wrapper>
        </>
    )
}

export default HFBpdIframeModal
