import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  .coemanage_edit__button_wrap {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    padding-top: 20px;
  }
  .flex align-center{
    -webkit-box-align: center;
    align-items: center;

  }
`;

export default Wrapper;
