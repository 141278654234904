import React, { useEffect, useRef, useState } from 'react';
import { Text, Loading, Image } from 'components';
import { MonitoringReplayIcon, PauseIcon } from 'icons';
import fetchAbsolute from 'utils/fetchAbsolute';
import { getCookie } from 'utils/cookie';
import Wrapper from './Styled';
import { useIntl, FormattedMessage } from "react-intl";
import { Slider } from 'antd';

const RealtimeModal = ({ visible, onCancel, taskId, workItemId, cr }) => {
    const { formatMessage } = useIntl();
    const imgErr = formatMessage({ id: 'T0122' }); // 이미지를 불러오는 도중 에러가 발생했습니다.
    const imgListNotExist = formatMessage({ id: 'T0123' });// 이미지 목록이 존재하지 않습니다.
    const failedMessage = formatMessage({ id: 'T1321' }); // //러너에이전트 미작동으로 화면 캡쳐를 하지 못했습니다.  관리자에게 문의바랍니다.

    const imageArray = useRef([]);
    const [image, setImage] = useState(null);
    const [isPlay, setIsPlay] = useState(false);
    const [loading, setLoading] = useState(false);
    const [slideVal, setSlideVal] = useState(0);
    const [playTotal, setPlayTotal] = useState(0);
    const [hour, setHour] = useState("00");
    const [min, setMin] = useState("00");
    const [second, setSecond] = useState("00");

    const [playhour, setPlayHour] = useState("00");
    const [playMin, setPlayMin] = useState("00");
    const [playSecond, setPlaySecond] = useState("00");

    const count = useRef(0);
    const playTime = useRef(0);
    let interval = useRef(null);
    let playInteval = useRef(null);
    const onClickStartButton = () => {
        setIsPlay(true);
    };
    const onClickPauseButton = () => {
        setIsPlay(false);
    };

    const onChangeSlider = (value) => {
        setSlideVal(value);

        count.current = value;
        playTime.current = value * 2;
        timeFormat(playTime.current, "play");
        if (value >= playTotal) {
            return;
        }
        setImage(imageArray.current[value].fileName);

    };

    const timeFormat = (seconds, type) => {
    
        //3항 연산자를 이용하여 10보다 작을 경우 0을 붙이도록 처리 
        const hour = parseInt(seconds / 3600) < 10 ? '0' + parseInt(seconds / 3600) : parseInt(seconds / 3600);
        const min = parseInt((seconds % 3600) / 60) < 10 ? '0' + parseInt((seconds % 3600) / 60) : parseInt((seconds % 3600) / 60);
        const sec = seconds % 60 < 10 ? '0' + seconds % 60 : seconds % 60;
        if (type === "play") {
            setPlayHour(hour);
            setPlayMin(min);
            setPlaySecond(sec);
        } else {
            setHour(hour);
            setMin(min);
            setSecond(sec);
        }
    }

    useEffect(() => {
        if (isPlay) {
            //플레이 누르고 이미지 변경
            interval.current = setInterval(() => {
                if (count.current > imageArray.current.length - 1) {
                    count.current = 0;
                    playTime.current = 0;
                    setIsPlay(false);
                    setSlideVal(0);
                    setImage(imageArray.current[count.current].fileName);
                    setPlayHour("00");
                    setPlayMin("00");
                    setPlaySecond("00");
                } else {
                    count.current++;
                    setSlideVal(count.current);
                    if (count.current === imageArray.current.length) {
                        return;
                    }
                    setImage(imageArray.current[count.current].fileName);
                }
            }, 2000);

            //플레이 누르고 시간초 변경 되는 기능
            playInteval.current = setInterval(function () {

                const hour = parseInt(playTime.current / 3600) < 10 ? '0' + parseInt(playTime.current / 3600) : parseInt(playTime.current / 3600);
                const min = parseInt((playTime.current % 3600) / 60) < 10 ? '0' + parseInt((playTime.current % 3600) / 60) : parseInt((playTime.current % 3600) / 60);
                const sec = playTime.current % 60 < 10 ? '0' + playTime.current % 60 : playTime.current % 60;
                setPlayHour(hour);
                setPlayMin(min);
                setPlaySecond(sec);
                playTime.current++;
            }, 1000);
        } else {
            clearInterval(interval.current);
            clearInterval(playInteval.current);

        };

    }, [isPlay]);

    useEffect(() => {
        if (!visible) {
            setSlideVal(0);
            setIsPlay(false);
            count.current = 0;
            playTime.current = 0;
            clearInterval(interval.current);
            clearInterval(playInteval.current);
        } else {
            if (!taskId || !workItemId) return;

            const fetchMonitorData = async () => {
                setLoading(true);
                const result = await fetchAbsolute('get', `/monitoring/runner/getFileListByWorkItemId?crCode=${cr.crCd}&workItemId=${workItemId}&processId=${taskId}`);
                setLoading(false);
                if (result.error || result.data.resultCode === 'F') {
                    alert(failedMessage);
                    return onCancel();
                } else if (result.data.resultCode === 'S' && result.data.fileList.length === 0) {
                    alert(failedMessage);
                    return onCancel();
                }
                setPlayTotal(result.data.fileList.length);
                setPlayHour("00");
                setPlayMin("00");
                setPlaySecond("00");
                timeFormat(result.data.fileList.length * 2, "total");
                imageArray.current = [...result.data.fileList];
                setImage(imageArray.current[0].fileName);
            };
            fetchMonitorData();
        };
    }, [visible]);

    const [blobSrc, setBlobSrc] = useState('');
    const accessToken = getCookie('auth')?.accessToken;

    useEffect(() => {
        const inputImageInfo = async (src) => {
            const headers = new Headers();
            headers.append('Authorization', accessToken);

            /* 메인화면처럼 src가 빈 경우에 */
            if (src) {
                await fetch(src, { headers })
                    .then(response => response.blob())
                    .then(blobby => {
                        let objectUrl = window.URL.createObjectURL(blobby);
                        setBlobSrc(objectUrl);
                    });
            }
        }
        inputImageInfo(image);

        return () => {
            window.URL.revokeObjectURL(blobSrc);
        }
    }, [image]);

    return (
        <Wrapper
            style={{
                top: 10
            }}
            onCancel={onCancel}
            visible={visible}
            draggType={false}
            title={<><Text className='active_modal__title bold'><FormattedMessage id="T1037" /> : </Text><Text className='active_modal__title'>{taskId}</Text></>}
            footer={[
                <>
                    {
                        !isPlay ?
                            <div >
                                <div><MonitoringReplayIcon onClick={onClickStartButton} /></div>
                            </div> :
                            <div >

                                <div > <PauseIcon onClick={onClickPauseButton} /></div>
                            </div>
                    }
                </>
            ]}
        >
            <div className='active_modal__display'>
                {
                    loading ?
                        <Loading visible={loading} style={{
                            position: 'relative',
                            width: '100%',
                            height: '100%',
                            minHeight: 'unset'
                        }} /> :
                        <img src={blobSrc} loading='lazy' alt='img' />
                        // <img src={image} alt='img' />
                }
            </div>
            <div className='modal_slider_display'>
                <div className='modal_slider'>
                    <Slider
                        className='replay_slider'
                        trackStyle={{ backgroundColor: "#ff7b00" }}
                        handleStyle={{ borderColor: "#ff7b00" }}
                        value={slideVal}
                        max={imageArray.current.length}
                        tipFormatter={null}
                        onChange={onChangeSlider}
                    /></div>
                <div className='replay_playTime'><Text className='replay_playTime_Text'>{playhour + ":" + playMin + ":" + playSecond} / {hour + ":" + min + ":" + second} </Text></div>
                {/* <Text>{Math.round(count.current / playTotal * 100)}% / 100%</Text> */}
            </div>
        </Wrapper>
    )
};

export default RealtimeModal;