import React, { useState, useEffect } from "react";
import { getUniqueKey } from "utils/Str";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import {
  PageTemplate,
  Header,
  Footer,
  Table,
  Input,
  Button,
  Text,
  Collapse,
  FormTable
} from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { Select, Collapse as AntCollapse, Row, Col } from "antd";
import Wrapper from "./Styled";

/**
 * Filename: AdminQueue.js
 * 관리자 > Queue 정보 관리 페이지
 */

const { Option } = Select;
const { Panel } = AntCollapse;

const initialInputValues = {
  cr: "",
  processId: "",
};

const initialPageInfo = {
  total: 0,
  currentPage: 1,
  pageSize: 20,
};

const AdminQueue = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const errorMsg = formatMessage({ id: "T0133" }); // 작업을 실패하였습니다.

  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));

  const [queueList, setQueueList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const pageSize = [20, 50, 70];

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      currentPage: 1,
      pageSize: +value,
    };
    fetchQueueList({
      ...inputValues, ...searchObj,
    })
  }

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    fetchQueueList({ ...inputValues, ...pageInfo, currentPage: +pagination.current });
  };

  const onChangeCr = (value) => {
    setInputValues({
      ...inputValues,
      cr: value
    })
  };

  const onChangeProcessId = (e) => {
    setInputValues({
      ...inputValues,
      processId: e.target.value
    });
  };

  /* 초기화 버튼 */
  const onClickResetCondition = () => {
    setInputValues({
      ...initialInputValues,
      cr: crInfo[0].crCd
    });
    fetchQueueList({
      ...initialInputValues,
      ...initialPageInfo,
      cr: crInfo[0].crCd,
    });
  };

  // 검색 버튼
  const onClickSearchWorkItem = async () => {
    fetchQueueList({ ...inputValues, ...pageInfo, currentPage: 1 });
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearchWorkItem(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const fetchQueueList = async (data) => {
    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/queue/queueslist?pageNo=${data.currentPage}&pageSize=${data.pageSize}&crCd=${data.cr}&processId=${data.processId}`
    );
    setTableLoading(false);
    if (result.error) {
      return alert(errorMsg);
    }
    setQueueList(result.data?.list);
    const { currentPage, pageSize, total } = data;
    setPageInfo({ currentPage, pageSize, total })
  };

  const columns = [
    {
      title: <FormattedMessage id="T1038" />, // Queue ID
      dataIndex: "id",
      align: "center",
      key: "id",
      width: 200,
      render: (text, record) => (
        <Link to={`/admin/queue/${inputValues?.cr}/${record.name}`}>
          {text}
        </Link>
      ),
    },
    {
      title: <FormattedMessage id="T1037" />, // Process ID
      dataIndex: "processId",
      align: "center",
      key: "processId",
      width: 200,
      render: (text) => (
        <Link
          to={`/assign/lookup/${text}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </Link>
      ),
    },
    {
      title: <FormattedMessage id="T1044" />, // Post Processing Flag
      dataIndex: "postProcessingFlag",
      align: "center",
      key: "postProcessingFlag",
    },
    {
      title: <FormattedMessage id="T1045" />, // Maxium Retry Count
      dataIndex: "maxRetryCount",
      align: "center",
      key: "maxRetryCount",
    },
    {
      title: <FormattedMessage id="T1046" />, // Maxium Run Bot Count
      dataIndex: "maxRunBotCount",
      align: "center",
      key: "maxRunBotCount",
    },
  ];

  useEffect(() => {
    if (crInfo.length === 0) return;
    const tmpInputValues = {
      ...initialInputValues,
      cr: crInfo[0].crCd,
    };
    setInputValues({ ...tmpInputValues });
    fetchQueueList({ ...tmpInputValues, ...initialPageInfo });
  }, [crInfo]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper style={{ padding: "30px 100px" }}>
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <FormTable pointBorder={false} size="sm">
              <colgroup>
                <col width="10.375%" />
                <col width="13.625%" />
                <col width="10.375%" />
                <col width="13.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ borderRadius: "14px 0 0 0" }}>
                    <Text>{formatMessage({ id: "T1036" })}</Text>{/* CR */}
                  </th>
                  <td>
                    <Select
                      name="search"
                      style={{ width: 250 }}
                      onChange={onChangeCr}
                      value={inputValues.cr}
                    >
                      {crInfo?.map((v) => (
                        <Option value={v.crCd} key={v.crCd}>
                          {v.crDesc}
                        </Option>
                      ))}
                    </Select>
                  </td>
                  <th><Text>{formatMessage({ id: "T1037" })}</Text></th>{/* Process ID */}
                  <td>
                    <Input
                      style={{ width: 250 }}
                      onChange={onChangeProcessId}
                      value={inputValues?.processId}
                      placeholder={formatMessage({ id: "T1037" })} // Process ID
                      onKeyPress={onKeyPress}
                    />
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <Row style={{ padding: "0 13px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0 25px",
                  padding: "10px 0",
                }}
              >
                <Button width="120" height="40" onClick={onClickResetCondition}>
                  <FormattedMessage id="T0037" />{/* 초기화 */}
                </Button>
                <Button
                  width="120"
                  height="40"
                  type="primary"
                  onClick={onClickSearchWorkItem}
                >
                  <FormattedMessage id="T0039" />{/* 검색 */}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Col span={6} style={{ paddingTop: 20 }}>
          <span>
            {/* 페이지당 행 */}
            {formatMessage({ id: "T0036" })} &emsp;
            <Select
              name="pagination"
              defaultValue={pageSize[0]}
              value={+pageInfo.pageSize}
              onSelect={onSelectPage}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                </Option>
              ))}{/* {number} 개 */}
            </Select>
          </span>
        </Col>
        <div style={{ paddingTop: 20 }}>
          <Table
            defaultPageSize={+pageInfo.pageSize}
            pagination={true}
            pageSizeVisible={false}
            onChange={onChangeTableColumns}
            rowKey={(record) => record.id}
            loading={tableLoading}
            columns={columns}
            dataSource={queueList}
            locale={{ emptyText: `${formatMessage({ id: "T0842" })}` }} // 데이터가 존재하지 않습니다
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.pageSize,
            }}
          />
        </div>
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminQueue;
