import React, { useEffect, useState } from "react";
import "./common.css";
// import { useSelector } from 'react-redux';
// import { getUniqueKey } from 'utils/Str';

/**
 * Filename: /index.js
 * Description: 과제 조회 -> 3.개발 진행 -> 박스우드 AssignBoxwoodWorkFlow
 */

const AssignBoxwoodWorkFlow = ({ profile, type, boxwoodResultCheck }) => {
  let baseUrl = "https://dreago.net:8080/Boxwood_portal/embed";
  const [url, setUrl] = useState("");

  useEffect(() => {
    // console.log(type, "type");
    if (type === "insert") {
      setUrl(`${baseUrl}/process/new?userId=${profile.EMP_NO}&taskNm=workflow`);
    } else if (type === "lookup") {
      setUrl(
        `${baseUrl}/process/direct?userId=${profile.EMP_NO}&processCd=${boxwoodResultCheck?.processCd}&taskId=${boxwoodResultCheck.workflowId}&taskNm=${boxwoodResultCheck.workflowId}&mode=read`
      );
    } else if (type === "modify") {
      setUrl(
        `${baseUrl}/process/direct?userId=${profile.EMP_NO}&processCd=${boxwoodResultCheck?.processCd}&taskId=${boxwoodResultCheck.workflowId}&taskNm=${boxwoodResultCheck.workflowId}&mode=edit`
      );
    }
  }, [type, boxwoodResultCheck, boxwoodResultCheck.deployYn]);

  return (
    <div>
      <iframe
        style={{
          border: 0,
          margin: "0 auto",
          display: "block",
        }}
        width="100%"
        src={url}
        height="700"
        id="workflow"
        name="workflow"
        title="workflow"
      />
    </div>
  );
};

export default AssignBoxwoodWorkFlow;
