import fetchAbsolute from "utils/fetchAbsolute";
import { takeEvery, put, select } from 'redux-saga/effects';
import { LOAD_WORKFLOW_DATA, loadWorkflowDataFail, loadWorkflowDataSuccess, INSTANT_START_WORKFLOW, TEST_START_WORKFLOW } from './action';
import { setVisible } from '../loading/action';
import { getUniqueKey } from 'utils/Str';

function* getData({ workflowId }) {
    yield put(setVisible(true));
    const result = yield fetchAbsolute('get', `/workflow/workflowInfoHF?workflowId=${workflowId}`);
    if (result.error) {
        yield put(loadWorkflowDataFail());
    } else {
        yield put(loadWorkflowDataSuccess({ ...result.data }));

    };
    yield put(setVisible(false));
}
function* instantStart(param) {
    const workflowData = yield select(state => state.get(getUniqueKey('hfWorkflowLookup')).workflowData);

    const { successMsg, failedMsg } = param.data;

    const result = yield fetchAbsolute("post", `/workflowSchedule/runProcess?processId=${workflowData.processId}`);

    if (!result.error) {
        alert(successMsg);
        param.cb();
    } else {
        alert(failedMsg);
    };
}

function* testStart(param) {
    const workflowData = yield select(state => state.get(getUniqueKey('hfWorkflowLookup')).workflowData);

    const { successMsg, failedMsg } = param.data;

    const result = yield fetchAbsolute("post", `/workflowSchedule/testRunProcess?processId=${workflowData.processId}`);

    if (!result.error) {
        alert(successMsg);
        param.cb();
    } else {
        alert(failedMsg);
    };
}

function* rootSaga() {
    yield takeEvery(LOAD_WORKFLOW_DATA, getData);
    yield takeEvery(INSTANT_START_WORKFLOW, instantStart);
    yield takeEvery(TEST_START_WORKFLOW, testStart);
};

export default rootSaga;