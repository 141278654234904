import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Table,
  Text,
  Input,
  Organization,
  Collapse,
  FormTable,
  RangePicker
} from "components";
import { Select, Collapse as AntCollapse, Row, Col } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import InputSearch from "components/atoms/InputSearch";
import useFormatMessage from "hooks/useFormatMessage";
// import { setAssignListValue} from 'store/assignList/action';  reducer action 안만듬

const initialPageInfo = {
  sort: "rechDate",
  direction: ",desc",
  total: 0,
  currentPage: 1,
  pageSize: 10,
};

const initSearchObject = {
  title: "",
  empNo: [],
  regFr: "",
  regTo: "",
};

const { Option } = Select;
const { Panel } = AntCollapse;

/**
 * 관리자 > 만족도 조사 관리 > 만족도 조사 이력 페이지
 * 
 */

const SatisfactionHistory = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const pageSize = [10, 20, 50];

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      currentPage: 1,
      pageSize: +value,
    };
    setPageInfo({ ...searchObj });
    fetchHistoryList({ ...searchObj });
  }

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'rechDate';
    let orderBy = "";
    if (!sorter.order) {
      order = "rechDate"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    setPageInfo({ ...searchObj });
    fetchHistoryList({ ...searchObj });
  };


  const profile = useSelector((state) => state.get("auth").get("profile"));
  const [searchObject, setSearchObject] = useState({ ...initSearchObject });
  const [historyList, setHistoryList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    empNo: false,
  });
  const [sortedInfo, setSortedInfo] = useState({});

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const fetchHistoryList = async (page, searchValues) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    if (searchValues === undefined) {
      searchValues = { ...searchObject };
    }

    const empNo = searchValues.empNo.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum);
      return tmpArr;
    });
    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/admin/research/userResearchListMulVal`
      + `?page=${page.currentPage - 1}&size=${page.pageSize}&sort=${page.sort}${page.direction}`,
      {
        data: {
          empNo: empNo?.flat(),
          title: searchValues.title,
          regFr: searchValues.regFr,
          regTo: searchValues.regTo,
        },
      }
    );
    setTableLoading(false);
    if (result.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    }
    setHistoryList([...result.data.lists]);
    setPageInfo({ ...page, total: result.data.totalCount });
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true,
    });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    setSearchObject({
      ...searchObject,
      [modalLabel]: data,
    });
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setSearchObject({
      ...searchObject,
      [name]: value,
    });
  };

  const onClickSearch = () => {
    fetchHistoryList();
  };

  const onClickReset = () => {
    setSortedInfo({});
    setPageInfo({ ...initialPageInfo })
    setSearchObject({ ...initSearchObject });
    fetchHistoryList(initialPageInfo, initSearchObject);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDataRange(range);
  };

  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDataRange = ([regFr, regTo]) => {
    const tmp = { ...searchObject, regFr, regTo };
    setSearchObject(tmp);
  };

  const columns = [
    {
      key: 1,
      dataIndex: "title",
      width: 90,
      title: formatMessage({ id: "T0845" }), // 만족도 조사 제목
      sorter: (a, b) => a.title - b.title,
      sortOrder: sortedInfo.field === "title" && sortedInfo.order
    },
    {
      key: 2,
      dataIndex: "empNam",
      width: 70,
      title: formatMessage({ id: "T0847" }), // 참여자
      sorter: (a, b) => a.empNam - b.empNam,
      sortOrder: sortedInfo.field === "empNam" && sortedInfo.order
    },
    {
      key: 3,
      dataIndex: "point",
      width: 50,
      title: formatMessage({ id: "T0848" }), // 점수
      sorter: (a, b) => a.point - b.point,
      sortOrder: sortedInfo.field === "point" && sortedInfo.order
    },
    {
      key: 4,
      dataIndex: "comments",
      width: 400,
      title: formatMessage({ id: "T0849" }), // 만족도 의견
    },
    {
      key: 5,
      dataIndex: "rechDate",
      title: formatMessage({ id: "T0850" }), // 만족도 참여날짜
      width: 90,
      sorter: (a, b) => a.rechDate.localeCompare(b.rechDate),
      sortOrder: sortedInfo.field === "rechDate" && sortedInfo.order,
      render: (text) => moment(text, "YYYYMMDD").format("YYYY-MM-DD"),
    },
  ];

  useEffect(() => {
    fetchHistoryList();
  }, []);

  return (
    <div className="satisfaction_box history_box">
      <Collapse
        expandIconPosition="end"
        onChange={onChangeCollapse}
        expandIcon={() =>
          !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
        }
        activeKey={openCollapse ? ["1"] : []}
        defaultActiveKey={openCollapse ? ["1"] : []}
      >
        <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
          <FormTable pointBorder={false} size="sm">
            <colgroup>
              <col width="10.375%" />
              <col width="13.625%" />
              <col width="10.375%" />
              <col width="13.625%" />
            </colgroup>
            <tbody>
              <tr>
                <th style={{ borderRadius: "14px 0 0 0" }}>
                  <Text>{formatMessage({ id: "T0845" })}</Text>
                  {/* 만족도 조사 제목 */}
                </th>
                <td>
                  <Input
                    placeholder={formatMessage({ id: "T0845" })} // 만족도 조사 제목
                    style={{ width: 250 }}
                    name="title"
                    value={searchObject.title}
                    onChange={onChangeInput}
                    onKeyPress={onKeyPress}
                  />
                </td>
                <th><Text> {formatMessage({ id: "T0847" })}</Text></th>{/* 참여자 */}
                <td>
                  <InputSearch
                    onClick={onClickModalButton}
                    placeholder={formatMessage({ id: "T0847" })} //참여자
                    width={250}
                    height={32}
                    data-label="empNo"
                    name="empNam"
                    value={searchObject.empNo.map((c) => c.empNam).join(",")}
                  />
                </td>
              </tr>
              <tr>
                <th><Text>{formatMessage({ id: "T0851" })}</Text></th>{/* 참여 날짜 */}
                <td>
                  <RangePicker
                    style={{
                      width: 250,
                      alignItems: "center",
                    }}
                    onChangeDate={onChangeDates}
                    onCalendarChange={onChangeDates}
                    value={[
                      searchObject.regFr !== "" ? moment(searchObject.regFr) : "",
                      searchObject.regTo !== "" ? moment(searchObject.regTo) : "",
                    ]}
                    defaultValue={[undefined, undefined]}
                  />
                </td>
              </tr>
            </tbody>
          </FormTable>
          <Row style={{ padding: "0 13px" }}>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "0 25px",
                padding: "10px 0",
              }}
            >
              <Button width="120" height="40" onClick={onClickReset}>
                <FormattedMessage id="T0037" />{/* 초기화 */}
              </Button>
              <Button
                width="120"
                height="40"
                type="primary"
                onClick={onClickSearch}
              >
                <FormattedMessage id="T0039" />{/* 검색 */}
              </Button>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <div className="left" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <span>
          {/* 페이지당 행 */}
          {formatMessage({ id: "T0036" })} &emsp;
          <Select
            name="pagination"
            defaultValue={pageSize[0]}
            value={+pageInfo.pageSize}
            onSelect={onSelectPage}
          >
            {pageSize.map((v, index) => (
              <Option key={index} value={v}>
                {fmMessage({ id: "T1228", values: { number: v } })}
              </Option>
            ))}{/* {number} 개 */}
          </Select>
        </span>
      </div>
      <div>
        <Table
          rowKey={e => e.seq}
          columns={columns}
          dataSource={historyList}
          loading={tableLoading}
          showSorterTooltip={true}
          pagination={true}
          locale={{ emptyText: `${formatMessage({ id: "T0863" })}` }} // 업데이트 이력이 없습니다.
          pageOptions={{
            total: +pageInfo.total,
            current: +pageInfo.currentPage,
            pageSize: +pageInfo.pageSize,
          }}
          onChange={onChangeTableColumns}
        />
      </div>
      <Organization
        visible={modalVisible.empNo}
        companyCd={profile.COMP_CD}
        title={formatMessage({ id: "T1192" })} // 회원 조회
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={searchObject["empNo"]}
      />
    </div >
  );
};

export default SatisfactionHistory;
