import React from 'react';
import Wrapper from './Styled';

const Loading = ({ visible = false, position = "fixed", height = "100", minHeight = 'full', ...args }) => {

    return (
        <Wrapper className='loading__wrapper' position={position} height={height} minHeight={minHeight} visible={visible} {...args}>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </Wrapper>
    );
}

export default Loading;