import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Text,
  Button,
  UserTag,
  Modal,
  Table,
  PermissionComponent,
} from "components";
import { Row, Select, Col, Input as AntInput, Tooltip } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";
import { changeHistoryData } from "store/assignLookup/action";
import fetchAbsolute from "utils/fetchAbsolute";
import sendNoti from "utils/sendNoti";
import { getUniqueKey } from "utils/Str";
import { FormattedMessage, useIntl } from "react-intl";
import HFBpdIframe from "components/organisms/HFBpdIframe";
import HFBpdIframeModal from "components/atoms/HFBpdIframeModal";

const { Option } = Select;
const { TextArea } = AntInput;

//1. [접수] 기본 정보

const LookupReceipt = ({ history }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const profile = useSelector(state => state.get('auth').get('profile'));
  const assignLookup = useSelector((state) => state.get(getUniqueKey("assignLookup")));
  const menuList = useSelector((state) => state.get(getUniqueKey("menuList")).menuList);

  const columns = [
    {
      title: <FormattedMessage id="T1120" />, // 시스템
      dataIndex: "appNm",
      key: "appNm",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.1",
      dataIndex: "level1",
      key: "level1",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.2",
      dataIndex: "level2",
      key: "level2",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.3",
      dataIndex: "level3",
      key: "level3",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.4",
      dataIndex: "level4",
      key: "level4",
      align: "center",
      width: "16.6666%",
    },
    {
      title: "Lv.5",
      dataIndex: "level5",
      key: "level5",
      align: "center",
      width: "16.6666%",
    },
  ];


  //bpdData 
  const [selectedBpdId, setSetSelectedBpdId] = useState('');
  const [selectedBpd, setSelectedBpd] = useState({});

  // 담당자, 개발자 프로필
  const [mainProfile, setMainProfile] = useState({});
  const [subProfiles, setSubProfiles] = useState([]);
  const [regProfile, setRegProfile] = useState({});

  // 사용 메뉴 flat 하게 만든 배열
  const [usingMenu, setUsingMenu] = useState([]);

  // 과제 반려 모달
  const [reasonValue, setReasonValue] = useState({});
  const [rejectVisible, setRejectVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState(false);

  // 과제 진행 모달
  const [progressVisible, setProgressVisible] = useState(false);

  // Task 파일 목록
  const [taskFiles, setTaskFiles] = useState([]);

  const [hasBizflow, setHasBizflow] = useState(false);

  const reasonReturn = formatMessage({ id: "T0165" }); // 반려 사유를 적어주세요.
  const ReturnFail = formatMessage({ id: "T0166" }); // 과제 반려를 실패하였습니다.
  const developAcceptFail = formatMessage({ id: "T0167" }); // 개발 승인이 실패하였습니다.
  const taskMgrId = formatMessage({ id: "T0006" }); // 업무 담당자
  const selectOption = formatMessage({ id: "T0024" }); // 선택
  const confirmationText = formatMessage({ id: "T0064" }); // 확인

  const [stopReason, setStopReason] = useState([]);

  // 개발 반려 버튼 클릭 - 개발반려 모달 오픈
  const onClickRejectButton = () => {
    const fetchStopReason = async () => {
      const result = await fetchAbsolute("get", "/common/commonCd/getCategoryId/STOP_REASON");
      if (!result.error) {
        setStopReason([...result.data]);
      }
    }

    fetchStopReason();
    setRejectVisible(true);
  }

  const onCancelReject = () => setRejectVisible(false);
  const onChangeReject = (e) => setRejectReason(e.target.value);
  const onClickProgressModal = () => setProgressVisible(true);
  const onCancelProgress = () => setProgressVisible(false);
  // bpdIframeModal 추가
  const [bpdIframeVisible, setBpdIframeVisible] = useState(false);
  const [doubleCheckVisible, setDoubleCheckVisible] = useState(false);

  const onMaximizeIframe = () => setBpdIframeVisible(true);

  const onModifyBpdIframe = () => setBpdIframeVisible(true);
  const onOkBpdIframe = () => setDoubleCheckVisible(true);
  const onCancelBpdIframe = () => setBpdIframeVisible(false);
  const onOkDoubleCheck = () => {
    setBpdIframeVisible(false);
    setDoubleCheckVisible(false);
  }
  const onCancelDoubleCheck = () => setDoubleCheckVisible(false);

  // 개발 진행 모달 OK 버튼 이벤트
  const onOkProgress = async () => {
    const result = await fetchAbsolute("post", "/task/oracle/chgTask", {
      data: {
        taskId: assignLookup.taskData?.taskId,
        chgCd: "T10",
        chgCdDetail: "T12",
        reasonCd: "",
        chgEmpNo: profile?.EMP_NO,
        chgDetailDesc: formatMessage({ id: 'T1031' }), // 개발 승인
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) {
      return alert(developAcceptFail);
    }
    if (assignLookup.taskData?.taskType === "COE") {
      sendNoti("TP04", assignLookup.taskData?.taskId);
    }
    dispatch(changeHistoryData(result.data));
    setProgressVisible(false);
  };

  // 개발 반려 모달 OK 버튼 이벤트
  const onOkReject = async () => {
    if (reasonValue.operator === "ETC" && rejectReason.trim().length === 0) {
      rejectReason("");
      return alert(reasonReturn);
    }

    const result = await fetchAbsolute("post", "/task/oracle/chgTask", {
      data: {
        taskId: assignLookup.taskData?.taskId,
        chgCd: "T50",
        chgCdDetail: "T13", // 세부 코드 - 개발 반려
        reasonCd: reasonValue.cd,
        chgEmpNo: profile?.EMP_NO,
        chgDetailDesc:
        reasonValue.label === "ETC"
          ? rejectReason
          : reasonValue.description
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) {
      return alert(ReturnFail);
    }
    sendNoti("TP05", assignLookup.taskData?.taskId);

    dispatch(changeHistoryData(result.data));
    setRejectVisible(false);
    setRejectReason("");
  };

  const onClickModifyButton = () => {
    const path = `/assign/modify/${assignLookup.taskData?.taskId}`;
    history.push(path);
  };

  // Business Process Design
  const onSelectBpd = async (e) => {
    setSetSelectedBpdId(e);
    const selectedData = assignLookup.taskData.bpdList?.find(data => data.bpdId === e);
    setSelectedBpd(selectedData);
  }

  // 중단사유 select change
  const onChangeStopChange = (e, obj) => {
    setReasonValue({
      cd: e,
      description: obj.children,
      label :obj.label
    })
  }

  useEffect(() => {
    if (!assignLookup.taskData?.taskId) return;

    const fetchUser = (empNum) => {
      return fetchAbsolute("get", `/common/memberNoList?empNo=${empNum}`);
    };
    // 담당자 프로필 세팅
    const setMainInfo = async () => {
      const mainInfo = assignLookup.taskData?.taskMemberList.find(
        (member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "N"
      );
      const subList = assignLookup.taskData?.taskMemberList.filter(
        (member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "Y"
      );
      setSubProfiles([
        ...subList.map((v) => ({
          NAME_KOR: v.empNm,
          EMP_NO: v.empNo,
          DEPT_NAME_KOR: v.orgNm,
          FILE_ID: v.empFileId,
        })),
      ]);
      setMainProfile({
        NAME_KOR: mainInfo.empNm,
        EMP_NO: mainInfo.empNo,
        DEPT_NAME_KOR: mainInfo.orgNm,
        FILE_ID: mainInfo.empFileId,
      });
    };
    // 등록자 프로필 세팅
    const setRegInfo = async () => {
      const regResult = await fetchUser(assignLookup.taskData?.regId);
      const reg = regResult.data.mdFPAList[0];
      if (!reg) {
        return;
      }
      setRegProfile({
        NAME_KOR: reg.empNam,
        EMP_NO: reg.empNum,
        DEPT_NAME_KOR: reg.orgNam,
        FILE_ID: reg.fileId,
      });
    };
    // Task 파일 목록 가져오기
    const fetchFileList = async () => {
      const files = await fetchAbsolute(
        "get",
        `/file/listTaskFile/${assignLookup.taskData?.taskId}`
      );
      if (!files.error) {
        setTaskFiles(
          ...[files.data?.filter((file) => file.type.trim() === "001")]
        );
      }
    };

    /* flat하게 바꾸기 위해 concat 사용 */
    const tmpArr = [].concat(
      ...assignLookup.taskData?.taskUseSystemList.map((system) =>
        system.taskUseMenuList.map((menu) => ({
          appId: system.systemId,
          appNm: system.systemNm,
          menuNm:
            menu[
            Object.keys(menu)
              .filter((v) => v.includes("level"))
              .sort()
              .reverse()[0]
            ],
          ...menu,
          menuId: menu.mseq,
        }))
      )
    );
    setUsingMenu([...tmpArr]);

    setSelectedBpd(assignLookup.taskData.bpdList[0]);
    setSetSelectedBpdId(assignLookup.taskData.bpdList[0]?.bpdId);

    setMainInfo();
    setRegInfo();
    fetchFileList();
  }, [assignLookup.taskData]);

  useEffect(() => {
    if (menuList.length === 0) return;
    if (menuList.filter((menu) => menu.menuId === 1009 && menu.useYn === "Y").length > 0) {
      // BizFlow 메뉴가 있으면
      setHasBizflow(true);
    }
  }, [menuList]);

  return (
    <>
      <Modal
        width={540}
        visible={rejectVisible}
        title={formatMessage({ id: "T0500" })} // 개발 반려 사유 입력
        okText={formatMessage({ id: "T0066" })} // 입력
        onCancel={onCancelReject}
        onOk={onOkReject}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0501" })}{/* 과제 반려 사유 */}
        </Text>
        <Select
          value={reasonValue.cd}
          onChange={(e, obj) => onChangeStopChange(e, obj)}
          defaultValue=""
          id="stopReasonSelect"
          style={{ width: 215, marginBottom: 15, marginTop: 15 }}
          filterOption={stopReason.sort().map((v) => v.cd)}
        >
          <Option value="">{selectOption}</Option>
          {
          stopReason.map((v) => {
            return (
              <Option key={v.cd} value={v.cd} label={v.attrCd1}>
                {v.cdNm}
              </Option>
            );
          })}
        </Select>
        {reasonValue.label === "ETC" ? (
          <TextArea
            placeholder={formatMessage({ id: "T0502" })} // 개발 반려 사유를 입력해주세요.
            autoSize={{ minRows: 4, maxRows: 4 }}
            onChange={onChangeReject}
            value={rejectReason}
            maxLength="500"
          />
        ) : (
          ""
        )}
      </Modal>
      <Modal
        centered
        visible={progressVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0503" })} // 개발 진행 확인
        onOk={onOkProgress}
        onCancel={onCancelProgress}
      >
        <Text>{formatMessage({ id: "T0504" })}</Text>{/* 개발을 진행하시겠습니까? */}
      </Modal>
      {assignLookup.taskData?.statusCd === "T50" ? (
        <div className="basic_box status__disabled">
          <div className="basic_box__title">
            <Text className="sub_box__title">
              {formatMessage({ id: "T0491" })}{/* 1. [접수] 기본 정보 */}
            </Text>
          </div>
          <div className="basic_box__info">
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0429" })}{/* 등록자 */}
                </Text>
              </Col>
              <Col span={19}>
                <UserTag profile={regProfile} contextVisible={true}/>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">{taskMgrId}(Main)</Text>
              </Col>
              <Col span={19}>
                <UserTag profile={mainProfile} contextVisible={true}/>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">{taskMgrId}(Sub)</Text>
              </Col>
              <Col span={19} className="basic_row__content">
                {subProfiles.map((member, index) => (
                  <UserTag profile={{ ...member }} key={index} contextVisible={true}/>
                ))}
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0431" })}{/* 업무 정보 */}
                </Text>
              </Col>
              <Col span={19}>
                <div className="work_box__wrap">
                  <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0432" })}{/* 동일 또는 유사 업무 구성원 */}
                    </Text>
                    <Text className="work_box__value">
                      {assignLookup.taskData?.memberCnt}&nbsp;
                      {formatMessage({ id: "T0433" })}{/* 명 */}
                    </Text>
                  </div>
                  <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0492" })}{/* 1회 평균 수행 시간 */}
                    </Text>
                    <Text className="work_box__value">
                      {assignLookup.taskData?.avgExeTime}
                      {formatMessage({ id: "T0493" })}{/* 분 */}
                    </Text>
                  </div>
                  <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0436" })}{/* 평균 업무 수행 횟수 */}
                    </Text>
                    <Text className="work_box__value">
                      <FormattedMessage
                        id="T1133"
                        values={{
                          cnt: assignLookup.taskData?.avgExeCnt,
                        }}
                      />
                      {/* 일/{cnt}회 */}
                    </Text>
                  </div>
                  {/* <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0444" })} {/*내/외부 규정 준수 여부
                    </Text>
                    <Text className="work_box__value">
                      {assignLookup.taskData?.ruleKeepYn === "Y"
                        ? `${formatMessage({ id: "T0445" })}`
                        : `${formatMessage({ id: "T0495" })}`}
                    </Text>
                  </div> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  <FormattedMessage id="T0496" />{/* 업무 시스템 */}
                </Text>
              </Col>
              <Col span={19}>
                {assignLookup.taskData.taskUseSystemList?.map(
                  (system, index) => (
                    <Text key={index} className="work_system">
                      {index > 0 && " / "}&nbsp;
                      {system.inOutCd === "O" ? (
                        <>
                          <div className="work_system__icon outside">
                            <FormattedMessage id="T0498" />{/* 외부 */}
                          </div>
                          &nbsp;
                          <Tooltip title={system.outSystemLink}>
                            <Text type="link" link={system.outSystemLink}>
                              {system.systemNm}
                            </Text>
                          </Tooltip>
                        </>
                      ) : (
                        <div className="work_system__icon inside">
                          <FormattedMessage id="T0497" />{/* 내부 */}
                          &nbsp;
                          {system.systemNm}
                        </div>
                      )}
                    </Text>
                  )
                )}
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0499" })}{/* 시스템 사용 메뉴 */}
                </Text>
              </Col>
              <Col span={19} className="table_disabled">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={usingMenu}
                  style={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0449" })}{/* 참고자료 */}
                </Text>
              </Col>
              <Col span={19} className="reference__file">
                {taskFiles.map((file, index) => {
                  return (
                    file.type === "001" && (
                      <div className="file__list" key={index}>
                        <PaperClipOutlined />
                        {file.fileName}
                      </div>
                    )
                  );
                })}
              </Col>
            </Row>
            {
              hasBizflow && assignLookup.taskData.bpdList?.length > 0 &&
              <Row>
                <Col span={5} style={{ alignSelf: 'center' }}>
                  <Text className="basic_row__title">
                    Business Process Design
                  </Text>
                </Col>
                <Col span={19}>
                  <Select
                    style={{ width: '100%', marginBottom: 15, marginTop: 15 }}
                    defaultValue=''
                    onSelect={onSelectBpd}
                    disabled
                  >
                    <Option key='1' value=''>선택</Option>
                  </Select>
                </Col>
              </Row>
            }
            <Row>
              <Col span={9} offset={15} className="receipt__button_group">
                <PermissionComponent fnCode="FN02">
                  <Button
                    className="block__button"
                    type="default"
                    width="120"
                    disabled
                  >
                    <FormattedMessage id="T0054" />{/* 수정 */}
                  </Button>
                </PermissionComponent>
                {assignLookup.taskData?.statusCd === "T10" &&
                  assignLookup.taskData?.detailStatusCd === "T11" && (
                    <>
                      <PermissionComponent fnCode="FN09">
                        <Button
                          type="primary"
                          width="120"
                          onClick={onClickProgressModal}
                        >
                          <FormattedMessage id="T0055" />{/* 개발 진행 */}
                        </Button>
                      </PermissionComponent>
                      <PermissionComponent fnCode="FN11">
                        <Button
                          type="primary"
                          width="120"
                          onClick={onClickRejectButton}
                        >
                          <FormattedMessage id="T0056" />{/* 개발 반려 */}
                        </Button>
                      </PermissionComponent>
                    </>
                  )}
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div className="basic_box">
          <div className="basic_box__title">
            <Text>{formatMessage({ id: "T0491" })}</Text>{/* 1. [접수] 기본 정보 */}
          </div>
          <div className="basic_box__info">
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0429" })}{/* 등록자 */}
                </Text>
              </Col>
              <Col span={19}>
                <UserTag profile={regProfile} contextVisible={true} />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">{taskMgrId}(Main)</Text>
              </Col>
              <Col span={19}>
                <UserTag profile={mainProfile} contextVisible={true} />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">{taskMgrId}(Sub)</Text>
              </Col>
              <Col span={19} className="basic_row__content">
                {subProfiles.map((member, index) => (
                  <UserTag profile={{ ...member }} key={index} contextVisible={true}/>
                ))}
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0431" })}{/* 업무 정보 */}
                </Text>
              </Col>
              <Col span={19}>
                <div className="work_box__wrap">
                  <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0432" })}{/* 동일 또는 유사 업무 구성원 */}
                    </Text>
                    <Text className="work_box__value">
                      {assignLookup.taskData?.memberCnt}&nbsp;
                      {formatMessage({ id: "T0433" })}{/* 명 */}
                    </Text>
                  </div>
                  <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0492" })}{/* 1회 평균 수행 시간 */}
                    </Text>
                    <Text className="work_box__value">
                      {assignLookup.taskData?.avgExeTime}
                      {formatMessage({ id: "T0493" })}{/* 분 */}
                    </Text>
                  </div>
                  <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0436" })}{/* 평균 업무 수행 횟수 */}
                    </Text>
                    <Text className="work_box__value">
                      {
                        [
                          {
                            value: "daily",
                            text: formatMessage({ id: "T0437" }), // 일
                          },
                          {
                            value: "weekly",
                            text: formatMessage({ id: "T0438" }), // 주
                          },
                          {
                            value: "monthly",
                            text: formatMessage({ id: "T0439" }), // 월
                          },
                          {
                            value: "quarterly",
                            text: formatMessage({ id: "T0440" }), // 분기
                          },
                          {
                            value: "half",
                            text: formatMessage({ id: "T0441" }), // 반기
                          },
                          {
                            value: "yearly",
                            text: formatMessage({ id: "T0442" }), // 연
                          },
                        ].find(
                          (v) => v.value === assignLookup.taskData?.avgExeCycle
                        )?.text
                      }
                      <FormattedMessage
                        id="T1132" // /{cnt}회
                        values={{
                          cnt: assignLookup.taskData?.avgExeCnt,
                        }}
                      />
                    </Text>
                  </div>
                  {/* <div className="work_box">
                    <Text className="work_box__title">
                      {formatMessage({ id: "T0444" })} {/*내/외부 규정 준수 여부
                    </Text>
                    <Text className="work_box__value">
                      {assignLookup.taskData?.ruleKeepYn === "Y"
                        ? `${formatMessage({ id: "T0445" })}`
                        : `${formatMessage({ id: "T0495" })}`}
                    </Text>
                  </div> */}
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  <FormattedMessage id="T0496" />{/* 업무 시스템 */}
                </Text>
              </Col>
              <Col span={19}>
                {assignLookup.taskData.taskUseSystemList?.map(
                  (system, index) => (
                    <Text key={index} className="work_system">
                      {index > 0 && "/ "}&nbsp;
                      {system.inOutCd === "O" ? (
                        <>
                          <div className="work_system__icon outside">
                            <FormattedMessage id="T0498" />{/* 외부 */}
                          </div>
                          &nbsp;
                          <Tooltip title={system.outSystemLink}>
                            <Text type="link" link={system.outSystemLink}>
                              {system.systemNm}
                            </Text>
                          </Tooltip>
                        </>
                      ) : (
                        <div className="work_system__icon inside">
                          <FormattedMessage id="T0497" />{/* 내부 */}
                          &nbsp;
                          {system.systemNm}
                        </div>
                      )}
                    </Text>
                  )
                )}
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  <FormattedMessage id="T0499" />{/* 시스템 사용 메뉴 */}
                </Text>
              </Col>
              <Col span={19}>
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={usingMenu}
                  style={{
                    width: "100%",
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0449" })}{/* 참고자료 */}
                </Text>
              </Col>
              <Col span={19} className="reference__file">
                {taskFiles.map((file, index) => {
                  return (
                    file.type === "001" && (
                      <Text
                        key={index}
                        type='download'
                        className="file__list"
                        download={file.down}
                        fileName={file.fileName}
                      >
                        <PaperClipOutlined />
                        {file.fileName}
                      </Text>
                    )
                  );
                })}
              </Col>
            </Row>
            {
              hasBizflow && assignLookup.taskData.bpdList?.length > 0 &&
              <>
                <Row>
                  <Col span={5}>
                    <Text className="basic_row__title">
                      Business Process Design
                    </Text>
                  </Col>
                  <Col span={19}>
                    <div className="bpd_list">
                      <Select
                        style={{ width: '100%' }}
                        onSelect={onSelectBpd}
                        value={selectedBpdId ? selectedBpdId : assignLookup.taskData.bpdList[0].bpdId}
                      >
                        {
                          assignLookup.taskData.bpdList.map(bpd => {
                            return (
                              <Option key={bpd.bpdId} value={bpd.bpdId}>[{bpd.bpdId}] {bpd.bpdNm}</Option>
                            )
                          })
                        }
                      </Select>
                      <Button
                        type="primary"
                        onClick={onMaximizeIframe}
                      >
                        <FormattedMessage id="T1650" />{/* 크게 보기 */}
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <HFBpdIframe
                    profile={profile}
                    type='lookup'
                    data={selectedBpd}
                    params={selectedBpdId}
                  />
                </Row>
              </>
            }
            <Row>
              <Col span={9} offset={15} className="receipt__button_group">
                <PermissionComponent fnCode="FN02">
                  <Button
                    type="default"
                    width="120"
                    onClick={onClickModifyButton}
                  >
                    <FormattedMessage id="T0054" />{/* 수정 */}
                  </Button>
                </PermissionComponent>
                {assignLookup.taskData?.statusCd === "T10" &&
                  assignLookup.taskData?.detailStatusCd === "T11" && (
                    <>
                      {/* <PermissionComponent fnCode='FN09'> */}
                      <Button
                        type="primary"
                        width="120"
                        onClick={onClickProgressModal}
                      >
                        <FormattedMessage id="T0055" />{/* 개발 진행 */}
                      </Button>
                      {/* </PermissionComponent> */}
                      <PermissionComponent fnCode="FN11">
                        <Button
                          type="primary"
                          width="120"
                          onClick={onClickRejectButton}
                        >
                          <FormattedMessage id="T0056" />{/* 개발 반려 */}
                        </Button>
                      </PermissionComponent>
                    </>
                  )}
              </Col>
            </Row>
          </div>
        </div >
      )}
      <HFBpdIframeModal
        type='lookup'
        data={selectedBpd}
        params={selectedBpdId}
        visible={bpdIframeVisible}
        doubleCheckVisible={doubleCheckVisible}
        onModifyBpdIframe={onModifyBpdIframe}
        onOkBpdIframe={onOkBpdIframe}
        onCancelBpdIframe={onCancelBpdIframe}
        onOkDoubleCheck={onOkDoubleCheck}
        onCancelDoubleCheck={onCancelDoubleCheck}
      />
    </>
  );
};

export default withRouter(LookupReceipt);