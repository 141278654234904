import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
    background: #fff;
    .admin_devicepool_top {
        display: flex;
        gap: 19px; 
        padding: 14.6px 0px 10.5px 0px;
        align-items: center;
    }
    .admin_member__button_group {
        display: flex; 
        justify-content: flex-end;
        gap: 0 25px;
    }
    tr:hover {
        text-decoration:underline
    }
    tr {
        cursor: pointer
    }
    .ant-row {
        display: block; !important
    }
    thead[class*="ant-table-thead"] th{
        font-weight: bold;
       
      }
`;

export default Wrapper;
