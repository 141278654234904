import React, { useEffect, useState } from 'react';
import { Tree, List } from 'antd';
import fetchAbsolute from 'utils/fetchAbsolute';
import { CloseCircleFilled } from '@ant-design/icons';
import { Input } from 'components';
import Wrapper from './Styled';
import { useIntl } from "react-intl";
import { useSelector } from 'react-redux';
/**
 * Filename: DepartmentList.js
 * Description: 담당부서 컴포넌트이다.
 */

const DepartmentList = ({ visible, title: customTitle, onOk = () => { }, onCancel = () => { }, mode = '', defaultData = [], }) => {
    /**
     * State 변수 선언
     */
    const { formatMessage } = useIntl();
    const profile = useSelector(state => state.get('auth').get('profile'));
    const [title] = useState(customTitle || formatMessage({ id: 'T0341' })); // 부서 검색
    const [allDepartmentDatas, setAllDepartmentDatas] = useState([]);
    const [departmentDatas, setDepartmentDatas] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [expand, setExpand] = useState([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isMultiple, setIsMultiple] = useState(false);

    // 조직도 팀 클릭 시 실행하는 이벤트
    const onSelectTree = async (codeIds) => {
        setSelectedKeys(codeIds);
    }

    const onSelectList = (codeId) => {
        if (mode) {
            let arr = [...selectedKeys].concat(codeId);
            /* 2번 같은 걸 눌렀다는 건 선택 해제 */
            let count = 0;
            for (let i = 0; i < arr.length; i++) {
                if (arr[i] === codeId) {
                    count++;
                }
            }
            if (count === 2) {
                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] === codeId) {
                        arr.splice(i, 1);
                        i--;
                    }
                }
            }
            setSelectedKeys([...arr]);
        } else {
            if (selectedKeys.length > 0 && selectedKeys[0] === codeId[0]) {
                setSelectedKeys([]);
            } else {
                setSelectedKeys(codeId);
            }
        }
    }

    const onClickOk = () => {
        if (mode) {
            let tmpArr = [];
            for (let i in selectedKeys) {
                const result = departmentDatas.filter(data => data.codeId === selectedKeys[i]);
                tmpArr.push(...result)
            }
            onOk(tmpArr.length !== 0 ? tmpArr : []);
        } else {
            const result = departmentDatas.find(data => data.codeId === selectedKeys[0]);
            onOk(result ? result : {});
        }
    }
    const onChangeSearchKeyword = (e) => {
        setSearchKeyword(e.target.value);
    }

    const onKeyPressKeyword = (e) => {
        const tmpDepartment = allDepartmentDatas.filter(v => (v.codeNm.toLowerCase()).includes(searchKeyword.toLowerCase()));
        setIsSearch(true);
        setSearchList([...tmpDepartment]);
    }

    const onClickSearchReset = () => {
        setSearchKeyword('');
        setIsSearch(false);
        // setSelectedKeys([]);
        // setSearchList([]);
    }

    const getDepartmentList = async () => {
        if (!visible) return;

        setLoading(true);
        const result = await fetchAbsolute('get', `/common/departmentList`);
        setLoading(false);

        setAllDepartmentDatas([...result.data?.mdList]);

        if (profile.DWP_USER_LEVEL === "UA06" || profile.DWP_USER_LEVEL === "UA05") {
            const result = await fetchAbsolute('get', `/common/departmentList`);
            setLoading(false);
            setAllDepartmentDatas([...result.data?.mdList]);
        } else {
            const result = await fetchAbsolute('get', `/common/departmentList`);
            setLoading(false);
            setAllDepartmentDatas([...result.data?.mdList]);
        }
    };

    useEffect(() => {
        setDepartmentDatas([...allDepartmentDatas]);
    }, [allDepartmentDatas]);

    useEffect(() => {
        const tmpDatas = [];
        let lastArray;
        // 레벨에 맞는 배열 가져오기 위한 재귀함수
        const getLevelArray = (array, level, count = 1) =>
            +level === count ? array : getLevelArray(array[array.length - 1].children, level, count + 1);

        departmentDatas.forEach((value) => {
            lastArray = getLevelArray(tmpDatas, value.level);
            const data = {
                title: value.codeNm,
                key: value.codeId,
                value: value.codeId, // treeSelect
                level: value.level,
                children: []
            };
            if (!lastArray.children) lastArray.push(data)
            else lastArray.children.push(data);
        });
        setTreeData(tmpDatas)
    }, [departmentDatas]);

    useEffect(() => {
        if (!visible) return;
        setSearchKeyword('');
        setIsSearch(false);
        getDepartmentList();
        // 초기화 눌렀을 때 자료 초기화되게 
        if (defaultData.length < 1) {
            setSelectedKeys([]);
            setSearchList([]);
        } else {
            const keys = defaultData.map(c => c.codeId)
            setSelectedKeys([...keys]);
            // tree가 안열려서 자식일 때 부모가 열려 있어야 한다.
            const parent = defaultData.filter(c => c.level !== 1).map(c => c.codeIdUpper);
            setExpand(parent);
        }
    }, [visible, defaultData]);

    /* 과제 목록 복수 검색  */
    useEffect(() => {
        if (!mode) return;
        setIsMultiple(true);
    }, [mode]);

    return (
        <Wrapper width={600} visible={visible} title={title} onOk={onClickOk} onCancel={onCancel}>
            {
                <div className='orga_wrapper'>
                    <div className='orga_section'>
                        <div className='orga_search__wrap'>
                            <Input
                                value={searchKeyword}
                                onChange={onChangeSearchKeyword}
                                placeholder={formatMessage({ id: 'T0342' })}//부서 이름을 검색하세요
                                className='orga_search'
                                onPressEnter={onKeyPressKeyword}
                                style={{ height: 32 }}
                            />
                            <CloseCircleFilled
                                className='input_clear'
                                onClick={onClickSearchReset}
                            />
                        </div>
                        <div className='orga_box'>
                            <div className='orga_content'>
                                {
                                    isSearch ?
                                        <List
                                            dataSource={searchList}
                                            renderItem={department => {
                                                /* 선택된 아이템 css 수정 */
                                                let matchCondition = ''
                                                const checkCode = selectedKeys.find(key => key === department.codeId);
                                                if (checkCode) {
                                                    matchCondition = 'orga_content__select_list'
                                                };
                                                return (
                                                    <List.Item
                                                        className={'orga_content__list ' + matchCondition}
                                                        onClick={() => onSelectList(department.codeId)}
                                                        key={department.codeId}
                                                    >
                                                        {department.codeNm}
                                                    </List.Item>
                                                )
                                            }}
                                        />
                                        :
                                        <Tree
                                            defaultExpandedKeys={expand}
                                            expandedKeys={expand}
                                            // autoExpandParent={true}
                                            selectedKeys={selectedKeys}
                                            onSelect={onSelectTree}
                                            onExpand={(e) => setExpand(e)}
                                            multiple={isMultiple}
                                            treeData={treeData}
                                        />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </Wrapper>
    )
}

export default DepartmentList;