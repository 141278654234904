import React from "react";
import { getUniqueKey } from "utils/Str";
import { useSelector } from "react-redux";
import { Row, Col } from "antd";
import {
  Text,
  UserTag,
} from "components";
import { SwapRightOutlined } from "@ant-design/icons";
import "moment/locale/ko";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

const ResultForm = ({
  analyticalDatas
}) => {
  const { formatMessage } = useIntl();
  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));


  return (
    <div className="basic_box">
      <div className="basic_box__title">
        <Text><FormattedMessage id="T1627" />{/* 2. [개발] 개발 시작 */}</Text>
      </div>
      <div className="basic_box__info">
        <Row>
          <Col span={5}>
            <Text className="basic_row__title">
              <FormattedMessage id="T1639" />{/* 개발자(Main) */}
            </Text>
          </Col>
          <Col span={19} className="basic_row__content">
            {
              hfWorkflowLookup.workflowData.wfMemberHFList?.filter(
                  (member) =>
                    member.mgrDevGbn === "D" && member.deputyHeadYn === "N"
                )
                .map((member, index) => {
                  return (
                    <UserTag
                      profile={{
                        NAME_KOR: member.empNm,
                        EMP_NO: member.empNo,
                        DEPT_NAME_KOR: member.orgNm,
                        FILE_ID: member.empFileId,
                      }}
                      contextVisible={true}
                      key={index}
                    />
                  );
                })
              }
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Text className="basic_row__title">
              <FormattedMessage id="T1640" />{/* 개발자(Sub) */}
            </Text>
          </Col>
          <Col span={19} className="basic_row__content">
              {
                hfWorkflowLookup.workflowData.wfMemberHFList
                  ?.filter(
                    (member) =>
                      member.mgrDevGbn === "D" && member.deputyHeadYn === "Y"
                  )
                  .map((member, index) => {
                    return (
                      <UserTag
                        profile={{
                          NAME_KOR: member.empNm,
                          EMP_NO: member.empNo,
                          DEPT_NAME_KOR: member.orgNm,
                          FILE_ID: member.empFileId,
                        }}
                        contextVisible={true}
                        key={index}
                      />
                    );
                })
              }
          </Col>
        </Row>

        <Row>
          <Col span={5}>
            <Text className="basic_row__title">
              <FormattedMessage id="T0507" /> {/* 개발 일정 */}
            </Text>
          </Col>
          <Col span={19} style={{paddingLeft:"5px"}}>
            <Text className="analytical__text">
              {moment(analyticalDatas.devSchedule[0]).format("YYYY-MM-DD(dd)")}
            </Text>
            <SwapRightOutlined />
            <Text className="analytical__text">
              {moment(analyticalDatas.devSchedule[1]).format("YYYY-MM-DD(dd)")}
            </Text>
            <Text className="analytical__text">
              &nbsp;|&nbsp;{formatMessage({ id: "T1225" })}&nbsp;
              {moment(analyticalDatas.devSchedule[1]).diff(
                moment(analyticalDatas.devSchedule[0]),
                "days"
              ) +
                1 +
                `${formatMessage({ id: "T0437" })}`}{/* 일 */}
            </Text>
            <Text className="analytical__text"></Text>
          </Col>
        </Row>

      </div>
    </div>
  );
};

export default ResultForm;