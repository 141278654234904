import React, { useState, useEffect } from "react";
import { Text, Image } from "components";
import ClosedCharacter from "../Img/itemShop_closed.png";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from 'react-intl';

const ShopClosed = ({ profile }) => {
  const { formatMessage } = useIntl();

  const [points, setPoints] = useState("");

  useEffect(() => {
    const fetchPoint = async () => {
      const result = await fetchAbsolute("get", `/academy/pointInfo?empNo=${profile.EMP_NO}`);
      setPoints(result.data);

    };
    fetchPoint();

  }, []);

  return (
    <div className="closed_section">
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <Text style={{ color: "white", fontWeight: "bold", fontSize: 30 }}>Item Shop</Text>
      </div>
      <div>
        <Text style={{ color: "white", fontSize: 22, fontWeight: "bold" }}>{formatMessage({ id: 'T0982' })}{/* 보유 포인트 : */}
          <Text style={{ color: "#53FF75", fontSize: 30, fontWeight: "bold" }}> {points?.toLocaleString()}pt</Text>
        </Text>
      </div>
      <div style={{ marginTop: 80 }}>
        <Image alt="" className="Itemshop_closed_img" src={ClosedCharacter} />
      </div>
      <div style={{ marginTop: 50, marginBottom: 50 }}>
        <Text style={{ color: "white", fontSize: 24 }}>
          {formatMessage({ id: "T0984" })
            .split("\n")
            .map((data) => {
              return (
                <span>
                  {data}
                  <br />
                </span>
              );
            })}{/* 현재 Item Shop은 영업을 하지 않습니다.


추후 공지를 참고하여 다시 방문해주세요! */}
        </Text>
      </div>
    </div>
  );
};

export default ShopClosed;
