import React, { useEffect, useState } from "react";
import { Text, Input, Button, FormTable, Upload } from "components";
import { Select } from "antd";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import { createBrowserHistory } from "history";
import { useIntl, FormattedMessage } from "react-intl";

const { Title } = Typography;
const { Option } = Select;

/**
 * Filename: RoundItemAdd/index.js
 * Description:RoundItemAdd
 * 관리자 > Item Shop 관리 > 회차별 상품 관리 > 상품 추가 페이지
 */

const RoundItemAdd = () => {
  const { formatMessage } = useIntl();
  const END_POINT = process.env.REACT_APP_API_URI;
  const history = createBrowserHistory({ forceRefresh: true });

  const [fileList, setFileList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [selectedRound, setSelectedRound] = useState("");
  const [defaultSelect, setDefaultSelect] = useState("");
  const [showYn, setShowYn] = useState("N");
  const [inputValue, setInputValue] = useState({
    product: "",
    price: "",
    qty: "",
  });

  const onClickAdd = async () => {
    const findroundseq = selectList.find((e) => e.round === selectedRound);

    const formData = new FormData();
    fileList.forEach((file) => formData.append("files", file));
    formData.append("roundSeq", findroundseq.roundSeq);
    formData.append("product", inputValue.product);
    formData.append("round", selectedRound);
    formData.append("price", inputValue.price);
    formData.append("qty", inputValue.qty);
    formData.append("showYn", showYn);

    const saveList = await fetchAbsolute(
      "post",
      `/academy/manageProductsByTimeSave`,
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (saveList.error) {
      return alert(formatMessage({ id: "T0235" })); //저장에 실패하였습니다.
    }
    alert(formatMessage({ id: "T0234" })); // 저장되었습니다.

    return history.push("/admin/jediitem/rounditemmanage");
  };

  const onChangeShowYn = (e) => {
    setShowYn(e);
  };
  const onSelectOption = (e) => {
    setSelectedRound(e);
  };

  const onBeforeupload = (inFile) => {
    const tmpArr = [inFile];
    setFileList([...tmpArr]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpArr]);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchRoundList = async () => {
      const result = await fetchAbsolute(
        "get",
        "/academy/manageRoundsViewList"
      );
      let cnt = result.data.manageRoundList.length;
      setSelectList([...result.data.manageRoundList]);
      setDefaultSelect(result.data.manageRoundList[cnt - 1].round);
      setSelectedRound(result.data.manageRoundList[cnt - 1].round);
    };
    fetchRoundList();
  }, []);

  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <Title className="title_name" level={4}>
          {formatMessage({ id: "T0834" })}{/* 상품 추가 */}
        </Title>
        <div className="update_content">
          <FormTable border={true}>
            <colgroup>
              <col width="15%" />
              <col width="35%" />
              <col width="15%" />
              <col width="35%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0835" })}</Text>{/* 회차 */}
                </th>
                <td>
                  <div>
                    <Select
                      style={{
                        width: '100%',
                        height: 32,
                      }}
                      className="round_select"
                      onChange={onSelectOption}
                      value={selectedRound || defaultSelect}
                    >
                      {selectList.map((e) => (
                        <Option value={e.round}>{e.round}</Option>
                      ))}
                    </Select>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0830" })}</Text>{/* 상품명 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      name="product"
                      value={inputValue.product}
                      onChange={onChangeInput}
                      placeholder={formatMessage({ id: "T0830" })} // 상품명
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0836" })}</Text>{/* 첨부 */}
                </th>
                <td colSpan={3}>
                  <Upload
                    action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                    multiple={false}
                    maxCount={1}
                    beforeUpload={onBeforeupload}
                    onRemove={onRemoveFile}
                    fileList={fileList}
                    accept={'.png, .jpg, .jpeg, .gif'}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0832" })}</Text>{/* 가격 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      tpye="number"
                      name="price"
                      value={inputValue.price}
                      onChange={onChangeInput}
                      placeholder={formatMessage({ id: "T0832" })} // 가격
                    />
                    &nbsp;Point
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0833" })}</Text>{/* 수량 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      tpye="number"
                      name="qty"
                      value={inputValue.qty}
                      onChange={onChangeInput}
                      placeholder={formatMessage({ id: "T0833" })} // 수량
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0827" })}</Text>{/* 노출 여부 입력 */}
                </th>
                <td>
                  <div>
                    <Select
                      id="showYn"
                      style={{
                        width: 200,
                      }}
                      defaultValue="N"
                      value={showYn || "N"}
                      onChange={onChangeShowYn}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 20,
        }}
      >
        <Button width="144" height="40" type="primary" onClick={onClickAdd}>
          <FormattedMessage id="T0049" />{/* 추가 */}
        </Button>
        &nbsp;&nbsp;
        <Link to={"/admin/jediitem/rounditemmanage"}>
          <Button width="144" height="40">
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default RoundItemAdd;
