import React, { useState, useEffect } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  SendMessage,
  Text,
  Image
} from "components";
import { useIntl } from 'react-intl';
import Wrapper from "./Styled";
import fetchAbsolute from "utils/fetchAbsolute";
import { useDispatch } from "react-redux";
import Image1 from "./AcademyImg/jediMaster_section1.png";
import Image3_1 from "./AcademyImg/Holy Jedi Stance.png";
import Image3_1_1 from "./AcademyImg/Holy Jedi Aura.png";
import Image3_2 from "./AcademyImg/Dark Jedi Stance.png";
import Image3_2_1 from "./AcademyImg/Dark Jedi Aura.png";
import Image4 from "./AcademyImg/jediMaster_section4.png";
import Image5 from "./AcademyImg/jediMaster_section5.png";
import ReturnToTop from "./AcademyImg/Return to top.png";
import Banner1 from "./AcademyImg/Jedi-Master-Banner-v1.png";
import Banner2 from "./AcademyImg/Jedi-Master-Banner-v2.png";
import Banner3 from "./AcademyImg/Jedi-Master-Banner-v3.png";
import Banner4 from "./AcademyImg/Jedi-Master-Banner-v4.png";
import Banner5 from "./AcademyImg/Jedi-Master-Banner-v5.png";
import Banner6 from "./AcademyImg/Jedi-Master-Banner-v6.png";
import Banner7 from "./AcademyImg/Jedi-Master-Banner-v7.png";
import { setVisible } from "store/loading/action";
import { EmailIcon, ProfileIcon } from "icons";

/**
 * Acamedy > Jedi Master (L5)
 */

// 변경된 api 추가, 여기에선 페이징 기능이 없지만 일단 수정
const initialPageInfo = {
  sort: "empNum",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 10,
  total: 0
};

const JediMasterAcademy = () => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const bannerList = [
    Banner1,
    Banner2,
    Banner3,
    Banner4,
    Banner5,
    Banner6,
    Banner7,
  ];

  const [smVisible, setSmVisible] = useState(false);
  const [empNo, setEmpNo] = useState("");
  const [infoMemberList, setInfoMemberList] = useState([]);
  const [idPhoto, setIdPhoto] = useState([]);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const onClickReturntoTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const onOkSendMessage = async () => {
    setSmVisible(false);
  };
  const onCancelSendMessage = () => {
    setSmVisible(false);
  };

  const onClickEmail = (e) => {
    setSmVisible(true);
    setEmpNo(e);
  };

  useEffect(() => {
    const fetchUserList = async (page) => {
      if (page === undefined) {
        page = { ...pageInfo }
      };
      dispatch(setVisible(true));
      const result = await fetchAbsolute(
        "get",
        `/academy/getAcademyList?lastLev=5&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
      );
      dispatch(setVisible(false));
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

      const { academyList } = result.data;

      const tmpArr = [];
      for (let i = 0; i < result.data.totalItemsCount; i++) {
        if (!result.data.academyList[i].fileId) return;
        const fileResult = await fetchAbsolute('get', `/file/listFile/${result.data.academyList[i].fileId}`);
        tmpArr.push(fileResult.data[0]);
        const datas = academyList.map((v) => ({
          ...v,
          link: tmpArr.filter(c => c.fileId === v.fileId)[0]?.link || ''
        }));
        setInfoMemberList([...datas]);
      }
    };
    fetchUserList();
  }, []);

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      isPageHeader={false}
      bodyColor="#000"
      footerColor="#000"
    >
      <SendMessage
        visible={smVisible}
        onOk={onOkSendMessage}
        onCancel={onCancelSendMessage}
        toEmpNo={empNo}
      />
      <Wrapper style={{ width: 1700, background: "black" }}>
        <div className="section section1">
          <Image alt="jedi master section1 img" src={Image1} />
          <p className="section_text">
            {formatMessage({ id: "T0971" }).split("\n").map((data, index) => {
              return (
                <span key={index}>
                  {data}
                  <br />
                </span>
              );
            })}{/* Jedi Master의 길은 선택 하는것이 아니다. Jedi Master의 길이 당신을 선택할 것이다. */}
          </p>
        </div>
      </Wrapper>
      <Wrapper style={{ background: "black" }}>
        {/* 두번째 섹션:S */}
        <div className="section section2">
          <p className="jedi_master__title">
            {formatMessage({ id: 'T0972' })}{/* Dreago를 이끄는 Jedi Master들을 소개합니다 */}
          </p>
          <div className="member_wrap">
            {infoMemberList.map((v, index) => (
              <div className="member_box">
                <Image
                  className="member_background"
                  alt="banner"
                  src={bannerList[index % 7]}
                />
                {(v.dwpUserLevel === "UA05" || v.dwpUserLevel === "UA06") && (
                  <div className="member_nametag"> {formatMessage({ id: 'T0973' })}</div>
                )}{/* 전사 RPA CoE */}
                <div className="member_image">
                  {
                    v.link ?
                      <Image
                        src={v.link}
                        width={70}
                        height={70}
                        style={{ borderRadius: '50%' }}
                      />
                      :
                      <ProfileIcon
                        style={{ width: 70, height: 70 }}
                      />
                  }
                </div>
                <div className="member_namecard">
                  <p className="member_name">{v.empNam}</p>
                  <p className="member_empNo">{v.empNum}</p>
                </div>
                <div className="member_org">
                  <p className="member_orgnm">{v.orgNam}</p>
                </div>
                <EmailIcon
                  className="member_email"
                  onClick={(e) => onClickEmail(v.empNum)}
                />
              </div>
            ))}
          </div>
          <div></div>
        </div>
        {/* 두번째 섹션:E */}
        {/* 세번째 섹션:S */}
        <div className="section section3">
          <div className="section_left__box">
            <p className="section_left__title jedi_master__title">
              {formatMessage({ id: 'T0974' })}{/* 선택받은 구성원만이 Jedi Master가 될 수 있습니다. */}
            </p>
            <p className="section_left__content">
              {formatMessage({ id: "T0975" }).split("\n").map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* Jedi Master는 Dreago Academy에서 활발한 활동을 보여주고 다른
구성원들이 업무 자동화에 어려움을 겪을 시 앞장서서 도와주는 Jedi
Knight에게 선택받을 자격이 주어집니다.

 Jedi Master 의회에서 가장 눈에띄는 Jedi Knight을 선정하고, 그에게
Jedi Master의 길을 걸을 기회를 부여하게 됩니다.
 Jedi Master로 승급 시, 악성 업무와의 수많은 전투로 인하여 자동화의
경지에 이른 Holy Jedi Master,
그리고 업무에 대한 증오심으로 인하여 악성 업무를 무자비하게
처치해온 Dark Jedi Master 중 택하여 승급 할 수 있습니다. */}
            </p>
          </div>
          <div className="section_right__box">
            <div className="section_right__char_box">
              <div className="section_right__char_image">
                <Image
                  className="section_right__char_aura"
                  alt="Holy Jedi Aura"
                  src={Image3_1_1}
                />
                <Image alt="Holy Jedi Master" src={Image3_1} />
              </div>
              <span className="section_right__char_label">
                Holy Jedi Master
              </span>
            </div>
            <div className="section_right__char_box">
              <div className="section_right__char_image">
                <Image
                  className="section_right__char_aura"
                  alt="Dark Jedi Aura"
                  src={Image3_2_1}
                />
                <Image alt="Dark Jedi Master" src={Image3_2} />
              </div>
              <span className="section_right__char_label">
                Dark Jedi Master
              </span>
            </div>
          </div>
        </div>
        {/* 세번째 섹션:E */}
        {/* 네번째 섹션:S */}
        <div className="section section4">
          <p className="jedi_master__title">
            {formatMessage({ id: 'T0976' })}{/* Jedi Master는 다음과 같은 역량을 가지고 있습니다 */}
          </p>
          <Image alt='' src={Image4} />
          <p className="section_text">
            {formatMessage({ id: "T0977" })
              .split("\n")
              .map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 그들에게 불가능이란 없습니다.

자동화 기준에 적합하지 않거나 사내규정을 위반하는 사항을 제외하고

Jedi Master는 Dreago Academy의 선봉에 서서 하이닉스 업무의 자동화를 이끌고,

 Jedi Master는 DT 담당 및 전사 RPA 전문가로서 활동할 수 있습니다.
 */}
          </p>
        </div>
        {/* 네번째 섹션:E */}
        {/* 다섯번째 섹션:S */}
        <div className="section section5">
          <Image alt='Light saber' src={Image5} />
          <p className="section_text">
            {formatMessage({ id: "T0978" })
              .split("\n")
              .map((data, index) => {
                return (
                  <span key={index}>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 당신에게도 Jedi Master의 길이 열릴 수 있습니다.

 아무나 가질 수 있는 이 광선검이 당신의 손에 쥐어지는 날까지

 Dreago Academy 군단에서의 활발한 활동 기대하겠습니다. */}
          </p>
        </div>
        {/* 다섯번째 섹션:E */}
        {/* 위로가기 섹션:S */}
        <div className="section section6">
          <a onClick={onClickReturntoTop} href="#!">
            <Image alt='go to top' src={ReturnToTop} />
            <Text className="academy_return_to_top">{formatMessage({ id: 'T0905' })}</Text>{/* 위로가기 */}
          </a>
        </div>
        {/* 위로가기 섹션:E */}
      </Wrapper>
    </PageTemplate>
  );
};

export default JediMasterAcademy;
