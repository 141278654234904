import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadTaskPermission } from "store/permission/action";
import { loadTaskData, resetTaskData } from "store/assignLookup/action";
import { useIntl } from "react-intl";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import { PageTemplate, Header, Footer } from "components";
import AssignLookupHeader from './LookupHeader';
import AssignLookupBody from './LookupBody';

/**
 * Filename: AssignLookup.js
 * Description: 과제 조회 페이지 총괄 컴포넌트
 * Tabs : 기본정보(basic), 개발환경(env), 운영현황(status)
 */

const AssignLookup = ({
  match: { params },
}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const profile = useSelector(state => state.get('auth').get('profile'));
  const taskData = useSelector(state => state.get(getUniqueKey('assignLookup')).taskData);

  useEffect(() => {
    if (!taskData.taskId) return;

    dispatch(
      loadTaskPermission({
        taskId: taskData.taskId,
        taskType: taskData.taskType,
      })
    );
  }, [taskData.taskId]);

  useEffect(() => {
    dispatch(loadTaskData(params.taskId));
    const fetchReadCount = async () => {
      const data = {
        taskId: params.taskId,
        empNo: profile.EMP_NO,
      };
      // 동일 사번은 1번으로 카운트
      const result = await fetchAbsolute("post", "/task/oracle/taskReadCnt", {
        data,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    };
    fetchReadCount();
    return () => {
      dispatch(resetTaskData());
    }
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />}>
      <AssignLookupHeader/>
      <AssignLookupBody />
    </PageTemplate>
  );
};

export default AssignLookup;