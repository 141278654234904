import styled from 'styled-components';

const Wrapper = styled.div` 
height: 100vh;
overflow: hidden;
background-repeat: no-repeat;
background-size: cover;
display: flex;
    .form {
        display: flex;
        align-items: center;
        width: 1400px;
        height: 600px; 
        margin: 0 auto;
        margin-top: 170px; 
        box-shadow: 0 0 2px rgb(0 0 0 / 19%), 0 7px 12px rgb(0 0 0 / 20%);
    }
    .dwp_logo__background { 
        width: 570px;
        height: 100%;
    } 
    .dwp_logo__background img:last-child {
        position: relative;
        width: 135px;
        height: 37px;
        margin: 175px 70px 0 70px;
    }
    // 2023-04-19 문구 추가
    .dwp_logo__background .contact {
        position: relative;
        color: #ffcd00;
        font-size: 17px;
        letter-spacing: -0.3px;
        margin: 175px 70px 0 70px;
    }
    .dwp_logo__background .contact > a {
        color:#fff !important;
    }
    .dwp_logo__background .contact > a:hover {
        color:#fff !important;
    }
    .ant-input:hover {
        /* border-color: #0067ac; */
        border-color: #ff7b00; 
    }
    .ant-checkbox-inner { 
        border: 1px solid #ff6600;
    }
    .ant-checkbox-wrapper {
        display: flex;
        justify-content: end;
        margin-top: -6px;
    }
    .ant-checkbox-wrapper span {
        font-size: 16px;
    }
    .login__background {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        height: 100%;
        width: 830px;  
        padding: 111px 175px;
    }
    .login__section {
        width: 480px;
        height: 322px;
    }
    .login__section .input__section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-bottom: 20px;
    }
    .input__section .ant-input {
        width: 480px;
        height: 48px !important;
        font-size: 16px;
    }
<<<<<<< HEAD
=======
    .login__section .title {
        font-size: 20px;
        font-weight: bold;  
    }
    .login__section .dev {
        font-size: 20px;
        font-weight: bold; 
        text-align:right; 
    }
    .btn__section { 
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 30px;
    }
    .btn__section .form_btn {
        width: 480px;
        height: 48px;
        font-size: 20px;
        cursor: pointer;
    } 
    .btn__section .form_reset_btn {
        width: 480px;
        height: 48px;
        font-size: 16px;
        cursor: pointer;
    }

    // 우리카드 수정사항
        /* .input__section .ant-input:hover {
            border-color: #0067ac;
        }
        .input__section .ant-checkbox .ant-checkbox-wrapper:hover {
            border-color:  #0067ac !important;
        }
        .input__section .ant-checkbox .ant-checkbox-inner {
            border-color : #0067ac !important;
        }    
        .input__section .ant-checkbox .ant-checkbox:hover {
            border-color : #0067ac !important;
        }
        .input__section .ant-checkbox .ant-checkbox-inner {
            border-color : #0067ac !important;
        }
        .input__section .ant-checkbox .ant-checkbox-input:focus {
            border-color : #0067ac !important;
        }
        .input__section .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #0067ac !important;
<<<<<<< HEAD
        }
>>>>>>> b3ef434b665a3a468d6712852c5bdd9ba6a779d9
=======
        } */
>>>>>>> f73d01242b6012cef44cab1c19ed1f994a2d1d88
    // 신한은행 수정사항
    /* .input__section .ant-input:hover {
        border-color: #000044;
    }
    .input__section .ant-checkbox .ant-checkbox-wrapper:hover {
        border-color:  #000044 !important;
    }
    .input__section .ant-checkbox .ant-checkbox-inner {
        border-color : #000044 !important;
    }    
    .input__section .ant-checkbox .ant-checkbox:hover {
        border-color : #000044 !important;
    }
    .input__section .ant-checkbox .ant-checkbox-inner {
        border-color : #000044 !important;
    }
    .input__section .ant-checkbox .ant-checkbox-input:focus {
        border-color : #000044 !important;
    }
    .input__section .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #000044 !important;
    } */
    .login__section .title {
        font-size: 20px;
        font-weight: bold;  
    }
    .login__section .dev {
        font-size: 20px;
        font-weight: bold; 
        text-align:right; 
    }
    .btn__section { 
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding-top: 30px;
    }
    .btn__section .form_btn {
        width: 480px;
        height: 48px;
        font-size: 20px;
        /* background-color: #000044; */
        cursor: pointer;
    } 
    .btn__section .form_btn:hover {
        background-color: #2424a6;
    } 
    .btn__section .form_reset_btn {
        width: 480px;
        height: 48px;
        font-size: 16px;
        /* color: #000044;
        border-color: #000044; */
        cursor: pointer;
    }
    /* .btn__section .form_reset_btn:hover {
        background-color: #f5f7ff;
    }  */
`;

export default Wrapper;