import React, { useEffect, useState } from "react";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";
import { Text } from "components";
import styled from "styled-components";
import fetchAbsolute from "utils/fetchAbsolute";
import { Tooltip } from "antd";
import { EqualIcon, FallingIcon, RisingIcon } from "icons";


/**
 * 메인 > DREAGO 현황
 *
 */


/* 박스 컴포넌트 */
const BoxWrapper = styled.div`
  border: 1px solid #d0d1d6;
  background: #fff;
  width: 400px;
  height: 185px;
  text-align: center;
  .top {
    background: #f8f8f9;
    padding: 15px;
  }
  .top .main {
    font-weight: bold;
    font-size: 15px;
  }
  .top .sub {
    color: #888888;
    font-size: 11px;
  }
  .bottom {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;
    height: 50%;
  }
  .bottom.next {
    flex-direction: column;
  }
  .bottom .last_days span {
    color: #ff7a00;
    /* 우리 수정 */
    /* color: #01d1c3; */
    font-weight: bold;
    font-size: 42px;
  }
  .bottom .last_days {
    color: #ff7a00;
    align-self: center;
  }
  .bottom .status_cnt {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 14px;
    padding: 0 5px;
    border-radius: 10px;
    height: 30px;
    min-width: fit-content;
    width: 72px;
    justify-content: center;
  }
  .bottom .status_cnt.positive,
  .bottom .status_cnt.positive span {
    background: #ffe6ea;
    color: #e54156;
  }
  .bottom .status_cnt.negative,
  .bottom .status_cnt.negative span {
    background: #bbe3fb;
    color: #1b7bb3;
  }
  .bottom .status_cnt.equal {
    background: #c9ecd5;
    color: #488a5e;
  }
  .font_18 {
    font-size: 18px !important;
    font-weight: normal !important;
  }
`;
const Box = ({ title, last30Days, statusCnt, unit, type }) => {
  const { formatMessage } = useIntl();

  const [checkNumber, setCheckNumber] = useState("");
  const [transTime, setTransTime] = useState({
    isOver: false,
    day: 0,
    dayToHour: 0,
  });
  const [savingTranTime, setSavingTranTime] = useState({
    isOver: true,
    day: 0,
    dayToHour: 0,
  });
  const [len, setLen] = useState(false);

  // 천단위 콤마 찍기
  const priceToString = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  useEffect(() => {
    const isPositive = (number) => {
      return number >= 0;
    };
    if (isPositive(statusCnt)) {
      if (statusCnt === 0) {
        setCheckNumber("equal");
      } else {
        setCheckNumber("positive");
      }
    } else {
      setCheckNumber("negative");
    }
    //100581274
    if (statusCnt >= 1000000 || last30Days >= 1000000) {
      setLen(true);
    }

    /* saving time 24시 이후 이면 일로 변환 */
    if (type && last30Days > 24) {
      const hourToDay = last30Days / 24;
      const day = Math.floor(hourToDay);
      const dayToHour = Math.round((hourToDay - day) * 24);
      setTransTime({
        isOver: true,
        day,
        dayToHour,
      });
      setLen(true);
    }
    if (type && statusCnt > 24) {
      const hourToDay = statusCnt / 24;
      const day = Math.floor(hourToDay);
      const dayToHour = Math.round((hourToDay - day) * 24);
      setSavingTranTime({
        isOver: true,
        day,
        dayToHour,
      });
      setLen(true);
    }
  }, [statusCnt, last30Days]);

  return (
    <BoxWrapper className="myrpa_box_container">
      <div className="top">
        <div className="main">{title}</div>
        <div className="sub">LAST 30 DAYS</div>
      </div>
      <div className={`bottom ${len && "next"}`}>
        <div className="last_days">
          {type && transTime.isOver ? (
            <>
              <Tooltip title={priceToString(last30Days) + `${formatMessage({ id: "T0563" })}`}>{/* 시간 */}
                <Text>{priceToString(transTime.day)}</Text>
                <span className="font_18">{formatMessage({ id: "T0437" })}&nbsp;</span>{/* 일 */}
                <Text>{priceToString(transTime.dayToHour)}</Text>
                <span className="font_18">{formatMessage({ id: "T0563" })}</span>{/* 시간 */}
              </Tooltip>
            </>
          ) : (
            <>
              <Text>{priceToString(last30Days)}</Text>
              <span className="font_18">{unit}</span>
            </>
          )}
        </div>
        <div className={`status_cnt ${checkNumber}`}>
          {checkNumber === "positive" ? "+" : ""}
          {/* 다국어 적용해야해 */}
          {type && savingTranTime.isOver ? (
            <>
              <Tooltip title={priceToString(statusCnt) + `${formatMessage({ id: "T0563" })}`}>{/* 시간 */}
                <Text>{priceToString(savingTranTime.day)}</Text>
                <>{formatMessage({ id: "T0437" })}&nbsp;</>{/* 일 */}
                <Text>{priceToString(savingTranTime.dayToHour)}</Text>
                <>{formatMessage({ id: "T0563" })}</>{/* 시간 */}
              </Tooltip>
            </>
          ) : (
            <>
              <Text>{priceToString(statusCnt)}</Text>
              {unit}
            </>
          )}
          {checkNumber === "positive" ? (
            <RisingIcon />
          ) : checkNumber === "negative" ? (
            <FallingIcon />
          ) : (
            <EqualIcon />
          )}
        </div>
      </div>
    </BoxWrapper>
  );
};

const KpiOrganization = () => {
  const { formatMessage } = useIntl();

  const [rpaStatus, setRpaStatus] = useState({});

  useEffect(() => {
    const getMyRpa = async () => {
      const result = await fetchAbsolute(
        "get",
        `/main/statusArea/getStatusAreaInfo`
      );
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setRpaStatus(result.data);
    };
    getMyRpa();
  }, []);

  return (
    <Wrapper>
      <div className="kpi__container">
        <div className="kpi__title">
          <span>{formatMessage({ id: "T0384" })}</span>{/* Dreago 현황 */}
        </div>
        <div className="box_area">
          <Box
            title="Citizen Developer"
            statusCnt={+rpaStatus.license30Cnt || 0}
            last30Days={+rpaStatus.licenseCnt || 0}
            unit={formatMessage({ id: "T0433" })} // 명
          />
          <Box
            title={formatMessage({ id: "T1536" })}  // 과제 등록 건수
            statusCnt={+rpaStatus.task30Cnt || 0}
            last30Days={+rpaStatus.taskCnt || 0}
            unit={formatMessage({ id: "T1537" })}  // 건 
          />
          <Box
            title={formatMessage({ id: "T1535" })} // 과제 절감 시간
            type="time"
            statusCnt={+rpaStatus.savings30Time || 0}
            last30Days={+rpaStatus.savingsTime || 0}
            unit={formatMessage({ id: "T0563" })} // 시간 
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default KpiOrganization;
