import React, { useState, useEffect } from "react";
import { Text, Button } from "components";
import { Tree, Select } from "antd";
import { FormattedMessage, useIntl } from "react-intl";
import fetchAbsolute from "utils/fetchAbsolute";

const { Option } = Select;

/**
 * Filename: MenuManagement/TreeMenu.js
 * Description: 관리자 -> 메뉴 관리 왼쪽 tree구조
 */

const MenuManagement = ({ getData, renderList, language, onChangeLanguage }) => {
  const { formatMessage } = useIntl();

  const [languageList, setLanguageList] = useState([]);
  const [treeList, setTreeList] = useState([]);
  const [allClassList, setAllClassList] = useState([]);
  const [originAllClassList, setOriginAllClassList] = useState([]);
  const [flatAllClassList, setFlatAllClassList] = useState([]);
  const [flatOriginAllClassList, setFlatOriginAllClassList] = useState([]);
  const [expand, setExpand] = useState([]);
  const [selectInfo, setSelectInfo] = useState();
  const [buttonStatus, setButtonStatus] = useState(false);
  const [onSelectCheck, setOnSelectCheck] = useState();
  const [lastKey, setLastKey] = useState();

  const onExpandTree = (keys) => {
    setExpand([...keys]);
  };

  const onSendData = (info, check, flatAllClassList) => {
    getData(info, check, flatAllClassList);
  };

  const onSelect = (selectedKeys, info) => {
    //row data 클릭시 정보를 부모 컴포넌트에 보낸 후 menuDetail
    onSendData(+selectedKeys[0] || -1, 2, flatAllClassList);
    //버튼 상태 변경
    setButtonStatus(false);

    setOnSelectCheck(info.selected);
    //선택된 정보 set
    setSelectInfo(info.node.props);
    //삭제 버튼 활성화 체크
    if (Object.keys(info.node.props).includes("children")) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }
  };

  const treeDataChange = () => {
    const objectKeyNameChange = allClassList.map((item) => {
      return {
        key: item.menuId,
        title: (
          <>
            <div style={{ fontSize: 16, fontWeight: "bold" }}>
              <span>
                <Button
                  type="primary"
                  style={{
                    width: 35,
                    borderRadius: 10,
                    height: 26,
                    display: "inline-flex",
                    alignItems: "flex-end",
                  }}
                >
                  <Text style={{ fontSize: 10, color: "white" }}>Lev 1</Text>
                </Button>
              </span>
              <span style={{ marginLeft: 5 }} id="cdNm">{item.menuNm}</span>
            </div>
          </>
        ),
        menuLevel: item.menuLevel,
        menuOrder: item.menuOrder,
        children: item.subMenu.map((item) => {
          return {
            key: item.menuId,
            title: (
              <>
                <div style={{ fontSize: 14, fontWeight: "bold" }}>
                  <span>
                    <Button
                      type="primary"
                      style={{
                        width: 35,
                        background: "#FFCD33",
                        borderRadius: 10,
                        height: 26,
                        display: "inline-flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <Text style={{ fontSize: 10 }}>Lev 2</Text>
                    </Button>
                  </span>
                  <span style={{ marginLeft: 5 }} id="cdNm">{item.menuNm}</span>
                </div>
              </>
            ),
            menuLevel: item.menuLevel,
            menuOrder: item.menuOrder,
            children: item.subMenu,
          };
        }),
      };
    });
    setTreeList(objectKeyNameChange);
  };

  const flatten = (data) => {
    const result = [];
    recursive(data);
    return result;

    function recursive(data) {
      data.forEach((dt, index) => {
        result.push({
          menuNm: dt.menuNm,
          menuId: dt.menuId,
          menuOrder: dt.menuOrder,
          parentId: dt.parentId,
          menuLevel: dt.menuLevel,
          menuUrl: dt.menuUrl,
          menuDescription: dt.menuDescription,
          useYn: dt.useYn,
          menuType: dt.menuType,
          displayYn: dt.displayYn,
          displayId: dt.displayId
        });
        recursive(dt.subMenu);
      });
    }
  };

  const onClickAdd = (e) => {
    if (Number(selectInfo?.menuLevel > 1)) {
      alert(formatMessage({ id: 'T0237' }));
      //하위레벨에 추가하실수 없습니다.
      return;
    }

    //상위 추가
    if (onSelectCheck?.length > 0 || onSelectCheck === undefined) {
      onSendData(lastKey + 1, 0, flatAllClassList);
    }
    //하위
    else {
      onSendData(selectInfo.eventKey, 1, flatAllClassList);
    }
  };

  useEffect(() => {
    const fetchLanguageList = async () => {
      const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const data = result.data.filter(v => v.useYn === 'Y');
      onChangeLanguage(data[0].cd);
      setLanguageList([...data]);
    }
    fetchLanguageList();
  }, []);

  useEffect(() => {
    if (!language) return;

    const fetchMenuList = async () => {
      const result = await fetchAbsolute("get", `/admin/menu/getAllMenuList?slocale=${language}`);
      setAllClassList(result.data);
      setOriginAllClassList(result.data);
    };
    fetchMenuList();
  }, [language]);

  useEffect(() => {
  }, [selectInfo]);

  useEffect(() => {
    treeDataChange();
    setFlatAllClassList(flatten(allClassList));
  }, [allClassList]);

  useEffect(() => {
    setFlatOriginAllClassList(flatten(originAllClassList));
  }, [originAllClassList]);

  useEffect(() => {
    if (renderList?.length > 0) {
      setAllClassList(renderList);
    }
  }, [renderList]);

  useEffect(() => {
    let menuIdArr = flatAllClassList.map((e) => e.menuId);
    setLastKey(Math.max.apply(Math, menuIdArr));
  }, [flatAllClassList]);

  useEffect(() => {
    if (!onSelectCheck) {
      setSelectInfo("");
    }
  }, [onSelectCheck]);

  return (
    <div className="org_chart__container">
      <div>
        <div style={{
          display: 'flex',
          gap: '5px'
        }}>
          <Button type={language === 'ko' ? "primary" : 'disabled'} onClick={onClickAdd} disabled={language !== 'ko'}>
            <FormattedMessage id="T0107" />{/* 메뉴 생성 */}
          </Button>
          <Select
            value={language}
            onChange={onChangeLanguage}
          >
            {
              languageList.map(lan => (
                <Option value={lan.cd} key={lan.cd}>{lan.cdNm}</Option>
              ))
            }
          </Select>
        </div>

        <div className="org_common__box">
          <Tree
            className="draggable-tree"
            treeData={treeList}
            defaultExpandedKeys={expand}
            expandedKeys={expand}
            defaultExpandAll
            onSelect={onSelect}
            onExpand={onExpandTree}
          ></Tree>
        </div>
      </div>
    </div>
  );
};

export default MenuManagement;
