import React from 'react';
import { FormattedMessage } from "react-intl";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Table } from 'components';

/**
 * Filename: AdminCredential/CredentialBottom.js
 * Description: Admin -> Credential 관리 -> 하단 데이터 컴포넌트
 */

const CredentialBottom = ({ attributeData, tableLoading, onClickDeleteAttribute, onClickEditAttribute }) => {
    const columns = [
        {
            key: 1,
            title: <FormattedMessage id="T1134" />, // Attribute
            dataIndex: "name",
        }, {
            key: 2,
            title: <FormattedMessage id="T1135" />, // Value
            dataIndex: "value",
            render: (text, record) => {
                return record.passwordFlag ? "********" : text;
            },
        }, {
            key: 3,
            title: <FormattedMessage id="T1136" />, // Password
            dataIndex: "passwordFlag",
            render: (value) => value ? 'Y' : 'N'
        }, {
            key: 4,
            title: <FormattedMessage id="T0100" />, // 설명
            dataIndex: "description",
        }, {
            title: <FormattedMessage id="T0528" />, // 기능
            dataIndex: "empNm",
            key: 6,
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', gap: 20 }}>
                        <EditOutlined onClick={() => onClickEditAttribute(record)} />
                        <DeleteOutlined onClick={() => onClickDeleteAttribute(record)} />
                    </div>
                );
            },
        }
    ]
    return (
        <div className='credential_bottom__wrap'>
            <Table
                className='credential_top__table'
                loading={tableLoading}
                rowKey={(item) => item.id}
                columns={columns}
                dataSource={attributeData.attributes}
            />
        </div>
    )
}

export default CredentialBottom;