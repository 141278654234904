import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { TimeTable } from "components";

const WorkFlowScheduleDaily = ({
  history,
  scheduleDatas,
  date = "",
  barColor,
}) => {
  const [dataSource, setDataSource] = useState([]);

  const columns = [];

  // 컬럼 세팅
  for (let i = 0; i < 24; i++) {
    const obj = {
      key: i + 1,
      title: i.toString().length < 2 ? `0${i}:00` : `${i}:00`,
      dataIndex: "workflowNm",
      width: 120,
      align: "center",
    };
    // Row의 첫 번째 시간 컬럼을 한 줄로 만듬

      obj.render = (text, record, index) => {
        if (i === +record.startTime.split(":")[0]) {
          const dateArr = record.startTime.split(":");
          const hourToMin = +dateArr[0] * 60;
          const totalMin = hourToMin + +dateArr[1];

          return {
            children: (
              <div
                className="schedule_daily__name_box"
                key={index}
                title={`${text}(${record.workflowId})\nMin Running Time : ${record.minTime
                  } sec\nMax Running Time : ${record.maxTime} sec\nRunningTime : ${record.runningTime || 0
                  } sec`}
                style={{
                  // left: 120 * 24 * (totalMin / 1440) + "px",
                  zIndex: 0,
                  //width: +record.runningTime / 60 + "px",
                  background: barColor[record.runningStatus] || "#fff3e8",
                  color: "#000",
                }}
                onClick={() => onClickDailyName(record.workflowId)}
              >
                {text}({record.workflowId})
              </div>
            ),
            props: {
              style: {
                position: "unset",
              },
            },
          };
        } else {
          return {
            children: (
              <div className="schedule_daily__name_box">
              </div>),
            props: {
              style: {
                position: "unset",
              },
            },
          }
        }
      };
    //  else {
    //   obj.render = () => ({
    //     props: {
    //       style: {
    //         position: "unset",
    //       },
    //     },
    //   });
    // }
    columns.push({ ...obj });
  }

  const onClickDailyName = (workflowId) => {
    history.push(`/bizflow/workflow/lookup/${workflowId}/schedule`);
  };

  const getDatas = async () => {
    setDataSource(
      Object.values(scheduleDatas)[0]?.map((data) => {
        return {
          ...data,
        };
      }).reduce(function (acc, current) {
        if (acc.findIndex(({ name }) => name === current.name) === -1) {
          acc.push(current);
        }
        return acc;
      }, [])
    );
  };

  useEffect(() => {
    if (scheduleDatas.length <= 0 || date.length <= 0) return;
    getDatas();
  }, [scheduleDatas, date]);

  return (
    <div>
      <TimeTable
        bordered
        className="schedule_daily__table"
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: 2000, y: 500 }}
        type='workflow'
      />
    </div>
  );
};

export default withRouter(WorkFlowScheduleDaily);
