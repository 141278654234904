// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-popover-inner-content {
    overflow-y: unset !important;
    font-size: 11px;
}
.ant-popover > .ant-popover-content {
    width: auto !important;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/HFBpdIframe/common.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,eAAe;AACnB;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".ant-popover-inner-content {\n    overflow-y: unset !important;\n    font-size: 11px;\n}\n.ant-popover > .ant-popover-content {\n    width: auto !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
