import React, { useEffect, useState } from "react";
import {
  PageTemplate,
  Header,
  Footer,
} from "components";
import {
  loadWorkflowData,
  resetWorkflowData,
} from "store/hfWorkflowLookup/action";
import { useDispatch, useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import WorkflowLookupHeader from "./WorkflowLookupHeader";
import WorkflowLookupBody from "./WorkflowLookupBody";

/**
 * Filename: HFWorkflowLookup/index.js
 * Description: Workflow 상세페이지 컴포넌트
 */

const HFWorkflowLookup = ({
  match: { params }, // {workflowId: 'W2303-001'}
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadWorkflowData(params.workflowId));
    return () => {
      dispatch(resetWorkflowData());
    };
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <WorkflowLookupHeader />
      <WorkflowLookupBody params={params} />
    </PageTemplate>
  );
};

export default HFWorkflowLookup;
