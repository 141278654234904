import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input as AntInput, Select } from 'antd';
import { Modal, Text, Input, Spin } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
const { Option } = Select;
const { TextArea } = AntInput;

const Wrapper = styled(props => <Modal {...props} />)`
`

const AddAttributeModal = ({ visible = false, onOk = () => { }, onCancel = () => { }, credentialName = '', crType = 'COE' }) => {
    const { formatMessage } = useIntl();
    const [attrName, setAttrName] = useState('');
    const [attrValue, setAttrValue] = useState('');
    const [attrPassword, setAttrPassword] = useState('N');
    const [attrDes, setAttrDes] = useState('');
    const [loading, setLoading] = useState(false);



    const onOkModal = async () => {
        if (attrName.trim().length <= 0) {
            return alert(formatMessage({ id: 'T0113' })); // Attribute Name을 입력해주세요.
        } else if (attrValue.trim().length <= 0) {
            return alert(formatMessage({ id: 'T0114' })); // Attribute Value를 입력해주세요
        }
        setLoading(true);
        const result = await fetchAbsolute("put", `/hwlm/credential`, {
            data: {
                name: credentialName,
                crType: crType.toUpperCase(),
                attributes: [
                    {
                        type: "ADD",
                        name: attrName,
                        description: attrDes,
                        value: attrValue,
                        userProvided: false,
                        masked: attrPassword === "Y",
                        passwordFlag: attrPassword === "Y",
                    },
                ],
            },
        });
        setLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        onOk();
    };

    const onChangeName = (e) => {
        const { value } = e.target;
        if (value.length > 50) return;
        setAttrName(value);
    }

    const onChangeValue = (e) => {
        const { value } = e.target;
        setAttrValue(value);
    }

    const onChangePassword = (e) => {
        setAttrValue('');
        setAttrPassword(e);
    }

    const onChangeDes = (e) => {
        const { value } = e.target;
        if (value.length > 255) return;
        setAttrDes(value);
    }

    useEffect(() => {
        setAttrName('');
        setAttrValue('');
        setAttrPassword('N');
        setAttrDes('');
    }, [visible]);

    return (
        <Wrapper
            width={540}
            visible={visible}
            title={formatMessage({ id: 'T0065' })} // ATTRIBUTE 추가
            okText={formatMessage({ id: 'T0066' })} // 입력
            onCancel={onCancel}
            onOk={onOkModal}
        >

            <div style={{
                zIndex: 1
            }}>
                <div style={{ display: "flex" }}>
                    <Text
                        style={{
                            display: "block",
                            fontSize: 14,
                            width: 130,
                        }}
                    >
                        <FormattedMessage id="T1134" />{/* Attribute */}
                    </Text>
                    <Input
                        value={attrName}
                        onChange={onChangeName}
                    />
                </div>
                <div style={{ display: "flex", marginTop: 20 }}>
                    <Text
                        style={{
                            display: "block",
                            fontSize: 14,
                            width: 130,
                        }}
                    >
                        <FormattedMessage id="T1135" />{/* Value */}
                    </Text>
                    <Input
                        type={attrPassword === 'Y' ? 'password' : 'text'}
                        value={attrValue}
                        onChange={onChangeValue}
                    />
                </div>
                <div style={{ display: "flex", marginTop: 20 }}>
                    <Text
                        style={{
                            display: "block",
                            fontSize: 14,
                            width: 103,
                        }}
                    >
                        <FormattedMessage id="T1136" />{/* Password */}
                    </Text>
                    <Select
                        value={attrPassword}
                        onChange={onChangePassword}
                        style={{ width: 100 }}
                    >
                        <Option value="N">N</Option>
                        <Option value="Y">Y</Option>
                    </Select>
                </div>
                <div style={{ display: "flex", marginTop: 20 }}>
                    <Text
                        style={{
                            display: "block",
                            fontSize: 14,
                            width: 130,
                        }}
                    >
                        <FormattedMessage id="T0100" />{/* 설명 */}
                    </Text>
                    <TextArea
                        autoSize={{ minRows: 4, maxRows: 4 }}
                        value={attrDes}
                        onChange={onChangeDes}
                    />
                </div>

                {!!loading &&
                    <Spin tip='Loading...' />
                }
            </div>
        </Wrapper >
    );
}
export default AddAttributeModal;