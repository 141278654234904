import React from 'react';
import { PageTemplate, Header, Footer } from 'components';

const TestDashBoard = () => {
    return (
        <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false}>
           <div></div>
        </PageTemplate>
    )
};

export default TestDashBoard;