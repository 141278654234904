import React, { useEffect, useState } from 'react';
import { Modal, Text, Table, Input, Button, Loading } from 'components';
import { Tabs } from 'antd';
import styled from 'styled-components';
import { MinusCircleFilled } from '@ant-design/icons';
import fetchAbsolute from 'utils/fetchAbsolute';
import { useIntl } from "react-intl";
import { Tooltip } from 'antd';

const { TabPane } = Tabs;

/**
 * 과제 등록 > 업무 수행시 사용 시스템 등록
 */

const Wrapper = styled(Modal)`
    .ant-table-thead th {
        height: 34px;
        padding: 0px 16px;
        font-size: 12px;
    }
    .ant-table-tbody td {
        font-size: 12px;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #ff7b00 !important;
    }
    .system_wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 650px;
    }
    .system_wrap .system_box {

    }
    .system_select_button {
        width: 26px;
        height: 26px;
    }
    .system_wrap .system_box:first-child {
        width: 55%;
        height: 100%;
    }
    .system_wrap .system_box:last-child {
        width: 40%;
        height: 100%;
    }
    .system_wrap .system_box .system_box__title {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 15px;
    }
    .system_wrap .system_box .system_box__content {
        height: 634px;
    }
    .system_wrap .system_box .system_box__content .ant-tabs-tab {
        font-size: 12px;
        height: 32px;
        line-height: 30px;
    }
    .system_wrap .system_box .system_box__content .ant-tabs-nav-container{
        height: 32px;
    }
    .system_wrap .system_box .system_box__content .ant-tabs-card .ant-tabs-content {
        margin-top: -16px;
    }
    .system_content__header {
        display: flex;
        height: 32px;
        justify-content: space-between;
        align-items: center;
    }
    .system_content__header .select_reset_button {
        width: 68px;
        height: 24px;
        font-size: 12px;
    }
    .system_content__section {
        height: 602px;
        border: 1px solid #e8e8e8;
    }
    .system_box__tab .system_content__section {
        border-top: 0;
    }
    .system_section__table {
        margin-top: 10px;
    }
    .system_section__table .ant-spin-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px 0;
    }
    .system_section__table .ant-table-pagination.ant-pagination {
        float: unset;
        margin: 0;
    }
`;


const initialPageInfo = {
    sort: "category",
    direction: ",asc",
    currentPage: 1,
    rowPerPage: 10,
    total: 0
};

const InsideSystem = ({ datas, rowSelection, onChangeSearch, searchKeyword, onChangeTableColumns, pageInfo }) => {
    const { formatMessage } = useIntl();

    const [classificationList, setClassificationList] = useState([]);
    const [workGbnList, setWorkGbnList] = useState([]);

    const columns = [
        {
            title: formatMessage({ id: 'T0362' }), // 분류
            dataIndex: 'category',
            key: 'category',
            width: 150,
            render: (text, record, index) => record.categoryName
        },
        {
            title: formatMessage({ id: 'T0363' }), // 시스템 명
            dataIndex: 'systemName',
            key: 'systemName',
            width: 150,
            render: (text, record, index) => {
                return (
                    <Tooltip title={text} key={index}>
                        <div
                            style={{
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                            }}
                        >
                            {text}
                        </div>
                    </Tooltip>
                )
            }
        },
        {
            title: formatMessage({ id: 'T0365' }), // 시스템 담당자
            dataIndex: 'systemUser',
            key: 'systemUser',
            width: 120,
            render: (text, record, index) => record.empNam
        }
    ];

    useEffect(() => {
        /* System Manage 기본 정보 셋팅 */
        const getClassification = async () => {
            const result = await fetchAbsolute('get', `/common/commonCd/getCategoryId/USESYS_TYPE`);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            setClassificationList([...result.data]);
        };
        const getGbn = async () => {
            const result = await fetchAbsolute('get', `/common/commonCd/getCategoryId/WORK_GBN`);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다. 
            setWorkGbnList([...result.data]);
        }
        getClassification();
        getGbn();
    }, []);

    return (
        <div className='system_content__section' style={{ padding: 8 }}>
            <Input
                placeholder={formatMessage({ id: 'T0366' })} // 내부 시스템을 검색하세요
                onChange={onChangeSearch}
                value={searchKeyword}
                style={{ height: 32 }}
            />
            <div className='system_section__table'>
                <Table
                    columns={columns}
                    dataSource={datas}
                    rowSelection={rowSelection}
                    locale={{ emptyText: ' ' }}
                    scroll={{ y: 471 }}
                    pagination={true}
                    onChange={onChangeTableColumns}
                    pageOptions={{
                        total: +pageInfo.total,
                        current: +pageInfo.currentPage,
                        pageSize: +pageInfo.rowPerPage,
                    }}
                />
            </div>
        </div>
    )
}
const OutsideSystem = ({ outSystemInfo, onChangeSystemInfo }) => {
    const { formatMessage } = useIntl();
    return (
        <div className='system_content__section' style={{ padding: 21 }}>
            <div>
                <Text style={{ fontSize: 12 }}>
                    {formatMessage({ id: 'T0367' })}{/* 외부 시스템 명 */}
                </Text>
                <Input
                    style={{
                        height: 32,
                        marginTop: 10,
                        marginBottom: 20
                    }}
                    name='systemName'
                    placeholder={formatMessage({ id: 'T1320' })} // 외부시스템을 입력하시고 아래 URL을 등록해 주세요.
                    value={outSystemInfo.systemName}
                    onChange={onChangeSystemInfo}
                />
            </div>
            <div>
                <Text style={{ fontSize: 12 }}>
                    {formatMessage({ id: 'T0368' })}{/* 외부 시스템 링크 */}
                </Text>
                <Input
                    style={{
                        marginTop: 10,
                        height: 32
                    }}
                    name='appLink'
                    placeholder='http://'
                    value={outSystemInfo.appLink}
                    onChange={onChangeSystemInfo}
                />
            </div>
        </div>
    )
}

const SystemSelector = ({ visible, title, onOk = () => { }, onCancel = () => { }, defaultData = [] }) => {
    const { formatMessage } = useIntl();

    const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
    const [allSystemDatas, setAllSystemDatas] = useState([]);
    const [systemDatas, setSystemDatas] = useState([]);
    const [checkedDatas, setCheckedDatas] = useState([]);
    const [selectDatas, setSelectDatas] = useState([]);
    const [systemKind, setSystemKind] = useState('I');
    const [loading, setLoading] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [outSystemInfo, setOutSystemInfo] = useState({
        systemName: '',
        appLink: ''
    });

    const rowSelection = {
        selectedRowKeys: checkedDatas,
        onSelectAll: (selected, selectedRows) => {
            if (selectedRows.length === 0) {
                setCheckedDatas([]);
            }
        },
        onChange: (selectedRowKeys) => {
            setCheckedDatas(selectedRowKeys);
        }
    };



    const onClickSelectButton = () => {
        const tmpArr = [...selectDatas];
        if (systemKind === 'I') {
            checkedDatas.forEach(key => {
                const newObj = { ...allSystemDatas[key], systemKind };
                if (tmpArr.findIndex(v => v.systemCd === newObj.systemCd) === -1) {
                    newObj.key = tmpArr.length;
                    tmpArr.push({ ...newObj });
                }
            });
            setCheckedDatas([]);
        } else if (systemKind === 'O') {
            const newObj = { ...outSystemInfo, systemKind, systemCd: `O${((tmpArr.length + 1) + '').padStart(3, '0')}`, key: tmpArr.length };
            tmpArr.push({ ...newObj });
            setOutSystemInfo({
                systemName: '',
                appLink: ''
            });
        }
        setSelectDatas([...tmpArr]);
    }

    const onChangeSystemInfo = (e) => {
        const { value, name } = e.target;
        if ((name === 'systemName' && value.length > 100) || (name === 'appLink' && value.length > 1000)) return;
        setOutSystemInfo({ ...outSystemInfo, [name]: value });
    }
    const onClickResetButton = () => {
        setSelectDatas([]);
    }
    const onChangeSearch = (e) => {
        setSearchKeyword(e.target.value);
    }
    const onClickOk = () => {
        onOk(selectDatas);
    }
    const onDeleteSelectData = (key) => {
        setSelectDatas([...selectDatas.filter(data => data.key !== key)]);
    }

    // 전체 목록이지만 혹시 몰라서 페이징 처리함 (정렬 기능을 넣게 된다면, api 다시 확인해야함. 페이지가 실제로 안나눠짐)
    const getSystemList = async (page) => {
        if (page === undefined) {
            page = { ...pageInfo }
        }
        const result = await fetchAbsolute('get', `/admin/system/systemAllLists?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`);
        setAllSystemDatas([...result.data.map((data, index) => ({ ...data, key: index }))]);
        setLoading(true);
    };

    const onChangeTabs = (e) => {
        setSystemKind(e);
    };

    const onChangeTableColumns = (pagination, filters, sorter) => {
        let order = sorter.field ? sorter.field : 'category';
        let orderBy = "";
        if (!sorter.order) {
            order = "category"
            orderBy = ",asc"
        }
        else if (sorter.order?.includes("desc")) orderBy = ",desc";
        else if (sorter.order?.includes("asc")) orderBy = ",asc";
        const searchObj = {
            ...pageInfo,
            sort: order,
            direction: orderBy,
            currentPage: pagination.current
        };
        setPageInfo({ ...pageInfo, ...searchObj });
        getSystemList(searchObj);
    };

    useEffect(() => {
        // 전체 데이터 중에서 searchKeyword를 포함하고 있는 데이터들을 추출 
        const filterDatas = [...allSystemDatas.filter(data => {
            return data.categoryName?.toLowerCase().includes(searchKeyword.toLowerCase()) || data.systemName?.toLowerCase().includes(searchKeyword.toLowerCase()) || data.empNam?.toLowerCase().includes(searchKeyword.toLowerCase());
        })];
        setSystemDatas([...filterDatas]);
    }, [searchKeyword]);

    useEffect(() => {
        setLoading(true);
        if (!visible) return;
        setLoading(false);
        const tmpArr = defaultData.map((v, index) => ({
            ...v,
            key: index
        }));
        setSelectDatas([...tmpArr]);
        getSystemList();
    }, [defaultData, visible]);

    useEffect(() => {
        setSystemDatas([...allSystemDatas]);
        if (allSystemDatas.length === 0) {
            setSystemKind('O');
        } else {
            setSystemKind('I');
        }
    }, [allSystemDatas]);

    /* 내부 시스템 조건 때문에 loading 시간이 필요하다 */
    if (!loading) {
        return <Loading visible={true} />
    }
    return (
        <Wrapper visible={visible} title={title} onOk={onClickOk} onCancel={onCancel}>
            <div className='system_wrap'>
                <div className='system_box'>
                    <div className='system_box__title'>
                        <Text> {formatMessage({ id: 'T0369' })}</Text>{/* 시스템 검색 */}
                    </div>
                    <div className='system_box__content system_box__tab'>
                        <Tabs type='card' onChange={onChangeTabs} tabBarStyle={{ height: 32 }}>
                            {
                                allSystemDatas.length > 0 &&
                                <TabPane tab={formatMessage({ id: 'T0370' })} key='I'>{/* 내부 시스템 */}
                                    <InsideSystem datas={systemDatas} rowSelection={rowSelection} onChangeSearch={onChangeSearch} searchKeyword={searchKeyword} onChangeTableColumns={onChangeTableColumns} pageInfo={pageInfo} />
                                </TabPane>
                            }
                            <TabPane tab={formatMessage({ id: 'T0371' })} key='O'>{/* 외부 시스템 */}
                                <OutsideSystem outSystemInfo={outSystemInfo} onChangeSystemInfo={onChangeSystemInfo} />
                            </TabPane>
                        </Tabs>
                    </div>
                </div>
                <div>
                    <Button className='system_select_button' type='primary' onClick={onClickSelectButton}>&gt;</Button>
                </div>
                <div className='system_box'>
                    <div className='system_box__title'>
                        <Text>{formatMessage({ id: 'T0372' })} </Text>{/* 선택한 시스템 항목 */}
                    </div>
                    <div className='system_box__content'>
                        <div className='system_content__header'>
                            <Text style={{ fontSize: 12 }}>{formatMessage({ id: 'T0338' })}</Text>{/* 선택 항목 */}
                            <Button className='select_reset_button' onClick={onClickResetButton}>{formatMessage({ id: 'T0037' })}</Button>{/* 초기화 */}
                        </div>
                        <div className='system_content__section'>
                            <Table
                                columns={[
                                    { // T0373: 내/외부,  T0498: 외부
                                        title: systemDatas.length > 0 ? formatMessage({ id: 'T0373' }) : formatMessage({ id: 'T0498' }), // T0373 : 내/외부 T0498 : 외부
                                        width: 120,
                                        dataIndex: 'systemKind',
                                        render: (text) => text === 'I' ? formatMessage({ id: 'T0497' }) : formatMessage({ id: 'T0498' }) // T0497 : 내부 T0498 : 외부
                                    }, {
                                        title: formatMessage({ id: 'T0363' }), // 시스템 명
                                        dataIndex: 'systemName',
                                    }, {
                                        title: '',
                                        key: 'delete',
                                        width: 40,
                                        align: 'center',
                                        render: (e) => <MinusCircleFilled onClick={() => onDeleteSelectData(e.key)} />
                                    }
                                ]}
                                dataSource={selectDatas}
                                pagination={false}
                                locale={{ emptyText: ' ' }}
                                scroll={{ y: 568 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default SystemSelector;