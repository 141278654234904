import React, { useState, useRef } from "react";
import styled from "styled-components";
import fetchAbsolute from "utils/fetchAbsolute";
import { LockIcon } from "icons";
import { Input, Button, Text, Spin } from "components";
import useFormatMessage from "hooks/useFormatMessage";
import backgroundImg from "./images/loginBg.png";
import { useIntl } from "react-intl";

/**
 * 로그인 > 비밀번호 초기화 (이메일로 받는) 페이지
 *
 */

const Wrapper = styled.div`
  height: 100vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${backgroundImg});
  display: flex;
  .form {
    display: flex;
    place-self: center;
    background: #fff;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: 700px;
    margin: 0 auto;
    box-shadow: 0 0 2px rgb(0 0 0 / 19%), 0 7px 12px rgb(0 0 0 / 20%);
  }
  .check_section {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: space-between;
  }
  .check_section span.anticon {
    padding-bottom: 20px;
  }
  .text_secotion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    padding-bottom: 20px;
  }
  .text_secotion .title {
    font-size: 20px;
    font-weight: bold;
  }
  .text_secotion .content {
    font-size: 16px;
  }
  .input__section {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .input__section .email {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .input__section .ant-input {
    width: 480px;
    height: 48px !important;
    font-size: 16px;
  }
  .btn__section .form_btn {
    width: 480px;
    height: 48px;
    font-size: 20px;
  }
  /* input 창 css */
  .ant-input:hover {
    border-color: #ff7b00;
  }
  input.ant-input:focus,
  ant-input-focused {
    border-color: #ff7b00 !important;
  }
  .back_to_sign_in {
    padding: 10px 0;
    font-weight: bold;
    font-size: 14px;
    color: #ff7b00;
    cursor: pointer;
  }
`;

const initInputValues = {
  userName: "",
  userEmail: "",
};

const RequestPassword = ({ history }) => {
  const [inputValues, setInputValues] = useState({ ...initInputValues });
  const [emailMessage, setEmailMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const requireFields = {
    userName: {
      ref: useRef(null),
      // message: `${formatMessage({ id: 'T0012' })}`, // 이름
      message: `이름`,
    },
    userEmail: {
      ref: useRef(null),
      // message: `${formatMessage({ id: 'T0602' })}`, // 이메일
      message: `이메일`,
    },
  };

  const onChangeName = (e) => {
    setInputValues({ ...inputValues, userName: e.target.value });
  };

  const onChangeEmail = (e) => {
    const idRegex =
      /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    const { value } = e.target;
    if (value.length > 0 && !idRegex.test(value)) {
      setEmailMessage("이메일 형식을 확인해주세요.");
      // setEmailMessage(formatMessage({ id: 'T1274' }));
      //이메일 형식을 확인해주세요.
    } else if (value.length > 100) {
      setEmailMessage("아이디는 100자를 넘을 수 없습니다.");
      // setEmailMessage(formatMessage({ id: 'T1275' }));
      // 아이디는 100자를 넘을 수 없습니다.
      return;
    } else {
      setEmailMessage("");
    }
    setInputValues({ ...inputValues, userEmail: value });
  };

  // 로그인으로 돌아가기
  const onClickGoBack = () => {
    history.push("/signin");
  };

  // 이메일로 링크 보내기 버튼
  const onClickSendLink = async () => {
    // 빈칸검사
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!inputValues[key] && Object.keys(inputValues[key]).length <= 0) {
        field.ref.current.focus();
        return alert(`${field.message} 항목은 필수 입력입니다.`);
        // return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
        //`${field.message} 항목은 필수 입력입니다.`);
      }
    }
    // 이메일 보내는 API 호출
    setLoading(true);
    const result = await fetchAbsolute("post", `/login/pwdResetMail`, {
      data: {
        emailAddr: inputValues.userEmail,
        empNam: inputValues.userName,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error || result.data.state === 400) {
      setLoading(false);
      return alert(result.data.message);
    } else {
      alert(result.data.message);
      history.push("/");
    }
    setLoading(false);
  };

  return (
    <Wrapper>
      <div className="form">
        {!!loading ? (
          <div
            style={{
              height: "100vh",
              overflow: "hidden",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Spin tip="Loading..." />
          </div>
        ) : (
          <div className="check_section">
            <LockIcon />
            <div className="text_secotion">
              <Text className="title">
                {/* <FormattedMessage id="T1276" /> */}
                로그인에 문제가 있나요?
              </Text>
              <Text className="content">
                {/* {formatMessage({ id: "T1277" })
                                        .split("\n")
                                        .map((data, index) => {
                                            return (
                                                <span key={index}>
                                                    {data}
                                                    <br />
                                                </span>
                                            );
                                        })} */}
                사용자 이름과 이메일을 입력하면
                <br />
                비밀번호를 재설정 할 수 있는 링크를 보내드립니다.
                <br />
              </Text>
            </div>
            <div className="input__section">
              <div className="name">
                <Input
                  value={inputValues.userName}
                  // placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T0012' }) } })} //'이름 입력'
                  placeholder="이름 입력"
                  onChange={onChangeName}
                  innerRef={requireFields.userName.ref}
                />
              </div>
              <div className="email">
                <Input
                  value={inputValues.userEmail}
                  // placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T0602' }) } })}// '이메일 입력'
                  placeholder="이메일 입력"
                  onChange={onChangeEmail}
                  innerRef={requireFields.userEmail.ref}
                />
                <Text style={{ color: "red" }}>{emailMessage}</Text>
              </div>
            </div>
            <div className="btn__section">
              <Button
                className="form_btn"
                type="primary"
                onClick={onClickSendLink}
              >
                {/* <FormattedMessage id="T1278" /> */}
                이메일로 링크 보내기
              </Button>
            </div>
            <Text className="back_to_sign_in" onClick={onClickGoBack}>
              {/* &lt;<FormattedMessage id="T0094" /> */}
              &lt; 뒤로
            </Text>
          </div>
        )}
      </div>
    </Wrapper>
  );
};

export default RequestPassword;
