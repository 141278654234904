import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
// import { Select } from "antd";
import {
  Modal,
  Text,
} from "components";
import { changeHistoryData, setTaskData } from "store/assignLookup/action";
import "moment/locale/ko";
import moment from "moment";
import sendNoti from "utils/sendNoti";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import { setVisible } from 'store/loading/action';
import InactiveForm from "./InactiveForm";
import EditableForm from "./EditableForm";
import ResultForm from "./ResultForm";

// const { Option } = Select;

/**
 * 기본 환경 > 개발 (분석/설계)
 * statusCd === T10 , detailStatusCd === T12
 * 비활성화는 T10 && T11
 * 활성화 T10 && T12
 * 활성화되면서 정보 출력 및 수정버튼은 나머지인 경우
 */
// const END_POINT = process.env.REACT_APP_API_URI;

const LookupAnalytical = () => {

  const profile = useSelector(state => state.get('auth').get('profile'));
  
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const taskDevId = formatMessage({ id: "T0007" }); // 개발자
  const selectOption = formatMessage({ id: "T0024" }); // 선택
  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const fail = formatMessage({ id: "T0133" }); // 작업을 실패하였습니다.
  const sameNameNotUpload = formatMessage({ id: "T0145" }); // 같은 이름의 파일은 업로드 할 수 없습니다.
  const techFormRequired = formatMessage({ id: "T0160" }); // 업무 기술서 항목은 필수 입력입니다.
  const developStartFail = formatMessage({ id: "T0161" }); // 개발 시작에 실패하였습니다.
  const updateFail = formatMessage({ id: "T0163" }); // 수정에 실패하였습니다.
  const updateSuccess = formatMessage({ id: "T0164" }); // 수정 완료되었습니다.

  // 필수 작성 항목
  const analyticalRequire = {
    developers: {
      ref: useRef(null),
      message: taskDevId,
    },
    devSchedule: {
      ref: useRef(null),
      message: formatMessage({ id: "T0507" }), //  개발 일정
    },
    fileList: {
      ref: useRef(null),
      message: formatMessage({ id: "T0509" }), // 업무 기술서
    },
  };

  const tmpFile = useRef({
    fileList: [],
    urlList: [],
  });
  const copyFileNameTimeout = useRef(null);
  const tmpVisibleCopyName = useRef([]);

  const dispatch = useDispatch();

  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const assignLookup = useSelector((state) =>
    state.get(getUniqueKey("assignLookup"))
  );

  // taskData에서 가져오는 analyticalDatas
  const [analyticalDatas, setAnalyticalDatas] = useState({
    developers: [],
    devSchedule: [],
    crInfo: {},
  });
  const [analyticalFileDatas, setAnalyticalFileDatas] = useState({
    fileList: [],
    urlList: [],
  });
  // 수정 및 입력 시 변경되는 analyticalDatas
  const [editAnalyticalDatas, setEditAnalyticalDatas] = useState({});
  // 수정 화면 여부 state
  const [editable, setEditable] = useState(false);

  const [deleteFiles, setDeleteFiles] = useState([]);
  const [visibleCopyFileName, setVisibleCopyFileName] = useState([]);

  // 개발 시작, 수정 확인 모달 Visible State
  const [startDevVisible, setStartDevVisible] = useState(false);
  const [editDevVisible, setEditDevVisible] = useState(false);


  const selectCr = crInfo.filter(
    (v) => v.crType === assignLookup.taskData.taskType && v.crEnvGubun === "DEV"
  );

  const goToDiyPrd = crInfo.filter(
    (v) => v.crType === assignLookup.taskData.taskType && v.crEnvGubun === "PRD"
  );

  // 파일 세팅 함수
  const fetchFileList = async () => {
    try {
      const files = await fetchAbsolute(
        "get",
        `/file/listTaskFile/${assignLookup.taskData?.taskId}`
      );
      const fileList = files.data
        ?.filter((file) => file.type.trim() === "002")
        .map((file) => ({
          down: file.down,
          name: file.fileName,
          uploaded: true,
          seq: file.seq,
          uid: file.seq,
        }));
      const urlList = files.data
        ?.filter((file) => file.type.trim() === "003")
        .map((file) => ({
          name: file.down,
          uploaded: true,
          seq: file.seq,
        }));
      setVisibleCopyFileName([...urlList.map((v) => false)]);
      setAnalyticalFileDatas({
        fileList: [...fileList],
        urlList: [...urlList],
      });
    } catch { }
  };

  const getMemberList = async (numbers) => {
    const arr = [];
    for (let i = 0; i < numbers.length; i++) {
      const number = numbers[i];
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${number}`
      );
      arr.push(result.data.mdFPAList[0]);
    }
    return arr;
  };

  // request Parameter 세팅 함수
  const setRequestParameter = (data) => {
    let parameter = {
      modId: profile.EMP_NO,
      statusCd: "T20",
      detailStatusCd: "T21",
      botFwYn: data.botFwYn,
      devSchFr: data.devSchFr,
      devSchTo: data.devSchTo,
      devCr: data.devCr,
      commonFileList: data.urlList.map((v, index) => ({
        seq: index,
        down: v.name,
      })),
    };

    if(data.botFwYn) {
      parameter = {...parameter, botFwYn:data.botFwYn}
    }

    return parameter;
  };

  // 개발 시작 버튼 - ※수정(업무 담당자에게 알림 발송, API 연결)
  const onOkStartDev = async () => {
    setStartDevVisible(false);
    dispatch(setVisible(true));

    const mergeData = {
      ...editAnalyticalDatas,
      botFwYn: editAnalyticalDatas.botFwYn ? editAnalyticalDatas.botFwYn : "N",
      devSchFr: moment(editAnalyticalDatas.devSchedule?.[0]).format("YYYYMMDD"),
      devSchTo: moment(editAnalyticalDatas.devSchedule?.[1]).format("YYYYMMDD"),
      devCr: crInfo.find(
        (v) =>
          v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "DEV"
      )?.crEnvGubun,
    };

    for (const key in analyticalRequire) {
      const field = analyticalRequire[key];
      if (
        key !== "fileList" &&
        (Object.keys(mergeData[key]).length <= 0 || !mergeData[key])
      ) {
        field.ref.current.focus();
        dispatch(setVisible(false));
        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
        //`${field.message} 항목은 필수 입력입니다.`);
      }
    }

    if (mergeData.urlList.length === 0 && mergeData.fileList.length === 0) {
      analyticalRequire.fileList.ref.current.focus();
      dispatch(setVisible(false));
      return alert(techFormRequired);
    }

    const parameter = setRequestParameter(mergeData);
    const modifyParameter = [
      ...assignLookup.taskData?.taskMemberList
        .filter((v) => v.mgrDevGbn === "M")
        .map((v) => ({
          mgrDevGbn: "M",
          empNo: v.empNo,
          deputyHeadYn: v.deputyHeadYn,
        })),
      ...mergeData.developers.map((v, index) => ({
        mgrDevGbn: "D",
        empNo: v.empNum,
        deputyHeadYn: index === 0 ? "N" : "Y",
      })),
    ];

    const startParameter = {
      ...assignLookup.taskData,
      taskMemberList: [...modifyParameter],
      ...parameter,
    };

    // 과제 상태 변경 및 URL List 업로드
    const dResult = await fetchAbsolute(
      "put",
      `/task/oracle/startTaskDev/${assignLookup.taskData?.taskId}`,
      {
        data: { ...startParameter },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (dResult.error) {
      dispatch(setVisible(false));
      return alert(developStartFail);
    }

    const newTaskData = await fetchAbsolute(
      "get",
      `/task/oracle/getTask/${assignLookup.taskData?.taskId}`
    );
    if (newTaskData.error) {
      dispatch(setVisible(false));
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    }

    dispatch(changeHistoryData(dResult.data[0], { ...newTaskData.data }));
    for (let i = 0; i < deleteFiles.length; i++) {
      const seq = deleteFiles[i];
      await fetchAbsolute("delete", `/file/deleteTaskFile/${seq}`);
    }
    const formData = new FormData();
    mergeData.fileList
      .filter((file) => !file.uploaded)
      .forEach((file) => formData.append("files", file));

    formData.append("empNo", profile.EMP_NO);
    formData.append("taskId", assignLookup.taskData?.taskId);
    formData.append("type", "002");

    const fResult = await fetchAbsolute("post", "/file/uploadTaskMultipleFiles", {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (fResult.error) {
      dispatch(setVisible(false));
      return alert(fail);
    }
    if (assignLookup.taskData?.taskType === "DIY") {
      sendNoti("TP06", assignLookup.taskData?.taskId);
    } else if (assignLookup.taskData?.taskType === "COE") {
      sendNoti("TP07", assignLookup.taskData?.taskId);
    }

    const originDev = [];
    if (
      assignLookup.taskData?.taskMemberList.find((v) => v.mgrDevGbn === "D")
        ?.length > 0
    ) {
      originDev
        .concat(
          assignLookup.taskData?.taskMemberList.find(
            (v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N"
          ).empNo
        )
        .concat(
          ...assignLookup.taskData?.taskMemberList
            .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
            .map((v) => v.empNo)
            .sort()
        );
    }
    const newDev = [];
    if (modifyParameter.find((v) => v.mgrDevGbn === "D")?.length > 0) {
      newDev
        .concat(
          modifyParameter.find(
            (v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N"
          ).empNo
        )
        .concat(
          ...modifyParameter
            .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
            .map((v) => v.empNo)
            .sort()
        );
    }

    if (JSON.stringify(originDev) !== JSON.stringify(newDev)) {
      sendNoti("TP24", assignLookup.taskData?.taskId);
    }

    alert(formatMessage({ id: "T0162" })); // 작업을 완료하였습니다.
    dispatch(setVisible(false));
    setEditable(false);
    fetchFileList();
    setDeleteFiles([]);
  };

  // 개발 시작 Modal Cancel 함수
  const onCancelStartDev = () => setStartDevVisible(false);
  // 개발 시작 버튼 클릭 함수
  const onClickStartDevButton = () => setStartDevVisible(true);

  /* 수정 확정 버튼을 눌렀을 때 */
  const onOkModifyDev = async () => {
    setEditDevVisible(false);
    dispatch(setVisible(true));

    const mergeData = {
      ...editAnalyticalDatas,
      devSchFr: moment(editAnalyticalDatas.devSchedule[0]).format("YYYYMMDD"),
      devSchTo: moment(editAnalyticalDatas.devSchedule[1]).format("YYYYMMDD"),
      devCr: crInfo.find(
        (v) =>
          v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "DEV"
      )?.crEnvGubun,
    };

    for (const key in analyticalRequire) {
      const field = analyticalRequire[key];
      if (
        key !== "fileList" &&
        (Object.keys(mergeData[key]).length <= 0 || !mergeData[key])
      ) {
        field.ref.current.focus();
        dispatch(setVisible(false));
        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
        //`${field.message} 항목은 필수 입력입니다.`);
      }
    }

    if (mergeData.urlList.length === 0 && mergeData.fileList.length === 0) {
      analyticalRequire.fileList.ref.current.focus();
      dispatch(setVisible(false));
      return alert(techFormRequired);
    }

    const parameter = setRequestParameter(mergeData);

    delete parameter.statusCd;
    delete parameter.detailStatusCd;

    const modifyParameter = [
      ...assignLookup.taskData?.taskMemberList
        .filter((v) => v.mgrDevGbn === "M")
        .map((v) => ({
          mgrDevGbn: "M",
          empNo: v.empNo,
          deputyHeadYn: v.deputyHeadYn,
        })),
      ...mergeData.developers.map((v, index) => ({
        mgrDevGbn: "D",
        empNo: v.empNum,
        deputyHeadYn: index === 0 ? "N" : "Y",
      })),
    ];

    const result = await fetchAbsolute(
      "put",
      `/task/oracle/modifyTask/${assignLookup.taskData?.taskId}`,
      {
        data: {
          ...parameter,
          taskMemberList: [...modifyParameter],
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (result.error) {
      dispatch(setVisible(false));
      return alert(updateFail);
    }

    dispatch(setTaskData({ ...result.data }));

    // file 지우기
    if (deleteFiles) {
      for (let i = 0; i < deleteFiles.length; i++) {
        const { seq } = deleteFiles[i];
        await fetchAbsolute("delete", `/file/deleteTaskFile/${seq}`);
      }
    }

    const formData = new FormData();

    mergeData.fileList
      .filter((file) => !file.uploaded)
      .forEach((file) => formData.append("files", file));

    formData.append("empNo", profile.EMP_NO);
    formData.append("taskId", assignLookup.taskData?.taskId);
    formData.append("type", "002");

    const fResult = await fetchAbsolute("post", "/file/uploadTaskMultipleFiles", {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (fResult.error) {
      dispatch(setVisible(false));
      return alert(fail);
    }

    const originDev = []
      .concat(
        assignLookup.taskData?.taskMemberList.find(
          (v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N"
        ).empNo
      )
      .concat(
        ...assignLookup.taskData?.taskMemberList
          .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
          .map((v) => v.empNo)
          .sort()
      );

    const newDev = []
      .concat(
        modifyParameter.find(
          (v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N"
        ).empNo
      )
      .concat(
        ...modifyParameter
          .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
          .map((v) => v.empNo)
          .sort()
      );

    if (JSON.stringify(originDev) !== JSON.stringify(newDev)) {
      sendNoti("TP24", assignLookup.taskData?.taskId);
    }

    alert(updateSuccess);
    setEditable(false);
    dispatch(setVisible(false));

    fetchFileList();
    setDeleteFiles([]);
  };
  // 수정 확정 Modal Cancel 함수
  const onCancelModifyDev = () => {
    setEditDevVisible(false);
  };
  // 수정 버튼 클릭 함수
  const onClickEditDevButton = () => setEditDevVisible(true);

  const copyText = (text) => {
    const tempElem = document.createElement("textarea");
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
  };

  // analytical 데이터 입력 시 state 세팅 함수
  const onChangeEditAnalyDatas = (name, val) => {
    if (Array.isArray(editAnalyticalDatas[name])) {
      setEditAnalyticalDatas({
        ...editAnalyticalDatas,
        [name]: !val ? [] : val,
      });
    } else {
      setEditAnalyticalDatas({
        ...editAnalyticalDatas,
        [name]: val,
      });
    }
  };

  // statementURL 추가 시 실행하는 함수
  const onAddStatementURL = (url) => {
    const tmpArr = [...(editAnalyticalDatas.urlList || [])];
    tmpArr.push({ name: url.trim() });
    setEditAnalyticalDatas({ ...editAnalyticalDatas, urlList: [...tmpArr] });
  };
  // statementURL 삭제 시 실행하는 함수
  const onClickDeleteStatementURL = (link) => {
    setEditAnalyticalDatas({
      ...editAnalyticalDatas,
      urlList: editAnalyticalDatas.urlList.filter((v) => v.name !== link),
    });
  };

  const onBeforeupload = (inFile) => {
    if (
      editAnalyticalDatas.fileList.findIndex(
        (file) => file.name === inFile.name
      ) !== -1
    ) {
      alert(sameNameNotUpload);
      return false;
    }
    tmpFile.current.fileList.push(inFile);
    onChangeEditAnalyDatas("fileList", [...tmpFile.current.fileList]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) {
      const tmpArr = [...deleteFiles];
      tmpArr.push({ ...rmFile, type: "002" });
      setDeleteFiles([...tmpArr]);
    }
    tmpFile.current.fileList = [
      ...(editAnalyticalDatas.fileList || []).filter(
        (file) => file.uid !== rmFile.uid
      ),
    ];
    onChangeEditAnalyDatas("fileList", [...tmpFile.current.fileList]);
  };

  /* 수정 버튼 눌렀을 경우 */
  const onClickEditableButton = () => {
    setEditable(true);
    setEditAnalyticalDatas({
      ...analyticalDatas,
      ...analyticalFileDatas,
    });
  };
  const onClickCancelEditButton = () => setEditable(false);

  const onClickCopyName = (data, index) => {
    copyText(data.name);
    tmpVisibleCopyName.current[index] = true;
    setVisibleCopyFileName([...tmpVisibleCopyName.current]);

    copyFileNameTimeout.current = setTimeout(() => {
      tmpVisibleCopyName.current[index] = false;
      setVisibleCopyFileName([...tmpVisibleCopyName.current]);
    }, 1000);
  };

  const onClickDeleteButton = (e, { label, index }) => {
    const tmpArr = [...editAnalyticalDatas[label]];
    tmpArr.splice(index, 1);
    setEditAnalyticalDatas({ ...editAnalyticalDatas, [label]: tmpArr });
  };

  useEffect(() => {
    if (editAnalyticalDatas.fileList?.length > 0)
      tmpFile.current.fileList = [...(editAnalyticalDatas.fileList || [])];
    if (editAnalyticalDatas.urlList?.length > 0)
      tmpFile.current.urlList = [...(editAnalyticalDatas.urlList || [])];
  }, [editAnalyticalDatas.fileList, editAnalyticalDatas.urlList]);

  // TaskData가 변경될 때 analytical Data를 변경해준다.
  useEffect(() => {
    if (!assignLookup.taskData?.taskId) return;
    const fetchInfo = async () => {
      const developersMain = await getMemberList(
        assignLookup.taskData?.taskMemberList
          .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N")
          .map((v) => v.empNo)
      );
      const developersSub = await getMemberList(
        assignLookup.taskData?.taskMemberList
          .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
          .map((v) => v.empNo)
      );
      const developers = developersMain.concat(...developersSub);

      const devSchedule = [];
      if (
        !assignLookup.taskData?.devSchFr ||
        !assignLookup.taskData?.devSchTo
      ) {
        const fromDate = moment();
        const toDate = moment().add(1, "months");
        devSchedule.push(fromDate, toDate);
      } else {
        devSchedule.push(
          moment(assignLookup.taskData?.devSchFr),
          moment(assignLookup.taskData?.devSchTo)
        );
      }
      setAnalyticalDatas({
        developers,
        devSchedule: [...devSchedule],
        crInfo: crInfo.find(
          (v) =>
            v.crType === assignLookup.taskData?.taskType &&
            v.crEnvGubun === "DEV"
        ),
      });
    };
    fetchInfo();
    fetchFileList();
  }, [assignLookup.taskData]);

  // analyticalData가 변경되면 수정 시 해당 내용들이 변경되어야하기 때문에 함께 변경해준다.
  useEffect(() => {
    setEditAnalyticalDatas({
      ...analyticalDatas,
      ...analyticalFileDatas,
    });
  }, [analyticalDatas, analyticalFileDatas]);

  return (
    <>
      <Modal
        centered
        visible={startDevVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0514" })} // 개발 시작 확인
        onOk={onOkStartDev}
        onCancel={onCancelStartDev}
      >
        <Text>{formatMessage({ id: "T0515" })}</Text>{/* 개발을 시작하시겠습니까? */}
      </Modal>
      <Modal
        centered
        visible={editDevVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0516" })} // 수정 확인
        onOk={onOkModifyDev}
        onCancel={onCancelModifyDev}
      >
        <Text>{formatMessage({ id: "T0517" })}</Text>{/* 수정하시겠습니까? */}
      </Modal>
      {(assignLookup.taskData?.statusCd === "T10" &&
        assignLookup.taskData?.detailStatusCd === "T11") ||
        assignLookup.taskData?.statusCd === "T50" ? 
        (
        <InactiveForm
          taskData={assignLookup.taskData || {}}
          selectOption={selectOption}
        />
      ) : (assignLookup.taskData?.statusCd === "T10" &&
        (assignLookup.taskData?.detailStatusCd === "T12" ||
          assignLookup.taskData?.taskType === "DIY")) 
        || editable ? (
        <EditableForm
          onClickStartDevButton={onClickStartDevButton}
          onBeforeupload={onBeforeupload}
          onRemoveFile={onRemoveFile}
          editable={editable}
          onClickCancelEditButton={onClickCancelEditButton}
          editAnalyticalDatas={editAnalyticalDatas}
          onAddStatementURL={onAddStatementURL}
          analyticalRequire={analyticalRequire}
          onChangeEditAnalyDatas={onChangeEditAnalyDatas}
          onClickEditDevButton={onClickEditDevButton}
          onClickDeleteStatementURL={onClickDeleteStatementURL}
          onClickDeleteButton={onClickDeleteButton}
          selectOption={selectOption}
          selectCr={selectCr}
          goToDiyPrd={goToDiyPrd}

        />
      ) : (
        <ResultForm
          onClickEditableButton={onClickEditableButton}
          taskData={assignLookup.taskData || {}}
          analyticalDatas={analyticalDatas}
          visibleCopyFileName={visibleCopyFileName}
          onClickCopyName={onClickCopyName}
          analyticalFileDatas={analyticalFileDatas}
          selectOption={selectOption}
          selectCr={selectCr}
          goToDiyPrd={goToDiyPrd}
        />
      )}
    </>
  );
};

export default LookupAnalytical;
