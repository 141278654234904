import { getUniqueKey } from 'utils/Str';

export const SET_MENU_LIST = getUniqueKey('SET_MENU_LIST');
export const SET_PATH_NAME = getUniqueKey('SET_PATH_NAME');
export const LOAD_MENU_LIST = getUniqueKey('LOAD_MENU_LIST');
export const SET_SELECTED_ID = getUniqueKey('SET_SELECTED_ID');

export function setMenuList() {
    return {
        type: SET_MENU_LIST
    };
};

export function setSelectedId(data) {
    return {
        type: SET_SELECTED_ID,
        data
    };
};

export function setPathname(path) {
    return {
        type: SET_PATH_NAME,
        path
    };
};

export function loadMenuList(menuData, displayData) {
    return {
        type: LOAD_MENU_LIST,
        menuData,
        displayData
    };
};