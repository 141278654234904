import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import {
    Table, Text, Button, Input, InputSearch, Organization, PageTemplate, Header, Footer, Collapse, FormTable,
} from "components";
import { Select, Tooltip, Collapse as AntCollapse, Row, Col } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from 'icons';
import fetchAbsolute from "utils/fetchAbsolute";
import queryString from "query-string";
import { Link } from 'react-router-dom';
import Wrapper from "./Styled";

/**
 * 관리자 > 내부 시스템 메뉴 관리 > 내부 시스템 메뉴 목록 페이지
 *
 */
const { Option } = Select;
const { Panel } = AntCollapse;

const initialInputValues = {
    category: [],
    mgrIds: [],
    systemName: '',
};

const initialPageInfo = {
    sort: "category",
    direction: ",asc",
    currentPage: 1,
    rowPerPage: 20,
    total: 0
};

const AdminIternalSystemList = ({ history }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const [classificationList, setClassificationList] = useState([]);
    const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
    const [sortedInfo, setSortedInfo] = useState({});
    const [inputValues, setInputValues] = useState({ ...initialInputValues });
    const [systemDataList, setSystemDataList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [mgrIdsVisible, setMgrIdsVisible] = useState(false);
    const [openCollapse, setOpenCollapse] = useState(true);

    const pageSize = [20, 50, 70];

    const onChangeCollapse = () => { setOpenCollapse((openCollapse) => !openCollapse); };

    /* 분류 Select */
    const onChangeClassification = (e) => {
        let tmpArr = [];
        for (let i in e) {
            const value = classificationList.find((cate) => cate.cd === e[i]);
            tmpArr.push(value);
        }
        setInputValues({
            ...inputValues,
            category: [...tmpArr],
        });
    };

    /* inputValues, searchValues */
    const onChangeValues = (e) => {
        const { name, value } = e.target;
        setInputValues({
            ...inputValues,
            [name]: value,
        });
    };

    /* 조직도 */
    const onOkOrg = (data) => {
        setInputValues({ ...inputValues, mgrIds: [...data] });
        setMgrIdsVisible(false);
    };

    const onClickOrg = () => {
        setMgrIdsVisible(true);
    };

    const onCancelOrg = () => {
        setMgrIdsVisible(false);
    };

    /* 페이지당 행 */
    const onChangePageSize = (e) => {
        getIternalMenuList(inputValues, { ...pageInfo, rowPerPage: +e, currentPage: 1 });
    };


    /* 검색 버튼 */
    const onClickSearch = () => {
        replaceSearch(inputValues, initialPageInfo);
    };

    /* 검색 초기화 버튼 */
    const onClickReset = () => {
        setInputValues({ ...initialInputValues });
        setPageInfo({ ...initialPageInfo });
        getIternalMenuList(initialInputValues, initialPageInfo);
        setSortedInfo({});
    };

    /* 검색 부분 query 만드는 메서드 */
    const replaceSearch = async (searchValues, searchPageInfo) => {
        const tmpObj = { ...searchPageInfo, ...searchValues };
        Object.keys(tmpObj).forEach((key, index) => {
            const data = tmpObj[key];

            if (["category"].includes(key)) {
                const tmpArr = [];
                for (let i in data) {
                    tmpArr.push(data[i].cd);
                }
                tmpObj[key] = tmpArr;
            }
            if (["mgrIds"].includes(key)) {
                const tmpArr = [];
                for (let i in data) {
                    tmpArr.push(data[i].empNum);
                }
                tmpObj[key] = tmpArr;
            }
        });

        const searchString = queryString.stringify(tmpObj);
        getIternalMenuList(tmpObj, { ...pageInfo, ...searchPageInfo });
        history.push({ search: searchString });
    };

    // 과제 ID나 과제명 인풋창에서 엔터 누르면 검색
    const onKeyPress = (e) => {
        if (e.key === "Enter") {
            onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
        }
    };

    /* 테이블 행 정렬 */
    const onChangeTableColumns = (pagination, filters, sorter) => {
        let order = sorter.field ? sorter.field : 'category';
        let orderBy = "";
        if (!sorter.order) {
            order = "category"
            orderBy = ",asc"
        }
        else if (sorter.order?.includes("desc")) orderBy = ",desc";
        else if (sorter.order?.includes("asc")) orderBy = ",asc";
        const searchObj = {
            ...pageInfo,
            sort: order,
            direction: orderBy,
            currentPage: pagination.current
        };
        setSortedInfo(sorter);
        getIternalMenuList(inputValues, searchObj);
    };

    /* 내부 시스템 메뉴 목록 */
    const getIternalMenuList = async (searchValues, page) => {
        if (page === undefined) {
            page = { ...pageInfo }
        }
        if (searchValues === undefined) {
            searchValues = { ...inputValues };
        }
        setTableLoading(true);
        const result = await fetchAbsolute(
            "post",
            `/admin/system/systemListsMulVal?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`, {
            data: {
                category: searchValues.category,
                systemUser: searchValues.mgrIds,
                systemName: searchValues.systemName,
            },
        }
        );
        setTableLoading(false);
        if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
        setSystemDataList([...result.data.lists]);
        setPageInfo({ ...page, total: +result.data.totalCount });
    };

    const columns = [
        {
            key: "category",
            title: formatMessage({ id: "T0362" }), // 분류 
            width: 100,
            dataIndex: "category",
            sorter: a => a.category,
            sortOrder: sortedInfo.columnKey === 'category' && sortedInfo.order,
            render: (text, record, index) => {
                const value = classificationList.find((cate) => cate.cd === text);
                return (
                    <Tooltip title={text} key={index}>
                        <Link to={`/admin/iternalsystem/modify/${record.systemCd}`}>
                            <div
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                }}
                            >
                                {value?.cdNm}
                            </div>
                        </Link>
                    </Tooltip>
                );
            },
        },
        {
            key: "systemName",
            title: formatMessage({ id: "T0363" }), // 시스템 명,  
            dataIndex: "systemName",
            sorter: a => a.systemName,
            sortOrder: sortedInfo.columnKey === 'systemName' && sortedInfo.order,
            width: 250,
            render: (text, record, index) => {
                return (
                    <Tooltip title={text} key={index}>
                        <Link to={`/admin/iternalsystem/modify/${record.systemCd}`}>
                            <div
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                }}
                            >
                                {text}
                            </div>
                        </Link>
                    </Tooltip>
                );
            },
        },
        {
            key: "systemUser",
            title: formatMessage({ id: "T0365" }), // 시스템 담당자,  
            width: 100,
            dataIndex: "systemUser",
            sorter: (a) => a.systemUser,
            sortOrder: sortedInfo.columnKey === 'systemUser' && sortedInfo.order,
            render: (text, record, index) => {
                return (
                    <Tooltip title={text} key={index}>
                        <Link to={`/admin/iternalsystem/modify/${record.systemCd}`}>
                            <div
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    cursor: "pointer",
                                }}
                            >
                                {record.empNam}
                            </div>
                        </Link>
                    </Tooltip>

                );
            },
        },
    ];

    /* System Manage 기본 정보 셋팅 */
    useEffect(() => {
        const getClassification = async () => {
            const result = await fetchAbsolute(
                "get",
                `/common/commonCd/getCategoryId/USESYS_TYPE`
            );
            if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
            setClassificationList([...result.data]);
        };
        getClassification();
        getIternalMenuList();
    }, []);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
            <Wrapper>
                <div className="common__container">
                    <Collapse
                        expandIconPosition="end"
                        onChange={onChangeCollapse}
                        expandIcon={() =>
                            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
                        }
                        activeKey={openCollapse ? ["1"] : []}
                        defaultActiveKey={openCollapse ? ["1"] : []}
                    >
                        <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
                            <FormTable pointBorder={false} size="sm">
                                <colgroup>
                                    <col width="10.375%" />
                                    <col width="13.625%" />
                                    <col width="10.375%" />
                                    <col width="13.625%" />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th style={{ borderRadius: "14px 0 0 0" }}>
                                            <Text>{formatMessage({ id: "T0362" })}</Text>
                                            {/* 분류 */}
                                        </th>
                                        <td>
                                            <Select
                                                mode="multiple"
                                                maxTagCount="responsive"
                                                style={{ width: 250 }}
                                                onChange={onChangeClassification}
                                                value={inputValues.category.map((cate) => cate.cd)}
                                                name="category"
                                                placeholder={formatMessage({ id: "T0362" })} // 분류
                                            >
                                                {classificationList.map((v) => {
                                                    return (
                                                        <Option value={v.cd} key={v.cd}>
                                                            {v.cdNm}
                                                        </Option>
                                                    );
                                                })}
                                            </Select>
                                        </td>
                                        <th><Text>{formatMessage({ id: "T0363" })}</Text></th>{/* 시스템 명 */}
                                        <td>
                                            <Input
                                                style={{ width: 250 }}
                                                placeholder={formatMessage({ id: "T0363" })} // 시스템 명
                                                value={inputValues.systemName}
                                                onChange={onChangeValues}
                                                name="systemName"
                                                maxLength={10}
                                                onKeyPress={onKeyPress}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th><Text>{formatMessage({ id: "T0365" })}</Text></th>{/* 시스템 담당자 */}
                                        <td>
                                            <InputSearch
                                                onClick={onClickOrg}
                                                width={250}
                                                height={32}
                                                data-label="mgrIds"
                                                placeholder={formatMessage({ id: "T0365" })} // 시스템 담당자
                                                value={inputValues.mgrIds.map(member => member.empNam)}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </FormTable>
                            <Row style={{ padding: "0 13px" }}>
                                <Col
                                    span={24}
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        gap: "0 25px",
                                        padding: "10px 0",
                                    }}
                                >
                                    <Button width="120" height="40" onClick={onClickReset}>
                                        <FormattedMessage id="T0037" />
                                        {/* 초기화 */}
                                    </Button>
                                    <Button
                                        width="120"
                                        height="40"
                                        type="primary"
                                        onClick={onClickSearch}
                                    >
                                        <FormattedMessage id="T0039" />
                                        {/* 검색 */}
                                    </Button>
                                </Col>
                            </Row>
                        </Panel>
                    </Collapse>
                    <div style={{ padding: '20px 0' }}>
                        <div className="top_each__section">
                            <div>
                                <Text>{formatMessage({ id: "T0036" })}</Text>&nbsp;
                                {/* 페이지당 행 */}
                                <Select
                                    style={{
                                        width: 89,
                                        height: 32,
                                    }}
                                    name="pagination"
                                    onSelect={onChangePageSize}
                                    value={pageInfo.rowPerPage}
                                >
                                    {pageSize.map((v, index) => (
                                        <Option key={index} value={v}>
                                            {fmMessage({ id: "T1228", values: { number: v } })}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <Link to="/admin/iternalsystem/enter">
                                    <Button type="primary" style={{ width: 150, height: 32 }} >
                                        <FormattedMessage id="T0049" />
                                    </Button>
                                    {/* 추가 */}
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Table
                            defaultValue={20}
                            columns={columns}
                            dataSource={systemDataList}
                            rowKey={(key) => key.systemCd}
                            pagination={true}
                            loading={tableLoading}
                            onChange={onChangeTableColumns}
                            pageOptions={{
                                total: +pageInfo.total,
                                current: +pageInfo.currentPage,
                                pageSize: +pageInfo.rowPerPage,
                            }}
                        />
                    </div>
                </div>
                <Organization
                    visible={mgrIdsVisible}
                    title={formatMessage({ id: "T0465" })} // 담당자 검색
                    onOk={onOkOrg}
                    onCancel={onCancelOrg}
                    defaultData={inputValues['mgrIds']}
                />
            </Wrapper>
        </PageTemplate >
    );
}
export default AdminIternalSystemList;