import React from "react";
import { Collapse as AntCollapse } from "antd";
import styled from "styled-components";
import { Container } from "semantic-ui-react";
/*
  ant-collapse-item ant-collapse-item-active
  ant-collapse-item
*/
const CollapseStyled = styled(AntCollapse)`
  border: 0;
  .ant-collapse-item:last-child > .ant-collapse-header {
    background-color: #fff;
    color: #FF6600;
    // 우리
    /* color: #0067ac; */
    font-weight: bold;
    font-size: 14px;
    width: 168px;
    height: 40px;
    padding-top: 7px;
    float: right;
    text-align: center;
    border-radius: 0;
  } 
  .collapse__body {
    text-align: center;
  }
  .ant-collapse-item {
    border: 0;
  }
  .ant-collapse-item.ant-collapse-item-active {
    background-color: #fff;
  }
  .ant-collapse-content {
    color: rgba(0, 0, 0, 0.85);
    background-color: #fff;
    clear: both;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
    border: 2px solid #d0d1d6;
    border-radius: 14px;
  }
  .ant-collapse-content.ant-collapse-content-active {
    border-top: none; 
  }
`; 

const Collapse = ({
  style = {},
  onChange = () => {},
  height = {},
  children,
  ...args
}) => {
  return (
    <Container className="collapse__container" style={{ overflow: "hidden" }}>
      <CollapseStyled className="collapse__body" onChange={onChange} {...args}>
        {children}
      </CollapseStyled>
    </Container>
  );
};

export default Collapse;
