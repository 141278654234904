import React, { useEffect, useState } from "react";
import { Bar, Pie } from "@ant-design/charts";
import { Text, InputSearch, Button, Loading } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

/**
 * Filename: AssignOperation.js
 * Description: 모니터링 > 운영 현황 모니터링 > 과제별 수행 현황
 */

const initialTypeStatus = {
  coeT10: 0,
  coeT20: 0,
  coeT30: 0,
  coeT40: 0,
  coeT50: 0,
  diyT10: 0,
  diyT20: 0,
  diyT30: 0,
  diyT40: 0,
  diyT50: 0,
};

const initialOperRatio = {
  notOperCnt: 0,
  notOperRatio: 0,
  operCnt: 0,
  operRatio: 0,
  total: 0,
};

/* 과제 유형별 상태 현황 */
const StatusChart = ({ searchDepartment }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const statusCode = {
    T10: formatMessage({ id: "T0395" }), // 접수
    T20: formatMessage({ id: "T0396" }), // 개발
    T30: formatMessage({ id: "T0397" }), // 테스트
    T40: formatMessage({ id: "T0398" }), // 운영
    T50: formatMessage({ id: "T0053" }), // 중단
  };

  const [config, setConfig] = useState({
    data: [],
    isStack: true,
    xField: "value",
    yField: "status",
    seriesField: "type",
    color: (_ref) => {
      const { type } = _ref;
      return type === "COE" ? "#1B99B3" : "#5C4B7B";
    },
    legend: {
      layout: "vertical",
      position: "right-bottom",
    },
    label: {
      position: "middle",
      layout: [
        { type: "interval-adjust-position" },
        { type: "interval-hide-overlap" },
        { type: "adjust-color" },
      ],
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      /* 데이터 가공해주기 */
      const orgCd = searchDepartment.map((data) => {
        const tmpArr = [];
        tmpArr.push(data.codeId);
        return tmpArr;
      });
      setIsLoading(true);
      const result = await fetchAbsolute(
        "post",
        `/monitor/taskPerform/getTaskTypeStatusMulVal`,
        {
          data: {
            orgCd: orgCd.flat(),
          },
        }
      );
      setIsLoading(false);
      if (result.error) {
        return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      }

      const tmpArr = Object.keys({ ...initialTypeStatus, ...result.data }).map(
        (v) => {
          const key = v.toUpperCase();

          return {
            status: statusCode[key.slice(3, key.length)],
            value: result.data[v] ? +result.data[v] : 0,
            type: key.slice(0, 3) === "DIY" ? "DIY" : "COE",
          };
        }
      );
      setConfig({
        ...config,
        data: [...tmpArr],
      });
    };
    fetchData();
  }, [searchDepartment]);

  if (isLoading) {
    return <Loading visible={isLoading} minHeight={10} height={20} />;
  }
  return <Bar {...config} style={{ height: "400px" }} />;
};

/* 과제 접수 대비 운영 비율 */
const RatioChart = ({ searchDepartment }) => {
  const fmMessage = useFormatMessage();
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const [config, setConfig] = useState({
    data: [],
    angleField: "value",
    colorField: "type",
    radius: 1,
    color: (_ref) => {
      const { type } = _ref;
      return type === `${formatMessage({ id: "T0398" })}`// 운영
        ? "#ff7a00"
        : "#7F7F8D";
    },
    // #0e5a8c
    label: {
      type: "inner",
      offset: "-30%",
      autoRotate: false,
      content: function content(_ref) {
        return "".concat(_ref.type).concat("\n").concat(_ref.value).concat("%");
      },
      style: {
        fontSize: 15,
      },
    },
    legend: {
      position: "left-bottom",
      layout: "vertical",
      itemName: {
        formatter: (text, item) => {
          return text;
        },
      },
    },
    interactions: [{ type: "element-selected" }, { type: "element-active" }],
    statistic: {
      title: false,
      content: false,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      /* 데이터 가공해주기 */
      const orgCd = searchDepartment.map((data) => {
        const tmpArr = [];
        tmpArr.push(data.codeId);
        return tmpArr;
      });
      setIsLoading(true);
      const result = await fetchAbsolute(
        "post",
        `/monitor/taskPerform/getTaskOperRatioMulVal`,
        {
          data: {
            orgCd: orgCd.flat(),
          },
        }
      );
      setIsLoading(false);
      if (result.error) {
        return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      }
      const tmpArr = Object.keys({ ...initialOperRatio, ...result.data })
        .filter((v) => v.includes("Ratio"))
        .map((v) => {
          return {
            type:
              v === "operRatio"
                ? `${formatMessage({ id: "T0398" })}` // 운영
                : `${formatMessage({ id: "T0591" })}`, // 미운영
            value: +(result.data[v] || 0),
          };
        })
        .reverse();
      setConfig({
        ...config,
        data: [...tmpArr],
        legend: {
          ...config.legend,
          title: {
            // text: `전체 접수: ${result.data.total}건`,
            text: `${fmMessage({
              id: "T0592",  // 전체 접수 : {number} 건
              values: { number: result.data.total || 0 },
            })}`,
            spacing: 20,
            style: {
              fontWeight: "bold",
            },
          },
          itemName: {
            formatter: (text) => {
              if (text === `${formatMessage({ id: "T0398" })}`) {
                // 운영
                return `${text}: ${result.data.operCnt || 0}건`;
              } else if (text === `${formatMessage({ id: "T0591" })}`) {
                // 미운영
                return `${text}: ${result.data.notOperCnt || 0}건`;
              } else {
                return text;
              }
            },
          },
        },
      });
    };
    fetchData();
  }, [searchDepartment]);

  if (isLoading) {
    return <Loading visible={isLoading} minHeight={10} height={20} />;
  }
  return <Pie {...config} style={{ height: "400px" }} />;
};

/*  과제 운영 평균 소요 시간  */
const ProdTimeChart = ({ searchDepartment }) => {
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  /* 형태가 달라지면서 순서가 필요해짐 */
  const statusCode = {
    devAvgDay: `${formatMessage({ id: "T0593" })}`, // 접수 - 개발
    testAvgDay: `${formatMessage({ id: "T0594" })}`, // 접수 - 테스트
    prdAvgDay: `${formatMessage({ id: "T0595" })}`, // 접수 - 운영
  }

  const [config, setConfig] = useState({
    data: [],
    appendPadding: [10, 60, 6, 20],
    isStack: true,
    xField: "value",
    yField: "label",
    legend: false,
    color: "#59617F",
    label: {
      position: "right",
      formatter: (_ref) => {
        const { value } = _ref;
        return `Days - ${value}`;
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      /* 데이터 가공해주기 */
      const orgCd = searchDepartment.map((data) => {
        const tmpArr = [];
        tmpArr.push(data.codeId);
        return tmpArr;
      });
      setIsLoading(true);
      const result = await fetchAbsolute(
        "post",
        `/monitor/taskPerform/getTaskAvgTimeMulVal`,
        {
          data: {
            orgCd: orgCd.flat(),
          },
        }
      );
      setIsLoading(false);
      if (result.error) {
        return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      }
      // const tmpArr = Object.keys(result.data)
      const tmpArr = Object.keys(statusCode)
        .map((v) => {
          return {
            label: statusCode[v],
            value: Math.round(+result.data[v] * 10) / 10
          }
        });
      setConfig({
        ...config,
        data: [...tmpArr],
      });
    };
    fetchData();
  }, [searchDepartment]);

  if (isLoading) {
    return <Loading visible={isLoading} minHeight={10} height={20} />;
  }
  return <Bar {...config} style={{ height: "400px" }} />;
};

const AssignPerform = ({
  onOpenDepartment,
  department,
  onClickSearchButton,
  searchDepartment,
}) => {
  const { formatMessage } = useIntl();
  return (
    <div className="operation_box">
      <div className="operation__top_menu">
        <div className="top_menu__box">
          <Text className="top_menu__text">
            {formatMessage({ id: "T0005" })}{/* 담당부서 */}
          </Text>
          <InputSearch
            placeholder={formatMessage({ id: "T0029" })} // 담당 부서 검색
            width={200}
            height={32}
            onClick={onOpenDepartment}
            value={department.map((c) => c.codeNm).join(", ")}
          />
        </div>
        <Button
          className="top_menu__button"
          type="primary"
          onClick={onClickSearchButton}
        >
          {formatMessage({ id: "T0039" })}{/* 검색 */}
        </Button>
      </div>
      <div className="operation__content">
        <div className="operation__content_box content__chart_box content__type_status">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0596" })}</Text>{/* 과제 유형별 상태 현황 */}
          </div>
          <div className="content__main">
            <div>
              <StatusChart searchDepartment={searchDepartment} />
            </div>
          </div>
        </div>
        <div className="operation__content_box content__chart_box content__prod_ratio">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0597" })}</Text>{/* 과제 접수 대비 운영 비율 */}
          </div>
          <div className="content__main">
            <div>
              <RatioChart searchDepartment={searchDepartment} />
            </div>
          </div>
        </div>
        <div className="operation__content_box content__chart_box content__prod_time">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0598" })}</Text>{/* 과제 운영 평균 소요 시간 */}
          </div>
          <div className="content__main">
            <div>
              <ProdTimeChart searchDepartment={searchDepartment} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignPerform;
