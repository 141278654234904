import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import fetchAbsolute from "utils/fetchAbsolute";
import { SettingMainModal, Footer, MainPopup, ResearchPopup } from "components";
import MainTop from "./FixedDisplay/MainTop";
import RecommendTask from "./FloatDisplay/RecommendTask";
import KpiOrganization from "./FloatDisplay/KpiOrganization";
import Wrapper from "./Styled";
import PersonalArea from "./PersonalArea/PersonalArea";
import OperSituation from "./FloatDisplay/OperSituation";
import MainCommunity from "./FloatDisplay/MainCommunity";
import Ranking from "./FloatDisplay/Ranking";
import MyTaskList from "./FloatDisplay/MyTaskList";
import { getUniqueKey } from "utils/Str";
import { getCookie } from "utils/cookie";
import { Col, Row } from "antd";
import moment from "moment";
import { useIntl } from "react-intl";

/**
 *  홈, 레이아웃 및 컴포넌트들
 *
 */

const HomeComponent = ({ profile }) => {
  const { formatMessage } = useIntl();

  const initialComponent = [
    {
      key: "RecommendTask",
      component: RecommendTask,
      size: 24,
      visible: true,
      title: formatMessage({ id: "T0383" }), // 추천과제
    },
    {
      key: "KPIOrganization",
      component: KpiOrganization,
      size: 24,
      visible: true,
      title: formatMessage({ id: "T0384" }), // Dreago 현황
    },
    {
      key: "MyTaskList",
      component: MyTaskList,
      size: 12,
      visible: true,
      title: formatMessage({ id: "T0385" }), // 내 과제 목록
    },
    {
      key: "OperSituation",
      component: OperSituation,
      size: 12,
      visible: true,
      title: formatMessage({ id: "T1505" }), // 운영 현황(당일)
    },
    {
      key: "MainCommunity",
      component: MainCommunity,
      size: 12,
      visible: true,
      title: formatMessage({ id: "T0387" }), // 커뮤니티
    },
    {
      key: "Ranking",
      component: Ranking,
      size: 12,
      visible: true,
      title: formatMessage({ id: "T0388" }), // 랭킹
    },
  ];

  const pageSettingSaveFail = formatMessage({ id: "T0215" }); // 화면 설정 저장을 실패했습니다.
  const pageSettingSaveSuccess = formatMessage({ id: "T0216" }); // 화면 설정이 저장되었습니다.

  const menuFlatList = useSelector(
    (state) => state.get(getUniqueKey("menuList")).menuFlatList
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const onClickSettingButton = () => setIsModalVisible(true);

  const [components, setComponents] = useState([]);
  const [sizeArr, setSizeArr] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [researchList, setResearchList] = useState([]);
  const [rightHeight, setRightHeight] = useState(0);

  /* 이 부분 수정해서 봐야해 */
  const onResetMainSetting = async () => {
    const result = await fetchAbsolute(
      "get",
      `/main/layout/deleteMainLayout?companyCd=${profile.COMP_CD}`
    );
    setIsModalVisible(false);
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다. 
    alert(formatMessage({ id: "T1506" })); // 초기화하였습니다
    getMyLayout();
  };

  const onOkMainSetting = (data) => {
    const param = data.map((v) => ({ key: v.key, visible: v.visible }));
    setComponents([...data]);
    setIsModalVisible(false);
    /* 수정, 등록 api */
    const getMyLayout = async () => {
      const result = await fetchAbsolute(
        "post",
        `/main/layout/saveMainLayout`,
        {
          data: {
            companyCd: profile.COMP_CD,
            empNo: profile.EMP_NO,
            layout: [...param],
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.error) {
        return alert(pageSettingSaveFail);
      } else {
        alert(pageSettingSaveSuccess);
      }
    };
    getMyLayout();
  };

  const onClickClosePopup = (noticeSeq) => {
    const index = popupList.findIndex((v) => v.noticeSeq === noticeSeq);
    if (index === -1) return;
    const tmpArr = [...popupList];
    tmpArr[index].isClose = true;
    setPopupList([...tmpArr]);
  };

  const onClickCloseResearch = (seqR) => {
    const tmpArr = [...researchList];
    const index = tmpArr.findIndex((v) => +v.seqR === +seqR);
    if (index === -1) return;
    tmpArr.splice(index, 1);
    setResearchList([...tmpArr]);
  };

  /* 목록 가져오기 */
  const getMyLayout = async () => {
    if (menuFlatList.length === 0 || !profile.EMP_NO) return;
    const permissionFlag =
      menuFlatList.findIndex((v) => v.menuUrl === "/monitoring/status") !==
      -1;

    const result = await fetchAbsolute(
      "get",
      `/main/layout/getMainLayout?companyCd=${profile.COMP_CD}&empNo=${profile.EMP_NO}`
    );
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    const layoutData = result.data.layout || [];

    const originComponents = [...initialComponent];
    const mergeData = layoutData
      .map((v) => {
        const index = originComponents.findIndex((c) => c.key === v.key);
        if (index === -1) return undefined;
        const data = { ...originComponents[index] };
        const visible =
          typeof v.visible === "string" ? JSON.parse(v.visible) : v.visible;
        originComponents.splice(index, 1);
        return {
          ...data,
          ...v,
          visible,
        };
      })
      .concat([...originComponents])
      .filter(
        (v) =>
          v !== undefined && (permissionFlag || v.key !== "OperSituation")
      );
    setComponents([...mergeData]);
  };

  useEffect(() => {
    const tmpArr = [...components.filter((v) => v.visible).map((v) => v.size)];
    const arr = [];
    let count = 0;

    for (let i = 0; i < tmpArr.length; i++) {
      const data = tmpArr[i];
      const nextData = tmpArr[i + 1] || 0;
      if (data !== 12) continue;
      count++;
      if (data !== nextData) {
        if (count % 2 !== 0) arr.push(i - count + 1);
        count = 0;
      }
    }
    setSizeArr([...arr]);
  }, [components]);
  
  /* 사용자별 layout 설정 조회 */
  useEffect(() => {
    if (!profile) return;
    getMyLayout();
  }, [menuFlatList, profile.EMP_NO]);

  useEffect(() => {
    const fetchPopupList = async () => {
      const result = await fetchAbsolute("get", `/admin/notice/noticeList`);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      const popupCookie = getCookie("popup") || [];
      setPopupList([
        ...result.data
          .filter((v) => v.showYn === "Y")
          .map((v) => ({ ...v, isClose: popupCookie.includes(+v.noticeSeq) })),
      ]);
    };
    const fetchResearchList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/research/researchList?empNo=${profile.EMP_NO}&withEmpNoYn=N`
      );
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      const nowNum = +moment().format("YYYYMMDD");
      const tmpArr = result.data.filter((v) => {
        const frNum = +moment(v.researchFrom).format("YYYYMMDD");
        const toNum = +moment(v.researchTo).format("YYYYMMDD");
        return v.useYn === "Y" && frNum <= nowNum && nowNum <= toNum;
      });
      setResearchList([...tmpArr]);
    };
    fetchPopupList();
    fetchResearchList();
  }, []);

  return (
    <Wrapper>
      {popupList.filter((v) => !v.isClose).length > 0 && (
        <MainPopup
          popupList={popupList.filter((v) => !v.isClose)}
          onClickClosePopup={onClickClosePopup}
        />
      )}
      {researchList.length > 0 && (
        <ResearchPopup
          researchList={researchList}
          empNo={profile.EMP_NO}
          onClickCloseResearch={onClickCloseResearch}
        />
      )}
      <div className="home_component__container">
        <div
          className="main_left"
          ref={(ref) => {
            if (!ref) return;
            setRightHeight(+ref.offsetHeight);
          }}
        >
          <MainTop
            onClickSettingButton={onClickSettingButton}
            profile={profile}
          />
          <div className="float__area">
            <Row gutter={[20, 20]}>
              {components
                .filter((v) => v.visible)
                .map((colData, idx, array) => {
                  const size = sizeArr.includes(idx) ? 24 : colData.size;
                  return (
                    <Col span={size} key={idx}>
                      <colData.component />
                    </Col>
                  );
                })}
            </Row>
          </div>
          <Footer />
        </div>
        <div className="main_right" style={{ height: rightHeight }}>
          <PersonalArea profile={profile} />
        </div>
      </div>
      <SettingMainModal
        centered="centered"
        title={formatMessage({ id: "T0389" })} // Main 화면 설정
        visible={isModalVisible}
        onOk={onOkMainSetting}
        onCancel={() => setIsModalVisible(false)}
        defaultComponent={components}
        width="510px"
        onReset={onResetMainSetting}

      />

    </Wrapper>
  );
};

export default HomeComponent;
