import { getUniqueKey } from 'utils/Str';

export const SET_ASSIGNLIST_VALUE = getUniqueKey('SET_ASSIGNLIST_VALUE');
export const SET_ASSIGNLIST_SPREAD = getUniqueKey('SET_ASSIGNLIST_SPREAD');
export const RESET_ASSIGNLIST_VALUE = getUniqueKey('RESET_ASSIGNLIST_VALUE');
export const SET_ASSIGNLIST_FILTERID = getUniqueKey('SET_ASSIGNLIST_FILTERID');

export function setAssignListValue(key, value) {
    return {
        type: SET_ASSIGNLIST_VALUE,
        key,
        value
    };
}

export function setAssignListSpread(data) {
    return {
        type: SET_ASSIGNLIST_SPREAD,
        data
    }
}

export function resetAssignListValue() {
    return {
        type: RESET_ASSIGNLIST_VALUE
    }
}

export function setAssignListFilterId(filterId) {
    return {
        type: SET_ASSIGNLIST_FILTERID,
        filterId
    }
}