import { useIntl } from 'react-intl';

function useFormatMessage() {
    const { formatMessage } = useIntl();

    const fmMessage = ({ id, values = {} }) => { 
        let msg = formatMessage({ id });

        if (Object.keys(values).length > 0) {
            Object.entries(values).forEach(([key, value]) => {
                const pattern = `{${key}}`;
                const reg = new RegExp(pattern, 'g');
                msg = msg.replace(reg, value);
            });
        }
        return msg;
    }

    return fmMessage;
}

export default useFormatMessage;