import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { loadTaskPermission } from "store/permission/action";
import {
  PageTemplate,
  Header,
  Footer,
  AssignEnterBasicBox,
  AssignEnterWorkBox,
  AssignEnterSystemBox,
  Button,
  Organization,
  SystemSelector,
  UsingMenu,
  BusinessClass,
} from "components";
import Container from "containers/Container";
import fetchAbsolute from "utils/fetchAbsolute";
import sendNoti from "utils/sendNoti";
import { setVisible } from "store/loading/action";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
/**
 * Filename: AssignEnter/index.js
 * Description: 과제 등록 컴포넌트
 */

const AssignEnter = ({
  profile,
  history,
  type = "enter",
  match: {
    params: { taskId: mTaskId },
  },
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const dispatch = useDispatch();

  const taskNm = formatMessage({ id: "T0002" }); // 과제명
  const taskType = formatMessage({ id: "T0003" }); // 과제 유형
  const taskMgrId = formatMessage({ id: "T0006" }); // 업무 담당자
  const taskDevId = formatMessage({ id: "T0007" }); // 개발자
  const taskWorkId = formatMessage({ id: "T0008" }); // 업무 분류
  const onOkText = formatMessage({ id: "T0054" }); // 수정

  const [requireFields, setRequireFields] = useState({
    businessClass: {
      ref: useRef(null),
      message: taskWorkId,
      use: true,
    },
    assignName: {
      ref: useRef(null),
      message: taskNm,
      use: true,
    },
    assignCategory: {
      ref: useRef(null),
      message: taskType,
      use: true,
    },
    workManager: {
      ref: useRef(null),
      message: taskMgrId,
      use: true,
    },
    developers: {
      ref: useRef(null),
      message: taskDevId,
      use: type === "modify",
    },
    assignDescription: {
      ref: useRef(null),
      message: formatMessage({ id: "T0430" }), // 과제 설명
      use: true,
    },
    // cycle: {
    //   ref: useRef(null),
    //   message: `평균 업무 수행 횟수 선택`,
    //   use: true,
    // },
    // personCount:{
    //   ref: useRef(null),
    //   message: `유사 업무 구성원`,
    //   use: true,
    // },
    // runtime:{
    //   ref: useRef(null),
    //   message: `1회 평균 수행시간`,
    //   use: true,
    // },
    // cycleCount:{
    //   ref: useRef(null),
    //   message: `평균 업무 수행 횟수`,
    //   use: true,
    // }

    // ruleAcquaint: {
    //   ref: useRef(null),
    //   message: `${formatMessage({ id: 'T0444' })}`, // 내/외부 규정 준수 여부
    //   use: true,
    // }
  });

  const [basicDatas, setBasicDatas] = useState({
    businessClass: {},
    assignName: "",
    assignCategory: "",
    shareAssign: "",
    workManager: [],
    totalManager: profile,
    developers: [],
    assignDescription: "",
    processDesign: [],
  });
  const [workDatas, setWorkDatas] = useState({
    personCount: 0,
    runtime: 0,
    cycle: "daily",
    cycleCount: 0,
    ruleAcquaint: false,
  });
  const [systemDatas, setSystemDatas] = useState({
    dependencySystem: [],
    usingMenu: [],
    fileList: [],
  });
  const [fixedMain, setFixedMain] = useState(false);
  const [defaultFiles, setDefaultFiles] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [defaultStatusCd, setDefaultStatusCd] = useState("");
  const [visibleOrgan, setVisibleOrgan] = useState(false);
  const [organLabel, setOrganLabel] = useState("");
  const [organData, setOrganData] = useState([]);
  const [visibleSystem, setVisibleSystem] = useState(false);
  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleBusiness, setVisibleBusiness] = useState(false);

  const [taskData, setTaskData] = useState({});

  const [pdChecked, setPdChecked] = useState(false);

  const setLoading = async (cb) => {
    dispatch(setVisible(true));
    await cb();
    dispatch(setVisible(false));
  };

  const getMemberList = async (numbers) => {
    const arr = [];
    for (let i = 0; i < numbers.length; i++) {
      const number = numbers[i];
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${number}`
      );
      arr.push(result.data.mdFPAList[0]);
    }
    return arr;
  };
  const removeUploadedFile = (seq) => {
    setDeleteFiles(deleteFiles.concat(seq));
  };
  const onChangeBasicDatas = async (name, value) => {
    if (name === "assignCategory") {
      if (["DIY", "EDU"].includes(value)) {
        const result = await fetchAbsolute(
          "get",
          `/common/memberNoList?empNo=${profile.EMP_NO}`
        );
        const ownManager = [result.data.mdFPAList[0]];
        setBasicDatas({
          ...basicDatas,
          [name]: value,
          workManager: [...ownManager],
          developers: [...ownManager],
        });
      } else {
        setBasicDatas({
          ...basicDatas,
          [name]: value,
          workManager: [],
          developers: [],
        });
      }
    } else if (name === "processDesign") {
      const tmpArr = [];
      for (let i = 0; i < value.length; i++) {
        const result = await fetchAbsolute(
          "get",
          `/bpd/bpdInfoHF?bpdId=${value[i]}`
        );
        tmpArr.push(result.data);
      }
      setBasicDatas({
        ...basicDatas,
        [name]: value,
        processDesign: [...tmpArr],
      });
    } else {
      setBasicDatas({ ...basicDatas, [name]: value });
    }
  };
  const onChangeWorkDatas = (name, value) => {
    setWorkDatas({ ...workDatas, [name]: value });
  };
  const onChangeSystemDatas = (name, value) => {
    setSystemDatas({ ...systemDatas, [name]: value });
  };
  // Modal Open 이벤트
  const onClickOrgaOpen = (label) => {
    if (type === 'modify' && label === 'workManager') {
      setFixedMain(true);
    } else if (
      type !== "modify" ||
      (type === "modify" && defaultStatusCd === "T10") ||
      label === "developers"
    ) {
      setFixedMain(false);
    } else {
      setFixedMain(true);
    }
    setVisibleOrgan(true);
    setOrganLabel(label);
    setOrganData(basicDatas[label]);
  };
  const onClickSystemOpen = () => setVisibleSystem(true);
  const onClickMenuOpen = () => setVisibleMenu(true);
  // Modal 창들 Ok 이벤트
  const onOkOrgan = async (data) => {
    setVisibleOrgan(false);
    setBasicDatas({ ...basicDatas, [organLabel]: [...data] });
  };
  const onOkSystem = (data) => {
    setVisibleSystem(false);
    setSystemDatas({ ...systemDatas, dependencySystem: [...data] });
  };
  const onOkMenu = (data) => {
    setVisibleMenu(false);
    setSystemDatas({ ...systemDatas, usingMenu: [...data] });
  };
  const onOkBusiness = (data) => {
    setVisibleBusiness(false);
    setBasicDatas({
      ...basicDatas,
      businessClass: data.length > 0 ? data[0] : {},
    });
  };
  // Modal 창들 Cancel 시 발생 이벤트
  const onCancelSystem = () => setVisibleSystem(false);
  const onCancelOrgan = () => setVisibleOrgan(false);
  const onCancelMenu = () => setVisibleMenu(false);
  const onCancelBusiness = () => setVisibleBusiness(false);
  const onClickBusinessOpen = () => setVisibleBusiness(true);

  // 목록 삭제 이벤트
  const onClickDeleteBasic = (name, index) => {
    const tmpArr = [...basicDatas[name]];
    tmpArr.splice(index, 1);
    setBasicDatas({ ...basicDatas, [name]: tmpArr });
  };
  const onClickDeleteSystem = (name, index) => {
    const tmpArr = [...systemDatas[name]];
    tmpArr.splice(index, 1);
    setSystemDatas({ ...systemDatas, [name]: tmpArr });
  };
  const onClickCancelButton = () => {
    if (type === "enter") {
      const path = "/assign/list";
      history.push(path);
    } else {
      history.goBack();
    }
  };
  // Request Parameter 생성 함수
  const setRequestParameter = (data) => {
    const parameter = {
      modId: profile.EMP_NO,
      regId: profile.EMP_NO,
      taskNm: data.assignName,
      taskDesc: data.assignDescription,
      workId: data.businessClass.cd,
      statusCd: "T10",
      shareTaskId: data.shareAssign,
      detailStatusCd:
        data.assignCategory === "EDU" || data.assignCategory === "DIY"
          ? "T12"
          : "T11",
      taskType: data.assignCategory,
      memberCnt: data.personCount,
      avgExeTime: data.runtime,
      avgExeCycle: data.cycle,
      avgExeCnt: data.cycleCount,
      ruleKeepYn: data.ruleAcquaint ? "Y" : "N",
      taskMemberList: [
        ...data.workManager.map((v, index) => ({
          mgrDevGbn: "M",
          empNo: v.empNum,
          deputyHeadYn: index === 0 ? "N" : "Y",
        })),
        ...data.developers.map((v, index) => ({
          mgrDevGbn: "D",
          empNo: v.empNum,
          deputyHeadYn: index === 0 ? "N" : "Y",
        })),
      ],
      taskUseSystemList: [
        ...data.dependencySystem.map((system) => ({
          systemId: system.systemCd,
          systemNm: system.systemName,
          inOutCd: system.systemKind,
          ...(system.systemKind === "O" // 외부 시스템 구분
            && { outSystemLink: system.appLink }
          ),
          taskUseMenuList: data.usingMenu
            .filter((v) => v.systemCd === system.systemCd)
            .map((v) => ({
              ...(system.systemKind === "I" // 내부 시스템 구분
                ? v.menuId
                  ? {
                    mseq: v.menuId,
                    level1: v.level1,
                    level2: v.level2,
                    level3: v.level3,
                    level4: v.level4,
                    level5: v.level5,
                  }
                  : {
                    level1: v.level1,
                    level2: v.level2,
                    level3: v.level3,
                    level4: v.level4,
                    level5: v.level5,
                  }
                : {
                  level1: v.level1,
                  level2: v.level2,
                  level3: v.level3,
                  level4: v.level4,
                  level5: v.level5,
                }),
            })),
        })),
      ],
      bpdList: [
        ...data.processDesign.map((v, index) => ({
          bpdId: v.bpdId,
          bpdNm: v.bpdNm,
          bpdDesc: v.bpdDesc,
          workId: v.workId,
          bpdOrgCd: v.bpdOrgCd,
          fileId: v.fileId,
          useYn: v.useYn,
          processCd: v.processCd,
        })),
      ],
    };
    return parameter;
  };

  const onClickSubmitButton = async () => {
    const fetchData = async () => {
      const mergeData = { ...basicDatas, ...workDatas, ...systemDatas };

      if (mergeData.assignDescription.length < 100) {
        return alert(formatMessage({ id: "T1156" })); // 과제 설명은 100자 이상 입력해주세요
      }

      for (const key in requireFields) {
        const field = requireFields[key];
        if (!field.use) continue;
        if (
          (key !== "ruleAcquaint" && Object.keys(mergeData[key]).length <= 0) ||
          (key === "ruleAcquaint" && !mergeData[key])
        ) {
          field.ref.current.focus();
          return alert(
            fmMessage({ id: "T0128", values: { field: field.message } })
          );
          //`${field.message} 항목은 필수 입력입니다.`);
        }
      }

      const parameter = setRequestParameter(mergeData);

      // 과제 등록 API 실행
      const assignResult = await fetchAbsolute("post", "/task/oracle/addTask", {
        data: { ...parameter },
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (assignResult.error) {
        return alert(formatMessage({ id: "T0300" }));
        //`과제 등록을 실패하였습니다.`);
      }

      // 과제 첨부파일 등록
      const { taskId, taskType } = assignResult.data;
      const formData = new FormData();
      mergeData.fileList.forEach((file) => formData.append("files", file));

      formData.append("empNo", profile.EMP_NO);
      formData.append("taskId", taskId);
      formData.append("type", "001");

      const fResult = await fetchAbsolute(
        "post",
        "/file/uploadTaskMultipleFiles",
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (!fResult.error) {
        if (taskType === "DIY") {
          sendNoti("TP01", taskId);
        } else if (taskType === "COE") {
          sendNoti("TP02", taskId);
          sendNoti("TP03", taskId);
        }
        alert(formatMessage({ id: "T0301" }));
        //'과제 등록을 완료하였습니다.')
        const path = "/assign/list";
        history.push(path);
        return;
      } else {
        // 파일 등록 실패 시 등록한 과제 삭제
        await fetchAbsolute("delete", `/task/oracle/removeTask/${taskId}`);
        return alert(formatMessage({ id: "T0300" }));
        //`과제 등록을 실패하였습니다.`);
      }
    };
    setLoading(fetchData);
  };

  const onClickModifyButton = async () => {
    const fetchData = async () => {
      const mergeData = { ...basicDatas, ...workDatas, ...systemDatas };

      if (mergeData.assignDescription.length < 100) {
        return alert(formatMessage({ id: "T1156" })); // 과제 설명은 100자 이상 입력해주세요
      }

      for (const key in requireFields) {
        const field = requireFields[key];
        if (!field.use) continue;
        if (
          (key !== "ruleAcquaint" && Object.keys(mergeData[key]).length <= 0) ||
          (key === "ruleAcquaint" && !mergeData[key])
        ) {
          field.ref.current.focus();
          return alert(
            fmMessage({ id: "T0128", values: { field: field.message } })
          );
          //`${field.message} 항목은 필수 입력입니다.`);
        }
      }

      const parameter = setRequestParameter(mergeData);

      delete parameter.regId;
      delete parameter.taskType;

      if (type === "modify") {
        delete parameter.statusCd;
        delete parameter.detailStatusCd;
      } else if (type === "recall") {
        parameter.modifyType = "R"; // 재요청
        parameter.statusCd = "T10";
        parameter.detailStatusCd = "T11";
      }

      const result = await fetchAbsolute(
        "put",
        `/task/oracle/modifyTask/${mTaskId}`,
        {
          data: { ...parameter },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.error) {
        // 과제(T1157), 수정(T0054), 재요청(T0083), 을 실패하였습니다.(T1161)
        return alert(
          `${formatMessage({ id: "T1157" })} ${type === "modify" ? `${onOkText}` : ""
          }${type === "recall" ? `${formatMessage({ id: "T0083" })}` : ""
          } ${formatMessage({ id: "T1161" })}`
        );
      }
      for (let i = 0; i < deleteFiles.length; i++) {
        const seq = deleteFiles[i];
        await fetchAbsolute("delete", `/file/deleteTaskFile/${seq}`);
      }
      const formData = new FormData();
      mergeData.fileList
        .filter((file) => !file.uploaded)
        .forEach((file) => formData.append("files", file));

      formData.append("empNo", profile.EMP_NO);
      formData.append("taskId", mTaskId);
      formData.append("type", "001");

      const fResult = await fetchAbsolute(
        "post",
        "/file/uploadTaskMultipleFiles",
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (fResult.error) {
        // 과제(T1157), 수정(T0054), 재요청(T0083), 을 실패하였습니다.(T1161)
        return alert(
          `${formatMessage({ id: "T1157" })} ${type === "modify" ? `${onOkText}` : ""
          }${type === "recall" ? `${formatMessage({ id: "T0083" })}` : ""
          }${formatMessage({ id: "T1161" })}`
        );
      }

      const originManager = []
        .concat(
          taskData.taskMemberList.find(
            (v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N"
          ).empNo
        )
        .concat(
          ...taskData.taskMemberList
            .filter((v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "Y")
            .map((v) => v.empNo)
            .sort()
        );

      const newManager = []
        .concat(
          parameter.taskMemberList.find(
            (v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N"
          ).empNo
        )
        .concat(
          ...parameter.taskMemberList
            .filter((v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "Y")
            .map((v) => v.empNo)
            .sort()
        );

      const originDev = [];
      if (
        taskData.taskMemberList.filter((v) => v.mgrDevGbn === "D")?.length > 0
      ) {
        originDev
          .concat(
            taskData.taskMemberList.find(
              (v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N"
            ).empNo
          )
          .concat(
            ...taskData.taskMemberList
              .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
              .map((v) => v.empNo)
              .sort()
          );
      }

      const newDev = [];
      if (
        parameter.taskMemberList.find((v) => v.mgrDevGbn === "D")?.length > 0
      ) {
        newDev
          .concat(
            parameter.taskMemberList.find(
              (v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N"
            ).empNo
          )
          .concat(
            ...parameter.taskMemberList
              .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
              .map((v) => v.empNo)
              .sort()
          );
      }

      if (taskData.taskType === "COE" && type === "recall") {
        sendNoti("TP03", mTaskId);
      }
      if (JSON.stringify(originManager) !== JSON.stringify(newManager)) {
        sendNoti("TP23", mTaskId);
      }
      if (JSON.stringify(originDev) !== JSON.stringify(newDev)) {
        sendNoti("TP24", mTaskId);
      }

      // 과제(T1157), 수정(T0054), 재요청(T0083), {field}을 완료하였습니다.(T1161)
      alert(
        `${formatMessage({ id: "T1157" })} ${type === "modify" ? `${onOkText}` : ""
        }${type === "recall" ? `${formatMessage({ id: "T0083" })}` : ""
        }${formatMessage({ id: "T1224" })}`
      ); // 재요청
      history.push(`/assign/lookup/${mTaskId}`);
    };
    setLoading(fetchData);
  };
  // 수정 / 재요청 시 초기 데이터 세팅
  useEffect(() => {
    if (!mTaskId) return;

    const fetchTaskInfo = async () => {
      const result = await fetchAbsolute(
        "get",
        `/task/oracle/getTask/${mTaskId}`
      );
      const { data } = result;

      if (result.redirectURL) {
        return history.replace(result.redirectURL);
      }
      if (Object.keys(data).length === 0) {
        return history.push("/error/notfound");
      }
      const workResult = await fetchAbsolute(
        "get",
        `/common/workClassification?cd=${data.workId}`
      );

      const workManagerMain = await getMemberList(
        data.taskMemberList
          .filter((v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N")
          .map((v) => v.empNo)
      );
      const workManagerSub = await getMemberList(
        data.taskMemberList
          .filter((v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "Y")
          .map((v) => v.empNo)
      );
      const workManager = workManagerMain.concat(...workManagerSub);
      const developersMain = await getMemberList(
        data.taskMemberList
          .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N")
          .map((v) => v.empNo)
      );
      const developersSub = await getMemberList(
        data.taskMemberList
          .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
          .map((v) => v.empNo)
      );
      const developers = developersMain.concat(...developersSub);

      const files = await fetchAbsolute(
        "get",
        `/file/listTaskFile/${data.taskId}`
      );
      const fileList = files.data
        .filter((file) => file.type.trim() === "001")
        .map((file) => ({
          name: file.fileName,
          uploaded: true,
          seq: file.seq,
          uid: file.seq,
        }));

      setTaskData({ ...data });

      setRequireFields({
        ...requireFields,
        developers: {
          ...requireFields.developers,
          use: type === "modify" && data.statusCd !== "T10",
        },
      });

      setDefaultStatusCd(data.statusCd);
      setDefaultFiles([...fileList]);
      setBasicDatas({
        businessClass: workResult.data,
        assignName: data.taskNm,
        assignCategory: data.taskType,
        shareAssign: "",
        workManager: workManager,
        totalManager: profile,
        developers: developers,
        assignDescription: data.taskDesc,
        processDesign: data.bpdList,
      });
      setWorkDatas({
        personCount: data.memberCnt,
        runtime: data.avgExeTime,
        cycle: data.avgExeCycle,
        cycleCount: data.avgExeCnt,
        ruleAcquaint: data.ruleKeepYn === "Y",
      });

      setSystemDatas({
        dependencySystem: data.taskUseSystemList.map((v) => ({
          systemCd: v.systemId,
          systemName: v.systemNm,
          systemKind: v.inOutCd,
          ...(v.inOutCd === "O" ? { appLink: v.outSystemLink } : {}),
        })),
        // 2레벨로 되어 있는 배열을 평탄하게 만들어주는 작업
        usingMenu: [].concat(
          ...data.taskUseSystemList.map((system) =>
            system.taskUseMenuList.map((menu) => ({
              systemCd: system.systemId,
              systemName: system.systemNm,
              menuNm:
                menu[
                Object.keys(menu)
                  .filter((v) => v.includes("level"))
                  .sort()
                  .reverse()[0]
                ],
              ...menu,
              menuId: menu.mseq,
            }))
          )
        ),
        fileList: fileList,
      });
      dispatch(
        loadTaskPermission({
          taskId: data.taskId,
          taskType: data.taskType,
        })
      );
    };
    setLoading(fetchTaskInfo);
  }, [mTaskId]);

  useEffect(() => {
    const appIds = systemDatas.dependencySystem.map((v) => v.systemCd);
    setSystemDatas({
      ...systemDatas,
      usingMenu: systemDatas.usingMenu.filter((v) =>
        appIds.includes(v.systemCd)
      ),
    });
  }, [systemDatas.dependencySystem]);

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchAbsolute(
        "get",
        `/task/oracle/getTask/${basicDatas.shareAssign}`
      );

      const {
        memberCnt: personCount,
        avgExeTime: runtime,
        avgExeCycle: cycle,
        avgExeCnt: cycleCount,
      } = result.data;
      const dependencySystem = result.data.taskUseSystemList.map((v) => ({
        systemCd: v.systemId,
        systemName: v.systemNm,
        systemKind: v.inOutCd,
        ...(v.inOutCd === "O" ? { appLink: v.outSystemLink } : {}),
      }));
      const usingMenu = [].concat(
        ...result.data.taskUseSystemList.map((system) =>
          system.taskUseMenuList.map((menu) => ({
            systemCd: system.systemId,
            systemName: system.systemNm,
            menuNm:
              menu[
              Object.keys(menu)
                .filter((v) => v.includes("level"))
                .sort()
                .reverse()[0]
              ],
            ...menu,
            menuId: menu.mseq,
          }))
        )
      );
      setSystemDatas({
        ...systemDatas,
        dependencySystem,
        usingMenu,
      });
      setWorkDatas({ ...workDatas, personCount, runtime, cycle, cycleCount });
    };
    if (basicDatas.shareAssign !== "") {
      fetchData();
    }
  }, [basicDatas.shareAssign]);

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      headerColor="none"
      {...{
        ...(type === "enter"
          ? {}
          : {
            menuTitle:
              type === "modify"
                ? `${formatMessage({ id: "T1158" })}` // 과제 수정
                : type === "recall" && `${formatMessage({ id: "T1159" })}`, // 과제 재요청
            depthList: [
              `${formatMessage({ id: "T1160" })}`, // 과제 관리
              type === "modify"
                ? `${formatMessage({ id: "T1158" })}` // 과제 수정
                : type === "recall" && `${formatMessage({ id: "T1159" })}`, // 과제 재요청
            ],
          }),
      }}
    >
      <Organization
        visible={visibleOrgan}
        title={
          {
            workManager: formatMessage({ id: "T1141" }), // 업무 담당자 등록
            developers: formatMessage({ id: "T0510" }), // 개발자 등록
          }[organLabel]
        }
        authCheck={organLabel === "developers"}
        onOk={onOkOrgan}
        onCancel={onCancelOrgan}
        defaultData={organData}
        msColumn={true}
        fixedMain={fixedMain}
      />
      {/* 업무 수행 시 사용 시스템 */}
      <SystemSelector
        visible={visibleSystem}
        title={formatMessage({ id: "T1154" })} // 업무 수행시 사용 시스템 등록
        onOk={onOkSystem}
        onCancel={onCancelSystem}
        defaultData={systemDatas.dependencySystem}
      />
      <UsingMenu
        visible={visibleMenu}
        title={formatMessage({ id: "T1155" })} // 업무 수행시 사용 메뉴 등록
        onOk={onOkMenu}
        onCancel={onCancelMenu}
        dependencySystem={systemDatas.dependencySystem}
        defaultData={systemDatas.usingMenu}
      />
      <BusinessClass
        visible={visibleBusiness}
        title={formatMessage({ id: "T1119" })} // 업무 분류 검색
        onOk={onOkBusiness}
        onCancel={onCancelBusiness}
        defaultData={
          Object.keys(basicDatas.businessClass).length > 0
            ? [basicDatas.businessClass]
            : []
        }
        max={1}
      />
      <Container
        style={{ display: "flex", flexDirection: "column", gap: "30px 0" }}
      >
        <AssignEnterBasicBox
          profile={profile}
          insertDatas={basicDatas}
          onChangeDatas={onChangeBasicDatas}
          onClickOrgaOpen={onClickOrgaOpen}
          onClickBusinessOpen={onClickBusinessOpen}
          onClickDelete={onClickDeleteBasic}
          requireFields={requireFields}
          type={type}
          defaultStatusCd={defaultStatusCd}
        />
        <AssignEnterWorkBox
          insertDatas={workDatas}
          onChangeDatas={onChangeWorkDatas}
          requireFields={requireFields}
        />
        <AssignEnterSystemBox
          insertDatas={systemDatas}
          defaultFiles={defaultFiles}
          removeUploadedFile={removeUploadedFile}
          onChangeDatas={onChangeSystemDatas}
          onClickSystemOpen={onClickSystemOpen}
          onClickMenuOpen={onClickMenuOpen}
          onClickDelete={onClickDeleteSystem}
        />
        <div className="flex justify-end">
          {type === "enter" ? (
            <Button
              type="primary"
              style={{ width: 150, height: 40 }}
              onClick={onClickSubmitButton}
            >
              <FormattedMessage id="T0050" />{/* 등록 */}
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ width: 150, height: 40 }}
              onClick={onClickModifyButton}
            >
              {type === "modify" && `${onOkText}`}
              {type === "recall" && `${formatMessage({ id: "T0083" })}`} {/* 재요청 */}
            </Button>
          )}
          <Button
            style={{ width: 150, height: 40, marginLeft: 30 }}
            onClick={onClickCancelButton}
          >
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>
        </div>
      </Container>
    </PageTemplate>
  );
};

export default AssignEnter;
