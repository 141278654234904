import { SET_PATH_NAME, LOAD_MENU_LIST, SET_SELECTED_ID } from './action';

/**
 * Breadcrumb 구현을 위한 reducer
 * 
 */
const menuListInitialState = {
    path: '/',
    menuList: [],
    menuFlatList: [],
    displayList: [],
    selectedMenuId : ''
};

const menuList = (state = menuListInitialState, action) => {
    switch (action.type) {
        case SET_PATH_NAME:
            return {
                ...state,
                path: action.path
            };
        case SET_SELECTED_ID:
            return {
                ...state,
                selectedMenuId: action.data
            };
        case LOAD_MENU_LIST:
            const { menuData, displayData } = action;
            const flatData = [];

            menuData.forEach(v => {
                flatData.push({ ...v });
                v.subMenu.forEach(s => {
                    flatData.push({ ...s });
                });
            });
            return {
                ...state,
                displayList: [...displayData],
                menuList: menuData,
                menuFlatList: flatData.reverse()
            };
        default:
            return state;

    };
};

export default menuList;
