import React, { useState } from "react";
import { Tabs } from "components";
import { useIntl } from "react-intl";
import PointTab from "./Tab/PointTab.js";
import AcademyTab from "./Tab/AcademyTab.js";

const { TabPane } = Tabs;

const MemberEditBottom = ({ empNum }) => {
  const { formatMessage } = useIntl();

  const [tabsValue, setTabsValue] = useState("point");

  const onChangeTab = (tabValue) => {
    setTabsValue(tabValue);
  };

  return (
    <div className="member_edit__bottom">
      <Tabs
        defaultActiveKey={tabsValue}
        activeKey={tabsValue}
        onChange={onChangeTab}
      >
        <TabPane tab={formatMessage({ id: "T0607" })} key="point">{/* 포인트 조회 */}
          <PointTab empNum={empNum} />
        </TabPane>
        <TabPane tab={formatMessage({ id: "T1193" })} key="academy">{/* Academy History */}
          <AcademyTab empNum={empNum} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default MemberEditBottom;
