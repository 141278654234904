import React, { useState, useEffect } from "react";
import { Table, Image } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl } from "react-intl";

/**
 * Academy > Item Shop > 상품 구매 이력 탭
 */

const initialPageInfo = {
  sort: "regTimestamp",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 5, // 사진이 있어서 부피 차지를 많이해서 5개로 
  total: 0
};

const JediItemPurchaseHistory = ({ profile }) => {
  const { formatMessage } = useIntl();

  const [dataSource, setDataSource] = useState([]);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [tableLoading, setTableLoading] = useState(false);

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'regTimestamp';
    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    fetchItemPurchaseHistory(searchObj);
  };

  const fetchItemPurchaseHistory = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    setTableLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/academy/purchaseHistoryByTime?empNum=${profile.EMP_NO}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setTableLoading(false);
    if (result.data.length === 0) {
      return;
    }
    setDataSource([...result.data]);
  };

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: "T0823" }), // 회차명
      dataIndex: "round",
      sorter: (a, b) => a.round,
      width: "15%",
      align: "center",
    },
    {
      key: 2,
      title: formatMessage({ id: 'T0831' }), // 상품이미지
      dataIndex: "link",
      align: "center",
      width: "15%",
      render: (link, index) => {
        return (
          <div key={index}>
            <Image
              width="70px"
              alt=""
              src={link}
              className="image"
              value={link}
            />
          </div>
        );
      },
    },
    {
      key: 3,
      title: formatMessage({ id: 'T0830' }), // 상품명
      dataIndex: "product",
      sorter: (a, b) => a.product,
      align: "center",
      width: "15%",
    },
    {
      key: 4,
      title: formatMessage({ id: "T0609" }), // 포인트
      dataIndex: "price", // antd v3
      sorter: (a, b) => a.price,
      align: "center",
      width: "15%",
      render: (value, index) => {
        return <div key={index}>{value.toLocaleString()}pt</div>;
      },
    },

    {
      key: 5,
      title: formatMessage({ id: "T1072" }), // 주문 일자
      dataIndex: "regTimestamp",
      align: "center",
      sorter: (a, b) => a.regTimestamp,
      width: "15%",
      render: (value, index) => {
        return moment(value).format("YYYY-MM-DD");
      },
    },
  ];

  useEffect(() => {
    fetchItemPurchaseHistory();
  }, []);

  return (
    <div className="closed_section">
      <Table
        className="itemshop__puchase_history"
        columns={columns}
        dataSource={dataSource}
        pagination={true}
        showSorterTooltip={true}
        rowKey={(e) => e.purchaseSeq}
        onChange={onChangeTableColumns}
        loading={tableLoading}
        pageOptions={{
          total: +pageInfo.total,
          current: +pageInfo.currentPage,
          pageSize: +pageInfo.rowPerPage,
        }}
      />
    </div>
  );
};

export default JediItemPurchaseHistory;