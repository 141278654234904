const { default: NameTag } = require("../NameTag");

const NameTagGroup = (members, label, onClickDelete, isDelete = true) => {
    return members.map((member, index) => {
        return (
            <NameTag
                key={index}
                text={`${member.empNam}(${member.empNum}) | ${member.orgNam}`}
                isDelete={!(!isDelete && member.isMain)}
                index={index}
                label={label}
                onDelete={onClickDelete}
                isMain={member.isMain}
            />
        )
    });
}

export default NameTagGroup;