// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language_popover .ant-radio-inner {
    width: 20px;
    height: 20px;
    border: 1px solid #b0b1b5;
    background:#fff;
}
.language_popover .ant-radio-checked .ant-radio-inner {
    border-color: #ff6600;
}
.language_popover .ant-radio-inner::after {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) !important;
    width: 6px;
    height: 6px;
    background: #ff6600;
    margin:0;
}
.language_popover .ant-radio-wrapper {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
}

.language_popover {
    margin: -12px -16px;
}

.language_popover .language_item {
    padding: 6px 0;
    padding-left: 5px;;
    display: flex;
    align-items: center;
    gap: 15px;
    border-bottom: 1px solid #D0D1D6;
    cursor: pointer;
}
.language_popover .language_item:hover {
    background: #E5E5E5;
}
.language_popover .language_item:nth-last-child() {
    border: 0;
}

.language_popover .ant-radio-group {
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/HomeComponent/PersonalArea/radio.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,qBAAqB;AACzB;AACA;IACI,QAAQ;IACR,SAAS;IACT,uDAAuD;IACvD,UAAU;IACV,WAAW;IACX,mBAAmB;IACnB,QAAQ;AACZ;AACA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gCAAgC;IAChC,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,SAAS;AACb;;AAEA;IACI,WAAW;AACf","sourcesContent":[".language_popover .ant-radio-inner {\n    width: 20px;\n    height: 20px;\n    border: 1px solid #b0b1b5;\n    background:#fff;\n}\n.language_popover .ant-radio-checked .ant-radio-inner {\n    border-color: #ff6600;\n}\n.language_popover .ant-radio-inner::after {\n    top: 50%;\n    left: 50%;\n    transform: translateX(-50%) translateY(-50%) !important;\n    width: 6px;\n    height: 6px;\n    background: #ff6600;\n    margin:0;\n}\n.language_popover .ant-radio-wrapper {\n    display: inline-flex;\n    align-items: center;\n    font-size: 12px;\n}\n\n.language_popover {\n    margin: -12px -16px;\n}\n\n.language_popover .language_item {\n    padding: 6px 0;\n    padding-left: 5px;;\n    display: flex;\n    align-items: center;\n    gap: 15px;\n    border-bottom: 1px solid #D0D1D6;\n    cursor: pointer;\n}\n.language_popover .language_item:hover {\n    background: #E5E5E5;\n}\n.language_popover .language_item:nth-last-child() {\n    border: 0;\n}\n\n.language_popover .ant-radio-group {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
