import { getCookie } from 'utils/cookie';

const fileDownload = async (file, fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);


    let headers = new Headers();
    headers.append('Authorization', getCookie('auth').accessToken);

    await fetch(file, { headers })
        .then(response => response.blob())
        .then(blobby => {
            let objectUrl = window.URL.createObjectURL(blobby);

            anchor.href = objectUrl;
            anchor.download = fileName;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        });
};

export default fileDownload;