import React, { useState, useEffect } from "react";
import { Text, FormTable, Input, Button, Modal } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import { Input as AntInput, Tree, Select, Checkbox } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import { setMenuList } from "store/menuList/action";
import { useDispatch } from "react-redux";

/**
 * Filename: MenuManagement/MenuDetails.js
 * Description: 관리자 -> 메뉴 관리 오른쪽 input구조
 */


const TreeNode = Tree.TreeNode;
const { Option } = Select;
const { TextArea } = AntInput;

const MenuDetail = ({
  keyVal,
  flatAllClassList,
  parentKeyVal,
  childCheck,
  renderData,
  cancelKey,
  language
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  //pos로 length 구분 하여 위치 확인 가능
  const [parentDetailData, setParentDetailData] = useState([]);
  const [PoolVisible, setPoolVisible] = useState(false);
  const [useYn, setUseYn] = useState("N");
  const [linkYn, setLinkYn] = useState(null);
  const [userGrade, setUserGrade] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [userGradeList, setUsetGradeList] = useState([]);
  const [originCheckedDatasList, setOriginCheckedDatasList] = useState([]);
  const [expand, setExpand] = useState([]);
  const [authGroupList, setAuthGroupList] = useState([]);
  const [checkInsertData, setCheckInserData] = useState([]);
  const [checkInsertTreeData, setCheckInsertTreeData] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [treeCheck, setTreeCheck] = useState([]);
  const [checkedDatasList, setCheckedDatasList] = useState([]);
  const [changeInput, setChangeInput] = useState({
    menuDescription: "",
    menuNm: "",
    menuUrl: "",
    menuOrder: "",
    displayId: '',
    displayYn: 'Y'
  });
  const [isKorean, setIsKorean] = useState(false);
  const onExpandTree = (keys) => {
    setExpand([...keys]);
  };

  const saveData = () => {
    //신규 하위
    if (parentKeyVal !== undefined) {
      return {
        menuId: parentKeyVal,
        menuDescription: parentDetailData[0].menuDescription,
        useYn: parentDetailData[0].useYn,
        menuLevel: parentDetailData[0].menuLevel,
        menuOrder: parentDetailData[0].menuOrder,
        menuUrl: parentDetailData[0].menuUrl,
        menuNm: parentDetailData[0].menuNm,
        menuType: parentDetailData[0].menuType,
        parentId: parentDetailData[0].parentId,
        displayId: parentDetailData[0].displayId || '',
        displayYn: parentDetailData[0].displayYn || 'Y',
        locale: language,
        subMenu: [
          {
            menuDescription: changeInput.menuDescription,
            menuNm: changeInput.menuNm,
            menuUrl: changeInput.menuUrl,
            useYn: useYn,
            menuType: linkYn,
            menuOrder: changeInput.menuOrder,
            displayId: changeInput.displayId,
            displayYn: changeInput.displayYn
          },
        ],
      };
    } else {
      //수정
      if (childCheck === 2) {
        //상위 노드 수정
        if (detailData[0].parentId === null) {
          return {
            menuId: keyVal,
            menuDescription: changeInput.menuDescription,
            menuNm: changeInput.menuNm,
            menuUrl: changeInput.menuUrl,
            locale: language,
            useYn: useYn,
            menuType: linkYn,
            menuOrder: changeInput.menuOrder,
            displayId: changeInput.displayId,
            displayYn: changeInput.displayYn
          };
        }
        //하위 노드 수정
        else {
          const parentData = flatAllClassList.filter(
            (e) => e.menuId === detailData[0].parentId
          );
          return {
            menuId: parentData[0].menuId,
            locale: language,
            menuDescription: parentData[0].menuId,
            menuNm: parentData[0].menuNm,
            menuOrder: parentData[0].menuOrder,
            menuType: parentData[0].menuType,
            menuUrl: parentData[0].menuUrl,
            useYn: parentData[0].useYn,
            displayId: parentData[0].displayId || '',
            displayYn: parentData[0].displayYn || 'Y',
            subMenu: [
              {
                menuId: detailData[0].menuId,
                menuDescription: changeInput.menuDescription,
                menuNm: changeInput.menuNm,
                menuUrl: changeInput.menuUrl,
                useYn: useYn,
                menuType: linkYn,
                menuOrder: changeInput.menuOrder,
                displayId: changeInput.displayId,
                displayYn: changeInput.displayYn
              },
            ],
          };
        }
        //신규 상위
      } else {
        return {
          menuDescription: changeInput.menuDescription,
          locale: language,
          menuNm: changeInput.menuNm,
          menuUrl: changeInput.menuUrl,
          useYn: useYn,
          menuType: linkYn,
          menuOrder: changeInput.menuOrder,
          displayId: changeInput.displayId,
          displayYn: changeInput.displayYn
        };
      }
    }
  };

  const handleSave = async (e) => {
    //메뉴 접근 권한 사용자 delete
    //수정시에만 접근 권한 추가 삭제 수정
    if (childCheck > 1) {
      const deleteAuthGroup = userGrade.map((e) => ({
        authCd: e.cd,
        menuId: keyVal,
      }));
      const delResult = await fetchAbsolute(
        "post",
        `/admin/menu/deleteAuthGroup`,
        {
          data: deleteAuthGroup,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (delResult.error) {
        return alert(formatMessage({ id: 'T0247' })); // 삭제에 실패하였습니다.
      }

      //메뉴 접근 권한 사용자 insert
      const concatAllData = checkInsertData.concat(checkInsertTreeData);
      if (concatAllData.length > 0) {
        const insResult = await fetchAbsolute(
          "post",
          "/admin/menu/saveAuthGroup",
          {
            data: concatAllData,
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (insResult.error) {
          if (insResult.data.message === "data is empty") {
            return;
          }
          return alert(formatMessage({ id: 'T0283' }));
          //"수정 실패했습니다.");
        }
      }
    }
    let fetchFlag = true;
    try {
      const saveList = await fetchAbsolute("post", `/admin/menu/saveMenu`, {
        data: saveData(),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (saveList.status === 200 || saveList.status === 201) {
        dispatch(setMenuList());
        alert(formatMessage({ id: 'T0234' }));
        //저장되었습니다.
      } else {
        throw new Error(saveList.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: 'T0235' }));
        //저장에 실패하였습니다.
      }
    }

    const fetchMenuList = async () => {
      const result = await fetchAbsolute("get", `/admin/menu/getAllMenuList?slocale=${language}`);
      onSendData(result.data, -1);
    };
    fetchMenuList();
  };

  const onSendData = (allClassList, key) => {
    renderData(allClassList, key);
  };
  const onCancel = () => {
    cancelKey(-1);
  };
  const onCancelDelete = () => setPoolVisible(false);

  const onOkDelete = async () => {
    let fetchFlag = true;
    try {
      const deleteKey = await fetchAbsolute(
        "get",
        `/admin/menu/deleteMenu?menuId=${keyVal}`,
        {}
      );
      if (deleteKey.status === 200 || deleteKey.status === 201) {
        alert(formatMessage({ id: 'T0284' }));
        //"삭제 되었습니다.");
      } else {
        throw new Error(deleteKey.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: 'T0247' }));
        //삭제에 실패하였습니다.
      }
    }
    setPoolVisible(false);

    //메뉴 삭제할 때 authgroup 삭제
    const deleteAuthGroup = userGrade.map((e) => ({
      authCd: e.cd,
      menuId: keyVal,
    }));

    const delResult = await fetchAbsolute(
      "post",
      `/admin/menu/deleteAuthGroup`,
      {
        data: deleteAuthGroup,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (delResult.error) {
      return alert(formatMessage({ id: 'T0282' }));
      //"삭제 실패했습니다.");
    }

    const fetchMenuList = async () => {
      const result = await fetchAbsolute("get", `/admin/menu/getAllMenuList`);
      onSendData(result.data);
    };
    fetchMenuList();

  };
  const handleDelete = (e) => { onCancel(); };

  //menuId 만 array return
  const checkFilter = (check) => {
    return check
      ?.filter((e) => e.authCd.includes("UA") === true)
      .map((e, index) => e.authCd);
  };
  const checkExFilter = (check) => {
    return check
      ?.filter((e) => e.authCd.includes("EX") === true)
      .map((e, index) => e.authCd);
  };
  const onChangeCheck = (e) => {
    setCheckedDatasList(e);
  };
  const onChangeAllCheck = (e) => {
    const UAList = userGrade.filter((e) => e.attrCd1 === "LV").map(e => e.cd);

    if (!e.target.checked) {
      setCheckedDatasList([]);
      setCheckAll(false);
    }
    else {
      setCheckedDatasList([...UAList]);
      setCheckAll(true);
    }

  };
  const onChangeLinkYn = (e) => {
    setLinkYn(e);
  };

  const onChangeUseYn = (e) => {
    setUseYn(e);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setChangeInput({
      ...changeInput,
      [name]: value,
    });
  };

  const onTreeCheck = (checkedKeysValue) => {
    // console.log(checkedKeysValue)
    setTreeCheck(checkedKeysValue);
  };

  useEffect(() => {
    setIsKorean(language === 'ko');
  }, [language]);

  useEffect(() => {
    //신규 추가
    if (parentKeyVal !== undefined) {
      setChangeInput({
        menuDescription: "",
        menuNm: "",
        menuUrl: "",
        menuOrder: "",
        displayId: '',
        displayYn: 'Y'
      });
      setUseYn("N");
      setLinkYn(null);
      const detailData = flatAllClassList.filter((e) => e.menuId === +parentKeyVal);
      setParentDetailData(detailData);
    }
    //수정데이터,삭제데이터
    else {
      const detailData = flatAllClassList.filter(
        (e) => e.menuId === Number(keyVal)
      );

      if (detailData.length === 0) {
        return;
      }

      setChangeInput({
        menuDescription: detailData[0].menuDescription,
        menuNm: detailData[0].menuNm,
        menuUrl: detailData[0].menuUrl,
        menuOrder: detailData[0].menuOrder,
        displayId: detailData[0].displayId || '',
        displayYn: detailData[0].displayYn || 'Y'
      });
      setUseYn(detailData[0].useYn);
      setLinkYn(detailData[0].menuType);
      setDetailData(detailData);
    }
    //공통코드 테이블에서 category id = authgroup 가져와서 전체 리스트 데이터 뿌려줌
    const fetchAuthGroup = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/AUTH_GROUP?sortVal=asc`
      );
      setUserGrade([...result.data]);
    };
    //체크된 항목 매핑 되어있는 전체 리스트
    const fetchGroupList = async () => {
      const result = await fetchAbsolute("get", `/admin/menu/getAuthGroupList`);
      setUsetGradeList([...result.data]);

      //menuId로 체크된 항목 필터링
      let filterChecked = result.data.filter((e) => e.menuId === keyVal);

      setTreeCheck(checkExFilter(filterChecked));
      setOriginCheckedDatasList(checkFilter(filterChecked));
      setCheckedDatasList(checkFilter(filterChecked));
    };
    //권한 그룹 소속된 사원 번호 가져오는 api
    const fetchAuthEmpList = async () => {
      const result = await fetchAbsolute("get", `/admin/menu/getAuthEmpList`);
      setAuthGroupList([...result.data]);
    };

    fetchAuthEmpList();
    fetchAuthGroup();
    fetchGroupList();

    setCheckAll(false);
  }, [keyVal]);

  useEffect(() => {
    const insertAuthGroup = [];
    checkedDatasList.forEach((dt, index) => {
      insertAuthGroup.push({
        menuId: keyVal,
        authCd: dt,
      });
    });
    setCheckInserData([...insertAuthGroup]);
    if (checkedDatasList.legnth !== 0) {
      const UAList = userGrade.filter((e) => e.attrCd1 === "LV").map(e => e.cd);
      if (UAList.length !== 0) {
        if (UAList.length === checkedDatasList.length) {
          setCheckAll(true);
        };
      };
    };
  }, [checkedDatasList]);

  useEffect(() => {
    const insertTreeAuthGroup = [];
    treeCheck.forEach((dt, index) => {
      insertTreeAuthGroup.push({
        menuId: keyVal,
        authCd: dt,
      });
    });
    setCheckInsertTreeData([...insertTreeAuthGroup]);
  }, [treeCheck]);

  return (
    <div className="org_details__container">
      <div className="org_common_title">
        <Text className="org_common_content">{formatMessage({ id: "T0664" })}</Text>{/* 메뉴 상세 정보 설정 */}
      </div>
      <div className="org_common__box">
        <FormTable style={{ margin: 20, width: "95%" }}>
          <colgroup>
            <col span="1" width="20%" />
            <col span="1" width="30%" />
            <col span="1" width="20%" />
            <col span="1" width="30%" />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <Text>{formatMessage({ id: "T0665" })}</Text>{/* 메뉴명 */}
              </th>
              <td>
                <div>
                  <Input
                    name="menuNm"
                    value={changeInput.menuNm}
                    style={{ width: "100%" }}
                    onChange={onChangeInput}
                  />
                </div>
              </td>
              <th>
                <Text>URL</Text>
              </th>
              <td>
                <div>
                  <Input
                    name="menuUrl"
                    value={changeInput.menuUrl}
                    style={{ width: "100%" }}
                    onChange={onChangeInput}
                    readOnly={!isKorean}
                    disabled={!isKorean}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <Text>{formatMessage({ id: "T0666" })}</Text>{/* 페이지 설명 */}
              </th>
              <td>
                <div>
                  <TextArea
                    name="menuDescription"
                    style={{ resize: "none" }}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    value={changeInput.menuDescription}
                    onChange={onChangeInput}
                    readOnly={!isKorean}
                    disabled={!isKorean}
                  />
                </div>
              </td>
              <th>
                <Text>{formatMessage({ id: "T0667" })}</Text>{/* 정렬 순서 */}
              </th>
              <td>
                <div>
                  <Input
                    name="menuOrder"
                    type="number"
                    value={changeInput.menuOrder}
                    style={{ width: "100%" }}
                    onChange={onChangeInput}
                    readOnly={!isKorean}
                    disabled={!isKorean}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>
                <Text>{formatMessage({ id: "T0668" })}</Text>{/* 메뉴 종류 */}
              </th>
              <td>
                <div>
                  <Select
                    id="linkYn"
                    style={{
                      width: "100%",
                    }}
                    defaultValue={null}
                    value={linkYn || null}
                    onChange={onChangeLinkYn}
                    readOnly={!isKorean}
                    disabled={!isKorean}
                  >
                    <Option value={null}>{formatMessage({ id: 'T0497' })}</Option>{/* 내부 */}
                    <Option value="LINK">LINK</Option>
                    <Option value="IFRM">iFrame</Option>
                  </Select>
                </div>
              </td>
              <th>
                <Text>{formatMessage({ id: "T0669" })}</Text>{/* 메뉴 사용여부 */}
              </th>
              <td>
                <div>
                  <Select
                    id="useYn"
                    style={{
                      width: "100%",
                    }}
                    defaultValue="N"
                    value={useYn || ""}
                    onChange={onChangeUseYn}
                    readOnly={!isKorean}
                    disabled={!isKorean}
                  >
                    <Option value="Y">Y</Option>
                    <Option value="N">N</Option>
                  </Select>
                </div>
              </td>
            </tr>
            {/* 신규 추가시에는 보이지 않고 신규 메뉴 추가후 수정 가능 창 보임 */}
            {childCheck > 1 && (
              <tr>
                <th>
                  <Text>{formatMessage({ id: "T0670" })}</Text>{/* 메뉴 접근 권한 */}
                </th>
                <td colSpan={4} style={{ position: 'relative' }}>
                  {
                    !isKorean &&
                    <div
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        background: 'rgba(200,200,200,0.5)',
                        left: 0,
                        top: 0,
                        cursor: 'no-drop',
                        zIndex: '100'
                      }}
                    >
                    </div>
                  }
                  <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <div className="system_section_check">
                      <Text >{formatMessage({ id: "T0671" })}</Text>{/* 사용자 등급별 */}
                      <hr />
                      <div className="checkAll_checkbox">
                        <Checkbox
                          onChange={onChangeAllCheck}
                          checked={checkAll}
                        >
                          {formatMessage({ id: "T0672" })}{/* 전체 선택 */}
                        </Checkbox>
                      </div>
                      <Checkbox.Group
                        onChange={(e) => onChangeCheck(e)}
                        options={userGrade
                          .filter((e) => e.attrCd1 === "LV")
                          .map((e) => ({ label: e.cdNm, value: e.cd }))}
                        value={checkedDatasList}
                      ></Checkbox.Group>
                    </div>
                    <div className="system_section_tree">
                      <Text >{formatMessage({ id: "T0673" })}</Text>{/* 사용자 권한 그룹별 */}
                      <hr />
                      <Tree
                        className="draggable-tree"
                        onCheck={onTreeCheck}
                        checkedKeys={treeCheck}
                        checkable
                        defaultExpandedKeys={expand}
                        expandedKeys={expand}
                        onExpand={onExpandTree}
                      >
                        {userGrade
                          .filter((e) => e.attrCd1 === "EX")
                          .map((res) => {
                            return (

                              <TreeNode key={res.cd} title={res.cdNm}>
                                {authGroupList
                                  .filter((e) => e.authCd === res.cd)
                                  .map((r, index) => {
                                    return (
                                      <TreeNode
                                        key={r.empNum}
                                        title={
                                          (
                                            <>
                                              <div>
                                                {r.empName === null ? <span>{formatMessage({ id: "T0674" })}</span> // 이름 없음
                                                  : <span>{r.empName}</span>
                                                }
                                                <span> / ({r.empNum})</span>
                                              </div>
                                            </>
                                          )
                                        }
                                        disableCheckbox={true}
                                      />
                                    );
                                  })}
                              </TreeNode>
                            );
                          })}
                      </Tree>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </FormTable>
        <div className="org_detail__button">
          <Button style={{ width: 150, height: 40 }} onClick={handleDelete}>
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>
          <Button
            type="primary"
            style={{ width: 150, height: 40 }}
            onClick={handleSave}
          >
            <FormattedMessage id="T0106" />{/* 등록 완료 */}
          </Button>
        </div>
      </div>

      <Modal
        width={540}
        visible={PoolVisible}
        title={formatMessage({ id: "T0675" })} // 메뉴 삭제
        okText={formatMessage({ id: 'T0072' })} // 삭제
        onCancel={onCancelDelete}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: 'T0676' })}{/* 정말로 선택된 메뉴를 삭제하시겠습니까? */}
        </Text>
      </Modal>
    </div>
  );
};
export default MenuDetail;