import styled from 'styled-components';

const HeaderWrap = styled.div`
    position: fixed;
    left: 0;
    top:0;
    display: flex;
    width: 100%;
    height: 60px;
    z-index: 1000;
    background: #fff;
    transition: visibility 0.1s linear, opacity 0.1s linear;
    border-bottom: 1px solid #ccccd4;
    &.header__hide {
        opacity: 0;
        visibility: hidden;
    }
    .header_logo__box {
        display: flex;
        align-items: center;
        padding: 0 38px;
        background: #fff;
    }
    .header_menu__box {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 38px;
        height: 100%;
        background: #fff;
        border-radius: 0px 0px 0px 35px;
    }
    .menu_main__box {
        display: flex;
        gap: 0 40px;
        margin: 0;
        height: 100%;
    }
    .menu_main__box .menu_main__menu {
        display: flex;
        height: 100%;
        color: #000;
        font-size: 16px;
        cursor: pointer;
        line-height: 60px;
    }
    .menu_main__box .menu_main__menu .menu_main__text {
        position: relative;
        display: block;
        color: inherit;
        font-size: inherit;
        white-space: nowrap;
    }
    .menu_main__box .menu_main__menu:hover {
        color: #ff7a00;
    }
    /* .menu_main__box .menu_main__menu:hover {
        color: #0067ac;
    } */
    .menu_main__box .menu_main__menu .menu_main__text::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0;
        height: 6px;
        background: #fff;
        transition: 0.2s;
    }
    
    .menu_main__box .menu_main__menu:hover .menu_main__text::before,
    .menu_main__box .menu_main__menu.select_menu .menu_main__text::before {
        width: 100%;
        left: 0;
    }

    .menu_sub__box {
        background: #fff;
        position: absolute;
        width: 100%;
        left: 0;
        top: calc(100% + 1px);
        display: flex;
        flex-wrap: wrap;
        visibility: hidden;
        cursor: default;
        z-index: 5;
        box-shadow : 0px 3px 3px rgba(194,194,194,0.16);
    }
    .menu_sub__box .menu_sub__menu {
        padding: 0 18px;
        position: relative;
        height: 60px;
        font-weight: normal;
        line-height: 60px;
        font-size: 16px;
        color: #000;
        white-space: pre;
    }
    .menu_sub__box .menu_sub__menu .menu_sub__text {
        display: block;
        height: 100%;
    }
    .menu_sub__box .menu_sub__menu .menu_sub__text::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 0;
        height: 4px;
        /* 우리카드 */
        /* background: #22e3a6; */
        background: #ff9a26;
        transition: 0.2s;
    }
    .menu_sub__box .menu_sub__menu .menu_sub__text:hover::before,
    .menu_sub__box .menu_sub__menu .menu_sub__text.select_menu::before {
        width: 100%;
        left: 0;
    }
    .menu_sub__box .menu_sub__menu .menu_sub__text:hover {
        /* 우리카드 */
        /* color: #2b64ce !important; */
        color: #ff7a00 !important;
    }
    .menu_main__box .menu_main__menu:hover .menu_sub__box {
        visibility: visible;
    }
    .menu_main__box .menu_main__menu.menu_main__menu_hover:hover ~ .menu_cover {
        visibility: visible;
    }
    .menu_main__menu .select_menu,
    .menu_sub__menu .select_menu {
        /* 우리 */
        /* color: #0067ac !important;
        font-weight: bold !important; */
        color: #ff7a00 !important;
        font-weight: bold !important;
    }
    .menu_cover {
        position: absolute;
        visibility: hidden;
        left: 0;
        top: 100%;
        width: 100%;
        height: 60px;
        background: #fff;
        box-shadow: 0 0 2px 0px rgb(0 0 0 / 16%);
        z-index: 1;
    }

    .menu_tools__box {
        display: flex;
        align-items: center;
        gap: 0 30px;
        padding-right:10px;
    }
    .menu_tools__box .ant-popover.ant-popover-placement-bottomRight {
        background: red !important
    }
    .menu_tools__box .menu_tools__link {
        display: flex;
        align-items: center;
        text-decoration: none;
        cursor: pointer;
        border: 1.5px solid #FFB164; 
        // 우리카드
        /* border: 1.5px solid #0067ac;  */
        height:35px;
        padding: 5px; 
        border-radius: 10px;
        padding : 5px 10px ;
        white-space: nowrap;
    }

    .menu_tools__box .menu_tools__link:hover {
        background: #FFB164;
        // 우리카드
        /* background: #0067ac; */
    }

    .menu_tools__box .menu_tools__link > span {
        font-size: 16px;
        color: #000;
    }

    // 우리카드
    /* .menu_tools__box .menu_tools__link:hover > span {
        font-size: 16px;
        color: #fff;
    } */
    .menu_tools__box .menu_tools__link:hover > span svg path#패스_10199,
    .menu_tools__box .menu_tools__link:hover > span svg path#패스_10200, 
    .menu_tools__box .menu_tools__link:hover > span svg line#선_1992 {
        stroke: #fff;
    }

    /* bell icon */
    .menu_tools__alram .ant-badge-count {
        background: #f0142f;
        position: absolute;
        top: 11px !important;
        right: 3px !important;
        box-shadow: none;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
    } 
    /* user */
    .user_thumbnail {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #333;
    }
    .user_thumbnail img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }   
    .avatar_profile {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        cursor: pointer;
    }
    .logo_dev{
        align-self: flex-end;
        font-weight:bold;
        font-size :10px;
    }
`;

export default HeaderWrap;