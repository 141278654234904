import React, { useEffect, useState } from "react";
import { PageTemplate, Header, Footer } from "components";
import Container from "containers/Container";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";

const IframePage = ({ match: { params }, history }) => {
    const { formatMessage } = useIntl();

    const [menuInfo, setMenuInfo] = useState({});

    useEffect(() => {
        const fetchMenuInfo = async () => {
            const result = await fetchAbsolute('get', `/admin/menu/getMenuInfo?menuId=${params.menuId}`);
            if (result.error) {
                return history.replace(result.redirectURL);
            }
            if (result.data.menuType !== 'IFRM') {
                return history.replace('/error/notfound');
            }
            setMenuInfo({ ...result.data });
        }
        fetchMenuInfo();
    }, [params.menuId]);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false}>
            <Container style={{ height: 'calc((100vh - 60px) - 50px)' }}>
                <iframe
                    frameBorder="0"
                    frameSpacing="0"
                    width='100%'
                    style={{
                        height: 'calc((100vh - 60px) - 50px)'
                    }}
                    src={menuInfo.menuUrl}
                    title={formatMessage({ id: "T1166" })} // iframe page
                />
            </Container>
        </PageTemplate>
    );
};

export default IframePage;