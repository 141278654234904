import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import {
  Button,
  Tabs,
  Table,
  Text,
  Modal,
  PermissionComponent,
  UserContext,
  ScheduleUserInputModal,
  DevicePool,
  Loading,
} from "components";
import { Select, Row, Col, Tabs as AntTabs, Spin, Tooltip } from "antd";
import { HeartFilled, PlayCircleFilled } from "@ant-design/icons";
import fetchAbsolute from "utils/fetchAbsolute";
import { empPoint } from "utils/savePoint";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import moment from "moment";
import XLSX from "xlsx-js-style";

const { TabPane } = AntTabs;
const { Option } = Select;

const initialPageInfo = {
  sort: "regTimestamp,desc",
  page: 1,
  size: 10,
  total: 0,
  tab: "all",
};

const initUserInputValues = [{ key: "", value: "" }];

const Wrapper = styled.div`
  .ant-tabs-ink-bar {
    bottom: 0;
    background: none;
  }
  .ant-table table {
    table-layout: fixed !important;
  }
  .people_section {
    display: flex;
  }
  .people_section .main {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .orgNm_section {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .loading_assignment_excel .lds-spinner {
    position: absolute !important;
    z-index: 100;
    height: none !important;
  }
  .loading_assignment_excel {
    height: none !important;
  }
`;

const AssignBottom = ({
  history,
  tableTotal,
  tabsValue,
  tableData,
  searchQuery,
  replaceSearch,
  toggleHeart,
  tableLoading,
  fetchPath,
  searchCondition,
  excelBody,
  setSortedInfo,
  sortedInfo,
  location: { search }
}) => {
  const profile = useSelector((state) => state.get("auth").get("profile"));
  const { formatMessage } = useIntl();

  const fmMessage = useFormatMessage();

  const pageSize = [20, 50, 70];

  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));

  const taskNm = formatMessage({ id: "T0002" }); // 과제명
  const taskType = formatMessage({ id: "T0003" }); // 과제 유형
  const devEmpNm = formatMessage({ id: "T0007" }); // 개발자
  const selectDevicePool = formatMessage({ id: "T0146" }); // Devie pool을 선택하세요
  const excuteStart = formatMessage({ id: "T0147" }); //즉시 실행이 시작되었습니다.
  const excuteFail = formatMessage({ id: "T0148" }); //즉시 실행 오류가 발생하였습니다
  const duplicationKeyExist = formatMessage({ id: "T0149" }); //중복된 키가 있습니다.
  const workGbnNm = formatMessage({ id: "T0340" }); // 업무분류
  const taskId = formatMessage({ id: "T0001" }); // 과제 ID
  const companyNm = formatMessage({ id: "T1500" }); // 회사명
  const mainOrgNm = formatMessage({ id: "T0455" }); // 부서
  const mainEmpNm = formatMessage({ id: "T0456" }); // 담당자
  const fte = formatMessage({ id: "T1099" }); // FTE
  const statusNm = formatMessage({ id: "T0457" }); // 과제상태
  const regTimestamp = formatMessage({ id: "T0394" }); // 등록일

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [runVisible, setRunVisible] = useState(false);
  const [devicePool, setDevicePool] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [userInputVisible, setUserInputVisible] = useState(false);
  const [devicePoolVisible, setDevicePoolVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [runInfo, setRunInfo] = useState({});
  const [userInputValues, setUserInputValues] = useState([
    ...initUserInputValues,
  ]);

  const crCodeInfo = crInfo.find(
    (v) => v.crType === runInfo.taskType && v.crEnvGubun === "PRD"
  )?.crCd;

  const getCrType = crInfo.find(
    (v) => v.crType === runInfo.taskType && v.crEnvGubun === "PRD"
  )?.crXEnv;

  const onCancelDevicePoolModal = () => setDevicePoolVisible(false);

  const immediatelyExecution = async (
    taskId,
    poolName,
    userInputValues,
    taskType
  ) => {
    let tmpUserInput;
    if (poolName?.length === 0) {
      return alert(selectDevicePool);
    }
    if (userInputValues[0].key !== "") {
      tmpUserInput = userInputValues.reduce(
        (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
        {}
      );
    }

    let fetchFlag = true;
    let parameter = {
      taskId,
      cr: crCodeInfo,
      poolName,
      scheduleType: "INSTANT",
      timeZone: "Asia/Seoul",
      userInput: {
        ...tmpUserInput,
      },
    };

    try {
      const result = await fetchAbsolute(
        "post",
        `/task/oracle/addTaskSchedule?crType=${getCrType}`,
        {
          data: { ...parameter },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.data.result === "fail") {
        const confirmResult = window.confirm(
          `${result.data?.message} \n${formatMessage({ id: "T1301" })}`
        ); // 대기 시간 이후에 자동으로 실행할까요?
        if (confirmResult) {
          const waiting = async () => {
            const result = await fetchAbsolute(
              "post",
              "/task/oracle/waitInstantAction",
              {
                data: { ...parameter },
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
          };
          waiting();
        }
      } else if (result.status === 200 || result.status === 201) {
        alert(excuteStart);
      } else {
        throw new Error(result.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(excuteFail);
      }
    }
  };

  const onOkDevicePoolModal = (data) => {
    // 선택한 값이 없을 때
    if (Object.keys(data).length === 0) return alert(selectDevicePool);
    setDevicePool({ ...data });
    setDevicePoolVisible(false);

    // deivepool 모달 -> user Input 모달로 이동할 때, 값 리셋
    setUserInputValues([{ key: "", value: "" }]);
    setUserInputVisible(true);
  };

  const onOkUserInput = async () => {
    let flag = false;
    userInputValues.reduce((acc, cur) => {
      if (acc.findIndex(({ key }) => key === cur.key) === -1) {
        acc.push(cur);
      } else {
        flag = true;
      }
      return acc;
    }, []);
    if (flag) {
      return alert(duplicationKeyExist);
    }
    setUserInputVisible(false);
    // setUserInputValues([...initUserInputValues]);
    immediatelyExecution(
      runInfo.taskId,
      devicePool.dpNm,
      userInputValues,
      runInfo.taskType
    );
    setDevicePool({});
    // setUserInputValues([{ key: "", value: "" }]);
    history.push(`/assign/lookup/${runInfo.taskId}/status`);
  };

  const onCancelUserInput = () => {
    setDevicePool({});
    setUserInputValues([{ key: "", value: "" }]);
    setUserInputVisible(false);
  };

  const onOkRun = () => {
    setRunVisible(false);
    setDevicePoolVisible(true);
    // setUserInputVisible(true);
  };

  const onClickOperation = async (record) => {
    setRunInfo(record);
    setRunVisible(true);
  };

  const excelDataFetch = async () => {
    let path = "";
    switch (searchCondition.tab) {
      case "all":
        path = "taskListExcel";
        break;
      case "myTask":
        path = "myTaskListExcel";
        break;
      case "interest":
        path = "interestTaskListExcel";
        break;
      case "shared":
        path = "sharedTaskListExcel";
        break;
      default:
        path = "taskListExcel";
    }

    setButtonDisabled(true);
    setLoading(true);
    const excelResult = await fetchAbsolute("post", `/task/oracle/${path}${search}`, {
      data: {
        ...excelBody,
      },
    });


    setLoading(false);
    setButtonDisabled(false);
    if (excelResult.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    const exceldatas = excelResult.data.map((v) => ({
      ...v,
      regTimestamp: moment(v.regTimestamp).format("YYYY-MM-DD"),

    }));

    const rows = exceldatas.map((item) => {
      return {
        [workGbnNm]: item.workNm,
        [taskId]: item.taskId,
        [taskNm]: item.taskNm,
        [taskType]: item.taskType || item.taskTypeDesc,
        [companyNm]: item.companyNm,
        [mainOrgNm]: item.mainOrgNm,
        [mainEmpNm]: item.mainEmpNms,
        [devEmpNm]: item.devEmpNms,
        [fte]: item.fte,
        [statusNm]: item.statusNm,
        [regTimestamp]: item.regTimestamp,
      };
    });
    /* generate worksheet and workbook */
    const worksheet = XLSX.utils.json_to_sheet(rows);
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* css */
    for (let i in worksheet) {
      if (typeof worksheet[i] !== "object") continue;
      /* header만 굵은 글씨 & 가운데 정렬 */
      worksheet[i].s = {
        font: {
          name: "Malgun Gothic",
          sz: 11,
        },
      };
      if (i.includes("1") && i.length === 2) {
        worksheet[i].s = {
          font: {
            name: "Malgun Gothic",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }

    if (!worksheet["!cols"]) worksheet["!cols"] = [];
    let cellsWidth = Array.from({ length: 11 }, () => ({ width: 30 })); // length는 header 컬럼 만큼
    worksheet["!cols"].push(...cellsWidth);

    /* create an XLSX file and try to save to Presidents.xlsx */
    XLSX.writeFile(workbook, "과제목록.xlsx", { compression: true });
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'regTimestamp';
    // workGbnNm이 정렬할 때는 workPathNm로 사용됨
    if (sorter.field === 'workGbnNm') order = sorter.columnKey
    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order + orderBy,
      page: pagination.current - 1
    };
    setSortedInfo(sorter);
    replaceSearch(searchObj);
  };

  const onChangeTab = (tabValue) => {
    const searchObj = {
      ...pageInfo,
      tab: tabValue,
      page: 0,
    };
    replaceSearch(searchObj);
  };

  const onSelectOption = (value) => {
    const searchObj = {
      ...pageInfo,
      size: value,
    };
    replaceSearch(searchObj);
  };

  // 관심 과제 추가 및 삭제
  const onClickHeartButton = async (record) => {
    const { taskId, interestTaskYn, taskType } = record;
    const taskYn = interestTaskYn === "Y" ? "N" : "Y";

    const addInterestTask = await fetchAbsolute(
      "post",
      `/common/commonInterestTask/${taskYn === "Y" ? "add" : "delete"}`,
      {
        data: {
          empNo: profile.EMP_NO,
          taskId: taskId,
          ...(taskYn === "Y" ? { useYn: "Y" } : {}),
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!addInterestTask.error && taskType === "DIY") {
      const empNo = record.taskMemberList?.find(
        (v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N"
      ).empNo;
      if (taskYn === "Y") {
        await empPoint("PT08", empNo);
      } else {
        await empPoint("PT09", empNo);
      }
    }
    toggleHeart();
  };

  const columns = [
    {
      key: 'interestTaskYn',
      title: "",
      width: 35,
      dataIndex: "interestTaskYn",
      render: (interestTaskYn, record) => {
        if (record.statusCd === "T40") {
          return (
            <span>
              {
                <HeartFilled
                  onClick={() => onClickHeartButton(record)}
                  data-taskid={record.taskId}
                  // style={{
                  //   color: interestTaskYn === "Y" ? "#0067ac" : "#eaeaed",
                  // }}
                  // 기존
                  style={{
                    color: interestTaskYn === "Y" ? "#ff7a00" : "#eaeaed",
                  }}
                />
              }
            </span>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      key: "workNm",
      title: formatMessage({ id: "T0340" }), //업무 분류 
      dataIndex: "workNm",
      dataSort: "workNm",
      width: 120,
      sorter: (list) => {
        return list.workNm;
      },
      sortOrder: sortedInfo.columnKey === 'workNm' && sortedInfo.order,
      render: (workNm, record) => {
        // const shortGbn = workNm?.split(">");
        return (
          <Tooltip title={record.workGbnNm}>
            <Link to={`/assign/lookup/${record.taskId}`}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {workNm}
              </div>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      key: 'taskId',
      title: formatMessage({ id: "T0001" }), //과제 ID 
      dataIndex: "taskId",
      dataSort: "s",
      width: 90,
      sorter: (list) => {
        return list.taskId;
      },
      sortOrder: sortedInfo.columnKey === 'taskId' && sortedInfo.order,
      render: (taskId, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <div>{taskId}</div>
          </Link>
        );
      },
    },
    {
      key: 'taskNm',
      title: taskNm,
      dataIndex: "taskNm",
      dataSort: "taskNm",
      width: 390,
      sorter: (list) => {
        return list.taskNm;
      },
      sortOrder: sortedInfo.columnKey === 'taskNm' && sortedInfo.order,
      render: (taskNm, record) => {
        return (
          <Tooltip title={taskNm}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Link to={`/assign/lookup/${record.taskId}`}>
                <div
                  style={{
                    width: 356,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {taskNm}
                </div>
              </Link>
              <div style={{ width: 20 }}>
                {/* prod */}
                {record.mgrOpYn === "Y" && (
                  <PlayCircleFilled
                    style={{
                      // 기존
                      color: "#ff7a00",
                      // color: "#0067ac",
                      fontSize: 20,
                    }}
                    onClick={() => onClickOperation(record)}
                  />
                )}
              </div>
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: 'taskType',
      title: formatMessage({ id: "T0740" }), //과제유형
      dataIndex: "taskType",
      dataSort: "taskType",
      width: 75,
      sorter: (list) => {
        return list.taskType;
      },
      sortOrder: sortedInfo.columnKey === 'taskType' && sortedInfo.order,
      render: (taskType, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            {/* 과제 유형을 조건부로 보여주기 */}
            <div>{record.taskTypeDesc || taskType}</div>
          </Link>
        );
      },
    },
    // profile.DWP_USER_LEVEL === "UA01" &&
    {
      key: 'companyNm',
      title: formatMessage({ id: "T1500" }), //회사명 
      width: 100,
      dataIndex: "companyNm",
      dataSort: "companyNm",
      sorter: (list) => {
        return list.companyNm;
      },
      sortOrder: sortedInfo.columnKey === 'companyNm' && sortedInfo.order,
      render: (companyNm, record) => {
        return (
          <Tooltip title={companyNm}>
            <Link to={`/assign/lookup/${record.taskId}`}>
              <div
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {companyNm}
              </div>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      key: 'mainOrgNm',
      title: formatMessage({ id: "T0455" }), //부서
      dataIndex: "mainOrgNm",
      dataSort: "mainOrgNm",
      width: 100,
      sorter: (list) => {
        return list.mainOrgNm;
      },
      sortOrder: sortedInfo.columnKey === 'mainOrgNm' && sortedInfo.order,
      render: (mainOrgNm, record) => {
        return (
          <Tooltip title={mainOrgNm}>
            <Link to={`/assign/lookup/${record.taskId}`}>
              <div className="orgNm_section">{mainOrgNm}</div>
            </Link>
          </Tooltip>
        );
      },
    },
    {
      key: 'mainEmpNm',
      title: formatMessage({ id: "T0456" }), //담당자
      dataIndex: "mainEmpNm",
      dataSort: "mainEmpNm",
      width: 80,
      sorter: (list) => {
        return list.mainEmpNm;
      },
      sortOrder: sortedInfo.columnKey === 'mainEmpNm' && sortedInfo.order,
      render: (mainEmpNm, list) => {
        const tmpSpan = document.createElement("span");
        tmpSpan.innerText = mainEmpNm;
        tmpSpan.style.fontSize = 12;
        document.body.appendChild(tmpSpan);
        const width = tmpSpan.offsetWidth;
        const style = {};
        if (width > 50) {
          style.width = 48;
          style.paddingRight = 2;
        }
        tmpSpan.remove();
        return (
          <Tooltip title={list.mainEmpNms}>
            <div className="people_section">
              <div className="main" style={{ ...style }}>
                <UserContext
                  profile={{
                    NAME_KOR: mainEmpNm,
                    EMP_NO: list.mainEmpNo,
                    DEPT_NAME_KOR: list.mainOrgNm,
                  }}
                  type="mgr"
                  contextVisible={true}
                >
                  <Text type="context" style={{ fontSize: 12 }}>
                    {mainEmpNm}
                  </Text>
                </UserContext>
              </div>
              {+list.mainEmpNmsCnt !== 0 && (
                <Text style={{ fontSize: 12 }}>({+list.mainEmpNmsCnt})</Text>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    //개발자
    {
      key: 'devEmpNm',
      title: devEmpNm,
      dataIndex: "devEmpNm",
      dataSort: "devEmpNm",
      width: 80,
      sorter: (list) => list.devEmpNm,
      sortOrder: sortedInfo.columnKey === 'devEmpNm' && sortedInfo.order,
      render: (devEmpNm, list) => {
        const tmpSpan = document.createElement("span");
        tmpSpan.innerText = devEmpNm;
        tmpSpan.style.fontSize = 12;
        document.body.appendChild(tmpSpan);
        const width = tmpSpan.offsetWidth;
        const style = {};
        if (width > 50) {
          style.width = 48;
        } else {
          style.paddingRight = 2;
        }
        tmpSpan.remove();
        return (
          <Tooltip title={list.devEmpNms}>
            <div className="people_section">
              <div className="main" style={{ ...style }}>
                <UserContext
                  profile={{
                    NAME_KOR: devEmpNm,
                    EMP_NO: list.devEmpNo,
                    DEPT_NAME_KOR: list.devOrgNm,
                  }}
                  type="dev"
                  contextVisible={true}
                >
                  <Text type="context" style={{ fontSize: 12 }}>
                    {devEmpNm}
                  </Text>
                </UserContext>
              </div>
              {+list.devEmpNmsCnt > 0 && list.devEmpNmsCnt && (
                <Text style={{ fontSize: 12 }}>({list.devEmpNmsCnt})</Text>
              )}
            </div>
          </Tooltip>
        );
      },
    },
    {
      key: 'fte',
      title: formatMessage({ id: "T1099" }), //FTE 
      width: 65,
      dataIndex: "fte",
      dataSort: "fte",
      sorter: (a,b) => parseFloat(a.fte) - parseFloat(b.fte),
      // sortOrder: null,
      sortOrder: sortedInfo.columnKey === 'fte' && sortedInfo.order,
      render: (fte, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <div>{fte}</div>
          </Link>
        );
      },
    },
    {
      key: 'statusNm',
      title: formatMessage({ id: "T0457" }), //과제상태
      width: 95,
      dataIndex: "statusNm",
      dataSort: "statusNm",
      sorter: (list) => {
        return list.statusNm;
      },
      sortOrder: sortedInfo.columnKey === 'statusNm' && sortedInfo.order,
      render: (statusNm, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <div>{statusNm}</div>
          </Link>
        );
      },
    },
    {
      key: 'regTimestamp',
      title: formatMessage({ id: "T0394" }), //등록일
      width: 100,
      dataIndex: "regTimestamp",
      dataSort: "regTimestamp",
      sorter: (list) => {
        return list.regTimestamp;
      },
      sortOrder: sortedInfo.columnKey === 'regTimestamp' && sortedInfo.order,
      render: (regTimestamp, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <div>{regTimestamp}</div>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    const keys = Object.keys(searchQuery);
    const initialKeys = Object.keys(initialPageInfo);
    const pageObj = { ...searchQuery };

    keys.forEach((key) => {
      if (!initialKeys.includes(key)) {
        delete pageObj[key];
      }
    });
    setPageInfo({
      ...pageObj,
    });
  }, [searchQuery]);

  if (tabsValue) {
    return (
      <Wrapper>
        <Tabs
          defaultActiveKey={tabsValue}
          activeKey={tabsValue}
          onChange={onChangeTab}
        >
          <TabPane
            tab={`${formatMessage({ id: "T0451" })} (${tableTotal.all || 0})`} //전체 과제
            key="all"
          />
          <TabPane
            tab={`${formatMessage({ id: "T0452" })} (${tableTotal.myTask || 0 //내 과제
              })`}
            key="myTask"
          />
          <TabPane
            tab={`${formatMessage({ id: "T0453" })} (${tableTotal.interest || 0 //관심 과제
              })`}
            key="interest"
          />
          <TabPane
            tab={`${formatMessage({ id: "T0454" })} (${tableTotal.shared || 0 //내가 공유 받은 과제
              })`}
            key="shared"
          />
        </Tabs>
        <Row gutter={[8, 24]}>
          <Col span={8}>
            <span>
              {formatMessage({ id: "T0036" })} &emsp; {/* 페이지당 행 */}
              <Select
                name="pagination"
                defaultValue={pageSize[0]}
                onSelect={onSelectOption}
                value={+searchQuery.size}
                style={{
                  width: 90,
                }}
              >
                {pageSize.map((v, index) => (
                  <Option key={index} value={v}>
                    {fmMessage({ id: "T1228", values: { number: v } })}
                    {/* number(개) */}
                  </Option>
                ))}
              </Select>
            </span>
          </Col>
          <Col span={8}></Col>
          <Col
            span={8}
            style={{
              textAlign: "right",
            }}
          >
            <PermissionComponent type="user" fnCode="FN01">
              <Link to="/assign/enter">
                <Button
                  style={{
                    width: 150,
                    height: 40,
                  }}
                  type="primary"
                >
                  <FormattedMessage id="T0040" />
                  {/* 과제 등록 */}
                </Button>
              </Link>
            </PermissionComponent>
            <Button
              style={{
                width: 150,
                height: 40,
                marginLeft: 25,
              }}
              onClick={excelDataFetch}
              disabled={buttonDisabled}
            >
              <FormattedMessage id="T0041" />
              {/* 엑셀 다운로드 */}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            {loading && (
              <Loading
                visible={loading}
                height={"none"}
                minHeight={"none"}
                position={"none"}
                className="loading_assignment_excel"
              />
            )}
            <Table
              style={{
                zIndex: 1000,
              }}
              rowKey={(item) => item.taskId}
              columns={columns}
              dataSource={tableData}
              pagination={true}
              align="center"
              defaultPageSize={20}
              pageSizeVisible={false}
              onChange={onChangeTableColumns}
              showSorterTooltip={true}
              loading={tableLoading}
              pageOptions={{
                total: tableTotal[tabsValue],
                current: +searchQuery.page + 1,
                pageSize: +searchQuery.size,
              }}
            />
          </Col>
        </Row>
        <Modal
          centered
          visible={runVisible}
          width={500}
          okText={formatMessage({ id: "T1125" })} //실행
          title={formatMessage({ id: "T0458" })} //과제 즉시 실행
          onOk={onOkRun}
          onCancel={() => setRunVisible(false)}
        >
          <Text>
            <FormattedMessage
              id="T1088"
              values={{
                taskName: runInfo.taskNm,
                taskId: runInfo.taskId,
              }}
            />
            {/* 과제를 즉시 실행하시겠습니까? */}
          </Text>
        </Modal>
        <ScheduleUserInputModal
          visible={userInputVisible}
          onOk={onOkUserInput}
          onCancel={onCancelUserInput}
          userInputValues={userInputValues}
          setUserInputValues={setUserInputValues}
        />
        <DevicePool
          visible={devicePoolVisible}
          onOk={onOkDevicePoolModal}
          onCancel={onCancelDevicePoolModal}
          crCode={crCodeInfo}
          defaultData={[devicePool]}
        />
      </Wrapper>
    );
  } else {
    return <Spin />;
  }
};

export default withRouter(AssignBottom);
