import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  margin-top: 50px;
  padding: 30px 0;

  /* 스케줄 페이지 상단 날짜 */
  .badge_status_info {
    display: flex;
    gap: 10px;
    align-self: center;
  }
  .badge_status_info .ant-badge-status-text {
    font-size: 13px;
  }
  .schedule_page__container {
    font-size: 23px;
    font-weight: bolder;
    width: 27%;
    padding-bottom: 1%;
    padding-top: 3%;
  }
  .schedule__bottom {
    padding-top: 50px;
    width: 100%;
  }
  .schedule__tooltip {
    display: grid;
    padding-bottom: 15px;
    grid-template-columns: repeat(3, 1fr);
  }
  .schedule__bottom .calendar_date {
    display: flex;
    justify-content: center;
  }
  .schedule__bottom .calendar_date .calendar_date__wrap {
    display: flex;
    gap: 0 50px;
    align-items: center;
  }
  .calendar_date__wrap .calendar_date__text {
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
  }
  .schedule__bottom .calendar_radio {
    display: flex;
    align-items: center;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent !important;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
    background-color: transparent;
  }
  .calendar__schedule_name {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
  }
  .ant-badge-status-text {
    font-size: 12px;
  }
  .schedule_daily__name_box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-fullcalendar-fullscreen
    .ant-fullcalendar-selected-day
    .ant-fullcalendar-date {
    background: unset !important;
  }
  .ant-fullcalendar-fullscreen
    .ant-fullcalendar-selected-day
    .ant-fullcalendar-value {
    color: rgba(0, 0, 0, 0.65) !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected
    .ant-picker-cell-inner
    .ant-picker-calendar-date-value {
    color: #000;
  }

  .ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today,
  .ant-fullcalendar-fullscreen .ant-fullcalendar-today .ant-fullcalendar-date {
    border-top-color: #e8e8e8;
    background: #fff3e8;
  }
  .ant-picker-calendar-full
    .ant-picker-panel
    .ant-picker-calendar-date-today
    .ant-picker-calendar-date-value,
  .ant-fullcalendar-fullscreen
    .ant-fullcalendar-today
    .ant-fullcalendar-date
    .ant-fullcalendar-value {
    color: #ff7a00;
  }
  /* bottom 하단 달력 css */
  .calendar__schedule_list {
    margin-left: -30px;
  }
  /* Weekly */
  .workflow-schedule-weekly .ant-table-content table > tbody > tr > td {
    padding: 10px 10px;
  }
  .schedule_weekly__name_box {
    cursor: pointer;
  }
  .schedule_weekly__name_box .ant-badge {
    display: flex;
    place-items: center;
  }
`;

export default Wrapper;
