import React from "react";
import { Tabs as AntTabs } from "antd";
import styled from "styled-components";
const { TabPane } = AntTabs;

/*
 .ant-tabs-nav-wrap {
    border-bottom: 3px solid #c1c3c9;
  }
*/
const TabStyled = styled(AntTabs)`
.ant-tabs-tab {
  place-content: center !important;
}
  ${props => props.body && `
      .ant-tabs-bar.ant-tabs-top-bar {
        display: none;
      }
    `
  }
  ${props => props.header && `
      .ant-tabs-content {
        display: none;
      }
    `
  }

  // 우리은행 수정사항
  /* ${props => props.custom && `
    .ant-tabs-nav-wrap {
      border-bottom: 3px solid #c1c3c9;
    }
    .ant-tabs-tab:hover {
      color: #000;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #2b64ce !important;
      text-shadow: 0 0 0.25px currentColor; 
    }
    .ant-tabs-ink-bar {
      border-bottom: 3px solid #22e3a6 !important;
      position: absolute;
      pointer-events: none;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #22e3a6;
      font-weight: 500;
      border-bottom: none !important;
    }
  `} */
  // 기존 드리고
  ${props => props.custom && `
    .ant-tabs-nav-wrap {
      border-bottom: 3px solid #c1c3c9;
    }
    .ant-tabs-tab:hover {
      color: #000;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #000 !important;
      text-shadow: 0 0 0.25px currentColor; 
    }
    .ant-tabs-ink-bar {
      border-bottom: 3px solid #000000 !important;
      position: absolute;
      pointer-events: none;
    }
    .ant-tabs-nav .ant-tabs-tab-active {
      color: #303030;
      font-weight: 500;
      border-bottom: none !important;
    }
  `}
`;

const Tabs = ({
  onChange = () => { },
  onTabClick = () => { },
  activeKey = "",
  custom = true,
  children,
  ...args
}) => {
  return (
    <TabStyled custom={custom} onChange={onChange} {...args}>
      {children}
    </TabStyled>
  );
};

Tabs.TabPane = TabPane;
export default Tabs;