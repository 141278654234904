import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import './index.css';
import configureStore from 'store';
import reportWebVitals from './reportWebVitals';
import LanguageProvider from 'containers/LanguageProvider';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Route>
      <LanguageProvider>
        <App />
      </LanguageProvider>
    </Route>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
