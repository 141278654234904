import { LOAD_WORKFLOW_DATA_SUCCESS, LOAD_WORKFLOW_DATA_FAIL, SET_WORKFLOW_DATA, CHANGE_HISTORY_WORKFLOW_DATA, CHANGE_WORKFLOW_SHCEDULE_STATUS, RESET_WORKFLOW_DATA } from './action';

/**
 * 권한 구현을 위한 State
 * 
 */

const lookupInitialState = {
    workflowData: {},
    scheduleStatus: false,
};

const workflowLookup = (state = lookupInitialState, action) => {

    switch (action.type) {
        case LOAD_WORKFLOW_DATA_SUCCESS:
            return {
                ...state,
                workflowData: {
                    ...action.workflowData
                }
            };
        case LOAD_WORKFLOW_DATA_FAIL:
            return {
                ...state,
                workflowData: {

                }
            };
        case SET_WORKFLOW_DATA:
            return {
                ...state,
                workflowData: {
                    ...state.workflowData,
                    ...action.data
                }
            };
        case CHANGE_HISTORY_WORKFLOW_DATA:
            return {
                ...state,
                workflowData: {
                    ...state.workflowData,
                    ...action.moreInfo,
                    statusCd: action.history.chgCd,
                    detailStatusCd: action.history.chgCdDetail,
                    taskHistoryList: state.workflowData.taskHistoryList.concat(action.history),
                }
            };
        case CHANGE_WORKFLOW_SHCEDULE_STATUS:
            return {
                ...state,
                scheduleStatus: action.bool
            };
        case RESET_WORKFLOW_DATA:
            return {
                ...lookupInitialState
            };
        default:
            return state;

    }
}

export default workflowLookup;
