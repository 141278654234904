import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getUniqueKey } from 'utils/Str';
import { empPoint } from "utils/savePoint";
import { Text, Button, FormTable } from "components";
import { HeartFilled, HeartOutlined, FileTextOutlined } from "@ant-design/icons";
import { CopyIcon, EyeIcon } from "icons";
import { Tooltip, Typography } from "antd";
import { setTaskData } from "store/assignLookup/action";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

/**
 * 과제 목록 > 과제 조회 (상세) > 왼편
 */

const { Paragraph } = Typography;

const TaskInfo = ({ history }) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const fmMessage = useFormatMessage();

  const profile = useSelector(state => state.get('auth').get('profile'));
  const assignLookup = useSelector(state => state.get(getUniqueKey('assignLookup')));
  const listSearch = useSelector(state => state.get(getUniqueKey('listUrl')).assignList);

  // 복사 버튼 클릭 시 1초 뒤에 사라지게 하는 timeOut 변수
  const copyNmTimeout = useRef(null);
  const copyIdTimeout = useRef(null);

  const taskId = formatMessage({ id: "T0001" }); // 과제 ID
  const taskNm = formatMessage({ id: "T0002" }); // 과제명
  const taskType = formatMessage({ id: "T0003" }); // 과제 유형
  const taskWorkId = formatMessage({ id: "T0008" }); // 업무 분류

  const [visibleCopyNm, setVisibleCopyNm] = useState(false);
  const [visibleCopyId, setVisibleCopyId] = useState(false);

  const [desEllipsis, setDesEllipsis] = useState(true);

  // 텍스트 카피
  const copyText = (text) => {
    const tempElem = document.createElement("textarea");
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
  };

  // 관심과제 버튼 눌렀을 때
  const onClickHeart = async (taskId) => {
    const interestTaskYn = assignLookup.taskData.interestTaskYn === "Y" ? "N" : "Y";

    const addInterestTask = await fetchAbsolute("post", `/common/commonInterestTask/${interestTaskYn === "Y" ? "add" : "delete"}`, {
      data: {
        empNo: profile.EMP_NO,
        taskId,
        ...(interestTaskYn === "Y" ? { useYn: "Y" } : {}),
      },
      headers: {
        "Content-Type": "application/json",
      },
    }
    );
    if (!addInterestTask.error && assignLookup.taskData.taskType === "DIY") {
      const empNo = assignLookup.taskData.taskMemberList.find((v) => v.mgrDevGbn === "M" && v.deputyHeadYn === "N")?.empNo;
      if (interestTaskYn === "Y") {
        await empPoint("PT08", empNo);
      } else {
        await empPoint("PT09", empNo);
      }
    }
    dispatch(setTaskData({
      interestTaskYn: interestTaskYn,
      interestCnt: addInterestTask.data
    }));
  }

  /* 과제명 복사 기능 */
  const onClickCopyNm = () => {
    copyText(assignLookup.taskData?.taskNm);
    clearTimeout(copyNmTimeout.current);
    setVisibleCopyNm(true);
    copyNmTimeout.current = setTimeout(() => {
      setVisibleCopyNm(false);
    }, 1000);
  };

  /* 과제 ID 복사 기능 */
  const onClickCopyId = () => {
    copyText(assignLookup.taskData?.taskId);
    clearTimeout(copyIdTimeout.current);
    setVisibleCopyId(true);
    copyIdTimeout.current = setTimeout(() => {
      setVisibleCopyId(false);
    }, 1000);
  };

  const [ellipsisStatus, setEllipsisStatus] = useState();

  return (
    <div className="assign_lookup__task_info">
      <div className="task_info__top_box">
        <Button
          type="primary"
          width="87"
          height="24"
          onClick={() =>
            history.push(`/assign/list${listSearch.trim().length ? "?" + listSearch : ""}`)
          }
        >
          <FileTextOutlined />
          <FormattedMessage id="T0052" />{/* 과제 목록 */}
        </Button>
      </div>
      <FormTable>
        <colgroup>
          <col width="8.375%" />
          <col width="16.625%" />
          <col width="7.375%" />
          <col width="17.625%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text style={{ fontSize: 18 }}>{taskNm}</Text>{/* 과제명 */}
            </th>
            <td colSpan={3}>
              <div style={{ display: "flex", gap: 30 }}>
                <div>
                  <Text style={{ fontSize: 18, wordBreak: "break-all" }}>
                    {assignLookup.taskData?.taskNm} &nbsp;
                  </Text>
                  <Tooltip title={formatMessage({ id: "T0460" })} open={visibleCopyNm}>{/* 복사되었습니다. */}
                    <CopyIcon
                      className=""
                      onClick={onClickCopyNm}
                      color="#333"
                    />
                  </Tooltip>
                </div>
                <div className="task_info__tool_box">
                  <div className="readcnt_box">
                    <EyeIcon className="readcnd_box__icon" />
                    <Text className="readcnt_count">
                      {assignLookup.taskData?.readCnt > 99
                        ? "+99"
                        : assignLookup.taskData?.readCnt}
                    </Text>
                  </div>
                  {assignLookup.taskData?.statusCd === "T40" && (
                    <div className="interest_box">
                      {assignLookup.taskData?.interestTaskYn === "Y" ? (
                        <HeartFilled
                          className="interest_box__icon"
                          onClick={() =>
                            onClickHeart(assignLookup.taskData?.taskId)
                          }
                        />
                      ) : (
                        <HeartOutlined
                          className="interest_empty_box__icon"
                          onClick={() =>
                            onClickHeart(assignLookup.taskData?.taskId)
                          }
                        />
                      )}
                      <Text className="interest_count">
                        {assignLookup.taskData?.interestCnt > 99
                          ? "+99"
                          : assignLookup.taskData?.interestCnt}
                      </Text>
                    </div>
                  )}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text>{taskId}</Text>
            </th>
            <td>
              <Text>{assignLookup.taskData?.taskId}</Text>
              <Tooltip title={formatMessage({ id: "T0460" })} open={visibleCopyId}>{/* 복사되었습니다. */}
                <CopyIcon
                  className="task_name__copy"
                  onClick={onClickCopyId}
                  color="#848484"
                  style={{ paddingLeft: 8 }}
                />
              </Tooltip>
            </td>
            <th>
              <Text><FormattedMessage id="T1095" /></Text>{/* 과제 버전 */}
            </th>
            <td>
              <Text style={{ fontWeight: "bold" }}>
                {assignLookup.taskData?.taskVersion}
              </Text>
              {/* `(내가 공유받은 버전 : ${assignLookup.taskData.sharedTaskVersion})` */}
              <Text>
                {assignLookup.taskData?.sharedTaskVersion
                  ? `(${fmMessage({
                    id: "T0461",
                    values: {
                      version: assignLookup.taskData.sharedTaskVersion,
                    },
                  })})`
                  : ""}
              </Text>
            </td>
          </tr>
          <tr>
            <th>
              <Text>{taskWorkId}</Text>
            </th>
            <td>
              <Text>{assignLookup.taskData?.workGbnNm}</Text>
            </td>
            <th>
              <Text>{taskType}</Text>
            </th>
            <td>
              <Text>{`${{
                DIY: `${formatMessage({ id: "T1093" })}`, // 직접개발
                COE: `${formatMessage({ id: "T1091" })}`, // 개발의뢰
                EDU: `${formatMessage({ id: "T1094" })}` // 교육과제
              }[
                assignLookup.taskData?.taskType
              ]
                }(${assignLookup.taskData?.taskType === "DIY" ? "DIY " : assignLookup.taskData?.taskType})`}</Text>
            </td>
          </tr>
          <tr style={{ height: 70 }}>
            <th>
              <Text>{formatMessage({ id: "T0430" })}</Text>{/* 과제 설명 */}
            </th>
            <td colSpan={5}>
              <div className="flex justify-between ellipsis_box">
                <Paragraph 
                  className="font_14 ellipsis_text"
                  ellipsis={desEllipsis ? { 
                    rows: 3,
                    onEllipsis: (event) => setEllipsisStatus(event)
                  } : false}
                >
                  {
                    assignLookup.taskData?.taskDesc
                      ?.split("\n")
                      .map((line) => {
                        return (
                          <>
                            {line}
                            <br />
                          </>
                        );
                    })
                    }
                </Paragraph>
                {
                  // ellpsisStatus가 true일때만 더보기 or 접기 버튼 보여주기
                  ellipsisStatus
                  &&
                    <div className="ellipsis_button_box">
                    { desEllipsis
                      ? 
                      <span
                        className="font_14 ellipsis_more"
                        onClick={() => setDesEllipsis(false)}
                      >
                          &lt;{formatMessage({ id: "T0096" })}{/* 더보기 */}
                      </span>
                      :
                      <span
                        className="font_14 ellipsis_more"
                        onClick={() => setDesEllipsis(true)}
                      >
                        &lt;{formatMessage({ id: "T0462" })}{/* 접기 */}
                      </span>
                      }
                    </div>
                  }
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text style={{ gap: 10, alignItems: "center" }}>
                {formatMessage({ id: "T0463" })}{/* 평가 결과 */}
              </Text>
            </th>
            <td colSpan={5}>
              <div className="task_info_table_task_result">
                <div className="container">
                  <Text className="title"><FormattedMessage id="T1099" /></Text>{/* FTE */}
                  <Text className="content_fte">
                    {(+assignLookup.taskData?.fte).toLocaleString()}
                  </Text>
                </div>
                <div className="container">
                  <Text className="title">
                    {formatMessage({ id: "T0464" })}{/* 연간 절감 시간 */}
                  </Text>
                  <Text className="content">
                    {(+assignLookup.taskData
                      ?.annualSavingsTime).toLocaleString()}
                    H
                  </Text>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </FormTable>
    </div>
  );
};

export default withRouter(TaskInfo);