import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import moment from "moment";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import { decodeHTMLEntites } from "utils/decodeHTMLEntites";
import { setVisible } from "store/loading/action";
import {  setCommunityCondition } from 'store/community/action';
import {
  PageTemplate,
  Header,
  Footer,
  Modal,
  Text,
} from "components";
import Container from "containers/Container";
import CommunityBottom from "./CommunityBottom";
import CommunityTop from "./CommunityTop";
import Wrapper from "./Styled";

const CommunityLookup = ({
  profile,
  match: { params },
  history,
}) => {
  const boardMaCondition = useSelector(
    (state) => state.get(getUniqueKey("community")).boardMaCondition
  );

  const { formatMessage } = useIntl();
  const confirmationText = formatMessage({ id: 'T0064' }); // 확인
  const commentUpdateSuccess = formatMessage({ id: 'T0206' });
  const commentDelSuccess = formatMessage({ id: 'T0207' });
  const ReplyNotDelete = formatMessage({ id: 'T0208' }); // 답글이 달린 글은 삭제할 수 없습니다.
  const postDelete = formatMessage({ id: 'T0209' });

  const dispatch = useDispatch();

  const [postingInfo, setPostingInfo] = useState([]);
  const [postingFileList, setPostingFileList] = useState([]);
  // const [boardMaCondition, setBoardMaCondition] = useState({});
  const [commentList, setCommentList] = useState([]);
  const [commentInfo, setCommentInfo] = useState([]);
  const [commentSeq, setCommentSeq] = useState("");
  const [newComment, setNewComment] = useState("");
  const [userAuth, setUserAuth] = useState("");
  // const [defaultValue, setDefaultValue] = useState("");
  const [commentByte, setCommentByte] = useState(0);
  const [originByte, setOriginByte] = useState(0);
  const [isEdit, setIsEdit] = useState([]);
  const [isDeleteVisible, setIsDeleteVisible] = useState(false);
  const [isDelCommentVisible, setIsDelCommentVisible] = useState(false);

  // TextArea의 내장된 기능을 사용하면 text 'lenght'기준으로만 제한할 수 있음
  // byte 기준으로 제한하려면 따로 아래처럼 작성해야함

  // 문자를 byte 단위로 변환한 결과값
  const onCalculateByteLength = (text) => {
    return text.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length;
  }

  /* 코멘트 달기 */
  const onClickAddComment = async () => {
    if (commentInfo.content.trim().length <= 0) {
      return alert(formatMessage({ id: "T0239" })); // 공백은 입력할 수 없습니다
    }
    const leaveComment = await fetchAbsolute(
      "post",
      `/board/boardCommnetSave`,
      {
        data: {
          boardSeq: params.boardSeq,
          commentSeq: 0,
          commentContents: commentInfo.content,
          secretYn: commentInfo.secretYn,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (leaveComment.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    // 댓글 달고 byte 초기화
    setCommentByte(0);
    getCommentList();
    setCommentInfo([]);
  };

  const onClickDelComment = (value) => {
    setCommentSeq(value);
    setIsDelCommentVisible(true);
  };

  const onOkDelComment = async () => {
    setIsDelCommentVisible(false);
    const deleteComment = await fetchAbsolute(
      "delete",
      `/board/boardCommnetDel/${commentSeq}`
    );
    if (deleteComment.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    alert(commentDelSuccess);
    setPostingInfo({ ...postingInfo, commentcount: deleteComment.data });
    getCommentList();
  };

  const onChangeComment = (e) => {
    const content = e.target.value;
    const maxByte = 2000; //최대 2000바이트
    let stringByteLength = onCalculateByteLength(content);
    if (stringByteLength > maxByte) {
      return alert(formatMessage({ id: 'T1076' }));
    } else {
      // document.getElementById("nowByte").innerText = stringByteLength;
      setCommentByte(stringByteLength);
      setCommentInfo({ content });
    }
  };


  const onClickEditIcon = (index) => {
    const tmp = [...isEdit];
    tmp[index] = !tmp[index];
    setIsEdit([...tmp]);

    // 수정하기 눌렀을 때 댓글 바이트 계산해주기
    if (index !== -1) {
      const tmpList = [...commentList];
      let stringByteLength = onCalculateByteLength(tmpList[index].commentContents);
      setOriginByte(stringByteLength);
    }
  };

  /* 인덱스를 찾아서 코멘트를 변경해야해 젭알 */
  const onChangeEditComment = (e, seq) => {
    const index = commentList.findIndex((v) => v.commentSeq === seq);
    if (index !== -1) {
      const tmp = [...commentList];
      tmp[index].commentContents = e.target.value;
      const maxByte = 2000; //최대 2000바이트
      let stringByteLength = onCalculateByteLength(tmp[index].commentContents);
      if (stringByteLength > maxByte) {
        return alert(formatMessage({ id: 'T1076' }));
      } else {
        setOriginByte(stringByteLength);
      }
    }
    setNewComment(e.target.value);
  };

  // 댓글 수정 취소 버튼
  const onClickCancelEdit = (value) => {
    const tmp = [...isEdit];
    tmp[value] = !tmp;
    setIsEdit([...tmp]);

    getCommentList();
  };

  /* 댓글 수정 완료 버튼 */
  const onClickEditComment = async (seq, index) => {
    /* 빈 댓글 수정 못하게 - 수정한 댓글의 byte가 0일때 alert창 띄워줌 */
    if(originByte===0) {
      return alert(formatMessage({ id: "T0239" })); // 공백은 입력할 수 없습니다
    }

    const originComment = commentList.filter((v) => v.commentSeq === seq)[0]
      .commentContents;

    const leaveComment = await fetchAbsolute(
      "post",
      `/board/boardCommnetSave`,
      {
        data: {
          boardSeq: params.boardSeq,
          commentSeq: seq,
          commentContents: newComment ? newComment : originComment,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (leaveComment.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    } else {
      const tmp = [...isEdit];
      tmp[index] = !tmp[index];
      setIsEdit([...tmp]);
      setCommentInfo([]);
      setNewComment("");
    }

    getCommentList()
    return alert(commentUpdateSuccess);;
  };

  const onClickBacktoList = () => {
    history.push(`/community/${params.boardId}/list`);
  };

  /* 게시글 추천 + */
  const onClickAddRecPosting = async (e) => {
    const result = await fetchAbsolute(
      "get",
      `/board/boardRcmdcountSave?boardSeq=${params.boardSeq}`
    );
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setPostingInfo({ ...postingInfo, rcmdcount: result.data, rcmdYn: "Y" });
  };

  /* 게시글 추천 - */
  const onClickCancelRecPosting = async (e) => {
    const result = await fetchAbsolute(
      "get",
      `/board/boardRcmdcountDel?boardSeq=${params.boardSeq}`
    );
    if (result.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    }
    setPostingInfo({ ...postingInfo, rcmdcount: result.data, rcmdYn: "N" });
  };

  const onClickDelete = () => {
    setIsDeleteVisible(true);
  };

  const onOkDelete = async () => {
    setIsDeleteVisible(false);
    // 모든 글은 삭제 할 수 있음
    // if (postingInfo.replyYn === "N") return alert(ReplyNotDelete);

    const deleteBoard = await fetchAbsolute(
      "delete",
      `/board/boardDel/${params.boardSeq}`
    );
    if (deleteBoard.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    } else {
      alert(postDelete);
      return  history.push(`/community/${params.boardId}/list`);
    }
  };

  const getCommentList = async () => {
    const result = await fetchAbsolute(
      "get",
      `/board/boardInfo?boardSeq=${params.boardSeq}`
    );
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    const { data } = result;
    setCommentList([...data.commentList]);
  };

  const getConditions = async () => {
    const result = await fetchAbsolute(
      "get",
      `/board/boardMaInfo?boardId=${params.boardId}`
    );

    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

    //URL로 접속시, 데이터 바로 가져올 수 있게
    dispatch(setCommunityCondition({ ...result.data.boardMa }));
    // setBoardMaCondition({ ...result.data.boardMa });
  }

  const getDetailsFirst = async () => {
    dispatch(setVisible(true));
    const result = await fetchAbsolute(
      "get",
      `/board/boardInfo?boardSeq=${params.boardSeq}`
    );
    dispatch(setVisible(false));

    // URL로 비밀글 들어왔을 경우에
    if (result.error) {
      alert(formatMessage({ id: 'T0210' })); // 접근 권한이 없습니다. 
    };

    let convert = "";
    if (result.data.board.boardContents) {
      convert = decodeHTMLEntites(result.data.board?.boardContents);
    }

    setPostingInfo({
      ...result.data.board,
      boardContents: convert, // decoded된 게시글 내용
      regTimestamp: moment(result.data.board.regTimestamp).format(
        "YYYY.MM.DD"
      )
    });
    setPostingFileList([...result.data.fileList]);
    setCommentList([...result.data.commentList]);
    const tmpEditArr = Array.from({length:result.data.commentList.length}, ()=>false)
    setIsEdit([...tmpEditArr]);
  };

  // 접속하면 모든 데이터 세팅
  useEffect(() => {
    if(!profile.EMP_NO) return;
    /* 권한  */
    setUserAuth(profile.DWP_USER_LEVEL);

    /* Y N 유무 때문에 master board list 불러옴 */
    // setBoardMaCondition(history.location.state.boardMaCondition);

    getConditions();
    getDetailsFirst();
  }, [params.boardSeq]);

  if (!boardMaCondition?.boardNm) {
    return (
      <div></div>
    )
  }

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      headerColor="none"
      menuTitle={`${boardMaCondition?.boardNm} ${formatMessage({ id: 'T1077' })}`}
      depthList={[`${formatMessage({ id: 'T0387' })}`, `${boardMaCondition?.boardNm}`, `${formatMessage({ id: 'T1077' })}`]}
    >
      <Container
        style={{ display: "flex", flexDirection: "column", gap: "30px 0" }}
      >
        <Wrapper>
          <CommunityTop
            postingInfo={postingInfo}
            postingFileList={postingFileList}
            onClickAddRec={onClickAddRecPosting}
            onClickCancelRec={onClickCancelRecPosting}
            onClickAddComment={onClickAddComment}
            commentInfo={commentInfo}
            onClickDelete={onClickDelete}
            // onClickEditDetail={onClickEditDetail}
            boardMaCondition={boardMaCondition}
            userAuth={userAuth}
            profile={profile}
            onClickBacktoList={onClickBacktoList}
          />
          {/* 댓글 입력창 및 댓글들 */}
          {boardMaCondition?.commentYn === "Y" && (
            <CommunityBottom
              // postingFileList={postingFileList}
              profile={profile}
              commentInfo={commentInfo}
              commentList={commentList}
              onClickDelComment={onClickDelComment}
              onClickEditComment={onClickEditComment}
              isEdit={isEdit}
              onClickEditIcon={onClickEditIcon}
              onChangeEditComment={onChangeEditComment}
              onChangeComment={onChangeComment}
              onClickAddComment={onClickAddComment}
              onClickCancelEdit={onClickCancelEdit}
              newComment={newComment}
              userAuth={userAuth}
              originByte={originByte}
              commentByte={commentByte}
            />
          )}
          <Modal
            centered
            visible={isDeleteVisible}
            width={540}
            okText={confirmationText}
            title={formatMessage({ id: 'T0090' })}
            onOk={onOkDelete}
            onCancel={() => setIsDeleteVisible(false)}
          >
            <div style={{ textAlign: "center", padding: "20.6px 0" }}>
              <Text>{formatMessage({ id: 'T0878' })}</Text>
            </div>
          </Modal>
          <Modal
            centered
            visible={isDelCommentVisible}
            width={400}
            okText={confirmationText}
            title={formatMessage({ id: 'T0879' })}
            onOk={onOkDelComment}
            onCancel={() => setIsDelCommentVisible(false)}
          >
            <div style={{ textAlign: "center", padding: "20.6px 0" }}>
              <Text>
                {formatMessage({ id: "T0880" })
                  .split("\\n")
                  .map((data, index) => {
                    return (
                      <span key={index}>
                        {data}
                        <br />
                      </span>
                    );
                  })}
              </Text>
            </div>
          </Modal>
        </Wrapper>
      </Container>
    </PageTemplate>
  );
};

export default CommunityLookup;