import styled from 'styled-components';

/**
 * PageTemplate 안에서 사용할 공통 스타일 컴포넌트
 */

const CommonStyled = styled.div`
  .flex {
    display: flex;
  }
  .flex-wrap-wrap {
    flex-wrap: wrap;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-end {
    justify-content: flex-end;
  }
  .justify-around {
    justify-content: space-around;
  }
  .justify-center {
    justify-content: center;
  }
  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: flex-start;
  }
  .align-end {
    align-items: flex-end;
  }
  .ant-row:not(:last-child) {
    margin-bottom:20px;
  }
  .ant-row.merge-row {
    margin-bottom:10px;
  }
  .container-spin {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100px;
  }
  .footer_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 15px;
    height: 49px;
  }
  .footer_box .footer__text {
    font-size: 12px;
    color: #7f7f8d;
  }  
  .ant-checkbox-checked::after { 
    
    border: 1px solid #ff7b00; 
    /* border: 1px solid #0067ac;  */
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: none !important;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    transition: none;
  }
  .ant-tabs-tab-btn:focus, .ant-tabs-tab-remove:focus, .ant-tabs-tab-btn:active, .ant-tabs-tab-remove:active {
    color: #000 !important;
  } 
  input.ant-input:hover {
    border-color: #ff7b00;
    /* border-color:#0067ac; */
  }  
  input.ant-input:focus, ant-input-focused {
    border-color: #ff7b00 !important;
    /* border-color: #0067ac !important; */
  }  
  .ant-select-selector:hover {
    border-color: #ff7b00 !important;
    /* border-color: #0067ac !important; */
  } 
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
    border-color: #FF7B00 !important;
    /* border-color: #0067ac !important; */
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent;
  }
`;

export default CommonStyled;