import React from "react";
import { Button, Text, Image } from "components";
import Wrapper from "./Styled";

import ReturnToTop from "../AcademyImg/Return to top.png";
import Image1 from "../AcademyImg/Padawan.png";
import Image2_1 from "../AcademyImg/padawan_image2_1.png";
import Image2_2 from "../AcademyImg/padawan_image2_2.png";
import Image4 from "../AcademyImg/padawan_image4.png";

import Banner1 from "../AcademyImg/banner1.png";
import Banner2 from "../AcademyImg/banner2.png";
import Banner3 from "../AcademyImg/banner3.png";
import Banner4 from "../AcademyImg/banner4.png";
import BannerArrow from "../AcademyImg/banner_arrow.png";
import { useIntl } from "react-intl";
const Information = () => {
  const { formatMessage } = useIntl();

  const onClickReturntoTop = () => {
    document.documentElement.scrollTop = 0;
  };
  const onClickButton = () => {
    window.open("http://skhu.skhynix.com/");
  };
  return (
    <>
      <Wrapper style={{ width: 1800 }}>
        <div className="section section1">
          <div className="section_box">
            <Image alt="section1 img" src={Image1} />
            <div className="section_text__box">
              <p className="padawan__title">{formatMessage({ id: 'T0934' })}</p>{/* Padawan (파다완) */}
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">{formatMessage({ id: 'T0909' })}</p>{/* 이야기 */}
                <p className="section_text__content">
                  {formatMessage({ id: 'T0935' })}{/* 악성업무 군단과의 끝없는 전투 현장을 목격하며 RPA 실습을
게을리하지 않던 Jedi 견습생들은 이제 제법 능숙히 광선검을

다룰수 있게 되었다. 이제 그들은 보조 역할을 하던 견습생
신분에서 벗어나 직접 전장에 뛰어들어고자 한다. Dreago Academy는
이런 그들에게 전투원의 상징인 망토와 전투용 광선검을 부여하여
승리를 기원하며 전장에 투입하기로 결정한다. */}
                </p>
              </p>
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">{formatMessage({ id: 'T0906' })}</p>{/* 소개 */}
                <p className="section_text__content">
                  {formatMessage({ id: 'T0936' })}{/* Padawan은 RPA 중급 교육을 이수한 구성원들에게 주어지는 계급입니다. RPA 주요 Command 및 Hynix Package를 눙숙하게 다룰 수 있으며, 여러 어플리케이션을 활용하여 고급 자동화 업무를 수행합니다. */}
                </p>
              </p>
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">
                  {formatMessage({ id: 'T0937' })}{/* Padawan이 되기 위해서는 다음과 같은 조건을 충족해야 합니다. */}
                </p>
                <p className="section_text__content section_text__list">
                  <ul>
                    <li>{formatMessage({ id: 'T0938' })}</li>{/* Youngling 혹은 코딩 경험이 있는 RPA 입문자 */}
                    <li>{formatMessage({ id: 'T0939' })}</li>{/* RPA 중급교육 이수 완료(사내집합교육) */}
                    <li>{formatMessage({ id: 'T0940' })}</li>{/* 여러 어플리케이션을 활용한 RPA 개발 역량 */}
                  </ul>
                </p>
              </p>
              <Button
                className="section__button"
                type="primary"
                onClick={onClickButton}
              >
                {formatMessage({ id: 'T0111' })}{/* Padawan으로 전직할 때가 된것 같군! */}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <div className="section section2">
          <p className="padawan__title">
            {formatMessage({ id: 'T0941' })}{/* Padawan은 다음과 같은 역량을 갖추게 됩니다 */}
          </p>
          <div className="section_content__wrap">
            <div className="section_content__box">
              <Image alt="Padawan section2 img" src={Image2_1} />
              <div className="section_text__box">
                <p className="section_text__title">
                  {formatMessage({ id: 'T0942' })}{/* 다양한 어플리케이션을 연동한 효율적인 과제 개발 */}
                </p>
                <p className="section_text__content">
                  {formatMessage({ id: 'T0943' })}{/* 타 어플리케이션과의 연동을 통해 효율적인 자동화 업무와 다양한
환경에서의

자동화 업무를 개발하여 확장성 높은 업무를 구현하기 시작합니다 */}
                </p>
              </div>
            </div>
            <div className="section_content__box">
              <Image
                alt="Padawan section2 img"
                src={Image2_2}
                style={{ marginTop: -90 }}
              />
              <div className="section_text__box">
                <p className="section_text__title">
                  {formatMessage({ id: 'T0944' })}{/* 커맨드의 조합으로 심화된 자동화 과제 개발 */}
                </p>
                <p className="section_text__content">
                  {formatMessage({ id: "T0945" })
                    .split("\n")
                    .map((data) => {
                      return (
                        <span>
                          {data}
                          <br />
                        </span>
                      );
                    })}{/* 주요 커맨드를 사용 할 수 있으며 커맨드의 조합을 통해
다양한 유형의 자동화 업무를 개발 할 수 있습니다 */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section section3">
          <p className="padawan__title">
            {formatMessage({ id: 'T0946' })}{/* Padawan이 되기 위해서는 다음과 같은 교육과정을 수료해야 합니다. */}
          </p>
          <div className="section_banner__wrap">
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner1}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0947' })}{/* Hynix Framework

구조 및 활용법 학습 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner2}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0948' })}{/* 기본/심화

Command 학습 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner3}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0949' })}{/* 시나리오

과제 수행 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner4}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0950' })}{/* Load Balancer
구조 이해 및 활용법 학습 */}
              </div>
            </div>
          </div>
        </div>
        <div className="section section4">
          <Image alt='Green Light saber' src={Image4} />
          <p className="section_text__content">
            {formatMessage({ id: "T0951" })
              .split("\n")
              .map((data) => {
                return (
                  <span>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 이제 준비기간은 끝났습니다.

이제 당신이 직접 악성업무 군단과 맞설 차례입니다.


늦어가는 퇴근시간을 막아주세요.

 */}
          </p>
          <Button
            className="section__button"
            type="primary"
            onClick={onClickButton}
          >
            {formatMessage({ id: 'T0111' })}{/* Padawan으로 전직할 때가 된것 같군! */}
          </Button>
        </div>
        <div className="section section5">
          <a onClick={onClickReturntoTop} href="#!">
            <Image alt='go to top' src={ReturnToTop} />
            <Text className="academy_return_to_top">{formatMessage({ id: 'T0905' })}</Text>{/* 위로가기 */}
          </a>
        </div>
      </Wrapper>
    </>
  );
};

export default Information;
