import React from "react";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedId } from "store/menuList/action";
import { getUniqueKey } from "utils/Str";

const MenuList = () => {
    const dispatch = useDispatch();

    const allList = useSelector((state) => state.get(getUniqueKey("menuList")).menuList);
    const profile = useSelector(state => state.get('auth').get('profile'));
    const selectedMenuId = useSelector((state) => state.get(getUniqueKey("menuList")).selectedMenuId);
    
    const onClickMainMenu = (e, menu) => {
        if(menu.menuUrl === null || menu.menuUrl === "") {
            e.preventDefault();
        } else {
            dispatch(setSelectedId(menu.menuId));
            return null
        }
    }

    return (
        allList.sort((a, b) => a.menuOrder - b.menuOrder)?.filter(v => v.displayYn !== 'N').map((menu, index) => (
        <li
            className={`menu_main__menu ${menu.subMenu?.length > 0 
                ? "menu_main__menu_hover" 
                : ""}`
            }
            key={index}
        >
            {menu.menuType === 'IFRM' 
            ? (
                <NavLink
                    onClick={(e)=>onClickMainMenu(e,menu)}
                    key={menu.menuId}
                    className={menu.menuId===selectedMenuId?"menu_main__text select_menu":"menu_main__text"}
                    activeClassName="select_menu"
                    to={menu.menuUrl === null || menu.menuUrl === "" 
                        ? "/notfound" 
                        : `/iframe/${menu.menuId}`
                    }
                >
                    {menu.menuNm}
                </NavLink>
            ) 
            : menu.menuType === 'LINK' 
                ? (
                    <a 
                        key={menu.menuId} 
                        className="menu_main__text" href={menu.menuUrl}
                        onClick={(e)=>onClickMainMenu(e,menu)}
                    >
                        {menu.menuNm}
                    </a>
                ) 
                : (
                    <NavLink
                        onClick={(e)=>onClickMainMenu(e,menu)}
                        key={menu.menuId}
                        className={menu.menuId===selectedMenuId?"menu_main__text select_menu":"menu_main__text"}
                        activeClassName="select_menu"
                        to={menu.menuUrl === null || menu.menuUrl === "" ? "/notfound" : menu.menuUrl}
                    >
                        {menu.menuNm}
                    </NavLink>
                )
            }
            {menu.subMenu?.length > 0 && (
            <ul
                className="menu_sub__box"
                ref={(ref) => {
                if (ref && ref.parentElement) {
                    const left = ref.parentElement.offsetLeft;
                    ref.style.paddingLeft = `${left}px`;
                }
                }}
            >
                {menu.subMenu
                    .sort((a, b) => a.menuOrder - b.menuOrder)
                    ?.filter(submenu => 
                        submenu.menuUrl !== '/admin/jediitem/roundlist' 
                        || (submenu.menuUrl === '/admin/jediitem/roundlist' 
                        && profile.companyCd === '1000'))
                    .map((submenu, index) => (
                        <li 
                            className="menu_sub__menu" 
                            key={index}
                        >
                            {
                            submenu.menuType === 'IFRM' ? (
                                <NavLink
                                    key={submenu.menuId}
                                    className="menu_sub__text"
                                    activeClassName="select_menu"
                                    onClick={() => {
                                        dispatch(setSelectedId(submenu.parentId));
                                    }}
                                    to={submenu.menuUrl === null ? "/notfound" : `/iframe/${submenu.menuId}`}
                                >{submenu.menuNm}
                                </NavLink>
                            ) : submenu.menuType === 'LINK' ? (
                                <a
                                    className="menu_sub__text"
                                    href={submenu.menuUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        dispatch(setSelectedId(submenu.parentId));
                                    }}
                                >
                                    {submenu.menuNm}
                                </a>
                            ) : (
                                <NavLink
                                    className="menu_sub__text"
                                    activeClassName="select_menu"
                                    onClick={() => {
                                        dispatch(setSelectedId(submenu.parentId));
                                    }}
                                    to={submenu.menuUrl === null? "/notfound" : submenu.menuUrl}
                                >
                                    {submenu.menuNm}
                                </NavLink>
                            )
                            }
                        </li>
                    ))
                }
            </ul>
            )}
        </li>
        ))
    )
}

export default MenuList;