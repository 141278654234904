import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import {
    Checkbox,
    Button
} from "components";
import { FormattedMessage } from "react-intl";
const { Title, Text } = Typography;

const Wrapper = styled.div`
    width:100%;
    padding: 34px 80px 30px 80px;
    border:1px solid #d0d1d6;
    border-radius: 10px;
    background: #fff;
    .title_constainer {
        display: flex;
        gap: 10px;
    } 
    /* 우리 */
    /* .ant-input:hover {
        border-color: #0067ac; 
    }
    input.ant-input:focus, ant-input-focused {
        border-color: #0067ac !important;
    }  
    .ant-select-selector:hover {
        border-color: #0067ac !important; 
    } 
    .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
        border-color: #0067ac !important;
    }  
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #0067ac !important;
    }   
    .ant-upload-list-item-name {
        color: #0067ac !important;
    }
    textarea.ant-input:focus, ant-input-focused {
        border-color: #0067ac !important; 
    } */

    /* 기존 */
    .ant-input:hover {
        border-color: #ff7b00; 
    }
    input.ant-input:focus, ant-input-focused {
        border-color: #ff7b00 !important;
    }  
    .ant-select-selector:hover {
        border-color: #ff7b00 !important; 
    } 
    .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
        border-color: #FF7B00 !important;
    }  
    .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
        border-color: #ff7b00 !important;
    }   
    .ant-upload-list-item-name {
        color: #FF7A00 !important;
    }
    textarea.ant-input:focus, ant-input-focused {
        border-color: #ff7b00 !important; 
    }
`

const AssignEnterBox = ({ title, subTitle, children, style, useCheck, onClickCheck, checked, type, onClickButton}) => {
    return (
        <Wrapper style={{ ...style }}>
            <div className='title_constainer'>
                {useCheck &&
                    <Checkbox onChange={onClickCheck} checked={checked} />
                }
                <Title style={{ marginBottom: '0.5em', fontSize: 18, fontWeight: 'bold', lineHeight: 1.38 }} level={4}>{title}</Title>
                {
                    type==='bpdLookup' 
                    && 
                    <Button
                    type="primary"
                    style={{ marginLeft:"30px", borderRadius: "4px"}}
                    onClick={onClickButton}
                    >
                        <FormattedMessage id="T1650" />{/* 크게 보기 */}
                    </Button>
                }
                <Text style={{ alignSelf: 'center' }}>{subTitle}</Text>
            </div>
            <div>
                {children}
            </div>
        </Wrapper>
    )
};

export default AssignEnterBox;