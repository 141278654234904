import styled from "styled-components";
import Modal from "../Modal";

const Wrapper = styled(Modal)`
  width: 550px;
  /* checkbox */
  /* 우리카드 */
  /* .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #0067ac;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #0067ac;
    border-color: #0067ac;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #0067ac;
  } 
  .ant-tree-checkbox-checked::after {
    border: 1px solid #0067ac;
  } */
  /* 기존 */
  .ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox:hover .ant-tree-checkbox-inner,
  .ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
    border-color: #ff7b00;
  }
  .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
    background-color: #ff7b00;
    border-color: #ff7b00;
  }
  .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
    background-color: #ff7b00;
  } 
  .ant-tree-checkbox-checked::after {
    border: 1px solid #ff7b00;
  }
  .device_pool_title_text {
    font-size: 14px;
    font-weight: bold;
  }
  .device_pool_box {
    display: flex;
    border: 1px solid #d2d3d7;
    background: #fff;
    padding: 20px 44px;
    gap: 10px;
    overflow-y: scroll;
    height: 200px;
  } 
  .device_pool_title {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .ant-input {
    font-size: 14px;
  } 
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: #ffe1bf !important;
  }
  /* .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: rgb(0, 103, 172, 0.2) !important;
  } */
`;

export default Wrapper;
