import React, { useEffect, useState, useRef } from 'react';
import styled from "styled-components";
import { LockIcon } from 'icons';
import { Input, Button, Text } from 'components';
import backgroundImg from './images/loginBg.png';
import fetchAbsolute from 'utils/fetchAbsolute';
import { removeCookie } from 'utils/cookie';
import { useDispatch } from 'react-redux';
import { resetAuth } from './action';
import { setVisible } from 'store/loading/action';

/**
 * 메일받고나서 비밀번호 초기화 시키는 페이지
 * 
 */

const Wrapper = styled.div` 
    height: 100vh;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${backgroundImg}); 
    display: flex; 
    .form {
        display: flex;
        place-self: center;
        background: #fff;
        align-items: center;
        justify-content: center;
        width: 700px;
        height: 700px; 
        margin: 0 auto; 
        box-shadow: 0 0 2px rgb(0 0 0 / 19%), 0 7px 12px rgb(0 0 0 / 20%);
    }
    .form .no_longer_exist {
        display: flex;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
    .reset_section {
        display: flex;
        flex-direction: column;
        gap: 20px;
        justify-content: space-between;
    } 
    .reset_section span.anticon {
        padding-bottom: 20px;
    }
    .text_secotion {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .text_secotion .title {
        font-size: 20px;
        font-weight: bold;
    }
    .text_secotion .content {
        font-size: 16px;
    }
    .input__section {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }
    .input__section .ant-input {
        width: 480px;
        height: 48px !important;
        font-size: 16px;
    } 
    .input__section .check { 
        display: flex;
        flex-direction: column;
        gap: 10px; 
    }
    .btn__section .form_btn {
        width: 480px;
        height: 48px;
        font-size: 20px; 
    }
    /* input 창 css */ 
    .ant-input:hover {
        border-color: #ff7b00; 
    }
    input.ant-input:focus, ant-input-focused {
    border-color: #ff7b00 !important;
    }   
    .new_password_section { 
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .new_password_section span {
        font-size: 13px; 
    }
`;

const initInputValues = {
    firstPassword: '',
    secondPassword: ''
};

const ResetPassword = ({ location, history }) => {
    const dispatch = useDispatch();

    const [inputValues, setInputValues] = useState({ ...initInputValues });

    const [pwCheck, setPwCheck] = useState('');
    const [isValidToken, setIsValidToken] = useState(false);
    const [loadingPage, setLoadingPage] = useState(true);

    // token 값 : search에 ?token= 7자 삭제
    const token = location.search?.substr(7);

    const setLoading = async (cb) => {
        dispatch(setVisible(true));
        await cb();
        dispatch(setVisible(false));
    }

    const requireFields = {
        firstPassword: {
            ref: useRef(null),
            // message: `${formatMessage({ id: 'T1258' })}`, // 새 비밀번호
            message: `새 비밀번호`
        },
        secondPassword: {
            ref: useRef(null),
            // message: `${formatMessage({ id: 'T1260' })}`, // 새 비밀번호 확인
            message: `새 비밀번호 확인`
        }
    };

    const onChangeFirstPassword = (e) => {
        setInputValues({ ...inputValues, firstPassword: e.target.value });
    };

    const onChangeRePassword = (e) => {
        setInputValues({ ...inputValues, secondPassword: e.target.value });
        if (inputValues.firstPassword !== e.target.value) {
            // setPwCheck(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // 비밀번호가 일치하지 않습니다.
            setPwCheck('비밀번호가 일치하지 않습니다.');
        } else {
            setPwCheck('');
        };
    };

    /* 비밀번호 재설정하기 버튼 */
    const onClickResetPassword = async () => {
        // 빈칸검사
        for (const key in requireFields) {
            const field = requireFields[key];
            if (!inputValues[key] && Object.keys(inputValues[key]).length <= 0) {
                field.ref.current.focus();
                return alert(`${field.message} 항목은 필수 입력입니다.`);
                // return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                //`${field.message} 항목은 필수 입력입니다.`);
            }
        }
        // 새 비밀번호 일치 검사
        if (inputValues.firstPassword !== inputValues.secondPassword) {
            // alert(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // '비밀번호가 일치하지 않습니다'
            alert(`비밀번호가 일치하지 않습니다`); // '비밀번호가 일치하지 않습니다'
        }
        // 토큰 검사 API 호출
        // token은 location에서 가지고와서 ?token= 이렇게 7자리 빼고 추출
        const result = await fetchAbsolute("put", `/login/pwdReset`, {
            data: {
                token: token,
                newPassword: inputValues.secondPassword,
            },
            headers: {
                "Content-Type": "application/json",
            },
        });
        if (result.error || result.data.result === 'fail') {
            history.push(`/resetpassword?token=${token}`);
            return alert(result.data.message)
        } else {
            // 초기화 버튼이 성공하면, 로그아웃 상태가 되어야 함 안그러면 메인으로 넘어감
            removeCookie('auth');
            dispatch(resetAuth());
            history.push("/signin");
            return alert(result.data.message)
            // return alert('작업을 완료하였습니다.'); // T0162
        }
    };

    // 첫 페이지 왔을 때 실행되는 토큰 검사
    const pwdToeknCheck = async () => {
        setLoadingPage(true);
        const result = await fetchAbsolute('get', `/login/pwdTokenCheck?token=${token}`);
        if (result.error) {
            setIsValidToken(false);
            return alert('Error');
        }
        setIsValidToken(true);
        setLoadingPage(false);
    }

    useEffect(() => {
        setLoading(pwdToeknCheck);
        return () => setLoadingPage(false);
    }, [location]);

    return (
        <Wrapper>
            <div className='form'>
                <div className='reset_section'>
                    <LockIcon />
                    {
                        !isValidToken ?
                            <div className='no_longer_exist'>
                                <span>
                                    {/* {formatMessage({ id: "T1273" })
                                        .split("\n")
                                        .map((data, index) => {
                                            return (
                                                <span key={index}>
                                                    {data}
                                                    <br />
                                                </span>
                                            );
                                        })} */}
                                    죄송합니다.<br />
                                    이 링크는 더 이상 유효하지 않습니다.<br />
                                    다시 확인해주십시오.<br />
                                </span>
                            </div>
                            :
                            <>
                                <div className='text_secotion'>
                                    <Text className='title'>
                                        {/* <FormattedMessage id="T1272" /> */}
                                        비밀번호 재설정하기
                                    </Text>
                                    <Text className='content'>
                                        {/* {fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1258' }) } })} */}
                                        새 비밀번호 입력
                                    </Text>
                                </div>
                                <div className='input__section'>
                                    <div className='new_password_section'>
                                        <Input
                                            name='firstPassword'
                                            value={inputValues.firstPassword}
                                            // placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1258' }) } })} // 새 비밀번호 입력
                                            placeholder="새 비밀번호 입력" // 새 비밀번호 입력
                                            type='password'
                                            onChange={onChangeFirstPassword}
                                            innerRef={requireFields.firstPassword.ref}
                                        />
                                        <Text>
                                            {/* &#42; <FormattedMessage id="T1262" /> */}
                                            &#42; 비밀번호는 8~16자 영문 대 소문자, 숫자, 특수문자를 사용하여 구성
                                        </Text>
                                    </div>
                                    <div className='check'>
                                        <Input
                                            name='secondPassword'
                                            value={inputValues.secondPassword}
                                            // placeholder={formatMessage({ id: 'T1260' })}// 새 비밀번호를 확인
                                            placeholder="새 비밀번호를 확인"
                                            type='password'
                                            onChange={onChangeRePassword}
                                            innerRef={requireFields.secondPassword.ref}
                                        />
                                        <Text style={{ color: 'red' }}>{pwCheck}</Text>
                                    </div>
                                </div>
                                <div className='btn__section'>
                                    <Button className='form_btn' type='primary' onClick={onClickResetPassword}>
                                        {/* <FormattedMessage id="T1272" /> */}
                                        비밀번호 재설정하기
                                    </Button>
                                </div>
                            </>
                    }
                </div>
            </div>
        </Wrapper>
    )
}

export default ResetPassword;