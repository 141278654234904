import styled from "styled-components";

const Wrapper = styled.div`
  width: 1400px; 
  margin: 0px auto; 
  height: fit-content; 
  background: #fff;
  .org_container { 
    gap: 0 50px; 
    padding: 30px;
    display: flex; 
  } 
  .org_common_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    align-items: center;
  }
  .org_common_content {
    font-size: 18px;
    font-weight: bold;
  }
  /* 트리 차트 부분 */
  .org_tree_container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 338px;
    padding: 20px 0 10px 0;
  }   
  .tree_top_area {
    display: flex;
    gap: 5px;
    justify-content: space-between;
  }
  .tree_btn_area {
    display: flex;
    gap: 5px;
  }
  .org_common__box {
    display: flex; 
    border: 1px solid #000;
    flex-direction: column;
    margin-top: 10px; 
    font-size: 16px;
    font-weight: bold;
  }
  .org_common__box .ant-tree.ant-tree-icon-hide {
    width: 100%;
  }  

  /* 디테일 상세 부분 */
  .org_details__container {
    flex:2;
  }
  .ant-table-thead th {
    height: 34px;
    padding: 0px 16px;
    font-size: 12px;
  }
  th {
    text-align: center;
  } 
  .org_thead_title {
    font-size: 18px;
    font-weight: bold;
    display: flex;
    padding: 10px 0;
  }
  .org_detail__button {
    display: flex;
    align-self: flex-end;
    padding-bottom: 20px; 
    padding-right: 20px; 
    gap: 20px;
  }
`;

export default Wrapper;
