import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { PageTemplate, Header, Footer, FormTable, Input, Button, Radio, Text, WebEditor, TypePicker } from 'components';
import Wrapper from './Styled';
import moment from 'moment';
import { setVisible } from 'store/loading/action';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import { decodeHTMLEntites } from 'utils/decodeHTMLEntites';

const initialInputValues = {
    noticeSeq: 0,
    noticeTitle: "",
    noticeContents: '',
    showYn: "Y",
    fromDate: moment().format('YYYY-MM-DD'),
    fromTime: moment().format('HH:mm'),
    toDate: moment().format('YYYY-MM-DD'),
    toTime: moment().format('HH:mm'),
    noticeDateFr: moment().format('YYYY-MM-DD HH:mm:ss'),
    noticeDateTo: moment().format('YYYY-MM-DD HH:mm:ss'),
    link: "",
    popWidth: 300,
    popHeight: 300,
    leftCoordinate: 120,
    topCoordinate: 120
}

/**
 * Filename: AdminPopupEdit/index.js
 * Description: Admin -> 공지 팝업 관리 생성 및 수정 컴포넌트
 */

const AdminPopupEdit = ({ history, match: { params }, type }) => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const btnRef = useRef(null);
    const TitleRequire = formatMessage({ id: 'T0125' }); // 제목은 필수 입력입니다
    const noticeTitle = formatMessage({ id: "T0017" }); // 제목
    const [inputValues, setInputValues] = useState({ ...initialInputValues });
    const [contentType, setContentType] = useState('web');
    const [webDefaultData, setWebDefaultData] = useState('');
    const onMouseLeave = () => {
        btnRef.current.focus();
    }

    const onChangeContent = (key, value) => {
        setInputValues({
            ...inputValues,
            [key]: value
        })
    }

    const onChangeContentType = (e) => {
        const { value } = e.target;
        setWebDefaultData('');
        setContentType(value);
        setInputValues({
            ...inputValues,
            link: '',
            noticeContents: ''
        })
    }

    const onChangeTitle = (e) => {
        const { value } = e.target;
        setInputValues({
            ...inputValues,
            noticeTitle: value
        })
    }

    const onChangePopInfo = (e) => {
        const { value, dataset: { type } } = e.target;
        if (isNaN(+value)) {
            return;
        }
        setInputValues({
            ...inputValues,
            [type]: value
        });
    }

    const onChangeFromRange = (key, value) => {
        const tmpObj = { ...inputValues };
        let formatString = '';
        switch (key) {
            case 'fromDate':
                formatString = 'YYYY-MM-DD';
                break;
            case 'fromTime':
                formatString = 'HH:mm'
                break;
            default:
        }
        tmpObj[key] = value.format(formatString);
        tmpObj.noticeDateFr = `${tmpObj.fromDate} ${tmpObj.fromTime}:00`;
        setInputValues({ ...tmpObj });
    }
    const onChangeToRange = (key, value) => {
        const tmpObj = { ...inputValues };
        let formatString = '';
        switch (key) {
            case 'toDate':
                formatString = 'YYYY-MM-DD';
                break;
            case 'toTime':
                formatString = 'HH:mm'
                break;
            default:
        }
        tmpObj[key] = value.format(formatString);
        tmpObj.noticeDateTo = `${tmpObj.toDate} ${tmpObj.toTime}:00`;
        setInputValues({ ...tmpObj });
    }

    const onClickBackButton = () => {
        history.push('/admin/popup');
    }

    const onClickSubmit = async () => {
        if (inputValues.noticeTitle.trim().length === 0) {
            return alert(TitleRequire);
        }
        const tmpObj = { ...inputValues };
        delete tmpObj.fromDate;
        delete tmpObj.fromTime;
        delete tmpObj.toDate;
        delete tmpObj.toTime;
        delete tmpObj.regId;
        delete tmpObj.regTimestamp;
        delete tmpObj.modId;
        delete tmpObj.modTimestamp;

        const formData = new FormData();
        Object.entries(tmpObj).forEach(([key, value]) => {
            formData.append(key, value);
        });

        dispatch(setVisible(true));
        const result = await fetchAbsolute('post', `/admin/notice/noticeSave`, {
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        dispatch(setVisible(false));
        if (result.error) {
            return alert(formatMessage({ id: 'T0290' }));
            //'공지 팝업 등록을 실패하였습니다.');
        }
        alert(formatMessage({ id: 'T0291' }));
        //'공지 팝업 등록을 완료했습니다.');

        history.push('/admin/popup');
    }

    const onClickDelete = async () => {
        const cf = window.confirm(formatMessage({ id: 'T0324' }));
        //'정말 해당 공지 팝업을 삭제하시겠습니까?'
        if (!cf) return;
        dispatch(setVisible(true));
        const result = await fetchAbsolute('delete', `/admin/notice/noticeDelete/${inputValues.noticeSeq}`);
        dispatch(setVisible(false));
        if (result.error) {
            return alert(formatMessage({ id: 'T0292' }));
            //'공지 팝업 삭제를 실패했습니다.');
        }
        alert(formatMessage({ id: 'T0293' }));
        //'공지 팝업 삭제를 완료했습니다.');
        history.push('/admin/popup');
    }

    useEffect(() => {
        if (type === 'create') return;
        const fetchNotice = async () => {
            dispatch(setVisible(true));
            const result = await fetchAbsolute('get', `/admin/notice/noticeInfo?noticeSeq=${params.noticeSeq}`);
            dispatch(setVisible(false));
            if (result.error) {
                return history.push('/admin/popup');
            }
            const { data } = result;

            const fromDateTime = moment(data.noticeDateFr);
            const toDateTime = moment(data.noticeDateTo);

            let convert = '';
            try {
                convert = decodeHTMLEntites(data.noticeContents);
            } catch { }

            setContentType(data.noticeContents?.length > 0 ? 'web' : data.link?.length > 0 ? 'link' : 'web');
            setWebDefaultData(convert);
            setInputValues({
                ...initialInputValues,
                ...data,
                noticeContents: convert,
                noticeDateFr: fromDateTime.format('YYYY-MM-DD HH:mm:ss'),
                noticeDateTo: toDateTime.format('YYYY-MM-DD HH:mm:ss'),
                fromDate: fromDateTime.format('YYYY-MM-DD'),
                fromTime: fromDateTime.format('HH:mm'),
                toDate: toDateTime.format('YYYY-MM-DD'),
                toTime: toDateTime.format('HH:mm'),
            });
        }
        fetchNotice();
    }, []);

    return (
        <PageTemplate
            header={<Header />}
            footer={<Footer />}
            headerColor='none'
            menuTitle={type === 'create' ? `${formatMessage({ id: 'T1203' })}` // 공지 팝업 등록
                : type === 'modify' && `${formatMessage({ id: 'T1204' })}`} // 공지 팝업 수정
            depthList={[`${formatMessage({ id: 'T0881' })}`, // 관리자
            type === 'create' ? `${formatMessage({ id: 'T1203' })}` // 공지 팝업 등록
                : type === 'modify' && `${formatMessage({ id: 'T1204' })}`]} // 공지 팝업 수정
        >
            <Wrapper style={{ padding: "30px 100px" }}>
                <FormTable>
                    <colgroup>
                        <col width='200' />
                        <col />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Text required={true}>{noticeTitle}</Text>
                            </th>
                            <td>
                                <div className='popup_edit__box'>
                                    <Input
                                        style={{
                                            width: 1054,
                                            height: 48
                                        }}
                                        placeholder={formatMessage({ id: "T0646" })} // 제목을 입력하세요
                                        value={inputValues.noticeTitle}
                                        onChange={onChangeTitle}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th> {formatMessage({ id: 'T0361' })}</th>{/* 내용 */}
                            <td>
                                <div className='popup_edit__box'>
                                    <div className='popup_edit__radio_box'>
                                        <Radio
                                            defaultValue='web'
                                            renderItem={[{
                                                value: 'web',
                                                text: formatMessage({ id: "T0647" }) // 웹에디터
                                            }, {
                                                value: 'link',
                                                text: formatMessage({ id: "T0648" }) // 외부 URL 링크
                                            }]}
                                            value={contentType}
                                            onChange={onChangeContentType}
                                        />
                                        {
                                            contentType === 'link' &&
                                            <Input
                                                placeholder={`${formatMessage({ id: "T0649" })} https://naver.com`} // 외부 기사 링크 URL
                                                style={{
                                                    width: 440,
                                                    height: 40
                                                }}
                                                value={inputValues.link}
                                                onChange={(e) => onChangeContent('link', e.target.value)}
                                            />
                                        }
                                    </div>
                                    {
                                        contentType === 'web' &&
                                        <div
                                            onMouseLeave={onMouseLeave}
                                            className='popup_edit__editor'
                                        >
                                            <WebEditor
                                                onChange={(value) => onChangeContent('noticeContents', value)}
                                                defaultValue={webDefaultData}
                                                height='385'
                                            />
                                        </div>
                                    }
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>팝업 창 크기</th>
                            <td>
                                <div className='popup_edit__box flex'>
                                    <div className='popup_edit__double'>
                                        <Text className='popup_edit__double_text'>{formatMessage({ id: "T0650" })}</Text>{/* 가로: */}
                                        <div className='popup_edit__double_input'>
                                            <Input
                                                label={formatMessage({ id: "T0651" })} // 픽셀
                                                style={{
                                                    width: 100,
                                                    height: 32
                                                }}
                                                data-type='popWidth'
                                                value={inputValues.popWidth}
                                                onChange={onChangePopInfo}
                                            />
                                        </div>
                                    </div>
                                    <div className='popup_edit__double'>
                                        <Text className='popup_edit__double_text'>{formatMessage({ id: "T0652" })}</Text>{/* 세로: */}
                                        <div className='popup_edit__double_input'>
                                            <Input
                                                label={formatMessage({ id: "T0651" })} // 픽셀
                                                style={{
                                                    width: 100,
                                                    height: 32
                                                }}
                                                data-type='popHeight'
                                                value={inputValues.popHeight}
                                                onChange={onChangePopInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0653" })}</th>{/* 팝업 창 위치 */}
                            <td>
                                <div className='popup_edit__box flex'>
                                    <div className='popup_edit__double'>
                                        <Text className='popup_edit__double_text'>{formatMessage({ id: "T0654" })}</Text>{/* 상단에서: */}
                                        <div className='popup_edit__double_input'>
                                            <Input
                                                label={formatMessage({ id: "T0651" })} // 픽셀
                                                style={{
                                                    width: 100,
                                                    height: 32
                                                }}
                                                data-type='topCoordinate'
                                                value={inputValues.topCoordinate}
                                                onChange={onChangePopInfo}
                                            />
                                        </div>
                                    </div>
                                    <div className='popup_edit__double'>
                                        <Text className='popup_edit__double_text'>{formatMessage({ id: "T0655" })}</Text>{/* 좌측에서: */}
                                        <div className='popup_edit__double_input'>
                                            <Input
                                                label={formatMessage({ id: "T0651" })} // 픽셀
                                                style={{
                                                    width: 100,
                                                    height: 32
                                                }}
                                                data-type='leftCoordinate'
                                                value={inputValues.leftCoordinate}
                                                onChange={onChangePopInfo}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0656" })}</th>{/* 공지 기간 설정 */}
                            <td>
                                <div className='popup_edit__box popup_edit__box_range'>
                                    <div className='popup_edit__datetime'>
                                        <TypePicker
                                            className='popup_edit__date_picker'
                                            type='date'
                                            placeholder={formatMessage({ id: "T0657" })} // 시작 날짜 선택
                                            format='YYYY-MM-DD'
                                            value={moment(inputValues.fromDate, 'YYYY-MM-DD')}
                                            onChange={value => onChangeFromRange('fromDate', value)}
                                        />
                                        <TypePicker
                                            className='popup_edit__time_picker'
                                            type='time'
                                            placeholder={formatMessage({ id: "T0658" })} // 시작 시간 선택
                                            format='HH:mm'
                                            value={moment(inputValues.fromTime, 'HH:mm')}
                                            onChange={value => onChangeFromRange('fromTime', value)}
                                        />
                                    </div>
                                    ~
                                    <div className='popup_edit__datetime'>
                                        <TypePicker
                                            className='popup_edit__date_picker'
                                            type='date'
                                            placeholder={formatMessage({ id: "T0659" })} // 종료 날짜 선택
                                            format='YYYY-MM-DD'
                                            value={moment(inputValues.toDate, 'YYYY-MM-DD')}
                                            onChange={value => onChangeToRange('toDate', value)}
                                            disabledDate={(current) => {
                                                return moment().add(-1, 'days') >= current
                                            }}
                                        />
                                        <TypePicker
                                            className='popup_edit__time_picker'
                                            type='time'
                                            placeholder={formatMessage({ id: "T0660" })} // 종료 시간 선택
                                            format='HH:mm'
                                            value={moment(inputValues.toTime, 'HH:mm')}
                                            onChange={value => onChangeToRange('toTime', value)}
                                        />
                                    </div>
                                    <Text className='popup_edit__range_text'>{formatMessage({ id: "T0661" })}</Text>{/* 설정한 기간에 팝업이 노출 됩니다. */}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </FormTable>
                <div className='popup_edit__button_wrap'>
                    {
                        type === 'modify' &&
                        <Button
                            type='primary'
                            width='150'
                            height='40'
                            onClick={onClickDelete}
                        >
                            {formatMessage({ id: 'T0072' })}{/* 삭제 */}
                        </Button>
                    }
                    <Button
                        type='primary'
                        width='150'
                        height='40'
                        onClick={onClickSubmit}
                        innerRef={btnRef}
                    >
                        {formatMessage({ id: 'T0073' })}{/* 저장 */}
                    </Button>
                    <Button
                        type='default'
                        width='150'
                        height='40'
                        onClick={onClickBackButton}
                    >
                        {formatMessage({ id: 'T0094' })}{/* 뒤로 */}
                    </Button>

                </div>
            </Wrapper>
        </PageTemplate >
    );
}

export default AdminPopupEdit;