import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  display: flex;
  flex-direction: column;
  .container {
    border: 1px solid #333;
    padding: 30px;
  }
  th {
    font-weight: bold;
  }
  .select_box {
    width: 339px;
  } 
  .btn_company {
    display: flex;
    justify-content: flex-end;
    padding-top: 19.2px;
    gap: 10px;
  }
`;

export default Wrapper;