import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import fetchAbsolute from "utils/fetchAbsolute";
import { setListUrl } from "store/listUrl/action";
import { Collapse as AntCollapse } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl } from "react-intl";
import {
  PageTemplate,
  Header,
  Footer,
  AssignNewScheduleTop,
  AssignNewScheduleBottom,
  Collapse,
  Loading,
} from "components";
import Wrapper from "./Styled";
import moment from "moment";
import queryString from "query-string";
import { setAssignScheduleSpread } from "store/assignSchedule/action";

const { Panel } = AntCollapse;

const initialSearchQuery = {
  yyyymm: moment().format("YYYYMM"),
  dd: moment().format("DD"),
  specificDay: moment().format("YYYYMMDD"),
  calendar: "monthly",
};

const initialInputValues = {
  taskId: "",
  taskNm: "",
  taskType: {},
  workId: [],
  orgCd: [],
  mgrEmpNo: [],
  devicePool: [],
  scheduleType: [],
};

const initialObj = {
  ...initialSearchQuery,
  ...initialInputValues,
};

const AssignNewSchedule = ({ location: { search }, history }) => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  // const [searchQuery, setSearchQuery] = useState({ ...initialObj });
  const [searchQuery, setSearchQuery] = useState({
    ...initialSearchQuery,
    ...initialInputValues,
    ...initialObj
  });


  // 과제 유형 리스트
  const [taskTypeList, setTaskTypeList] = useState([]);
  const [selectedDevicePool, setSelectedDevicePool] = useState([]);
  const [calInfo, setCalInfo] = useState({})
  const [openCollapse, setOpenCollapse] = useState(true);
  const [scheduleDatas, setScheduleDatas] = useState({});
  // scheduleSearch 텍스트
  const [scheduleSearch, setScheduleSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeCollapse = () =>
    setOpenCollapse((openCollapse) => !openCollapse);

  const replaceSearch = async (searchObject) => {
    const beforeSearch = [];
    const beforeObject = {};
    const initialKeys = Object.keys(initialInputValues);
    const searchObj = { ...initialObj, ...searchObject };
    const beforeKeys = Object.keys(searchObj).filter((key) =>
      initialKeys.includes(key)
    );

    searchObj["x-environment"] = "PRD";

    // 검색해야 할 내용들 정리
    Object.keys(searchObj).forEach((key) => {
      const data = searchObj[key];
      if (!beforeKeys.includes(key)) {
        return;
      }
      if (Object.keys(data).length <= 0) {
        delete searchObj[key];
        return;
      }

      beforeObject[key] = data;

      if (["mgrEmpNo"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          beforeSearch.push(data[i].empNam);
          tmpArr.push(data[i].empNum);
        }
        searchObj[key] = [...tmpArr];
      } else if (["orgCd"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          beforeSearch.push(data[i].codeNm);
          tmpArr.push(data[i].codeId);
        }
        searchObj[key] = [...tmpArr];
      } else if (["workId"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          beforeSearch.push(data[i].cdNm);
          tmpArr.push(data[i].cd);
        }
        searchObj[key] = [...tmpArr];
      } else if (["taskType"].includes(key)) {
        if (typeof data === "string") {
          beforeSearch.push(data);
          searchObj[key] = data;
        } else {
          beforeSearch.push(data.cd);
          searchObj[key] = data.cd;
        }
      } else if (["scheduleType"].includes(key)) {
        if (typeof data === "string") {
          beforeSearch.push(data);
          searchObj[key] = [data];
        } else {
          const tmpArr = [];
          for (let i in data) {
            beforeSearch.push(data[i].value);
            tmpArr.push(data[i].value);
          }
          searchObj[key] = [...tmpArr];
        }
      } else if (["devicePool"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          if (searchObj.taskType === "COE") {
            beforeSearch.push(data[i].dpNm);
            tmpArr.push(data[i].dpId);
          } else {
            beforeSearch.push(data[i].devicePoolName);
            tmpArr.push(data[i].devicePoolId);
          }
          setSelectedDevicePool(data);
        }
        searchObj[key] = [...tmpArr];
      } else if (beforeKeys.includes(key)) {
        if (typeof data === "string") {
          beforeSearch.push(data);
        } else {
          for (let i in data) {
            beforeSearch.push(data[i]);
          }
        }
      }
    });
    setCalInfo({ ...searchObj });
    setLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/task/oracle/taskScheduleListMulVal`,
      {
        data: {
          crXEnv: searchObj["x-environment"],
          calendar: searchObj.calendar,
          dd: searchObj.dd,
          yyyymm: searchObj.yyyymm,
          taskType: searchObj.taskType,
          taskId: searchObj.taskId,
          taskNm: searchObj.taskNm,
          orgCd: searchObj.orgCd || [],
          mgrEmpNo: searchObj.mgrEmpNo || [],
          workId: searchObj.workId || [],
          devicePool: searchObj.devicePool || [],
          scheduleType: searchObj.scheduleType || [],
          specificDay: searchObj.specificDay,
        },
      }
    );
    setLoading(false);
    if (result.error) return alert(formatMessage({ id: "T0185" })); //스케줄 정보 가져오는 도중 에러가 발생했습니다. 
    setScheduleDatas({ ...result.data }); // monthly, weekly, daily 스케줄 정보

    const beforeText = beforeSearch.slice(0, 2).join(", ");

    /* beforeSearch의 기본은 COE 설정이 되어있음 */
    if (beforeSearch.length > 0) {
      localStorage.setItem("beforeSearchSchedule", beforeText);
      localStorage.setItem(
        "beforeObjectSchedule",
        JSON.stringify(beforeObject)
      );
      setScheduleSearch(beforeText);
    }

    const searchString = queryString.stringify(searchObj);
    if ("?" + searchString === search) return;
    dispatch(setListUrl(searchString));
    history.replace({ search: searchString });
  };

  // query String 변경 될 때마다
  useEffect(() => {
    if (taskTypeList.length === 0) return;
    const preSearchObject = queryString.parse(search);

    /* 시작하는 첫 화면 초기 세팅 부분 */
    if (search.length <= 0) {
      replaceSearch({
        ...initialObj,
        taskType: { ...taskTypeList.sort((a, b) => b.sort - a.sort)[0] },
      });
      // 처음 taskType 설정
      dispatch(
        setAssignScheduleSpread({
          ...searchQuery,
          taskType: { ...taskTypeList.sort((a, b) => b.sort - a.sort)[0] },
        })
      );
      return;
    }

    // search query로 데이터 세팅하는 함수
    const setDatas = async () => {
      const { taskType, workId, orgCd, mgrEmpNo, devicePool } = preSearchObject;

      // 태스크 유형 정보 세팅
      if (taskType) {
        try {
          const { data } = await fetchAbsolute(
            "get",
            `/common/commonCd/findByCategoryIdAndCd?categoryId=TASK_TYPE&cd=${taskType}`
          );
          preSearchObject.taskType = { ...data };
        } catch {
          delete preSearchObject.taskType;
        }
      }
      // 부서 정보 세팅
      if (orgCd) {
        try {
          const tmpArr = [];
          if (typeof orgCd === "string") {
            const { data } = await fetchAbsolute(
              "get",
              `/common/departmentList?orgCd=${orgCd}`
            );
            tmpArr.push(data.mdList[0]);
          } else {
            for (let i in orgCd) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/departmentList?orgCd=${orgCd[i]}`
              );
              tmpArr.push(data.mdList[0]);
            }
          }
          preSearchObject.orgCd = [...tmpArr];
        } catch {
          delete preSearchObject.orgCd;
        }
      }
      // 매니저 정보 세팅
      if (mgrEmpNo) {
        try {
          const tmpArr = [];
          if (typeof mgrEmpNo === "string") {
            const { data } = await fetchAbsolute(
              "get",
              `/common/memberNoList?empNo=${mgrEmpNo}`
            );
            tmpArr.push(data.mdFPAList[0]);
          } else {
            for (let i in mgrEmpNo) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${mgrEmpNo[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
          }
          preSearchObject.mgrEmpNo = [...tmpArr];
        } catch {
          delete preSearchObject.mgrEmpNo;
        }
      }
      // 업무 분류
      if (workId) {
        try {
          const tmpArr = [];
          if (typeof workId === "string") {
            const { data } = await fetchAbsolute(
              "get",
              `/common/workClassification?cd=${workId}`
            );
            tmpArr.push(data);
          } else {
            for (let i in workId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/workClassification?cd=${workId[i]}`
              );
              tmpArr.push(data);
            }
          }
          preSearchObject.workId = [...tmpArr];
        } catch {
          delete preSearchObject.workId;
        }
      }

      if (devicePool) {
        preSearchObject.devicePool = [...selectedDevicePool];
      }
      const tmpObj = {
        ...initialObj,
        ...preSearchObject,
      };
      setSearchQuery({ ...tmpObj });
    };
    setDatas();
  }, [search, taskTypeList]);

  useEffect(() => {
    // taskType 가져오는 함수
    const fetchTaskType = async () => {
      try {
        const result = await fetchAbsolute(
          "get",
          "/common/commonCd/getCategoryId/TASK_TYPE"
        );
        const data = [
          ...result.data?.filter((v) => v.cd !== "EDU" && v.useYn === "Y"),
        ];
        setTaskTypeList([...data]);
      } catch (err) {
        return alert(err);
      }
    };
    fetchTaskType();

    return () => {
      setLoading(false);
    }
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      {!loading ? (
        <Wrapper>
          <Collapse
            expandIconPosition="end"
            onChange={onChangeCollapse}
            expandIcon={() =>
              !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
            }
            activeKey={openCollapse ? ["1"] : []}
            defaultActiveKey={openCollapse ? ["1"] : []}
          >
            <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
              <AssignNewScheduleTop
                replaceSearch={replaceSearch}
                searchQuery={searchQuery}
                scheduleSearch={scheduleSearch}
                setScheduleSearch={setScheduleSearch}
                taskTypeList={taskTypeList}
                setSelectedDevicePool={setSelectedDevicePool}
              />
            </Panel>
          </Collapse>
          <AssignNewScheduleBottom
            replaceSearch={replaceSearch}
            searchQuery={searchQuery}
            scheduleDatas={scheduleDatas}
            initialSearchQuery={initialSearchQuery}
            calInfo={calInfo}
          />
        </Wrapper>
      ) : (
        <Loading visible={loading} />
      )}
    </PageTemplate>
  );
};

export default AssignNewSchedule;
