import React, { useEffect, useState } from "react";
import { Tree, List, Tooltip } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { arrayMoveImmutable } from "array-move";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import {
  MinusCircleFilled,
  CloseCircleFilled,
  MenuOutlined,
} from "@ant-design/icons";
import { Modal, Text, Input, Table, Button } from "components";
import styled from "styled-components";
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import { useSelector } from "react-redux";

/**
 * Filename: Organization.js
 * Description: 조직도 공통 컴포넌트이다.
 *              max Prop으로 최대 선택할 수 있는 구성원 수를 선택할 수 있다.
 */

const Wrapper = styled(Modal)`
  .row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
  }

  .row-dragging td {
    padding: 16px;
  }

  .row-dragging .drag-visible {
    visibility: visible;
  }
  .ant-table-thead th {
    height: 34px;
    padding: 0px 16px;
    font-size: 12px;
  }
  .ant-table-tbody td {
    font-size: 12px;
  }
  .orga_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .orga_section_title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }
  .orga_section_title_text {
    font-size: 14px;
    font-weight: bold;
  }
  .orga_section_reset {
    width: 68px;
    height: 24px;
    font-size: 12px;
  }
  .orga_section_box {
    display: flex;
    flex-wrap: wrap;
    border: 1px solid #d2d3d7;
    height: 470px;
    background: #fff;
    padding: 12px;
    gap: 10px;
  }
  .orga_box {
    border: 1px solid #ccccd4;
    height: 400px;
  }
  .orga_box .orga_search {
    width: 100%;
  }
  .orga_select_button {
    width: 26px;
    height: 26px;
  }
  .orga_box .orga_title {
    display: flex;
    height: 40px;
    align-items: center;
    padding: 0 12px;
    border-bottom: 1px solid #d0d1d6;
  }
  .orga_box .orga_content {
    padding: 0 12px;
    overflow-y: scroll;
    height: calc(100% - 40px);
  }
  .orga_box .orga_content .orga_content__list {
    cursor: pointer;
  }
  .orga_box .orga_content.orga_table {
    padding: 0;
  }
  .orga_section__input {
    position: relative;
    width: 100%;
  }
  .orga_section__input .input_clear {
    position: absolute;
    top: 50%;
    right: 11px;
    font-size: 12px;
    color: #00000040;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .ant-table-placeholder {
    border-bottom: none;
  }
  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    /* background-color: #ffe1bf !important; */
    background-color: rgb(0, 103, 172,0.2) !important;
  }
  input.ant-input:hover {
    border-color: #ff7b00;
  }
  input.ant-input:focus,
  ant-input-focused {
    border-color: #ff7b00 !important;
  }
`;

const DragHandle = sortableHandle(() => (
  <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const Organization = ({
  visible,
  title = "",
  onOk,
  onCancel,
  defaultData = [],
  max = -1,
  msColumn = false,
  fixedMain = false,
  authCheck = false,
}) => {
  const { formatMessage } = useIntl();
  const profile = useSelector((state) => state.get("auth").get("profile"));
  const fmMessage = useFormatMessage();
  const empNam = formatMessage({ id: "T0012" }); // 이름
  const empNum = formatMessage({ id: "T0013" }); // 사번

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      {...props}
    />
  );

  const DraggableBodyRow = (props) => {
    const index = selectMembers.findIndex(
      (member) => member.empNum === props["data-row-key"]
    );
    return <SortableItem index={index} {...props} />;
  };

  /**
   * Table 컬럼 상수 선언
   */
  const memberColumns = [
    {
      title: empNam,
      dataIndex: "empNam",
      width: 75,
    },
    {
      title: empNum,
      dataIndex: "empNum",
      width: 100,
    },
    {
      title: formatMessage({ id: "T0331" }), //직위
      dataIndex: "jtitNam",
    },
  ];
  const selectColumns = [
    {
      key: 1,
      title: formatMessage({ id: "T0332" }), //팀명,
      dataIndex: "orgNam",
    },
    {
      key: 2,
      title: empNam,
      dataIndex: "empNam",
      width: 75,
    },
    {
      key: 3,
      title: empNum,
      dataIndex: "empNum",
      width: 100,
    },
    {
      key: 4,
      title: formatMessage({ id: "T0331" }), //직위
      dataIndex: "jtitNam",
    },
    {
      key: 5,
      title: "",
      width: 30,
      render: (e, record, index) =>
        index === 0 && fixedMain ? (
          <></>
        ) : (
          <MinusCircleFilled
            onClick={() => onDeleteSelectData(e.empNum)}
          ></MinusCircleFilled>
        ),
    },
  ];
  if (msColumn) {
    selectColumns.unshift({
      key: 0,
      title: "",
      render: (text, record, index) => (index === 0 ? "Main" : "Sub"),
    });
    selectColumns.unshift({
      title: "",
      width: 30,
      className: "drag-visible",
      render: (e, record, index) =>
        fixedMain && index === 0 ? <></> : <DragHandle />,
    });
  }

  /**
   * State 변수 선언
   */
  const [allOrganDatas, setAllOrganDatas] = useState([]);
  const [organDatas, setOrganDatas] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [expand, setExpand] = useState([]);
  const [memberLists, setMemberLists] = useState([]);
  const [tblSelectMembers, setTblSelectMembers] = useState([]);
  const [selectMembers, setSelectMembers] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [searchDepartment, setSearchDepartment] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [originSelected, setOriginSelected] = useState([]);
  const onSelectList = async (codeId) => {
    setSelectedKeys([codeId]);
  };
  // 조직도 팀 클릭 시 실행하는 이벤트
  const onSelectTree = async (codeIds, e) => {
    const [codeId] = codeIds;
    setSelectedKeys([codeId]);
  };
  // 선택 버튼 클릭 시 실행하는 이벤트
  const onClickSelectButton = async () => {
    /* 여러명일 수 있어서 for문 */
    if (authCheck) {
      for (let data of tblSelectMembers) {
        if (!memberLists[data]?.empNum) {
          continue;
        }
        const userAuthInfo = await fetchAbsolute(
          "get",
          `/auth/getUserAdInfo?empNum=${memberLists[data].empNum}`
        );
        if (
          userAuthInfo.data[0]?.dwpUserLevel === "UA01" ||
          !userAuthInfo.data[0]
        ) {
          return alert(formatMessage({ id: "T1057" }));
          //`회원 등급 Observer는 개발자 등록 불가`);
        }
      }
    }
    const tmpArray = [...selectMembers];
    // 중복 선택 확인
    tblSelectMembers.forEach((v) => {
      if (
        tmpArray.findIndex(
          (tmp) => tmp.empNum.toString() === memberLists[v].empNum.toString()
        ) === -1
      ) {
        tmpArray.push({ ...memberLists[v] });
      }
    });
    if (max !== -1 && max < tmpArray.length)
      return alert(fmMessage({ id: "T0121", values: { max } }));
    //`최대 선택 인원은 ${max}명입니다.`);
    setSelectMembers([...tmpArray]);
    setOriginSelected([...tmpArray]);
    setTblSelectMembers([]);
  };
  const rowSelection = {
    selectedRowKeys: tblSelectMembers,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setTblSelectMembers([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setTblSelectMembers(selectedRowKeys);
    },
  };
  const onDeleteSelectData = (e) => {
    const tmpArr = [...selectMembers];
    setSelectMembers([...tmpArr.filter((v) => v.empNum !== e)]);
  };
  const onClickResetButton = () => {
    if (fixedMain) {
      setSelectMembers(selectMembers.slice(0, 1));
    } else {
      setSelectMembers([]);
    }
  };
  const onClickOk = () => {
    onOk(selectMembers);
  };
  const onChangeSearchKeyword = (e) => {
    setSearchKeyword(e.target.value);
  };
  const onKeyPressKeyword = (e) => {
    if (searchKeyword.trim().length < 2) {
      setVisibleTooltip(true);
      return;
    }
    setVisibleTooltip(false);
    const fetchMember = async () => {
      const nameResult = await fetchAbsolute(
        "get",
        `/common/memberNameList?empNam=${searchKeyword}`
      );
      // 23-06-05 memberNoList는 backend 로직이 변경되어, 비어있을 때 {} 빈 객체 반환이라 조건 수정
      const empNumResult = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${searchKeyword}`
      );

      let tmpArr = [];

      if (Object.keys(empNumResult.data).length > 0) {
        tmpArr = nameResult.data.mdFPAList.map((v, index) => ({ ...v, key: index })).concat(empNumResult.data.mdFPAList.map((v, index) => ({ ...v, key: index })));
      } else {
        tmpArr = nameResult.data.mdFPAList.map((v, index) => ({ ...v, key: index }))
      }
      setMemberLists([...tmpArr]);
    };
    const tmpDepartment = allOrganDatas.filter((v) =>
      v.codeNm.toLowerCase().includes(searchKeyword.toLowerCase())
    );
    fetchMember();
    setSearchDepartment([...tmpDepartment]);
    setIsSearch(true);
    setTblSelectMembers([]);
  };
  const onExpandTree = (keys) => {
    setExpand([...keys]);
  };
  const onClickSearchReset = () => {
    setVisibleTooltip(false);
    setSearchKeyword("");
    setIsSearch(false);
    setSelectedKeys([]);
    setMemberLists([]);
    setTblSelectMembers([]);
  };
  const onSortEndTable = ({ oldIndex, newIndex }) => {
    if (fixedMain && (oldIndex === 0 || newIndex === 0)) {
      return;
    }
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(selectMembers),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setSelectMembers([...newData]);
    }
  };

  const getDepartmentList = async () => {
    if (!visible) return;

    setLoading(true);
    const result = await fetchAbsolute("get", `/common/departmentList`);
    setLoading(false);

    setAllOrganDatas([...result.data?.mdList]);

    // if (
    //   profile.DWP_USER_LEVEL === "UA06" ||
    //   profile.DWP_USER_LEVEL === "UA05"
    // ) {
    //   const result = await fetchAbsolute("get", `/common/departmentList`);
    //   setLoading(false);
    //   setAllOrganDatas([...result.data?.mdList]);
    // } else {
    //   const result = await fetchAbsolute(
    //     "get",
    //     `/common/departmentList?orgCd=${profile.COMP_CD}`
    //   );
    //   setLoading(false);
    //   setAllOrganDatas([...result.data?.mdList]);
    // }
  };

  useEffect(() => {
    selectColumns[selectColumns.length - 1].render = (e, record, index) =>
      index === 0 && fixedMain ? (
        <></>
      ) : (
        <MinusCircleFilled
          onClick={() => onDeleteSelectData(e.empNum)}
        ></MinusCircleFilled>
      );
  }, [fixedMain]);

  useEffect(() => {
    if (!visible) return;
    setExpand([]);
    setMemberLists([]);
    setTblSelectMembers([]);
    setSearchKeyword("");
    setSelectedKeys([]);
    setIsSearch(false);
    getDepartmentList();
    if (defaultData.length < 1) {
      setSelectMembers([]);
    } else {
      setSelectMembers([
        ...defaultData.filter((data) => data && Object.keys(data).length > 0),
      ]);
    }
  }, [visible, defaultData]);

  useEffect(() => {
    setOrganDatas([...allOrganDatas]);
  }, [allOrganDatas]);

  useEffect(() => {
    const tmpDatas = [];
    let lastArray;
    // 레벨에 맞는 배열 가져오기 위한 재귀함수
    const getLevelArray = (array, level, count = 1) =>
      +level === count
        ? array
        : getLevelArray(array[array.length - 1].children, level, count + 1);
    organDatas.forEach((value) => {
      lastArray = getLevelArray(tmpDatas, value.level);
      const data = {
        title: value.codeNm,
        key: value.codeId,
        level: value.level,
        children: [],
      };
      if (!lastArray.children) lastArray.push(data);
      else lastArray.children.push(data);
    });
    setTreeData(tmpDatas);
  }, [organDatas]);

  useEffect(() => {
    if (selectedKeys.length === 0) return;

    const fetchData = async () => {
      setLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/common/memberList?orgCd=${selectedKeys[0]}`
      );
      setLoading(false);
      // 조직원 정보들에 key 추가하여 저장
      setMemberLists([
        ...result.data.mdFPAList.map((v, index) => ({ ...v, key: index })),
      ]);
      setTblSelectMembers([]);
    };
    fetchData();
  }, [selectedKeys]);

  return (
    <Wrapper
      visible={visible}
      title={title}
      onOk={onClickOk}
      onCancel={onCancel}
    >
      <div className="orga_wrapper">
        <div className="orga_section">
          <div className="orga_section_title">
            <Text className="orga_section_title_text">
              {formatMessage({ id: "T0333" })}{/* 구성원 검색 */}
            </Text>
          </div>
          <div className="orga_section_box" style={{ width: 750 }}>
            <Tooltip
              title={formatMessage({ id: "T0334" })} // 두 글자 이상 입력해주세요.
              open={visibleTooltip}
              placement="bottom"
              trigger={[]}
              overlayInnerStyle={{
                fontSize: 12,
              }}
            >
              <div className="orga_section__input">
                <Input
                  value={searchKeyword}
                  onChange={onChangeSearchKeyword}
                  onPressEnter={onKeyPressKeyword}
                  placeholder={formatMessage({ id: "T0335" })} // 구성원 사번, 이름 혹은 팀 이름을 검색하세요
                  style={{ height: 32 }}
                />
                <CloseCircleFilled
                  className="input_clear"
                  onClick={onClickSearchReset}
                />
              </div>
            </Tooltip>
            <div className="orga_box" style={{ width: 330 }}>
              <div className="orga_title">
                <Text style={{ fontSize: 12 }}>
                  {formatMessage({ id: "T0336" })}{/* 팀 */}
                </Text>
              </div>
              <div className="orga_content">
                {isSearch ? (
                  <List
                    dataSource={searchDepartment}
                    renderItem={(department) => (
                      <List.Item
                        className="orga_content__list"
                        onClick={() => onSelectList(department.codeId)}
                        key={department.codeId}
                      >
                        {department.codeNm}
                      </List.Item>
                    )}
                  />
                ) : (
                  <Tree
                    treeData={treeData}
                    expandedKeys={expand}
                    // autoExpandParent={true}
                    onExpand={onExpandTree}
                    selectedKeys={selectedKeys}
                    onSelect={onSelectTree}
                  />
                )}
              </div>
            </div>
            <div className="orga_box" style={{ width: 380 }}>
              <div className="orga_title">
                <Text style={{ fontSize: 12 }}>
                  {formatMessage({ id: "T0337" })}{/* 구성원 */}
                </Text>
              </div>
              <div className="orga_content orga_table">
                <Table
                  className="orga_table"
                  columns={memberColumns}
                  dataSource={memberLists}
                  pagination={false}
                  locale={{ emptyText: " " }}
                  rowSelection={rowSelection}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="orga_section">
          <Button
            type="primary"
            className="orga_select_button"
            onClick={onClickSelectButton}
          >
            &gt;
          </Button>
        </div>
        <div className="orga_section" style={{ width: 540 }}>
          <div className="orga_section_title">
            <Text className="orga_section_title_text">
              {formatMessage({ id: "T0338" })}{/* 선택 항목 */}
            </Text>
            <Button className="orga_section_reset" onClick={onClickResetButton}>
              {formatMessage({ id: "T0037" })}{/* 초기화 */}
            </Button>
          </div>

          <div
            className="orga_section_box"
            style={{ padding: 0, overflowY: "scroll" }}
          >
            <Table
              className="orga_table"
              columns={selectColumns}
              dataSource={selectMembers}
              pagination={false}
              locale={{ emptyText: " " }}
              rowKey="empNum"
              style={{ width: "100%" }}
              components={{
                body: {
                  wrapper: (props) => (
                    <DraggableContainer onSortEnd={onSortEndTable} {...props} />
                  ),
                  row: DraggableBodyRow,
                },
              }}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Organization;
