import React, { useState, useEffect } from "react";
import { Text, Modal, Button, Organization, Input } from "components";
import { useIntl, FormattedMessage } from "react-intl";
import { Tree } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
const TreeNode = Tree.TreeNode;

/**
 * Filename: AuthGroupMangement/index.js
 * Description: 관리자 > 메뉴 관리 > 권한 그룹 관리
 */

const AuthGroupMangement = () => {
  const { formatMessage } = useIntl();

  const addText = formatMessage({ id: "T0049" }); // 추가
  const onOkText = formatMessage({ id: "T0054" }); // 수정

  const [expand, setExpand] = useState([]);
  const [exGroupCount, setExGroupCount] = useState(0);
  const [userGrade, setUserGrade] = useState([]);
  const [authGroupList, setAuthGroupList] = useState([]);
  const [orgVisible, setOrgVisible] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [authGroupInput, setAuthGroupInput] = useState("");
  const [addVisible, setAddVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [delUserVisible, setDelUserVisible] = useState(false);
  const [selectedAuthGroupKey, setSelectedAuthGroupKey] = useState();
  const [userInfo, setUserInfo] = useState();
  const [userSearchStatus, setUserSearchStatus] = useState(true);
  const [userDelStatus, setUserDelStatus] = useState(true);
  const [groupUpdateButtonStatus, setGroupUpdateButtonStatus] = useState(true);
  const [groupDelButtonStatus, setGroupDelButtonStatus] = useState(true);
  const [updateGroupInput, setUpdateGroupInput] = useState({
    title: "",
    key: "",
    sort: "",
    attrCd1: "",
    useYn: "",
  });

  const [language, setLanguage] = useState("");

  const onCancelAdd = () => {
    setAuthGroupInput("");
    setAddVisible(false);
  };
  const onClickAddGroup = () => {
    setAddVisible(true);
  };

  const onCancelDeleteGroup = () => {
    setDeleteVisible(false);
  };
  const onClickDeleteGroup = () => {
    setDeleteVisible(true);
  };
  const onCancelUpdateGroup = () => {
    setUpdateVisible(false);
  };
  const onClickUpdateGroup = () => {
    setUpdateVisible(true);
  };
  const onClickOrg = () => {
    setOrgVisible(true);
  };
  const onClickCancelDeleteUser = () => {
    setDelUserVisible(false);
  };
  const onClickDeleteUser = () => {
    setDelUserVisible(true);
  };

  const onOkAdd = async () => {
    let count = Number(exGroupCount) + 1;
    if (count < 10) {
      count = "0" + count;
    }

    let fetchFlag = true;
    try {
      const saveList = await fetchAbsolute("post", `/common/commonCd/addList`, {
        data: [
          {
            categoryId: "AUTH_GROUP",
            cd: "EX" + count,
            cdNm: authGroupInput,
            useYn: "Y",
            sort: exGroupCount + 1,
            attrCd1: "EX",
            locale: language,
          },
        ],
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (saveList.status === 200 || saveList.status === 201) {
        alert(formatMessage({ id: "T0234" })); // 저장되었습니다.
      } else {
        throw new Error(saveList.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
      }
    }

    fetchAuthGroup();
    fetchAuthGroupList();
    setAuthGroupInput("");
    setAddVisible(false);
  };

  const onOkUpdateAuthGroup = async () => {
    let fetchFlag = true;
    try {
      const saveList = await fetchAbsolute("post", `/common/commonCd/addList`, {
        data: [
          {
            categoryId: "AUTH_GROUP",
            cd: updateGroupInput.key,
            cdNm: updateGroupInput.title,
            useYn: updateGroupInput.useYn,
            sort: updateGroupInput.sort,
            attrCd1: updateGroupInput.attrCd1,
            locale: language,
          },
        ],
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (saveList.status === 200 || saveList.status === 201) {
        alert(formatMessage({ id: "T0234" })); // 저장되었습니다.
      } else {
        throw new Error(saveList.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
      }
    }

    fetchAuthGroup();
    fetchAuthGroupList();
    setUpdateVisible(false);
  };

  const onOkDelAuthGroup = async () => {
    let fetchFlag = true;
    try {
      const delAuthGroup = await fetchAbsolute(
        "delete",
        `/common/commonCd/deleteList`,
        {
          data: [
            {
              categoryId: "AUTH_GROUP",
              cd: selectedAuthGroupKey,
              useYn: "N",
            },
          ],
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (delAuthGroup.status === 200 || delAuthGroup.status === 201) {
        alert(formatMessage({ id: "T0280" }));
        //권한 그룹 삭제 완료 되었습니다.
      } else {
        throw new Error(delAuthGroup.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0247" }));
        //삭제에 실패하였습니다.
      }
    }
    //삭제 후 다시 API 조회하기
    setDeleteVisible(false);
    fetchAuthGroup();
    fetchAuthGroupList();
  };

  const onChangeAuthGroup = (e) => {
    setAuthGroupInput(e.target.value);
  };
  const onChangeUpdateGroup = (e) => {
    const { value, name } = e.target;
    setUpdateGroupInput({
      ...updateGroupInput,
      [name]: value,
    });
  };
  const onExpandTree = (keys) => {
    setExpand([...keys]);
  };

  const onSelect = (selectedKeys, info) => {
    //권한 그룹 선택 할때
    if (selectedKeys[0]?.includes("EX")) {
      setUserSearchStatus(false);
      setUserDelStatus(true);
      setSelectedAuthGroupKey(selectedKeys[0]);
      setGroupUpdateButtonStatus(false);

      const selectArr = userGrade.filter(
        (e) => e.cd === info.node.props.eventKey
      );
      setUpdateGroupInput({
        title: info.node.props.title,
        key: info.node.props.eventKey,
        sort: selectArr[0].sort,
        attrCd1: selectArr[0].attrCd1,
        useYn: selectArr[0].useYn,
      });
      //권한 그룹에 사용자가 속해 있을때 . 자식 노드가 있을때
      if (info.node.props.children?.length > 0) {
        setGroupDelButtonStatus(true);
      } else {
        setGroupDelButtonStatus(false);
      }
      //사용자 선택 할때
    } else {
      if (selectedKeys?.length === 0) {
        setUserDelStatus(true);
        setGroupUpdateButtonStatus(true);
        setGroupDelButtonStatus(true);
        setUserInfo(info);
        setUserSearchStatus(true);
      } else {
        setGroupUpdateButtonStatus(true);
        setGroupDelButtonStatus(true);
        setUserInfo(info);
        setUserDelStatus(false);
        setUserSearchStatus(true);
      }
    }
  };

  const onOkOrg = (data) => {
    if (data?.length === 0) {
      setOrgData({});
    }
    onAddUser(data);
    setOrgVisible(false);
  };
  const ExCount = (e) => {
    const exList = e.filter((e) => e.attrCd1 === "EX");
    setExGroupCount(exList?.length);
  };

  const fetchAuthGroupList = async () => {
    const result = await fetchAbsolute("get", `/admin/menu/getAuthEmpList`);
    setAuthGroupList(result.data);
  };
  const fetchAuthGroup = async () => {
    const result = await fetchAbsolute(
      "get",
      `/common/commonCd/getCateIdAndUseYn/AUTH_GROUP?sortVal=asc`
    );
    ExCount([...result.data]);
    setUserGrade([...result.data]);
  };

  const onOkDelUser = async () => {
    let fetchFlag = true;
    try {
      const delUser = await fetchAbsolute("post", `/admin/menu/deleteAuthEmp`, {
        data: [
          {
            empNum: userInfo.node.props.eventKey,
            authCd: userInfo.node.props.authGroup,
          },
        ],
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (delUser.status === 200) {
        alert(formatMessage({ id: "T0281" }));
        //삭제되었습니다.
      } else {
        throw new Error(delUser.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0247" }));
        //삭제에 실패하였습니다.
      }
    }

    fetchAuthGroup();
    fetchAuthGroupList();
    setDelUserVisible(false);
  };

  const onAddUser = async (selectUser) => {
    const saveUser = selectUser.map((e) => ({
      empNum: e.empNum,
      authCd: selectedAuthGroupKey,
    }));

    let fetchFlag = true;
    try {
      const saveList = await fetchAbsolute("post", `/admin/menu/saveAuthEmp`, {
        data: saveUser,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (saveList.status === 200) {
        alert(formatMessage({ id: "T0234" })); // 저장되었습니다.
      } else {
        throw new Error(saveList.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0235" }));
        //저장에 실패하였습니다.
      }
    }

    fetchAuthGroup();
    fetchAuthGroupList();
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute(
      "get",
      "/common/commonCd/getCategoryId/LOCALE"
    );
    const data = result.data.filter((v) => v.useYn === "Y");
    setLanguage(data[0].cd);
    // setLanguageList([...data]);
  };

  useEffect(() => {
    fetchAuthGroup();
    fetchAuthGroupList();
    fetchLanguageList();
  }, []);

  return (
    <div className="org_group__container">
      <div>
        <Button
          className="button_event"
          type="primary"
          onClick={onClickAddGroup}
        >
          <FormattedMessage id="T0101" />{/* 권한 그룹 생성 */}
        </Button>
        <Button
          type={groupUpdateButtonStatus ? "disabled" : "default"}
          disabled={groupUpdateButtonStatus}
          className="button_event"
          onClick={onClickUpdateGroup}
        >
          <FormattedMessage id="T0102" />{/* 권한 그룹 수정 */}
        </Button>
        <Button
          type={groupDelButtonStatus ? "disabled" : "default"}
          disabled={groupDelButtonStatus}
          onClick={onClickDeleteGroup}
        >
          <FormattedMessage id="T0103" />{/* 권한 그룹 삭제 */}
        </Button>
        <Button
          type={userSearchStatus ? "disabled" : "default"}
          disabled={userSearchStatus}
          style={{ float: "right" }}
          onClick={onClickOrg}
        >
          <FormattedMessage id="T0104" />{/* 사용자 추가 */}
        </Button>
        <Button
          type={userDelStatus ? "disabled" : "default"}
          disabled={userDelStatus}
          className="button_event"
          style={{ float: "right" }}
          onClick={onClickDeleteUser}
        >
          <FormattedMessage id="T0105" />{/* 사용자 삭제 */}
        </Button>
        <div className="org_common__box">
          <Tree
            className="draggable-tree"
            onSelect={onSelect}
            defaultExpandedKeys={expand}
            expandedKeys={expand}
            onExpand={onExpandTree}
          >
            {userGrade
              .filter((e) => e.attrCd1 === "EX")
              .map((res) => {
                return (
                  <TreeNode key={res.cd} title={res.cdNm}>
                    {authGroupList
                      .filter((e) => e.authCd === res.cd)
                      .map((r) => {
                        return (
                          <TreeNode
                            key={r.empNum}
                            authGroup={r.authCd}
                            title={
                              <>
                                <div>
                                  {r.empName === null ? (
                                    <span>X</span>
                                  ) : (
                                    <span>{r.empName}</span>
                                  )}
                                  <span> / {r.empNum}</span>
                                </div>
                              </>
                            }
                          />
                        );
                      })}
                  </TreeNode>
                );
              })}
          </Tree>
        </div>
      </div>
      <Organization
        visible={orgVisible}
        onCancel={() => setOrgVisible(false)}
        onOk={onOkOrg}
        defaultData={[orgData]}
      />

      <Modal
        width={540}
        visible={addVisible}
        title={formatMessage({ id: "T0677" })} // 권한 그룹 추가
        okText={addText}
        onCancel={onCancelAdd}
        onOk={onOkAdd}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0678" })}{/* 그룹 추가 */}
        </Text>
        <Input
          onChange={onChangeAuthGroup}
          name="authGroupName"
          value={authGroupInput}
        ></Input>
      </Modal>

      <Modal
        width={540}
        visible={updateVisible}
        title={formatMessage({ id: "T0102" })} // 권한 그룹 수정
        okText={onOkText}
        onCancel={onCancelUpdateGroup}
        onOk={onOkUpdateAuthGroup}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0679" })}{/* 권한 그룹 */}
        </Text>
        <Input
          onChange={onChangeUpdateGroup}
          name="title"
          value={updateGroupInput.title}
        ></Input>
      </Modal>

      <Modal
        width={540}
        visible={deleteVisible}
        title={formatMessage({ id: "T0103" })} // 권한 그룹 삭제
        okText={formatMessage({ id: "T0072" })} // 삭제
        onCancel={onCancelDeleteGroup}
        onOk={onOkDelAuthGroup}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0680" })}{/* 정말로 선택된 권한 그룹을 삭제 하시겠습니까? 사용자가 매핑된 경우 삭제되지 않습니다. 해당그룹에 매핑된 사용사를 먼저 삭제 해주세요. */}
        </Text>
      </Modal>
      <Modal
        width={540}
        visible={delUserVisible}
        title={formatMessage({ id: "T0105" })} // 사용자 삭제
        okText={formatMessage({ id: "T0072" })} // 삭제
        onCancel={onClickCancelDeleteUser}
        onOk={onOkDelUser}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0681" })}{/* 정말로 선택된 사용자를 삭제 하시겠습니까? */}
        </Text>
      </Modal>
    </div>
  );
};

export default AuthGroupMangement;
