import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import fetchAbsolute from "utils/fetchAbsolute";
import { setListUrl } from 'store/listUrl/action';
import { Collapse as AntCollapse } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl } from 'react-intl';
import {
  PageTemplate,
  Header,
  Footer,
  WorkFlowScheduleTop,
  WorkFlowScheduleBottom,
  Collapse,
  Loading,
} from "components";
import Wrapper from "./Styled";
import moment from "moment";
import queryString from 'query-string';

const { Panel } = AntCollapse;

const initialSearchQuery = {
  yyyymm: moment().format('YYYYMM'),
  dd: moment().format('DD'),
  specificDay: moment().format("YYYYMMDD"),
  calendar: 'monthly',
};

/**
  workflowId: Workflow Id
  workflowNm: 업무명
  workId: 업무 분류
  scheduleType: 스케줄 타입
  workflowOrgCd: 담당 부서
  regId: 등록자 
 */
const initialInputValues = {
  workflowId: '',
  workflowNm: '',
  scheduleType: '',
  workId: {},
  workflowOrgCd: {},
  regId: {},
};

const initialObj = {
  ...initialSearchQuery,
  ...initialInputValues
};

const WorkFlowSchedule = ({ location: { search }, history }) => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState({ ...initialSearchQuery, ...initialInputValues });

  const [openCollapse, setOpenCollapse] = useState(true);
  const [loading, setLoading] = useState(false);

  const [scheduleDatas, setScheduleDatas] = useState([]);

  // scheduleSearch 텍스트
  const [scheduleSearch, setScheduleSearch] = useState('');

  const onChangeCollapse = () => setOpenCollapse((openCollapse) => !openCollapse);

  const replaceSearch = (searchObject) => {
    const beforeSearch = [];
    const beforeObject = {};
    const initialKeys = Object.keys(initialInputValues);
    const searchObj = { ...initialObj, ...searchObject };
    const beforeKeys = Object.keys(searchObj).filter(key => initialKeys.includes(key));
    // 검색해야 할 내용들 정리
    Object.keys(searchObj).forEach(key => {
      const data = searchObj[key];
      if (!beforeKeys.includes(key)) {
        return;
      }
      if (Object.keys(data).length <= 0) {
        delete searchObj[key];
        return;
      }

      beforeObject[key] = data;

      if (['regId'].includes(key)) {
        beforeSearch.push(data.empNam);
        searchObj[key] = typeof data === 'object' ? data.empNum : data;
      } else if (['workflowOrgCd'].includes(key)) {
        beforeSearch.push(data.codeNm);
        searchObj[key] = typeof data === 'object' ? data.codeId : data;
      } else if (['workId'].includes(key)) {
        beforeSearch.push(data.cdNm);
        searchObj[key] = typeof data === 'object' ? data.cd : data;
      } else if (['workflowWorkId'].includes(key)) {
        beforeSearch.push(data.cdNm);
        searchObj[key] = typeof data === 'object' ? data.cd : data;
      } else if (['scheduleType'].includes(key)) {
        beforeSearch.push(data.value);
        searchObj[key] = typeof data === 'object' ? data.value : data;
      } else if (beforeKeys.includes(key)) {
        beforeSearch.push(data);
      }
    });

    if (beforeSearch[0] !== undefined) {
      if (beforeSearch.length > 0) {
        const beforeText = beforeSearch.slice(0, 2).join(', ');
        localStorage.setItem('beforeSearchWorkflowSchedule', beforeText);
        localStorage.setItem('beforeObjectWorkflowSchedule', JSON.stringify(beforeObject));
        setScheduleSearch(beforeText);
      }
    }

    const searchString = queryString.stringify(searchObj);
    if ('?' + searchString === search) {
      return;
    }
    dispatch(setListUrl(searchString));
    history.replace({ search: searchString });
  };

  const getScheduleData = async () => {
    const searchObject = queryString.parse(search);
    const searchString = queryString.stringify(searchObject);
    setLoading(true);
    const result = await fetchAbsolute("get", `/boxwood/boxwoodScheduleList?${searchString}`);
    setLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0185' })); //스케줄 정보 가져오는 도중 에러가 발생했습니다.
    setScheduleDatas({ ...result.data }); // monthly, weekly, daily 스케줄 정보
  };

  // query String 변경 될 때마다
  useEffect(() => {
    const preSearchObject = queryString.parse(search);
    if (search.length <= 0) {
      replaceSearch({ ...initialObj });
      return;
    } else if (!preSearchObject.yyyymm) {
      replaceSearch({
        ...initialObj,
        ...preSearchObject
      });
      return;
    }
    // 사번으로 구성원 찾아서 반환하는 함수
    const getMemberInfo = async (empNo) => {
      try {
        const { data } = await fetchAbsolute('get', `/common/memberNoList?empNo=${empNo}`);
        if (!data.mdFPAList?.length) {
          throw new Error();
        }
        return data.mdFPAList;
      } catch {
        return [];
      }
    }
    // search query로 데이터 세팅하는 함수
    const setDatas = async () => {
      const searchObject = queryString.parse(search);

      const { workId, workflowOrgCd, regId, scheduleType } = searchObject;

      // 부서 정보 세팅
      if (workflowOrgCd) {
        try {
          const { data } = await fetchAbsolute('get', `/common/departmentList?orgCd=${workflowOrgCd}`)
          if (!data.mdList?.length) {
            throw new Error();
          }
          searchObject.workflowOrgCd = { ...data?.mdList[0] };
        } catch {
          delete searchObject.workflowOrgCd;
        }
      }
      // 등록자 정보 세팅
      if (regId) {
        const result = await getMemberInfo(regId);
        if (result.length) {
          searchObject.regId = result[0];
        } else {
          delete searchObject.regId;
        }
      }
      if (workId) {
        try {
          const { data } = await fetchAbsolute('get', `/common/workClassification?cd=${workId}`);
          if (!data) {
            throw new Error();
          }
          searchObject.workId = { ...data };
        } catch {
          delete searchObject.workId
        }
      }
      if (scheduleType) {
        searchObject.scheduleType = {
          text: scheduleType,
          value: scheduleType
        }
      }
      const tmpObj = {
        ...initialObj,
        ...searchObject
      }
      setSearchQuery({ ...tmpObj });
      getScheduleData(tmpObj);
    }
    setDatas();
  }, [search]);

  if (loading) {
    return <Loading visible={loading} />
  }
  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor='#fff'>
      {
        <Wrapper>
          <Collapse
            expandIconPosition="end"
            onChange={onChangeCollapse}
            expandIcon={() =>
              !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
            }
            activeKey={openCollapse ? ["1"] : []}
            defaultActiveKey={openCollapse ? ["1"] : []}
          >
            <Panel header={formatMessage({ id: 'T1194' })} key='1'>{/* 상세검색 */}
              <WorkFlowScheduleTop
                replaceSearch={replaceSearch}
                searchQuery={searchQuery}
                scheduleSearch={scheduleSearch}
                setScheduleSearch={setScheduleSearch}
              />
            </Panel>
          </Collapse>
          <WorkFlowScheduleBottom
            replaceSearch={replaceSearch}
            searchQuery={searchQuery}
            scheduleDatas={scheduleDatas}
          />
        </Wrapper>
      }
    </PageTemplate>
  );
};

export default WorkFlowSchedule;