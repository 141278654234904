import React, { useState, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import {
  PageTemplate,
  Header,
  Footer,
  Input,
  Button,
  FormTable,
  Spin
} from "components";
import Wrapper from "./Styled";
import fetchAbsolute from "utils/fetchAbsolute";
import { Select } from "antd";

/**
 * Filename: AdminQueueEdit.js
 * 관리자 Queue 정보 수정 페이지
 */

const { Option } = Select;

const AdminQueueEdit = ({
  history,
  match: { params },
}) => {
  const { formatMessage } = useIntl();

  const errorMsg = formatMessage({ id: "T0133" }); // 작업을 실패하였습니다.
  const successMsg = formatMessage({ id: "T0162" }); // 작업을 완료하였습니다.

  const [inputValues, setInputValues] = useState({});
  const [loading, setLoading] = useState(false);

  const onChangeValues = (label, value) => {
    setInputValues({ ...inputValues, [label]: value });
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    onChangeValues(name, value);
  };

  const onChangeSelect = (e) => {
    onChangeValues("postProcessingFlag", e);
  };

  /* 수정 버튼 */
  const onClickEditButton = async () => {
    setLoading(true);
    // 3가지만 변경 가능(Post Processing Flag, Maximum Retry Count, Maximum Run Bot Count)
    const result = await fetchAbsolute(
      "put",
      `/queue/queuesInfo?crCd=${params.crCd}`,
      {
        data: {          
            id: inputValues.id,
            devicePoolId: inputValues.devicePoolId,
            processId: inputValues.processId,
            postProcessingFlag: inputValues.postProcessingFlag,
            maxRetryCount: +inputValues.maxRetryCount,
            maxRunBotCount: +inputValues.maxRunBotCount
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setLoading(false);
    if (result.error) {
      return alert(errorMsg);
    } else {
      alert(successMsg);
    }
    history.push(`/admin/queue`);
  };

  /* 취소 버튼 */
  const onClickCancel = () => {
    history.push(`/admin/queue`);
  };

  /* 초기화 버튼 */
  const onClickReset = () => {
    getQueueInfo();
  };

  /* Queue Info 불러오기 */
  const getQueueInfo = async () => {
    setLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/queue/queuesInfo?crCd=${params.crCd}&name=${params.queueName}`
    );
    setLoading(false);
    if (result.error) return alert(errorMsg);
    setInputValues(result.data);
  };

  useEffect(() => {
    if (!params.queueName) return;
    getQueueInfo();
  }, [params.queueName]);

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      headerColor="none"
      menuTitle={`${formatMessage({ id: 'T1053' })}`} // Queue 정보 수정
      depthList={
        [`${formatMessage({ id: 'T0881' })}`, // 관리자
        `${formatMessage({ id: 'T1053' })}`] // Queue 정보 수정
      }
    >
      <Wrapper style={{ padding: "30px 50px" }}>
        {!!loading ? (
          <Spin tip="Loading..." />
        ) : (
          <>
            <div className="container">
              <FormTable>
                <colgroup>
                  <col width="19.85%" />
                  <col width="30.08%" />
                  <col width="19.85%" />
                  <col width="30.08%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th><FormattedMessage id="T1036" /></th>{/* CR */}
                    <td>
                      {params.crCd.includes("DIY") ? "DIY" : "COE"} {params.crCd.includes("PRD") ? `${formatMessage({ id: 'T0398' })}` : // 운영
                        `${formatMessage({ id: 'T0396' })}` // 개발
                      }
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1038" /></th>{/* Queue ID */}
                    <td>{inputValues.id}</td>
                    <th><FormattedMessage id="T1040" /></th>{/* Queue Name */}
                    <td>{inputValues.name}</td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1039" /></th>{/* Queue Desc. */}
                    <td>
                      <Input disabled="true" value={inputValues.description} />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1041" /></th>{/* Queue Status */}
                    <td>
                      <Select
                        className="select_box"
                        disabled="true"
                        value={inputValues.status}
                      >
                        <Option key="ACTIVE" value="ACTIVE">
                          ACTIVE
                        </Option>
                        <Option key="INACTIVE" value="INACTIVE">
                          INACTIVE
                        </Option>
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1042" /></th>{/* Device Pool ID */}
                    <td>
                      <Input disabled="true" value={inputValues.devicePoolId} />
                    </td>
                    <th><FormattedMessage id="T1043" /></th>{/* Default Device ID */}
                    <td>
                      <Input
                        disabled="true"
                        value={inputValues.defaultDeviceId}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1037" /></th>{/* Process ID */}
                    <td>{inputValues.processId}</td>
                    <th><FormattedMessage id="T1048" /></th>{/* Master Task File ID */}
                    <td>
                      <Input
                        disabled="true"
                        value={inputValues.masterTaskFileId}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1044" /></th>{/* Post Processing Flag */}
                    <td>
                      <Select
                        className="select_box"
                        onChange={onChangeSelect}
                        value={inputValues.postProcessingFlag}
                        name="postProcessingFlag"
                      >
                        <Option value="Y">Y</Option>
                        <Option value="N">N</Option>
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1045" /></th>{/* Maxium Retry Count */}
                    <td>
                      <Input
                        onChange={onChangeInput}
                        name="maxRetryCount"
                        value={inputValues.maxRetryCount}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1049" /></th>{/* Minimum Available Bot Count */}
                    <td>
                      <Input
                        disabled="true"
                        value={inputValues.minAvailableBotCount}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1046" /></th>{/* Maxium Run Bot Count */}
                    <td>
                      <Input
                        onChange={onChangeInput}
                        name="maxRunBotCount"
                        value={inputValues.maxRunBotCount}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1050" /></th>{/* Average Run Time */}
                    <td>
                      <Input disabled="true" value={inputValues.avgRunTime} />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1051" /></th>{/* Overhead Threshold */}
                    <td>
                      <Input
                        disabled="true"
                        value={inputValues.overheadThreshold}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1052" /></th>{/* Send Mail Flag */}
                    <td>
                      <Select
                        className="select_box"
                        disabled="true"
                        value={inputValues.sendMailFlag}
                      >
                        <Option value="Y">Y</Option>
                        <Option value="N">N</Option>
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <th><FormattedMessage id="T1047" /></th>{/* Duplicate Allowed Flag */}
                    <td>
                      <Select
                        className="select_box"
                        disabled="true"
                        value={inputValues.duplicateAllowedFlag}
                      >
                        <Option value="Y">Y</Option>
                        <Option value="N">N</Option>
                      </Select>
                    </td>
                  </tr>
                </tbody>
              </FormTable>
            </div>
            <div className="btn_company">
              <Button
                type="default"
                width="150"
                height="40"
                onClick={onClickReset}
              >
                <FormattedMessage id="T0037" />{/* 초기화 */}
              </Button>
              <Button
                type="default"
                width="150"
                height="40"
                onClick={onClickCancel}
              >
                <FormattedMessage id="T0051" />{/* 취소 */}
              </Button>
              <Button
                type="primary"
                width="150"
                height="40"
                onClick={onClickEditButton}
              >
                <FormattedMessage id="T0054" />{/* 수정 */}
              </Button>
            </div>
          </>
        )}
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminQueueEdit;