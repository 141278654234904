import React from "react";
import { Upload as AntUpload } from "antd";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const { Dragger } = AntUpload;

const DraggerWrap = styled(Dragger)`
  padding: 5px 0px;
  font-size: 14px;
  letter-spacing: -1px;
  color: #b3b3b3;
  background: #fff !important;
  .ant-upload {
    background: #fff;
  }
  .ant-upload.ant-upload-btn {
    padding: 0;
  }
  .underline {
    text-decoration: underline;
    font-weight: bold;
  }
  .ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: #ff7b00 !important;
  }
`;

const Upload = ({ type = "", accept = '.txt, .pdf, .png, .jpg, .jpeg, .xlsx, .pptx, .jar, .jks, .docx, .gif', ...args }) => {
  return (
    <div
      className="drag-box"
      style={{ border: "1px solid #bbb", padding: "2px" }}
    >
      <DraggerWrap accept={accept} {...args}>
        <p>
          <FormattedMessage id="T1197" />{/* 첨부파일을 여기로 끌어오거나 */}
        </p>
        <p>
          <span className="underline">
            <FormattedMessage id="T1198" />{/* 클릭 */}
          </span>
          <FormattedMessage id="T1199" />{/* 하여 업로드하세요 */}
        </p>
        <p>
          최대 업로드 파일 크기 : <b>100MB</b>
        </p>
        <p>
          사용할 수 있는 데이터 파일&nbsp;
          <b>{accept}</b>
        </p>
      </DraggerWrap>
    </div>
  );
};

export default Upload;
