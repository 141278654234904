import styled from 'styled-components';

const Wrapper = styled.div` 
  .date__btn {
      &:focus {
        background: #FF7A00;
        color: #fff;
      }
  } 
  .list_top__spec_box {
    padding-bottom: 1%; 
    padding-left: 10px;
  }
  .list_top__spec_box th {
    background: none;
    font-weight: normal;
  }
  .before__input {
    position: relative;
  }
  .before__input input {
    cursor: pointer;
  }
  .before__input .before__clear {
    position: absolute;
    top: 50%;
    right: 11px;
    font-size: 12px;
    color: #00000040;
    cursor: pointer;
    transform: translateY(-50%);
  }
  .date_button__wrap {
    display: flex;
    justify-content: space-between;
  }
  .filter_option {
    width: 100%;
    background: #000;
  }
  .ant-select-selection-selected-value {
    width: 100%;
  }
  .ant-radio-button-wrapper:focus-within {
    box-shadow: none;
  } 
`;

export default Wrapper;