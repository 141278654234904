import React, { useEffect, useState } from 'react';
import { Text, LinkModal, Button } from 'components';
import { useDispatch } from 'react-redux';
import { setVisible } from 'store/loading/action';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from 'react-intl';
import { Input as AntInput } from 'antd';

const { TextArea } = AntInput;

const TransferRefuse = ({ match: { params } }) => {
    const { formatMessage } = useIntl();

    const { seq, bpdId } = params;

    const dispatch = useDispatch();

    const [transformReason, setTransformReason] = useState('')
    const [checkStatus, setCheckStatus] = useState(false);

    const onChangeReason = (e) => {
        const { value } = e.target;
        setTransformReason(value);
    }

    const TransformRequest = async () => {
        dispatch(setVisible(true));
        const result = await fetchAbsolute('post', `/handyflow/handyflowBpdToWfRejection`, {
            data: {
                reqSeq: seq,
                refuseReason: transformReason
            }
        });
        dispatch(setVisible(false));
        if (result.data.statusCodeValue === 400)
            alert(formatMessage({ id: 'T1638' })); // 이미 승인(반려) 되었습니다.
        else {
            alert(formatMessage({ id: 'T0162' })); // 작업을 완료하였습니다. 
        }
        window.open('about:blank', '_self').self.close();
    };

    // 거절 된 상태에선 알림만 나오게
    useEffect(() => {
        if (!params) return;
        const checkStatus = async () => {
            const result = await fetchAbsolute('get', `/bpd/bpdToWfInfo?bpdId=${bpdId}`);
            if (result.data.find((val) => val.reqSeq === +seq).apprStatus !== 'REQUEST') {
                setCheckStatus(false);
                alert(formatMessage({ id: 'T1638' })); // 이미 승인(반려) 되었습니다.
                return window.open('about:blank', '_self').self.close();
            }
            setCheckStatus(true);
        }
        checkStatus();
    }, [params])

    if (!checkStatus) {
        return <></>
    }
    return (
        <LinkModal
            visible={true}
            title=""
            width={450}
            closable={false}
            footer={[]}
            titleDisabled
            footerDisabled
        >
            <div className='bpd_request_box'>
                <Text className='bpd_request_box__text'>
                    <Text required={true} >
                        {formatMessage({ id: 'T1589' })}{/* 거절 사유 */}
                    </Text>
                    <TextArea
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        value={transformReason}
                        onChange={onChangeReason}
                    />
                </Text>
                <div className='btn_container'>
                    <Button type='primary' onClick={TransformRequest}>{formatMessage({ id: 'T0066' })}</Button>{/* 입력 */}
                </div>
            </div>
        </LinkModal>
    )
}

export default TransferRefuse;