import React, { useState} from "react";
import {
  Text,
  Button,
  RangePicker,
  Organization,
  NameTagGroup
} from "components";
import { Row, Col, Select} from "antd";
import "moment/locale/ko";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

const { Option } = Select;

const EditableForm = ({
    analyticalDatas, 
    editAnalyticalDatas, 
    onChangeSchedule, 
    analyticalRequire,
    onChangeEditAnalyDatas,
    onClickDeleteButton,
    onClickStartDevButton
  }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();
  
    const [visibleOrgan, setVisibleOrgan] = useState(false);
  
    const onClickOrgaButton = () => {
      setVisibleOrgan(true);
    };
  
    const onOkOrgan = (data) => {
      // if(name==="developers") {
      //   val.map((data,index)=>{
      //     data.
      //   })
      // }
  
      // const tmpData = data.
      const tmpArr = [...data];
      const newTmpArr = tmpArr.map((val,index)=>{
        if(index===0) {
          return val = {...val, deputyHeadYn:"N", mgrDevGbn:"D"}
        } else {
          return val = {...val, deputyHeadYn:"Y", mgrDevGbn:"D"}
        }
      })
  
      onChangeEditAnalyDatas("developers", newTmpArr);
      setVisibleOrgan(false);
    };
  
    const onCancelOrgan = () => {
      setVisibleOrgan(false);
    };
  
    return(
      <div className="basic_box">
      <div className="basic_box__title">
        <Text>
          <FormattedMessage id="T1627" />{/* 2. [개발] 개발 시작 */}
        </Text>
        
      </div>
      {Object.keys(editAnalyticalDatas).length > 0 && (
        <div className="basic_box__info">
          <Row>
            <Col span={5}>
              <Text className="basic_row__title" required>
                <FormattedMessage id="T1627" />{/* 개발자 */}
              </Text>
            </Col>
            <Col span={19} className="basic_row__content">
              <Button
                // innerRef={analyticalRequire.developers.ref}
                data-label="developers"
                onClick={onClickOrgaButton}
              >
                <FormattedMessage id="T0049" />{/* 추가 */}
              </Button>
              <div
                className="flex flex-wrap-wrap"
                style={{ marginTop: 8, gap: "8px 22px" }}
              >
                {/* isMain은 css때문에 추가해준것 */}
                {NameTagGroup(editAnalyticalDatas.developers?.map((v, index) => ({
                      ...v,
                      isMain: index === 0,
                    })),
                    "developers",
                    onClickDeleteButton
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <Text className="basic_row__title" required>
              <FormattedMessage id="T0507" /> {/* 개발 일정 */}
              </Text>
            </Col>
            <Col span={19}>
              <RangePicker
                // innerRef={analyticalRequire.devSchedule?.ref || null}
                style={{ width: 280 }}
                format="YYYY-MM-DD"
                name="devSchedule"
                value={[...(editAnalyticalDatas.devSchedule || analyticalDatas.devSchedule)]}
                onChange={onChangeSchedule}
              />
            </Col>
          </Row>
          <Row>
            <Col span={6} offset={18} className="flex justify-end">
                <Button
                  onClick={onClickStartDevButton}
                  type="primary"
                  width="120"
                >
                  <FormattedMessage id="T0057" />{/* 개발 시작 */}
                </Button>
            </Col>
          </Row>
          </div>
        )}
          <Organization
          visible={visibleOrgan}
          title={formatMessage({ id: "T0510" })} // 개발자 등록
          onOk={onOkOrgan}
          onCancel={onCancelOrgan}
          defaultData={[...(editAnalyticalDatas.developers || [])]}
          msColumn={true}
        />
      </div>
    )
  }

  export default EditableForm;