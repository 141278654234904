import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import WorkflowLookupReceipt from "./WorkflowLookupReceipt";
import WorkflowLookupStart from "./WorkflowLookupStart/index";
import WorkflowLookupProgress from "./WorkflowLookupProgress/index";
import WorkflowLookupInspection from "./WorkflowLookupInspection";
import Stepper from "../WorkflowStepper";

const WorkflowBasic = ({
    params
}) => {
    const profile = useSelector(state => state.get('auth').get('profile'));
    const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

    const [checkPoint, setCheckPoint] = useState(1);

    //1 아무것도 진행안됐을때
    //2 접수 완료
    //3 개발 완료(분석설계)
    //4 개발 완료(개발 진행)
    //5 테스트 완료
    //6 사용 안됨~
    useEffect(() => {
    //2번 렌더링
        if(hfWorkflowLookup.workflowData.statusCd==="WS10" && hfWorkflowLookup.workflowData.detailStatusCd==="WS11") {
            setCheckPoint(2);
        } else if (hfWorkflowLookup.workflowData.statusCd==="WS20"&&hfWorkflowLookup.workflowData.detailStatusCd==="WS22") {
            setCheckPoint(2);
        } else if(hfWorkflowLookup.workflowData.statusCd==="WS20"
            &&(hfWorkflowLookup.workflowData.detailStatusCd==="WS21"||hfWorkflowLookup.workflowData.detailStatusCd==="WS23")) {
                setCheckPoint(3);
            } else if (hfWorkflowLookup.workflowData.statusCd==="WS30"&&hfWorkflowLookup.workflowData.detailStatusCd==="WS31") {
            setCheckPoint(4);
        } else if(hfWorkflowLookup.workflowData.statusCd==="WS40") {
            setCheckPoint(5);
        }
    }, [hfWorkflowLookup.workflowData.statusCd, hfWorkflowLookup.workflowData.detailStatusCd]);

    return (
        <div className="flex basic_info_box lookup_body__box">
            <Stepper checkPoint={checkPoint} />
            <div className="basic_box__wrap">
                <WorkflowLookupReceipt profile={profile} />
                <WorkflowLookupStart />
                <WorkflowLookupProgress params={params} />
                <WorkflowLookupInspection />
            </div>
        </div>
    )

}

export default WorkflowBasic;