import React from 'react';
import { Text } from 'components';
import styled from 'styled-components';
// import footerLogo from './footerLogo.png';
import footerLogo from './footerLogo_woori.png'; // 우리카드 수정

/**
 * Application Footer 영역의 Organisms Component
 * 
 * @class Footer
 */

const FooterWrap = styled.div`
  ${props => props.type === 'white' && `
   background: #fff;  
  `
  }
`

const Footer = ({ type = 'default', ...args }) => {
  return (
    <FooterWrap className='footer_box' type={type} style={{ padding: 0 }}>
      <Text className='footer__text'><img style={{ paddingRight: 19 }} src={footerLogo} alt='logo' />Copyright © {new Date().getFullYear()} Atman Partners Inc. All rights reserved.</Text>
    </FooterWrap>
  );
};

export default Footer;