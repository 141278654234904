import React, { useRef } from "react";
import JoditEditor from "jodit-react";
import styled from 'styled-components';

const StyledEditor = styled.div`
  a.jodit-status-bar-link {
    display: none;
  }
`;

const WebEditor = ({
  value = "",
  defaultValue = "",
  onChange = () => {},
  ...props
}) => { 
  const editor = useRef(null); 

  const config = {
    // all options from https://xdsoft.net/jodit/docs/classes/config.Config.html
    readonly: false,
    placeholder: "",
    height: 350,
    enableDragAndDropFileToEditor: true,
    uploader: {
      insertImageAsBase64URI: true,
    },
    askBeforePasteHTML: false, // 복사 붙여넣기 할 때 팝업창 안뜨게
    askBeforePasteFromWord: false, // 복사 붙여넣기 할 때 팝업창 안뜨게
    defaultActionOnPaste: "insert_clear_html", // 복사 붙여넣기 할 때 팝업창 안뜨게
    image: {
      openOnDblClick: false, 
    },
    popup: {
      img: false,
    },
    imageDefaultWidth: 'auto',
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "eraser",
      "|",
      "ul",
      "ol",
      "|",
      "outdent",
      "indent",
      "align",
      "|",
      "font",
      "fontsize",
      "paragraph",
      "|",
      "brush",
      "|",
      "image",
      "video",
      "hr",
      "table",
      "link",
      "|",
      "undo",
      "redo",
      "|",
      "find",
      "|",
      "source",
      "|",
      "preview",
    ],
  }; 

  return (
    <StyledEditor>
      <JoditEditor 
        ref={editor}
        value={defaultValue}
        config={config}
        tabIndex={1}
        onBlur={onChange}
      />
    </StyledEditor>
  );
};

export default WebEditor;