import styled from "styled-components";

const Wrapper = styled.div`
  width: 1400px; 
  margin: 0px auto; 
  height: fit-content; 
  background: #fff;
  /* 공통 */
  .common__container {
    background: #fff; 
    padding: 30.1px 30px; 
    margin-bottom: 30px;
    border-top:none;
  }
  .common__title {
    padding-bottom: 10px;
  }
  button {
    width: 140px;
    height: 40px;
  }
  .iternal_system_manage__top {
    display: flex;
    justify-content: space-between;
  }
  .iternal_system__title {
    font-size: 20px;
    font-weight: bold; 
  }
  /* 상단 검색 부분*/
  .top_each__section {
    display: flex;
    justify-content: space-between;
  }
  .top_each__section .search_section {
    display: flex;
    gap: 10px;
  }
  /* 시스템 메뉴 등록, 시스템 메뉴 수정 */
  .component__section {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .button_company {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  .button_company button {
    width: 150px;
    height: 40px;
  }
  /* bottom */
  .system_box__content .ant-table-thead th {
    height: 34px;
    padding: 0px 16px;
    font-size: 14px;
  }
  .ant-tabs-tab:hover {
    color: #000;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
    text-shadow: 0 0 0.25px currentColor; 
  }
  input.ant-input:hover {
      border-color: #ff7b00;
  }  
  input.ant-input:focus, ant-input-focused {
      border-color: #ff7b00 !important;
  }  
  .ant-select-selector:hover {
      border-color: #ff7b00 !important;
  } 
  .ant-select-focused:not(.ant-select-disabled) .ant-select-selector {
      border-color: #FF7B00 !important;
  }
  .system_box__content .ant-table-tbody td {
      font-size: 12px;
  }
  .system_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      height: 525px;
  }
  .system_wrap .system__header {
      width: 100%; 
      margin-bottom: 19px;
  }
  .system_wrap .system__header .system__header_title {
      display: block;
      margin-bottom: 10px;
      font-size: 12px;
  }
  .system_select_button {
      width: 26px;
      height: 26px;
  }
  .system_wrap > .system_box:nth-of-type(1) {
      width: 50%;
      height: 100%;
  }
  .system_wrap .system_box:last-of-type {
      width: 45%;
      height: 100%;
  }
  .system_wrap .system_box .system_box__title {
      font-size: 14px;
      font-weight: bold;
      padding-bottom: 15px;
  }
  .system_wrap .system_box .system_box__content {
      height: 634px;
  }
  .system_wrap .system_box .system_box__content .ant-tabs-tab {
      font-size: 12px;
      height: 32px;
      line-height: 30px;
  }
  .system_wrap .system_box .system_box__content .ant-tabs-nav-container{
      height: 32px;
  }
  .system_wrap .system_box .system_box__content .ant-tabs-card .ant-tabs-content {
      margin-top: -16px;
  } 
  .system_content__header {
      display: flex;
      height: 32px;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
  }
  .system_content__header span {
    font-weight: bold;
    font-size: 16px;
  }
  .system_content__header .select_reset_button {
      width: 68px;
      height: 30px;
      font-size: 12px;
  }
  .system_content__section {
      height: 465px;
      border: 1px solid #e8e8e8;
  } 
  .system_section__table {
      margin-top: 10px;
  } 
`;

export default Wrapper;
