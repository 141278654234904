import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { PageTemplate, Header, Footer, Collapse } from "components";
import { Collapse as AntCollapse } from "antd";
import Wrapper from "./Styled";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import fetchAbsolute from "utils/fetchAbsolute";
import {
  setAdminMemberValue,
  resetAdminMemberValue,
  setAdminMemberPageInfo,
} from "store/adminMember/action";
import AdminMemberTop from "./AdminMemberTop";
import AdminMemberBottom from "./AdminMemberBottom";
import { useIntl } from "react-intl";

/**
 * Filename: AdminMemberManage/index.js
 * Description: Admin -> 회원 관리 컴포넌트
 */

const { Panel } = AntCollapse;

const initialInputValues = {
  empNum: [],
  orgCds: [],
  abLevel: [],
  dwpUserLevel: [],
};

const initialPageInfo = {
  sort: "empNum,desc",
  page: 1,
  size: 10,
  total: 0
};


const AdminMemberManage = () => {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const adminMember = useSelector((state) => state.get(getUniqueKey("adminMember")));

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const [openCollapse, setOpenCollapse] = useState(true);

  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [searchValues, setSearchValues] = useState({ ...initialInputValues });
  const [tableLoading, setTableLoading] = useState(false);
  const [searchObj, setSearchObj] = useState({});
  const [excelBody, setExcelBody] = useState({});

  const fetchTableData = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    };

    setTableLoading(true);

    const parameter = {
      companyCd: searchValues.orgCds || [],
      empNum: searchValues.empNum || [],
      abLevel: searchValues.abLevel || [],
      dwpUserLevel: searchValues.dwpUserLevel || [],
      // page: +pageInfo.page,
      // pageSize: +pageInfo.pageSize,
    };

    const result = await fetchAbsolute(
      "post",
      `/common/memberListWithPageMulVal?page=${page.page - 1}&size=${page.size}&sort=${page.sort}`,
      {
        data: {
          ...parameter,
        },
      }
    );
    setTableLoading(false);
    if (result.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    }
    setExcelBody({ ...parameter });

    const { data } = result;
    setPageInfo({ ...page, total: +data.totalItemsCount });
    setTableData([...data.memberList]);
  };

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'empNum';
    setSortedInfo(sorter);
    let orderBy = "";
    if (!sorter.order) {
      order = "empNum"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order + orderBy,
      page: pagination.current
    };

    setSortedInfo(sorter);
    fetchTableData({ ...searchObj });
  };

  const onSelectOption = (value) => {
    const searchObj = {
      ...pageInfo,
      page: 1,
      size: +value,
    };
    fetchTableData({ ...searchObj });
  };

  const onChangeSearchObject = (key, value) => {
    dispatch(setAdminMemberValue(key, value));
  };

  /* 검색 기능 */
  const onClickSearchButton = () => {
    replaceSearch({ ...adminMember.inputValues });
    dispatch(setAdminMemberPageInfo({ page: 1 }));
  };

  const replaceSearch = (searchObj) => {
    Object.keys(searchObj).forEach((key) => {
      const data = searchObj[key];
      if (Object.keys(data).length <= 0) {
        delete searchObj[key];
        return;
      }
      if (["empNum"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          tmpArr.push(data[i].empNum);
        }
        searchObj[key] = tmpArr;
      } else if (["orgCds"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          tmpArr.push(data[i].codeId);
        }
        searchObj[key] = tmpArr;
      } else if (["abLevel", "dwpUserLevel"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          tmpArr.push(data[i].cd);
        }
        searchObj[key] = tmpArr;
      }
    });
    setSearchValues({ ...searchObj });
    setSearchObj({ ...searchObj });
  };

  const onClickReset = () => {
    dispatch(resetAdminMemberValue());
    setSearchValues({ ...initialInputValues });
    setPageInfo({ ...initialPageInfo });
  };

  useEffect(() => {
    fetchTableData();
  }, [searchValues]);

  useEffect(() => {
    //페이지 처음 접속할 때만 reset
    return () => onClickReset();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper
        style={{
          padding: "30px 50px",
        }}
      >
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <AdminMemberTop
              onChangeSearchObject={onChangeSearchObject}
              onClickSearchButton={onClickSearchButton}
              onClickReset={onClickReset}
            />
          </Panel>
        </Collapse>
        <AdminMemberBottom
          searchObj={searchObj}
          tableData={tableData}
          tableLoading={tableLoading}
          onChangeTableColumns={onChangeTableColumns}
          onSelectOption={onSelectOption}
          excelBody={excelBody}
          pageInfo={pageInfo}
          sortedInfo={sortedInfo}
        />
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminMemberManage;
