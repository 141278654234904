import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { TimeTable } from "components";
import moment from "moment";
import { Badge } from "antd";

const HFWorkFlowScheduleWeekly = ({
  date,
  onChangeDate,
  scheduleDatas,
  calInfo
}) => {
  const badgeColor = {
    WIP: "processing",
    COMPLETED: "success",
    RUN_FAILED: "error",
    Scheduled: "default",
  };
  const [dataSource, setDataSource] = useState([]);

  const columns = [];

  // 컬럼 세팅
  for (let i = 0; i < 7; i++) {
    const obj = {
      key: i,
      title: `${moment(date).day(i).format("YYYY-MM-DD")} (${moment(date)
        .day(i)
        .format("ddd")})`,
      dataIndex: moment(date).day(i).format("YYYYMMDD"),
      width: 115,
      align: "center",
    };
    // Row의 첫 번째 시간 컬럼을 한 줄로 만듬
    obj.render = (value, record) => {
      if (value) {
        return (
          <div
            className="schedule_weekly__name_box"
            key={record.workflowId}
            style={{
              zIndex: 0,
              display: "flex",
              // background: barColor[value],
              color: "#000",
            }}
            onClick={() => onClickDate(i)}
          >
            <Badge
              status={badgeColor[value]}
            // text={`${record.workflowNm}(${record.workflowId})`}
            />
            <span style={{ fontSize: 12, marginLeft: 8 }}>{record.workflowNm}({record.workflowId})</span>
          </div>
        );
      }
    };
    columns.push({ ...obj });
  }

  const onClickDate = (index) => {
    // console.log("week캘린더클릭", index);
    const specificDay = moment(date).day(index).format("YYYYMMDD");
    const yyyymm = moment(date).day(index).format("YYYYMM");
    const dd = moment(date).day(index).format("DD");

    onChangeDate({
      yyyymm: yyyymm,
      dd: dd,
      specificDay: specificDay,
      calendar: "daily"
    });
  };

  const getDatas = async () => {
    setDataSource(
      Object.values(scheduleDatas)
        ?.map((data, index) => {
          return {
            ...data,
            key: index,
          };
        }).reduce(function (acc, current) {
          if (acc.findIndex(({ name }) => name === current.name) === -1) {
            acc.push(current);
          }
          return acc;
        }, [])
    );
  };

  useEffect(() => {
    if (Object.keys(scheduleDatas).length <= 0 || date.length <= 0 || calInfo.calendar !== 'weekly') return;
    getDatas();
  }, [date, scheduleDatas, calInfo]);

  return (
    <div className="workflow-schedule-weekly">
      <TimeTable
        bordered
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        type='workflow'
      />
    </div>
  );
};

export default withRouter(HFWorkFlowScheduleWeekly);
