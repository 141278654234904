import React from "react";
import { Text, Table, Button, PermissionComponent } from "components";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * Workflow > 스케줄 관리 현황 > 스케줄 현황
 */
const ScheduleStatus = ({ onClickEditStatusBtn, scheduleList, schduleDeleteBtn, tableLoading }) => {
  const { formatMessage } = useIntl();

  const scheduleType = formatMessage({ id: "T0010" });
  const endDate = formatMessage({ id: "T0021" });

  const columns = [{
    title: <FormattedMessage id="T1059" />,
    key: 1,
    align: "center",
    render: (text, record, index) => index + 1,
  }, {
    title: `${formatMessage({ id: "T0564" })}`,
    dataIndex: "name",
    key: 2,
  }, {
    title: scheduleType,
    dataIndex: "exeCycle",
    key: 3,
  }, {
    title: <FormattedMessage id="T0020" />,
    dataIndex: "startDate",
    width: 90,
    key: 4,
    align: "center",
    render: (date) => {
      return moment(date).format("YYYY-MM-DD");
    },
  }, {
    title: endDate,
    dataIndex: "endDate",
    width: 90,
    key: 5,
    render: (date) => {
      return !date ? "" : moment(date).format("YYYY-MM-DD");
    },
  }, {
    title: `${formatMessage({ id: 'T0394' })}`,
    dataIndex: "createdOn",
    width: 90,
    key: 6,
  }, {
    title: "",
    width: 130,
    key: 7,
    render: (data, record) => (

      <div className="schedule_status__button_wrap">
        {record.scheduleType === "INSTANT" ?
          <Button
            type="default"
            width="50"
            height="24"
            onClick={() => onClickEditStatusBtn(data)}
            disabled
          >
            <FormattedMessage id="T0054" />{/* 수정 */}
          </Button> : <Button
            type="default"
            width="50"
            height="24"
            onClick={() => onClickEditStatusBtn(data)}

          >
            <FormattedMessage id="T0054" />{/* 수정 */}
          </Button>
        }
        <Button
          type="default"
          width="50"
          height="24"
          onClick={() => schduleDeleteBtn(data)}
        >
          {formatMessage({ id: 'T0072' })}{/* 삭제 */}
        </Button>

      </div>

    ),
  },
  ];

  return (
    <div className="status_box">
      <div className="status_box__title">
        <Text>{formatMessage({ id: "T0565" })}</Text>
      </div>
      <div className="status_box__info">
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={scheduleList}
          locale={{ emptyText: `${formatMessage({ id: 'T0566' })}` }}
          rowKey={(item) => item.name + item.startTime}
        />
      </div>
    </div>
  );
};

export default ScheduleStatus;