import React, { useEffect, useState } from "react";
import { Table, Text, Input, Button, FormTable } from "components";
import { Select } from "antd";
import styled from "styled-components";
import fetchAbsolute from "utils/fetchAbsolute";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;

/**
 * Filename: AdminDeviceBox/index.js
 * Description: Device Pool Add -> Device Pool 추가
 */

const Wrapper = styled.div`
  .ant-table-thead th {
    height: 34px;
    padding: 0px 16px;
    font-size: 12px;
  }
  .ant-table-tbody td {
    font-size: 12px;
  }
  .system_wrap {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .system_select_button {
    width: 26px;
    height: 26px;
  }
  .system_wrap .system_box:first-child {
    float: left;
    
    height: 100%;
  }
  .system_wrap .system_box:last-child {
    float: right;
   
    height: 100%;
  }
  .system_wrap .system_box .system_box__title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 15px;
    height: 32px;
  }
  .system_wrap .system_box .system_box__content {
    height: 550px;
  }
  .system_wrap
    .system_box
    .system_box__content
    .ant-tabs-card
    .ant-tabs-content {
    margin-top: -16px;
  }

  .system_content__header {
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
  }
  .system_section__table {
    margin-top: 10px;
  }
  .system_section__table .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;
  }

  .select_reset_button {
    width: 80px;
    height: 27px;
    font-size: 14px;
  }
`;


const AvailableBot = ({
  dataSource,
  rowSelection,
  onChangeSearch,
  avaSearch,
  tableLoading,
}) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: <FormattedMessage id="T1111" />, // Status
      dataIndex: "status",
      key: "status",
      width: "42%",
      align: "center",
    },
    {
      title: <FormattedMessage id="T1096" />, // Device Name
      dataIndex: "hostName",
      key: "hostName",
      width: "35%",
      align: "center",
    },
    {
      title: <FormattedMessage id="T1060" />, // Device Pool
      dataIndex: "poolName",
      key: "poolName",
      width: "23%",
      align: "center",
    },
  ];
  return (
    <div className="system_content__section">
      <Input
        placeholder={formatMessage({ id: 'T0687' })} // 검색하세요
        style={{ height: 32 }}
        onChange={onChangeSearch}
        value={avaSearch}
      />
      <div className="system_section__table">
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={dataSource}
          rowSelection={rowSelection}
          scroll={{ y: 400 }}
          rowKey={(e) => e.id}
        />
      </div>
    </div>
  );
};

const SelectedBot = ({
  rowSelection,
  selectDatas,
  selectedSearch,
  onChangeSelectedSearch,
}) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: <FormattedMessage id="T1111" />, // Status
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "42%",
    },
    {
      title: <FormattedMessage id="T1096" />, // Device Name
      dataIndex: "hostName",
      key: "hostName",
      align: "center",
      width: "35%",
    },
    {
      title: <FormattedMessage id="T1060" />, // Device Pool
      dataIndex: "poolName",
      key: "poolName",
      align: "center",
      width: "23%",
    },
  ];
  return (
    <div className="system_content__section">
      <Input
        placeholder={formatMessage({ id: 'T0687' })} // 검색하세요
        onChange={onChangeSelectedSearch}
        value={selectedSearch}
        style={{ height: 32 }}
      />
      <div className="system_section__table">
        <Table
          columns={columns}
          dataSource={selectDatas}
          rowSelection={rowSelection}
          scroll={{ y: 400 }}
          rowKey={(e) => e.id}
        />
      </div>
    </div>
  );
};

const DevicePoolAddBox = ({ history }) => {
  const { formatMessage } = useIntl();

  const inputValueAlert = formatMessage({ id: 'T0135' }); // 입력값을 넣어주세요.
  const selectPlaceholder = formatMessage({ id: 'T0688' }); // 선택해주세요

  const [crValue, setCrValues] = useState("");
  const [selectDatas, setSelectDatas] = useState([]);
  const [checkedDatas, setCehckedDatas] = useState([]);
  const [systemDatas, setSystemDatas] = useState([]);
  const [avaSearch, setAvaSearch] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [systemSelDatas, setSystemSelDatas] = useState([]);
  const [crList, SetCrList] = useState([]);
  const [poolGroupList, setPoolGroupList] = useState([]);
  const [selectCr, setSelectCr] = useState({});
  const [dataSource, setDataSource] = useState([]);
  const [selectPoolGroup, setSelectPoolGroup] = useState("");
  const [tableLoading, setTableLoading] = useState(false);

  const [inputValues, setInputValues] = useState({
    poolName: "",
    poolDescription: "",
  });

  const onChangeSelectedSearch = (e) => {
    setSelectedSearch(e.target.value);
  };
  const onChangeAvaSearch = (e) => {
    setAvaSearch(e.target.value);
  };

  const onClickResetButton = () => {
    setSelectDatas([]);
  };

  const onChangeCr = (e) => {
    const filterValue = crList.filter((element) => element.crCd === e);
    setSelectCr(filterValue);
    setCrValues(e);
  };

  const onChangePoolGroup = (e) => {
    setSelectPoolGroup(e);
  };

  const onChangeInputValue = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onClickPoolAdd = async () => {
    if (inputValues.poolDescription === "" || inputValues.poolName === "" || selectDatas.length === 0 || selectCr[0].crType === ""
    ) {
      if (selectDatas.length === 0) {
        alert(formatMessage({ id: 'T0134' })); // 선택 봇을 넣어주세요.
        return;
      }
      if (selectCr[0].crType === "") {
        alert(inputValueAlert);
        return;
      }
      if (inputValues.poolDescription === "" || inputValues.poolName === "") {
        alert(inputValueAlert);
        return;
      }
    }
    if (selectCr[0].crType === "DIY") {
      setSelectPoolGroup("");
    }
    if (selectCr[0].crType === "COE") {
      if (selectPoolGroup.length === 0) {
        alert(formatMessage({ id: 'T0136' })); // Device Pool Group 을 설정해주세요
        return;
      }
    }

    const result = await fetchAbsolute(
      "post",
      `/admin/devicePool/saveDevicePool`,
      {
        data: {
          crType: selectCr[0].crType,
          devicePoolGroupCd: selectPoolGroup,
          crCode: selectCr[0].crCd,
          crUri: selectCr[0].crUri,
          devicePoolName: inputValues.poolName,
          description: inputValues.poolDescription,
          devices: selectDatas.map((e) => e.hostName),
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (result.status === 200 || result.status === 201) {
      alert(formatMessage({ id: 'T0137' })); // Device Pool이 추가 되었습니다.
      history.push("/admin/devicepool")
    } else {
      if (result.data.message === 'devicePoolName is exist')
        return alert(formatMessage({ id: 'T0138' })) // 중복된 Device Pool Name 이 존재 합니다.
      throw new Error(result.status);
    }

    setCrValues("");
    setSelectPoolGroup("");
    setDataSource([]);
    setSelectDatas([]);
    setInputValues({
      poolName: "",
      poolDescription: "",
    });
  };
  // 선택 버튼 클릭 시 insert하는 이벤트
  const onClickSelectButton = () => {
    const tmpArr = [...selectDatas];

    // 중복 선택 확인
    checkedDatas.forEach((v) => {
      const val = dataSource.find((ele) => ele.id === v);
      if (tmpArr.findIndex((tmp) => tmp.id.toString() === val.id.toString()) === -1) {
        tmpArr.push({ ...val });
      }
    });
    setSelectDatas([...tmpArr]);
    setCehckedDatas([]);
  };
  // 선택 버튼 클릭 시 delete 이벤트
  const onClickPoolDeleteButton = (e) => {
    //선택 값으로 넘어 온 것들 temparr 에 담는다
    const tempArr = [...selectDatas];
    const temp = tempArr.filter((x) => !checkedDatas.includes(x.id));
    setSelectDatas([...temp]);
    setCehckedDatas([]);
  };

  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCehckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCehckedDatas(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        disabled: record.poolName.length > 0,
      };
    },
  };
  useEffect(() => {
    setSystemDatas([...dataSource]);
  }, [dataSource]);

  useEffect(() => {
    // 전체 데이터 중에서 searchKeyword를 포함하고 있는 데이터들을 추출
    const filterDatas = [
      ...dataSource.filter((data) => {
        return (
          data.status?.toLowerCase().includes(avaSearch.toLowerCase()) ||
          data.hostName?.toLowerCase().includes(avaSearch.toLowerCase()) ||
          data.poolName?.toLowerCase().includes(avaSearch.toLowerCase())
        );
      }),
    ];
    setSystemDatas([...filterDatas]);
  }, [avaSearch]);

  useEffect(() => {
    // 선택된 데이터 중에서 searchKeyword를 포함하고 있는 데이터들을 추출
    const filterSelDatas = [
      ...selectDatas.filter((data) => {
        return (
          data.status?.toLowerCase().includes(selectedSearch.toLowerCase()) ||
          data.hostName?.toLowerCase().includes(selectedSearch.toLowerCase()) ||
          data.poolName?.toLowerCase().includes(selectedSearch.toLowerCase())
        );
      }),
    ];
    setSystemSelDatas([...filterSelDatas]);
  }, [selectedSearch]);

  useEffect(() => {
    setSystemSelDatas([...selectDatas]);
  }, [selectDatas]);

  useEffect(() => {
    if (Object.keys(selectCr).length === 0) {
      setDataSource([]);
      return;
    }
    const fetchDevice = async () => {
      setTableLoading(true);
      const result = await fetchAbsolute(
        "post",
        `/admin/devicePool/getDeviceList?crCode=${selectCr[0].crCd}`
      );
      setTableLoading(false);
      setDataSource([...result.data]);
    };
    fetchDevice();
  }, [selectCr]);

  useEffect(() => {
    const fetchPoolCr = async () => {
      const result = await fetchAbsolute("get", "/common/getControlRoomList");
      SetCrList([...result.data]);
    };
    fetchPoolCr();

    const fetchPoolGroup = async () => {
      const PoolGroupResult = await fetchAbsolute(
        "get",
        "/admin/commonMaster/getDevicePoolList"
      );
      setPoolGroupList([...PoolGroupResult.data]);
    };
    fetchPoolGroup();
  }, []);

  return (
    <div>
      <FormTable border={true} >
        <colgroup>
          <col width='13.85%' />
          <col width='36.08%' />
          <col width='13.85%' />
          <col width='36.08%' />
        </colgroup>
        <tbody>
          <tr>
            <th >
              <Text><FormattedMessage id="T1036" /></Text>{/* CR */}
            </th>
            <td>
              <Select
                style={{ width: 320, height: 40 }}
                name="crselect"
                onChange={onChangeCr}
                value={crValue || undefined}
                placeholder={selectPlaceholder}
                defaultValue=""
              >
                <Option value="">{selectPlaceholder}</Option>
                {crList.map((v) => (
                  <Option key={v.crCd} value={v.crCd}>{v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ?
                    v.crTypeDesc + ` ${formatMessage({ id: "T0683" })}` // (운영)
                    : v.crTypeDesc + ` ${formatMessage({ id: "T0682" })}` // (개발)
                  }
                  </Option>
                ))}
              </Select>
            </td>
            <th >
              <Text>{formatMessage({ id: "T0727" })}</Text>{/* Device Pool Group */}
            </th>
            <td>
              <Select
                style={{ width: 320, height: 40 }}
                name="poolgroupselect"
                defaultValue=""
                disabled={crValue.substr(0, 3) === "COE" ? false : true}
                placeholder={selectPlaceholder}
                onChange={onChangePoolGroup}
                value={selectPoolGroup || undefined}
              >
                <Option value="">{selectPlaceholder}</Option>
                {poolGroupList.map((e) => (
                  e.useYn === "Y" &&
                  <Option value={e.cd}>{e.cdNm}</Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <th scope="col">
              <Text><FormattedMessage id="T1061" /></Text>{/* Device Pool Name */}
            </th>
            <td>
              <Input
                name="poolName"
                onChange={onChangeInputValue}
                value={inputValues.poolName}
                style={{
                  width: 320,
                  marginRight: 20,
                }}
              />
            </td>
            <th>
              <Text><FormattedMessage id="T1062" /></Text>{/* Device Pool Description */}
            </th>
            <td>
              <Input
                name="poolDescription"
                onChange={onChangeInputValue}
                value={inputValues.poolDescription}
                style={{
                  width: 320,
                  marginRight: 20,
                }}
              />
            </td>
          </tr>
          <tr>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text><FormattedMessage id="T0355" /></Text>{/* Device */}
            </th>

            <td colSpan="4">
              <Wrapper>
                <div className="system_wrap">
                  <div className="system_box">
                    <div className="system_box__title">
                      <Text>{formatMessage({ id: 'T0689' })}</Text>{/* 가용 Bot Runner */}
                    </div>
                    <div className="system_box__content">
                      <AvailableBot
                        rowSelection={rowSelection}
                        dataSource={systemDatas}
                        onChangeSearch={onChangeAvaSearch}
                        avaSearch={avaSearch}
                        tableLoading={tableLoading}
                      />
                    </div>
                  </div>
                  <div>
                    <Button
                      className="system_select_button"
                      type="primary"
                      onClick={onClickSelectButton}
                    >
                      &gt;
                    </Button>
                    <br /> <br />
                    <Button
                      className="system_select_button"
                      type="primary"
                      onClick={onClickPoolDeleteButton}
                    >
                      &lt;
                    </Button>
                  </div>

                  <div className="system_box">
                    <div className="system_box">
                      <div style={{ display: "flex" }}>
                        <div
                          style={{ marginRight: "auto" }}
                          className="system_box__title"
                        >
                          <Text>{formatMessage({ id: 'T0690' })}</Text>{/* 선택 Bot Runner */}
                        </div>
                        <div style={{ display: "flex" }}>
                          <Button
                            style={{ marginLeft: "auto" }}
                            className="select_reset_button"
                            onClick={onClickResetButton}
                          >
                            <FormattedMessage id="T0037" />{/* 초기화 */}
                          </Button>
                        </div>
                      </div>
                      <div className="system_box__content">
                        <SelectedBot
                          selectDatas={systemSelDatas}
                          rowSelection={rowSelection}
                          onChangeSelectedSearch={onChangeSelectedSearch}
                          selectedSearch={selectedSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Wrapper>
            </td>
          </tr>
        </tbody>
      </FormTable>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 20,
          background: "white",
        }}
      >
        <Button width="144" height="40" type="primary" onClick={onClickPoolAdd}>
          <FormattedMessage id="T0049" />{/* 추가 */}
        </Button>
        &nbsp; &nbsp;
        <Link to="/admin/devicepool">
          <Button width="144" height="40">
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default DevicePoolAddBox;
