import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import {
    TypePicker,
    Empty
} from 'components';
import { useIntl } from "react-intl";
import { Column } from '@ant-design/plots';
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";

const { Title } = Typography;

/**
 * 대시보드 > Insight > DIY License 현황
 * SKC -> PBL 로 변경해야해
 * 
 */

const DIYLicenseStatus = () => {
    const { formatMessage } = useIntl();
    const [standardDate, setStandardDate] = useState(moment());
    const [licenseStatus, setLicenseStatus] = useState([]);

    const config = {
        isGroup: true,
        tooltip: false,
        xField: 'type',
        yField: 'value',
        color: '#fdc459',
        dodgePadding: 3,
        legend: false,
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position', },
                { type: 'interval-hide-overlap', },
                { type: 'adjust-color', },
            ],
        },
    };

    const onChangeDate = (date) => {
        setStandardDate(date);
    };

    const getDiyRoiList = async () => {
        const result = await fetchAbsolute('post', `/dashboard/OperInsight/DiyLicenseStatusList`, {
            data: {
                regDate: standardDate.format("YYYY-MM-DD")
            }
        });
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        tmpArr.push(
            {
                key: data.date,
                type: '사용가능 개수',
                value: +data.availableCnt || 0
            },
            {
                key: data.date,
                type: '사용중인 개수',
                value: +data.usedCnt || 0
            },
        )
        setLicenseStatus([...tmpArr]);
    };

    useEffect(() => {
        getDiyRoiList();
    }, [standardDate]);

    return (
        <div className='box__container'>
            <div className='bot__title'>
                {/* DIY License 현황 */}
                <Title
                    className='main__title'
                    level={4}
                >
                    {formatMessage({ id: "T1526" })}
                </Title>
                <div className="condition">
                    <div>{formatMessage({ id: "T1512" })}</div>{/* 기준일자 */}
                    <div>
                        <TypePicker
                            placeholder={moment().format("YYYY.MM.DD")}
                            defaultValue={moment()}
                            value={standardDate}
                            format="YYYY-MM-DD"
                            width="170"
                            height="32"
                            onChange={onChangeDate}
                            allowClear={false}
                        />
                    </div>
                </div>
            </div>
            <Divider />
            {
                licenseStatus.length > 0 ?
                    <Column {...config}
                        className="dashboard_graph"
                        data={licenseStatus}
                        style={{ height: "300px" }}
                        key="diy-license-status-graph"
                    /> : <Empty />
            }
        </div>
    )
}
export default DIYLicenseStatus