import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    width: 1600px;

    .admin_msgtmp__select {
        width: 300px;
        height: 32px;
    }
    .admin_msgtmp__btn_wrap {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        gap: 10px;
    }
    .admin_msgtmp__info_label {
        display: block;
        padding: 15px 0;
        font-size: 12px;
        color: #ff6600;
    }
    .admin_msgtmp__info_table {
        border: 2px solid #ff6600;
        margin-bottom: 10px;
    }
    .admin_msgtmp__info_table tr {
        height: 30px;
    }
    .admin_msgtmp__info_table tr:first-of-type {
        border-top: none;
    }
    .admin_msgtmp__info_table tbody tr:last-of-type {
        border-bottom: none;
    }
    .admin_msgtmp__info_table th {
        background: #fce6ba;
    }
    .admin_msgtmp__info_table tr {
        border-bottom: 1px solid #d0d1d6;
    }
    .admin_msgtmp__info_table th,
    .admin_msgtmp__info_table td {
        font-size: 10px;
    }
    .admin_msgtmp__textarea {
        resize: none;
        height: 500px;
    }
    button:focus {
      outline: 0;
    } 
`;

export default Wrapper;