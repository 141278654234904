import React from "react";
import "antd/dist/antd.css";
import footerImage from "assets/error_footer.png";
import "assets/error.css";
import { Image } from 'components';

const NotFound = () => {
  return (
    <div className="error_page__wrap">
      <div className="error__info_box">
        <span className="error__code">404</span>
        <h3 className="error__title">Page not found</h3>
        <div className="error__content">
          <p>Sorry, but the page you are looking for is not found</p>
          <p>Make sure you have typed the current URL.</p>
        </div>
      </div>
      <span className="error__bg_text">404</span>
      <Image
        className="error__footer"
        src={footerImage}
        style={{ width: "100%" }}
        alt="error_img"
      />
    </div>
  );
};

export default NotFound;