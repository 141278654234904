import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { Text, Button, Table } from "components";
import { Input as AntInput } from "antd";
import Wrapper from "../Styled";
import { FormattedMessage, useIntl } from "react-intl";
const { TextArea } = AntInput;

const GuideSteps = () => {

  const { formatMessage } = useIntl();

  const title = formatMessage({ id: "T0017" }); // 제목
  const firstBlankNotAllowed = formatMessage({ id: 'T0230' }); // 해당 항목에는 첫 글자 공백을 사용할 수 없습니다.

  const [guideSteps, setGuideSteps] = useState([]);
  const [language, setLanguage] = useState('');

  const columns = [
    {
      key: "index",
      title: "",
      width: 10,
      render: (value, row, index) => index + 1
    },
    {
      key: "cdNm",
      title: formatMessage({ id: 'T0808' }), // 단계
      dataIndex: "cdNm",
      width: 150
    },
    {
      key: "attrCd1",
      title: title,
      dataIndex: "attrCd1",
      render: (value, row) => {
        return (
          <TextArea
            value={value}
            style={{ resize: "none", height: "fit-content" }}
            onChange={(e) => onChangeInput(row.cd, "attrCd1", e.target.value)}
          />
        );
      },
    },
    {
      key: "attrCd2",
      title: formatMessage({ id: 'T0361' }), // 내용
      dataIndex: "attrCd2",
      render: (value, row) => {
        return (
          <TextArea
            value={value}
            style={{ resize: "none", height: "fit-content" }}
            onChange={(e) => onChangeInput(row.cd, "attrCd2", e.target.value)}
          />
        );
      },
    },
    {
      key: "attrCd3",
      title: formatMessage({ id: 'T0809' }), // 연결 URL
      dataIndex: "attrCd3",
      render: (value, row) => {
        return (
          <TextArea
            value={value}
            style={{ resize: "none", height: "fit-content" }}
            onChange={(e) => onChangeInput(row.cd, "attrCd3", e.target.value)}
          />
        );
      },
    },
  ];

  const onChangeInput = (cd, label, value) => {
    const tmpArr = [...guideSteps];
    const index = tmpArr.findIndex((v) => v.cd === cd);
    if (index === -1) return;
    if (value === " ") {
      alert(firstBlankNotAllowed);
      return;
    }
    tmpArr[index][label] = value;
    setGuideSteps([...tmpArr]);
  };

  /* 수정 */
  const onClickEditGuide = async () => {
    const parameter = [];

    /* categoryId, useYn 변결될 값이 아니라 고정 */
    guideSteps.map((v) => {
      return parameter.push({
        categoryId: "STEP_GUIDE",
        cd: v.cd,
        cdNm: v.cdNm,
        useYn: "Y",
        attrCd1: v.attrCd1,
        attrCd2: v.attrCd2,
        attrCd3: v.attrCd3,
        sort: v.sort,
        locale: language,
      });
    });

    const modifyCd = await fetchAbsolute("post", "/common/commonCd/addList", {
      data: [...parameter],
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (modifyCd.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    else {
      alert(formatMessage({ id: 'T0263' }));
      //가이드 스텝 정보가 수정되었습니다.
    }
  };

  /* 초기화 */
  const onClickReset = async () => {
    const result = await fetchAbsolute(
      "get",
      `/common/commonCd/getCateIdAndUseYn/STEP_GUIDE?sortVal=asc`
    );
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setGuideSteps([...result.data]);
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
    const data = result.data.filter(v => v.useYn === 'Y');
    setLanguage(data[0].cd);
  }

  /* CommonCd 가져오기 */
  useEffect(() => {
    const getStepGuideCd = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/STEP_GUIDE?sortVal=asc`
      );
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setGuideSteps([...result.data]);
    };
    getStepGuideCd();
    fetchLanguageList();
  }, []);

  return (
    <Wrapper>
      <div className="common__container">
        <Text className="common__title">{formatMessage({ id: 'T0797' })}</Text>{/* 단계별 가이드 */}
        <div style={{ paddingTop: 10.8 }} className="steps_table">
          <Table
            columns={columns}
            dataSource={guideSteps}
            rowKey={(e) => e.cd}
          />
        </div>
        <div className="submit_button__section">
          <Button
            type="primary"
            className="submit_button"
            onClick={onClickEditGuide}
          >
            <FormattedMessage id="T0054" />{/* 수정 */}
          </Button>
          <Button
            type="default"
            className="submit_button"
            onClick={onClickReset}
          >
            <FormattedMessage id="T0037" />{/* 초기화 */}
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};
export default GuideSteps;