import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { Text } from "components";
import Wrapper from "./Styled";
import { useIntl, FormattedMessage } from "react-intl";

const TreeNode = Tree.TreeNode;

/**
 * Filename: DevicePool/index.js
 * Description: Device Pool 컴포넌트이다.
 */

const DevicePool = ({
  visible,
  crCode = '',
  onOk = () => { },
  onCancel = () => { },
  mode = '',
  defaultData = []
}) => {
  const { formatMessage } = useIntl();

  const [devicePoolDatas, setDevicePoolDatas] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [isMultiple, setIsMultiple] = useState(false);
  const [expand, setExpand] = useState([]);

  const onSelectTree = async (codeIds) => {
    setSelectedKeys(codeIds);
  };
  
  const onOkModal = () => {
    if (mode) {
      let tmpArr = [];
      for (let i in selectedKeys) {
        const result = devicePoolDatas.find(v => v.dpId === selectedKeys[i])
        tmpArr.push(result);
        // level 2 조건을 주는 이유가 뭔지 모르겠음
        // const result = devicePoolDatas.find(v => +v.level === 2 && v.dpId === selectedKeys[i])
        // tmpArr.push(result);
      }
      onOk(tmpArr || []);
    } else {
      const selectData = crCode.includes('COE') ? devicePoolDatas.find((v) => +v.level === 2 && v.dpId === selectedKeys[0]) : devicePoolDatas.find(v => v.dpId === selectedKeys[0]);
      onOk(selectData || {});
    }
    if (selectedKeys.length < 1) {
      return alert(formatMessage({ id: "T0146" })); // Device Pool을 선택하세요
    }
  };

  useEffect(() => {
    if (!crCode || !visible) return;
    const fetchData = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/devicePool/getDevicePoolGroupListByCrCd?CrCode=${crCode}`
      );
      setDevicePoolDatas([...result.data]);
    };
    fetchData();
  }, [crCode, visible]);

  useEffect(()=>{
    if(defaultData.length<1) {
      setSelectedKeys([]);
    } else {
        //defaultData가 1개 이상있으면 무조건 expand가 열려있어야 하니까
        const dpIdArr = defaultData.map(c=>c.dpId);
        const tempArr = [];
        tempArr.push(defaultData[0].dpGroupCd);
        setExpand(tempArr);
        setSelectedKeys(dpIdArr);
    }  
  },[visible]);

  /* Device Pool 목록 복수 검색  */
  useEffect(() => {
    if (!mode) return;
    setIsMultiple(true);
  }, [mode]);

  return (
    <Wrapper
      width={500}
      visible={visible}
      title={formatMessage({ id: "T1060" })} // Device Pool
      onOk={onOkModal}
      onCancel={onCancel}
    >
      <div className="device_pool_wrapper">
        <div className="device_pool_container">
          <div className="device_pool_title">
            <Text className="device_pool_title_text">
              <FormattedMessage id="T1060" />
            </Text>
          </div>
          <div className="device_pool_box">
            <Tree
              // treeData={devicePoolDatas}
              selectedKeys={selectedKeys}
              onSelect={onSelectTree}
              multiple={isMultiple}
              // defaultExpandedKeys={expand}
              expandedKeys={expand}
              onExpand={(e) => setExpand(e)}
              // autoExpandParent={true}
            >
            {/* {
                  devicePoolDatas
                    .filter((v) => +v.level === 1)
                    .map((v) => {
                      return (
                        <TreeNode selectable={false} title={v.dpNm} key={v.dpGroupCd}>
                          {devicePoolDatas
                            .filter(
                              (n) => +n.level === 2 && v.dpGroupCd === n.dpGroupCd
                            )
                            .map((data) => {
                              return <TreeNode title={data.dpNm} key={data.dpId} />
                            })}
                        </TreeNode>
                      );
                    })
                }    */}
              {
                // COE는 Group이 있어서 레벨이 2까지라 조건
                crCode.includes('COE') ?
                  devicePoolDatas
                    .filter((v) => +v.level === 1)
                    .map((v) => {
                      return (
                        <TreeNode selectable={false} title={v.dpNm} key={v.dpGroupCd}>
                          {devicePoolDatas
                            .filter(
                              (n) => +n.level === 2 && v.dpGroupCd === n.dpGroupCd
                            )
                            .map((data) => {
                              return <TreeNode title={data.dpNm} key={data.dpId} />
                            })}
                        </TreeNode>
                      );
                    })
                  :
                  devicePoolDatas.map((v) => {
                    return (
                      <TreeNode title={v.dpNm} key={v.dpId} />
                    );
                  })
              }
            </Tree>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default DevicePool;
