import React, { useState, useEffect } from "react";
import { Table, Text, Modal } from "components";
import { Select } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;

/**
 * Filename: CrInfo/index.js
 * Description: cr 관리
 */

const CrInfo = ({ history }) => {
  const { formatMessage } = useIntl();
  const selectRowPerPage = formatMessage({ id: "T0036" }); // 페이지당 행
  const fmMessage = useFormatMessage();
  const pageSize = [20, 50, 70];

  const columns = [
    {
      key: 1,
      title: <FormattedMessage id="T1067" />,
      dataIndex: "cd",
      align: "center",
      sorter: (a, b) => a.cd.localeCompare(b.cd),
      render: (text, record, index) =>
        record.attrCd5 ? record.attrCd5 + text.substring(3, 10) : text,
    },
    {
      key: 2,
      title: <FormattedMessage id="T0739" />,
      dataIndex: "attrCd2",
      align: "center",
      sorter: (a, b) => a.attrCd2.localeCompare(b.attrCd2),
    },
    {
      key: 3,
      title: <FormattedMessage id="T0740" />,
      dataIndex: "attrCd1",
      align: "center",
      sorter: (a, b) => a.attrCd1.localeCompare(b.attrCd1),
      render: (text, record, index) =>
        record.attrCd5?.length > 0 ? record.attrCd5 : text,
    },
    {
      key: 4,
      title: <FormattedMessage id="T0741" />,
      dataIndex: "cdNm",
      align: "center",
      sorter: (a, b) => a.cdNm.localeCompare(b.cdNm),
      render: (text, record, index) =>
        record.attrCd5?.length > 0
          ? record.cd.includes("DEV")
            ? record.attrCd5 + " (개발)"
            : record.attrCd5 + " (운영)"
          : text,
    },
    {
      key: 5,
      title: <FormattedMessage id="T0742" />,
      dataIndex: "attrCd3",
      align: "center",
      sorter: (a, b) => a.attrCd3.localeCompare(b.attrCd3),
    },
    {
      key: 6,
      title: <FormattedMessage id="T0737" />,
      dataIndex: "useYn",
      align: "center",
      sorter: (a, b) => a.useYn.localeCompare(b.useYn),
    },
  ];

  const [PoolVisible, setPoolVisible] = useState(false);
  const [checkedDatas, setCheckedDatas] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [crList, setCrList] = useState([]);
  const [crPage, setCrPage] = useState(0);

  const onSelectOption = (value) => {
    setRowPerPage(+value);
  };

  const onChangePage = (currentPage) => {
    setCrPage(currentPage);
  };

  const onOkDelete = async () => {
    let fetchFlag = true;
    try {
      const deleteCRInfo = await fetchAbsolute(
        "delete",
        `/common/commonCd/deleteList`,
        {
          data: {
            categoryId: "CR_INFO",
            cd: checkedDatas[0],
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (deleteCRInfo.status === 204 || deleteCRInfo.status === 200) {
        alert(formatMessage({ id: "T0246" }));
        //CR 정보가 삭제되었습니다.
      } else {
        throw new Error(deleteCRInfo.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0247" }));
        //삭제에 실패하였습니다.
      }
    }
    const fetchPoolCr = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/CR_INFO?sortVal=asc`
      );
      setCrList([...result.data]);
    };

    const crInfoUpdate = async () => {
      await fetchAbsolute("get", "/common/updateControlRoomCd");
    };
    crInfoUpdate();
    fetchPoolCr();

    setPoolVisible(false);
  };

  const onCancelDelete = () => setPoolVisible(false);

  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCheckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCheckedDatas(selectedRowKeys);
    },
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => {
        history.push({
          pathname: `/admin/common/crinfo/${record.cd}`,
        });
      },
    };
  };

  useEffect(() => {
    const fetchPoolCr = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/CR_INFO?sortVal=asc`
      );
      setCrList([...result.data]);
    };
    fetchPoolCr();
  }, []);

  useEffect(() => {}, [rowPerPage]);
  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <div className="realtime__table_header">
          <Text>{selectRowPerPage}</Text> &nbsp;
          <Select
            style={{
              width: 89,
              height: 32,
            }}
            name="pagination"
            defaultValue={pageSize[0]}
            onSelect={onSelectOption}
          >
            {pageSize.map((v, index) => (
              <Option key={index} value={v}>
                {fmMessage({ id: "T1228", values: { number: v } })}
              </Option>
            ))}
          </Select>
        </div>
        <Table
          className="realtime__table_content"
          rowKey={(e) => e.cd}
          columns={columns}
          dataSource={crList}
          pagination={true}
          defaultPageSize={+rowPerPage}
          pageSizeVisible={false}
          showSorterTooltip={true}
          rowSelection={rowSelection}
          onRow={onRow}
          // pageOptions={{
          //   total: crList.length,
          //   current: crPage,
          //   pageSize: rowPerPage,
          //   onChange: onChangePage,
          // }}
        />
      </div>

      <Modal
        width={540}
        visible={PoolVisible}
        title={formatMessage({ id: "T0744" })} //?정말로 선택된 CR 정보를 삭제하시겠습니까?
        okText={formatMessage({ id: "T0072" })} //삭제
        onCancel={onCancelDelete}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0744" })}
        </Text>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {formatMessage({ id: "T0745" })}
        </Text>
      </Modal>
    </div>
  );
};

export default CrInfo;
