import React from "react";
import "antd/dist/antd.css";
import footerImage from "assets/error_footer.png";
import "assets/error.css";
import { Image } from 'components';

const ServerError = () => {
  return (
    <div className="error_page__wrap">
      <div className="error__info_box">
        <span className="error__code">500</span>
        <h3 className="error__title">This page isn't working</h3>
        <div className="error__content">
          <p>The server has been deserted for a while.</p>
          <p>please be patient or try agian later.</p>
        </div>
      </div>
      <span className="error__bg_text">500</span>
      <Image
        className="error__footer"
        src={footerImage}
        style={{ width: "100%" }}
        alt="error"
      />
    </div>
  );
};

export default ServerError;