import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .admin_member__table_wrap {
        margin-top: 30px;
    }
    thead[class*="ant-table-thead"] th{
        font-weight: bold;
      }
`;

export default Wrapper;