// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-popover-inner-content {
    max-height: 400px;
    overflow-y: scroll;
    min-width: 200px;
}
/* 기존 */
.ant-popover-content .ant-popover-title {
    background: #fe9823 !important;
}
.ant-popover-arrow-content {
    background-color: #fe9823 !important; 
}
/* 우리카드 */
/* .ant-popover-content .ant-popover-title {
    background: #2b64ce !important;
}
.ant-popover-arrow-content {
    background-color: #2b64ce !important; 
} */
.ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 12px !important;
}
/* My Profile 부분 */
.user_account__wrap .bottom__section {
    display: flex;
    justify-content: space-between;     
    align-items: center;
}
.user_account__wrap .bottom__section .mysettings{ 
    font-size: 13px;
    text-decoration: underline !important;
    color: #000 !important;
    cursor: pointer;
    border: none; 
}  
.user_account__wrap .bottom__section .mysettings:hover {
    background: none;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/Header/common.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;AACA,OAAO;AACP;IACI,8BAA8B;AAClC;AACA;IACI,oCAAoC;AACxC;AACA,SAAS;AACT;;;;;GAKG;AACH;IACI,sBAAsB;AAC1B;AACA,kBAAkB;AAClB;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,qCAAqC;IACrC,sBAAsB;IACtB,eAAe;IACf,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".ant-popover-inner-content {\n    max-height: 400px;\n    overflow-y: scroll;\n    min-width: 200px;\n}\n/* 기존 */\n.ant-popover-content .ant-popover-title {\n    background: #fe9823 !important;\n}\n.ant-popover-arrow-content {\n    background-color: #fe9823 !important; \n}\n/* 우리카드 */\n/* .ant-popover-content .ant-popover-title {\n    background: #2b64ce !important;\n}\n.ant-popover-arrow-content {\n    background-color: #2b64ce !important; \n} */\n.ant-popover-placement-bottomRight .ant-popover-arrow {\n    right: 12px !important;\n}\n/* My Profile 부분 */\n.user_account__wrap .bottom__section {\n    display: flex;\n    justify-content: space-between;     \n    align-items: center;\n}\n.user_account__wrap .bottom__section .mysettings{ \n    font-size: 13px;\n    text-decoration: underline !important;\n    color: #000 !important;\n    cursor: pointer;\n    border: none; \n}  \n.user_account__wrap .bottom__section .mysettings:hover {\n    background: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
