import React from 'react';
import styled from 'styled-components';
import image from './readyOpen.png';
import { Image } from 'components';

const Wrapper = styled.div`
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    img {
        width: 100%;
    }
`

const ReadyOpen = () => { 
    return (
        <Wrapper>
            <Image src={image} alt="pareparing"/>      
        </Wrapper>
    );
}

export default ReadyOpen;