import { getUniqueKey } from 'utils/Str';

export const SET_BOXWOODSERVICE_VALUE = getUniqueKey('SET_BOXWOODSERVICE_VALUE');
export const SET_BOXWOODSERVICE_PAGEINFO = getUniqueKey('SET_BOXWOODSERVICE_PAGEINFO');
export const SET_BOXWOODSERVICE_SPREAD = getUniqueKey('SET_BOXWOODSERVICE_SPREAD');
export const RESET_BOXWOODSERVICE_VALUE = getUniqueKey('RESET_BOXWOODSERVICE_VALUE');

export function setBoxwoodServiceValue(key, value) {
    return {
        type: SET_BOXWOODSERVICE_VALUE,
        key,
        value
    };
}

export function setBoxwoodServicePageInfo(data) {
    return {
        type: SET_BOXWOODSERVICE_PAGEINFO,
        data
    };
}

export function setBoxwoodServiceSpread(data) {
    return {
        type: SET_BOXWOODSERVICE_SPREAD,
        data
    }
}

export function resetBoxwoodServiceValue() {
    return {
        type: RESET_BOXWOODSERVICE_VALUE
    }
} 