import { SET_HFWORKFLOWSCHEDULE_VALUE, SET_HFWORKFLOWSCHEDULE_SPREAD, RESET_HFWORKFLOWSCHEDULE_VALUE, SET_HFWORKFLOWSCHEDULE_FILTERID } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const hfWorkflowScheduleInitialState = {
    inputValues: {
        workflowId: '',
        workflowNm: '',
        scheduleType: [],
        workId: [],
        workflowOrgCd: [],
        regId: [],
    },
    filterId: ''
};

const hfWorkflowSchedule = (state = hfWorkflowScheduleInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_HFWORKFLOWSCHEDULE_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_HFWORKFLOWSCHEDULE_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_HFWORKFLOWSCHEDULE_VALUE: {
            const inputValues = {
                ...hfWorkflowScheduleInitialState.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        case SET_HFWORKFLOWSCHEDULE_FILTERID: {
            return {                                                    
                ...state,
                filterId: action.filterId
            }
        }
        default:
            return state;
    }
}

export default hfWorkflowSchedule;
