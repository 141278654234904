export const AUTH_SIGNIN = 'App/AUTH_SIGNIN';
export const AUTH_LOAD = 'App/AUTH_LOAD';
export const SET_AUTH_LOADING = 'App/SET_AUTH_LOADING';
export const AUTH_SIGNIN_SUCCESS = 'App/AUTH_SIGNIN_SUCCESS';
export const AUTH_SIGNIN_FAILED = 'App/AUTH_SIGNIN_FAILED';
export const AUTH_SIGNIN_RESET = 'App/AUTH_SIGNIN_RESET';
export const SSO_SIGNIN_SUCCESS = 'App/SSO_SIGNIN_SUCCESS';
export const SSO_LOAD = 'App/SSO_LOAD';
export const SSO_SIGNIN = 'App/SSO_SIGNIN';
export const SSO_LIVE_SUCCESS = 'App/SSO_LIVE_SUCCESS';
