import React, { useEffect, useState } from "react";
import { Button } from 'components';
import { Tree } from 'antd';
import { FormattedMessage } from "react-intl";

/**
 *  AdminOrganization > Management > TreeList.js
 *  관리자 > 조직관리 > 좌측 > 조직 목록 나타내는 페이지
 */

const TreeList = ({ treeData, getData, setSelectedKey, selectedKey, flatDepartmentList, onClickDelete, childCheck, setChildCheck, selectedDatas, setSelectedDatas, initialChangeInput, setIsOrgCdDisabled }) => {
    const [lastKey, setLastKey] = useState('');
    const [expand, setExpand] = useState([]);
    const [treeExpandKeys, setTreeExpandKeys] = useState([]);
    const [isDeleteDisabled, setIsDeleteDisabled] = useState(true);

    const onSendData = (orgCd, level, children, organizationList, isNew) => {
        getData(orgCd, level, children, organizationList, isNew);
    };

    const onExpand = (keys) => {
        setTreeExpandKeys([...keys]);
        setExpand([...keys]);
    };

    /* 조직 추가 버튼 기능 */
    const onClickAdd = async () => {
        const lastOrgSortNum = treeData[treeData.length - 1].orgSortNum;
        //상위 추가 (조직 선택이 안돼있는 경우)
        if (selectedKey[0] === 0 || selectedKey[0] === undefined) {
            onSendData(lastKey + 1, 0, childCheck, flatDepartmentList, -1);
            setSelectedDatas({
                ...initialChangeInput,
                orgSortNum: lastOrgSortNum + 1, // 마지막 num + 1
            });
            setIsOrgCdDisabled(true);
        }
        //하위 (조직 선택이 돼있는 경우 - 회사코드만 그대로 )
        else {
            const addLevel = +selectedDatas.level;
            onSendData(selectedKey, addLevel + 1, childCheck, flatDepartmentList, 1);
            setSelectedDatas({
                ...initialChangeInput,
                companyCd: selectedDatas.companyCd,
                level: addLevel + 1,
                orgSortNum: 1,
                codeIdUpper: selectedDatas.codeId,
            })
        }
        const set = new Set([selectedDatas.orgCd, selectedDatas.codeId, ...treeExpandKeys]);
        onExpand(set);
    };

    // 조직도 팀 선택시 키 저장
    const onSelect = async (selectedKeys, e) => {
        // 삭제 버튼 활성화체크 
        if (selectedKeys.length > 0) {
            setIsDeleteDisabled(false);
            onSendData(selectedKeys[0] || -1, +e.node.level, +e.node.children.length, flatDepartmentList);
            setSelectedKey(selectedKeys[0]); // codeId
            setChildCheck(+e.node.children.length); // 삭제 가능 여부
        } else {
            onSendData();
            setSelectedKey([]); // codeId
            setIsDeleteDisabled(true);
        }
    }

    useEffect(() => {
        let codeIdArr = flatDepartmentList.map((e) => e.codeId);
        setLastKey(Math.max.apply(Math, codeIdArr));
    }, [flatDepartmentList]);

    return (
        <div className='org_tree_container'>
            <div>
                <div className="tree_top_area">
                    <div className="tree_btn_area">
                        <Button
                            type="primary"
                            onClick={onClickAdd}
                        >
                            <FormattedMessage id="T1253" />{/* 조직 추가 */}
                        </Button>
                        <Button
                            type={isDeleteDisabled ? "disabled" : "default"}
                            disabled={isDeleteDisabled}
                            onClick={onClickDelete}
                        >
                            <FormattedMessage id="T0072" />{/* 삭제  */}
                        </Button>
                    </div>
                </div>
                <div className="org_common__box">
                    <Tree
                        treeData={treeData}
                        onSelect={onSelect}
                        onExpand={onExpand}
                        defaultExpandedKeys={expand}
                        expandedKeys={expand}
                        defaultExpandAll
                    />
                </div>
            </div>
        </div>
    )
}
export default TreeList;