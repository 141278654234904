import React, { useState, useEffect, useReducer, useRef } from "react";
import { Pie } from "@ant-design/charts";
import { Link, withRouter } from "react-router-dom";
import {
  Text,
  TypePicker,
  InputSearch,
  Button,
  Table,
  RealtimeModal,
  HFFailedLogModal,
  Organization,
  Empty,
  Loading,
  ScheduleServiceLog
} from "components";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { Select, Tooltip, Input as AntdInput } from "antd";
import {
  LogIcon,
  SuccessIcon,
  MonitoringIcon,
  FailedIcon,
} from "icons";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import ProgressModal from "components/atoms/ProgressModal";

/**
 * Filename: HFWorkflowOperation.js
 * Description: BizFlow > Workflow 운영 현황 모니터링
 */

const { Option } = Select;

const HFWorkflowOperation = ({
  onReset,
  onOpenDepartment,
  department,
  onClickSearchButton,
  searchDepartment,
  performPermission,
  profile,
  performLoading,
}) => {
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));

  const permission = useSelector((state) =>
    state.get(getUniqueKey("permission"))
  );
  const { formatMessage } = useIntl();

  // 실패 사례 분석 Select 데이터들
  const latelyTypeList = [
    {
      text: formatMessage({ id: "T1292" }), //전일
      value: "yesterday",
    },
    {
      text: formatMessage({ id: "T0045" }), // 일주일
      value: "week",
    },
    {
      text: formatMessage({ id: "T0584" }), // 한달
      value: "month",
    },
    {
      text: formatMessage({ id: "T0585" }), // 년
      value: "year",
    },
  ];

  // 검색 조건
  const [selectDate, setSelectDate] = useState(moment());
  const [searchDate, setSearchDate] = useState(moment().format("YYYYMMDD"));
  const [orgaData, setOrgaData] = useState([]);
  const [searchOrga, setSearchOrga] = useState([]);
  const [latelyType, setLatelyType] = useState(latelyTypeList[0]);
  const [performPieStatus, setPerformPieStatus] = useState(false);
  const [config, setConfig] = useState({})
  // 데이터들
  // 업무수행상태 데이터
  const [performStatusData, setPerformStatusData] = useState([]);
  // 실패 사례 분석 데이터
  const [failCaseData, setFailCaseData] = useState([]);
  // Bot 수행 내역 데이터
  const [botPerformData, setBotPerformData] = useState([]);
  // Bot 수행 내역 ROW 데이터
  const [botPerformRowInfo, setBotPerformRowInfo] = useState([]);
  const [operRowInfo, setOperRowInfo] = useState({});
  // 모달
  const [logVisible, setLogVisible] = useState(false);
  const [failLogVisible, setFailLogVisible] = useState(false);
  const [orgaVisible, setOrgaVisible] = useState(false);
  const [replayVisible, setReplayVisible] = useState(false);
  // 로딩 
  const [performStatusLoading, setPerformStatusLoading] = useState(false) // 업무수행상태
  const [failCaseDataLoading, setFailLoading] = useState(false) // 실패 사례 분석
  const [botPerformDataLoading, setBotPerformLoading] = useState(false) // 업무 수행 내역

  const [progressVisible, setProgressVisible] = useState(false);
  const [progressData, setProgressData] = useState();

  const [orgaDataLoading, setOrgaDataLoading] = useState(false);

  const [logCrEnv, setLogCrEnv] = useState("");
  const [replayData, setReplayData] = useState({
    workflowId: "",
    workItemId: "",
    cr: "",
  });
  // 모달창 최대/최소화
  const [modalMaximization, setModalMaximization] = useState(false);
  const [overlap, setOverlap] = useState(false);

  const onMaximizeModal = () => setModalMaximization(true);
  const onMinimizeModal = () => setModalMaximization(false);

  /* 데이터 가공해주기 */
  const orgCd = department.map((data) => {
    const tmpArr = [];
    tmpArr.push(data.codeId);
    return tmpArr;
  });

  const empNo = orgaData.map((data) => {
    const tmpArr = [];
    tmpArr.push(data.empNum);
    return tmpArr;
  });

  const modalTitle = `${botPerformRowInfo.workflowId} ${formatMessage({
    id: "T0599", //과제 재실행
  })}`;

  const workflowId = formatMessage({ id: 'T1316' }); // 업무 ID
  // const workflowNm = formatMessage({ id: "T0002" }); // 과제명
  const workflowNm = formatMessage({ id: 'T1237' }); // 업무명
  const taskMgrId = formatMessage({ id: "T0006" }); // 업무 담당자
  const operDate = formatMessage({ id: "T0011" }); // 수행일
  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const recordUpdateErr = formatMessage({ id: "T0191" }); // 레코드 데이터를 수정 하는 중 오류가 발생하였습니다.
  const scheduleReSuccess = formatMessage({ id: "T0192" }); // 스케줄 재실행이 완료되었습니다.
  const scheduleReFail = formatMessage({ id: "T0193" }); // 스케줄 재실행에 실패하였습니다. 

  const caseColumns = [
    {
      title: workflowId,
      dataIndex: "workflowId",
      key: "workflowId",
      width: 80,
      render: (workflowId, record) => {
        return (
          <Link to={`/bizflow/hfworkflow/lookup/${workflowId}`} target="_blank"
            rel="noopener noreferrer">
            <div>{workflowId}</div>
          </Link>
        );
      },
    },
    {
      title: workflowNm,
      dataIndex: "workflowNm",
      key: "workflowNm",
      width: 320,
      render: (workflowNm, record) => {
        return (
          <Link to={`/bizflow/hfworkflow/lookup/${record.workflowId}`} target="_blank"
            rel="noopener noreferrer">
            <Tooltip title={workflowNm}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  width: 320,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {workflowNm}
              </div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: formatMessage({ id: "T0581" }), // 실패횟수
      dataIndex: "failCnt",
      key: "failCnt",
      width: 75,
    },

    {
      title: formatMessage({ id: "T0570" }), // 로그기록
      align: "center",
      width: 100,
      render: (data, record, index) => (
        <div key={index}>
          <LogIcon
            className="abled_icons"
            onClick={() => onClickFailLog(data)}
          />
        </div>
      ),
    },

  ];

  const runColumns = [
    {
      title: workflowId,
      dataIndex: "workflowId",
      key: "workflowId",
      width: 80,
      render: (workflowId, record) => {
        return (
          <Link to={`/bizflow/hfworkflow/lookup/${workflowId}`} target="_blank"
            rel="noopener noreferrer">
            <div>{workflowId}</div>
          </Link>
        );
      },
    },
    {
      title: workflowNm,
      dataIndex: "workflowNm",
      key: "workflowNm",
      width: 360,
      render: (workflowNm, record) => {
        return (
          <Link to={`/bizflow/hfworkflow/lookup/${record.workflowId}`} target="_blank"
            rel="noopener noreferrer">
            <Tooltip title={workflowNm}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  width: 360,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {workflowNm}
              </div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      title: `${formatMessage({ id: "T0400" })}`, // 시작시간
      dataIndex: "startDate",
      key: "startDate",
      width: 140,
      render: (text) =>
        !text ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: `${formatMessage({ id: "T0401" })}`, // 종료시간
      dataIndex: "endDate",
      key: "endDate",
      width: 140,
      render: (text) =>
        !text ? "" : moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: `${formatMessage({ id: "T0583" })}`, // 소요시간(분)
      dataIndex: "time",
      key: "time",
      align: "center",
      width: 110,
    },
    {
      title: `${formatMessage({ id: "T0350" })}`, // 실행상태
      key: "status",
      align: "center",
      dataIndex: "status",
      width: 110,
      render: (text) =>
        text === "Complete" ? (
          <div className="operation_table__success">
            <SuccessIcon />
            <Text>{formatMessage({ id: "T0403" })}</Text>  {/* 성공 */}
          </div>
        ) : text === "Failed" ? (
          <div className="operation_table__fail">
            <FailedIcon />
            <Text>{formatMessage({ id: "T0404" })}</Text>  {/* 실패 */}
          </div>
        ) : (
          <Text style={{ fontSize: 12 }}>{text}</Text>
        ),
    },

    {
      title: `${formatMessage({ id: "T0570" })}`, // 로그 기록
      width: 100,
      align: "center",
      dataIndex: "processInstanceIds",
      key: "id",
      render: (data, record, index) => (
        <div className="operation_note__box" key={index}>
          <Tooltip title={formatMessage({ id: "T1167" })}> {/* Replay */}
            <MonitoringIcon
              onClick={() => onClickStatus(record)}
              className="abled_icons"
            />
          </Tooltip>
          <Tooltip title={formatMessage({ id: "T0570" })}> {/* 로그기록 */}
            <LogIcon
              className="abled_icons"
              onClick={() => onClickLog(data, record)}
            />
          </Tooltip>
        </div>
      ),
    },

  ];

  const onOkOrga = (data) => {
    if (data.length > 0) {
      setOrgaData([...data]);
    } else {
      setOrgaData([]);
    }
    setOrgaVisible(false);
  };

  const onCancelOrga = () => {
    setOrgaVisible(false);
  };

  const onOpenOrga = () => {
    setOrgaVisible(true);
  };

  //업무 수행 내역
  const getBotResult = async () => {
    setBotPerformLoading(true)
    const botResult = await fetchAbsolute(
      "post",
      `/monitor/wfOperation/getWfPerformList`,
      {
        data: {
          orgCd: orgCd.flat(),
          empNo: empNo.flat(),
          performDate: selectDate.format("YYYYMMDD"),
        },
      }
    );
    setBotPerformLoading(false)
    setBotPerformData([...botResult.data]);
  };

  //실패 사례 분석
  const getFailedResult = async () => {
    setFailLoading(true)
    const failedResult = await fetchAbsolute(
      "post",
      `/monitor/wfOperation/getFailCaseAnalysisList`,
      {
        data: {
          orgCd: orgCd.flat(),
          empNo: empNo.flat(),
          performDate: selectDate.format("YYYYMMDD"),
          dateType: latelyType.value,
        },
      }
    );
    setFailLoading(false)
    setFailCaseData([...failedResult.data]);
  }

  // 업무 수행 상태
  const getStatusResult = async () => {
    setPerformStatusLoading(true)
    const statusResult = await fetchAbsolute(
      "post",
      `/monitor/wfOperation/getWfPerformStatus`,
      {
        data: {
          orgCd: orgCd.flat(),
          empNo: empNo.flat(),
          performDate: selectDate.format("YYYYMMDD"),
          // performDate:("YYYYMMDD"),
        },
      }
    );
    setPerformStatusLoading(false)
    const tmpArr = [];
    tmpArr.push({
      type: `${formatMessage({ id: "T0403" })}`, // 성공
      value: +statusResult.data.doneStatusCnt,
    });
    tmpArr.push({
      type: `${formatMessage({ id: "T0404" })}`,  // 실패
      value: +statusResult.data.failedStatusCnt,
    });
    setPerformStatusData([...tmpArr]);

    setConfig({
      angleField: "value",
      data: tmpArr,
      colorField: "type",
      radius: 0.8,
      label: {
        type: "inner",
        offset: "-30%",
        autoRotate: false,
        content: function content(_ref) {
          const percent = _ref.percent;
          return ""
            .concat(_ref.value)
            .concat("\n")
            .concat((percent * 100).toFixed(1))
            .concat("%");
        },
        style: {
          fontSize: 15,
          textAlign: "center",
        },
      },
      legend: {
        position: "left-bottom",
        layout: "vertical",
      },

      color: (_ref) => {
        const { type } = _ref;
        switch (type) {
          case `${formatMessage({ id: "T0403" })}`: // 성공
            return "#ff9a26";
          case `${formatMessage({ id: "T0404" })}`: // 실패
            return "#E54156";
          case `${formatMessage({ id: "T0580" })}`: // 예약
            return "#0E5A8C";
          default:
        }
      },
      interactions: [{ type: "element-active" }],
    });
  }

  const onChangeDate = (date) => {
    setSelectDate(date);
  };

  const onChangeLatelyType = (type) => {
    const tmpObj = latelyTypeList.find((v) => v.value === type);
    setLatelyType({ ...tmpObj });
  };

  const onClickSearch = () => {
    const dateString = selectDate.format("YYYYMMDD");
    setSearchDate(dateString);
    setSearchOrga([...orgaData]);
    onClickSearchButton();
  };
  /* 검색 초기화 기능 */
  const onClickSearchReset = () => {
    //권한이 없으면 true 있으면 false
    const flag = permission.user.findIndex((v) => v.fnCode === "FN29") !== -1;

    // department 초기화
    onReset();
    // 수행일 초기화
    setSelectDate(moment());
    setSearchDate(moment().format("YYYYMMDD"));

    // 실폐 사례 분석 type 리스트 디플트값으로 초기화
    setLatelyType(latelyTypeList[0]);

    // 업무담당자 초기화z
    if (flag) {
      setOrgaData([]);
      setSearchOrga([]);
    }
  };
  //테스트 진행상태 팝업 - iframe
  const onClickStatus = (record) => {
    setProgressData(record);
    setProgressVisible(true);
  }
  const onCancelStatus = () => {
    setProgressVisible(false);
    setModalMaximization(false);
  }

  const onClickLog = async (data, record) => {
    setOperRowInfo({ ...record });
    setLogVisible(true);
  };

  const onClickFailLog = (data) => {
    setBotPerformRowInfo(data);
    setLogCrEnv(
      crInfo.find((v) => v.crType === data.taskType && v.crEnvGubun === "PRD")
        ?.crXEnv
    );
    setFailLogVisible(true);
  };
  const onOkFailLog = () => setFailLogVisible(false);
  const onOkLog = () => setLogVisible(false);
  const onCancelLog = () => setLogVisible(false);
  const onCancelFailLog = () => setFailLogVisible(false);

  const onOkReplay = () => {
    setReplayVisible(false);
  };
  const onCancelReplay = () => {
    setReplayVisible(false);
  };

  const [orgaDataState, dispatchOrgaData] = useReducer((state, action) => {
    switch (action.type) {
      case 'SET_DATA':
        return {
          ...state,
          orgaData: action.payload,
          searchData: action.payload,
          orgaDataLoading: true,
        };
      case 'CLEAR_DATA':
        return {
          ...state,
          orgaData: [],
          searchData: [],
          orgaDataLoading: true,
        };
      default:
        return state;
    }
  }, {
    orgaData: [],
    searchData: [],
    orgaDataLoading: false,
  });
  
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (!searchDate) return;
    getFailedResult();
    getBotResult();
    getStatusResult();
  }, [searchDate, searchDepartment, searchOrga]);

  
  useEffect(() => {
    if (isFirstRender.current) {
      //첫번째 렌더링 후에 false로 업데이트
      isFirstRender.current = false;
      //첫번째 렌더링은 skip (다른 useEffect에서 이미 함수 호출함)
      return;
    }
    getFailedResult();
  }, [latelyType]);

  useEffect(() => {
    const fetchUser = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${profile.EMP_NO}`
      );
      if (result.error || result.data.mdFPAList.length === 0) {
        return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      }
      dispatchOrgaData({ type: 'SET_DATA', payload: result.data.mdFPAList });
      // setOrgaData([...result.data.mdFPAList]);
      // setSearchOrga([...result.data.mdFPAList]);
      // setOrgaDataLoading(true);
    };
    if (!performLoading) return;
    if (!performPermission) {
      fetchUser();
    } else {
      dispatchOrgaData({ type: 'CLEAR_DATA' });
      // setOrgaData([]);
      // setSearchOrga([]);
      // setOrgaDataLoading(true);
    }
  }, [performPermission, performLoading]);

  //pie chart 데이터 없음 보여주는 로직
  useEffect(() => {
    const temp = performStatusData
      .map((e) => e.value > 0)
      .findIndex((v) => v === true);
    if (temp === -1) {
      setPerformPieStatus(true);
    } else {
      setPerformPieStatus(false);
    }
  }, [performStatusData]);

  return (
    <div className="operation_box">
      <div className="operation__top_menu">
        <div className="top_menu__box">
          <Text className="top_menu__text">{operDate}</Text>
          {/* 수행일 */}
          <TypePicker
            placeholder={moment().format("YYYY.MM.DD")}
            defaultValue={moment()}
            value={selectDate}
            width={170}
            height={32}
            onChange={onChangeDate}
            allowClear={false}
          />
        </div>
        {performPermission && (
          <div className="top_menu__box">
            <Text className="top_menu__text">
              {formatMessage({ id: "T0005" })}
            </Text>
            {/* 담당부서 */}
            <InputSearch
              placeholder={formatMessage({ id: "T0029" })} // 담당 부서 검색
              width={200}
              height={32}
              onClick={onOpenDepartment}
              value={department.map((c) => c.codeNm).join(", ")}
            />
          </div>
        )}
        <div className="top_menu__box">
          <Text className="top_menu__text">{taskMgrId}</Text>
          <InputSearch
            placeholder={formatMessage({ id: "T0586" })} // 업무 담당자 검색
            width={200}
            height={32}
            onClick={performPermission ? onOpenOrga : () => { }}
            value={orgaData.map((c) => c.empNam).join(", ")}
          />
        </div>
        <Button
          className="top_menu__button"
          type="primary"
          onClick={onClickSearch}
        >
          <FormattedMessage id="T0039" />
          {/* 검색 */}
        </Button>

        <Button className="top_menu__button" onClick={onClickSearchReset}>
          <FormattedMessage id="T0037" />
          {/* 초기화 */}
        </Button>
      </div>
      <div className="operation__content">
        <div className="operation__content_box content__chart_box content__bot_status">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T1581" })}</Text>
            {/* 업무수행상태 */}
          </div>
          <div className="content__main">
            <div>
              {performStatusLoading ? (
                <Loading visible={performStatusLoading} height={17} minHeight={10} />
              ) : (
                Object.keys(config).length > 0 &&
                !performStatusLoading &&
                performStatusData.length > 0 &&
                (!performPieStatus ?
                  <Pie
                    {...config}
                    style={{ height: "400px" }}
                  />
                  : <Empty />)
              )}
            </div>
          </div>
        </div>
        <div className="operation__content_box content__fail_case">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T0589" })}</Text>
            {/* 실패 사례 분석 (최근 */}
            <Select
              style={{
                width: 90,
                fontSize: 12,
                margin: "0 10px",
                alignSelf: "normal",
              }}
              defaultValue={latelyTypeList[0].value}
              value={latelyType.value}
              onChange={onChangeLatelyType}
            >
              {latelyTypeList.map((type) => (
                <Option value={type.value} key={type.value}>
                  {type.text}
                </Option>
              ))}
            </Select>
            <Text>)</Text>
          </div>
          <div className="content__main">
            <Table
              className="failed__table"
              columns={caseColumns}
              dataSource={failCaseData}
              style={{ width: "100%" }}
              rowKey={(record) => record.workflowId + record.failCnt}
              loading={failCaseDataLoading}
            />
          </div>
        </div>
        <div className="operation__content_box content__bot_run">
          <div className="content__title">
            <div className="box"></div>
            <Text>{formatMessage({ id: "T1582" })}</Text>
            {/* 업무 수행 내역 */}
          </div>
          <div className="content__main">
            <Table
              className="bot_perform__table"
              rowKey={(record) =>
                record.workflowId + record.startDate + record.endDate
              }
              columns={runColumns}
              dataSource={botPerformData}
              loading={botPerformDataLoading}
            />
          </div>
        </div>
      </div>
      <Organization
        max={1}
        visible={orgaVisible}
        onOk={onOkOrga}
        onCancel={onCancelOrga}
        defaultData={[orgaData]}
        title={formatMessage({ id: "T0586" })} // 업무 담당자 검색
      />
      <ProgressModal
        // visible={true} 
        visible={progressVisible}
        data={progressData}
        onCancel={onCancelStatus}
        modalMaximization={modalMaximization}
        setModalMaximization={setModalMaximization}
        onMaximizeModal={onMaximizeModal}
        onMinimizeModal={onMinimizeModal}
        overlap={overlap}
      />
      <HFFailedLogModal
        title={formatMessage({ id: "T1595" })} // 업무 Fail Log
        visible={failLogVisible}
        onOk={onOkFailLog}
        onCancel={onCancelFailLog}
        recordData={botPerformRowInfo}
      />
      <RealtimeModal
        visible={replayVisible}
        onOk={onOkReplay}
        onCancel={onCancelReplay}
        workflowIdId={replayData.workflowIdId}
        workItemId={replayData.workItemId}
        cr={replayData.cr}
      />
      <ScheduleServiceLog
        type="oper"
        onOk={onOkLog}
        recordData={operRowInfo}
        visible={logVisible}
        onCancel={onCancelLog}
      />
    </div >
  );
};

export default withRouter(HFWorkflowOperation);
