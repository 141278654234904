import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    /* 공통 */
    .box__container {
        width:100%;
        padding: 20px 20px;
        border:1px solid #d0d1d6;
        border-radius: 10px;
        background: #fff;    
        height: 425px;
        max-height: 455px;
        overflow: auto;
    }
    .main__title {
        margin-bottom: 0.5em;
        font-size: 18;
        font-weight: bold;
        line-height: 1.38;
    }
    .bot__title { 
        display: flex;
        justify-content: space-between;
    }
    .condition {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    
    /* 상태별 진척 현황 */
    .task_state__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .standard_time {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: right;
    }
    .first__section, .second__section {
        display: flex;
        gap: 10px;
        height: 100%;
    }
    .progress_status {
        width: 40%;
    }
    .org_operation {
        width: 60%;
    }
    .org_tat, .task_stage_tat {
        width: 50%;
    }
    /* 조직별 운영건수 비교 */
    .selectRadioCategory .ant-radio-inner {
        height: 15px;
        width: 15px;
    }
    .selectRadioCategory .ant-radio-inner::after {
        top: 55%;
        left: 55%;
        width: 15px;
        height: 15px;
    }
    .selectRadioCategory .ant-radio-wrapper { 
        align-items: baseline; 
    }
`;

export default Wrapper;