import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Input as AntInput, Select } from 'antd';
import { Modal, Text, Input, Spin } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;
const { TextArea } = AntInput;

const dummyValue = '<-!@#$->';

const Wrapper = styled(props => <Modal {...props} />)`
`

const ModifyAttributeModal = ({ visible = false, onOk = () => { }, onCancel = () => { }, credentialName = '', crType = 'COE', attribute = {} }) => {
  const { formatMessage } = useIntl();
  const valueFlag = useRef(false);

  const [attrName, setAttrName] = useState('');
  const [attrValue, setAttrValue] = useState('');
  const [attrPassword, setAttrPassword] = useState('N');
  const [attrDes, setAttrDes] = useState('');
  const [loading, setLoading] = useState(false)

  const onOkModal = async () => {
    if (attrValue.trim().length <= 0) {
      return alert(formatMessage({ id: 'T0114' }));
      //'Attribute Value를 입력해주세요');
    }
    setLoading(true);
    const result = await fetchAbsolute('put', `/hwlm/credential`, {
      data: {
        name: credentialName,
        crType: crType.toUpperCase(),
        attributes: [
          {
            type: "UPDATE",
            name: attribute.name,
            description: attrDes,
            value: attrPassword === 'Y' && attrValue === dummyValue ? attribute.value : attrValue,
            userProvided: false,
            masked: attrPassword === 'Y',
            passwordFlag: attrPassword === 'Y'
          }
        ]
      }
    });
    setLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

    setAttrName('');
    setAttrValue('');
    setAttrPassword('Y');
    setAttrDes('');
    onOk();
  }

  const onChangeValue = (e) => {
    const { value } = e.target;
    valueFlag.current = true;
    setAttrValue(value);
  }

  const onChangePassword = (e) => {
    setAttrValue('');
    setAttrPassword(e);
  }

  const onChangeDes = (e) => {
    const { value } = e.target;
    if (value.length > 255) return;
    setAttrDes(value);
  }

  const onFocusValue = () => {
    if (attrPassword === 'N' || valueFlag.current) return;
    setAttrValue('');
  }
  const onBlurValue = () => {
    if (attrPassword === 'N' || attrValue.length > 0) return;
    setAttrValue(dummyValue);
  }

  useEffect(() => {
    if (!visible || Object.keys(attribute).length <= 0) {
      return;
    }
    setAttrName(attribute.name);
    setAttrValue(attribute.passwordFlag ? dummyValue : attribute.value);
    setAttrPassword(attribute.passwordFlag ? 'Y' : 'N');
    setAttrDes(attribute.description);
  }, [attribute, visible]);

  return (
    <Wrapper
      width={540}
      visible={visible}
      title={formatMessage({ id: "T0357" })} // Attribute 수정
      okText={formatMessage({ id: "T0066" })} // 입력
      onCancel={onCancel}
      onOk={onOkModal}
    >
      <div style={{ display: "flex" }}>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            width: 130,
          }}
        >
          <FormattedMessage id="T1134" />
        </Text>
        <Input disabled value={attrName} />
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            width: 130,
          }}
        >
          <FormattedMessage id="T1135" />
        </Text>
        <Input
          type={attrPassword === "Y" ? "password" : "text"}
          onFocus={onFocusValue}
          onBlur={onBlurValue}
          value={attrValue}
          onChange={onChangeValue}
        />
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            width: 103,
          }}
        >
          <FormattedMessage id="T1136" />
        </Text>
        <Select
          value={attrPassword}
          onChange={onChangePassword}
          style={{ width: 100 }}
        >
          <Option value="N">N</Option>
          <Option value="Y">Y</Option>
        </Select>
      </div>
      <div style={{ display: "flex", marginTop: 20 }}>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            width: 130,
          }}
        >
          {formatMessage({ id: "T0100" })}{/* 설명 */}
        </Text>
        <TextArea
          autoSize={{ minRows: 4, maxRows: 4 }}
          value={attrDes}
          onChange={onChangeDes}
        />
      </div>
      {!!loading &&
        <Spin tip='Loading...' />
      }
    </Wrapper>
  );
}
export default ModifyAttributeModal;