import React, { useState, useEffect } from "react";
import { Text, Modal, Button } from "components";
import { Input } from "antd";
import { Tree } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
/**
 * Filename: AssignClassification/index.js
 * Description: 과제 업무 분류
 */

const AssignClassification = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const blankNotAllowed = formatMessage({ id: "T0239" }); // 공백은 입력할 수 없습니다
  const addText = formatMessage({ id: "T0049" }); // 추가
  const onOkText = formatMessage({ id: "T0054" }); // 수정

  const [treeList, setTreeList] = useState([]);
  const [addVisible, setAddVisible] = useState(false);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [createValue, setCreateValue] = useState({
    createTitle: "",
    createKey: "",
    createSort: "",
  });
  const [updateValue, setupdateValue] = useState({
    title: "",
    key: "",
    sort: "",
  });
  const [selectInfo, setSelectInfo] = useState({});
  const [allClassList, setAllClassList] = useState([]);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [selectDatas, setSelectDatas] = useState({
    title: "",
    key: "",
    sort: "",
  });
  const [expand, setExpand] = useState([]);
  const [onSelectCheck, setOnSelectCheck] = useState(false);
  const [addButtonStatus, setAddButtonStatus] = useState(false);
  const [language, setLanguage] = useState("");
  const [treeExpandKeys, setTreeExpandKeys] = useState([]);

  const onSelect = (selectedKeys, info) => {
    setOnSelectCheck(info.selected);

    if (info.selected) {
      setSelectInfo(info.node);
      if (info.node.level === 5 || info.node.level === "5") {
        setAddButtonStatus(true);
      } else {
        setAddButtonStatus(false);
      }
    } else {
      setSelectInfo({});
      setAddButtonStatus(false);
    }

    //삭제 버튼 활성화 체크
    if (selectedKeys.length === 0) {
      setButtonStatus(true);
      setSelectInfo({});
    } else if (
      info.node.children?.length === 0 ||
      info.node.children === undefined
    ) {
      setButtonStatus(false);
    } else {
      setButtonStatus(true);
    }

    //키값으로 변경된 origin 데이터 에서 조회
    const keyFindVal = allClassList.find((e) => e.cd === info.node.key);
    //수정완료 후 다시 초기화 하기
    if (selectedKeys?.length === 0) {
      setSelectDatas({ title: "", key: "" });
      return;
    }

    //selectDatas에 선택 값 넣기
    setSelectDatas({
      title: keyFindVal.cdNm,
      key: keyFindVal.cd,
      sort: keyFindVal.sort,
    });
  };

  const onExpandTree = (keys) => {
    setTreeExpandKeys([...keys]);
    setExpand([...keys]);
  };

  const onCancelAdd = () => {
    setUpdateVisible(false);
    setDeleteVisible(false);
    setCreateValue({
      createTitle: "",
      createKey: "",
      createSort: "",
    });
    setAddVisible(false);
  };

  const onCancelUpdate = () => {
    setUpdateVisible(false);
    setDeleteVisible(false);
    setAddVisible(false);
  };

  const onOkAdd = () => {
    const tempArr = [...allClassList];
    if (createValue.createTitle === "" || createValue.createKey === "") {
      alert(blankNotAllowed);
      return;
    }
    if (allClassList.findIndex((v) => v.cd === createValue.createKey) !== -1) {
      return alert(
        fmMessage({
          id: "T1285",
          values: { name: formatMessage({ id: "T1289" }) },
        })
      ); // 중복된 Code값은/을 입력 할 수 없습니다.
    }

    if (!onSelectCheck) {
      tempArr.splice(allClassList.length + 1, 0, {
        cd: createValue.createKey,
        cdNm: createValue.createTitle,
        upCd: "0000",
        //sort: allClassList.length + 1,
        sort: createValue.createSort,
        level: "1",
        locale: language,
      });

      setAllClassList([...tempArr]);
      setCreateValue({
        createTitle: "",
        createKey: "",
        createSort: "",
      });
      setAddVisible(false);
    } else {
      const idx = allClassList.findIndex((item) => item.cd === selectInfo.key);
      tempArr.splice(idx + 1, 0, {
        cd: createValue.createKey,
        cdNm: createValue.createTitle,
        upCd: selectInfo.key,
        //sort: +selectInfo.children?.length + 1,
        sort: createValue.createSort,
        level: (parseInt(selectInfo.level) + 1).toString(),
      });

      setAllClassList([...tempArr]);
      setCreateValue({
        createTitle: "",
        createKey: "",
        createSort: "",
      });
      setAddVisible(false);
    }
    const set = new Set([
      selectInfo.key,
      createValue.createKey,
      ...treeExpandKeys,
    ]);
    onExpandTree(set);
  };

  const onOkUpdate = () => {
    const tempArr = [...allClassList];
    const index = allClassList.findIndex((item) => item.cd === selectInfo.key);

    tempArr[index] = {
      cd: updateValue.key,
      cdNm: updateValue.title,
      upCd: selectInfo.upCd,
      sort: updateValue.sort,
      level: selectInfo.level,
    };
    setAllClassList([...tempArr]);
    setUpdateVisible(false);
  };

  const handleReset = (e) => {
    const fetchWorkList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/workClassificationList`
      );
      setAllClassList(result?.data);
    };
    setOnSelectCheck(false);
    fetchWorkList();
    fetchLanguageList();
  };

  const flatten = (data) => {
    const result = [];
    recursive(data);
    return result;

    function recursive(data) {
      data.forEach((dt, index) => {
        const e = allClassList.find((e) => e.cd === dt.key);
        result.push({
          categoryId: "WORK_GBN",
          cdNm: e.cdNm,
          cd: dt.key,
          level: dt.level,
          sort: dt.sort,
          upCd: dt.upCd,
          useYn: "Y",
          locale: language,
        });
        recursive(dt.children);
      });
    }
  };

  const handleSave = async (e) => {
    const treedt = flatten(treeList);
    //console.log(treedt);
    let fetchFlag = true;

    try {
      const saveList = await fetchAbsolute(
        "post",
        "/common/commonCd/addList?delCategoryId=WORK_GBN",
        {
          data: treedt,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (saveList.status === 200 || saveList.status === 201) {
        alert(formatMessage({ id: "T0234" })); // 저장되었습니다.
      } else {
        throw new Error(saveList.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
      }
    }

    const fetchWorkList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/workClassificationList`
      );
      setAllClassList(result?.data);
    };
    fetchWorkList();
    fetchLanguageList();
  };

  const handleUpdate = (e) => {
    if (!selectDatas.title || selectDatas.title.length === 0) {
      alert(formatMessage({ id: "T0236" })); // 업무 분류를 선택 해주세요.
      return 0;
    }
    setUpdateVisible(true);
  };

  const handleAdd = (e) => {
    // if (selectInfo?.level > 1) {
    //   alert(formatMessage({ id: "T0237" }));
    //   //하위레벨에 추가하실수 없습니다.
    //   return;
    // }
    setAddVisible(true);
  };

  const handleRemove = (e) => {
    if (!selectDatas.title || selectDatas.title.length === 0) {
      alert(formatMessage({ id: "T0235" }));
      //저장에 실패하였습니다.
      return 0;
    }
    if (
      Object.keys(selectInfo?.children).length > 0 ||
      selectInfo?.children === undefined
    ) {
      alert(formatMessage({ id: "T0238" }));
      //하위 항목을 제거해주세요
      return 0;
    }

    setDeleteVisible(true);
  };

  const onOkDelete = () => {
    const temp = [...allClassList];
    const idx = allClassList.findIndex((item) => item.cd === selectDatas.key);
    temp.splice(idx, 1);

    setAllClassList([...temp]);
    setDeleteVisible(false);
    setOnSelectCheck(false);
  };

  const onChangeupdateValue = (e) => {
    const { value, name } = e.target;
    setupdateValue({
      ...updateValue,
      [name]: value,
    });
  };
  const onChangeCreateValue = (e) => {
    const { value, name } = e.target;

    setCreateValue({
      ...createValue,
      [name]: value,
    });
  };
  const flatToTree = () => {
    const tmpDatas = [];
    let lastArray;
    // 레벨에 맞는 배열 가져오기 위한 재귀함수
    const getLevelArray = (array, level, count = 1) =>
      +level === count
        ? array
        : getLevelArray(array[array?.length - 1]?.children, level, count + 1);
    allClassList.forEach((value) => {
      lastArray = getLevelArray(tmpDatas, value.level);

      const data = {
        title: (
          <>
            <div>
              <span id="cdNm">{value.cdNm}</span> &nbsp;
              <span>
                /&nbsp; Code : {value.cd} / Sort : {value.sort}
              </span>
            </div>
          </>
        ),
        key: value.cd,
        sort: value.sort,
        upCd: value.upCd,
        level: value.level,
        children: [],
      };

      if (!lastArray?.children) lastArray?.push(data);
      else lastArray?.children.push(data);
    });
    setTreeList(tmpDatas);
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute(
      "get",
      "/common/commonCd/getCategoryId/LOCALE"
    );
    const data = result.data.filter((v) => v.useYn === "Y");
    setLanguage(data[0].cd);
  };

  useEffect(() => {
    const treeTitle = allClassList.find((e) => e.cd === selectInfo.key);
    if (treeTitle?.cdNm.length !== 0) {
      setupdateValue({
        title: treeTitle?.cdNm,
        key: treeTitle?.cd,
        sort: treeTitle?.sort,
      });
    }
  }, [selectInfo]);

  useEffect(() => {
    const fetchWorkList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/workClassificationList`
      );
      setAllClassList(result?.data);
    };
    fetchWorkList();
    fetchLanguageList();
  }, []);

  useEffect(() => {
    flatToTree();
  }, [allClassList]);

  return (
    <div className="realtime__content_box">
      <Modal
        width={540}
        visible={addVisible}
        title={formatMessage({ id: "T0730" })} // 분류 추가
        okText={addText}
        onCancel={onCancelAdd}
        onOk={onOkAdd}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0362" })}{/* 분류 */}
        </Text>
        <Input
          onChange={onChangeCreateValue}
          name="createTitle"
          value={createValue.createTitle}
        />
        <br />
        <br />
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          <FormattedMessage id="T1178" />{/* CODE */}
        </Text>
        <Input
          onChange={onChangeCreateValue}
          name="createKey"
          value={createValue.createKey}
        />
        <br />
        <br />
        <Text
          style={{
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T1633" })}{/*  정렬 */}
        </Text>
        <Input
          type="number"
          placeholder={formatMessage({ id: "T1634" })} // 숫자만 입력해주세요.
          onChange={onChangeCreateValue}
          name="createSort"
          value={createValue.createSort}
        />
      </Modal>
      <Modal
        width={540}
        visible={updateVisible}
        title={formatMessage({ id: "T0731" })} //분류 수정
        okText={onOkText}
        onCancel={onCancelUpdate}
        onOk={onOkUpdate}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0362" })}{/* 분류 */}
        </Text>
        <Input
          onChange={onChangeupdateValue}
          name="title"
          value={updateValue.title}
        />
        <br />
        <br />
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          <FormattedMessage id="T1178" />{/* CODE */}
        </Text>
        <Input
          onChange={onChangeupdateValue}
          name="key"
          value={updateValue.key}
          readOnly
          disabled
        />
        <br />
        <br />
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T1633" })}{/* 정렬 */}
        </Text>
        <Input
          type="number"
          placeholder={formatMessage({ id: "T1634" })} // 숫자만 입력해주세요.
          onChange={onChangeupdateValue}
          name="sort"
          value={updateValue.sort}
        />
      </Modal>
      <Modal
        width={540}
        visible={deleteVisible}
        title={formatMessage({ id: "T0732" })} // 분류 삭제
        okText={formatMessage({ id: "T0072" })} //삭제
        onCancel={onCancelUpdate}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0733" })}{/* 정말로 선택된 분류를 삭제 하시겠습니까? */}
        </Text>
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0734" })}{/* 하위 분류가 있는 경우 삭제 되지 않습니다. 하위 분류를 먼저 삭제해주세요. */}
        </Text>
      </Modal>

      <div className="admin_common_box">
        <Text style={{ fontSize: 20, fontWeight: "bold" }} level={4}>
          <FormattedMessage id="T0340" />{/* 업무분류 */}
        </Text>
        <div className="admin_common_tree">
          <div className="tree_box">
            <Tree
              className="draggable-tree"
              treeData={treeList}
              defaultExpandedKeys={expand}
              expandedKeys={expand}
              showLine
              onSelect={onSelect}
              onExpand={onExpandTree}
            />
          </div>
        </div>
        <div>
          <div className="button_area">
            <Button
              type={addButtonStatus ? "disabled" : "default"}
              disabled={addButtonStatus}
              onClick={handleAdd}
            >
              <FormattedMessage id="T0049" />{/* 추가 */}
            </Button>
            &nbsp;
            <Button onClick={handleUpdate}>
              <FormattedMessage id="T0054" />{/* 수정 */}
            </Button>
            &nbsp;
            <Button
              type={buttonStatus ? "disabled" : "primary"}
              disabled={buttonStatus}
              onClick={handleRemove}
            >
              <FormattedMessage id="T0072" />{/* 삭제 */}
            </Button>
          </div>
          <div className="button_save_area">
            <Button type="primary" onClick={handleSave}>
              <FormattedMessage id="T0073" />{/* 저장 */}
            </Button>
            &nbsp;
            <Button onClick={handleReset}>
              <FormattedMessage id="T0037" />{/* 초기화 */}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignClassification;
