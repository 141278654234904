import React, { useState, useEffect } from "react";
import { Table, Text, Input, Button, RangePicker, Organization, Collapse, FormTable } from "components";
import { Select, Collapse as AntCollapse, Row, Col } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl } from "react-intl";
import XLSX from "xlsx-js-style";
import InputSearch from "components/atoms/InputSearch";
import { useSelector } from "react-redux";
import useFormatMessage from "hooks/useFormatMessage";
/**
 * Filename: RoundPurchaseHistory/index.js
 * Description: 관리자 > Item Shop 관리 > 회차별 구매 이력
 */

const { Option } = Select;
const { Panel } = AntCollapse;

const initialInputValues = {
  round: [],
  product: "",
  purchaser: [],
  regFr: "",
  regTo: "",
};

const initialPageInfo = {
  sort: "regTimestamp",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 10,
  total: 0
};

const RoundPurchaseHistory = () => {
  const { formatMessage } = useIntl();

  const fmMessage = useFormatMessage();
  const profile = useSelector((state) => state.get("auth").get("profile"));
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [dataSource, setDataSource] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const selectPlaceholder = formatMessage({ id: "T0024" }); // 선택
  const purchaser = formatMessage({ id: "T0839" }); // 구매자
  //MJ 추가
  const pageSize = [10, 20, 50, 70];
  const [sortedInfo, setSortedInfo] = useState({});

  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    purchaser: false,
  });
  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true,
    });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    setInputValues({
      ...inputValues,
      [modalLabel]: data,
    });
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const onClickReset = async () => {
    setSortedInfo({});
    setInputValues(initialInputValues);
    fetchItemList(initialPageInfo, initialInputValues);
  }

  const onClickExcelButton = async () => {
    const round = formatMessage({ id: "T0835" }); // 회차
    const product = formatMessage({ id: "T0830" }); // 상품명
    const regTimestamp = formatMessage({ id: "T0840" }); // 구매일
    const price = formatMessage({ id: "T0832" }); // 가격
    let isSkipHeader = true;
    let rows = [[round, purchaser, product, regTimestamp, price]];

    if (dataSource.length !== 0) {
      // 데이터가 있으면 skipHeader가 false 여야해서
      isSkipHeader = false;
      rows = dataSource.map((data) => {
        return {
          [round]: data.round,
          [purchaser]: data.purchaser,
          [product]: data.product,
          [regTimestamp]: moment(data.regTimestamp).format("YYYY-MM-DD"),
          [price]: data.price,
        };
      });
    }

    const worksheet = XLSX.utils.json_to_sheet(rows, {
      skipHeader: isSkipHeader,
    });
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* css */
    for (let i in worksheet) {
      if (typeof worksheet[i] !== "object") continue;
      /* header만 굵은 글씨 & 가운데 정렬 */
      worksheet[i].s = {
        font: {
          name: "Malgun Gothic",
          sz: 11,
        },
      };
      if (i.includes("1") && i.length === 2) {
        worksheet[i].s = {
          font: {
            name: "Malgun Gothic",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }
    if (!worksheet["!cols"]) worksheet["!cols"] = [];
    let cellsWidth = Array.from({ length: 5 }, () => ({ width: 20 })); // length는 header 컬럼 만큼
    worksheet["!cols"].push(...cellsWidth);

    XLSX.writeFile(workbook, "Purchase Orders.xlsx", { compression: true });
  };

  const onClickSearch = () => {
    fetchItemList();
  };

  const onChangeSearchValues = (e) => {
    const { value, name } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onSelectOption = (e) => {
    let tmpArr = [];
    for (let i in e) {
      const value = selectList.find((type) => type === e[i]);
      tmpArr.push(value);
    }
    setInputValues({
      ...inputValues,
      round: [...tmpArr],
    });
  };

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      page: 1,
      rowPerPage: +value,
    };
    fetchItemList({ ...searchObj });
  }

  const onChangeDates = (val) => {
    const range = val.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDateRange(range);
  };

  const onChangeDateRange = ([regFr, regTo]) => {
    setInputValues({
      ...inputValues,
      regFr: regFr || "",
      regTo: regTo || "",
    });
  };
  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'regTimestamp';
    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    setPageInfo({ ...pageInfo, ...searchObj });
    fetchItemList(searchObj);
  };

  // 업무 ID나 업무명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  }

  const fetchItemList = async (page, searchValues) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    if (searchValues === undefined) {
      searchValues = { ...inputValues }
    }
    const purchaser = searchValues.purchaser.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum);
      return tmpArr;
    });
    const round = searchValues.round.map((data) => {
      const tmpArr = [];
      tmpArr.push(data);
      return tmpArr;
    });


    let parameter = {
      round: round?.flat() || [],
      product: searchValues.product || "",
      purchaser: purchaser?.flat(),
      regFr: searchValues.regFr || "",
      regTo: searchValues.regTo || "",
    };

    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/academy/purchaseHistoryByTimeListMulVal?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`, {
      data: {
        ...parameter
      }
    }
    );
    setTableLoading(false)
    setDataSource([...result.data.purHisTimeList]);
    //페이지인포도 세팅
    setPageInfo({ ...page, total: result.data.totalItemsCount })
  };


  const fetchRoundList = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    const result = await fetchAbsolute(
      "get",
      `/academy/manageRoundsViewList?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );

    const roundList = [...result.data.manageRoundList].map(value => value.round);
    setSelectList([...roundList]);
  };

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: "T0835" }), // 회차
      dataIndex: "round",
      sorter: (a, b) => a.round,
      width: 100,
      align: "center",
      sortOrder: sortedInfo.field === "round" && sortedInfo.order
    },
    {
      key: 2,
      title: purchaser, // 구매자
      dataIndex: "purchaserNam",
      sorter: (a, b) => a.purchaserNam,
      sortOrder: sortedInfo.field === "purchaserNam" && sortedInfo.order,
      render: (a,b) => <span>{a}({b.purchaser})</span>,
      width: 100,
      align: "center",
    },
    {
      key: 3,
      title: formatMessage({ id: "T0830" }), // 상품명
      dataIndex: "product",
      sorter: (a, b) => a.product,
      sortOrder: sortedInfo.field === "product" && sortedInfo.order,
      width: 200,
      align: "center",
    },
    {
      key: 4,
      title: formatMessage({ id: "T0609" }), // 포인트
      dataIndex: "price", // antd v3
      sorter: (a, b) => a.price - b.price,
      sortOrder: sortedInfo.field === "price" && sortedInfo.order,
      align: "center",
      width: 100,
      render: (value, index) => {
        return <div key={index}>{value.toLocaleString()}pt</div>;
      },
    },
    {
      key: 5,
      title: formatMessage({ id: "T0840" }), // 구매일
      dataIndex: "regTimestamp",
      align: "center",
      sorter: (a, b) => a.regTimestamp,
      sortOrder: sortedInfo.field === "regTimestamp" && sortedInfo.order,
      width: 150,
      render: (record) => moment(record).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: 6,
      title: formatMessage({ id: "T0623" }), // 상태
      dataIndex: "cancelYn",
      sorter: (a, b) => a.cancelYn,
      sortOrder: sortedInfo.field === "cancelYn" && sortedInfo.order,
      align: "center",
      width: 100,
      render: (value, index) =>
        value === "Y"
          ? formatMessage({ id: "T0051" }) // 취소
          : formatMessage({ id: "T0841" }), // 구매
    },
  ];

  useEffect(() => {
    fetchItemList();
    fetchRoundList();
  }, []);

  return (
    <div className="realtime__content_box">
      <Collapse
        expandIconPosition="end"
        onChange={onChangeCollapse}
        expandIcon={() =>
          !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
        }
        activeKey={openCollapse ? ["1"] : []}
        defaultActiveKey={openCollapse ? ["1"] : []}
      >
        <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
          <FormTable pointBorder={false} size="sm">
            <colgroup>
              <col width="10.375%" />
              <col width="13.625%" />
              <col width="10.375%" />
              <col width="13.625%" />
            </colgroup>
            <tbody>
              <tr>
                <th style={{ borderRadius: "14px 0 0 0" }}>
                  <Text>{formatMessage({ id: "T0821" })}</Text>{/*회차별 상품 관리 */}
                </th>
                <td>
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    style={{ width: 288 }}
                    name="round"
                    className="round_select"
                    onChange={onSelectOption}
                    placeholder={selectPlaceholder}
                    value={inputValues.round}
                  >
                    {selectList.map((e, index) => (
                      <Option value={e} key={index}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </td>
                <th><Text>{purchaser}</Text></th>{/* 구매자 */}
                <td>
                  <InputSearch
                    onClick={onClickModalButton}
                    width={288}
                    height={32}
                    marginRight={20}
                    name="purchaser"
                    data-label="purchaser"
                    placeholder={formatMessage({ id: "T0839" })} // 구매자
                    value={inputValues.purchaser.map((c) => c.empNam).join(",")}
                  />
                </td>
              </tr>
              <tr>
                <th><Text>{formatMessage({ id: "T0830" })}</Text></th>{/* 상품명 */}
                <td>
                  <Input
                    placeholder={formatMessage({ id: "T0830" })} // 상품명
                    style={{ width: 288 }}
                    name="product"
                    value={inputValues.product}
                    onChange={onChangeSearchValues}
                    onKeyPress={onKeyPress}
                  />
                </td>
                <th><Text>{formatMessage({ id: "T0840" })}</Text></th>{/* 구매일 */}
                <td>
                  <RangePicker
                    format="YYYY-MM-DD"
                    name="dates"
                    defaultValue={[undefined, undefined]}
                    onCalendarChange={onChangeDates}
                    onChange={onChangeDates}
                    value={[
                      inputValues.regFr && inputValues.regFr.length > 0
                        ? moment(inputValues.regFr)
                        : undefined,
                      inputValues.regTo && inputValues.regTo.length > 0
                        ? moment(inputValues.regTo)
                        : undefined,
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </FormTable>
          <Row style={{ padding: "0 13px" }}>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "0 25px",
                padding: "10px 0",
              }}
            >
              <Button width="120" height="40" onClick={onClickReset}>
                {formatMessage({ id: "T0037" })}{/* 초기화 */}
              </Button>
              <Button
                width="120"
                height="40"
                type="primary"
                onClick={onClickSearch}
              >
                {formatMessage({ id: "T0039" })}{/* 검색 */}
              </Button>
            </Col>
          </Row>
        </Panel>
      </Collapse>
      <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
        <Row gutter={[8, 24]} className="align-end">
          <Col span={6}>
            <span>
              {/*  페이지당 행 */}
              {formatMessage({ id: "T0036" })} &emsp;
              <Select
                name="pagination"
                defaultValue={pageSize[0]}
                value={+pageInfo.rowPerPage}
                onSelect={onSelectPage}
              >
                {pageSize.map((v, index) => (
                  <Option key={index} value={v}>
                    {fmMessage({ id: "T1228", values: { number: v } })}
                  </Option>
                ))}{/* {number} 개 */}
              </Select>
            </span>
          </Col>
          <Col offset={12} span={6} className="round_list__button_group">
            <Button
              style={{ width: 150 }}
              type="default"
              onClick={onClickExcelButton}
            >
              {formatMessage({ id: "T0041" })}{/* 엑셀 다운로드 */}
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        className="realtime__table_content"
        columns={columns}
        dataSource={dataSource}
        pagination={true}
        // defaultPageSize={10}
        showSorterTooltip={true}
        rowKey={(e) => e.purchaseSeq}
        onChange={onChangeTableColumns}
        loading={tableLoading}
        pageOptions={{
          total: +pageInfo.total,
          current: +pageInfo.currentPage,
          pageSize: +pageInfo.rowPerPage,
        }}
      />
      <Organization
        visible={modalVisible.purchaser}
        companyCd={profile.COMP_CD}
        placeholder={purchaser}
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues["purchaser"]}
      />
    </div>
  );
};

export default RoundPurchaseHistory;