import React, { useEffect, useState } from "react";
import { Text, Radio, Button, TypePicker, Checkbox } from "components";
import { getUniqueKey } from "utils/Str";
import { useSelector } from "react-redux";
import { ScheInfoIcon } from "icons";
import { Row, Col, Select, InputNumber, Checkbox as AntCheckbox } from "antd";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;

/**
 * Filename: ScheduleEnter.js
 * Workflow > 스케줄 등록
 * Description: workflow 스케줄 등록 컴포넌트
 */

const ScheduleEnter = ({
  operValues,
  commonSchedules,
  rMonthly,
  rWeekly,
  rDaily,
  onChangeCycleDaily,
  onChangeReapeatWeekly,
  onChangeCommonDatas,
  onChangeOperValues,
  onSelectMonthlyDatas,
  requireFields,
  radioRepeatField,
  checkboxRepeatField,
  editable,
  onClickRegSchedule,
  onClickModifySchedule,
  disabledBtn,
  onClickResetSchedule,
  onCancelModifySchedule,
  scheduleInfo,
  executeButton,
  setEmailTriggerStatus,
  emailTriggerList,
  onChangeEmailTrigger,
  onChangeChatTrigger,
  onClickEmailTriggerInfo,
  chatbotStatus,
  emailInfoSeq,
}) => {
  const { formatMessage } = useIntl();

  const scheduleType = formatMessage({ id: "T0010" });
  const selectOption = formatMessage({ id: "T0024" });
  const immediatelyText = formatMessage({ id: "T0059" }); // 즉시 실행

  const [radioItem, setRadioItem] = useState([]);

  // operValues
  const onChangeScheduleType = (e) => {
    onChangeOperValues("radioScheduleType", e.target.value);

    if (e.target.value === "TRIGGER_EMAIL") {
      setEmailTriggerStatus(true);
    } else {
      setEmailTriggerStatus(false);
    }
  }

  const onSelectScheduleCycle = (e) => onChangeOperValues("scheduleCycle", e);

  // dailyOper
  const onChangeStartDate = (e) => onChangeCommonDatas("startDate", e);
  const onChangeStartTime = (e) => onChangeCommonDatas("startTime", e);

  // rWeekly
  const onChangeCycleWeekly = (e) => onChangeReapeatWeekly("weeklyInterval", e);
  const onChangeWeeklyDay = (e) => onChangeReapeatWeekly("weeklyDay", e);

  // commonSchedules
  const onChangeWeeklyStartDate = (e) => onChangeCommonDatas("startDate", e);
  const onChangeWeeklySatrtTime = (e) => onChangeCommonDatas("startTime", e);
  const onChangeWeeklyEndDate = (e) => onChangeCommonDatas("endDate", e);
  const onChangeWeeklyEndTime = (e) => onChangeCommonDatas("endTime", e);
  const onChangeRepeatCheck = (e) => onChangeCommonDatas("repeatCheck", e.target.checked);
  const onChangeRepeatTime = (e) => onChangeCommonDatas("repeatTime", e);
  const onSelectRepeatCycle = (e) => onChangeCommonDatas("repeatCycle", e);

  // rMonthly
  const onChangeMonth = (e) => onSelectMonthlyDatas("month", e);
  const onChangeMonthlyScheduleType = (e) => onSelectMonthlyDatas("scheduleType", e.target.value);
  const onSelectMonthlyDate = (e) => onSelectMonthlyDatas("date", e);
  const onSelectMonthlyWeek = (e) => onSelectMonthlyDatas("week", e);
  const onSelectMonthlyDay = (e) => onSelectMonthlyDatas("day", e);

  /* 스케줄 유형 날짜 및 시간 disabled 조건 */
  const disabledDate = (current) => moment().format("YYYYMMDD") > current.format("YYYYMMDD");
  const disabledHours = () => {
    const dateString = moment().format("YYYYMMDD");
    const minString = moment().format("mm");
    const hourString = moment().format("HH");
    if (moment(commonSchedules.startDate).format("YYYYMMDD") === dateString) {
      // 시간 disabled
      return Array.from({ length: +minString >= 59 ? hourString + 1 : hourString }, (v, i) => i);
    }
  };
  const disabledMinutes = (selectedHour) => {
    const dateString = moment().format("YYYYMMDD");
    const minString = moment().format("mm");
    const hourString = moment().format("HH");

    if (moment(commonSchedules.startDate).format("YYYYMMDD") === dateString && +selectedHour === +hourString) {
      // 분 disabled
      return Array.from({ length: +minString + 1 }, (v, i) => i);
    } else if (+selectedHour === -1) {
      return Array.from({ length: 60 }, (v, i) => i);
    }
  };

  useEffect(() => {
    const tmpArr = [
      {
        key: 1,
        value: "immediately",
        text: immediatelyText,
        render: (
          <>
            <Text className="schedule_radio_text" >{immediatelyText}</Text>
          </>
        ),
      }, {
        key: 2,
        value: "oneTime",
        text: `${formatMessage({ id: "T0544" })}`,
        render: (
          <>
            <Text className="schedule_radio_text">{formatMessage({ id: "T0544" })}</Text>
          </>
        ),
      }, {
        key: 3,
        value: "repeat",
        render: (
          <>
            <Text className="schedule_radio_text">반복실행</Text>
            <Select
              className="status_box__cycle"
              defaultValue="DAILY"
              value={operValues.scheduleCycle}
              name="schedule_cycle"
              disabled={operValues.radioScheduleType !== "repeat"}
              onSelect={onSelectScheduleCycle}
            >
              {[
                {
                  key: 1,
                  value: "DAILY",
                  text: formatMessage({ id: "T0437" }),// 일
                },
                {
                  key: 2,
                  value: "WEEKLY",
                  text: formatMessage({ id: "T1139" }),// 주
                },
                {
                  key: 3,
                  value: "MONTHLY",
                  text: formatMessage({ id: "T0439" }),// 월
                },
              ].map((v, index) => (
                <Option key={index} value={v.value}>
                  {v.text}
                </Option>
              ))}
            </Select>
          </>
        ),
      },
      {
        key: 4,
        value: "TRIGGER_EMAIL",
        text: `Email ${formatMessage({ id: "T1306" })}`,
        render: (
          <>
            <Text className="schedule_radio_text"> Email {formatMessage({ id: "T1306" })}</Text>
          </>
        ),
      },
      {
        key: 5,
        value: "TRIGGER_CHATBOT",
        text: `Chatbot ${formatMessage({ id: "T1306" })}`,
        render: (
          <>
            <Text className="schedule_radio_text">Chatbot {formatMessage({ id: "T1306" })}</Text>
          </>
        ),
      },

    ];

    let findImmediately = tmpArr.findIndex(i => i.value === "immediately");
    if (editable) {
      tmpArr.splice(findImmediately, 1);
    }

    setRadioItem([...tmpArr]);

  }, [executeButton, scheduleInfo, operValues, editable]);

  return (
    <div className="status_box">
      <div className="status_box__title">
        {editable ? <Text>{formatMessage({ id: "T1034" })}</Text> : <Text>{formatMessage({ id: "T0547" })}</Text>}
      </div>
      <div className="status_box__info">

        <Row>
          <Col span={2} style={{ paddingTop: 5 }}>
            <Text className="status_row__title" required={true}>
              {scheduleType}
            </Text>
          </Col>
          <Col span={22} className="status_row__schedule_kind">
            <div className="flex">
              <Radio
                className="flex"
                value={operValues.radioScheduleType}
                renderItem={radioItem}
                name="schedule_type"
                onChange={onChangeScheduleType}
                type="schedule_trigger"
              />

            </div>
            {operValues.radioScheduleType === "oneTime" && (
              <div className="status_box__schedule_kind">
                <div className="schedule_kind__onetime">
                  <Text className="font_12">{formatMessage({ id: "T0542" })}</Text> {/* 시작 날짜 */}
                  <TypePicker
                    className="schedule_onetime__date"
                    type="date"
                    placeholder={formatMessage({ id: 'T1104' })}
                    format="YYYY-MM-DD"
                    label="startDate"
                    value={commonSchedules.startDate}
                    onChange={onChangeStartDate}
                    disabledDate={disabledDate}
                    innerRef={requireFields.startDate.ref}
                  />
                  <TypePicker
                    className="schedule_onetime__time"
                    type="time"
                    placeholder="00:00"
                    format="HH:mm"
                    value={commonSchedules.startTime}
                    onChange={onChangeStartTime}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                    innerRef={requireFields.startTime.ref}
                  />
                </div>
              </div>
            )}
            {operValues.radioScheduleType === "repeat" && (
              <div className="status_box__schedule_kind schedule_repeat">
                {operValues.scheduleCycle === "DAILY" && (
                  <div className="schedule_kind__cycle cycle_daily">
                    <Text>{formatMessage({ id: "T0549" })}</Text>
                    <Select
                      className="schedule_kind__daily_select"
                      defaultValue={1}
                      onChange={onChangeCycleDaily}
                      value={
                        rDaily.dailyInterval
                          ? rDaily.dailyInterval
                          : rWeekly.weeklyInterval
                            ? rWeekly.weeklyInterval
                            : 1
                      }
                    >
                      {Array.from({ length: 31 }, () => 0).map((v, index) => (
                        <Option key={index} value={index + 1}>
                          {index + 1}
                        </Option>
                      ))}
                    </Select>
                    <Text>{formatMessage({ id: "T0550" })}</Text>
                  </div>
                )}
                {operValues.scheduleCycle === "WEEKLY" && (
                  <div className="schedule_kind__cycle cycle_weekly">
                    <div className="schedule_kind__weekly_box">
                      <Text>{formatMessage({ id: "T0549" })}</Text>
                      <Select
                        className="schedule_kind__weekly_select"
                        defaultValue="1"
                        onChange={onChangeCycleWeekly}
                        value={rWeekly.weeklyInterval}
                      >
                        {Array.from({ length: 5 }, () => 0).map(
                          (v, index) => (
                            <Option key={index} value={index + 1}>
                              {index + 1}
                            </Option>
                          )
                        )}
                      </Select>
                      <Text>{formatMessage({ id: "T0551" })}</Text>
                    </div>
                    <AntCheckbox.Group
                      className="schedule_kind__weekly_box"
                      onChange={onChangeWeeklyDay}
                      value={rWeekly.weeklyDay}
                    >
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="MON"
                      >
                        {formatMessage({ id: "T0439" })}
                      </Checkbox>
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="TUE"
                      >
                        {formatMessage({ id: "T0552" })}
                      </Checkbox>
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="WED"
                      >
                        {formatMessage({ id: "T0553" })}
                      </Checkbox>
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="THU"
                      >
                        {formatMessage({ id: "T0554" })}
                      </Checkbox>
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="FRI"
                      >
                        {formatMessage({ id: "T0555" })}
                      </Checkbox>
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="SAT"
                      >
                        {formatMessage({ id: "T0556" })}
                      </Checkbox>
                      <Checkbox
                        className="schedule_kind__cycle_checkbox"
                        name="day"
                        size="18"
                        value="SUN"
                      >
                        {formatMessage({ id: "T0437" })}{/* 일 */}
                      </Checkbox>
                    </AntCheckbox.Group>
                  </div>
                )}
                {operValues.scheduleCycle === "MONTHLY" && (
                  <div className="schedule_kind__cycle cycle_monthly">
                    <AntCheckbox.Group
                      className="schedule_kind__monthly_box"
                      onChange={onChangeMonth}
                      value={rMonthly.month}
                    >
                      <Checkbox size="18" value="JAN">
                        1
                      </Checkbox>
                      <Checkbox size="18" value="FEB">
                        2
                      </Checkbox>
                      <Checkbox size="18" value="MAR">
                        3
                      </Checkbox>
                      <Checkbox size="18" value="APR">
                        4
                      </Checkbox>
                      <Checkbox size="18" value="MAY">
                        5
                      </Checkbox>
                      <Checkbox size="18" value="JUN">
                        6
                      </Checkbox>
                      <Checkbox size="18" value="JUL">
                        7
                      </Checkbox>
                      <Checkbox size="18" value="AUG">
                        8
                      </Checkbox>
                      <Checkbox size="18" value="SEP">
                        9
                      </Checkbox>
                      <Checkbox size="18" value="OCT">
                        10
                      </Checkbox>
                      <Checkbox size="18" value="NOV">
                        11
                      </Checkbox>
                      <Checkbox size="18" value="DEC">
                        12
                      </Checkbox>
                    </AntCheckbox.Group>
                    <div className="schedule_kind__monthly_box">
                      <Radio
                        className="monthly_box__radio_wrap"
                        name="monthly_schedule_type"
                        value={rMonthly.scheduleType}
                        onChange={onChangeMonthlyScheduleType}
                        renderItem={[
                          {
                            value: "DAILY",
                            key: 1,
                            text: "",
                            render: (
                              <>
                                <Select
                                  className="monthly_box__day_select"
                                  defaultValue={1}
                                  onSelect={onSelectMonthlyDate}
                                  value={rMonthly.date}
                                  disabled={
                                    rMonthly.scheduleType === "MONTHLY"
                                  }
                                >
                                  {Array.from({ length: 31 }, () => 0).map(
                                    (v, index) => (
                                      <Option key={index} value={index + 1}>
                                        {index + 1}
                                      </Option>
                                    )
                                  )}
                                </Select>
                                <Text>{formatMessage({ id: "T0437" })}</Text>{/* 일 */}
                              </>
                            ),
                          },
                          {
                            value: "MONTHLY",
                            key: 2,
                            text: "",
                            render: (
                              <>
                                <Select
                                  className="monthly_box__turn_select"
                                  placeholder={formatMessage({ id: "T0557" })}
                                  defaultValue="FIRST"
                                  value={rMonthly.week}
                                  onSelect={onSelectMonthlyWeek}
                                  disabled={rMonthly.scheduleType === "DAILY"}
                                >
                                  <Option value="FIRST">
                                    {formatMessage({ id: "T0557" })}
                                  </Option>
                                  <Option value="SECOND">
                                    {formatMessage({ id: "T0558" })}
                                  </Option>
                                  <Option value="THIRD">
                                    {formatMessage({ id: "T0559" })}
                                  </Option>
                                  <Option value="FOURTH">
                                    {formatMessage({ id: "T0560" })}
                                  </Option>
                                  <Option value="FIFTH">
                                    {formatMessage({ id: "T0561" })}
                                  </Option>
                                </Select>
                                <Select
                                  className="monthly_box__month_select"
                                  placeholder={formatMessage({ id: "T0439" })}
                                  disabled={rMonthly.scheduleType === "DAILY"}
                                  value={rMonthly.day}
                                  defaultValue="MON"
                                  onSelect={onSelectMonthlyDay}
                                >
                                  <Option value="MON">
                                    {formatMessage({ id: "T0439" })}
                                  </Option>
                                  <Option value="TUE">
                                    {formatMessage({ id: "T0552" })}
                                  </Option>
                                  <Option value="WED">
                                    {formatMessage({ id: "T0553" })}
                                  </Option>
                                  <Option value="THUS">
                                    {formatMessage({ id: "T0554" })}
                                  </Option>
                                  <Option value="FRI">
                                    {formatMessage({ id: "T0555" })}
                                  </Option>
                                  <Option value="SAT">
                                    {formatMessage({ id: "T0556" })}
                                  </Option>
                                  <Option value="SUN"> {formatMessage({ id: "T0437" })}</Option>{/* 일 */}
                                </Select>
                              </>
                            ),
                          },
                        ]}
                      />
                    </div>
                  </div>
                )}
                <div className="schedule_kind__date_range">
                  <div className="schedule_kind__date">
                    <Text>{formatMessage({ id: "T0542" })}</Text>{/* 시작 날짜 */}
                    <TypePicker
                      type="date"
                      placeholder={formatMessage({ id: 'T1104' })}
                      format="YYYY-MM-DD"
                      label="startDate"
                      name="startDate"
                      value={commonSchedules.startDate}
                      onChange={onChangeWeeklyStartDate}
                      disabledDate={disabledDate}
                      innerRef={requireFields.startDate.ref}
                    />
                  </div>
                  <div className="schedule_kind__date">
                    <Text>{formatMessage({ id: "T0540" })}</Text>
                    <TypePicker
                      placeholder={formatMessage({ id: 'T1103' })}
                      type="date"
                      data-label="endDate"
                      format="YYYY-MM-DD"
                      value={commonSchedules?.endDate}
                      onChange={onChangeWeeklyEndDate}
                      disabledDate={disabledDate}
                      innerRef={radioRepeatField.endDate.ref}
                    />
                  </div>
                  <div className="schedule_kind__date">
                    <Text>{formatMessage({ id: "T0543" })}</Text>
                    <TypePicker
                      type="time"
                      placeholder="00:00"
                      format="HH:mm"
                      label="startTime"
                      value={commonSchedules?.startTime}
                      onChange={onChangeWeeklySatrtTime}
                      disabledHours={disabledHours}
                      disabledMinutes={disabledMinutes}
                      innerRef={requireFields.startTime.ref}
                    />
                  </div>
                </div>
              </div>
            )}
            {operValues.radioScheduleType === "repeat" && (
              <>
                <div className="schedule_kind__repeat">
                  <Checkbox
                    className="schedule_kind__repeat_check"
                    size="18"
                    onChange={onChangeRepeatCheck}
                    checked={commonSchedules.repeatCheck}
                  >
                    {formatMessage({ id: "T0562" })}
                  </Checkbox>
                  {commonSchedules.repeatCheck ? (
                    <div className="schedule_kind__repeat_box">
                      <div className="schedule_kind__date_range">
                        <div className="schedule_kind__date">
                          <br />
                          <InputNumber
                            min={1}
                            max={23}
                            defaultValue={1}
                            value={commonSchedules?.repeatTime}
                            onChange={onChangeRepeatTime}
                            placeholder="1"
                          />
                        </div>
                        <div className="schedule_kind__date">
                          <br />
                          <Select
                            className="schedule_kind__repeat_time"
                            placeholder={`${selectOption}`}
                            data-label="repeatCycle"
                            value={commonSchedules.repeatCycle}
                            defaultValue="MINUTES"
                            onSelect={onSelectRepeatCycle}
                          >
                            <Option value="MINUTES">{formatMessage({ id: 'T0493' })}</Option>
                            <Option value="HOURS">
                              {formatMessage({ id: "T0563" })}
                            </Option>
                          </Select>
                        </div>
                        <div className="schedule_kind__date">
                          <Text>{formatMessage({ id: "T0541" })}</Text>
                          <TypePicker
                            format="HH:mm"
                            placeholder="00:00"
                            type="time"
                            label="endTime"
                            name="endTime"
                            value={commonSchedules?.endTime}
                            onChange={onChangeWeeklyEndTime}
                            innerRef={checkboxRepeatField.endTime.ref}
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="schedule_kind__repeat_box">
                      <div className="schedule_kind__date_range">
                        <div className="schedule_kind__date">
                          <br />
                          <InputNumber value={1} disabled />
                        </div>
                        <div className="schedule_kind__date">
                          <br />
                          <Select
                            className="schedule_kind__repeat_time"
                            value={formatMessage({ id: 'T0493' })}
                            disabled
                          />
                        </div>
                        <div className="schedule_kind__date">
                          <Text>{formatMessage({ id: "T0541" })}</Text>
                          <TypePicker
                            format="HH:mm"
                            placeholder="00:00"
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}


            {operValues.radioScheduleType === "TRIGGER_EMAIL" && (
              <div className="status_box__schedule_kind">
                <Text className="email_trigger_name">Email Trigger Name</Text>
                <Select
                  showSearch
                  style={{
                    width: 250,
                  }}
                  placeholder={formatMessage({ id: "T1304" })}
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.includes(input)}
                  onChange={onChangeEmailTrigger}
                  value={emailInfoSeq.toString() || undefined}
                >
                  {emailTriggerList.map((e) => {
                    return (
                      <Option key={e.emailInfoSeq} value={e.emailInfoSeq}>{e.name}</Option>
                    )
                  })}

                </Select>

                <ScheInfoIcon
                  onClick={onClickEmailTriggerInfo}
                  style={{ marginLeft: "20px", alignSelf: "center", paddingTop: 2 }}
                />
              </div>
            )}

            {operValues.radioScheduleType === "TRIGGER_CHATBOT" && (
              <div className="status_box__schedule_kind">
                <Text className="email_trigger_name"><FormattedMessage id="T1305" /></Text>  {/* 활성화 여부 */}
                <Radio
                  value={chatbotStatus}
                  defaultValue={"activate"}
                  onChange={onChangeChatTrigger}
                  renderItem={[
                    {
                      value: "activate",
                      text: "Activate",
                    },
                    {
                      value: "deactivate",
                      text: "Deactivate",
                    },
                  ]}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col
            className="flex justify-end"
            style={{ gap: "0 10px" }}
            span={6}
            offset={18}
          >
            <Button width="144" height="40" onClick={onClickResetSchedule}>
              <FormattedMessage id="T0037" />{/* 초기화 */}
            </Button>
            {!editable ? (
              <Button
                width="144"
                height="40"
                type="primary"
                onClick={onClickRegSchedule}
                disabled={disabledBtn}
              >
                <FormattedMessage id="T0050" />{/* 등록 */}
              </Button>
            ) : (
              <>
                <Button
                  width="144"
                  height="40"
                  type="primary"
                  onClick={onClickModifySchedule}
                >
                  <FormattedMessage id="T0054" />{/* 수정 */}
                </Button>
                <Button
                  width="144"
                  height="40"
                  type="default"
                  onClick={onCancelModifySchedule}
                >
                  <FormattedMessage id="T0051" />{/* 취소 */}
                </Button>
              </>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ScheduleEnter;