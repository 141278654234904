import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import { Button, Text } from "components";
import { Tooltip } from "antd";
import Wrapper from "./Styled";
import Zero from "./zero.png";
import First from "./1st.png";
import Second from "./2nd.png";
import Third from "./3rd.png";
import Fourth from "./4th.png";
import Fifth from "./5th.png";

/**
 * 단계별 가이드 제공
 *
 */

const MainGuideStep = ({ profile }) => {
  const { formatMessage } = useIntl();

  const [guideSteps, setGuideSteps] = useState([]);
  const [myTaskStep, setMyTaskStep] = useState({});

  const imgs = [
    {
      key: 1,
      cd: "SG01",
      title: Zero,
    },
    {
      key: 2,
      cd: "SG02",
      title: First,
    },
    {
      key: 3,
      cd: "SG03",
      title: Second,
    },
    {
      key: 4,
      cd: "SG04",
      title: Third,
    },
    {
      key: 5,
      cd: "SG05",
      title: Fourth,
    },
    {
      key: 6,
      cd: "SG06",
      title: Fifth,
    },
  ];

  useEffect(() => {
    const getStepGuideCd = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/STEP_GUIDE`
      );
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      const tmpArr = result.data.map((v) => {
        const tmpObj = { ...imgs.find((i) => i.cd === v.cd) };
        return {
          ...v,
          ...tmpObj,
        };
      });
      setGuideSteps([...tmpArr]);
    };
    const getMyTaskStep = async () => {
      const result = await fetchAbsolute(
        "get",
        `/main/personalized/getTaskStep?empNo=${profile.EMP_NO}`
      );
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setMyTaskStep({ ...result.data });
    };
    getStepGuideCd();
    getMyTaskStep();
  }, []);

  return (
    <Wrapper>
      {guideSteps
        .filter((v) => v.cd === myTaskStep.step)
        .map((steps, index) => {
          const checkHttp = steps.attrCd3.includes("http"); // alert일 경우에 _blank에선 안되게(오류 발생)
          return (
            <React.Fragment key={index}>
              <img
                src={`${steps.title}`}
                className="steps_img"
                alt={`${steps.title}`}
              />
              <div className="steps_info">
                <Tooltip title={steps.attrCd1}>
                  <Text className="guide_step__title">{steps.attrCd1}</Text>
                </Tooltip>
                <Tooltip title={steps.attrCd2}>
                  <Text className="guide_step__detail">{steps.attrCd2}</Text>
                </Tooltip>
                <div className="take_a_look">
                  <Button className="btn_style">
                    <a
                      href={steps.attrCd3}
                      target={checkHttp && "_blank"}
                      rel="noopener noreferrer"
                    >
                      {formatMessage({ id: "T0098" })}{/* 가이드 보기 */}
                    </a>
                  </Button>
                </div>
              </div>
            </React.Fragment>
          );
        })}
    </Wrapper>
  );
};

export default MainGuideStep;
