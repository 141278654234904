import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled";
import { Row, Col, Select, Radio } from "antd";
import {
  Input,
  Button,
  InputSearch,
  RangePicker,
  FormTable,
  Text,
  DepartmentList,
  Organization,
  BusinessClass,
  Modal,
  RadioButton,
} from "components";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  setHandyFlowWorkflowListValue,
  setHandyFlowWorkflowListSpread,
  resetHandyFlowWorkflowListValue,
  setHandyFlowWorkflowListFilterId,
} from "store/hfworkflowList/action";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

const initialInputValues = {
  workflowId: "",
  workflowNm: "",
  workflowOrgCd: [],
  workId: [],
  regFr: "",
  regTo: "",
  regType: "",
  devIds: [],
  mgrIds: [],
  statusCd: []
};

/**
 * Filename: HFWorkflowListTop/index.js
 * Description: workflow 목록 조회 페이지
 */

const HFWorkflowListTop = ({
  replaceSearch,
  searchQuery,
  beforeSearch,
  setBeforeSearch,
  setSortedInfo
}) => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.get("auth").get("profile"));
  const inputValues = useSelector(
    (state) => state.get(getUniqueKey("hfworkflowList")).inputValues
  );
  const filterId = useSelector(
    (state) => state.get(getUniqueKey("hfworkflowList")).filterId
  );

  const isDelete = useRef(false);
  const searchPlaceholder = formatMessage({ id: "T0025" }); // 검색어를 입력하세요
  const mgrEmpNoPlaceholder = formatMessage({ id: "T0026" }); // 사용자를 선택하세요
  const recentSearch = formatMessage({ id: "T0031" }); // 최근 검색
  const myFilter = formatMessage({ id: "T0032" }); // 내 필터
  const selectFilter = formatMessage({ id: "T0033" }); // 필터 선택
  const saveFilter = formatMessage({ id: "T0034" }); // 필터 저장
  const filterNm = formatMessage({ id: "T0035" }); // 필터명
  const filterLimited = formatMessage({ id: "T0152" }); // 필터 저장은 5개까지만 가능합니다.
  const selectPlaceholder = formatMessage({ id: "T0024" }); // 선택
  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    workflowOrgCd: false,
    devIds: false,
    filter: false,
    workId: false,
    mgrIds: false,

  });
  // 저장 필터 이름
  const [saveFilterName, setSaveFilterName] = useState("");
  // filter List State
  const [filterList, setFilterList] = useState([]);
  const [isCheckedDate, setIsCheckedDate] = useState(false);
  const [checkedDate, setCheckedDate] = useState("");
  const [statusCdList, setStatusCdList] = useState([]);

  //  select 부분에선 e.target.value 등이 안먹혀서 둘 만 따로 'e'로 관리
  const onChangeSearchInput = (label, value) => {
    dispatch(setHandyFlowWorkflowListValue(label, value));
  };
  // Date는 두 개의 키 값을 변경하기 때문에 충돌 방지
  const onChangeDateRange = ([regFr, regTo]) => {
    dispatch(
      setHandyFlowWorkflowListSpread({
        regFr: regFr || "",
        regTo: regTo || "",
      })
    );
  };

  // 업무 ID나 업무명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearchButton(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  // input 부분 나머지 search와 select는 위에 따로 빼줌
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    onChangeSearchInput(name, value);
  };

  // RangePicker 변경 함수
  const onChangeDates = (val) => {
    const range = val.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDateRange(range);
  };

  // Date 버튼 클릭 이벤트
  const onChangeDateRadio = (e) => {
    const { value } = e.target;
    setIsCheckedDate(e.target.checked);
    dispatch(setHandyFlowWorkflowListValue("regType", value));
  };

  const onClickDateRadio = (e) => {
    setCheckedDate(e.target.value);
    if (e.target.value === checkedDate) {
      setIsCheckedDate(false);
      setCheckedDate("");
      dispatch(
        setHandyFlowWorkflowListSpread({
          regFr: "",
          regTo: "",
        })
      );
      dispatch(setHandyFlowWorkflowListValue("regType", ""));
    }
  };

  // 저장할 필터 이름 변경 이벤트
  const onChangeSaveFilterName = (e) => {
    // 필터 이름 15글자 제한
    if (e.target.value.length > 15) return;
    setSaveFilterName(e.target.value);
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true,
    });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    dispatch(setHandyFlowWorkflowListValue(modalLabel, data));
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  const onOkFilterModal = async () => {
    setModalVisible({
      ...modalVisible,
      filter: false,
    });

    if (saveFilterName.trim().length === 0) {
      alert(formatMessage({ id: "T0150" })); // 필터명을 입력해주세요.
      return;
    }
    if (
      filterList.findIndex(
        (v) => v.filterNm.toUpperCase() === saveFilterName.toUpperCase()
      ) !== -1
    ) {
      alert(formatMessage({ id: "T0151" })); // 필터명이 중복됩니다.
      return;
    }
    // 내 필터 5개 제한
    if (filterList.length > 4) {
      alert(filterLimited);
      return;
    }
    const saveInputValues = inputValues;

    //regType을 "custom"으로 바꿔줌
    if (saveInputValues.regType) {
      saveInputValues.regType = "custom";
    }

    const addFilter = await fetchAbsolute("post", `/common/commonFilter/addNew`, {
      data: {
        ...setFilterObject(saveInputValues, saveFilterName, "filterSave"),
        filterGbn: "w",
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    setSaveFilterName("");
    setFilterList([
      ...filterList,
      {
        ...setFilterObject(saveInputValues, saveFilterName),
        filterId: addFilter.data.filterId,
        filterNm: saveFilterName,
      },
    ]);
  };
  // statusCd 변경 함수
  const onChangeState = (e) => {
    let tmpArr = [];
    for (let i in e) {
      const value = statusCdList.find((status) => status.cd === e[i]);
      tmpArr.push(value);
    }
    onChangeSearchInput("statusCd", [...tmpArr]);
  };

  // 필터 저장할 때 parameter 세팅 / filterList state 값 세팅
  const setFilterObject = (values, filterName, type) => {
    const convertedValues = {
      workflowId: values.workflowId,
      workflowNm: values.workflowNm,
      workflowOrgCd: values.workflowOrgCd.map(val=>val.codeId),
      mgrIds: values.mgrIds.map(val=>val.empNum),
      devIds: values.devIds.map(val=>val.empNum),
      workId: values.workId.map(val=>val.cd),
      statusCd: values.statusCd.map(val=>val.cd),
      regFr: values.regFr,
      regTo: values.regTo,
      regType: values.regType
    }

    if(type==="filterSave") {
      const filterSaveObject = {
        empNo: profile.EMP_NO,
        filterNm: filterName,
        filterList: [
          {
            filterNum: "filter1", //workflowId
            attrList: convertedValues.workflowId===''?[]:[convertedValues.workflowId],
          },
          {
            filterNum: "filter2", //workflowNm
            attrList: convertedValues.workflowNm===''?[]:[convertedValues.workflowNm],
          },
          {
            filterNum: "filter3", //devIds
            attrList: convertedValues.devIds.flat(),
          },
          {
            filterNum: "filter4", //mgrIds
            attrList: convertedValues.mgrIds.flat(),
          },
          {
            filterNum: "filter5", //workId
            attrList: convertedValues.workId.flat(),
          },
          {
            filterNum: "filter6", //workflowOrgCd
            attrList: convertedValues.workflowOrgCd.flat(),
          },
          {
            filterNum: "filter7", //regFr
            attrList: convertedValues.regFr===''?[]:[convertedValues.regFr],
          },
          {
            filterNum: "filter8", //regTo
            attrList: convertedValues.regTo===''?[]:[convertedValues.regTo],
          },
          {
            filterNum: "filter9", //regType
            attrList: convertedValues.regType===''?[]:[convertedValues.regType],
          },
          {
            filterNum: "filter10", //statusCd
            attrList: convertedValues.statusCd.flat(),
          },
        ],
      };
      return filterSaveObject;
    } else {
      return convertedValues;
    }
  };

  // 초기화 버튼 클릭 이벤트
  const onClickResetButton = () => {
    dispatch(resetHandyFlowWorkflowListValue());
    dispatch(setHandyFlowWorkflowListFilterId(""));
    replaceSearch({ ...initialInputValues, page: 0 });
    setSortedInfo({})
  };

  // 검색 버튼 클릭 이벤트
  const onClickSearchButton = () => {
    dispatch(setHandyFlowWorkflowListFilterId(""));
    replaceSearch({ ...inputValues, page: 0 });
  };
  // 최근 검색 이벤트
  const onClickBeforeSearch = () => {
    const searchObj = JSON.parse(localStorage.getItem("beforeHFWorkflowObject") || {});
    dispatch(setHandyFlowWorkflowListSpread({ ...initialInputValues, ...searchObj }));
    replaceSearch({ ...searchObj, page: 0 });
  };

  // 최근 검색 삭제 클릭 이벤트
  const onClickBeforeSearchDelete = () => {
    setBeforeSearch("");
    localStorage.setItem("beforeHFWorkflowSearch", "");
    localStorage.setItem("beforeHFWorkflowObject", "");
  };

  // 필터 리스트 변경 이벤트
  const onSelectFilterList = (value) => {
    //검색어 남겨져 있는 상태에서 필터검색시 초기화x
    // setSearchQuery(initialSearchQuery);
    // 삭제 버튼 눌렀을 때 filterId 변경되지 않게 세팅
    if (isDelete.current) {
      isDelete.current = false;
      return;
    }
    dispatch(setHandyFlowWorkflowListFilterId(value));
  };


  // 필터 삭제 버튼 클릭 이벤트
  const onClickFilterDelete = async (e) => {
    isDelete.current = true;
    const value = e.currentTarget.dataset.filterid;
    const deleteFilterList = await fetchAbsolute(
      "delete",
      `/common/commonFilter/deleteNew`,
      {
        data: {
          empNo: profile.EMP_NO,
          filterId: value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (deleteFilterList.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    const tmpArr = [...filterList];
    if (filterId === value) { dispatch(setHandyFlowWorkflowListFilterId("")) }
    setFilterList([...tmpArr.filter((filter) => filter.filterId !== value)]);
  };

  useEffect(() => {
    if (inputValues.regType === "custom" || inputValues.regType === "") return;

    const today = moment().format("YYYY-MM-DD");
    const dateObj = {
      regFr: "",
      regTo: today,
    };

    switch (inputValues.regType) {
      case "today":
        dateObj.regFr = moment().add(0, "d").format("YYYY-MM-DD");
        break;
      case "week":
        dateObj.regFr = moment().subtract(6, "d").format("YYYY-MM-DD");
        break;
      case "month":
        dateObj.regFr = moment().subtract(1, "months").format("YYYY-MM-DD");
        break;
      default:
    }

    dispatch(
      setHandyFlowWorkflowListSpread({
        ...dateObj,
      })
    );
  }, [inputValues.regType]);

  useEffect(() => {
    dispatch(
      setHandyFlowWorkflowListSpread({
        ...searchQuery,
      })
    );
  }, [searchQuery]);

  /* 필터 선택시 inputValues에 뿌려줌 */
  useEffect(() => {
    if (filterId === "" || filterList.length === 0) return;
    const filterObject = {
      ...(filterList.find((v) => v.filterId === filterId) || {}),
    };
    if (filterObject) {
      delete filterObject.filterNm;
      delete filterObject.filterId;

      const { devIds, workflowOrgCd, workId, mgrIds, statusCd } = filterObject;

      const setDatas = async () => {
        // 부서 정보 세팅
        if (workflowOrgCd) {
            const tmpArr = [];
            for (let i in workflowOrgCd) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/departmentList?orgCd=${workflowOrgCd[i]}`
              );
              tmpArr.push(data?.mdList[0]);
            }
            filterObject.workflowOrgCd = [...tmpArr];
        }
        // 개발자 정보 세팅
        if (devIds) {
            const tmpArr = [];
            for (let i in devIds) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${devIds[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
            filterObject.devIds = [...tmpArr];
        }
        // 업무 분류  정보 세팅
        if (workId) {
            const tmpArr = [];
            for (let i in workId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/workClassification?cd=${workId[i]}`
              );
              tmpArr.push(data);
            }
            filterObject.workId = [...tmpArr];
        }
        // 업무 담당자  정보 세팅
        if (mgrIds) {
            const tmpArr = [];
            for (let i in mgrIds) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${mgrIds[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
            filterObject.mgrIds = [...tmpArr];
        }
        // 업무 상태 정보 세팅
        if (statusCd) {
            const tmpArr = [];
            for (let i in statusCd) {
              const value = statusCdList.find(
                (status) => status.cd === statusCd[i]
              );
              tmpArr.push(value);
            }
            filterObject.statusCd = [...tmpArr];
        }
        dispatch(setHandyFlowWorkflowListSpread({ ...searchQuery, ...filterObject, }));
        replaceSearch({ ...filterObject, page: 0 });
      }
      setDatas();
    }
  }, [filterId, filterList]);

  useEffect(() => {
    // task Status Code 가져오는 함수
    const fetchStatusCd = async () => {
      try {
        const result = await fetchAbsolute(
          "get",
          "/common/commonCd/getCategoryId/WORKFLOW_STATUS"
        );
        setStatusCdList([
          ...result.data?.filter((v) => v.upCd === "0" && v.useYn === "Y"),
        ]);
      } catch (err) {
        return alert(err);
      }
    };
    const fetchFilterList = async () => {
      try {
        const { data } = await fetchAbsolute(
          "get",
          `/common/commonFilter/findByEmpNoAndFilterGbnNew?empNo=${profile.EMP_NO}&filterGbn=w`
        );
        const lists = data?.map((filter) => ({
          filterId: filter.filterId,
          filterNm: filter.filterNm,
          workflowId: filter.filter1?.map((data) => data.filterAttr)[0] || "",
          workflowNm: filter.filter2?.map((data) => data.filterAttr)[0] || "",
          devIds: filter.filter3?.map((data) => data.filterAttr) || [],
          mgrIds: filter.filter4?.map((data) => data.filterAttr) || [],
          workId: filter.filter5?.map((data) => data.filterAttr) || [],
          workflowOrgCd: filter.filter6?.map((data) => data.filterAttr) || [],
          regFr: filter.filter7?.map((data) => data.filterAttr)[0] || "",
          regTo: filter.filter8?.map((data) => data.filterAttr)[0] || "",
          regType: filter.filter9?.map((data) => data.filterAttr) || [],
          statusCd: filter.filter10?.map((data) => data.filterAttr) || [],
        }));
        setFilterList([...lists]);
      } catch (e) { }
    };
    setBeforeSearch(localStorage.getItem("beforeHFWorkflowSearch") || "");
    fetchFilterList();
    fetchStatusCd();
    dispatch(setHandyFlowWorkflowListFilterId(""));
  }, []);

  return (
    <Wrapper>
      <FormTable pointBorder={false} size="sm">
        <colgroup>
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="17.625%" />
        </colgroup>
        <tbody>
          <tr>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text>{formatMessage({ id: "T1316" })}</Text>
              {/* 업무 ID */}
            </th>
            <td>
              <Input

                placeholder={searchPlaceholder}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="workflowId"
                value={inputValues.workflowId}
                onChange={onChangeValues}
                onKeyPress={onKeyPress}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T1237" })}</Text>{/* 업무명 */}
            </th>
            <td>
              <Input
                placeholder={searchPlaceholder}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="workflowNm"
                value={inputValues.workflowNm}
                onChange={onChangeValues}
                onKeyPress={onKeyPress}
              />
            </td>
            <th span={2}>
              <Text>{formatMessage({ id: "T0007" })}</Text>{/* 개발자 */}
            </th>
            <td span={4}>
              <div>
                <InputSearch
                  onClick={onClickModalButton}
                  width={200}
                  height={32}
                  placeholder={mgrEmpNoPlaceholder}
                  data-label="devIds"
                  value={inputValues.devIds.map((c) => c.empNam).join(", ")}
                />
              </div>
            </td>
            <th rowSpan={2}>
              <Text>{formatMessage({ id: "T0004" })}</Text>{/* 등록날짜 */}
            </th>
            <td rowSpan={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px 0",
                  padding: "14px 0",
                }}
              >
                <RangePicker
                  popupStyle={{
                    ".ant-picker-cell-inner": {
                      background: "#000",
                    },
                  }}
                  style={{
                    width: 252,
                    alignItems: "center",
                  }}
                  size="middle"
                  format="YYYY-MM-DD"
                  value={[
                    inputValues.regFr && inputValues.regFr.length > 0
                      ? moment(inputValues.regFr)
                      : undefined,
                    inputValues.regTo && inputValues.regTo.length > 0
                      ? moment(inputValues.regTo)
                      : undefined,
                  ]}
                  name="dates"
                  defaultValue={[undefined, undefined]}
                  disabled={!(inputValues.regType === "custom")}
                  onCalendarChange={onChangeDates}
                  onChange={onChangeDates}
                />
                <Radio.Group
                  className="date_button__wrap"
                  onChange={onChangeDateRadio}
                  checked={isCheckedDate}
                  defaultValue="today"
                  value={inputValues.regType}
                >
                  <RadioButton
                    value="today"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0044" />
                    {/* 오늘 */}
                  </RadioButton>
                  <RadioButton
                    value="week"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0045" />
                    {/* 일주일 */}
                  </RadioButton>
                  <RadioButton
                    value="month"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0046" />
                    {/* 1개월 */}
                  </RadioButton>
                  <RadioButton
                    value="custom"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0047" />
                    {/* 직접입력 */}
                  </RadioButton>
                </Radio.Group>
              </div>
            </td>
          </tr>
          <tr>
            <th span={2}>
              <Text>{formatMessage({ id: "T1261" })}</Text>{/* 관련부서 */}
            </th>
            <td span={4}>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="workflowOrgCd"
                placeholder={searchPlaceholder}
                value={inputValues.workflowOrgCd.map((c) => c.codeNm).join(", ")}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T0340" })}</Text> {/* 업무분류 */}
            </th>
            <td>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="workId"
                placeholder={searchPlaceholder}
                value={inputValues.workId.map((c) => c.cdNm).join(", ")}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T1584" })}</Text>
              {/* 업무 상태 */}
            </th>
            <td colSpan={5}>
              <Select
                maxTagCount='responsive'
                mode="multiple"
                size="default"
                style={{ minWidth: 200 }}
                name="statusCd"
                value={inputValues.statusCd.map((status) => status.cd)}
                placeholder={selectPlaceholder}
                onChange={onChangeState}
              >
                {statusCdList.map((v, index) => (
                  <Option key={index} value={v.cd}>
                    {v.cdNm}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
          <tr>
            <th span={2}>
              <Text>{formatMessage({ id: "T0006" })}</Text>{/* 업무 담당자 */}
            </th>
            <td span={4}>
              <div>
                <InputSearch
                  onClick={onClickModalButton}
                  width={200}
                  height={32}
                  placeholder={mgrEmpNoPlaceholder}
                  data-label="mgrIds"
                  value={inputValues.mgrIds.map((c) => c.empNam).join(", ")}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Row style={{ padding: "0 13px" }}>
        <Col
          span={16}
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {beforeSearch.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {/* 최근 검색 */}
              <Text>{recentSearch}</Text>
              <div style={{ width: 200 }}>
                <div className="before__input">
                  <Input
                    style={{
                      width: 200,
                      height: 32,
                    }}
                    readOnly
                    autoFocus
                    value={beforeSearch}
                    onClick={onClickBeforeSearch}
                  />
                  <CloseCircleFilled
                    className="before__clear"
                    onClick={onClickBeforeSearchDelete}
                  />
                </div>
              </div>
            </div>
          )}
          {filterList.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              {/* 내 필터 */}
              <Text>{myFilter}</Text>
              <div>
                <Select
                  size="default"
                  defaultValue="none"
                  value={filterId}
                  style={{
                    width: 200,
                    fontSize: 14,
                  }}
                  onSelect={onSelectFilterList}
                >
                  <Option className="filter_option" value="">
                    {selectFilter}
                  </Option>
                  {filterList.map((filter) => {
                    return (
                      <Option
                        className="filter_option"
                        style={{ width: "100%" }}
                        key={filter.filterId}
                        value={filter.filterId}
                      >
                        <div
                          style={{
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <Text
                            style={{
                              display: "block",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingRight: 20,
                            }}
                            title={filter.filterNm}
                          >
                            {filter.filterNm}
                          </Text>
                          <CloseCircleFilled
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translateY(-50%)",
                            }}
                            onClick={onClickFilterDelete}
                            data-filterid={filter.filterId}
                          />
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0 10px",
            padding: "10px 0",
          }}
        >
          <Button width="100" height="32" onClick={onClickResetButton}>
            <FormattedMessage id="T0037" />
            {/* 초기화 */}
          </Button>
          <Button
            width="100"
            height="32"
            data-label="filter"
            onClick={(e) => onClickModalButton(e.target)}
          >
            <FormattedMessage id="T0038" />
            {/* 필터저장 */}
          </Button>
          <Button
            onClick={onClickSearchButton}
            width="100"
            height="32"
            type="primary"
          >
            <FormattedMessage id="T0039" />
            {/* 검색 */}
          </Button>
        </Col>
      </Row>
      <DepartmentList
        visible={modalVisible.workflowOrgCd}
        companyCd={profile.COMP_CD}
        onOk={onOkModal}
        onCancel={onCancelModal}
        mode="multiple"
        defaultData={inputValues[modalLabel]}
      />
      <Organization
        visible={modalVisible.mgrIds || modalVisible.devIds}
        companyCd={profile.COMP_CD}
        title={
          {
            mgrIds: formatMessage({ id: "T0465" }),  // 담당자 검색
            devIds: formatMessage({ id: "T1126" }),  // 개발자 검색
          }[modalLabel]
        }
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues[modalLabel]}
      />
      <BusinessClass
        title={formatMessage({ id: "T1119" })} // 업무 분류 검색
        visible={modalVisible.workId}
        onOk={onOkModal}
        onCancel={onCancelModal}
        mode="multiple"
        defaultData={inputValues[modalLabel]}
      />
      <Modal
        centered={true}
        title={saveFilter}
        visible={modalVisible.filter}
        onOk={onOkFilterModal}
        onCancel={onCancelModal}
        width="500px"
      >
        <div id="modalForm">
          <label for="filterNm">{filterNm}</label>
          <Input
            id="filterId"
            name="filterNm"
            type="text"
            value={saveFilterName}
            mexLength={50}
            onChange={onChangeSaveFilterName}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default HFWorkflowListTop;
