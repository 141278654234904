import fetchAbsolute from "utils/fetchAbsolute";
import { takeEvery, put, select } from "redux-saga/effects";
import {
  LOAD_TASK_DATA,
  loadTaskDataFail,
  loadTaskDataSuccess,
  TASK_INSTANT_START,
} from "./action";
import { setVisible } from "../loading/action";
import { loadTaskPermission } from "../permission/action";
import { getUniqueKey } from "utils/Str";

function* getTaskData({ taskId }) {
  yield put(setVisible(true));
  const result = yield fetchAbsolute("get", `/task/oracle/getTask/${taskId}`);
  if (result.error) {
    yield put(loadTaskDataFail());
  } else {
    yield put(loadTaskDataSuccess({ ...result.data }));
    yield put(
      loadTaskPermission({
        taskId: result.data.taskId,
        taskType: result.data.taskType,
      })
    );
  }
  yield put(setVisible(false));
}
function* instantStart(param) {
  const assignLookup = yield select((state) =>
    state.get(getUniqueKey("assignLookup"))
  );

  const {
    poolName,
    cr,
    inputValues,
    chooseDevice,
    successMsg,
    failedMsg,
    crType,
    directionMsg,
  } = param.data;

  if (!poolName) return alert(chooseDevice);

  let tmpUserInput = {};
  if (poolName.length === 0) {
    return alert(chooseDevice);
  }
  if (inputValues?.length > 0 && !!inputValues[0].key) {
    tmpUserInput = inputValues.reduce(
      (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
      {}
    );
  }

  const parameter = {
    taskId: assignLookup.taskData.taskId,
    cr,
    poolName,
    scheduleType: "INSTANT",
    timeZone: "Asia/Seoul",
    userInput: {
      ...tmpUserInput,
    },
    crType,
  };

  const result = yield fetchAbsolute(
    "post",
    `/task/oracle/addTaskSchedule?crType=${crType}`,
    {
      data: { ...parameter },
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  if (result.data.result === "fail") {
    const confirmResult = window.confirm(
      `${result.data?.message} \n${directionMsg}`
    ); // 대기 시간 이후에 자동으로 실행할까요?
    if (confirmResult) {
      const waiting = async () => {
        const result = await fetchAbsolute(
          "post",
          "/task/oracle/waitInstantAction",
          {
            data: { ...parameter },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (result.error) return;
      };
      waiting();
    }
  } else if (!result.error) {
    alert(successMsg);
    param.cb();
  } else {
    alert(failedMsg);
  }
}

function* rootSaga() {
  yield takeEvery(LOAD_TASK_DATA, getTaskData);
  yield takeEvery(TASK_INSTANT_START, instantStart);
}

export default rootSaga;
