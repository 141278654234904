import React, { useState } from 'react'
import { Text, Button, Table } from "components";
import { useIntl, FormattedMessage } from "react-intl";
import moment from 'moment';
import Wrapper from "../Styled";
import { Select } from "antd";
import useFormatMessage from "hooks/useFormatMessage";

/** 
 * Admin > Agent > Runner Agent SSL JKS 인증서 관리 
 * SSL 소켓 서버및 클라이언트 작동시 유효기간에 맞는 인증서 다운로드 받아서 작동하기 위해서 인증서 관리 화면
 *
 */

const initialPageInfo = {
  total: 0,
  currentPage: 1,
  pageSize: 10,
};

const { Option } = Select;

const AgentCertiList = ({ agentSslList, onClickAddSsl, onClickEditSsl }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const pageSize = [10, 20, 50];

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      currentPage: 1,
      pageSize: +value,
    };
    setPageInfo({ ...searchObj });
  }

  const onChangeTableColumns = (pagination, filters, sorter) => {
    setPageInfo({ ...pageInfo, currentPage: +pagination.current });
  };

  const columns = [
    {
      key: 'fileName',
      dataIndex: "fileName",
      title: <FormattedMessage id="T1296" />, // File Name 
      render: (text, record, index) => {
        return (
          <div key={index}>
            <Text
              type="download"
              download={record.down}
              fileName={text}
            >
              {text}
            </Text>
          </div>
        )
      }
    },
    {
      key: 'startDate',
      dataIndex: "startDate",
      title: <FormattedMessage id="T1297" />, // 유효기간  
      render: (text, record, index) => {
        return (
          <div
            key={index}
            className='version__type'
            onClick={() => onClickEditSsl(record)}
          >
            {text} ~ {record.endDate}
          </div>
        );
      }
    },
    {
      key: 'empNam',
      dataIndex: "empNam",
      title: <FormattedMessage id="T0456" />, // 담당자 
    },
    {
      key: 'updateDt',
      dataIndex: "updateDt",
      title: <FormattedMessage id="T1082" />, // Update Date 
      render: (text, record, index) => {
        const formattedDate = moment(text).format('YYYY-MM-DD')
        return (
          <div key={index}>
            {formattedDate}
          </div>
        )
      }
    },
  ]
  return (
    <Wrapper>
      <div className="common__container">
        <div className="ver_manage__top">
          <div className="left">
            <span>{/* 페이지당 행 */}
              {formatMessage({ id: "T0036" })} &emsp;
              <Select
                name="pagination"
                defaultValue={pageSize[0]}
                value={+pageInfo.pageSize}
                onSelect={onSelectPage}
              >
                {pageSize.map((v, index) => ( // {number} 개
                  <Option key={index} value={v}>
                    {fmMessage({ id: "T1228", values: { number: v } })}
                  </Option>
                ))}
              </Select>
            </span>
          </div>
          <div>
            <Button
              type='primary'
              onClick={onClickAddSsl}
              style={{ width: 150, height: 32 }}
            >
              <FormattedMessage id="T0049" />{/* 추가 */}
            </Button>
          </div>
        </div>
        <div style={{ paddingTop: 10.8 }}>
          <Table
            columns={columns}
            dataSource={agentSslList}
            rowKey={(item) => item.sslInfoSeq}
            defaultPageSize={+pageInfo.pageSize}
            pagination={true}
            onChange={onChangeTableColumns}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.pageSize,
            }}
          />
        </div>
      </div>
    </Wrapper>
  )
}

export default AgentCertiList;