import React from "react";
import { PageTemplate, Header, Footer } from "components";
import Wrapper from "./Styled";
import Intro from "./Intro.js";

const JediAcademy = () => {
  return (
    <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false} footerColor="#000">
      <div style={{  background: "black" }}>
        <Wrapper>
        <Intro />
        </Wrapper>
      </div>
    </PageTemplate>
  );
};

export default JediAcademy;
