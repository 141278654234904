import React, { useState, useEffect } from "react";
import { Table, Text, Modal, Button } from "components";
import { Select, Input } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;

/**
 * Filename: AssignMentPauseGroup/index.js
 * Description: 과제 중단 사유
 */

const AssignMentPause = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const selectRowPerPage = formatMessage({ id: "T0036" }); // 페이지당 행

  const pageSize = [20, 50, 70];

  const [PoolVisible, setPoolVisible] = useState(false);
  const [checkedDatas, setCheckedDatas] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [originDataSource, setOriginDataSource] = useState([]);
  const [sortValue, setSortValue] = useState(0);
  const [originSortValue, setOriginSortValue] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(20);
  const [poolPage, setPoolPage] = useState(0);
  const [language, setLanguage] = useState("");

  const onSelectOption = (value) => {
    setRowPerPage(+value);
  };

  const onChangeInput = (cd, label, value) => {
    const tmpArr = [...dataSource];
    const index = tmpArr.findIndex((v) => v.cd === cd);
    if (index === -1) return;
    if (value === " ") {
      alert(formatMessage({ id: "T0230" })); // 해당 항목에는 첫 글자 공백을 사용할 수 없습니다.
      return;
    }
    tmpArr[index][label] = value;
    setDataSource([...tmpArr]);
  };

  const onCancelDelete = () => setPoolVisible(false);

  const onClickAdd = () => {
    const tmpArr = [...dataSource];
    tmpArr.push({
      categoryId: "STOP_REASON",
      cd: "S" + (sortValue + 1).toString(),
      cdNm: "",
      sort: sortValue + 1,
      useYn: "N",
      locale: language,
    });
    setSortValue(sortValue + 1);
    setDataSource([...tmpArr]);
  };

  const onClickReset = () => {
    const fetchTaskStopReason = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/${"STOP_REASON"}?sortVal=asc`
      );
      setDataSource([...result.data]);
      setSortValue(originSortValue);
    };
    fetchTaskStopReason();
  };

  const onClickSave = async (row) => {
    const tmpArr = [...dataSource];

    let emptyFlag = true;

    tmpArr.forEach((e) => {
      if (
        e.cdNm === null ||
        e.cdNm === "" ||
        e.sort === null ||
        e.sort === ""
      ) {
        emptyFlag = false;
      }
    });

    if (!emptyFlag) {
      alert(formatMessage({ id: "T0239" })); // 공백은 입력할 수 없습니다.
      return;
    }

    let fetchFlag = true;
    try {
      const saveTaskStopReason = await fetchAbsolute(
        "post",
        `/common/commonCd/addList`,
        {
          data: tmpArr,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        saveTaskStopReason.status === 200 ||
        saveTaskStopReason.status === 201
      ) {
        alert(formatMessage({ id: "T0240" })); // 등록었습니다.
      } else {
        throw new Error(saveTaskStopReason.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0241" })); // 과제 중단 사유 등록에 실패하였습니다.
      }
    }
    //insert 후 다시 API 조회하기
    const fetchPoolList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/${"STOP_REASON"}?sortVal=asc`
      );
      setDataSource([...result.data]);
      setOriginDataSource([...result.data]);

      let count = result.data[result.data.length - 1].cd.split("S");

      setSortValue(Number(count[1]));
      setOriginSortValue(Number(count[1]));
    };
    fetchPoolList();
    setPoolVisible(false);
  };

  const onOkDelete = async () => {
    //delete 할 데이터 array 반복문 돌려서 삭제하기
    const delData = checkedDatas.map((ele) =>
      originDataSource.find((e) => e.cd === ele)
    );

    let fetchFlag = true;
    try {
      const deleteTaskStopReason = await fetchAbsolute(
        "delete",
        `/common/commonCd/deleteList`,
        {
          data: delData,
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (
        deleteTaskStopReason.status === 200 ||
        deleteTaskStopReason.status === 201
      ) {
        alert(formatMessage({ id: "T0242" })); // 과제 중단 사유가 삭제되었습니다.
      } else {
        throw new Error(deleteTaskStopReason.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0243" })); // `과제 중단 사유 삭제에 실패하였습니다.`
      }
    }
    //삭제 후 다시 API 조회하기
    const fetchPoolList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/${"STOP_REASON"}?sortVal=asc`
      );
      setDataSource([...result.data]);
      setOriginDataSource([...result.data]);

      let count = result.data[result.data.length - 1].cd.split("S");

      setSortValue(Number(count[1]));
      setOriginSortValue(Number(count[1]));
    };
    fetchPoolList();
    setPoolVisible(false);
  };

  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCheckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCheckedDatas(selectedRowKeys);
    },
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute(
      "get",
      "/common/commonCd/getCategoryId/LOCALE"
    );
    const data = result.data.filter((v) => v.useYn === "Y");
    setLanguage(data[0].cd);
  };

  const onChangePage = (currentPage) => {
    setPoolPage(currentPage);
  };

  const onClickDeleteButton = (e) => {
    if (checkedDatas.length === 0) {
      return alert(formatMessage({ id: "T0244" })); // "삭제할 데이터를 선택해주세요. "
    }
    if (originDataSource.length < dataSource.length) {
      const val = formatMessage({ id: "T0245" })
        .split("\n")
        .map((data) => {
          return data;
        });
      return alert(val.join("\n"));
      //"데이터 저장을 먼저 진행 해주시기 바랍니다. \n 삭제할 수 없습니다");
    }

    setPoolVisible(true);
  };

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: "T0735" }), // 코드
      dataIndex: "cd",
      sorter: (a, b) => a.cd.localeCompare(b.cd),
      width: "15%",
      align: "center",
    },
    {
      key: 2,
      title: formatMessage({ id: "T0100" }), // 설명
      dataIndex: "cdNm",
      align: "center",
      sorter: (a, b) => a.cdNm.localeCompare(b.cdNm),
      width: "40%",
      render: (value, row, index) => {
        return (
          <Input
            value={value}
            required
            onChange={(e) => onChangeInput(row.cd, "cdNm", e.target.value)}
          />
        );
      },
    },
    {
      key: 3,
      title: formatMessage({ id: "T0736" }), // 정렬순서
      dataIndex: "sort",
      sorter: (a, b) => a.sort - b.sort,
      align: "center",
      width: "15%",
      render: (value, row, index) => {
        return (
          <Input
            type="number"
            value={value}
            required
            onChange={(e) => onChangeInput(row.cd, "sort", e.target.value)}
          />
        );
      },
    },
    {
      key: 4,
      title: formatMessage({ id: "T1632" }), // 구분자
      dataIndex: "attrCd1",
      align: "center",
      width: "15%",
      render: (value, row, index) => {
        return (
          <Input
            value={value}
            onChange={(e) => onChangeInput(row.cd, "attrCd1", e.target.value)}
          />
        );
      },
    },
    {
      key: 5,
      title: formatMessage({ id: "T0737" }), // 사용여부
      dataIndex: "useYn",
      align: "center",
      sorter: (a, b) => a.useYn.localeCompare(b.useYn),
      width: "15%",
      render: (select, row) =>
        dataSource.length >= 1 ? (
          <Select
            defaultValue="N"
            value={select}
            style={{ width: "80%" }}
            onChange={(e) => onChangeInput(row.cd, "useYn", e)}
          >
            <Option value="Y">Y</Option>
            <Option value="N">N</Option>
          </Select>
        ) : null,
    },
  ];

  useEffect(() => {
    const fetchTaskStopReason = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/${"STOP_REASON"}?sortVal=asc`
      );

      setDataSource([...result.data]);
      setOriginDataSource([...result.data]);

      let count = result.data[result.data.length - 1].cd.split("S");

      setSortValue(Number(count[1]));
      setOriginSortValue(Number(count[1]));
    };
    fetchTaskStopReason();
    fetchLanguageList();
  }, []);

  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <div className="realtime__table_header">
          <Text>{selectRowPerPage}</Text>
          <Select
            style={{
              width: 89,
              height: 32,
            }}
            className="realtime__table_page"
            defaultValue={pageSize[0]}
            onChange={onSelectOption}
          >
            {pageSize.map((v, index) => (
              <Option key={index} value={v}>
                {fmMessage({ id: "T1228", values: { number: v } })}
              </Option>
            ))}
          </Select>
        </div>
        <Table
          className="realtime__table_content"
          columns={columns}
          dataSource={dataSource}
          pagination={true}
          defaultPageSize={+rowPerPage}
          pageSizeVisible={false}
          showSorterTooltip={true}
          rowSelection={rowSelection}
          rowKey={(e) => e.cd}
        // pageOptions={{
        //   total: dataSource.length,
        //   current: poolPage,
        //   pageSize: rowPerPage,
        //   onChange: onChangePage,
        // }}
        />

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 20,
            }}
          >
            <Button width="144" height="40" onClick={onClickReset}>
              {formatMessage({ id: "T0037" })}{/* 초기화 */}
            </Button>
            &nbsp; &nbsp;
            <Button width="144" height="40" type="primary" onClick={onClickAdd}>
              {formatMessage({ id: "T0049" })}{/* 추가 */}
            </Button>
            &nbsp; &nbsp;
            <Button
              width="144"
              height="40"
              type="primary"
              onClick={onClickSave}
            >
              {formatMessage({ id: "T0073" })}{/* 저장 */}
            </Button>
            &nbsp; &nbsp;
            <Button width="144" height="40" onClick={onClickDeleteButton}>
              {formatMessage({ id: "T0072" })}{/* 삭제 */}
            </Button>
          </div>
        </div>
        <Modal
          width={540}
          visible={PoolVisible}
          title={formatMessage({ id: "T0072" })} // 삭제
          okText={formatMessage({ id: "T0072" })} // 삭제
          onCancel={onCancelDelete}
          onOk={onOkDelete}
        >
          <Text
            style={{
              display: "block",
              fontSize: 14,
            }}
          >
            {formatMessage({ id: "T0733" })}{/* 정말로 선택된 분류를 삭제 하시겠습니까? */}
          </Text>
        </Modal>
      </div>
    </div>
  );
};

export default AssignMentPause;
