import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  width: 1600px;
  textarea.ant-input {
    max-width: fit-content !important;
  }
  .top__section {
    display: flex;
    gap: 10px;
    align-items: center;
    padding-bottom: 10px;
  }
  .top__section .ant-select-selection-selected-value {
    /* width: fit-content; */
    padding: 0 10px;
  }
  .submit_button__section {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    gap: 20px;
  }
  .submit_button {
    width: 140px;
    height: 40px;
  } 
  .ant-table-tbody>tr>td, .ant-table-thead>tr>th { 
    word-break: break-all !important;
  }
  /* 테이블 페이지 부분 */
  li.ant-pagination-options {
    display: none;
  }
`;

export default Wrapper;
