import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getUniqueKey } from 'utils/Str';

/**
 * Redux + Redux-saga 실습 예제 Counter Component
 * 
 * @class
 */
class Counter extends Component {
    render() {
        return (
            <div>
                <h1>Value : {this.props.value}</h1>
            </div>
        );
    }
}
 
let mapStateToProps = (state) => {
    return {
        value: state.get(getUniqueKey('counter')).get('value')
    };
};
 
export default connect(mapStateToProps)(Counter);
