import React, { useEffect, useState, useRef } from "react";
import Wrapper from "./Styled";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import { PageTemplate, Header, Footer, Modal, Text, Organization } from "components";
import useFormatMessage from "hooks/useFormatMessage";
import IternalSystemManage from "./Manage/IternalSystemManage";

/**
 * 관리자 > 내부 시스템 메뉴 관리 > 내부 시스템 메뉴 등록 / 수정 페이지
 *
 */

const initialInputValues = {
  systemCd: 0,
  category: [],
  systemName: "",
  systemTask: [],
  summary: "",
  systemUser: [],
};

const initialMenuInsertInfo = {
  level1: "",
  level2: "",
  level3: "",
  level4: "",
  level5: "",
};

const AdminIternalSystemMenu = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [classificationList, setClassificationList] = useState([]);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [addSystemView, setAddSystemView] = useState(false);
  const [orgVisible, setOrgVisible] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [selectDatas, setSelectDatas] = useState([]);
  const [menuInsertInfo, setMenuInsertInfo] = useState({
    ...initialMenuInsertInfo,
  });
  const [rowData, setRowData] = useState({});

  const requireFields = {
    category: {
      ref: useRef(null),
      message: formatMessage({ id: "T0362" }), // 분류
    },
    systemName: {
      ref: useRef(null),
      message: formatMessage({ id: "T0363" }), // 시스템 명
    },
    systemUser: {
      ref: useRef(null),
      message: formatMessage({ id: "T0365" }), // 시스템 담당자
    },
  };

  /* 취소 버튼 기능 */
  const onClickCancelButton = () => {
    setInputValues({ ...initialInputValues });
    setMenuInsertInfo({ ...initialMenuInsertInfo });
    setRowData({});
    setSelectDatas([]);
  };

  /* 시스템 삭제 */
  const onOkDeleteSystem = async () => {
    setDeleteVisible(false);
    const result = await fetchAbsolute(
      "delete",
      `/admin/system/systemDelete?systemCd=${inputValues.systemCd}`
    );
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    alert(formatMessage({ id: "T0284" })); // 삭제 되었습니다.
    setInputValues({ ...initialInputValues });
    setSelectDatas([]); // 삭제하면 다 날아가게 
  };

  const onClickCancelSystem = () => {
    setDeleteVisible(false);
  };

  const onClickDeleteSystem = () => {
    setDeleteVisible(true);
  };

  /* 조직도 */
  const onOkOrg = (data) => {
    if (data.length > 0) {
      setInputValues({ ...inputValues, systemUser: [...data] });
    } else {
      setInputValues({ ...inputValues, systemUser: [] });
    }
    setOrgVisible(false);
  };

  const onClickOrg = () => {
    setOrgVisible(true);
  };

  const onCancelOrg = () => {
    setOrgVisible(false);
  };

  /* inputValues, searchValues */
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  /* 분류 Select */
  const onChangeClassification = (e) => {
    let tmpArr = [];
    for (let i in e) {
      const value = classificationList.find((cate) => cate.cd === e[i]);
      tmpArr.push(value);
    }
    setInputValues({
      ...inputValues,
      category: [...tmpArr],
    });
  };
  /* 내부 시스템 메뉴 추가 및 수정 */
  const onClickSubmit = async () => {
    /* 빈칸 검사 */
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!inputValues[key] || Object.keys(inputValues[key]).length <= 0) {
        field.ref.current.focus();
        //`${field.message} 항목은 필수 입력입니다.`);
        return alert(
          fmMessage({ id: "T0128", values: { field: field.message } })
        );
      }
    }

    let parameter = {
      category: inputValues.category.cd,
      systemCd: inputValues.systemCd,
      systemName: inputValues.systemName,
      //systemTask: inputValues?.systemTask.cd,
      summary: inputValues.summary,
      systemUser: inputValues.systemUser.empNum,
      menuList: [...selectDatas],
    };

    /* 첫 등록일 때는 systemCd가 들어가면 안된다. */
    if (inputValues.systemCd === 0) {
      delete parameter.systemCd;
    }

    const result = await fetchAbsolute("post", `/admin/system/systemRegister`, {
      data: { ...parameter },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    alert(`${formatMessage({ id: "T0224" })}`); // 추가되었습니다. 
    setInputValues({ ...initialInputValues });
    setMenuInsertInfo({ ...initialMenuInsertInfo });
    setSelectDatas([]);
  };

  useEffect(() => {
    /* System Manage 기본 정보 셋팅 */
    const getClassification = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/USESYS_TYPE`
      );
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setClassificationList([...result.data]);
    };
    getClassification();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper>
        <IternalSystemManage
          addSystemView={addSystemView}
          onClickOrg={onClickOrg}
          onClickCancelButton={onClickCancelButton}
          onClickDeleteSystem={onClickDeleteSystem}
          onClickSubmit={onClickSubmit}
          inputValues={inputValues}
          onChangeValues={onChangeValues}
          menuInsertInfo={menuInsertInfo}
          setMenuInsertInfo={setMenuInsertInfo}
          classificationList={classificationList}
          onChangeClassification={onChangeClassification}
          requireFields={requireFields}
          rowData={rowData}
          selectDatas={selectDatas}
          setSelectDatas={setSelectDatas}
        />
      </Wrapper>
      <Organization
        visible={orgVisible}
        title={formatMessage({ id: "T0465" })} // 담당자 검색
        onOk={onOkOrg}
        onCancel={onCancelOrg}
        defaultData={inputValues.systemUser}
      />
      <Modal
        centered
        visible={deleteVisible}
        width={500}
        okText={formatMessage({ id: "T0064" })} // 확인
        title={formatMessage({ id: "T0072" })} // 삭제
        onOk={onOkDeleteSystem}
        onCancel={onClickCancelSystem}
      >
        <div style={{ textAlign: "center" }}>
          <Text>{formatMessage({ id: "T1234" })}</Text>
          {/* 정말로 삭제 하시겠습니까? */}
        </div>
      </Modal>
    </PageTemplate>
  );
};

export default AdminIternalSystemMenu;
