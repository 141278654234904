import React, { useState } from "react";
import { Tabs } from "components";
import Container from "containers/Container";
import Padawan from './Padawan/index.js'
import Station from "./Station/index.js"
import Wrapper from "./Styled";
import { PageTemplate, Header, Footer } from "components";
import { useIntl } from 'react-intl';

const { TabPane } = Tabs;

const PadawanAcademy = () => {
  const { formatMessage } = useIntl();

  const [activeKey, setActiveKey] = useState('introPadaWan');

  const onChnageTab = (tabKey) => {
    setActiveKey(tabKey);
  }

  return (
    <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false} bodyColor='#000' footerColor="#000">
      <Wrapper>
        <Container>
          <Tabs activeKey={activeKey} className="stationTab" type="card" custom={false} header onChange={onChnageTab}>
            <TabPane tab={formatMessage({ id: 'T0906' })} key="introPadaWan">{/* 소개 */}
            </TabPane>
            <TabPane tab={formatMessage({ id: 'T0933' })} key="padawanStation" >
            </TabPane>
          </Tabs>
        </Container>
      </Wrapper>
      {
        activeKey === 'introPadaWan' &&
        <Padawan />
      }
      {
        activeKey === 'padawanStation' &&
        <Station />
      }
    </PageTemplate>
  );
};

export default PadawanAcademy;
