import React from 'react';
import { PageTemplate, Header, Footer, Tabs } from 'components';
import SatisfactionManage from './SatisfactionManage.js';
import SatisfactionHistory from './SatisfactionHistory.js';
import Wrapper from './Styled';
import { useIntl } from "react-intl";

const { TabPane } = Tabs;

/**
 * 관리자 > 만족도 조사 관리 페이지
 * 선택 여부에 따라 메인에 노출
 * 
 */
const AdminSatisfaction = () => {
    const { formatMessage } = useIntl();

    return (
        <PageTemplate header={<Header />} footer={<Footer />} bodyColor="none" headerColor='none'>
            <Wrapper style={{ padding: "30px 0" }}>
                <Tabs type='card' custom={false} defaultActiveKey='1'>
                    <TabPane tab={formatMessage({ id: 'T0843' })} key='1'>{/* 만족도 조사 관리 */}
                        <SatisfactionManage />
                    </TabPane>
                    <TabPane tab={formatMessage({ id: 'T0844' })} key='2'>{/* 만족도 조사 이력 */}
                        <SatisfactionHistory />
                    </TabPane>
                </Tabs>
            </Wrapper>
        </PageTemplate >
    );
}

export default AdminSatisfaction;