import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .satisfaction_edit__button_wrap {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding-top: 20px;
    }
`;

export default Wrapper;