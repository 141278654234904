import {
    SET_COMMUNITY_VALUE, 
    SET_COMMUNITY_SPREAD, 
    RESET_COMMUNITY_VALUE, 
    SET_COMMUNITY_CONDITION, 
    RESET_COMMUNITY_CONDITION
} from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const assignListInitialState = {
    inputValues: {
        title: '',
        regFr: '',
        regTo: '',
        writer: [],
    },
    boardMaCondition : {}
};

const community = (state = assignListInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_COMMUNITY_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_COMMUNITY_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues
                }
            }
        }
        case RESET_COMMUNITY_VALUE: {
            const inputValues = {
                ...assignListInitialState.inputValues
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues
                }
            }
        }
        case SET_COMMUNITY_CONDITION: {
            const boardMaCondition = {
                ...action.data
            };
            return {
                ...state,
                boardMaCondition: {
                    ...boardMaCondition
                }
            }
        }
        case RESET_COMMUNITY_CONDITION: {
            return {
                ...state,
                boardMaCondition: {}
            }
        }
        default:
            return state;
    }
}

export default community;