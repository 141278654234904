import React from 'react';
import { PageTemplate, Header, Footer, AdminBoardAdd } from 'components';
import { useIntl } from "react-intl";

/**
 * Filename: AdminBoardCreate/index.js
 * Description: Admin -> 게시판 추가 컴포넌트
 */

const AdminBoardCreate = ({ profile, type = 'create' }) => {
    const { formatMessage } = useIntl();

    return (
        <PageTemplate
            header={<Header />}
            footer={<Footer />}
            headerColor='none'
            menuTitle={
                type === 'create' ? `${formatMessage({ id: 'T1168' })}` // 게시판 등록
                    : type === 'modify' && `${formatMessage({ id: 'T1169' })}` // 게시판 수정
            }
            depthList={
                [`${formatMessage({ id: 'T0881' })}`, // 관리자
                type === 'create' ? `${formatMessage({ id: 'T1168' })}` // 게시판 등록
                    : type === 'modify' && `${formatMessage({ id: 'T1169' })}`] // 게시판 수정
            }
        >
            <AdminBoardAdd type={type} profile={profile} />
        </PageTemplate >
    );
}

export default AdminBoardCreate;