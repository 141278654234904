import React, { useState } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Collapse,
  AdminDeviceAddBox,
  Text,
} from "components";
import Wrapper from "./Styled";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl, FormattedMessage } from 'react-intl';

const DevicePoolAdd = ({ history }) => {
  const { formatMessage } = useIntl();

  const [openCollapse, setOpenCollapse] = useState(false);
  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      headerColor='none'
      menuTitle={`${formatMessage({ id: 'T1065' })}`} // Device Pool 등록
      depthList={[`${formatMessage({ id: 'T0881' })}`, // 관리자
      `${formatMessage({ id: 'T1065' })}`] // Device Pool 등록
      }>
      <Wrapper
        style={{
          padding: "30px 50px",
        }}
      >
        <Text style={{ fontSize: 20, fontWeight: "bold" }}><FormattedMessage id="T1066" /></Text>{/* Device Pool 추가 */}
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          defaultActiveKey={["1"]}
        >
          <AdminDeviceAddBox history={history} style={{ paddingTop: 20 }} />

        </Collapse>

      </Wrapper>
    </PageTemplate>
  );
};

export default DevicePoolAdd;
