import React from "react";
import { Button, Text, Image } from "components";
import Wrapper from "./Styled";

import ReturnToTop from "../AcademyImg/Return to top.png";
import Image1 from "../AcademyImg/Youngling.png";
import Image2_1 from "../AcademyImg/youngling_image2_1.png";
import Image2_2 from "../AcademyImg/youngling_image2_2.png";
import Image4 from "../AcademyImg/youngling_image4.png";

import Banner1 from "../AcademyImg/banner1.png";
import Banner2 from "../AcademyImg/banner2.png";
import Banner3 from "../AcademyImg/banner3.png";
import Banner4 from "../AcademyImg/banner4.png";
import BannerArrow from "../AcademyImg/banner_arrow.png";
import { FormattedMessage, useIntl } from "react-intl";
const Information = () => {
  const { formatMessage } = useIntl();

  const onClickReturntoTop = () => {
    document.documentElement.scrollTop = 0;
  };

  const onClickButton = () => {
    window.open("http://skhu.skhynix.com/");
  };

  return (
    <>
      <Wrapper style={{ width: 1800 }}>
        <div className="section section1">
          <div className="section_box">
            <Image alt="section1 img" src={Image1} />
            <div className="section_text__box">
              <p className="youngling__title">{formatMessage({ id: 'T0908' })}</p>{/* Youngling (영링) */}
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">{formatMessage({ id: 'T0909' })}</p>{/* 이야기 */}
                <p className="section_text__content">
                  {formatMessage({ id: 'T0910' })}{/* 매번 반복되고 단순한 작업으로 시간을 잃어가던 구성원들은
더이상 참을 수 없었다. 자신의 업무 혁신을 위하여 구성원들은

Dreago Academy에 지원하여 초급교육을 이수하게 된다.
Youngling으로 거듭난 그들은 본인 업무의 자동화 가능성을 깨우쳐
낭비되는 업무시간을 줄이고자 본격적으로 RPA 실력을 갈고 닦기
시작한다. */}
                </p>
              </p>
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">{formatMessage({ id: 'T0906' })}</p>{/* 소개 */}
                <p className="section_text__content">
                  {formatMessage({ id: 'T0911' })}{/**Youngling은 RPA 초급 교육을 이수한 Dreago의 신규 구성원 입니다.
RPA 초급 교육을 희망하는 구성원은 누구나 SKHU를 통해 Smart
Cookie를 신청하여 교육을 받을 수 있으며, 초급 교육 이수 및
과제 제출 완료시 Youngling (Lv.2) 등급이 부여됩니다.
Youngling은 Automation Anywhere에 대한 기본적인 정보와 간단한
Command를 활용하여 자동화 업무를 구현 할 수 있습니다. */}
                </p>
              </p>
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">
                  {formatMessage({ id: 'T0912' })}{/* Youngling이 되기 위해서는 다음과 같은 조건을 충족해야 합니다. */}
                </p>
                <p className="section_text__content section_text__list">
                  <ul>
                    <li>{formatMessage({ id: 'T0913' })}</li>{/* RPA 초급교육 이수 ( SKHU Smart Cookie 22강 수강) */}
                    <li>{formatMessage({ id: 'T0914' })}</li>{/* 온라인 교육 이수 후 과제 제출 */}
                    <li>{formatMessage({ id: 'T0915' })}</li>{/* RPA에 대한 열정 보유 */}
                  </ul>
                </p>
              </p>
              <Button
                className="section__button"
                type="primary"
                onClick={onClickButton}
              >
                <FormattedMessage id="T0112" />{/* Youngling이 되고 싶습니다! */}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <div className="section section2">
          <p className="youngling__title">
            {formatMessage({ id: 'T0916' })}{/* Youngling은 다음과 같은 역량을 갖추게 됩니다 */}
          </p>
          <div className="section_content__wrap">
            <div className="section_content__box">
              <Image alt="Padawan section2 img" src={Image2_1} />
              <div className="section_text__box">
                <p className="section_text__title">
                  {formatMessage({ id: 'T0917' })}{/* Dreago 및 Automation Anywhere 이해 */}
                </p>
                <p className="section_text__content">
                  {formatMessage({ id: "T0918" }).split("\n").map((data) => {
                    return (
                      <span>
                        {data}
                        <br />
                      </span>
                    );
                  })}{/**Dreago 플랫폼의 사용법과 Automation Anywhere의 기본적인 요소를
습득하고 Dreago Academy의 일원으로 활동할 수 있는 자격을 갖추게 됩니다 */}
                </p>
              </div>
            </div>
            <div className="section_content__box">
              <Image alt="Padawan section2 img" src={Image2_2} />
              <div className="section_text__box">
                <p className="section_text__title">
                  {formatMessage({ id: 'T0919' })}{/* 간단한 커맨드를 사용한 단순작업 자동화 */}
                </p>
                <p className="section_text__content">
                  {formatMessage({ id: "T0920" }).split("\n").map((data) => {
                    return (
                      <span>
                        {data}
                        <br />
                      </span>
                    );
                  })}{/* 기본적인 커맨드를 활용하여 반복적인 업무를 자동화 할 수 있는
기본 작업 과제를 개발할 수 있습니다 */}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="section section3">
          <p className="youngling__title">
            {formatMessage({ id: 'T0921' })}{/* Youngling이 되기 위해서는 다음과 같은 교육과정을 수료해야 합니다. */}
          </p>
          <div className="section_banner__wrap">
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner1}
              />
              <div className="section_banner__content">
                {formatMessage({ id: "T0922" }).split("\n").map((data) => {
                  return (
                    <span>
                      {data}
                      <br />
                    </span>
                  );
                })}{/* RPA 기본 개념 및 적용 가능 업무 설계 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner2}
              />
              <div className="section_banner__content">
                {formatMessage({ id: "T0923" }).split("\n").map((data) => {
                  return (
                    <span>
                      {data}
                      <br />
                    </span>
                  );
                })}{/* Dreago 플랫폼 기본 활용 방법 숙지 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner3}
              />
              <div className="section_banner__content">
                {formatMessage({ id: "T0924" }).split("\n").map((data) => {
                  return (
                    <span>
                      {data}
                      <br />
                    </span>
                  );
                })}{/* 주요 기본 Command 학습 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner4}
              />
              <div className="section_banner__content">
                {formatMessage({ id: "T0925" }).split("\n").map((data) => {
                  return (
                    <span>
                      {data}
                      <br />
                    </span>
                  );
                })}{/* 간단한 시나리오  과제 수행 */}
              </div>
            </div>
          </div>
        </div>
        <div className="section section4">
          <Image alt='Green Light saber' src={Image4} />
          <p className="section_text__content">
            {formatMessage({ id: "T0926" }).split("\n").map((data) => {
              return (
                <span>
                  {data}
                  <br />
                </span>
              );
            })}{/* 업무의 파도가 밀려오고 있는 지금 이 순간에도,

이 광선검은 주인을 기다리고 있습니다.

준비되셨습니까?
 */}
          </p>
          <Button
            className="section__button"
            type="primary"
            onClick={onClickButton}
          >
            <FormattedMessage id="T0112" />{/* Youngling이 되고 싶습니다! */}
          </Button>
        </div>
        <div className="section section5">
          <a onClick={onClickReturntoTop} href="#!">
            <Image alt='go to top' src={ReturnToTop} />
            <Text className="academy_return_to_top">{formatMessage({ id: 'T0905' })}</Text>{/* 위로가기 */}
          </a>
        </div>
      </Wrapper>
    </>
  );
};

export default Information;
