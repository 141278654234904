import React, { useEffect, useRef, useState } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  AssignEnterBox,
  Text,
  Button,
  UserTag,
  FormTable,
  HFBpdIframe,
  Modal,
  Loading,
  Organization,
  HFBpdIframeModal
} from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import Container from "containers/Container";
import { CopyIcon, EyeIcon } from "icons";
import { useIntl, FormattedMessage } from "react-intl";
import { Input as AntInput } from 'antd';
import { setVisible } from "store/loading/action";
import {
  PaperClipOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons";
import Wrapper from "./Styled";
import { Tooltip } from "antd";
import { getUniqueKey } from "utils/Str";
import { useDispatch, useSelector } from "react-redux";
import NameTagGroup from "components/atoms/NameTagGroup";

const { TextArea } = AntInput;

const HFBpdLookup = ({ profile, history, match: { params } }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const listSearch = useSelector(state => state.get(getUniqueKey('listUrl')).hfBpdList);

  // 담당자, 개발자 프로필
  const [mainProfile, setMainProfile] = useState({});
  const [subProfiles, setSubProfiles] = useState([]);

  const [bpdData, setBpdData] = useState({});
  const [bpdType, setBpdType] = useState("");
  const [loading, setLoading] = useState(false);
  const [visibleOrgan, setVisibleOrgan] = useState(false);
  const [fixedMain, setFixedMain] = useState(false);
  const [transformVisible, setTransformVisible] = useState(false);
  const [transformUser, setTransformUser] = useState([]);
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [visibleCopyNm, setVisibleCopyNm] = useState(false);
  const [visibleCopyId, setVisibleCopyId] = useState(false);
  const [transformReason, setTransformReason] = useState('')

  // 복사 버튼 클릭 시 1초 뒤에 사라지게 하는 timeOut 변수
  const copyNmTimeout = useRef(null);
  const copyIdTimeout = useRef(null);

  // 관심 과제 추가 및 삭제
  const onClickHeartButton = async (record) => {
    const { bpdId, interestGbn } = record;

    const taskYn = interestGbn === "Y" ? "N" : "Y";
    // /${taskYn === "Y" ? "add" : "delete"}

    if (taskYn === "Y") {
      const addInterestPD = await fetchAbsolute(
        "get",
        `/bpd/boardRcmdcountSaveHF?bpdId=${bpdId}`
      );
      if (addInterestPD.status !== 200) {
        alert(formatMessage({ id: "T1235" })) // 관심 저장 실패
      }
      fetchBpdData();
    } else if (taskYn === "N") {
      const delInterestPD = await fetchAbsolute(
        "get",
        `/bpd/boardRcmdcountDelHF?bpdId=${bpdId}`
      );
      if (delInterestPD.status !== 200) {
        alert(formatMessage({ id: "T1235" })) // 관심 저장 실패
      }
      fetchBpdData();
    }
  };

  const onOkDelete = async () => {
    const bpdDel = await fetchAbsolute("get", `/bpd/deleteBpdHF?bpdId=${bpdData.bpdId}`);
    if (bpdDel.error) {
      return alert(`Process Design ${formatMessage({ id: "T0282" })}`); // 삭제 실패 하였습니다
    } else {
      const path = "/bizflow/hfbpd/list";
      history.push(path);
      return;
    }
  };

  const onCancelDelete = () => setDeleteVisible(false);

  const onClickDelete = () => setDeleteVisible(true);
  const onClickTransform = () => setTransformVisible(true);
  const onCancelTransform = () => setTransformVisible(false);
  const onClickOrgaOpen = () => {
    setTransformVisible(false);
    setFixedMain(true);
    setVisibleOrgan(true);
  };

  const onChangeReason = (e) => {
    const { value } = e.target;
    setTransformReason(value);
  }

  const onOkOrgan = (data) => {
    setTransformUser([...data]);
    setVisibleOrgan(false);
    setTransformVisible(true);
  };

  const onCancelOrgan = () => {
    setVisibleOrgan(false);
    setTransformVisible(true);
  };

  const onClickDeleteOrg = (e, { index }) => {
    const tmpArr = [...transformUser];
    tmpArr.splice(index, 1);
    setTransformUser([...tmpArr]);
  }

  /* workflow 변환 버튼 기능 */
  const onOkTransform = async () => {
    if (Object.keys(transformUser).length === 0) return alert(formatMessage({ id: 'T0196' })); // 담당자를 지정해주세요. 
    dispatch(setVisible(true));

    // 승인 요청 api
    const wfMemberHFList = transformUser.map((v, index) => ({
      mgrDevGbn: "D",
      empNo: v.empNum,
      deputyHeadYn: index === 0 ? "N" : "Y",
    }));

    const result = await fetchAbsolute("post", `/bpd/reqBpdToWf`, {
      data: {
        bpdId: bpdData.bpdId,
        reason: transformReason,
        wfMemberHFList,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    dispatch(setVisible(false));
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setTransformVisible(false);
    alert(formatMessage({ id: 'T1591' })); // 변환 승인을 요청하였습니다.
    setTransformUser([]);
    setTransformReason('');
  };

  const fetchBpdData = async () => {
    setLoading(true);
    const result = await fetchAbsolute("get", `/bpd/bpdInfoHF?bpdId=${params.bpdId}`);
    setLoading(false);
    // const fetchUser = (empNum) => {
    //   return fetchAbsolute("get", `/common/memberNoList?empNo=${empNum}`);
    // };
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    // 담당자 프로필 세팅
    const setMainInfo = () => {
      const mainInfo = result?.data.bpdMemberHFList.find(
        (member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "N"
      );
      const subList = result?.data.bpdMemberHFList.filter(
        (member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "Y"
      );
      setSubProfiles([
        ...subList.map((v) => ({
          NAME_KOR: v.empNm,
          EMP_NO: v.empNo,
          DEPT_NAME_KOR: v.orgNm,
          FILE_ID: v.empFileId,
        })),
      ]);
      setMainProfile({
        NAME_KOR: mainInfo.empNm,
        EMP_NO: mainInfo.empNo,
        DEPT_NAME_KOR: mainInfo.orgNm,
        FILE_ID: mainInfo.empFileId,
      });
    };
    setBpdData({ ...result.data });
    setBpdType("lookup");
    setMainInfo();
  };

  const onClickCancel = () => {
    // history.goBack();
    history.push(`/bizflow/hfbpd/list${listSearch.trim().length ? "?" + listSearch : ""}`)
  };

  const onClickModify = () => {
    const path = `/bizflow/hfbpd/modify/${bpdData.bpdId}`;
    history.push(path);
  };

  // 텍스트 카피
  const copyText = (text) => {
    const tempElem = document.createElement("textarea");
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
  };

  /* 과제명 복사 기능 */
  const onClickCopyNm = () => {
    copyText(bpdData?.bpdNm);
    clearTimeout(copyNmTimeout.current);
    setVisibleCopyNm(true);
    copyNmTimeout.current = setTimeout(() => {
      setVisibleCopyNm(false);
    }, 1000);
  };

  /* 과제 ID 복사 기능 */
  const onClickCopyId = () => {
    copyText(bpdData?.bpdId);
    clearTimeout(copyIdTimeout.current);
    setVisibleCopyId(true);
    copyIdTimeout.current = setTimeout(() => {
      setVisibleCopyId(false);
    }, 1000);
  };

  // bpdIframeModal 추가
  const [bpdIframeVisible,setBpdIframeVisible] = useState(false);
  const [doubleCheckVisible,setDoubleCheckVisible] = useState(false);

  const onMaximizeIframe = () => setBpdIframeVisible(true);

  const onModifyBpdIframe = () =>  setBpdIframeVisible(true);
  const onOkBpdIframe = () => setDoubleCheckVisible(true);
  const onCancelBpdIframe = () => setBpdIframeVisible(false);
  const onOkDoubleCheck = () => {
    setBpdIframeVisible(false);
    setDoubleCheckVisible(false);
  }
  const onCancelDoubleCheck = () => setDoubleCheckVisible(false);

  useEffect(() => {
    fetchBpdData();
  }, []);

  /* test 테스트 process design 불러오는 */
  useEffect(() => {
    if (Object.keys(bpdData).length === 0) return;
  }, [bpdData]);
  if (loading) {
    return <Loading visible={loading} />
  }

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper>
        <Container
          className="process_design_basic_info"
          style={{ display: "flex", flexDirection: "column", gap: "30px 0" }}
        >
          {/*---------------- 기본 정보 Part ---------------- */}
          <AssignEnterBox>
            <FormTable>
              <colgroup>
                <col width="13.5%" />
                <col width="36.5%" />
                <col width="13.5%" />
                <col width="36.5%" />
                <col width="13.5%" />
                <col width="36.5%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T1237" })}</Text>{/* 업무명 */}
                  </th>
                  <td colSpan={3}>
                    <div className="icon__group">
                      <div>
                        <Text style={{ fontSize: 18, wordBreak: "break-all" }}>{bpdData?.bpdNm}</Text>&nbsp;
                        <Tooltip title={formatMessage({ id: "T0460" })} open={visibleCopyNm}>{/* 복사되었습니다. */}
                          <CopyIcon
                            className=""
                            onClick={onClickCopyNm}
                            color="#333"
                          />
                        </Tooltip>
                      </div>
                      <div className="bizflow__tool_box">
                        <div className="readcnt_box">
                          <EyeIcon className="readcnd_box__icon" />
                          <Text className="readcnt_count">
                            {bpdData?.readCnt > 99
                              ? "+99"
                              : bpdData?.readCnt || 0}
                          </Text>
                        </div>
                        <div className="interest_box">
                          {bpdData.interestGbn === "Y" ? (
                            <HeartFilled
                              className="interest_box__icon"
                              onClick={() => onClickHeartButton(bpdData)}
                            />
                          ) : (
                            <HeartOutlined
                              className="interest_empty_box__icon"
                              onClick={() => onClickHeartButton(bpdData)}
                            />
                          )}
                          <Text className="interest_count">
                            {bpdData.interestCnt > 99
                              ? "+99"
                              : bpdData.interestCnt}
                          </Text>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T1316" })}</Text>{/* 업무 ID */}
                  </th>
                  <td>
                    <Text>{bpdData?.bpdId}</Text>
                    <Tooltip title={formatMessage({ id: "T0460" })} open={visibleCopyId}>{/* 복사되었습니다. */}
                      <CopyIcon
                        className="task_name__copy"
                        onClick={onClickCopyId}
                        color="#848484"
                        style={{ paddingLeft: 8 }}
                      />
                    </Tooltip>
                  </td>
                  <th>
                    <Text>{formatMessage({ id: "T0008" })}</Text>{/* 업무 분류 */}
                  </th>
                  <td>
                    <Text>{bpdData?.workPathNm}</Text>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T1129" })}</Text>{/* 업무 담당자(Main) */}
                  </th>
                  <td>
                    <UserTag profile={mainProfile} />
                  </td>
                  <th>
                    <Text>{formatMessage({ id: "T0429" })}</Text>{/* 등록자 */}
                  </th>
                  <td>
                    <UserTag
                      profile={{
                        NAME_KOR: bpdData?.regEmpNam,
                        EMP_NO: bpdData?.regId,
                        DEPT_NAME_KOR: bpdData.regEmpOrgNam,
                        FILE_ID: bpdData.regFileId,
                      }}
                      contextVisible={true}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T1130" })}</Text>{/* 업무 담당자(Sub) */}
                  </th>
                  <td colSpan={3}>
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10 }}>
                      {subProfiles.map((member, index) => (
                        <UserTag profile={{ ...member }} key={index} />
                      ))}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T1236" })}</Text>{/* 업무 설명 */}
                  </th>
                  <td className="desc_break" colSpan={3}>
                    <Text>{bpdData?.bpdDesc}</Text>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0513" })}</Text>{/* 첨부파일 */}
                  </th>
                  <td>
                    {bpdData.fileList?.length > 0
                      ? bpdData.fileList.map((file, index) => (
                        <div className="file__list">
                          <Text
                            key={index}
                            type="download"
                            download={file.down}
                            fileName={file.fileName}
                          >
                            <PaperClipOutlined />
                            {file.fileName}
                          </Text>
                        </div>
                      ))
                      : `${formatMessage({ id: "T0513" })} 없음 ` // 첨부파일 없음
                    }
                  </td>
                </tr>
                {
                  bpdType === 'modify'
                  &&
                  <tr>
                    <th>
                      Process Design
                    </th>
                    <td>
                      <Button><FormattedMessage id="T1652" />{/* 수정하러 가기 */}</Button>
                    </td>
                  </tr>
                }
              </tbody>
            </FormTable>
          </AssignEnterBox>
          {/*---------------- Process Design Part ---------------- */}
          {
            bpdType !== 'modify'
            &&
            <AssignEnterBox 
              title="Process Design"
              type="bpdLookup"
              onClickButton={onMaximizeIframe}
            >
              {/* <Button
                type="primary"
                style={{ marginBottom:"5px", borderRadius: "4px"}}
                onClick={onMaximizeIframe}
              >
                <FormattedMessage id="T1650" />
              </Button> */}
              <div>
                <HFBpdIframe
                  profile={profile}
                  type={bpdType}
                  data={bpdData}
                  params={params}
                />
              </div>
            </AssignEnterBox>
          }
          <div className="flex justify-end">
            {
              // 업무 담당자들한테만 보여야해
              bpdData.bpdMemberHFList?.find(v => v.empNo === profile.EMP_NO) &&
              <Button
                type="primary"
                style={{ width: 150, height: 40 }}
                onClick={onClickTransform}
              >
                {formatMessage({ id: "T1580" })}{/* Workflow 변환 */}
              </Button>
            }
            {(bpdData.regId === profile.EMP_NO ||
              profile.DWP_USER_LEVEL === "UA06" ||
              profile.DWP_USER_LEVEL === "UA05") && (
                <>
                  <Button
                    type="primary"
                    style={{ width: 150, marginLeft: 30, height: 40 }}
                    onClick={onClickModify}
                  >
                    {formatMessage({ id: "T0054" })}{/* 수정 */}
                  </Button>
                  <Button
                    style={{ width: 150, height: 40, marginLeft: 30 }}
                    onClick={onClickDelete}
                  >
                    {formatMessage({ id: "T0072" })}{/* 삭제 */}
                  </Button>
                </>
              )}
            <Button
              style={{ width: 150, marginLeft: 30, height: 40 }}
              onClick={onClickCancel}
            >
              {formatMessage({ id: "T1233" })}{/* 목록 */}
            </Button>
          </div>
        </Container>
      </Wrapper>
      <Modal
        width={540}
        visible={deleteVisible}
        title={`Process Design  ${formatMessage({ id: "T0072" })}`} // Process Design 삭제
        okText={formatMessage({ id: "T0072" })} // 삭제
        onCancel={onCancelDelete}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T1234" })}{/* 정말로 삭제 하시겠습니까? */}
        </Text>
      </Modal>
      <Modal
        width={580}
        visible={transformVisible}
        title={formatMessage({ id: "T1586" })} // 변경 사유 입력
        okText={formatMessage({ id: "T1149" })} // 요청
        onCancel={onCancelTransform}
        onOk={onOkTransform}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', gap: 10
        }}>
          <div style={{
            display: 'flex', gap: 34, paddingBottom: 20
          }}>
            <Text style={{ fontSize: "14px", flex: 'none' }} required={true}>
              {formatMessage({ id: "T1585" })}{/* 변경 사유 */}
            </Text>
            <TextArea
              autoSize={{ minRows: 4, maxRows: 4 }}
              value={transformReason}
              onChange={onChangeReason}
            />
          </div>
          <div>
            <Text style={{ fontSize: "14px" }} required={true}>
              {formatMessage({ id: "T1126" })}{/* 개발자 검색 */}
            </Text>
            <span style={{ paddingRight: "20px" }} />
            {
              <Button
                data-label='developers'
                onClick={onClickOrgaOpen}
              >
                {formatMessage({ id: "T0049" })}{/* 추가 */}
              </Button>
            }
          </div>
          <div style={{ paddingLeft: 92, display: 'flex', flexWrap: 'wrap', gap: '8px 10px' }}>
            {
              NameTagGroup(transformUser.map((v, index) => ({ ...v, isMain: index === 0 })), 'transformUser', onClickDeleteOrg)
            }
          </div>
        </div>
      </Modal>
      <Organization
        visible={visibleOrgan}
        title={formatMessage({ id: "T1126" })} // 개발자 검색
        onOk={onOkOrgan}
        onCancel={onCancelOrgan}
        defaultData={transformUser}
        msColumn={true}
        fixedMain={fixedMain}
      />
      <HFBpdIframeModal
        type={bpdType}
        data={bpdData}
        params={params}
        visible={bpdIframeVisible}
        doubleCheckVisible={doubleCheckVisible}
        onModifyBpdIframe={onModifyBpdIframe}
        onOkBpdIframe={onOkBpdIframe}
        onCancelBpdIframe={onCancelBpdIframe}
        onOkDoubleCheck={onOkDoubleCheck}
        onCancelDoubleCheck={onCancelDoubleCheck}
      />
    </PageTemplate>
  );
};

export default HFBpdLookup;
