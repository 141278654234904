import { getUniqueKey } from 'utils/Str';

export const SET_ADMINMEMBER_VALUE = getUniqueKey('SET_ADMINMEMBER_VALUE');
export const SET_ADMINMEMBER_SPREAD = getUniqueKey('SET_ADMINMEMBER_SPREAD');
export const RESET_ADMINMEMBER_VALUE = getUniqueKey('RESET_ADMINMEMBER_VALUE');
export const SET_ADMINMEMBER_PAGEINFO = getUniqueKey('SET_ADMINMEMBER_PAGEINFO');

export function setAdminMemberValue(key, value) {
    return {
        type: SET_ADMINMEMBER_VALUE,
        key,
        value
    };
};

export function setAdminMemberSpread(data) {
    return {
        type: SET_ADMINMEMBER_SPREAD,
        data
    };
};

export function resetAdminMemberValue() {
    return {
        type: RESET_ADMINMEMBER_VALUE
    };
};

export function setAdminMemberPageInfo(data) {
    return {
        type: SET_ADMINMEMBER_PAGEINFO,
        data
    };
};