import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled";
import { Row, Col, Select, Radio } from "antd";
import {
  Input,
  Button,
  InputSearch,
  RangePicker,
  FormTable,
  Text,
  DepartmentList,
  Organization,
  BusinessClass,
  Modal,
  RadioButton,
} from "components";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  setHandyFlowBpdListValue,
  setHandyFlowBpdListSpread,
  resetHandyFlowBpdListValue,
  setHandyFlowBpdListFilterId,
} from "store/hfBpdList/action";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

const initialInputValues = {
  bpdId: "",
  bpdNm: "",
  bpdOrgCd: [],
  workId: [],
  mgrIds: [], // 담당자 추가 
  regFr: "",
  regTo: "",
  regType: "",
};

/**
 * Filename: HFBpdListTop/index.js
 * Description: 핸디플로우 BPD 검색 컴포넌트
 */

const HFBpdListTop = ({
  replaceSearch,
  searchQuery,
  beforeSearch,
  setBeforeSearch,
  initialSearchQuery,
  setSortedInfo,
  setSearchQuery,
  tabsValue,
}) => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.get("auth").get("profile"));
  const inputValues = useSelector(
    (state) => state.get(getUniqueKey("hfBpdList")).inputValues
  );
  const filterId = useSelector(
    (state) => state.get(getUniqueKey("hfBpdList")).filterId
  );

  const isDelete = useRef(false);
  const searchPlaceholder = formatMessage({ id: "T0025" }); // 검색어를 입력하세요
  const mgrEmpNoPlaceholder = formatMessage({ id: "T0026" }); // 사용자를 선택하세요
  const recentSearch = formatMessage({ id: "T0031" }); // 최근 검색  
  const filterNm = formatMessage({ id: "T0035" }); // 필터명 
  const filterLimited = formatMessage({ id: "T0152" }); // 필터 저장은 5개까지만 가능합니다.

  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    bpdOrgCd: false,
    filter: false,
    workId: false,
    mgrIds: false,
  });
  // 저장 필터 이름
  const [saveFilterName, setSaveFilterName] = useState("");
  // filter List State
  const [filterList, setFilterList] = useState([]);
  const [isCheckedDate, setIsCheckedDate] = useState(false);
  const [checkedDate, setCheckedDate] = useState("");

  //  select 부분에선 e.target.value 등이 안먹혀서 둘 만 따로 'e'로 관리
  const onChangeSearchInput = (label, value) => {
    dispatch(setHandyFlowBpdListValue(label, value));
  };
  // Date는 두 개의 키 값을 변경하기 때문에 충돌 방지
  const onChangeDateRange = ([regFr, regTo]) => {
    dispatch(
      setHandyFlowBpdListSpread({
        regFr: regFr || "",
        regTo: regTo || "",
      })
    );
  };

  // 업무 ID나 업무명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearchButton(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  // input 부분 나머지 search와 select는 위에 따로 빼줌
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    onChangeSearchInput(name, value);
  };

  // RangePicker 변경 함수
  const onChangeDates = (val) => {
    const range = val.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDateRange(range);
  };

  // Date 버튼 클릭 이벤트
  const onChangeDateRadio = (e) => {
    const { value } = e.target;
    setIsCheckedDate(e.target.checked);
    dispatch(setHandyFlowBpdListValue("regType", value));
  };

  const onClickDateRadio = (e) => {
    setCheckedDate(e.target.value);
    if (e.target.value === checkedDate) {
      setIsCheckedDate(false);
      setCheckedDate("");
      dispatch(
        setHandyFlowBpdListSpread({
          regFr: "",
          regTo: "",
        })
      );
      dispatch(setHandyFlowBpdListValue("regType", ""));
    }
  };

  // 저장할 필터 이름 변경 이벤트
  const onChangeSaveFilterName = (e) => {
    // 필터 이름 15글자 제한
    if (e.target.value.length > 15) return;
    setSaveFilterName(e.target.value);
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true,
    });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    dispatch(setHandyFlowBpdListValue(modalLabel, data));
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  const onOkFilterModal = async () => {
    setModalVisible({
      ...modalVisible,
      filter: false,
    });
    if (saveFilterName.trim().length === 0) {
      alert(formatMessage({ id: "T0150" })); // 필터명을 입력해주세요.
      return;
    }
    if (
      filterList.findIndex(
        (v) => v.filterNm.toUpperCase() === saveFilterName.toUpperCase()
      ) !== -1
    ) {
      alert(formatMessage({ id: "T0151" })); // 필터명이 중복됩니다.
      return;
    }
    // 내 필터 5개 제한
    if (filterList.length > 4) {
      alert(filterLimited);
      return;
    }
    const saveInputValues = inputValues;

    //MJ 추가 - regType은 "custom"으로
    if (saveInputValues.regType) {
      saveInputValues.regType = "custom";
    }

    const addFilter = await fetchAbsolute("post", `/common/commonFilter/addNew`, {
      data: {
        ...setFilterObject(saveInputValues, saveFilterName),
        filterGbn: "b",
      }
    });
    setSaveFilterName("");
    setFilterList([
      ...filterList,
      {
        ...saveInputValues,
        filterId: addFilter.data.filterId,
        filterNm: saveFilterName,
      },
    ]);

  };

  // 필터 저장할 때 값 세팅
  const setFilterObject = (values, filterName) => {
    const bpdOrgCd = values.bpdOrgCd.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.codeId);
      return tmpArr;
    });
    const mgrIds = values.mgrIds.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum);
      return tmpArr;
    });
    const workId = values.workId.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.cd);
      return tmpArr;
    });

    const convertObject = {
      empNo: profile.EMP_NO,
      filterNm: filterName,
      filterList: [
        {
          filterNum: "filter1", //bpdId
          attrList: [values.bpdId] || [],
        },
        {
          filterNum: "filter2", //bpdNm
          attrList: [values.bpdNm] || [],
        },
        {
          filterNum: "filter3", //mgrIds
          attrList: mgrIds.flat() || [],
        },
        {
          filterNum: "filter4", //bpdOrgCd
          attrList: bpdOrgCd.flat() || [],
        },
        {
          filterNum: "filter5", //regFr
          attrList: [values.regFr] || [],
        },
        {
          filterNum: "filter6", //regTo
          attrList: [values.regTo] || [],
        },
        {
          filterNum: "filter7", //regType
          attrList: [values.regType] || [],
        },
        {
          filterNum: "filter8", //workId
          attrList: workId.flat() || [],
        },
      ],
    };
    return convertObject;
  };

  // 초기화 버튼 클릭 이벤트
  const onClickResetButton = () => {
    dispatch(resetHandyFlowBpdListValue());
    dispatch(setHandyFlowBpdListFilterId(""));
    replaceSearch({ ...initialInputValues, page: 0 });
    setSortedInfo({})
  };

  // 검색 버튼 클릭 이벤트
  const onClickSearchButton = () => {
    dispatch(setHandyFlowBpdListFilterId(""));
    replaceSearch({ ...inputValues, page: 0 });
  };

  // 최근 검색 인풋창
  const onClickBeforeSearch = () => {
    const searchObj = JSON.parse(localStorage.getItem("beforeBpdObject") || {});
    dispatch(setHandyFlowBpdListSpread({ ...initialInputValues, ...searchObj }));
    replaceSearch({ ...searchObj, page: 0 });
  };

  // 최근 검색 삭제 클릭 이벤트
  const onClickBeforeSearchDelete = () => {
    setBeforeSearch("");
    localStorage.setItem("beforeBpdSearch", "");
    localStorage.setItem("beforeBpdObject", "");
  };

  // 필터 리스트 변경 이벤트
  const onSelectFilterList = (value) => {
    //검색어 남겨져 있는 상태에서 필터검색시 초기화
    // setSearchQuery(initialSearchQuery);
    // 삭제 버튼 눌렀을 때 filterId 변경되지 않게 세팅
    if (isDelete.current) {
      isDelete.current = false;
      return;
    }
    dispatch(setHandyFlowBpdListFilterId(value));
  };

  // 필터 삭제 버튼 클릭 이벤트
  const onClickFilterDelete = async (e) => {
    isDelete.current = true;
    const value = e.currentTarget.dataset.filterid;
    const deleteFilterList = await fetchAbsolute(
      "delete",
      `/common/commonFilter/deleteNew`,
      {
        data: {
          empNo: profile.EMP_NO,
          filterId: value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (deleteFilterList.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    const tmpArr = [...filterList];
    if (filterId === value) { dispatch(setHandyFlowBpdListFilterId("")) }
    setFilterList([...tmpArr.filter((filter) => filter.filterId !== value)]);
  };

  useEffect(() => {
    if (inputValues.regType === 'custom' || inputValues.regType === "") return;
    const today = moment().format("YYYY-MM-DD");
    const dateObj = {
      regFr: "",
      regTo: today,
    };

    switch (inputValues.regType) {
      case "today":
        dateObj.regFr = moment().add(0, "d").format("YYYY-MM-DD");
        break;
      case "week":
        dateObj.regFr = moment().subtract(6, "d").format("YYYY-MM-DD");
        break;
      case "month":
        dateObj.regFr = moment().subtract(1, "months").format("YYYY-MM-DD");
        break;
      default:
    }

    if ((!dateObj.regFr && !dateObj.regTo) || !dateObj.regFr) {
    } else {
      dispatch(
        setHandyFlowBpdListSpread({
          ...dateObj,
        })
      );
    }
  }, [inputValues.regType]);

  useEffect(() => {
    dispatch(
      setHandyFlowBpdListSpread({
        ...searchQuery,
      })
    );
  }, [searchQuery]);

  /* 필터 선택시 inputValues에 뿌려줌 */
  useEffect(() => {
    if (filterId === "" || filterList.length === 0) return;
    const filterObject = {
      ...(filterList.find((v) => v.filterId === filterId) || {}),
    };

    if (filterObject) {
      delete filterObject.filterNm;
      delete filterObject.filterId;

      const { mgrIds, bpdOrgCd, workId } = filterObject;

      const setDatas = async () => {
        // 부서 정보 세팅
        if (bpdOrgCd) {
          if (typeof bpdOrgCd[0] !== "object") {
            if (typeof bpdOrgCd === {}) return (filterObject.bpdOrgCd = bpdOrgCd);
            const tmpArr = [];
            for (let i in bpdOrgCd) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/departmentList?orgCd=${bpdOrgCd[i]}`
              );
              tmpArr.push(data?.mdList[0]);
            }
            filterObject.bpdOrgCd = [...tmpArr];
          }
        }
        // 업무 분류
        if (workId) {
          if (typeof workId[0] !== "object") {
            const tmpArr = [];
            for (let i in workId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/workClassification?cd=${workId[i]}`
              );
              tmpArr.push(data);
            }
            filterObject.workId = [...tmpArr];
          }
        }
        // 매니저 정보 세팅
        if (mgrIds) {
          if (typeof mgrIds[0] !== "object") {
            const tmpArr = [];
            for (let i in mgrIds) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${mgrIds[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
            filterObject.mgrIds = [...tmpArr];
          }
        }
        dispatch(setHandyFlowBpdListSpread({ ...searchQuery, ...filterObject, }));
        replaceSearch({ ...filterObject, currentPage: 1 });
      }
      setDatas();
    }
  }, [filterId, filterList]);

  useEffect(() => {
    const fetchFilterList = async () => {
      try {
        const { data } = await fetchAbsolute(
          "get",
          `/common/commonFilter/findByEmpNoAndFilterGbnNew?empNo=${profile.EMP_NO}&filterGbn=b`
        );
        const lists = data?.map((filter) => ({
          filterId: filter.filterId,
          filterNm: filter.filterNm,
          bpdId: filter.filter1.map((data) => data.filterAttr)[0] || "",
          bpdNm: filter.filter2?.map((data) => data.filterAttr)[0] || "",
          mgrIds: filter.filter3?.map((data) => data.filterAttr) || [],
          bpdOrgCd: filter.filter4?.map((data) => data.filterAttr) || [],
          regFr: filter.filter5?.map((data) => data.filterAttr)[0] || "",
          regTo: filter.filter6?.map((data) => data.filterAttr)[0] || "",
          regType: filter.filter7?.map((data) => data.filterAttr) || [],
          workId: filter.filter8?.map((data) => data.filterAttr) || [],
        }));
        setFilterList([...lists]);
      } catch (e) { }
    };
    setBeforeSearch(localStorage.getItem("beforeBpdSearch") || "");
    fetchFilterList();
    dispatch(setHandyFlowBpdListFilterId(""));
  }, []);

  return (
    <Wrapper>
      <FormTable pointBorder={false} size="sm">
        <colgroup>
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="17.625%" />
        </colgroup>
        <tbody>
          <tr>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text>{formatMessage({ id: "T1316" })}</Text>
              {/* 업무 ID */}
            </th>
            <td>
              <Input
                placeholder={searchPlaceholder}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="bpdId"
                value={inputValues.bpdId}
                onChange={onChangeValues}
                onKeyPress={onKeyPress}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T1237" })}</Text>{/* 업무명 */}
            </th>
            <td>
              <Input
                placeholder={searchPlaceholder}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="bpdNm"
                value={inputValues.bpdNm}
                onChange={onChangeValues}
                onKeyPress={onKeyPress}
              />
            </td>
            <th span={2}>
              <Text>{formatMessage({ id: "T0006" })}</Text>{/* 업무 담당자 */}
            </th>
            <td span={4}>
              <div>
                <InputSearch
                  onClick={onClickModalButton}
                  width={200}
                  height={32}
                  data-label="mgrIds"
                  placeholder={mgrEmpNoPlaceholder}
                  value={inputValues.mgrIds.map((c) => c.empNam).join(", ")}
                />
              </div>
            </td>
            <th rowSpan={2}>
              <Text>{formatMessage({ id: "T0004" })}</Text>{/* 등록날짜 */}
            </th>
            <td rowSpan={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px 0",
                  padding: "14px 0",
                }}
              >
                <RangePicker
                  popupStyle={{
                    ".ant-picker-cell-inner": {
                      background: "#000",
                    },
                  }}
                  style={{
                    width: 252,
                    alignItems: "center",
                  }}
                  size="middle"
                  format="YYYY-MM-DD"
                  value={[
                    inputValues.regFr && inputValues.regFr.length > 0
                      ? moment(inputValues.regFr)
                      : undefined,
                    inputValues.regTo && inputValues.regTo.length > 0
                      ? moment(inputValues.regTo)
                      : undefined,
                  ]}
                  name="dates"
                  defaultValue={[undefined, undefined]}
                  disabled={!(inputValues.regType === "custom")}
                  onCalendarChange={onChangeDates}
                  onChange={onChangeDates}
                />
                <Radio.Group
                  className="date_button__wrap"
                  onChange={onChangeDateRadio}
                  checked={isCheckedDate}
                  defaultValue="today"
                  value={inputValues.regType}
                >
                  <RadioButton
                    value="today"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0044" />
                    {/* 오늘 */}
                  </RadioButton>
                  <RadioButton
                    value="week"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0045" />
                    {/* 일주일 */}
                  </RadioButton>
                  <RadioButton
                    value="month"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0046" />
                    {/* 1개월 */}
                  </RadioButton>
                  <RadioButton
                    value="custom"
                    className="date__btn"
                    onClick={onClickDateRadio}
                  >
                    <FormattedMessage id="T0047" />
                    {/* 직접입력 */}
                  </RadioButton>
                </Radio.Group>
              </div>
            </td>
          </tr>
          <tr>
            <th span={2}>
              <Text>{formatMessage({ id: "T0005" })}</Text>{/* 담당부서 */}
            </th>
            <td span={4}>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="bpdOrgCd"
                placeholder={searchPlaceholder}
                value={inputValues.bpdOrgCd.map((c) => c.codeNm).join(", ")}
              />
            </td>
            <th span={2}>
              <Text>{formatMessage({ id: "T0340" })}</Text>
              {/* 업무분류 */}
            </th>
            <td span={4}>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="workId"
                placeholder={searchPlaceholder}
                value={inputValues.workId.map((c) => c.cdNm).join(", ")}
              />
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Row style={{ padding: "0 13px" }}>
        <Col
          span={16}
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {beforeSearch.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text>{recentSearch}</Text>
              <div style={{ width: 200 }}>
                <div className="before__input">
                  <Input
                    style={{
                      width: 200,
                      height: 32,
                    }}
                    readOnly
                    autoFocus
                    value={beforeSearch}
                    onClick={onClickBeforeSearch}
                  />
                  <CloseCircleFilled
                    className="before__clear"
                    onClick={onClickBeforeSearchDelete}
                  />
                </div>
              </div>
            </div>
          )}
          {filterList.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text>{formatMessage({ id: "T0032" })}</Text>{/* 내 필터 */}
              <div>
                <Select
                  size="default"
                  defaultValue="none"
                  value={filterId}
                  style={{
                    width: 200,
                    fontSize: 14,
                  }}
                  onSelect={onSelectFilterList}
                >
                  <Option className="filter_option" value="">
                    {formatMessage({ id: "T0033" })}{/* 필터 선택 */}
                  </Option>
                  {filterList.map((filter) => {
                    return (
                      <Option
                        className="filter_option"
                        style={{ width: "100%" }}
                        key={filter.filterId}
                        value={filter.filterId}
                      >
                        <div
                          style={{
                            width: "100%",
                            position: "relative",
                          }}
                        >
                          <Text
                            style={{
                              display: "block",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              paddingRight: 20,
                            }}
                            title={filter.filterNm}
                          >
                            {filter.filterNm}
                          </Text>
                          <CloseCircleFilled
                            style={{
                              position: "absolute",
                              top: "50%",
                              right: "5px",
                              transform: "translateY(-50%)",
                            }}
                            onClick={onClickFilterDelete}
                            data-filterid={filter.filterId}
                          />
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0 10px",
            padding: "10px 0",
          }}
        >
          <Button width="100" height="32" onClick={onClickResetButton}>
            <FormattedMessage id="T0037" />
            {/* 초기화 */}
          </Button>
          <Button
            width="100"
            height="32"
            data-label="filter"
            onClick={(e) => onClickModalButton(e.target)}
          >
            <FormattedMessage id="T0038" />
            {/* 필터저장 */}
          </Button>
          <Button
            onClick={onClickSearchButton}
            width="100"
            height="32"
            type="primary"
          >
            <FormattedMessage id="T0039" />
            {/* 검색 */}
          </Button>
        </Col>
      </Row>
      <DepartmentList
        visible={modalVisible.bpdOrgCd}
        companyCd={profile.COMP_CD}
        onOk={onOkModal}
        onCancel={onCancelModal}
        mode="multiple"
        defaultData={inputValues[modalLabel]}
      />
      <Organization
        visible={modalVisible.mgrIds}
        companyCd={profile.COMP_CD}
        title={formatMessage({ id: "T0333" })} // 구성원 검색 
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues[modalLabel]}
      />
      <BusinessClass
        title={formatMessage({ id: "T1119" })} // 업무 분류 검색
        visible={modalVisible.workId}
        onOk={onOkModal}
        onCancel={onCancelModal}
        mode="multiple"
        defaultData={inputValues[modalLabel]}
      />
      <Modal
        centered={true}
        title={formatMessage({ id: "T0034" })} // 필터 저장
        visible={modalVisible.filter}
        onOk={onOkFilterModal}
        onCancel={onCancelModal}
        width="500px"
      >
        <div id="modalForm">
          <label for="filterNm">{filterNm}</label>
          <Input
            id="filterId"
            name="filterNm"
            type="text"
            value={saveFilterName}
            mexLength={50}
            onChange={onChangeSaveFilterName}
          />
        </div>
      </Modal>
    </Wrapper>
  );
};

export default HFBpdListTop;
