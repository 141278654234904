import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import fetchAbsolute from "utils/fetchAbsolute";
import { Input, Button, Table, Radio, Text } from "components";
import Wrapper from "./Styled";
import { Select } from "antd";
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import { setCheckBoard } from "store/communityCheck/action";
import { useDispatch } from "react-redux";
import { setMenuList } from "store/menuList/action";

/**
 * 관리자 탭 > 게시판 관리
 */

const initialPageInfo = {
  sort: "regTimestamp",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};
const { Option } = Select;

const AdminBoardManage = () => {
  const fmMessage = useFormatMessage();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const boardNmPlaceholder = formatMessage({ id: "T0030" }); // 게시판 명을 입력해주세요 

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [sortedInfo, setSortedInfo] = useState({});

  const pageSize = [20, 50, 70];

  const [boardList, setBoardList] = useState([]);
  const [boardNm, setBoardNm] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [isResetVisible, setIsResetVisible] = useState(false);

  const onChangeShowMain = async (e, record) => {
    const useYn = e.target.value;
    const updateUse = await fetchAbsolute(
      "get",
      `/board/boardMaUseYnUpdate?boardId=${record.boardId}&useYn=${useYn}`
    );
    /* 메뉴 상태 바로 변경 적용 */
    dispatch(setMenuList());
    fetchBoardCheck();
  };

  const fetchBoardCheck = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    // Board Check 저장
    const result = await fetchAbsolute("get", `/board/boardMaList?boardNm=${boardNm}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`);
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    dispatch(setCheckBoard([...result.data.boardMaList]));
  };
  const onChangePageSize = (e) => {
    getBoardList({ ...pageInfo, rowPerPage: +e, currentPage: 1 }, boardNm);
  };

  const onChangeInput = (e) => {
    setBoardNm(e.target.value);
  };

  const onPressComment = (e) => {
    if (e.key.toUpperCase() === "ENTER") {
      onClickSearch();
    }
  };
  const onClickSearch = () => {
    setIsResetVisible(true);
    getBoardList(pageInfo, boardNm);
  };

  const onClickReset = () => {
    setBoardNm("");
    setIsResetVisible(false);
    getBoardList(initialPageInfo, '');
    setSortedInfo({});
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'regTimestamp';
    let orderBy = "";
    if (!sorter.order) {
      order = "regTimestamp"
      orderBy = ",asc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    getBoardList(searchObj, boardNm);
  };

  const getBoardList = async (page, boardNm) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    setTableLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/board/boardMaList?boardNm=${boardNm}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setTableLoading(false);
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    setBoardList([...result.data.boardMaList]);
    setPageInfo({ ...page, total: +result.data.totalItemsCount });
  };

  const columns = [
    {
      title: formatMessage({ id: "T0622" }), // 게시판 명
      dataIndex: "boardNm",
      key: "boardNm",
      sorter: (a, b) => a.boardNmb,
      sortOrder: sortedInfo.columnKey === 'boardNm' && sortedInfo.order,
      render: (value, row, index) => {
        return (
          <Link to={`/admin/board/${row.boardId}/modify`}>
            <div>{value}</div>
          </Link>
        );
      },
    },
    {
      title: formatMessage({ id: "T0394" }), // 등록일
      dataIndex: "regTimestamp",
      key: "regTimestamp",
      width: 342,
      sortOrder: sortedInfo.columnKey === 'regTimestamp' && sortedInfo.order,
      render: (date) => moment(date).format("YYYY.MM.DD HH:mm"),
      sorter: (a, b) => a.regTimestamp,
      // sorter: (a, b) =>
      //   +moment(a.regTimestamp).format("YYYYMMDDHHmm") -
      //   +moment(b.regTimestamp).format("YYYYMMDDHHmm"),
    },
    {
      title: formatMessage({ id: "T0623" }), // 상태
      dataIndex: "useYn",
      key: "useYn",
      width: 173,
      sorter: (a, b) => a.useYn,
      sortOrder: sortedInfo.columnKey === 'useYn' && sortedInfo.order,
      render: (value, record) => {
        return (
          <Radio
            checked={value}
            defaultValue={value}
            onChange={(e) => onChangeShowMain(e, record)}
            renderItem={[
              {
                value: "Y",
                text: "On",
              },
              {
                value: "N",
                text: "Off",
              },
            ]}
          />
        );
      },
    },
  ];

  /* 게시판 목록 불러오기 */
  useEffect(() => {
    getBoardList();
  }, []);

  return (
    <Wrapper>
      <div className="board_manage__top">
        <div style={{ alignSelf: "flex-end" }}>
          <span>
            {formatMessage({ id: "T0036" })} &emsp; {/* 페이지당 행 */}
            <Select
              name="pagination"
              style={{ width: 89, height: 32 }}
              onSelect={onChangePageSize}
              value={+pageInfo.rowPerPage}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                </Option>
              ))}
            </Select>
          </span>
        </div>
        <div className="board_manage__top_right">
          <Input
            placeholder={boardNmPlaceholder}
            style={{
              width: 200,
              height: 32,
            }}
            value={boardNm}
            onChange={onChangeInput}
            onPressEnter={onPressComment}
          />
          <Button
            type="default"
            width="100"
            height="32"
            onClick={onClickSearch}
          >
            {formatMessage({ id: "T0039" })}
            {/* 검색 */}
          </Button>
          {isResetVisible && (
            <Button
              type="default"
              width="100"
              height="32"
              onClick={onClickReset}
            >
              {formatMessage({ id: "T0037" })}
              {/* 초기화 */}
            </Button>
          )}
          <Link to="/admin/board/create">
            <Button type="primary" width="150" height="40">
              <div style={{ marginTop: "-7px" }}>
                <Text
                  style={{ fontSize: 24, fontWeight: "bold", color: "#fff" }}
                >
                  +
                </Text>
                <Text style={{ fontSize: 14, color: "#fff" }}>
                  {formatMessage({ id: "T0071" })}{/* 게시판 생성 */}
                </Text>
              </div>
            </Button>
          </Link>
        </div>
      </div>
      <div className="board_manage__bottom">
        <Table
          pagination={true}
          loading={tableLoading}
          rowKey={(item) => item.boardId}
          columns={columns}
          dataSource={boardList}
          style={{ cursor: "pointer" }}
          align="center"
          locale={{ emptyText: " " }}
          onChange={onChangeTableColumns}
          pageOptions={{
            total: +pageInfo.total,
            current: +pageInfo.currentPage,
            pageSize: +pageInfo.rowPerPage,
          }}
        />
      </div>
    </Wrapper>
  );
};

export default withRouter(AdminBoardManage);
