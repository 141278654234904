import React from "react";
import { Text, Table, Button, PermissionComponent } from "components";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * 과제 조회 > 운영 현황 > 스케줄 현황
 */
const LookupScheduleStatus = ({ onClickEditStatusBtn, scheduleList, schduleDeleteBtn }) => {
  const { formatMessage } = useIntl();

  const scheduleType = formatMessage({ id: "T0010" }); // 스케줄 유형
  const endDate = formatMessage({ id: "T0021" }); // 종료일

  const columns = [{
    title: <FormattedMessage id="T1059" />, // NO
    key: 1,
    align: "center",
    render: (text, record, index) => index + 1,
  }, {
    title: <FormattedMessage id="T1036" />, // CR
    dataIndex: "cr",
    key: 2,
    align: "center",
    render: (data, record, index) => (
      data === "DIY" ? "DIY" : data
    ),
  }, {
    title: <FormattedMessage id="T1060" />, // Device Pool
    dataIndex: "poolName",
    width: 90,
    key: 3,
    align: "center",
  }, {
    title: <FormattedMessage id="T0564" />, // 스케줄명
    dataIndex: "name",
    key: 4,
  }, {
    title: scheduleType,
    dataIndex: "exeCycle",
    key: 5,
  }, {
    title: <FormattedMessage id="T0020" />, // 시작일
    dataIndex: "startDate",
    width: 90,
    key: 6,
    align: "center",
    render: (date) => {
      return moment(date).format("YYYY-MM-DD");
    },
  }, {
    title: endDate,
    dataIndex: "endDate",
    width: 90,
    key: 7,
    render: (date) => {
      return !date ? "" : moment(date).format("YYYY-MM-DD");
    },
  }, {
    title: formatMessage({ id: 'T0394' }), // 등록일
    dataIndex: "createdOn",
    width: 90,
    key: 8,
  }, {
    title: "",
    width: 130,
    key: 9,
    render: (data) => (
      <div className="schedule_status__button_wrap">
        <PermissionComponent fnCode='FN22'>
          <Button
            type="default"
            width="50"
            height="24"
            onClick={() => onClickEditStatusBtn(data)}
          >
            <FormattedMessage id="T0054" />{/* 수정 */}
          </Button>
        </PermissionComponent>
        <PermissionComponent fnCode='FN23'>
          <Button
            type="default"
            width="50"
            height="24"
            onClick={() => schduleDeleteBtn(data)}
          >
            <FormattedMessage id="T0072" />{/* 삭제 */}
          </Button>
        </PermissionComponent>
      </div>
    ),
  },
  ];
  return (
    <div className="status_box">
      <div className="status_box__title">
        <Text>{formatMessage({ id: "T0565" })}</Text>{/* 스케줄 현황 */}
      </div>
      <div className="status_box__info">
        <Table
          columns={columns}
          dataSource={scheduleList}
          locale={{ emptyText: `${formatMessage({ id: 'T0566' })}` }} // 운영 중인 스케줄이 없습니다.
          rowKey={(item) => item.name}
        />
      </div>
    </div>
  );
};

export default LookupScheduleStatus;