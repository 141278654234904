import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .admin_email_trigger__table_wrap {
        margin-top: 20px;
    }

    .admin_email_trigger_top {
        display: flex;
        gap: 15px; 
        padding: 14.6px 0px 10.5px 20px;
        align-items: center;
    }
    .admin_email__button_group {
        display: flex; 
        justify-content: flex-end;
        gap: 0 25px;
    }
    // tr:hover {
    //     text-decoration:underline
    // }
    tr {
        cursor: pointer
    }
    // .ant-row {
    //     display: block; !important
    // }
    thead[class*="ant-table-thead"] th{
        font-weight: bold;
       
      }

    .email_trigger__button_group {
        display: flex;
        justify-content: flex-end;
        gap: 0 15px;
    }
`;

export default Wrapper;