import { SET_LIST_URL } from './action';

/**
 * List URL 관리하는 Reducer
 * 
 */

const listUrlsInitialState = {
    assignList: '',
    assignListSchedule: '',
    hfBpdList: '',
    hfWorkflowList: '',
    hfListSchedule: ''
};

const listUrls = (state = listUrlsInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_LIST_URL: {
            return {
                ...state,
                [action.key]: action.search
            };
        }
        default:
            return state;

    };
};

export default listUrls;
