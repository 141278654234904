import React, { useState, useEffect } from 'react';
import { PageTemplate, Header, Footer, Button, Spin, DepartmentList, Modal, Text } from 'components';
import MemberEditTop from './MemberEditTop';
import MemberEditBottom from './MemberEditBottom';
import Wrapper from './Styled';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import UploadImg from './UploadImg';

/**
 * Filename: AdminMemberEdit/index.js
 * Description: 관리자 -> 회원 관리 -> 회원 조회 및 수정
 */

const AdminMemberEdit = ({ history, match: { params: { empNum } } }) => {
    const { formatMessage } = useIntl();

    const [memberInfo, setMemberInfo] = useState({});
    const [idPhoto, setIdPhoto] = useState({});

    const [dwpUserLevelList, setDwpUserLevelList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [fileUploadVisible, setFileUploadVisible] = useState(false);
    const [orgVisible, setOrgVisible] = useState(false);
    const [dwpUserLoading, setDwpUserLoading] = useState(false);
    const [abLevelList, setAbLevelList] = useState([]);
    const [abLevelLoading, setAbLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeCheck, setChangeCheck] = useState(false);
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [abLevel, setAbLevel] = useState('');
    const [dwpLevel, setDwpLevel] = useState('');
    const [userLevel, setUserLevel] = useState('');
    const [previewSrc, setPreviewSrc] = useState('');

    const [department, setDepartment] = useState([]);

    const fetchMemberInfo = async () => {
        const result = await fetchAbsolute('get', `/common/memberInfo?empNum=${empNum}`);
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }

        const orgResult = await fetchAbsolute('get', `/common/departmentList?orgCd=${result.data.orgCd}`);
        if (orgResult.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }

        // 전화번호는 DB에 하이픈 없이 저장되기 때문에
        setMemberInfo({ ...result.data, cphoneNum: result.data.cphoneNum?.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "") || '' });
        setDepartment([...orgResult.data.mdList])
    }

    const onChangeAbLevel = (value) => {
        setAbLevel(value);
    }
    const onChangeDwpLevel = (value) => {
        setDwpLevel(value);
    }

    const onClickChangeIMG = () => {
        setFileUploadVisible(true);
    }

    const onClickOrg = () => {
        setOrgVisible(true);
    }

    const onOkOrg = (data) => {
        setMemberInfo({ ...memberInfo, orgNam: data.codeNm, orgCd: data.codeId, ...data });
        setOrgVisible(false);
    }

    const onClickDeleteMember = () => setDeleteVisible(true);
    const onCancelDeleteMember = () => setDeleteVisible(false);
    const onOkDeleteMember = async () => {
        const result = await fetchAbsolute(
            "get",
            `/common/memberDelete?empNum=${empNum}`
        );
        if (result.error) {
            alert(result.message);
            setDeleteVisible(false)
            return;
        }
        alert(formatMessage({ id: "T0281" })); // 삭제되었습니다.
        return history.push("/admin/member");
    }

    const onChangeMemberPhone = (e) => {
        // 숫자만 입력하도록 정규화 검사 
        const { value } = e.target;
        // - 하이픈 보이게
        const onlyNumber = value.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
        setMemberInfo({ ...memberInfo, cphoneNum: onlyNumber });
    }

    const onChangeMemberName = (e) => {
        const { value } = e.target;
        setMemberInfo({ ...memberInfo, empNam: value });
    }

    const onClickList = () => {
        history.push({ pathname: '/admin/member' });
    };

    const onOkUploadImage = () => {
        setFileUploadVisible(false);
    }

    const onClickSearch = async () => {
        setLoading(true);
        setChangeCheck(true);
        const formData = new FormData();
        fileList.forEach((file) => formData.append("file", file));
        const { companyCd, empNum, empNam, cphoneNum, orgNam, orgCd } = memberInfo;

        const tmp = cphoneNum;
        const saveOnlyNumber = tmp.replace(/\-/g, '');
        /*
            companyCd : 회사 코드
            empNum : 사번
            dwpUserLevel : 회원 등급
            abLevel : 회원 역량 등급
            empNam : 이름
            cphoneNum : 전화번호
            orgNam : 조직 이름
            orgCd: 조직 코드,
        */
        formData.append('companyCd', companyCd);
        formData.append('empNum', empNum);
        formData.append('dwpUserLevel', dwpLevel || dwpUserLevelList[0]?.cd);
        formData.append('abLevel', abLevel || abLevelList[0]?.cd);
        formData.append('empNam', empNam);
        formData.append('cphoneNum', saveOnlyNumber);
        formData.append('orgNam', orgNam);
        formData.append('orgCd', orgCd);

        const result = await fetchAbsolute('post', `/common/modifyUserAdInfo`, {
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            }

        });
        setLoading(false);
        if (result.error) {
            return alert(formatMessage({ id: 'T0272' }));
            //'사용자 업데이트 중 문제가 발생했습니다.');
        }
        alert(formatMessage({ id: 'T0273' }));
        setFileList([]);
        //'사용자 업데이트가 완료되었습니다.');
        fetchMemberInfo();
    }

    /* 이미지 업로드 할 때 사용 */
    const onChangeImg = (e) => {
        setPreviewSrc(URL.createObjectURL(e.file));
    }

    const onCancelUploadImg = () => {
        setFileUploadVisible(false);
        setFileList([]);
        setPreviewSrc('');
    }

    useEffect(() => {
        const fetchUserLevel = async () => {
            setDwpUserLoading(true);
            const result = await fetchAbsolute('get', `/common/commonCd/getCategoryId/USER_LEVEL`);
            setDwpUserLoading(false);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            if (userLevel === 'UA02') { // citizen developer
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y' && ['UA01', 'UA02', 'UA03'].includes(v.cd))]);
            } else if (userLevel === 'UA03') { // citizen director
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y' && ['UA01', 'UA02', 'UA03'].includes(v.cd))]);
            } else if (userLevel === 'UA07') { //citizen supporter
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y' && ['UA01', 'UA07'].includes(v.cd))]);
            } else if (userLevel === 'UA04') { // coe developer
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y' && ['UA01', 'UA04'].includes(v.cd))]);
            } else if (userLevel === 'UA05') { // coe operator
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y' && ['UA01', 'UA05'].includes(v.cd))]);
            } else if (userLevel === 'UA06') { // coe member
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y' && ['UA01', 'UA06'].includes(v.cd))]);
            } else {
                setDwpUserLevelList([...result.data.filter(v => v.useYn === 'Y')]);
            }
        }
        const fetchAbLevel = async () => {
            setAbLoading(true);
            const result = await fetchAbsolute('get', `/common/commonCd/getCategoryId/AB_LEVEL`);
            setAbLoading(false);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            setAbLevelList([...result.data.filter(v => v.useYn === 'Y')]);
        }
        fetchUserLevel();
        fetchAbLevel();
    }, [userLevel]);

    useEffect(() => {
        if (Object.keys(memberInfo).length === 0) {
            return;
        }
        setAbLevel(memberInfo.abLevel || '');
        setDwpLevel(memberInfo.dwpUserLevel || '');
    }, [memberInfo]);

    useEffect(() => {
        if (!empNum) return;
        const userAuthInfo = async () => {
            setLoading(true);
            const result = await fetchAbsolute('get', `/auth/getUserAdInfo?empNum=${empNum}`);
            setLoading(false);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            if (result.data[0]?.dwpUserLevel) {
                setUserLevel(result.data[0].dwpUserLevel)
            } else {
                setUserLevel('');
            }
        }
        userAuthInfo();
        fetchMemberInfo();
    }, [empNum]);

    /* 프로필 사진 이미지 가지고 오기 */
    useEffect(() => {
        if (!memberInfo.fileId) return;
        const getUserFile = async () => {
            setLoading(true);
            const result = await fetchAbsolute('get', `/file/listFile/${memberInfo.fileId}`);
            setLoading(false);
            if (result.error) {
                return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            }
            setIdPhoto(result.data[0]);
        }
        getUserFile();
    }, [memberInfo.fileId, previewSrc]);

    return (
        !!loading ?
            <div style={{
                height: '100vh',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center'
            }}
            >
                <Spin tip="Loading..." />
            </div> :
            <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'
                menuTitle={`${formatMessage({ id: 'T1192' })}`} // 회원 조회
                depthList={[`${formatMessage({ id: 'T0881' })}`, // 관리자
                `${formatMessage({ id: 'T1192' })}`]}  // 회원 조회
            >
                <Wrapper
                    style={{
                        padding: "30px 0",
                    }}
                >
                    <MemberEditTop
                        memberInfo={memberInfo}
                        dwpUserLevelList={dwpUserLevelList}
                        dwpUserLoading={dwpUserLoading}
                        abLevelList={abLevelList}
                        abLevelLoading={abLevelLoading}
                        abLevel={abLevel}
                        dwpLevel={dwpLevel}
                        onChangeAbLevel={onChangeAbLevel}
                        onChangeDwpLevel={onChangeDwpLevel}
                        onOkOrg={onOkOrg}
                        onClickOrg={onClickOrg}
                        orgVisible={orgVisible}
                        onClickChangeIMG={onClickChangeIMG}
                        idPhoto={idPhoto}
                        onChangeMemberPhone={onChangeMemberPhone}
                        onChangeMemberName={onChangeMemberName}
                        fileList={fileList}
                        previewSrc={previewSrc}
                    />
                    {/* 아카데미 없는 ver -- 밑에 탭 안보이게 */}
                    {/* 아카데미 없는 ver -- 밑에 탭 안보이게 */}
                    {
                        abLevel && abLevel !== undefined &&
                        <MemberEditBottom empNum={empNum} />
                    }
                    <div className='member__button_wrap'>
                        <Button
                            onClick={onClickList}
                        >
                            {formatMessage({ id: 'T0094' })}{/* 뒤로 */}
                        </Button>
                        <Button
                            onClick={onClickDeleteMember}
                        >
                            {formatMessage({ id: 'T0105' })}{/* 사용자 삭제 */}
                        </Button>
                        <Button
                            type='primary'
                            onClick={onClickSearch}
                        >
                            {formatMessage({ id: 'T0073' })}{/* 저장 */}
                        </Button>
                    </div>
                </Wrapper>
                <DepartmentList
                    visible={orgVisible}
                    onCancel={() => setOrgVisible(false)}
                    onOk={onOkOrg}
                    defaultData={department}
                />
                <UploadImg
                    visible={fileUploadVisible}
                    onOk={onOkUploadImage}
                    onCancel={onCancelUploadImg}
                    fileList={fileList}
                    setFileList={setFileList}
                    onChange={onChangeImg}
                />
                <Modal
                    centered
                    visible={deleteVisible}
                    width={500}
                    okText={formatMessage({ id: "T0064" })} // 확인
                    title={formatMessage({ id: "T0105" })} // 사용자 삭제 
                    onOk={onOkDeleteMember}
                    onCancel={onCancelDeleteMember}
                >
                    <div>
                        <Text>{formatMessage({ id: "T1234" })}</Text>{/* 정말로 삭제 하시겠습니까? */}
                    </div>
                </Modal>
            </PageTemplate >
    );
}

export default AdminMemberEdit;