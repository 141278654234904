// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_page__wrap {
    position: relative;
    width: 100%;
    /* min-height: inherit; */
    height: 100vh;
    background: #fff;
    overflow: hidden;
}
.error__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    -webkit-user-select: none;
            user-select: none;
}
.error__info_box {
    text-align: center;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 100;
}
.error__code {
    font-size: 200px;
    color: #ff6600;
    font-weight: bold;
    line-height: 1.3;
}
.error__title {
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.error__content {
    padding-top: 18px;
    color: #000;
    font-size: 16px;
    line-height: 1.5;
}
.error__bg_text {
    position: absolute;
    top: 40%;
    left: 50%;
    color: #ff6600;
    font-size: 750px;
    font-weight: bold;
    opacity: 0.04;
    transform: translateX(-50%) translateY(-50%);
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/assets/error.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IACzB,aAAa;IACb,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,OAAO;IACP,SAAS;IACT,WAAW;IACX,yBAAiB;YAAjB,iBAAiB;AACrB;AACA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,4CAA4C;IAC5C,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,WAAW;AACf;AACA;IACI,iBAAiB;IACjB,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,4CAA4C;IAC5C,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".error_page__wrap {\n    position: relative;\n    width: 100%;\n    /* min-height: inherit; */\n    height: 100vh;\n    background: #fff;\n    overflow: hidden;\n}\n.error__footer {\n    position: absolute;\n    left: 0;\n    bottom: 0;\n    width: 100%;\n    user-select: none;\n}\n.error__info_box {\n    text-align: center;\n    position: absolute;\n    top: 45%;\n    left: 50%;\n    transform: translateY(-50%) translateX(-50%);\n    z-index: 100;\n}\n.error__code {\n    font-size: 200px;\n    color: #ff6600;\n    font-weight: bold;\n    line-height: 1.3;\n}\n.error__title {\n    font-size: 20px;\n    font-weight: bold;\n    color: #000;\n}\n.error__content {\n    padding-top: 18px;\n    color: #000;\n    font-size: 16px;\n    line-height: 1.5;\n}\n.error__bg_text {\n    position: absolute;\n    top: 40%;\n    left: 50%;\n    color: #ff6600;\n    font-size: 750px;\n    font-weight: bold;\n    opacity: 0.04;\n    transform: translateX(-50%) translateY(-50%);\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
