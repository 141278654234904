import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
    padding-top: 35px;
    .closed_section {
    min-height: 960px;
    text-align: center;
    vertical-align:middle;
    position:relative;
  }
  // table  
  thead[class*="ant-table-thead"] th {
    background-color: #ff6600 !important;
    color: white;
    font-weight: bold; !important;
    font-size: 12px;
  }
   .ant-table-tbody > tr > td {
    color: white !important;
    background: #000 !important;
  }
  .ant-table-pagination{
    background-color: transparent !important;
  }
  .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
    background: unset !important;
  } 
  .ant-table-placeholder{
    background: black !important;
  } 
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #2f4f4f !important;
  }
  .ant-table-pagination{
    background: black !important;
  }
  .ant-spin-container {
    margin-top:50px;
  } 
  .ant-spin-container .ant-pagination li button,
  .ant-spin-container .ant-pagination li a { 
    background: black;
    color: white !important; 
  }
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
      background: unset;
  }
  //tab

  /* Tab 부분 */
  .stationTab .ant-tabs-nav-scroll {
    background: white;
  }

  .stationTab .ant-tabs-nav .ant-tabs-tab-active {
    font-weight: bold;
    font-color:white;
    border-bottom: 3px solid #ff6600 !important ;
  }
  .stationTab .ant-tabs-nav-wrap {
    border: 1px solid #000000;
  }
  .stationTab .ant-tabs-tab {
    background: black;
    color: white;
    border: 1px solid black !important ;
  }
  .stationTab .ant-tabs-nav-wrap{
    border: 1px solid black !important ;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    background: black;
    font-weight: bold;
    color: white !important ;
    border-color: black;
    border-bottom: 3px solid #ff6600 !important ;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    background: black;
    font-weight: bold;
    font-size: 18px;
    color: gray;
    border: none;
  }
  .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: bold;
    color: white;
  }


  .block__button{
    background: white;
    color: #d0d1d6;
    float:right;
    width:80px;
    height:24px;
}


  .Itemshop_Card_Wrap{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top:100px
  }
  .Itemshop_Card_Content{
    position: relative;
    width:335px;
    height:300px;
    border: 1px solid white;
    border-radius:5px;
    background:white;
   
  }
  .Itemshop_Image{
    height:201px;
    background:#F8F8F8;
    border-bottom:1px solid #D9D9D9;
  }
  .Itemshop_Desc{
    height:100px;
    padding:15px;
   
  }

  .Itemshop_Desc_Title{
    float:left;
    font-size:20px;
    font-weight:bold;
    margin-bottom:1px;
  }
  .Itemshop_Desc_Content{
    float:left;
    font-size:13px;
    font-weight:bold;
    color:#000000;
  }
  .Itemshop_Box_Button{
    width: 80px;
    height: 24px;
    float: right; 
  }

  .productName{
    font-weight:bold;
  }
  .modalContent{
    display: block;
    font-size: 14px;
  }
  // Table
  .itemshop__puchase_history {

  }
`;

export default Wrapper;
