import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  min-height: 960px;
  text-align: center;
  background: black;
  position: relative;
  .search_area p {
    color: #fff;
    letter-spacing: -1px;
  }
  .station__title {
    font-size: 23px;
    font-weight: bold;
  }
  thead[class*="ant-table-thead"] th {
      background-color: #ff6600 !important;
      color: white !important;
      font-weight: bold; !important;
      font-size: 12px !important;
  }
  .ant-table-tbody > tr > td {
    color: white !important;
    background: #000 !important;
  }
  .ant-table-pagination{
    background-color: transparent !important;
  }
  .ant-table-tbody > tr.ant-table-row-level-1:hover > td {
    background: unset !important;
  } 
  .ant-table-placeholder{
    background: black !important;
  } 
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #2f4f4f !important;
  }
  .ant-table-pagination{
    background: black !important;
  }
  .ant-spin-container {
    margin-top:50px;
  } 
  .ant-spin-container .ant-pagination li button,
  .ant-spin-container .ant-pagination li a { 
    background: black;
    color: white !important; 
  }
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
      background: unset;
  }

  .ant-empty-description{
    color:white !important;
  }
  .search_area {
    position: relative;
    margin-top: 30px;
  }
  .search__character {
    position: absolute;
    top: 0;
    left: 0;
  }
  .search__top {
    margin: 0 auto;
    text-align: center;
  }
  .search__bottom {
    // display: inline-block;
    // position: relative;
    // margin: 0 auto;
  } 
  .sword__section { 
    display: flex;
    position: absolute;
    left: 455px;
    gap: 22px;
    padding-left: 10px;
    top: 159px;
  }
  .search__bottom .search__container {    
    display: flex;
    gap: 20px;
    align-items: center;
  } 
  .search__bottom .search__container .title {
    font-weight: bold;
  }    
  .search__bottom .button__section {
    display: flex;
    gap: 5px;
  }
  .search__bottom .search__total__button {  
    border-radius: 15px;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis, .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis{
    color :white !important;
  }
`;

export default Wrapper;
