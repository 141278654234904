import React, { useEffect, useState } from 'react';
import { FormTable } from 'components';
import { useIntl } from 'react-intl';

import fetchAbsolute from "utils/fetchAbsolute";

/**
 *  관리자 > 회원 관리 > 회원 조회 > Academy History 탭
 */

const initialPageInfo = {
    sort: "", // 여기선 sort를 사용하지 않음
    direction: ",desc",
    currentPage: 1,
    rowPerPage: 10,
    total: 0
};

const AcademyTab = ({ empNum }) => {
    const { formatMessage } = useIntl();

    const [historyList, setHistoryList] = useState({});
    const [loading, setLoading] = useState(false);
    const [pageInfo, setPageInfo] = useState({ ...initialPageInfo }); // paging 처리 일단

    useEffect(() => {
        const fetchHistoryList = async () => {
            setLoading(true);
            const result = await fetchAbsolute('get', `/academy/getAcademyHistoryList?empNo=${empNum}&page=${pageInfo.currentPage - 1}&size=${pageInfo.rowPerPage}&sort=${pageInfo.sort}${pageInfo.direction}`);
            setLoading(false);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다. 
            setHistoryList({ ...result.data.academyHistoryList[0] });
            setPageInfo({ ...pageInfo, total: result.data.totalItemsCount });
        }
        fetchHistoryList();
        return () => {
            setLoading(false);
        }
    }, []);

    return (
        <div className='member__academy_tab'>
            <div className='academy_tab__table'>
                <FormTable>
                    <colgroup>
                        <col width='30%' />
                        <col width='70%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{formatMessage({ id: "T0612" })}</th>{/* 최근 교육 */}
                            <td>{historyList.lastEduname} {historyList.lastEducd && `(${historyList.lastEducd})`}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0613" })}</th>{/* 최근 교육 상태 */}
                            <td>{historyList.lastStatus}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0614" })}</th>{/* 최근 교육일	 */}
                            <td>{historyList.lastDate}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0615" })}</th>{/* LV 2 교육 이수 */}
                            <td>{historyList.lev2 === 'N' || !historyList.lev2 ? `${formatMessage({ id: "T0619" })}` : `${formatMessage({ id: "T0620" })} (${historyList.lev2Date})`}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0616" })}</th>{/* LV 3 교육 이수 */}
                            <td>{historyList.lev3 === 'N' || !historyList.lev3 ? `${formatMessage({ id: "T0619" })}` : `${formatMessage({ id: "T0620" })} (${historyList.lev3Date})`}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0617" })}</th>{/* LV 4 교육 이수 */}
                            <td>{historyList.lev4 === 'N' || !historyList.lev4 ? `${formatMessage({ id: "T0619" })}` : `${formatMessage({ id: "T0620" })} (${historyList.lev4Date})`}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0618" })}</th>{/* LV 5 교육 이수 */}
                            <td>{historyList.lev5 === 'N' || !historyList.lev5 ? `${formatMessage({ id: "T0619" })}` : `${formatMessage({ id: "T0620" })} (${historyList.lev5Date})`}</td>
                        </tr>
                        {/* T0619 : 미이수 , T0620 : 이수 */}
                    </tbody>
                </FormTable>
            </div>
        </div>
    )
}

export default AcademyTab;