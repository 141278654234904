import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";
import {
  FormTable,
  Text,
  Button,
  InputSearch,
  Organization,
  DepartmentList,
} from "components";
import { Select, Row, Col } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import { setAdminMemberValue } from "store/adminMember/action";

/**
 * 관리자 > 회원 관리 > Top 검색
 */

const { Option } = Select;

const AdminMemberTop = ({
  onChangeSearchObject,
  onClickSearchButton,
  onClickReset,
}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.get("auth").get("profile"));

  const empNam = formatMessage({ id: "T0012" }); // 이름
  const orgNam = formatMessage({ id: "T0014" }); // 조직명
  const abLevel = formatMessage({ id: "T0015" }); // 역량 등급
  const dwpUserLevel = formatMessage({ id: "T0016" }); // 회원 등급

  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    orgCds: false,
    empNum: false,
  });

  const searchObject = useSelector(
    (state) => state.get(getUniqueKey("adminMember")).inputValues
  );

  const [dwpUserLevelList, setDwpUserLevelList] = useState([]);
  const [dwpUserLoading, setDwpUserLoading] = useState(false);
  const [abLevelList, setAbLevelList] = useState([]);
  const [abLevelLoading, setAbLoading] = useState(false);

  const onChangeAbLevel = (e) => {
    let tmpArr = [];
    for (let i in e) {
      const value = abLevelList.find((status) => status.cd === e[i]);
      tmpArr.push(value);
    }
    onChangeSearchObject("abLevel", [...tmpArr]);
  };
  const onChangeDwpUserLevel = (e) => {
    let tmpArr = [];
    for (let i in e) {
      const value = dwpUserLevelList.find((status) => status.cd === e[i]);
      tmpArr.push(value);
    }
    onChangeSearchObject("dwpUserLevel", [...tmpArr]);
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true,
    });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    dispatch(setAdminMemberValue(modalLabel, data));
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({ ...modalVisible, [modalLabel]: false });
  };

  useEffect(() => {
    const fetchUserLevel = async () => {
      setDwpUserLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/USER_LEVEL`
      );
      setDwpUserLoading(false);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setDwpUserLevelList([...result.data.filter((v) => v.useYn === "Y")]);
    };
    const fetchAbLevel = async () => {
      setAbLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/AB_LEVEL`
      );
      setAbLoading(false);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setAbLevelList([...result.data.filter((v) => v.useYn === "Y")]);
    };
    fetchUserLevel();
    fetchAbLevel();
  }, []);
  return (
    <div>
      <FormTable pointBorder={false} size="sm">
        <colgroup>
          <col width="14%" />
          <col width="19.333%" />
          <col width="14%" />
          <col width="19.333%" />
        </colgroup>
        <tbody>
          <tr>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text>{empNam}</Text>
              {/* 이름 */}
            </th>
            <td>
              <InputSearch
                onClick={onClickModalButton}
                width={250}
                height={32}
                placeholder={empNam}
                data-label="empNum"
                name="empNum"
                value={searchObject.empNum.map((c) => c.empNam).join(", ")}
              />
            </td>
            <th>
              <Text>{orgNam}</Text>
            </th>
            <td>
              <InputSearch
                onClick={onClickModalButton}
                width={250}
                height={32}
                data-label="orgCds"
                name="orgCds"
                placeholder={orgNam}
                value={searchObject.orgCds?.map((c) => c.codeNm).join(", ")}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text>{abLevel}</Text>
              {/* 역량 등급 */}
            </th>
            <td>
              <Select
                style={{
                  width: 250,
                  height: 32,
                }}
                mode="multiple"
                maxTagCount="responsive"
                loading={abLevelLoading}
                onChange={onChangeAbLevel}
                placeholder={abLevel}
                name="abLevel"
                value={searchObject.abLevel.map((level) => level.cd)}
              >
                {abLevelList.map((v) => (
                  <Option key={v.cd} value={v.cd}>
                    {v.cdNm}
                  </Option>
                ))}
              </Select>
            </td>
            <th>
              <Text>{dwpUserLevel}</Text>
              {/* 회원 등급 */}
            </th>
            <td>
              <Select
                mode="multiple"
                maxTagCount="responsive"
                style={{
                  width: 250,
                  height: 32,
                }}
                name="dwpUserLevel"
                defaultValue=""
                loading={dwpUserLoading}
                onChange={onChangeDwpUserLevel}
                placeholder={dwpUserLevel}
                value={searchObject.dwpUserLevel.map((level) => level.cd)}
              >
                {dwpUserLevelList.map((v) => (
                  <Option key={v.cd} value={v.cd}>
                    {v.cdNm}
                  </Option>
                ))}
              </Select>
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Row style={{ padding: "0 13px" }}>
        <Col
          span={12}
          style={{
            padding: 10,
            display: "flex",
          }}
        ></Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0 25px",
            padding: "10px 0",
          }}
        >
          <Button width="120" height="40" onClick={onClickReset}>
            <FormattedMessage id="T0037" />
            {/* 초기화 */}
          </Button>
          <Button
            width="120"
            height="40"
            type="primary"
            onClick={onClickSearchButton}
          >
            <FormattedMessage id="T0039" />
            {/* 검색 */}
          </Button>
        </Col>
      </Row>
      {/* 회원 조회 */}
      <Organization
        visible={modalVisible.empNum}
        companyCd={profile.COMP_CD}
        title={formatMessage({ id: "T1192" })} // 회원 조회
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={searchObject['empNum']}
      />
      {/* 부서 조회 */}
      <DepartmentList
        visible={modalVisible.orgCds}
        companyCd={profile.COMP_CD}
        onOk={onOkModal}
        onCancel={onCancelModal}
        mode="multiple"
        defaultData={searchObject['orgCds']}
      />
    </div>
  );
};

export default AdminMemberTop;
