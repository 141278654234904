import styled from "styled-components";

const Wrapper = styled.div`
  border: 1px solid #d0d1d6;
  border-radius: 10px;
  background: #fff;
  width: 1400;
  min-height: 500px;
  height: fit-content;
  margin-bottom: 10px;
  padding: 30px 51px;
  .posting_title_section {
    display: flex;
    gap: 20px;
  }
  .posting_title_section .secret_check {
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
    font-size: 14px;
    font-weight: bold;
  }
  .button_company {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding-top: 10px;
  }
  .button_company button {
    width: 150px;
    height: 40px;
  }
  button:focus {
    outline: 0;
  } 
`;

export default Wrapper;
