import React, { useState, useEffect } from "react";
import { Button, Text, Table, Loading } from "components";
import { Link, withRouter } from "react-router-dom";
import { Col, Row, Select } from "antd";
import SendEmail from "./SendEmail";
import SendPoint from "./SendPoint";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import fetchAbsolute from "utils/fetchAbsolute";
import XLSX from "xlsx-js-style";

/**
 * 관리자 > 회원 관리 > 회원 관리 목록 > Bottom 테이블
 * SKC와 다르게 회원 등록 기능이 있음
 */

const { Option } = Select;

const AdminMemberBottom = ({
  tableData,
  tableLoading,
  onChangeTableColumns,
  onSelectOption,
  history,
  excelBody,
  pageInfo,
  sortedInfo,
}) => {
  const { formatMessage } = useIntl();

  const fmMessage = useFormatMessage();

  const pageSize = [10, 20, 50, 70];

  const empNam = formatMessage({ id: "T0012" }); //이름
  const empNum = formatMessage({ id: "T0013" }); //사번
  const abLevelNm = formatMessage({ id: "T0015" }); //역량등급
  const dwpUserLevelNm = formatMessage({ id: "T0016" }); //회원등급 
  const companyNm = formatMessage({ id: "T0601" }); // 조직
  const emailAddr = formatMessage({ id: "T0602" }); // 이메일 

  // 선택된 회원의 사번
  const [selectKeys, setSelectKeys] = useState([]);
  const [visibleSendEmail, setVisibleSendEmail] = useState(false);
  const [selectEmailList, setSelectEmailList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleSendPoint, setvisibleSendPoint] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onClickRegisterMember = () => {
    // 회원등록 페이지로 이동
    history.push("/admin/member/manage/register");
  };

  const onClickSendEmail = () => {
    if (selectEmailList.length === 0) {
      return alert(formatMessage({ id: "T0275" }));
      //'이메일을 보낼 사람을 선택해주세요');
    }
    setVisibleSendEmail(true);
  };
  const onOkSendEmail = () => {
    setVisibleSendEmail(false);
  };
  const onCancelSendEmail = () => {
    setVisibleSendEmail(false);
  };

  const onClickSendPoint = () => {
    if (selectKeys.length === 0) {
      return alert(formatMessage({ id: "T0276" }));
      //'포인트를 지급할 사람을 선택해주세요');
    }
    setvisibleSendPoint(true);
  };
  const onOkSendPoint = () => {
    setvisibleSendPoint(false);
  };
  const onCancelSendPoint = () => {
    setvisibleSendPoint(false);
  };

  const fetchExcelData = async () => {
    setButtonDisabled(true);
    setLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/common/memberListWithPageMulValExcel`,
      {
        data: {
          ...excelBody,
        },
      }
    );

    setLoading(false);
    setButtonDisabled(false);
    if (result.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    }

    let isSkipHeader = true;

    const { data } = result;
    let rows = [[dwpUserLevelNm, companyNm, empNam, empNum, emailAddr]];

    if (data.length !== 0) {
      // 데이터가 있으면 skipHeader가 false 여야해서
      isSkipHeader = false;
      rows = data.map((data) => {
        return {
          [dwpUserLevelNm]: data.dwpUserLevelNm, // 회원 등급
          [companyNm]: data.companyNm, // 조직
          [empNam]: data.empNam, // 이름
          [empNum]: data.empNum, // 사번
          [emailAddr]: data.emailAddr, // 이메일
        };
      });
    }
    const worksheet = XLSX.utils.json_to_sheet(rows, {
      skipHeader: isSkipHeader,
    });
    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    /* css */
    for (let i in worksheet) {
      if (typeof worksheet[i] !== "object") continue;
      /* header만 굵은 글씨 & 가운데 정렬 */
      worksheet[i].s = {
        font: {
          name: "Malgun Gothic",
          sz: 11,
        },
      };
      if (i.includes("1") && i.length === 2) {
        worksheet[i].s = {
          font: {
            name: "Malgun Gothic",
            sz: 11,
            bold: true,
          },
          alignment: {
            wrapText: true,
            vertical: "center",
            horizontal: "center",
          },
        };
      }
    }
    if (!worksheet["!cols"]) worksheet["!cols"] = [];
    let cellsWidth = Array.from({ length: 5 }, () => ({ width: 20 })); // length는 header 컬럼 만큼
    worksheet["!cols"].push(...cellsWidth);

    XLSX.writeFile(workbook, "Member List.xlsx", { compression: true });
  };

  const rowSelection = {
    selectedRowKeys: selectKeys,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setSelectKeys([]);
        setSelectEmailList([]);
      }
    },
    onChange: (selectedRowKeys) => {
      const tmpEmailList = [];
      selectedRowKeys.forEach((v) => {
        const email = tableData.find((j) => j.empNum === v)?.emailAddr;
        tmpEmailList.push(email);
      });
      setSelectEmailList([...tmpEmailList.filter((v) => !!v)]);
      setSelectKeys([...selectedRowKeys]);
    },
  };

  const columns = [
    {
      key: "abLevelNm",
      title: abLevelNm,
      dataIndex: "abLevelNm",
      dataSort: "abLevelNm",
      sortOrder: sortedInfo.columnKey === 'abLevelNm' && sortedInfo.order,
      sorter: (record) => record.abLevelNm,
      render: (text, record) => (
        <Link to={`/admin/member/${record.empNum}`}>{text}</Link>
      ),
    },
    {
      key: "dwpUserLevelNm",
      title: dwpUserLevelNm,
      dataIndex: "dwpUserLevelNm",
      dataSort: "dwpUserLevelNm",
      sortOrder: sortedInfo.columnKey === 'dwpUserLevelNm' && sortedInfo.order,
      sorter: (record) => record.dwpUserLevelNm,
      render: (text, record) => (
        <Link to={`/admin/member/${record.empNum}`}>{text}</Link>
      ),
    },
    {
      key: "orgNam",
      title: formatMessage({ id: "T0601" }), //조직
      dataIndex: "orgNam",
      dataSort: "orgNam",
      sortOrder: sortedInfo.columnKey === 'orgNam' && sortedInfo.order,
      sorter: (record) => record.orgNam,
      render: (text, record) => (
        <Link to={`/admin/member/${record.empNum}`}>{text}</Link>
      ),
    },
    {
      key: "empNam",
      title: empNam,
      dataIndex: "empNam",
      dataSort: "empNam",
      sortOrder: sortedInfo.columnKey === 'empNam' && sortedInfo.order,
      sorter: (record) => record.empNam,
      render: (text, record) => (
        <Link to={`/admin/member/${record.empNum}`}>{text}</Link>
      ),
    },
    {
      key: "empNum",
      title: empNum,
      dataIndex: "empNum",
      sorter: (record) => record.empNum,
      sortOrder: sortedInfo.columnKey === 'empNum' && sortedInfo.order,
      render: (text, record) => (
        <Link to={`/admin/member/${record.empNum}`}>{text}</Link>
      ),
    },
    {
      key: "emailAddr",
      title: formatMessage({ id: "T0602" }), //이메일
      dataIndex: "emailAddr",
      dataSort: "emailAddr",
      sortOrder: sortedInfo.columnKey === 'emailAddr' && sortedInfo.order,
      sorter: (record) => record.emailAddr,
      render: (text, record) => (
        <Link to={`/admin/member/${record.empNum}`}>{text}</Link>
      ),
    },
  ];

  useEffect(() => {
    setSelectKeys([]);
    setSelectEmailList([]);
  }, [tableData]);

  return (
    <div className="admin_member__table_wrap">
      <SendEmail
        visible={visibleSendEmail}
        onOk={onOkSendEmail}
        onCancel={onCancelSendEmail}
        emailList={selectEmailList}
      />
      <SendPoint
        visible={visibleSendPoint}
        onOk={onOkSendPoint}
        onCancel={onCancelSendPoint}
        empNumList={selectKeys}
      />

      <Row gutter={[8, 24]} className="align-end">
        <Col span={6}>
          <span>{/*  페이지당 행 */}
            {formatMessage({ id: "T0036" })} &emsp;
            <Select
              name="pagination"
              defaultValue={pageSize[0]}
              value={+pageInfo.size}
              onSelect={onSelectOption}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                  {/* {number} 개  */}
                </Option>
              ))}
            </Select>
            <Text style={{ fontWeight: "bold", fontSize: 18 }}>
              &nbsp;&nbsp;Total {pageInfo.total.toLocaleString()}
              &nbsp;
              {formatMessage({ id: "T0433" })} {/* 명 */}
            </Text>
          </span>
        </Col>
        <Col offset={7} span={11} className="admin_member__button_group">
          <Button
            width="100"
            height="32"
            type="primary"
            onClick={onClickRegisterMember}
          >
            <FormattedMessage id="T1280" />
            {/* 회원 등록 */}
          </Button>
          <Button
            width="100"
            height="32"
            type="primary"
            onClick={onClickSendEmail}
          >
            <FormattedMessage id="T0042" />
            {/* 이메일 발송 */}
          </Button>
          <Button
            width="100"
            height="32"
            type="primary"
            onClick={onClickSendPoint}
          >
            <FormattedMessage id="T0043" />
            {/* 포인트 지급 */}
          </Button>
          <Button
            onClick={fetchExcelData}
            disabled={buttonDisabled}
            width="100"
            height="32"
          >
            <FormattedMessage id="T0041" />
            {/* 엑셀 다운로드 */}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {loading && (
            <Loading
              visible={loading}
              height={"none"}
              minHeight={"none"}
              position={"none"}
              className="loading_assignment_excel"
            />
          )}
          <Table
            rowKey={(item) => item.empNum}
            columns={columns}
            dataSource={tableData}
            loading={tableLoading}
            pagination={true}
            defaultPageSize={20}
            pageSizeVisible={false}
            showSorterTooltip={true}
            rowSelection={rowSelection}
            onChange={onChangeTableColumns}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.page,
              pageSize: +pageInfo.size,
            }}
          />
        </Col>
      </Row>
      <a download="memberList.xls" href="#!" id="memberListExcel"></a>
    </div>
  );
};

export default withRouter(AdminMemberBottom);
