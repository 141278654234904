import { START_LOAD_DATA, LOAD_PERMISSION_SUCCESS, LOAD_PERMISSION_FAIL, SET_TASK_INFO } from './action';

/**
 * 권한 구현을 위한 State
 * 
 */

const permissionInitialState = {
    user: [],
    task: [],
    taskId: '',
    taskType: '',
    userLoading: true,
    taskLoading: false
};
 
const permission = (state = permissionInitialState, action) => {
    switch(action.type) {
        case START_LOAD_DATA:
            let lKey = 'userLoading';
            if(action.divi === 'task') {
                lKey = 'taskLoading';
            };
            return {
                ...state,
                [lKey]: true,
            };
        case LOAD_PERMISSION_SUCCESS:
            let data = [...action.data];
            let loadingKey = 'userLoading';
            if(action.divi === 'task') {
                loadingKey = 'taskLoading';
                data = data.concat(...new Set(state.user.map(v => v.fnCode)));
            };
            return {
                ...state,
                [action.divi]: data,
                [loadingKey]: false,
            };
        case LOAD_PERMISSION_FAIL: 
            return {
                ...state,
                loading: false
            };
        case SET_TASK_INFO:
            return {
                ...state,
                taskId: action.taskId,
                taskType: action.taskType
            };
        default: 
            return state;
        
    };
};
   
export default permission;
