import styled from "styled-components";

const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;

  /* 개인화 영역*/
  .user__image:first-child {
    z-index: 0;
  }
  .personal_container {
    display: flex;
    height: 100%;
    flex-direction: column; 
  }
  .personal_area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .personal_box_title__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .personal_box__container {
    display: flex;
    flex-direction: column;
    border: 1px solid #d0d1d6;
    padding: 16.9px 16.6px;
    background: #f8f8f9;
    gap: 29px;
    width: 424.9px;
  }
  .personal_todo__title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
  }
  .personal_todo__title_left {
    display: flex;
    align-items: flex-end;
    gap: 4px;
  }
  .personal__title {
    font-size: 20px;
    font-weight: bold;
    color: rgb(0 0 0 / 87%);
  }
  .personal__title_info {
    font-size: 14px;
    color: #060606;
    opacity: 0.87;
  }
  .personal__total { 
    font-size: 12px;
    color: #060606;
    opacity: 0.87;
  }
  /* 개인화 영역 - personal box */
  .personal_box__title {
    font-weight: bold;
    font-size: 13px;
  }
  .personal_box__top_part {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .todo_title__container {
    display: flex;
    justify-content: space-between;
  }
  .todo__content {
    word-break: break-all;
  }
  .personal_box__button {
    background: inherit;
    border: none;
    font-size: 12px;
    color: #979797;
    width: fit-content;
    height: fit-content;
  }
  .personal_box__content {
    font-size: 16px;
  }
  .challenging__container {
    display: flex;
    justify-content: flex-start;
  }
  .challenging_content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .user_thumbnail {
    display: flex;
  }
  .challenging__text {
    font-size: 12px;
    color: #000;
    opacity: 0.87;
  }
  .personal_box_leture__button {
    background: inherit;
    border: none;
    color: f000000;
    opacity: 0.3;
    font-weight: bold;
    font-size: 14px;
    height: 20px;
  }
  .personal_body_container {
    position: relative;
    overflow: hidden;
    padding: 0 20px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: -2px;
    height:1430px;
    width: 466px;
    border: 2px solid #D2D2D7;
  }
  .personal_body_contents {
    z-index: 10;
    position: inherit;
  }
  .personal_body_contents .container {
    padding: 11.9px 0 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .personal_body_contents__second {
    z-index: 10;
    position: inherit;
    padding-right: 5px;
    overflow-y: auto; 
    min-height: 368px;
    max-height: calc(100% - 80.67px);
  }
  .personal_body_contents__second::-webkit-scrollbar {
    width: 4px;
  }
  .personal_body_contents__second::-webkit-scrollbar-track {
    background: #e4e4e4;
  }
  .personal_body_contents__second::-webkit-scrollbar-thumb {
    background: #a8a9ad;
    border-radius: 2px;
  }
  .personal_todo_title {
    display: flex;
    justify-content: space-between;
  }
  .todo__container {
    display: flex;
    flex-direction: column;
    border: 1px solid #d0d1d6;
    padding:15px 15px;
    gap: 8px;
    margin-bottom: 8px;
  }
  .todo_date__text {
    font-size: 14px;
  }
`;

export default Wrapper;
