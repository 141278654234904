import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import Wrapper from "./Styled";
import { Table, Tabs, Button } from "components";
import { Tabs as AntTabs, Tooltip } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

const { TabPane } = AntTabs;

/**
 * 내 과제 목록
 *
 */

const MyTaskList = ({ history }) => {
  const { formatMessage } = useIntl();

  const taskNm = formatMessage({ id: "T0002" }); // 과제명
  const taskType = formatMessage({ id: "T0003" }); // 과제 유형

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: 'T0001' }), // 과제 ID
      dataIndex: "taskId",
      width: 80,
      render: (taskId) => {
        return (
          <Link to={`/assign/lookup/${taskId}`}>
            <div>{taskId}</div>
          </Link>
        );
      },
    },
    {
      key: 2,
      title: taskNm,
      dataIndex: "taskNm",
      width: 120,
      render: (name, data) => {
        return (
          <Link to={`/assign/lookup/${data.taskId}`}>
            <Tooltip title={name}>
              <span>{name}</span>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      key: 3,
      title: formatMessage({ id: 'T0394' }), // 등록일
      dataIndex: "regTimestamp",
      width: 80,
      render: (time, data) => {
        return (
          <Link to={`/assign/lookup/${data.taskId}`}>
            <div>{time}</div>
          </Link>
        );
      },
    },
    {
      key: 4,
      title: taskType,
      dataIndex: "taskType",
      width: 60,
      render: (taskType, data) => {
        return (
          <Link to={`/assign/lookup/${data.taskId}`}>
            <div>{data.taskTypeDesc || taskType}</div>
          </Link>
        );
      },
    },
  ];

  const [datas, setDatas] = useState([]);
  const [tabsValue, setTabsValue] = useState(1);
  const [total, setTotal] = useState({
    receipt: "0",
    developing: "0",
    testing: "0",
    operating: "0",
  });
  const [tableLoading, setTableLoading] = useState(false);

  const items = [
    {
      key: 1,
      title: `${formatMessage({ id: 'T0395' })} (${total.receipt})`, // 접수
      statusCode: "T10",
    },
    {
      key: 2,
      title: `${formatMessage({ id: 'T0396' })} (${total.developing})`, // 개발
      statusCode: "T20",
    },
    {
      key: 3,
      title: `${formatMessage({ id: 'T0397' })} (${total.testing})`, // 테스트
      statusCode: "T30",
    },
    {
      key: 4,
      title: `${formatMessage({ id: 'T0398' })} (${total.operating})`, // 운영
      statusCode: "T40",
    },
  ];

  const onChangeTab = (tabsValue) => setTabsValue(tabsValue);

  const setTableDatas = (tabsValue) => {
    const getMyAssignment = async (statusCd) => {
      try {
        setTableLoading(true);
        const result = await fetchAbsolute(
          "get",
          `/task/oracle/myTaskList?statusCd=${statusCd}&rowPerPage=5`
        );
        setTableLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        setDatas(
          result.data.list.map((v) => ({
            ...v,
            regTimestamp: moment(v.regTimestamp).format("YYYY-MM-DD"),
          }))
        );
        setTotal({
          ...total,
          receipt: result.data.receiptCount,
          developing: result.data.developmentCount,
          testing: result.data.testCount,
          operating: result.data.operationCount,
        });
      } catch (err) {
        return alert(err);
      }
    };
    switch (+tabsValue) {
      case 1:
        getMyAssignment("T10");
        break;
      case 2:
        getMyAssignment("T20");
        break;
      case 3:
        getMyAssignment("T30");
        break;
      case 4:
        getMyAssignment("T40");
        break;
      default:
        break;
    }
  };

  const onClickMoreButton = () => {
    history.push("/assign/list?tab=myTask");
  };

  useEffect(() => {
    setTableDatas(tabsValue);
  }, [tabsValue]);

  return (
    <Wrapper>
      <div className="common__container">
        <div className="common__div">
          <div className="common__title"> {formatMessage({ id: 'T0385' })}</div>{/* 내 과제 목록 */}
          <Button className="float_display__button" onClick={onClickMoreButton}>
            {formatMessage({ id: 'T0096' })}{/* 더보기 */}
            <RightOutlined />
          </Button>
        </div>
        <Tabs
          defaultActiveKey={tabsValue}
          activeKey={tabsValue}
          onChange={onChangeTab}
        >
          {items.map((item) => {
            return <TabPane tab={item.title} key={item.key} />;
          })}
        </Tabs>
        <Table
          rowKey={(item) => item.taskId}
          columns={columns}
          dataSource={datas}
          paginaton="false"
          defaultPageSize={5}
          loading={tableLoading}
          locale={{ emptyText: `${formatMessage({ id: 'T0399' })}` }} // 아직 등록한 과제가 없습니다.
        />
      </div>
    </Wrapper>
  );
};

export default withRouter(MyTaskList);