import React from "react";
import { Table as AntTable } from "antd";
import styled from "styled-components";
import { Spin } from 'components';
/*
    border: 1px solid rgb(220, 221, 225);
    border-radius: 20px;
*/
const TableStyled = styled(AntTable)`
  tr {
    position: relative;
    overflow: hidden;
  }
  td.ant-table-cell:last-child {
    padding-left: 0;
    padding-right: 0;
    position: relative;
  }
  .ant-table-thead > tr > th {
    height: 40px;
    padding-top: 0;
    padding-bottom: 0;
  } 
  
   ${props => props.type === 'default' && `
   .ant-table-thead > tr > th:first-child {
      background: #fff;
      border: none;
      color: #000;
    }
    .ant-table-thead > tr > th:not(:first-child) {
    position: relative;
    background: #eaeaed;
    border-top: 1px solid #d0d1d6;
    border-right: none !important;
   }
  .ant-table-thead > tr > th:not(:first-child)::after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 50%;
    width: 1px;
    height: 26px;
    background: rgba(0, 0, 0, 0.3);
    transform: translateY(-50%);
   }
  `} 
  ${props => props.type === 'workflow' && `
    .ant-table-thead > tr > th {
      position: relative;
      background: #eaeaed;
      border-top: 1px solid #d0d1d6;
      border-right: none !important;
    }
    .ant-table-thead > tr > th::after {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      top: 50%;
      width: 1px;
      height: 26px;
      background: rgba(0, 0, 0, 0.3);
      transform: translateY(-50%);
    }
    `
  }  
  .ant-table-thead > tr > th:nth-last-child(1)::after {
      display: none;
  }
  td.ant-table-cell,
  .ant-table-row td {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
  }
  .ant-table {
    width: 100%;
  }
  .ant-spin-container {
    margin: 15px;
  }
  .ant-spin-nested-loading {
    border: 1px solid rgb(220, 221, 225);
    border-radius: 20px;
  }
  .ant-table-content {
    overflow: auto;
  }
`;

const TimeTable = ({
  children,
  pagination = false,
  defaultPageSize = 20,
  pageOptions = {},
  pageSizeVisible = true,
  align = "center",
  type = 'default',
  ...args
}) => {
  return (
    <TableStyled
      pagination={false}
      align={align}
      type={type}
      pageSizeVisible={pageSizeVisible}
      {...args}
      loading={{ indicator: <div><Spin tip="Loading..." /></div>, spinning: !!args.loading }}
    >
      {children}
    </TableStyled>
  );
};

export default TimeTable;