import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  .ant-tabs-card .ant-tabs-content {
    margin-top: -17px;
  }
  .ant-tabs-nav-list {
    gap: 10px;
  }
  .ant-tabs-nav-list .ant-tabs-tab,
  .ant-tabs-nav .ant-tabs-tab {
    width: 180px;
    text-align: center;
    background: #eaeaed;
  }
  .ant-tabs-nav-list .ant-tabs-tab:hover,
  .ant-tabs-nav .ant-tabs-tab:hover {
    color: #000;
  }
  .ant-tabs-tab.ant-tabs-tab-active {
    background: #fff !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 6px 6px 0 0;
    border: 1px solid #d0d1d6;
    font-size: 16px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: white !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    height: 40px;
    color: #000;
    background: #fff;
    border-bottom: none !important;
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0px 10px 0 0;
    background: #eaeaed;
    border: 1px solid #d0d1d6;
    border-radius: 10px 10px 0 0;
  }
  .operation_box {
    border: 1px solid #d0d1d6;
    background: #fff;
    padding: 20px 0;
  }
  .operation__top_menu {
    display: flex;
    align-items: center;
    gap: 0 30px;
    padding: 0 46px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eaeaed;
  }
  .operation__top_menu .top_menu__box {
    display: flex;
    gap: 0 20px;
    align-items: center;
    padding: 9px 32px;
    border-radius: 25px;
    background: #f8f8f8;
  }
  .operation__top_menu .top_menu__box .top_menu__text {
    font-size: 13px;
    font-weight: bold;
  }

  .operation__content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 12px 47px;
  }
  .operation__content .operation__content_box.content__bot_status {
    flex: 2;
  }
  .operation__content .operation__content_box.content__fail_case {
    flex: 3;
  }
  .operation__content .operation__content_box.content__bot_run {
    width: 100%;
  }
  .operation__content_box .content__title {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .operation__content_box .content__title .box {
    width: 7px;
    height: 23px;
    background: #59617f 0% 0% no-repeat padding-box;
  }
  .operation__content_box .content__title > span {
    font-size: inherit;
  }
  .operation__content_box.content__chart_box .content__main {
    background: #f8f8f8;
    padding: 14px 16px;
  }
  .operation__content_box.content__chart_box .content__main > div {
    width: 100%;
    height: 100%;
  }
  .content__main {
    margin-top: 20px;
    border: 1px solid #d0d1d6;
    min-height: 175px;
    // height: 315px;
  }
  .content__main .ant-table-thead > tr > th,
  .content__main .ant-table-tbody > tr > td {
    height: 38px;
    padding: 0 9px;
    color: #000;
  }
  .failed__table .ant-table {
    overflow-y: auto;
    max-height: 428px;
    overflow-y: scroll;
  }
  .bot_perform__table .ant-table {
    height: 315px;
    overflow-y: scroll;
  }
  .operation__content .operation__content_box.content__type_status {
    width: 100%;
  }
  .operation__content .operation__content_box.content__prod_ratio {
    flex: 2;
  }
  .operation__content .operation__content_box.content__prod_time {
    flex: 3;
  }

  .operation_note__box {
    display: flex;
    justify-content: space-around;
    gap: 5px;
  }

  .operation_botstatus__result_wrap {
    display: inline-flex;
    gap: 3px;
    align-items: center; 
  }
  .operation_botstatus__result_wrap span {
    font-size: 12px;
    color: #707070;
  }

  /* 과제 운영 모니터링 > Bot 수행 내역 아이콘 css */
  .abled_icons path.monitoring1,
  .abled_icons path.monitoring2,
  .abled_icons path#패스_10258,
  .abled_icons path#패스_10255,
  .abled_icons path#패스_10113,
  .abled_icons path#패스_10114,
  .abled_icons line#선_491,
  .abled_icons line#선_492,
  .abled_icons path#패스_10112,
  .abled_icons rect#사각형_5861 {
    stroke: #000;
  }
  .abled_icons path.monitoring2 {
    fill: #000;
  }
`;

export default Wrapper;
