import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const pdfDownload = (pdfName, pageHeight = 650, pageWidth = 900, margin = 60) => {
    const graphElements = document.getElementsByClassName("dashboard_graph");
    const graphWrapper = document.getElementsByClassName("dashboard_graph_wrapper");
    const titles = document.getElementsByClassName("main__title");

    /* 그래프 제목 순서대로 가져오기 */
    let getElementsId = [];
    for (let i = 0; i < titles.length; i++) {
        getElementsId.push(titles[i])
    };

    if (!graphElements) {
        console.error("Element with className 'dashboard_graph' not found!");
        return;
    };

    // Promise.all을 사용하여 각각의 html2canvas 프로미스가 모두 resolve될 때까지 대기
    Promise.all(Array.from(graphElements).map((graphElement) => {
        const wrapper = document.createElement('div');
        wrapper.className = 'dashboard_graph_wrapper';
        if (graphElement.style.minWidth) {
            // 조직별 비교 때문에
            wrapper.style.width = graphElement.style.minWidth
        } else {
            wrapper.style.width = graphElement.style.width || wrapper.style.width
        }
        wrapper.style.height = graphElement.style.height + 5 || wrapper.style.height
        wrapper.style.padding = "50px 100px 50px 0px";
        graphElement.parentNode.insertBefore(wrapper, graphElement);
        wrapper.appendChild(graphElement);
        return html2canvas(wrapper, {
            scale: 1,
            useCORS: true,
            border: false, // 테두리 제거 설정   
            backgroundColor: null, // 테두리 없애는데 화질은 낮아짐
        });
    })).then((canvases) => {
        // 이미지를 저장할 배열을 생성
        const imgDataArray = [];

        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'pt',
            format: [842, 595], // A4 용지 크기  
        });

        // html2canvas로부터 생성된 각각의 캔버스에 대해 처리
        canvases.forEach((canvas, index) => {
            // 이미지의 너비와 높이
            const imgWidth = canvas.width + pageWidth - (canvas.width % pageWidth);
            const imgHeight = canvas.height + pageHeight - (canvas.height % pageHeight);

            // 페이지를 나눌 x, y 위치 초기화 
            let xPos = 0;
            let yPos = 0;

            // canvas를 여러 페이지로 분할하여 imgDataArray에 저장 
            while (yPos < imgHeight) {
                const newCanvas = document.createElement('canvas');
                const context = newCanvas.getContext('2d');
                newCanvas.width = pageWidth / 1.15;
                newCanvas.height = pageHeight / 1.15;

                // 다음 페이지에서 잘린 부분만 취득
                const sourceX = xPos;
                const sourceY = yPos;
                const sourceWidth = (imgWidth - sourceX > pageWidth ? pageWidth : imgWidth - sourceX);
                const sourceHeight = (imgHeight - sourceY > pageHeight ? pageHeight : imgHeight - sourceY);

                // 잘린 부분 그리기
                context.drawImage(canvas, sourceX, sourceY, sourceWidth, sourceHeight - 50, 0, 0, pageWidth / 1.15, pageHeight / 1.15);
                // 캔버스에 텍스트 그리기
                context.font = 'bold 14px Nanum Gothic';
                context.textAlign = 'center ';

                if (xPos === 0 && yPos === 0) context.fillText(getElementsId[index]?.innerText, 0, 20);

                // imgDataArray에 이미지 데이터 추가
                imgDataArray.push(newCanvas.toDataURL('image/png', 1.0));

                yPos += sourceHeight;

                if (yPos >= imgHeight) {
                    xPos += pageWidth;
                    if (xPos >= imgWidth) {
                        break;
                    }
                    yPos = 0;
                }
            }
        });

        // imgDataArray에 저장된 이미지를 pdf에 추가
        imgDataArray.forEach((imgData, index) => {
            if (index !== 0) {
                pdf.addPage();
            }
            // addImage(imageData, format, x, y, width, height, alias, compression, rotation)
            pdf.addImage(imgData, 'png', margin, margin, pageWidth / 1.15 - (2 * margin), pageHeight / 1.15 - (2 * margin), null, 'FAST');
            // pdf.text(`page ${index + 1}`, margin, margin - 10);
        });
        pdf.save(pdfName);
    }).catch(error => {
        console.error(error);
    });
    for (let i = 0; i < graphWrapper.length; i++) {
        graphWrapper[i].style.padding = "0px";
    };
};

export default pdfDownload;
