import React, { useEffect, useState } from "react";
import { Table, Text, Input, Button, FormTable, Modal } from "components";
import { Select } from "antd";
import styled from "styled-components";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import { useDispatch } from "react-redux";
import { setVisible } from "store/loading/action";
import { FormattedMessage, useIntl } from "react-intl";
const { Option } = Select;

/**
 * Filename: AdminDeviceBox/index.js
 * Description: Device Pool Add -> Device Pool 추가
 */

const Wrapper = styled.div`
  .ant-table-thead th {
    height: 34px;
    padding: 0px 16px;
    font-size: 12px;
  }
  .ant-table-tbody td {
    font-size: 12px;
  }
  .system_wrap {
    display: inline-flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
  .system_select_button {
    width: 26px;
    height: 26px;
  }
  .system_delete_button {
    width: 26px;
    height: 26px;
  }
  .system_wrap .system_box:first-child {
    float: left;

    height: 100%;
  }
  .system_wrap .system_box:last-child {
    float: right;

    height: 100%;
  }
  .system_wrap .system_box .system_box__title {
    font-size: 14px;
    font-weight: bold;
    padding-bottom: 15px;
    height: 32px;
  }
  .system_wrap .system_box .system_box__content {
  }

  .system_wrap
    .system_box
    .system_box__content
    .ant-tabs-card
    .ant-tabs-content {
    margin-top: -16px;
  }

  .system_content__header {
    display: flex;
    height: 32px;
    justify-content: space-between;
    align-items: center;
  }
  .system_section__table {
    margin-top: 10px;
  }
  .system_section__table .ant-spin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px 0;
  }

  .select_reset_button {
    width: 80px;
    height: 27px;
    font-size: 14px;
  }
  .div_add_delete_button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex: auto;
    margin: auto 0;
    gap: 20px;
  }
`;

const AvailableBot = ({
  dataSource,
  rowSelection,
  onChangeSearch,
  avaSearch,
}) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: <FormattedMessage id="T1111" />, // Status
      dataIndex: "status",
      key: "status",
      width: "42%",
      align: "center",
    },
    {
      title: <FormattedMessage id="T1096" />, // Device Name
      dataIndex: "hostName",
      key: "hostName",
      width: "35%",
      align: "center",
    },
    {
      title: <FormattedMessage id="T1060" />, // Device Pool
      dataIndex: "poolName",
      key: "poolName",
      width: "23%",
      align: "center",
    },
  ];
  return (
    <div className="system_content__section">
      <Input
        placeholder={formatMessage({ id: "T0687" })} // 검색하세요
        style={{ height: 32 }}
        onChange={onChangeSearch}
        value={avaSearch}
      />
      <div className="system_section__table">
        <Table
          columns={columns}
          dataSource={dataSource}
          rowSelection={rowSelection}
          scroll={{ y: 400 }}
          rowKey={(e) => e.id}
        />
      </div>
    </div>
  );
};

const SelectedBot = ({
  rowSelection,
  selectDatas,
  selectedSearch,
  onChangeSelectedSearch,
}) => {
  const { formatMessage } = useIntl();

  const columns = [
    {
      title: <FormattedMessage id="T1111" />, // Status
      dataIndex: "status",
      key: "status",
      width: "42%",
      align: "center",
    },
    {
      title: <FormattedMessage id="T1096" />, // Device Name
      dataIndex: "hostName",
      key: "hostName",
      width: "35%",
      align: "center",
    },
    {
      title: <FormattedMessage id="T1060" />, // Device Pool
      dataIndex: "poolName",
      key: "poolName",
      width: "23%",
      align: "center",
    },
  ];
  return (
    <div className="system_content__section">
      <Input
        placeholder={formatMessage({ id: "T0687" })} // 검색하세요
        onChange={onChangeSelectedSearch}
        value={selectedSearch}
        style={{ height: 32 }}
      />
      <div className="system_section__table">
        <Table
          columns={columns}
          dataSource={selectDatas}
          rowSelection={rowSelection}
          scroll={{ y: 400 }}
          rowKey={(e) => e.id}
        />
      </div>
    </div>
  );
};
const DeviceUpdateBox = ({ devicePoolName, crType, history, crCode }) => {
  const dispatch = useDispatch();

  const { formatMessage } = useIntl();

  const [devicePoolErr, setDevicePoolErr] = useState(false);
  const [delCheck, setDelCheck] = useState([]);
  const [selectDatas, setSelectDatas] = useState([]);
  const [checkedDatas, setCehckedDatas] = useState([]);
  const [checkStatus, setCheckStatus] = useState(true);
  const [selectCheckStatus, setSelectCheckStatus] = useState(true);
  const [systemDatas, setSystemDatas] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [avaSearch, setAvaSearch] = useState("");
  const [selectedSearch, setSelectedSearch] = useState("");
  const [PoolVisible, setPoolVisible] = useState(false);
  const [systemSelDatas, setSystemSelDatas] = useState([]);
  const [devicePool, setDevicePool] = useState([]);
  const [originSelected, setOriginSelected] = useState([]);
  const [originDataSoruce, setOriginDataSource] = useState([]);
  const [poolGroupList, setPoolGroupList] = useState([]);
  const [selectPoolGroup, setSelectPoolGroup] = useState("");
  const [inputValues, setInputValues] = useState({
    poolName: "",
    poolDescription: "",
  });

  const onOkDelete = async () => {
    //체크한 row poolname으로 조회후 정보 가져오기

    let fetchFlag = true;
    try {
      const deleteDevicePool = await fetchAbsolute(
        "delete",
        `/admin/devicePool/deleteDevicePool?&devicePoolName=${devicePool[0].devicePoolName}&crCode=${crCode}`
      );
      if (deleteDevicePool.status === 200 || deleteDevicePool.status === 201) {
        alert(formatMessage({ id: "T0139" })); // Device Pool이 삭제되었습니다.
        history.push("/admin/devicepool");
        return;
      } else {
        throw new Error(deleteDevicePool.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0140" })); // Device Pool 삭제에 실패하였습니다.
      }
    }
    setPoolVisible(false);
  };

  const onCancelDelete = () => setPoolVisible(false);

  const onClicPoolDeleteButton = () => {
    if (devicePool[0].crType === "COE") {
      if (devicePool[0].devicePoolGroupCd.length !== 0) {
        if (!devicePoolErr) {
          return alert(formatMessage({ id: "T0141" })); // Device Pool Group 설정을 해제 한 후 삭제 할 수 있습니다
        }
      }
    }
    setPoolVisible(true);
  };
  const onChangeSelectedSearch = (e) => {
    const { value } = e.target;
    setSelectedSearch(value);
  };
  const onChangeAvaSearch = (e) => {
    setAvaSearch(e.target.value);
  };

  const onClickResetButton = () => {
    setDataSource([...originDataSoruce]);
    setSelectDatas([...originSelected]);
  };

  const onChangeInputValue = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onChangePoolGroup = (e) => {
    setSelectPoolGroup(e);
  };

  // 선택 버튼 클릭 시 insert하는 이벤트
  const onClickSelectButton = () => {
    const tmpArr = [...selectDatas];
    checkedDatas.forEach((v) => {
      tmpArr.push(dataSource.find((e) => e.id === v));
    });
    const filterDataSource = dataSource.filter(
      (v) => !checkedDatas.includes(v.id)
    );
    setDataSource([...filterDataSource]);
    setSelectDatas([...tmpArr]);
    setCehckedDatas([]);
    setSelectCheckStatus(true);
    setCheckStatus(true);
  };

  // 선택 버튼 클릭 시 delete 이벤트
  const onClickPoolDeleteButton = (e) => {
    //선택 값으로 넘어 온 것들 temparr 에 담는다
    const tempArr = [...selectDatas];
    const temp = tempArr.filter((x) => !checkedDatas.includes(x.id));
    checkedDatas.forEach((v) => {
      dataSource.push(tempArr.find((ele) => ele.id === v));
    });
    setDelCheck([...checkedDatas]);
    setDataSource([...dataSource]);
    setSelectDatas([...temp]);
    setCehckedDatas([]);
    setSelectCheckStatus(true);
    setCheckStatus(true);
  };
  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCehckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCehckedDatas(selectedRowKeys);

      if (selectedRowKeys.length !== 0) {
        setCheckStatus(false);
        setSelectCheckStatus(true);
      } else {
        setCheckStatus(true);
      }
    },
    getCheckboxProps: (record) => {
      const delId = delCheck.find((e) => e === record.id);
      return {
        disabled:
          record.poolName.length > 0
            ? record.id === delId
              ? false
              : true
            : false,
      };
    },
  };

  const rowSelectionSelected = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCehckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCehckedDatas(selectedRowKeys);

      if (selectedRowKeys.length !== 0) {
        setSelectCheckStatus(false);
        setCheckStatus(true);
      } else {
        setSelectCheckStatus(true);
      }
    },
  };

  const PoolGroupSelect = () => (
    <Select
      style={{ width: 320, height: 40 }}
      name="poolgroupselect"
      placeholder={formatMessage({ id: "T0688" })} // 선택해주세요
      onChange={onChangePoolGroup}
      value={selectPoolGroup || undefined}
    >
      <Option value="">{formatMessage({ id: "T0688" })}</Option>{/* 선택해주세요 */}
      {poolGroupList.map(
        (e) =>
          e.useYn === "Y" && (
            <Option key={e.cdNm} value={e.cd}>
              {e.cdNm}
            </Option>
          )
      )}
    </Select>
  );

  const onClickUpdate = async () => {
    if (
      inputValues.poolDescription === "" ||
      inputValues.poolName === "" ||
      selectDatas.length === 0
    ) {
      if (selectDatas.length === 0) {
        alert(formatMessage({ id: "T0134" })); // 선택 봇을 넣어주세요.
        return;
      }
      if (inputValues.poolDescription === "" || inputValues.poolName === "") {
        alert(formatMessage({ id: "T0025" })); // 검색어를 입력하세요
        return;
      }
    }

    let fetchFlag = true;

    try {
      const result = await fetchAbsolute(
        "put",
        `/admin/devicePool/modifyDevicePool`,
        {
          data: {
            crType: devicePool[0].crType,
            crCode: devicePool[0].crCode,
            devicePoolGroupCd: selectPoolGroup,
            devicePoolName: inputValues.poolName,
            description: inputValues.poolDescription,
            devices: selectDatas.map((e) => e.hostName),
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (result.status === 200 || result.status === 201) {
        alert(formatMessage({ id: "T0142" })); // Device Pool이 수정 되었습니다.

        history.push("/admin/devicepool");
      } else {
        throw new Error(result.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(formatMessage({ id: "T0143" })); // Device Pool 수정에 실패하였습니다.
      }
    }
  };

  useEffect(() => {
    setSystemDatas([...dataSource]);
  }, [dataSource]);

  useEffect(() => {
    // 전체 데이터 중에서 searchKeyword를 포함하고 있는 데이터들을 추출
    const filterDatas = [
      ...dataSource.filter((data) => {
        return (
          data.status?.toLowerCase().includes(avaSearch.toLowerCase()) ||
          data.hostName?.toLowerCase().includes(avaSearch.toLowerCase()) ||
          data.poolName?.toLowerCase().includes(avaSearch.toLowerCase())
        );
      }),
    ];
    setSystemDatas([...filterDatas]);
  }, [avaSearch]);

  useEffect(() => {
    // 선택된 데이터 중에서 searchKeyword를 포함하고 있는 데이터들을 추출
    const filterSelDatas = [
      ...selectDatas.filter((data) => {
        return (
          data.status?.toLowerCase().includes(selectedSearch.toLowerCase()) ||
          data.hostName?.toLowerCase().includes(selectedSearch.toLowerCase()) ||
          data.poolName?.toLowerCase().includes(selectedSearch.toLowerCase())
        );
      }),
    ];
    setSystemSelDatas([...filterSelDatas]);
  }, [selectedSearch]);

  useEffect(() => {
    setSystemSelDatas([...selectDatas]);
  }, [selectDatas]);

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setVisible(true));
      const result = await fetchAbsolute(
        "get",
        `/admin/devicePool/getDevicePool?devicePoolName=${devicePoolName}&crCode=${crCode}`
      );
      const poolGroupResult = await fetchAbsolute(
        "get",
        "/admin/commonMaster/getDevicePoolList"
      );
      dispatch(setVisible(false));
      if (!poolGroupResult.error) {
        setPoolGroupList([...poolGroupResult.data]);
      }
      if (result.data.message !== "devicePoolName not exist") {
        if (JSON.stringify(result.data) !== "{}") {
          setDevicePool([result.data.devicePool]);
          setSelectPoolGroup(result.data.devicePool.devicePoolGroupCd);

          if (!Object.keys(result.data).includes("deviceList")) {
            alert(
              `해당 Device Pool이 Control Room에 존재하지 않습니다.\n삭제 후 다시 등록해주세요.`
            );
            setDevicePoolErr(true);
          } else {
            setDataSource([...result.data.deviceList]);
            setSelectDatas([...result.data.deviceDetails]);
            //초기화 하기 위해 값 담기
            setOriginSelected([...result.data.deviceDetails]);
            setOriginDataSource([...result.data.deviceList]);
          }
        }
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    try {
      setInputValues({
        poolName: devicePool[0].devicePoolName,
        poolDescription: devicePool[0].description,
      });
    } catch (e) { }
  }, [devicePool]);

  return (
    <div>
      <FormTable pointBorder={false} size="sm">
        <colgroup>
          <col width="13.85%" />
          <col width="36.08%" />
          <col width="13.85%" />
          <col width="36.08%" />
        </colgroup>
        {devicePool.map((e, index) => (
          <tbody key={index}>
            <tr>
              <th>
                <Text>
                  &nbsp;
                  <FormattedMessage id="T1036" />
                  &nbsp;
                </Text>
              </th>
              <td>
                {e.crTypeDesc?.length > 0 ? (
                  e.crCode.includes("DEV") ? (
                    <Text>
                      &nbsp;
                      {e.crTypeDesc + ` ${formatMessage({ id: "T0682" })}`}{/* (개발) */}
                      &nbsp;
                    </Text>
                  ) : (
                    <Text>
                      &nbsp;
                      {e.crTypeDesc + ` ${formatMessage({ id: "T0683" })}`}{/* (운영) */}
                      &nbsp;
                    </Text>
                  )
                ) : e.crCode.includes("DEV") ? (
                  <Text>
                    &nbsp;{crType + ` ${formatMessage({ id: "T0682" })}`}&nbsp;
                  </Text>// (개발)
                ) : (
                  <Text>
                    &nbsp;{crType + ` ${formatMessage({ id: "T0683" })}`}&nbsp;
                  </Text>// (운영)
                )}
              </td>
              <th>
                {e.crType === "DIY" ? (
                  <Text>
                    <FormattedMessage id="T1042" />{/* Device Pool ID */}
                  </Text>
                ) : (
                  <Text>{formatMessage({ id: "T0727" })}</Text>// Device Pool Group
                )}
              </th>
              <td>
                {e.crType === "DIY" ? (
                  <Text>&nbsp;{e.devicePoolId}</Text>
                ) : (
                  <PoolGroupSelect />
                )}
              </td>
            </tr>

            <tr>
              <th>
                {e.crType === "DIY" ? (
                  <Text>
                    <FormattedMessage id="T1061" />{/* Device Pool Name */}
                  </Text>
                ) : (
                  <Text>
                    <FormattedMessage id="T1042" />{/* Device Pool ID */}
                  </Text>
                )}
              </th>
              <td>
                {e.crType === "DIY" ? (
                  <Text>&nbsp;{inputValues.poolName}</Text>
                ) : (
                  <Text>&nbsp;{e.devicePoolId}</Text>
                )}
              </td>
              <th>
                <Text>
                  <FormattedMessage id="T1062" />{/* Device Pool Description */}
                </Text>
              </th>
              <td>
                <Input
                  name="poolDescription"
                  onChange={onChangeInputValue}
                  value={inputValues.poolDescription}
                  style={{
                    width: 320,
                    marginRight: 20,
                  }}
                />
              </td>
            </tr>
            {e.crType === "COE" && (
              <tr>
                <th>
                  <Text>
                    <FormattedMessage id="T1061" />{/* Device Pool Name */}
                  </Text>
                </th>
                <td>
                  <Text>&nbsp;{inputValues.poolName}</Text>
                </td>
              </tr>
            )}

            <tr>
              <th style={{ borderRadius: "14px 0 0 0" }}>
                <Text>
                  <FormattedMessage id="T0355" />{/* Device */}
                </Text>
              </th>
              <td colSpan="4">
                <Wrapper>
                  <div className="system_wrap">
                    <div className="system_box">
                      <div className="system_box__title">
                        <Text>{formatMessage({ id: "T0689" })}</Text>{/* 가용 Bot Runner */}
                      </div>
                      <div className="system_box__content">
                        <AvailableBot
                          rowSelection={rowSelection}
                          dataSource={systemDatas}
                          onChangeSearch={onChangeAvaSearch}
                          avaSearch={avaSearch}
                        />
                      </div>
                    </div>
                    <div className="div_add_delete_button">
                      <Button
                        className="system_select_button"
                        type="primary"
                        onClick={onClickSelectButton}
                        disabled={selectCheckStatus === false && true}
                      >
                        &gt;
                      </Button>

                      <Button
                        className="system_delete_button"
                        type="primary"
                        onClick={onClickPoolDeleteButton}
                        disabled={checkStatus === false && true}
                      >
                        &lt;
                      </Button>
                    </div>
                    <div className="system_box">
                      <div className="system_box">
                        <div style={{ display: "flex" }}>
                          <div
                            style={{ marginRight: "auto" }}
                            className="system_box__title"
                          >
                            <Text>{formatMessage({ id: "T0690" })}</Text>{/* 선택 Bot Runner */}
                          </div>
                          <div style={{ display: "flex" }}>
                            <Button
                              style={{ marginLeft: "auto" }}
                              className="select_reset_button"
                              onClick={onClickResetButton}
                            >
                              <FormattedMessage id="T0037" />
                              {/* 초기화 */}
                            </Button>
                          </div>
                        </div>
                        <div className="system_box__content">
                          <SelectedBot
                            selectDatas={systemSelDatas}
                            rowSelection={rowSelectionSelected}
                            onChangeSelectedSearch={onChangeSelectedSearch}
                            selectedSearch={selectedSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal
                    width={540}
                    visible={PoolVisible}
                    title={formatMessage({ id: "T0684" })} // Device Pool 삭제
                    okText={formatMessage({ id: "T0072" })} // 삭제
                    onCancel={onCancelDelete}
                    onOk={onOkDelete}
                  >
                    <Text
                      style={{
                        display: "block",
                        fontSize: 14,
                      }}
                    >
                      {formatMessage({ id: "T0685" })}{/* 정말로 선택된 Device Pool을 삭제하시겠습니까? */}
                    </Text>
                    <Text
                      style={{
                        display: "block",
                        fontSize: 14,
                        color: "rgba(0, 0, 0, 0.6)",
                      }}
                    >
                      {formatMessage({ id: "T0686" })}{/* ※ 스케줄이 설정된 Device Pool을 삭제 할 경우 문제가 될 수 있습니다. */}
                    </Text>
                  </Modal>
                </Wrapper>
              </td>
            </tr>
          </tbody>
        ))}
      </FormTable>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 20,
          background: "white",
        }}
      >
        <Button width="144" height="40" type="primary" onClick={onClickUpdate}>
          <FormattedMessage id="T0054" />
          {/* 수정 */}
        </Button>
        &nbsp; &nbsp;
        <Button
          width="144"
          height="40"
          type="primary"
          onClick={onClicPoolDeleteButton}
        >
          {formatMessage({ id: "T0072" })}
          {/* 삭제 */}
        </Button>
        &nbsp; &nbsp;
        <Link to="/admin/devicepool">
          <Button width="144" height="40">
            <FormattedMessage id="T0051" />
            {/* 취소 */}
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default DeviceUpdateBox;
