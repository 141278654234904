import React, { useState, useEffect } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Button, Text } from "components";
import { Modal as AntModal, Switch } from "antd";
import styled from "styled-components";
import { SquareIcon } from "icons";
import { useIntl } from "react-intl";

/**
 * 메인화면 설정 모달
 * Main 화면 설정
 */

const ModalStyled = styled(AntModal)`
  .ant-modal-header {
    border-top: 7px solid #ff7b00;
  }
  .ant-modal-title {
    font-weight: bold;
  }
  .setting_modal__container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #b9b9b9;
    padding: 10px 10px;
    margin-top: 10px;
  }
  ul.setting_modal_wrapper {
    padding-left: unset;
  }
  .setting_modal__content span {
    padding-right: 10px;
    font-size: 14px;
  }
  .ant-switch-checked {
    background-color: #ff7b00;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
`;

const CustomDragHandle = styled(SquareIcon)`
  cursor: pointer !important;
`;

const DragHandle = sortableHandle(() => <CustomDragHandle />);

const SortableItem = sortableElement(({ items, onChange }) => (
  <li className="setting_modal__container">
    <div className="setting_modal__content">
      <DragHandle />
      <Text>{items.title}</Text>
    </div>
    <Switch
      defaultChecked
      checked={items.visible}
      onChange={(value) => onChange(items.key, value)}
    />
  </li>
));

const SortableContainer = sortableContainer(({ children }) => {
  return <ul className="setting_modal_wrapper">{children}</ul>;
});

const SettingMainModal = ({
  visible = false,
  title = "",
  onOk = () => { },
  onCancel = () => { },
  onReset = () => { },
  defaultComponent,
  width = 1400,
  profile,
  ...args
}) => {
  const { formatMessage } = useIntl();

  const [mainComponents, setMainComponents] = useState([]);

  const onChangeSwitch = (label, value) => {
    const tmpArr = [...mainComponents];
    const index = tmpArr.findIndex((v) => v.key === label);
    const tmpObj = { ...tmpArr[index] };
    tmpObj.visible = value;
    tmpArr[index] = tmpObj;
    setMainComponents([...tmpArr]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const tmpArr = [...mainComponents];
    const tmpData = tmpArr[oldIndex];
    tmpArr[oldIndex] = tmpArr[newIndex];
    tmpArr[newIndex] = tmpData;
    setMainComponents([...tmpArr]);
  };

  const onOkModal = () => onOk(mainComponents);

  useEffect(() => {
    setMainComponents([...defaultComponent]);
  }, [visible]);

  return (
    <ModalStyled
      width={width}
      visible={visible}
      title={title}
      onOk={onOkModal}
      onCancel={onCancel}
      onReset={onReset}
      footer={[
        <Button key="submit" type="primary" onClick={onOkModal}>
          {formatMessage({ id: "T0073" })}
          {/* 저장 */}
        </Button>,
        <Button key="reset" onClick={onReset}>
          {formatMessage({ id: "T0037" })}
          {/* 초기화 */}
        </Button>,
        <Button key="back" onClick={onCancel}>
          {formatMessage({ id: "T0051" })}{/* 취소 */}
        </Button>,
      ]}
      {...args}
    >
      <label>
        {formatMessage({ id: "T0423" })}
        {/* 원하는 정보를 선택 드래그하여 대시보드 표시 순서를 조절할 수 있습니다. */}
      </label>
      <SortableContainer onSortEnd={onSortEnd} useDragHandle>
        {mainComponents.map((items, index) => {
          return (
            <SortableItem
              key={index}
              items={items}
              index={index}
              onChange={onChangeSwitch}
            />
          );
        })}
      </SortableContainer>
    </ModalStyled>
  );
};

export default SettingMainModal;
