import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  PageTemplate, Header, Footer, Input, Button, Table, Text, Collapse, FormTable,
} from 'components';
import { CloseCollapseIcon, OpenCollapseIcon } from 'icons';
import { Select, Collapse as AntCollapse, Row, Col } from "antd";
import Wrapper from './Styled';
import fetchAbsolute from "utils/fetchAbsolute";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

/**
 * Filename: AdminCoeAssignManagement/index.js
 * Description: coe 즉시 실행 관리
*/
const { Option } = Select;
const { Panel } = AntCollapse;

const initialPageInfo = {
  sort: "taskId",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};
const initialInputValues = {
  taskId: "",
  userExeYn: "",
  taskNm: "",
};

const AdminCoeAssignManagement = ({ history }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  // const [tableDataSource, setTableDataSource] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  // const [total, setTotal] = useState(0);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const [dataSource, setDataSource] = useState([]);
  const pageSize = [20, 50, 70];
  const selectRowPerPage = formatMessage({ id: "T0036" }); // 페이지당 행
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [sortedInfo, setSortedInfo] = useState({});


  const onClickAdd = () => {
    history.push("/admin/coe/assignmanage/create");
  };

  const getDataSource = async (searchValues, page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    if (searchValues === undefined) {
      searchValues = { ...inputValues };
    }
    const result = await fetchAbsolute(
      "post",
      `/admin/commonMaster/taskMainCoeList?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`, {
      data: {
        taskId: searchValues.taskId || [],
        taskNm: searchValues.taskNm,
        userExeYn: searchValues.userExeYn || []
      }
    }

    );
    setDataSource([...result.data.list]);  // 여기랑 column 수정해야함 500 에러뜨는 이유가 api 가아님
    // setPageInfo(...page, result.data.listCount);
    setPageInfo({ ...page, total: +result.data.totalItemsCount });

  };

  const columns = [
    {
      key: "taskId",
      title: formatMessage({ id: 'T0868' }),  // CoE 과제 ID
      dataIndex: "taskId",
      align: "center",
      width: "30%",
      render: (text, record) => (
        <Link to={`/admin/coe/assignmanage/modify/${record.taskId}`}>{text}</Link>
      ),
    },
    {
      key: "taskNm",
      title: formatMessage({ id: 'T0869' }), // CoE 과제명
      dataIndex: "taskNm",
      align: "center",
      width: "50%",
      render: (text, record) => (
        <Link to={`/admin/coe/assignmanage/modify/${record.taskId}`}>{text}</Link>
      ),
    },
    {
      key: "userExeYn",
      title: formatMessage({ id: 'T0870' }), // 즉시 실행 가능
      dataIndex: "userExeYn",
      align: "center",
      width: "20%",
      render: (text, record) => (
        <Link to={`/admin/coe/assignmanage/modify/${record.taskId}`}>{text}</Link>
      ),
    },
  ];
  // const onRow = (record, rowIndex) => {
  //   return {
  //     onClick: () => {
  //       history.push(`/admin/coe/assignmanage/modify/${record.taskId}`);
  //     },
  //   };
  // };
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };
  const onClickSearch = async () => {
    getDataSource(inputValues, initialPageInfo);
  };
  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };
  const onClickReset = () => {
    setInputValues({ ...initialInputValues });
    getDataSource({ ...initialInputValues });
  };
  const onChangeSearchInput = (e) => {
    const { name, value } = e.target;
    if (name === "taskId") {
      setInputValues({ ...inputValues, taskId: [value] });
    } else if (name === "taskNm") {
      setInputValues({ ...inputValues, taskNm: value });
    }
  };
  const onChangeSelectType = (e) => {

    setInputValues({ ...inputValues, userExeYn: [e] });

  }
  const onChangePageSize = (e) => {
    getDataSource(inputValues, { ...pageInfo, rowPerPage: +e, currentPage: 1 });
  };

  const onChangeTableData = (pagination, filters, sorter) => {
    const order = sorter.field ? sorter.field : 'taskId';
    let orderBy = "";
    if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (!sorter.order || sorter.order?.includes("asc")) orderBy = ",asc";

    const searchObj = {
      ...pageInfo,
      order: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    getDataSource(inputValues, searchObj);
  };
  // const onChangeUserExeYn = (e) => {
  //   let userExeYnList = e;
  //   if (typeof e === "object") {
  //     userExeYnList = e.target.value;
  //   }
  //   setInputValues({ ...inputValues, userExeYn: userExeYnList });
  // };
  // const onChangeUserExeYn = (e) => {
  //       // setShowYnList([...result.data?.filter((v) => v.showYn === "Y")])

  // }
  useEffect(() => {
    getDataSource();
  }, []);
  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      <Wrapper style={{ padding: "30px 0" }}>
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <FormTable pointBorder={false} size="sm">
              <colgroup>
                <col width="10.375%" />
                <col width="13.625%" />
                <col width="10.375%" />
                <col width="13.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ borderRadius: "14px 0 0 0" }}>
                    <Text>{formatMessage({ id: "T0868" })}</Text>{/* CoE 과제 ID*/}
                  </th>
                  <td>
                    <Input
                      placeholder={formatMessage({ id: "T0868" })} // CoE 과제 ID
                      name='taskId'
                      onKeyPress={onKeyPress}
                      value={inputValues.taskId}
                      onChange={onChangeSearchInput}
                      style={{ width: 300 }}
                    />
                  </td>
                  <th><Text>{formatMessage({ id: "T0869" })}</Text></th> {/* CoE 과제명*/}
                  <td>
                    <Input
                      placeholder={formatMessage({ id: "T0869" })} // CoE 과제명
                      name='taskNm'
                      onKeyPress={onKeyPress}
                      value={inputValues.taskNm}
                      onChange={onChangeSearchInput}
                      style={{ width: 300 }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0870" })}</Text>{/* 즉시 실행 가능 */}
                  </th>
                  <td>
                    <Select
                      name='userExeYn'
                      style={{ width: 300 }}
                      onChange={onChangeSelectType}
                      value={inputValues.userExeYn}
                      placeholder={formatMessage({ id: "T0870" })} // 즉시 실행 가능
                      defaultValue={""}
                    >
                      <Option value="">{"All"}</Option>
                      <Option value="Y">{"Y"}</Option>
                      <Option value="N">{"N"}</Option>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <Row style={{ padding: "0 13px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0 25px",
                  padding: "10px 0",
                }}
              >
                <Button width="120" height="40" onClick={onClickReset}>
                  <FormattedMessage id="T0037" />
                  {/* 초기화 */}
                </Button>
                <Button
                  width="120"
                  height="40"
                  type="primary"
                  onClick={onClickSearch}
                >
                  <FormattedMessage id="T0039" />
                  {/* 검색 */}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div className="CoE_manage__top">
          <div style={{ alignSelf: "center" }}>
            <span>
              {selectRowPerPage} &emsp;
              <Select
                style={{
                  width: 89,
                  height: 32,
                }}
                name="pagination"
                onSelect={onChangePageSize}
                value={pageInfo.rowPerPage}
              >
                {pageSize.map((v, index) => (
                  <Option key={index} value={v}>
                    {fmMessage({ id: "T1228", values: { number: v } })}
                  </Option>
                ))}
              </Select>
            </span>
          </div>
          <div className="admin_coemanage_addButton">
            <Button type="primary" style={{ width: 150, height: 32 }} onClick={onClickAdd}>
              <FormattedMessage id="T0049" />{/* 추가 */}
            </Button>
          </div>
        </div>
        <div className="admin_coemanage__table_wrap">
          <Table
            pagination={true}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.rowPerPage,
            }}
            onChange={onChangeTableData}
            loading={tableLoading}
            rowKey={(item) => item.taskId}
            columns={columns}
            dataSource={dataSource}
          ></Table>
        </div>
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminCoeAssignManagement;
