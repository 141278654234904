import React from 'react';
import styled from 'styled-components';
import { Modal } from 'components';

const Wrapper = styled(props => <Modal {...props} />)`
    ${props => props.footerDisabled &&
        `
            .ant-modal-footer {
                display: none;
            }
        `
    }
    ${props => props.footerDisabled &&
        `
            .ant-modal-body {
                border-top: 6px solid #ff8800;
            }
        `
    }
    .request_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 20px 0;
    }
    .request_box__text {
        font-size: 16px;
        font-weight: bold;
    }
    .bpd_request_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .bpd_request_box__text {
        font-size: 16px;
        font-weight: bold;
    }
    .btn_container {
        display: flex;
        gap: 10px;
        justify-content: end;
    }
`;

const LinkModal = ({ children, ...args }) => {
    return (
        <Wrapper
            {...args}
        >
            {children}
        </Wrapper>
    )
}

export default LinkModal;