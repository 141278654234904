import { getUniqueKey } from 'utils/Str';

export const SET_BOXWOODCONNECTOR_VALUE = getUniqueKey('SET_BOXWOODCONNECTOR_VALUE');
export const SET_BOXWOODCONNECTOR_PAGEINFO = getUniqueKey('SET_BOXWOODCONNECTOR_PAGEINFO');
export const SET_BOXWOODCONNECTOR_SPREAD = getUniqueKey('SET_BOXWOODCONNECTOR_SPREAD');
export const RESET_BOXWOODCONNECTOR_VALUE = getUniqueKey('RESET_BOXWOODCONNECTOR_VALUE');

export function setBoxwoodConnectorValue(key, value) {
    return {
        type: SET_BOXWOODCONNECTOR_VALUE,
        key,
        value
    };
}

export function setBoxwoodConnectorPageInfo(data) {
    return {
        type: SET_BOXWOODCONNECTOR_PAGEINFO,
        data
    };
}

export function setBoxwoodConnectorSpread(data) {
    return {
        type: SET_BOXWOODCONNECTOR_SPREAD,
        data
    }
}

export function resetBoxwoodConnectorValue() {
    return {
        type: RESET_BOXWOODCONNECTOR_VALUE
    }
} 