import { LOAD_HEADER_NOTILIST, LOAD_SIDE_NOTILIST } from "./action";

/**
 * Breadcrumb 구현을 위한 reducer
 * 
 */

const notiListinitailState = {
    headerNoti: [],
    personalAreaNoti: []
}

const notiList = (state = notiListinitailState, action) => {
    switch (action.type) {
        case LOAD_HEADER_NOTILIST:
            const { headerNoti } = action;
            return {
                ...state,
                headerNoti
            }
        case LOAD_SIDE_NOTILIST:
            const { personalAreaNoti } = action;
            return {
                ...state,
                personalAreaNoti
            }
        default:
            return state;
    }
}

export default notiList;