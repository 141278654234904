import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  .top__container {
    display: flex;
    gap: 19px;
    padding-bottom: 20px;
  }
  .top_each__section {
    display: flex;
    gap: 15px;
    align-items: center;
  }
`;

export default Wrapper;