import { SET_BOARD_CHECK } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const boardCheckInitial = [];

const boardCheckInfo = (state = boardCheckInitial, action) => {
    switch(action.type) {
        case SET_BOARD_CHECK:
            return [...action.data];
        default: 
            return state;
        
    };
};
   
export default boardCheckInfo;