import React from 'react';

/**
 * width가 1600픽셀인 Container 생성
 * 각각의 스타일 적용을 위해 props에 style을 객체 형식으로 받아 스타일 적용
 */

const Container = ({ style, children, ...args }) => {
    return (
        <div style={{width:1400, margin:'0 auto', ...style}} {...args}>
            {children}
        </div>
    )
};

export default Container;