import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setVisible } from "store/loading/action";
import { getUniqueKey } from "utils/Str";
import sendNoti from "utils/sendNoti";
import fetchAbsolute from "utils/fetchAbsolute";
import { Text, Button, UserTag, PermissionComponent, Modal } from "components";
import { Row, Col, Input as AntInput } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { changeHistoryData } from "store/assignLookup/action";
import { useIntl, FormattedMessage } from "react-intl";

import moment from "moment";

const { TextArea } = AntInput;

/**
 * Filename: LookupInspection.js
 * Description: 과제 조회 페이지 하단에 기본 정보 탭 중 4. [테스트] 개발 검수 컴포넌트
 */

const LookupInspection = () => {
  const { formatMessage } = useIntl();

  const profile = useSelector(state => state.get('auth').get('profile'));
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const assignLookup = useSelector((state) =>
    state.get(getUniqueKey("assignLookup"))
  );

  const selectCr =
    crInfo.find(
      (v) =>
        v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "DEV"
    ) || {};
    
  const inputComment = formatMessage({ id: "T0154" }); // 코멘트를 입력해주세요.
  const workFail = formatMessage({ id: "T0155" }); // 작업에 실패하였습니다.
  const inputMessage = formatMessage({ id: "T0156" }); // 메세지를 입력해주세요.

  const dispatch = useDispatch();

  const [isCommentVisible, setIsCommentVisible] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  const [testResultValue, setTestResultValue] = useState("");
  const [commentDatas, setCommentDatas] = useState([]);

  const onClickDeleteComment = async (index) => {
    const deleteComment = await fetchAbsolute(
      "delete",
      `/task/oracle/removeTaskTestResult?taskId=${commentDatas[index].taskId}&resultSeq=${commentDatas[index].resultSeq}`
    );
    if (deleteComment.error) {
      return alert(formatMessage({ id: "T0247" })); // 삭제에 실패하였습니다.
    }
    const tmpDatas = [...commentDatas];
    tmpDatas.splice(index, 1);
    setCommentDatas([...tmpDatas.filter((idx) => idx !== index)]);
  };

  const onCancelComment = () => setIsCommentVisible(false);
  const onChangeComment = (e) => setCommentValue(e.target.value);

  // 테스트 완료 버튼 클릭 이벤트
  const onClickFinishTestButton = () => setIsCommentVisible(true);

  // 테스트 완료 모달 OK 클릭 이벤트
  const onOkComment = async () => {
    if (commentValue.trim().length === 0) {
      setCommentValue("");
      return alert(inputMessage);
    }
    dispatch(setVisible(true));

    let parameter = {
      taskId: assignLookup.taskData?.taskId,
      chgCd: "T30",
      chgCdDetail: "T32",
      chgEmpNo: profile.EMP_NO,
      chgDetailDesc: `${formatMessage({ id: "T0062" })}`, // 테스트 완료
    };

    /* 개발 CR이 없는 경우에는 운영 상태(T40)로 전환 */

    if (Object.keys(selectCr).length === 0) {
      parameter = {
        taskId: assignLookup.taskData?.taskId,
        chgCd: "T40",
        chgCdDetail: "T41",
        chgEmpNo: profile.EMP_NO,
        chgDetailDesc: `${formatMessage({ id: "T0067" })}`, // 운영 배포
      };
    }

    const changeStatus = await fetchAbsolute("post", "/task/oracle/chgTask", {
      data: {
        ...parameter,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await fetchAbsolute(
      "post",
      "/task/oracle/addTaskTestResult",
      {
        data: {
          taskId: assignLookup.taskData?.taskId,
          testResultDesc: commentValue,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (changeStatus.error || result.error) {
      alert(workFail);
      return dispatch(setVisible(false));
    }

    const empResult = await fetchAbsolute(
      "get",
      `/common/memberNoList?empNo=${result.data.testEmpNo}`
    );
    const tmpComment = {
      ...result.data,
      ...empResult.data.mdFPAList[0],
      regTimestamp: moment(result.data.regTimestamp).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };
    if (result.data.testResultDesc) {
      setCommentDatas(commentDatas.concat({ ...tmpComment }));
    } else {
      alert(workFail);
      return dispatch(setVisible(false));
    }

    dispatch(setVisible(false));
    sendNoti("TP09", assignLookup.taskData?.taskId);
    dispatch(changeHistoryData(changeStatus.data));
    setIsCommentVisible(false);
  };

  // 테스트 코멘트 등록 버튼 클릭 이벤트
  const onClickTestCommentButton = async () => {
    if (testResultValue.trim().length === 0) {
      alert(inputComment);
      setTestResultValue("");
      return;
    }

    dispatch(setVisible(true));

    const result = await fetchAbsolute(
      "post",
      "/task/oracle/addTaskTestResult",
      {
        data: {
          taskId: assignLookup.taskData?.taskId,
          testResultDesc: testResultValue,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (result.error) {
      alert(workFail);
      return dispatch(setVisible(false));
    }
    const empResult = await fetchAbsolute(
      "get",
      `/common/memberNoList?empNo=${result.data.testEmpNo}`
    );
    const tmpComment = {
      ...result.data,
      ...empResult.data.mdFPAList[0],
      regTimestamp: moment(result.data.regTimestamp).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    };

    if (result.data.testResultDesc) {
      setCommentDatas(commentDatas.concat(tmpComment));
    } else {
      alert(workFail);
      return dispatch(setVisible(false));
    }

    setTestResultValue("");
    dispatch(setVisible(false));
  };

  const testResultComment = (e) => setTestResultValue(e.target.value);

  useEffect(() => {
    /* 테스트 결과 코멘트 목록 및 DWP 파일 첨부 목록 불러오기 */
    if (!assignLookup.taskData?.taskId) {
      return;
    }
    const getComments = async () => {
      const result = await fetchAbsolute(
        "get",
        `/task/oracle/taskTestResultList/${assignLookup.taskData?.taskId}`
      );
      for (let i = 0; i < result.data.length; i++) {
        const empResult = await fetchAbsolute(
          "get",
          `/common/memberNoList?empNo=${result.data[i].testEmpNo}`
        );
        result.data[i] = {
          ...result.data[i],
          ...empResult.data.mdFPAList[0],
          regTimestamp: moment(result.data[i].regTimestamp).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
        };
      }
      setCommentDatas(result.data);
    };
    getComments();
  }, [assignLookup.taskData?.taskId]);

      // 테스트 결과 댓글 삭제 모달
    const [commentDelVisible, setCommentDelVisible] = useState(false);
    const [commentDelData, setCommentDelData] = useState();
  
    const onOpenCommentDel  = (index) => {
      setCommentDelData(index);
      setCommentDelVisible(true);
    }

    const onOKCommentDel = () => {
      onClickDeleteComment(commentDelData);
      setCommentDelVisible(false);
    }

    const onCancelCommentDel = () => setCommentDelVisible(false);

  return (
    <>
      <Modal
        width={540}
        visible={isCommentVisible}
        title={formatMessage({ id: "T0489" })} // 테스트 결과 입력
        okText={formatMessage({ id: "T0066" })} // 입력
        onCancel={onCancelComment}
        onOk={onOkComment}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
            paddingBottom: 10,
          }}
        >
          {formatMessage({ id: "T0490" })}{/* 테스트 결과 */}
        </Text>
        <TextArea
          onChange={onChangeComment}
          value={commentValue}
          maxLength={500}
          style={{ height: "100px" }}
          placeholder={formatMessage({ id: "T0489" })} // 테스트 결과 입력 
        />
      </Modal>
      <Modal
        centered
        visible={commentDelVisible}
        width={500}
        onText={formatMessage({ id: "T0064" })} // 확인
        title={formatMessage({ id: "T1661" })} // 코멘트 삭제
        onOk={onOKCommentDel}
        onCancel={onCancelCommentDel}
      >
        <div>
          {/* 작성한 코멘트를 삭제하시겠습니까? */}
          {formatMessage({ id: 'T1662' })}
        </div> 
      </Modal>
      {assignLookup.taskData?.statusCd === "T30" &&
        assignLookup.taskData?.detailStatusCd === "T31" ? (
        <div className="basic_box">
          <div className="basic_box__title">
            <Text>{formatMessage({ id: "T0521" })}</Text>{/* 4. [테스트] 개발 검수 */}
          </div>
          <div className="basic_box__info">
            <Row>
              <Col span={5}>
                <Text className="basic_row__title">
                  {formatMessage({ id: "T0490" })}{/* 테스트 결과 */}
                </Text>
              </Col>
              <PermissionComponent fnCode="FN13">
                <Col span={14}>
                  <TextArea
                    style={{
                      width: 580,
                    }}
                    placeholder={formatMessage({ id: "T0522" })} // 테스트 결과를 입력해주세요
                    allowClear
                    value={testResultValue}
                    onChange={testResultComment}
                    // onPressEnter={onPressResult}
                    maxLength={500}
                    rows={2}
                  />
                </Col>
              </PermissionComponent>
              <PermissionComponent fnCode="FN13">
                <Col span={5} className="flex justify-end">
                  <Button
                    type="default"
                    height="54"
                    width="220"
                    onClick={onClickTestCommentButton}
                  >
                    <FormattedMessage id="T0050" />{/* 등록 */}
                  </Button>
                </Col>
              </PermissionComponent>
            </Row>
            <div>
              {commentDatas.sort().map((comment, index) => {
                return (
                  <Row key={index} className="continue_comment__container">
                    <div className="user_info">
                      <UserTag
                        key={index}
                        profile={{
                          NAME_KOR: comment.empNam,
                          EMP_NO: comment.empNum,
                          DEPT_NAME_KOR: comment.orgNam,
                          FILE_ID: comment.fileId,
                        }}
                        contextVisible={true}
                      />
                    </div>
                    <div className="user_comment">
                      <Text>{comment.testResultDesc.split("\n").map((data, index) => {
                        return (
                          <span key={index}>
                            {data}<br />
                          </span>
                        )
                      })}</Text>
                    </div>
                    <div className="comment_time">
                      <Text>{comment.regTimestamp}</Text>
                      <PermissionComponent fnCode="FN13">
                        <DeleteOutlined
                          onClick={() => onOpenCommentDel(index)}
                        />
                      </PermissionComponent>
                    </div>
                  </Row>
                );
              })}
            </div>
            <PermissionComponent fnCode="FN14">
              <Row>
                <Col span={6} offset={18} className="flex justify-end">
                  <Button
                    type="primary"
                    width="120"
                    onClick={onClickFinishTestButton}
                  >
                    <FormattedMessage id="T0062" />{/* 테스트 완료 */}
                  </Button>
                </Col>
              </Row>
            </PermissionComponent>
          </div>
        </div>
      ) : // 개발자가 테스트 결과를 남긴 경우나 이걸 지나간 경우!
        assignLookup.taskData?.detailStatusCd === "T32" ||
          assignLookup.taskData?.statusCd === "T40" ? (
          <div className="basic_box">
            <div className="basic_box__title">
              <Text>{formatMessage({ id: "T0521" })}</Text>{/* 4. [테스트] 개발 검수 */}
            </div>
            <div className="basic_box__info">
              <Row>
                <Col span={3}>
                  <Text style={{ fontWeight: "bold" }}>
                    {formatMessage({ id: "T0490" })}{/* 테스트 결과 */}
                  </Text>
                </Col>
                <Col span={19}>
                  <Row className="the_end_comment__from_dev">
                    <div className="user_info">
                      <UserTag
                        profile={{
                          NAME_KOR: commentDatas[commentDatas.length - 1]?.empNam,
                          EMP_NO: commentDatas[commentDatas.length - 1]?.empNum,
                          DEPT_NAME_KOR:
                            commentDatas[commentDatas.length - 1]?.orgNam,
                          FILE_ID: commentDatas[commentDatas.length - 1]?.fileId,
                        }}
                        contextVisible={true}
                      />
                    </div>
                    <div className="user_comment">
                      <Text>
                        <Text>{commentDatas[commentDatas.length - 1]?.testResultDesc.split("\n").map((data, index) => {
                          return (
                            <span key={index}>
                              {data}<br />
                            </span>
                          )
                        })}</Text>
                      </Text>
                    </div>
                    <div className="comment_time">
                      <Text>
                        {commentDatas[commentDatas.length - 1]?.regTimestamp}
                      </Text>
                    </div>
                  </Row>
                  {commentDatas
                    .slice(0, commentDatas.length - 1)
                    .sort()
                    .map((comment, index) => {
                      return (
                        <Row key={index} className="continue_comment__container">
                          <div className="user_info">
                            <UserTag
                              key={index}
                              profile={{
                                NAME_KOR: comment.empNam,
                                EMP_NO: comment.empNum,
                                DEPT_NAME_KOR: comment.orgNam,
                                FILE_ID: comment.fileId,
                              }}
                              contextVisible={true}
                            />
                          </div>
                          <div className="user_comment">
                            <Text>{comment.testResultDesc.split("\n").map((data, index) => {
                              return (
                                <span key={index}>
                                  {data}<br />
                                </span>
                              )
                            })}</Text>
                          </div>
                          <div className="comment_time">
                            <Text>{comment.regTimestamp}</Text>
                          </div>
                        </Row>
                      );
                    })}
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <div className="basic_box status__disabled">
            <div className="basic_box__title">
              <Text className="sub_box__title">
                {formatMessage({ id: "T0521" })}{/* 4. [테스트] 개발 검수 */}
              </Text>
            </div>
            <div className="basic_box__info">
              <Row>
                <Col span={5}>
                  <Text className="basic_row__title">
                    {formatMessage({ id: "T0490" })}{/* 테스트 결과 */}
                  </Text>
                </Col>
                <PermissionComponent fnCode="FN13">
                  <Col span={14} className="basic_row__content">
                    <TextArea
                      style={{
                        width: 580,
                      }}
                      autoSize={{ minRows: 2, maxRows: 2 }}
                      placeholder={formatMessage({ id: "T0522" })} // 테스트 결과를 입력해주세요
                      disabled
                    />
                  </Col>
                </PermissionComponent>
                <PermissionComponent fnCode="FN13">
                  <Col span={5} className="flex justify-end">
                    <Button
                      type="default"
                      height="54"
                      width="220"
                      className="block__button"
                      disabled
                    >
                      <FormattedMessage id="T0050" />{/* 등록 */}
                    </Button>
                  </Col>
                </PermissionComponent>
              </Row>
              <PermissionComponent fnCode="FN14">
                <Row>
                  <Col span={6} offset={18} className="flex justify-end">
                    <Button
                      type="default"
                      width="120"
                      className="block__button"
                      disabled
                    >
                      <FormattedMessage id="T0062" />{/* 테스트 완료 */}
                    </Button>
                  </Col>
                </Row>
              </PermissionComponent>
            </div>
          </div>
        )}
    </>
  );
};

export default LookupInspection;
