import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    z-index: 5000;
    .main_popup__box {
        position: absolute;
    	overflow: hidden;
    	background: rgb(255, 255, 255);
    	border-radius: 5px;
    	box-shadow: rgb(0 0 0 / 16%) 0px 9px 28px;
    	border: 2px solid rgb(206, 206, 206);
    }
    .main_popup__header {
        width: 100%;
        height: 50px;
        padding: 0px 20px;
        background: #F9F9F9;
        border-bottom: 2px solid #D0D1D6;
        text-align: left;
        line-height: 50px;
        box-shadow: rgb(0 0 0 / 4%) 0px 2px 1px;
    }
    .main_popup__header > span {
        color: #000;
        font-size: 18px;
    }
    .main_popup__header_close {
        position: absolute;
        top:25px;
        right: 20px;
        transform: translateY(-50%);
        color: #fff;
        cursor: pointer;
    }
    .main_popup__body_content {
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid rgb(206, 206, 206);
        height: calc((100% - 50px) - 50px);
    }
    .main_popup__body_link {
        border-bottom: 1px solid #cecece;
        height: calc(100% - 70px - 63px);
    }
    .main_popup__footer {
       display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	height: 50px;
    }
    .main_popup__footer_check {
        padding-left: 20px;
        display: flex;
        align-items: center;
    }
    .main_popup__footer_check span {
        font-size: 14px;
        color: rgba(0,0,0,0.87);
    }
    .main_poup__footer_close {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 100%;
    }
    .main_poup__footer_close::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 18px);
        background: #cecece;
    }
    .main_poup__footer_close button {
        width: 100%;
        height: 100%;
        font-size: 14px;
    }
`;

export default Wrapper;