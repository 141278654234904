import React from "react";
import {
  AssignEnterBox,
  Text,
  Input,
  Radio,
  FormTable,
} from "components";
import { useIntl, FormattedMessage } from "react-intl";
import { Popover } from "antd";
import "./common.css";
/**
 * Filename: AssignEnterWorkBox/index.js
 * Description: 과제 등록 -> 업무 정보 섹션
 */

const AssignEnterWorkBox = ({ insertDatas, onChangeDatas, requireFields }) => {
  const { formatMessage } = useIntl();
  const personContent = (
    <div className="popover_contentBox">
      <span className="content_text">
        <FormattedMessage id="T1208" />{/* 완전히 동일한 해당 업무를 수행하는 구성원의 수 */}
      </span>
      <br />
      <br />
      <span style={{ color: "#0000FF" }}>
        {formatMessage({ id: "T1124" }) // 
          .split("\n")
          .map((data, index) => {
            return (
              <span key={index}>
                {data}
                <br />
              </span>
            );
          })}
        {/**예시) 만약 구매 요청 업무를 수행한다고 가정하고, 3명이 업무를 수행하는데,
각자가 한 번에 10건의(즉 여러 라인의) 구매 요청 건이 들어있는 엑셀 파일을
매일 9개씩 처리하는데, 이때 엑셀 1라인(즉 1건) 처리하는데 10분이 걸릴 경우
- 동일 또는 유가 업무 구성원 : 3
- 평균 업무 수행 횟수(일) : 10 x 9 = 90
- 1 일 평균 수행 시간 : 10분 */}
      </span>
    </div>
  );
  const timeContent = (
    <div className="popover_contentBox">
      <span className="content_text">
        <FormattedMessage id="T1122" />{/* 해당 업무를 1명이 1건 처리하는데 소모되는 시간 */}
      </span>
      <br />
      <br />
      <span style={{ color: "#0000FF" }}>
        {formatMessage({ id: "T1124" })
          .split("\n")
          .map((data, index) => {
            return (
              <span key={index}>
                {data}
                <br />
              </span>
            );
          })}
        {/**예시) 만약 구매 요청 업무를 수행한다고 가정하고, 3명이 업무를 수행하는데,
각자가 한 번에 10건의(즉 여러 라인의) 구매 요청 건이 들어있는 엑셀 파일을
매일 9개씩 처리하는데, 이때 엑셀 1라인(즉 1건) 처리하는데 10분이 걸릴 경우
- 동일 또는 유가 업무 구성원 : 3
- 평균 업무 수행 횟수(일) : 10 x 9 = 90
- 1 일 평균 수행 시간 : 10분 */}
      </span>
    </div>
  );
  const countContent = (
    <div className="popover_contentBox">
      <span>
        <FormattedMessage id="T1123" />{/* 해당 업무가 주기적이라고 할 때, 주기 별로 수행하는 업무의 1회 평균 수행시간 */}
      </span>
      <br />
      <br />
      <span style={{ color: "#0000FF" }}>
        {formatMessage({ id: "T1124" })
          .split("\n")
          .map((data, index) => {
            return (
              <span key={index}>
                {data}
                <br />
              </span>
            );
          })}
        {/**예시) 만약 구매 요청 업무를 수행한다고 가정하고, 3명이 업무를 수행하는데,
각자가 한 번에 10건의(즉 여러 라인의) 구매 요청 건이 들어있는 엑셀 파일을
매일 9개씩 처리하는데, 이때 엑셀 1라인(즉 1건) 처리하는데 10분이 걸릴 경우
- 동일 또는 유가 업무 구성원 : 3
- 평균 업무 수행 횟수(일) : 10 x 9 = 90
- 1 일 평균 수행 시간 : 10분 */}
      </span>
    </div>
  );
  const numberTypeInput = ["personCount", "runtime", "cycleCount"];
  const onChangeInput = (e) => {
    const { name, value, checked } = e.target;
    if (name === "ruleAcquaint") {
      onChangeDatas(name, checked);
    } else {
      if (numberTypeInput.includes(name)) {
        if (isNaN(+value) || +value > 10000) return;
        else onChangeDatas(name, +value);
      } else {
        onChangeDatas(name, value);
      }
    }
  };
  return (
    <AssignEnterBox title={formatMessage({ id: "T0431" })}>{/* 업무 정보 */}
      <FormTable>
        <colgroup>
          <col width="17.666666666666668%" />
          <col width="45.833333333333336%" />
          <col width="17.666666666666668%" />
          <col width="18.833333333333336%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text required={true}>{formatMessage({ id: "T0432" })}</Text>{/* 동일 또는 유사 업무 구성원 */}
            </th>
            <td>
              <Popover
                className="popover_box"
                content={personContent}
                arrowPointAtCenter
              >
                <Input
                  style={{
                    width: 80,
                  }}
                  //innerRef={requireFields.personCount.ref}
                  label={formatMessage({ id: "T0433" })} // 명
                  name="personCount"
                  value={insertDatas.personCount}
                  onChange={onChangeInput}
                />
              </Popover>
            </td>
            <th>
              <Text required={true}>
                {formatMessage({ id: "T0434" })}{/* 1회 평균 수행시간 */}
              </Text>
            </th>
            <td>
              <Popover content={timeContent}>
                <Input
                  style={{
                    width: 80,
                  }}
                  //innerRef={requireFields.runtime.ref}
                  label={formatMessage({ id: "T0435" })}//(단위: 분)
                  name="runtime"
                  value={insertDatas.runtime}
                  onChange={onChangeInput}
                />
              </Popover>
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>{formatMessage({ id: "T0436" })}</Text>{/* 평균 업무 수행 횟수 */}
            </th>
            <td>
              <div className="flex align-center">
                <Radio
                  renderItem={[
                    {
                      value: "daily",
                      text: formatMessage({ id: "T0437" }), // 일
                    },
                    {
                      value: "weekly",
                      text: formatMessage({ id: "T0438" }), // 주
                    },
                    {
                      value: "monthly",
                      text: formatMessage({ id: "T0439" }), // 월
                    },
                    {
                      value: "quarterly",
                      text: formatMessage({ id: "T0440" }), // 분기
                    },
                    {
                      value: "half",
                      text: formatMessage({ id: "T0441" }), // 반기
                    },
                    {
                      value: "yearly",
                      text: formatMessage({ id: "T0442" }), // 연
                    },
                  ]}
                  //innerRef={requireFields.cycle.ref}
                  value={insertDatas.cycle}
                  name="cycle"
                  onChange={onChangeInput}
                />
                <div className="flex align-end">
                  <Popover content={countContent} arrowPointAtCenter>
                    <Input
                      style={{
                        width: 72,
                      }}
                      //innerRef={requireFields.cycleCount.ref}
                      label={formatMessage({ id: "T0443" })} // 주기별 횟수
                      name="cycleCount"
                      value={insertDatas.cycleCount}
                      onChange={onChangeInput}
                    />
                  </Popover>
                </div>
              </div>
            </td>
            {/* <th>
              <div style={{ display: "flex", gap: 15, alignItems: "center", placeContent: 'center' }}>
                <Text required={true}>{formatMessage({ id: "T0444" })}</Text> 
              </div>
            </th>
            <td>
              <Checkbox
                innerRef={requireFields.ruleAcquaint.ref}
                onChange={onChangeInput}
                name="ruleAcquaint"
                checked={insertDatas.ruleAcquaint}
              >
                {formatMessage({ id: "T0445" })}
              </Checkbox>
            </td> */}
          </tr>
        </tbody>
      </FormTable>
    </AssignEnterBox>
  );
};

export default AssignEnterWorkBox;