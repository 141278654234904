import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { Table, Button, Text, Upload, Input, FormTable, Image } from "components";
import Wrapper from "../Styled";
import { Select, Divider } from "antd";
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;

const WelcomeBanner = () => {
  const { formatMessage } = useIntl();
  const END_POINT = process.env.REACT_APP_API_URI;
  const correctUrl = formatMessage({ id: "T0158" }); // 올바른 형식의 URL을 입력하세요

  const [bannerList, setBannerList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [bannerUse, setBannerUse] = useState("Y");
  const [bannerPageLink, setBannerPageLink] = useState("");
  const [bannerOrder, setBannerOrder] = useState(0);
  const [rowDataInfo, setRowDataInfo] = useState({});
  const [addView, setAddView] = useState(false);
  const [editView, setEditView] = useState(false);

  const onClickCancel = () => {
    onChangeList();
    setResetInfo();
  };

  const onClickGoToEditor = (value) => {
    setEditView(true);
    const tmpData = bannerList.filter((v) => v.link === value)[0];
    setRowDataInfo(tmpData);
    setBannerPageLink(tmpData.pageLink);
  };

  const onClickAdd = () => {
    if (fileList.length <= 0) return alert(formatMessage({ id: "T0264" })); // `배너 이미지를 올려주세요`
    const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    if (
      bannerPageLink.length > 0 &&
      !bannerPageLink.match(expression)
    ) {
      return alert(correctUrl);
    }

    const saveBanner = async () => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("files", file));

      const result = await fetchAbsolute(
        "post",
        `/admin/commonBanner/saveBannerWithFile?bannerId=0&bannerOrder=${bannerOrder}&bannerUseYn=${bannerUse}&pageLink=${bannerPageLink}`,
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result.error) {
        alert(formatMessage({ id: "T0265" }));
        //"배너 추가에 실패하였습니다."
      } else {
        alert(formatMessage({ id: "T0266" }));
        //"배너 추가가 완료되었습니다."
        onChangeList();
        setAddView(false);
      }
    };
    setResetInfo();
    saveBanner();
  };

  const onClickDeleteImage = () => {
    const tmpObj = { ...rowDataInfo };
    delete tmpObj.link;
    setRowDataInfo({ ...tmpObj });
  };

  const onClickEdit = async () => {
    if (
      (!rowDataInfo.link && fileList.length === 0) ||
      (rowDataInfo.link && fileList.length > 0)
    ) {
      return alert(formatMessage({ id: "T0267" }));
      // 하나의 이미지 파일을 올려주세요.
    }

    if (
      bannerPageLink.length > 0 &&
      !bannerPageLink.match(/(http|https):\/\/((\w+)[.])+/gi)
    ) {
      return alert(correctUrl);
    }

    let parameter;

    if (rowDataInfo.link?.length > 0) {
      parameter = `/admin/commonBanner/saveBannerWithFile?bannerId=${rowDataInfo.bannerId}&bannerFileId=${rowDataInfo.bannerFileId}&bannerOrder=${rowDataInfo.bannerOrder}&bannerUseYn=${rowDataInfo.bannerUseYn}&pageLink=${bannerPageLink}`;
    } else {
      parameter = `/admin/commonBanner/saveBannerWithFile?bannerId=${rowDataInfo.bannerId}&bannerOrder=${rowDataInfo.bannerOrder}&bannerUseYn=${rowDataInfo.bannerUseYn}&pageLink=${bannerPageLink}`;
    }

    const formData = new FormData();
    fileList.forEach((file) => formData.append("files", file));
    const result = await fetchAbsolute("post", `${parameter}`, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (result.error) {
      alert(formatMessage({ id: "T0268" }));
      //"배너 수정에 실패하였습니다."
    } else {
      alert(formatMessage({ id: "T0269" }));
      //"배너 수정이 완료되었습니다."
      onChangeList();
    }
    setEditView(false);
    setResetInfo();
  };

  const onClickDelete = () => {
    const deleteBanner = async () => {
      const result = await fetchAbsolute(
        "delete",
        `/admin/commonBanner/deleteBanner`,
        {
          data: [
            {
              bannerId: rowDataInfo.bannerId,
            },
          ],
          headers: { "Content-Type": "application/json" },
        }
      );
      if (result.error) {
        alert(formatMessage({ id: "T0270" }));
        // "배너 삭제에 실패하였습니다.");
      } else {
        alert(formatMessage({ id: "T0271" }));
        // "배너 삭제가 완료되었습니다.");

        onChangeList();
      }
    };
    setResetInfo();
    deleteBanner();
  };

  const onBeforeupload = (inFile) => {
    const tmpArr = [inFile];
    setFileList([...tmpArr]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    // console.log(rmFile)
    // console.log(fileList)
    const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpArr]);
    onChangeList();
  };

  const onChangeEdit = (e) => {
    setRowDataInfo({ ...rowDataInfo, bannerUseYn: e });
  };

  const onChangePageLink = (e) => {
    setBannerPageLink(e.target.value);
  };

  const onChangeBannerOrder = (e) => {
    const { value } = e.target;
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
      setBannerOrder(value);
      setRowDataInfo({ ...rowDataInfo, bannerOrder: value });
    }
  };

  const onChangeList = () => {
    const getBannerList = async () => {
      const adminResult = await fetchAbsolute(
        "get",
        `/admin/commonBanner/getBannerList`
      );
      if (adminResult.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const replaceList = adminResult.data?.map((v) => {
        return {
          ...v,
          link: v.link.replace("200x200", "0x0"), // 이 부분 삭제 가능 200x200 사용안하니까
        };
      });
      setBannerList(
        [...replaceList].sort((a, b) => {
          return a.bannerOrder - b.bannerOrder;
        })
      );
    };
    getBannerList();
  };

  const setResetInfo = () => {
    setFileList([]);
    setBannerOrder(0);
    setBannerUse("Y");
    setEditView(false);
    setAddView(false);
    setBannerPageLink("");
  };

  const columns = [
    {
      key: "bannerOrder",
      title: formatMessage({ id: "T0799" }), // 롤링 순서
      dataIndex: "bannerOrder",
      align: "center",
    },
    {
      key: "link",
      title: formatMessage({ id: "T0800" }), // 배너 이미지
      dataIndex: "link",
      render: (image, index) => {
        return (
          <div key={index}>
            <Image
              src={image}
              onClick={() => onClickGoToEditor(image)}
              width="400px"
              alt="banner img"
              className="welcome_image"
              value={image}
              style={{ cursor: "pointer" }}
            />
          </div>
        );
      },
    },
    {
      key: "pageLink",
      title: formatMessage({ id: "T0801" }), // 배너 링크
      dataIndex: "pageLink",
      render: (link, index) => {
        return (
          <div key={index}>
            <Text type="link" link={link}>
              {link}
            </Text>
          </div>
        );
      },
    },
    {
      key: "bannerUseYn",
      title: formatMessage({ id: "T0802" }), // 사용 여부
      dataIndex: "bannerUseYn",
      align: "center",
    },
  ];

  useEffect(() => {
    const getBannerList = async () => {
      const adminResult = await fetchAbsolute(
        "get",
        `/admin/commonBanner/getBannerList`
      );
      if (adminResult.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const replaceList = adminResult.data?.map((v) => {
        return {
          ...v,
          link: v.link.replace("200x200", "0x0"), // 이부분은 삭제 가능 이제 사이즈가 200x200으로 안나오니까
        };
      });
      setBannerList(
        [...replaceList].sort((a, b) => {
          return a.bannerOrder - b.bannerOrder;
        })
      );
    };
    getBannerList();
  }, []);

  return (
    <Wrapper>
      <div className="common__container">
        {addView ? (
          <>
            <Text className="common__title">
              {formatMessage({ id: "T0803" })}{/* Welcome Banner 추가 */}
            </Text>
            <div style={{ paddingBottom: 10.8 }}></div>
            <FormTable>
              <colgroup>
                <col width="7.375%" />
                <col width="16.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0799" })}{/* 롤링 순서 */}
                    </Text>
                  </th>
                  <td>
                    <Input
                      defaultValue={0}
                      value={bannerOrder}
                      onChange={onChangeBannerOrder}
                      type="number"
                      placeholder="0"
                      style={{ width: 110 }}
                      min={0}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0800" })}{/* 배너 이미지 */}
                    </Text>
                    <br />
                    <Text style={{ color: "D0D1D6", fontSize: 13 }}>
                      {formatMessage({ id: "T0804" })}{/* (파일은 한번에 1개만 추가 가능합니다) */}
                    </Text>
                  </th>
                  <td>
                    <Upload
                      action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                      multiple={false}
                      maxCount={1}
                      beforeUpload={onBeforeupload}
                      onRemove={onRemoveFile}
                      fileList={fileList}
                      accept={'.png, .jpg, .jpeg, .gif'}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0805" })}</Text>{/* 연결할 링크 */}
                  </th>
                  <td>
                    <Input
                      value={bannerPageLink}
                      name="bannerPageLink"
                      onChange={onChangePageLink}
                      style={{ width: '100%', height: 40 }}
                      size="large"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0802" })}</Text>{/* 사용 여부 */}
                  </th>
                  <td>
                    <Select
                      value={bannerUse}
                      name="bannerUse"
                      onChange={onChangeEdit}
                      style={{ width: 110, height: 40 }}
                      size="large"
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <div className="submit_button__section">
              <Button
                type="primary"
                className="submit_button"
                onClick={onClickAdd}
              >
                <FormattedMessage id="T0049" />{/* 추가 */}
              </Button>
              <Button
                type="default"
                className="submit_button"
                onClick={onClickCancel}
              >
                <FormattedMessage id="T0051" />{/* 취소 */}
              </Button>
            </div>
          </>
        ) : editView ? (
          <>
            <Text className="common__title">
              {formatMessage({ id: "T0806" })}{/* Welcome Banner 수정 */}
            </Text>
            <div style={{ paddingBottom: 10.8 }}></div>
            <FormTable>
              <colgroup>
                <col width="285.82px" />
                <col width="1156.19px" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0799" })}{/* 롤링 순서 */}
                    </Text>
                  </th>
                  <td>
                    <Input
                      value={rowDataInfo?.bannerOrder}
                      onChange={onChangeBannerOrder}
                      type="number"
                      placeholder="0"
                      style={{ width: 110 }}
                      min={0}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0800" })}{/* 배너 이미지 */}
                    </Text>
                    <br />
                    <Text style={{ color: "D0D1D6", fontSize: 13 }}>
                      {formatMessage({ id: "T0804" })}{/* (파일은 한번에 1개만 추가 가능합니다) */}
                    </Text>
                  </th>
                  <td className="edit_file__section">
                    <div>
                      <Upload
                        action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                        multiple={false}
                        maxCount={1}
                        beforeUpload={onBeforeupload}
                        onRemove={onRemoveFile}
                        fileList={fileList}
                        accept={'.png, .jpg, .jpeg, .gif'}
                      />
                    </div>
                    {rowDataInfo.link && (
                      <>
                        <Divider />
                        <div className="edit_file__image_button">
                          <Image
                            alt="Banner img"
                            src={rowDataInfo.link}
                            className="welcome_image"
                          />
                          <Button
                            className="submit_button"
                            type="default"
                            onClick={onClickDeleteImage}
                          >
                            {formatMessage({ id: "T0072" })}{/* 삭제 */}
                          </Button>
                        </div>
                      </>
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0805" })}</Text>{/* 연결할 링크 */}
                  </th>
                  <td>
                    <Input
                      placeholder={formatMessage({ id: "T0511" })} // URL을 입력하세요
                      value={bannerPageLink}
                      name="bannerPageLink"
                      onChange={onChangePageLink}
                      style={{ width: "100%", height: 40 }}
                      size="large"
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0802" })}</Text>{/* 사용 여부 */}
                  </th>
                  <td>
                    <Select
                      value={rowDataInfo.bannerUseYn}
                      name="bannerUse"
                      onChange={onChangeEdit}
                      style={{ width: 110, height: 40 }}
                      size="large"
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <div className="submit_button__section">
              <Button
                type="primary"
                className="submit_button"
                onClick={onClickEdit}
              >
                <FormattedMessage id="T0054" />{/* 수정 */}
              </Button>
              <Button
                type="primary"
                className="submit_button"
                onClick={onClickDelete}
              >
                {formatMessage({ id: "T0072" })}{/* 삭제 */}
              </Button>
              <Button
                type="default"
                className="submit_button"
                onClick={onClickCancel}
              >
                <FormattedMessage id="T0051" />{/* 취소 */}
              </Button>
            </div>
          </>
        ) : (
          <>
            <Text className="common__title">
              {formatMessage({ id: "T0807" })}{/* 배너 목록 */}
            </Text>
            <div style={{ paddingBottom: 10.8 }}></div>
            <Table
              dataSource={bannerList}
              columns={columns}
              rowKey={(item) => item.bannerId}
            />
            <div className="submit_button__section">
              <Button
                type="primary"
                className="submit_button"
                onClick={() => setAddView(true)}
              >
                <FormattedMessage id="T0049" />{/* 추가 */}
              </Button>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  );
};
export default WelcomeBanner;