import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { FormTable, Text, Input, Radio, Button } from "components";
import { Select } from 'antd';
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";

const { Option } = Select;

/**
 *  관리자 > 게시판 관리 > 게시판 수정/ 등록 > 게시판 기본 정보
 * 
 */

const BoardBasicInfo = ({ location: { pathname }, requireFields, onChangeValues, basicInfo, type, onClickCheckId, isVerified, language, onChangeLanguage }) => {
  const { formatMessage } = useIntl();

  const [languageList, setLanguageList] = useState([]);

  const radioItem = [
    {
      value: "Y",
      text: formatMessage({ id: "T0630" }), // 사용
    },
    {
      value: "N",
      text: formatMessage({ id: "T0631" }), // 미사용
    },
  ];

  useEffect(() => {
    const fetchLanguageList = async () => {
      const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
      const data = result.data.filter(v => v.useYn === 'Y');
      setLanguageList([...data]);
      onChangeLanguage(data[0].cd);
    }
    fetchLanguageList();
  }, [type]);

  return (
    <Wrapper>
      <div className='basic__wrapper'>
        <Text className='basic__title'>{formatMessage({ id: "T0632" })}</Text>{/* 게시판 기본 정보 */}
        <FormTable>
          <colgroup>
            <col width='13.85%' />
            <col width='36.08%' />
            <col width='13.85%' />
            <col width='36.08%' />
          </colgroup>
          <tbody>
            <tr>
              <th>
                <Text required={true}>{formatMessage({ id: "T0622" })}</Text>{/* 게시판 명 */}
              </th>
              <td>
                <div className="table__flex_box">
                  <Input
                    innerRef={requireFields.boardNm.ref}
                    onChange={onChangeValues}
                    name='boardNm'
                    value={basicInfo.boardNm}
                    maxLength={40}
                  />
                  {
                    type === 'modify' &&
                    <Select
                      value={language}
                      onChange={onChangeLanguage}
                    >
                      {
                        languageList.map(lan => (
                          <Option key={lan.cd} value={lan.cd}>{lan.cdNm}</Option>
                        ))
                      }
                    </Select>
                  }
                </div>
              </td>
              <th>
                <Text required={true}>{formatMessage({ id: "T0624" })}</Text>{/* 게시판 ID */}
              </th>
              <td>
                {
                  type === 'modify' ?
                    <Text>{basicInfo.boardId}</Text>
                    :
                    <div className='board_id__checking'>
                      <Input
                        placeholder={formatMessage({ id: "T0633" })} // 영문과 숫자만 가능합니다. 
                        innerRef={requireFields.boardId.ref}
                        onChange={onChangeValues}
                        className='info_table__input'
                        name='boardId'
                        value={basicInfo.boardId}
                        maxLength={20}
                        disabled={isVerified}
                      />
                      <Button
                        type='primary'
                        onClick={onClickCheckId}
                      > {formatMessage({ id: 'T0070' })}</Button>{/* 중복 체크 */}
                    </div>
                }
              </td>
            </tr>
            <tr>
              <th>
                <Text>{formatMessage({ id: "T0634" })}</Text>{/* 게시판 URL */}
              </th>
              <td colSpan={3}><Text>{window.location.host}{`/community/${basicInfo.boardId}/list`}</Text></td>
            </tr>
            {
              language === 'ko' &&
              <>
                <tr>
                  <th>
                    <Text required={true}>{formatMessage({ id: "T0635" })}</Text>{/* 쓰기 기능 */}
                  </th>
                  <td>
                    <Radio
                      className="info_table__td"
                      name='writeYn'
                      value={basicInfo.writeYn}
                      onChange={onChangeValues}
                      defaultValue="N"
                      renderItem={radioItem}
                    />
                  </td>
                  <th>
                    <Text required={true}>{formatMessage({ id: "T0636" })}</Text>{/* 답글 기능 */}
                  </th>
                  <td>
                    <Radio
                      className="info_table__td"
                      name='replyYn'
                      value={basicInfo.replyYn}
                      onChange={onChangeValues}
                      defaultValue="N"
                      renderItem={radioItem}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text required={true}>{formatMessage({ id: "T0628" })}</Text>{/* 댓글 기능 */}
                  </th>
                  <td>
                    <Radio
                      className="info_table__td"
                      name='commentYn'
                      value={basicInfo.commentYn}
                      onChange={onChangeValues}
                      defaultValue="N"
                      renderItem={radioItem}
                    />
                  </td>
                  <th>
                    <Text required={true}>{formatMessage({ id: "T0637" })}</Text>{/* 비밀글 기능 */}
                  </th>
                  <td>
                    <Radio
                      className="info_table__td"
                      name='secretYn'
                      value={basicInfo.secretYn}
                      onChange={onChangeValues}
                      defaultValue="N"
                      renderItem={radioItem}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text required={true}>{formatMessage({ id: "T0638" })}</Text>{/* 메인 노출 기능 */}
                  </th>
                  <td>
                    <Radio
                      className="info_table__td"
                      name='showMainYn'
                      value={basicInfo.showMainYn}
                      onChange={onChangeValues}
                      defaultValue="N"
                      renderItem={radioItem}
                    />
                  </td>
                  <th>
                    <Text required={true}>{formatMessage({ id: "T0629" })}</Text>{/* 게시판 사용 */}
                  </th>
                  <td>
                    <Radio
                      className="info_table__td"
                      name='useYn'
                      value={basicInfo.useYn}
                      onChange={onChangeValues}
                      defaultValue="N"
                      renderItem={radioItem}
                    />
                  </td>
                </tr>
              </>
            }
          </tbody>
        </FormTable>
      </div>
    </Wrapper>
  );
};

export default withRouter(BoardBasicInfo);