import React, { useEffect, useRef, useState } from 'react';
import { getCookie } from 'utils/cookie';
import { useHistory } from "react-router-dom"
import { ProfileIcon } from 'icons';
import { Avatar } from 'antd';

const Image = ({ type, src = '', width = '', height = '', alt = '', loading = 'lazy', ...args }) => {
    const history = useHistory();

    const [blobSrc, setBlobSrc] = useState('');

    const mountedRef = useRef(true);
    const accessToken = getCookie('auth')?.accessToken;

    useEffect(() => {
        const inputImageInfo = async (src) => {
            if (!accessToken) return history.push('/');
            const headers = new Headers();
            headers.append('Authorization', accessToken);

            /* 메인화면처럼 src가 빈 경우에 */
            if (src) {
                await fetch(src, { headers })
                    .then(response => response.blob())
                    .then(blobby => {
                        if (!mountedRef.current) return null;
                        let objectUrl = window.URL.createObjectURL(blobby);
                        setBlobSrc(objectUrl);
                    });
            }
        }
        inputImageInfo(src);

        return () => {
            window.URL.revokeObjectURL(blobSrc);
            mountedRef.current = false;
        }
    }, [src]);

    switch (type) {
        case 'avatar':
            return (
                <Avatar
                    src={blobSrc}
                    alt={alt}
                    size={width}
                    icon={
                        !src &&
                        <ProfileIcon
                            style={{
                                width: '100%',
                                height: '100%'
                            }}
                        />
                    }
                    {...args}
                />
            );
        default:
            return (
                <img
                    src={blobSrc}
                    alt={alt}
                    width={width}
                    height={height}
                    loading={loading}
                    style={{ objectFit: 'cover' }}
                    {...args}
                />
            )
    }
}

export default Image; 
