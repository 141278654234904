import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import fetchAbsolute from "utils/fetchAbsolute";
import { getUniqueKey } from "utils/Str";
import {
  Button,
  Input,
  FormTable,
  Text,
  WebEditor,
  Upload,
  Checkbox,
  PageTemplate,
  Header,
  Footer,
} from "components";
import { setVisible } from "store/loading/action";
import Container from "containers/Container";
import { useIntl } from "react-intl";
import { decodeHTMLEntites } from "utils/decodeHTMLEntites";
import useFormatMessage from "hooks/useFormatMessage";
import Wrapper from "./Styled";

/**
 * Community
 * 게시글 작성 및 수정
 */

const CommunityEnter = ({
  match: { params },
  history,
  type,
}) => {
  const { formatMessage } = useIntl();
  const END_POINT = process.env.REACT_APP_API_URI;
  const fmMessage = useFormatMessage();
  const boardTitle = formatMessage({ id: "T0017" }); // 제목
  const fail = formatMessage({ id: 'T0133' }); // 작업을 실패하였습니다.
  const sameNameNotUpload = formatMessage({ id: 'T0145' }); // 같은 이름의 파일은 업로드 할 수 없습니다.
  const titleInput = formatMessage({ id: 'T0211' }); // 제목을 입력해주세요.
  const postUpdateSuccess = formatMessage({ id: 'T0212' }); // 게시글 수정이 완료 되었습니다.
  const postCreateSuccess = formatMessage({ id: 'T0213' }); // 게시글 작성이 완료 되었습니다.

  const boardMaCondition = useSelector(
    (state) => state.get(getUniqueKey("community")).boardMaCondition
  );

  const dispatch = useDispatch();

  const tmpFileList = useRef([]);
  const btnRef = useRef(null);

  const [defaultValue, setDefaultValue] = useState("");
  const [boardCondition, setBoardCondition] = useState({});
  const [boardFileList, setBoardFileList] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [boardInfo, setBoardInfo] = useState({
    secretYn: "N",
  });

  const onChangeEditor = (value) => {
    setBoardInfo({
      ...boardInfo,
      boardContents: value,
    });
  };

  const onChangeTitle = (e) => {
    const title = e.target.value;
    if (title.length > 99) return;
    setBoardInfo({ ...boardInfo, boardTitle: e.target.value });
  };
  const onChangeSecret = (e) => {
    setBoardInfo({ ...boardInfo, secretYn: e.target.checked ? "Y" : "N" });
  };

  const onBeforeUpload = (inFile) => {
    if (
      tmpFileList.current.findIndex((file) => file.name === inFile.name) !== -1
    ) {
      alert(sameNameNotUpload);
      return false;
    }
    if (inFile.size > 1024 * 1024 * 100) {
      return alert(
        fmMessage({
          id: "T1571",
          values: { field: Math.round((inFile.size / 1024 / 1024) * 100) / 100 },
        })
      );
      // 100MB 이하 파일만 등록할 수 있습니다.\n\n "현재파일 용량 : {field}MB
    }
    tmpFileList.current.push(inFile);
    setBoardFileList([...tmpFileList.current]);
    return false;
  };


  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) {
      tmpFileList.current = [
        ...tmpFileList.current.filter((file) => file.seq !== rmFile.seq),
      ];
    } else {
      tmpFileList.current = [
        ...tmpFileList.current.filter((file) => file.uid !== rmFile.uid),
      ];
    }
    setBoardFileList([...tmpFileList.current]);
    setDeleteFiles(deleteFiles.concat(rmFile.seq));
  };

  /* 글 작성 기능 */
  const onClickSubmit = async () => {
    dispatch(setVisible(true));
    if (!boardInfo.boardTitle || boardInfo.boardTitle.trim().length === 0) {
      dispatch(setVisible(false));
      return alert(titleInput);
    }
    let convert;
    if (type === "modify" || type === "reply") {
      convert = boardInfo.boardContents
        ? boardInfo.boardContents
        : defaultValue;
    } else {
      convert = boardInfo.boardContents ? boardInfo.boardContents : " ";
    }

    let fileId =
      boardFileList.length > 0 && boardFileList.map((v) => v.fileId)[0];

    const parameter = {
      boardId: params.boardId,
      boardSeq: type !== "modify" ? 0 : params.boardSeq,
      boardTitle: boardInfo.boardTitle,
      boardContents: convert,
      secretYn: boardInfo.secretYn,
      delYn: "N",
      fileId: fileId ? fileId : "",
      pBoardSeq:
        type === "modify" && params.pboardSeq
          ? params.pboardSeq
          : type === "reply"
            ? params.boardSeq
            : 0,
    };

    const formData = new FormData();

    Object.entries(parameter).forEach(([key, value]) => {
      formData.append(key, value);
    });

    if (!type) {
      boardFileList.forEach((file) => formData.append("files", file));
    } else {
      for (let i = 0; i < deleteFiles.length; i++) {
        const seq = deleteFiles[i];
        await fetchAbsolute("post", `/file/deleteFile/${seq}`);
      }
      boardFileList
        .filter((file) => !file.uploaded)
        .forEach((file) => formData.append("files", file));
    }

    const savePosting = await fetchAbsolute("post", `/board/boardSave`, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (!savePosting.error) {
      if (type === "modify") {
        alert(postUpdateSuccess);
      } else {
        alert(postCreateSuccess);
      }
    } else {
      dispatch(setVisible(false));
      return alert(fail);
    }
    dispatch(setVisible(false));
    if (!type) {
      history.push(`/community/${params.boardId}/list`);
    }
    //답글달때 답글 단글로 이동
    else if (savePosting.data.pboardSeq > 0) {
      history.push(`/community/${params.boardId}/${savePosting.data.boardSeq}/lookup`);
    }
    else {
      history.push(`/community/${params.boardId}/${params.boardSeq}/lookup`);
    }
  };

  const onClickCancelButton = () => {
    const path = `/community/${params.boardId}/list`;
    history.push(path);
  }

  /* 수정, 답글 초기 데이터 셋팅 */
  useEffect(() => {
    /* Y N 유무 때문에 master board list 불러옴 */

    // 게시판 정보 가져오는거
    const getBoardInfo = async () => {
      dispatch(setVisible(true));

      if (!type) {
        if (boardMaCondition.contentsTmp) {
          const convert = decodeHTMLEntites(boardMaCondition.contentsTmp);
          setBoardInfo({
            ...boardInfo,
            boardContents: convert
          })
          setDefaultValue(convert);
        } else {
          setDefaultValue("");
        }
      }
      setBoardCondition({ ...boardMaCondition });
      dispatch(setVisible(false));
    };

    // 게시글 정보 가져오는거
    const getInfo = async () => {
      const result = await fetchAbsolute(
        "get",
        `/board/boardInfo?boardSeq=${params.boardSeq}`
      );
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

      const tmpObj = { ...result.data.board };
      const convertContents = decodeHTMLEntites(result.data.board.boardContents);
      tmpObj.boardContents = convertContents;

      if (type === "reply") {
        // 답글에선 제목 빼기
        const notice =
          "<br/><br/><br/><br/><p>-------------------------------------------------------------------------------[Original Message]-------------------------------------------------------------------------------</p>";
        tmpObj.boardContents = notice + convertContents;
      } else if (type === "modify") {
        if (result.data.fileList.length > 0) {
          const fileId = result.data.fileList.map((v) => v.fileId)[0];
          if (fileId) {
            const files = await fetchAbsolute(
              "get",
              `/file/listFile/${fileId}`
            );
            const fileList = files.data.map((file) => ({
              fileId: file.fileId,
              name: file.fileName,
              uploaded: true,
              seq: file.seq,
              uid: file.seq,
            }));
            setBoardFileList(fileList);
          }
        }
      }
      setBoardInfo({ ...tmpObj });
      setDefaultValue(tmpObj.boardContents);
    };
    getBoardInfo();
    if (type) {
      getInfo();
    }
  }, [params.boardId]);

  const onMouseLeave = () => {
    btnRef.current.focus();
  }

  useEffect(() => {
    if (boardFileList.length > 0) {
      tmpFileList.current = [...boardFileList];
    }
  }, [boardFileList]);

  if (!boardCondition?.boardNm) {
    return (
      <div></div>
    )
  }
  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      headerColor="none"
      menuTitle={
        type === "reply"
          ? `${boardCondition?.boardNm} ${formatMessage({ id: "T1078" })}` // 답글 등록
          : type === "modify"
            ? `${boardCondition?.boardNm} ${formatMessage({ id: "T1083" })}` // 게시글 수정
            : `${boardCondition?.boardNm} ${formatMessage({ id: "T0885" })}` // 게시글 작성
      }
      depthList={[
        `${formatMessage({ id: "T0387" })}`, // 커뮤니티
        `${boardCondition?.boardNm}`,
        type === "reply"
          ? `${formatMessage({ id: "T1078" })}`  // 답글 등록
          : type === "modify"
            ? `${formatMessage({ id: "T1083" })}` // 게시글 수정
            : `${formatMessage({ id: "T0885" })}`, // 게시글 작성
      ]}
    >
      <Container
        style={{ display: "flex", flexDirection: "column", gap: "30px 0" }}
      >
        <Wrapper>
          <div className="basic__wrapper">
            <FormTable>
              <colgroup>
                <col width="12.5%" />
                <col width="37.5%" />
                <col width="12.5%" />
                <col width="37.5%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Text required={true}>{boardTitle}</Text>
                  </th>
                  <td colSpan={3}>
                    <div className="posting_title_section">
                      <Input
                        onChange={onChangeTitle}
                        value={boardInfo.boardTitle}
                      />
                      {boardCondition.secretYn === "Y" && (
                        <Text className="secret_check">
                          <Checkbox
                            onChange={onChangeSecret}
                            checked={boardInfo.secretYn === "Y" ? true : false}
                          />
                          {formatMessage({ id: "T0884" })}{/* 비밀 기능 */}
                        </Text>
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0885" })}</Text>{/* 게시글 작성 */}
                  </th>
                  <td colSpan={3} onMouseLeave={onMouseLeave}>
                    <WebEditor
                      defaultValue={defaultValue}
                      onChange={onChangeEditor}
                    />
                  </td>
                </tr>
                <tr>
                  <th>
                    <Text>{formatMessage({ id: "T0886" })}</Text>{/* 파일첨부 */}
                  </th>
                  <td colSpan={3}>
                    <Upload
                      action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                      multiple={true}
                      beforeUpload={onBeforeUpload}
                      type='condition'
                      onRemove={onRemoveFile}
                      fileList={boardFileList} />
                  </td>
                </tr>
              </tbody>
            </FormTable>
          </div>
          <div className="button_company">
            <Button
              onClick={onClickCancelButton}
            >
              {formatMessage({ id: "T0094" })}{/* 뒤로 */}
            </Button>
            <Button
              type="primary"
              onClick={onClickSubmit}
              innerRef={btnRef}
            >
              {type === "modify"
                ? `${formatMessage({ id: "T1090" })}` // 수정
                : `${formatMessage({ id: "T0073" })}` // 저장
              }
            </Button>
          </div>
        </Wrapper>
      </Container>
    </PageTemplate>
  );
};

export default withRouter(CommunityEnter);