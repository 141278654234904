import styled from "styled-components";

const Wrapper = styled.div`
  border: 1px solid #d0d1d6;
  border-radius: 10px;
  background: #fff;
  width: 1400px; 
  height: fit-content;
  margin-bottom: 40px;
  padding: 49px 50px 29.5px 50px;
  /* top */
  .headline {
    font-size: 22px;
    font-weight: bold;
    word-break: break-all;
  }
  .headline__date {
    display: flex;
    align-self: flex-end;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.6);
    padding: 0 38.3px 0 20px;
  } 
  .top_first_line {
    display: flex;
    padding-bottom: 17.7px;
  }
  .icon__group {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .readcnt_box {
    display: flex;
    width: 68px;
    height: 28px;
    gap: 5.4px;
    border-radius: 14px;
    border: 1px solid #979797;
    justify-content: center;
    align-items: center;
  }
  .readcnt_box .readcnt_count,
  .interest_box .interest_count {
    font-size: 14px;
  }
  .interest_box {
    display: flex;
    width: 62.67px;
    height: 28px;
    border-radius: 14px;
    border: 1px solid #ff7a00;
    /* border: 1px solid #0067ac; */
    justify-content: center;
    align-items: center;
    gap: 5.4px;
  }
  .interest_box:hover {
    transform: scale(1.1);
  } 
  .interest_box .interest_box__icon,
  .interest_empty_box__icon {
    color: #ff7a00;
  }
  /* .interest_box .interest_box__icon,
  .interest_empty_box__icon {
    color: #0067ac;
  } */
  .top_second_line {
    display: flex;
    justify-content: space-between;
  }
  .top_second_line .writer {
    display: flex;
    place-items: center;
  }
  .top_second_line .writer .main {
    font-size: 14px;
    font-weight: bold;
  }
  .top_second_line .admin {
    display: flex;
    gap: 5px;
    padding-left: 7px;
  }
  .top_second_line .admin .text {
    font-size: 14px;
    align-self: center;
    border-bottom: 1px solid #000;
  }
  .button_company {
    display: flex;
    gap: 20px;
  }
  .button_company #Fill_2 {
    fill: #ff7a00;
    /* fill: #0067ac; */
  }
  .button_company .btns {
    width: 140px;
    height: 40px;  
  }
  .top_content_section {
    padding: 23.5px 44.5px;
  } 
  .actual_content {
    overflow-wrap: anywhere;
  }
  /* 테이블이 있을 경우에 스타일 주기 */
  .actual_content table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 12px 0 14px;
    color: #222;
    width: auto;
    border-collapse: collapse;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .actual_content table > tr:last-of-type {
    border-bottom: 2px solid #888888;
  } 
  .actual_content table > tr:first-of-type {
      border-top: 2px solid #de3d0f;
  }
  .actual_content table > thead > tr > th {
    background-color: #555;
    font-weight: 300;
    color: #fff;
    padding-top: 6px;
  }
  .actual_content table th, .actual_content table td {
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 14px 5px 12px;
    height: 32px;
  }
  .attachments {
    padding-bottom: 20px;
  }
  .attachments .file_list {
    display: flex;
    width: 76px;
    height: 22px;
    border-radius: 2px;
    background-color: #59617F; 
    color: rgba(255, 255, 255, 0.87);
    place-content: center;
    position: relative; 
    top: 9px; 
  } 
  .attachments .file_name_list {
    display: flex;
    width: fit-content;
    height: fit-content;
    min-height: 42px;
    border-radius: 10px;
    background-color: #F2F4F8; 
    padding: 12px 14px 10px 18px;
    flex-flow: wrap;
    flex-direction: column;
  }
  .top_comment_section {
    display:flex;
    flex-direction: column;
    width: 1297px;
    min-height: 144px;
    border-radius: 10px;
    border: 1px solid #d8d8d8;
    background-color: #f9f9f9; 
    padding: 18px 28px 0 28px;
  }
  .top_comment_section .comment {
    display:flex;
    gap: 19.7px;
  }
  .comment_btn_company {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .comment_btn {
    width: 150px; 
    height: 64px;
    display: flex;
    align-items: center;
    place-content: center;
    gap: 10px;
  } 
  .comment_alert {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .comment_mini #합치기_15 #테두리{
    fill: #707070;
  }
  .comment_mini #그룹_73374 ellipse{
    fill: #707070;
  }
  .secret_check {
    display: flex;
    place-content: flex-end;
    width: 1180px;
    gap: 10px;
    padding: 15px 0 26px 0;
    font-size: 12px;
  } 
  /* Bottom */
  .bottom_container { }
  .bottom_top_section {
    display: flex;
    justify-content: space-between;
    padding: 29px 0 19.5px 0;
    align-items: end;
  }  
  .search_btns_section .search__button, .search__input { 
    height: 32px !important;
  }
  .search_btns_section {
    display: flex;
    gap: 21.6px;
  }
  .search_btns_section .top_search_button {
    border: 1px solid #ffa700;
    color: #ffa700;
    background: #fff;
  }
  .comment_container { 
    border-top: 2px solid #888888; 
    border-bottom: 1px solid #d0d1d6;
    height: fit-content; 
  } 
  .comment__info {
    display: flex;
    flex-direction: column;
    min-height: 90px;
    border-bottom: 1px solid #d5d5d5; 
    height: auto;
  }
  .comment__info.selected {
    background: #eee;
    margin: 5px 5px;
    padding-bottom: 5px;
  }
  span .ant-checkbox-checked{
    background: #333;
  }
  .comment_container .comment__info path #Fill_1,#Fill_2,#Fill_3,#Fill_4,#Fill_5 {
    fill: #333;
  }
  .comment__info .icons {
    display: flex;
    gap: 22.8px;
    padding-top: 6px;
  }
   
  .comment_first_row {
    display: flex;
    gap: 24.2px;
    padding-top: 21px;
    place-items: self-end;
  }
  .comment_first_row .user_thumbnail {
    align-self: center;
  }
  .comment_second_row {
    display: flex;
    padding: 14.5px 0 14.5px 40px;
  }
  .comment_second_row span {
    font-size: 12px;
    overflow-wrap: anywhere;
  }
  .comment_second_row .edit__version {
    display: flex;
    gap: 20px;
    padding-bottom: 14.5px;
  }
  .edit_comment_btn__section {
    display: flex; 
    gap: 40px;
    flex-direction: column;
  }
  .edit_comment_btn__section .btns{
    display: flex;
    gap: 10px
  }
  /* 테이블 스타일 적용 test */
  .tui-editor-contents table th {
    
  }
`;

export default Wrapper;