import React, { useEffect, useState } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Text,
  Input,
  Button,
  FormTable,
} from "components";
import { Select } from "antd";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled";
import { createBrowserHistory } from "history";
import { useIntl, FormattedMessage } from "react-intl";

const { Title } = Typography;
const { Option } = Select;

/**
 * Filename: CrInfoInsert/index.js
 * Description:CrInfoInsert
 */

const CrInfoInsert = () => {
  const { formatMessage } = useIntl();

  const history = createBrowserHistory({ forceRefresh: true });

  const [assignType, setAssignType] = useState([]);
  const [useYn, setUseYn] = useState("");
  const [crEnv, setCrEnv] = useState("");
  const [crList, setCrList] = useState([]);
  const [crType, setCrType] = useState("");
  const [inputValues, setInputValues] = useState({
    crName: "",
    crUri: "",
  });
  const [language, setLanguage] = useState("");

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onChangeAssignType = (e) => {
    setAssignType(e);
  };

  const onChangeCrType = (e) => {
    setCrType(e);
  };
  const onChangeUseYn = (e) => {
    setUseYn(e);
  };
  const onChangeEnvType = (e) => {
    setCrEnv(e);
  };

  const crInfoUpdate = async () => {
    await fetchAbsolute("get", "/common/updateControlRoomCd");
  };

  const onClickAdd = async () => {
    let countCheck = crList.filter(
      (e) => e.attrCd1 === assignType && e.attrCd2 === crEnv
    );

    let count = Number(countCheck.length) + 1;
    if (count < 10) {
      count = "0" + count;
    }
    {
      let fetchFlag = true;
      try {
        const saveList = await fetchAbsolute(
          "post",
          `/common/commonCd/addList`,
          {
            data: {
              categoryId: "CR_INFO",
              cd: assignType + "_" + crEnv + "_" + count,
              cdNm: inputValues.crName,
              useYn: useYn,
              attrCd1: assignType,
              attrCd2: crEnv,
              attrCd3: inputValues.crUri,
              attrCd4: crType,
              sort: crList.length + 1,
              locale: language,
            },
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (saveList.status === 201 || saveList.status === 204) {
          alert(formatMessage({ id: "T0234" })); // 저장되었습니다.
          crInfoUpdate();

          history.push("/admin/common/crinfo");
        } else {
          throw new Error(saveList.status);
        }
      } catch (e) {
        fetchFlag = false;
      } finally {
        if (!fetchFlag) {
          return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
        }
      }
    }
  };

  const fetchLanguageList = async () => {
    const result = await fetchAbsolute(
      "get",
      "/common/commonCd/getCategoryId/LOCALE"
    );
    const data = result.data.filter((v) => v.useYn === "Y");
    setLanguage(data[0].cd);
  };

  useEffect(() => {
    const fetchPoolCr = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/CR_INFO?sortVal=asc`
      );
      setCrList([...result.data]);
    };
    fetchPoolCr();
    fetchLanguageList();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />}>
      <Wrapper>
        <div className="realtime_content_box">
          <Title className="title_name" level={4}>
            <FormattedMessage id="T1069" />{/* CR 정보 추가 */}
          </Title>
          <div className="update_content">
            <FormTable pointBorder={false}>
              <colgroup>
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
                <col width="25%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <Text required={true}>
                      <FormattedMessage id="T1179" />{/* 개발/운영구분 */}
                    </Text>
                  </th>
                  <td>
                    <div className="flex align-center">
                      <Select
                        id="EnvType"
                        style={{
                          width: 320,
                          marginRight: 20,
                        }}
                        placeholder={formatMessage({ id: "T0688" })} // 선택해주세요
                        value={crEnv}
                        onChange={onChangeEnvType}
                      >
                        <Option value="DEV">DEV</Option>
                        <Option value="PRD">PRD</Option>
                      </Select>
                    </div>
                  </td>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0740" })}{/* 과제유형 */}
                    </Text>
                  </th>
                  <td>
                    <Select
                      id="assignType"
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      placeholder={formatMessage({ id: "T0688" })} // 선택해주세요
                      value={assignType}
                      onChange={onChangeAssignType}
                    >
                      {Array.from(
                        new Set([...crList.map((v) => v.attrCd1)])
                      ).map((e) => (
                        <Option value={e}>{e}</Option>
                      ))}
                    </Select>
                  </td>
                </tr>

                <tr>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0741" })}{/* CR 이름 */}
                    </Text>
                  </th>
                  <td>
                    <div className="flex align-center">
                      <Input
                        style={{
                          width: 320,
                          marginRight: 20,
                        }}
                        name="crName"
                        value={inputValues.crName}
                        onChange={onChangeInput}
                      />
                    </div>
                  </td>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0742" })}{/* CR 접속 URL */}
                    </Text>
                  </th>
                  <td>
                    <Input
                      name="crUri"
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      value={inputValues.crUri}
                      onChange={onChangeInput}
                    />
                  </td>
                </tr>

                <tr>
                  <th>
                    <Text required={true}>
                      <FormattedMessage id="T1068" />{/* CR TYPE */}
                    </Text>
                  </th>
                  <td>
                    <Select
                      id="crType"
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      placeholder={formatMessage({ id: "T0688" })} // 선택해주세요
                      value={crType}
                      onChange={onChangeCrType}
                    >
                      {Array.from(
                        new Set([...crList.map((v) => v.attrCd4)])
                      ).map((e) => (
                        <Option value={e}>{e}</Option>
                      ))}
                    </Select>
                  </td>
                  <th>
                    <Text required={true}>
                      {formatMessage({ id: "T0737" })}{/* 사용여부 */}
                    </Text>
                  </th>
                  <td>
                    <Select
                      id="useYn"
                      style={{
                        width: 320,
                        marginRight: 20,
                      }}
                      placeholder={formatMessage({ id: "T0688" })} // 선택해주세요
                      value={useYn}
                      onChange={onChangeUseYn}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </td>
                </tr>
              </tbody>
            </FormTable>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 20,
            }}
          >
            <Button width="144" height="40" type="primary" onClick={onClickAdd}>
              <FormattedMessage id="T0049" />
              {/* 추가 */}
            </Button>
            &nbsp;&nbsp;
            <Link to="/admin/common/crinfo">
              <Button width="144" height="40">
                <FormattedMessage id="T0051" />
                {/* 취소 */}
              </Button>
            </Link>
          </div>
        </div>
      </Wrapper>
    </PageTemplate>
  );
};

export default CrInfoInsert;
