import React, { useEffect, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { arrayMoveImmutable } from "array-move";
import { Text, Table, Button } from "components";
import Wrapper from "./Styled";
import { MenuOutlined } from "@ant-design/icons";
import { MinusCircleIcon } from "icons";
import { FormattedMessage } from "react-intl";


/**
 *  관리자 > 게시판 관리 > 게시판 수정/ 등록 > 게시판 목록 컬럼 설정
 * 
 */
const FunctionList = ({ rowSelection, datas }) => {
  const columns = [
    {
      title: <FormattedMessage id="T0640" />, // 기능 항목
      key: "cdNm",
      dataIndex: "cdNm",
    },
  ];

  return (
    <div className="col_table_list__wrapper">
      <Table
        columns={columns}
        dataSource={datas}
        scroll={{ y: 291 }}
        rowSelection={rowSelection}
        rowKey={(e) => e.cd}
        locale={{ emptyText: " " }}
      />
    </div>
  );
};

const SelectedList = ({
  datas,
  onClickDeleteIcon,
  setUsingColumns,
  rowSelection
}) => {
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
  ));
  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const columns = [
    {
      title: "",
      key: 1,
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: <FormattedMessage id='T0338' />, // 선택 항목
      key: 4,
      dataIndex: "cdNm",
      width: 110,
      render: (text) => {
        return <div>{text}</div>;
      },
    },
    {
      title: "",
      key: 3,
      dataIndex: "cdNm",
      width: 30,
      render: (text) => {
        return <MinusCircleIcon onClick={() => onClickDeleteIcon(text)} />;
      },
    },
  ];

  const [dataSource, setDataSource] = useState([]);

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      {...props}
    />
  );

  const DraggableBodyRow = (props) => {
    const index = dataSource.findIndex(
      (column) => column.cd === props["data-row-key"]
    );
    return <SortableItem index={index} {...props} />;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const tmpData = newData.map((v, index) => ({
        ...v,
        sort: index,
      }));
      setDataSource([...tmpData]);
      setUsingColumns([...tmpData]);
    }
  };

  useEffect(() => {
    setDataSource([...datas]);
  }, [datas]);

  return (
    <div>
      <Table
        dataSource={dataSource}
        columns={columns}
        scroll={{ y: 291 }}
        rowSelection={rowSelection}
        rowKey={(e) => e.cd}
        locale={{ emptyText: " " }}
        components={{
          body: {
            wrapper: (props) => (
              <DraggableContainer onSortEnd={onSortEnd} {...props} />
            ),
            row: DraggableBodyRow,
          },
        }}
      />
    </div>
  );
};

const BoardSettingCol = ({
  onClickReset,
  rowSelection,
  columnList,
  onClickAdd,
  onClickDelete,
  usingColumns,
  onClickDeleteIcon,
  setUsingColumns,
}) => {
  return (
    <Wrapper>
      <div className="setting_col__wrapper">
        <Text className="col__title"><FormattedMessage id="T0641" /></Text>{/* 게시판 목록 컬럼 설정 */}
        <div className="setting_col__container">
          <div className="reset__button__area">
            <Button
              type="default"
              className="reset__button"
              onClick={onClickReset}
            >
              <FormattedMessage id="T0037" />{/* 초기화 */}
            </Button>
          </div>
          <div className="tables__container">
            <FunctionList rowSelection={rowSelection} datas={columnList} />
            <div className="table_button__company">
              <Button className="buttons" type="primary" onClick={onClickAdd}>
                &gt;
              </Button>
              <Button
                className="buttons"
                type="primary"
                onClick={onClickDelete}
              >
                &lt;
              </Button>
            </div>
            <SelectedList
              rowSelection={rowSelection}
              datas={usingColumns}
              onClickDeleteIcon={onClickDeleteIcon}
              setUsingColumns={setUsingColumns}
            />
          </div>
        </div>
        <div className="col_bottom__part"></div>
      </div>
    </Wrapper>
  );
};

export default BoardSettingCol;