import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Organization, Modal, Table, Button, InputSearch } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import useFormatMessage from "hooks/useFormatMessage";
import { ShrinkOutlined, ExpandOutlined } from "@ant-design/icons";

/**
 * Filename: FailedLogModal.js
 * Description: 모니터링 > 실패 로그 결과를 나타내는 창
 */

const Wrapper = styled((props) => <Modal {...props} />)`
  .ant-table-wrapper {
    padding: 10px 40px;
  }
  .primary__button {
    width: 200px;
    height: 40px;
  }
  .default__button {
    height: 40px;
  }
  .ant-modal-footer button + button {
    margin-left: 0;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    gap: 15px;
  }
  .ant-table-title {
    display: none !important;
  }
  .ant-modal-header {
    border-top: 7px solid #ff7b00;
  }
  /* 우리카드 */
  /* .ant-modal-header {
    border-top: 7px solid #0067ac;
  } */
`;

const FailedLogModal = ({
  visible,
  onOk = () => { },
  onCancel = () => { },
  title = "",
  recordData,
  devicePool,
  ...args
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const profile = useSelector((state) => state.get("auth").get("profile"));

  const [dataSource, setDataSource] = useState([]);
  const [originDataSource, setOriginDataSource] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [orgVisible, setOrgVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orgData, setOrgData] = useState({});
  const [searchText, setSearchText] = useState("");

  const [isMaximized, setIsMaximized] = useState(false);

  const [scrollHeight, setScrollHeight] = useState("330px");

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const filteredData = originDataSource.filter((entry) =>
      entry.description
        .toString()
        .toLowerCase()
        .includes(searchText.toLowerCase())
    );
    setDataSource(filteredData);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setDataSource(originDataSource);
  };

  const onChangeSearchKeyword = (e) => {
    setSearchText(e.target.value);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          placeholder={formatMessage({ id: "T0687" })} // 검색하세요
          value={searchText}
          onChange={onChangeSearchKeyword}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            <FormattedMessage id="T0039" />{/* 검색 */}
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            <FormattedMessage id="T0037" />{/* 초기화 */}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
  });

  const onClickResetFilter = () => {
    setFilterInfo({});
    setSearchText("");
    setDataSource(originDataSource);
  };
  const onClickOrg = () => {
    setOrgVisible(true);
  };
  const onOkOrg = (data) => {
    if (data.length === 0) {
      setOrgData({});
    }
    setOrgData({ ...data[0] });
    setOrgVisible(false);
  };

  const onChangeTable = (pagination, filters) => {
    setFilterInfo({ ...filters });
  };

  const onOkModal = async () => {
    if (!orgData.empNum) return alert(formatMessage({ id: "T0117" })); // 사용자를 선택해주세요
    const result = await fetchAbsolute(
      "get",
      `/common/memberNoList?empNo=${orgData.empNum}`
    );
    if (result.error) {
      return alert(formatMessage({ id: "T0118" })); // 사용자 정보를 가져오는데 실패하였습니다.
    }
    let tableHtml = `<table border='1' cellspacing='0'>
    <colgroup>
      <col width='100'>
      <col width='150'>
      <col width='200'>
      <col width='170'>
      <col width='120'>
      <col>
    </colgroup>
    <thead>
      <tr>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">Work Id</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage(
      { id: "T0349" }
    )}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage(
      { id: "T1314" }
    )}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage(
      { id: "T0355" }
    )}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage(
      { id: "T0350" }
    )}</th>
        <th style="height: 25px; background: #FF7B00; color: #fff; font-size: 12px;">${formatMessage(
      { id: "T0100" }
    )}</th>
      </tr>
    </thead>
    <tbody>`;
    dataSource.forEach((data) => {
      tableHtml += `<tr>
          <td style="text-align: center; font-size: 12px;">${data.workItemId || ""
        }</td>
          <td style="text-align: center; font-size: 12px;">${moment(data.timeStamp).format("YYYY-MM-DD HH:mm:ss") || ""
        }</td>
          <td style="font-size: 12px;">${data.taskName || ""}</td>
          <td style="text-align: center; font-size: 12px;">${data.hostName || ""
        }</td>
          <td style="text-align: center; font-size: 12px;">${data.bizStatus || ""
        }</td>
          <td style="font-size: 12px;">${data.description || ""}</td>
        </tr>`;
    });
    tableHtml += `</tbody>
    </table>`;

    const title = `${recordData.taskNm}(${recordData.taskId}) Fail Log`;
    const content = `${fmMessage({
      id: "T1657",
      values: { name: recordData.taskNm, id: recordData.taskId },
    })}
    ${tableHtml}`;
    // <p style="font-size: 12px;">안녕하십니까.</p>
    // <p style="font-size: 12px;">RPAgo2.0입니다.</p>
    // <p style="font-size: 12px;">${recordData.taskNm}(${recordData.taskId})과제의 Fail Log 전달드립니다.</p>
    // <p style="font-size: 12px;">감사합니다.</p>
    const sendResult = await fetchAbsolute("post", `/mail/sendSimpleText`, {
      data: {
        sendFrom: profile.EMAIL,
        sendTo: [result.data.mdFPAList[0].emailAddr],
        sendCc: [],
        sendBcc: [],
        shareGroupId: [],
        mailSubject: title,
        mailText: content,
      },
    });

    if (sendResult.error || sendResult.data.result === "Fail") {
      return alert(formatMessage({ id: "T0119" })); // 메일 전송을 실패하였습니다
    }

    alert(formatMessage({ id: "T0120" })); // 메일 전송을 완료했습니다.
    setOrgData({});
    onOk();
  };

  const onClickMaximize = () => {
    setIsMaximized(true);
    setScrollHeight("calc(100vh - 256px)");
    document.getElementsByClassName('log_modal')[0].setAttribute("style", "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;");
    document.getElementsByClassName('log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:calc(100vh - 123px);");
  };

  const onClickMinimize = () => {
    setIsMaximized(false);
    setScrollHeight("330px");
    document.getElementsByClassName('log_modal')[0].setAttribute("style", "width:1400px;");
    document.getElementsByClassName('log_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", "height:460px;");
  };

  const columns = [
    {
      key: "workItemId",
      title: <FormattedMessage id="T1108" />, // WorkItem ID
      dataIndex: "workItemId",
      width: 180,
      filters: filterList[0]?.filters,
      filteredValue: filterInfo.workItemId || null,
      onFilter: (value, record) => record.workItemId.includes(value),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.workItemId - b.workItemId,
    },
    {
      key: "timeStamp",
      title: <FormattedMessage id="T0349" />, // 실행 시간
      dataIndex: "timeStamp",
      width: 165,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        +moment(a.timeStamp).format("YYYYMMDDHHmmss") -
        +moment(b.timeStamp).format("YYYYMMDDHHmmss"),
      filteredValue: filterInfo.timeStamp || null,
      filters: filterList[1]?.filters,
      onFilter: (value, record) => record.timeStamp.includes(value),
    },
    {
      key: "taskName",
      title: <FormattedMessage id="T1314" />, // 태스크명
      dataIndex: "taskName",
      width: 175,
      filters: filterList[2]?.filters,
      filteredValue: filterInfo.taskName || null,
      onFilter: (value, record) => record.taskName.includes(value),
    },
    {
      key: "hostName",
      title: <FormattedMessage id="T0355" />, // Device
      dataIndex: "hostName",
      width: 175,
      filters: filterList[3]?.filters,
      filteredValue: filterInfo.hostName || null,
      sorter: (a, b) => a,
      onFilter: (value, record) => record.hostName.includes(value),
    },
    {
      key: "bizStatus",
      title: <FormattedMessage id="T0350" />, // 실행상태
      dataIndex: "bizStatus",
      width: 150,
      filters: filterList[4]?.filters,
      filteredValue: filterInfo.bizStatus || null,
      onFilter: (value, record) =>
        value === " "
          ? record.bizStatus === undefined
          : record.bizStatus?.includes(value),
      render: (value) => (value === undefined ? " " : value),
    },
    {
      key: "description",
      width: 200,
      title: <FormattedMessage id="T0100" />, // 설명
      dataIndex: "description",
      ...getColumnSearchProps("description"),
    },
  ];

  /* onCancel 눌렀을 때 작은 화면으로 돌아가도록 */
  useEffect(() => {
    if (!document.getElementsByClassName("log_modal")[0]) return;

    if (!visible) {
      setIsMaximized(false);
      setScrollHeight("330px");
      document
        .getElementsByClassName("log_modal")[0]
        .setAttribute("style", "width:1400px;");
      document
        .getElementsByClassName("log_modal")[0]
        .getElementsByClassName("ant-modal-body")[0]
        .setAttribute("style", "height:460px;");
    }
  }, [visible]);

  useEffect(() => {
    const fetchLogResult = async () => {
      let data = [];
      if (!recordData.workItemIds) return;
      setLoading(true);
      // api 변경 workItemIds -> 배열로 
      const workItemIds = recordData.workItemIds.split(',');
      const result = await fetchAbsolute(
        "post",
        `/log/botLog/getBotFrameworkLogList`,
        {
          data: {
            crEnv: devicePool,
            runner: recordData.runner,
            taskId: recordData.taskId,
            workItemIds,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      data.push(
        ...result.data?.botList.map((v) => ({
          ...v,
          timeStamp: moment(v.timeStamp).format("YYYY-MM-DD HH:mm:ss"),
        }))
      );
      setLoading(false);
      const newColumn = columns.map((v) => {
        const groupArray = new Set(
          data.map((d) => {
            if (v.dataIndex === "timeStamp") {
              return moment(d[v.dataIndex]).format("YYYY-MM-DD");
            }
            return d[v.dataIndex] || " ";
          })
        );
        const filters = [...groupArray].map((d) => ({ text: d, value: d }));
        return {
          ...v,
          filters,
        };
      });
      setDataSource([...data].sort((a, b) => b.idx - a.idx));
      setOriginDataSource([...data].sort((a, b) => b.idx - a.idx));
      setFilterList(newColumn);
    };
    setDataSource([]);
    fetchLogResult();
  }, [recordData, devicePool]);

  return (
    <>
      <Wrapper
        className="log_modal"
        title={
          <div className="modal_title">
            {title}
            {!isMaximized ? (
              <ExpandOutlined
                className="title_expand_icon"
                onClick={onClickMaximize}
                id="max_btn"
              />
            ) : (
              <ShrinkOutlined
                className="title_expand_icon"
                onClick={onClickMinimize}
                id="min_btn"
              />
            )}
          </div>
        }
        visible={visible}
        okText={formatMessage({ id: "T1211" })} // Log 공유하기
        onCancel={onCancel}
        modalRender={false}
        bodyStyle={{ height: 460 }}
        footer={[
          <Button
            className="default__button"
            type="white"
            onClick={onClickResetFilter}
          >
            {formatMessage({ id: "T0068" })}{/* 필터 초기화 */}
          </Button>,
          <InputSearch
            placeholder={formatMessage({ id: "T0026" })} // 사용자를 선택하세요
            onClick={onClickOrg}
            value={orgData.empNam}
          />,
          <Button
            key="submit"
            type="primary"
            onClick={onOkModal}
            className="primary__button"
          >
            {formatMessage({ id: "T0069" })}
            {/* 공유하기 */}
          </Button>,
          <Button key="back" className="default__button" onClick={onCancel}>
            <FormattedMessage id="T0051" />
            {/* 취소 */}
          </Button>,
        ]}
        {...args}
      >
        <Table
          columns={columns}
          locale={{ emptyText: `${formatMessage({ id: "T1215" })}` }} // 로그 내역이 없습니다.
          dataSource={dataSource}
          // scroll={{ y: 330 }}
          onChange={onChangeTable}
          rowKey={(item) => item.idx}
          scroll={{ y: `${scrollHeight}` }}
          loading={loading}
        />
      </Wrapper>
      <Organization
        visible={orgVisible}
        onCancel={() => setOrgVisible(false)}
        onOk={onOkOrg}
        max={1}
        defaultData={[orgData]}
      />
    </>
  );
};

export default FailedLogModal;
