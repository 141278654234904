import React, { useState, useEffect } from "react";
import { Collapse as AntCollapse } from "antd";
import { useDispatch } from "react-redux";
import { setListUrl } from "store/listUrl/action";
import { resetAssignListValue } from "store/assignList/action";
import {
  PageTemplate,
  Header,
  Footer,
  AssignListTop,
  AssignListBottom,
  Collapse,
} from "components";
import Container from "containers/Container";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl } from "react-intl";
import fetchAbsolute from "utils/fetchAbsolute";
import queryString from "query-string";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUniqueKey } from "utils/Str";

const { Panel } = AntCollapse;

const initialInputValues = {
  taskId: "",
  taskNm: "",
  taskType: [],
  orgCds: [],
  mgrIds: [],
  devIds: [],
  statusCd: [],
  workId: [],
  regFr: "",
  regTo: "",
  regType: "",
};

const initialSearchQuery = {
  sort: "regTimestamp,desc",
  page: 0,
  size: 20,
  tab: "all",
  total: 0
};

const AssignList = ({ location: { search }, history }) => {
  const { formatMessage } = useIntl();
  const inputValues = useSelector(
    (state) => state.get(getUniqueKey("assignList")).inputValues
  );

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState({ ...initialSearchQuery });
  const [sortedInfo, setSortedInfo] = useState({})
  const [beforeSearch, setBeforeSearch] = useState("");
  const [openCollapse, setOpenCollapse] = useState(true);
  const [tabsValue, setTabsValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [excelBody, setExcelBody] = useState({});
  //excel 검색 조건 obj
  const [searchCondition, setSearchCondition] = useState({
    order: "regTimestamp",
    direction: "desc",
    tab: "all",
  });
  const [tableTotal, setTableTotal] = useState({
    all: 0,
    myTask: 0,
    interest: 0,
    shared: 0,
  });

  const [tableLoading, setTableLoading] = useState(false);

  const convertTypeDate = (type) => {
    const dateObj = {
      regFr: "",
      regTo: moment().format("YYYYMMDD"),
    };
    switch (type) {
      case "today":
        dateObj.regFr = moment().add(0, "d").format("YYYYMMDD");
        break;
      case "week":
        dateObj.regFr = moment().subtract(6, "d").format("YYYYMMDD");
        break;
      case "month":
        dateObj.regFr = moment().subtract(1, "months").format("YYYYMMDD");
        break;
      default:
    }
    return dateObj;
  };

  const onChangeCollapse = () =>
    setOpenCollapse((openCollapse) => !openCollapse);

    const convertData = (searchObj, type) => {
      /* 데이터 가공해주기 */
      Object.keys(searchObj).forEach((key) => {
        const data = searchObj[key];
        
        if (["mgrIds", "devIds"].includes(key)) {
          searchObj[key] = data.map(item => item.empNum);
        } else if (["orgCds"].includes(key)) {
          searchObj[key] = data.map(item => item.codeId);
        } else if (["workId", "taskType", "statusCd"].includes(key)) {
          searchObj[key] = data.map(item => item.cd);
        } else if (["regFr", "regTo"].includes(key)) {
          searchObj[key] = data.replace(/-/g, "");
        } else if (key === "regType") {
          if (["today", "week", "month"].includes(data)) {
            const dateObj = convertTypeDate(data);
            searchObj.regFr = dateObj.regFr;
            searchObj.regTo = dateObj.regTo;
          } else if (['custom'].includes(data[0])) {
            searchObj.regFr = moment(searchObj.regFr).format("YYYYMMDD");
            searchObj.regTo = moment(searchObj.regTo).format("YYYYMMDD");
          }
        }
      });

      if(type==="replaceSearch") {
        Object.keys(searchObj).forEach((key) => {
          const data = searchObj[key];
          if (Object.keys(data).length <= 0 && typeof data !== "number") {
            delete searchObj[key];
          }
        })

        return searchObj;
      } else {
        const parameter = {
          taskId: searchObj.taskId,
          taskNm: searchObj.taskNm,
          taskType: searchObj.taskType?.flat(), // 배열
          orgCds: searchObj.orgCds?.flat(), // 배열
          mgrIds: searchObj.mgrIds?.flat(), // 배열
          devIds: searchObj.devIds?.flat(), // 배열
          workId: searchObj.workId?.flat(), // 배열
          statusCd: searchObj.statusCd?.flat(), // 배열
          regFr:searchObj.regFr,
          regTo:searchObj.regTo,
        };
        return parameter;
      }
    }

  const replaceSearch = (searchObject) => {
    // const initialKeys = Object.keys(initialInputValues);
    const initialSearchObj = { ...searchQuery, ...searchObject };

    // 데이터 가공 - 검색할 데이터 정리
    const searchObj = convertData(initialSearchObj,"replaceSearch");

    setSearchCondition({
      direction: searchObj.direction,
      order: searchObj.order,
      tab: searchObj.tab,
      devIds: searchObj.devIds,
      mgrIds: searchObj.mgrIds,
      orgCds: searchObj.orgCds,
      regFr: searchObj.regFr,
      regTo: searchObj.regTo,
      regType: searchObj.regType,
      statusCd: searchObj.statusCd,
      taskId: searchObj.taskId,
      taskNm: searchObj.taskNm,
      taskType: searchObj.taskType,
      workId: searchObj.workId,
    });

    const searchString = queryString.stringify(searchObj);

    if ("?" + searchString === search) return;

    dispatch(setListUrl("assignList", searchString));
    history.push({ search: searchString });
  };

  const getTableDatas = async (searchObject) => {
    const searchObj = { ...inputValues, ...searchObject }
    let path = "";
    switch (searchObject.tab) {
      case "all":
        path = "taskListWithPagingMulValNew";
        break;
      case "myTask":
        path = "myTaskListWithPagingMulValNew";
        break;
      case "interest":
        path = "interestTaskListWithPagingMulValNew";
        break;
      case "shared":
        path = "sharedTaskListWithPagingMulValNew";
        break;
      default:
        path = "taskListWithPagingMulValNew";
    }
    //setFetchPath(path);
    setTableLoading(true);

    /* 데이터 가공해주기 */
    const parameter = convertData(searchObj,"getTableData");

    const result = await fetchAbsolute("post", `/task/oracle/${path}${search}`, {
      data: {
        ...parameter,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });

    setTableLoading(false);

    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    /* 엑셀을 위한 */
    setExcelBody({ ...parameter });

    const {
      totalCount,
      myTaskCount,
      interestTaskCount,
      sharedTaskCount,
      taskList,
    } = result.data;


    setTableTotal({
      all: totalCount || 0,
      myTask: myTaskCount || 0,
      interest: interestTaskCount || 0,
      shared: sharedTaskCount || 0,
    });

    const datas = taskList.map((v) => ({
      ...v,
      orgNm: v.mainOrgNm,
      mgr: v.mainEmpNm,
      dev: v.devEmpNm,
      regTimestamp: moment(v.regTimestamp).format("YYYY-MM-DD"),
    }));
    setTableData([...datas]);

    /* AssignListTop 최근 검색 */
    const beforeArray = [];
    const beforeObject = {};

    const initialKeys = Object.keys(initialInputValues);
    const beforeKeys = Object.keys(searchObject).filter((key) =>
      initialKeys.includes(key)
    );

    Object.keys(searchObject).forEach((key) => {
      const data = searchObject[key];
      if (!beforeKeys.includes(key)) return;

      beforeObject[key] = data;

      if (["mgrIds", "devIds"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].empNam);
        }
      } else if (["orgCds"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].codeNm);
        }
      } else if (["workId"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].cdNm);
        }
      } else if (["taskType", "statusCd"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].cdNm);
        }
      } else if (["regFr", "regTo"].includes(key)) {
        beforeArray.push(data);
      } else if (beforeKeys.includes(key)) {
        beforeArray.push(data);
      }
    });
    if (beforeArray.length > 0) {
      const beforeText = beforeArray.slice(0, 2).join(", ");
      localStorage.setItem("beforeSearch", beforeText);
      localStorage.setItem("beforeObject", JSON.stringify(beforeObject));
      setBeforeSearch(beforeText);
    }
  };

  const toggleHeart = () => {
    getTableDatas({ ...searchQuery });
  };

  const convertWholeData = async (search) => {
    const searchObject = queryString.parse(search);

    const { regFr, regTo, regType, mgrIds, devIds } = searchObject;

    // 태스크 유형 정보 세팅
    if (inputValues.taskType) {
      try {
        searchObject.taskType = [...inputValues.taskType];
      } catch {
        delete searchObject.taskType;
      }
    }
    // 부서 정보 세팅
    if (inputValues.orgCds) {
      try {
        searchObject.orgCds = [...inputValues.orgCds];
      } catch {
        delete searchObject.orgCds;
      }
    }
    // 매니저 정보 세팅 mgrIds 
    if (mgrIds) {
      try {
          searchObject.mgrIds = [...inputValues.mgrIds];
      } catch {
        delete searchObject.mgrIds;
      }
    }

    // 개발자 정보 세팅
    if (devIds) {
      try {
          searchObject.devIds = [...inputValues.devIds];
      } catch {
        delete searchObject.devIds;
      }
    }

    // 과제 상태 세팅
    if (inputValues.statusCd) {
      try {
        searchObject.statusCd = [...inputValues.statusCd];
      } catch {
        delete searchObject.statusCd;
      }
    }
    //업무분류 세팅
    if (inputValues.workId) {
      try {
        searchObject.workId = [...inputValues.workId];
      } catch {
        delete searchObject.workId;
      }
    }
    if (inputValues.regFr) {
      const date = moment(inputValues.regFr).format('YYYY-MM-DD');
      if (date === 'Invalid date') {
        delete searchObject.regFr;
      } else {
        searchObject.regFr = date;
      }
    }
    if (inputValues.regTo) {
      const date = moment(inputValues.regTo).format('YYYY-MM-DD');
      if (date === 'Invalid date') {
        delete searchObject.regTo;
      } else {
        searchObject.regTo = date;
      }
    }
    if (regType && !['custom', ''].includes(regType)) {
      // 원래 세팅돼있는 값과 다르면
      const dateObj = convertTypeDate(regType);
      if (dateObj.regFr !== regFr || dateObj.regTo !== regTo) {
        searchObject.regFr = dateObj.regFr;
        searchObject.regTo = dateObj.regTo;
      }
    }
    const tmpObj = {
      ...initialSearchQuery,
      ...searchObject
    }

    return {...tmpObj}
  }

  // query String 변경 될 때마다
  useEffect(() => {
    const preSearchObject = queryString.parse(search);

    if (search.length <= 0) {
      dispatch(resetAssignListValue());
      replaceSearch(initialInputValues);

      return;
    } else if (
      !preSearchObject.page ||
      !preSearchObject.size ||
      !preSearchObject.sort ||
      !preSearchObject.tab
    ) {
      replaceSearch({
        ...searchQuery,
        ...preSearchObject,
      });
      return;
    }

    const { regFr, regTo, regType } = preSearchObject;

    let isReplace = false;
    if (regType && !['custom', ''].includes(regType)) {
      // 원래 세팅돼있는 값과 다르면
      const dateObj = convertTypeDate(regType);
      if (dateObj.regFr !== regFr || dateObj.regTo !== regTo) {
        isReplace = true;
      }
    }
    const fetchData = async () => {
      const tmpObj = await convertWholeData(search);
      if (isReplace) {
        replaceSearch({ ...tmpObj });
      } else {
        setSearchQuery({ ...tmpObj });
        setTabsValue(tmpObj.tab);
        getTableDatas({ ...tmpObj });
      }
    }
  
    fetchData();
  }, [search]);
  
  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      <Container
        style={{
          padding: "30px 0",
          background: "#fff",
        }}
      >
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <AssignListTop
              searchQuery={searchQuery}
              replaceSearch={replaceSearch}
              beforeSearch={beforeSearch}
              setBeforeSearch={setBeforeSearch}
              setSearchQuery={setSearchQuery}
              initialSearchQuery={initialSearchQuery}
              setSortedInfo={setSortedInfo}
              tabsValue={tabsValue}
            />
          </Panel>
        </Collapse>
        <AssignListBottom
          tableTotal={tableTotal}
          tabsValue={tabsValue}
          tableData={tableData}
          //fetchPath={fetchPath}
          searchCondition={searchCondition}
          replaceSearch={replaceSearch}
          searchQuery={searchQuery}
          toggleHeart={toggleHeart}
          tableLoading={tableLoading}
          excelBody={excelBody}
          sortedInfo={sortedInfo}
          setSortedInfo={setSortedInfo}
          serach={search}
        />
      </Container>
    </PageTemplate>
  );
};

export default AssignList;