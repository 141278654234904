import React, { useState, useEffect} from 'react'
import { Modal, Text, Button } from 'components';
import styled from 'styled-components';
import { useIntl } from "react-intl";

const Wrapper = styled(props => <Modal {...props} />)`
    .ant-popover-inner-content {
        overflow-y: unset !important;
        font-size: 11px;
    }
    .ant-popover > .ant-popover-content {
        width: auto !important;
    }
`

const HFWorkflowIframeModal = ({ 
    visible = false,
    type,
    data,
    onCancel,
    confirmModalVisible,
    onOkConfirmButton,
    onCancelConfirmButton,
    onOkIframe
}) => {
    const { formatMessage } = useIntl();
    const url = "https://dreago.net:8070/ibpm-web";
    const [src, setSrc] = useState("");

    useEffect(() => {
        if (!visible) return;

        const getData = async () => {
            if (data.processId === undefined) return;
            // EXECUTEFLAG=Y
            if (type === "insert") {
                setSrc(
                    url +
                    `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${data.processId}?locale=ko&EXECUTEFLAG=Y&processVersion=EDIT`
                );
                } else if (type === "modify") {
                setSrc(
                    url +
                    `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${data.processId}?locale=ko&EXECUTEFLAG=Y&processVersion=EDIT`
                );
                } else if (type === "lookup") {
                setSrc(
                    url +
                    `/openapi/bpm/popup/processPopUpMain?processid=${data.processId}&viewDetailPage=false&LegacyAuthCheckFilterUser=dreago_admin`
                    // `/openapi/bpm/popup/processPopUpMain?processid=${data.processId}&viewDetailPage=false&LegacyAuthCheckFilterUser=dreago_admin`
                );
                }
            };
            getData();

            let height = (type === "lookup" ? 'calc(100vh - 62px)' : 'calc(100vh - 115px)');

            document.getElementsByClassName('hfworkflow_iframe_modal')[0].setAttribute("style", "width:100vw;top:0px;padding-bottom:0px;max-width:100vw;");
            document.getElementsByClassName('hfworkflow_iframe_modal')[0].getElementsByClassName('ant-modal-body')[0].setAttribute("style", `height:${height};`);

    }, [visible])


    return (
        <>
        {   
            type==="modify"
            &&
            <Modal
                    centered
                    visible={confirmModalVisible}
                    width={500}
                    okText={formatMessage({ id: 'T0064' })} // 확인
                    title={formatMessage({ id: 'T1647' })} // 저장 확인
                    onOk={onOkIframe}
                    onCancel={onCancelConfirmButton}
                >
                    <div style={{ textAlign: "center" }}>
                    <Text>
                        {formatMessage({ id: 'T1648' })}
                        {/* 저장 버튼을 클릭하셨나요? */}
                    </Text>
                    <br />
                    <br />
                    <Text style={{ color: "#e54156" }}>
                        {formatMessage({ id: 'T1649' })
                            .split('\n')
                            .map((data,index)=>{
                            return (
                                <span key={index}>
                                    {data}
                                    <br />
                                </span>
                            )
                        })}
                        {/* * 저장 하지 않고 확인 버튼을 클릭하실 경우
                        수정한 Workflow가 저장되지 않습니다. */}
                    </Text>
                    </div>
            </Modal>
        }
        <Wrapper
            visible={visible}
            className='hfworkflow_iframe_modal'
            title='Workflow'
            okText={formatMessage({ id: 'T0064' })} // 확인
            onOk={onOkConfirmButton}
            onCancel={onCancel}
            footer={type==="modify"
                ?
                [ <Button type="primary" onClick={onOkConfirmButton}>
                    {formatMessage({ id: 'T0064' })}{/* 확인 */}
                </Button>,
                <Button onClick={onCancel}>
                    {formatMessage({ id: 'T0051' })}{/* 취소 */}
                </Button>]:null}
        >
                <iframe
                style={{
                    border: 0,
                    margin: "0 auto",
                    display: "block",
                }}
                width="100%"
                height='100%'
                title="workflow"
                id="workflow"
                className="workflow"
                src={src}
            />
        </Wrapper>
        </>
    )
}

export default HFWorkflowIframeModal
