import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import sendNoti from "utils/sendNoti";
import useFormatMessage from 'hooks/useFormatMessage';
import { setVisible } from "store/loading/action";
import { changeScheduleStatus, setTaskData } from "store/assignLookup/action";
import { 
    DevicePool, 
    PermissionComponent, 
    ScheduleUserInputModal, 
    EmailTriggerModal } from 'components';
import ScheduleEnter from "./ScheduleEnter";
import ScheduleStatus from "./ScheduleStatus";
import ScheduleProdStatus from "./ScheduleProdStatus";

/**
 * 과제 조회 > 운영 현황 총괄
 * Filename: AssignLookupProdStatus.js
 * Description: 과제 조회 페이지 하단에 운영현황 탭
 */

const initUserInputValues = [{ key: "", value: "" }];

const AssignLookupProdStatus = () => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();
    const duplicationKeyExist = formatMessage({ id: 'T0149' }); // 중복된 키가 있습니다.
    const devicePoolText = formatMessage({ id: 'T1060' }); // Device Pool

    const profile = useSelector(state => state.get('auth').get('profile'));
    const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
    const assignLookup = useSelector((state) => state.get(getUniqueKey("assignLookup")));

    const dispatch = useDispatch();

    const crCodeInfo = crInfo.find((v) => v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD")?.crCd;

    const getCrType = crInfo.find((v) => v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD")?.crXEnv;

    const [editable, setEditable] = useState(false);
    const [workItemList, setWorkItemList] = useState([]);
    const [scheduleList, setScheduleList] = useState([]);
    const [emailTriggerList, setEmailTriggerList] = useState([]);
    const [emailTriggerVal, setEmailTriggerVal] = useState("");
    const [userInputVisible, setUserInputVisible] = useState(false);
    const [devicePoolVisible, setDevicePoolVisible] = useState(false);
    const [emailTriggerVisible, setEmailTriggerVisible] = useState(false);
    const [emailTriggerStatus, setEmailTriggerStatus] = useState(false);
    const [chatbotStatus, setChatbotStatus] = useState("deactivate");

    /* 스케줄 등록 > 스케줄 유형 > 반복 실행 > 일, 주, 월 */
    const [operValues, setOperValues] = useState({
        crTypeValue: null,
        devicePool: null,
        timeZone: "Asia/Seoul",
        radioScheduleType: '',
        scheduleCycle: "DAILY",
    });

    const [rDaily, setRDaily] = useState({ dailyInterval: 1 });
    const [rWeekly, setRWeekly] = useState({ weeklyInterval: 1, weeklyDay: "" });
    const [rMonthly, setRMonthly] = useState({
        month: "",
        scheduleType: "DAILY",
        date: 1,
        week: "FIRST",
        day: "MON",
    });

    /* 스케줄 등록 > 스케줄 유형 공통 */
    const [commonSchedules, setCommonSchedules] = useState({
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        repeatCheck: false,
        repeatTime: 1,
        repeatCycle: "MINUTES",
    });
    
    const [userInputValues, setUserInputValues] = useState([...initUserInputValues]);

    const radioRepeatField = {
        endDate: {
            ref: useRef(null),
            message: formatMessage({ id: "T0540" }), // 종료 날짜
        }
    }

    const checkboxRepeatField = {
        endTime: {
            ref: useRef(null),
            message: formatMessage({ id: "T0541" }), // 종료 시간
        }
    }

    const requireFields = {
        devicePool: {
            ref: useRef(null),
            message: formatMessage({ id: "T1060" }), // Device Pool
        },
        timeZone: {
            ref: useRef(null),
            message: formatMessage({ id: "T1137" }), // Time Zone
        },
        startDate: {
            ref: useRef(null),
            message: formatMessage({ id: "T0542" }), // 시작 날짜
        },
        startTime: {
            ref: useRef(null),
            message: formatMessage({ id: "T0543" }), // 시작 시간
        }
    };

    const onOkUserInput = async () => {
        // userInputValues에 key 값이 겹치면 마지막에 입력된 것만 저장되므로 알럿 띄워줘야해 
        let flag = false;
        userInputValues.reduce((acc, cur) => {
            if (acc.findIndex(({ key }) => key === cur.key) === -1) {
                acc.push(cur);
            } else {
                flag = true;
            }
            return acc;
        }, []);
        if (flag) return alert(duplicationKeyExist);

        setUserInputVisible(false);

        const scheduleDatas = {
            ...operValues,
            ...rDaily,
            ...rWeekly,
            ...rMonthly,
            ...commonSchedules,
        };
        
        let parameter;
        parameter = setRequestParameter(scheduleDatas);

        if(editable) {
            const result = await fetchAbsolute(
                "put",
                `/task/oracle/modifyTaskSchedule?x-environment=PRD`,
                {
                    data: {
                        ...parameter,
                    },
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            // setDisabledBtn(false);
            if (result.error) {
                const val = formatMessage({ id: "T0179" }).split("\n").map(data => {
                    return data
                });
                return alert(val.join('\n'));
            }
            // 스케줄 조건을 다시 한 번 확인해주세요.
            // 예) 반복 실행에 속한 단위가 시작날짜와 종료날짜 사이에 있어야 합니다.
            alert(formatMessage({ id: 'T0186' })) // 스케줄 수정이 완료되었습니다.
    
        } else {
            const result = await fetchAbsolute(
                "post", 
                `/task/oracle/addTaskSchedule?crType=${getCrType}`
                , {
                data: { ...parameter },
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (result.error) {
                // setDisabledBtn(false);
                const val = formatMessage({ id: "T0179" }).split("\n").map(data => {
                    return data
                });
                // 스케줄 조건을 다시 한 번 확인해주세요.
                // 예) 반복 실행에 속한 단위가 시작날짜와 종료날짜 사이에 있어야 합니다.
                alert(val.join('\n'));
                return dispatch(setVisible(false));
            }
            // setDisabledBtn(false);
            alert(formatMessage({ id: 'T0180' })); // 스케줄 등록이 완료되었습니다
        }

        dispatch(setVisible(true));

        const chgSchduleStatus = await fetchAbsolute(
            "post",
            "/task/oracle/chgTask",
            {
                data: {
                    taskId: assignLookup.taskData?.taskId,
                    chgCd: "T40",
                    chgCdDetail: "T42", // 스케줄 등록
                    chgEmpNo: profile.EMP_NO,
                    chgDetailDesc: formatMessage({ id: 'T0547' }), // 스케줄 등록
                },
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (chgSchduleStatus.error) {
            alert(formatMessage({ id: 'T0181' })); // 스케줄 등록에 실패하였습니다.
            return dispatch(setVisible(false));
        }
        // // 등록 버튼 누르면 device pool 초기화
        setOperValues({...operValues, devicePool:null});
        sendNoti('TP13', assignLookup.taskData?.taskId);
        dispatch(setTaskData({ ...chgSchduleStatus.data }));
        dispatch(setVisible(false));
        fetchScheduleList();
        
        onClickResetSchedule();
        setEditable(false);
    };

    const onCancelUserInput = () => {
        // setUserInputValues([{ key: "", value: "" }]);
        setUserInputVisible(false);
    };

    const onChangeCycleDaily = (e) => setRDaily({ dailyInterval: e });

    const onSelectMonthlyDatas = (label, value) => {
        setRMonthly({
            ...rMonthly,
            [label]: value,
        });
    };

    const onChangeReapeatWeekly = (label, value) => {
        setRWeekly({
            ...rWeekly,
            [label]: value,
        });
    };

    const onChangeCommonDatas = (label, value) => {
        setCommonSchedules({
            ...commonSchedules,
            [label]: value,
        });
    };

    const onChangeOperValues = (label, value) => {
        setOperValues({
            ...operValues,
            [label]: value,
        });
    };

    /* 초기화 */
    const onClickResetSchedule = () => {
        //const typeCondition = assignLookup.taskData?.taskType === 'DIY' || assignLookup.taskData?.mgrOpYn === 'Y' ? 'immediately' : 'oneTime';
        
        // 수정 상태이면 automationName 넣어주기
        if(editable) {
            setOperValues({
                crTypeValue: null,
                devicePool: '',
                timeZone: "Asia/Seoul",
                radioScheduleType: 'immediately',
                scheduleCycle: "DAILY",
                name: operValues.name
            });
        } else {
            setOperValues({
                crTypeValue: null,
                devicePool: '',
                timeZone: "Asia/Seoul",
                radioScheduleType: 'immediately',
                scheduleCycle: "DAILY",
            });
        }

        setRDaily({ dailyInterval: 1 });
        setRWeekly({ weeklyInterval: 1, weeklyDay: "" });
        setRMonthly({
            month: "",
            scheduleType: "DAILY",
            date: 1,
            week: "FIRST",
            day: "MON",
        });
        /* 스케줄 등록 > 스케줄 유형 공통 */
        setCommonSchedules({
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            repeatCheck: false,
            repeatTime: 1,
            repeatCycle: "MINUTES"
            // repeatCycle: "HOURS",
        });
        setChatbotStatus("deactivate");
        setEmailTriggerVal("");
        //setRadioButtonStatus(false);
        setUserInputValues([{ key: "", value: "" }]);
    };

    // Request Parameter 생성 함수
    const setRequestParameter = (data) => {
        let basicParam;
        let addParam;
        let repeatParam;
        let automationName = { automationName: data.name };

        // userInputValues 데이터 parameter 형식에 맞춰 가공
        let tmpUserInput = {};
        if (userInputValues?.length > 0 && !!userInputValues[0].key) {
            tmpUserInput = userInputValues.reduce(
                (acc, cur) => Object.assign(acc, { [cur.key]: cur.value }),
                {}
            );
        }

        if (data.radioScheduleType === "repeat") {
            basicParam = {
                taskId: assignLookup.taskData?.taskId,
                cr: crCodeInfo,
                poolName: data.devicePool.dpNm || data.devicePool,
                timeZone: "Asia/Seoul",
                scheduleType: data.scheduleCycle,
                startDate: data.startDate?.format("YYYY-MM-DD"),
                endDate: data.endDate?.format("YYYY-MM-DD"),
                startTime: data.startTime?.format("HH:mm"),
                repeatEnabled: data.repeatCheck,
                emailScheduleSeq: data.emailScheduleSeq,
                userInput: {...tmpUserInput}
            };
        } else if (data.radioScheduleType === "TRIGGER_CHATBOT" || data.radioScheduleType === "TRIGGER_EMAIL") {
            basicParam = {
                taskId: assignLookup.taskData?.taskId,
                cr: crCodeInfo,
                poolName: data.devicePool.dpNm || data.devicePool,
                timeZone: "Asia/Seoul",
                scheduleType: data.radioScheduleType,
                startDate: moment(data.startDate)?.format("YYYY-MM-DD"),
                endDate: moment(data.startDate)?.format("YYYY-MM-DD"),
                startTime: moment(data.startTime, "HH:mm").format("HH:mm"),
                repeatEnabled: data.repeatCheck,
                emailScheduleSeq: data.emailScheduleSeq,
            };
            if (data.radioScheduleType === "TRIGGER_CHATBOT")
                basicParam = {
                    ...basicParam,
                    activate: chatbotStatus,
                };
            else {
                basicParam = {
                    ...basicParam,
                    emailInfoSeq: emailTriggerVal,
                };
            }
        } else if(data.radioScheduleType === "immediately") {
            basicParam = {
                taskId: assignLookup.taskData?.taskId,
                cr: crCodeInfo,
                poolName: data.devicePool.dpNm || data.devicePool,
                timeZone: "Asia/Seoul",
                scheduleType: "INSTANT",
                userInput: {...tmpUserInput}
            };
        } else {
            basicParam = {
                taskId: assignLookup.taskData?.taskId,
                cr: crCodeInfo,
                poolName: data.devicePool.dpNm || data.devicePool,
                timeZone: "Asia/Seoul",
                scheduleType: "NONE",
                startDate: moment(data.startDate)?.format("YYYY-MM-DD"),
                endDate: moment(data.startDate)?.format("YYYY-MM-DD"),
                startTime: moment(data.startTime, "HH:mm").format("HH:mm"),
                repeatEnabled: data.repeatCheck,
                emailScheduleSeq: data.emailScheduleSeq,
                userInput: {...tmpUserInput}
            };
        }

        if (data.repeatCheck) {
            repeatParam = {
                repeatOccurrence: {
                    runEvery: data.repeatTime,
                    timeUnit: data.repeatCycle,
                    endTime: moment(data.endTime).format("HH:mm"),
                },
            };
        }

        if (data.radioScheduleType === "repeat") {
            if (data.scheduleCycle === "DAILY") {
                addParam = {
                    dailyRecurrence: {
                        interval: data.dailyInterval,
                    },
                };
            } else if (data.scheduleCycle === "WEEKLY") {
                addParam = {
                    weeklyRecurrence: {
                        interval: data.weeklyInterval,
                        daysOfWeek: data.weeklyDay, //[MON,TUE]
                    },
                };
            } else if (data.scheduleCycle === "MONTHLY") {
                if (data.scheduleType === "DAILY") {
                    addParam = {
                        monthlyDateRecurrence: {
                            dateOfMonth: data.date,
                            monthsOfYear: data.month,
                        },
                    };
                } else {
                    addParam = {
                        monthlyWeekDayRecurrence: {
                            weekOfMonth: data.week,
                            dayOfWeek: data.day,
                            monthsOfYear: data.month,
                        },
                    };
                }
            }
        }

        let parameter = Object.assign(
            {},
            basicParam,
            repeatParam,
            addParam,
            automationName
        );

        return parameter;
    };

    // email trigger button event
    const onClickRegTrigger = async () => {
        if ((!operValues.devicePool || operValues.devicePool.length <= 0)) {
            return alert(fmMessage({ id: 'T0128', values: { field: "Device pool" } }));
        }

        let parameter = {
            poolName: operValues.devicePool.dpNm ? operValues.devicePool.dpNm : operValues.devicePool,
            cr: crCodeInfo,
            timeZone: operValues.timeZone,
            taskId: assignLookup.taskData?.taskId,
            scheduleType: operValues.radioScheduleType,
        }
        if (operValues.radioScheduleType === "TRIGGER_EMAIL") {
            if (emailTriggerVal.length < 1) {
                return alert("Email Trigger Name을 선택해주세요");
            }
            parameter = {
                ...parameter,
                emailInfoSeq: emailTriggerVal
            }
        } else {
            parameter = {
                ...parameter,
                activate: chatbotStatus
            }
        }

        const result = await fetchAbsolute("post", `/task/oracle/addTaskSchedule?crType=${getCrType}`, {
            data: parameter,
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (result.data === "ChatBot 트리거가 이미 생성되어 있습니다.!!!" || result.data === "이메일 트리거가 이미 생성되어 있습니다.!!!") {
            return alert("Trigger가 이미 등록 되어 있습니다.");
        }
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const chgSchduleStatus = await fetchAbsolute(
            "post",
            "/task/oracle/chgTask",
            {
                data: {
                    taskId: assignLookup.taskData?.taskId,
                    chgCd: "T40",
                    chgCdDetail: "T42", // 스케줄 등록
                    chgEmpNo: profile.EMP_NO,
                    chgDetailDesc: formatMessage({ id: 'T0547' }), // 스케줄 등록
                },
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        if (chgSchduleStatus.error) {
            alert(formatMessage({ id: 'T0181' })); // 스케줄 등록에 실패하였습니다.
            return dispatch(setVisible(false));
        }
        alert("Trigger Event를 저장했습니다.");
        onClickResetSchedule();
        fetchScheduleList();
    };

    /* 스케줄 등록 */
    const onClickRegSchedule = async () => {
        // setDisabledBtn(true);
        const scheduleDatas = {
            ...operValues,
            ...rDaily,
            ...rWeekly,
            ...rMonthly,
            ...commonSchedules,
        };

        // 기본
        for (const key in requireFields) {
            const field = requireFields[key];

            if ((!scheduleDatas[key] || Object.keys(scheduleDatas[key]).length <= 0) && operValues.radioScheduleType !== "immediately") {
                try {
                    // setDisabledBtn(false);
                    field.ref.current.focus();
                    return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                    //`${field.message} 항목은 필수 입력입니다.`);
                } catch {
                    // setDisabledBtn(false);
                    return alert(formatMessage({ id: 'T0181' })); // 스케줄 등록에 실패하였습니다.
                }
            }
        }

        // 즉시실행 선택시 && devicepool 선택 X
        if (operValues.radioScheduleType === "immediately" && (operValues.devicePool === null || operValues.devicePool === "")) {
            // setDisabledBtn(false);
            return alert(fmMessage({ id: 'T0128', values: { field: devicePoolText } }));
            //`${field.message} 항목은 필수 입력입니다.`);
        }

        // 반복 실행
        if (operValues.radioScheduleType === "repeat") {
            for (const key in radioRepeatField) {
                const field = radioRepeatField[key];
                if (Object.keys(scheduleDatas[key]).length <= 0) {
                    try {
                        // setDisabledBtn(false);
                        field.ref.current.focus();
                        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                        //`${field.message} 항목은 필수 입력입니다.`);
                    } catch (e) {
                        // setDisabledBtn(false);
                        return formatMessage({ id: 'T0181' }); // 스케줄 등록에 실패하였습니다.
                    }
                }
            }
        }

        // 반복
        if (commonSchedules.repeatCheck) {
            for (const key in checkboxRepeatField) {
                const field = checkboxRepeatField[key];
                if (Object.keys(scheduleDatas[key]).length <= 0) {
                    try {
                        // setDisabledBtn(false);
                        field.ref.current.focus();
                        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                        //`${field.message} 항목은 필수 입력입니다.`);
                    } catch (e) {
                        // setDisabledBtn(false);
                        return formatMessage({ id: 'T0181' }); // 스케줄 등록에 실패하였습니다.
                    }
                }
            }
        }

        setUserInputVisible(true);
        setUserInputValues([{ key: "", value: "" }]);
    };

    /* 스케줄 삭제 버튼 기능 */
    const schduleDeleteBtn = async (data) => {
        const result = window.confirm(formatMessage({ id: 'T0321' })); //해당 과제의 스케줄을 삭제하시겠습니까?

        if (result) {
            dispatch(setVisible(true));
            const deleteSchedule = await fetchAbsolute(
                "delete",
                `/task/oracle/removeTaskSchedule?x-environment=PRD&taskId=${assignLookup.taskData?.taskId}&automationNm=${data.name}`
            );
            if (deleteSchedule.error) {
                alert(formatMessage({ id: 'T0184' })); // 스케줄 삭제에 실패하였습니다.
                return dispatch(setVisible(false));

            }
            alert(formatMessage({ id: 'T0183' })); // 스케줄이 삭제되었습니다.

            const chgSchduleStatus = await fetchAbsolute("post", "/task/oracle/chgTask", {
                data: {
                    taskId: assignLookup.taskData?.taskId,
                    chgCd: "T40",
                    chgCdDetail: "T44", // 스케줄 삭제
                    chgEmpNo: profile.EMP_NO,
                    chgDetailDesc: formatMessage({ id: 'T1033' }), // 스케줄 삭제
                },
                headers: {
                    "Content-Type": "application/json",
                },
            });
            if (chgSchduleStatus.error) {
                return alert(formatMessage({ id: 'T0182' })); // 스케줄 삭제에 실패하였습니다.
            }
            fetchScheduleList();
        }
        dispatch(setVisible(false));
        sendNoti('TP15', assignLookup.taskData?.taskId);
        setEditable(false);
        onClickResetSchedule();
    };

    /* 스케줄 현황 > 수정 버튼 : 등록에 버튼 변경, AA 정보 셋팅 */
    const onClickEditStatusBtn = async (data) => {
        // await fetchEmailTriggerList();

        setEditable(true);
        try {
            const dataInfo = await fetchAbsolute(
                "get",
                `/task/oracle/taskSchedule?x-environment=PRD&taskId=${assignLookup.taskData?.taskId}&automationName=${data.name}`
            );

            if (dataInfo.data.list[0]?.scheduleType === "TRIGGER_EMAIL") {
                setOperValues({
                    crTypeValue: dataInfo.data.list[0]?.cr,
                    devicePool: dataInfo.data.list[0]?.poolName,
                    timeZone: dataInfo.data.list[0]?.timeZone,
                    radioScheduleType: dataInfo.data.list[0]?.scheduleType,
                    scheduleCycle: dataInfo.data.list[0]?.scheduleType === "NONE" ? "DAILY" : dataInfo.data.list[0]?.scheduleType === "TRIGGER_EMAIL" ? "DAILY" : dataInfo.data.list[0]?.scheduleType,
                    name: dataInfo.data.list[0]?.name,
                    emailScheduleSeq: dataInfo.data.list[0]?.emailScheduleSeq
                });
                onChangeEmailTrigger(dataInfo.data.list[0]?.emailInfoSeq)
            }
            else if (dataInfo.data.list[0]?.scheduleType === "TRIGGER_CHATBOT") {
                setOperValues({
                    crTypeValue: dataInfo.data.list[0]?.cr,
                    devicePool: dataInfo.data.list[0]?.poolName,
                    timeZone: dataInfo.data.list[0]?.timeZone,
                    radioScheduleType: dataInfo.data.list[0]?.scheduleType,
                    scheduleCycle: dataInfo.data.list[0]?.scheduleType === "NONE" ? "DAILY" : dataInfo.data.list[0]?.scheduleType === "TRIGGER_CHATBOT" ? "DAILY" : dataInfo.data.list[0]?.scheduleType,
                    name: dataInfo.data.list[0]?.name,
                    emailScheduleSeq: dataInfo.data.list[0]?.emailScheduleSeq
                });
                setChatbotStatus(dataInfo.data.list[0]?.activate);
            }
            else {
                //userInput값이 있으면 userInputValues에 세팅하고 없으면 기본값으로 세팅해줌
                if(dataInfo.data.list[0].userInput) {
                    let userInput = dataInfo.data.list[0].userInput;
                    let tmpArr = [];
                    Object.keys(userInput).map(val => {
                        tmpArr.push({key:val, value:userInput[val]})
                    })
                    setUserInputValues([...tmpArr]);
                } else {
                    setUserInputValues([{ key: "", value: "" }]);
                }

                setOperValues({
                    crTypeValue: dataInfo.data.list[0]?.cr,
                    devicePool: dataInfo.data.list[0]?.poolName,
                    timeZone: dataInfo.data.list[0]?.timeZone,
                    radioScheduleType: dataInfo.data.list[0]?.scheduleType === "NONE" ? "oneTime" : "repeat",
                    scheduleCycle: dataInfo.data.list[0]?.scheduleType === "NONE" ? "DAILY" : dataInfo.data.list[0]?.scheduleType,
                    name: dataInfo.data.list[0]?.name,
                    emailScheduleSeq: data.emailScheduleSeq,
                });
                setRDaily({ dailyInterval: dataInfo.data.list[0].dailyRecurrence?.interval ? dataInfo.data.list[0].dailyRecurrence?.interval : 1 });

                setRWeekly({
                    weeklyInterval: dataInfo.data.list[0].weeklyRecurrence?.interval ? dataInfo.data.list[0].weeklyRecurrence?.interval : 1,
                    weeklyDay: dataInfo.data.list[0].weeklyRecurrence?.daysOfWeek,
                });

                setRMonthly({
                    month: dataInfo.data.list[0].monthlyDateRecurrence ? dataInfo.data.list[0]?.monthlyDateRecurrence?.monthsOfYear : dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.monthsOfYear,
                    scheduleType: dataInfo.data.list[0]?.scheduleType,
                    date: dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dateOfMonth ? dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dateOfMonth : 1,
                    week: dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.weekOfMonth ? dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.weekOfMonth : 'FIRST',
                    day: dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dayOfWeek ? dataInfo.data.list[0]?.monthlyWeekDayRecurrence?.dayOfWeek : 'MON',
                });

                setCommonSchedules({
                    startDate: moment(dataInfo.data.list[0]?.startDate),
                    startTime: moment(dataInfo.data.list[0]?.startTime, "HH:mm"),
                    endDate: moment(dataInfo.data.list[0]?.endDate),
                    endTime: dataInfo.data.list[0]?.repeatOccurrence?.endTime ? moment(dataInfo.data.list[0]?.repeatOccurrence?.endTime, "HH:mm") : moment('00:00', 'HH:mm'),
                    repeatCheck: JSON.parse(dataInfo.data.list[0]?.repeatEnabled),
                    repeatTime: dataInfo.data.list[0]?.repeatOccurrence?.runEvery ? dataInfo.data.list[0]?.repeatOccurrence?.runEvery : 1,
                    repeatCycle: dataInfo.data.list[0]?.repeatOccurrence?.timeUnit ? dataInfo.data.list[0]?.repeatOccurrence?.timeUnit : "MINUTES",
                });
            }

        } catch (err) {
            return alert(formatMessage({ id: 'T0185' })); // 스케줄 정보 가져오는 도중 에러가 발생했습니다.
        }
    };

    /* 스케줄 수정 > 수정(실제 수정되는 버튼) */
    const onClickModifySchedule = async () => {
        const scheduleDatas = {
            ...operValues,
            ...rDaily,
            ...rWeekly,
            ...rMonthly,
            ...commonSchedules,
        };
    
        // 기본
        for (const key in requireFields) {
            const field = requireFields[key];

            if ((!scheduleDatas[key] || Object.keys(scheduleDatas[key]).length <= 0) && operValues.radioScheduleType !== "immediately") {
                try {
                    // setDisabledBtn(false);
                    field.ref.current.focus();
                    return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                    //`${field.message} 항목은 필수 입력입니다.`);
                } catch {
                    // setDisabledBtn(false);
                    return alert(formatMessage({ id: 'T0181' })); // 스케줄 등록에 실패하였습니다.
                }
            }
        }

        // 즉시실행 선택시 && devicepool 선택 X
        if (operValues.radioScheduleType === "immediately" && (operValues.devicePool === null || operValues.devicePool === "")) {
            // setDisabledBtn(false);
            return alert(fmMessage({ id: 'T0128', values: { field: devicePoolText } }));
            //`${field.message} 항목은 필수 입력입니다.`);
        }

        // 반복 실행
        if (operValues.radioScheduleType === "repeat") {
            for (const key in radioRepeatField) {
                const field = radioRepeatField[key];
                if (Object.keys(scheduleDatas[key]).length <= 0) {
                    try {
                        // setDisabledBtn(false);
                        field.ref.current.focus();
                        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                        //`${field.message} 항목은 필수 입력입니다.`);
                    } catch (e) {
                        // setDisabledBtn(false);
                        return formatMessage({ id: 'T0181' }); // 스케줄 등록에 실패하였습니다.
                    }
                }
            }
        }

        // 반복
        if (commonSchedules.repeatCheck) {
            for (const key in checkboxRepeatField) {
                const field = checkboxRepeatField[key];
                if (Object.keys(scheduleDatas[key]).length <= 0) {
                    try {
                        // setDisabledBtn(false);
                        field.ref.current.focus();
                        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                        //`${field.message} 항목은 필수 입력입니다.`);
                    } catch (e) {
                        // setDisabledBtn(false);
                        return formatMessage({ id: 'T0181' }); // 스케줄 등록에 실패하였습니다.
                    }
                }
            }
        }

        setUserInputVisible(true);
    };

    /* Device Pool List for COE */
    const onClickDevicePoolModal = () => setDevicePoolVisible(true);
    const onCancelDevicePoolModal = () => setDevicePoolVisible(false);
    const onOkDevicePoolModal = (data) => {
        // if(allClassList.findIndex(v => v.upCd === selectObj.cd) !== -1) {
        //     return alert(selectAlert);
        // }
        // onOk([selectObj]);
        setOperValues({ ...operValues, devicePool: data })
        setDevicePoolVisible(false);
    }

    /* 스케줄 등록 취소 버튼 */
    const onCancelModifySchedule = () => {
        onClickResetSchedule();
        setEditable(false);
    };

    const fetchScheduleList = async () => {
        const result = await fetchAbsolute("get", `/task/oracle/taskSchedules?x-environment=PRD&taskId=${assignLookup.taskData?.taskId}`);
        if (result.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
        const datas = result.data.list?.map((v) => ({
            ...v,
            createdOn: moment(v.createdOn).format("YYYY-MM-DD"),
        }));
        setScheduleList([...datas]);
    }

    const onChangeEmailTrigger = (e) => {
        setEmailTriggerVal(e);
    }

    const onChangeChatTrigger = (e) => {
        setChatbotStatus(e.target.value);
    }

    const onCancleEmailTrigModal = () => {
        setEmailTriggerVisible(false);
    }

    const onClickEmailTriggerInfo = () => {
        if (emailTriggerVal.length < 1) return alert(`Email Trigger Name${formatMessage({ id: 'T1311' })}`); // Email Trigger Name을 선택해주세요
        setEmailTriggerVisible(true);
    }

    const fetchEmailTriggerList = async () => {
        const TriggerResult = await fetchAbsolute("get", `/admin/trigger/email/list`);
        if (TriggerResult.error) {
            return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        }
        setEmailTriggerList([...TriggerResult.data.lists]);
    }

    useEffect(() => {
        if (!assignLookup.taskData?.taskId) return;
        /* DIY DEVICE POOL 가져오는 API */
        const fetchDIYDevicePool = async () => {
            setOperValues({
                ...operValues,
                radioScheduleType: 'immediately',
                // radioScheduleType: assignLookup.taskData?.mgrOpYn === 'Y' ? 'immediately' : 'oneTime',
            })
        };
        fetchDIYDevicePool();
        fetchScheduleList();
    }, [assignLookup.taskData?.taskId]);

    useEffect(() => {
        const bool = scheduleList.length > 0 || workItemList.findIndex(v => moment(v.startDateTime).format('YYYYMMDD') === moment().format('YYYYMMDD')) !== -1;
        dispatch(changeScheduleStatus(bool));
    }, [scheduleList, workItemList]);

    useEffect(() => {
        if (emailTriggerStatus) {
            fetchEmailTriggerList();
        }
    }, [emailTriggerStatus, emailTriggerVal]);

    return (
        <div className="status_box__wrap lookup_body__box">
            <PermissionComponent fnCode='FN21'>
                <ScheduleEnter
                    operValues={operValues}
                    commonSchedules={commonSchedules}
                    rDaily={rDaily}
                    rWeekly={rWeekly}
                    rMonthly={rMonthly}
                    editable={editable}
                    setEditable={setEditable}
                    requireFields={requireFields}
                    radioRepeatField={radioRepeatField}
                    checkboxRepeatField={checkboxRepeatField}
                    emailTriggerList={emailTriggerList}
                    emailInfoSeq={emailTriggerVal}
                    setEmailTriggerStatus={setEmailTriggerStatus}
                    chatbotStatus={chatbotStatus}
                    onChangeOperValues={onChangeOperValues}
                    onChangeCommonDatas={onChangeCommonDatas}
                    onChangeCycleDaily={onChangeCycleDaily}
                    onChangeReapeatWeekly={onChangeReapeatWeekly}
                    onSelectMonthlyDatas={onSelectMonthlyDatas}
                    onClickRegSchedule={onClickRegSchedule}
                    onClickModifySchedule={onClickModifySchedule}
                    onCancelModifySchedule={onCancelModifySchedule}
                    onClickResetSchedule={onClickResetSchedule}
                    onClickDevicePoolModal={onClickDevicePoolModal}
                    onChangeEmailTrigger={onChangeEmailTrigger}
                    onChangeChatTrigger={onChangeChatTrigger}
                    onClickEmailTriggerInfo={onClickEmailTriggerInfo}
                    onClickRegTrigger={onClickRegTrigger}
                />
            </PermissionComponent>
            <ScheduleStatus
                onClickEditStatusBtn={onClickEditStatusBtn}
                schduleDeleteBtn={schduleDeleteBtn}
                scheduleList={scheduleList}

            />
            <ScheduleProdStatus
                // taskData={taskData}
                dataSource={workItemList}
                setDataSource={setWorkItemList}
            />
            <DevicePool
                visible={devicePoolVisible}
                onOk={onOkDevicePoolModal}
                onCancel={onCancelDevicePoolModal}
                crCode={crCodeInfo}
                defaultData={operValues.devicePool === null || operValues.devicePool === ""? [] : [operValues.devicePool]}
            />
            <ScheduleUserInputModal
                visible={userInputVisible}
                onOk={onOkUserInput}
                onCancel={onCancelUserInput}
                userInputValues={userInputValues}
                setUserInputValues={setUserInputValues}
            />
            <EmailTriggerModal
                visible={emailTriggerVisible}
                onCancel={onCancleEmailTrigModal} 
                emailInfoSeq={emailTriggerVal}
            />
        </div>
    );
};

export default AssignLookupProdStatus;