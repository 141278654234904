import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import { FormTable, Text, Input, Button } from 'components';
import fetchAbsolute from 'utils/fetchAbsolute';
import moment from 'moment';
import useFormatMessage from 'hooks/useFormatMessage';

const OrganizationDetails = ({
  initialChangeInput,
  keyVal,
  cancelKey,
  selectedDatas,
  isCompanyCdDisabled,
  isOrgCdDisabled,
  flatDepartmentList,
  requireFields,
  getDepartmentList,
  treeData,
}) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [changeInput, setChangeInput] = useState({ ...initialChangeInput });

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    // 날짜에는 정규식 적용 YYYY-MM-DD
    if (['orgStartDt', 'orgEndDt'].includes(name)) {
      const dateFormat = value.replace(/[^0-9]/g, '').replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
      setChangeInput({
        ...changeInput,
        [name]: dateFormat,
      });
    } else if (selectedDatas.companyCd === '' && name === 'companyCd') {
      setChangeInput({
        ...changeInput,
        [name]: value,
        'codeId': value,
      });
    } else {
      setChangeInput({
        ...changeInput,
        [name]: value,
      });
    }
  };

  const onCancel = () => {
    cancelKey(-1);
  };

  const onClickDelete = () => {
    onCancel();
  };

  // 수정 버튼
  const onClickEdit = async () => {
    const startDate = moment(changeInput.orgStartDt);
    const endDate = moment(changeInput.orgEndDt);

    /* 날짜 형식 확인 */
    if (endDate - startDate < 0) {
      return alert(formatMessage({ id: 'T1249' })); // 종료일이 시작일보다 과거일 수 없습니다. 
    }
    if ((!startDate._isValid || !endDate._isValid) || (changeInput.orgStartDt.length !== 10 || changeInput.orgEndDt.length !== 10)) {
      return alert(formatMessage({ id: 'T1250' })); // 날짜를 확인해주세요. T1250
    }

    // 빈칸 검사
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!changeInput[key] || Object.keys(changeInput[key]).length <= 0) {
        field.ref.current.focus();
        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
        // return alert(`${field.message} 항목은 필수 입력입니다.`);
      }
    }

    // 기존 정렬순서와 겹치면 children을 뺏어가서 확인해줘야 한다.
    const checkDuplicateSortNum = treeData.filter(v => +v.orgSortNum === +changeInput.orgSortNum);

    // 레벨이 1인 경우에만 (최상위만 중복 안되게)
    if (selectedDatas.codeId === selectedDatas.companyCd && checkDuplicateSortNum.length > 0) {
      return alert('정렬 번호는 중복될 수가 없습니다.');
    };

    const result = await fetchAbsolute('put', `/org/orgListModify`, {
      data: [{
        "companyCd": changeInput.companyCd, // 회사코드
        "orgCd": changeInput.codeId, // 조직코드
        "prntOrgCd": changeInput.codeIdUpper,
        "orgLvlNum": changeInput.codeLevel,
        "orgSortNum": changeInput.orgSortNum, // 정렬
        "orgNam": changeInput.codeNm, // 조직명 
        "orgStartDt": moment(changeInput.orgStartDt).format('YYYYMMDD'), // 조직 시작일
        "orgEndDt": moment(changeInput.orgEndDt).format('YYYYMMDD'), // 조직 종료일
      }],
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.status === 200 || result.status === 201) {
      getDepartmentList();
      alert(formatMessage({ id: 'T0234' })); //저장되었습니다.
    } else {
      throw new Error(result.status);
    }
  }

  const onClickSave = async () => {
    // 빈칸 검사
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!changeInput[key] || Object.keys(changeInput[key]).length <= 0) {
        field.ref.current.focus();
        return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
        // return alert(`${field.message} 항목은 필수 입력입니다.`);
      }
    }

    const startDate = moment(changeInput.orgStartDt);
    const endDate = moment(changeInput.orgEndDt);

    // 저장 전에 조직코드가 겹치는지 확인
    const checkCodeId = flatDepartmentList.filter(list => list.codeId === changeInput.codeId).length;
    if (checkCodeId === 1) return alert(formatMessage({ id: 'T1251' })); // 중복된 조직코드입니다.
    /* 날짜 형식 확인 */
    if (endDate - startDate < 0) {
      return alert(formatMessage({ id: 'T1249' })); // 종료일이 시작일보다 과거일 수 없습니다. 
    }
    if ((!startDate._isValid || !endDate._isValid) || (changeInput.orgStartDt.length !== 10 || changeInput.orgEndDt.length !== 10)) {
      return alert(formatMessage({ id: 'T1250' })); // 날짜를 확인해주세요.
    }

    // 기존 정렬순서와 겹치면 children을 뺏어가서 확인해줘야 한다.
    const checkDuplicateSortNum = treeData.filter(v => +v.orgSortNum === +changeInput.orgSortNum);

    // 레벨이 1인 경우에만 (최상위만 중복 안되게)
    if ((selectedDatas.codeId === selectedDatas.companyCd || selectedDatas.companyCd === "") && checkDuplicateSortNum.length > 0) {
      return alert('정렬 번호는 중복될 수가 없습니다.');
    };


    /* 최상위에는 prntOrgCd 가 '' 이어야되고 아닌 애들은 조직코드 필요 */
    const result = await fetchAbsolute('post', `/org/orgListAdd`, {
      data: [{
        "companyCd": changeInput.companyCd, // 회사코드
        "orgCd": changeInput.codeId, // 조직코드
        "prntOrgCd": changeInput.companyCd === changeInput.codeId ? '' : changeInput.codeIdUpper ? changeInput.codeIdUpper : changeInput.companyCd,
        "orgLvlNum": changeInput.codeLevel,
        "orgSortNum": changeInput.orgSortNum, // 정렬
        "orgNam": changeInput.codeNm, // 조직명 
        "orgStartDt": moment(changeInput.orgStartDt).format('YYYYMMDD'), // 조직 시작일
        "orgEndDt": moment(changeInput.orgEndDt).format('YYYYMMDD'), // 조직 종료일
      }],
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.status === 200 || result.status === 201) {
      getDepartmentList();
      alert(formatMessage({ id: 'T0234' })); //저장되었습니다.
    } else {
      throw new Error(result.status);
    }
  };

  /* Tree에서 조직 선택시 기본정보 셋팅 */
  useEffect(() => {
    if (selectedDatas) {
      setChangeInput({
        companyCd: selectedDatas.companyCd,
        codeId: selectedDatas.codeId,
        orgStartDt: selectedDatas.orgStartDt,
        orgEndDt: selectedDatas.orgEndDt,
        codeNm: selectedDatas.codeNm,
        orgSortNum: selectedDatas.orgSortNum,
        codeIdUpper: selectedDatas.codeIdUpper,
        codeLevel: selectedDatas.codeLevel,
      });
    } else {
      setChangeInput({ ...initialChangeInput });
    }
  }, [selectedDatas, keyVal]);

  return (
    <div className="org_details__container">
      <div className="org_common_title">
        <Text className="org_common_content"><FormattedMessage id="T1252" /></Text>{/*조직 상세 정보 설정*/}
      </div>
      <div className="org_common__box">
        <FormTable style={{ margin: 20, width: "95%" }}>
          <colgroup>
            <col span="1" width="20%" />
            <col span="1" width="30%" />
            <col span="1" width="20%" />
            <col span="1" width="30%" />
          </colgroup>
          <thead>
            <tr>
              <td colSpan={2}>
                {
                  changeInput.codeNm &&
                  <Text className='org_thead_title'>{changeInput.codeNm}</Text>
                }
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th><Text><FormattedMessage id='T1242' /></Text></th>{/* 회사코드 */}
              <td>
                <div>
                  <Input
                    value={changeInput?.companyCd}
                    name='companyCd'
                    disabled={isCompanyCdDisabled}
                    onChange={onChangeInput}
                    placeholder={formatMessage({ id: 'T1242' })} //'회사코드'
                    innerRef={requireFields.companyCd.ref}
                  />
                </div>
              </td>
              <th><Text><FormattedMessage id='T1243' /></Text></th>{/* 조직코드 */}
              <td>
                <div>
                  <Input
                    value={changeInput?.codeId}
                    name='codeId'
                    disabled={isOrgCdDisabled}
                    onChange={onChangeInput}
                    placeholder={formatMessage({ id: 'T1243' })} //'조직코드'
                    innerRef={requireFields.codeId.ref}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th><Text><FormattedMessage id='T1244' /></Text></th> {/*조직 시작일*/}
              <td>
                <div>
                  <Input
                    value={changeInput.orgStartDt}
                    name='orgStartDt'
                    onChange={onChangeInput}
                    placeholder='YYYY-MM-DD'
                    maxLength={10}
                    innerRef={requireFields.orgStartDt.ref}
                  />
                </div>
              </td>
              <th><Text><FormattedMessage id='T1245' /></Text></th> {/*조직 종료일*/}
              <td>
                <div>
                  <Input
                    value={changeInput.orgEndDt}
                    name='orgEndDt'
                    onChange={onChangeInput}
                    placeholder='YYYY-MM-DD'
                    maxLength={10}
                    innerRef={requireFields.orgEndDt.ref}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th><Text><FormattedMessage id='T0014' /></Text></th>{/* 조직명 */}
              <td>
                <div>
                  <Input
                    value={changeInput.codeNm}
                    name='codeNm'
                    onChange={onChangeInput}
                    placeholder={formatMessage({ id: 'T0014' })} //'조직명'
                    innerRef={requireFields.codeNm.ref}
                  />
                </div>
              </td>
              <th><Text><FormattedMessage id='T0667' /></Text></th>{/*정렬 순서*/}
              <td>
                <div>
                  <Input
                    value={changeInput.orgSortNum}
                    name='orgSortNum'
                    onChange={onChangeInput}
                    type="number"
                    min={1}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </FormTable>
        <div className="org_detail__button">
          <Button style={{ width: 150, height: 40 }}
            onClick={onClickDelete}
          >
            <FormattedMessage id="T0051" />{/*취소*/}
          </Button>
          {
            // 조직 코드가 있을 경우 수정 아니면 등록 완료
            selectedDatas.codeId ?
              <Button
                type="primary"
                style={{ width: 150, height: 40 }}
                onClick={onClickEdit}
              >
                <FormattedMessage id="T0054" />{/*수정*/}
              </Button>
              :
              <Button
                type="primary"
                style={{ width: 150, height: 40 }}
                onClick={onClickSave}
              >
                <FormattedMessage id="T0106" />{/*등록완료*/}
              </Button>
          }
        </div>
      </div>
    </div>
  )
}

export default OrganizationDetails;