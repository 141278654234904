import React, { useState, useEffect } from 'react';
import { PageTemplate, Header, Footer, FormTable, Text, Input, TypePicker, Button } from 'components';
import { Select } from 'antd';
import fetchAbsolute from "utils/fetchAbsolute";
import moment from 'moment';
import Wrapper from './Styled';
import { useIntl, FormattedMessage } from "react-intl";

const { Option } = Select;

const AdminSatisfactionEdit = ({ history, match: { params }, type = 'create' }) => {
    const { formatMessage } = useIntl();

    const [inputDatas, setInputDatas] = useState({
        seqR: 0,
        title: '',
        researchFrom: moment().format('YYYY-MM-DD'),
        researchTo: moment().format('YYYY-MM-DD'),
        useYn: 'N'
    });

    const onChangeUseYn = (value) => {
        setInputDatas({
            ...inputDatas,
            useYn: value
        });
    }

    const onChangeInput = (e) => {
        const { value, name } = e.target;
        setInputDatas({
            ...inputDatas,
            [name]: value
        });
    }

    const onChangeFromDate = (e) => {
        setInputDatas({
            ...inputDatas,
            researchFrom: e.format('YYYY-MM-DD')
        });
    }
    const onChangeToDate = (e) => {
        setInputDatas({
            ...inputDatas,
            researchTo: e.format('YYYY-MM-DD')
        });
    }

    const onClickSave = async () => {
        if (inputDatas.title.trim().length === 0) {
            return alert(formatMessage({ id: 'T0294' })); // 만족도 조사 제목은 필수 입력입니다.
        }
        const result = await fetchAbsolute('post', `/admin/research/researchSave`, {
            data: {
                ...inputDatas,
                researchFrom: inputDatas.researchFrom + 'T00:00:00',
                researchTo: inputDatas.researchTo + 'T00:00:00',
            }
        });
        if (result.error) {
            return alert(formatMessage({ id: 'T0295' })); //'만족도 조사 저장을 실패하였습니다.');
        }
        alert(formatMessage({ id: 'T0296' })); //'만족도 조사 저장을 완료했습니다.');
        history.push('/admin/satisfaction');
    }

    const onClickBack = () => {
        history.push('/admin/satisfaction');
    }

    const onClickDelete = async () => {
        const confirmResult = window.confirm(formatMessage({ id: 'T0325' })); // 만족도 조사를 삭제하시겠습니까?
        if (!confirmResult) {
            return;
        }
        const result = await fetchAbsolute('delete', `/admin/research/researchDelete/${params.seqR}`);
        if (result.error) {
            return alert(formatMessage({ id: 'T0297' })); //'만족도 조사 삭제를 실패했습니다.');
        }
        alert(formatMessage({ id: 'T0298' })); //'만족도 조사 삭제를 완료했습니다.');
        history.push('/admin/satisfaction');
    }

    useEffect(() => {
        if (type !== 'modify') return;

        const fetchResearch = async () => {
            const result = await fetchAbsolute('get', `/admin/research/researchInfo?seqR=${params.seqR}`);
            if (result.error) {
                return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            }
            const { data } = result;
            setInputDatas({
                ...data,
                researchFrom: moment(data.researchFrom).format('YYYY-MM-DD'),
                researchTo: moment(data.researchTo).format('YYYY-MM-DD'),
            });
        }
        fetchResearch();
    }, []);

    return (
        <PageTemplate
            header={<Header />}
            footer={<Footer />}
            bodyColor="none"
            headerColor='none'
            menuTitle={type === 'create' ? `${formatMessage({ id: 'T1205' })}` // 만족도 조사 등록
                : type === 'modify' && `${formatMessage({ id: 'T1206' })}` // 만족도 조사 수정
            }
            depthList={[`${formatMessage({ id: 'T0881' })}`, // 관리자
            type === 'create' ? `${formatMessage({ id: 'T1205' })}` // 만족도 조사 등록
                : type === 'modify' && `${formatMessage({ id: 'T1206' })}`] // 만족도 조사 수정
            }
        >
            <Wrapper style={{ padding: "30px 60px" }}>
                <FormTable>
                    <colgroup>
                        <col width="30%" />
                        <col width="70%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Text required={true}>{formatMessage({ id: 'T0845' })}</Text>{/* 만족도 조사 제목 */}
                            </th>
                            <td>
                                <Input
                                    placeholder={formatMessage({ id: 'T0845' })}  // 만족도 조사 제목
                                    style={{
                                        width: 250,
                                        height: 32
                                    }}
                                    name='title'
                                    value={inputDatas.title}
                                    onChange={onChangeInput}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th><Text required={true}>{formatMessage({ id: 'T0824' })}</Text></th>{/* 기간 */}
                            <td>
                                <div className='history_box__date_box'>
                                    <TypePicker
                                        popupStyle={{
                                            ".ant-picker-cell-inner": {
                                                background: "#000",
                                            },
                                        }}
                                        style={{
                                            width: 200,
                                            height: 32,
                                            alignItems: "center",
                                        }}
                                        size="middle"
                                        format="YYYY-MM-DD"
                                        value={moment(inputDatas.researchFrom, 'YYYY-MM-DD')}
                                        onChange={onChangeFromDate}
                                    />
                                    ~
                                    <TypePicker
                                        popupStyle={{
                                            ".ant-picker-cell-inner": {
                                                background: "#000",
                                            },
                                        }}
                                        style={{
                                            width: 200,
                                            height: 32,
                                            alignItems: "center",
                                        }}
                                        size="middle"
                                        format="YYYY-MM-DD"
                                        value={moment(inputDatas.researchTo, 'YYYY-MM-DD')}
                                        onChange={onChangeToDate}
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text required={true}>{formatMessage({ id: 'T0846' })}</Text>{/* 노출 여부 */}
                            </th>
                            <td>
                                <Select
                                    defaultValue='N'
                                    value={inputDatas.useYn}
                                    onChange={onChangeUseYn}
                                    style={{
                                        width: 65,
                                        height: 32
                                    }}
                                >
                                    <Option value='N'>N</Option>
                                    <Option value='Y'>Y</Option>
                                </Select>
                            </td>
                        </tr>
                    </tbody>
                </FormTable>
                {
                    type === 'modify' ?
                        <div className='satisfaction_edit__button_wrap'>
                            <Button
                                onClick={onClickBack}
                            >
                                <FormattedMessage id="T0051" />{/* 취소 */}
                            </Button>
                            <Button
                                onClick={onClickDelete}
                            >
                                <FormattedMessage id="T0072" />{/* 삭제 */}
                            </Button>
                            <Button
                                type='primary'
                                onClick={onClickSave}
                            >
                                <FormattedMessage id="T0054" />{/* 수정 */}
                            </Button>
                        </div>
                        :
                        <div className='satisfaction_edit__button_wrap'>
                            <Button
                                onClick={onClickBack}
                            >
                                <FormattedMessage id="T0051" />{/* 취소 */}
                            </Button>
                            <Button
                                type='primary'
                                onClick={onClickSave}
                            >
                                <FormattedMessage id="T0049" />{/* 추가 */}
                            </Button>
                        </div>
                }
            </Wrapper>
        </PageTemplate >
    );
}

export default AdminSatisfactionEdit;