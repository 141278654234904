import React, { useState, useRef, useEffect } from "react";
import { Button, Text, Modal, Image } from "components";
import { Tooltip } from "antd";
import bg from "../Img/itemShop_Open_bg.png";
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { useIntl, FormattedMessage } from "react-intl";

/**
 * Academy > Item Shop > Shop 탭
 */

const ShopOpen = ({ profile }) => {
  const { formatMessage } = useIntl();

  const selectOption = formatMessage({ id: "T0024" }); // 선택
  const confirmationText = formatMessage({ id: "T0064" }); // 확인

  const [Visible, setVisible] = useState(false);
  const [purchaseVisible, setPurchaseVisible] = useState(false);
  const [productVisible, setProductVisible] = useState(false);
  const [purchaseStatusVisible, setPurchaseStatusVisible] = useState(false);
  const [purchaseErrorMsg, setPurchaseErrorMsg] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [points, setPoints] = useState();
  const [round, setRound] = useState();
  const [rowData, setRowData] = useState([]);
  const [remainTime, setRemainTime] = useState();
  const envTimeout = useRef(null);

  const getCountDown = (e) => {
    //끝나는 날짜 + 1
    let dday = new Date(`${e} 00:00:00`).getTime() + 86400000;

    setInterval(function () {
      let today = new Date().getTime();
      let gap = dday - today;
      let hour = Math.ceil(gap / (1000 * 60 * 60));
      let min = Math.ceil((gap % (1000 * 60 * 60)) / (1000 * 60));
      let sec = Math.ceil((gap % (1000 * 60)) / 1000);

      setRemainTime(
        hour -
        1 +
        `${formatMessage({ id: "T0563" })} ` + // 시간
        min +
        `${formatMessage({ id: "T0493" })} ` + // 분
        sec +
        `${formatMessage({ id: "T0981" })}` // 초
      );
    }, 1000);
  };

  const fetchItemList = async () => {
    const today = moment().format("YYYY-MM-DD");
    const result = await fetchAbsolute(
      "get",
      `/academy/getProductWithPointInfo?empNo=${profile.EMP_NO}&curDate=${today}`
    );
    if (result.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    }
    const replaceList = result.data?.userProductList.map((v) => {
      return {
        ...v,
        link: v.link.replace("200x200", "0x0"),
      };
    });

    setRound(result.data.round);
    setPoints(result.data.point);
    // setDataSource([
    //   ...result.data.userProductList.filter((e) => e.showYn === "Y"),
    // ]);
    setDataSource([...replaceList.filter((e) => e.showYn === "Y")]);
    getCountDown(moment(result.data.roundTo).format("YYYY-MM-DD"));
  };

  const onCancelProduct = () => {
    setProductVisible(false);
  };

  const onClickDelete = (record) => {
    setVisible(true);
    setRowData(record);
  };
  const onCancelDelete = () => setVisible(false);

  const onOkDelete = async () => {
    const saveList = await fetchAbsolute(
      "post",
      `/academy/purchaseHistoryByTimeSave`,
      {
        data: {
          purchaseSeq: 0,
          roundSeq: rowData.roundSeq,
          round: rowData.round,
          productSeq: rowData.productSeq,
          purchaser: profile.EMP_NO,
          cancelYn: "Y",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (saveList.error) {
      return alert(formatMessage({ id: "T0314" })); //취소에 실패하였습니다.
    }

    //setButtonStatus(true);
    setVisible(false);
    fetchItemList();
  };

  const onCancelPurchase = () => setPurchaseVisible(false);

  const onClickAdd = (record) => {
    setPurchaseVisible(true);
    setRowData(record);
  };

  const onOkPurcahse = async () => {
    const saveList = await fetchAbsolute(
      "post",
      `/academy/purchaseHistoryByTimeSave`,
      {
        data: {
          purchaseSeq: 0,
          roundSeq: rowData.roundSeq,
          round: rowData.round,
          productSeq: rowData.productSeq,
          purchaser: profile.EMP_NO,
          cancelYn: "N",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (saveList.error) {
      return alert(formatMessage({ id: "T0235" })); //저장에 실패하였습니다.
    } else if (saveList.data.resultCode === "200") {
      setPurchaseVisible(false);
      fetchItemList();
    } else if (saveList.data.resultCode === "E") {
      setPurchaseErrorMsg(saveList.data.message);
      clearTimeout(envTimeout.current);
      setPurchaseStatusVisible(true);
      envTimeout.current = setTimeout(() => {
        setPurchaseStatusVisible(false);
      }, 2000);

      return;
    }
  };

  const onClickImg = (record) => {
    setProductVisible(true);
    setRowData(record);
  };

  useEffect(() => {
    fetchItemList();
  }, []);

  //useEffect(() => {}, [dataSource]);

  return (
    <div className="closed_section">
      <div>
        <div style={{ position: "relative" }}>
          <Image alt="" src={bg} />
          <div style={{ position: "absolute", top: "10%", left: "41%" }}>
            <Text style={{ color: "white", fontWeight: "bold", fontSize: 30 }}>
              [{round}]
            </Text>
            <div>
              <div>
                <Text
                  style={{ color: "white", fontWeight: "bold", fontSize: 30 }}
                  id="count"
                >
                  {remainTime}
                </Text>
              </div>
              <div>
                <Text
                  style={{
                    color: "white",
                    fontSize: 22,
                    fontWeight: "bold",
                    marginRight: 4,
                  }}
                >
                  {formatMessage({ id: "T0982" })}{/* 보유 포인트 : */}
                </Text>
                <Text
                  style={{ color: "#53FF75", fontSize: 30, fontWeight: "bold" }}
                >
                  {points?.toLocaleString()} pt
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="Itemshop_Card_Wrap">
        {dataSource.map((e, index) => (
          <div className="Itemshop_Card_Content" key={index}>
            <div className="Itemshop_Image">
              <a href="#!">
                <Image
                  width="200px"
                  height="200px"
                  alt=""
                  src={e.link}
                  className="image"
                  value={e.link}
                  onClick={() => onClickImg(e)}
                />
              </a>
            </div>
            <div className="Itemshop_Desc">
              <Text className="Itemshop_Desc_Title">{e.product}</Text>
              <br />
              <br />
              <Text className="Itemshop_Desc_Content">
                {e.price.toLocaleString()}pt &nbsp;|&nbsp;
              </Text>
              &nbsp;
              <Text className="Itemshop_Desc_Content"> {e.qty}개 </Text>&nbsp;
              {(() => {
                if (
                  dataSource.find((e) => e.purchaseYn === "Y") === undefined
                ) {
                  if (e.purchaseYn === "Y") {
                    return (
                      <Button
                        className="Itemshop_Box_Button"
                        onClick={() => onClickDelete(e)}
                      >
                        <FormattedMessage id="T0051" />{/* 취소 */}
                      </Button>
                    );
                  } else if (e.purchaseYn === "N") {
                    return (
                      <Button
                        className="Itemshop_Box_Button"
                        onClick={() => onClickAdd(e)}
                      >
                        {`${selectOption}`}
                      </Button>
                    );
                  }
                }
                //구매가 하나라도 있을때
                else {
                  if (e.purchaseYn === "Y") {
                    return (
                      <Button
                        className="Itemshop_Box_Button"
                        onClick={() => onClickDelete(e)}
                      >
                        <FormattedMessage id="T0051" />{/* 취소 */}
                      </Button>
                    );
                  } else if (e.purchaseYn === "N") {
                    return (
                      <Button type="disabled" className="block__button">
                        {selectOption}
                      </Button>
                    );
                  }
                }
              })()}
            </div>
          </div>
        ))}
      </div>
      <Modal
        width={540}
        visible={Visible}
        title={rowData.product + ` ${formatMessage({ id: "T1220" })}`} // 구매 취소
        okText={confirmationText}
        onCancel={onCancelDelete}
        onOk={onOkDelete}
      >
        <div>
          <Text>
            <b>{rowData.product}</b>
            <FormattedMessage id="T1219" />{/* 선택을 취소 합니다. */}
          </Text>
        </div>
        <Text className="modalContent">
          {Number(rowData.price).toLocaleString()}
          <FormattedMessage id="T1218" />{/* 포인트가 가산 됩니다. */}
        </Text>
        <br /> <br />
        <Text className="modalContent">{formatMessage({ id: "T0983" })}</Text>{/* 정말로 취소하시겠습니까? */}
      </Modal>

      <Modal
        width={540}
        visible={purchaseVisible}
        title={rowData.product + ` ${formatMessage({ id: "T0841" })}`} // 구매
        onCancel={onCancelPurchase}
        // onOk={onOkPurcahse}
        footer={[
          <Tooltip title={purchaseErrorMsg} visible={purchaseStatusVisible}>
            <Button type="primary" onClick={onOkPurcahse}>
              <FormattedMessage id="T0064" />{/* 확인 */}
            </Button>
          </Tooltip>,
          <Button onClick={onCancelPurchase} className="cancel__button">
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>,
        ]}
      >
        <div>
          <Text>
            <b>{rowData.product}</b>
            <FormattedMessage id="T1217" />{/* 을/를 선택하셨습니다 */}
          </Text>
        </div>
        <Text className="modalContent">
          {Number(rowData.price).toLocaleString()}
          <FormattedMessage id="T1216" />{/* 포인트가 차감 됩니다. */}
        </Text>
        <br /> <br />
        <Text className="modalContent">
          <FormattedMessage id="T1221" />{/* 그래도 선택 하시겠습니까? */}
        </Text>
      </Modal>

      <Modal
        width={800}
        visible={productVisible}
        title={rowData.product}
        onCancel={onCancelProduct}
        footer={[]}
      >
        <div style={{ textAlign: "center" }}>
          <Image
            style={{ maxHeight: 600, maxWidth: 750 }}
            alt=""
            width="auto"
            height="auto"
            src={rowData.link}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ShopOpen;
