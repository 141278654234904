import styled from "styled-components";

const Wrapper = styled.div`
  background: #fff;
  width: 1400px;
  height: fit-content;
  padding-bottom: 50px; 
  .comment_cnt {
    font-size: 14px;
    color: #ff7a00;
    /* color: #0067ac; */
  }
  .board_manage__top {
    display: flex;
    justify-content: space-between;
    padding: 8.5px 0 15px 0px;
  }
  .board_manage__top_right {
    padding: 14.6px 0 10.5px 20px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0 20px;
  }
  .bottom_buttons__container {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  .bottom_buttons {
    width: 150px;
    height: 40px;
  } 
  table {
    table-layout: fixed !important;
  }
  .ant-table-footer {
    padding: 29px 0 30px 0;
    background: #fff;
  }
  .ant-table-column-sorters {
    align-items: start;
  }
  .ant-table-tbody > tr > td .title_section{
    display: flex;    
    align-items: center; 
    gap: 2px;
  }
  .ant-spin-container {
    gap: 30px;
  }
  .table_title { 
    font-size: 14px;
    max-width: 96%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .comment_cnt { }
  /* 테이블 제목 hover 할 때 */
  .ant-table-tbody > tr.ant-table-row:hover > td .table_title {
    /* color: #ff7a00; */
    color: #0067ac;
    text-decoration: underline;
    font-weight: bold;
  }
`;

export default Wrapper;
