import styled from "styled-components";

const Wrapper = styled.div`
  background: f6f6f6;

  .process_design_basic_info th {
    font-weight: bold;
  }
  .desc_break{
    white-space:pre-wrap;
    word-break:break-all;
  } 
  .icon__group {
    display: flex;
    gap: 10px;
    align-items: center;
  }  
  .bizflow__tool_box {
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 0 10px;
  }
  .bizflow__tool_box > div {
    display: flex;
    align-items: center;
    padding: 0 11px;
    height: 28px;
    border-radius: 14px;
    gap: 0 5px;
    font-size: 20px;
    transition: 0.4s;
  }
  .readcnt_box .readcnt_count,
  .interest_box .interest_count {
    font-size: 14px;
  }
  .readcnt_box {
    background: #eee;
  }
  .interest_box:hover {
    transform: scale(1.1);
  }
  .interest_box {
    background: #fff3e8;
  }
  .interest_box .interest_box__icon,
  .interest_empty_box__icon {
    color: #ff7a00;
  }
  .file__list a {
    text-decoration: underline;
    font-weight: bold;
    font-size: 14px;
  } 
`;

export default Wrapper;