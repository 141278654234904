import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)` 
  /* 공통 */ 
  .common__container {
    background: #fff; 
    padding: 30.1px 30px;
    border: 1px solid rgb(208, 209, 214);
    margin-bottom: 30px;
    border-top:none;
  }
  .common__title {
    font-size: 20px;
    font-weight: bold; 
  }  
  .submit_button__section {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    gap: 20px;
  }
  .submit_button {
    width: 140px;
    height: 40px
  }
  /* tabs */
  .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }
  .ant-tabs-nav-list {
    gap: 10px;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    width: 180px;
    text-align: center;
    background: #eaeaed;
  }
  .ant-tabs-nav-list .ant-tabs-tab:hover {
    color: #000;
  }
  .ant-tabs-nav-list .ant-tabs-tab-active {
    background: #fff !important;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, 
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
      border-radius: 6px 6px 0 0;
      border: 1px solid #d0d1d6;
      font-size: 16px;
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-tab-active ant-tabs-tab,
  .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: #fff !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000;
  }  
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
    color: #000;
    background: #fff !important;
    border-bottom: none !important;
   
  }
  .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
    margin: 0px 10px 0 0;
    background: #eaeaed;
    border: 1px solid #d0d1d6;
    border-radius: 10px 10px 0 0;
    border-bottom: none !important;npm run bit
  } 
  .ant-tabs-tab:hover {
    color: #ff7a00;
  }
  .ant-input:hover {
    border-color: #ff7b00;
  }
  /* Welcom Banner 웰컴 배너 */ 
  .edit_file__section {
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
  }
  .edit_file__image_button {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .edit_file__image_button .welcome_image {
    max-width: 755px;
  }
  /* 랭킹 기준 설정 ranking */
  .ranking_table__container .ant-table-thead > tr > th, .ant-table-tbody > tr > td { 
    font-size: 12px;
  }
  .ranking_table__container .ant-select-selection__rendered {
    font-size: 12px !important;
  } 
  .ranking_table__container input.ant-input {
    font-size: 12px;
  }
`;

export default Wrapper;
