import React from "react";
import { Row, Col, Select, Tooltip } from "antd";
import {
    Text,
    Button,
    UserTag,
    PermissionComponent,
} from "components";
import { CopyIcon } from "icons";
import {  SwapRightOutlined } from "@ant-design/icons";
import "moment/locale/ko";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

//  개발시작 누른 후 화면
const ResultForm = ({
    onClickEditableButton,
    taskData,
    analyticalDatas,
    visibleCopyFileName,
    onClickCopyName,
    analyticalFileDatas,
    selectCr,
    goToDiyPrd,
}) => {
    const { formatMessage } = useIntl();

    return (
        <div className="basic_box">
            <div className="basic_box__title">
            <Text>{formatMessage({ id: "T0505" })}</Text>{/* 2. [개발] 분석/설계 */}
            </div>
            <div className="basic_box__info">
            <Row>
                <Col span={5}>
                <Text className="basic_row__title">
                    {formatMessage({ id: "T0506" })}(Main){/* 개발 담당자 */}
                </Text>
                </Col>
                <Col span={19} className="basic_row__content">
                {taskData.taskMemberList
                    ?.filter(
                    (member) =>
                        member.mgrDevGbn === "D" && member.deputyHeadYn === "N"
                    )
                    .map((member, index) => {
                    return (
                        <UserTag
                        profile={{
                            NAME_KOR: member.empNm,
                            EMP_NO: member.empNo,
                            DEPT_NAME_KOR: member.orgNm,
                            FILE_ID: member.empFileId,
                        }}
                        contextVisible={true}
                        key={index}
                        />
                    );
                    })}
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title">
                    {formatMessage({ id: "T1128" })}{/* 개발 담당자(Sub) */}
                </Text>
                </Col>
                <Col span={19} className="basic_row__content">
                {taskData.taskMemberList
                    ?.filter(
                    (member) =>
                        member.mgrDevGbn === "D" && member.deputyHeadYn === "Y"
                    )
                    .map((member, index) => {
                    return (
                        <UserTag
                        profile={{
                            NAME_KOR: member.empNm,
                            EMP_NO: member.empNo,
                            DEPT_NAME_KOR: member.orgNm,
                            FILE_ID: member.empFileId,
                        }}
                        contextVisible={true}
                        key={index}
                        />
                    );
                    })}
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <Text className="basic_row__title">
                        {formatMessage({ id: "T1678" })}{/* Bot F/W 기능 선택 */}
                    </Text>
                </Col>
                <Col span={19}>
                    { taskData.botFwYn === "Y"
                        ? 
                        <span>{formatMessage({ id: "T1680" })}</span> // Bot 업무량 분산을 위한 병렬 처리 On
                        :
                        <span>{formatMessage({ id: "T1681" })}</span> // Bot 업무량 분산을 위한 병렬 처리 Off
                    }
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0507" })}{/* 개발 일정 */}
                </Text>
                </Col>
                <Col span={19}>
                <Text className="analytical__text">
                    {moment(analyticalDatas.devSchedule[0]).format("YYYY-MM-DD(dd)")}
                </Text>
                <SwapRightOutlined />
                <Text className="analytical__text">
                    {moment(analyticalDatas.devSchedule[1]).format("YYYY-MM-DD(dd)")}
                </Text>
                <Text className="analytical__text">{/* T1225 : 총 */}
                    &nbsp;|&nbsp;{formatMessage({ id: "T1225" })}&nbsp;
                    {moment(analyticalDatas.devSchedule[1]).diff(
                    moment(analyticalDatas.devSchedule[0]),
                    "days"
                    ) +
                    1 +
                    `${formatMessage({ id: "T0437" })}`}{/* 일 */}
                </Text>
                <Text className="analytical__text"></Text>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0508" })}{/* 개발 CR */}
                </Text>
                </Col>
                <Col span={19}>
                <Text className="analytical__text">
                    {
                    Object.keys(selectCr).length > 0 ?
                        selectCr.map((v) => v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + " (운영)" : v.crTypeDesc + " (개발)")
                        :
                        goToDiyPrd.map((v) => v.crTypeDesc === null ? v.crDesc : v.crEnvGubun === "PRD" ? v.crTypeDesc + " (운영)" : v.crTypeDesc + " (개발)")
                    }
                </Text>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0509" })}{/* 업무 기술서 */}
                </Text>
                </Col>
                <Col span={19}>
                <div className="statement__box">
                    <div className="work_desc__container">
                    <Text style={{ width: 50 }} className="analytical__text">
                        URL
                    </Text>
                    <Text className="analytical__text">
                        {analyticalFileDatas.urlList?.map((url, index) => (
                        <div key={index} className="file__list">
                            <Text type="link" link={url.name}>
                            {url.name}
                            </Text>
                            <Tooltip
                            trigger={[]}
                            title={formatMessage({ id: "T0460" })} // 복사되었습니다.
                            visible={visibleCopyFileName[index]}
                            >
                            <CopyIcon
                                className="file_name__copy"
                                onClick={() => onClickCopyName(url, index)}
                                color="#acacac"
                            />
                            </Tooltip>
                        </div>
                        ))}
                    </Text>
                    </div>
                    <div className="file__container">
                    <Text className="analytical__text">
                        {formatMessage({ id: "T0513" })} {/* 첨부파일 */}
                    </Text>
                    <Text className="reference__file">
                        {analyticalFileDatas.fileList?.map((file, index) => {
                        return (
                            <Text
                            className="file__list"
                            key={index}
                            type="download"
                            download={file.down}
                            fileName={file.name}
                            >
                            {file.name}
                            </Text>
                        );
                        })}
                    </Text>
                    </div>
                </div>
                </Col>
            </Row>
            <Row>
                <Col span={6} offset={18} className="flex justify-end">
                <PermissionComponent fnCode="FN02">
                    <Button
                    onClick={onClickEditableButton}
                    type="default"
                    width="120"
                    >
                    <FormattedMessage id="T0054" />{/* 수정 */}
                    </Button>
                </PermissionComponent>
                </Col>
            </Row>
            </div>
        </div>
    );
};

export default ResultForm;