import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUniqueKey } from 'utils/Str';
import { AssignEnterBox, Text, Input, Button, UserTag, NameTag, FormTable } from 'components';
import { Input as AntInput, Select, Popover } from 'antd';
import fetchAbsolute from 'utils/fetchAbsolute';
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import NameTagGroup from 'components/atoms/NameTagGroup';

const { Option } = Select;
const { TextArea } = AntInput;

/**
 * Filename: AssignEnterBasicBox/index.js
 * Description: 과제 등록 -> 기본 정보 섹션
 */

const AssignEnterBasicBox = ({ profile = {}, insertDatas, onChangeDatas, onClickOrgaOpen, onClickBusinessOpen, onClickDelete, requireFields, type, defaultStatusCd }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const menuList = useSelector((state) => state.get(getUniqueKey("menuList")).menuList);
    const userPermission = useSelector(state => state.get(getUniqueKey('permission')).user);

    const assignDesc = (
        <div className="popover_contentBox">
            <span className="content_text">
                예시) ERP에서 미매입 입고 내역을 상세 조회한다.
                <br />
                (업체별, 기간별로 조회)  세금 계산서 발생 요청 메일을 해당 업체 담당자에게 발송한다.
                <br />
                메일 발송 내역을 엑셀에 기록한다.
                <br />
                이 업무는 매월 15일, 25일, 말일에 수행한다.
            </span>
        </div>
    );


    const [taskType, setTaskType] = useState([]);
    const [shareList, setShareList] = useState([]);
    const [bpdList, setBpdList] = useState([]);
    const [selectedBpdList, setSelectedBpdList] = useState([]);
    const [textLength, setTextLength] = useState(0);
    const [hasBizflow, setHasBizflow] = useState(false);
    const [checkCd, setCheckCd] = useState(false);

    const taskNm = formatMessage({ id: "T0002" }); // 과제명 

    const onChangeInput = (e) => {
        const { name, value } = e.target;
        if (name === 'assignName' && value.length > 100) {
            return;
        } else if (name === 'assignDescription' && value.length > 1000) {
            onChangeDatas(name, value.slice(0, 1000));
        } else {
            onChangeDatas(name, value);
        }
        // 총 글자수
        if (name === 'assignDescription') {
            setTextLength(+value.length);
        }
    }
    const onChangeAssignCategory = (e) => {
        onChangeDatas('assignCategory', e);
    }
    const onChangeBpdList = async (e) => {
        if (!e) return;
        const tmpList = insertDatas.processDesign.filter(list => list.bpdId !== e).map(id => id.bpdId);
        setSelectedBpdList(tmpList);
        onChangeDatas('processDesign', e);
    }

    const onChangeShare = (e) => {
        onChangeDatas('shareAssign', e);
    }

    const onClickOrgaButton = (e) => {
        const { label } = e.target.dataset;
        onClickOrgaOpen(label);
    }

    const onClickDeleteButton = (e, { label, index }) => {
        onClickDelete(label, index);
    }

    useEffect(() => {
        if (insertDatas.processDesign?.length < 1) {
            setSelectedBpdList();
        } else {
            const tmpList = insertDatas.processDesign.map(list => list.bpdId);
            setSelectedBpdList(tmpList);
        }
    }, [insertDatas.processDesign]);

    useEffect(() => {
        if (!insertDatas.businessClass.cd) return;
        const fetchData = async () => {
            const result = await fetchAbsolute('post', `/bpd/bpdListHF`, {
                data: {
                    workId: [insertDatas.businessClass.cd]
                }
            });
            setBpdList([...result.data.bpds]);
        }
        fetchData();
    }, [insertDatas.businessClass]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetchAbsolute('get', `/common/commonShareTask/findByEmpNo/${profile.EMP_NO}`);
            setShareList([...result.data.map(v => ({ shareTaskId: v.shareTaskId, shareTaskNm: v.shareTaskNm }))]);
        }
        if (Object.keys(profile).length > 0) {
            fetchData();
        }
    }, [profile]);

    useEffect(() => {
        if (type === 'modify') {
            setTextLength(insertDatas.assignDescription.
                length);
        }
    }, [insertDatas.assignDescription]);

    useEffect(() => {
        if (menuList.length === 0) return;
        if (menuList.filter((menu) => menu.menuId === 1009 && menu.useYn === "Y").length > 0) {
            // BizFlow 메뉴가 있으면
            setHasBizflow(true);
        }
    }, [menuList]);

    useEffect(() => {
        const fetchData = async () => {
            const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/TASK_TYPE');
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

            const { DWP_USER_LEVEL, AB_LEVEL } = profile;

            // Citizen Developer === UA02 
            if (DWP_USER_LEVEL === 'UA02' && +AB_LEVEL === 1) {
                setTaskType([...result.data?.filter(v => v.useYn === 'Y' && v.cd === 'EDU')]);
            } else {
                setTaskType([...result.data?.filter(v => v.useYn === 'Y')]);
            }
        }
        fetchData();
    }, []);

    return (
        <AssignEnterBox title={formatMessage({ id: "T0424" })}>{/* 기본 정보 */}
            <FormTable>
                <colgroup>
                    <col width='12.5%' />
                    <col width='37.5%' />
                    <col width='12.5%' />
                    <col width='37.5%' />
                </colgroup>
                <tbody>
                    <tr>
                        <th>
                            <Text required={true}>{taskNm}</Text>{/* 과제명 */}
                        </th>
                        <td colSpan={3}>
                            <Input
                                innerRef={requireFields.assignName.ref}
                                name='assignName'
                                style={{ width: '100%' }}
                                value={insertDatas.assignName}
                                onChange={onChangeInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text required={true}>{formatMessage({ id: "T0008" })}</Text>{/* 업무분류 */}
                        </th>
                        <td>
                            <div className='flex align-center'>
                                <Input
                                    readOnly
                                    style={{
                                        width: 320,
                                        marginRight: 20,
                                    }}
                                    innerRef={requireFields.businessClass.ref}
                                    name='businessClass'
                                    value={insertDatas.businessClass.cdPathNm}
                                    onChange={onChangeInput}
                                />
                                <Button onClick={onClickBusinessOpen}>
                                    <FormattedMessage id="T0048" />{/* 설정 */}
                                </Button>
                            </div>
                        </td>
                        <th>
                            <Text required={true}>{formatMessage({ id: 'T0429' })}</Text>{/* 등록자 */}
                        </th>
                        <td>
                            <UserTag
                                profile={insertDatas.totalManager}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text required={true}><FormattedMessage id="T0003" /></Text> {/* 과제 유형 */}
                        </th>
                        <td colSpan={(defaultStatusCd === 'T10' || defaultStatusCd === '') ? 1 : 3}>
                            <Select
                                disabled={type !== 'enter'}
                                ref={requireFields.assignCategory.ref}
                                size='default'
                                defaultValue='EDU'
                                style={{
                                    width: '100%'
                                }}
                                name='assignCategory'
                                value={insertDatas.assignCategory}
                                onChange={onChangeAssignCategory}
                            >
                                <Option value=''>{formatMessage({ id: 'T0425' })}</Option>{/* 과제 유형을 선택하세요 */}
                                {
                                    taskType.filter(v => userPermission.findIndex(f => f.taskType === v.cd && f.fnCode === 'FN01') !== -1).map(type => (
                                        <Option value={type.cd} key={type.cd}>{type.cdNm}({type.attrCd1 || type.cd})</Option>
                                    ))
                                }
                            </Select>
                        </td>
                        {
                            (defaultStatusCd === 'T10' || defaultStatusCd === '') &&
                            <>
                                <th>
                                    <Text>{formatMessage({ id: 'T0426' })}</Text>{/* 공유 과제 적용 */}
                                </th>
                                <td>
                                    <Select
                                        size='default'
                                        defaultValue=''
                                        style={{
                                            width: '100%'
                                        }}
                                        name='shareAssign'
                                        onChange={onChangeShare}
                                        value={insertDatas.shareAssign}
                                    >
                                        <Option value=''>{formatMessage({ id: 'T0427' })}</Option>{/* 공유과제를 선택하세요 */}
                                        {
                                            shareList.map(share => (
                                                <Option value={share.shareTaskId} key={share.shareTaskId}>[{share.shareTaskId}] {share.shareTaskNm}</Option>
                                            ))
                                        }
                                    </Select>
                                </td>
                            </>
                        }
                    </tr>
                    <tr>
                        <th>
                            <Text required={true}>{formatMessage({ id: 'T0428' })}</Text>{/* 업무담당자 */}
                        </th>
                        <td colSpan={insertDatas.workManager.length > 2 ? 3 : 1}>
                            {
                                <Button
                                    innerRef={requireFields.workManager.ref}
                                    data-label='workManager'
                                    onClick={onClickOrgaButton}
                                >
                                    <FormattedMessage id="T0049" />{/* 추가 */}
                                </Button>
                            }
                            <div className='flex flex-wrap-wrap' style={{ marginTop: 8, gap: '8px 10px' }}>
                                {
                                    NameTagGroup(insertDatas.workManager.map((v, index) => ({ ...v, isMain: index === 0 })), 'workManager', onClickDeleteButton, type !== 'modify' || (type === 'modify' && defaultStatusCd === 'T10'))
                                }
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text required={requireFields.developers.use}>{formatMessage({ id: "T0007" })}</Text>{/* 개발자 */}
                        </th>
                        <td colSpan={insertDatas.developers.length > 2 ? 3 : 1}>
                            {
                                <Button
                                    innerRef={requireFields.developers.ref}
                                    data-label='developers'
                                    onClick={onClickOrgaButton}
                                >
                                    <FormattedMessage id="T0049" />{/* 추가 */}
                                </Button>
                            }
                            <div className='flex flex-wrap-wrap' style={{ marginTop: 8, gap: '8px 10px' }}>
                                {
                                    NameTagGroup(insertDatas.developers.map((v, index) => ({ ...v, isMain: index === 0 })), 'developers', onClickDeleteButton)
                                }
                            </div>
                        </td>
                    </tr>
                    {
                        insertDatas.developers.length > 2 &&
                        <tr>
                            <th>
                                <Text required={true}>{formatMessage({ id: 'T0429' })}</Text>{/* 등록자 */}
                            </th>
                            <td colSpan={3}>
                                <UserTag
                                    profile={insertDatas.totalManager}
                                />
                            </td>
                        </tr>
                    }
                    <tr>
                        <th>
                            <Text required={true}>{formatMessage({ id: 'T0430' })}</Text>{/* 과제 설명 */}
                            <div style={{ fontSize: 12 }}>({formatMessage({ id: 'T1223' })})</div>{/* 100자 이상 필수 */}
                            <div style={{ fontSize: 12 }}>{fmMessage({ id: 'T1318', values: { field: textLength } })}</div>{/* 입력 글자 수 : */}
                        </th>
                        <td colSpan={3}>
                            <Popover
                                className="popover_box"
                                content={assignDesc}
                                arrowPointAtCenter
                            >
                                <TextArea
                                    name='assignDescription'
                                    value={insertDatas.assignDescription}
                                    onChange={onChangeInput}
                                    ref={requireFields.assignDescription.ref}

                                />
                            </Popover>
                        </td>
                    </tr>
                    {
                        hasBizflow &&
                        <tr>
                            <th>
                                <Text>Process Design</Text>
                            </th>
                            <td colSpan={3}>
                                <Select
                                    disabled={Object.keys(insertDatas.businessClass).length === 0}
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    name='processDesign'
                                    value={selectedBpdList || undefined}
                                    onChange={onChangeBpdList}
                                    placeholder={`Process Design${formatMessage({ id: 'T1311' })}`}
                                >
                                    {
                                        bpdList.map((bpd) => (
                                            <Option value={bpd.bpdId} key={bpd.bpdId} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                                                [{bpd.bpdId}] {bpd.bpdNm}
                                            </Option>
                                        ))
                                    }
                                </Select>
                            </td>
                        </tr>
                    }
                </tbody>
            </FormTable>
        </AssignEnterBox>
    )
};

export default AssignEnterBasicBox;