import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { withRouter } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import {
  Text,
  Button,
  FormTable,
  Upload,
  Input,
  InputSearch,
  Modal,
} from "components";
import { Select, Divider } from "antd";
import Wrapper from "../Styled";

const { Option } = Select;

const initInputValues = {
  agentType: "RN",
  version: "1.0.0",
};

/**
 * Admin > Agent 버전관리
 */

const AgentAddVer = ({
  onBeforeupload,
  onRemoveFile,
  fileList,
  setType,
  addView,
  rowInfo,
  setRowInfo,
  onChangeList,
  orgaData,
  onClickOrg,
  setFileList,
  onClickCancel,
  setOrgaData,
}) => {
  const fmMessage = useFormatMessage();
  const END_POINT = process.env.REACT_APP_API_URI;
  const { formatMessage } = useIntl();
  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const fail = formatMessage({ id: "T0133" }); // 작업을 실패하였습니다.
  const NotAllowedEndPoint = formatMessage({ id: "T0219" }); // 끝에 마침표가 올 수 없습니다.
  const versionRequired = formatMessage({ id: "T0220" }); // Version은 필수 사항입니다.
  const uploadLimit = formatMessage({ id: "T0221" }); // 하나의 업로드 파일을 올려주세요.
  const managerRequired = formatMessage({ id: "T0222" }); // 담당자는 필수 사항입니다.
  const addFail = formatMessage({ id: "T0223" }); // 실패하였습니다.
  const addSuccess = formatMessage({ id: "T0224" }); // 추가되었습니다.
  const sameVersionNotAllowed = formatMessage({ id: "T0225" }); // 현재 버전과 같을 수 없습니다.
  const uploadRequired = formatMessage({ id: "T0226" }); // 업로드 파일은 필수 사항입니다.
  const agentDelSuccess = formatMessage({ id: "T0227" }); // Agent삭제가 완료되었습니다.
  const versionLengthCheck = formatMessage({ id: "T0228" }); // version 길이를 확인해주세요

  const [inputValues, setInputValues] = useState({ ...initInputValues });
  const [currentVer, setCurrentVer] = useState("");
  const [deleteVisible, setDeleteVisible] = useState(false);

  const onClickDeleteAgent = () => setDeleteVisible(true);

  const onCancelDeleteAgent = () => setDeleteVisible(false);

  const onClickEditAgent = async () => {
    const tmp = rowInfo.ver;
    const reg = /^\.+|\.+$/g;
    if (reg.test(rowInfo.ver)) alert(NotAllowedEndPoint);
    setRowInfo({ ...rowInfo, ver: tmp.replace(reg, "") });

    /* 빈칸 검사 */
    if (!rowInfo.ver) return alert(versionRequired);
    if (
      (fileList.length === 0 && !rowInfo.down) ||
      (fileList.length > 0 && rowInfo.down)
    )
      return alert(uploadLimit); // 하나의 업로드 파일을 올려주세요.
    if (!rowInfo.empNum) return alert(managerRequired);

    const fileId = fileList.length === 0 ? rowInfo.fileId : "";

    /* Agent 마스터 등록 */
    const formData = new FormData();
    fileList.forEach((file) => formData.append("files", file));

    const result = await fetchAbsolute(
      `post`,
      `/admin/agent/saveAgentVerMaster?type=${inputValues.agentType}&ver=${inputValues.version}&empNum=${orgaData.empNum}&fileId=${fileId}`,
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (result.error) return alert(addFail);
    else alert(addSuccess);
    onChangeList();
    setFileList([]);
    setType("basic");
  };

  const onClickAddAgent = () => {
    /* 버전이라 끝에 마침표가 있으면 알럿띄우고, 삭제 시켜준다 */
    const tmp = inputValues.version;
    const reg = /^\.+|\.+$/g;
    if (reg.test(inputValues.version)) alert(NotAllowedEndPoint);
    setInputValues({ ...inputValues, version: tmp.replace(reg, "") });

    /* 빈칸 검사 및 이전 버전과 같은 지 체크 */
    if (tmp === currentVer) return alert(sameVersionNotAllowed);
    if (!inputValues.version) return alert(versionRequired);
    if (fileList.length <= 0) return alert(uploadRequired);
    if (!orgaData.empNum) return alert(managerRequired);

    /* Agent 마스터 등록 */
    const agentEnter = async () => {
      const formData = new FormData();
      fileList.forEach((file) => formData.append("files", file));

      const result = await fetchAbsolute(
        `post`,
        `/admin/agent/saveAgentVerMaster?type=${inputValues.agentType}&ver=${inputValues.version}&empNum=${orgaData.empNum}`,
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (result.error) return alert(addFail);
      else {
        setInputValues({ ...initInputValues });
        onChangeList();
        setType("basic");
        setFileList([]);
        setOrgaData({});
        alert(addSuccess);
      }
    };
    agentEnter();
  };

  const onclickDeleteFile = () => {
    const tmpObj = { ...rowInfo };
    delete tmpObj.down;
    setRowInfo({ ...tmpObj });
  };

  const onOkDeleteAgent = async () => {
    const result = await fetchAbsolute(
      "post",
      `/admin/agent/deleteAgentVerMaster`,
      {
        data: [
          {
            type: rowInfo.type,
            fileId: rowInfo.fileId,
            ver: rowInfo.ver,
            empNum: rowInfo.empNum,
          },
        ],
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (result.error) return alert(fail);
    else alert(agentDelSuccess);
    onChangeList();
    setType("basic");
  };

  const onChangeVer = (e) => {
    const { value } = e.target;
    if (value.length > 10) return alert(versionLengthCheck);
    const reg = /^([\d.])*$/g;
    const limit = value.replace(/[.]{2,}/g, "."); // 점이 2개 연속일 수 없도록
    if (reg.test(value)) {
      setInputValues({ ...inputValues, version: limit });
      setRowInfo({ ...rowInfo, ver: limit });
    }
  };

  const onChangeSelectType = (e) => {
    setInputValues({ ...inputValues, agentType: e });
    setRowInfo({ ...rowInfo, type: e });
  };

  /* 버전 가지고 오기 */
  useEffect(() => {
    const getVer = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/agent/getAgentLastVer?type=${inputValues.agentType}`
      );
      setCurrentVer(result.data);
      setInputValues({
        ...inputValues,
        version: result.data ? result.data : "1.0.0",
      });
    };
    getVer();
  }, [inputValues.agentType]);

  return (
    <Wrapper>
      <div className="common__container">
        <div className="agent__section">
          {addView ? (
            <>
              <Text className="agent__title">
                {formatMessage({ id: "T0854" })}{/* Agent 버전 추가 */}
              </Text>
              <div className="agent__box">
                <FormTable>
                  <colgroup>
                    <col width="7.375%" />
                    <col width="16.625%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1081" /></Text>{/* Agent Type */}
                      </th>
                      <td>
                        <Select
                          style={{ width: 150 }}
                          onChange={onChangeSelectType}
                          defaultValue="RN"
                        >
                          <Option value="RN"><FormattedMessage id="T1080" /></Option>{/* Runner Agent */}
                          <Option value="PC"><FormattedMessage id="T1079" /></Option> {/*Dreago Agent*/}
                        </Select>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1085" /></Text>{/* Version */}
                        <br />
                        <Text
                          style={{
                            color: "D0D1D6",
                            fontSize: 13,
                            fontWeight: "bold",
                          }}
                        >
                          {currentVer ? (
                            <>
                              {/* (현재 버전{currentVer}) */}
                              {fmMessage({ id: "T0855", values: { version: currentVer } })}
                            </>
                          ) : (
                            <></>
                          )}
                        </Text>
                      </th>
                      <td>
                        <Input
                          style={{ height: 40 }}
                          placeholder={formatMessage({ id: "T0856" })} // 숫자와 소수점만 입력
                          value={inputValues.version}
                          onChange={onChangeVer}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1084" /></Text>{/* Update File */}
                        <br />
                        <Text style={{ color: "D0D1D6", fontSize: 13 }}></Text>
                      </th>
                      <td>
                        <Upload
                          action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                          multiple={false}
                          maxCount={1}
                          beforeUpload={onBeforeupload}
                          onRemove={onRemoveFile}
                          fileList={fileList}
                          accept={'.jar'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T0456" /></Text>{/* 담당자 */}
                      </th>
                      <td>
                        <InputSearch
                          placeholder={formatMessage({ id: "T0857" })} // 담당자를 검색하세요
                          onClick={onClickOrg}
                          value={orgaData.empNam}
                        />
                      </td>
                    </tr>
                  </tbody>
                </FormTable>
              </div>
            </>
          ) : (
            <>
              <Text className="agent__title">
                {formatMessage({ id: "T0858" })}{/* Agent 버전 수정 */}
              </Text>
              <div className="agent__box">
                <FormTable>
                  <colgroup>
                    <col width="7.375%" />
                    <col width="16.625%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1081" /></Text>{/* Agent Type */}
                      </th>
                      <td>
                        {
                          rowInfo.type === "PC" ?
                            `${formatMessage({ id: 'T1079' })}` //  Dreago Agent
                            : `${formatMessage({ id: 'T1080' })}` // Runner Agent
                        }
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1085" /></Text>{/* Version */}
                      </th>
                      <td>{rowInfo.ver}</td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1084" /></Text>{/* Update File */}
                        <br />
                        <Text style={{ color: "D0D1D6", fontSize: 13 }}>
                          {formatMessage({ id: "T0804" })}{/* (파일은 한번에 1개만 추가 가능합니다) */}
                        </Text>
                      </th>
                      <td>
                        <Upload
                          action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                          multiple={false}
                          maxCount={1}
                          beforeUpload={onBeforeupload}
                          onRemove={onRemoveFile}
                          fileList={fileList}
                          accept={'.jar'}
                        />
                        {rowInfo.down && (
                          <>
                            <Divider />
                            <div className="delete__file">
                              <Text>{rowInfo.fileName}</Text>
                              <Button
                                style={{ width: 50, height: 30 }}
                                type="default"
                                onClick={onclickDeleteFile}
                              >
                                {formatMessage({ id: "T0072" })}{/* 삭제 */}
                              </Button>
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T0456" /></Text>{/* 담당자 */}
                      </th>
                      <td>
                        <InputSearch
                          placeholder={formatMessage({ id: "T0857" })} // 담당자를 검색하세요
                          onClick={onClickOrg}
                          value={orgaData.empNam}
                        />
                      </td>
                    </tr>
                  </tbody>
                </FormTable>
              </div>
            </>
          )}
          <div className="btn__section">
            {addView ? (
              <Button type="primary" style={{ width: 120, height: 40 }} onClick={onClickAddAgent}>
                <FormattedMessage id="T0049" />{/* 추가 */}
              </Button>
            ) : (
              <>
                <Button type="primary" style={{ width: 120, height: 40 }} onClick={onClickEditAgent}>
                  <FormattedMessage id="T0054" />{/* 수정 */}
                </Button>
                <Button type="default" style={{ width: 120, height: 40 }} onClick={onClickDeleteAgent}>
                  <FormattedMessage id="T0072" />{/* 삭제 */}
                </Button>
              </>
            )}
            <Button type="default" style={{ width: 120, height: 40 }} onClick={onClickCancel}>
              <FormattedMessage id="T0051" />{/* 취소 */}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        centered
        visible={deleteVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0859" })} // Agent 버전 삭제
        onOk={onOkDeleteAgent}
        onCancel={onCancelDeleteAgent}
      >
        <div style={{ textAlign: "center" }}>
          <Text>{formatMessage({ id: "T0860" })}</Text>{/* 정말로 선택된 Agent 버전을 삭제하시겠습니까? */}
        </div>
      </Modal>
    </Wrapper>
  );
};

export default withRouter(AgentAddVer);