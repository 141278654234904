import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    padding: 30px 0;
    display: flex;
    justify-content: center; 
    background: #fff; 
    // 공통 
    .check_message {
        font-size: 13px;
        color: red;
    } 
    .admin_manage_btn_wrap {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        gap: 10px;
    }
    /* 내 정보 */ 
    .common_container_info table {
        height: 334px;
    }
    .common_container, .common_container_info { 
        display: flex;  
    }
    .common_container table { 
        height: 530px;
        width: 615px;
    }
    .common_container .first_section { 
        width: fit-content;
    }
    .second_section { 
        width: fit-content;
        min-width: 430px;
    }
    .first_section table tbody > tr > td {
        width: 100%;
    }
    .first_section .photo_section {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column; 
        align-items: center;
        gap: 30px; 
        padding: 30px 30px;
    } 
    .first_section .photo_section .upload_part {
        display: flex;
        place-items: center;
        height: fit-content;
    }
    .first_section .photo_section .upload_part .drag-box {
        width: 310px; 
    }
    .photo_section .btn_part {
        width: 100%;
    }
    .id_photo_part {
        width: 250px;
        height: 250px;
    }
    .btn_part button {
        width: 100%; 
    }
    // 비밀번호 확인
    .password_check_section {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    // 회원 등록
    .duplication_section {
        display: flex;
        gap: 10px;
    }
    .second_section input.ant-input {
        width: 360px;
    }
    .email_section {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }  
    .duplication_btn_section {
        display: flex;
        gap: 5px;
        flex-direction: column;
    }
    /* 비밀번호 확인 페이지 MyPwdCheck */
    .pwd_check_container {
        width: 1100px;
        height: fit-content;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .pwd_check_box {
        background: #f5f5f5;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 70px 0;
        align-items: center;
    }
    .pwd_check_notice {
        text-align: center;
    }
    .pwd_check_notice span {
        font-size: 16px;
    }
    .pwd_check_btn_section { 
        display: flex;
        gap: 10px;
        justify-content: center;
    }
    .new_password_section {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
    .new_password_section span {
        font-size: 13px; 
    }
`;

export default Wrapper;