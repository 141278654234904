import { SET_WORKFLOWSCHEDULE_VALUE, SET_WORKFLOWSCHEDULE_SPREAD, RESET_WORKFLOWSCHEDULE_VALUE, SET_WORKFLOWSCHEDULE_FILTERID } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const workflowScheduleInitialState = {
    inputValues: {
        workflowId: '',
        workflowNm: '',
        scheduleType: [],
        workId: [],
        workflowOrgCd: [],
        regId: [],
    },
    filterId: ''
};

const workflowSchedule = (state = workflowScheduleInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_WORKFLOWSCHEDULE_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            };
        }
        case SET_WORKFLOWSCHEDULE_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_WORKFLOWSCHEDULE_VALUE: {
            const inputValues = {
                ...workflowScheduleInitialState.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        case SET_WORKFLOWSCHEDULE_FILTERID: {
            return {
                ...state,
                filterId: action.filterId
            }
        }
        default:
            return state;

    }
}

export default workflowSchedule;
