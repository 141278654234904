import React from 'react';
import Wrapper from './Styled';
import Popup from './Popup';

const MainPopup = ({ popupList, onClickClosePopup }) => { 
    return (
        <Wrapper>
            {
                popupList.map(v => (
                    <Popup
                        key={v.noticeSeq}
                        noticeSeq={v.noticeSeq}
                        onClickClosePopup={onClickClosePopup}
                    />
                ))
            }
        </Wrapper>
    )
}

export default MainPopup;