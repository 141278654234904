import React, { useState, useEffect } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Button,
  Input,
  Table,
  Text,
  Modal,
  Collapse,
  FormTable,
} from "components";
import { Col, Row, Select, Collapse as AntCollapse, } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import Wrapper from "./Styled";
import fetchAbsolute from "utils/fetchAbsolute";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Panel } = AntCollapse;
const { Option } = Select;

/**
 * Filename: admin devicepool/index.js
 * Description: device pool 관리
 */

const initInputValues = {
  crCode: [],
  poolGroup: [],
  poolName: "",
};

const initialPageInfo = {
  sort: "crCode",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

const AdminDevicePool = ({ history }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const pageSize = [20, 50, 70];

  const [inputValues, setInputValues] = useState({ ...initInputValues });
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const deleteDevicePoolCom = formatMessage({ id: "T0139" }); //Device Pool 이 삭제되었습니다.
  const deleteDevicePoolFail = formatMessage({ id: "T0140" }); //Device Pool 삭제에 실패하였습니다.
  const devicePoolRemoveBeforeDel = formatMessage({ id: "T0141" }); //Device Pool Group 설정을 해제 한 후 삭제 할 수 있습니다

  const [PoolVisible, setPoolVisible] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [checkedDatas, setCheckedDatas] = useState([]);
  const [allList, setAllList] = useState([]);
  const [originDatas, setOriginDatas] = useState([]);
  const [poolGroupList, setPoolGroupList] = useState([]);
  const [crList, setCrList] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const onChangePageSize = (e) => {
    fetchPoolList(inputValues, { ...pageInfo, rowPerPage: +e, currentPage: 1 });
  };

  const onOkDelete = async () => {
    //체크한 row poolname으로 조회후 정보 가져오기
    const delData = checkedDatas.map((ele) =>
      originDatas.find((e) => e.devicePoolName === ele)
    );
    //delete 할 데이터 array 반복문 돌려서 삭제하기
    let fetchFlag = true;

    try {
      const deleteDevicePool = await fetchAbsolute(
        "delete",
        `/admin/devicePool/deleteDevicePool?devicePoolName=${delData[0].devicePoolName}&crCode=${delData[0].crCode}`
      );
      if (deleteDevicePool.status === 200 || deleteDevicePool.status === 201) {
        alert(deleteDevicePoolCom);
      } else {
        throw new Error(deleteDevicePool.status);
      }
    } catch (e) {
      fetchFlag = false;
    } finally {
      if (!fetchFlag) {
        return alert(deleteDevicePoolFail);
      }
    }
    //삭제 후 다시 API 조회
    fetchPoolList();
    setPoolVisible(false);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch();
    }
  };

  const onCancelDelete = () => setPoolVisible(false);

  const onChangeValues = (e) => {
    const { value } = e.target;
    setInputValues({
      ...inputValues,
      poolName: value,
    });
  };

  const onClickSearch = () => {
    fetchPoolList();
  };

  const onClickReset = () => {
    setInputValues({ ...initInputValues });
    fetchPoolList({ ...initInputValues });
  };

  const onChangePoolGroup = (e) => {
    setInputValues({
      ...inputValues,
      poolGroup: e,
    });
  };

  /* 테이블 행 정렬 */
  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'crCode';
    let orderBy = "";
    if (!sorter.order) {
      order = "crCode"
      orderBy = ",asc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    fetchPoolList(inputValues, searchObj);
  };

  const fetchPoolList = async (searchValues, page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    if (searchValues === undefined) {
      searchValues = { ...inputValues };
    }
    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/admin/devicePool/getDevicePoolListMulVal?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`, {
      data: {
        crCode: searchValues.crCode.includes("") ? [] : searchValues.crCode,
        devicePoolGroupCd: searchValues.poolGroup,
        devicePoolName: searchValues.poolName,
      },
    }
    );
    setTableLoading(false);
    const { data: { lists, totalItemsCount } } = result;
    setAllList([...lists]);
    setOriginDatas([...lists]);
    setPageInfo({ ...page, total: +totalItemsCount });
  };

  const onChangeCr = (e) => {
    if (e.substr(0, 3) !== "COE") {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }

    setInputValues({
      ...inputValues,
      crCode: [e.toUpperCase()],
      poolGroup: e.substr(0, 3) !== "COE" ? [] : inputValues.poolGroup,
    });
  };

  const onClickDeleteButton = (e) => {
    let poolNameCheck = originDatas.find(
      (v) => v.devicePoolName === checkedDatas[0]
    );

    if (checkedDatas.length === 0) {
      return alert(formatMessage({ id: "T0253" })); // 삭제할 데이터를 선택해주세요.
    }
    if (checkedDatas.length > 1) {
      return alert(formatMessage({ id: "T0259" })); // 1개의 Device Pool만 선택 가능합니다.
    }
    if (poolNameCheck.crType === "COE") {
      if (poolNameCheck.devicePoolGroupNm.length !== 0) {
        alert(devicePoolRemoveBeforeDel);
        return;
      }
    }
    setPoolVisible(true);
  };

  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onSelectAll: (selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setCheckedDatas([]);
      }
    },
    onChange: (selectedRowKeys) => {
      setCheckedDatas(selectedRowKeys);
    },
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => {
        history.push(
          `/admin/devicepool/${record.crType}/${record.devicePoolName}/${record.crCode}`
        );
      },
    };
  };

  const columns = [
    {
      key: 1,
      title: <FormattedMessage id="T1036" />, // CR
      dataIndex: "crType",
      align: "center",
      width: "15%",
      render: (text, record) => {
        if (record.crCode.includes("DEV")) {
          return text + ` ${formatMessage({ id: "T0682" })}`; // (개발)
        } else {
          return text + ` ${formatMessage({ id: "T0683" })}`; // (운영)
        }
      },
    },
    {
      key: 2,
      title: <FormattedMessage id="T0727" />, //Device Pool Group
      dataIndex: "devicePoolGroupNm",
      align: "center",
      width: "15%",
    },
    {
      key: 3,
      title: <FormattedMessage id="T1042" />, //Device Pool ID
      dataIndex: "devicePoolId",
      align: "center",
      width: "15%",
    },
    {
      key: 4,
      title: <FormattedMessage id="T1061" />, //Device Pool Name
      dataIndex: "devicePoolName",
      align: "center",
      width: "20%",
    },
    {
      key: 5,
      title: <FormattedMessage id="T1117" />, //Description
      width: "25%",
      align: "center",
      dataIndex: "description",
    },
    {
      key: 6,
      title: <FormattedMessage id="T1118" />, //# of Device
      align: "center",
      width: "10%",
      dataIndex: "deviceCount",
      render: (text, record) => {
        return Number(text);
      },
    },
  ];

  useEffect(() => {
    const fetchPoolGroup = async () => {
      //Devie pool Group select 불러옴
      const PoolGroupResult = await fetchAbsolute(
        "get",
        "/admin/commonMaster/getDevicePoolList"
      );
      setPoolGroupList([...PoolGroupResult.data]);
    };

    const fetchPoolCr = async () => {
      const result = await fetchAbsolute("get", "/common/getControlRoomList");
      setCrList([...result.data]);
    };

    fetchPoolGroup();
    fetchPoolCr();
    fetchPoolList();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper
        style={{
          padding: "30px 50px",
        }}
      >
        <div>
          <Collapse
            expandIconPosition="end"
            onChange={onChangeCollapse}
            expandIcon={() =>
              !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
            }
            activeKey={openCollapse ? ["1"] : []}
            defaultActiveKey={openCollapse ? ["1"] : []}
          >
            <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
              <FormTable pointBorder={false} size="sm">
                <colgroup>
                  <col width="10.375%" />
                  <col width="13.625%" />
                  <col width="10.375%" />
                  <col width="13.625%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th style={{ borderRadius: "14px 0 0 0" }}>
                      <Text>{formatMessage({ id: "T1036" })}</Text>{/* CR */}
                    </th>
                    <td>
                      <Select
                        style={{ width: 250 }}
                        name="crselect"
                        id="crSelect"
                        onChange={onChangeCr}
                        value={inputValues.crCode}
                        placeholder="All"
                      >
                        <Option value="">All</Option>
                        {crList.map((v) => (
                          <Option value={v.crCd} key={v.crCd}>
                            {v.crTypeDesc === null
                              ? v.crDesc
                              : v.crEnvGubun === "PRD"
                                ? v.crTypeDesc + ` ${formatMessage({ id: "T0683" })}` // (운영)
                                : v.crTypeDesc + ` ${formatMessage({ id: "T0682" })}` // (개발)  
                            }
                          </Option>
                        ))}
                      </Select>
                    </td>
                    <th><Text>{formatMessage({ id: "T0727" })}</Text></th>{/* Device Pool Group */}
                    <td>
                      <Select
                        placeholder={formatMessage({ id: "T0024" })} // 선택
                        style={{ width: 250 }}
                        name="poolGroup"
                        value={inputValues.poolGroup}
                        onChange={onChangePoolGroup}
                        id="poolGroup"
                        maxTagCount="responsive"
                        mode="multiple"
                        disabled={isDisabled}
                      >
                        {poolGroupList.map(
                          (option, index) =>
                            option.useYn === "Y" && (
                              <Select.Option key={index} value={option.cd}>
                                {option.cdNm}
                              </Select.Option>
                            )
                        )}
                      </Select>
                    </td>
                  </tr>
                  <tr>
                    <th><Text>{formatMessage({ id: "T1061" })}</Text></th>{/*  Device Pool Name */}
                    <td>
                      <Input
                        placeholder={formatMessage({ id: "T1061" })} // Device Pool Name
                        style={{ width: 250 }}
                        name="poolName"
                        value={inputValues.poolName}
                        onChange={onChangeValues}
                        onKeyPress={onKeyPress}
                      />
                    </td>
                  </tr>
                </tbody>
              </FormTable>
              <Row style={{ padding: "0 13px" }}>
                <Col
                  span={24}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "0 25px",
                    padding: "10px 0",
                  }}
                >
                  <Button width="120" height="40" onClick={onClickReset}>
                    <FormattedMessage id="T0037" />{/* 초기화 */}
                  </Button>
                  <Button
                    width="120"
                    height="40"
                    type="primary"
                    onClick={onClickSearch}
                  >
                    <FormattedMessage id="T0039" />{/* 검색 */}
                  </Button>
                </Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
        <div
          style={{
            padding: '20px 0',
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <span>
              {formatMessage({ id: "T0036" })}{/* 페이지당 행 */}
            </span>&nbsp;
            <Select
              style={{ width: 89, height: 32 }}
              name="pagination"
              onSelect={onChangePageSize}
              value={pageInfo.rowPerPage}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                  {/* {number} 개 */}
                </Option>
              ))}
            </Select>
          </div>
          <div>
            <Button width="150" onClick={onClickDeleteButton}>
              {formatMessage({ id: "T0072" })}{/* 삭제 */}
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              width="150"
              type="primary"
              onClick={() => {
                history.push(`/admin/devicepool/pooladd`);
              }}
            >
              <FormattedMessage id="T0049" />{/* 추가 */}
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(e) => e.devicePoolName}
                columns={columns}
                dataSource={allList}
                pagination={true}
                loading={tableLoading}
                showSorterTooltip={true}
                rowSelection={rowSelection}
                onRow={onRow}
                onChange={onChangeTableColumns}
                pageOptions={{
                  total: +pageInfo.total,
                  current: +pageInfo.currentPage,
                  pageSize: +pageInfo.rowPerPage,
                }}
              />
            </Col>
          </Row>
        </div>
        <Modal
          width={540}
          visible={PoolVisible}
          title={formatMessage({ id: "T0684" })} //Device Pool 삭제
          okText={formatMessage({ id: "T0072" })} //삭제
          onCancel={onCancelDelete}
          onOk={onOkDelete}
        >
          <Text
            style={{
              display: "block",
              fontSize: 14,
            }}
          >
            {formatMessage({ id: "T0685" })}{" "}
            {/* 정말로 선택된 Device Pool을 삭제하시겠습니까? */}
          </Text>
          <Text
            style={{
              display: "block",
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {formatMessage({ id: "T0686" })}{" "}
            {/* ※ 스케줄이 설정된 Device Pool을 삭제 할 경우 문제가 될 수 있습니다. */}
          </Text>
        </Modal>
      </Wrapper>
    </PageTemplate >
  );
};

export default AdminDevicePool;
