import React from "react";
import { PageTemplate, Header, Footer, Button } from "components";
import Container from "containers/Container";
import "antd/dist/antd.css";
import { FormattedMessage } from 'react-intl';

const Boxwood = ({ profile }) => {
  const onClick = (e) => {
    const { value } = e.target.dataset;
    let url = 'http://52.78.98.103:8080/Boxwood_portal/';

    switch (value) {
      case "1":
        url = url + 'connector';
        break;
      case "2":
        url = url + 'serviceTask';
        break;
      case "3":
        url = url + 'process';
        break;
      case "4":
        url = url + 'monitoring';
        break;
      case "5":
        url = url + 'kpis';
        break;
      case "6":
        url = url + 'designer';
        break;
      case "7":
        url = "http://52.78.98.103:8080/Boxwood_portal/embed/designer/direct/new";
        break;
      case "8":
        url = url + 'triggerMgt';
        break;
      default:
    }
    document.box_frame.location = url + `?userId=${profile.EMP_NO}`;
  };



  // function initBoxwoodModelerPage(parameter) {

  //   const iframeWindow = document.getElementById('box_frame').contentWindow;

  //   return {
  //     _postMessage: function (parameter) {
  //       iframeWindow.postMessage(parameter,
  //         "*");

  //     },



  //     /**

  //      * 프로세스 디자인 저장 요청

  //      */

  //     requestProcessDesignSave: function () {
  //       this._postMessage({
  //         "command": "req_dwp_save_design"
  //       });

  //     },



  //     /**

  //      * 프로세스 저장 요청

  //      */

  //     requestProcessSave: function () {
  //       this._postMessage({
  //         "command": "req_dwp_save_process"
  //       });

  //     },



  //     /**

  //      * 프로세스 배포 요청

  //      */

  //     requestProcessDeploy: function () {
  //       this._postMessage({
  //         "command": "req_dwp_deploy_process"
  //       });
  //     }
  //   }
  // }



  function postMessage(command) {

    const iframeWindow = document.getElementById('box_frame')?.contentWindow;


    // switch (command) {
    //   case "req_dwp_save_design":
    //     iframeWindow.postMessage({
    //       command: "req_dwp_save_design"
    //     }, "http://52.78.98.103:8080/Boxwood_portal")
    //     break;
    // }


    iframeWindow.postMessage({
      command: "req_dwp_save_design"
    }, "http://52.78.98.103:8080/Boxwood_portal")


    window.addEventListener('message',
      function (e) {

        const receiveData = e.data;
        const cmd = receiveData.command;
        const result = receiveData.result;

        switch (cmd) {

          case "resp_dwp_save_design":

            /*
            [Sample result]
            {
                "isSuccess": true,
                "processDesignCd": "MODELERDATA2021110230459923570239575843485709324702934",
                "taskId": ""
            }
 
            [Description]
             - isSuccess: 저장 성공 여부
             - processDesignCd: 박스우드 프로세스 디자인 키값
             - taskId: DWP 자동화 과제 ID
           */

            break;

          case "resp_dwp_save_process":

            /*
 
            [Sample result]
            {
                "isSuccess": true,
                "processCd": "PROCESS2021110230459923570239575239480843485709324702934",
                "taskId": "",
            }
 
            [Description]
             - isSuccess: 저장 성공 여부
             - processCd: 박스우드 프로세스 키값
             - taskId: DWP 자동화 과제 ID         
            */
            break;

          case "resp_dwp_deploy_process":

            /*
            [Sample result]
            {
                "isSuccess": true,
                "processCd": "PROCESS2021110230459923570239575239480843485709324702934",
                "taskId": ""
                "triggerUrl": "http://..."
            }
 
            [Description]
             - isSuccess: 저장 성공 여부
             - processCd: 박스우드 프로세스 키값
             - taskId: DWP 자동화 과제 ID
             - triggerUrl: 박스우드 프로세스 실행 요청 URL
            */

            break;
          default:
        }

      });

  }


  return (
    <PageTemplate header={<Header />} footer={<Footer />}>
      <Container>
        <div style={{
          display: 'flex',
          justifyContent: 'space-around'
        }}>
          <Button width='170' data-value="1" onClick={onClick}>
            Connector
          </Button>
          <Button width='170' data-value="2" onClick={onClick}>
            Service Task
          </Button>
          <Button width='170' data-value="3" onClick={onClick}>
            Process
          </Button>
          <Button width='170' data-value="4" onClick={onClick}>
            Monitoring
          </Button>
          <Button width='170' data-value="5" onClick={onClick}>
            <FormattedMessage id="T1212" />
          </Button>
          <Button width='170' data-value="6" onClick={onClick}>
            Modeling Tool
          </Button>
          <Button width='170' data-value="7" onClick={onClick}>
            AddTask
          </Button>
          <Button type="primary" onClick={postMessage}>
            저장하기
          </Button>
        </div>
        <div>
          <iframe
            frameBorder="0"
            frameSpacing="0"
            marginwidth="0"
            style={{
              position: "absolute",
              overflow: "hidden",
              width: 1400,
              height: "calc(100vh - 118px - 64px - 60px)",
            }}
            id='box_frame'
            name='box_frame'
            title='box_frame'
          />

        </div>
      </Container>
    </PageTemplate>
  );
};

export default Boxwood;
