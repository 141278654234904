import React, { useState, useEffect } from "react";
import { Collapse as AntCollapse } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setListUrl } from "store/listUrl/action";
import { resetHandyFlowBpdListValue } from "store/hfBpdList/action";
import {
  PageTemplate,
  Header,
  Footer,
  HFBpdListTop,
  HFBpdListBottom,
  Collapse,
} from "components";
import Container from "containers/Container";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import { useIntl } from "react-intl";
import fetchAbsolute from "utils/fetchAbsolute";
import queryString from "query-string";
import moment from "moment";
import { getUniqueKey } from "utils/Str";

/**
 * Filename: HFBpdList/index.js
 * BizFlow > Business Process Design  > List
 * Description: Business Process Design List 컴포넌트
 */

const { Panel } = AntCollapse;

const initialSearchQuery = {
  sort: "regTimestamp,desc",
  page: 0,
  size: 20,
  tab: "all",
  total: 0
};

const initialInputValues = {
  bpdId: "",
  bpdNm: "",
  bpdOrgCd: [],
  workId: [],
  mgrIds: [],
  regFr: "",
  regTo: "",
  regType: "",
};

const HFBpdList = ({ location: { search }, history }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const inputValues = useSelector(
    (state) => state.get(getUniqueKey("hfBpdList")).inputValues
  );

  const [searchQuery, setSearchQuery] = useState(initialSearchQuery);
  const [sortedInfo, setSortedInfo] = useState({})
  const [beforeSearch, setBeforeSearch] = useState("");
  const [openCollapse, setOpenCollapse] = useState(true);
  const [tabsValue, setTabsValue] = useState("");
  const [tableData, setTableData] = useState([]);
  const [excelBody, setExcelBody] = useState([]);
  const [tableTotal, setTableTotal] = useState({
    all: 0,
    myTask: 0,
    interest: 0,
  });

  const [tableLoading, setTableLoading] = useState(false);
  //데이트 타입 변경
  const convertTypeDate = (type) => {
    const dateObj = {
      regFr: "",
      regTo: moment().format("YYYYMMDD"),
    };
    switch (type) {
      case "today":
        dateObj.regFr = moment().add(0, "d").format("YYYYMMDD");
        break;
      case "week":
        dateObj.regFr = moment().subtract(6, "d").format("YYYYMMDD");
        break;
      case "month":
        dateObj.regFr = moment().subtract(1, "months").format("YYYYMMDD");
        break;
      default:
    }

    return dateObj;
  };
  //검색단 접기
  const onChangeCollapse = () =>
    setOpenCollapse((openCollapse) => !openCollapse);

  const replaceSearch = (searchObject) => {
    const initialKeys = Object.keys(initialInputValues);
    const searchObj = { ...searchQuery, ...searchObject };
    const beforeKeys = Object.keys(searchObj).filter((key) =>
      initialKeys.includes(key)
    );

    // 검색해야 할 내용들 정리
    Object.keys(searchObj).forEach((key) => {
      const data = searchObj[key];

      if (!beforeKeys.includes(key)) {
        return;
      }

      if (Object.keys(data).length <= 0) {
        delete searchObj[key];
        return;
      }

      if (["mgrIds"].includes(key)) {
        if (typeof data === 'string') {
          searchObj[key] = data;
        } else {
          const tmpArr = [];
          for (let i in data) {
            tmpArr.push(data[i].empNum);
          }
          searchObj[key] = tmpArr;
        }
      } else if (["bpdOrgCd"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          tmpArr.push(data[i].codeId);
        }
        searchObj[key] = tmpArr;
      } else if (["workId"].includes(key)) {
        const tmpArr = [];
        for (let i in data) {
          tmpArr.push(data[i].cd);
        }
        searchObj[key] = tmpArr;
      } else if (["regFr", "regTo"].includes(key)) {
        searchObj[key] = data.replace(/-/g, "");
      } else if (key === "regType") {
        if (["today", "week", "month"].includes(data)) {
          const dateObj = convertTypeDate(data);
          searchObj.regFr = dateObj.regFr;
          searchObj.regTo = dateObj.regTo;
        } else if (['custom'].includes(data[0])) {
          searchObj.regFr = moment(searchObj.regFr).format("YYYYMMDD");
          searchObj.regTo = moment(searchObj.regTo).format("YYYYMMDD");
        }
      }
      // } else if (key === "regType" && !['custom',""].includes(data)) {
      //   const dateObj = convertTypeDate(data);
      //   searchObj.regFr = dateObj.regFr;
      //   searchObj.regTo = dateObj.regTo;
      // }
    });

    const searchString = queryString.stringify(searchObj);

    if ("?" + searchString === search) return;
    dispatch(setListUrl("hfBpdList", searchString));
    history.push({ search: searchString });
  };

  const getTableDatas = async (searchObject) => {
    const searchObj = { ...inputValues, ...searchObject }
    let path = "";
    switch (searchObject.tab) {
      case "all":
        path = "bpdListHF";
        break;
      case "myTask":
        path = "bpdMyListHF";
        break;
      case "interest":
        path = "bpdInterestListHF";
        break;
      default:
        path = "bpdListHF";
    }
    /* 데이터 가공해주기 */
    const bpdOrgCd = searchObj.bpdOrgCd.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.codeId);
      return tmpArr;
    });
    const mgrIds = searchObj.mgrIds.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum);
      return tmpArr;
    });
    const workId = searchObj.workId.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.cd);
      return tmpArr;
    });

    const regFr = searchObj.regFr.replace(/-/g, "");
    const regTo = searchObj.regTo.replace(/-/g, "");

    let bpdId = [];

    if (searchObject.bpdId === null) {
      bpdId = [];
    } else if (searchObject.bpdId != null) {
      bpdId = [searchObject.bpdId];
    }

    const parameter = {
      bpdId,
      workId: workId?.flat(),
      bpdNm: searchObject.bpdNm,
      regFr,
      regTo,
      bpdOrgCd: bpdOrgCd?.flat(),
      mgrIds: mgrIds?.flat(), // 기존 등록자 key 그대로 담당자에 사용(api 호출할 경우에)
      sessionId: searchObj.sessionId,
    };
    setTableLoading(true);

    const result = await fetchAbsolute("post", `/bpd/${path}${search}`, {
      data: {
        ...parameter,
      }
    });
    // totalCount
    setTableLoading(false);
    if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.

    /* 엑셀을 위한 */
    setExcelBody({ ...parameter });

    const { totalCount, myTaskCount, interestTaskCount, bpds } = result.data;

    setTableTotal({
      all: bpds.length>0 && totalCount ? totalCount : 0,
      myTask: bpds.length>0 && myTaskCount ? myTaskCount : 0,
      interest: bpds.length>0 && interestTaskCount ? interestTaskCount : 0,
    });
    
    const datas = bpds.map((v) => ({
      ...v,
      regTimestamp: moment(v.regTimestamp).format("YYYY-MM-DD"),
    }));
    setTableData([...datas]);

    const beforeArray = [];
    const beforeObject = {};

    const initialKeys = Object.keys(initialInputValues);
    const beforeKeys = Object.keys(searchObject).filter((key) =>
      initialKeys.includes(key)
    );

    Object.keys(searchObject).forEach((key) => {
      const data = searchObject[key];

      if (!beforeKeys.includes(key)) {
        return;
      }

      beforeObject[key] = data;

      if (["mgrIds"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].empNam);
        }
      } else if (["bpdOrgCd"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].codeNm);
        }
      } else if (["workId"].includes(key)) {
        for (let i in data) {
          beforeArray.push(data[i].cdNm);
        }
      } else if (["regFr", "regTo"].includes(key)) {
        beforeArray.push(data);
      } else if (beforeKeys.includes(key)) {
        beforeArray.push(data);
      }
    });
    if (beforeArray.length > 0) {
      const beforeText = beforeArray.slice(0, 2).join(", ");
      localStorage.setItem("beforeBpdSearch", beforeText);
      localStorage.setItem("beforeBpdObject", JSON.stringify(beforeObject));
      setBeforeSearch(beforeText);
    }
  };

  const toggleHeart = () => {
    getTableDatas({ ...searchQuery });
  };

  // query String 변경 될 때마다
  useEffect(() => {
    const preSearchObject = queryString.parse(search);
    if (search.length <= 0) {
      replaceSearch({ ...searchQuery });
      return;
    } else if (
      !preSearchObject.page ||
      !preSearchObject.size ||
      !preSearchObject.sort ||
      !preSearchObject.tab
    ) {
      replaceSearch({
        ...searchQuery,
        ...preSearchObject,
      });
      return;
    }
    // search query로 데이터 세팅하는 함수
    const setDatas = async () => {
      const searchObject = queryString.parse(search);
      const { regFr, regTo, regType } = searchObject;
      const { mgrIds, workId, bpdOrgCd } = preSearchObject;

      let isReplace = false;

      // 부서 정보 세팅      
      if (bpdOrgCd) {
        try {
          if (typeof bpdOrgCd === 'string') {
            const { data: { mdList } } = await fetchAbsolute(
              "get",
              `/common/departmentList?orgCd=${bpdOrgCd}`
            );
            searchObject.bpdOrgCd = [...mdList[0]];
          } else {
            const tmpArr = [];
            for (let i in bpdOrgCd) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/departmentList?orgCd=${bpdOrgCd[i]}`
              );
              tmpArr.push(data?.mdList[0]);
            }
            searchObject.bpdOrgCd = [...tmpArr];
          }
        } catch {
          delete searchObject.bpdOrgCd;
        }
      };
      // 등록자 정보 세팅
      if (mgrIds) {
        try {
          if (typeof mgrIds === 'string') {
            const { data: { mdFPAList } } = await fetchAbsolute(
              "get",
              `/common/memberNoList?empNo=${mgrIds}`
            );
            searchObject.mgrIds = [...mdFPAList];
          } else {
            const tmpArr = [];
            for (let i in mgrIds) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${mgrIds[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
            searchObject.mgrIds = [...tmpArr];
          }
        } catch {
          delete searchObject.mgrIds;
        }
      }
      // 업무 분류 정보 세팅
      if (workId) {
        try {
          if (typeof workId === 'string') {
            const { data } = await fetchAbsolute(
              "get",
              `/common/workClassification?cd=${workId}`
            );
            searchObject.workId = [{ ...data }];
          } else {
            const tmpArr = [];
            for (let i in workId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/workClassification?cd=${workId[i]}`
              );
              tmpArr.push(data);
            }
            searchObject.workId = [...tmpArr];
          }
        } catch {
          delete searchObject.workId;
        }
      }
      if (inputValues.regFr) {
        const date = moment(inputValues.regFr).format("YYYY-MM-DD");
        if (date === "Invalid date") {
          delete searchObject.regFr;
        } else {
          searchObject.regFr = date;
        }
      }
      if (inputValues.regTo) {
        const date = moment(inputValues.regTo).format("YYYY-MM-DD");
        if (date === "Invalid date") {
          delete searchObject.regTo;
        } else {
          searchObject.regTo = date;
        }
      }
      if (regType && !["custom", ""].includes(regType)) {
        const dateObj = convertTypeDate(regType);
        if (dateObj.regFr !== regFr || dateObj.regTo !== regTo) {
          searchObject.regFr = dateObj.regFr;
          searchObject.regTo = dateObj.regTo;
          isReplace = true;
        }
      }
      const tmpObj = {
        ...initialSearchQuery,
        ...searchObject,
      };
      if (isReplace) {
        replaceSearch({ ...tmpObj });
      } else {
        setSearchQuery({ ...tmpObj });
        setTabsValue(tmpObj.tab);
        getTableDatas({ ...tmpObj });
      }
    };
    setDatas();
  }, [search]);

  useEffect(() => {
    return () => {
      dispatch(resetHandyFlowBpdListValue());
      setTableLoading(false);
    };
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      <Container
        style={{
          padding: "30px 0",
          background: "#fff",
        }}
      >
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key='1'>{/* 상세검색 */}
            <HFBpdListTop
              searchQuery={searchQuery}
              replaceSearch={replaceSearch}
              beforeSearch={beforeSearch}
              setBeforeSearch={setBeforeSearch}
              initialSearchQuery={initialSearchQuery}
              setSearchQuery={setSearchQuery}
              setSortedInfo={setSortedInfo}
            />
          </Panel>
        </Collapse>
        <HFBpdListBottom
          tableTotal={tableTotal}
          tabsValue={tabsValue}
          tableData={tableData}
          replaceSearch={replaceSearch}
          searchQuery={searchQuery}
          toggleHeart={toggleHeart}
          tableLoading={tableLoading}
          sortedInfo={sortedInfo}
          setSortedInfo={setSortedInfo}
          excelBody={excelBody}
        />
      </Container>
    </PageTemplate>
  );
};

export default HFBpdList;
