import React from "react";
import Container from "containers/Container";
import Wrapper from "./Styled";
import TaskInfo from "./TaskInfo";
import TaskStatus from "./TaskStatus"; 

/**
 * Filename: AssignLookupHeader.js
 * Description: 과제 조회 페이지 상단 과제 정보들이 과 탭이 나오는 화면
 */

const AssignLookupHeader = () => {  
  return (
    <Wrapper>
      <Container style={{ background: "#fff", paddingBottom: 40 }}>
        <div className="assign_lookup__header">
          <TaskInfo/>
          <TaskStatus/>
        </div>
      </Container>
    </Wrapper>
  );
};

export default AssignLookupHeader;
