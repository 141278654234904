import React from 'react';
import styled from 'styled-components';
import { Modal, Text } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage'

const Wrapper = styled(props => <Modal {...props} />)`

`
/**
 * Filename: DeleteCredentialModal/index.js
 * Description: Credential 삭제 모달 
 */

const DeleteCredentialModal = ({ visible, onOk = () => { }, onCancel = () => { }, credentialName = '', crType = 'COE' }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();
    const onOkModal = async () => {
        const result = await fetchAbsolute('delete', `/hwlm/credential`, {
            data: {
                name: credentialName,
                crType: crType.toUpperCase(),
            }
        });
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        onOk();
    }
    return (
        <Wrapper
            width={450}
            visible={visible}
            title={formatMessage({ id: 'T0346' })} // Credential 삭제
            okText={formatMessage({ id: 'T1149' })} // 요청
            onCancel={onCancel}
            onOk={onOkModal}
        >
            <Text
                style={{
                    display: "block",
                    fontSize: 14,
                }}
            >
                {fmMessage({ id: 'T0347', values: { credentialName } }).split("\n").map((data, index) => {
                    return (
                        <span key={index}>
                            {data}
                            <br />
                        </span>
                    );
                })}

                {/* {credentialName} Credential을 삭제하시겠습니까?
                <br />
                Credential 값을 사용하고 있는 과제가 있거나
                <br />
                Attribute가 존재하는 경우 삭제되지 않습니다. */}
            </Text>
            <Text
                style={{
                    display: "block",
                    fontSize: 14,
                    paddingTop: 10,
                    color: "#808080",
                }}
            >
                {formatMessage({ id: 'T0348' })}{/* ※ 삭제가 되지 않는 경우 관리자에게 문의하시길 바랍니다. */}
            </Text>
        </Wrapper>
    )
}

export default DeleteCredentialModal;