import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUniqueKey } from 'utils/Str';
import fetchAbsolute from "utils/fetchAbsolute";
import moment from "moment";
import { UserTag, Text } from 'components';
import { CheckOutlined } from '@ant-design/icons';
import { setVisible } from "store/loading/action";
import { useIntl } from 'react-intl';

/**
 * Filename: AssginLookupHistory.js
 * Description: 과제 조회 페이지 하단에 히스토리 탭
 */

const AssginLookupHistory = () => {
    const { formatMessage } = useIntl();

    const assignLookup = useSelector(state => state.get(getUniqueKey('assignLookup')));

    const dispatch = useDispatch();

    const [historyDatas, setHistoryDatas] = useState([]);

    useEffect(() => {
        if (!assignLookup.taskData?.taskId) {
            return;
        }
        const getHistory = async () => {
            dispatch(setVisible(true));
            const result = await fetchAbsolute("get", `/task/oracle/taskHistoryList/${assignLookup.taskData?.taskId}`);
            if(result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            dispatch(setVisible(false));
            setHistoryDatas([...result.data]);
        }
        getHistory();
    }, [assignLookup.taskData]);

    return (
        <div className='history_wrap lookup_body__box'>
            {
                historyDatas.map((v, index) => {
                    return (
                        <div className='history_box' key={index}>
                            <div className='history_date'>
                                <Text className='history_date__text'>
                                    {moment(v.chgTimestamp).format("YYYY.MM.DD HH:mm:ss")}
                                    {/* {v.chgTimestamp} */}
                                </Text>
                            </div>
                            <div className='history_box__sub'>
                                <div className='history_check'>
                                    <CheckOutlined />
                                </div>
                                <div className='history_tail'></div>
                                <div className='history_content'>
                                    <Text className='history_content__title'>
                                        {
                                            v.chgCdDetail ?
                                                v.chgNmDetail
                                                :
                                                v.chgNm
                                        }
                                    </Text>
                                    <UserTag
                                        className='history_content__user'
                                        profile={{
                                            NAME_KOR: v.chgEmpNm,
                                            EMP_NO: v.chgEmpNo,
                                            DEPT_NAME_KOR: v.chgOrgNm,
                                            FILE_LINK: v.chgFileLink
                                            // FILE_ID: v.fileId,
                                        }}
                                        contextVisible={true}
                                    />
                                    <Text className='history_content__text'>
                                        {
                                            (
                                                v.chgDetailDesc?.split("\n").map((line, index, arr) => {
                                                    return (
                                                        <div key={index}>
                                                            {line}
                                                            {
                                                                arr.length - 1 > index && <br />
                                                            }
                                                        </div>
                                                    );
                                                })
                                            )
                                        }
                                    </Text>
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

export default AssginLookupHistory;