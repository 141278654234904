import styled from 'styled-components';

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.4);
    z-index: 5000;
    .main_popup__box {
        position: absolute;
        border-radius: 5px;
        overflow: hidden;
        border: 2px solid rgb(206, 206, 206);
        box-shadow: rgb(0 0 0 / 16%) 0px 9px 28px;
        background: rgb(255, 255, 255);
        top: 10%;
        left: 30%; 
        width: 250px;
    }
    .main_popup__header {
        position: relative;
        width: 100%;
        height: 50px;
        padding: 0px 17px;
        background: #F9F9F9;
        line-height: 50px;
        border-bottom: 2px solid #D0D1D6;
        box-shadow: rgb(0 0 0 / 4%) 0px 2px 1px;
    }
    .main_popup__header > span {
        color: #000;
        font-size: 18px;
        font-weight: bold; 
    }
    .main_popup__header_close {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .main_popup__body_content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        text-align: left;
        border-bottom: 1px solid #cecece;
        height: 250px;
    }
    .main_popup__box_textarea {
        height: 100%;
        resize: none;
    }
    .main_popup__footer {
        display: flex;
        justify-content: flex-end;
        height: 50px;
    }
    .main_popup__footer_close {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        width: 114px;
        height: 100%;
    }
    .main_popup__footer_close::before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: calc(100% - 18px);
        background: #cecece;
    }
    .main_popup__footer_close button {
        width: 100%;
        height: 100%;
        font-size: 14px;
    }
    ul.ant-rate {
        display: flex;
        place-content: center;
        gap: 5px;
        font-size: 22px;
    }
    .flex.justify-end button {
        width: -webkit-fill-available;
        font-size: 14px;
    }
`;

export default Wrapper;