import {
    SET_HANDYFLOWWORKFLOWLIST_VALUE,
    SET_HANDYFLOWWORKFLOWLIST_SPREAD,
    RESET_HANDYFLOWWORKFLOWLIST_VALUE,
    SET_HANDYFLOWWORKFLOWLIST_FILTERID,
  } from "./action";
  
  /**
   * Breadcrumb 구현을 위한 State
   *
   */
  const hfWorkflowListInitial = {
    inputValues: {
      workflowId: '',
      workflowNm: '',
      workflowOrgCd: [],
      workId: [],
      statusCd: [],
      mgrIds: [],
      devIds: [],
      devEmpNm: [],
      regFr: '',
      regTo: '',
      regType: '',
    },
    filterId: "",
    pageInfo: {
      order: "regTimestamp desc",
      currentPage: 1,
      rowPerPage: 20,
      tab: "all",
    },
    tableData: [],
    taskCount: {
      totalCount: 0,
      myTaskCount: 0,
      interestTaskCount: 0,
    },
  };
  
  const hfworkflowList = (state = hfWorkflowListInitial, action) => {
    switch (action.type) {
      // inputValue 변경
      case SET_HANDYFLOWWORKFLOWLIST_VALUE: {
        const inputValues = {
          ...state.inputValues,
        };
        return {
          ...state,
          inputValues: {
            ...inputValues,
            [action.key]: action.value,
          },
        };
      }
      case SET_HANDYFLOWWORKFLOWLIST_SPREAD: {
        const inputValues = {
          ...state.inputValues,
          ...action.data,
        };
        return {
          ...state,
          inputValues,
        };
      }
      case RESET_HANDYFLOWWORKFLOWLIST_VALUE: {
        const inputValues = {
          ...hfWorkflowListInitial.inputValues,
        };
        return {
          ...state,
          inputValues,
        };
      }
      case SET_HANDYFLOWWORKFLOWLIST_FILTERID: {
        return {
          ...state,
          filterId: action.filterId,
        };
      }
      default:
        return state;
    }
  };
  
  export default hfworkflowList;
  