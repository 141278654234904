import React from "react";
import { Row, Col, Select, Checkbox } from "antd";
import {
    Text,
    Button,
    UserTag,
    RangePicker,
    Upload,
    Input,
    PermissionComponent,
} from "components";
import "moment/locale/ko";
import { InfoCircleOutlined } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";                                                                                                                                                                                                             
const { Option } = Select;

// disabled 되어있는 화면
const InactiveForm = ({ taskData, selectOption }) => {
    const { formatMessage } = useIntl();
    return (
        <div className="basic_box status__disabled">
            <div className="basic_box__title">
            <Text className="sub_box__title">{formatMessage({ id: "T0505" })}</Text>{/* 2. [개발] 분석/설계 */}
            </div>
            <div className="basic_box__info">
            <Row>
                <Col span={5}>
                <Text className="basic_row__title">
                    {formatMessage({ id: "T1127" })}{/* 개발 담당자(Main) */}
                </Text>
                </Col>
                <Col span={19} className="basic_row__content">
                {taskData.taskMemberList
                    ?.filter(
                    (member) =>
                        member.mgrDevGbn === "D" && member.deputyHeadYn === "N"
                    )
                    .map((member, index) => (
                    <UserTag
                        key={index}
                        profile={{
                        NAME_KOR: member.empNm,
                        EMP_NO: member.empNo,
                        DEPT_NAME_KOR: member.orgNm,
                        FILE_ID: member.empFileId,
                        }}
                    />
                    ))}
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title">
                    {formatMessage({ id: "T1128" })}{/* 개발 담당자(Sub) */}
                </Text>
                </Col>
                <Col span={19} className="basic_row__content">
                {taskData.taskMemberList
                    ?.filter(
                    (member) =>
                        member.mgrDevGbn === "D" && member.deputyHeadYn === "Y"
                    )
                    .map((member, index) => (
                    <UserTag
                        key={index}
                        profile={{
                        NAME_KOR: member.empNm,
                        EMP_NO: member.empNo,
                        DEPT_NAME_KOR: member.orgNm,
                        FILE_ID: member.empFileId,
                        }}
                    />
                    ))}
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                    <Text className="basic_row__title">
                        {formatMessage({ id: "T1678" })}{/* Bot F/W 기능 선택 */}
                    </Text>
                    &nbsp;
                    <InfoCircleOutlined className="basic_row__title"/>
                </Col>
                <Col span={19} style={{display:"flex"}}>
                    <Checkbox disabled/>
                    <span className="basic_row__content">
                        &nbsp;&nbsp;{formatMessage({ id: "T1679" })} {/* Bot 업무량 분산을 위한 병렬 처리 */}
                    </span> 
                </Col>
                </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0507" })}{/* 개발 일정 */}
                </Text>
                </Col>
                <Col span={19}>
                <RangePicker style={{ width: 280 }} disabled />
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0508" })}{/* 개발 CR */}
                </Text>
                </Col>
                <Col span={19}>
                <Select
                    style={{ width: 280 }}
                    size="default"
                    placeholder={`${selectOption}`}
                    disabled
                >
                    <Option value="">{selectOption}</Option>
                </Select>
                </Col>
            </Row>
            <Row>
                <Col span={5}>
                <Text className="basic_row__title" required>
                    {formatMessage({ id: "T0509" })} {/* 업무 기술서 */}
                </Text>
                </Col>
                <Col span={19}>
                <div className="statement__box">
                    <div className="flex">
                    <Input style={{ height: 30 }} disabled />
                    <Button type="default" className="block__button" disabled>
                        <FormattedMessage id="T0050" />{/* 등록 */}
                    </Button>
                    </div>
                </div>
                <Upload disabled />
                </Col>
            </Row>
            <Row>
                <Col span={6} offset={18} className="flex justify-end">
                <PermissionComponent fnCode="FN10">
                    <Button
                    type="default"
                    width="120"
                    className="block__button"
                    disabled
                    >
                    <FormattedMessage id="T0057" />{/* 개발 시작 */}
                    </Button>
                </PermissionComponent>
                </Col>
            </Row>
            </div>
        </div>
        );
    };

export default InactiveForm;