import React, { useEffect, useState, useRef} from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Text, Checkbox } from 'components';
import { setCookie, getCookie } from 'utils/cookie';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import { decodeHTMLEntites } from 'utils/decodeHTMLEntites';

const Popup = ({ noticeSeq, onClickClosePopup }) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false);
    const [noticeData, setNoticeData] = useState({});
    const [checked, setChecked] = useState(false);
    const [contentType, setContentType] = useState('web');

    const onChangeCheckbox = (e) => {
        const { target: { checked } } = e;
        setChecked(checked);
        onClickClose(checked);
    }

    const onClickClose = (data) => {
        let isChecked = checked;
        // checkbox 누르기만해도 기능 작동하도록
        if (typeof data === 'boolean') {
            isChecked = data
        }
        if (isChecked) {
            const cookiePopup = getCookie('popup') || [];
            cookiePopup.push(+noticeSeq);

            const date = new Date();
            date.setDate(date.getDate() + 1);
            date.setHours(0, 0, 0, 0);
            date.setMinutes(date.getMinutes() + 1);

            setCookie('popup', [...cookiePopup], {
                expires: date
            });
        }
        onClickClosePopup(noticeSeq);
    }
    
    useEffect(() => {
        const fetchNotice = async () => {
            setLoading(true);
            const result = await fetchAbsolute('get', `/admin/notice/noticeInfo?noticeSeq=${noticeSeq}`);
            setLoading(false);
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            const { data } = result;

            let convert = '';
            try {
                convert = decodeHTMLEntites(data.noticeContents);
            } catch { }

            setContentType(data.noticeContents?.length > 0 ? 'web' : data.link?.length > 0 ? 'link' : 'web');
            setNoticeData({
                ...result.data,
                noticeContents: convert
            });
        }
        fetchNotice();
    }, []);

    if (loading) {
        return null;
    }
    return (
        <div className='main_popup__box' style={{
            width: noticeData.popWidth,
            height: noticeData.popHeight,
            left: noticeData.leftCoordinate,
            top: noticeData.topCoordinate
        }}>
            <div className='main_popup__header'>
                <Text>{noticeData.noticeTitle}</Text>
                <CloseOutlined className='main_popup__header_close' onClick={onClickClose} />
            </div>
            {
                contentType === 'web' ?
                    <div className='main_popup__body_content' dangerouslySetInnerHTML={{ __html: noticeData.noticeContents }}>
                    </div>
                    :
                    <div className='main_popup__body_link'>
                        <iframe
                            title='popup'
                            src={noticeData.link}
                            width='100%'
                            height='100%'
                        />
                    </div>
            }
            <div className='main_popup__footer'>
                <div className='main_popup__footer_check'>
                    <Checkbox
                        onChange={onChangeCheckbox}
                        checked={checked}
                    >
                        {formatMessage({ id: 'T0412' })}{/* 오늘 다시 보지 않기 */}
                    </Checkbox>
                </div>
            </div>
        </div>
    )
}

export default Popup;