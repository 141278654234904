import { SET_ADMINMEMBER_VALUE, SET_ADMINMEMBER_SPREAD, RESET_ADMINMEMBER_VALUE, SET_ADMINMEMBER_PAGEINFO } from './action';

const adminMemberInitialState = {
    inputValues: {
        empNum: [],
        orgCds: [],
        abLevel: [],
        dwpUserLevel: [],
    },
    pageInfo: {
        sort: 'empNum,desc',
        currentPage: 1,
        rowPerPage: 10,
        total: 0
    }
};

const adminMember = (state = adminMemberInitialState, action) => {
    switch (action.type) {
        case SET_ADMINMEMBER_VALUE: {
            return {
                ...state,
                inputValues: {
                    ...state.inputValues,
                    [action.key]: action.value
                }
            };

        }
        case SET_ADMINMEMBER_SPREAD: {
            return {
                ...state,
                inputValues: {
                    ...state.inputValues,
                    ...action.data
                }
            }
        }
        case RESET_ADMINMEMBER_VALUE: {
            return {
                ...adminMemberInitialState
            }
        }
        case SET_ADMINMEMBER_PAGEINFO: {
            return {
                ...state,
                pageInfo: {
                    ...state.pageInfo,
                    ...action.data
                }
            };
        }
        default:
            return state;
    };
};

export default adminMember;
