import React, { useEffect, useState } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Text,
  Input,
  Button,
  FormTable,
  Modal,
} from "components";
import { Select, Typography } from "antd";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled";
import { useIntl, FormattedMessage } from "react-intl";
import { createBrowserHistory } from "history";

const { Title } = Typography;
const { Option } = Select;

const CrInfoUpdate = ({ match: { params } }) => {
  const { formatMessage } = useIntl();
  const history = createBrowserHistory({ forceRefresh: true });

  const [PoolVisible, setPoolVisible] = useState(false);
  const [useYn, setUseYn] = useState("");
  const [allList, setAllList] = useState([]);
  const [crList, setCrList] = useState([]);
  const [crType, setCrType] = useState("");
  const [inputValues, setInputValues] = useState({
    crName: "",
    crUri: "",
  });

  const onOkDelete = async () => {
    //delete 할 데이터 array 반복문 돌려서 삭제하기
    const deleteCRInfo = await fetchAbsolute(
      "delete",
      `/common/commonCd/deleteList`,
      {
        data: {
          categoryId: "CR_INFO",
          cd: params.crcd,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (deleteCRInfo.error) {
      alert(formatMessage({ id: "T0258" })); // CR 정보 삭제를 실패했습니다.
    } else {
      alert(formatMessage({ id: "T0246" })); // CR 정보가 삭제되었습니다.
      await crInfoUpdate();
      return history.push("/admin/common/crinfo");
    }
    setPoolVisible(false);
  };

  const onCancelDelete = () => setPoolVisible(false);

  const onClickDelete = (e) => {
    setPoolVisible(true);
  };
  const crInfoUpdate = () => {
    return fetchAbsolute("get", "/common/updateControlRoomCd");
  };

  const onClickAdd = async () => {
    const saveList = await fetchAbsolute("put", `/common/commonCd/modify`, {
      data: {
        categoryId: "CR_INFO",
        cd: params.crcd,
        cdNm: inputValues.crName,
        useYn: useYn,
        attrCd1: crList[0].attrCd1,
        attrCd2: crList[0].attrCd2,
        attrCd3: inputValues.crUri,
        attrCd4: crType,
        attrCd5: crList[0].attrCd1 === "DIY" ? "DIY" : null,
        sort: crList[0].sort,
        regId: crList[0].regId,
        regTimestamp: crList[0].regTimestamp,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (saveList.error) {
      return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
    }
    alert(formatMessage({ id: "T0234" })); // 저장되었습니다.
    await crInfoUpdate();
    return history.push("/admin/common/crinfo");
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputValues({
      ...inputValues,
      [name]: value,
    });
  };

  const onChangeUseYn = (e) => {
    setUseYn(e);
  };

  const onChangeCrType = (e) => {
    setCrType(e);
  };

  useEffect(() => {
    const fetchPoolCr = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/CR_INFO?sortVal=asc`
      );
      const crInfo = result.data.filter((e) => e.cd === params.crcd);
      setCrList(crInfo);
      setAllList([...result.data]);
    };
    fetchPoolCr();
  }, [params.crcd]);

  useEffect(() => {
    if (crList.length > 0) {
      setInputValues({
        crName: crList[0].cdNm,
        crUri: crList[0].attrCd3,
      });
      setCrType(crList[0].attrCd4);
      setUseYn(crList[0].useYn);
    }
  }, [crList]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />}>
      <div className="wrap">
        {crList.length > 0 && (
          <Wrapper>
            <div className="realtime_content_box">
              <Title className="title_name" level={4}>
                <FormattedMessage id="T1070" />{/* CR 정보 수정 */}
              </Title>
              <div className="update_content">
                <FormTable pointBorder={false}>
                  <colgroup>
                    <col width="12%" />
                    <col width="15%" />
                    <col width="12%" />
                    <col width="15%" />
                    <col width="12%" />
                    <col width="15%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}>
                          <FormattedMessage id="T1067" />{/* CR ID */}
                        </Text>
                      </th>
                      <td>
                        <div className="flex align-center">
                          <Text>
                            {crList[0].attrCd5
                              ? crList[0].attrCd5 +
                              crList[0].cd.substring(3, 10)
                              : crList[0].attrCd1}
                          </Text>
                        </div>
                      </td>

                      <th>
                        <Text required={true}>
                          {formatMessage({ id: "T0739" })}{/* 개별/운영 구분 */}
                        </Text>
                      </th>
                      <td>
                        <div className="flex align-center">
                          <Text>{crList[0].attrCd2}</Text>
                        </div>
                      </td>
                      <th>
                        <Text required={true}>
                          {formatMessage({ id: "T0740" })}{/* 과제유형 */}
                        </Text>
                      </th>
                      <td>
                        <Text>{crList[0].attrCd5 || crList[0].attrCd1}</Text>
                      </td>
                    </tr>
                  </tbody>
                </FormTable>
                <FormTable pointBorder={false}>
                  <colgroup>
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                    <col width="25%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}>
                          {formatMessage({ id: "T0741" })}{/* CR 이름 */}
                        </Text>
                      </th>
                      <td>
                        <div className="flex align-center">
                          <Input
                            style={{
                              width: 320,
                              marginRight: 20,
                            }}
                            name="crName"
                            value={inputValues.crName}
                            onChange={onChangeInput}
                          />
                        </div>
                      </td>
                      <th>
                        <Text required={true}>
                          {formatMessage({ id: "T0742" })}{/* CR 접속 URL */}
                        </Text>
                      </th>
                      <td>
                        <Input
                          name="crUri"
                          style={{
                            width: 320,
                            marginRight: 20,
                          }}
                          value={inputValues.crUri}
                          onChange={onChangeInput}
                        />
                      </td>
                    </tr>
                  </tbody>
                </FormTable>

                <FormTable pointBorder={false}>
                  <colgroup>
                    <col width="299" />
                    <col width="374" />
                    <col width="299" />
                    <col width="374" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}>
                          <FormattedMessage id="T1068" />{/* CR TYPE */}
                        </Text>
                      </th>
                      <td>
                        {/* <Select
                          id="crType"
                          style={{
                            width: 320,
                            marginRight: 20,
                          }}
                          placeholder={formatMessage({ id: 'T0688' })} // 선택해주세요
                          value={crType}
                          onChange={onChangeCrType}
                          disabled
                        >
                          {Array.from(
                            new Set([...allList.map((v) => v.attrCd4)])
                          ).map((e) => (
                            <Option value={e}>{e}</Option>
                          ))}
                        </Select> */}
                        <Text>{crType}</Text>
                      </td>
                      <th>
                        <Text required={true}>
                          {formatMessage({ id: "T0737" })}{/* 사용여부 */}
                        </Text>
                      </th>
                      <td>
                        <Select
                          id="useYn"
                          style={{
                            width: 320,
                            marginRight: 20,
                          }}
                          placeholder={formatMessage({ id: "T0688" })} // 선택해주세요
                          value={useYn}
                          onChange={onChangeUseYn}
                        >
                          <Option value="Y">Y</Option>
                          <Option value="N">N</Option>
                        </Select>
                      </td>
                    </tr>
                  </tbody>
                </FormTable>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingTop: 20,
                }}
              >
                <Button
                  width="144"
                  height="40"
                  type="primary"
                  onClick={onClickAdd}
                >
                  {formatMessage({ id: "T0054" })} {/* 수정 */}
                </Button>
                &nbsp;&nbsp;
                <Button width="144" height="40" onClick={onClickDelete}>
                  {formatMessage({ id: "T0072" })}{/* 삭제 */}
                </Button>
                &nbsp;&nbsp;
                <Link to="/admin/common/crinfo">
                  <Button width="144" height="40">
                    {formatMessage({ id: "T0051" })}{/* 취소 */}
                  </Button>
                </Link>
              </div>
            </div>

            <Modal
              width={540}
              visible={PoolVisible}
              title={formatMessage({ id: "T0744" })} // 정말로 선택된 CR 정보를 삭제하시겠습니까?
              okText={formatMessage({ id: "T0072" })} // 삭제
              onCancel={onCancelDelete}
              onOk={onOkDelete}
            >
              <Text
                style={{
                  display: "block",
                  fontSize: 14,
                }}
              >
                {formatMessage({ id: "T0744" })}{/* 정말로 선택된 CR 정보를 삭제하시겠습니까? */}
              </Text>
            </Modal>
          </Wrapper>
        )}
      </div>
    </PageTemplate>
  );
};

export default CrInfoUpdate;
