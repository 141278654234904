import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useIntl, FormattedMessage } from "react-intl";
import moment from "moment";
import { Radio, Popover } from 'antd';
import { CloseOutlined } from "@ant-design/icons";
import { LanguageIcon } from 'icons';
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import changeLocale from 'containers/LanguageProvider/actions';
import { 
  setHeaderNotiList, 
  setHeaderNotiListWithPeriod,
  setSideNotiList,
  setSideNotiListWithPeriod
} from "store/notiList/action";
import Wrapper from "./Styled";
import { Button, Text, TodoBox } from "components";
import './radio.css';

/**
 * 개인화 영역
 *
 */

const PersonalArea = ({ profile, history }) => {

  const dispatch = useDispatch();

  const language = useSelector(state => state.get('language'));
  const notiAllList = useSelector(state => state.get(getUniqueKey('notiList')));

  const { formatMessage } = useIntl();

  const [personalNotiList, setPersonalNotiList] = useState([]);
  const [userAuth, setUserAuth] = useState("");
  const [taskCount, setTaskCount] = useState(0);
  const [levelInfo, setLevelInfo] = useState({});

  const [languageList, setLanguageList] = useState([]);
  const [locale, setLocale] = useState(language.get('locale'));
  const [viewAll, setViewAll] = useState(false);

  const onClickGotoEnter = () => {
    history.push("/assign/enter");
  };

  const onClickDeleteNoti = async (notiSeq) => {
    const result = await fetchAbsolute("post", `/common/removeNotiUser`, {
      data: {
        empNo: profile.EMP_NO,
        notiSeq: notiSeq,
      },
    });
    // dispatch(setNotiList());
    if (result.error || result.data.resultCode === "F") return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    if (result.data.resultCode === "S") {
      const tmpArr = [...personalNotiList.filter((v) => v.notiSeq !== notiSeq)];
      setPersonalNotiList([
        ...tmpArr.map((v, index) => ({
          ...v,
          key: index,
          ntContentStr: v.ntContentStr.replace(
            /<a\s/gi,
            `<a style="color: #053c66 !important; text-decoration: underline;"`
          ),
        })),
      ]);
    }
    if(viewAll) {
      dispatch(setHeaderNotiList());
      dispatch(setSideNotiList());
    } else {
      dispatch(setHeaderNotiListWithPeriod());
      dispatch(setSideNotiListWithPeriod());
    }
  };

  const onClickDeleteNotiAll = async () => {
    const result = await fetchAbsolute("post", `/common/removeAllNotiUser`, {
      data: {
        empNo: profile.EMP_NO,
      },
    });;
    if (result.error || result.data.resultCode === "F") return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    if (result.data.resultCode === "S") {
      setPersonalNotiList([]);
    }
    dispatch(setHeaderNotiListWithPeriod());
    dispatch(setSideNotiListWithPeriod());
  };

  const onChangeLanguage = (e) => {
    setLocale(e.target.value);
  }

  const onClickLanguageItem = (e) => {
    const { lang } = e.currentTarget.dataset;
    setLocale(lang);
  }

  const languageContent = (
    <div
      className="language_popover"
    >
      <Radio.Group
        name='language'
        onChange={onChangeLanguage}
        value={locale}
      >
        {
          languageList.map(v => (
            <div className="language_item" key={v.cd} data-lang={v.cd} onClick={onClickLanguageItem}>
              <Radio
                value={v.cd}
              />
              <Text>{v.cdNm}</Text>
            </div>
          ))
        }
      </Radio.Group>
    </div>
  );

  useEffect(() => {
    if (language.get('locale') === locale) return;

    const setLocale = async () => {
      await fetchAbsolute('put', `/common/updateAuthCdUseUserAdInfo?empNum=${profile.EMP_NO}`, { locale });
      dispatch(changeLocale(locale));
    }
    setLocale();
  }, [locale]);

  useEffect(() => {
    const fetchNotiList = async () => {
      setPersonalNotiList([...notiAllList.personalAreaNoti.dwpList.map((v) => ({
        ...v,
        ntContentStr: v.ntContentStr.replace(
          /<a\s/gi,
          `<a style="color: #053c66 !important; text-decoration: underline;"`
        ),
      })),
      ]);
    };
    fetchNotiList();
  }, [notiAllList])

  useEffect(()=>{
    if(viewAll) {
      dispatch(setSideNotiList());
    } else {
      dispatch(setSideNotiListWithPeriod());
    }
  },[viewAll]);

  useEffect(() => {
    if (!profile.EMP_NO) return;
    const fetchTaskList = async () => {
      const result = await fetchAbsolute("get", `/task/oracle/myTaskList`);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const count =
        result.data.receiptCount +
        result.data.developmentCount +
        result.data.testCount +
        result.data.operationCount;
      setTaskCount(count);
    };
    const fetchUserAuth = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${profile.EMP_NO}`
      );
      if (result.error || result.data.mdFPAList.length === 0) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setUserAuth(result.data.mdFPAList[0].dwpUserLevel);
    };
    const fetchLevel = async () => {
      const result = await fetchAbsolute(
        "get",
        `/main/personalized/getPersonalized?empNo=${profile.EMP_NO}`
      );
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      const { data } = result;
      setLevelInfo({ ...data });
    };
    const fetchLanguageList = async () => {
      const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
      const data = result.data.filter(v => v.useYn === 'Y');
      setLanguageList([...data]);
    }
    fetchTaskList();
    fetchUserAuth();
    fetchLevel();
    fetchLanguageList();
  }, [profile.EMP_NO]);

  return (
    <Wrapper>
      <div className="personal_container">
        <div className="personal_body_container"        >
          <div className="personal_body_contents">
            <div className="container">
              <div>
                <Text className="personal__title">
                  <FormattedMessage
                    id="T0415" // {name}님
                    values={{
                      name: profile.NAME_KOR,
                    }}
                  />
                </Text>
                {/* 이거 기능은 구현돼있는데 여기 안뜨는거임 포인트 부분 */}
                {/* <--- 아카데미가 빠지면서 수정되는 level name, point 삭제 부분 ---> */}
                {/* <--- 아카데미가 빠지면서 수정되는 level name, point 삭제 부분 ---> */}
                <Text className="personal__title_info">
                  ({levelInfo.dwpUserLevelNm})

                  {/* 아카데미 등급, 포인트 */}
                  {levelInfo.lastLevNm && " / " + levelInfo.lastLevNm}
                  {levelInfo.point > 0 && " / " + (+(levelInfo.point) || 0)?.toLocaleString() + 'pt'})
                </Text>
                {/* <--- 아카데미가 빠지면서 수정되는 level name, point 삭제 부분 ---> */}
                {/* <--- 아카데미가 빠지면서 수정되는 level name, point 삭제 부분 ---> */}
              </div>
              {
                languageList.length > 1 &&
                <Popover
                  placement="bottomRight"
                  content={languageContent}
                  title="Language"
                  trigger={["hover"]}
                >
                  <LanguageIcon
                  />
                </Popover>
              }
            </div>
            {/* <--- 아카데미가 빠지면서 삭제 부분 ---> */}
            {/* <--- 아카데미가 빠지면서 삭제 부분 ---> */}

            {/* <PersonalBox profile={profile} levelInfo={levelInfo} /> */}

            {/* <--- 아카데미가 빠지면서 삭제 부분 ---> */}
            {/* <--- 아카데미가 빠지면서 삭제 부분 ---> */}
          </div>
          <div className="personal_body_contents__second">
            <div className="personal_todo__title">
              <div className="personal_todo__title_left">
                <Text className="personal__title">To-do</Text>
                <Text className="personal__total">
                  <FormattedMessage
                    id="T0416" // 총 {number}건
                    values={{
                      number: notiAllList.personalAreaNoti.totalCount + (taskCount === 0 && userAuth !== "UA01" ? 1 : 0),
                    }}
                  />
                </Text>
              </div>
              {notiAllList.personalAreaNoti.currentCount > 0 && (
                <div className="personal_todo_title__right">
                  <Button
                    className="personal_todo__all_delete"
                    onClick={onClickDeleteNotiAll}
                    type='default'
                  >
                    {formatMessage({ id: 'T0095' })}{/* 전체 삭제 */}
                  </Button>
                </div>
              )}
            </div>
            {taskCount === 0 && userAuth !== "UA01" && (
              <TodoBox
                title={formatMessage({ id: 'T0417' })} // 새로운 과제를 등록해서 RPA 사용하기
                text={formatMessage({ id: 'T0418' })} // 직접 과제를 등록해 업무에 적용해보세요
                btn_content={formatMessage({ id: 'T0419' })} // 과제 등록하기
                type="primary"
                isRequested={true}
                dateStyle={{ color: "#060606", opacity: 0.6 }}
                isPurple={true}
                onClick={onClickGotoEnter}
              />
            )}
            {!(taskCount === 0 && userAuth !== "UA01") &&
              notiAllList.personalAreaNoti.totalCount === 0 && (
                <div
                  className="todo__container"
                  style={{ textAlign: "center", fontSize: 13 }}
                >
                  <Text>{formatMessage({ id: 'T0420' })}</Text>{/* 할 일이 없습니다. */}
                </div>
              )}
            {personalNotiList.map((noti, index) => (
              <div className="todo__container" key={index}>
                <div className="todo_title__container">
                  <div
                    className="todo__content"
                    dangerouslySetInnerHTML={{ __html: noti.ntContentStr }}
                    onClick={() => onClickDeleteNoti(noti.notiSeq)}
                  ></div>
                  <div>
                    <CloseOutlined
                      onClick={() => onClickDeleteNoti(noti.notiSeq)}
                    />
                  </div>
                </div>
                <div className="todo_date__container">
                  <Text className="todo_date__text">
                    {moment(noti.ntDate)
                      .format("YYYY-MM-DD a hh:mm")
                      .replace("am", `${formatMessage({ id: 'T0421' })}`) // 오전
                      .replace("pm", `${formatMessage({ id: 'T0422' })}`) // 오후
                    }
                  </Text>
                </div>
              </div>
            ))}
            {
              (!viewAll 
              && notiAllList.personalAreaNoti.totalCount>0 
              && notiAllList.personalAreaNoti.totalCount !== notiAllList.personalAreaNoti.currentCount
              )
              &&
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center',
                alignItems:'center', 
                height: '34px'
              }}>
                <Button
                  onClick={()=>setViewAll(true)}
                >
                  {formatMessage({ id: 'T0096' })}{/* 더보기 */}
                </Button>
              </div>
            }
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(PersonalArea);