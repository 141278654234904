import React, { useEffect, useState } from 'react';
import { FormTable, Input, Button, Text, Upload, UserThumbnail, Image } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

/**
 * 내 정보 변경(사진, 비밀번호) 화면
 * Header > Avatar > 내 설정
 */

const EditMyInfo = ({ profile, history, initialInputValues, inputValues, setInputValues, onChangePassword, onChangeRePassword, pwCheck, onClickSubmit, fileList, onRemoveFile, onBeforeupload, requireFields, onChangePhone, onChangeImg, previewSrc }) => {
    const END_POINT = process.env.REACT_APP_API_URI;
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const [idPhoto, setIdPhoto] = useState({});

    const onClickCancel = () => {
        history.push('/');
    };

    /* 프로필 사진 가지고 오기 */
    useEffect(() => {
        if (!profile.FILE_ID) return;
        const getUserFile = async () => {
            const result = await fetchAbsolute('get', `/file/listFile/${profile.FILE_ID}`)
            if (result.error) {
                return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            }
            if (fileList.length > 0) {
                setIdPhoto('');
            } else {
                setIdPhoto(result.data[0]);
            }
        }
        getUserFile();
    }, [profile.FILE_ID, fileList]);

    /* 휴대폰 정보 셋팅 */
    useEffect(() => {
        if (!profile.PHONE_NUMBER) return;
        const onlyNumber = profile.PHONE_NUMBER.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
        setInputValues({
            ...initialInputValues,
            cphoneNum: onlyNumber,
        })
    }, [profile.PHONE_NUMBER]);

    return (
        <div className='common_container'>
            <div className='first_section'>
                <FormTable>
                    <colgroup>
                        <col width='13.5%' />
                        <col width='36.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <div className='photo_section'>
                                    <div className='id_photo_part'>
                                        {
                                            Object.keys(idPhoto).length > 0 && fileList.length === 0 ?
                                                <Image
                                                    src={idPhoto.link}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover',
                                                        borderRadius: 150
                                                    }}
                                                />
                                                : previewSrc ? <Image src={previewSrc} alt="preview-img" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: 150 }} />
                                                    :
                                                    <UserThumbnail width={250} />
                                        }
                                    </div>
                                    <div className='upload_part'>
                                        <Upload
                                            action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={onBeforeupload}
                                            onRemove={onRemoveFile}
                                            fileList={fileList}
                                            onChange={onChangeImg}
                                            accept={'.png, .jpg, .jpeg, .gif'}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </FormTable></div>
            <div className='second_section'>
                <FormTable>
                    <colgroup>
                        <col width='16.5%' />
                        <col width='33.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Text>
                                    <FormattedMessage id="T0602" />{/* 이메일 */}
                                </Text>
                            </th>
                            <td>
                                <Text>{profile.EMAIL}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T0012" />{/* 이름 */}</Text>
                            </th>
                            <td>
                                <Text>{profile.NAME_KOR}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T1255" />{/* 전화번호 */}</Text>
                            </th>
                            <td>
                                <Input
                                    value={inputValues.cphoneNum}
                                    placeholder={formatMessage({ id: 'T1256' })}// 휴대 전화번호를 -없이 입력
                                    onChange={onChangePhone}
                                    maxLength={13}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T0455" />{/* 부서 */}</Text>
                            </th>
                            <td>
                                <Text>{profile.DEPT_NAME_KOR}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T1257" />{/* 기존 비밀번호 */}</Text>
                            </th>
                            <td>
                                <Input
                                    value={inputValues.prePassword}
                                    placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1257' }) } })} // 기존 비밀번호 입력
                                    onChange={onChangePassword}
                                    name='prePassword'
                                    type='password'
                                    innerRef={requireFields.prePassword.ref}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T1258" />{/* 새 비밀번호 */}</Text>
                            </th>
                            <td>
                                <div className='new_password_section'>
                                    <Input
                                        value={inputValues.password}
                                        placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1258' }) } })} // 새 비밀번호 입력
                                        onChange={onChangePassword}
                                        name='password'
                                        type='password'
                                        innerRef={requireFields.password.ref}
                                    />
                                    <Text>
                                        &#42; <FormattedMessage id="T1262" /> {/* 비밀번호는 8~16자 영문 대 소문자, 숫자, 특수문자를 사용하여 구성 */}
                                    </Text>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text><FormattedMessage id="T1260" />{/* 새 비밀번호 확인 */}</Text>
                            </th>
                            <td>
                                <div className='password_check_section'>
                                    <Input
                                        value={inputValues.rePassword}
                                        placeholder={formatMessage({ id: 'T1260' })}// 새 비밀번호를 확인
                                        onChange={onChangeRePassword}
                                        name='rePassword'
                                        type='password'
                                        innerRef={requireFields.rePassword.ref}
                                    />
                                    <Text className='check_message'>{pwCheck}</Text>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </FormTable>
                <div className='admin_manage_btn_wrap'>
                    <Button
                        type='primary'
                        width='150'
                        height='40'
                        onClick={onClickSubmit}
                    >
                        {formatMessage({ id: 'T0073' })}{/* 저장 */}
                    </Button>
                    <Button
                        type='default'
                        width='150'
                        height='40'
                        onClick={onClickCancel}
                    >
                        <FormattedMessage id="T0051" />{/* 취소 */}
                    </Button>
                </div>
            </div>
        </div>

    )
}

export default EditMyInfo;