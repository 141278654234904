import { getUniqueKey } from 'utils/Str';

export const SET_LIST_URL = getUniqueKey('SET_LIST_URL');

export function setListUrl(key, search) {
    return {
        type: SET_LIST_URL,
        key,
        search
    };
};