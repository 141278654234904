import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setVisible } from "store/loading/action";
import { taskInstantStart, changeHistoryData, changeScheduleStatus } from "store/assignLookup/action";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Wrapper from "./Styled";
import 'antd/dist/antd.min.css'
import { Select, Input as AntInput, Tooltip } from "antd";
import { Text, UserTag, Button, Modal, InputSearch, Organization, RequestShare, PermissionComponent, ScheduleUserInputModal, DevicePool } from "components";
import { ExclamationCircleFilled } from "@ant-design/icons";
import sendNoti from "utils/sendNoti";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * 과제 목록 > 과제 조회 (상세) > 오른편
 * Description: 과제 진행 상태와 담당자 및 과제 상태 변경할 수 있는 공간
 */

const { Option } = Select;
const { TextArea } = AntInput;

const initUserInputValues = [{ key: "", value: "" }];

const TaskStatus = ({history}) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const selectOption = formatMessage({ id: "T0024" }); // 선택
  const confirmationText = formatMessage({ id: 'T0064' }); // 확인
  const duplicationKeyExist = formatMessage({ id: 'T0149' }); // 중복된 키가 있습니다.
  const assignShareFail = formatMessage({ id: 'T0194' }); // 과제 공유 신청이 실패하였습니다.
  const assignShareSuccess = formatMessage({ id: 'T0195' }); // 과제 공유 신청이 완료되었습니다.
  const selectManager = formatMessage({ id: 'T0196' }); // 담당자를 지정해주세요.
  const mainMangerSame = formatMessage({ id: 'T0197' }); // 현재 업무 담당자(Main)와 이관 대상자가 같습니다.
  const assignStopReason = formatMessage({ id: 'T0200' }); // 과제 중단 사유를 선택해주세요
  const assignStopReasonInput = formatMessage({ id: 'T0201' }); // 중단 사유를 적어주세요.
  const assignStopFail = formatMessage({ id: 'T0202' }); // 과제 중단을 실패하였습니다.
  const assignStopSuccess = formatMessage({ id: 'T0203' }); // 과제 중단이 완료되었습니다.
  const assignStopCancelSuccess = formatMessage({ id: 'T0204' }); // 과제 중단 해제를 완료했습니다.
  const assignStopCancelFail = formatMessage({ id: 'T0205' }); // 과제 중단 해제를 실패하였습니다.

  const profile = useSelector(state => state.get('auth').get('profile'));
  const assignLookup = useSelector(state => state.get(getUniqueKey('assignLookup')));
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const taskCr = crInfo.find((v) => v.crType === assignLookup.taskData?.taskType && v.crEnvGubun === "PRD") || {};

  const [devProfile, setDevProfile] = useState({});
  const [regProfile, setRegProfile] = useState({});
  const [testerProfile, setTesterProfile] = useState({});

  const [discontinueReason, setDiscontinueReason] = useState("");
  const [reasonValue, setReasonValue] = useState({
    cd: "",
    operator: ""
  });
  const [discontinueVisible, setDiscontinueVisible] = useState(false);
  const [deletetaskVisible, setDeleteTaskVisible] = useState(false);
  const [visibleOrgan, setVisibleOrgan] = useState(false);
  const [asapConfirmVisible, setAsapConfirmVisible] = useState(false);
  const [devicePool, setDevicePool] = useState({});
  const [operResult, setOperResult] = useState({});
  const [transferUser, setTransferUser] = useState({});
  const [userInputValues, setUserInputValues] = useState([...initUserInputValues]);
  const [devicePoolVisible, setDevicePoolVisible] = useState(false);
  const [shareVisible, setShareVisible] = useState(false);
  const [userInputVisible, setUserInputVisible] = useState(false);
  const [transferVisible, setTransferVisible] = useState(false);
  const [relaeseConfirmVisible, setRelaeseConfirmVisible] = useState(false);
  const [taskHistory, setTaskHistory] = useState([]);

  // Modal Cancel 함수들
  const onCancelDevicePoolModal = () => {
    setDevicePoolVisible(false);
    setDevicePool({});
  }

  const onCancelShare = () => setShareVisible(false);
  const onCancelAsapRun = () => setAsapConfirmVisible(false);
  const onCancelUserInput = () => {
    setUserInputValues([{ key: "", value: "" }]);
    setDevicePool({});
    setUserInputVisible(false);
  };
  const onCancelDiscontinue = () => setDiscontinueVisible(false);
  const onCancelTransfer = () => setTransferVisible(false);
  const onCancelReleaseConfirm = () => setRelaeseConfirmVisible(false);
  const onCancelOrgan = () => {
    setVisibleOrgan(false);
    setTransferVisible(true);
  };

  // Modal Ok 함수들
  const onOkShare = async () => {
    dispatch(setVisible(true));
    const result = await fetchAbsolute("post", `/task/oracle/reqSharedTask?taskId=${assignLookup.taskData?.taskId}`);
    dispatch(setVisible(false));
    setShareVisible(false);
    if (result.error) {
      alert(assignShareFail);
    } else {
      alert(assignShareSuccess);
    }
  };

  const onOkDevicePoolModal = (data) => {
    // 선택한 값이 없을 때
    if (Object.keys(data).length === 0) return alert(formatMessage({ id: "T0146" })); // Devie pool을 선택하세요
    setDevicePool({ ...data });
    setDevicePoolVisible(false);

    // deivepool 모달 -> user Input 모달로 이동할 때, 값 리셋
    setUserInputValues([{ key: "", value: "" }]);
    setUserInputVisible(true);
  }

  /* 업무 이관 버튼 기능 */
  const onOkTransfer = async () => {
    if (!transferUser.empNum) return alert(selectManager);
    if (assignLookup.taskData?.taskMemberList.findIndex((v) => v.empNo === transferUser.empNum && v.deputyHeadYn === "N" && v.mgrDevGbn === "M") !== -1) {
      return alert(mainMangerSame);
    }
    dispatch(setVisible(true));
    const result = await fetchAbsolute("post", `/task/oracle/reqTransferTask?taskId=${assignLookup.taskData?.taskId}&transferEmpNo=${transferUser.empNum}`);
    dispatch(setVisible(false));
    if (result.error) {
      return alert(formatMessage({ id: "T0198" })); // 업무 이관 요청 작업을 실패하였습니다.
    }
    // alert(formatMessage({ id: "T0199" })); // 글자 추가됨
    alert(formatMessage({ id: "T1629" })) // 업무 이관 요청을 완료하였습니다.\n(업무 이관 작업에 1분 정도 시간이 소요됩니다.)
    setTransferVisible(false);
  };

  const onOkUserInput = () => {
    // userInputValues에 key 값이 겹치면 마지막에 입력된 것만 저장되므로 알럿 띄워줘야해 
    let flag = false;
    userInputValues.reduce((acc, cur) => {
      if (acc.findIndex(({ key }) => key === cur.key) === -1) {
        acc.push(cur);
      } else {
        flag = true;
      }
      return acc;
    }, []);
    if (flag) {
      return alert(duplicationKeyExist);
    }
    setUserInputVisible(false);

    const poolName = devicePool.dpNm;
    setDevicePool({});

    dispatch(taskInstantStart({
      cr: taskCr.crCd,
      poolName: poolName,
      inputValues: userInputValues,
      chooseDevice: formatMessage({ id: "T0146" }), // Device Pool을 선택하세요
      successMsg: formatMessage({ id: "T0147" }), // 즉시 실행이 시작되었습니다.
      failedMsg: formatMessage({ id: "T0148" }), // 즉시 실행 오류가 발생하였습니다.
      crType: taskCr.crXEnv,
      directionMsg: formatMessage({ id: "T1301" }), // 대기 시간 이후에 자동으로 실행할까요?
    }, () => {
      history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/status`);
    }));
  }

  /* 즉시 실행 모달 기능 */
  const onOkAsapRun = () => {
    setAsapConfirmVisible(false);
    setDevicePoolVisible(true);
  };

  const onOkOrgan = (data) => {
    if (data.length === 0) setTransferUser({});
    else setTransferUser({ ...data[0] });
    setVisibleOrgan(false);
    setTransferVisible(true);
  };
  const onOkReleaseButton = async () => {
    //history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/env`);
    window.location.href = `/assign/lookup/${assignLookup.taskData?.taskId}/env`;
  };

  // 과제 삭제
  const onOkDelete = async () => {
    const deletetask = await fetchAbsolute('delete', `/task/oracle/removeTask/${assignLookup.taskData?.taskId}`);
    if(deletetask.error) {
      return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    } else {
      alert(formatMessage({ id: 'T0281' }));//삭제되었습니다.
      return history.push(`/assign/list`);
    }
  };

  // 과제 중단 Modal onOk 함수
  const onOkDiscontinue = async () => {
    if (reasonValue.cd === "") {
      return alert(assignStopReason);
    }
    if (reasonValue.operator === "ETC" && discontinueReason.trim().length === 0) {
      alert(assignStopReasonInput);
      setDiscontinueReason("");
      return;
    }

    dispatch(setVisible(true));
    const result = await fetchAbsolute("post", "/task/oracle/chgTask", {
      data: {
        taskId: assignLookup.taskData?.taskId,
        chgCd: "T50",
        chgCdDetail: "",
        reasonCd: reasonValue.cd,
        chgEmpNo: profile.EMP_NO,
        chgDetailDesc:
          reasonValue.operator === "ETC" ? discontinueReason : stopReason.find((v) => (v.cd === reasonValue.cd ? v.cdNm : "")).cdNm,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) {
      alert(assignStopFail);
      return dispatch(setVisible(false));
    }

    sendNoti("TP19", assignLookup.taskData?.taskId);
    // 히스토리 목록에 추가하는거
    dispatch(changeHistoryData(result.data));
    setDiscontinueVisible(false);
    setDiscontinueReason("");
    alert(assignStopSuccess);
    dispatch(setVisible(false));
    history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/basic`);
  };

  // 과제 중단 해제 Modal onOk 함수
  const onOkReleaseConfirm = async () => {
    dispatch(setVisible(true));
    const recentStatus = await fetchAbsolute("get", `/task/oracle/taskHistoryBeforeRecent/${assignLookup.taskData?.taskId}`);
    const result = await fetchAbsolute("post", "/task/oracle/chgTask", {
      data: {
        taskId: assignLookup.taskData?.taskId,
        chgCd: recentStatus.data.chgCd,
        chgCdDetail: recentStatus.data.chgCdDetail,
        chgEmpNo: profile.EMP_NO,
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) {
      dispatch(setVisible(false));
      return alert(`${assignStopCancelFail}`);
    }
    alert(assignStopCancelSuccess);
    setRelaeseConfirmVisible(false);
    dispatch(setVisible(false));
    dispatch(changeHistoryData(result.data));
    history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/basic`);
  };

  const [stopReason, setStopReason] = useState([]);

  // Modal 띄우는 함수들
  // 과제 중단 클릭 - 과제중단 모달 오픈
  const onClickDiscontinueButton = () => {
    const fetchStopReason = async () => {
      const result = await fetchAbsolute("get", "/common/commonCd/getCategoryId/STOP_REASON");
      if (!result.error) {
        setStopReason([...result.data]);
      }
    }
    fetchStopReason();
    setDiscontinueVisible(true);
  }
    const onClickDeleteTaskButton = () => setDeleteTaskVisible(true);
  const onClickTransferButton = () => setTransferVisible(true);
  const onClickAsapModal = () => setAsapConfirmVisible(true);
  const onClickUnsuspended = () => setRelaeseConfirmVisible(true);
  const onClickShareButton = () => setShareVisible(true);
  const onClickOrgaOpen = () => {
    setTransferVisible(false);
    setVisibleOrgan(true);
  };

  const onChangeDiscontinue = (e) => setDiscontinueReason(e.target.value);

  /* 스케줄 관리 눌렀을 때 이동 */
  const onClickScheduleModal = () => {
    // window.location.href = `/assign/lookup/${assignLookup.taskData?.taskId}/status`;
    history.push(`/assign/lookup/${assignLookup.taskData?.taskId}/status`);
  };

  /* T13인 경우 재요청 */
  const onClickRequest = () => {
    const path = `/assign/recall/${assignLookup.taskData?.taskId}`;
    history.push(path);
  };

  // taskMemberList 프로필 세팅
  useEffect(() => {
    const fetchUser = (empNum) => {
      return fetchAbsolute("get", `/common/memberNoList?empNo=${empNum}`);
    };
    // 담당자 프로필 세팅
    const setRegInfo = async () => {
      const regResult = await fetchUser(assignLookup.taskData?.regId);
      const reg = regResult.data.mdFPAList[0];
      if (!reg) {
        return;
      }
      setRegProfile({
        NAME_KOR: reg?.empNam,
        EMP_NO: reg?.empNum,
        DEPT_NAME_KOR: reg?.orgNam,
        FILE_ID: reg?.fileId,
      });
    };
    // 개발 담당자 프로필 세팅
    const setDevInfo = async () => {
      const devList = assignLookup.taskData?.taskMemberList.filter((member) => member.mgrDevGbn === "D" && member.deputyHeadYn === 'N');
      if (devList.length === 0) return;
      const devResult = await fetchUser(devList[0].empNo);
      if (devResult.error || devResult.data.mdFPAList.length === 0) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }
      const dev = devResult.data.mdFPAList[0];
      setDevProfile({
        NAME_KOR: dev?.empNam,
        EMP_NO: dev?.empNum,
        DEPT_NAME_KOR: dev?.orgNam,
        FILE_ID: dev?.fileId,
      });
    };
    /* 메인 업무 담당자 프로필 세팅 */
    const setTesterInfo = async () => {
      const testerList = assignLookup.taskData?.taskMemberList.filter((member) => member.mgrDevGbn === "M" && member.deputyHeadYn === "N");
      if (testerList.length === 0) return;
      const testerResult = await fetchUser(testerList[0].empNo);
      if (testerResult.error || testerResult.data.mdFPAList.length === 0) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }
      const tester = testerResult.data.mdFPAList[0];
      setTesterProfile({
        NAME_KOR: tester?.empNam,
        EMP_NO: tester?.empNum,
        DEPT_NAME_KOR: tester?.orgNam,
        FILE_ID: tester?.fileId,
      });
    };
    if (assignLookup.taskData?.taskMemberList?.length > 0 && !!assignLookup.taskData?.regId) {
      setRegInfo();
      setDevInfo();
      setTesterInfo();
    }
  }, [assignLookup.taskData?.taskMemberList, assignLookup.taskData?.regId]);

  // 중단사유 select change
  const onChangeStopChange = (e, obj) => {
    setReasonValue({
      cd: e.length < 1 ? e : obj.key,
      operator: obj.label
    })
  }

  // 스케줄 개수 가져오기
  useEffect(() => {
    if (
      !assignLookup.taskData?.taskId ||
      !taskCr.crCd ||
      assignLookup.taskData?.statusCd !== "T40"
    )
      return;

    const getData = async () => {
      const workItemResult = await fetchAbsolute(
        "post",
        `/hwlm/WorkItem?x-environment=${taskCr.crXEnv}`,
        {
          data: {
            page: {
              offset: 0,
              length: 1000,
            },
            sort: [
              {
                field: "creationDate",
                direction: "desc",
              },
            ],
            filter: {
              operands: [
                {
                  operator: "eq",
                  field: "processId",
                  value: assignLookup.taskData?.taskId,
                },
              ],
            },
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const scheduleResult = await fetchAbsolute(
        "get",
        `/task/oracle/taskSchedules?x-environment=PRD&taskId=${assignLookup.taskData?.taskId}`
      );
      if (workItemResult.error || scheduleResult.error) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }
      const filterData = workItemResult.data.list.filter(
        (v) =>
          moment(v.startDateTime).format("YYYYMMDD") ===
          moment().format("YYYYMMDD")
      );
      const bool =
        scheduleResult.data.list?.length > 0 ||
        workItemResult.data.list?.findIndex(
          (v) =>
            moment(v.startDateTime).format("YYYYMMDD") ===
            moment().format("YYYYMMDD")
        ) !== -1;
      dispatch(changeScheduleStatus(bool));
      setOperResult({
        success: filterData.filter((v) => v.status === "Complete").length,
        progress: filterData.filter((v) => v.status === "WIP").length,
        failed: filterData.filter((v) => v.status === "Failed").length,
      });
    };
    getData();
  }, [assignLookup.taskData?.taskId, taskCr]);

  useEffect(() => {
    if (!assignLookup.taskData?.taskId) return;
    const getHistory = async () => {
      dispatch(setVisible(true));
      const result = await fetchAbsolute("get", `/task/oracle/taskHistoryList/${assignLookup.taskData?.taskId}`);
      dispatch(setVisible(false));
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setTaskHistory(result.data);
    };
    getHistory();
  }, [assignLookup.taskData]);

  return (
    <Wrapper>
      <Organization
        visible={visibleOrgan}
        title={formatMessage({ id: "T0465" })} // 담당자 검색
        onOk={onOkOrgan}
        onCancel={onCancelOrgan}
        defaultData={[transferUser]}
        max={1}
      />
      <Modal
        width={500}
        visible={transferVisible}
        title={formatMessage({ id: "T0086" })} // 업무 이관
        okText={formatMessage({ id: "T1149" })} // 요청
        onCancel={onCancelTransfer}
        onOk={onOkTransfer}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {assignLookup.taskData?.taskNm}
          {formatMessage({ id: "T0466" })}{/* 업무를 다른 구성원에게 이관합니다. */}
        </Text>
        <br />
        <Text
          style={{
            display: "block",
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {formatMessage({ id: "T0467" })
            .split("\n")
            .map((data, index) => {
              return (
                <span key={index}>
                  {data}
                  <br />
                </span>
              );
            })}
          {/* ※ 선택한 업무 인수자에게 메일로 승인 요청이 진행되며,
          <br />
          승인 시 자세한 절차가 안내됩니다. */}
        </Text>
        <br />
        <div style={{ display: "flex", alignItems: "baseline" }}>
          <Text style={{ fontSize: "14px" }} required={true}>
            {formatMessage({ id: "T0468" })}{/* 담당자 지정 */}
          </Text>
          <span style={{ paddingRight: "20px" }} />
          <InputSearch
            placeholder={formatMessage({ id: "T0465" })} // 담당자 검색
            onClick={onClickOrgaOpen}
            value={transferUser.empNam}
          />
        </div>
      </Modal>
      <RequestShare
        visible={shareVisible}
        onCancel={onCancelShare}
        onOk={onOkShare}
      />
      <Modal
        centered
        visible={relaeseConfirmVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0469" })} // 중단 해제 확인
        onOk={onOkReleaseConfirm}
        onCancel={onCancelReleaseConfirm}
      >
        <Text> {formatMessage({ id: "T0470" })}</Text>{/* 과제 중단을 해제하시겠습니까? */}
      </Modal>
      <Modal
        width={540}
        visible={discontinueVisible}
        title={formatMessage({ id: "T0471" })} // 과제 중단 사유 입력
        okText={formatMessage({ id: "T0066" })} // 입력
        onCancel={onCancelDiscontinue}
        onOk={onOkDiscontinue}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0472" })}{/* 과제 진행을 중단하며, 등록된 스케줄이 있는 경우 삭제됩니다. */}
        </Text>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {formatMessage({ id: "T0473" })}{/* ※ 중단된 과제는 "운행" 버튼을 통해 활성화 가능합니다. */}
        </Text>
        <Text
          style={{
            display: "block",
            fontSize: 14,
            marginTop: 20,
          }}
        >
          {formatMessage({ id: "T0474" })}{/* 과제 중단 사유 */}
        </Text>
        <Select
          value={reasonValue.cd}
          onChange={(e, obj) => onChangeStopChange(e, obj)}
          defaultValue=""
          style={{ width: 215, marginBottom: 15, marginTop: 15 }}
          filterOption={stopReason.sort().map((v) => v.cd)}
        >
          <Option value="">{selectOption}</Option>
          {stopReason.map((v) => {
            return (
              <Option key={v.cd} value={v.cd} label={v.attrCd1}>
                {v.cdNm}
              </Option>
            );
          })}
        </Select>
        {reasonValue.operator === "ETC" ? (
          <TextArea
            placeholder={formatMessage({ id: "T0475" })} // 중단 사유를 입력해주세요.
            rows={4}
            onChange={onChangeDiscontinue}
            value={discontinueReason}
            autoSize={{ minRows: 4, maxRows: 4 }}
            maxLength="500"
          />
        ) : (
          ""
        )}
      </Modal>
      {/* 과제 삭제 모달 */}
      <Modal
        centered
        visible={deletetaskVisible}
        width={400}
        okText={confirmationText}
        title={formatMessage({ id: 'T0072' })} // 삭제
        onOk={onOkDelete}
        onCancel={() => setDeleteTaskVisible(false)}
      >
        <div style={{ textAlign: "center", padding: "20.6px 0" }}>
          <Text>
            {formatMessage({ id: 'T1234' })}  {/* 정말로 삭제하시겠습니까? */}
          </Text>
        </div>
      </Modal>
      <Modal
        centered
        visible={asapConfirmVisible}
        width={500}
        okText={formatMessage({ id: "T1125" })} // 실행
        title={formatMessage({ id: "T0458" })} // 과제 즉시 실행
        onOk={onOkAsapRun}
        onCancel={onCancelAsapRun}
      >
        <Text>
          {assignLookup.taskData?.taskNm}({assignLookup.taskData?.taskId})&nbsp;
          {formatMessage({ id: "T0476" })}&nbsp;{/* 과제를 즉시 실행하시겠습니까? */}
        </Text>
      </Modal>
      <ScheduleUserInputModal
        visible={userInputVisible}
        onOk={onOkUserInput}
        onCancel={onCancelUserInput}
        userInputValues={userInputValues}
        setUserInputValues={setUserInputValues}
      />
      <DevicePool
        visible={devicePoolVisible}
        onOk={onOkDevicePoolModal}
        onCancel={onCancelDevicePoolModal}
        crCode={taskCr.crCd}
        defaultData={[devicePool]}
      />
      {assignLookup.taskData?.statusCd === "T50" ? (
        <div className="assign_lookup__task_status">
          <div>
            <div className="discontinue__container">
              <div className="discontinue__icon">
                <div>
                  <ExclamationCircleFilled />
                </div>
              </div>
              <div className="discontinue__box">
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingBottom: "15px",
                    }}
                  >
                    <Text className="font_20">
                      <FormattedMessage id="T0053" />{/* 중단 */}
                    </Text>
                    <Text className="font_14">
                      {moment(
                        assignLookup.taskData?.taskHistoryList[
                          assignLookup.taskData?.taskHistoryList.length - 1
                        ]?.chgTimestamp
                      )?.format("YYYY-MM-DD HH:mm:ss")}
                    </Text>
                  </div>
                  <div className="discontinue__text">
                    <Text>
                      {/* 중단 사유 내용 부분 */}
                      {taskHistory[0]?.chgDetailDesc}
                    </Text>
                  </div>
                </div>
                <div className="task_status__button_group">
                  {
                    assignLookup.taskData?.taskMemberList?.map(
                      (v) =>
                        (v.mgrDevGbn === 'M' && v.empNo.includes(profile.EMP_NO) && v.deputyHeadYn === "N") || (['UA06', 'UA05'].includes(profile.DWP_USER_LEVEL))
                    ) &&
                    <Button onClick={onClickDeleteTaskButton}>
                      <FormattedMessage id="T0072" />{/* 삭제 */}
                    </Button>
                  }
                  {assignLookup.taskData?.detailStatusCd === "T13" ? (
                    <PermissionComponent fnCode="FN03">
                      <Button type="primary" onClick={onClickRequest}>
                        {formatMessage({ id: "T0083" })}{/* 재요청 */}
                      </Button>
                    </PermissionComponent>
                  ) : (
                    <PermissionComponent
                      fnCode={
                        assignLookup.taskData?.taskHistoryList[
                          assignLookup.taskData?.taskHistoryList.length - 2
                        ]?.chgCd === "T40"
                          ? "FN08"
                          : "FN07"
                      }
                    >
                      <Button type="primary" onClick={onClickUnsuspended}>
                        {formatMessage({ id: "T0084" })} {/* 중단해제버튼 */}
                      </Button>
                    </PermissionComponent>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="assign_lookup__task_status">
          <div>
            <div
              className={`task_status__box ${assignLookup.taskData?.statusCd === "T10" &&
                "status_check_point"
                }`}
            >
              <div className="task_status__status">
                <div className="task_status__step"></div>
                <Text className="font_14 bold">
                  {formatMessage({ id: "T0395" })}{/* 접수 */}
                </Text>
              </div>
              <Tooltip title={regProfile.DEPT_NAME_KOR}>
                <div className="task_status__manager">
                  <Text className="font_14">
                    {formatMessage({ id: "T0456" })}{/* 담당자 */}
                  </Text>
                  <UserTag profile={regProfile} contextVisible={true} />
                </div>
              </Tooltip>
              <div className="task_status__datetime">
                <Text className="font_14">
                  {moment(
                    assignLookup.taskData?.taskHistoryList?.find(
                      (v) => v.chgCd === "T10"
                    )?.chgTimestamp
                  ).format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </div>
            </div>
            {["T20", "T30", "T40"].includes(assignLookup.taskData?.statusCd) ? (
              <div
                className={`task_status__box ${assignLookup.taskData?.statusCd === "T20" &&
                  "status_check_point"
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0396" })}{/* 개발 */}
                  </Text>
                </div>
                <Tooltip title={devProfile.DEPT_NAME_KOR}>
                  <div className="task_status__manager">
                    <Text className="font_14">
                      {formatMessage({ id: "T0456" })}{/* 담당자 */}
                    </Text>
                    <UserTag profile={devProfile} contextVisible={true} />
                  </div>
                </Tooltip>
                <div className="task_status__datetime">
                  <Text className="font_14">
                    {moment(
                      assignLookup.taskData?.taskHistoryList?.find(
                        (v) => v.chgCd === "T20"
                      )?.chgTimestamp
                    ).format("YYYY-MM-DD HH:mm:ss")}
                  </Text>
                </div>
              </div>
            ) : (
              <div
                className={`task_status__box ${assignLookup.taskData?.statusCd === "T20" &&
                  "status_check_point"
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0396" })}{/* 개발 */}
                  </Text>
                </div>
              </div>
            )}
            {assignLookup.taskData?.statusCd === "T30" ||
              assignLookup.taskData?.statusCd === "T40" ? (
              <div
                className={`task_status__box ${assignLookup.taskData?.statusCd === "T30" &&
                  assignLookup.taskData?.detailStatusCd !== "T32" &&
                  "status_check_point"
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0397" })}{/* 테스트 */}
                  </Text>
                </div>
                <Tooltip title={testerProfile.DEPT_NAME_KOR}>
                  <div className="task_status__manager">
                    <Text className="font_14">
                      {formatMessage({ id: "T0456" })}{/* 담당자 */}
                    </Text>
                    <UserTag profile={testerProfile} contextVisible={true} />
                  </div>
                </Tooltip>
                <div className="task_status__datetime">
                  <Text className="font_14">
                    {moment(
                      assignLookup.taskData?.taskHistoryList?.find(
                        (v) => v.chgCd === "T30"
                      )?.chgTimestamp
                    ).format("YYYY-MM-DD HH:mm:ss")}
                  </Text>
                </div>
              </div>
            ) : (
              <div
                className={`task_status__box ${assignLookup.taskData?.statusCd === "T30" &&
                  "status_check_point"
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0397" })}{/* 테스트 */}
                  </Text>
                </div>
              </div>
            )}
            {assignLookup.taskData?.statusCd === "T40" ? (
              <div
                className={`task_status__box ${assignLookup.taskData?.statusCd === "T40" &&
                  "status_check_point"
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0398" })}{/* 운영 */}
                  </Text>
                </div>

                {!assignLookup?.scheduleStatus && (
                  <>
                    <div className="task_status__notice_schedule">
                      <Text className="font_14">
                        &nbsp;
                        {formatMessage({ id: "T0477" })}&nbsp;{/* 스케줄 등록 필요 */}
                      </Text>
                    </div>
                    <PermissionComponent fnCode="FN21">
                      <div className="task_status__datetime">
                        <Button
                          type="primary"
                          style={{ height: "26px" }}
                          onClick={onClickScheduleModal}
                        >
                          {formatMessage({ id: "T0085" })}{/* 스케줄 관리 */}
                        </Button>
                      </div>
                    </PermissionComponent>
                  </>
                )}
                {assignLookup?.scheduleStatus && (
                  <>
                    <div className="task_status_notice__today">
                      <Text className="font_14">Today</Text>
                    </div>
                    <div className="task_status_notice__result">
                      <div style={{ display: 'flex', gap: 2 }}>
                        <Text className="font_14">
                          {formatMessage({ id: "T0403" })}{/* 성공 */}
                        </Text>
                        <Text className="font_14" style={{ color: "green" }}>
                          {operResult.success}
                        </Text>
                      </div>
                      <div style={{ display: 'flex', gap: 3 }}>
                        <Text className="font_14">
                          {formatMessage({ id: "T0404" })}{/* 실패 */}
                        </Text>
                        <Text className="font_14" style={{ color: "red" }}>
                          {operResult.failed}
                        </Text>
                      </div>
                      <div style={{ display: 'flex', gap: 2 }}>
                        <Text className="font_14">
                          {formatMessage({ id: "T0478" })}{/* 진행 */}
                        </Text>
                        <Text className="font_14" style={{ color: "blue" }}>
                          {operResult.progress}
                        </Text>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div
                className={`task_status__box ${assignLookup.taskData?.statusCd === "T40" ||
                  (assignLookup.taskData?.detailStatusCd === "T32" &&
                    "status_check_point")
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0398" })}{/* 운영 */}
                  </Text>
                </div>
                {assignLookup.taskData?.statusCd === "T30" &&
                  assignLookup.taskData?.detailStatusCd === "T32" &&
                  assignLookup.taskData?.taskType === "EDU" ? (
                  <>
                    <div className="task_status__notice_finished">
                      <Text className="font_14">
                        &nbsp;
                        <FormattedMessage id="T0062" />&nbsp;{/* 테스트 완료 */}
                      </Text>
                    </div>
                  </>
                ) : (
                  assignLookup.taskData?.statusCd === "T30" &&
                  assignLookup.taskData?.detailStatusCd === "T32" && (
                    <>
                      <div className="task_status__notice_with_btn">
                        <Text className="font_14">
                          {formatMessage({ id: "T0479" })}&nbsp;{/* 운영배포 필요 */}
                        </Text>
                      </div>
                      <PermissionComponent fnCode="FN15">
                        <div className="task_status__datetime">
                          <Button
                            type="primary"
                            style={{ height: "26px" }}
                            onClick={onOkReleaseButton}
                          >
                            {formatMessage({ id: "T0077" })}{/* 운영배포 */}
                          </Button>
                        </div>
                      </PermissionComponent>
                    </>
                  )
                )}
              </div>
            )}
          </div>
          <div className="task_status__button_group">
            {
              // 본인이 담당하는 과제인 경우에만 노출, 운영 현황 tab이동(상태는 운영일 때)
              assignLookup.taskData?.mgrOpYn === "Y" && (
                <Button onClick={onClickAsapModal}>
                  <FormattedMessage id="T0059" />{/* 즉시 실행 */}
                </Button>
              )
            }
            {
              // 운영 T40 * api 연결 필요함
              ((assignLookup.taskData?.statusCd === "T30" &&
                assignLookup.taskData?.detailStatusCd === "T32" &&
                assignLookup.taskData?.taskType === "EDU") ||
                (assignLookup.taskData?.taskType !== "EDU" &&
                  assignLookup.taskData?.statusCd === "T40")) && (
                <PermissionComponent fnCode="FN04">
                  <Button onClick={onClickShareButton}>
                    {formatMessage({ id: "T0087" })}{/* 공유 받기 */}
                  </Button>
                </PermissionComponent>
              )
            }
            {
              // 운영 T40이면서 업무 담당자인 경우에만 * 과제 담당자인 경우
              assignLookup.taskData?.statusCd === "T40" && (
                <PermissionComponent fnCode="FN05">
                  <Button onClick={onClickTransferButton}>
                    {formatMessage({ id: "T0086" })}{/* 업무 이관 */}
                  </Button>
                </PermissionComponent>
              )
            }
            {
              /* 담당자 && CoE(Admin) */
              assignLookup.taskData?.taskMemberList?.map(
                (v) =>
                  v.mgrDevGbn === 'M' && v.empNo.includes(profile.EMP_NO) && v.deputyHeadYn === "N" &&
                  <PermissionComponent fnCode="FN06">
                    <Button onClick={onClickDiscontinueButton}>
                      <FormattedMessage id="T0053" />{/* 중단 */}
                    </Button>
                  </PermissionComponent>
              )
            }
            {
              assignLookup.taskData?.taskMemberList?.map(
                (v) =>
                  (v.mgrDevGbn === 'M' && v.empNo.includes(profile.EMP_NO) && v.deputyHeadYn === "N") || (['UA06', 'UA05'].includes(profile.DWP_USER_LEVEL))
              ) &&
              <Button onClick={onClickDeleteTaskButton}>
                <FormattedMessage id="T0072" />{/* 삭제 */}
              </Button>
            }
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default withRouter(TaskStatus);