import { getUniqueKey } from 'utils/Str';

export const LOAD_WORKFLOW_DATA = getUniqueKey('LOAD_WORKFLOW_DATA');
export const LOAD_WORKFLOW_DATA_SUCCESS = getUniqueKey('LOAD_WORKFLOW_DATA_SUCCESS');
export const LOAD_WORKFLOW_DATA_FAIL = getUniqueKey('LOAD_WORKFLOW_DATA_FAIL');
export const SET_WORKFLOW_DATA = getUniqueKey('SET_WORKFLOW_DATA');
export const CHANGE_HISTORY_WORKFLOW_DATA = getUniqueKey('CHANGE_HISTORY_WORKFLOW_DATA');
export const WORKFLOW_INSTANT_START = getUniqueKey('WORKFLOW_INSTANT_START');
export const CHANGE_WORKFLOW_DATA = getUniqueKey('CHANGE_WORKFLOW_DATA');
export const CHANGE_WORKFLOW_SHCEDULE_STATUS = getUniqueKey('CHANGE_WORKFLOW_SHCEDULE_STATUS');
export const RESET_WORKFLOW_DATA = getUniqueKey('RESET_WORKFLOW_DATA');

export function loadWorkflowData(workflowId) {
    return {
        type: LOAD_WORKFLOW_DATA,
        workflowId
    };
};
export function loadWorkflowDataSuccess(workflowData) {
    return {
        type: LOAD_WORKFLOW_DATA_SUCCESS,
        workflowData
    };
};
export function loadWorkflowDataFail(error) {
    return {
        type: LOAD_WORKFLOW_DATA_FAIL,
        error
    };
};
export function setWorkflowData(data) {
    return {
        type: SET_WORKFLOW_DATA,
        data
    };
};
export function changeHistoryWorkflowData(history, moreInfo = {}) {
    return {
        type: CHANGE_HISTORY_WORKFLOW_DATA,
        history,
        moreInfo
    };
};
export function WORKFLOWInstantStart(data, cb = () => { }) {
    return {
        type: WORKFLOW_INSTANT_START,
        data,
        cb
    };
};
export function changeWorkflowScheduleStatus(bool) {
    return {
        type: CHANGE_WORKFLOW_SHCEDULE_STATUS,
        bool
    };
};

export function resetWorkflowData() {
    return {
        type: RESET_WORKFLOW_DATA
    };
};