import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    width: 1600px;
    .popup_manage__top {
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
    }
    .popup_manage__top_right {
        display: flex;
        align-items: flex-end;
        gap: 0 20px;
    }
    .popup_manage__bottom {
        margin-top: 25px;
    }
    .popup_manage__bottom td,
    .popup_manage__bottom th {
        height: 49px;
        padding-top: 0;
        padding-bottom: 0;
    }
    /* 상태 radio 영역 */
    .ant-radio-inner {
        width: 20px;
        height: 20px;
        border: 1px solid #b0b1b5;
        background: #fff;
    }
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: #ff7a00;
    }
    .ant-radio-wrapper { 
        font-size: 14px; 
        place-items: normal;
    }
    span.ant-radio + * {
        padding-right: 10px;
        padding-left: 5px;
    } 
    .ant-table {
      width: 100%;
    }
    .ant-table-thead > tr > th {q
      height: 50px;
      padding: 16px 16px 15px 20.3px;
      color: #000;
    }
    .ant-table-tbody td {
      height: 50px;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.87);
    }
    .ant-table-tbody td > span {
        font-size: inherit;
        color: inherit;
    }
    .ant-table-pagination.ant-pagination { 
      padding-bottom: 84px;
    }
    .popup_title:hover {
        color: #ff6600 !important;
        text-decoration: underline;
    }
    .popup_title > span {
        color: inherit;
        font-size: 12px;
    }
    .popup_status {
        display: flex;
        gap: 0 5px;
    }
    .popup_status > span {
        font-size: 12px;
    }
    .popup_status__progress > span {
        color: #2b65d9;
    }
    .popup_status__waiting > span {
        color: #e10013;
    }
    .popup_status__stop > span {
        color: #3dba61;
    }
`;

export default Wrapper;