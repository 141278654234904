import { getUniqueKey } from "utils/Str";

export const SET_BOXWOODASSIGNlIST_VALUE = getUniqueKey(
  "SET_BOXWOODASSIGNlIST_VALUE"
);
export const SET_BOXWOODASSIGNLIST_SPREAD = getUniqueKey(
  "SET_BOXWOODASSIGNLIST_SPREAD"
);
export const RESET_BOXWOODASSIGNLIST_VALUE = getUniqueKey(
  "RESET_BOXWOODASSIGNLIST_VALUE"
);
export const SET_BOXWOODASSIGNLIST_FILTERID = getUniqueKey(
  "SET_BOXWOODASSIGNLIST_FILTERID"
);

export function setBoxwoodAssignListValue(key, value) {
  return {
    type: SET_BOXWOODASSIGNlIST_VALUE,
    key,
    value,
  };
}

export function setBoxwoodAssignListSpread(data) {
  return {
    type: SET_BOXWOODASSIGNLIST_SPREAD,
    data,
  };
}

export function resetBoxwoodAssignListValue() {
  return {
    type: RESET_BOXWOODASSIGNLIST_VALUE,
  };
}

export function setBoxwoodAssignListFilterId(filterId) {
  return {
    type: SET_BOXWOODASSIGNLIST_FILTERID,
    filterId,
  };
}
