import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import { Modal } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import {
  Text,
} from "components";
import "moment/locale/ko";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import { setVisible } from 'store/loading/action';
import ResultForm from "./ResultForm";
import EditableForm from "./EditableForm";
import { setWorkflowData } from 'store/hfWorkflowLookup/action';
/**
 * 기본 환경 > 개발 (분석/설계)
 * 비활성화는 T10 && T11
 * 활성화 T10 && T12
 * 활성화되면서 정보 출력 및 수정버튼은 나머지인 경우
 */

const WorkflowLookupStart = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const selectOption = formatMessage({ id: "T0024" });
  const confirmationText = formatMessage({ id: "T0064" }); // 확인
  const developStartFail = formatMessage({ id: "T0161" });

  const dispatch = useDispatch();

  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

  // hfWorkflowLookup.workflowData에서 가져오는 analyticalDatas
  const [analyticalDatas, setAnalyticalDatas] = useState({
    developers: [],
    devSchedule: [],
  });

  // 수정 및 입력 시 변경되는 analyticalDatas
  const [editAnalyticalDatas, setEditAnalyticalDatas] = useState({});
  // 수정 화면 여부 state
  const [editable, setEditable] = useState(false);
  
  const [deleteFiles, setDeleteFiles] = useState([]);

  // 개발 시작, 수정 확인 모달 Visible State
  const [startDevVisible, setStartDevVisible] = useState(false);
  const [editDevVisible, setEditDevVisible] = useState(false);


  const onClickEditableButton = () => {
    setEditable(true);
    setEditAnalyticalDatas({
      ...analyticalDatas,
    });
  };

  // 수정 버튼 클릭 함수
  const onClickEditDevButton = () => setEditDevVisible(true);

  // analytical 데이터 입력 시 state 세팅 함수
  const onChangeEditAnalyDatas = (name, val) => {
    if (Array.isArray(editAnalyticalDatas[name])) {
      setEditAnalyticalDatas({
        ...editAnalyticalDatas,
        [name]: !val ? [] : val,
      });
    } else {
      setEditAnalyticalDatas({
        ...editAnalyticalDatas,
        [name]: val,
      });
    }
  };


  //날짜 입력시 state 세팅
  const onChangeSchedule = (e) => {
    onChangeEditAnalyDatas("devSchedule", e)
  };

  const onClickDeleteButton = (e, { label, index }) => {
    const tmpArr = [...editAnalyticalDatas[label]];
    tmpArr.splice(index, 1);
    setEditAnalyticalDatas({ ...editAnalyticalDatas, [label]: tmpArr });
  };

  // 개발 시작 Modal Cancel 함수
  const onCancelStartDev = () => setStartDevVisible(false);
  // 개발 시작 버튼 클릭 함수
  const onClickStartDevButton = () => setStartDevVisible(true);

  const getMainMember = async (number) => {
    const arr = [];
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${number}`
      );
      result.data.mdFPAList[0].deputyHeadYn = "N"
      arr.push(result.data.mdFPAList[0]);
    return arr;
  };

  const getSubMemberList = async (numbers) => {
    const arr = [];
    for (let i = 0; i < numbers.length; i++) {
      const number = numbers[i];
      const result = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${number}`
      );
      result.data.mdFPAList[0].deputyHeadYn = "Y"
      arr.push(result.data.mdFPAList[0]);
    }
    return arr;
  };

  const fetchInfo = async (inputData) => {
    const developersMain = await getMainMember(
      inputData?.wfMemberHFList
        .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "N")
        .map((v) => v.empNo)
    );

    const developersSub = await getSubMemberList(
      inputData?.wfMemberHFList
        .filter((v) => v.mgrDevGbn === "D" && v.deputyHeadYn === "Y")
        .map((v) => v.empNo)
    );

    const developers = developersMain.concat(...developersSub);

  //hfWorkflowLookup.workflowData 
  //   "wfDevInfo": {
  //   "idx": 0,
  //   "workflowId": "string",
  //   "planStartDt": "string",
  //   "planEndDt": "string"
  // },
    const devSchedule = [];
    if (
      inputData.wfDevInfo
    ) {
      devSchedule.push(
        moment(inputData.wfDevInfo.planStartDt),
        moment(inputData.wfDevInfo.planEndDt)
      );
    } else {
      const fromDate = moment();
      const toDate = moment().add(1, "months");
      devSchedule.push(fromDate, toDate);
    }
    setAnalyticalDatas({
      developers,
      devSchedule: [...devSchedule],
    });
  };

    // 개발 시작 OK 버튼 - ※수정(업무 담당자에게 알림 발송, API 연결)
    const onOkStartDev = async () => {
      setStartDevVisible(false);
      dispatch(setVisible(true));
      const mergeData = {
        workflowId: hfWorkflowLookup.workflowData.workflowId,
        startDt: moment(editAnalyticalDatas.devSchedule?.[0]).format("YYYY-MM-DD"),
        endDt: moment(editAnalyticalDatas.devSchedule?.[1]).format("YYYY-MM-DD"),
      };

      const modifyParameter = [
        ...editAnalyticalDatas.developers
          .map((v) => ({
            mgrDevGbn: "D",  //D아닌가..?
            empNo: v.empNum,
            deputyHeadYn: v.deputyHeadYn,
          }))
      ];
  
      const startParameter = {
        ...mergeData,
        wfMemberHFList: [...modifyParameter]
      };

      //과제 상태 변경
      const dResult = await fetchAbsolute(
        "post", `/workflow/startDev`, {
          data: { ...startParameter }
        }
      );

      if(dResult.status===200) {
        dispatch(setVisible(true));
      } else if (dResult.error) {
        dispatch(setVisible(false));
        return alert(developStartFail);
      }

      const newData = await fetchAbsolute(
        "get",
        `/workflow/workflowInfoHF?workflowId=${hfWorkflowLookup.workflowData.workflowId}`
      );
      if (newData.error) {
        dispatch(setVisible(false));
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }

      fetchInfo(newData.data);
      dispatch(setWorkflowData(newData.data));

      dispatch(setVisible(false));
      setEditable(false);
    };


  // WorkflowData가 변경될 때 analytical Data를 변경해준다.
  useEffect(() => {
    if (!hfWorkflowLookup.workflowData.workflowId) return;
    fetchInfo(hfWorkflowLookup.workflowData);
  }, [hfWorkflowLookup.workflowData]);

  // // analyticalData가 변경되면 수정 시 해당 내용들이 변경되어야하기 때문에 함께 변경해준다.
  useEffect(() => {
    setEditAnalyticalDatas({
      ...analyticalDatas,
    });
  }, [analyticalDatas]);

  return (
    <>
      <Modal
        centered
        visible={startDevVisible}
        width={500}
        okText={confirmationText}
        title={formatMessage({ id: "T0514" })}
        onOk={onOkStartDev}
        onCancel={onCancelStartDev}
      >
        <Text>{formatMessage({ id: "T0515" })}</Text>
      </Modal>
      
    {/* - 진행 상태 : 접수(WS10)
    - 상세 진행 상태 : 접수 완료(WS11)
    - 로그인 사용자가 개발자인 경우만 EditedForm 출력 */}
        
    {
      hfWorkflowLookup.workflowData.statusCd === "WS10"
      &&hfWorkflowLookup.workflowData.detailStatusCd === "WS11"
      && (hfWorkflowLookup.workflowData.empLevGbns?.includes('D') || hfWorkflowLookup.workflowData.empLevGbns?.includes('A'))
    ? 
    <EditableForm
      editable={editable}
      analyticalDatas={analyticalDatas}
      editAnalyticalDatas={editAnalyticalDatas}
      onClickStartDevButton={onClickStartDevButton}
      onChangeEditAnalyDatas={onChangeEditAnalyDatas}
      onChangeSchedule={onChangeSchedule}
      onClickEditDevButton={onClickEditDevButton}
      onClickDeleteButton={onClickDeleteButton}
      selectOption={selectOption}
    />
    :
    <ResultForm
      onClickEditableButton={onClickEditableButton}
      analyticalDatas={analyticalDatas}
      selectOption={selectOption}
    />
    }
    </>
  );
};

export default WorkflowLookupStart;