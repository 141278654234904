import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Text, UserTag, Button } from "components";
import { EyeIcon, ProfileIcon, BackToListIcon } from "icons";
import {
  HeartFilled,
  HeartOutlined, 
} from "@ant-design/icons";
import { Divider} from "antd";
import { useIntl } from "react-intl";

const CommunityTop = ({
  match: { params },
  history,
  postingInfo,
  postingFileList, 
  onClickAddRec,
  onClickCancelRec, 
  onClickDelete,
  boardMaCondition, 
  userAuth,
  profile,
  onClickBacktoList,
}) => {
  const { formatMessage } = useIntl();
  
  const writer = formatMessage({ id: "T0018" });

  // // /* 게시물 수정 버튼 기능 */
  const onClickEditDetail = () => {
    if (postingInfo.pboardSeq !== 0) {
      history.push(`/community/${params.boardId}/${params.boardSeq}/${postingInfo.pboardSeq}/modify`);
    } else {
      history.push(`/community/${params.boardId}/${params.boardSeq}/modify`);
    }
  }; 
  
  return (
    <div>
      <div>
        <div className="top_first_line">
          <Text className="headline">{postingInfo.boardTitle}</Text>
          <Text className="headline__date">{postingInfo.regTimestamp}</Text>
          <div className="icon__group">
            <div className="readcnt_box">
              <EyeIcon className="readcnd_box__icon" />
              <Text className="readcnt_count">
                {postingInfo.readcount > 99 ? "+99" : postingInfo.readcount}
              </Text>
            </div>
            <div className="interest_box">
              {postingInfo.rcmdYn === "Y" ? (
                <HeartFilled
                  className="interest_box__icon"
                  onClick={onClickCancelRec}
                  data-boardSeq={postingInfo.boardSeq}
                />
              ) : (
                <HeartOutlined
                  className="interest_empty_box__icon"
                  onClick={onClickAddRec}
                  data-boardSeq={postingInfo.boardSeq}
                />
              )}
              <Text className="interest_count">
                {postingInfo.rcmdcount > 99 ? "+99" : postingInfo.rcmdcount}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="top_second_line">
          <div className="writer">
            <Text className="main">{writer}</Text>
            {postingInfo.regId === "admin" ? (
              <div className="admin">
                <ProfileIcon style={{ width: 25, height: 25 }} />
                <Text className="text">{formatMessage({ id: 'T0881' })}</Text>
              </div>
            ) : (
              <UserTag
                profile={{
                  NAME_KOR: postingInfo.regEmpNam,
                  EMP_NO: postingInfo.regId,
                  DEPT_NAME_KOR: postingInfo.regOrgNam,
                  FILE_ID: postingInfo.regFileId,
                }}
                contextVisible={true}
              />
            )}
          </div>
          <div className="button_company">
            <Button
              className="btns"
              type="default"
              style={{ display: "flex", alignItems: "center", placeContent: "center", }}
              onClick={onClickBacktoList}
            >
              <BackToListIcon />
              {formatMessage({ id: 'T0089' })}
            </Button>
            {(postingInfo.regId === profile.EMP_NO ||
              userAuth === "UA06" ||
              userAuth === "UA05") && (
              <>
                <Button className="btns" type="default" onClick={onClickDelete}>
                  {formatMessage({ id: 'T0090' })}{/* 게시물 삭제 */}
                </Button>
                <Button
                  className="btns"
                  type="default"
                  onClick={onClickEditDetail}
                >
                  {formatMessage({ id: 'T0091' })}{/* 게시물 수정 */}
                </Button>
              </>
            )}
            {boardMaCondition.replyYn === "Y" && postingInfo.pboardSeq === 0 && (
              <Link
                to={`/community/${params.boardId}/${params.boardSeq}/reply`}
              >
                <Button className="btns" type="default">
                  {formatMessage({ id: 'T0092' })}
                </Button>
              </Link>
            )}
          </div>
        </div>
        <Divider
          // style={{ border: "1px solid #0067ac", background: "#0067ac" }}
          style={{ border: "1px solid #ff7a00", background: "#ff7a00" }}
        />
        <div className="top_content_section">
          <div className="actual_content">
            <div
              dangerouslySetInnerHTML={{ __html: postingInfo.boardContents }}
              style={{ fontSize: 14 }}
            ></div>
          </div>
          {postingFileList.length > 0 && (
            <div className="attachments">
              <div className="file_list">{formatMessage({ id: 'T0513' })}{/*첨부파일*/}</div>
              <div className="file_name_list">
                {postingFileList.map((v, index) => (
                  <Text
                    key={index} 
                    type='download'
                    download={v.down}
                    fileName={v.fileName}
                  >
                    {v.fileName}
                  </Text>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default withRouter(CommunityTop);