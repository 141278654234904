import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import Wrapper from "./Styled.js";
import { Row, Col, Select } from "antd";
import {
  Input,
  Button,
  InputSearch,
  FormTable,
  Text,
  DepartmentList,
  Organization,
  BusinessClass,
  DevicePool,
  Modal,
} from "components";
import { CloseCircleFilled } from "@ant-design/icons";
import {
  setAssignScheduleValue,
  setAssignScheduleSpread,
  setAssignScheduleFilterId,
} from "store/assignSchedule/action";
import { FormattedMessage, useIntl } from "react-intl";

const { Option } = Select;

/**
 * Filename: AssignNewSceduleTop/index.js
 * Description: 과제 조회 상단 검색 컴포넌트
 */


const initialPageInfo = {
  sort: "crCode",
  direction: ",asc",
  currentPage: 1,
  rowPerPage: 20,
  total: 0
};

const AssignNewSceduleTop = ({
  replaceSearch,
  searchQuery,
  scheduleSearch,
  setScheduleSearch,
  taskTypeList,
  setSelectedDevicePool,
}) => {
  const { formatMessage } = useIntl();

  const initialInputValues = {
    taskId: "",
    taskNm: "",
    taskType: {},
    workId: [],
    orgCd: [],
    mgrEmpNo: [],
    devicePool: [],
    scheduleType: [],
  };

  const scheduleTypeList = [
    {
      text: formatMessage({ id: "T1150" }), // NONE
      value: "NONE",
    },
    {
      text: formatMessage({ id: "T1151" }), // DAILY
      value: "DAILY",
    },
    {
      text: formatMessage({ id: "T1152" }), // WEEKLY
      value: "WEEKLY",
    },
    {
      text: formatMessage({ id: "T1153" }), // MONTHLY
      value: "MONTHLY",
    },
  ];

  const dispatch = useDispatch();

  const profile = useSelector((state) => state.get("auth").get("profile"));
  const inputValues = useSelector(
    (state) => state.get(getUniqueKey("assignSchedule")).inputValues
  );
  const filterId = useSelector(
    (state) => state.get(getUniqueKey("assignSchedule")).filterId
  );

  const isDelete = useRef(false);
  const [loading, setLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const selectPlaceholder = formatMessage({ id: "T0024" }); // 선택
  const taskNm = formatMessage({ id: "T0002" }); // 과제명
  const taskOrgCd = formatMessage({ id: "T0005" }); // 담당부서
  const taskNmPlaceholder = formatMessage({ id: "T0023" }); // 과제명을 입력하세요
  const selectOption = formatMessage({ id: "T0024" }); // 선택
  const searchPlaceholder = formatMessage({ id: "T0025" }); // 검색어를 입력하세요
  const mgrEmpNoPlaceholder = formatMessage({ id: "T0026" }); // 사용자를 선택하세요
  const devicePoolPlaceholder = formatMessage({ id: "T0146" }); // Device Pool을 선택하세요
  const myFilter = formatMessage({ id: "T0032" }); // 내 필터
  const selectFilter = formatMessage({ id: "T0033" }); // 필터 선택
  const filterLimited = formatMessage({ id: "T0152" });
  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState("");
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    orgCd: false,
    mgrEmpNo: false,
    devicePool: false,
    workId: false,
    filter: false,
  });
  // 저장 필터 이름
  const [saveFilterName, setSaveFilterName] = useState("");
  // filter List State
  const [filterList, setFilterList] = useState([]);

  // Device Pool
  const crInfo = useSelector((state) => state.get(getUniqueKey("crInfo")));
  const crCodeInfo = crInfo.find(
    (v) => v.crType === inputValues?.taskType.cd && v.crEnvGubun === "PRD"
  )?.crCd;
  const [diyList, setDiyList] = useState([]);
  const [coeList, setCoeList] = useState([]);

  // 과제 ID나 과제명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearchButton(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  //  select 부분에선 e.target.value 등이 안먹혀서 둘 만 따로 'e'로 관리
  const onChangeSearchInput = (label, value) => {
    dispatch(setAssignScheduleValue(label, value));
  };

  // taskType 과제 유형 변경 함수
  const onChangeType = (e) => {
    const value = taskTypeList.find((type) => type.cd === e);
    setSelectedDevicePool([]);
    dispatch(
      setAssignScheduleSpread({
        taskType: { ...value },
        devicePool: [],
      })
    );
  };

  // diy device pool 변경 함수
  const onChangeDevicePool = (pool) => {
    let tmpArr = [];
    for (let i in pool) {
      const diyDevicePool = diyList.find((v) => v.devicePoolId === pool[i]);
      tmpArr.push(diyDevicePool);
    }
    onChangeSearchInput("devicePool", [...tmpArr]);
    setSelectedDevicePool([...tmpArr]);

    if (pool === undefined || pool === "") {
      sessionStorage.removeItem("DIY");
    } else {
      const getDiYNm = diyList.filter((v) => v.devicePoolId === pool)[0]
        ?.devicePoolName;
      sessionStorage.setItem("DIY", getDiYNm);
    }
  };

  // shceduleType 변경 함수
  const onChangeScheduleType = (e) => {
    let tmpArr = [];
    for (let i in e) {
      const value = scheduleTypeList.find((type) => type.value === e[i]);
      tmpArr.push(value);
    }
    onChangeSearchInput("scheduleType", [...tmpArr]);
  };

  // input 부분 나머지 search와 select는 위에 따로 빼줌
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    onChangeSearchInput(name, value);
  };

  // 저장할 필터 이름 변경 이벤트
  const onChangeSaveFilterName = (e) => {
    // 필터 이름 15글자 제한
    if (e.target.value.length > 15) return;
    setSaveFilterName(e.target.value);
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({ ...modalVisible, [label]: true });
  };

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    dispatch(setAssignScheduleValue(modalLabel, data));
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
    if (modalLabel === "devicePool") {
      sessionStorage.setItem("COE", data[0].dpNm);
    }
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false,
    });
  };

  const onOkFilterModal = async () => {
    setModalVisible({
      ...modalVisible,
      filter: false,
    });

    if (saveFilterName.trim().length === 0) {
      alert(formatMessage({ id: "T0150" })); // 필터명을 입력해주세요.
      return;
    }
    if (
      filterList.findIndex(
        (v) => v.filterNm.toUpperCase() === saveFilterName.toUpperCase()
      ) !== -1
    ) {
      alert(formatMessage({ id: "T0151" })); // 필터명이 중복됩니다.
      return;
    }
    // 내 필터 5개 제한
    if (filterList.length > 4) {
      alert(filterLimited);
      return;
    }

    const saveInputValues = inputValues;

    const addFilter = await fetchAbsolute(
      "post",
      `/common/commonFilter/addNew`,
      {
        data: {
          ...setFilterObject(saveInputValues, saveFilterName),
          filterGbn: "s",
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    setSaveFilterName("");
    setFilterList([
      ...filterList,
      {
        ...saveInputValues,
        filterId: addFilter.data.filterId,
        filterNm: saveFilterName,
      },
    ]);
  };

  // 필터 저장할 때 값 세팅
  const setFilterObject = (values, filterName) => {
    const workId = values.workId.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.cd);
      return tmpArr;
    });
    const orgCd = values.orgCd.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.codeId);
      return tmpArr;
    });
    const mgrEmpNo = values.mgrEmpNo.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.empNum);
      return tmpArr;
    });
    const devicePool = values.devicePool.map((data) => {
      // dpId(COE)
      const tmpArr = [];
      tmpArr.push(data.id || data.dpId || data.devicePoolId);
      return tmpArr;
    });
    const scheduleType = values.scheduleType.map((data) => {
      const tmpArr = [];
      tmpArr.push(data.value);
      return tmpArr;
    });

    const convertObject = {
      empNo: profile.EMP_NO,
      filterNm: filterName,
      filterList: [
        {
          filterNum: "filter1", //taskId
          attrList: [values.taskId] || [],
        },
        {
          filterNum: "filter2", //taskNm
          attrList: [values.taskNm] || [],
        },
        {
          filterNum: "filter3", //taskType
          attrList: [values.taskType?.cd] || [],
        },
        {
          filterNum: "filter4", //workId
          attrList: workId.flat() || [],
        },
        {
          filterNum: "filter5", //orgCd
          attrList: orgCd.flat() || [],
        },
        {
          filterNum: "filter6", //mgrEmpNo
          attrList: mgrEmpNo.flat() || [],
        },
        {
          filterNum: "filter7", //devicePool
          attrList: devicePool.flat() || [],
        },
        {
          filterNum: "filter8", //scheduleType
          attrList: scheduleType.flat() || [],
        },
      ],
    };
    return convertObject;
  };

  // 초기화 버튼 클릭 이벤트
  const onClickResetButton = () => {
    dispatch(setAssignScheduleFilterId(""));
    replaceSearch({
      ...initialInputValues,
      taskType: { ...taskTypeList.sort((a, b) => b.sort - a.sort)[0] },
    });
    // 초기화 taskType 설정
    dispatch(
      setAssignScheduleSpread({
        ...initialInputValues,
        taskType: { ...taskTypeList.sort((a, b) => b.sort - a.sort)[0] },
      })
    );
    sessionStorage.clear(); // device pool 둘 다 삭제
  };

  // 검색 버튼 클릭 이벤트
  const onClickSearchButton = () => {
    dispatch(setAssignScheduleFilterId(""));
    replaceSearch({ ...inputValues });
  };

  // 최근 검색 인풋창 클릭시
  const onClickScheduleSearch = () => {
    const searchObj = JSON.parse(
      localStorage.getItem("beforeObjectSchedule") || {}
    );
    dispatch(setAssignScheduleSpread({ ...initialInputValues, ...searchObj }));
    replaceSearch({ ...searchObj });
  };

  // 최근 검색 삭제 클릭 이벤트
  const onClickScheduleSearchDelete = () => {
    setScheduleSearch("");
    localStorage.setItem("beforeSearchSchedule", "");
    localStorage.setItem("beforeObjectSchedule", "");
  };

  // 필터 리스트 변경 이벤트
  const onSelectFilterList = (value) => {
    // 삭제 버튼 눌렀을 때 filterId 변경되지 않게 세팅
    if (isDelete.current) {
      isDelete.current = false;
      return;
    }
    dispatch(setAssignScheduleFilterId(value));
  };

  // 필터 삭제 버튼 클릭 이벤트
  const onClickFilterDelete = async (e) => {
    isDelete.current = true;
    const value = e.currentTarget.dataset.filterid;
    const deleteFilterList = await fetchAbsolute(
      "delete",
      `/common/commonFilter/deleteNew`,
      {
        data: {
          empNo: profile.EMP_NO,
          filterId: value,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (deleteFilterList.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
    const tmpArr = [...filterList];
    if (filterId === value) {
      dispatch(setAssignScheduleFilterId(""));
    }
    setFilterList([...tmpArr.filter((filter) => filter.filterId !== value)]);
  };

  useEffect(() => {
    if (filterId === "" || filterList.length === 0) {
      return;
    }
    const filterObject = {
      ...(filterList.find((v) => v.filterId === filterId) || {}),
    };

    if (filterObject) {
      delete filterObject.filterNm;
      delete filterObject.filterId;

      const { devicePool, mgrEmpNo, orgCd, taskType, workId } = filterObject;

      const setDatas = async () => {
        // 부서 정보 세팅
        if (orgCd) {
          if (typeof orgCd[0] !== "object") {
            if (typeof orgCd === {}) return (filterObject.orgCd = orgCd);
            const tmpArr = [];
            for (let i in orgCd) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/departmentList?orgCd=${orgCd[i]}`
              );
              tmpArr.push(data?.mdList[0]);
            }
            filterObject.orgCd = [...tmpArr];
          }
        }
        // 업무 담당자 정보 세팅
        if (mgrEmpNo) {
          if (typeof mgrEmpNo[0] !== "object") {
            const tmpArr = [];
            for (let i in mgrEmpNo) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/memberNoList?empNo=${mgrEmpNo[i]}`
              );
              tmpArr.push(data.mdFPAList[0]);
            }
            filterObject.mgrEmpNo = [...tmpArr];
          }
        }
        // 업무 분류
        if (workId) {
          if (typeof workId[0] !== "object") {
            const tmpArr = [];
            for (let i in workId) {
              const { data } = await fetchAbsolute(
                "get",
                `/common/workClassification?cd=${workId[i]}`
              );
              tmpArr.push(data);
            }
            filterObject.workId = [...tmpArr];
          }
        }
        //devicePool
        if (devicePool) {
          if (typeof devicePool[0] !== "object") {
            const tmpArr = [];
            if (taskType === "COE") {
              for (let i in devicePool) {
                tmpArr.push(
                  coeList.find((pool) => pool.dpId === devicePool[i])
                );
              }
            } else {
              for (let i in devicePool) {
                tmpArr.push(
                  diyList.find((pool) => pool.devicePoolId === devicePool[i])
                );
              }
            }
            filterObject.devicePool = [...tmpArr];
          }
        }
        dispatch(setAssignScheduleSpread({ ...filterObject, ...searchQuery }));
        replaceSearch({ ...filterObject });
      };
      setDatas();
    }
  }, [filterId, filterList]);

  useEffect(() => {
    // DIY Device Pool 목록
    const getDiyDevicePoolList = async (page) => {
      if (page === undefined) {
        page = { ...pageInfo }
      }
      setLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/admin/devicePool/getDevicePoolList?crType=DIY&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
      );
      setLoading(false);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      setDiyList([...result.data.lists]);
      setPageInfo({ ...page, total: +result.data.totalItemsCount });
    };
    // COE Device Pool 목록
    const getCoeDevicePoolList = async () => {
      /* Device Pool COE */
      setLoading(true);
      const { data } = await fetchAbsolute(
        "get",
        `/admin/devicePool/getDevicePoolGroupListByCrCd?CrCode=COE_PRD_01`
      );
      setLoading(false);
      setCoeList([...data]);
    };
    const fetchFilterList = async () => {
      try {
        setLoading(true);
        const { data } = await fetchAbsolute(
          "get",
          `/common/commonFilter/findByEmpNoAndFilterGbnNew?empNo=${profile.EMP_NO}&filterGbn=s`
        );
        const lists = data?.map((filter) => ({
          filterId: filter.filterId,
          filterNm: filter.filterNm,
          taskId: filter.filter1?.map((data) => data.filterAttr)[0] || "",
          taskNm: filter.filter2?.map((data) => data.filterAttr)[0] || "",
          taskType: filter.filter3?.map((data) => data.filterAttr)[0] || "",
          workId: filter.filter4?.map((data) => data.filterAttr) || [],
          orgCd: filter.filter5?.map((data) => data.filterAttr) || [],
          mgrEmpNo: filter.filter6?.map((data) => data.filterAttr) || [],
          devicePool: filter.filter7?.map((data) => data.filterAttr) || [],
          scheduleType: filter.filter8?.map((data) => data.filterAttr) || [],
        }));
        setLoading(false);
        setFilterList([...lists]);
      } catch (e) { } finally { setLoading(false); }
    };
    setScheduleSearch(localStorage.getItem("beforeSearchSchedule") || "");
    fetchFilterList();
    getDiyDevicePoolList();
    getCoeDevicePoolList();
    return () => {
      dispatch(setAssignScheduleFilterId(""));
      setLoading(false);
    }
  }, []);

  return (
    <Wrapper>
      <DepartmentList
        visible={modalVisible.orgCd}
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues[modalLabel]}
        mode="multiple"
      />
      <Organization
        visible={modalVisible.mgrEmpNo}
        title={formatMessage({ id: "T0465" })} // 담당자 검색
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues[modalLabel]}
        mode="multiple"
      />
      <BusinessClass
        title={formatMessage({ id: "T1119" })} // 업무 분류 검색
        visible={modalVisible.workId}
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues[modalLabel]}
        mode="multiple"
      />
      <DevicePool
        visible={modalVisible.devicePool}
        onOk={onOkModal}
        onCancel={onCancelModal}
        crCode={crCodeInfo}
        defaultData={inputValues[modalLabel]}
        mode="multiple"
      />
      <Modal
        centered="centered"
        title={formatMessage({ id: "T0034" })} // 필터 저장
        visible={modalVisible.filter}
        onOk={onOkFilterModal}
        onCancel={onCancelModal}
        width="500px"
      >
        <div id="modalForm">
          <label for="filterNm">{formatMessage({ id: "T0035" })}</label>
          {/* 필터명 */}
          <Input
            id="filterId"
            name="filterNm"
            type="text"
            value={saveFilterName}
            mexLength={50}
            onChange={onChangeSaveFilterName}
          />
        </div>
      </Modal>
      <FormTable
        pointBorder={false}
        size="sm"
        className="assign_list_schedule_top"
      >
        <colgroup>
          <col width="9.375%" />
          <col width="15.625%" />
          <col width="9.375%" />
          <col width="15.625%" />
          <col width="9.375%" />
          <col width="15.625%" />
          <col width="9.375%" />
          <col width="15.625%" />
        </colgroup>
        <tbody>
          <tr gutter={[24, 16]}>
            <th style={{ borderRadius: "14px 0 0 0" }}>
              <Text>{formatMessage({ id: "T0001" })}</Text>
              {/* 과제 ID */}
            </th>
            <td>
              <Input
                placeholder={formatMessage({ id: "T0022" })} // 과제 ID를 입력하세요
                style={{
                  width: 200,
                  height: 32,
                }}
                name="taskId"
                value={inputValues.taskId}
                onChange={onChangeValues}
                onKeyPress={onKeyPress}
              />
            </td>
            <th>
              <Text>{taskNm}</Text>
            </th>
            <td>
              <Input
                placeholder={taskNmPlaceholder}
                style={{
                  width: 200,
                  height: 32,
                }}
                name="taskNm"
                value={inputValues.taskNm}
                onChange={onChangeValues}
                onKeyPress={onKeyPress}
              />
            </td>
            <th>
              <Text>{formatMessage({ id: "T0003" })}</Text>
              {/* 과제 유형 */}
            </th>
            <td>
              <Select
                size="default"
                style={{ width: 200 }}
                name="taskType"
                value={inputValues.taskType.cd}
                onChange={onChangeType}
                placeholder={selectOption}
                filterOption={taskTypeList.sort((a, b) =>
                  a.sort > b.sort ? -1 : 1
                )}
              >
                {taskTypeList.map((v) => {
                  return (
                    <Option key={v.cd} value={v.cd}>
                      {v.cdNm}-{v.attrCd1 || v.cd}
                    </Option>
                  );
                })}
              </Select>
            </td>
            <th>
              <Text>{formatMessage({ id: "T0008" })}</Text>
              {/* 업무 분류 */}
            </th>
            <td>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="workId"
                placeholder={searchPlaceholder}
                value={inputValues.workId.map((c) => c.cdNm).join(", ")}
              />
            </td>
          </tr>
          <tr>
            <th span={2}>
              <Text>{taskOrgCd}</Text>
            </th>
            <td span={4}>
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="orgCd"
                placeholder={searchPlaceholder}
                value={inputValues.orgCd.map((c) => c.codeNm).join(", ")}
              />
            </td>
            <th span={2}>
              <Text>{formatMessage({ id: "T0006" })}</Text>
              {/* 업무 담당자 */}
            </th>
            <td span={4}>
              <div>
                <InputSearch
                  onClick={onClickModalButton}
                  width={200}
                  height={32}
                  placeholder={mgrEmpNoPlaceholder}
                  data-label="mgrEmpNo"
                  value={inputValues.mgrEmpNo.map((c) => c.empNam).join(", ")}
                />
              </div>
            </td>
            <th span={2}>
              <Text>
                <FormattedMessage id="T1060" />
                {/* Device Pool */}
              </Text>
            </th>
            <td span={4}>
              <div>
                {inputValues.taskType.cd === "COE" ? (
                  <InputSearch
                    onClick={onClickModalButton}
                    placeholder={devicePoolPlaceholder}
                    width={200}
                    height={32}
                    data-label="devicePool"
                    value={inputValues.devicePool.map((d) => d.dpNm).join(", ")}
                  />
                ) : (
                  <Select
                    size="default"
                    mode="multiple"
                    maxTagCount='responsive'
                    style={{ width: 200 }}
                    onChange={onChangeDevicePool}
                    placeholder={devicePoolPlaceholder}
                    value={inputValues.devicePool.map(
                      (pool) => pool.devicePoolId
                    )}
                  >
                    {diyList.map((v) => (
                      <Option value={v.devicePoolId} key={v.devicePoolId}>
                        {v.devicePoolName}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
            </td>
            <th>
              <Text>{formatMessage({ id: "T0010" })}</Text>
              {/* 스케줄 유형  */}
            </th>
            <td>
              <Select
                size="default"
                style={{ width: 200 }}
                mode="multiple"
                maxTagCount='responsive'
                name="scheduleType"
                placeholder={selectPlaceholder}
                value={inputValues.scheduleType}
                onChange={onChangeScheduleType}
                filterOption={taskTypeList
                  .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                  .map((item) => item.cd)}
              >
                {scheduleTypeList.map((v) => {
                  // T0544:  1회 실행
                  return (
                    <Option key={v.value} value={v.value}>
                      {v.text === "NONE" ? `${formatMessage({ id: "T0544" })}` : v.text}
                    </Option>
                  );
                })}
              </Select>
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Row style={{ padding: "0 13px" }}>
        <Col
          span={16}
          style={{
            padding: 10,
            display: "flex",
            alignItems: "center",
            gap: "30px",
          }}
        >
          {scheduleSearch.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text>{formatMessage({ id: "T0031" })}</Text>
              {/* 최근 검색 */}
              <div style={{ width: 200 }}>
                <div className="before__input">
                  <Input
                    style={{
                      width: 200,
                      height: 32,
                    }}
                    readOnly
                    autoFocus
                    value={scheduleSearch}
                    onClick={onClickScheduleSearch}
                  />
                  <CloseCircleFilled
                    className="before__clear"
                    onClick={onClickScheduleSearchDelete}
                  />
                </div>
              </div>
            </div>
          )}
          {filterList.length > 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
              }}
            >
              <Text>{myFilter}</Text>
              <div>
                <Select
                  size="default"
                  defaultValue="none"
                  value={filterId}
                  style={{
                    width: 200,
                    fontSize: 14,
                  }}
                  onSelect={onSelectFilterList}
                >
                  <Option value="">{selectFilter}</Option>
                  {filterList.map((filter) => {
                    return (
                      <Option key={filter.filterId} value={filter.filterId}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div
                            sytle={{
                              width: 80,
                            }}
                          >
                            {filter.filterNm}
                          </div>
                          <div
                            sytle={{
                              width: 20,
                            }}
                          >
                            {
                              <CloseCircleFilled
                                onClick={onClickFilterDelete}
                                data-filterid={filter.filterId}
                              />
                            }
                          </div>
                        </div>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}
        </Col>
        <Col
          span={8}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "0 10px",
            padding: "10px 0",
          }}
        >
          <Button width="100" height="32" onClick={onClickResetButton}>
            <FormattedMessage id="T0037" />
            {/* 초기화 */}
          </Button>
          <Button
            width="100"
            height="32"
            data-label="filter"
            onClick={(e) => onClickModalButton(e.target)}
          >
            <FormattedMessage id="T0038" />
            {/* 필터저장 */}
          </Button>
          <Button
            onClick={onClickSearchButton}
            width="100"
            height="32"
            type="primary"
          >
            <FormattedMessage id="T0039" />
            {/* 검색 */}
          </Button>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default AssignNewSceduleTop;
