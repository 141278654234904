import React from "react";
import { Divider, Typography } from 'antd';
import { Empty } from 'components';
import { useIntl } from "react-intl";
import { Column } from '@ant-design/plots';

const { Title } = Typography;

/**
 * 대시보드 > Insight > 일별 Runner 수행 과제수
 * 
 */


const DailyRunnerPerformance = ({ dailySuccess }) => {
    const { formatMessage } = useIntl();

    const config = {
        isGroup: true,
        xField: 'rundate',
        yField: 'finishedTaskCnt',
        seriesField: 'devicename',
        dodgePadding: 2,
        intervalPadding: 10,
        label: {
            position: 'middle',
            layout: [
                { type: 'interval-adjust-position', },
                { type: 'interval-hide-overlap', },
                { type: 'adjust-color', },
            ],
            style: {
                fontWeight: 500
            },
        },
        xAxis: {
            label: {
                autoRotate: false,
            },
        },
        // scrollbar: {
        //     type: 'horizontal',
        // },
    }


    return (
        <div className='box__container'>
            {/* 일별 Runner 수행 과제수 */}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1531" })}
            </Title>
            <Divider />
            {
                dailySuccess.length > 0 ?
                    <Column data={dailySuccess} {...config}
                        className="dashboard_graph" style={{ height: 300, width: `calc(100px * ${dailySuccess.length})` }} />
                    : <Empty />
            }
        </div>
    )
}
export default DailyRunnerPerformance