import { getUniqueKey } from 'utils/Str';

export const SET_COMMUNITY_VALUE = getUniqueKey('SET_COMMUNITY_VALUE');
export const SET_COMMUNITY_SPREAD = getUniqueKey('SET_COMMUNITY_SPREAD');
export const RESET_COMMUNITY_VALUE = getUniqueKey('RESET_COMMUNITY_VALUE');
export const SET_COMMUNITY_CONDITION = getUniqueKey('SET_COMMUNITY_CONDITION');
export const RESET_COMMUNITY_CONDITION = getUniqueKey('RESET_COMMUNITY_CONDITION');

export function setCommunityValue(key, value) {
    return {
        type: SET_COMMUNITY_VALUE,
        key,
        value
    };
}

export function setCommunitySpread(data) {
    return {
        type: SET_COMMUNITY_SPREAD,
        data
    }
}

export function resetCommunityValue() {
    return {
        type: RESET_COMMUNITY_VALUE
    }
}

export function setCommunityCondition(data) {
    return {
        type: SET_COMMUNITY_CONDITION,
        data
    }
}

export function resetCommunityCondition() {
    return {
        type: RESET_COMMUNITY_CONDITION,
    }
} 