import { SET_BOXWOODSERVICE_VALUE, SET_BOXWOODSERVICE_SPREAD, RESET_BOXWOODSERVICE_VALUE, SET_BOXWOODSERVICE_PAGEINFO } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */
const boxwoodServiceInit = {
    inputValues: {
        serviceTaskNameLike: '',
        activeState: 'ALL',
        categories: []
    },
    pageInfo: {
        order: 'createdAt desc',
        startAt: 0,
        filteredLength: 20,
        total: 0,
    }
};

const boxwoodService = (state = boxwoodServiceInit, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_BOXWOODSERVICE_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_BOXWOODSERVICE_PAGEINFO: {
            const pageInfo = {
                ...state.pageInfo,
                ...action.data
            };
            return {
                ...state,
                pageInfo
            }
        }
        case SET_BOXWOODSERVICE_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_BOXWOODSERVICE_VALUE: {
            const inputValues = {
                ...boxwoodServiceInit.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        default:
            return state;

    }
}

export default boxwoodService;
