import { Divider, Typography } from 'antd';
import { useIntl } from "react-intl";
import { Column } from '@ant-design/charts';
import { Empty } from 'components';

import { each, groupBy } from '@antv/util';

const { Title } = Typography;

/**
 * 대시보드 > Insight > 과제별 운영 성공률
 * 
 */


const TaskSuccessRate = ({ dailyOperSuccess }) => {
    const { formatMessage } = useIntl();

    const annotations = [];

    each(groupBy(dailyOperSuccess, 'taskId'), (values, k) => {
        const convert = values.reduce((a, b) => +a.rate);
        const makeHeight = values.reduce((a, b) => a.value);

        annotations.push({
            type: 'text',
            position: (xScale, yScales) => {
                const index = xScale.values.indexOf(k);
                return [index, makeHeight];
            },
            content: `${convert}%`,
            style: {
                textAlign: 'center',
                fontSize: 14,
                fontWeight: 500,
                fill: 'rgba(0,0,0,0.85)',
                // fill: '#fff',
            },
            offsetY: -15,
        });
    });
  
    const config = {
        // isStack: true,
        xField: 'taskId',
        yField: 'value',
        color: ['#bebebe', '#fc5310'],
        seriesField: 'type',
        label: {
            position: 'middle',
            // position: (xScale, yScales, index) => {  
            // },
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'adjust-color' },
                // { type: 'interval-hide-overlap' }, 
            ],
            content: function content(item) {
                return ""
                    .concat(+item.value)
                    .concat(formatMessage({ id: 'T1537' })) // 건
            },
        },
        xAxis: {
            label: {
                autoRotate: false,
            },
        },
        yAxis: {
            tickCount: 10, // y축의 tick 개수를 5개로 지정
            tickInterval: 20, // y축의 tick 간격을 50으로 지정
        },
        legend: false,
        annotations
    };

    return (
        <div className='box__container'>
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1533" })}  {/* 과제별 운영 성공률 */}
            </Title>
            <Divider />
            {
                dailyOperSuccess.length > 0 
                ?
                    <Column {...config} data={dailyOperSuccess}
                        className="dashboard_graph" 
                        style={{ height: 300, width: `calc(100px * ${dailyOperSuccess.length})` }} /> 
                :   <Empty />
            }
        </div>
    )
}
export default TaskSuccessRate