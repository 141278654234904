import { getUniqueKey } from 'utils/Str';

export const SET_ITERNAL_SYSTEM_VALUE = getUniqueKey('SET_ITERNAL_SYSTEM_VALUE');
export const SET_ITERNAL_SYSTEM_SPREAD = getUniqueKey('SET_ITERNAL_SYSTEM_SPREAD');
export const RESET_ITERNAL_SYSTEM_VALUE = getUniqueKey('RESET_ITERNAL_SYSTEM_VALUE'); 

export function setIternalSystemValue(key, value) {
    return {
        type: SET_ITERNAL_SYSTEM_VALUE,
        key,
        value
    };
}

export function setIternalSystemSpread(data) {
    return {
        type: SET_ITERNAL_SYSTEM_SPREAD,
        data
    }
}

export function resetIternalSystemValue() {
    return {
        type: RESET_ITERNAL_SYSTEM_VALUE
    }
} 