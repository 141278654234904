import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .admin_member__table_wrap {
        margin-top: 30px;
    }
    .admin_member__button_group {
        display: flex;
        justify-content: flex-end;
        gap: 0 15px;
    }
    .loading_assignment_excel .lds-spinner {
        position:absolute !important;
        z-index:100;
        height:none !important;
      }
      .loading_assignment_excel {
        height:none !important;
      }
`;

export default Wrapper;