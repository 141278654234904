import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { Modal, Text, Button } from "components";
import { DeleteIcon } from 'icons';
import { Input as AntInput } from "antd";
import { useIntl } from "react-intl";

const { TextArea } = AntInput;
/**
 * Filename: RedoModal/index.js
 * 과제 재실행 모달
 * AdminWorkItem, LookupScheduleStatus, HFWorkflowOperation, AssignOperation 컴포넌트에서 사용
 */

const Wrapper = styled((props) => <Modal {...props} />)`
    overflow-y: "scroll";
    height: 300;
`;

const RedoModal = ({
    redoVisible,
    onOkRedo = () => { },
    onCancelRedo = () => { },
    onClickRestore = () => { },
    onChangeRecordKey = () => { },
    onChangeRecordValue = () => { },
    onClickAdd = () => { },
    onClickDelete = () => { },
    recordData
}) => {
    const { formatMessage } = useIntl();

    return (
        <Wrapper
            width={900}
            title={formatMessage({ id: "T0599" })} // 과제 재실행
            okText={formatMessage({ id: "T1149" })} // 요청
            visible={redoVisible}
            onOk={onOkRedo}
            onCancel={onCancelRedo}
        >
            <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
            }}
            >
                <Text
                    style={{
                    display: "block",
                    fontSize: 16,
                    fontWeight: "bold",
                    }}
                >
                    Record Data
                </Text>
                <div style={{ display: "flex", width:"240px", justifyContent:"space-between"}}>
                    <Button style={{width:"145px"}}onClick={onClickRestore}>
                        {formatMessage({ id: "T0082" })}{/* 초기 데이터 원복 */}
                    </Button>
                    <Button style={{width:"90px"}} type="primary" onClick={onClickAdd}>
                        {formatMessage({ id: 'T0049' })}{/* 추가 */}
                    </Button>
                </div>
            </div>
            {recordData?.map((v, index) => {
            return (
                <div style={{ display: "flex", gap: "0 10px", padding: "10px 0" }}>
                <TextArea
                    style={{ width: "20%" }}
                    rows={8}
                    value={v.key}
                    data-index={index}
                    onChange={onChangeRecordKey}
                />
                <TextArea
                    style={{ width: "80%" }}
                    rows={8}
                    value={v.value}
                    data-index={index}
                    onChange={onChangeRecordValue}
                />
                <DeleteIcon onClick={(e) => onClickDelete(e, index)} />
                </div>
            );
            })}
            <Text
            style={{
                display: "block",
                fontSize: 14,
                textAlign: "center",
            }}
            >
            {formatMessage({ id: "T0575" })}{/* 해당 과제 재실행을 수행합니다. */}
            </Text>
        </Wrapper>
    )
}

export default RedoModal;