import { call, put, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import fetchAbsolute from 'utils/fetchAbsolute';
import {
    AUTH_SIGNIN,
    AUTH_LOAD,
    SET_AUTH_LOADING,
    AUTH_SIGNIN_SUCCESS,
    SSO_SIGNIN_SUCCESS,
    SSO_LOAD,
    SSO_SIGNIN
} from './constants';

function* checkAuthorization(action) {
    const authInfo = yield select(state => state.get('auth'));
    if (authInfo.get('uuid')) {
        action.push('/');
    } else {
        yield put({
            type: AUTH_LOAD,
            payload: { ...action.payload },
            push: action.push,
            // push: action.push('/'),
            setCookie: action.setCookie
        });
    }
}


const setLoading = bool => ({
    type: SET_AUTH_LOADING,
    payload: {
        loading: bool
    }
});

const fetchAuthorization = data => fetchAbsolute('post', `/login/login`, { data });

function* loadAuthorization(action) {
    const data = {
        email: action.payload.empNo,
        password: action.payload.empPw
    };

    yield put(setLoading(true));
    const result = yield call(fetchAuthorization, data);
    // yield call(action.setCookie, 'auth', JSON.stringify(result.data.data));
    action.setCookie('auth', JSON.stringify(result.data.data), {
        path: '/'
    });
    if (result.error) {
        alert('에러');
        yield put(setLoading(false));
    } else if (result.data.state === 400) {
        alert(result.data.message);
        yield put(setLoading(false));
    } else {
        yield put(setLoading(false));
        yield put({
            type: AUTH_SIGNIN_SUCCESS,
            payload: {
                ...result.data.data
            }
        });
        // yield call(action.push, '/'); 
        yield call(action.push, action.payload.url);
    }
}

function* checkAuthSSO(action) {

    yield put({
        type: SSO_LOAD,
        payload: action.payload.empNo,
        push: action.push,
        //push: action.push('/'),
    });
}


const fetchSsoUserInfo = data => fetchAbsolute('get', `/auth/getSsoUserAdInfo`,);

function* loadSsoUserInfo(action) {

    const data = {
        empNo: action.payload
    };

    yield put(setLoading(true));
    const result = yield call(fetchSsoUserInfo, data);

    if (result.error) {
        alert('에러');
        yield put(setLoading(false));
    } else if (result.data.state === 400) {
        alert(result.data.message);
        yield put(setLoading(false));
    } else {
        yield put(setLoading(false));
        yield put({
            type: SSO_SIGNIN_SUCCESS,
            payload: {
                ...result.data[0]
            }
        });
    }
    //yield call(action.push, '/'); 
    //yield call(action.push, action.payload.url);
}

export function* commonLoggingInterface(payload) {
    try {
        axios.post('/api/common/v1/notify/log', payload.data);
    } catch (e) {
        console.log(e);
    }
}

export default function* () {
    yield takeLatest(SSO_SIGNIN, checkAuthSSO);
    yield takeLatest(SSO_LOAD, loadSsoUserInfo);
    yield takeLatest(AUTH_SIGNIN, checkAuthorization);
    yield takeLatest(AUTH_LOAD, loadAuthorization);
}
