import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import BoardBasicInfo from "./Basic/BoardBasicInfo";
import BoardSettingCol from "./Basic/BoardSettingCol";
import BoardEditor from "./Basic/BoardEditor";
import { Button } from "components";
import Wrapper from "./Styled";
import { useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";
import { setVisible } from "store/loading/action";
import { decodeHTMLEntites } from "utils/decodeHTMLEntites";

const AdminBoardAdd = ({ type, history, match: { params } }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const dispatch = useDispatch();
  const btnRef = useRef(null);
  const failedMeg = formatMessage({ id: "T0153" }); // 오류가 발생하였습니다.

  const requireFields = {
    boardNm: {
      ref: useRef(null),
      message: formatMessage({ id: "T0622" }), // 게시판 명
    },
    boardId: {
      ref: useRef(null),
      message: formatMessage({ id: "T0624" }), // 게시판 ID
    },
    writeYn: {
      ref: useRef(null),
      message: formatMessage({ id: "T0625" }), // 본문 게시 기능
    },
    replyYn: {
      ref: useRef(null),
      message: formatMessage({ id: "T0626" }), // 답글 작성 기능
    },
    secretYn: {
      ref: useRef(null),
      message: formatMessage({ id: "T0627" }), // 본문 비밀글 기능
    },
    commentYn: {
      ref: useRef(null),
      message: formatMessage({ id: "T0628" }), // 댓글 기능
    },
    useYn: {
      ref: useRef(null),
      message: formatMessage({ id: "T0629" }), // 게시판 사용
    },
  };

  const [basicInfo, setBasicInfo] = useState({
    boardNm: "",
    boardId: "",
    boardUrl: "",
    writeYn: "N",
    replyYn: "N",
    secretYn: "N",
    commentYn: "N",
    showMainYn: "N",
    useYn: "N",
    contentsTmp: "", // 템플릿
    boardUseColumnList: [],
  });

  const [checkedDatas, setCheckedDatas] = useState([]);
  const [usingColumns, setUsingColumns] = useState([]);
  const [columnList, setColumnList] = useState([]);
  /* 초기화버튼 기능을 위한 origin states */
  const [originList, setOriginList] = useState([]);
  const [originUsingColumns, setOriginUsingColumns] = useState([]);
  const [defaultValue, setDefaultValue] = useState("");
  const [isVerified, setIsVertified] = useState(false);

  const [language, setLanguage] = useState("");

  const onMouseLeave = () => {
    btnRef.current.focus();
  };

  const onChangeEditor = (value) => {
    setBasicInfo({
      ...basicInfo,
      contentsTmp: value,
    });
  };
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    const regId = value.replace(/[^A-Za-z0-9]/gi, "");
    if (name === "boardId") {
      setBasicInfo({
        ...basicInfo,
        [name]: regId,
      });
    } else {
      setBasicInfo({
        ...basicInfo,
        [name]: value,
      });
    }
  };

  const onClickSubmitPost = async () => {
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!basicInfo[key] || Object.keys(basicInfo[key]).length <= 0) {
        field.ref.current.focus();
        return alert(
          fmMessage({ id: "T0128", values: { field: field.message } }) // {field} 항목은 필수 입력입니다.
        );
      }
    }

    /* 아이디 중복 체크 했는지 확인 */
    if (!type) {
      if (!isVerified) return alert(formatMessage({ id: "T0129" })); // 아이디 중복체크를 해주세요.
    }

    if (!usingColumns.length > 0) return alert(formatMessage({ id: "T0130" })); // 게시판 목록 컬럼을 설정해주세요.

    const column = [
      ...usingColumns.map((v) => ({
        boardId: basicInfo.boardId,
        useColumn: v.cd,
        sort: v.sort,
      })),
    ];

    const parameter = {
      boardId: basicInfo.boardId,
      boardNm: basicInfo.boardNm,
      writeYn: basicInfo.writeYn,
      replyYn: basicInfo.replyYn,
      commentYn: basicInfo.commentYn,
      secretYn: basicInfo.secretYn,
      showMainYn: basicInfo.showMainYn,
      useYn: basicInfo.useYn,
      blocale: language,
      contentsTmp: basicInfo.contentsTmp, // 템플릿
      boardUseColumn: JSON.stringify({ boardUseColumn: column }),
    };

    const formData = new FormData();
    Object.entries(parameter).forEach(([key, value]) => {
      formData.append(key, value);
    });

    const saveBoard = await fetchAbsolute("post", `/board/boardMaSave`, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (!saveBoard.error) {
      if (type === "modify") {
        alert(formatMessage({ id: "T0131" })); // 게시판 수정이 완료 되었습니다.
      } else {
        alert(formatMessage({ id: "T0132" })); // 게시판 생성이 완료 되었습니다.
      }
    } else {
      return alert(formatMessage({ id: "T0133" })); // 작업을 실패하였습니다.
    }
    history.push("/admin/board");
  };

  const onClickReset = () => {
    if (basicInfo.boardUseColumnList) {
      setColumnList([...originList]);
      setUsingColumns([...originUsingColumns]);
    } else {
      setColumnList([...originList]);
      setUsingColumns([]);
    }
  };

  const rowSelection = {
    selectedRowKeys: checkedDatas,
    onChange: (selectedRowKeys) => {
      setCheckedDatas(selectedRowKeys);
    },
  };

  const onChangeRowSelect = (e, item) => {
    const tmpArr = [...checkedDatas];
    if (e.target.checked) {
      tmpArr.push(item);
    } else {
      const index = tmpArr.findIndex((v) => v === item);
      if (index !== -1) {
        tmpArr.splice(index, 1);
      }
    }
    setCheckedDatas([...tmpArr]);
  };

  const onClickAdd = () => {
    const tmpArr = [...usingColumns];
    checkedDatas.forEach((v) => {
      tmpArr.push(columnList.find((e) => e.cd === v));
    });
    const leftColumns = columnList.filter((v) => !checkedDatas.includes(v.cd));
    setColumnList([...leftColumns]);
    setUsingColumns([...tmpArr]);
    setCheckedDatas([]);
  };

  const onClickDelete = () => {
    const tmpArr = [...columnList];
    checkedDatas.forEach((v) => {
      tmpArr.push(usingColumns.find((e) => e.cd === v));
    });
    const survivedColumns = usingColumns.filter(
      (v) => !checkedDatas.includes(v.cd)
    );
    setUsingColumns([...survivedColumns]);
    setColumnList([...tmpArr]);
    setCheckedDatas([]);
  };

  const onClickDeleteIcon = (item) => {
    const tmpArr = [...columnList];
    tmpArr.push(usingColumns.find((v) => v.cdNm === item));
    const survivedColumns = usingColumns.filter((v) => v.cdNm !== item);
    setUsingColumns([...survivedColumns]);
    setColumnList([...tmpArr]);
    setCheckedDatas([]);
  };

  const onClickCheckId = async () => {
    if (basicInfo.boardNm.trim().length <= 0) {
      return alert(
        fmMessage({
          id: "T0128",
          values: { field: formatMessage({ id: "T0624" }) }, // 게시판 ID
        })
      ); // 항목은 필수 입력입니다.
    }
    const result = await fetchAbsolute(
      "get",
      `/board/boardMaBoardIdChk?boardId=${basicInfo.boardId}`
    );
    if (result.data === 1)
      return alert(
        formatMessage({ id: "T0129" })
      ); //중복된 ID가 있습니다. 다른 ID를 입력해주세요.
    else {
      setIsVertified(true);
      return alert(formatMessage({ id: "T1058" })); // 사용할 수 있는 아이디입니다.
    }
  };

  const onChangeLanguage = (value) => {
    setLanguage(value);
  };

  /* 게시판 ID에 따라서 URL 값도 달라져야 하므로 */
  useEffect(() => {
    setBasicInfo({
      ...basicInfo,
      boardUrl: `http://www.dwp.co.kr/board?boardid=${basicInfo.boardId}`,
    });
  }, [basicInfo.boardId]);

  /* 컬림 리스트 가져와서 셋팅 */
  useEffect(() => {
    if (!basicInfo.boardId || !basicInfo.boardUseColumnList) return;
    const getColumnList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/BOARD_COLUMN`
      );
      if (result.error) return alert(failedMeg); // 오류가 발생하였습니다.
      const usingColumnList = basicInfo.boardUseColumnList?.map(
        (v) => v.useColumn
      );
      setUsingColumns([
        ...basicInfo.boardUseColumnList?.map((v) => ({
          cd: v.useColumn,
          cdNm: v.useColumnNm,
          sort: v.sort,
        })),
      ]);
      setColumnList([
        ...result.data.filter(
          (v) => v.useYn === "Y" && !usingColumnList.includes(v.cd)
        ),
      ]);
      setOriginUsingColumns([
        ...basicInfo.boardUseColumnList?.map((v) => ({
          cd: v.useColumn,
          cdNm: v.useColumnNm,
          sort: v.sort,
        })),
      ]);
      setOriginList([
        ...result.data.filter(
          (v) => v.useYn === "Y" && !usingColumnList.includes(v.cd)
        ),
      ]);
    };
    getColumnList();
  }, [basicInfo.boardUseColumnList]);

  useEffect(() => {
    /* 게시판 modify 상태인 경우 상세 검색 */
    if (type !== "modify" || !language) return;
    const getBoardInfo = async () => {
      dispatch(setVisible(true));
      const result = await fetchAbsolute(
        "get",
        `/board/boardMaInfo?boardId=${params.boardId}&slocale=${language}`
      );
      dispatch(setVisible(false));
      if (result.error) return alert(failedMeg); // 오류가 발생하였습니다.
      setBasicInfo({
        ...basicInfo,
        ...result.data.boardMa,
        boardUseColumnList: result.data.boardUseColumnList,
      });
      if (result.data.boardMa.contentsTmp) {
        const convert = decodeHTMLEntites(result.data.boardMa.contentsTmp);
        setDefaultValue(convert);
      } else {
        setDefaultValue("");
      }
    };
    getBoardInfo();
  }, [type, language]);

  useEffect(() => {
    // 게시판 생성 board column 가져오는 함수
    const getColumnList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/BOARD_COLUMN`
      );
      if (result.error) return alert(failedMeg); // 오류가 발생하였습니다.
      setColumnList([...result.data.filter((v) => v.useYn === "Y")]);
      setOriginList([...result.data.filter((v) => v.useYn === "Y")]);
    };
    getColumnList();
  }, []);

  return (
    <Wrapper>
      <BoardBasicInfo
        requireFields={requireFields}
        onChangeValues={onChangeValues}
        basicInfo={basicInfo}
        type={type}
        isVerified={isVerified}
        onClickCheckId={onClickCheckId}
        language={language}
        onChangeLanguage={onChangeLanguage}
      />
      {language === "ko" && (
        <>
          <BoardEditor
            defaultValue={defaultValue}
            onChangeEditor={onChangeEditor}
            onMouseLeave={onMouseLeave}
          />
          <BoardSettingCol
            basicInfo={basicInfo}
            onClickReset={onClickReset}
            rowSelection={rowSelection}
            columnList={columnList}
            onClickAdd={onClickAdd}
            onClickDelete={onClickDelete}
            usingColumns={usingColumns}
            onClickDeleteIcon={onClickDeleteIcon}
            onChangeRowSelect={onChangeRowSelect}
            checkedDatas={checkedDatas}
            setUsingColumns={setUsingColumns}
          />
        </>
      )}
      <div className="submit__button__area">
        <Button
          className="submit__button"
          type="primary"
          onClick={onClickSubmitPost}
          innerRef={btnRef}
        >
          {type === "modify" ? formatMessage({ id: "T0054" }) : formatMessage({ id: "T0064" })}
        </Button>{/* T0054 : 수정 , T0064 : 확인 */}
        <Link to="/admin/board">
          <Button className="submit__button" type="default">
            {formatMessage({ id: "T0051" })}{/* 취소 */}
          </Button>
        </Link>
      </div>
    </Wrapper>
  );
};

export default withRouter(AdminBoardAdd);
