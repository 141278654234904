import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .te-mode-switch-section {
        display: none !important;
    }
    .popup_edit__box {
        width: 1050px;
    }
    .popup_edit__radio_box {
        display: flex;
        align-items: center;
    }
    .popup_edit__editor {
        margin-top: 10px;
    }
    .popup_edit__box.flex {
        justify-content: space-between;
    }
    .popup_edit__double {
        display: flex;
        align-items: center;
        flex: 1;
    }
    .popup_edit__double .popup_edit__double_text {
        flex: 1;
        font-siz: 14px;
    }
    .popup_edit__double .popup_edit__double_input {
        flex: 4;
    }
    .popup_edit__box_range {
        display: flex;
        gap: 10px;
        align-items: center;
        height: 32px;
    }
    .popup_edit__datetime {
        display: flex;
        gap: 5px;
        height: 32px;
    } 
    .popup_edit__date_picker {
        width: 200px;
    }
    .popup_edit__time_picker {
        width: 100px;
    }
    .popup_edit__range_text {
        font-size: 14px;
        padding-left: 20px;
    }
    .popup_edit__button_wrap {
        display: flex;
        justify-content: flex-end;
        padding-top: 30px;
        gap: 10px;
    }
    .custom_radio >span {
        font-size: 14px;
    }
    button:focus {
      outline: 0;
    } 
`;

export default Wrapper;