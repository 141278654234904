import React, { useState, useEffect } from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Badge, Popover, Divider } from "antd";
import moment from "moment";
import { LinkIcon, ProfileIcon, BellIcon } from "icons";
import { CloseCircleFilled } from "@ant-design/icons";
import { getUniqueKey } from "utils/Str";
import { removeCookie } from 'utils/cookie';
import fetchAbsolute from "utils/fetchAbsolute";
import { resetAuth } from 'restrict/action';
import { setVisible } from 'store/loading/action';
import { setSelectedId } from "store/menuList/action";
import { 
  setHeaderNotiList, 
  setHeaderNotiListWithPeriod,
  setSideNotiList,
  setSideNotiListWithPeriod
} from "store/notiList/action";
import { Text, 
  PermissionComponent, 
  Button, 
  Image,
  MenuList
} from "components";
import HeaderWrap from "./styled";
import LogoImg from "assets/DREAGO_logo.png";
// import LogoImg from "assets/woori_header_logo.png";
import './common.css';
/**
 * Application Header 영역의 Organisms Component
 *
 * Application Route Path 변경을 위한 Navigator 기능 담당
 *
 * @class Header
 */

const Header = ({ history }) => {
  const dispatch = useDispatch();

  // const allList = useSelector((state) => state.get(getUniqueKey("menuList")).menuList);
  const notiAllList = useSelector(state => state.get(getUniqueKey('notiList')));
  const crInfo = useSelector(state => state.get(getUniqueKey('crInfo')));
  const profile = useSelector(state => state.get('auth').get('profile'));
  const sessionAuth = useSelector(state => state.get('auth').get('token'));
  const selectCr = crInfo.find(v => v.crType === 'DIY' && v.crEnvGubun === 'DEV') || {};

  const { formatMessage } = useIntl();
  const licenseGrantFail = formatMessage({ id: 'T0178' }); // License 부여를 실패했습니다.
  const crInfoNotFound = formatMessage({ id: 'T0214' }); // CR 정보를 찾지 못 했습니다.


  const [topNotiList, setTopNotiList] = useState([]);
  const [idPhoto, setIdPhoto] = useState({});
  const [loading, setLoading] = useState(false);
  const [viewAll, setViewAll] = useState(false);

  const onClickMySettings = () => {
    history.push(`/myaccount/myinfo/${profile.EMP_NO}`);
  }

  // 로그아웃 버튼
  const onClickLogout = async () => {
    const result = await fetchAbsolute('post', `/login/logout`, {
      data: {
        "accessToken": sessionAuth.accessToken,
        "refreshToken": sessionAuth.refreshToken
      },
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (result.error) {
      alert(result.data.message);
    }
    removeCookie('auth');
    dispatch(resetAuth());
    return history.push('/signin');
  }

  const onClickDeleteNotiAll = async () => {
    const result = await fetchAbsolute('post', `/common/removeAllNotiUser`, {
      data: {
        empNo: profile.EMP_NO
      }
    });
    if (result.error || result.data.resultCode === 'F') return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    if (result.data.resultCode === 'S') {
      setTopNotiList([]);
    }
    dispatch(setHeaderNotiListWithPeriod());
    dispatch(setSideNotiListWithPeriod());
  }

  const content = (
    <div className='header__content_wrap' style={{
      overflowY: 'scroll',
      maxHeight: 376
    }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button onClick={onClickDeleteNotiAll}>{formatMessage({ id: 'T0095' })}</button>{/* 전체 삭제 */}
        </div>
        <Divider />
        {topNotiList.map((noti, index) => (
          <React.Fragment key={index}>
            <div key={'box_' + index}>
              <div className="header_alert_popover__area">
                <div>
                  <Badge dot={noti.readYn === 'N'}>
                    <div className="popover_title" dangerouslySetInnerHTML={{ __html: noti.ntContentStr }} onClick={() => onClickDeleteNoti(noti.notiSeq)}></div>
                  </Badge>
                </div>
                <div className="header_alert_popover__date">
                  <Text>{(moment(noti.ntDate).format('YYYY-MM-DD a hh:mm')).replace('am', `${formatMessage({ id: 'T0421' })}`).replace('pm', `${formatMessage({ id: 'T0422' })}`)}</Text>{/* T0421 : 오전 , T0422 : 오후 */}
                  <CloseCircleFilled onClick={() => onClickDeleteNoti(noti.notiSeq)} />
                </div>
              </div>
            </div>
            <Divider key={'line_' + index} />
          </React.Fragment>
        ))}
        {
          !viewAll && notiAllList.headerNoti.totalCount !==notiAllList.headerNoti.currentCount
          &&
          <div
            className="header_alert_popover__button"
            style={{
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            height: '35px'
          }}>
            <Button
              onClick={()=>setViewAll(true)}
            >
              {formatMessage({ id: 'T0096' })}{/* 더보기 */}
            </Button>
          </div>
        }
    </div>
  );

  const noNotiContent = (
    <div className='header__content_wrap'>
      <div style={{ textAlign: 'center' }}>
        <Text><FormattedMessage id="T1164" /></Text>{/* 알림이 없습니다. */}
      </div>
    </div>
  )

  const userContent = (
    <div className='user_account__wrap'>
      <div style={{ textAlign: 'center' }}>
        <Text style={{ fontWeight: 'bold' }}>{profile.NAME_KOR}({profile.DEPT_NAME_KOR})</Text>
        <Divider />
        <Text>{profile.EMAIL}</Text>
        <Divider />
        <div className="bottom__section">
          <Button
            className='mysettings'
            onClick={onClickMySettings}
          >
            <FormattedMessage id="T0048" />{/* 설정 */}
          </Button>
          <Button
            type='popover'
            onClick={onClickLogout}
          >
            <FormattedMessage id="T1254" />{/* 로그아웃 */}
          </Button>
        </div>
      </div>
    </div>
  );

  /* 개발하러가기 기능 */
  const onClickGotoDev = async () => {
    dispatch(setVisible(true));
    const result = await fetchAbsolute('get', `/admin/license/addLicense?empNum=${profile.EMP_NO}`);
    dispatch(setVisible(false));
    if (result.error || result.data.resultCode !== 'S') {
      return alert(result.data.message || licenseGrantFail);
    };

    /* 개발 CR이 없는 경우 운영 CR로 이동 */
    if (Object.keys(selectCr).length === 0) {
      const goToDiyPrd = crInfo.find(v => v.crType === 'DIY' && v.crEnvGubun === 'PRD');
      return window.open(goToDiyPrd.crUri);
      // return alert(crInfoNotFound); // CR 정보를 찾지 못 했습니다.
    };
    window.open(selectCr.crUri);
  }

  const onChangeVisible = async (visible) => {
    if (!visible && topNotiList?.filter(v => v.readYn === 'N').length > 0) {
      const result = await fetchAbsolute('get', `/common/notiDwpRead?empNo=${profile.EMP_NO}&ntType=T`);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setTopNotiList([...result.data.map(v => ({
        ...v,
        ntContentStr: v.ntContentStr.replace(`<p style="font-size: 18;font-weight: bold; ">`, '').replace(`</a></p>`, '</a>').replace(/<a\s/gi, `<a style="color: #053c66 !important; text-decoration: underline;"`)
      }))]);
    }
  }

  const onClickDeleteNoti = async (notiSeq) => {
    const result = await fetchAbsolute('post', `/common/removeNotiUser`, {
      data: {
        "empNo": profile.EMP_NO,
        "notiSeq": notiSeq
      }
    });
    // dispatch(setNotiList());
    if (result.error || result.data.resultCode === 'F') return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    if (result.data.resultCode === 'S') {
      const tmpArr = [...topNotiList?.filter(v => v.notiSeq !== notiSeq)];
      setTopNotiList([...tmpArr]);
    }
    if(viewAll) {
      dispatch(setHeaderNotiList());
      dispatch(setSideNotiList());
    } else {
      dispatch(setHeaderNotiListWithPeriod());
      dispatch(setSideNotiListWithPeriod());
    }
  };

  /* 프로필 사진 이미지 가지고 오기 */
  useEffect(() => {
    const getUserFile = async () => {
      if (!profile.FILE_ID) return;
      setLoading(true);
      const result = await fetchAbsolute('get', `/file/listFile/${profile.FILE_ID}`)
      setLoading(false);
      if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setIdPhoto(result.data[0] || '');
    }
    getUserFile();
  }, [profile.FILE_ID]);

  useEffect(() => {
    // if (!profile.EMP_NO) return;
    const fetchTopNoti = async () => {
      setTopNotiList([...notiAllList.headerNoti.dwpList.map(v => ({
        ...v,
        ntContentStr: v.ntContentStr.replace(`<p style="font-size: 18;font-weight: bold; ">`, '').replace(`</a></p>`, '</a>').replace(/<a\s/gi, `<a style="color: #053c66 !important; text-decoration: underline;"`)
      }))]);
    }
    fetchTopNoti();
  }, [notiAllList]);

  useEffect(()=>{
      if(viewAll) {
        dispatch(setHeaderNotiList());
      } else {
        dispatch(setHeaderNotiListWithPeriod());
      }
  },[viewAll]);

  useEffect(() => {
    return () => {
      setLoading(false);
    }
  }, []);

  if (Object.keys(profile).length === 0) {
    return null;
  } else {
    return (
      <HeaderWrap>
        <div className="header_logo__box">
          <Link 
            to="/home"
            onClick={()=>dispatch(setSelectedId(''))}
          >
            <Image
              className="header_logo__image"
              src={LogoImg}
              alt="Dreago Logo img"
              height="30" width="128"
              // height="27" width="206"
            />
          </Link>
          {window.location.href.includes('dev.') &&
            <Text className="logo_dev">DEV</Text>
          }
        </div>
        <div className="header_menu__box">
          <ul className="menu_main__box">
            <MenuList />
          </ul>
          <div className="menu_tools__box">
            <PermissionComponent type="user" fnCode="FN01">
              <Link className="menu_tools__link" to="/assign/enter">
                <span><FormattedMessage id="T0040" /></span>{/* 과제 등록 */}
              </Link>
            </PermissionComponent>
            {/* CR에 DIY 없으면 버튼 사라지고 있으면 버튼 */}
            {
              crInfo.findIndex(v => v.crType === 'DIY') !== -1 &&
              <Text
                className="menu_tools__link"
                onClick={onClickGotoDev}
              >
                <LinkIcon /> <span> <FormattedMessage id="T1162" /></span>{/* 개발하러가기 */}
              </Text>
            }
            <Popover
              placement="bottomRight"
              content={notiAllList.headerNoti.totalCount === 0 ? noNotiContent : content}
              title={formatMessage({ id: "T1163" })} // 알림
              trigger="hover"
              openChange={onChangeVisible}
            >
              <Badge
                className="menu_tools__alram"
                count={notiAllList.headerNoti.totalCount}
              >
                <BellIcon />
              </Badge>
            </Popover>
            <Popover
              placement="bottomRight"
              content={userContent}
              title='My Profile'
              trigger="click"
              style={{ width: 500 }}
            >
              {
                idPhoto.link ?
                  <Image
                    className='avatar_profile'
                    src={idPhoto.link} />
                  :
                  <ProfileIcon
                    style={{ width: 25, height: 25, fontSize: 20 }}
                  />
              }
            </Popover>
          </div>
        </div>
      </HeaderWrap>
    );
  }
};

export default React.memo(withRouter(Header));