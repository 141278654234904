import React, { useEffect, useState } from 'react';
import { FormTable, Input, Button, Text, InputSearch, Upload, UserThumbnail, DepartmentList, Image } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

const initialInputValues = {
    name: '',
    email: '',
    phone: '',
    password: '',
    rePassword: '',
    department: [],
}

/**
 *  관리자 > 회원 관리 > 회원 등록 버튼
 *  회원 등록하는 페이지
 */

const RegisterMemberInfo = ({ history, requireFields, type }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();

    const END_POINT = process.env.REACT_APP_API_URI;
    const [fileList, setFileList] = useState([]);
    const [orgVisible, setOrgVisible] = useState(false);
    const [isVerified, setIsVertified] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [inputValues, setInputValues] = useState({ ...initialInputValues });
    const [pwCheck, setPwCheck] = useState('');
    const [emailCheck, setEmailCheck] = useState('');
    const [allowEmailMsg, setAllowEmailMsg] = useState('');
    const [previewSrc, setPreviewSrc] = useState('');

    const onChangeName = (e) => {
        setInputValues({ ...inputValues, name: e.target.value });
    }

    const onChangePhone = (e) => {
        // 숫자만 입력하도록 정규화 검사 
        const { value } = e.target;
        const onlyNumber = value.replace(/[^0-9]/g, '').replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
        setInputValues({ ...inputValues, phone: onlyNumber });
    }

    const onChangeEmail = (e) => {
        setAllowEmailMsg('');
        const { value } = e.target;
        const idRegex =
            /([\w-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
        if (value.length > 0 && !idRegex.test(value)) {
            setEmailCheck(formatMessage({ id: 'T1274' })); //이메일 형식을 확인해주세요.
        } else if (value.length > 100) {
            setEmailCheck(formatMessage({ id: 'T1275' })); // 아이디는 100자를 넘을 수 없습니다.
            return;
        } else {
            setEmailCheck('');
            if (value.length > 0) {
                setIsDisabled(false);
            } else {
                setIsDisabled(true);
            }
        }
        setInputValues({ ...inputValues, email: e.target.value });
    }

    const onChangeFirstPassword = (e) => {
        setInputValues({ ...inputValues, password: e.target.value });
    }

    const onChangeRePassword = (e) => {
        setInputValues({ ...inputValues, rePassword: e.target.value });
        if (inputValues.password && (inputValues.password !== e.target.value)) {
            setPwCheck(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // 비밀번호가 일치하지 않습니다.
        } else {
            setPwCheck('');
        }
    }

    const onBeforeupload = (inFile) => {
        const tmpArr = [inFile];
        setFileList([...tmpArr]);
        return false;
    };

    const onRemoveFile = (rmFile) => {
        const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
        setFileList([...tmpArr]);
    };

    const onClickSubmit = async () => {
        /* 아이디 중복 체크 했는지 확인 */
        if (!isVerified) return alert(formatMessage({ id: 'T0129' })); // 아이디 중복체크를 해주세요. 

        /* 빈칸 검사 */
        for (const key in requireFields) {
            const field = requireFields[key];
            if (!field.use) continue;
            if (!inputValues[key] && Object.keys(inputValues[key]?.length <= 0)) {
                field.ref.current.focus();
                return alert(fmMessage({ id: 'T0128', values: { field: field.message } }));
                //`${field.message} 항목은 필수 입력입니다.`);
            }
        }
        if (inputValues.orgNam === undefined) return alert(formatMessage({ id: 'T0342' })); // 부서 이름을 검색하세요
        if (inputValues.password !== inputValues.rePassword) return alert(fmMessage({ id: 'T1270', values: { name: formatMessage({ id: "T1271" }) } })); // '비밀번호가 일치하지 않습니다'

        const formData = new FormData();
        fileList.forEach((file) => formData.append('file', file));

        const tmp = inputValues.phone;
        const saveOnlyNumber = tmp.replace(/\-/g, '');

        formData.append('emailAddr', inputValues.email);
        formData.append('password', inputValues.password);
        formData.append('empNam', inputValues.name);
        formData.append('orgCd', inputValues.orgCd);
        formData.append('orgNam', inputValues.orgNam);
        formData.append('cphoneNum', saveOnlyNumber);
        formData.append('companyCd', inputValues.companyCd);

        // 사용자 정보 저장
        const result = await fetchAbsolute('post', `/auth/userAdInfoSave`, {
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });
        if (result.error || result.data.result === 'fail') return alert(result.data.message);
        else {
            alert(fmMessage({ id: 'T1281', values: { field: formatMessage({ id: "T1280" }) } })); // 회원 등록을 완료하였습니다
            return history.push('/admin/member');
        }
    }
    const onClickCancel = () => {
        history.push(`/admin/member`);
    };

    const onClickOrg = () => {
        setOrgVisible(true);
    }

    /* 아이디 이메일 중복 검사 */
    const onClickCheckEmail = async () => {
        const result = await fetchAbsolute(
            'get',
            `/auth/checkMailDuplicate/${inputValues.email}`,
        );
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        else {
            setIsVertified(!result.data); // result.data === false 면 중복아니라 사용 가능 
            if (result.data === true) {
                setAllowEmailMsg(fmMessage({ id: 'T1282', values: { field: formatMessage({ id: 'T1283' }) } })); // 중복된 아이디입니다
            } else {
                setIsDisabled(true);
                setAllowEmailMsg(formatMessage({ id: "T1058" }));// 사용할 수 있는 아이디입니다 
            }
        }
    }

    const onOkOrg = (data) => {
        if (Object.keys(data).length === 0) {
            setInputValues({});
        }
        setInputValues({ ...inputValues, orgCd: data.codeId, orgNam: data.codeNm, companyCd: data.companyCd, department: data });
        setOrgVisible(false);
    }

    /* 이미지 미리보기 할 때 사용 */
    const onChangeImg = (e) => {
        if (e.fileList.length === 0) return;
        /* 업로드 칸에서 지울 경우에 */
        if (e.file.status === 'removed') {
            setPreviewSrc('');
        }
        setPreviewSrc(URL.createObjectURL(e.file));
    }

    useEffect(() => {
        /* 파일을 하나 더 추가해서 기존 것을 없애는 경우 */
        setPreviewSrc('');
    }, [fileList])

    return (
        <div className='common_container'>
            <div className='first_section'>
                <FormTable>
                    <colgroup>
                        <col width='13.5%' />
                        <col width='36.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td>
                                <div className='photo_section'>
                                    <div>
                                        {
                                            previewSrc ?
                                                <Image src={previewSrc} alt="preview-img" style={{ width: 130, height: 130, objectFit: 'cover', borderRadius: '50%' }} />
                                                :
                                                <UserThumbnail width={130} />
                                        }
                                    </div>
                                    <div className='upload_part'>
                                        <Upload
                                            action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                                            multiple={false}
                                            maxCount={1}
                                            beforeUpload={onBeforeupload}
                                            onRemove={onRemoveFile}
                                            fileList={fileList}
                                            onChange={onChangeImg}
                                            accept={'.png, .jpg, .jpeg, .gif'}
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </FormTable></div>
            <div className='second_section'>
                <FormTable>
                    <colgroup>
                        <col width='16.5%' />
                        <col width='33.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                <Text required={true}> <FormattedMessage id="T0602" />{/* 이메일 */}</Text>
                            </th>
                            <td>
                                <div className='duplication_section'>
                                    <div className='email_section'>
                                        <Input
                                            placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T0602' }) } })}// '이메일 입력'
                                            style={{ width: 250 }}
                                            onChange={onChangeEmail}
                                            value={inputValues.email}
                                            innerRef={requireFields.email.ref}
                                        />
                                        <Text className='check_message'>{emailCheck}</Text>
                                    </div>
                                    <div className='duplication_btn_section'>
                                        <Button
                                            onClick={onClickCheckEmail}
                                            disabled={isDisabled}
                                        >
                                            <FormattedMessage id="T0070" />
                                            {/* 중복 체크 */}
                                        </Button>
                                        <Text style={isVerified ? { color: 'blue', fontSize: 12 } : { color: 'red', fontSize: 12 }}>
                                            {allowEmailMsg}
                                        </Text>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text required={true}><FormattedMessage id="T0012" /></Text>{/* 이름 */}
                            </th>
                            <td>
                                <Input
                                    placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T0012' }) } })}// '이름 입력'
                                    onChange={onChangeName}
                                    value={inputValues.name}
                                    innerRef={requireFields.name.ref}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text required={true}><FormattedMessage id="T1255" />{/* 전화번호 */}</Text>
                            </th>
                            <td>
                                <Input
                                    value={inputValues.phone}
                                    placeholder={formatMessage({ id: 'T1256' })}// 휴대 전화번호를 -없이 입력
                                    name='phone'
                                    onChange={onChangePhone}
                                    type='text'
                                    innerRef={requireFields.phone.ref}
                                    maxlength={13}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text required={true}><FormattedMessage id="T0455" />{/* 부서 */}</Text>
                            </th>
                            <td>
                                <InputSearch
                                    height='32'
                                    width='360'
                                    placeholder={formatMessage({ id: "T0029" })} // 담당 부서 검색
                                    onClick={onClickOrg}
                                    value={inputValues.orgNam}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text required={true}><FormattedMessage id="T1271" /></Text>{/* 비밀번호 */}
                            </th>
                            <td>
                                <div className='new_password_section'>
                                    <Input
                                        value={inputValues.password}
                                        placeholder={fmMessage({ id: 'T1259', values: { field: formatMessage({ id: 'T1271' }) } })}// '비밀번호 입력'
                                        name='password'
                                        onChange={onChangeFirstPassword}
                                        type='password'
                                        innerRef={requireFields.password.ref}
                                    />
                                    <Text>
                                        &#42; <FormattedMessage id="T1262" /> {/* 비밀번호는 8~16자 영문 대 소문자, 숫자, 특수문자를 사용하여 구성 */}
                                    </Text>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <Text required={true}>{fmMessage({ id: 'T1284', values: { field: formatMessage({ id: 'T1271' }) } })}</Text>{/* 비밀번호 확인 */}
                            </th>
                            <td>
                                <div className='password_check_section'>
                                    <Input
                                        value={inputValues.rePassword}
                                        placeholder={fmMessage({ id: 'T1284', values: { field: formatMessage({ id: 'T1271' }) } })}// '비밀번호 확인'
                                        name='rePassword'
                                        onChange={onChangeRePassword}
                                        type='password'
                                        innerRef={requireFields.rePassword.ref}
                                    />
                                    <Text className='check_message'>{pwCheck}</Text>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </FormTable>
                <div className='admin_manage_btn_wrap'>
                    <Button
                        type='primary'
                        width='150'
                        height='40'
                        onClick={onClickSubmit}
                    >
                        {formatMessage({ id: 'T0073' })}{/* 저장 */}
                    </Button>
                    <Button
                        type='default'
                        width='150'
                        height='40'
                        onClick={onClickCancel}
                    >
                        <FormattedMessage id="T0051" />{/* 취소 */}
                    </Button>
                </div>
            </div>
            <DepartmentList
                visible={orgVisible}
                onCancel={() => setOrgVisible(false)}
                onOk={onOkOrg}
                defaultData={inputValues['department']}
            />
        </div>
    );
}

export default RegisterMemberInfo;