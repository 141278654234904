import React, { useEffect, useState } from 'react';
import { AssignEnterBox, Table } from 'components';
import { Tooltip } from 'antd';
import { useIntl, FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import fetchAbsolute from 'utils/fetchAbsolute';

/**
 * Filename: AdminEmail AssignmentArea/index.js
 * Description: admin Email Trigger AssignmentArea 
 * 관리자 > Email Trigger 관리 > 과제 수정
 */
//

const initialPageInfo = {
    order: "TASK_ID DESC",
    currentPage: 1,
    rowCount: 5,
    total: 0,
};

const AssignmentArea = ({
    memailInfoSeq
}) => {
    const { formatMessage } = useIntl();

    const [assignmentList, setAssignmentList] = useState([]);
    const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
    const [tableLoading, setTableLoading] = useState(false);

    const columns = [
        {
            key: 0,
            title: <FormattedMessage id="T0340" />, // 업무분류
            dataIndex: "workNm",
            dataSort: "WORK_NM",
            width: 100,
            sorter: (record1) => {
                return record1.workNm;
            },
            render: (text, record) => {
                const shortGbn = text?.split(">");
                return (
                    <Tooltip title={text}>
                        <Link
                            target="_blank"
                            to={`/assign/lookup/${record.taskId}`} rel="noopener noreferrer"
                        >
                            <div
                                style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                {shortGbn && shortGbn[shortGbn.length - 1].trim()}
                            </div>
                        </Link>
                    </Tooltip>
                );
            },
        },
        {
            key: 1,
            title: <FormattedMessage id="T0001" />, // 과제 ID
            dataIndex: "taskId",
            dataSort: "TASK_ID",
            width: 50,
            sorter: (record1) => {
                return record1.taskId;
            },
            render: (text, record) => {
                return (
                    <Link
                        target="_blank"
                        rel="noopener noreferrer"
                        to={`/assign/lookup/${record.taskId}`}>
                        <div>{text}</div>
                    </Link>
                );
            },
        },
        {
            key: 2,
            title: <FormattedMessage id="T0002" />, // 과제명
            dataIndex: "taskNm",
            dataSort: "TASK_NM",
            width: 200,
            sorter: (record1) => {
                return record1.taskNm;
            },
            render: (text, record) => {
                return (
                    <Tooltip title={text}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Link
                                target="_blank"
                                to={`/assign/lookup/${record.taskId}`}
                                rel="noopener noreferrer"
                            >
                                <div
                                    style={{
                                        width: 356,
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    {text}
                                </div>
                            </Link>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            key: 3,
            title: <FormattedMessage id="T0740" />, // 과제 유형
            dataIndex: "taskType",
            dataSort: "TASK_TYPE",
            width: 80,
            sorter: (record1) => {
                return record1.taskType;
            },
            render: (text, record) => {
                return (
                    <Link
                        target="_blank"
                        to={`/assign/lookup/${record.taskId}`}
                        rel="noopener noreferrer"
                    >
                        <div>{text}</div>
                    </Link>
                );
            },
        },
        {
            key: 4,
            title: <FormattedMessage id="T0455" />, // 부서
            dataIndex: "regEmpOrgNam",
            dataSort: "REG_EMP_ORG_NAM",
            width: 80,
            sorter: (record1) => {
                return record1.regEmpOrgNam;
            },
            render: (text, record) => {
                return (
                    <Link
                        target="_blank"
                        to={`/assign/lookup/${record.taskId}`}
                        rel="noopener noreferrer"
                    >
                        <div>{text}</div>
                    </Link>
                );
            },
        },
        {
            key: 5,
            title: <FormattedMessage id="T0456" />, // 담당자
            dataIndex: "regEmpNam",
            dataSort: "REG_EMP_NAM",
            width: 80,
            sorter: (record1) => {
                return record1.regEmpNam;
            },
            render: (text, record) => {
                return (
                    <Link
                        target="_blank"
                        to={`/assign/lookup/${record.taskId}`}
                        rel="noopener noreferrer"
                    >
                        <div>{text}</div>
                    </Link>
                );
            },
        }
    ]

    const getList = async (data) => {
        if (data === undefined) {
            data = { ...pageInfo };
        }
        setTableLoading(true);
        const result = await fetchAbsolute('get', `/admin/trigger/email/taskInfoList?emailInfoSeq=${memailInfoSeq}&currentPage=${data.currentPage}&rowCount=${data.rowCount}&order=${data.order}`);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        setTableLoading(false);
        setAssignmentList(result.data.taskList);
        setPageInfo({ ...pageInfo, ...data, total: result.data.totalItemsCount })
    }

    const onChangeTableColumns = (pagination, filters, sorter) => {
        const order = columns[sorter.columnKey].dataSort;
        let orderBy = "";
        if (sorter.order?.includes("desc")) {
            orderBy = " DESC";
        }
        else if (sorter.order?.includes("asc")) {
            orderBy = " ASC";
        }

        const searchObj = {
            ...pageInfo,
            order: order + orderBy,
        };
        getList(searchObj);
    };

    const onChangePage = (currentPage) => {
        const searchObj = {
            ...pageInfo,
            currentPage,
        };
        setPageInfo({ ...pageInfo, ...searchObj });
        getList(searchObj);
    };

    useEffect(() => {
        getList();
    }, [memailInfoSeq]);

    return (
        <AssignEnterBox title="Assignment Area">
            <Table
                columns={columns}
                dataSource={assignmentList}
                rowKey={(item) => item.taskId}
                pagination={true}
                align="center"
                defaultPageSize={5}
                pageSizeVisible={false}
                onChange={onChangeTableColumns}
                showSorterTooltip={true}
                loading={tableLoading}
                pageOptions={{
                    total: +pageInfo.total,
                    current: +pageInfo.currentPage,
                    pageSize: +pageInfo.rowCount,
                }}
            />
        </AssignEnterBox>
    )
};

export default AssignmentArea;