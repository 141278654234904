import React from 'react';
import { PageTemplate, Header, Footer } from 'components';

const Dashboard = () => {
    return (
        <PageTemplate header={<Header />} footer={<Footer />} isPageHeader={false}>
            <div style={{ textAlignLast: 'center' }}>
                <iframe
                    frameBorder='0'
                    frameSpacing='0'
                    marginwidth='0'
                    style={{ overflow: 'hidden', width: '100%', height: 'calc(100vh - 118px - 64px)' }}
                    // src='http://tbl.skhynix.com/#/site/RPA/views/CitizenDeveloper/02_D_License?:iid=1&&showVizHome=no&:embed=yes&:toolbar=no'
                    title='dashboard'
                >
                </iframe>
            </div>
        </PageTemplate>
    )
}

export default Dashboard;