import React, { useState, useEffect } from 'react';
import { PageTemplate, Header, Footer, WebEditor, Radio, FormTable, Input, Button, Text } from 'components';
import { Select, Input as AntInput } from "antd";
import Wrapper from './Styled';
import fetchAbsolute from 'utils/fetchAbsolute';
import './edit.css';
import { useIntl, FormattedMessage } from "react-intl";
import { decodeHTMLEntites } from 'utils/decodeHTMLEntites';

const { TextArea } = AntInput;
const { Option } = Select;

/**
 * Filename: AdminMessageTemplate/index.js
 * Description: Admin -> 알림 Template 관리 컴포넌트
 */

const AdminMessageTemplate = () => {
    const { formatMessage } = useIntl();

    const uploadImageCallBack = async (file) => {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return new Promise(
            (resolve, reject) => {
                resolve({ data: { link: result_base64 } });
            }
        );
    }

    const toolbar = {
        options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'history'],
        inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
        },
        image: {
            uploadCallback: uploadImageCallBack,
            previewImage: true,
            alt: { present: true, mandatory: false },
            inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
        }
    };

    const [ntTypeList, setNtTypeList] = useState([]);
    const [ntCdList, setNtCdList] = useState([]);
    const [ntMsgTypeList, setNtMsgTypeList] = useState([]);
    const [languageList, setLanguageList] = useState([]);

    // input 데이터
    const [ntType, setNtType] = useState('');
    const [ntCd, setNtCd] = useState('');
    const [title, setTitle] = useState('');
    const [useYn, setUseYn] = useState('Y');
    const [content, setContent] = useState('');
    const [beyond, setBeyond] = useState('');
    const [ntMsgType, setNtMsgType] = useState('');

    const [language, setLanguage] = useState('');

    const [defaultContent, setDefaultContent] = useState('');
    const [defaultBeyond, setDefaultBeyond] = useState('');

    const onMouseLeave = () => {
        document.querySelector('.sc-fznyAO jkNcij')?.scrollIntoView();
    }

    const fetchTemplate = async () => {
        if (ntType.length === 0 || ntCd.length === 0) return;
        const result = await fetchAbsolute('get', `/common/notiTemplate?ntCd=${ntCd}&ntType=${ntType}&lang=${language}`);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        let ntContent = '';
        try {
            ntContent = decodeHTMLEntites(result.data.ntContents);
            if (ntType === 'C') {
                ntContent = ntContent.replace(/\\n/g, '\n',);
            }
        } catch {
            ntContent = result.data.ntContents;
        }
        setContent(ntContent || '');
        setDefaultContent(ntContent || '');
        setTitle(result.data.ntTitle || '');
        setUseYn(result.data.useYn || '');
        if (ntType === 'C') {
            let ntBeyond = '';
            try {
                ntBeyond = decodeHTMLEntites(result.data.ntBeyond);
                ntBeyond = ntBeyond.replace(/\\n/g, '\n');
            } catch {
                ntBeyond = result.data.ntBeyond;
            }
            setNtMsgType(result.data.ntMsgType);
            setBeyond(ntBeyond || '');
            setDefaultBeyond(ntBeyond || '');
        }
    }

    const onChangeNtType = (value) => {
        setNtType(value);
    }

    const onChangeNtCd = (value) => {
        setNtCd(value);
    }

    const onChangeNtMsgType = (value) => {
        setNtMsgType(value);
    }

    const onChangeTitle = (e) => {
        const { value } = e.target;
        setTitle(value);
    }

    const onChangeContent = (value) => {
        setContent(value);
    }

    const onChangeBeyond = (e) => {
        const { value } = e.target;
        setBeyond(value);
    }

    const onChangeLanguage = (value) => {
        setLanguage(value);
    }

    const onChangeCheckUse = (e) => {
        const { value } = e.target;
        setUseYn(value);
    }

    const onClickSubmitButton = async () => {
        if (ntType.length === 0) {
            return alert(formatMessage({ id: 'T0285' }));
            //'형태를 선택해주세요'
        }
        if (ntCd.length === 0) {
            return alert(formatMessage({ id: 'T0286' }));
            //'유형을 선택해주세요.'
        }
        const parameter = {
            ntCd: ntCd,
            ntType: ntType,
            ntContents: content,
            ntTitle: title,
            lang: language,
            useYn: useYn,
            ...(
                ntType === 'C' ?
                    {
                        ntBeyond: beyond,
                        ntMsgType: ntMsgType,
                    } : {}
            )
        };

        const formData = new FormData();
        Object.entries(parameter).forEach(([key, value]) => {
            formData.append(key, value);
        });
        const result = await fetchAbsolute('post', `/common/saveNotiTemplate`, {
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (result.error) {
            return alert(formatMessage({ id: 'T0287' }));
            //'알림 Template 저장을 실패했습니다.');
        }

        await fetchTemplate();

        alert(formatMessage({ id: 'T0288' }));
        //'알림 Template 저장을 완료했습니다.');
    }

    useEffect(() => {
        const fetchNtType = async () => {
            const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/NT_TYPE');
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            const data = result.data.filter(v => v.useYn === 'Y');
            setNtType(data[0].cd);
            setNtTypeList([...data]);
        }

        const fetchNtCd = async () => {
            const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/NT_CD');
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            const data = result.data.filter(v => v.useYn === 'Y');
            setNtCd(data[0].cd);
            setNtCdList([...data]);
        }

        const fetchMsgType = async () => {
            const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/NT_MSG_TYPE');
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            const data = result.data.filter(v => v.useYn === 'Y');
            setNtMsgType(data[0].cd);
            setNtMsgTypeList([...data]);
        }

        const fetchLanguageList = async () => {
            const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            const data = result.data.filter(v => v.useYn === 'Y');
            setLanguage(data[0].cd);
            setLanguageList([...data]);
        }

        fetchMsgType();
        fetchNtType();
        fetchNtCd();
        fetchLanguageList();
    }, []);

    useEffect(() => {
        fetchTemplate();
    }, [ntType, ntCd, language]);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <Wrapper style={{ padding: "30px 50px" }}>
                <FormTable>
                    <colgroup>
                        <col width='13.85%' />
                        <col width='36.08%' />
                        <col width='13.85%' />
                        <col width='36.08%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>
                                {formatMessage({ id: 'T0691' })}{/* 형태 */}
                            </th>
                            <td>
                                <Select
                                    className='admin_msgtmp__select'
                                    value={ntType}
                                    onChange={onChangeNtType}
                                >
                                    {
                                        ntTypeList.map(type => (
                                            <Option value={type.cd} key={type.cd}>{type.cdNm} ({type.cd})</Option>
                                        ))
                                    }
                                </Select>
                            </td>
                            <th>
                                {formatMessage({ id: 'T0692' })}{/* 유형 */}
                            </th>
                            <td>
                                <Select
                                    className='admin_msgtmp__select'
                                    value={language}
                                    onChange={onChangeLanguage}
                                >
                                    {
                                        languageList.map(lan => (
                                            <Option value={lan.cd} key={lan.cd}>{lan.cdNm}</Option>
                                        ))
                                    }
                                </Select>
                            </td>
                        </tr>
                        <tr>
                            <th>
                                {formatMessage({ id: 'T0692' })}{/* 유형 */}
                            </th>
                            <td>
                                <Select
                                    className='admin_msgtmp__select'
                                    value={ntCd}
                                    onChange={onChangeNtCd}
                                >
                                    {
                                        ntCdList.map(ntCd => (
                                            <Option value={ntCd.cd} key={ntCd.cd}>{ntCd.cdNm} ({ntCd.cd})</Option>
                                        ))
                                    }
                                </Select>
                            </td>
                            <th>
                                {formatMessage({ id: 'T0802' })}{/* 사용 여부 */}
                            </th>
                            <td>
                                <Radio
                                    value={useYn}
                                    onChange={onChangeCheckUse}
                                    defaultValue="Y"
                                    renderItem={[{
                                        value: "Y",
                                        text: `${formatMessage({ id: "T0630" })}`, // 사용
                                    },
                                    {
                                        value: "N",
                                        text: `${formatMessage({ id: "T0631" })}`, // 미사용
                                    }
                                    ]}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: 'T0017' })}</th>{/* 제목 */}
                            <td colSpan='3'>
                                <Input
                                    value={title}
                                    onChange={onChangeTitle}
                                    placeholder={formatMessage({ id: 'T0693' })} //  Template 제목을 입력하세요.
                                />
                            </td>
                        </tr>
                        <tr>
                            <th> {formatMessage({ id: 'T0361' })}</th>{/* 내용 */}
                            <td colSpan='3' onMouseLeave={onMouseLeave}>
                                {
                                    ntType === 'C' ?
                                        <TextArea
                                            value={content}
                                            defaultValue={defaultContent}
                                            onChange={(e) => onChangeContent(e.target.value)}
                                            className='admin_msgtmp__textarea'
                                        />
                                        :
                                        <WebEditor
                                            height='500'
                                            onChange={onChangeContent}
                                            defaultValue={defaultContent}
                                        />

                                }
                                <div className='admin_msgtmp__info_wrap'>
                                    <Text className='admin_msgtmp__info_label'>{formatMessage({ id: 'T0694' })}</Text>{/* ※ 사용 가능한 변수 설명 */}
                                    <table className='admin_msgtmp__info_table'>
                                        <colgroup>
                                            <col width='180' />
                                            <col width='419' />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th><FormattedMessage id='T0695' /></th>{/* 변수 */}
                                                <th><FormattedMessage id='T0100' /></th>{/* 설명 */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{`{{ Receiver }}`}</td>
                                                <td><FormattedMessage id='T0697' /></td>{/* 메일 수신자 이름 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Receiver_Email }}`}</td>
                                                <td><FormattedMessage id='T1507' /></td>{/* 메일 수신자 이메일 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Receiver_EmpNum }}`}</td>
                                                <td><FormattedMessage id='T1508' /></td>{/* 메일 수신자 사번 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Receiver_Position }}`}</td>
                                                <td><FormattedMessage id='T0699' /></td>{/* 메일 수신자 직급 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Requestor }}`}</td>
                                                <td><FormattedMessage id='T0701' /></td>{/* 공유 요청자 이름 노출 (과제 공유 요청/승인/거절 시 사용) */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Requestor_Email }}`}</td>
                                                <td><FormattedMessage id='T1509' /></td>{/* 공유 요청자 이메일 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Requestor_EmpNum }}`}</td>
                                                <td><FormattedMessage id='T1510' /></td>{/* 공유 요청자 사번 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Requestor_Position }}`}</td>
                                                <td><FormattedMessage id='T0703' /></td>{/* 공유 요청자 직급 노출 (과제 공유 요청/승인/거절 시 사용) */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Name }}`}</td>
                                                <td><FormattedMessage id='T0705' /></td>{/* 과제 이름 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_ID }}`}</td>
                                                <td><FormattedMessage id='T0707' /></td>{/* 자동으로 생성된 과제 ID 노출 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Type }}`}</td>
                                                <td><FormattedMessage id='T0709' /></td>{/* 과제 유형 노출 (DIY, COE) */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Short_Cut_URL }}`}</td>
                                                <td><FormattedMessage id='T0711' /></td>{/* 과제 상세 화면 바로가기 URL 노출 (링크 포함) */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Contents }}`}</td>
                                                <td><FormattedMessage id='T0713' /></td>{/* 적용 내용 노출 (스케줄 정보, 담당자 변경 등의 상세 내용) */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Transfer_Approval }}`}</td>
                                                <td><FormattedMessage id='T0715' /></td>{/* 업무 인수자가 업무 이관을 승인하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Transfer_Rejection }}`}</td>
                                                <td><FormattedMessage id='T0717' /></td>{/* 업무 인수자가 업무 이관을 거절하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Share_Approval }}`}</td>
                                                <td><FormattedMessage id='T0719' /></td>{/* 업무 담당자가 과제 공유를 승인하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Share_Rejection }}`}</td>
                                                <td><FormattedMessage id='T0721' /></td>{/* 업무 담당자가 과제 공유를 거절하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Workflow_Transfer_Approval }}`}</td>
                                                <td><FormattedMessage id='T1587' /></td>{/* 업무 담당자가 Workflow 변환을 승인하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Workflow_Transfer_Rejection }}`}</td>
                                                <td><FormattedMessage id='T1588' /></td>{/* 업무 담당자가 Workflow 변환을 거절하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Deploy_Approval }}`}</td>
                                                <td><FormattedMessage id='T0723' /></td>{/* COE가 운영 배포를 승인하는 경우 클릭 */}
                                            </tr>
                                            <tr>
                                                <td>{`{{ Task_Deploy_Rejection }}`}</td>
                                                <td><FormattedMessage id="T0725" /></td>{/* COE가 운영 배포를 거절하는 경우 클릭 */}
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td>
                        </tr>
                        {
                            ntType === 'C' && (
                                <>
                                    <tr>
                                        <th>
                                            <FormattedMessage id='T1200' />{/* Beyond */}
                                        </th>
                                        <td colSpan='3'>
                                            <TextArea
                                                value={beyond}
                                                defaultValue={defaultBeyond}
                                                onChange={onChangeBeyond}
                                                className='admin_msgtmp__textarea'
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <FormattedMessage id='T1201' />{/* Message Type */}
                                        </th>
                                        <td colSpan='3'>
                                            <Select
                                                className='admin_msgtmp__select'
                                                value={ntMsgType}
                                                onChange={onChangeNtMsgType}
                                            >
                                                {
                                                    ntMsgTypeList.map(msgType => (
                                                        <Option value={msgType.cd} key={msgType.cd}>{msgType.cdNm} ({msgType.cd})</Option>
                                                    ))
                                                }
                                            </Select>
                                        </td>
                                    </tr>
                                </>
                            )
                        }
                    </tbody>
                </FormTable>
                <div className='admin_msgtmp__btn_wrap'>
                    <Button
                        type='primary'
                        width='150'
                        height='40'
                        onClick={onClickSubmitButton}
                    >
                        {formatMessage({ id: 'T0073' })}{/* 저장 */}
                    </Button>
                    <Button
                        type='default'
                        width='150'
                        height='40'
                    >
                        <FormattedMessage id="T0037" />{/* 초기화 */}
                    </Button>
                </div>
            </Wrapper>
        </PageTemplate >
    );
}

export default AdminMessageTemplate;
