import React, { useState } from 'react';
import styled from 'styled-components';
import { Input as AntInput } from 'antd';
import { Modal, Text, Input } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
const { TextArea } = AntInput;

const Wrapper = styled(props => <Modal {...props} />)`
    .ant-modal-body {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`

const AddCredentialModal = ({ visible = false, onOk = () => { }, onCancel = () => { }, crType = 'COE' }) => {
    const { formatMessage } = useIntl();

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const onOkAddCredential = async () => {
        if (name.trim().length <= 0) {
            return alert(formatMessage({ id: 'T0115' })); // Name은 필수 입력란입니다.
        }
        const result = await fetchAbsolute('post', `/hwlm/credential`, {
            data: {
                name,
                crType,
                description,
                attributes: []
            }
        });
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        setName('');
        setDescription('');
        onOk();
    }

    const onChangeNameInput = (e) => {
        const { value } = e.target;

        if (value.length > 50) {
            return;
        }

        setName(value);
    }

    const onChangeDesInput = (e) => {
        const { value } = e.target;

        if (value.length > 255) {
            return;
        }

        setDescription(value);
    }

    return (
        <Wrapper
            width={450}
            visible={visible}
            title={formatMessage({ id: 'T0343' })} // Credential 추가
            okText={formatMessage({ id: 'T0066' })} // 입력
            onCancel={onCancel}
            onOk={onOkAddCredential}
        >
            <div style={{ display: "flex" }}>
                <Text
                    style={{
                        display: "block",
                        fontSize: 14,
                        width: 130,
                    }}
                >
                    Name
                </Text>
                <Input
                    value={name}
                    onChange={onChangeNameInput}
                />
            </div>
            <div style={{ display: "flex" }}>
                <Text
                    style={{
                        display: "block",
                        fontSize: 14,
                        width: 130,
                    }}
                >
                    Description
                </Text>
                <TextArea
                    value={description}
                    onChange={onChangeDesInput}
                />
            </div>
        </Wrapper>
    )
}
export default AddCredentialModal;