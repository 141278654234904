import { Divider, Typography } from 'antd';
import { Empty, Text } from 'components';
import { useIntl } from "react-intl";
import { Scatter } from '@ant-design/plots';

const { Title } = Typography;

/**
 * 대시보드 > Insight > 운영 과제 성공률
 * 
 */


const OperSuccessRate = ({ operSuccess }) => {
    const { formatMessage } = useIntl();


    const config = {
        appendPadding: 30,
        xField: 'x', // totalCnt
        yField: 'y', // totalSec
        colorField: 'companyNm',
        // color: ['r(0.4, 0.3, 0.7) 0:rgba(255,255,255,0.5) 1:#5B8FF9', 'r(0.4, 0.4, 0.7) 0:rgba(255,255,255,0.5) 1:#61DDAA'],
        sizeField: 'y', // totalSec
        size: [12, 30],
        shape: 'circle',
        annotations: [
            {
                type: 'text',
                content: formatMessage({ id: "T1552" }), // 수행횟수
                position: ['50%', '0%'],
                offsetY: -15,
                style: {
                    textAlign: 'center',
                },
            },
            {
                type: 'text',
                content: formatMessage({ id: "T1553" }), // 수행시간(SEC)
                rotate: Math.PI / 2,
                position: ['100%', '40%'],
                offsetX: 15,
            },
        ],
        yAxis: {
            nice: true,
            line: {
                style: {
                    stroke: '#eee',
                },
            },
        },
        xAxis: {
            grid: {
                line: {
                    style: {
                        stroke: '#eee',
                    },
                },
            },
            line: {
                style: {
                    stroke: '#eee',
                },
            },
        },
        tooltip: {
            customContent: (title, item) => {
                return (
                    <div className="usage_tooltip">
                        <div>
                            <Text><b>{item[0]?.data.companyNm}</b></Text>
                        </div>
                        {/* 과제명 */}
                        <div>
                            {formatMessage({ id: "T0002" })}&#58;&nbsp;<b>{item[0]?.data.taskNm}</b>
                        </div>
                        {/*  Process ID */}
                        <div>
                            {formatMessage({ id: "T1037" })}&#58;&nbsp;<b>{item[0]?.data.processId}</b>
                        </div>
                        {/*  수행횟수 */}
                        <div>
                            {formatMessage({ id: "T1552" })}&#58;&nbsp;<b>{+item[0]?.data.x}</b>
                        </div>
                        {/*  수행시간(SEC) */}
                        <div>
                            {formatMessage({ id: "T1553" })}&#58;&nbsp;<b>{+item[0]?.data.y}</b>
                        </div>
                    </div>
                );
            }
        },
    }

    return (
        <div className='box__container'>
            {/* 운영 과제 성공률 */}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1532" })}
            </Title>
            <Divider />
            {
                operSuccess.length > 0 ?
                    <Scatter data={operSuccess}{...config}
                        className="dashboard_graph" style={{ height: "300px" }} />
                    : <Empty />
            }
        </div>
    )
}
export default OperSuccessRate