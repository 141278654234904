import React, { useState } from "react";
import { Button, Text } from "components";
import { Modal as AntModal } from "antd";
import styled from 'styled-components';
import { useIntl } from "react-intl";

/**
 * Filename: RequestShare.js
 * Description: 사용자 소통 기능 > 업무 공유 받기
 *              onOk 함수를 Prop으로 넘겨서 등록 버튼을 눌렀을 때 이벤트를 지정할 수 있다.
 *              OnCancel 함수를 Prop으로 넘겨서 취소 버튼을 누르거나 Modal이 아닌 바깥 영역을 클릭하였을 떄의 이벤트를 지정할 수 있다.
 */


const ModalStyled = styled(AntModal)`
 .ant-modal-header {
     border-top: 7px solid #ff7b00;
 }
 .ant-modal-title {
     font-weight: bold;
 }
`

const RequestShare = ({
  visible = false,
  title: customTitle,
  onOk = () => { },
  onCancel = () => { },
  children,
  width = 500,
  taskNm = "",
  ...args
}) => {
  const { formatMessage } = useIntl()
  const okText = formatMessage({ id: 'T1149' }); // 요청
  const [title] = useState(customTitle || formatMessage({ id: 'T0358' })); // 과제 공유 받기 요청

  return (
    <ModalStyled
      width={width}
      visible={visible}
      title={title}
      onOk={onOk}
      onCancel={onCancel}
      footer={[
        <Button key="submit" type="primary" onClick={onOk}>
          {okText}
        </Button>,
        <Button key="back" onClick={onCancel}>
          {formatMessage({ id: 'T0051' })}{/* 취소 */}
        </Button>,
      ]}
      {...args}
    >
      <Text
        style={{
          display: "block",
          fontSize: 14,
        }}
      >
        {formatMessage({ id: 'T0359' })}{/* 담당자에게 과제 공유 승인을 요청합니다. */}
      </Text>
      <Text
        style={{
          display: "block",
          fontSize: 14,
          color: "rgba(0, 0, 0, 0.6)",
        }}
      >
        {formatMessage({ id: 'T0360' })}{/* ※ 승인이 완료되면 자세한 안내 가이드가 발송됩니다. */}
      </Text>
      {children}
    </ModalStyled>
  );
};

export default RequestShare;
