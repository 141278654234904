import React, { useState, useEffect } from "react";
import { Table, Button, Image } from "components";
import { Row, Col, Select } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;

/**
 * Filename: RoundList/index.js
 * 관리자 > Item Shop 관리 > 회차별 상품 관리 목록
 */

const initialPageInfo = {
  sort: "productSeq",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 10,
  total: 0
};

const RoundItemManagement = ({ history }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [dataSource, setDataSource] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [selectedRound, setSelectedRound] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });

  const onSelectOption = (e) => {
    setSelectedRound(e);
  };

  const pageSize = [10, 20, 50, 70];

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      page: 1,
      rowPerPage: +value,
    };
    fetchItemList(selectedRound, { ...searchObj });
    //fetchItemList({ ...searchObj });
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => {
        history.push({
          pathname: `/admin/jediitem/rounditemupdate/${record.productSeq}`,
        });
      },
    };
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    const order = sorter.field ? sorter.field : 'productSeq';
    let orderBy = "";
    if (!sorter.order || sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    fetchItemList(selectedRound, searchObj);
  };

  const fetchItemList = async (round, page) => {
    if (!round) return;
    if (page === undefined) {
      page = { ...pageInfo }
    };

    setTableLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/academy/manageProductsByTimeList?round=${round}&page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
    );
    setTableLoading(false);
    if (result.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다. 
    }
    const replaceList = result.data?.manageProductList.map((v) => {
      return {
        ...v,
        link: v.link.replace(
          "200x200",
          "0x0"
        ),
      };
    });
    setDataSource([...replaceList]);
    setPageInfo({ ...page, total: result.data.totalItemsCount });
  };

  const onClickAdd = () => {
    return history.push(`/admin/jediitem/rounditemadd`);
  };

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: 'T0830' }), // 상품명
      dataIndex: "product",
      sorter: (a, b) => a.product,
      width: 150,
      align: "center",
    },
    {
      key: 2,
      title: formatMessage({ id: 'T0831' }), // 상품이미지
      dataIndex: "link",
      align: "center",
      width: 200,
      render: (link, index) => {
        return (
          <div key={index}>
            <Image
              width="100"
              height="100"
              alt="product image"
              src={link}
              className="image"
              value={link}
            />
          </div>
        );
      },
    },
    {
      key: 3,
      title: formatMessage({ id: 'T0832' }), // 가격
      dataIndex: "price",
      sorter: (a, b) => a.price - b.price,
      align: "center",
      width: 100,
      render: (value, index) => {
        return <div key={index}>{value.toLocaleString()}pt</div>;
      },
    },
    {
      key: 4,
      title: formatMessage({ id: 'T0833' }), // 수량
      dataIndex: "qty",
      align: "center",
      sorter: (a, b) => a.qty - b.qty,
      width: 100,
    },
    {
      key: 5,
      title: formatMessage({ id: 'T0819' }), // 노출여부
      dataIndex: "showYn",
      align: "center",
      sorter: (a, b) => a.showYn,
      width: 100,
    },
  ];

  useEffect(() => {
    fetchItemList(selectedRound, { ...initialPageInfo });
  }, [selectedRound]);

  useEffect(() => {
    const fetchRoundList = async () => {
      const result = await fetchAbsolute(
        "get",
        `/academy/manageRoundsViewList`
      );
      if (result.error) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }
      setSelectList([...result.data.manageRoundList]);
      let cnt = result.data.manageRoundList.length;
      setSelectedRound(result.data.manageRoundList[cnt - 1]?.round);
    };
    fetchRoundList();
  }, []);

  return (
    <div className="realtime__content_box">
      {/* <div className="realtime__content_box"> */}
      <Row gutter={[8, 24]} className="align-end">
        <Col span={6}>
          <span>
            {/*  페이지당 행 */}
            {formatMessage({ id: "T0036" })} &emsp;
            <Select
              name="pagination"
              defaultValue={pageSize[0]}
              value={+pageInfo.rowPerPage}
              onSelect={onSelectPage}
            >
              {pageSize.map((v, index) => (
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                </Option>
              ))}
            </Select>
          </span>
        </Col>
        <Col offset={12} span={6} className="round_list__button_group">
          <span>회차 &emsp;
            {selectList !== undefined && (
              <Select
                style={{
                  width: 150,
                  height: 32,
                }}
                className="round_select"
                onChange={onSelectOption}
                value={selectedRound}
              >
                {selectList.map((e) => (
                  <Option value={e.round} key={e.round}>
                    {e.round}
                  </Option>
                ))}
              </Select>
            )}
          </span>
        </Col>
      </Row>
      {/* </div> */}
      <div className="realtime__table_wrap">

        <Table
          className="table_content"
          columns={columns}
          dataSource={dataSource}
          pagination={true}
          showSorterTooltip={true}
          onRow={onRow}
          rowKey={(e) => e.productSeq}
          onChange={onChangeTableColumns}
          loading={tableLoading}
          pageOptions={{
            total: +pageInfo.total,
            current: +pageInfo.currentPage,
            pageSize: +pageInfo.rowPerPage,
          }}
        />
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: 20,
            }}
          >
            <Button width="144" height="40" type="primary" onClick={onClickAdd}>
              <FormattedMessage id="T0049" />{/* 추가 */}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundItemManagement;