import React from "react";
import {
  PageTemplate,
  Header,
  Footer, 
  CommunityList
} from "components";
import Container from "containers/Container";

const Community = () =>  {
  return(
    <PageTemplate header={<Header />} footer={<Footer type='white' />} bodyColor={'#fff'}>
       <Container
        style={{ display: "flex", flexDirection: "column", gap: "30px 0", }}
      >
        <CommunityList/>
      </Container>
    </PageTemplate>
  );
};

export default Community;
