import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Text, Spin } from 'components';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";


const Wrapper = styled(props => <Modal {...props} />)`
`

const DeleteAttributeModal = ({ visible = false, onOk = () => { }, onCancel = () => { }, credentialName = '', crType = 'COE', attribute = {} }) => {
    const { formatMessage } = useIntl();
    const [loading, setLoading] = useState(false)
    const onOkModal = async () => {
        setLoading(true);
        const result = await fetchAbsolute('put', `/hwlm/credential`, {
            data: {
                name: credentialName,
                crType: crType.toUpperCase(),
                attributes: [
                    {
                        type: "DELETE",
                        name: attribute.name,
                    }
                ]
            }
        });
        setLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        onOk();
    }
    return (
        <Wrapper
            width={450}
            visible={visible}
            title={formatMessage({ id: 'T0344' })} // Attribute 삭제
            okText={formatMessage({ id: 'T1149' })} // 요청
            onCancel={onCancel}
            onOk={onOkModal}
        >
            <Text
                style={{
                    display: "block",
                    fontSize: 14,
                }}
            >
                {formatMessage({ id: 'T0345' })}{/* 정말 해당 Attribute를 삭제하시겠습니까? */}
            </Text>
            {!!loading &&
                <Spin tip='Loading...' />
            }
        </Wrapper>
    )
}

export default DeleteAttributeModal;