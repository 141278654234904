import { SET_BOXWOODASSIGNlIST_VALUE, SET_BOXWOODASSIGNLIST_SPREAD, RESET_BOXWOODASSIGNLIST_VALUE, SET_BOXWOODASSIGNLIST_FILTERID } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */
const boxwoodAssignListInitial = {
    inputValues: {
        bpdId: '',
        bpdNm: '',
        bpdOrgCd: '',
        workId: '',
        regId: '',
        regFr: '',
        regTo: '',
        regType: ''
    },
    filterId: '',
    pageInfo: {
        order: 'regTimestamp desc',
        currentPage: 1,
        rowPerPage: 20,
        tab: 'all'
    },
    tableData: [],
    taskCount: {
        totalCount: 0,
        myTaskCount: 0,
        interestTaskCount: 0,
    },
};

const boxwoodAssignList = (state = boxwoodAssignListInitial, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_BOXWOODASSIGNlIST_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_BOXWOODASSIGNLIST_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_BOXWOODASSIGNLIST_VALUE: {
            const inputValues = {
                ...boxwoodAssignListInitial.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        case SET_BOXWOODASSIGNLIST_FILTERID: {
            return {
                ...state,
                filterId: action.filterId
            }
        }
        default:
            return state;

    }
}

export default boxwoodAssignList;
