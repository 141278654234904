import styled from 'styled-components';

const Wrapper = styled.div`
    padding-bottom: 1%; 
    padding-left: 10px;
    .show__container {
        background: #000;
    }
    th {
        background: inherit;
        font-weight: normal;
    }
`

export default Wrapper;