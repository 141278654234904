import React from 'react';
import { Dropdown, Typography } from 'antd';
import styled from 'styled-components';
import fileDownload from 'utils/fileDownload';

const { Text: AntText } = Typography;

const TextWrap = styled(AntText)`
    position: relative;
    color: rgba(0,0,0,0.87);
    font-size: 14px;
    ${props => props.required && `
        &::after {
            content: '*';
            display: block;
            position: absolute;
            top:0;
            right: -6px;
            color:#ff0000;
            font-size:16px;
        }
    `}
    ${props => props.type === 'context' && `
        &:hover {
            text-decoration: underline;
            color: #4747b3;
        }
    `}
`;
const LinkWrap = styled.a`
    position: relative;
    color: #5096ff !important;
    text-decoration: underline !important;
    font-size: 16px;
    ${props => props.required && `
        &::after {
            content: '*';
            display: block;
            position: absolute;
            top:0;
            right: -6px;
            color:#ff0000;
            font-size:16px;
        }
    `}
`;

const Text = ({ style, desc = '', children, type = 'default', contextMenu = <></>, link = '', fileName = '', download = '', onClick = () => { }, required = false, ...args }) => {
    const onClickFileDown = () => {
        fileDownload(download, fileName);
    };
    switch (type) {
        case 'download':
            return (
                <LinkWrap required={required} fileName={fileName} download={download} onClick={onClickFileDown} style={{ ...style }} {...args} rel="noopener noreferrer">
                    {children}
                    {desc.length > 0 && <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)', marginLeft: 5 }}>{desc}</span>}
                </LinkWrap>
            );
        case 'link':
            return (
                <LinkWrap
                    required={required}
                    style={{ ...style }}
                    href={link}
                    target='_blank'
                    rel="noopener noreferrer"
                    {...args}
                >
                    {children}
                    {desc.length > 0 && <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)', marginLeft: 5 }}>{desc}</span>}
                </LinkWrap>
            );
        case 'context':
            return (
                <Dropdown overlay={contextMenu} trigger={['click', 'contextMenu']}>
                    <TextWrap required={required} type={type} style={{ ...style }} {...args}>
                        {children}
                        {desc.length > 0 && <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)', marginLeft: 5 }}>{desc}</span>}
                    </TextWrap>
                </Dropdown>
            );
        default:
            return (
                <TextWrap required={required} style={{ ...style }} onClick={onClick} {...args}>
                    {children}
                    {desc.length > 0 && <span style={{ fontSize: 12, color: 'rgba(0,0,0,0.6)', marginLeft: 5 }}>{desc}</span>}
                </TextWrap>
            )
    }
};

export default Text;