import React from 'react';
import Icon from '@ant-design/icons';

const ProfileSVG = ({ style }) => (
    <svg id="profile" xmlns="http://www.w3.org/2000/svg" width={style.width || 32} height={style.height || 32} viewBox="0 0 25 25">
        <circle id="circle" data-name="circle" cx="12.5" cy="12.5" r="12.5" fill="#adadad" />
        <path id="path" data-name="path" d="M0,11.429V10C0,8.429,2.571,7.143,5.714,7.143S11.429,8.429,11.429,10v1.428ZM2.857,2.858A2.857,2.857,0,1,1,5.714,5.715,2.857,2.857,0,0,1,2.857,2.858Z" transform="translate(6.677 6.123)" fill="#fff" />
    </svg>
);

const BellSVG = () => (
    <svg id="Bell" xmlns="http://www.w3.org/2000/svg" width="32" height="36" viewBox="0 0 32 36">
        <g id="ic-bell">
            <path id="패스_401" data-name="패스 401" d="M0,0H32V36H0Z" fill="none" />
            <path id="패스_402" data-name="패스 402" d="M11.5,6A2.784,2.784,0,0,1,14,3a2.784,2.784,0,0,1,2.5,3,10.73,10.73,0,0,1,5,9v4.5A6.15,6.15,0,0,0,24,24H4a6.15,6.15,0,0,0,2.5-4.5V15a10.73,10.73,0,0,1,5-9" transform="translate(2 1.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path id="패스_403" data-name="패스 403" d="M9,17v1.5a4.5,4.5,0,0,0,9,0V17" transform="translate(2.5 8.5)" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
        </g>
    </svg>

);

const CopySVG = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15.74" viewBox="0 0 14 15.74">
        <g id="Outlined_File_copy" transform="translate(-3 -2)">
            <path id="패스_10210" data-name="패스 10210" d="M13.35,3H4v9.35" transform="translate(0 0)" fill="none" stroke={color ? color : '#848484'} strokeMiterlimit="10" strokeWidth="2" />
            <rect id="사각형_19621" data-name="사각형 19621" width="8.65" height="10.572" transform="translate(7.35 6.168)" fill="none" stroke={color ? color : '#848484'} strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
);

const RgoSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="81.5" viewBox="0 0 122 81.5">
        <defs>
            <clipPath id="clip-path">
                <rect id="사각형_19817" data-name="사각형 19817" width="120" height="81" transform="translate(159 132)" fill="#fff" />
            </clipPath>
            <linearGradient id="linear-gradient" x1="-170.274" y1="-19.11" x2="-170.094" y2="-19.11" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#e41134" />
                <stop offset="1" stopColor="#f58120" />
            </linearGradient>
        </defs>
        <g id="알고_캐릭터" data-name="알고 캐릭터" transform="translate(-262 -116.422)">
            <g id="마스크_그룹_151" data-name="마스크 그룹 151" transform="translate(103 -15.578)" clipPath="url(#clip-path)">
                <g id="알파" transform="translate(-1084.01 -247.864)">
                    <g id="배불뚝이" transform="translate(1245.28 380.096)">
                        <path id="합치기_16" data-name="합치기 16" d="M5504.372-11573.236v-4.441c-.348,0-.7.008-1.045.008-.334,0-.672,0-1.006-.009h-.308v4.442a9.442,9.442,0,0,1-9.442,9.438,9.439,9.439,0,0,1-9.436-9.438v-4.722a9.456,9.456,0,0,1,.281-2.295,51.863,51.863,0,0,1-16.131-8.758,42.984,42.984,0,0,1-4.715-4.955,42.769,42.769,0,0,1-4.554-6.9,42.938,42.938,0,0,1-3.8-10.088,33.009,33.009,0,0,1-15.069-14.112,6.758,6.758,0,0,1,2.664-8,6.211,6.211,0,0,1,3.756-1.279,3.255,3.255,0,0,1,1.4.316,3,3,0,0,1,1.321,1.155c.029.044.058.09.085.137a28.006,28.006,0,0,0,4.8,6.378,53.478,53.478,0,0,1,3.723-16.691,45.793,45.793,0,0,1,10.387-15.557,46.259,46.259,0,0,1,33.05-13.095c.563-.006,1.121-.006,1.681.006v-4.079a5.974,5.974,0,0,1-1.913-1.452,5.981,5.981,0,0,1,.552-8.438,5.987,5.987,0,0,1,6.012-1.115,5.28,5.28,0,0,1,1.408-4.445l1.271,1.45a3.366,3.366,0,0,0-.267,4.075,3.371,3.371,0,0,0,4.671.947l1.267,1.447a5.287,5.287,0,0,1-4.583.818,5.977,5.977,0,0,1-1.9,5.818,5.946,5.946,0,0,1-1.8,1.089v4a48.519,48.519,0,0,1,45.112,49.076q0,1.723-.1,3.381a5.821,5.821,0,0,1,.515.584l.061.069a33.007,33.007,0,0,1,4.92,21.311,6.761,6.761,0,0,1-6.769,5.028c-3.245.027-5.411-1.8-5.187-4.022q.1-.921.144-1.844a38.518,38.518,0,0,1-2.872,4.036,42.6,42.6,0,0,1-19.712,13.263,9.389,9.389,0,0,1,.405,2.666v.009a.565.565,0,0,1,0,.07v4.722c0,.3-.015.607-.044.9a9.442,9.442,0,0,1-9.4,8.535A9.439,9.439,0,0,1,5504.372-11573.236Zm1.978-105.937.015-.015a2.684,2.684,0,0,0,.223-3.791,2.688,2.688,0,0,0-3.791-.223,2.684,2.684,0,0,0-.223,3.791v-.012A2.686,2.686,0,0,0,5506.349-11679.173Z" transform="translate(-5438.73 11691.231)" fill="#d6d6d6" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
                    </g>
                </g>
                <g id="그룹_73468" data-name="그룹 73468" transform="translate(-1436.819 -1088.886)">
                    <g id="배불뚝이-2" data-name="배불뚝이" transform="translate(1596.089 1221.117)">
                        <g id="그룹_73405" data-name="그룹 73405" transform="translate(44.626 104.354)">
                            <path id="사각형_19798" data-name="사각형 19798" d="M1779.617,1628.114h0a9.487,9.487,0,0,1,9.487,9.487v4.744a9.487,9.487,0,0,1-9.487,9.487h0a9.487,9.487,0,0,1-9.487-9.487V1637.6A9.487,9.487,0,0,1,1779.617,1628.114Z" transform="translate(-1770.13 -1628.11)" fill="#f70" />
                            <path id="빼기_3" data-name="빼기 3" d="M1803.691,1651.82a10.062,10.062,0,0,1-1.186-.076,9.485,9.485,0,0,0,8.3-9.411v-4.744a9.486,9.486,0,0,0-8.3-9.415,9.487,9.487,0,0,1,10.6,8.227,9.648,9.648,0,0,1,.074,1.188v4.744A9.487,9.487,0,0,1,1803.691,1651.82Z" transform="translate(-1794.204 -1628.099)" fill="#eb6d00" />
                        </g>
                        <g id="그룹_73404" data-name="그룹 73404" transform="translate(65.972 104.354)">
                            <path id="사각형_19798-2" data-name="사각형 19798-2" d="M1862.866,1628.114h0a9.487,9.487,0,0,1,9.487,9.487v4.744a9.487,9.487,0,0,1-9.487,9.487h0a9.487,9.487,0,0,1-9.487-9.487V1637.6A9.487,9.487,0,0,1,1862.866,1628.114Z" transform="translate(-1853.379 -1628.11)" fill="#f70" />
                            <path id="빼기_3-2" data-name="빼기 3-2" d="M1886.94,1651.82a10.068,10.068,0,0,1-1.186-.076,9.485,9.485,0,0,0,8.3-9.411v-4.744a9.486,9.486,0,0,0-8.3-9.415,9.487,9.487,0,0,1,10.6,8.227,9.7,9.7,0,0,1,.074,1.188v4.744A9.487,9.487,0,0,1,1886.94,1651.82Z" transform="translate(-1877.453 -1628.099)" fill="#eb6d00" />
                        </g>
                        <g id="그룹_73411" data-name="그룹 73411" transform="translate(14.384)">
                            <path id="패스_20692" data-name="패스 20692" d="M1756.636,1347.106a47.424,47.424,0,0,1-4.846,21.79,38.909,38.909,0,0,1-4.487,6.895c-8.864,10.885-23.218,16.38-39.426,16.38q-1.162,0-2.313-.038-1.972-.066-3.9-.238-1.219-.112-2.42-.267c-.4-.051-.8-.108-1.195-.167s-.792-.123-1.187-.19-.785-.136-1.175-.21l-.415-.08q-.373-.073-.746-.154l-.474-.1q-.981-.215-1.949-.464c-.333-.087-.664-.174-.995-.267a52.252,52.252,0,0,1-5.293-1.772c-.169-.067-.338-.135-.508-.2q-.53-.215-1.054-.446c-.151-.067-.3-.133-.453-.2-.167-.074-.331-.149-.495-.226l-.493-.231c-.161-.077-.326-.156-.487-.236l-.485-.241q-.481-.246-.957-.5l-.471-.257q-.469-.261-.934-.531c-.154-.09-.307-.179-.459-.272a40.17,40.17,0,0,1-10.569-9.087,38.242,38.242,0,0,1-3.5-5.092c-.352-.6-.685-1.218-1.005-1.846q-.77-1.512-1.421-3.118-.427-1.042-.8-2.128c-.123-.362-.243-.726-.361-1.095-.057-.185-.116-.37-.172-.557q-.165-.554-.32-1.115c-.052-.192-.1-.387-.154-.579-.151-.572-.29-1.154-.416-1.741-.043-.2-.084-.392-.125-.59q-.185-.888-.333-1.8c-.033-.205-.067-.407-.1-.613-.1-.613-.182-1.236-.251-1.864q-.181-1.523-.272-3.11c0-.031,0-.064-.005-.1-.01-.218-.023-.436-.033-.656-.031-.654-.049-1.313-.057-1.982a.048.048,0,0,0,0-.018c-.005-.226-.005-.451-.005-.679v-.051c-.005-.436-.005-.875,0-1.308a48.764,48.764,0,0,1,47.761-48.074q.838-.015,1.679-.005A48.761,48.761,0,0,1,1756.636,1347.106Z" transform="translate(-1657.338 -1278.036)" fill="#f17000" />
                            <path id="교차_11" data-name="교차 11" d="M1658.858,1347.084a46.487,46.487,0,1,1,92.784,0c0,28.992-20.554,44.158-46.391,45.026C1679.416,1391.239,1658.858,1376.075,1658.858,1347.084Z" transform="translate(-1657.076 -1278.011)" fill="#f70" />
                            <path id="패스_20635" data-name="패스 20635" d="M1692.727,1348.481c0,19.292,17.973,18.025,40.14,18.025s40.14,1.29,40.14-18.025c-.593-22.171-19.041-34.917-41.207-34.329C1710.458,1314.721,1693.291,1327.14,1692.727,1348.481Z" transform="translate(-1682.381 -1290.283)" fill="#071c3a" />
                            <path id="사각형_19793" data-name="사각형 19793" d="M1846.5,1281.617h0c-1.313,0-2.372-.626-2.372-1.395v-5.189c0-.773,1.058-1.395,2.372-1.395h0c1.309,0,2.372.621,2.372,1.395v5.18C1848.873,1280.99,1847.81,1281.617,1846.5,1281.617Z" transform="translate(-1794.913 -1260.171)" fill="#e41134" />
                            <g id="그룹_73405-2" data-name="그룹 73405-2" transform="translate(45.803)">
                                <g id="그룹_73404-2" data-name="그룹 73404-2">
                                    <path id="패스_20636-2" data-name="패스 20636-2" d="M1841.348,1239.14a5.77,5.77,0,1,0,.005.005Zm-6.551,5.74a2.7,2.7,0,1,1,3.811.223l-.016.014h0a2.7,2.7,0,0,1-3.795-.251Z" transform="translate(-1830.82 -1232.997)" fill="#e41134" />
                                    <path id="패스_20637-2" data-name="패스 20637-2" d="M1869.305,1227.62a3.387,3.387,0,0,1-4.426-5.047l-1.276-1.456a5.313,5.313,0,0,0,6.978,7.96Z" transform="translate(-1854.1 -1221.117)" fill="url(#linear-gradient)" />
                                </g>
                            </g>
                            <g id="그룹_73409" data-name="그룹 73409" transform="translate(30.958 38.584)">
                                <g id="그룹_73408" data-name="그룹 73408">
                                    <g id="그룹_73406" data-name="그룹 73406">
                                        <rect id="사각형_19794" data-name="사각형 19794" width="7.528" height="14.216" rx="3.764" fill="#fff" />
                                    </g>
                                    <rect id="사각형_19795" data-name="사각형 19795" width="7.528" height="14.216" rx="3.764" transform="translate(30.525)" fill="#fff" />
                                    <g id="그룹_73407" data-name="그룹 73407" transform="translate(10.112 16.9)">
                                        <g id="패스_20638" data-name="패스 20638">
                                            <path id="패스_20693" data-name="패스 20693" d="M1821.275,1447.7h0a8.96,8.96,0,0,1-8.91-8.911,1.281,1.281,0,0,1,1.282-1.286h15.264a1.282,1.282,0,0,1,1.282,1.286,8.966,8.966,0,0,1-8.91,8.91Zm-6.215-7.632a6.344,6.344,0,0,0,12.429,0Z" transform="translate(-1812.36 -1437.505)" fill="#fff" />
                                        </g>
                                    </g>
                                </g>
                            </g>
                            <ellipse id="타원_4138" data-name="타원 4138" cx="5.872" cy="2.851" rx="5.872" ry="2.851" transform="matrix(0.655, -0.755, 0.755, 0.655, 15.47, 43.294)" fill="#414d5b" />
                            <path id="패스_20694" data-name="패스 20694" d="M1702.169,1392.147h-1.185a52,52,0,0,1-34.482-11.39,43.038,43.038,0,0,1-14.276-33.905,53.8,53.8,0,0,1,3.833-20.415A46,46,0,0,1,1666.5,1310.8a46.5,46.5,0,0,1,33.3-13.159c.128,0,.256,0,.385,0h1.077a46.286,46.286,0,0,0-43.272,29.341c-.005.01-.01.023-.015.036a48.77,48.77,0,0,0-3.41,18c0,.608.01,1.215.033,1.821a47.538,47.538,0,0,0,5.01,21.995,39.9,39.9,0,0,0,4.546,6.938c8.048,9.872,20.8,15.782,36.79,16.321.405.016.815.026,1.225.033Z" transform="translate(-1652.187 -1278.021)" fill="#ff9f4d" />
                            <path id="패스_20695" data-name="패스 20695" d="M1762.688,1579.345a39,39,0,0,1-4.405,6.895h-78.917a43.009,43.009,0,0,1-4.493-6.938h87.307A3.016,3.016,0,0,1,1762.688,1579.345Z" transform="translate(-1669.056 -1487.459)" fill="#9c1134" />
                            <path id="패스_20696" data-name="패스 20696" d="M1761.133,1575.345a38.908,38.908,0,0,1-4.487,6.895h-80.39a42.948,42.948,0,0,1-4.577-6.938h88.935A3.127,3.127,0,0,1,1761.133,1575.345Z" transform="translate(-1666.681 -1484.485)" fill="#e41134" />
                        </g>
                        <g id="그룹_73464" data-name="그룹 73464" transform="translate(0 57.163)">
                            <path id="패스_20636-3" data-name="패스 20636-3" d="M1599.183,1445.343h0c2.655-1.892,5.493-1.635,6.586.32a27.988,27.988,0,0,0,13.783,12.411c2.065.883,2.611,3.679,1.007,6.517h0a6.788,6.788,0,0,1-7.716,3.5,33.161,33.161,0,0,1-16.335-14.709A6.788,6.788,0,0,1,1599.183,1445.343Z" transform="translate(-1596.089 -1444.053)" fill="#f70" />
                        </g>
                        <g id="그룹_73465" data-name="그룹 73465" transform="translate(102.46 70.77)">
                            <path id="패스_20637-3" data-name="패스 20637-3" d="M2005.527,1525.944h0c-3.26.028-5.437-1.812-5.212-4.04a27.989,27.989,0,0,0-4.172-18.072c-1.185-1.907-.029-4.511,2.914-5.916h0a6.788,6.788,0,0,1,8.329,1.558,33.161,33.161,0,0,1,4.945,21.418A6.788,6.788,0,0,1,2005.527,1525.944Z" transform="translate(-1995.684 -1497.12)" fill="#f70" />
                        </g>
                        <g id="그룹_73466" data-name="그룹 73466" transform="translate(107.955 70.786)">
                            <path id="빼기_4-2" data-name="빼기 4-2" d="M2021.445,1525.962h-.072a7.564,7.564,0,0,1-1.209-.09,6.569,6.569,0,0,0,5.693-4.957,33.42,33.42,0,0,0-4.948-21.427,5.346,5.346,0,0,0-3.794-2.2,7.869,7.869,0,0,1,1.19-.091,5.805,5.805,0,0,1,4.985,2.3,33.376,33.376,0,0,1,4.943,21.427A6.761,6.761,0,0,1,2021.445,1525.962Z" transform="translate(-2017.116 -1497.182)" fill="#e86b00" />
                        </g>
                        <g id="그룹_73467" data-name="그룹 73467" transform="translate(4.126 57.167)">
                            <path id="빼기_5-2" data-name="빼기 5-2" d="M1626.465,1468.428a4.164,4.164,0,0,1-1.651-.317l-.593-.257a7.9,7.9,0,0,0,3.567-3.245,5.8,5.8,0,0,0,.716-4.612,3.09,3.09,0,0,0-1.726-1.9A28.15,28.15,0,0,1,1613,1445.69a2.992,2.992,0,0,0-.82-.95,5.807,5.807,0,0,1,2.59-.669,3.266,3.266,0,0,1,2.974,1.612,28.148,28.148,0,0,0,13.779,12.41,3.065,3.065,0,0,1,1.725,1.9,5.8,5.8,0,0,1-.716,4.612A7.543,7.543,0,0,1,1626.465,1468.428Z" transform="translate(-1612.179 -1444.068)" fill="#f06f00" />
                        </g>
                    </g>
                    <rect id="사각형_19815" data-name="사각형 19815" width="34.435" height="20.033" rx="5" transform="translate(1643.819 1305.02)" fill="#9c1134" />
                    <rect id="사각형_19816" data-name="사각형 19816" width="34.435" height="20.033" rx="5" transform="translate(1643.819 1304.185)" fill="#e41134" />
                    <text id="rpa" transform="translate(1647.177 1320.036)" fill="#fff" fontSize="15" fontFamily="Amboy-Black, Amboy" fontWeight="800"><tspan x="0" y="0">rpa</tspan></text>
                </g>
            </g>
            <line id="선_2036" data-name="선 2036" x2="115" transform="translate(268.5 197.422)" fill="none" stroke="#d6d6d6" strokeLinecap="round" strokeWidth="1" />
        </g>
    </svg>
)

const ReportSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.333" height="17" viewBox="0 0 13.333 17">
        <g id="다운" transform="translate(-3.333 -1.333)" opacity="0.6">
            <line id="선_531" data-name="선 531" x2="6.667" transform="translate(6.667 9.167)" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="1.5" />
            <line id="선_532" data-name="선 532" x2="5" transform="translate(6.667 12.5)" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="1.5" />
            <path id="패스_10136" data-name="패스 10136" d="M12.5,3.333h3.333V17.5H4.167V3.333H7.5" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="1.667" />
            <rect id="사각형_5912" data-name="사각형 5912" width="5" height="2.5" transform="translate(7.5 2.083)" fill="none" stroke="#333" strokeMiterlimit="10" strokeWidth="1.5" />
        </g>
    </svg>
);

const LinkSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="10" viewBox="0 0 20 10">
        <g id="Outlined_Formatting_link" transform="translate(-2 -7)">
            <path id="패스_10199" data-name="패스 10199" d="M11,8H7a4,4,0,0,0,0,8h4" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
            <path id="패스_10200" data-name="패스 10200" d="M13,8h4a4,4,0,0,1,0,8H13" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
            <line id="선_1992" data-name="선 1992" x2="8" transform="translate(8 12)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const SquareSVG = () => (
    <svg id="ico_handler" xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" >
        <rect id="사각형_1400" data-name="사각형 1400" width="2" height="2" rx="1" transform="translate(4)" fill="#858791" />
        <rect id="사각형_1401" data-name="사각형 1401" width="2" height="2" rx="1" transform="translate(4 4)" fill="#858791" />
        <rect id="사각형_1402" data-name="사각형 1402" width="2" height="2" rx="1" transform="translate(4 8)" fill="#858791" />
        <rect id="사각형_1403" data-name="사각형 1403" width="2" height="2" rx="1" transform="translate(0 8)" fill="#858791" />
        <rect id="사각형_1404" data-name="사각형 1404" width="2" height="2" rx="1" transform="translate(0 4)" fill="#858791" />
        <rect id="사각형_1405" data-name="사각형 1405" width="2" height="2" rx="1" fill="#858791" />
        <rect id="사각형_1477" data-name="사각형 1477" width="2" height="2" rx="1" transform="translate(8)" fill="#858791" />
        <rect id="사각형_1478" data-name="사각형 1478" width="2" height="2" rx="1" transform="translate(8 4)" fill="#858791" />
        <rect id="사각형_1479" data-name="사각형 1479" width="2" height="2" rx="1" transform="translate(8 8)" fill="#858791" />
    </svg>
)

const ScheInfoSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
        <g id="스케줄_information" transform="translate(-2.75 -2.25)">
            <path id="패스_696" data-name="패스 696" d="M11.557,15.278V11H10.005" transform="translate(-1.022 -1.739)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="1.5" />
            <path id="패스_697" data-name="패스 697" d="M9.49,16.424" transform="translate(-1.526 -2.983)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="2" />
            <circle id="타원_148" data-name="타원 148" cx="0.972" cy="0.972" r="0.972" transform="translate(9.563 5.566)" fill="#e10013" />
            <line id="선_498" data-name="선 498" x2="4.667" transform="translate(8.167 13.5)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="1.5" />
            <circle id="타원_149" data-name="타원 149" cx="7" cy="7" r="7" transform="translate(3.5 3)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="1.5" />
        </g>
    </svg>
)

const InfoSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5">
        <g id="Outlined_Status_information" transform="translate(-2.75 -2.25)">
            <path id="패스_696" data-name="패스 696" d="M11.335,14.667V11h-1.33" transform="translate(-1.805 -2.634)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="1.5" />
            <path id="패스_697" data-name="패스 697" d="M9.49,16.424" transform="translate(-2.163 -4.475)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="2" />
            <circle id="타원_148" data-name="타원 148" cx="0.833" cy="0.833" r="0.833" transform="translate(8.697 5.2)" fill="#e10013" />
            <line id="선_498" data-name="선 498" x2="4" transform="translate(7.5 12)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="1.5" />
            <circle id="타원_149" data-name="타원 149" cx="6" cy="6" r="6" transform="translate(3.5 3)" fill="none" stroke="#e10013" strokeMiterlimit="10" strokeWidth="1.5" />
        </g>
    </svg>
)

const ProcessingSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.698" height="18.698" viewBox="0 0 18.698 18.698">
        <g id="진행중" transform="translate(1 0.954)">
            <path id="패스_10108" data-name="패스 10108" d="M8.349,0A8.349,8.349,0,1,1,0,8.349,8.349,8.349,0,0,1,8.349,0Z" transform="translate(0 0.046)" fill="none" stroke="#2b65d9" strokeWidth="2" />
            <path id="패스_10107" data-name="패스 10107" d="M12,2.93v8.341l5.937,5.852a8.258,8.258,0,0,0,2.412-5.844A8.354,8.354,0,0,0,12,2.93Z" transform="translate(-3.652 -2.93)" fill="#2b65d9" />
        </g>
    </svg>
)

const SuccessSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
        <g id="Outlined_Status_checkmark_circle" transform="translate(-2 -2)">
            <path id="패스_802" data-name="패스 802" d="M9,0A9,9,0,1,1,0,9,9,9,0,0,1,9,0Z" transform="translate(3 3)" fill="#3dba61" stroke="#3dba61" strokeWidth="2" />
            <path id="패스_694" data-name="패스 694" d="M7,12.5l3,3,7-7" fill="rgba(61,186,97,0)" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.8" />
        </g>
    </svg>
);

const FailedSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
        <g id="Outlined_Status_misuse" transform="translate(-2 -2)">
            <circle id="타원_150" data-name="타원 150" cx="9" cy="9" r="9" transform="translate(3 3)" fill="#e10013" stroke="#e10013" strokeMiterlimit="10" strokeWidth="2" />
            <line id="선_499" data-name="선 499" x2="8" y2="8" transform="translate(8 8)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.8" />
            <line id="선_500" data-name="선 500" x1="8" y2="8" transform="translate(8 8)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1.8" />
        </g>
    </svg>
)

const BlackSuccessSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
        <g id="Outlined_Status_checkmark_circle" transform="translate(-2 -2)">
            <path id="패스_694" data-name="패스 694" d="M7,12.5l3,3,7-7" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="1.8" />
            <circle id="타원_145" data-name="타원 145" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const BlackFailedSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
        <g id="Outlined_Status_misuse" transform="translate(-2 -2)">
            <line id="선_499" data-name="선 499" x2="8" y2="8" transform="translate(8 8)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="1.8" />
            <line id="선_500" data-name="선 500" x1="8" y2="8" transform="translate(8 8)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="1.8" />
            <circle id="타원_150" data-name="타원 150" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const BlackRequestSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
        <g id="Outlined_Status_help" transform="translate(-2 -2)">
            <line id="선_497" data-name="선 497" y1="2" transform="translate(12 15)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="1.8" />
            <circle id="타원_147" data-name="타원 147" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="2" />
            <path id="패스_695" data-name="패스 695" d="M9.8,9.682A2.142,2.142,0,0,1,12,7.5a2.089,2.089,0,0,1,2.2,2.182c-.088,1.175-1.244,1.451-1.753,2C12,12.168,12,12.665,12,13.5" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="1.8" />
        </g>
    </svg>
)
const BlackRejectSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
        <g id="Outlined_Status_error" transform="translate(-2 -2)">
            <line id="선_494" data-name="선 494" x1="12" y2="12" transform="translate(6 6)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="2" />
            <circle id="타원_146" data-name="타원 146" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#707070" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const MonitoringSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.584" height="18" viewBox="0 0 18.584 18">
        <g id="Outlined_Technology_devices" transform="translate(-2 -3)">
            <g id="그룹_73159" data-name="그룹 73159" transform="translate(-1796 -1337)">
                <g id="패스_10259" data-name="패스 10259" transform="translate(1806.702 1343.702)" fill="none">
                    <path className='monitoring1' d="M4.3,0A4.3,4.3,0,1,1,0,4.3,4.3,4.3,0,0,1,4.3,0Z" stroke="#E2E2E3" />
                    <path className='monitoring2' d="M 4.2978515625 2.000001430511475 C 3.030811786651611 2.000001430511475 2.000001430511475 3.030811786651611 2.000001430511475 4.2978515625 C 2.000001430511475 5.564891815185547 3.030811786651611 6.595701217651367 4.2978515625 6.595701217651367 C 5.564891815185547 6.595701217651367 6.595701217651367 5.564891815185547 6.595701217651367 4.2978515625 C 6.595701217651367 3.030811786651611 5.564891815185547 2.000001430511475 4.2978515625 2.000001430511475 M 4.2978515625 1.9073486328125e-06 C 6.671491622924805 1.9073486328125e-06 8.595701217651367 1.924211502075195 8.595701217651367 4.2978515625 C 8.595701217651367 6.671491622924805 6.671491622924805 8.595701217651367 4.2978515625 8.595701217651367 C 1.924211502075195 8.595701217651367 1.9073486328125e-06 6.671491622924805 1.9073486328125e-06 4.2978515625 C 1.9073486328125e-06 1.924211502075195 1.924211502075195 1.9073486328125e-06 4.2978515625 1.9073486328125e-06 Z" stroke="#E2E2E3" fill="#E2E2E3" />
                </g>
                <path id="패스_10258" data-name="패스 10258" d="M-10965.332-3017.016v-4.232" transform="translate(11983.448 -3741.464) rotate(-42)" fill="none" stroke="#E2E2E3" strokeLinejoin="round" strokeWidth="2" />
            </g>
            <path id="패스_10255" data-name="패스 10255" d="M7,20h4V16H3V4H19V7" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const LogSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13.591" height="17.216" viewBox="0 0 13.591 17.216">
        <g id="log" transform="translate(1 0.9)">
            <line id="선_491" data-name="선 491" x2="6.623" transform="translate(2.484 7.037)" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="1.8" />
            <line id="선_492" data-name="선 492" x2="4.967" transform="translate(2.484 10.349)" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="1.8" />
            <path id="패스_10112" data-name="패스 10112" d="M13.279,4h3.312V18.075H5V4H8.312" transform="translate(-5 -2.758)" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="2" />
            <rect id="사각형_5861" data-name="사각형 5861" width="4.967" height="2.484" transform="translate(3.312)" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="1.8" />
        </g>
    </svg>
)

const RestartSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.135" height="14.566" viewBox="0 0 17.135 14.566">
        <g id="retry" transform="translate(0.636 1)">
            <path id="패스_10113" data-name="패스 10113" d="M8.364,14.25,5.432,17.182,2.5,14.25" transform="translate(-2.5 -6.082)" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="1.8" />
            <path id="패스_10114" data-name="패스 10114" d="M12.283,17.066A6.283,6.283,0,1,0,6,10.783v4.775" transform="translate(-3.068 -4.5)" fill="none" stroke="#E2E2E3" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

// 기존
const OpenCollapseSVG = () => (
    <svg id="down" xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#ffa200" />
                <stop offset="1" stopColor="#ff6f00" />
            </linearGradient>
        </defs>
        <rect id="사각형_19767" data-name="사각형 19767" width="30" height="25" rx="5" fill="url(#linear-gradient)" />
        <g id="_24x24" data-name="24x24" transform="translate(5 3)">
            <rect id="_24x24-2" data-name="24x24" width="20" height="20" fill="none" />
        </g>
        <g id="Icon" transform="translate(7 9)">
            <path id="Fill_1" data-name="Fill 1" d="M1.123,0,0,1.144,7.708,9l7.706-7.848L14.291.007l-6.584,6.7Z" fill="#fff" />
        </g>
    </svg>
)

const CloseCollapseSVG = () => (
    <svg id="Icon_Outlined_20px_Down" data-name="Icon/Outlined/20px/Down" xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#ffa200" />
                <stop offset="1" stopColor="#ff6f00" />
            </linearGradient>
        </defs>
        <rect id="사각형_19767" data-name="사각형 19767" width="30" height="25" rx="5" fill="url(#linear-gradient)" />
        <g id="_24x24" data-name="24x24" transform="translate(5 3)">
            <rect id="_24x24-2" data-name="24x24" width="20" height="20" fill="none" />
        </g>
        <g id="Icon" transform="translate(22.414 18) rotate(180)">
            <path id="Fill_1" data-name="Fill 1" d="M1.123,0,0,1.144,7.708,9l7.706-7.848L14.291.007l-6.584,6.7Z" fill="#fff" />
        </g>
    </svg>
);

// // 우리카드
// const OpenCollapseSVG = () => (
//     <svg id="down" xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25">
//         <defs>
//             <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
//                 <stop offset="0" stopColor="#9bc5e0" />
//                 <stop offset="1" stopColor="#0067ac" />
//             </linearGradient>
//         </defs>
//         <rect id="사각형_19767" data-name="사각형 19767" width="30" height="25" rx="5" fill="url(#linear-gradient)" />
//         <g id="_24x24" data-name="24x24" transform="translate(5 3)">
//             <rect id="_24x24-2" data-name="24x24" width="20" height="20" fill="none" />
//         </g>
//         <g id="Icon" transform="translate(7 9)">
//             <path id="Fill_1" data-name="Fill 1" d="M1.123,0,0,1.144,7.708,9l7.706-7.848L14.291.007l-6.584,6.7Z" fill="#fff" />
//         </g>
//     </svg>
// )

// const CloseCollapseSVG = () => (
//     <svg id="Icon_Outlined_20px_Down" data-name="Icon/Outlined/20px/Down" xmlns="http://www.w3.org/2000/svg" width="30" height="25" viewBox="0 0 30 25">
//         <defs>
//             <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
//                 <stop offset="0" stopColor="#9bc5e0" />
//                 <stop offset="1" stopColor="#0067ac" />
//             </linearGradient>
//         </defs>
//         <rect id="사각형_19767" data-name="사각형 19767" width="30" height="25" rx="5" fill="url(#linear-gradient)" />
//         <g id="_24x24" data-name="24x24" transform="translate(5 3)">
//             <rect id="_24x24-2" data-name="24x24" width="20" height="20" fill="none" />
//         </g>
//         <g id="Icon" transform="translate(22.414 18) rotate(180)">
//             <path id="Fill_1" data-name="Fill 1" d="M1.123,0,0,1.144,7.708,9l7.706-7.848L14.291.007l-6.584,6.7Z" fill="#fff" />
//         </g>
//     </svg>
// );

const RightTriangleSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.444" height="27" viewBox="0 0 21.444 27">
        <path id="다각형_6" data-name="다각형 6" d="M13.5,0,27,21.444H0Z" transform="translate(21.444) rotate(90)" fill="#fff" />
    </svg>
);

const EyeSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.942" height="11.85" viewBox="0 0 19.942 11.85">
        <g id="그룹_73205" data-name="그룹 73205" transform="translate(-749.128 -552.026)">
            <path id="패스_10265" data-name="패스 10265" d="M768.57,557.951s-4.24,5.425-9.471,5.425-9.471-5.425-9.471-5.425,4.24-5.425,9.471-5.425S768.57,557.951,768.57,557.951Z" fill="#515151" stroke="#515151" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" />
            <circle id="타원_142" data-name="타원 142" cx="5.425" cy="5.425" r="5.425" transform="translate(753.674 552.526)" fill="#fff" stroke="#515151" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="1" />
            <circle id="타원_143" data-name="타원 143" cx="2.836" cy="2.836" r="2.836" transform="translate(756.263 555.115)" fill="#515151" stroke="#515151" strokeLinecap="round" strokeLinejoin="bevel" strokeWidth="1" />
            <circle id="타원_144" data-name="타원 144" cx="0.836" cy="0.836" r="0.836" transform="translate(760.264 555.115)" fill="#fff" />
        </g>
    </svg>
);

const BasicLetterSVG = () => (
    <svg id="Badge_rounded_Count03" data-name="Badge/rounded/Count03" xmlns="http://www.w3.org/2000/svg" width="36" height="20" viewBox="0 0 36 20">
        <g id="badge" transform="translate(2 -2.5)">
            <rect id="Rectangle" width="36" height="20" rx="10" transform="translate(-2 2.5)" fill="#fcaa1d" />
        </g>
        <g id="그룹_73499" data-name="그룹 73499" transform="translate(-1.565 -2)">
            <text id="기본" transform="translate(9.565 16.5)" fill="#fff" fontSize="10" fontFamily="NotoSansCJKkr-Bold, Noto Sans CJK KR" fontWeight="700"><tspan x="0" y="0">기본</tspan></text>
        </g>
    </svg>
);


const DeleteSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
        <g id="Icon_Outlined_24px_Delete" data-name="Icon/Outlined/24px/Delete">
            <g id="_24x24" data-name="24x24">
                <rect id="_24x24-2" data-name="24x24" width="24" height="24" fill="none" />
            </g>
            <g id="Icon" transform="translate(4 2.5)">
                <path id="Fill_1" data-name="Fill 1" d="M0,2H6V0H0Z" transform="translate(5)" />
                <path id="Fill_2" data-name="Fill 2" d="M0,2H16V0H0Z" transform="translate(0 3)" />
                <path id="Fill_3" data-name="Fill 3" d="M14,16H0V0H14V16ZM2,2V14H12V2Z" transform="translate(1 3)" />
                <path id="Fill_4" data-name="Fill 4" d="M0,9H1.8V0H0Z" transform="translate(5.099 6.5)" />
                <path id="Fill_5" data-name="Fill 5" d="M0,9H1.8V0H0Z" transform="translate(9.099 6.5)" />
            </g>
        </g>
    </svg>
);

const AddFileSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Outlined_File_document_add" transform="translate(-2.5 1.9)" opacity="0.6">
            <rect id="_20x20" data-name="20x20" width="20" height="20" transform="translate(2.5 -1.9)" fill="none" />
            <g id="그룹_73348" data-name="그룹 73348" transform="translate(2 -3.9)">
                <line id="선_2006" data-name="선 2006" x2="7" transform="translate(2.5 8)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.8" />
                <line id="선_2007" data-name="선 2007" y1="7" transform="translate(6 4.5)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.8" />
                <path id="패스_10253" data-name="패스 10253" d="M10,3h9V14.7L12.875,21H5V14" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
                <path id="패스_10254" data-name="패스 10254" d="M13,21V15h6" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.8" />
            </g>
        </g>
    </svg>
);

const ArrowNextSVG = () => (
    <svg id="Icon_Outlined_16px_RIght" data-name="Icon/Outlined/16px/RIght" xmlns="http://www.w3.org/2000/svg" width="14.117" height="24.626" viewBox="0 0 14.117 24.626">
        <path id="Fill_1" data-name="Fill 1" d="M1.805,0,0,1.807l10.5,10.5L0,22.819l1.805,1.807L14.117,12.312Z" transform="translate(0)" fill="#8f9094" />
    </svg>
);

const ArrowPrevSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.117" height="24.626" viewBox="0 0 14.117 24.626">
        <path id="Fill_1" data-name="Fill 1" d="M12.312,0,0,12.312,12.312,24.626l1.805-1.807L3.612,12.312l10.5-10.5Z" fill="#8f9094" />
    </svg>
);

const SlideDotSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9">
        <circle id="dots" cx="4.5" cy="4.5" r="4.5" fill='current' />
    </svg>
);

const PauseSVG = () => (
    <svg id="일시정지" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <path id="패스_10108" data-name="패스 10108" d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z" fill="#f70" />
        <g id="그룹_73410" data-name="그룹 73410" transform="translate(15 15)">
            <rect id="사각형_19804" data-name="사각형 19804" width="8" height="20" fill="#fff" />
            <rect id="사각형_19805" data-name="사각형 19805" width="8" height="20" transform="translate(12)" fill="#fff" />
        </g>
    </svg>
);

const MonitoringReplaySVG = () => (
    <svg id="진행중" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
        <path id="패스_10108" data-name="패스 10108" d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z" fill="#f70" />
        <path id="다각형_6" data-name="다각형 6" d="M8,0l8,12H0Z" transform="translate(32 9) rotate(90)" fill="#fff" />
        <text id="Replay" transform="translate(9 38.184)" fill="#fff" fontSize="10" fontFamily="NotoSansCJKkr-Regular, Noto Sans CJK KR"><tspan x="0" y="0">Replay</tspan></text>
    </svg>
);

// 기존
const CoeSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21.756" height="22.786" viewBox="0 0 21.756 22.786">
        <defs>
            <filter id="패스_21000" x="13.727" y="14.757" width="8.029" height="8.029" filterUnits="userSpaceOnUse">
                <feOffset dx="-1" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="0.5" result="blur" />
                <feFlood floodColor="#a86504" floodOpacity="0.2" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="그룹_74680" data-name="그룹 74680" transform="translate(-236.535 0)">
            <path id="패스_20999" data-name="패스 20999" d="M299.089,110.258a2.68,2.68,0,0,0-2.68-2.68H280.483a2.68,2.68,0,0,0-2.68,2.68v15.926a2.68,2.68,0,0,0,2.68,2.68H294.06l5.029-5.029Z" transform="translate(-41.268 -107.578)" fill="#784AC8" />
            <g transform="matrix(1, 0, 0, 1, 236.54, 0)" filter="url(#패스_21000)">
                <path id="패스_21000-2" data-name="패스 21000" d="M336.94,161.787h-2.349a2.68,2.68,0,0,0-2.68,2.68v2.349Z" transform="translate(-315.68 -145.53)" fill="#ffc380" />
            </g>
            <g id="그룹_74679" data-name="그룹 74679" transform="translate(238.677 7.189)">
                <path id="패스_21001" data-name="패스 21001" d="M284.943,134.98a3.133,3.133,0,0,1,3.095-3.432,2.733,2.733,0,0,1,1.952.843l-.7.834a1.726,1.726,0,0,0-1.224-.55c-1.008,0-1.763.851-1.763,2.261,0,1.428.684,2.288,1.736,2.288a1.861,1.861,0,0,0,1.377-.647l.7.816a2.71,2.71,0,0,1-2.123.967C286.311,138.36,284.943,137.171,284.943,134.98Z" transform="translate(-284.943 -131.548)" fill="#fff" />
                <path id="패스_21002" data-name="패스 21002" d="M303.483,134.927c0-2.138,1.215-3.379,2.978-3.379s2.978,1.251,2.978,3.379-1.214,3.433-2.978,3.433S303.483,137.056,303.483,134.927Zm4.6,0c0-1.41-.63-2.253-1.619-2.253s-1.619.842-1.619,2.253.63,2.3,1.619,2.3S308.08,136.328,308.08,134.927Z" transform="translate(-297.91 -131.548)" fill="#fff" />
                <path id="패스_21003" data-name="패스 21003" d="M327.693,131.968h4.084v1.09h-2.753v1.516h2.339v1.1h-2.339v1.755h2.852v1.1h-4.183Z" transform="translate(-314.847 -131.842)" fill="#fff" />
            </g>
        </g>
    </svg>
)

const DiySVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="21.5" viewBox="0 0 20.5 21.5">
        <defs>
            <filter id="패스_21005" x="12.775" y="13.732" width="7.725" height="7.767" filterUnits="userSpaceOnUse">
                <feOffset dx="-1" input="SourceAlpha" />
                <feGaussianBlur stdDeviation="0.5" result="blur" />
                <feFlood floodColor="#1b6b9a" />
                <feComposite operator="in" in2="blur" />
                <feComposite in="SourceGraphic" />
            </filter>
        </defs>
        <g id="그룹_74683" data-name="그룹 74683" transform="translate(-308.232 0)"><path id="패스_21004" data-name="패스 21004" d="M383.294,109.893a2.668,2.668,0,0,0-2.68-2.656H364.688a2.668,2.668,0,0,0-2.68,2.656v15.785a2.668,2.668,0,0,0,2.68,2.656h13.577l5.029-4.984Z" transform="translate(-53.776 -107.237)" fill="#1B7BB3"></path><g transform="matrix(1, 0, 0, 1, 308.23, 0)" filter="url(#패스_21005)"><path id="패스_21005-2" data-name="패스 21005" d="M420.685,161.787h-2.349a2.68,2.68,0,0,0-2.68,2.68v2.349Z" transform="translate(-399.4 -145.72)" fill="#d5530f"></path></g><g id="그룹_74682" data-name="그룹 74682" transform="translate(311.947 7.314)"><path id="패스_21006" data-name="패스 21006" d="M374.4,131.627h1.826a2.96,2.96,0,0,1,3.293,3.3c0,2.24-1.259,3.356-3.221,3.356h-1.9Zm1.745,5.587c1.241,0,2.015-.666,2.015-2.285s-.774-2.24-2.015-2.24h-.414v4.525Z" transform="translate(-374.398 -131.627)" fill="#fff"></path><path id="패스_21007" data-name="패스 21007" d="M395.818,131.627h1.332v6.657h-1.332Z" transform="translate(-389.394 -131.627)" fill="#fff"></path><path id="패스_21008" data-name="패스 21008" d="M404.8,135.846l-2-4.219h1.412l.666,1.619c.189.495.369.963.576,1.475h.036c.207-.513.4-.981.594-1.475l.666-1.619h1.385l-2.006,4.219v2.438H404.8Z" transform="translate(-394.287 -131.627)" fill="#fff"></path></g></g>
    </svg>
);

// 우리카드
// const CoeSVG = () => (
//         <svg xmlns="http://www.w3.org/2000/svg" width="21.756" height="22.786" viewBox="0 0 21.756 22.786">
//             <defs>
//                 <filter id="패스_21000" x="13.727" y="14.757" width="8.029" height="8.029" filterUnits="userSpaceOnUse">
//                     <feOffset dx="-1" input="SourceAlpha" />
//                     <feGaussianBlur stdDeviation="0.5" result="blur" />
//                     <feFlood floodColor="#0067ac" floodOpacity="0.2" />
//                     <feComposite operator="in" in2="blur" />
//                     <feComposite in="SourceGraphic" />
//                 </filter>
//             </defs>
//             <g id="그룹_74680" data-name="그룹 74680" transform="translate(-236.535 0)">
//                 <path id="패스_20999" data-name="패스 20999" 
//                     d="M299.089,110.258a2.68,2.68,0,0,0-2.68-2.68H280.483a2.68,2.68,0,0,0-2.68,2.68v15.926a2.68,2.68,0,0,0,2.68,2.68H294.06l5.029-5.029Z" 
//                     transform="translate(-41.268 -107.578)" fill="#0067ac" />
//                 <g transform="matrix(1, 0, 0, 1, 236.54, 0)" filter="url(#패스_21000)">
//                     <path id="패스_21000-2" data-name="패스 21000" d="M336.94,161.787h-2.349a2.68,2.68,0,0,0-2.68,2.68v2.349Z" transform="translate(-315.68 -145.53)" fill="#22e3a6" />
//                 </g>
//                 <g id="그룹_74679" data-name="그룹 74679" transform="translate(238.677 7.189)">
//                     <path id="패스_21001" data-name="패스 21001" d="M284.943,134.98a3.133,3.133,0,0,1,3.095-3.432,2.733,2.733,0,0,1,1.952.843l-.7.834a1.726,1.726,0,0,0-1.224-.55c-1.008,0-1.763.851-1.763,2.261,0,1.428.684,2.288,1.736,2.288a1.861,1.861,0,0,0,1.377-.647l.7.816a2.71,2.71,0,0,1-2.123.967C286.311,138.36,284.943,137.171,284.943,134.98Z" transform="translate(-284.943 -131.548)" fill="#fff" />
//                     <path id="패스_21002" data-name="패스 21002" d="M303.483,134.927c0-2.138,1.215-3.379,2.978-3.379s2.978,1.251,2.978,3.379-1.214,3.433-2.978,3.433S303.483,137.056,303.483,134.927Zm4.6,0c0-1.41-.63-2.253-1.619-2.253s-1.619.842-1.619,2.253.63,2.3,1.619,2.3S308.08,136.328,308.08,134.927Z" transform="translate(-297.91 -131.548)" fill="#fff" />
//                     <path id="패스_21003" data-name="패스 21003" d="M327.693,131.968h4.084v1.09h-2.753v1.516h2.339v1.1h-2.339v1.755h2.852v1.1h-4.183Z" transform="translate(-314.847 -131.842)" fill="#fff" />
//                 </g>
//             </g>
//         </svg>
// );

// const DiySVG = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" width="20.5" height="21.5" viewBox="0 0 20.5 21.5">
//         <defs>
//             <filter id="패스_21005" x="12.775" y="13.732" width="7.725" height="7.767" filterUnits="userSpaceOnUse">
//                 <feOffset dx="-1" input="SourceAlpha" />
//                 <feGaussianBlur stdDeviation="0.5" result="blur" />
//                 <feFlood floodColor="#00355d" />
//                 <feComposite operator="in" in2="blur" />
//                 <feComposite in="SourceGraphic" />
//             </filter>
//         </defs>
//         <g id="그룹_74683" data-name="그룹 74683" transform="translate(-308.232 0)">
//             <path id="패스_21004" 
//                 data-name="패스 21004" d="M383.294,109.893a2.668,2.668,0,0,0-2.68-2.656H364.688a2.668,2.668,0,0,0-2.68,2.656v15.785a2.668,2.668,0,0,0,2.68,2.656h13.577l5.029-4.984Z" 
//                 transform="translate(-53.776 -107.237)" fill="#00355d">
//             </path>
//         <g transform="matrix(1, 0, 0, 1, 308.23, 0)" filter="url(#패스_21005)">
//             <path id="패스_21005-2" data-name="패스 21005" d="M420.685,161.787h-2.349a2.68,2.68,0,0,0-2.68,2.68v2.349Z" transform="translate(-399.4 -145.72)" fill="#22e3a6"></path>
//         </g><g id="그룹_74682" data-name="그룹 74682" transform="translate(311.947 7.314)"><path id="패스_21006" data-name="패스 21006" d="M374.4,131.627h1.826a2.96,2.96,0,0,1,3.293,3.3c0,2.24-1.259,3.356-3.221,3.356h-1.9Zm1.745,5.587c1.241,0,2.015-.666,2.015-2.285s-.774-2.24-2.015-2.24h-.414v4.525Z" transform="translate(-374.398 -131.627)" fill="#fff"></path><path id="패스_21007" data-name="패스 21007" d="M395.818,131.627h1.332v6.657h-1.332Z" transform="translate(-389.394 -131.627)" fill="#fff"></path><path id="패스_21008" data-name="패스 21008" d="M404.8,135.846l-2-4.219h1.412l.666,1.619c.189.495.369.963.576,1.475h.036c.207-.513.4-.981.594-1.475l.666-1.619h1.385l-2.006,4.219v2.438H404.8Z" transform="translate(-394.287 -131.627)" fill="#fff"></path></g></g>
//     </svg>
// );

const PopupProgressSVG = () => (
    <svg id="진행중" xmlns="http://www.w3.org/2000/svg" width="16.698" height="16.698" viewBox="0 0 16.698 16.698">
        <path id="패스_10108" data-name="패스 10108" d="M8.349,0A8.349,8.349,0,1,1,0,8.349,8.349,8.349,0,0,1,8.349,0Z" fill="#2b65d9" />
        <path id="다각형_6" data-name="다각형 6" d="M3.5,0,7,5.444H0Z" transform="translate(11.444 5) rotate(90)" fill="#fff" />
    </svg>
);

const PopupWaitSVG = () => (
    <svg id="레이어_1" data-name="레이어 1" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <circle id="타원_148" data-name="타원 148" cx="9" cy="9" r="9" fill="#e10013" />
        <path id="패스_10278" data-name="패스 10278" d="M8.52,5.09V2.54A6.52,6.52,0,0,0,3.65,5.36l2.2,1.26A3.93,3.93,0,0,1,8.52,5.09Z" fill="#fff" />
        <g id="그룹_73346" data-name="그룹 73346" opacity="0.8">
            <path id="패스_10279" data-name="패스 10279" d="M5.84,11.37l-2.2,1.27a6.5,6.5,0,0,0,4.88,2.82V12.91a3.91,3.91,0,0,1-2.68-1.54Z" fill="#fff" />
        </g>
        <g id="그룹_73347" data-name="그룹 73347" opacity="0.9">
            <path id="패스_10280" data-name="패스 10280" d="M5.35,7.46,3.17,6.2a6.4,6.4,0,0,0,0,5.6l2.19-1.26a4,4,0,0,1,0-3.08Z" fill="#fff" />
        </g>
        <g id="그룹_73348" data-name="그룹 73348" opacity="0.5">
            <path id="패스_10281" data-name="패스 10281" d="M12.65,10.55l2.18,1.26a6.36,6.36,0,0,0,0-5.62L12.64,7.44A3.89,3.89,0,0,1,13,9,4,4,0,0,1,12.65,10.55Z" fill="#fff" />
        </g>
        <g id="그룹_73349" data-name="그룹 73349" opacity="0.7">
            <path id="패스_10282" data-name="패스 10282" d="M9.48,12.91v2.55a6.47,6.47,0,0,0,4.87-2.81l-2.2-1.27a3.93,3.93,0,0,1-2.67,1.53Z" fill="#fff" />
        </g>
        <g id="그룹_73350" data-name="그룹 73350" opacity="0.3">
            <path id="패스_10283" data-name="패스 10283" d="M12.14,6.62l2.2-1.27A6.47,6.47,0,0,0,9.48,2.54V5.09a3.91,3.91,0,0,1,2.66,1.53Z" fill="#fff" />
        </g>
    </svg>
);

const PopupStopSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g id="그룹_73339" data-name="그룹 73339" transform="translate(-615 -16.082)">
            <circle id="타원_145" data-name="타원 145" cx="9" cy="9" r="9" transform="translate(615 16.082)" fill="#3dba61" />
            <rect id="사각형_19776" data-name="사각형 19776" width="10" height="2" transform="translate(619 24.082)" fill="#fff" />
        </g>
    </svg>
);

const MinusCircleSVG = () => (
    <svg id="del" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="그룹_3779" data-name="그룹 3779">
            <g id="타원_114" data-name="타원 114" fill="#707070" stroke="#707070" strokeWidth="1">
                <circle cx="7" cy="7" r="7" stroke="none" />
                <circle cx="7" cy="7" r="6.5" fill="none" />
            </g>
            <line id="선_1959" data-name="선 1959" x2="9" transform="translate(2.911 7.389)" fill="none" stroke="#fff" strokeWidth="1" />
        </g>
    </svg>
);

const CommentSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.758" height="13.029" viewBox="0 0 17.758 13.029">
        <g id="그룹_73381" data-name="그룹 73381" transform="translate(-109.5)">
            <g id="합치기_15" data-name="합치기 15" transform="translate(109.5)" fill="rgba(255,255,255,0)">
                <path d="M 8.865202903747559 12.02351093292236 L 8.023190498352051 10.56527996063232 L 7.878849983215332 10.31529998779297 L 7.590189933776855 10.31529998779297 L 5.408100128173828 10.31529998779297 C 2.701760053634644 10.31529998779297 0.5000001192092896 8.113540649414062 0.5000001192092896 5.407200336456299 C 0.5000001192092896 2.70136022567749 2.701760053634644 0.5000003576278687 5.408100128173828 0.5000003576278687 L 12.35070037841797 0.5000003576278687 C 15.05654048919678 0.5000003576278687 17.25790023803711 2.70136022567749 17.25790023803711 5.407200336456299 C 17.25790023803711 8.113540649414062 15.05654048919678 10.31529998779297 12.35070037841797 10.31529998779297 L 10.1271505355835 10.31529998779297 L 9.836239814758301 10.31529998779297 L 9.692470550537109 10.56821060180664 L 8.865202903747559 12.02351093292236 Z" stroke="none" />
                <path id="테두리" d="M 8.86180591583252 11.01771259307861 L 9.545320510864258 9.815299987792969 L 12.35070037841797 9.815299987792969 C 14.78083992004395 9.815299987792969 16.75790023803711 7.837830543518066 16.75790023803711 5.407200336456299 C 16.75790023803711 2.977060317993164 14.78083992004395 1.000000357627869 12.35070037841797 1.000000357627869 L 5.408100128173828 1.000000357627869 C 2.97747015953064 1.000000357627869 1.00000011920929 2.977060317993164 1.00000011920929 5.407200336456299 C 1.00000011920929 7.837830543518066 2.97747015953064 9.815299987792969 5.408100128173828 9.815299987792969 L 8.167500495910645 9.815299987792969 L 8.86180591583252 11.01771259307861 M 8.868599891662598 13.02930068969727 L 7.590189933776855 10.81529998779297 L 5.408100128173828 10.81529998779297 C 2.421000003814697 10.81529998779297 1.190185514587938e-07 8.39430046081543 1.190185514587938e-07 5.407200336456299 C 1.190185514587938e-07 2.421000242233276 2.421000003814697 3.448486438628606e-07 5.408100128173828 3.448486438628606e-07 L 12.35070037841797 3.448486438628606e-07 C 15.33689975738525 3.448486438628606e-07 17.75790023803711 2.421000242233276 17.75790023803711 5.407200336456299 C 17.75790023803711 8.39430046081543 15.33689975738525 10.81529998779297 12.35070037841797 10.81529998779297 L 10.1271505355835 10.81529998779297 L 8.868599891662598 13.02930068969727 Z" stroke="none" fill="#fff" />
            </g>
            <g id="그룹_73374" data-name="그룹 73374" transform="translate(113.434 4.652)">
                <ellipse id="타원_158" data-name="타원 158" cx="0.983" cy="0.968" rx="0.983" ry="0.968" transform="translate(0 0)" fill="#fff" />
                <ellipse id="타원_159" data-name="타원 159" cx="0.983" cy="0.968" rx="0.983" ry="0.968" transform="translate(3.932 0)" fill="#fff" />
                <ellipse id="타원_160" data-name="타원 160" cx="0.983" cy="0.968" rx="0.983" ry="0.968" transform="translate(7.863 0)" fill="#fff" />
            </g>
        </g>
    </svg>
);

const EditSVG = () => (
    <svg id="Icon_Outlined_20px_Edit" data-name="Icon/Outlined/20px/Edit" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 17.214 17.214">
        <g id="_24x24" data-name="24x24">
            <rect id="_24x24-2" data-name="24x24" width="16" height="16" fill="none" />
        </g>
        <g id="Icon" transform="translate(2.8 2.8)">
            <path id="Fill_1" data-name="Fill 1" d="M3.7,4.973,0,1.273,1.273,0l3.7,3.7Z" transform="translate(6.331 3.11)" />
            <path id="Fill_2" data-name="Fill 2" d="M4,14.414H0v-4L10.411,0l4,4L4,14.414Zm6.408-12.2L1.565,11.059v1.789H3.355L12.2,4Z" transform="translate(0 0)" />
        </g>
    </svg>
);

const ReplySVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="17.339" height="17.2" viewBox="0 0 17.339 17.2">
        <g id="합치기_13" data-name="합치기 13" transform="translate(0 -0.001)" fill="#68696e">
            <path d="M 11.01024055480957 16.70076179504395 C 10.76783084869385 16.70076179504395 10.50620079040527 16.50896072387695 10.50620079040527 16.19910049438477 L 10.50620079040527 13.99950122833252 L 10.50620079040527 13.49950122833252 L 10.00620079040527 13.49950122833252 L 1.615500450134277 13.49950122833252 C 1.000410437583923 13.49950122833252 0.5000004768371582 12.99909114837646 0.5000004768371582 12.38400077819824 L 0.5000004768371582 1.615501046180725 C 0.5000004768371582 1.000910997390747 1.000410437583923 0.5009010434150696 1.615500450134277 0.5009010434150696 C 2.230090379714966 0.5009010434150696 2.730100393295288 1.000910997390747 2.730100393295288 1.615501046180725 L 2.730100393295288 10.76850128173828 L 2.730100393295288 11.26850128173828 L 3.230100393295288 11.26850128173828 L 10.00620079040527 11.26850128173828 L 10.50620079040527 11.26850128173828 L 10.50620079040527 10.76850128173828 L 10.50620079040527 8.199001312255859 C 10.50620079040527 7.884891033172607 10.7622709274292 7.69719123840332 11.00984001159668 7.69719123840332 C 11.11604022979736 7.69719123840332 11.21551036834717 7.731410980224609 11.30550003051758 7.798901081085205 L 16.63891983032227 11.79852104187012 C 16.76622009277344 11.89405059814453 16.83926010131836 12.04013061523438 16.83917045593262 12.19921112060547 C 16.83909034729004 12.35786056518555 16.76621055603027 12.50338077545166 16.63922119140625 12.59846115112305 L 11.30548000335693 16.59922027587891 C 11.2156400680542 16.66659164428711 11.11630058288574 16.70076179504395 11.01024055480957 16.70076179504395 Z" stroke="none" />
            <path d="M 11.00620079040527 16.19867515563965 L 16.33893966674805 12.19853115081787 L 11.00620079040527 8.199423789978027 L 11.00620079040527 11.76850128173828 L 2.230100393295288 11.76850128173828 L 2.230100393295288 1.615501046180725 C 2.230100393295288 1.276611089706421 1.954390525817871 1.000900983810425 1.615500450134277 1.000900983810425 C 1.276110410690308 1.000900983810425 1.000000476837158 1.276611089706421 1.000000476837158 1.615501046180725 L 1.000000476837158 12.38400077819824 C 1.000000476837158 12.72339057922363 1.276110410690308 12.99950122833252 1.615500450134277 12.99950122833252 L 11.00620079040527 12.99950122833252 L 11.00620079040527 16.19867515563965 M 11.01024055480957 17.20076179504395 C 10.49223041534424 17.20076179504395 10.00620079040527 16.79109191894531 10.00620079040527 16.19910049438477 L 10.00620079040527 13.99950122833252 L 1.615500450134277 13.99950122833252 C 0.7227004766464233 13.99950122833252 4.684448242642247e-07 13.27590084075928 4.684448242642247e-07 12.38400077819824 L 4.684448242642247e-07 1.615501046180725 C 4.684448242642247e-07 0.7236010432243347 0.7227004766464233 0.0009010317735373974 1.615500450134277 0.0009010317735373974 C 2.507400512695312 0.0009010317735373974 3.230100393295288 0.7236010432243347 3.230100393295288 1.615501046180725 L 3.230100393295288 10.76850128173828 L 10.00620079040527 10.76850128173828 L 10.00620079040527 8.199001312255859 C 10.00620079040527 7.374600887298584 10.94670104980469 6.904801845550537 11.60550022125244 7.398900985717773 L 16.93889999389648 11.3985013961792 C 17.47260093688965 11.79900074005127 17.47260093688965 12.59910106658936 16.93889999389648 12.99870109558105 L 11.60550022125244 16.99920082092285 C 11.42028999328613 17.13810157775879 11.21282005310059 17.20076179504395 11.01024055480957 17.20076179504395 Z" stroke="none" fill="#68696e" />
        </g>
    </svg>
);

const ApprovalSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="60.667" height="60.667" viewBox="0 0 60.667 60.667">
        <path id="ico_status_Success" d="M31.333,1.333a30,30,0,1,0,30,30A30,30,0,0,0,31.333,1.333Zm-6,44.319-10.91-10.91,3.82-3.817,7.09,7.09,19.09-19.09,3.82,3.817Z" transform="translate(-1 -1)" fill="#f80" stroke="#f80" strokeWidth="0.667" />
    </svg>
)

// 우리카드
// const BackToListSVG = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
//         <g id="목록으로_돌아가기" data-name="목록으로 돌아가기" transform="translate(0 24) rotate(-90)">
//             <g id="_24x24" data-name="24x24">
//                 <rect id="_24x24-2" data-name="24x24" width="24" height="24" fill="none" />
//             </g>
//             <g id="Icon" transform="translate(3 5)">
//                 <path id="Fill_1" data-name="Fill 1" d="M0,0V14.476H18V9.9H16.315v2.97H1.567V1.608H8V0Z" fill="#0067ac" />
//                 <path id="Fill_2" data-name="Fill 2" d="M6,0V2h3.52L4.743,6.627l1.48,1.481L11,3.481V7h2V0Z" transform="translate(5.001)" fill="#0067ac" />
//             </g>
//             <line id="선_2026" data-name="선 2026" y2="8" transform="translate(7.5 8.5)" fill="none" stroke="#0067ac" strokeWidth="1" />
//             <line id="선_2027" data-name="선 2027" y2="6" transform="translate(10.5 10.5)" fill="none" stroke="#0067ac" strokeWidth="1" />
//         </g>
//     </svg>
// )

// 기존
const BackToListSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g id="목록으로_돌아가기" data-name="목록으로 돌아가기" transform="translate(0 24) rotate(-90)">
            <g id="_24x24" data-name="24x24">
                <rect id="_24x24-2" data-name="24x24" width="24" height="24" fill="none" />
            </g>
            <g id="Icon" transform="translate(3 5)">
                <path id="Fill_1" data-name="Fill 1" d="M0,0V14.476H18V9.9H16.315v2.97H1.567V1.608H8V0Z" fill="#ff7a00" />
                <path id="Fill_2" data-name="Fill 2" d="M6,0V2h3.52L4.743,6.627l1.48,1.481L11,3.481V7h2V0Z" transform="translate(5.001)" fill="#ff7a00" />
            </g>
            <line id="선_2026" data-name="선 2026" y2="8" transform="translate(7.5 8.5)" fill="none" stroke="#ff7a00" strokeWidth="1" />
            <line id="선_2027" data-name="선 2027" y2="6" transform="translate(10.5 10.5)" fill="none" stroke="#ff7a00" strokeWidth="1" />
        </g>
    </svg>
)

const LookCloserSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17.5" viewBox="0 0 16 20.4">
        <g id="Outlined_File_task_view" transform="translate(-4 -1.6)">
            <path id="패스_383" data-name="패스 383" d="M15,4h4V21H5V4H9" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
            <rect id="사각형_170" data-name="사각형 170" width="6" height="2.484" transform="translate(9 2.5)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.8" />
            <path id="패스_384" data-name="패스 384" d="M16.5,13a5.3,5.3,0,0,1-9,0,5.3,5.3,0,0,1,9,0Z" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="1.8" />
            <circle id="타원_116" data-name="타원 116" cx="0.75" cy="0.75" r="0.75" transform="translate(11.25 12.25)" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const LanguageSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Outlined_Technology_wikis" transform="translate(-2 -2)">
            <circle id="타원_88" data-name="타원 88" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
            <circle id="타원_89" data-name="타원 89" cx="9" cy="9" r="9" transform="translate(3 3)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
            <ellipse id="타원_90" data-name="타원 90" cx="4" cy="9" rx="4" ry="9" transform="translate(8 3)" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
            <path id="패스_233" data-name="패스 233" d="M21,12H3" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="2" />
        </g>
    </svg>
)

const LockSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 32 32">
        <g data-name="Shield Lock" id="Shield_Lock-2">
            <path className="cls-1" fill='#ff7b00' d="M28,6C19.51,6,16.84,2.46,16.82,2.43A1,1,0,0,0,16,2a.94.94,0,0,0-.83.42S12.49,6,4,6A1,1,0,0,0,3,7v8.76c0,9.25,7.11,12,11.35,13.66l1.27.5a.94.94,0,0,0,.76,0l1.26-.5C21.89,27.77,29,25,29,15.76V7A1,1,0,0,0,28,6Z" />
            <path className="cls-2" fill='#fff' d="M18,15H14a1,1,0,0,1-1-1V12a3,3,0,0,1,6,0v2A1,1,0,0,1,18,15Zm-3-2h2V12a1,1,0,0,0-2,0Z" />
            <rect className="cls-3" fill='#fff' height="10" rx="3" ry="3" width="10" x="11" y="13" />
            <path className="cls-2" fill='#ff7b00' d="M16,20a2,2,0,1,1,2-2A2,2,0,0,1,16,20Zm0-2Z" />
        </g>
    </svg>
)

const CameraSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
        <g id="camera" data-name="camera" transform="translate(-523 -192)">
            <circle id="타원_201" data-name="타원 201" cx="15" cy="15" r="15" transform="translate(524 193)" fill="#7f8589" stroke="#fff" strokeMiterlimit="10" strokeWidth="2" />
            <circle id="타원_202" data-name="타원 202" cx="2.779" cy="2.779" r="2.779" transform="translate(536.221 205.474)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
            <path id="패스_803" data-name="패스 803" d="M14.423,5.708,12.281,4H8L5.856,5.708H3v8.416H17.278V5.708Z" transform="translate(528.861 198.938)" fill="none" stroke="#fff" strokeMiterlimit="10" strokeWidth="1" />
        </g>
    </svg>
)

const EmailSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.804" height="14.262" viewBox="0 0 22.804 14.262">
        <g id="이메일" transform="translate(-0.003 0)">
            <g id="그룹_74872" data-name="그룹 74872">
                <path id="패스_10195" data-name="패스 10195" d="M0,0H21.937V14H0Z" transform="translate(0.368 0.262)" fill="#d5d5d5" />
                <path id="패스_10196" data-name="패스 10196" d="M0,0H10.969V14H0Z" transform="translate(11.337 0.262)" fill="#b5b5b5" />
            </g>
            <path id="패스_10192" data-name="패스 10192" d="M.4,0,13.428.516l.2.31L0,.261Z" transform="matrix(0.839, 0.545, -0.545, 0.839, 0.453, 0)" fill="#fff" />
            <path id="패스_10193" data-name="패스 10193" d="M0,.622,13.59,0l-.215.331L.4.878Z" transform="matrix(-0.839, 0.545, -0.545, -0.839, 22.809, 0.74)" fill="#fff" />
        </g>
    </svg>
)
const RisingSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="그룹_75020" data-name="그룹 75020" transform="translate(-75.527 0.236)">
            <circle id="타원_4549" data-name="타원 4549" cx="7" cy="7" r="7" transform="translate(75.527 -0.236)" fill="#e54156" />
            <path id="상승_아이콘" data-name="상승 아이콘" d="M3.4,0,6.8,5.18H0Z" transform="translate(79.129 4.174)" fill="#fff" />
        </g>
    </svg>

)

const FallingSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="상승" transform="translate(89.527 13.764) rotate(180)">
            <circle id="타원_4549" data-name="타원 4549" cx="7" cy="7" r="7" transform="translate(75.527 -0.236)" fill="#1b7bb3" />
            <path id="상승_아이콘" data-name="상승 아이콘" d="M3.4,0,6.8,5.18H0Z" transform="translate(79.129 3.174)" fill="#fff" />
        </g>
    </svg>
)

const EqualSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
        <g id="동일" transform="translate(-75.527 0.236)">
            <circle id="타원_4549" data-name="타원 4549" cx="7" cy="7" r="7" transform="translate(75.527 -0.236)" fill="#488a5e" />
            <rect id="동일icon" width="8.235" height="1.373" transform="translate(78.715 6.147)" fill="#fff" />
        </g>
    </svg>
)

const RunningTaskRunAgainSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <image id="Triangle_1" data-name="Triangle 1" width="17" height="17" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAACOUlEQVQ4jWJ0cnJiwAJAguFQWg4q/4iBgWEfAwPDKgYGhr0oehgYGAAAAAD//2JB42swMDBMZ2BgcMBiuAoUpzEwMBxgYGDIZGBguAGWYWBgAAAAAP//YkJSaM/AwHAKhyHoAKQGpBakh4GBgYEBAAAA//+CGQRyyUYGBgZeIgyBAZBakB4NBgYGBgAAAAD//wIZxMjAwDCfgYGBnwRDYACkZz4DAwMjAAAA//8CGeTMwMBggU1VR0cHg7y8PCHDLBgYGJwBAAAA//8CGRSGS4WpqSnD7NmzGfLy8hj4+fE4mIEhDAAAAP//AhmENf5hgJmZmcHf359h0aJFYBrExwAMDE4AAAAA//8CGSRLyO0gwMPDA3YZyIUgl6IABgZZAAAAAP//Ahn0kxiD8AIGBgYAAAAA//8CJchnDAwM6oQUfvz4kWHhwoUMW7ZsYfj79y+qJAPDEwAAAAD//wIZBEpYOA0CaQJpnj9/PsPnz5+xK2JgOAoAAAD//wIZtJSBgSEWm+zp06cZpk+fzvDw4UP8zmVgWAIAAAD//wJlWlCCPIYrLREBTjAwMFgBAAAA//8CBfZ/BgaGRFAwkGEISE8SAwPDfwAAAAD//4LlNVAu9mdgYMAZCFgASC1Iz3UGBgYGAAAAAP//Qs79BxkYGMygTiUEQGpAakF6GBgYGBgAAAAA//9CL49ALrNiYGBwZWBgCIHmQ1CC/cXAwPCYgYHhMAMDwxoGBobd0CCBAAYGBgAAAAD//wMA6sRVFgPPpQ0AAAAASUVORK5CYII="/>
    </svg>
)

const PauseRunningTaskSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="18" height="18" viewBox="0 0 18 18">
        <image id="Rectangle_2" data-name="Rectangle 2" width="17" height="17" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAC7klEQVQ4jUzJMQ3AIBAAwCsiSCqD8QcUoBcDKKiM1gQhaRi49a6IcEjIqGgouPe/eNAx8GH+gwUAAP//TMwxDQAgEACxDgwYwAICcMCAGAzhBRNoIjD9eGlyKV4wMNFQkIO9rug4WNhfcAEAAP//QjYoioGBoRhqCC7AzsDAIAPF8lDXLmBgYGAAAAAA//9ilpWVZWRgYHBmYGCoY2BgMMZjCDqQYGBgUGJgYLjPwMBwDwAAAP//AoWJOAMDQw40PBgYGRkZfv36xfDhwweGz58/wzWD2CAxkBxIDRTogvUyMMgAAAAA//8ckKERAEEIxKJwdIFHvrwK6JkW8DSC+4ECspnNXnvAFt9RZgYzw90PzsxjIgJVparobkTkvMAHvB8AAP//AhnkxcDAwA+z4uvXrwzGxsYMpaWlDE+fPoUblJ2dzSAtLc3Q3d0NNgxqEAjwMjAwuAMAAAD//wK5wgA5dkDO/vv3L5gNch0MwNggOSSvgQAHAwODMQAAAP//AhkECjQU8P//fzAfRuMSgwMGBjEAAAAA//8CGYRiPFmAgeEPAAAA//8CGQRKsSgA5nRkL2ATgwMGhlcAAAAA//8CGQRK9j+QvcDMzAxms7OD0h8EwNggOTTv/WRgYLgIAAAA//8CJUiQCicGBgZumEJQwN6+fZvhyJEjDA8ePACrfv36NcOpU6cYzp8/z/Dlyxe4ZQwMDO8ZGBimAwAAAP//AmVaUDKfAUoqEJczgg369u0bWDEo7YAAKE2BYoyLiwvsOqirQMQ2BgaGTAAAAAD//wKlI1AYTYbmHyOQAlAaQUonYAAzEC3mLjEwMExiYGB4AgAAAP//AnkNJHAPlBYZGBgUkIoNQuA8AwNDPwMDwzoGBgYGAAAAAP//ghkEApehGZAPmlpBCQ0eEFDwC1oO7WdgYGiHGcLAwMAAAAAA//9CL4/2MjAwXIGWOaAw04MWdH9AUczAwHCDgYFhC1QdItkwMDAAAAAA//8DAL7jrcxV8kHiAAAAAElFTkSuQmCC"/>
    </svg>
)

const StopRunningTaskSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <image id="stop_00" width="17" height="17" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAACuklEQVQ4jUzJsQnAIAAAwXMK9whY2qVzXidILa4hGUIkksLrng8t3Q4BERkF19+fgY6KBy/mPlgAAAD//0zMQREAEBRAwT04KEAEBTSQRx5dtDOMwz/vm5fiBQMTHQU5WEX7zR0u7Cc4AAAA//9CNiiSgYGhiIGBwQTNcGQAMlgWCYswMDAsZWBgYAAAAAD//2KCeseZgYGhmIAh6MAQarEzAwMDIwAAAP//ArkIFAYg74AkGBgYGRn+//rN8O/7d4b//+BBAJFiYmJg4uRkYGRjZWD4/x8kZADWy8BwFQAAAP//Ahlkx8DAYMnAwAByHcP/378Z2CTFGbj1tBlYeLlRDPrz+SvD10tXGX69eMnAyAIOFZAeSwYGBjsAAAAA//8C8UCxww9T/O/bdwZuXS0G+doSBmZuThSD/n79zvCwqZvhx70HDMx8vDBhfgYGBm8AAAAA//8CmQjyEjx2QN5h4efFMAQEQGIgOTQvczAwMBgCAAAA//8CGSSBoQPif+wAmxwDgwQAAAD//wKHC8WAgeEPAAAA//8CGQRKsaiAEZQicABscgwMLwEAAAD//wIZBEqlP2AioCj+8/EzOGDRwd9v3xn+fPoMVoMEfjAwMFwCAAAA//8Cxdo2BgYGd1iAM3FxMny9fI3hYXMPZvR/+QqWA6lBAh8ZGBi2AwAAAP//Ahl0iIGB4TgDA4MfyBxGVlaGX89fgqOYiAQJUnCcgYHhIAAAAP//AhkECqMp0LxjDFLAyMrCwMwKTyeYABFzF8B6GRheAAAAAP//AnkWJLqXgYGhl4GB4QwJMQgK2z6wXgaG/wAAAAD//0LO/cuhZQwo74AyL6gYYUfTDArYNwwMDOdQihEGBgYAAAAA//9CL4/2gTIgNP/5QFM9KMH+BkUxKHagkQMKVxAfAhgYGAAAAAD//wMAmF2LJLHYcfIAAAAASUVORK5CYII="/>
    </svg>
)

const RetryBizSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <image id="refresh-arrow" width="15" height="15" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAB7klEQVQokVTJwQkAIAwAsSg4j+s4out0HkGEvuo9c81fx8LEKWsgsHFT8AAAAP//QsbKDAwMkxkYGCyxSULFJ0HVMTAwMDAAAAAA//+CYRUGBoZ+BgYGFhwaYQAk3wdWz8DAAAAAAP//gjl1CgMDAxtUII2BgeEZAwPDfyh+y8DAEMvAwBDKwMCwC6puGgMDAxMAAAD//wIpjmJgYDCGauyCatgHVezNwMAwByoG8us9qDpzBgaGKAAAAAD//wIxmqECjlBFHWhODUFyBUwzAwMDQzMAAAD//wL5ARbilQwMDHcZGBgq0DQrMDAwrISG/gW4KAMDIwAAAP//AmkGmQgCDgwMDIuxBFIP1qBjYPgPAAAA//8CBRbM5g8MDAyiuAMaHML+cB4DAyMAAAD//wJphtk8n4GBwZeBgUEeh+ZDDAwMc+E8Bob/AAAAAP//AhGw0JaE+usOAwODNpIiCQYGhoVQxYFQMXMGBoYoAAAAAP//AjGQ49mAgYHhJVThaahtP6D8dKhGSDwzMDABAAAA//9C9g8shUlBA+kRAwPDOwYGhlVISRYk3wtWz8DAAAAAAP//QsaE0rYF1IWQtM3AwAAAAAD//8KWqyIZGBj0GBgYfkFjAuRkkFMvMTAwLIfnKgYGBgAAAAD//wMAmY5BhfhmD20AAAAASUVORK5CYII="/>
    </svg>
)

const PauseForResultSVG = () => (
    <svg xmlns="http://www.w3.org/2000/svg"width="16" height="16" viewBox="0 0 16 16">
        <image id="Rectangle_2" data-name="Rectangle 2" width="16" height="16" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAABeklEQVQokUzKQQ0AEAAAwCODKh6mhU1Lkqgig9m83PtCLcUnoqEjI2FjYWDivI8LAAD//2JB0qjIwMCwioGBwQTZNAYGBl6oXBgDA8M5BgaGSAYGhlsMDAwMAAAAAP//AtkEAkoMDAwnsGhEB0YMDAxHGBgYVBgYGBgAAAAA//8CaQbhlQwMDGIENMKAKAMDwwoGBgYmAAAAAP//gvmRkI3owJiBgSEIAAAA//8CaY5Clzly7BgYo7NRAANDKAAAAP//Amk2JNFWCGBgMAYAAAD//wJpFiZLKwODEAAAAP//Aml+S5ZWBoZ3AAAAAP//Amk+T5ZWBobzAAAAAP//AmleTpZWBoZVAAAAAP//AiVPkAGnoMFPLLjAwMBgDAAAAP//AmkEpdUIBgaGV0TqfA1O+wwM/wAAAAD//4IlzzsMDAxW0LSLD1xkYGCwB6tnYGAAAAAA//9Czhh3oTkpBIpB8Q9KiiCbQM5cDc1ZkFzFwMAAAAAA//8DAAvfLmQosL3GAAAAAElFTkSuQmCC"/>
    </svg>
)

// const StopForResultSVG = () => (
//     <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
//         <image id="stop_00" width="17" height="17" href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAABiklEQVQokUzKQQ0AEAAAwCODKhpIYJPSpohpIoPZvNz7wsrFJ6KiISNhY6Jj4LyPCwAA//9iQdKoxMDAsIqBgcEY2TQGBgZeBgYGRQYGhjAGBoazUPoeAwMDAwAAAP//AtkE03gci0Z0AJIHqVNiYGBgAAAAAP//AmkG4ZUMDAxiBDTCAEjdSgYGBiYAAAAA//8CaQxmYGAwIVIjDJgwMDAEAwAAAP//Avk5El1GbVY/A4+BDorYlwtXGG6lFSIEGBgiAQAAAP//AtlsiK4ZXSNWMQYGHQAAAAD//wJpFibRyRDAwCAEAAAA//8CaX5LllYGhncAAAAA//8CaT5PllYGhisAAAAA//8CaV6OLgoKHAyxi1dRBRgYlgMAAAD//wIlT5ABJ0mMrjMMDAzmAAAAAP//AmkEpdUIBgaGV0RqBKmLYGBg+AcAAAD//4Ilz7sMDAxW0LSLD4DkQeruMjAwMAAAAAD//0LOGCABM6RcZQCNxnfQQF3DwMCwFp6rGBgYAAAAAP//AwBuUTJYpGH0pAAAAABJRU5ErkJggg=="/>
//     </svg>
// )

export const CameraIcon = props => <Icon component={() => <CameraSVG />} {...props} />;
export const LookCloserIcon = props => <Icon component={() => <LookCloserSVG />} {...props} />;
export const BackToListIcon = props => <Icon component={() => <BackToListSVG />} {...props} />;
export const ReplyIcon = props => <Icon component={() => <ReplySVG />} {...props} />;
export const EditIcon = props => <Icon component={() => <EditSVG />} {...props} />;
export const CommentIcon = props => <Icon component={() => <CommentSVG />} {...props} />;
export const MinusCircleIcon = props => <Icon component={() => <MinusCircleSVG />} {...props} />;
export const DiyIcon = props => <Icon component={() => <DiySVG />} {...props} />;
export const CoeIcon = props => <Icon component={() => <CoeSVG />} {...props} />;
export const RgoIcon = props => <Icon component={() => <RgoSVG />} {...props} />;
export const SlideDotIcon = props => <Icon component={() => <SlideDotSVG />} {...props} />;
export const ArrowNextIcon = props => <Icon component={() => <ArrowNextSVG />} {...props} />;
export const ArrowPrevIcon = props => <Icon component={() => <ArrowPrevSVG />} {...props} />;
export const BasicLetterIcon = props => <Icon component={() => <BasicLetterSVG />} {...props} />;
export const CloseCollapseIcon = props => <Icon component={() => <CloseCollapseSVG />} {...props} />;
export const OpenCollapseIcon = props => <Icon component={() => <OpenCollapseSVG />} {...props} />;
export const ProfileIcon = props => <Icon component={() => <ProfileSVG style={props.style} />} {...props} />;
export const BellIcon = props => <Icon component={BellSVG} {...props} />;
export const CopyIcon = props => <Icon component={() => <CopySVG {...props} />} {...props} />;
export const ReportIcon = props => <Icon component={ReportSVG} {...props} />;
export const LinkIcon = props => <Icon component={LinkSVG} {...props} />;
export const SquareIcon = props => <Icon component={SquareSVG} {...props} />;
export const ScheInfoIcon = props => <Icon component={ScheInfoSVG} {...props} />;
export const InfoIcon = props => <Icon component={InfoSVG} {...props} />;
export const ProcessingIcon = props => <Icon component={ProcessingSVG} {...props} />;
export const SuccessIcon = props => <Icon component={SuccessSVG} {...props} />;
export const FailedIcon = props => <Icon component={FailedSVG} {...props} />;
export const BlackSuccessIcon = props => <Icon component={BlackSuccessSVG} {...props} />;
export const BlackFailedIcon = props => <Icon component={BlackFailedSVG} {...props} />;
export const BlackRequestIcon = props => <Icon component={BlackRequestSVG} {...props} />;
export const BlackRejectIcon = props => <Icon component={BlackRejectSVG} {...props} />;
export const MonitoringIcon = props => <Icon component={MonitoringSVG} {...props} />;
export const LogIcon = props => <Icon component={LogSVG} {...props} />;
export const RestartIcon = props => <Icon component={RestartSVG} {...props} />;
export const RightTriangle = props => <Icon component={RightTriangleSVG} {...props} />;
export const EyeIcon = props => <Icon component={EyeSVG} {...props} />;
export const DeleteIcon = props => <Icon component={DeleteSVG} {...props} />;
export const AddFileIcon = props => <Icon component={AddFileSVG} {...props} />;
export const PauseIcon = props => <Icon component={PauseSVG} {...props} />;
export const PopupProgressIcon = props => <Icon component={PopupProgressSVG} {...props} />;
export const PopupWaitIcon = props => <Icon component={PopupWaitSVG} {...props} />;
export const PopupStopIcon = props => <Icon component={PopupStopSVG} {...props} />;
export const ApprovalIcon = props => <Icon component={ApprovalSVG} {...props} />;
export const MonitoringReplayIcon = props => <Icon component={MonitoringReplaySVG} {...props} />;
export const LanguageIcon = props => <Icon component={LanguageSVG} {...props} />;
export const LockIcon = props => <Icon component={LockSVG} {...props} />;
export const EmailIcon = props => <Icon component={EmailSVG} {...props} />;
export const RisingIcon = props => <Icon component={RisingSVG} {...props} />;
export const FallingIcon = props => <Icon component={FallingSVG} {...props} />;
export const EqualIcon = props => <Icon component={EqualSVG} {...props} />;
export const RunningTaskRunAgainIcon = props => <Icon component={RunningTaskRunAgainSVG} {...props} />;
export const PauseRunningTaskIcon = props => <Icon component={PauseRunningTaskSVG} {...props} />;
export const StopRunningTaskIcon = props => <Icon component={StopRunningTaskSVG} {...props} />;
export const RetryBizIcon = props => <Icon component={RetryBizSVG} {...props} />;
export const PauseForResultIcon = props => <Icon component={PauseForResultSVG} {...props} />;
//export const StopForResultIcon = props => <Icon component={StopForResultSVG} {...props} />;