import React from 'react';
import { Radio } from 'antd';
import styled from 'styled-components';

/**
 * Filename: Radio.js
 * Description: Radio 공통 컴포넌트
 */

const RadioWrap = styled(Radio.Group)`
    .ant-radio-inner {
        width: 20px;
        height: 20px;
        border: 1px solid #b0b1b5;
        background:#fff;
    }
    input[type='radio']:hover {
        background: #333;
    }
    .ant-radio-checked .ant-radio-inner {
        border-color: #ff6600;
    }
    .ant-radio-inner::after { 
        background: #ff6600;
    }
    .ant-radio-wrapper {
        display: inline-flex;
        align-items: center;
        font-size: 12px;
    }
`;

const RadioGroup = ({ renderItem = [], type, ...args }) => {
    return (
        <RadioWrap {...args}>
            {
                renderItem.map(item => {
                    if (type === "schedule_trigger") {
                        return (
                            <div className='custom_radio__wrap'>
                                <Radio className='custom_radio' key={item.value} value={item.value}>
                                    {/* {(item.text !== undefined && item.length > 0) && item.text} */}
                                </Radio>
                                {item.render}

                            </div>
                        )
                    }
                    else if (item.render) {
                        return (
                            <div className='custom_radio__wrap'>
                                <Radio className='custom_radio' key={item.value} value={item.value}>
                                    {(item.text !== undefined && item.length > 0) && item.text}
                                </Radio>
                                {item.render}
                            </div>
                        )
                    }

                    else {
                        return (
                            <Radio className='custom_radio' key={item.value} value={item.value}>
                                {item.text}
                            </Radio>
                        )
                    }
                })
            }
        </RadioWrap>
    )
}

export default RadioGroup;