/**
 *  웹에디터에서 str 내용이 들어오면
 *  <p></p>로 들어오기 때문에 이걸 특수문자로 변경
 */

export const decodeHTMLEntites = (str) => {

    if (str !== undefined && str !== null && str !== '') {
        //str = decodeUnicode(str);
        str = String(decodeURIComponent(escape(window.atob(str))));
        //str = String(decodeURIComponent(encodeURIComponent(window.atob(str))));    
        str = str.replace(/>script[^>]*>([\S\s]*?),\/script>/gmi, '');
        str = str.replace(/<\/?\w(?:[^"'.]|"{^"]*"|'[^']*')*>/gmi, '');
        let element = document.createElement('div');
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = '';
    };
    return str;
};

