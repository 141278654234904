import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import { Table, Tabs } from "components";
import Wrapper from "./Styled";
import { Tabs as AntTabs, Tooltip } from "antd";
import { useIntl } from "react-intl";
const { TabPane } = AntTabs;

/**
 * 메인화면 > 랭킹
 *
 */

const Ranking = () => {
  const { formatMessage } = useIntl();

  const [tableLoading, setTableLoading] = useState(false);
  const [tabsValue, setTabsValue] = useState(1);
  const [cateForTabs, setCateForTabs] = useState([]);
  const [colList, setColList] = useState([]);
  const [datas, setDatas] = useState([]);
  const [columns, setColumns] = useState([]);

  const widthSetting = {
    taskNm: 200,
    ranking: 40,
  };
  const alignCenter = {
    ranking: 'center'
  }

  const onChangeTab = (tabsValue) => {
    setTabsValue(tabsValue);
    getRankingList(tabsValue, colList);
  };

  const getRankingList = async (tabsValue, colData) => { // 자료 잘 가져옴
    setTableLoading(true);
    const result = await fetchAbsolute(
      "get",
      `/main/rank/getRankList?rankCd=${tabsValue}`
    );
    setTableLoading(false);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    const test = {
      col1: result.data.commonCdList.attrCd2,
      col2: result.data.commonCdList.attrCd3,
      col3: result.data.commonCdList.attrCd4,
      col4: result.data.commonCdList.attrCd5,
    };
    const tmpArr = Object.values(test).map((v) => {
      if (v !== null) {
        const tmpObj = colData.find((i) => i.cd === v);
        return {
          key: tmpObj.attrCd1,
          cd: tmpObj.cd,
          title: tmpObj.cdNm,
          dataIndex: tmpObj.attrCd1,
          align: alignCenter[tmpObj.attrCd1],
          width: widthSetting[tmpObj.attrCd1],
          render: (text, record) => {
            const value = tmpObj.attrCd1 === 'score' ? (+text)?.toLocaleString() : text;
            return (
              record.taskId || record.taskNm ?
                <Link to={`/assign/lookup/${record.taskId}`}>
                  <Tooltip title={value}>
                    <span>{value}</span>
                  </Tooltip>
                </Link>
                :
                <Tooltip title={value}>
                  <span>{value}</span>
                </Tooltip>
            );
          },
        };
      } else {
        return { width: 5 };
      }
    });
    setDatas([...result.data.rankList]);
    setColumns([...tmpArr]);
  };

  /* tab 목록 가져오기 */
  useEffect(() => {
    const getStepGuideCd = async () => {
      setTableLoading(true);
      const result = await fetchAbsolute(
        "get",
        `/common/commonCd/getCategoryId/RANK_STD`
      );
      const colResult = await fetchAbsolute(
        "get",
        `/common/commonCd/getCateIdAndUseYn/RANK_COLUMN?sortVal=asc`
      );
      setTableLoading(false);
      if (result.error || colResult.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      setCateForTabs([...result.data]);
      setColList([...colResult.data]);
      getRankingList(result.data[0]?.cd, colResult.data); // 첫페이지 셋팅
    };
    getStepGuideCd();
  }, []);

  return (
    <Wrapper>
      <div className="common__container">
        <div className="common__title">
          <span>{formatMessage({ id: 'T0388' })}</span>{/* 랭킹 */}
        </div>
        <Tabs defaultActiveKey={tabsValue} onChange={onChangeTab}>
          {cateForTabs.map((item) => {
            return <TabPane tab={item.attrCd1} key={item.cd} />;
          })}
        </Tabs>
        <Table
          rowKey={(item) => item.taskId}
          columns={columns}
          dataSource={datas}
          paginaton="false"
          defaultPageSize={5}
          loading={tableLoading}
          locale={{ emptyText: `${formatMessage({ id: 'T0411' })}` }}
        />
      </div>
    </Wrapper>
  );
};

export default withRouter(Ranking);