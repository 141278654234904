import { SET_ASSIGNSCHEDULE_VALUE, SET_ASSIGNSCHEDULE_SPREAD, RESET_ASSIGNSCHEDULE_VALUE, SET_ASSIGNSCHEDULE_FILTERID } from './action';

/**
 * Breadcrumb 구현을 위한 State
 * 
 */

const assignScheduleInitialState = {
    inputValues: {
        taskId: '',
        taskNm: '',
        taskType: {},
        workId: [],
        orgCd: [],
        mgrEmpNo: [],
        devicePool: [],
        scheduleType: [],
    },
    filterId: ''
};

const assignSchedule = (state = assignScheduleInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_ASSIGNSCHEDULE_VALUE: {
            const inputValues = {
                ...state.inputValues,
            };
            return {
                ...state,
                inputValues: {
                    ...inputValues,
                    [action.key]: action.value
                }
            }
        }
        case SET_ASSIGNSCHEDULE_SPREAD: {
            const inputValues = {
                ...state.inputValues,
                ...action.data
            };
            return {
                ...state,
                inputValues
            }
        }
        case RESET_ASSIGNSCHEDULE_VALUE: {
            const inputValues = {
                ...assignScheduleInitialState.inputValues
            };
            return {
                ...state,
                inputValues
            }
        }
        case SET_ASSIGNSCHEDULE_FILTERID: {
            return {
                ...state,
                filterId: action.filterId
            }
        }
        default:
            return state;

    }
}

export default assignSchedule;
