import {
    AUTH_SIGNIN,
    AUTH_SIGNIN_SUCCESS,
    AUTH_SIGNIN_RESET,
    SSO_SIGNIN_SUCCESS,
    SSO_LIVE_SUCCESS,
    SSO_SIGNIN
} from './constants';

export const fetchAuthSignIn = ({ empNo, empPw, url }, push, setCookie) => ({
    type: AUTH_SIGNIN,
    payload: {
        empNo,
        empPw,
        url
    },
    push,
    setCookie
});

export const setAuth = (data) => ({
    type: AUTH_SIGNIN_SUCCESS,
    payload: {
        ...data
    }
})

export const resetAuth = () => {
    return {
        type: AUTH_SIGNIN_RESET
    }
}

export const fetchSSOLoginSUCCESS = ({ data }) => ({
    type: SSO_SIGNIN_SUCCESS,
    payload: {
        ...data
    }
});
//제일 먼저 empNum 으로 sso_signin 들어감 ->saga.js -> checkAuthSSO -> dispath 
export const fetchSSOAuth = ({ empNo }, push) => ({
    type: SSO_SIGNIN,
    payload: {
        empNo
    },
    push,
});
export const fetchSSOLive = (ssoStatus) => ({
    type: SSO_LIVE_SUCCESS,
    ssoStatus: ssoStatus,
});

