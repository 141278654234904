import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Select } from 'antd';
import fetchAbsolute from 'utils/fetchAbsolute';
import pdfDownload from 'utils/pdfDownload';
import {
    PageTemplate,
    Header,
    Footer,
    Text,
    Button,
    TypePicker,
} from 'components';
import Wrapper from './Styled'
import OrgComparison from './OrgComparison';
import TimeSavingStatus from './TimeSavingStatus';
import TimeSavingTask from './TimeSavingTask';

const { Option } = Select;

/**
 * 대시보드 > 절감 시간 현황 
 */

const DashboardTimeSaving = () => {
    const { formatMessage } = useIntl();
    const [taskTypeList, setTaskTypeList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [taskData, setTaskData] = useState([]);
    const [orgData, setOrgData] = useState([]);
    const [standardDate, setStandardDate] = useState(moment());
    const [defaultTaskType, setDefaultTaskType] = useState([]);
    const [conditions, setConditions] = useState({
        taskType: [],
        org: [],
    });
    const [savingTaskCondition, setSavingTaskCondition] = useState({
        taskType: [],
        rowPerPage: 10
    });
    const [isLoading, setIsLoading] = useState(false);

    const pageSize = [10, 50, 100];

    const taskConfig = {
        xField: 'value',
        yField: 'label',
        yAxis: {
            label: {
                autoRotate: false, // scroll
            },
        },
        offsetY: 6,
        color: '#ff7b00',
        style: {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 500,
            fill: 'rgba(0,0,0,0.85)',
        },
        marginRatio: 0,
        label: {
            position: 'outside',
            offset: 4,
            style: {
                fill: '#000',
                fontWeight: 500,
            },
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'interval-hide-overlap' },
                { type: 'adjust-color' },
            ],
            content: function content(_ref) {
                return ""
                    .concat(priceToString(_ref.value) || 0)
                    .concat('hr');
            },
        },
    };

    const orgConfig = {
        isGroup: true,
        xField: 'company',
        yField: 'value',
        color: ['#ff7b00', '#bebebe'],
        seriesField: 'label',
        label: {
            position: 'middle',
            style: {
                fill: '#000',
                fontWeight: 500,
            },
            layout: [
                { type: 'interval-adjust-position', },
                { type: 'adjust-color', },
            ],
            content: (item) => {
                if (item.type === 'taskCnt') {
                    return "".concat(priceToString(item.value) || 0).concat(formatMessage({ id: 'T1537' })) // 건
                } else {
                    return "".concat(priceToString(item.value) || 0).concat('hr');
                }
            }
        },
        legend: false,
        xAxis: {
            label: {
                autoRotate: false,
            },
        },
    };

    // 천단위 콤마 찍기
    const priceToString = (number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const onChangeDate = (date, dateString) => {
        setStandardDate(date);
    };

    /* 과제 유형 */
    const onChangeType = (e) => {
        setConditions({
            ...conditions,
            taskType: [...e]
        })
    };

    /* 조직별 */
    const onChangeOrg = (e) => {
        /* 배열 */
        setConditions({
            ...conditions,
            org: [...e],
        });
    };

    /* 절감시간TOP */
    const onSelectTop = (e) => {
        setSavingTaskCondition({
            ...savingTaskCondition,
            rowPerPage: +e
        });
    }

    /* 과제별 절감 시간 */
    const fetchSavingTimeForTask = async () => {
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...conditions.taskType];
        if (conditions.taskType.length === 0) tmpTaskType = [...defaultTaskType];

        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/SavingsTimeStatus/SavingsTimeByTaskList`, {
            data: {
                deployDate: standardDate.format('YYYY-MM-DD'),
                orgCd: conditions.org,
                taskType: tmpTaskType || []
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        /* pagesize 별로 자르기 */
        let length = savingTaskCondition.rowPerPage;
        if (length > data.length) length = data.length;

        for (let i = 0; i < length; i++) {
            tmpArr.push(
                {
                    label: data[i].taskId,
                    key: data[i].taskId,
                    value: +data[i].annualSavingsTime || 0
                }
            )
        }
        setTaskData([...tmpArr]);
    };

    /* 조직별 비교 */
    const fetchComparisonOrg = async () => {
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...conditions.taskType];
        if (conditions.taskType.length === 0) tmpTaskType = [...defaultTaskType];

        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/SavingsTimeStatus/ComparisonByOrgList`, {
            data: {
                deployDate: standardDate.format('YYYY-MM-DD'),
                orgCd: conditions.org,
                taskType: tmpTaskType || [],
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    company: data[i].companyNm,
                    key: data[i].companyCd,
                    label: formatMessage({ id: "T1521" }), // 절감시간
                    value: +data[i].saveTime || 0,
                    type: 'savingTime',
                },
                {
                    company: data[i].companyNm,
                    key: data[i].companyCd,
                    label: formatMessage({ id: "T1546" }), // 과제건수
                    value: +data[i].taskCnt || 0,
                    type: 'taskCnt',
                },
            )
        }
        setOrgData([...tmpArr]);
    };

    useEffect(() => {
        fetchSavingTimeForTask();
    }, [conditions.org, conditions.taskType, standardDate]);

    useEffect(() => {
        fetchComparisonOrg();
    }, [conditions, standardDate]);

    useEffect(() => {
        if (taskTypeList.length === 0) return;
        const tmpArr = [];
        for (let i in taskTypeList) {
            tmpArr.push(taskTypeList[i].cd)
        }
        setConditions({
            ...conditions,
            taskType: [...tmpArr]
        })
        setSavingTaskCondition({
            ...savingTaskCondition,
            taskType: [...tmpArr]
        });
        setDefaultTaskType([...tmpArr]);
    }, [taskTypeList]);

    useEffect(() => {
        // taskType 가져오는 함수
        const fetchTaskType = async () => {
            try {
                const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/OPER_TYPE');
                setTaskTypeList([...result.data?.filter(v => v.useYn === 'Y')]);
            } catch (err) {
                return alert(err);
            }
        }
        /* 조직별 리스트 가져오기 */
        const getCompanyList = async () => {
            const result = await fetchAbsolute('get', `/admin/commonMaster/getKpiStandardCompanyList`);
            setCompanyList([...result.data]);
        };
        getCompanyList();
        fetchTaskType();
    }, []);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <Wrapper>
                <div className='time_saving_container'>
                    <div className='standard_conditions'>
                        <div className='condition'>
                            <Text className="">{formatMessage({ id: "T1512" })}</Text>{/* 기준일자 */}
                            <TypePicker
                                placeholder={moment().format("YYYY.MM.DD")}
                                defaultValue={moment()}
                                value={standardDate}
                                width="170"
                                height="32"
                                onChange={onChangeDate}
                                allowClear={false}
                            />
                        </div>
                        <div className='condition'>
                            <Text className="">{formatMessage({ id: "T1516" })}</Text>{/* 조직별 */}
                            <Select
                                mode='multiple'
                                maxTagCount='responsive'
                                style={{
                                    width: 220
                                }}
                                onChange={onChangeOrg}
                                value={conditions.org}
                                placeholder={formatMessage({ id: "T0391" })}  // 전체
                            >
                                {
                                    companyList.map(list => {
                                        return (
                                            <Option key={list.companyCd} value={list.companyCd}>{list.companyNm}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className='condition'>
                            <Text className="">{formatMessage({ id: "T0740" })}</Text>{/* 과제유형 */}
                            <Select
                                style={{
                                    minWidth: 130,
                                    width: 'fit-content'
                                }}
                                name="taskType"
                                mode='multiple'
                                value={conditions.taskType}
                                onChange={onChangeType}
                                filterOption={taskTypeList
                                    .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                                    .map((item) => item.cd)}
                            >
                                {
                                    taskTypeList.map(type => {
                                        return (
                                            <Option key={type.cd} value={type.cd}>{type.cd}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div>
                            <Button onClick={() =>
                                pdfDownload('Dashboard_Saving_time.pdf')} >{formatMessage({ id: 'T1317' })} </Button>{/* PDF 다운로드 */}
                        </div>
                    </div>
                    <div className='first__section'>
                        <div className='time_saving_status'>
                            <TimeSavingStatus
                                standardDate={standardDate.format('YYYY-MM-DD')}
                                conditions={conditions}
                                defaultTaskType={defaultTaskType}
                            />
                        </div>
                        <div className='time_saving_task'>
                            <TimeSavingTask
                                taskConfig={taskConfig}
                                taskData={taskData}
                                pageSize={pageSize}
                                savingTaskCondition={savingTaskCondition}
                                onSelectTop={onSelectTop}
                            />
                        </div>
                    </div>
                    <div className='second__section'>
                        <div className='org_comparison'>
                            <OrgComparison
                                orgData={orgData}
                                orgConfig={orgConfig}
                                defaultTaskType={defaultTaskType}
                            />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </PageTemplate >
    )
}

export default DashboardTimeSaving;