import React, { useState, useEffect } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Input,
  Button,
  Table,
  Text,
} from "components";
import { Select, Input as AntInput } from "antd";
import Wrapper from "./Styled";
import fetchAbsolute from "utils/fetchAbsolute";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
const { Option } = Select;
const { TextArea } = AntInput;

/**
 * Filename: AdminCommonCd/index.js
 * Description: Admin -> Common Cd 관리 페이지
 * 공통 코드 통합 관리
 */

const pageInfoInitialState = {
  total: 0,
  currentPage: 1,
  rowPerPage: 20,
}

const AdminCommonCd = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const firstBlankNotAllowed = formatMessage({ id: 'T0230' }); // 해당 항목에는 첫 글자 공백을 사용할 수 없습니다.
  const commonCdSearchFail = formatMessage({ id: 'T0232' }); // 공통코드 리스트 조회에 실패하였습니다.

  const [pageInfo, setPageInfo] = useState({ ...pageInfoInitialState })

  const onChangePage = (pagination, pageSize) => {
    setPageInfo({ ...pageInfo, currentPage: pagination, rowPerPage: pageSize })
  }

  const columns = [
    {
      key: "cd",
      title: <FormattedMessage id="T1170" />, // cd
      dataIndex: "cd",
      fixed: "left",
      render: (text, record) => {
        return (
          <TextArea
            disabled
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) => onChangeInput(record.cd, "cd", e.target.value)}
            name={record.cd}
          />
        );
      },
    },
    {
      key: "cdNm",
      title: <FormattedMessage id="T1171" />, // cdNm
      dataIndex: "cdNm",
      fixed: "left",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) => onChangeInput(record.cd, "cdNm", e.target.value)}
            name={record.cd}
          />
        );
      },
    },
    {
      key: "upCd",
      title: <FormattedMessage id="T1172" />, // upCd
      dataIndex: "upCd",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) => onChangeInput(record.cd, "upCd", e.target.value)}
            name={record.cd}
          />
        );
      },
    },
    {
      key: "attrCd1",
      title: <FormattedMessage id="T1173" />, // attrCd1
      dataIndex: "attrCd1",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) =>
              onChangeInput(record.cd, "attrCd1", e.target.value)
            }
            name={record.cd}
          />
        );
      },
    },
    {
      key: "attrCd2",
      title: <FormattedMessage id="T1174" />, // attrCd2
      dataIndex: "attrCd2",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) =>
              onChangeInput(record.cd, "attrCd2", e.target.value)
            }
            name={record.cd}
          />
        );
      },
    },
    {
      key: "attrCd3",
      title: <FormattedMessage id="T1175" />, // attrCd3
      dataIndex: "attrCd3",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) =>
              onChangeInput(record.cd, "attrCd3", e.target.value)
            }
            name={record.cd}
          />
        );
      },
    },
    {
      key: "attrCd4",
      title: <FormattedMessage id="T1176" />, // attrCd4
      dataIndex: "attrCd4",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) =>
              onChangeInput(record.cd, "attrCd4", e.target.value)
            }
            name={record.cd}
          />
        );
      },
    },
    {
      key: "attrCd5",
      title: <FormattedMessage id="T1177" />, // attrCd5
      dataIndex: "attrCd5",
      render: (text, record) => {
        return (
          <TextArea
            value={text}
            style={{
              resize: "none",
              height: "fit-content",
              width: "fit-content",
            }}
            onChange={(e) =>
              onChangeInput(record.cd, "attrCd5", e.target.value)
            }
            name={record.cd}
          />
        );
      },
    },
    {
      key: "sort",
      title: <FormattedMessage id="T0864" />, // 순서
      dataIndex: "sort",
      width: 80,
      render: (text, record) => {
        return (
          <Input
            value={text}
            style={{ width: 50 }}
            name={record.cd}
            onChange={(e) => onChangeInput(record.cd, "sort", e.target.value)}
          />
        );
      },
    },
    {
      key: "useYn",
      title: <FormattedMessage id="T0819" />, // 노출여부
      dataIndex: "useYn",
      width: 80,
      render: (select, record) =>
        datas.length >= 1 ? (
          <Select
            defaultValue="Y"
            value={select}
            onChange={(e) => onChangeInput(record.cd, "useYn", e)}
            style={{ width: "100%", fontSize: 12 }}
          >
            <Option value="Y">Y</Option>
            <Option value="N">N</Option>
          </Select>
        ) : null,
    },
  ];

  const [loading, setLoading] = useState(false);

  const [datas, setDatas] = useState([]);
  const [commonCdList, setCommonCdList] = useState([]);
  const [commonCd, setCommonCd] = useState("");

  const [languageList, setLanguageList] = useState([]);
  const [language, setLanguage] = useState('');

  const onSelectCdId = (e) => {
    setCommonCd(e);
  };

  const onChangeInput = (cd, label, value) => {
    const tmpArr = [...datas];
    const index = tmpArr.findIndex((v) => v.cd === cd);
    if (index === -1) return;
    if (value === " ") {
      alert(firstBlankNotAllowed);
      return;
    }
    tmpArr[index][label] = value;
    setDatas([...tmpArr]);
  };

  /* 수정 */
  const onClickEdit = async () => {
    const parameter = [];

    datas.map((v) => {
      return parameter.push({
        categoryId: commonCd,
        cd: v.cd,
        cdNm: v.cdNm,
        upCd: v.upCd,
        useYn: v.useYn,
        attrCd1: v.attrCd1,
        attrCd2: v.attrCd2,
        attrCd3: v.attrCd3,
        attrCd4: v.attrCd4,
        attrCd5: v.attrCd5,
        sort: v.sort,
        locale: language
      });
    });

    const modifyCd = await fetchAbsolute("post", "/common/commonCd/addList", {
      data: [...parameter],
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (modifyCd.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    else {
      alert(fmMessage({ id: 'T0231', values: { commonCd } }))
      //`${commonCd} 정보가 수정되었습니다.`);
    }
  };

  /* 초기화 */
  const onClickReset = () => {
    getCdList();
  };

  const getCdList = async () => {
    setLoading(true);
    const result = await fetchAbsolute("get", `/common/commonCd/getCateIdAndUseYn/${commonCd}?sortVal=ASC`, { locale: language });
    setPageInfo({ ...pageInfoInitialState, total: result.data.length });
    setLoading(false);
    if (result.error) {
      alert(commonCdSearchFail);
    }
    setDatas(result.data);
  };

  const onChangeLanguage = (value) => {
    setLanguage(value);
  }

  useEffect(() => {
    if (!commonCd || !language) return;
    getCdList();
  }, [commonCd, language]);

  useEffect(() => {
    const fetchDataList = async () => {
      const result = await fetchAbsolute("get", `/common/commonCatg/findAll?allowYn=Y`);
      if (result.error) {
        alert(commonCdSearchFail);
      }
      setCommonCdList([...result.data.commonCatgs]);
    };
    const fetchLanguageList = async () => {
      const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/LOCALE');
      const data = result.data.filter(v => v.useYn === 'Y');
      setLanguage(data[0].cd);
      setLanguageList([...data]);
    }
    fetchDataList();
    fetchLanguageList();
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} bodyColor="#fff">
      <Wrapper style={{ padding: "30px 0" }}>
        <div>
          <div className="top__section">
            <Text>{formatMessage({ id: 'T0865' })}</Text>{/* 공통 코드 */}
            <Select
              placeholder={formatMessage({ id: 'T0866' })} // 공통 코드 선택
              style={{
                minWidth: 200,
                width: "fit-content",
                height: 32,
              }}
              onSelect={onSelectCdId}
            >
              {commonCdList.map((cd) => (
                <Option value={cd.categoryId} key={cd.categoryId}>
                  {cd.categoryNm}({cd.categoryId})
                </Option>
              ))}
            </Select>
            <Select
              value={language}
              onChange={onChangeLanguage}
            >
              {
                languageList.map(lan => (
                  <Option value={lan.cd} key={lan.cd}>
                    {lan.cdNm}
                  </Option>
                ))
              }
            </Select>
          </div>
        </div>
        <div className="table__section">
          <Table
            rowKey={(item) => item.cd}
            columns={columns}
            dataSource={datas}
            pagination={false}
            // pageOptions={{ 
            //   total: pageInfo.total,
            //   current: pageInfo.currentPage,
            //   pageSize: pageInfo.rowPerPage,
            //   onChange: onChangePage,
            // }}
            defaultPageSize={10}
            locale={{ emptyText: `${formatMessage({ id: 'T0867' })}` }} // 코드를 선택해주세요
            scroll={{ x: "auto" }}
            loading={loading}
          />
        </div>
        <div className="submit_button__section">
          <Button
            type="primary"
            className="submit_button"
            onClick={onClickEdit}
          >
            <FormattedMessage id="T0054" />{/* 수정 */}
          </Button>
          <Button
            type="default"
            className="submit_button"
            onClick={onClickReset}
          >
            <FormattedMessage id="T0037" />{/* 초기화 */}
          </Button>
        </div>
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminCommonCd;