import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    /* Index & 공통 */ 
     .box__container {
        width:100%;
        padding: 20px 20px;
        border:1px solid #d0d1d6;
        border-radius: 10px;
        background: #fff;
        height: 415px;
        overflow: auto;
    }
     .main__title {
        margin-bottom: 0.5em;
        font-size: 18;
        font-weight: bold;
        line-height: 1.38;
    }
     .bot__title { 
        display: flex;
        justify-content: space-between;
    }
    .condition {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .time_saving_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .standard_conditions {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: right;
    }
    .first__section, .second__section {
        display: flex;
        gap: 10px;
        height: 100%;
    }
    .time_saving_status {
        width: 30%;
    }
    .time_saving_task {
        width: 70%;
    }
    .org_comparison {
        width: 100%;
    }
    /* 절감 시간 현황 */
    .circle__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
    .status__circle {
        display: flex;
        flex-direction: column;
        height: 155px;
        width: 155px; 
        border-radius: 50%;
        display: inline-block; 
    }
    .status__circle.first {
        background-color: #ff7b00;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .status__circle.second {
        background-color: #fdc459; 
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .status__circle.first span, .status__circle.second span {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
    }
`;

export default Wrapper;