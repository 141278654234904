import { useEffect, useState } from 'react';
import {
  AssignEnterBox,
  Text,
  Input,
  Button,
  UserTag,
  FormTable,
  Upload,
  HFBpdIframeModal
} from "components";
import { Input as AntInput } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import NameTagGroup from "components/atoms/NameTagGroup";

const { TextArea } = AntInput;

/**
 * Filename: HFBpdEnterBasicBox/index.js
 * Description: HandyFlow 과제 등록 -> 기본 정보 섹션
 */

const HFBpdEnterBasicBox = ({
  insertDatas,
  onChangeDatas,
  onClickBusinessOpen,
  requireFields,
  onRemoveFile,
  onBeforeupload,
  fileList,
  type,
  onClickOrga,
  onClickDelete,
  params,
  isSavedBasicInfo,
  onModifyBpdIframe
}) => {
  const END_POINT = process.env.REACT_APP_API_URI;

  const { formatMessage } = useIntl(); 

  // const [bpdIframeVisible,setBpdIframeVisible] = useState(false);
  // const [doubleCheckVisible,setDoubleCheckVisible] = useState(false);
  // const onModifyBpdIframe = () =>  setBpdIframeVisible(true);
  // const onOkBpdIframe = () => setDoubleCheckVisible(true);
  // const onCancelBpdIframe = () => setBpdIframeVisible(false);
  // const onOkDoubleCheck = () => {
  //   setBpdIframeVisible(false);
  //   setDoubleCheckVisible(false);
  // }
  // const onCancelDoubleCheck = () => setDoubleCheckVisible(false);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "taskName" && value.length > 100) {
      return;
    } else if (name === "taskDesc" && value.length > 1000) {
      onChangeDatas(name, value.slice(0, 1000));
    } else {
      onChangeDatas(name, value);
    }
  };

  return (
    <AssignEnterBox title={formatMessage({ id: "T0424" })}>{/* 기본 정보 */}
      <FormTable>
        <colgroup>
          <col width="12.5%" />
          <col width="37.5%" />
          <col width="12.5%" />
          <col width="37.5%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text required={true}>{formatMessage({ id: "T0008" })}</Text>{/* 업무 분류 */}
            </th>
            <td>
              <div className="flex align-center">
                <Input
                  readOnly
                  style={{
                    width: 320,
                    marginRight: 20,
                  }}
                  innerRef={requireFields.businessClass.ref}
                  name="businessClass"
                  value={insertDatas.businessClass.cdPathNm}
                  onChange={onChangeInput}
                />
                <Button onClick={onClickBusinessOpen}>
                  <FormattedMessage id="T0048" />
                  {/* 설정 */}
                </Button>
              </div>
            </td>
            <th>
              <Text required={true}>{formatMessage({ id: "T0429" })}</Text>{/* 등록자 */}
            </th>
            <td>
              <UserTag profile={insertDatas.totalManager} />
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>
                <FormattedMessage id="T1237" />{/* 업무명 */}
              </Text>
            </th>
            <td colSpan={3}>
              <Input
                innerRef={requireFields.taskName.ref}
                name="taskName"
                style={{ width: '100%' }}
                value={insertDatas.taskName}
                onChange={onChangeInput}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>{formatMessage({ id: 'T0428' })}</Text>{/* 업무담당자 */}
            </th>
            <td colSpan={insertDatas.workManager.length > 2 ? 3 : 1}>
              {
                <Button
                  innerRef={requireFields.workManager.ref}
                  data-label='workManager'
                  onClick={onClickOrga}
                >
                  <FormattedMessage id="T0049" />{/* 추가 */}
                </Button>
              }
              <div className='flex flex-wrap-wrap' style={{ marginTop: 8, gap: '8px 10px' }}>
                {
                  NameTagGroup(insertDatas.workManager.map((v, index) => ({ ...v, isMain: index === 0 })), 'workManager', onClickDelete)
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>
                <FormattedMessage id="T1236" />{/* 업무 설명 */}
              </Text>
            </th>
            <td colSpan={3}>
              <TextArea
                style={{ height: 120 }}
                name="taskDesc"
                maxLength={1000}
                value={insertDatas.taskDesc}
                onChange={onChangeInput}
                ref={requireFields.taskDesc.ref}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text>
                <FormattedMessage id="T0513" />{/* 첨부파일 */}
              </Text>
            </th>
            <td colSpan={3}>
              <Upload
                action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                multiple={true}
                beforeUpload={onBeforeupload}
                onRemove={onRemoveFile}
                fileList={fileList}
              />
            </td>
          </tr>
          {
            type !== 'lookup' && isSavedBasicInfo
            &&
              <tr>
                <th>
                  Process Design
                </th>
                <td colSpan={3}>
                  <Button type="primary" onClick={onModifyBpdIframe}>
                    {
                      type ==="modify"
                      ? <FormattedMessage id="T1652" /> // 수정하러 가기
                      : <span>등록하러 가기</span>
                    }
                  </Button>
                </td>
              </tr>
          }
        </tbody>
      </FormTable>
      {/* <HFBpdIframeModal
        type={type}
        // data={insertDatas}
        data={bPdData}
        params={params}
        visible={bpdIframeVisible}
        doubleCheckVisible={doubleCheckVisible}
        onModifyBpdIframe={onModifyBpdIframe}
        onOkBpdIframe={onOkBpdIframe}
        onCancelBpdIframe={onCancelBpdIframe}
        onOkDoubleCheck={onOkDoubleCheck}
        onCancelDoubleCheck={onCancelDoubleCheck}
      /> */}
    </AssignEnterBox>
  );
};

export default HFBpdEnterBasicBox;
