import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import { PageTemplate, Header, Footer, Tabs, Organization } from "components";
import Wrapper from "./Styled";
import AgentVerManage from "./AdminAgentTabs/AgentVerManage";
import AgentAddVer from "./AdminAgentTabs/AgentAddVer";
import AgentUpdateHistory from "./AdminAgentTabs/AgentUpdateHistory";
import AgentCertiList from "./AdminAgentTabs/AgentCertiList";
import AgentAddCerti from "./AdminAgentTabs/AgentAddCerti";

const { TabPane } = Tabs;

/**
 * Filename: AdminAgent/index.js
 * Description: Admin -> agent 버전 관리
 */

const initInputValues = {
  startDate: '',
  endDate: '',
  remark: ''
};

const AdminAgent = ({ history }) => {
  const { formatMessage } = useIntl();

  const [inputValues, setInputValues] = useState({ ...initInputValues });

  const [tabsValue, setTabsValue] = useState("1");
  const [type, setType] = useState("basic");
  const [addView, setAddView] = useState(false);
  const [addCertiView, setAddCertiView] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [rowInfo, setRowInfo] = useState([]);
  const [rowSslInfo, setRowSslInfo] = useState([]);
  const [agentTypeList, setAgentTypeList] = useState([]);
  const [agentSslList, setAgentSslList] = useState([]);
  const [orgaData, setOrgaData] = useState({});
  const [orgVisible, setOrgVisible] = useState(false);

  const onChangeTab = (key) => {
    setTabsValue(key);
    if (key === '3') {
      setType('certiList');
    } else {
      setType('basic');
    }
  };

  /* 파일 관련 */
  const onBeforeupload = (inFile) => {
    const tmpArr = [inFile];
    setFileList([...tmpArr]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpArr]);
  };

  const onclickDeleteFile = () => {
    const tmpObj = { ...rowSslInfo };
    delete tmpObj.fileId;
    delete tmpObj.down;
    setRowSslInfo({ ...tmpObj });
  };

  const onChangeSelectType = async (value) => {
    if (value === undefined) value = "";
    const result = await fetchAbsolute(
      "get",
      `/admin/agent/getAgentVerMasterList?type=${value}`
    );
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setAgentTypeList(
      [...result.data].sort((a, b) => {
        return a.ver.localeCompare(b.ver)
      })
    );
  };

  const onClickAddAgent = () => {
    setType("add");
    setAddView(true);
    setOrgaData({});
    setFileList([]);
  };

  const onClickAddSsl = () => {
    setType("addCerti");
    setAddCertiView(true);
    setOrgaData({});
    setFileList([]);
  }

  const onOkOrg = (data) => {
    if (data.length > 0) {
      setOrgaData({ ...data[0] });
    } else {
      setOrgaData({});
    }
    setOrgVisible(false);
  };

  const onClickOrg = () => {
    setOrgVisible(true);
  };

  const onCancelOrg = () => {
    setOrgVisible(false);
  };

  const onClickCancel = () => {
    setType('basic');
    setAddView(false);
    setOrgaData({});
    setFileList([]);
  };

  /* 클릭한 Agent 정보 가져오기 */
  const onClickEdit = async (data) => {
    const result = await fetchAbsolute(
      "get",
      `/common/memberNoList?empNo=${data.empNum}`
    );
    setRowInfo(data);
    setOrgaData({ ...result.data.mdFPAList[0] });
    setAddView(false);
    setType("add");
  };

  /* Agent SSL Info List 조회 */
  const getAgentSslList = async () => {
    const result = await fetchAbsolute('get', '/admin/agent/getAgentSslInfoList');
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setAgentSslList(result.data);
  }

  /* 클릭한 SSL 정보 가져오기 */
  const onClickEditSsl = async (data) => {
    // const testResult = await fetchAbsolute('get', `/admin/agent/getAgentSslInfo?sslInfoSeq=${data.sslInfoSeq}`); // 넘겨받는 data와 결과값이 같아서 주석
    // console.log(testResult)
    const result = await fetchAbsolute(
      "get",
      `/common/memberNoList?empNo=${data.empNum}`
    );
    setRowSslInfo(data);
    setOrgaData({ ...result.data.mdFPAList[0] });
    setType("addCerti");
    setAddCertiView(false);
    setFileList([]);
  }

  const onClickCancelSsl = () => {
    setType('certiList');
    setAddCertiView(false);
    setInputValues({ ...initInputValues });
    setOrgaData({});
    setFileList([]);
  };

  useEffect(() => {
    if (tabsValue !== '3') return;
    getAgentSslList();
  }, [tabsValue])

  useEffect(() => {
    onChangeSelectType("");
  }, []);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
      <Wrapper>
        <Tabs
          type="card"
          custom={false}
          activeKey={tabsValue}
          defaultActiveKey="1"
          onChange={onChangeTab}
        >
          <TabPane tab={formatMessage({ id: 'T0852' })} key="1">{/* Agent 버전 관리 */}
            {type === "basic" ? (
              <AgentVerManage
                type={type}
                setType={setType}
                agentTypeList={agentTypeList}
                onChangeSelectType={onChangeSelectType}
                onClickAddAgent={onClickAddAgent}
                onClickEdit={onClickEdit}
              />
            ) : type === "add" && (
              <AgentAddVer
                onBeforeupload={onBeforeupload}
                onRemoveFile={onRemoveFile}
                type={type}
                setType={setType}
                setOrgaData={setOrgaData}
                history={history}
                fileList={fileList}
                setFileList={setFileList}
                addView={addView}
                rowInfo={rowInfo}
                setRowInfo={setRowInfo}
                onChangeList={onChangeSelectType}
                onClickOrg={onClickOrg}
                orgaData={orgaData}
                onClickCancel={onClickCancel}
              />
            )}
          </TabPane>
          <TabPane tab={formatMessage({ id: 'T0853' })} key="2">{/* Agent 업데이트 이력 */}
            <AgentUpdateHistory />
          </TabPane>
          <TabPane tab={formatMessage({ id: 'T1295' })} key="3">{/* Runner Agent 인증서 */}
            {
              type === "addCerti" ?
                <AgentAddCerti
                  rowSslInfo={rowSslInfo}
                  addCertiView={addCertiView}
                  onClickCancelSsl={onClickCancelSsl}
                  orgaData={orgaData}
                  onClickOrg={onClickOrg}
                  onBeforeupload={onBeforeupload}
                  onRemoveFile={onRemoveFile}
                  fileList={fileList}
                  inputValues={inputValues}
                  setInputValues={setInputValues}
                  setType={setType}
                  getAgentSslList={getAgentSslList}
                  onclickDeleteFile={onclickDeleteFile}
                  setFileList={setFileList}
                />
                :
                type === 'certiList' &&
                <AgentCertiList
                  agentSslList={agentSslList}
                  onClickAddSsl={onClickAddSsl}
                  onClickEditSsl={onClickEditSsl}
                />
            }
          </TabPane>
        </Tabs>
      </Wrapper>
      <Organization
        visible={orgVisible}
        title={formatMessage({ id: 'T0465' })} // 담당자 검색
        onOk={onOkOrg}
        onCancel={onCancelOrg}
        defaultData={[orgaData]}
        max={1}
      />
    </PageTemplate>
  );
};

export default AdminAgent;