// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdw-image-alignment {
    display: inline !important;
}
    
.rdw-image-center {
    display: flex !important;
    justify-content: center;
}
    
.rdw-image-right {
    float: right !important;
}
    
.rdw-image-left {
    float: left !important;
}`, "",{"version":3,"sources":["webpack://./src/components/pages/AdminMessageTemplate/edit.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;IACxB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".rdw-image-alignment {\n    display: inline !important;\n}\n    \n.rdw-image-center {\n    display: flex !important;\n    justify-content: center;\n}\n    \n.rdw-image-right {\n    float: right !important;\n}\n    \n.rdw-image-left {\n    float: left !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
