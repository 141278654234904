import React, { useEffect, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';
import fetchAbsolute from "utils/fetchAbsolute";
import {
  Text,
  Button,
  FormTable,
  Upload,
  Input,
  InputSearch,
  Modal,
} from "components";
import { Input as AntInput } from 'antd';
import Wrapper from "../Styled";
import { Divider } from "antd";

/** 
 * Admin > Agent 버전 관리 > Runner Agent 인증서
 * Runner Agent JKS 인증서 추가/수정 화면
 *
 */


const { TextArea } = AntInput;

const AgentAddCerti = ({
  rowSslInfo,
  setFileList,
  addCertiView,
  onClickCancelSsl,
  orgaData,
  onClickOrg,
  onBeforeupload,
  onRemoveFile,
  fileList,
  inputValues,
  setInputValues,
  setType,
  getAgentSslList,
  onclickDeleteFile,
}) => {
  const END_POINT = process.env.REACT_APP_API_URI;

  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [deleteVisible, setDeleteVisible] = useState(false);

  const onClickDeleteAgentSsl = () => setDeleteVisible(true);
  const onCancelDeleteAgentSsl = () => setDeleteVisible(false);

  const onClickAddAgentSsl = async () => {
    /* 빈칸 검사 */
    if (!inputValues.startDate) {
      return alert(fmMessage({ id: 'T0128', values: { field: formatMessage({ id: "T1104" }) } })); // start date 항목은 필수 입력입니다.
    }
    if (!inputValues.endDate) {
      return alert(fmMessage({ id: 'T0128', values: { field: formatMessage({ id: "T1103" }) } })); // end date 항목은 필수 입력입니다.
    }
    if (fileList.length <= 0) return alert(formatMessage({ id: "T0226" })); // 업로드 파일은 필수 사항입니다. 
    if (!orgaData.empNum) return alert(formatMessage({ id: "T0222" })); // 담당자는 필수 사항입니다.

    /* Agent SSL 등록 */
    const formData = new FormData();

    const parameter = {
      "sslInfoSeq": 0,
      "startDate": inputValues.startDate,
      "endDate": inputValues.endDate,
      "empNum": orgaData.empNum,
      "remark": inputValues.remark || '',
    };

    Object.entries(parameter).forEach(([key, value]) => {
      formData.append(key, value);
    });

    fileList.forEach((file) => formData.append("files", file));

    const result = await fetchAbsolute('post', `/admin/agent/getAgentSslInfoSave`, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    alert(formatMessage({ id: 'T0224' })); // 추가되었습니다.
    getAgentSslList();
    setType('certiList');
  };

  /*  SSL 수정 기능 */
  const onClickEditAgentSsl = async () => {
    /* 빈칸 검사 */
    if (!inputValues.startDate) {
      return alert(fmMessage({ id: 'T0128', values: { field: formatMessage({ id: "T1104" }) } })); // start date 항목은 필수 입력입니다.
    };
    if (!inputValues.endDate) {
      return alert(fmMessage({ id: 'T0128', values: { field: formatMessage({ id: "T1103" }) } })); // end date 항목은 필수 입력입니다.
    };
    if ((!rowSslInfo.down && fileList.length === 0) || (rowSslInfo.fileId && fileList.length > 0)) {
      return alert(formatMessage({ id: 'T0221' }));
      // 하나의 업로드 파일을 올려주세요.
    };
    if (!orgaData.empNum) return alert(formatMessage({ id: "T0222" })); // 담당자는 필수 사항입니다.

    /* Agent SSL 수정 */
    const formData = new FormData();

    const parameter = {
      "sslInfoSeq": rowSslInfo.sslInfoSeq,
      "fileId": rowSslInfo.fileId,
      "startDate": inputValues.startDate,
      "endDate": inputValues.endDate,
      "empNum": orgaData.empNum,
      "remark": inputValues.remark || '',
    };

    if (!rowSslInfo.fileId) {
      delete parameter.fileId;
    }

    Object.entries(parameter).forEach(([key, value]) => {
      formData.append(key, value);
    });

    fileList.forEach((file) => formData.append("files", file));

    const result = await fetchAbsolute('post', `/admin/agent/getAgentSslInfoSave`, {
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    alert(formatMessage({ id: 'T0164' })); // 수정 완료되었습니다.
    setFileList([]);
    getAgentSslList();
    setType('certiList');
  };

  /* 삭제 버튼 기능 */
  const onOkDeleteAgentSsl = async () => {
    setDeleteVisible(false);
    const result = await fetchAbsolute('delete', `/admin/agent/getAgentSslInfoDel/${rowSslInfo.sslInfoSeq}`);
    if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    alert(formatMessage({ id: 'T0284' })); //삭제 되었습니다.  
    getAgentSslList();
    setType('certiList');
  }

  /* InputValues Setting */
  const onChangeValues = (e) => {
    const { name, value } = e.target;
    if (['startDate', 'endDate'].includes(name)) {
      const regDate = value.replace(/[^0-9]/g, '').replace(/^(\d{0,4})(\d{0,2})(\d{0,2})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
      setInputValues({ ...inputValues, [name]: regDate });
    } else {
      setInputValues({ ...inputValues, [name]: value });
    }
  };

  useEffect(() => {
    if (addCertiView) {
      setInputValues({});
    } else {
      setInputValues({
        startDate: rowSslInfo.startDate,
        endDate: rowSslInfo.endDate,
        remark: rowSslInfo.remark
      });
    };
  }, []);

  return (
    <Wrapper>
      <div className="common__container">
        <div className="agent__section">
          {addCertiView ? (
            <>
              <Text className="agent__title">
                <FormattedMessage id="T1298" />{/* Runner Agent 인증서 추가 */}
              </Text>
              <div className="agent__box">
                <FormTable>
                  <colgroup>
                    <col width="7.375%" />
                    <col width="16.625%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1297" /></Text></th>{/* start date */}
                      <td className="certification_period_section">
                        <Input
                          value={inputValues.startDate}
                          onChange={onChangeValues}
                          name='startDate'
                          maxLength={10}
                          placeholder={formatMessage({ id: 'T1104' })}// 'Start Date 입력'
                        />
                        ~
                        <Input
                          value={inputValues.endDate}
                          onChange={onChangeValues}
                          name='endDate'
                          maxLength={10}
                          placeholder={formatMessage({ id: 'T1103' })}// 'End Date 입력'
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}>
                          <FormattedMessage id="T1084" />{/* Update File */}
                        </Text>
                        <br />
                        <Text style={{ color: "D0D1D6", fontSize: 13 }}></Text>
                      </th>
                      <td>
                        <Upload
                          action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                          multiple={false}
                          maxCount={1}
                          beforeUpload={onBeforeupload}
                          onRemove={onRemoveFile}
                          fileList={fileList}
                          accept={'.jar'}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text><FormattedMessage id="T0456" /></Text>{/* 담당자 */}
                      </th>
                      <td>
                        <InputSearch
                          placeholder={formatMessage({ id: "T0857" })} // 담당자를 검색하세요
                          onClick={onClickOrg}
                          value={orgaData.empNam}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th><FormattedMessage id="T0582" /></th>{/* 비고 */}
                      <td>
                        <TextArea
                          value={inputValues.remark}
                          mexLengh={33} //varchar(100)
                          rows={2}
                          onChange={onChangeValues}
                          name='remark'
                          style={{ resize: 'none' }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </FormTable>
              </div>
            </>
          ) : (
            <>
              <Text className="agent__title">
                <FormattedMessage id="T1299" />{/* Runner Agent 인증서 수정 */}
              </Text>
              <div className="agent__box">
                <FormTable>
                  <colgroup>
                    <col width="7.375%" />
                    <col width="16.625%" />
                  </colgroup>
                  <tbody>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1297" /></Text></th>{/* start date */}
                      <td className="certification_period_section">
                        <Input
                          value={inputValues.startDate}
                          onChange={onChangeValues}
                          name='startDate'
                          maxLength={10}
                          placeholder={formatMessage({ id: 'T1104' })}// 'Start Date 입력'
                        />
                        ~
                        <Input
                          value={inputValues.endDate}
                          onChange={onChangeValues}
                          name='endDate'
                          maxLength={10}
                          placeholder={formatMessage({ id: 'T1103' })}// 'End Date 입력'
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text><FormattedMessage id="T0456" /></Text>{/* 담당자 */}
                      </th>
                      <td>
                        <InputSearch
                          placeholder={formatMessage({ id: "T0857" })} // 담당자를 검색하세요
                          onClick={onClickOrg}
                          value={orgaData.empNam}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <Text required={true}><FormattedMessage id="T1084" /></Text>{/* Update File */}
                        <br />
                        <Text style={{ color: "D0D1D6", fontSize: 13 }}>
                          <FormattedMessage id="T0804" />{/* (파일은 한번에 1개만 추가 가능합니다) */}
                        </Text>
                      </th>
                      <td>
                        <Upload
                          action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                          multiple={false}
                          maxCount={1}
                          beforeUpload={onBeforeupload}
                          onRemove={onRemoveFile}
                          fileList={fileList}
                          accept={'.jar'}
                        />
                        {rowSslInfo.down && (
                          <>
                            <Divider />
                            <div className="delete__file">
                              <Text>{rowSslInfo.fileName}</Text>
                              <Button
                                style={{ width: 50, height: 30 }}
                                type="default"
                                onClick={onclickDeleteFile}
                              >
                                <FormattedMessage id="T0072" />{/* 삭제 */}
                              </Button>
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <th><FormattedMessage id="T0582" /></th>{/* 비고 */}
                      <td>
                        <TextArea
                          value={inputValues.remark}
                          mexLengh={33} //varchar(100)
                          rows={2}
                          onChange={onChangeValues}
                          name='remark'
                          style={{ resize: 'none' }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </FormTable>
              </div>
            </>
          )}
          <div className="btn__section">
            {addCertiView ? (
              <Button type="primary" style={{ width: 120, height: 40 }} onClick={onClickAddAgentSsl}>
                <FormattedMessage id="T0049" />{/* 추가 */}
              </Button>
            ) : (
              <>
                <Button type="primary" style={{ width: 120, height: 40 }} onClick={onClickEditAgentSsl}>
                  <FormattedMessage id="T0054" />{/* 수정 */}
                </Button>
                <Button type="default" style={{ width: 120, height: 40 }} onClick={onClickDeleteAgentSsl}>
                  <FormattedMessage id="T0072" />{/* 삭제 */}
                </Button>
              </>
            )}
            <Button type="default" style={{ width: 120, height: 40 }} onClick={onClickCancelSsl}>
              <FormattedMessage id="T0051" />{/* 취소 */}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        centered
        visible={deleteVisible}
        width={500}
        okText={formatMessage({ id: "T0064" })} // 확인
        title={formatMessage({ id: "T0859" })} // Agent 버전 삭제
        onOk={onOkDeleteAgentSsl}
        onCancel={onCancelDeleteAgentSsl}
      >
        <div style={{ textAlign: "center" }}>
          <Text>{formatMessage({ id: "T1234" })}</Text>{/* 정말로 삭제 하시겠습니까? */}
        </div>
      </Modal>
    </Wrapper>
  )
}

export default AgentAddCerti;