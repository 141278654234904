import React, { useState } from 'react';
import { Rate, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { Text, Button } from 'components';
import moment from 'moment';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
const { TextArea } = Input;

const Popup = ({ research, empNo, onClickCloseResearch }) => {
    const { formatMessage } = useIntl();
    const researchResultSaveFail = formatMessage({ id: 'T0217' }); // 만족도 조사 결과 등록을 실패했습니다.
    const researchResultSaveSuccess = formatMessage({ id: 'T0218' }); // 만족도 조사 결과 등록을 완료했습니다.

    const [point, setPoint] = useState(0);
    const [comments, setComments] = useState('');

    const onChangePoint = (e) => {
        setPoint(e);
    }

    const onChangeComments = (e) => {
        const { value } = e.target;
        setComments(value);
    }

    const onClickSubmit = async () => {
        if (point === 0) {
            return alert(formatMessage({ id: "T1559" })) // 별을 눌러주세요
        } else {

            const result = await fetchAbsolute('post', `/admin/research/userResearchSave`, {
                data: {
                    rechDate: moment().format('YYYYMMDD'),
                    empNo: empNo,
                    point: point,
                    comments: comments,
                    seqR: +research.seqR
                }
            });
            if (result.error) {
                return alert(researchResultSaveFail);
            }
            alert(researchResultSaveSuccess);
            onClickClose();
        }
    }

    const onClickClose = () => {
        onClickCloseResearch(research.seqR);
    }

    return (
        <div className='main_popup__box'>
            <div className='main_popup__header'>
                <Text>{research.title}</Text>
                <CloseOutlined className='main_popup__header_close' onClick={onClickClose} />
            </div>
            <div className='main_popup__body_content'>
                <div>
                    <Rate
                        allowHalf
                        value={point}
                        onChange={onChangePoint}
                    />
                </div>
                <TextArea
                    value={comments}
                    onChange={onChangeComments}
                    className='main_popup__box_textarea'
                    placeholder={formatMessage({ id: "T1232" })}//만족도 코멘트를 입력해주세요.
                />
                <div className='flex justify-end'>
                    <Button
                        type='primary'
                        onClick={onClickSubmit}
                    >
                        {formatMessage({ id: 'T0050' })}{/* 등록 */}
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default Popup;