import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { setPathname, setMenuList } from "store/menuList/action";
import { setCrInfo } from "store/crInfo/action";
import { loadUserPermission } from "store/permission/action";
import { setCheckBoard } from "store/communityCheck/action";
import { setHeaderNotiListWithPeriod, setSideNotiListWithPeriod} from "store/notiList/action";
import fetchAbsolute from "utils/fetchAbsolute";
import { getUniqueKey } from "utils/Str";
import PermissionRoute from "./PermissionRoute";
import Loading from "../components/pages/Loading";
import {
  Home,
  DashboardTaskState,
  DashboardTimeSaving,
  DashboardInsight,
  DashboardROI,
  AssignEnter,
  AssignList,
  AssignLookup,
  AssignNewSchedule,
  WorkflowSchedule,
  HFWorkflowSchedule,
  MonitoringStatus,
  MonitoringRealtime,
  AdminMemberManage,
  MemberInfo,
  MyInformation,
  AdminMemberEdit,
  Boxwood,
  AdminDevicePool,
  AdminDevicePoolAdd,
  AdminDevicePoolUpdate,
  AdminCommonMaster,
  AdminCredential,
  AdminOrganization,
  AdminMenu,
  AdminWorkItem,
  AdminQueue,
  AdminQueueEdit,
  NotFound,
  ServerError,
  ClientError,
  AdminCrInfoInsert,
  AdminCrInfoUpdate,
  AdminBoard,
  AdminBoardCreate,
  AdminMainManage,
  RedirectHome,
  SharedApproval,
  SharedRefuse,
  TransferApproval,
  TransferRefuse,
  TransformApproval,
  TransformRefuse,
  DeployedApproval,
  DeployedRefuse,
  Community,
  CommunityLookup,
  CommunityEnter,
  AdminPopup,
  AdminPopupEdit,
  AdminCommonCd,
  AdminMessageTemplate,
  JediAcademy,
  JediYoungling,
  JediPadawan,
  JediKnight,
  JediMaster,
  JediAdminItemShop,
  JediItemShop,
  AdminLicense,
  AdminAgent,
  AdminSatisfaction,
  AdminSatisfactionEdit,
  IframePage,
  AdminCoeAssignManagement,
  AdminCoeAssignManagementEdit,
  AdminIternalSystemList,
  AdminIternalSystemEnter,
  ReadyForService,
  HFBpdList,
  HFBpdEnter,
  HFBpdLookup,
  HFWorkflowList,
  HFWorkflowEnter,
  HFWorkflowLookup,
  AdminEmailTrigger,
  AdminEmailTriggerEnter,
  HFMonitoring,
  AdminTest, // test
} from "components";

/**
 * Application 내 Route 기능을 담당하는 Class 입니다.
 *
 * @class
 */

const Routes = ({ profile, location, history }) => {
  const dispatch = useDispatch();

  const [userAuth, setUserAuth] = useState({});
  const [authLoading, setAuthLoading] = useState(true);
  const [routeList, setRouteList] = useState([]);
  const communityCheck = useSelector((state) =>
    state.get(getUniqueKey("communityCheck"))
  );

  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(setPathname(location.pathname));
    //페이지 이동시 최상단 고정
    window.scrollTo(0, 0);
    //게시판 관리자에서 off시 상단 메뉴에서 접속시 home으로
    if (location.pathname.includes(`/community/`)) {
      communityCheck.map((e) => {
        if (
          e.useYn === "N" &&
          location.pathname.includes(`/community/${e.boardId}`)
        ) {
          alert("게시판 관리에서 사용여부를 체크해주세요.");
          return history.push("/home");
        }
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    const fetchCrInfo = async () => {
      // ControlRoom List 저장
      const result = await fetchAbsolute("get", `/common/getControlRoomList`);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      const { data } = result;
      dispatch(setCrInfo([...data]));
    };
    const fetchBoardCheck = async () => {
      const result = await fetchAbsolute("get", `/board/boardMaList`);
      if (result.error) return alert(formatMessage({ id: "T0153" })); // 오류가 발생하였습니다.
      dispatch(setCheckBoard([...result.data.boardMaList]));
    };
    const fetchUser = async () => {
      // 로그인 한 User의 권한 확인
      setAuthLoading(true);
      const userResult = await fetchAbsolute(
        "get",
        `/common/memberNoList?empNo=${profile.EMP_NO}`
      );
      const codeResult = await fetchAbsolute(
        "get",
        `/common/commonCd/findByCategoryIdAndCd?categoryId=USER_LEVEL&cd=${userResult.data.mdFPAList[0]?.dwpUserLevel || "UA01"
        }`
      );
      const tmpArr = [
        <Route
          exact
          path="/"
          render={(props) => <RedirectHome {...props} />}
        />,
        <Route exact path="/home" render={() => <Home profile={profile} />} />,
        <Route
          exact
          path="/dashboard/taskstate"
          render={() => <DashboardTaskState profile={profile} />}
        />,
        <Route
          exact
          path="/dashboard/timesaving"
          render={() => <DashboardTimeSaving profile={profile} />}
        />,
        <Route
          exact
          path="/dashboard/insight"
          render={() => <DashboardInsight profile={profile} />}
        />,
        <Route
          exact
          path="/dashboard/roistate"
          render={() => <DashboardROI profile={profile} />}
        />,
        <Route
          exact
          path="/assign/enter"
          component={(props) => (
            <PermissionRoute type="user" fnCode="FN01" {...props}>
              <AssignEnter profile={profile} {...props} />
            </PermissionRoute>
          )}
        />,
        <Route
          path="/assign/modify/:taskId"
          component={(props) => (
            <PermissionRoute fnCode="FN02" {...props}>
              <AssignEnter profile={profile} {...props} type="modify" />
            </PermissionRoute>
          )}
        />,
        <Route
          path="/assign/recall/:taskId"
          component={(props) => (
            <PermissionRoute fnCode="FN03" {...props}>
              <AssignEnter profile={profile} {...props} type="recall" />
            </PermissionRoute>
          )}
        />,
        <Route
          path="/assign/list"
          component={(props) => <AssignList profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/assign/lookup/:taskId"
          component={(props) => <AssignLookup profile={profile} {...props} />}
        />,
        <Route
          exact // 23-06-21 이걸 추가했더니 tabValue가 넘어가기 시작(갑자기 안됐었음)
          path="/assign/lookup/:taskId/:tabValue"
          component={(props) => <AssignLookup profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/assign/newschedule"
          component={(props) => (
            <AssignNewSchedule profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/schedule"
          component={(props) => (
            <WorkflowSchedule profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/hfschedule"
          component={(props) => (
            <HFWorkflowSchedule profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/monitoring/status"
          component={(props) => (
            <MonitoringStatus profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/monitoring/realtime"
          component={(props) => (
            <MonitoringRealtime profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/jedaiacademy"
          component={(props) => <JediAcademy profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/community/:boardId/list"
          component={(props) => <Community profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/community/:boardId/:boardSeq/lookup"
          component={(props) => (
            <CommunityLookup profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/community/:boardId/enter"
          component={(props) => <CommunityEnter profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/community/:boardId/:boardSeq/modify"
          component={(props) => (
            <CommunityEnter type="modify" profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/community/:boardId/:boardSeq/:pboardSeq/modify"
          component={(props) => (
            <CommunityEnter type="modify" profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/community/:boardId/:boardSeq/reply"
          component={(props) => (
            <CommunityEnter type="reply" profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/iframe/:menuId"
          component={(props) => <IframePage profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jedaiacademy"
          component={(props) => <JediAcademy profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/station"
          component={(props) => <JediAcademy profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/youngling"
          component={(props) => <JediYoungling profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/padawan"
          component={(props) => <JediPadawan profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/jediknight"
          component={(props) => <JediKnight profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/master"
          component={(props) => <JediMaster profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/shared/approval/:taskId/:seq"
          component={(props) => <SharedApproval profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/shared/refuse/:taskId/:seq"
          component={(props) => <SharedRefuse profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/transfer/approval/:taskId/:seq"
          component={(props) => (
            <TransferApproval profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/transfer/refuse/:taskId/:seq"
          component={(props) => <TransferRefuse profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/transform/approval/:bpdId/:seq"
          component={(props) => (
            <TransformApproval profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/transform/refuse/:bpdId/:seq"
          component={(props) => <TransformRefuse profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/deployed/approval/:taskId/:seq"
          component={(props) => (
            <DeployedApproval profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/deployed/refuse/:taskId/:seq"
          component={(props) => <DeployedRefuse profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/readyforservice"
          component={(props) => (
            <ReadyForService profile={profile} {...props} />
          )}
        />,
        <Route path="/error/server" component={() => <ServerError />} />,
        <Route path="/error/client" component={() => <ClientError />} />,
        <Route
          path="/error/notfound"
          component={(props) => <NotFound {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/itemshop/:tabValue"
          component={(props) => <JediItemShop profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/jediacademy/itemshop"
          component={(props) => <JediItemShop profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/bizflow/hfbpd/list"
          component={(props) => <HFBpdList profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/bizflow/hfbpd/enter"
          component={(props) => <HFBpdEnter profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/bizflow/hfbpd/modify/:bpdId"
          component={(props) => (
            <HFBpdEnter type="modify" profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/hfbpd/lookup/:bpdId"
          component={(props) => <HFBpdLookup profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/bizflow/HFMonitoring"
          component={(props) => <HFMonitoring profile={profile} {...props} />}
        />,
        <Route
          exact
          path="/bizflow/hfworkflow/list"
          component={(props) => (
            <HFWorkflowList profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/hfworkflow/enter"
          component={(props) => (
            <HFWorkflowEnter profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/hfworkflow/modify/:workflowId"
          component={(props) => (
            <HFWorkflowEnter type="modify" profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/hfworkflow/lookup/:workflowId"
          component={(props) => (
            <HFWorkflowLookup profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/bizflow/hfworkflow/lookup/:workflowId/:tabValue"
          component={(props) => (
            <HFWorkflowLookup profile={profile} {...props} />
          )}
        />,
        <Route
          exact
          path="/myaccount/myinfo/:empNum"
          component={(props) => (
            <MyInformation profile={profile} type="myinfo" {...props} />
          )}
        />,
        <Route
          exact
          path="/edit/myaccount/modify/:empNum"
          component={(props) => (
            <MyInformation profile={profile} type="modify" {...props} />
          )}
        />,
      ];
      // if(profile.COMP_CD === '1000') {
      //   tmpArr.push(...[
      //   ])
      // }
      if (codeResult.data?.attrCd1 === "ADMIN") {
        tmpArr.push(
          ...[
            <Route
              exact
              path="/admin/board"
              component={(props) => <AdminBoard profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/board/create"
              component={(props) => (
                <AdminBoardCreate profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/board/:boardId/modify"
              component={(props) => (
                <AdminBoardCreate type="modify" profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/member"
              component={(props) => (
                <AdminMemberManage profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/member/manage/register"
              component={(props) => (
                <MemberInfo type="register" profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/member/:empNum"
              component={(props) => (
                <AdminMemberEdit profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/organization"
              component={(props) => (
                <AdminOrganization profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/devicepool"
              component={(props) => (
                <AdminDevicePool profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/devicepool/:crType/:devicePoolName/:crCode"
              component={(props) => (
                <AdminDevicePoolUpdate profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/devicepool/pooladd"
              component={(props) => (
                <AdminDevicePoolAdd profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/common"
              component={(props) => (
                <AdminCommonMaster profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/common/:tabValue"
              component={(props) => (
                <AdminCommonMaster profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/common/crinfo/crinsert"
              component={(props) => (
                <AdminCrInfoInsert profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/common/crinfo/:crcd"
              component={(props) => (
                <AdminCrInfoUpdate profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/credential"
              component={(props) => (
                <AdminCredential profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/menu/:tabValue"
              component={(props) => <AdminMenu profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/menu/"
              component={(props) => <AdminMenu profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/commoncd"
              component={(props) => (
                <AdminCommonCd profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/bizflow"
              component={() => <Boxwood profile={profile} />}
            />,
            <Route
              exact
              path="/admin/main/manage"
              component={(props) => (
                <AdminMainManage profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/agent/manage"
              component={(props) => <AdminAgent profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/popup/create"
              component={(props) => (
                <AdminPopupEdit profile={profile} type="create" {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/popup/modify/:noticeSeq"
              component={(props) => (
                <AdminPopupEdit profile={profile} type="modify" {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/popup"
              component={(props) => <AdminPopup profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/msgtemplate"
              component={(props) => (
                <AdminMessageTemplate profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/workitem"
              component={(props) => (
                <AdminWorkItem profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/queue"
              component={(props) => <AdminQueue profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/queue/:crCd/:queueName"
              component={(props) => (
                <AdminQueueEdit profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/license"
              component={(props) => (
                <AdminLicense profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/satisfaction"
              component={(props) => (
                <AdminSatisfaction profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/satisfaction/create"
              component={(props) => (
                <AdminSatisfactionEdit profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/satisfaction/modify/:seqR"
              component={(props) => (
                <AdminSatisfactionEdit
                  profile={profile}
                  type="modify"
                  {...props}
                />
              )}
            />,
            <Route
              exact
              path="/admin/coe/assignmanage"
              component={(props) => (
                <AdminCoeAssignManagement profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/coe/assignmanage/create"
              component={(props) => (
                <AdminCoeAssignManagementEdit profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/coe/assignmanage/modify/:taskId"
              component={(props) => (
                <AdminCoeAssignManagementEdit
                  profile={profile}
                  type="modify"
                  {...props}
                />
              )}
            />,
            <Route
              exact
              path="/admin/jediitem"
              component={(props) => (
                <JediAdminItemShop profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/jediitem/:tabValue"
              component={(props) => (
                <JediAdminItemShop profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/jediitem/:tabValue/:roundSeq"
              component={(props) => (
                <JediAdminItemShop profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/iternalsystem/list"
              component={(props) => (
                <AdminIternalSystemList profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/iternalsystem/enter"
              component={(props) => <AdminIternalSystemEnter profile={profile} {...props} />}
            />,
            <Route
              exact
              path="/admin/iternalsystem/modify/:systemCd"
              component={(props) => (
                <AdminIternalSystemEnter type="modify" profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/emailtrigger"
              component={(props) => (
                <AdminEmailTrigger profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/emailtrigger/enter"
              component={(props) => (
                <AdminEmailTriggerEnter profile={profile} {...props} />
              )}
            />,
            <Route
              exact
              path="/admin/emailtrigger/modify/:emailInfoSeq"
              component={(props) => (
                <AdminEmailTriggerEnter
                  type="modify"
                  profile={profile}
                  {...props}
                />
              )}
            />,
            /* Test Page */
            <Route
              exact
              path="/testpage"
              component={(props) => <AdminTest profile={profile} {...props} />}
            />,
          ]
        );
      }
      tmpArr.push(<Route component={() => <NotFound />} />);
      setRouteList([...tmpArr]);
      setAuthLoading(false);
      setUserAuth({ ...(codeResult.data || { attrCd1: "" }) });
    };

    fetchUser();
    fetchCrInfo();
    // fetchConnectorCategoryList();
    fetchBoardCheck();
    dispatch(setMenuList());
    dispatch(setHeaderNotiListWithPeriod());
    dispatch(setSideNotiListWithPeriod());
    dispatch(loadUserPermission());
  }, []);

  if (
    authLoading ||
    Object.keys(userAuth).length === 0 ||
    routeList.length === 0
  ) {
    return <Loading visible={true} />;
  }
  return (
    <Switch>
      {routeList.map((v, index) => {
        const newE = React.cloneElement(v, {
          key: index,
        });
        return newE;
      })}
    </Switch>
  );
};

export default Routes;
