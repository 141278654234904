import React, { useState, useRef } from "react";
import { withRouter } from 'react-router-dom';
import {
  Text,
  FormTable,
  Button
} from "components";
import { Tooltip, Typography } from "antd";
import { CopyIcon, EyeIcon } from "icons";
import { useIntl, FormattedMessage } from "react-intl";
import {
  HeartFilled,
  HeartOutlined,
  FileTextOutlined
} from "@ant-design/icons";
import fetchAbsolute from "utils/fetchAbsolute";
import { getUniqueKey } from "utils/Str";
import { useDispatch, useSelector } from "react-redux";
import { loadWorkflowData } from "store/hfWorkflowLookup/action";

/**
 * Workflow 목록 > Workflow 조회 (상세) > 왼편
 * 업무명 등 기본적인 정보
 */

const { Paragraph } = Typography;

const WorkflowInfo = ({history}) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));
  const listSearch = useSelector(state => state.get(getUniqueKey('listUrl')).hfWorkflowList);

  const [desEllipsis, setDesEllipsis] = useState(true);
  const [visibleCopyNm, setVisibleCopyNm] = useState(false);
  const [visibleCopyId, setVisibleCopyId] = useState(false);
  // 복사 버튼 클릭 시 1초 뒤에 사라지게 하는 timeOut 변수
  const copyNmTimeout = useRef(null);
  const copyIdTimeout = useRef(null);

  // 관심 과제 추가 및 삭제
  const onClickHeartButton = async (record) => {
    const { workflowId, interestGbn } = record;

    const taskYn = interestGbn === "Y" ? "N" : "Y";
    if (taskYn === "Y") {
      const addInterestPD = await fetchAbsolute(
        "get",
        `/workflow/boardRcmdcountSaveHF?workflowId=${workflowId}`
      );
      if (addInterestPD.status !== 200) {
        alert(formatMessage({ id: "T1235" })); //관심 저장 실패
      }
      dispatch(loadWorkflowData(workflowId));
      // fetchWorkflowData();
    } else if (taskYn === "N") {
      const delInterestPD = await fetchAbsolute(
        "get",
        `/workflow/boardRcmdcountDelHF?workflowId=${workflowId}`
      );
      if (delInterestPD.status !== 200) {
        alert(formatMessage({ id: 'T1241' })) //관심 삭제 실패
      }
      dispatch(loadWorkflowData(workflowId));
      // fetchWorkflowData();
    }
  };

  // 텍스트 카피
  const copyText = (text) => {
    const tempElem = document.createElement("textarea");
    tempElem.value = text;
    document.body.appendChild(tempElem);

    tempElem.select();
    document.execCommand("copy");
    document.body.removeChild(tempElem);
  };

  /* 과제명 복사 기능 */
  const onClickCopyNm = () => {
    copyText(hfWorkflowLookup.workflowData?.workflowNm);
    clearTimeout(copyNmTimeout.current);
    setVisibleCopyNm(true);
    copyNmTimeout.current = setTimeout(() => {
      setVisibleCopyNm(false);
    }, 1000);
  };

  /* 과제 ID 복사 기능 */
  const onClickCopyId = () => {
    copyText(hfWorkflowLookup.workflowData?.workflowId);
    clearTimeout(copyIdTimeout.current);
    setVisibleCopyId(true);
    copyIdTimeout.current = setTimeout(() => {
      setVisibleCopyId(false);
    }, 1000);
  };

  const [ellipsisStatus, setEllipsisStatus] = useState();

  return (
    <div className="assign_lookup__task_info">
      <div className="task_info__top_box">
        <Button
          type="primary"
          width="87"
          height="24"
          onClick={() =>
            history.push(`/bizflow/hfworkflow/list${listSearch.trim().length ? "?" + listSearch : ""}`)
          }
        >
          <FileTextOutlined />
          <FormattedMessage id="T1682" /> {/* 업무 목록 */}
        </Button>
      </div>
      <FormTable>
        <colgroup>
          <col width="8.375%" />
          <col width="16.625%" />
          <col width="7.375%" />
          <col width="17.625%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T1237" })}</Text>{/* 업무명 */}
            </th>
            <td colSpan={3}>
              <div className="icon__group">
                <div>
                  <Text style={{ fontSize: 18, wordBreak: "break-all" }}>{hfWorkflowLookup.workflowData?.workflowNm}</Text>&nbsp;
                  <Tooltip title={formatMessage({ id: "T0460" })} open={visibleCopyNm}>{/* 복사되었습니다. */}
                    <CopyIcon
                      className=""
                      onClick={onClickCopyNm}
                      color="#333"
                    />
                  </Tooltip>
                </div>
                <div className="task_info__tool_box">
                  <div className="readcnt_box">
                    <EyeIcon className="readcnd_box__icon" />
                    <Text className="readcnt_count">
                      {hfWorkflowLookup.workflowData?.readCnt > 99
                        ? "+99"
                        : hfWorkflowLookup.workflowData?.readCnt || 0}
                    </Text>
                  </div>
                  <div className="interest_box">
                    {hfWorkflowLookup.workflowData.interestGbn === "Y" ? (
                      <HeartFilled
                        className="interest_box__icon"
                        onClick={() => onClickHeartButton(hfWorkflowLookup.workflowData)}
                      />
                    ) : (
                      <HeartOutlined
                        className="interest_empty_box__icon"
                        onClick={() => onClickHeartButton(hfWorkflowLookup.workflowData)}
                      />
                    )}
                    <Text className="interest_count">
                      {hfWorkflowLookup.workflowData.interestCnt > 99
                        ? "+99"
                        : hfWorkflowLookup.workflowData.interestCnt}
                    </Text>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T1316" })}</Text>{/* 업무 ID */}
            </th>
            <td>
              <Text>{hfWorkflowLookup.workflowData?.workflowId}</Text>
              <Tooltip title={formatMessage({ id: "T0460" })} open={visibleCopyId}>{/* 복사되었습니다. */}
                <CopyIcon
                  className="task_name__copy"
                  onClick={onClickCopyId}
                  color="#848484"
                  style={{ paddingLeft: 8 }}
                />
              </Tooltip>
            </td>
            <th>
              <Text>{formatMessage({ id: "T0008" })}</Text>
              {/* 업무 분류 */}
            </th>
            <td>
              <Text>{hfWorkflowLookup.workflowData?.workPathNm}</Text>
            </td>
          </tr>
          <tr>
            <th>
              <Text>{formatMessage({ id: "T1236" })}</Text>
              {/* 업무 설명 */}
            </th>
            <td className="desc_break" colSpan={3}>
              <div className="flex justify-between ellipsis_box">
              <Paragraph 
                  className="font_14 ellipsis_text"
                  ellipsis={desEllipsis ? { 
                    rows: 3,
                    onEllipsis: (event) => setEllipsisStatus(event)
                  } : false}
                >
                  {
                    hfWorkflowLookup.workflowData?.workflowDesc
                      ?.split("\n")
                      .map((line) => {
                        return (
                          <>
                            {line}
                            <br />
                          </>
                        );
                    })
                    }
                </Paragraph>
                {
                  // ellpsisStatus가 true일때만 더보기 or 접기 버튼 보여주기
                  ellipsisStatus
                  &&
                    <div className="ellipsis_button_box">
                    { desEllipsis
                      ? 
                      <span
                        className="font_14 ellipsis_more"
                        onClick={() => setDesEllipsis(false)}
                      >
                          &lt;{formatMessage({ id: "T0096" })}{/* 더보기 */}
                      </span>
                      :
                      <span
                        className="font_14 ellipsis_more"
                        onClick={() => setDesEllipsis(true)}
                      >
                        &lt;{formatMessage({ id: "T0462" })}{/* 접기 */}
                      </span>
                      }
                    </div>
                  }
              </div>
            </td>
          </tr>
        </tbody>
      </FormTable>
    </div>
  );
};


export default withRouter(WorkflowInfo);