import React from "react";
import { Divider, Typography, } from 'antd';
import { Loading, Empty } from 'components';
import { useIntl } from "react-intl";
import { Bar } from '@ant-design/plots';

const { Title } = Typography;

/**
 * 대시보드 > ROI 현황 > 과제별 ROI
 */

const TaskROI = ({ byTaskList, isLoading }) => {
    const { formatMessage } = useIntl();

    const config = {
        color: '#ff7b00',
        xField: 'ROI',
        yField: 'type',
        legend: {
            position: 'top-left',
        },
        style: {
            textAlign: 'center',
            fontSize: 14,
            fontWeight: 500,
            fill: 'rgba(0,0,0,0.85)',
        },
        label: {
            position: 'outside',
            content: (item) => {
                return item.ROI.toFixed(2);
            },
        },
        offsetY: 6,
        // scrollbar: {
        //     type: 'vertical',
        // },
    }

    if (isLoading) {
        return <Loading visible={isLoading} />
    }
    return (
        <div className='box__container'>
            <div className='bot__title'>
                {/* 과제별 ROI */}
                <Title
                    className='main__title'
                    level={4}
                >
                    {formatMessage({ id: "T1525" })}
                </Title>
            </div>
            <Divider />
            {
                byTaskList.length > 0 ?
                    <Bar data={byTaskList} {...config} className="dashboard_graph" style={{ height: `calc(30px * ${byTaskList.length})`, minHeight: 270 }} /> : <Empty />
            }
        </div>
    )
}

export default TaskROI