import React, { useState } from "react";
import { PageTemplate, Header, Footer, Tabs } from "components";
import { useIntl } from "react-intl";
import Wrapper from './Styled';
import WelcomeBanner from "./AdminMainManageTabs/WelcomeBanner";
import GuideSteps from "./AdminMainManageTabs/GuideSteps";
import RankingStandard from "./AdminMainManageTabs/RankingStandard";

const { TabPane } = Tabs;

const AdminMainManage = () => {
  const { formatMessage } = useIntl();

  const [tabsValue, setTabsValue] = useState('');

  const onChangeTab = (key) => {
    setTabsValue(key);
  }

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
      <Wrapper>
        {
          <Tabs type="card" custom={false} activeKey={tabsValue} defaultActiveKey='1' onChangeTabs={onChangeTab}>
            <TabPane tab='Welcome Banner' key='1'>
              <WelcomeBanner />
            </TabPane>
            <TabPane tab={formatMessage({ id: 'T0797' })} key='2'>{/* 단계별 가이드 */}
              <GuideSteps />
            </TabPane>
            <TabPane tab={formatMessage({ id: 'T0798' })} key='3'>{/* 랭킹 기준 설정 */}
              <RankingStandard />
            </TabPane>
          </Tabs>
        }
      </Wrapper>
    </PageTemplate>
  );
}
export default AdminMainManage;
