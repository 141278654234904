import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)` 
    .ant-tabs-card .ant-tabs-content {
        margin-top: -17px;
    }
    .ant-tabs-nav-list {
        gap: 10px; 
    }
    .ant-tabs-nav-list .ant-tabs-tab,
    .ant-tabs-nav .ant-tabs-tab {
        width: 180px;
        text-align: center;
        background: #eaeaed;
    }
    .ant-tabs-nav-list .ant-tabs-tab:hover,
    .ant-tabs-nav .ant-tabs-tab:hover {
        color: #000;
    }
    .ant-tabs-tab.ant-tabs-tab-active {
        background: #fff !important;
    }  
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, 
    .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
        border-radius: 6px 6px 0 0;
        border: 1px solid #d0d1d6;
        font-size: 16px;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
        border-bottom: none;
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #000; 
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
        height: 40px;
        color: #000;
        background: #fff;
        border-bottom: none !important;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
        margin: 0px 10px 0 0;
        background: #eaeaed;
        border: 1px solid #d0d1d6;
        border-radius: 10px 10px 0 0;
    }

    .realtime_box {
        border: 1px solid #d0d1d6;
        background: #fff;
        padding: 20px 0;
    }
    .realtime__top_menu {
        display: flex;
        align-items: center;
        gap: 0 30px;
        padding: 0 46px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eaeaed;
    }
    .realtime__top_menu .top_menu__box {
        display: flex;
        gap: 0 20px;
        align-items: center;
        padding: 9px 32px;
        border-radius: 25px;
        background: #f8f8f8;
    }
    .realtime__top_menu .top_menu__box .top_menu__text {
        font-size: 14px;
        font-weight: bold;
    }
    .realtime__content_box {
        padding: 28px 48px;
    }
    .realtime__content_box.content__active {
        display: grid;
        grid-template-columns: repeat(4, 310px);
        gap: 20px 20px;
    }
    .btn__section {
        display: flex;
        gap: 10px;
    }
    .active_box {
        padding: 9px 14px;
        width: 100%;
        min-height: 430px;
        border: 1px solid #d0d1d6;
        background: #fff;
        transition: 0.4s;
        cursor: pointer;
    }
    .active_box__top {
        width: 100%;
        height: 168px;
        overflow: hidden;
    }
    .active_box__top img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .active_box__bottom {
        display: flex;
        flex-direction: column;
        gap: 4px 0;
        padding-top: 10px;
    }
    .active_box__bottom_row span {
        font-size: 12px;
        word-break: break-all;
    }
    .active_box__bottom_row.host_name .bottom_row__title {
        font-weight: bold;
        color: #ff6600;
    }
    .active_box__bottom_row.task_name .bottom_row__title {
        font-weight: bold;
        color: #ff7a00;
    }
    .active_box__bottom_row.agnt_status .bottom_row__title {
        font-weight: bold;
    }
    .active_box__bottom_row.agnt_status.agnt_started .bottom_row__content {
        color: #3dba61;
    }
    .active_box__bottom_row.agnt_status.agnt_empty .bottom_row__content {
        color: #2b65d9;
    }
    .active_box__bottom_row.agnt_status.agnt_failed.bottom_row__content {
        color: #ff0000;
    }
    .active_box:hover {
        border-color: #ff6600;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        transform: scale(1.05);
    }
    .realtime__table_wrap {
        display: flex;
        flex-direction: column;
        gap: 10px 0;
        width: 100%;
        min-height: 650px;
    }
    .realtime__table_wrap .realtime__table_header > span {
        font-size: 12px;
    }
    .realtime__table_wrap .realtime__table_header .realtime__table_page {
        margin-left: 12px;
    }
    .realtime__table_wrap .realtime__table_content {
        width: 100%;
    }

    /* select box */
    .ant-select-selection-item, .ant-select-selection-placeholder {
        font-size: 13px;
    }
   
`;

export default Wrapper;