import React from "react";
import {
  AssignEnterBox,
  Text,
  Input,
  Button,
  UserTag,
  FormTable,
  Upload,
  NameTagGroup,
} from "components";
import { Input as AntInput } from "antd";
import { useIntl, FormattedMessage } from "react-intl";
import { Select } from "antd";
 
const { Option } = Select;

const { TextArea } = AntInput;

/**
 * Filename: HFWorkflowEnterBasic/index.js
 * Description: workflow 등록 / 수정 -> 기본 정보
 */
const HFWorkflowEnterBasic = ({
  selectedBpdList,
  onChangeBpdList,
  bpdList,
  insertDatas,
  onChangeDatas,
  onClickBusinessOpen,
  requireFields,
  onRemoveFile,
  onBeforeupload,
  fileList,
  onClickOrgaOpen,
  onClickDelete,
}) => {
  const { formatMessage } = useIntl();
  const END_POINT = process.env.REACT_APP_API_URI;
  // const tmpFile = useRef([]);
  //const userPermission = useSelector(state => state.get(getUniqueKey('permission')).user);

  const taskWorkId = formatMessage({ id: "T0008" }); // 업무 분류

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "taskName" && value.length > 100) {
      return;
    } else if (name === "taskDesc" && value.length > 1000) {
      onChangeDatas(name, value.slice(0, 1000));
    } else {
      onChangeDatas(name, value);
    }
  };


  const onClickOrga = (e) => {
    const { label } = e.target.dataset;
    onClickOrgaOpen(label);
  }

  const onClickDeleteButton = (e, { label, index }) => {
    onClickDelete(label, index);
  }

  return (
    <AssignEnterBox title={formatMessage({ id: "T0424" })}>{/* 기본 정보 */}
      <FormTable style={{ tableLayout: 'fixed' }}>
        <colgroup>
          <col width="12.5%" />
          <col width="37.5%" />
          <col width="12.5%" />
          <col width="37.5%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text required={true}>
                <FormattedMessage id="T1237" />{/* 업무명 */}
              </Text>
            </th>
            <td colSpan={3}>
              <Input
                innerRef={requireFields.taskName.ref}
                name="taskName"
                value={insertDatas.taskName}
                onChange={onChangeInput}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>{taskWorkId}</Text>
            </th>
            <td>
              <div className="flex align-center">
                <Input
                  readOnly
                  style={{
                    width: 320,
                    marginRight: 20,
                  }}
                  innerRef={requireFields.businessClass.ref}
                  name="businessClass"
                  value={insertDatas.businessClass.cdPathNm}
                  onChange={onChangeInput}
                />
                <Button onClick={onClickBusinessOpen}>
                  <FormattedMessage id="T0048" />
                </Button>
                {/* 설정 */}
              </div>
            </td>
            <th>
              <Text required={true}>{formatMessage({ id: "T0429" })}</Text>{/* 등록자 */}
            </th>
            <td>
              <UserTag profile={insertDatas.totalManager} />
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>{formatMessage({ id: 'T0428' })}</Text>{/* 업무담당자 */}
            </th>
            <td colSpan={insertDatas.workManager.length > 2 ? 3 : 1}>
              {
                <Button
                  innerRef={requireFields.workManager.ref}
                  data-label='workManager'
                  onClick={onClickOrga}
                >
                  <FormattedMessage id="T0049" />{/* 추가 */}
                </Button>
              }
              <div className='flex flex-wrap-wrap' style={{ marginTop: 8, gap: '8px 10px' }}>
                {
                  NameTagGroup(insertDatas.workManager.map((v, index) => ({ ...v, isMain: index === 0 })), 'workManager', onClickDeleteButton)
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>{formatMessage({ id: "T0007" })}</Text>{/* 개발자 */}
            </th>
            <td colSpan={insertDatas.developers.length > 2 ? 3 : 1}>
              {
                <Button
                  innerRef={requireFields.developers.ref}
                  data-label='developers'
                  onClick={onClickOrga}
                >
                  <FormattedMessage id="T0049" />{/* 추가 */}
                </Button>
              }
              <div className='flex flex-wrap-wrap' style={{ marginTop: 8, gap: '8px 10px' }}>
                {
                  NameTagGroup(insertDatas.developers.map((v, index) => ({ ...v, isMain: index === 0 })), 'developers', onClickDeleteButton)
                }
              </div>
            </td>
          </tr>
          <tr>
            <th>
              <Text required={true}>
                <FormattedMessage id="T1236" />{/* 업무 설명 */}
              </Text>
            </th>
            <td colSpan={3}>
              <TextArea
                style={{ height: 120 }}
                maxLength={1000}
                name="taskDesc"
                value={insertDatas.taskDesc}
                onChange={onChangeInput}
                ref={requireFields.taskDesc.ref}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text>
                <FormattedMessage id="T0513" />{/* 첨부파일 */}
              </Text>
            </th>
            <td colSpan={3}>
              <Upload
                action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                multiple={true}
                beforeUpload={onBeforeupload}
                onRemove={onRemoveFile}
                fileList={fileList}
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text>Process Design</Text>
            </th>
            <td colSpan={3}>
              <div>
                <Select
                  disabled={Object.keys(insertDatas.businessClass).length === 0}
                  mode="multiple"
                  allowClear
                  style={{ width: 1048 }}
                  name="processDesign"
                  value={selectedBpdList || undefined}
                  onChange={onChangeBpdList}
                  placeholder={<FormattedMessage id="T0688" />} // 선택해주세요
                >
                  {bpdList.map((bpd) => (
                    <Option value={bpd.bpdId} key={bpd.bpdId} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                      [{bpd.bpdId}] {bpd.bpdNm}
                    </Option>
                  ))}
                </Select>
              </div>
            </td>
          </tr>
        </tbody>
      </FormTable>
    </AssignEnterBox>
  );
};

export default HFWorkflowEnterBasic;
