import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    width: 1400px;
    padding: 30px 50px;
    margin-bottom: 30px !important;
    .board_manage__top {
        display: flex;
        justify-content: space-between;
    }
    .board_manage__top_right {
        display: flex;
        align-items: flex-end;
        gap: 0 20px;
    }
    .board_manage__bottom {
        margin-top: 25px;
    }
    .board_manage__bottom td,
    .board_manage__bottom th {
        height: 49px;
        padding-top: 0;
        padding-bottom: 0;
    }
    /* 상태 radio 영역 */
    .ant-radio-inner {
        width: 20px;
        height: 20px;
        border: 1px solid #b0b1b5;
        background: #fff;
    }
    /* .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: #0067ac;
    } */
    .ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
        border-color: #ff7a00;
    }
    .ant-radio-wrapper { 
        font-size: 14px; 
        place-items: normal;
    }
    span.ant-radio + * {
        padding-right: 10px;
        padding-left: 5px;
    }
    /* 테이블 영역 */ 
    .ant-spin-container {
        gap: 8px 0;
    } 
    .ant-table-thead > tr > th {
      height: 50px;
      padding: 16px 16px 15px 20.3px;
    }
    .ant-table-tbody > tr > td {
      height: 50px;
      font-size: 14px;
      padding-left: 20px;
    } 
    .ant-table-pagination.ant-pagination { 
      padding-bottom: 84px;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td {
      color: #ff6600;
      text-decoration: underline;
    }
`;

export default Wrapper;