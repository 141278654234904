import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Text, Table, Button, RangePicker, ScheduleServiceLog } from "components";
import { LogIcon, MonitoringIcon } from "icons";
import { Tooltip } from "antd";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import fetchAbsolute from "utils/fetchAbsolute";
import ProgressModal from "components/atoms/ProgressModal";

/**
 * Workflow 조회 > 운영 현황  > 운영 현황
 * 
 */

const initialInputValues = {
  status: "",
  regFr: moment().format("YYYY-MM-DD"),
  regTo: moment().format("YYYY-MM-DD"),
};

const initialPageInfo = {
  total: 0,
  pageSize: 10,
  currentPage: 1,
}

const ScheduleProdStatus = ({ workflowData, operData, setOperData, params }) => {
  const { formatMessage } = useIntl();
  //const reducerData = useSelector(state => state.get(getUniqueKey('hfWorkflowLookup')));
  const [tableLoading, setTableLoading] = useState(false);
  //const [operData, setOperData] = useState([]);
  const [logVisible, setLogVisible] = useState(false);
  const [progressVisible, setProgressVisible] = useState(false);
  const [rowInfo, setRowInfo] = useState([]);
  const [progressData, setProgressData] = useState({});
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [pageInfo, setPageInfo] = useState({});

  const onCancelLog = () => setLogVisible(false);
  const onOkLog = () => setLogVisible(false);

  const [sortedInfo, setSortedInfo] = useState({});

  const onChangeTableData = (data, filters, sorter) => {
    setPageInfo({ ...pageInfo, currentPage: data.current });
    setSortedInfo(sorter);
  };

  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDataRange = ([regFr, regTo]) => {
    const tmp = { ...inputValues, regFr, regTo };
    setInputValues(tmp);
  };
  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDataRange(range);
  };

  const onClickLog = async (data, record) => {
    setRowInfo({
      ...record, workflowNm: workflowData.workflowNm, workflowId: workflowData.workflowId
    });
    setLogVisible(true);
  };

  const onClickProgress = (data) => {
    setProgressData(data);
    setProgressVisible(true);
  }

  const onCancelProgress = () => {
    setProgressVisible(false);
    setModalMaximization(false);
  }


  /* 운영 현황 > 초기화 기능 */
  const onClickResetCondition = async () => {
    setInputValues({ ...initialInputValues });
    getOperStatusList({ ...initialInputValues });
  };

  // const testData = new Date('2023-01-11');
  // const testData2 = new Date('2023-07-01');

  /* 운영 현황 목록 */
  const getOperStatusList = async (values) => {
    let searchValues = { ...inputValues };
    /* 초기화 기능 때문에 필요함 */
    if (values !== undefined) {
      searchValues = { ...values }
    }
    setTableLoading(true);
    const { data } = await fetchAbsolute(
      "post", `/workflow/workflowOperationStatusHF`, {
      data: {
        processId: workflowData.processId,
        fromDate: searchValues.regFr,
        toDate: searchValues.regTo
      }
    });
    //데이터 처리해주는 부분 삭제
    setTableLoading(false);
    setPageInfo({ ...initialPageInfo, total: data.length, currentPage: 1 });
    //같은 id 데이터가 중복으로 나와서 중복 없애줌ㄴ
    const removeDuplication = data.reduce((acc, current) => {
      const x = acc.find(item => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    setOperData(removeDuplication);
    setSortedInfo({
      order: 'descend',
      columnKey: 'startDate',
    });
  };

  const onClickSearchProd = () => {
    getOperStatusList();
  }

  // 모달창 최대/최소화
  const [modalMaximization, setModalMaximization] = useState(false);
  const onMaximizeModal = () => setModalMaximization(true);
  const onMinimizeModal = () => setModalMaximization(false);
  const [overlap, setOverlap] = useState(false);


  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      align: 'center',
      key: 'id',
      width: 200,
    },
    {
      title: 'Result',
      dataIndex: "result",
      align: 'center',
      key: "result",
      render: (result) => result,
      sorter: (a, b) => { return a.result.localeCompare(b.result) },
      sortOrder: sortedInfo.columnKey === 'result' ? sortedInfo.order : null,
    },
    {
      title: 'Version',
      dataIndex: "version",
      align: 'center',
      key: "version",
      render: (version) => version,
      // sorter: (a, b) => { return a.version.localeCompare(b.version)},
      sorter: (a, b) => { return a.version - b.version },
      sortOrder: sortedInfo.columnKey === 'version' ? sortedInfo.order : null,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      width: 200,
      defaultSortOrder: "descend",
      sortOrder: sortedInfo.columnKey === 'startDate' ? sortedInfo.order : null,
      // sortOrder: sortedInfo.columnKey === 'startDate'? sortedInfo.order : null,
      // sorter: (a, b) =>
      //   +moment(a.startDate).format("YYYYMMDDHHmmss") -
      //   +moment(b.startDate).format("YYYYMMDDHHmmss"),
      sorter: (a, b) => a.startDate.localeCompare(b.startDate),
      render: (data) => { return moment(data).format('YYYY-MM-DD HH:mm:ss') }
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      width: 200,
      sortOrder: sortedInfo.columnKey === 'endDate' ? sortedInfo.order : null,
      sorter: (a, b) => a.endDate.localeCompare(b.endDate),
      render: (data) => { return data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '' }
    },
    {
      title: 'Log',
      key: "id",
      dataIndex: "id",
      align: 'center',
      width: 200,
      render: (data, recordData, index) => (
        <div className="prod_icons" key={index}>
          {
            // 완료인 경우에만 안보이게
            recordData.result !== '완료' &&
            <Tooltip title={formatMessage({ id: "T1592" })}>{/* 진행 상태 */}
              <MonitoringIcon
                onClick={() => onClickProgress(recordData)}
                className="abled_icons"
              />
            </Tooltip>
          }
          <Tooltip title={formatMessage({ id: "T0570" })}>{/* 로그 기록 */}
            <LogIcon onClick={() => onClickLog(data, recordData)}
              className="abled_icons" />
          </Tooltip>
        </div>
      )
    },
  ];

  useEffect(() => {
    if (!params) return;
    getOperStatusList();
  }, [params]);

  return (
    <>
      <div className="status_box">
        <div className="status_box__title">
          <Text>{formatMessage({ id: 'T0386' })}</Text>{/* 운영 현황 */}
        </div>
        <div className="status_box__info">
          <Table
            columns={columns}
            rowKey={recordData => recordData.id + recordData.procStatus}
            dataSource={operData}
            loading={tableLoading}
            size="small"
            onChange={(data, filters, sorter) => onChangeTableData(data, filters, sorter)}
            // onClick={(sort)=>test(sort)}
            defaultPageSize={10}
            // pageSizeVisible={false}
            pagination={true}
            pageOptions={{
              total: pageInfo.length,
              current: pageInfo.currentPage,
              pageSize: pageInfo.pageSize,
            }}
            title={() => {
              return (
                <div className="top__container">
                  <div className="left">
                    <div>
                      <Text>
                        <FormattedMessage id="T1104" />&nbsp;
                      </Text>
                      <RangePicker
                        onChangeDate={onChangeDates}
                        onCalendarChange={onChangeDates}
                        format="YYYY-MM-DD"
                        value={[
                          inputValues.regFr && inputValues.regFr.length > 0
                            ? moment(inputValues.regFr)
                            : undefined,
                          inputValues.regTo && inputValues.regTo.length > 0
                            ? moment(inputValues.regTo)
                            : undefined,
                        ]}
                        defaultValue={[undefined, undefined]}
                        disabledDate={(current) => {
                          return (current < moment().subtract(1, "month")) ||
                            (current > moment().add(0, "day"))
                        }}
                        allowClear={true}
                      />
                    </div>
                  </div>
                  <div className="btn__company">
                    <Button type="primary" onClick={onClickSearchProd}>
                      <FormattedMessage id="T0039" />
                    </Button>
                    <Button type="default" onClick={onClickResetCondition}>
                      <FormattedMessage id="T0037" />
                    </Button>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
      <ScheduleServiceLog
        type="prod"
        recordData={rowInfo}
        visible={logVisible}
        onOk={onOkLog}
        onCancel={onCancelLog}
      />
      <ProgressModal
        // visible={true} 
        visible={progressVisible}
        data={progressData}
        onCancel={onCancelProgress}
        modalMaximization={modalMaximization}
        setModalMaximization={setModalMaximization}
        onMaximizeModal={onMaximizeModal}
        onMinimizeModal={onMinimizeModal}
        overlap={overlap}
      />
    </>
  );
};

export default withRouter(ScheduleProdStatus);