import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    background: #fff;
    .admin_member__table_wrap {
        margin-top: 30px;
    }
    .admin_member__button_group {
        display: flex;
        justify-content: flex-end;
        gap: 0 25px;
    }
    thead[class*="ant-table-thead"] th{
        font-weight: bold;
      }
`;

export default Wrapper;