import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  PageTemplate,
  Header,
  Footer,
  AssignEnterBox,
  HFWorkflowEnterBasic,
  Button,
  BusinessClass,
  HFWorkflowIframe,
  Organization,
} from "components";
import Container from "containers/Container";
import fetchAbsolute from "utils/fetchAbsolute";
import { setVisible } from "store/loading/action";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

/**
 * Filename: HFWorkflowEnter/index.js
 * Description: 워크플로우 등록 컴포넌트
 */

const HFWorkflowEnter = ({
  profile,
  history,
  type = "enter",
  match: {
    params, params: { workflowId: mworkflowId },
  },
}) => {
  const tmpFile = useRef([]);
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();
  const dispatch = useDispatch();

  const [requireFields, setRequireFields] = useState({
    businessClass: {
      ref: useRef(null),
      message: formatMessage({ id: "T0008" }), // 업무 분류
      use: true,
    },
    taskName: {
      ref: useRef(null),
      message: formatMessage({ id: "T1237" }), // 업무명
      use: true,
    },
    workManager: {
      ref: useRef(null),
      message: formatMessage({ id: "T0428" }), // 업무담당자
      use: true,
    },
    developers: {
      ref: useRef(null),
      message: formatMessage({ id: "T0007" }), // 개발자
      use: true,
    },
    taskDesc: {
      ref: useRef(null),
      message: formatMessage({ id: "T1236" }), // 업무 설명
      use: true,
    },
  });

  const [basicDatas, setBasicDatas] = useState({
    businessClass: {},
    taskName: "",
    department: [],
    totalManager: profile,
    taskDesc: "",
    workManager: [],
    developers: [],
  });

  const [lookupWorkflow, setLookupWorkflow] = useState({});
  const [fileList, setFileList] = useState([]);
  const [organLabel, setOrganLabel] = useState("");
  const [organData, setOrganData] = useState([]);
  const [defaultFiles, setDefaultFiles] = useState([]);
  const [visibleBusiness, setVisibleBusiness] = useState(false);
  const [fixedMain, setFixedMain] = useState(false);
  const [visibleOrgan, setVisibleOrgan] = useState(false);
  const [workflowType, setWorkflowType] = useState(
    type === "enter" ? "insert" : type === "lookup"
  );
  const [workflowData, setWorkflowData] = useState({});
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [bpdList, setBpdList] = useState([]);
  const [selectedBpdList, setSelectedBpdList] = useState();

  const setLoading = async (cb) => {
    dispatch(setVisible(true));
    await cb();
    dispatch(setVisible(false));
  };

  const onChangeBpdList = (e) => {
    setSelectedBpdList(e);
  };

  const onOkOrgan = async (data) => {
    setVisibleOrgan(false);
    setBasicDatas({ ...basicDatas, [organLabel]: [...data] });
  };

  const onCancelOrgan = () => setVisibleOrgan(false);

  //첨부파일
  const onBeforeupload = (inFile) => {
    if (fileList.findIndex((file) => file.name === inFile.name) !== -1) {
      alert(
        fmMessage({
          id: "T1287",
          values: { field: formatMessage({ id: "T1288" }) },
        })
      );
      // 같은 이름의 파일은/을 업로드 할 수 없습니다.
      return false;
    }
    if (inFile.size > 1024 * 1024 * 100) {
      return alert(
        fmMessage({
          id: "T1571",
          values: { field: Math.round((inFile.size / 1024 / 1024) * 100) / 100 },
        })
      );
      // 100MB 이하 파일만 등록할 수 있습니다.\n\n "현재파일 용량 : {field}MB
    }
    tmpFile.current.push(inFile);
    setFileList([...tmpFile.current]);
    return false;
  };

  const removeUploadedFile = (seq) => {
    setDeleteFiles(deleteFiles.concat(seq));
  };

  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) removeUploadedFile(rmFile.seq);
    tmpFile.current = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpFile.current]);
  };

  const onChangeBasicDatas = async (name, value) => {
    setBasicDatas({ ...basicDatas, [name]: value });
  };
  const onOkBusiness = (data) => {
    setVisibleBusiness(false);
    setBasicDatas({
      ...basicDatas,
      businessClass: data.length > 0 ? data[0] : {},
    });
  };
  // Modal 창들 Cancel 시 발생 이벤트

  const onCancelBusiness = () => setVisibleBusiness(false);
  const onClickBusinessOpen = () => setVisibleBusiness(true);

  // 목록 삭제 이벤트
  const onClickDeleteBasic = (name, index) => {
    const tmpArr = [...basicDatas[name]];
    tmpArr.splice(index, 1);
    setBasicDatas({ ...basicDatas, [name]: tmpArr });
  };

  const onClickCancelButton = () => {
    if (type === "enter") {
      const path = "/bizflow/hfworkflow/list";
      history.push(path);
    } else {
      const path = `/bizflow/hfworkflow/lookup/${mworkflowId}`;
      history.push(path);
    }
  };

  // Modal Open 이벤트
  const onClickOrgaOpen = (label) => {
    if (type !== "modify" || label === "developers") {
      setFixedMain(false);
    } else {
      setFixedMain(true);
    }
    setVisibleOrgan(true);
    setOrganLabel(label);
    setOrganData(basicDatas[label]);
  };

  // Request Parameter 생성 함수
  const setRequestParameter = (data) => {
    const parameter = {
      workflowId: data.workflowId,
      workflowNm: data.taskName,
      workflowDesc: data.taskDesc,
      workId: data.businessClass.cd,
      bpdIdList: data.selectedBpdList,
      processId: data.processId,
      useYn: "Y",
      fileId: data.fileId,
      wfMemberHFList: data.wfMemberHFList
    }
    if (type === 'enter') {
      delete parameter.workflowId;
      // delete parameter.bpdOrgCd;
    }
    if (fileList.length === 0) {
      delete parameter.fileId;
    }
    return parameter;
  }

  /* 기본 정보 저장 */
  const onClickTempSave = async (data) => {
    let parameter = {};

    if (type === 'modify') {
      parameter = {
        workflowNm: data.workflowNm,
        processId: data.processId,
        workflowId: data.workflowId,
      }
      if (data.workflowNm === '') return alert(
        fmMessage({ id: "T0128", values: { field: formatMessage({ id: "T1237" }) } }) // 업무명은 항목은 필수 입력입니다.
      );
    } else {
      // 필수 입력 항목 체크
      for (const key in requireFields) {
        const field = requireFields[key];
        if (!field.use) continue;
        if (
          (key !== "ruleAcquaint" && Object.keys(basicDatas[key]).length <= 0) ||
          (key === "ruleAcquaint" && !basicDatas[key])
        ) {
          field.ref.current.focus();
          return alert(
            fmMessage({ id: "T0128", values: { field: field.message } })
          );
          //`${field.message} 항목은 필수 입력입니다.`);
        }
      }
      parameter = { workflowNm: data.taskName }
      if (data.taskName === '') return alert(
        fmMessage({ id: "T0128", values: { field: formatMessage({ id: "T1237" }) } }) // 업무명은 항목은 필수 입력입니다.
      );
    }

    // file 첨부했을 경우
    const formData = new FormData();
    fileList
      .filter((file) => !file.uploaded)
      .forEach((file) => formData.append("files", file));

    const fResult = await fetchAbsolute(
      "post",
      "/file/uploadMultipleFiles",
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    const result = await fetchAbsolute("post", "/workflow/tempSaveWorkflowHF", {
      data: { ...parameter },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.error) {
      return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
    }
    const { data: { processId } } = result;
    setWorkflowData({ ...workflowData, processId });
    if (fileList.length > 0) { // 첨부 파일이 있을 때
      setBasicDatas({ ...basicDatas, fileId: fResult.data[0]?.fileId || data.fileId });
    }

    if (type !== 'modify') return alert(formatMessage({ id: "T0249" })); // 저장에 되었습니다.
  }

  // 등록
  const onClickSubmit = async () => {
    // 필수 입력 항목 체크
    for (const key in requireFields) {
      const field = requireFields[key];
      if (!field.use) continue;
      if (
        (key !== "ruleAcquaint" && Object.keys(basicDatas[key]).length <= 0) ||
        (key === "ruleAcquaint" && !basicDatas[key])
      ) {
        field.ref.current.focus();
        return alert(
          fmMessage({ id: "T0128", values: { field: field.message } })
        );
        //`${field.message} 항목은 필수 입력입니다.`);
      }
    }

    // file 첨부했을 경우
    const formData = new FormData();
    fileList
      .filter((file) => !file.uploaded)
      .forEach((file) => formData.append("files", file));

    const fResult = await fetchAbsolute(
      "post",
      "/file/uploadMultipleFiles",
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );


    if (fileList.length > 0) { // 첨부 파일이 있을 때
      setBasicDatas({ ...basicDatas, fileId: fResult.data[0]?.fileId });
    }

    const wfMemberHFList = [
      ...basicDatas.workManager.map((v, index) => ({
        mgrDevGbn: "M",
        empNo: v.empNum,
        deputyHeadYn: index === 0 ? "N" : "Y",
      })),
      ...basicDatas.developers.map((v, index) => ({
        mgrDevGbn: "D",
        empNo: v.empNum,
        deputyHeadYn: index === 0 ? "N" : "Y",
      })),
    ];

    const mergeData = { ...basicDatas, wfMemberHFList, ...workflowData, selectedBpdList, fileId: fResult.data[0]?.fileId };
    const parameter = setRequestParameter(mergeData);

    const result = await fetchAbsolute("post", `/workflow/saveWorkflowHF`, {
      data: { ...parameter },
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (result.error) {
      return alert(formatMessage({ id: "T0235" })); // 저장에 실패하였습니다.
    } else {
      alert(formatMessage({ id: "T0249" })); // 저장에 되었습니다.
      const path = "/bizflow/hfworkflow/list";
      history.push(path);
      return;
    }
  };

  const onClickModify = async () => {
    const fetchData = async () => {
      //필수 입력 항목 체크
      for (const key in requireFields) {
        const field = requireFields[key];
        if (!field.use) continue;
        if (
          (key !== "ruleAcquaint" && Object.keys(basicDatas[key]).length <= 0) ||
          (key === "ruleAcquaint" && !basicDatas[key])
        ) {
          field.ref.current.focus();
          return alert(
            fmMessage({ id: "T0128", values: { field: field.message } })
          );
          //`${field.message} 항목은 필수 입력입니다.`);
        }
      }

      if (deleteFiles.length !== 0) {
        for (let i = 0; i < deleteFiles.length; i++) {
          const seq = deleteFiles[i];
          await fetchAbsolute("post", `/file/deleteFile/${seq}`);
        }
      }

      // file 첨부했을 경우
      const formData = new FormData();
      fileList
        .filter((file) => !file.uploaded)
        .forEach((file) => formData.append("files", file));

      formData.append("fileId", basicDatas.fileId);

      const fResult = await fetchAbsolute(
        "post",
        "/file/uploadMultipleFiles",
        {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let fileId = lookupWorkflow.fileId;

      // 첨부된 파일이 있으면 fileId를 받은 다음에 modify 진행
      if (fResult.data.length > 0) {
        fileId = fResult.data[0].fileId;
      }

      // 담당자 관련
      const wfMemberHFList = [
        ...basicDatas.workManager.map((v, index) => ({
          mgrDevGbn: "M",
          empNo: v.empNum,
          deputyHeadYn: index === 0 ? "N" : "Y",
        })),
        ...basicDatas.developers.map((v, index) => ({
          mgrDevGbn: "D",
          empNo: v.empNum,
          deputyHeadYn: index === 0 ? "N" : "Y",
        })),
      ];

      const mergeData = {
        ...lookupWorkflow, ...basicDatas, selectedBpdList, workflowData, lookupWorkflow, wfMemberHFList, fileId
      }
      const parameter = setRequestParameter(mergeData);

      const result = await fetchAbsolute("post", `/workflow/saveWorkflowHF`, {
        data: { ...parameter },
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      if (result.error) {
        return alert(`Workflow ${formatMessage({ id: "T0235" })}`); // 저장에 실패하였습니다.
      } else {
        alert(`Workflow ${formatMessage({ id: "T0249" })}`); // 저장에 되었습니다.
        const path = `/bizflow/hfworkflow/lookup/${mworkflowId}`;
        history.push(path);
        return;
      }
    };
    setLoading(fetchData);
  };

  //수정시
  useEffect(() => {
    if (!mworkflowId) return;
    const fetchTaskInfo = async () => {
      const result = await fetchAbsolute("get", `/workflow/workflowInfoHF?workflowId=${mworkflowId}`);
      const { data } = result;

      if (result.redirectURL) {
        return history.replace(result.redirectURL);
      }
      if (Object.keys(data).length === 0) {
        return history.push("/error/notfound");
      }
      if (data.fileList !== undefined) {
        const fileData = data.fileList?.map((file) => ({
          name: file.fileName,
          uploaded: true,
          seq: file.seq,
          uid: file.seq,
        }));
        setFileList([...fileData]);
        setDefaultFiles([...fileData]);
        setLookupWorkflow({ ...data, fileData });
      } else {
        setLookupWorkflow({ ...data });
      }

      setRequireFields({ ...requireFields });

      const workManager = data.wfMemberHFList
        .filter(v => v.mgrDevGbn === 'M')
        .map(v => ({
          ...v,
          empNam: v.empNm,
          empNum: v.empNo,
          orgNam: v.orgNm
        }));
      const developers = data.wfMemberHFList
        .filter(v => v.mgrDevGbn === 'D')
        .map(v => ({
          ...v,
          empNam: v.empNm,
          empNum: v.empNo,
          orgNam: v.orgNm
        }))

      // 관련 부서 workflowOrgCd
      const departResult = await fetchAbsolute(
        "get",
        `/common/departmentList?orgCd=${data.workflowOrgCd}`
      );

      setBasicDatas({
        businessClass: { cd: data.workId, cdPathNm: data.workNm },
        taskName: data.workflowNm,
        department: departResult.data?.mdList,
        totalManager: {
          NAME_KOR: data.regEmpNam,
          EMP_NO: data.regId,
          DEPT_NAME_KOR: data.orgNam,
        },
        taskDesc: data.workflowDesc,
        fileId: data.fileId,
        workManager,
        developers,
      });

      const tempBpdList = data.bpdInfoList.map((e) => e.bpdId);

      setWorkflowType("lookup"); // 개발자의 경우 수정됐었는데, 이 부분이 삭제 됨
      setWorkflowData(data);
      setSelectedBpdList(tempBpdList);
      // 수정화면에서 check-out을 위해 tempSave 호출
      // onClickTempSave(data);
    };
    setLoading(fetchTaskInfo);
  }, [mworkflowId]);

  /* Business Process Design 선택 목록 */
  useEffect(() => {
    if (!basicDatas.businessClass.cd) return;
    const fetchData = async () => {
      const result = await fetchAbsolute("post", `/bpd/bpdListHFExcel?workId=${basicDatas.businessClass.cd}`, {
        data: {
          workId: [basicDatas.businessClass.cd]
        }
      }
      );
      setBpdList([...result.data]);
    };
    fetchData();
  }, [basicDatas.businessClass]);

  useEffect(() => {
    if (defaultFiles.length > 0) tmpFile.current = [...defaultFiles];
  }, [defaultFiles]);

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Container
        style={{ display: "flex", flexDirection: "column", gap: "30px 0" }}
      >
        {
          // 업무 담당자인 경우 -> 수정 가능
          // 개발자인 경우 -> 조회만 가능
        }
        <div
          style={(type === 'modify' &&
            (lookupWorkflow.empLevGbns?.find(v => v === 'M' || v === 'A'))) ?
            {} : type === 'modify' ? { pointerEvents: 'none' } : {}
          }
        >
          <HFWorkflowEnterBasic
            selectedBpdList={selectedBpdList}
            onChangeBpdList={onChangeBpdList}
            bpdList={bpdList}
            insertDatas={basicDatas}
            onChangeDatas={onChangeBasicDatas}
            onClickBusinessOpen={onClickBusinessOpen}
            onClickDelete={onClickDeleteBasic}
            requireFields={requireFields}
            type={type}
            onBeforeupload={onBeforeupload}
            onRemoveFile={onRemoveFile}
            fileList={fileList}
            onClickOrgaOpen={onClickOrgaOpen}
          />
        </div>
        {/* {
          !mworkflowId &&
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button
              type={isSavedBasicInfo ? "disabled" : "primary"}
              onClick={() => onClickTempSave(basicDatas)}
              disabled={isSavedBasicInfo}
            >
              <FormattedMessage id="T1560" /> 
            </Button>
          </div>
        } */}
        {/* ------ HandyFlow workflow 부분 (수정할 때만 노출) ------ */}
        {
          // 업무 담당자인 경우, 진행상태가 접수(WS10)가 아닌 경우
        }
        {
          type === 'modify' && mworkflowId && (lookupWorkflow.empLevGbns?.find(v => v === 'M' || v === 'A')) && (lookupWorkflow.statusCd !== 'WS10') &&
          <div>
            <AssignEnterBox title="Workflow">
              <HFWorkflowIframe
                key='enter'
                profile={profile}
                type={workflowType}
                data={workflowData || {}}
                params={params}
              />
            </AssignEnterBox>
          </div>
        }
        <div className="flex justify-end">
          {type === "enter" ? (
            <Button
              type="primary"
              style={{ width: 150, height: 40 }}
              onClick={onClickSubmit}
            >
              <FormattedMessage id="T0050" />{/* 등록 */}
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ width: 150, height: 40 }}
              onClick={onClickModify}
            >
              {type === "modify" && <FormattedMessage id="T0054" />}
              {/* 수정 */}
            </Button>
          )}
          <Button
            style={{ width: 150, height: 40, marginLeft: 30 }}
            onClick={onClickCancelButton}
          >
            <FormattedMessage id="T0051" />{/* 취소 */}
          </Button>
        </div>
      </Container>
      {/* 업무분류 */}
      <BusinessClass
        visible={visibleBusiness}
        title={formatMessage({ id: "T1119" })} // 업무 분류 검색
        onOk={onOkBusiness}
        onCancel={onCancelBusiness}
        defaultData={
          Object.keys(basicDatas.businessClass).length > 0
            ? [basicDatas.businessClass]
            : []
        }
        max={1}
      />
      <Organization
        visible={visibleOrgan}
        title={
          {
            workManager: `${formatMessage({ id: "T1141" })}`, // 업무 담당자 등록
            developers: `${formatMessage({ id: "T0510" })}`, // 개발자 등록
          }[organLabel]
        }
        authCheck={organLabel === "developers"}
        onOk={onOkOrgan}
        onCancel={onCancelOrgan}
        defaultData={organData}
        msColumn={true}
      />
    </PageTemplate >
  );
};

export default HFWorkflowEnter;