import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  background: #fff;
  .top__container {
    display: flex;
    gap: 19px;
    padding-bottom: 20px;
  }
  .top_each__section {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  .work_item__result_wrap {
    display: inline-flex;
    gap: 3px;
    align-items: center; 
  }
  .work_item__result_wrap span {
    font-size: 12px;
  }
  .work_item__stauts_wrap {
    display: flex;
    align-items: center;
    justify-content: space-around;
  } 
  .work_item__stauts_wrap .btn__company {
    display: flex;
    gap: 10px;
  }
  // 테이블 설정
  .ant-table table { 
    table-layout: fixed;
  }
  .ant-table-thead>tr:first-child>th:last-child {
    text-align: center;
  } 
  .ant-table-tbody > tr > td span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
  } 
  /* abled 아이콘 css */ 
    .abled_icons path.monitoring1, .abled_icons path.monitoring2, .abled_icons path#패스_10258, .abled_icons path#패스_10255,
    .abled_icons path#패스_10113, .abled_icons path#패스_10114 
    ,.abled_icons line#선_491, .abled_icons line#선_492, .abled_icons path#패스_10112, .abled_icons rect#사각형_5861 {
        stroke: #000;
    } 
    .abled_icons path.monitoring2 {
        fill: #000;
    }
`;

export default Wrapper;