import React from 'react';
import { Select } from 'antd';
import { Text, UserThumbnail, FormTable, InputSearch, Image, Input } from 'components';
import { useIntl } from "react-intl";
import { CameraIcon } from 'icons';

const { Option } = Select;

/**
 * 관리자 > 회원 관리 > 회원 조회 > 회원 정보 조회 (회원 조회 상단)
 */

const MemberEditTop = ({ memberInfo, dwpUserLevelList, dwpUserLoading, abLevelList, abLevelLoading, abLevel, dwpLevel, onChangeAbLevel, onChangeDwpLevel, onClickOrg, onClickChangeIMG, idPhoto, onChangeMemberPhone, onChangeMemberName, fileList, previewSrc }) => {
    const { formatMessage } = useIntl();

    return (
        <div className='member_edit__top'>
            <div>
                <div className='member_edit__top_avatar'>
                    <Text className='member_edit__top_text'>{formatMessage({ id: "T0605" })}</Text>{/* 회원 정보 조회 */}
                    <div className='id_photo_part'>
                        {
                            previewSrc ? <Image src={previewSrc} alt="preview-img" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} /> :
                                memberInfo.fileId?.length > 0 && Object.keys(idPhoto).length > 0 ? <Image src={idPhoto.link} alt='id photo' style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
                                    :
                                    <UserThumbnail width={130} />
                        }
                    </div>
                    <Text className='member_edit__top_text'>{memberInfo.empNam} <span>({memberInfo.empNum})</span></Text>
                    <div className='camera_icon'>
                        <CameraIcon onClick={onClickChangeIMG} />
                    </div>
                </div>
            </div>
            <div className='member_edit__top_table'>
                <FormTable size='sm'>
                    <colgroup>
                        <col width='12.5%' />
                        <col width='37.5%' />
                        <col width='12.5%' />
                        <col width='37.5%' />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{formatMessage({ id: "T0012" })}</th>{/*이름*/}
                            <td>
                                <Input
                                    value={memberInfo.empNam}
                                    style={{ width: 250 }}
                                    onChange={onChangeMemberName}
                                />
                            </td>
                            <th>{formatMessage({ id: "T0602" })}</th>{/* 이메일 */}
                            <td>{memberInfo.emailAddr}</td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0601" })}</th>{/*조직*/}
                            <td>
                                <InputSearch
                                    width='250'
                                    height='32'
                                    onClick={onClickOrg}
                                    value={memberInfo.orgNam || ''}
                                />
                            </td>
                            <th>{formatMessage({ id: "T1255" })}</th>{/* 전화번호 */}
                            <td>
                                <Input
                                    value={memberInfo.cphoneNum}
                                    style={{ width: 250 }}
                                    onChange={onChangeMemberPhone}
                                    placeholder={formatMessage({ id: "T1255" })} // 전화번호
                                    maxLength={13}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{formatMessage({ id: "T0606" })}</th> {/*회원 역량 등급*/}
                            <td>
                                <Select
                                    style={{
                                        width: 250,
                                        height: 32
                                    }}
                                    value={abLevel || abLevelList[0]?.cd || ''}
                                    onChange={onChangeAbLevel}
                                    loading={abLevelLoading}
                                >
                                    {
                                        abLevelList.map(v => (
                                            <Option key={v.cd} value={v.cd}>{v.cdNm} (LEV {v.cd})</Option>
                                        ))
                                    }
                                </Select>
                            </td>
                            <th>{formatMessage({ id: "T0016" })}</th>{/* 회원 등급 */}
                            <td>
                                <Select
                                    style={{
                                        width: 250,
                                        height: 32
                                    }}
                                    value={dwpLevel || dwpUserLevelList[0]?.cd || ''}
                                    onChange={onChangeDwpLevel}
                                    loading={dwpUserLoading}
                                >
                                    {
                                        dwpUserLevelList.map(v => (
                                            <Option key={v.cd} value={v.cd}>{v.cdNm} ({v.cd})</Option>
                                        ))
                                    }
                                </Select>
                            </td>
                        </tr>
                    </tbody>
                </FormTable>
            </div>
        </div>
    )
}

export default MemberEditTop;