import React, { useState, useEffect } from "react";
import { Divider, Typography, Select } from 'antd';
import { Empty } from 'components';
import { useIntl } from "react-intl";
import { Bar } from '@ant-design/plots';
import fetchAbsolute from "utils/fetchAbsolute";

const { Option } = Select;

const { Title } = Typography;

/**
 * 대시보드 > Insight > 조직별 만족도
 * 
 */


const OrgSatisfaction = ({ rechDateFrom, rechDateTo, companyList }) => {
    const { formatMessage } = useIntl();

    const [satisData, setSatisData] = useState([]);
    const [titleList, setTitleList] = useState([]);
    const [selectedTitle, setSelctedTitle] = useState([]);
    const [companyCd, setCompanyCd] = useState([]);


    const config = {
        xField: 'rate',
        yField: 'title',
        seriesField: 'star',
        isPercent: true,
        isStack: true,
        offsetY: 6,
        // scrollbar: {
        //     type: 'vertical',
        // },
        // color: ['#FFF3A9', '#FDC459', '#FC8F10', '#FF711F', '#FC5310'],
        label: {
            position: 'middle',
            content: (item) => {
                if (item.rate !== 0)
                    return (item.rate * 100).toFixed(0).concat('%');
            },
            style: {
                fill: '#fff',
            },
        },
        legend: {
            itemName: {
                formatter: (item) => {
                    if (item === 'oneStar') return '★';
                    else if (item === 'twoStar') return '★★';
                    else if (item === 'threeStar') return '★★★';
                    else if (item === 'fourStar') return '★★★★';
                    else if (item === 'fiveStar') return '★★★★★';
                },
            },
            align: 'center',
            position: 'bottom'
        },
    };

    /* 만족도 조사 제목 리스트 */
    const fetchTitleList = async () => {
        const result = await fetchAbsolute('get', `/dashboard/OperInsight/SatisfactionAllTitleList`);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
        setTitleList([...result.data]);
    }

    const fetchOrgSatisfaction = async () => {
        const stars = ['oneStar', 'twoStar', 'threeStar', 'fourStar', 'fiveStar']
        const result = await fetchAbsolute('post', `/dashboard/OperInsight/SatisfactionByOrgList`, {
            data: {
                rechDateFrom,
                // rechDateFrom: '20210101', // 자료가 있는 부분이라 테스트용 나중에 지워야함
                rechDateTo,
                seqR: selectedTitle,
                companyCd
            }
        });
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        const putStars = [];

        for (let i = 0; i < stars.length; i++) {
            const list = data.map(satis => ({
                ...satis,
                star: stars[i],
                rate: +satis[stars[i]] || 0
            }))
            putStars.push(...list)
        }

        for (let i in putStars) {
            tmpArr.push(
                {
                    title: putStars[i]?.companyNm + `\n[ ${putStars[i].title} ]`,
                    // title: putStars[i].title,
                    key: putStars[i].seqR,
                    star: putStars[i].star,
                    rate: +putStars[i].rate,
                }
            )
        }
        setSatisData([...tmpArr]);
    };

    const onChangeTitle = (e) => {
        if (e === '') setSelctedTitle([]);
        else setSelctedTitle([e]);
    };

    const onChangeCompany = (e) => {
        setCompanyCd([...e]);
    }

    useEffect(() => {
        fetchOrgSatisfaction();
    }, [rechDateFrom, rechDateTo, selectedTitle, companyCd]);

    useEffect(() => {
        fetchTitleList();
    }, []);

    return (
        <div className='box__container'>
            <div className='bot__title'>

                <Title
                    className='main__title'
                    level={4}
                >
                    {formatMessage({ id: "T1529" })}{/* 조직별 만족도 */}
                </Title>
                <div className="condition">
                    <div>{formatMessage({ id: "T1516" })}</div>{/* 조직별 */}
                    <div>
                        <Select
                            style={{
                                width: 150
                            }}
                            mode='multiple'
                            maxTagCount='responsive'
                            onChange={onChangeCompany}
                            value={companyCd}
                            placeholder={formatMessage({ id: "T0391" })}  // 전체
                        >
                            {
                                companyList.map(list => {
                                    return (
                                        <Option key={list.companyCd} value={list.companyCd}>{list.companyNm}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div>{formatMessage({ id: "T0845" })}</div>{/* 만족도 조사 제목 */}
                    <div>
                        <Select
                            style={{
                                width: 150
                            }}
                            defaultValue=''
                            onChange={onChangeTitle}
                            value={selectedTitle}
                            placeholder={formatMessage({ id: "T0391" })}  // 전체
                        >
                            <Option key='' value=''>{formatMessage({ id: "T0391" })}</Option>{/* 전체 */}
                            {
                                titleList.map(list => {
                                    return (
                                        <Option key={list.seqR} value={list.seqR}>{list.title}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <Divider />
            {
                satisData.length > 0 ?
                    <Bar {...config}
                        data={satisData}
                        className="dashboard_graph"
                        style={{ height: `calc(10px * ${satisData.length})`, minHeight: 300 }}
                    /> : <Empty />
            }
        </div>
    )
}
export default OrgSatisfaction 