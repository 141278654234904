import { SET_COMMUNITY_URL, RESET_COMMUNITY_URL } from './action';

/**
 * Community URL 관리하는 Reducer
 * 
 */

const listUrlsInitialState = {
    community: ''
};

const communityUrls = (state = listUrlsInitialState, action) => {
    switch (action.type) {
        // inputValue 변경
        case SET_COMMUNITY_URL: {
            return {
                ...state,
                [action.key]: action.search
            };
        }
        case RESET_COMMUNITY_URL: {
            const community = {
                ...listUrlsInitialState.community
            }
            return {
                ...state,
                community
            }
        }
        default:
            return state;

    };
};

export default communityUrls;
