import styled from "styled-components";
import Container from "containers/Container";

const Wrapper = styled(Container)`
  .section {
    width: 100%;
    position: relative;
  }
  .section p,
  .section span {
    color: #fff;
    letter-spacing: -1px;
    font-size: 16px;
  }
  .section .jedi_master__title {
    font-size: 23px;
    font-weight: bold;
    padding-bottom:60px;
    padding-top:10px;
  }

  .section.section1 img {
    width: 100%;
  }
  .section1 .section_text {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 17px;
    text-align: center;
  }

  .section2 {
    text-align: center;
  }
  .section2 .member_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 5px;
  }
  .section2 .member_box {
    position: relative;
  
  }
  .section2 .member_box .member_nametag {
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 82px;
    height: 25px;
    border: 4px solid #F5BC1F;
    background: #000;
    color: #50FC6D;
    text-align: center;
    font-size: 10px;
    font-weight: bold;
  }
  .section2 .member_box .member_image {
    position: absolute;
    top: 109px;
    left: 50%;
    transform: translateX(-50%);
  }
  .section2 .member_box .member_namecard {
    position: absolute;
    top: 211px;
    left: 50%;
    transform: translateX(-50%);
    height: 40.5px;
    text-align: center;
  }
  .section2 .member_box .member_namecard p {
    margin-bottom: 0;
  }
  .section2 .member_box .member_namecard .member_name {
    font-size: 14px;
    font-weight: bold;
  }
  .section2 .member_box .member_namecard .member_empNo {
    font-size: 11px;
  }
  .section2 .member_org {
    position: absolute;
    top: 265px;
    left: 50%;
    transform: translateX(-50%);
  }
  .section2 .member_org .member_orgnm {
    font-size: 12px;
  }
  .section2 .member_email {
    position: absolute;
    top: 313px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    cursor: pointer;
  } 
  .section2 .member_email svg { 
    width: 31.27px !important;
    height: 18.24px !important;
  }
  .section.section3 {
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    gap: 140px;
  }
  .section3 .section_left__box {
    flex: 1.5;
  }
  .section3 .section_left__content {
    margin-top: 70px;
    font-size: 16px;
    word-break: keep-all;
  }
  .section3 .section_right__box {
    flex: 1;
    display: flex;
  }
  .section3 .section_right__char_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .section3 .section_right__char_image {
    width: 100%;
    position: relative;
  }
  .section3 .section_right__char_image img {
    position: relative;
    z-index: 1;
  }
  .section3 .section_right__char_image .section_right__char_aura {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 0;
  }
  .section3 .section_right__char_label {
    font-size: 12px;
  }

  .section4 {
    padding-top: 40px;
    text-align: center;
  }
  .section4 .section_text {
    margin-top: -88px;
    line-height: 25px;
    font-size: 16px;
  }

  .section5 {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .section5 img {
    display: block;
    margin: 0 auto;
  }
  .section5 .section_text {
    marign-top: 40px;
    font-size: 23px;
    text-align: center;
    line-height: 33px;
  }
  
  .section6 {
    padding: 140px 0;
    text-align:center;
  }
  .section6 img {
    display: block;
    margin: 0 auto;
    cursor: pointer;
    z-index: 2000,
  }

.academy_return_to_top{
    color:#FF6600 !important ;
    font-size:18px;
    z-index: 1,
}
`;

export default Wrapper;
