import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import { Empty } from 'components';
import { useIntl } from "react-intl";
import { Bar } from '@ant-design/plots';
import fetchAbsolute from "utils/fetchAbsolute";

const { Title } = Typography;

/**
 * 대시보드 > Insight > 로그인 카운트 (기간 중 로그인 비율)
 * 
 */


const LoginCount = ({ regDateFrom, regDateTo }) => {
    const { formatMessage } = useIntl();
    const [loginData, setLoginData] = useState([]);

    const config = {
        xField: 'signInCnt',
        yField: 'empNum',
        legend: {
            position: 'top-left',
        },
        offsetY: 6,
        // scrollbar: {
        //     type: 'vertical',
        // },
        color: '#E1E1E1',
        label: {
            position: 'middle',
            offset: 0,
            style: {
                fill: '#000',
                fontWeight: 500,
            },
            content: (item) => {
                return `${item.signInCnt}(${item.signInRatio.toFixed(1)}%)`
            }
        },
        tooltip: false,
        barBackground: {
            style: {
                fill: 'rgba(0,0,0,0.1)',
            },
        },
        interactions: [
            {
                type: 'active-region',
                enable: false,
            },
        ],
    };


    const fetchLoginCount = async () => {
        const result = await fetchAbsolute('post', `/dashboard/OperInsight/LoginCountList`, {
            data: {
                regDateFrom,
                regDateTo
            }
        });
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    key: data[i].empNum,
                    empNum: data[i].empNum,
                    signInCnt: +data[i].signInCnt || 0,
                    signInRatio: +data[i].signInRatio || 0,
                }
            )
        }
        setLoginData([...tmpArr]);
    }

    useEffect(() => {
        fetchLoginCount();
    }, [regDateFrom, regDateTo]);

    return (
        <div className='box__container'>
            {/* 로그인 카운트 (기간 중 로그인 비율) */}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1528" })}
            </Title>
            <Divider />
            {
                loginData.length > 0 ?
                    <Bar {...config}
                        data={loginData}
                        className="dashboard_graph"
                        style={{ height: "300px" }}
                    /> : <Empty />
            }
        </div>
    )
}
export default LoginCount