import React from 'react';
import styled from 'styled-components';
import { Radio } from "antd";

const Wrapper = styled(Radio.Button)`
border-radius: 0;
padding: 0;
text-align: center;
background: #fff;
border: 1px solid #d0d1d6;
width: ${props => props.width}px;
height: ${props => props.height}px;
font-size: ${props => props.fontSize}px;
span {
    font-size: inherit;
}
&:hover {
    border-color: #ff7a00;
    /* 우리카드 */
    /* border-color: #0067ac;
    color: #0067ac; */
}
&:active {
    outline: none;
    background: #FF6600;
    /* 우리카드 */
    /* background: #0067ac;
    color: #fff; */
}
&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
    border: none;
}
&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    box-shadow: none;
}
&.ant-radio-button-wrapper-checked {
    background: #FF7B00;
    /* 우리카드 */
    /* background: #0067ac; */
    border: none;
    color:#fff;
    box-shadow: none;
}
&.ant-radio-button-wrapper-checked:hover {
    color:#fff;
    background: #FF6600;
    /* 우리카드 */
    /* background: #0067ac; */
}
&.ant-radio-button-wrapper-checked:active {
    color:#fff;
    background: #FF7700;
    /* 우리카드 */
    /* background: #0067ac; */
}

`;

const RadioButton = ({ style = {}, width='63', height='32', fontSize='12', children, ...args }) => {
    return (
        <Wrapper width={width} height={height} fontSize={fontSize} {...args}>
            {children}
        </Wrapper>
    );
}

export default RadioButton;