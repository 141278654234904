import { getUniqueKey } from 'utils/Str';

export const START_LOAD_DATA = getUniqueKey('START_LOAD_DATA');
export const LOAD_USER_PERMISSION = getUniqueKey('LOAD_USER_PERMISSION');
export const LOAD_TASK_PERMISSION = getUniqueKey('LOAD_TASK_PERMISSION');
export const LOAD_PERMISSION_SUCCESS = getUniqueKey('LOAD_PERMISSION_SUCCESS');
export const LOAD_PERMISSION_FAIL = getUniqueKey('LOAD_PERMISSION_FAIL');
export const SET_TASK_INFO = getUniqueKey('SET_TASK_INFO');

export function startLoadData(divi) {
    return {
        type: START_LOAD_DATA,
        divi
    }
}
export function loadUserPermission() {
    return {
        type: LOAD_USER_PERMISSION,
    };
}
export function loadTaskPermission(taskInfo) {
    return {
        type: LOAD_TASK_PERMISSION,
        taskInfo
    }
}
export function loadPermissionSuccess(divi, data) {
    return {
        type: LOAD_PERMISSION_SUCCESS,
        divi,
        data
    };
}
export function loadPermissionFail(divi, error) {
    return {
        type: LOAD_PERMISSION_FAIL,
        divi,
        error
    };
}

export function setTaskInfo({ taskId, taskType }) {
    return {
        type: SET_TASK_INFO,
        taskId,
        taskType
    }
}