import React, { useState, useEffect } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  FormTable,
  Text,
  Input,
  Button,
  Modal,
} from "components";
import { Select } from "antd";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";

import Wrapper from "./Styled";

/**
 * CoE 과제 즉시 실행 관리 추가
 * 
 */

const { Option } = Select;

const AdminCoeAssignManagementEdit = ({
  history,
  match: { params },
  type = "create",
}) => {
  const { formatMessage } = useIntl();
  const CoEExcuteManage = formatMessage({ id: 'T0229' }); // CoE 과제 즉시 실행 관리가 저장되었습니다.


  const [visible, setVisible] = useState(false);
  const [inputDatas, setInputDatas] = useState({
    taskId: "",
    taskNm: "",
    useYn: "N",
  });

  const onChangeInput = (e) => {
    const { value, name } = e.target;
    setInputDatas({
      ...inputDatas,
      [name]: value,
    });
  };

  const onOkDelete = async () => {
    const create = await fetchAbsolute(
      "put",
      "/admin/commonMaster/updateTaskMainCoe",
      {
        data: {
          taskId: inputDatas.taskId,
          userExeYn: null,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (create.error)
      return alert(formatMessage({ id: 'T0247' })); // 삭제에 실패하였습니다.
    else {
      alert(formatMessage({ id: 'T1054' })); // `선택하신 CoE 과제 즉시 실행 관리가 삭제되었습니다.`);
      return history.push("/admin/coe/assignmanage");
    }
  };

  const onCancelDelete = () => {
    setVisible(false);
  };

  const onClickDelete = () => {
    setVisible(true);
  };
  const onClickSearch = () => {
    if (inputDatas?.taskId.length < 2 || inputDatas?.taskId === undefined) {
      setInputDatas({
        taskId: "",
        taskNm: "",
        useYn: "N",
      });
      return alert(formatMessage({ id: 'T0025' })); // 검색어를 입력하세요
    }
    if (inputDatas?.taskId.includes("D")) {
      setInputDatas({
        taskId: "",
        taskNm: "",
        useYn: "N",
      });
      return alert(formatMessage({ id: 'T1055' })); // CoE 과제만 추가 해주세요.
    }

    const fetchData = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/commonMaster/readTaskMainCoe?taskId=${inputDatas.taskId}`
      );

      if (result?.data.length === 0) {
        setInputDatas({
          taskId: "",
          taskNm: "",
          useYn: "N",
        });
        return alert(formatMessage({ id: 'T1056' })); // 존재하지 않는 과제ID 입니다
      } else {
        setInputDatas({
          taskId: result.data.taskId,
          taskNm: result.data.taskNm,
          useYn: "N",
        });
      }
    };
    fetchData();
  };

  const onChangeUseYn = (value) => {
    setInputDatas({
      ...inputDatas,
      useYn: value,
    });
  };

  const onClickSave = async () => {
    const create = await fetchAbsolute(
      "put",
      "/admin/commonMaster/updateTaskMainCoe",
      {
        data: {
          taskId: inputDatas.taskId,
          userExeYn: inputDatas.useYn,
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (create.error)
      return alert(formatMessage({ id: 'T0235' })); // 저장에 실패하였습니다.
    else {
      alert(CoEExcuteManage);
      return history.push("/admin/coe/assignmanage");
    }
  };

  const onClickBack = () => {
    history.push("/admin/coe/assignmanage");
  };

  useEffect(() => {
    if (type !== "modify") return;

    const fetchData = async () => {
      const result = await fetchAbsolute(
        "get",
        `/admin/commonMaster/readTaskMainCoe?taskId=${params.taskId}`
      );
      if (result.error) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      }
      const { data } = result;
      setInputDatas({
        ...data,
        useYn: result.data.userExeYn,
      });
    };
    fetchData();
  }, []);

  return (
    <PageTemplate
      header={<Header />}
      footer={<Footer />}
      bodyColor="none"
      headerColor="none"
      menuTitle={
        type === "create"
          ? `${formatMessage({ id: 'T0871' })}` // CoE 과제 즉시 실행 관리 추가
          : type === "modify" && `${formatMessage({ id: 'T0872' })}` // CoE 과제 즉시 실행 관리 수정
      }
      depthList={[
        `${formatMessage({ id: 'T0881' })}`, // 관리자
        type === "create"
          ? `${formatMessage({ id: 'T0871' })}` // CoE 과제 즉시 실행 관리 추가
          : type === "modify" && `${formatMessage({ id: 'T0872' })}`, // CoE 과제 즉시 실행 관리 수정
      ]}
    >
      <Wrapper style={{ padding: "30px 60px" }}>
        <FormTable>
          <colgroup>
            <col width="30%" />
            <col width="70%" />
          </colgroup>
          <tbody>
            {type === "create" ? (
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: 'T0868' })}</Text>{/* CoE 과제 ID */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      placeholder={formatMessage({ id: 'T0868' })} // CoE 과제 ID
                      style={{
                        width: 250,
                        height: 33,
                      }}
                      maxLength={10}
                      name="taskId"
                      value={inputDatas.taskId}
                      onChange={onChangeInput}
                    />
                    <Button style={{ marginLeft: 20 }} onClick={onClickSearch}>
                      &nbsp;
                      <FormattedMessage id="T0039" />&nbsp;{/* 검색 */}
                    </Button>
                  </div>
                </td>
              </tr>
            ) : (
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: 'T0868' })}</Text>{/* CoE 과제 ID */}
                </th>
                <td>
                  <Text name="taskNm">{inputDatas.taskId}</Text>
                </td>
              </tr>
            )}
            <tr>
              <th>
                <Text required={true}>{formatMessage({ id: 'T0869' })}</Text>{/* CoE 과제명 */}
              </th>
              <td>
                <Text name="taskNm">{inputDatas.taskNm}</Text>
              </td>
            </tr>

            <tr>
              <th>
                <Text required={true}>{formatMessage({ id: 'T0870' })}</Text>{/* 즉시 실행 가능 */}
              </th>
              <td>
                <Select
                  defaultValue="N"
                  value={inputDatas.useYn}
                  onChange={onChangeUseYn}
                  style={{
                    width: 250,
                    height: 32,
                  }}
                >
                  <Option value="N">N</Option>
                  <Option value="Y">Y</Option>
                </Select>
              </td>
            </tr>
          </tbody>
        </FormTable>
        {type === "modify" ? (
          <div className="coemanage_edit__button_wrap">
            <Button style={{ width: 120, height: 40 }} onClick={onClickBack}><FormattedMessage id="T0051" /></Button>{/* 취소 */}
            <Button style={{ width: 120, height: 40 }} onClick={onClickDelete}>{formatMessage({ id: 'T0072' })}</Button>{/* 삭제 */}
            <Button style={{ width: 120, height: 40 }} type="primary" onClick={onClickSave}>
              <FormattedMessage id="T0054" />{/* 수정 */}
            </Button>
          </div>
        ) : (
          <div className="coemanage_edit__button_wrap">
            <Button style={{ width: 120, height: 40 }} onClick={onClickBack}><FormattedMessage id="T0051" /></Button>{/* 취소 */}
            <Button style={{ width: 120, height: 40 }} type="primary" onClick={onClickSave}>
              <FormattedMessage id="T0049" />{/* 추가 */}
            </Button>
          </div>
        )}

        <Modal
          width={540}
          visible={visible}
          title={formatMessage({ id: 'T0873' })}//CoE 과제 즉시 실행 관리 삭제
          okText={formatMessage({ id: 'T0072' })}//삭제
          onCancel={onCancelDelete}//
          onOk={onOkDelete}
        >
          <Text
            style={{
              display: "block",
              fontSize: 14,
            }}
          >
            {formatMessage({ id: "T0874" })
              .split("\n")
              .map((data) => {
                return (
                  <span>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 정말로 선택된 CoE 과제 즉시 실행 관리를
삭제하시겠습니까? */}
          </Text>
        </Modal>
      </Wrapper>
    </PageTemplate>
  );
};

export default AdminCoeAssignManagementEdit;
