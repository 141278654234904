import React, { useEffect, useState } from "react";
import { Text, Input, Button, FormTable, RangePicker } from "components";
import { Input as AntInput, Select } from "antd";
import { Typography } from "antd";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import { createBrowserHistory } from "history";
import moment from "moment";
import { useIntl } from "react-intl";

const { TextArea } = AntInput;
const { Title } = Typography;
const { Option } = Select;

/**
 * Filename: RoundCreate/index.js
 * Description:RoundCreate
 * Item Shop 관리 > 회차 관리 > 회차 추가
 */

const RoundCreate = () => {
  const { formatMessage } = useIntl();
  const history = createBrowserHistory({ forceRefresh: true });

  const [date, setDate] = useState({
    roundFr: "",
    roundTo: "",
  });

  const [selDateStatus, setSelDateStatus] = useState(false);
  const [betweenDate, setBetweenDate] = useState([]);
  const [disableDate, setDisableDate] = useState([]);
  const [showYn, setShowYn] = useState("Y");
  const [inputValue, setInputValue] = useState({
    round: "",
    note: "",
  });

  const onClickAdd = async () => {
    if (selDateStatus) {
      const saveList = await fetchAbsolute(
        "post",
        `/academy/manageRoundsSave`,
        {
          data: {
            round: inputValue.round,
            roundFr: date.roundFr,
            roundTo: date.roundTo,
            showYn: showYn,
            note: inputValue.note,
          },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (saveList.error) {
        return alert(formatMessage({ id: "T0235" })); //저장에 실패하였습니다.
      }
      alert(formatMessage({ id: "T0234" })); // 저장되었습니다.

      history.push("/admin/jediitem/roundlist");
    } else {
      return alert(formatMessage({ id: "T0311" })); //이미 등록된 회차 날짜가 존재 합니다
    }
  };

  const onChangeShowYn = (e) => {
    setShowYn(e);
  };

  // Date는 두 개의 키 값을 변경하기 때문에 충돌 방지
  const onChangeDateRange = ([roundFr, roundTo]) => {
    setDate({
      roundFr: roundFr || "",
      roundTo: roundTo || "",
    });
  };

  const onChangeDates = (val) => {
    const range = val.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));

    if (range.length === 2) {
      const dateArray = [];
      let currentDate = moment(range[0]);
      let endDate = moment(range[1]);
      while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }

      let intersection = disableDate.findIndex((x) => dateArray.includes(x));
      //겹치는 날짜가 없을때
      if (intersection === -1) {
        onChangeDateRange(range);
        setSelDateStatus(true);
      }
      //겹치는 날짜가 있을때
      else {
        setSelDateStatus(false);
      }
    }
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const disabledDate = (current) => {
    let index = disableDate.findIndex(
      (date) => date === moment(current).format("YYYY-MM-DD")
    );
    //db에 있는 날짜가 일치 하는게 없다면 false
    return index === -1 ? false : true;
  };

  const fetchDateList = async (select) => {
    const result = await fetchAbsolute("get", "/academy/manageRoundsViewList");
    setBetweenDate([...result.data.manageRoundList]);
  };

  const getDates = (dates) => {
    const dateArray = [];
    let currentDate;
    let endDate;
    dates.forEach((e) => {
      currentDate = moment(e.roundFr);
      endDate = moment(e.roundTo);
      while (currentDate <= endDate) {
        dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
        currentDate = moment(currentDate).add(1, "days");
      }
      setDisableDate(dateArray);
      return;
    });
  };

  useEffect(() => {
    fetchDateList();
  }, []);

  useEffect(() => {
    getDates(betweenDate);
  }, [betweenDate]);

  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <Title className="title_name" level={4}>
          {formatMessage({ id: "T0825" })}{/* 회차 추가 */}
        </Title>
        <div className="update_content">
          <FormTable border={true}>
            <colgroup>
              <col width="15%" />
              <col width="35%" />
              <col width="15%" />
              <col width="35%" />
            </colgroup>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0823" })}</Text>{/* 회차명 */}
                </th>
                <td colSpan={3}>
                  <div className="flex align-center">
                    <Input
                      maxLength={6}
                      name="round"
                      value={inputValue.round}
                      onChange={onChangeInput}
                      placeholder={formatMessage({ id: "T0823" })} // 회차명
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0100" })}</Text>{/* 설명 */}
                </th>
                <td colSpan={3}>
                  <TextArea
                    name="note"
                    style={{ resize: "none" }}
                    autoSize={{ minRows: 2, maxRows: 2 }}
                    value={inputValue.note}
                    onChange={onChangeInput}
                    placeholder={formatMessage({ id: "T0100" })} // 설명
                  />
                </td>
              </tr>

              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0826" })}</Text>{/* 기간 설정 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <RangePicker
                      size="large"
                      format="YYYY-MM-DD"
                      name="dates"
                      defaultValue={[undefined, undefined]}
                      disabledDate={disabledDate}
                      onCalendarChange={onChangeDates}
                      onChange={onChangeDates}
                    />
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0827" })}</Text>{/* 노출 여부 입력 */}
                </th>
                <td >
                  <div>
                    <Select
                      id="showYn"
                      style={{
                        width: "30%",
                      }}
                      value={showYn || ""}
                      onChange={onChangeShowYn}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 20,
        }}
      >
        <Button width="144" height="40" type="primary" onClick={onClickAdd}>
          {formatMessage({ id: "T0049" })}{/* 추가 */}
        </Button>
        &nbsp;&nbsp;
        <Link to="/admin/jediitem/roundlist">
          <Button width="144" height="40">
            {formatMessage({ id: "T0051" })}{/* 취소 */}
          </Button>
        </Link>
      </div>
    </div >
  );
};

export default RoundCreate;
