import React from "react";
import styled from "styled-components";
import { Button } from 'components';

const TodoWrap = styled.div`
  position: relative;   
  .todo__container {
    display: flex;
    flex-direction: column;
    border: 1px solid #d0d1d6;
    padding:15px 15px;
    gap: 8px;
    margin-bottom: 8px;
  }
  .todo__container.purple {
    background: #e7e6ff;
    border: none;
    box-shadow: 0px 3px 6px 0 rgba(0, 0, 0, 0.16);
  }
  .box_top__container {
    display: flex;
    gap: 15px;
  } 
  .box_title_button__container {
    display: flex;
    justify-content: space-between;
  }
  .box_top__container .todo__title {
    font-weight: bold;
    font-size: 16px;
  } 
  .box_top__container .label__myTask {
    background: #ff0016;
    border-radius: 30px;
    color: #fff;
    width: fit-content;
    text-align: center;
    font-size: 13px;
    height: 23px;
    padding: 0 10px;
  } 
  .box_top__container .label__requested {
    background: #666666;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    font-size: 13px;
    height: 23px;
    width: fit-content;
    padding: 0 10px;
  }
  .todo__content {
    font-size: 14px;    
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .todo__date {
    font-size: 13px;
    paddingleft: 10px;
    font-weight: bold;
    color: #060606;
    opacity: 0.3;
  }
  .todo__button { 
    font-size: 14px;
    font-weight: bold;
    padding: 6px 13px 6px 13px;
    width: fit-content;
    height: 32px;
    line-height: normal;
  }
  .box_close {
    color: #999999;
  }
  span.anticon.anticon-close.box_close.purple {
    color: #000;
  }
`;

const TodoBox = ({ 
  onClick = () => {},
  title,
  text,
  btn_content,
  index = "",
  label = "",  
  type='', 
  isPurple='false',
  style={}
}) => {
  const onClickButton = (e) => {
    onClick(e, { index, label });
  }; 
  return (
    <TodoWrap style={style}>
      <div className={`todo__container ${isPurple? 'purple' : ''}`}>
        <div className="box_title_button__container">
          <div className='box_top__container'>
            <div className="todo__title">{title}</div>
          </div>
          <div>
          </div>
        </div>
        <div className="todo__content">
          {text}
          <div className="challenging__container"> 
            <div>
              <Button type={type} className="todo__button" onClick={onClickButton}>{btn_content}</Button>
            </div>
          </div>
        </div>
      </div>
    </TodoWrap>
  );
};

export default TodoBox;
