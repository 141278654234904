import React from 'react';
import Wrapper from './Styled';
import Popup from './Popup';

/**
 * 만족도 조사 팝업
 * 
 */
const ResearchPopup = ({ researchList, empNo, onClickCloseResearch }) => {
    return (
        <Wrapper>
            {
                researchList.map(research => (
                    <Popup
                        key={research.seqR}
                        research={research}
                        empNo={empNo}
                        onClickCloseResearch={onClickCloseResearch}
                    />
                ))
            }
        </Wrapper>
    )
}

export default ResearchPopup;