import React from 'react';
import Wrapper from './Styled';

function Spin() {
    return (
        <Wrapper style={{ zIndex: 100 }}>
            <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </Wrapper>
    )
}

export default Spin;