import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Modal, Button, FormTable, Text } from "components";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from 'hooks/useFormatMessage';

/**
 * Filename: EmailTriggerModal.js 
 * Description: 선택된 이메일 trigger 정보 보여주는 modal
 */

const Wrapper = styled((props) => <Modal {...props} />)`
  .ant-table-wrapper {
    padding: 10px 40px;
  }
  .primary__button {
    width: 200px;
    height: 40px;
  }
  .default__button {
    height: 40px;
  }
  .ant-modal-footer button + button {
    margin-left: 0;
  }
  .ant-modal-footer {
    display: flex;
    justify-content: end;
    gap: 15px;
  }
  .ant-table-title {
    display: none !important;
  }
`;

const EmailTriggerModal = ({
    visible,
    onOk = () => { },
    onCancel = () => { },
    title = "",
    emailInfoSeq,

    //   recordData,
    //   devicePool,
}) => {
    const { formatMessage } = useIntl();

    const [loading, setLoading] = useState(false);
    const [dataSource, setDataSource] = useState([])


    useEffect(() => {
        if (!visible) return;

        const fetchEmailTrigResult = async () => {
            setLoading(true);
            const result = await fetchAbsolute('get', `/admin/trigger/email/info?emailInfoSeq=${emailInfoSeq}`);

            setLoading(false);

            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            setDataSource(result.data)
        };
        fetchEmailTrigResult();
    }, [emailInfoSeq, visible]);



    return (
        <Wrapper
            width={1200}
            visible={visible}
            title={`Email Trigger ${formatMessage({ id: 'T1308' })}`}// Email Trigger  정보
            onCancel={onCancel}
            footer={[
                <Button key="back" className="default__button" onClick={onCancel}>
                    <FormattedMessage id="T1307" />{/* 닫기 */}
                </Button>,
            ]}
        >
            <FormTable>
                <colgroup>
                    <col width='15%' />
                    <col width='35%' />
                    <col width='15%' />
                    <col width='35%' />
                </colgroup>
                <tbody>
                    <tr>
                        <th>
                            <Text> Email Trigger Name </Text>
                        </th>
                        <td>
                            <div className='flex align-center'>
                                <Text> {dataSource.name} </Text>
                            </div>
                        </td>
                        <th>
                            <Text>Trigger <FormattedMessage id="T1305" /></Text>{/* Trigger 활성화 여부 */}
                        </th>
                        <td>
                            <Text> {dataSource.activate} </Text>
                        </td>
                    </tr>

                    <tr>
                        <th>
                            <Text >URL</Text>
                        </th>
                        <td >
                            <Text>
                                {dataSource.url}
                            </Text>
                        </td>
                        <th>
                            <Text >Port</Text>
                        </th>
                        <td>
                            <Text>
                                {dataSource.port}
                            </Text>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text>Protocol</Text>
                        </th>
                        <td >
                            <Text>
                                {dataSource.protocol}
                            </Text>
                        </td>
                        <th>
                            <Text><FormattedMessage id="T1313" /></Text>{/* TLS여부 */}
                        </th>
                        <td>
                            <Text>
                                {dataSource.tlsYn}
                            </Text>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text>Email </Text>
                        </th>
                        <td >
                            <Text>
                                {dataSource.email}
                            </Text>
                        </td>

                    </tr>
                    <tr>
                        <th>
                            <Text><FormattedMessage id="T1309" /></Text>{/* 확인주기 (초) */}
                        </th>
                        <td >
                            <Text>
                                {dataSource.cycle}
                            </Text>
                        </td>
                        <th>
                            <Text>Folder</Text>
                        </th>
                        <td >
                            <Text>
                                {dataSource.folder}
                            </Text>
                        </td>

                    </tr>
                    <tr>
                        <th>
                            <Text >Sender</Text>
                        </th>
                        <td>

                            {dataSource.senderList?.map((e, index) =>
                                <div className='input__area'>
                                    <Text>{e.keyWord}</Text>
                                </div>
                            )}
                        </td>
                        <th>
                            <Text >Subject</Text>
                        </th>
                        <td>

                            {dataSource.subjectrList?.map((e, index) =>
                                <div className='input__area'>
                                    <Text>{e.keyWord}</Text>
                                </div>
                            )}

                        </td>
                    </tr>

                    <tr>
                        <th>
                            <Text >Attatch File Name</Text>
                        </th>
                        <td>
                            {dataSource.fileNameList?.map((e, index) =>
                                <div className='input__area'>
                                    <Text>{e.keyWord}</Text>
                                </div>
                            )}
                        </td>
                    </tr>

                </tbody>
            </FormTable>

        </Wrapper >
    );
};

export default EmailTriggerModal;