import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { SendMessage } from 'components';
import { Menu, Dropdown } from 'antd';
import { useIntl } from "react-intl";

const UserContext = ({ columns, children, profile, history, dataList = [], type = ""}) => {
    const { formatMessage } = useIntl();
    const basicMenus = [
        {
            key: 1,
            item: <Menu.Item key="1">{formatMessage({ id: 'T0328' })}</Menu.Item>,// 메일 보내기
        }, {
            key: 3,
            item: <Menu.Item key="3">{formatMessage({ id: 'T0330' })}</Menu.Item>,// 담당 과제 조회하기 
        }
    ];

    const boxwoodMenus = [
        {
            key: 1,
            item: <Menu.Item key="1">{formatMessage({ id: 'T0328' })}</Menu.Item>,// 메일 보내기
        }, {
            key: 4,
            item: <Menu.Item key="4">{formatMessage({ id: 'T1315' })}</Menu.Item>,// 담당 업무 조회하기 
        }
    ];

    const [menuList, setMenuList] = useState([]);
    const [smVisible, setSmVisible] = useState(false);

    //담당자 우클릭 했을때 나오는 박스가 Menu 
    const onClickMenu = (e) => {
        switch (+e.key) {
            case 1:
                setSmVisible(true);
                break;
            case 3:
                //bizBox
                history.push(`/assign/list?mgrIds=${profile.EMP_NO || profile.empNum}`);
                break;
            case 4:
                //bizFlow
                history.push(`/assign/list?mgrIds=${profile.EMP_NO || profile.empNum}`);
                break;
            default:
        }

    }


    const onOkSendMessage = async () => {
        setSmVisible(false);
    }

    const onCancelSendMessage = () => {
        setSmVisible(false);
    }

    useEffect(() => {
        const tmpMenus = basicMenus.filter(menu => dataList.length === 0 || dataList.includes(menu.key));
        /* type 종류 2가지 bpd, workflow */
        if (type === "hfBpd" || type === "hfWorkflow") {
            setMenuList([...boxwoodMenus])
        } else {
            setMenuList([...tmpMenus]);
        }
    }, []);

    return (
        <>
            {
                menuList.findIndex(menu => menu.key === 1) !== -1 &&
                <SendMessage
                    visible={smVisible}
                    onOk={onOkSendMessage}
                    onCancel={onCancelSendMessage}
                    toEmpNo={profile.EMP_NO || profile.empNum}
                />
            }
            <Dropdown
                overlay={(
                    <Menu onClick={onClickMenu}>
                        {
                            menuList.map(menu => menu.item)
                        }
                    </Menu>
                )}
                trigger={['click', 'contextMenu']}
            >
                {children}
            </Dropdown>
        </>
    )
}

export default withRouter(UserContext);