import { getUniqueKey } from 'utils/Str';

export const SET_WORKFLOWSCHEDULE_VALUE = getUniqueKey('SET_WORKFLOWSCHEDULE_VALUE');
export const SET_WORKFLOWSCHEDULE_SPREAD = getUniqueKey('SET_WORKFLOWSCHEDULE_SPREAD');
export const RESET_WORKFLOWSCHEDULE_VALUE = getUniqueKey('RESET_WORKFLOWSCHEDULE_VALUE');
export const SET_WORKFLOWSCHEDULE_FILTERID = getUniqueKey('SET_WORKFLOWSCHEDULE_FILTERID');

export function setWorkFlowScheduleValue(key, value) {
    return {
        type: SET_WORKFLOWSCHEDULE_VALUE,
        key,
        value
    };
}

export function setWorkFlowScheduleSpread(data) {
    return {
        type: SET_WORKFLOWSCHEDULE_SPREAD,
        data
    }
}

export function resetWorkFlowScheduleValue() {
    return {
        type: RESET_WORKFLOWSCHEDULE_VALUE
    }
}

export function setWorkFlowScheduleFilterId(filterId) {
    return {
        type: SET_WORKFLOWSCHEDULE_FILTERID,
        filterId
    }
}