import React, { useRef, useState } from "react";
import { Button } from "components";
import { Modal as AntModal } from "antd";
import styled from "styled-components";
import { useIntl, FormattedMessage } from "react-intl";
import Draggable from 'react-draggable';

/**
 * Filename: Modal.js
 * Description: Modal을 띄우기 위한 공통 컴포넌트
 *              onOk 함수를 Prop으로 넘겨서 등록 버튼을 눌렀을 때 이벤트를 지정할 수 있다.
 *              OnCancel 함수를 Prop으로 넘겨서 취소 버튼을 누르거나 Modal이 아닌 바깥 영역을 클릭하였을 떄의 이벤트를 지정할 수 있다.
 *              Draggable
 */

const ModalStyled = styled(AntModal)`
  span {
    font-size: 14px;
  }
  .ant-modal-header {
    border-top: 7px solid #ff7b00;
    /* border-top: 7px solid #0067ac; */
  }
  .ant-modal-title {
    font-weight: bold;
  }
  span.anticon.anticon-close.ant-modal-close-icon {
    vertical-align: -webkit-baseline-middle;
  }
  .ant-modal-footer { 
    display: flex;
    justify-content: flex-end;
    gap: 20px;
  }
  .ant-input:hover {
    border-color: #ff7b00; 
  }
  input.ant-input:focus, ant-input-focused {
    border-color: #ff7b00 !important;
  }  
  textarea.ant-input:focus, ant-input-focused {
    border-color: #ff7b00 !important; 
  }
  .abled_icons path.monitoring1,
  .abled_icons path.monitoring2,
  .abled_icons path#패스_10258,
  .abled_icons path#패스_10255,
  .abled_icons path#패스_10113,
  .abled_icons path#패스_10114,
  .abled_icons line#선_491,
  .abled_icons line#선_492,
  .abled_icons path#패스_10112,
  .abled_icons rect#사각형_5861 {
    stroke: #000;
  }
  .abled_icons path.monitoring2 {
    fill: #000;
  }
`;

const Modal = ({
  visible = false,
  title = "",
  onOk = () => { },
  onCancel = () => { },
  children,
  width = 1400,
  okText: customTitle,
  draggType,
  ...args
}) => {
  const { formatMessage } = useIntl();
  const draggleRef = useRef(null);
  const [disabled, setDisabled] = useState(true);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const [okText] = useState(customTitle || formatMessage({ id: "T0050" })); // 등록

  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!draggType) {
      setDisabled(true);
    }
    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  return (
    <ModalStyled
      width={width}
      open={visible}
      title={<div
        style={{
          width: '100%',
          cursor: 'move',
        }}
        onMouseOver={() => {
          if (disabled) {
            setDisabled(false);
          }
        }}
        onMouseOut={() => {
          setDisabled(true);
        }}
        onFocus={() => { }}
        onBlur={() => { }} // end
      >
        {title}
      </div>}
      onOk={onOk}
      onCancel={onCancel}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
      footer={[
        <Button key="submit" type="primary" onClick={onOk}>
          {okText}
        </Button>,
        <Button key="back" onClick={onCancel} className='cancel__button'>
          <FormattedMessage id="T0051" />{/* 취소 */}
        </Button>,
      ]}
      {...args}
    >
      {children}
    </ModalStyled>
  );
};

export default Modal;