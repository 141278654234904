import React, { useEffect, useState } from "react";
import {
  Text,
  Input,
  Button,
  FormTable,
  Upload,
  Modal,
  Image,
} from "components";
import { Select } from "antd";
import { Typography, Divider } from "antd";
import { Link } from "react-router-dom";
import fetchAbsolute from "utils/fetchAbsolute";
import { createBrowserHistory } from "history";
import { useIntl, FormattedMessage } from "react-intl";

const { Title } = Typography;
const { Option } = Select;

/**
 * Filename: RoundItemUpdate/index.js
 * Description:RoundItemUpdate
 * 관리자 > Item Shop 관리 > 회차별 상품 관리 > 상품 수정 페이지
 */

const RoundItemUpdate = ({ seq }) => {
  const { formatMessage } = useIntl();
  const END_POINT = process.env.REACT_APP_API_URI;
  const history = createBrowserHistory({ forceRefresh: true });

  const [Visible, setVisible] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const [selectedRound, setSelectedRound] = useState("");
  const [defaultSelect, setDefaultSelect] = useState("");
  const [showYn, setShowYn] = useState("");
  const [inputValue, setInputValue] = useState({
    productSeq: "",
    product: "",
    price: "",
    qty: "",
  });

  const onClickAdd = async () => {
    if (
      (!dataSource.link && fileList.length === 0) ||
      (dataSource.link && fileList.length > 0)
    ) {
      return alert(formatMessage({ id: "T0267" })); // 하나의 이미지 파일을 올려주세요.
    }

    const findroundseq = selectList.find((e) => e.round === selectedRound);

    const formData = new FormData();
    fileList.forEach((file) => formData.append("files", file));
    formData.append("roundSeq", findroundseq.roundSeq);
    formData.append("productSeq", inputValue.productSeq);
    formData.append("product", inputValue.product);
    formData.append("round", selectedRound);
    formData.append("price", inputValue.price);
    formData.append("qty", inputValue.qty);
    formData.append("showYn", showYn);

    if (dataSource.link?.length > 0) {
      formData.append("fileId", dataSource.fileId);
    }

    const saveList = await fetchAbsolute(
      "post",
      `/academy/manageProductsByTimeSave`,
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (saveList.error) {
      return alert(formatMessage({ id: "T0235" })); //저장에 실패하였습니다.
    }
    alert(formatMessage({ id: "T0234" })); //저장되었습니다
    history.push("/admin/jediitem/rounditemmanage");
  };

  const onOkDelete = () => {
    const deleteJediItem = async () => {
      const result = await fetchAbsolute(
        "delete",
        `/academy/manageProductsByTimeDel/${seq}`
      );
      if (result.error) {
        alert(formatMessage({ id: "T0308" })); //상품 삭제에 실패하였습니다.
      } else {
        setVisible(false);
        history.push("/admin/jediitem/rounditemmanage");
      }
    };
    deleteJediItem();
  };

  const onClickDelete = () => {
    setVisible(true);
  };

  const onCancelDelete = () => setVisible(false);
  const onClickDeleteImage = () => {
    const tmpObj = { ...dataSource };
    delete tmpObj.link;
    setDataSource({ ...tmpObj });
  };

  const onChangeShowYn = (e) => {
    setShowYn(e);
  };
  const onSelectOption = (e) => {
    setSelectedRound(e);
  };

  const onBeforeupload = (inFile) => {
    const tmpArr = [inFile];
    setFileList([...tmpArr]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    const tmpArr = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpArr]);
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;

    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchRoundList = async () => {
      const result = await fetchAbsolute(
        "get",
        "/academy/manageRoundsViewList"
      );
      setSelectList([...result.data.manageRoundList]);
    };
    const fetchItemDetail = async () => {
      const { data } = await fetchAbsolute(
        "get",
        `/academy/manageProductsByTimeInfo?productSeq=${seq}`
      );
      setInputValue({
        productSeq: data.productSeq,
        product: data.product,
        price: data.price,
        qty: data.qty,
      });
      setDefaultSelect(data.round);
      setSelectedRound(data.round);
      setShowYn(data.showYn);
      setDataSource(data);
    };
    fetchItemDetail();
    fetchRoundList();
  }, [seq]);

  return (
    <div className="realtime__content_box">
      <div className="realtime__table_wrap">
        <Title className="title_name" level={4}>
          {formatMessage({ id: "T0837" })}{/* 상품 수정 */}
        </Title>
        <div className="update_content">
          <FormTable border={true}>
            <colgroup>
              <col width="15%" />
              <col width="35%" />
              <col width="15%" />
              <col width="35%" />
            </colgroup>
            <thead></thead>
            <tbody>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0835" })}</Text>{/* 회차 */}
                </th>
                <td>
                  <div>
                    <Select
                      className="round_select"
                      onChange={onSelectOption}
                      style={{
                        width: '100%',
                        height: 32
                      }}
                      value={selectedRound || defaultSelect}
                    >
                      {selectList.map((e) => (
                        <Option value={e.round}>{e.round}</Option>
                      ))}
                    </Select>
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0830" })}</Text>{/* 상품명 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      name="product"
                      value={inputValue.product}
                      onChange={onChangeInput}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0836" })}</Text>{/* 첨부 */}
                </th>
                <td colSpan={3}>
                  <div>
                    <Upload
                      action={`${END_POINT}/file/uploadTaskMultipleFiles`}
                      multiple={false}
                      maxCount={1}
                      beforeUpload={onBeforeupload}
                      onRemove={onRemoveFile}
                      fileList={fileList}
                      accept={'.png, .jpg, .jpeg, .gif'}
                    />
                  </div>
                  {dataSource.link && (
                    <>
                      <Divider />
                      <div className="edit_file__image_button">
                        <Image
                          alt="product Image"
                          src={dataSource.link}
                          className="product_image"
                          width='350'
                          height='350'
                        />
                        <Button
                          className="submit_button"
                          type="default"
                          onClick={onClickDeleteImage}
                        >
                          {formatMessage({ id: "T0072" })}{/* 삭제 */}
                        </Button>
                      </div>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0832" })}</Text>{/* 가격 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      tpye="number"
                      name="price"
                      value={inputValue.price}
                      onChange={onChangeInput}
                    />
                    &nbsp;Point
                  </div>
                </td>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0833" })}</Text>{/* 수량 */}
                </th>
                <td>
                  <div className="flex align-center">
                    <Input
                      tpye="number"
                      name="qty"
                      value={inputValue.qty}
                      onChange={onChangeInput}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <Text required={true}>{formatMessage({ id: "T0827" })}</Text>{/* 노출 여부 입력 */}
                </th>
                <td>
                  <div>
                    <Select
                      id="showYn"
                      style={{
                        width: 200,
                      }}
                      defaultValue="N"
                      value={showYn || "N"}
                      onChange={onChangeShowYn}
                    >
                      <Option value="Y">Y</Option>
                      <Option value="N">N</Option>
                    </Select>
                  </div>
                </td>
              </tr>
            </tbody>
          </FormTable>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: 20,
        }}
      >
        <Button width="144" height="40" type="primary" onClick={onClickAdd}>
          {formatMessage({ id: "T0054" })}{/* 수정 */}
        </Button>
        &nbsp;&nbsp;
        <Button width="144" height="40" type="primary" onClick={onClickDelete}>
          {formatMessage({ id: "T0072" })}{/* 삭제 */}
        </Button>
        &nbsp;&nbsp;
        <Link to="/admin/jediitem/rounditemmanage">
          <Button width="144" height="40">
            {formatMessage({ id: "T0051" })}{/* 취소 */}
          </Button>
        </Link>
      </div>

      <Modal
        width={540}
        visible={Visible}
        title={formatMessage({ id: "T0072" })} //삭제
        okText={formatMessage({ id: "T0072" })} //삭제
        onCancel={onCancelDelete}
        onOk={onOkDelete}
      >
        <Text
          style={{
            display: "block",
            fontSize: 14,
          }}
        >
          {formatMessage({ id: "T0838" })}{/* 정말로 상품을 삭제하시겠습니까? */}
        </Text>
      </Modal>
    </div>
  );
};

export default RoundItemUpdate;
