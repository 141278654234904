import React, { useEffect, useState } from "react";
import "./common.css";

/**
 * Filename: HFWorkflowIframe/index.js
 * Description: 과제 등록 -> Workflow Iframe
 */

const HFWorkflowIframe = ({ type, data = {}, customHeight="700" }) => {
  const url = "https://dreago.net:8070/ibpm-web";
  const [src, setSrc] = useState("");

  useEffect(() => {
    const getData = async () => {
      if (data.processId === undefined) return;
      // EXECUTEFLAG=Y
      if (type === "insert") {
        setSrc(
          url +
          `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${data.processId}?locale=ko&EXECUTEFLAG=Y&processVersion=EDIT`
        );
      } else if (type === "modify") {
        setSrc(
          url +
          `/activiti-modeler/editor/index.jsp?LegacyAuthCheckFilterUser=dreago_admin#/editor/${data.processId}?locale=ko&EXECUTEFLAG=Y&processVersion=EDIT`
        );
      } else if (type === "lookup") {
        setSrc(
          url +
          `/openapi/bpm/popup/processPopUpMain?processid=${data.processId}&viewDetailPage=false&LegacyAuthCheckFilterUser=dreago_admin`
        );
      }
    };
    getData();
  }, [type, data.processId]);


  return (
        <iframe
              style={{
                border: 0,
                margin: "0 auto",
                display: "block",
              }}
              width="100%"
              height={customHeight}
              title="workflow"
              id="workflow"
              className="workflow"
              src={src}
          />
  );
};

export default HFWorkflowIframe;
