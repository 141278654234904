import React, { useState, useEffect } from 'react';
import { Text, LinkModal } from 'components';
import { useDispatch } from 'react-redux';
import { setVisible } from 'store/loading/action';
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from 'react-intl';

const TransferApproval = ({ match: { params } }) => {
    const { formatMessage } = useIntl();

    const { taskId, seq } = params;

    const dispatch = useDispatch();

    const [requestResult, setRequestResult] = useState({});

    useEffect(() => {
        const fetchSharedRequest = async () => {
            dispatch(setVisible(true));
            const result = await fetchAbsolute('post', `/task/oracle/approvalTransferTask?taskId=${taskId}&reqSeq=${seq}`);
            dispatch(setVisible(false));

            setRequestResult({ ...result });
        }

        fetchSharedRequest();
    }, [taskId]);

    if (Object.keys(requestResult).length === 0) {
        return (
            <></>
        )
    } else {
        return (
            <LinkModal
                visible={true}
                title=""
                width={540}
                closable={false}
                footer={[]}
                titleDisabled
                footerDisabled
            >
                <div className='request_box'>
                    <Text className='request_box__text'>
                        {!requestResult.error ?
                            `업무 이관 요청을 완료하였습니다.\n(업무 이관 작업에 1분 정도 시간이 소요됩니다.)`
                            :
                            requestResult.data?.message || `${formatMessage({ id: 'T0991' })}`
                            // 업무 이관 승인을 실패하였습니다.
                        }
                    </Text>
                </div>
            </LinkModal>
        );
    }
}

export default TransferApproval;