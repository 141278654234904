import React, { useState } from 'react'
import {
  Text,
  FormTable,
  Input,
  InputSearch,
  Organization
} from "components";
import { Select, Input as AntInput } from 'antd';
import { useIntl, FormattedMessage } from "react-intl";

const { TextArea } = AntInput;

const { Option } = Select;

/**
 * 관리자 > 내부 시스템 메뉴 관리 > 내부 시스템 메뉴 등록 / 수정 페이지
 * 상단 부분
 * 
 */


const UseIternalSystem = ({ inputValues, setInputValues, onChangeValues, classificationList, onChangeClassification, requireFields }) => {
  const { formatMessage } = useIntl();

  const [orgVisible, setOrgVisible] = useState(false);

  const onClickOrg = () => {
    setOrgVisible(true);
  };

  const onCancelOrg = () => {
    setOrgVisible(false);
  };


  /* 조직도 */
  const onOkOrg = (data) => {
    if (data.length > 0) {
      setInputValues({ ...inputValues, systemUser: [...data] });
    } else {
      setInputValues({ ...inputValues, systemUser: [] });
    }
    setOrgVisible(false);
  };

  return (
    <div>
      <FormTable>
        <colgroup>
          <col width="10.375%" />
          <col width="13.625%" />
          <col width="10.375%" />
          <col width="13.625%" />
        </colgroup>
        <tbody>
          <tr>
            <th>
              <Text required={true}>
                <FormattedMessage id="T0362" />{/* 분류 */}
              </Text>
            </th>
            <td className="certification_period_section">
              <Select
                // value={inputValues.category.map(cate => cate.cd)} 
                onChange={onChangeClassification}
                placeholder={formatMessage({ id: "T0024" })} // 선택
                name='category'
                ref={requireFields.category.ref}
                style={{ width: '88%' }}
              >
                {
                  classificationList.map(v => (
                    <Option value={v.cd} key={v.cd}>{v.cdNm}</Option>
                  ))
                }
              </Select>
            </td>
            <th>
              <Text required={true}>
                <FormattedMessage id="T0363" />{/* 시스템 명 */}
              </Text>
            </th>
            <td className="certification_period_section">
              <Input
                value={inputValues.systemName}
                innerRef={requireFields.systemName.ref}
                onChange={onChangeValues}
                name='systemName'
                maxLength={100}
                //style={{ width: '88%'}}
                placeholder={formatMessage({ id: 'T0363' })} // 시스템 명
              />
            </td>
          </tr>
          <tr>
            <th>
              <Text >
                설명
              </Text>
            </th>
            <td className="certification_period_section">

              <TextArea
                value={inputValues.summary}
                //innerRef={requireFields.systemName.ref}
                onChange={onChangeValues}
                name='summary'
                height={32}
                style={{ width: '400px' }}
                placeholder={"입력해주세요."}
              />
            </td>
            <th>
              <Text required={true}>
                <FormattedMessage id="T0365" />{/* 시스템 담당자 */}
              </Text>
            </th>
            <td className="certification_period_section">
              <InputSearch
                onClick={onClickOrg}
                innerRef={requireFields.systemUser.ref}
                width={400}
                height={32}
                data-label="systemUser"
                placeholder={formatMessage({ id: "T0025" })}// 검색어를 입력하세요
              // value={inputValues.systemUser.map(c => c.empNam).join(', ')}

              />
            </td>
          </tr>
        </tbody>
      </FormTable>
      <Organization
        visible={orgVisible}
        title={formatMessage({ id: "T0465" })} // 담당자 검색
        onOk={onOkOrg}
        onCancel={onCancelOrg}
        defaultData={[inputValues['systemUser']]}
        max={1}
      />
    </div>
  )
};
export default UseIternalSystem;