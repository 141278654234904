import React, { useState, useEffect } from "react";
import Wrapper from "./Styled";
import CharacterImage from "../AcademyImg/station_character.png";
import StationImage from "../AcademyImg/jedi_knight_search.png";
import { EmailIcon } from "icons";
import { Tooltip } from "antd";
import {
  Text, Table, Button, SendMessage, Image, InputSearch, DepartmentList, Organization
} from "components";
import { SearchOutlined } from "@ant-design/icons";
import fetchAbsolute from "utils/fetchAbsolute";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * Academy > Jedi Knight (L4) > Jedi Knight 주둔지
 */

const initialInputValues = {
  empNum: [],
  orgCd: [],
};

const initialPageInfo = {
  sort: "empNum",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 10,
  total: 0
};

const JediKnightStation = () => {
  const { formatMessage } = useIntl();

  const profile = useSelector(state => state.get('auth').get('profile'));

  const [dataSource, setDataSource] = useState([]);
  const [empNo, setEmpNo] = useState();
  const [smVisible, setSmVisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [inputValues, setInputValues] = useState({ ...initialInputValues });
  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  // Modal 라벨 state
  const [modalLabel, setModalLabel] = useState('');
  // Modal visible state
  const [modalVisible, setModalVisible] = useState({
    orgCd: false,
    empNum: false,
  });
  // 검색 눌렀을 때 정보
  const [searchInfo, setSearchInfo] = useState({
    empNum: [],
    orgCd: [],
  });

  const searchPlaceholder = formatMessage({ id: "T0025" }); // 검색어를 입력하세요

  // Modal Okay 버튼 클릭 이벤트 - filter Modal 제외
  const onOkModal = (data) => {
    setInputValues({ ...inputValues, [modalLabel]: data })
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false
    });
  };

  // Modal 닫기 이벤트
  const onCancelModal = () => {
    setModalVisible({
      ...modalVisible,
      [modalLabel]: false
    });
  };

  // Modal Open 버튼 클릭 이벤트
  const onClickModalButton = (e) => {
    const { label } = e.dataset;
    setModalLabel(label);
    setModalVisible({
      ...modalVisible,
      [label]: true
    });
  }

  /* 초기화 기능 */
  const onClickReset = () => {
    setInputValues({
      ...initialInputValues,
    });
    setSearchInfo({
      empNum: [],
      orgCd: [],
    });
    setPageInfo({
      ...initialPageInfo
    })
  }

  /* 검색 기능 */
  const onClickSearch = () => {
    if (pageInfo.currentPage === 1) {
      fetchList();
    }
    setSearchInfo({
      empNum: inputValues.empNum,
      orgCd: inputValues.orgCd,
    });
    setPageInfo({ ...initialPageInfo });
  };

  const onOkSendMessage = async () => {
    setSmVisible(false);
  };

  const onCancelSendMessage = () => {
    setSmVisible(false);
  };

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'empNum';
    let orderBy = "";
    if (!sorter.order) {
      order = "empNum"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setPageInfo({ ...pageInfo, ...searchObj });
    fetchList(searchObj);
  };

  const onClickEmail = (e) => {
    setSmVisible(true);
    setEmpNo(e.empNum);
  };

  const fetchList = async (page) => {
    if (page === undefined) {
      page = { ...pageInfo }
    };
    setTableLoading(true);
    /* 데이터 가공해주기 */
    const empNum = inputValues.empNum.map(data => {
      const tmpArr = [];
      tmpArr.push(data.empNum)
      return tmpArr;
    })
    const orgCd = inputValues.orgCd.map(data => {
      const tmpArr = [];
      tmpArr.push(data.codeId)
      return tmpArr;
    })
    const result = await fetchAbsolute("post", `/academy/getAcademyListMulVal?page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`, {
      data: {
        lastLev: "4", // knight level
        empNum: empNum.flat(),
        orgCd: orgCd.flat(),
      }
    });
    setTableLoading(false);
    setDataSource([...result.data.academyList]);
    setPageInfo({ ...page, total: result.data.totalItemsCount });
  };

  useEffect(() => {
    fetchList();
  }, [pageInfo.currentPage, searchInfo.empNum, searchInfo.orgCd]);

  const columns = [
    {
      key: 1,
      title: formatMessage({ id: "T0012" }), // 이름
      dataIndex: "empNam",
      align: "center",
      width: 10,
      sorter: (a, b) => a.empNam,
    },
    {
      key: 2,
      title: formatMessage({ id: "T0013" }), // 사번
      dataIndex: "empNum",
      align: "center",
      width: 100,
      sorter: (a, b) => a.empNum,
    },
    {
      key: 3,
      title: formatMessage({ id: 'T0927' }), // Dreago계급
      dataIndex: "lastLevNm",
      align: "center",
      width: 100,
    },
    {
      key: 4,
      title: formatMessage({ id: 'T0928' }), // 소속부서
      dataIndex: "orgNam",
      align: "center",
      width: 150,
      sorter: (a, b) => a.orgNam,
    },
    {
      key: 5,
      title: formatMessage({ id: 'T0929' }), // 최근 개발 과제
      dataIndex: "taskNm",
      align: "center",
      width: 200,
      render: (text, record) => {
        return (
          <Link to={`/assign/lookup/${record.taskId}`}>
            <Tooltip title={text}>
              <span style={{ color: '#fff' }}>{text}</span>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      key: 6,
      title: formatMessage({ id: 'T0930' }), // 개발 과제 수
      dataIndex: "taskCnt",
      align: "center",
      width: 100,
    },
    {
      key: 7,
      title: formatMessage({ id: 'T0931' }), // 접선경로
      dataIndex: "active",
      align: "center",
      width: 100,
      render: (index, record) => (
        <>
          {
            <a href="#!">
              <EmailIcon onClick={(e) => onClickEmail(record)} />
            </a>
          }
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <div className="search_area">
        <Image
          alt="Charater img"
          className="search__character"
          src={CharacterImage}
        />
        <div className="search__top">
          <p className="station__title">{formatMessage({ id: 'T0953' })}</p>{/* Jedi Knight 주둔지 */}
          <p className="station__content">
            {/* 힘을 나눠줄 수 있는 Jedi Knight의 주둔지 입니다. 눈앞에 벽이 보인다면, 이들과 함께 헤쳐나가세요. */}
            {formatMessage({ id: "T0970" }).split("\n").map((data, index) => {
              return (
                <span key={index}>
                  {data}
                  <br />
                </span>
              );
            })}{/* 힘을 나눠줄 수 있는 Jedi Knight의 주둔지 입니다.
눈앞에 벽이 보인다면, 이들과 함께 헤쳐나가세요. */}
          </p>
        </div>
        <div className="search__bottom">
          <Image
            alt="search bar img"
            src={StationImage}
            width='70%'
          />
          <div className="sword__section">
            <div className="search__container">
              <Text className='title'>{formatMessage({ id: "T1555" })}</Text>{/* 사용자 */}
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="empNum"
                placeholder={searchPlaceholder}
                value={inputValues.empNum.map(c => c.empNam).join(', ')}
              />
            </div>
            <div className="search__container">
              <Text className='title'>{formatMessage({ id: "T0019" })}</Text>{/* 소속 */}
              <InputSearch
                onClick={onClickModalButton}
                width={200}
                height={32}
                data-label="orgCd"
                placeholder={searchPlaceholder}
                value={inputValues.orgCd.map(c => c.codeNm
                ).join(', ')}
              />
            </div>
            <div className="button__section">
              <Button
                className="search__total__button"
                onClick={onClickReset}
                width={55}
              >
                {formatMessage({ id: "T0037" })}{/* 초기화 */}
              </Button>
              <Button
                className="search__total__button"
                onClick={onClickSearch}
                width={45}
                type='primary'
              >
                <SearchOutlined />
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Table
        className="station_table_content"
        rowKey={(e) => e.cd}
        columns={columns}
        dataSource={dataSource}
        pagination={true}
        showSorterTooltip={true}
        onChange={onChangeTableColumns}
        loading={tableLoading}
        pageOptions={{
          total: +pageInfo.total,
          current: +pageInfo.currentPage,
          pageSize: +pageInfo.rowPerPage,
        }}
      />
      <SendMessage
        visible={smVisible}
        onOk={onOkSendMessage}
        onCancel={onCancelSendMessage}
        toEmpNo={empNo}
      />
      {/* 부서 조회 */}
      <DepartmentList
        visible={modalVisible.orgCd}
        companyCd={profile.COMP_CD}
        onOk={onOkModal}
        onCancel={onCancelModal}
        mode='multiple'
        defaultData={inputValues['orgCd']}
      />
      {/* 사용자 조회 */}
      <Organization
        visible={modalVisible.empNum}
        companyCd={profile.COMP_CD}
        title={formatMessage({ id: 'T1555' })} // 사용자
        onOk={onOkModal}
        onCancel={onCancelModal}
        defaultData={inputValues[modalLabel]}
      />
    </Wrapper>
  );
};

export default JediKnightStation;