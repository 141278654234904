import React, { useEffect, useState } from "react";
import fetchAbsolute from "utils/fetchAbsolute";
import { Text, Button, Table, Input, RangePicker, Collapse, FormTable } from "components";
import moment from "moment";
import { Select, Collapse as AntCollapse, Row, Col } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import Wrapper from "../Styled";
import { useIntl, FormattedMessage } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;
const { Panel } = AntCollapse;

const initInputValues = {
  type: [], // Agent Type
  hostName: "",
  newVer: [],
  code: [], // Result
  regFr: "",
  regTo: "",
};

/**
 * Admin > Agent > Agent 업데이트 이력
 */

const initialPageInfo = {
  sort: "updateDt",
  direction: ",desc",
  total: 0,
  currentPage: 1,
  pageSize: 10,
};

const AgentUpdateHistory = () => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const pageSize = [10, 20, 50];

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      currentPage: 1,
      pageSize: +value,
    };
    setPageInfo({ ...searchObj });
    getHistoryList({ ...searchObj });
  }

  const [sortedInfo, setSortedInfo] = useState({});

  const onChangeTableColumns = (pagination, filters, sorter) => {
    let order = sorter.field ? sorter.field : 'updateDt';
    let orderBy = "";
    if (!sorter.order) {
      order = "updateDt"
      orderBy = ",desc"
    }
    else if (sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    setPageInfo({ ...searchObj });
    getHistoryList({ ...searchObj });
  };

  const agentStatus = {
    200: `${formatMessage({ id: "T0861" })}`, // Update 불필요
    400: `${formatMessage({ id: "T1100" })}`, // Network Error
    500: `${formatMessage({ id: "T1101" })}`, // Service Internal Error
    800: `${formatMessage({ id: "T0862" })}`, // Update 필요(수행)
  };

  const [datas, setDatas] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);
  const [inputValues, setInputValues] = useState({ ...initInputValues });

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  // from, to 두 개의 키 값을 변경하기 때문에
  const onChangeDataRange = ([regFr, regTo]) => {
    const tmp = { ...inputValues, regFr, regTo };
    setInputValues(tmp);
  };

  const onChangeDates = (value) => {
    const range = value.map((v) => (!v ? "" : v.format("YYYY-MM-DD")));
    onChangeDataRange(range);
  };

  const onChangeSelectType = (e) => {
    setInputValues({ ...inputValues, type: e });
  };

  const onChangeSelectResult = (e) => {
    setInputValues({ ...inputValues, code: e });
  };

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === "newVer") {
      onChangeSearchInput(name, [value]);
    } else {
      onChangeSearchInput(name, value);
    }
  };

  const onChangeSearchInput = (label, value) => {
    setInputValues({ ...inputValues, [label]: value });
  };

  const getHistoryList = async (page, searchValues) => {
    /* 초기화 기능 때문에 필요함 */
    if (page === undefined) {
      page = { ...pageInfo }
    }
    if (searchValues === undefined) {
      searchValues = { ...inputValues };
    }
    setTableLoading(true);
    const result = await fetchAbsolute(
      "post",
      `/admin/agent/getAgentHostStatusListMulVal`
      + `?page=${page.currentPage - 1}&size=${page.pageSize}&sort=${page.sort}${page.direction}`,
      {
        data: {
          code: searchValues.code,
          hostName: searchValues.hostName,
          type: searchValues.type,
          ver: searchValues.newVer,
          updateDtFrom: searchValues.regFr,
          updateDtTo: searchValues.regTo,
        },
      }
    );
    setTableLoading(false);
    setDatas([...result.data.list]);
    setPageInfo({ ...page, total: result.data.totalItemsCount });
  };

  /* 검색 버튼 기능 */
  const onClickSearchAgent = () => {
    getHistoryList();
  };

  /* 검색 초기화 기능 */
  const onClickReset = () => {
    setSortedInfo({});
    setInputValues({ ...initInputValues });
    getHistoryList(initialPageInfo, initInputValues);
  };
  // 업무 ID나 업무명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearchAgent(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const columns = [
    {
      key: "type",
      title: <FormattedMessage id="T1081" />, // Agent Type
      dataIndex: "type",
      render: (text) => {
        return text === "PC"
          ? `${formatMessage({ id: "T1080" })}`
          : `${formatMessage({ id: "T1080" })}`; // Runner Agent
      },
    },
    {
      key: "hostName",
      title: <FormattedMessage id="T1165" />, // Host Name
      dataIndex: "hostName",
      sorter: (a, b) => a.hostName - b.hostName,
      sortOrder: sortedInfo.columnKey === "hostName" && sortedInfo.order
    },
    {
      key: "preVer",
      title: <FormattedMessage id="T1086" />, // Previous Version
      dataIndex: "preVer",
      sorter: (a, b) => a.preVer - b.preVer,
      sortOrder: sortedInfo.columnKey === "preVer" && sortedInfo.order
    },
    {
      key: "newVer",
      title: <FormattedMessage id="T1087" />, // New Version
      dataIndex: "newVer",
      sorter: (a, b) => a.newVer - b.newVer,
      sortOrder: sortedInfo.columnKey === "newVer" && sortedInfo.order
    },
    {
      key: "code",
      title: <FormattedMessage id="T1102" />, // Result
      dataIndex: "code",
      render: (text) => agentStatus[text],
      sorter: (a, b) => a.code - b.code,
      sortOrder: sortedInfo.columnKey === "code" && sortedInfo.order
    },
    {
      key: "updateDt",
      title: <FormattedMessage id="T1082" />, // Update Date
      dataIndex: "updateDt",
      sorter: (a, b) => a.updateDt - b.updateDt,
      sortOrder: sortedInfo.columnKey === "updateDt" && sortedInfo.order
    },
  ];

  useEffect(() => {
    getHistoryList();
  }, []);

  return (
    <Wrapper>
      <div className="common__container">
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <FormTable pointBorder={false} size="sm">
              <colgroup>
                <col width="10.375%" />
                <col width="13.625%" />
                <col width="10.375%" />
                <col width="13.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ borderRadius: "14px 0 0 0" }}>
                    <Text>{formatMessage({ id: "T1081" })}</Text>{/* Agent Type */}
                  </th>
                  <td>
                    <Select
                      onChange={onChangeSelectType}
                      defaultValue="RN"
                      value={inputValues.type}
                      style={{ width: 252 }}
                      mode="multiple"
                      maxTagCount="responsive"
                      placeholder={formatMessage({ id: "T1081" })} // Agent Type
                    >
                      {/* <Option value="">ALL</Option>
                <Option value="PC"><FormattedMessage id ="T1079"/></Option> */}
                      <Option value="RN">
                        {formatMessage({ id: "T1080" })}{/* Runner Agent */}
                      </Option>
                    </Select>
                  </td>
                  <th><Text> {formatMessage({ id: "T1165" })}</Text></th>{/* Host Name */}
                  <td>
                    <Input
                      value={inputValues.hostName}
                      style={{ width: 252 }}
                      placeholder={formatMessage({ id: "T1165" })} // Host Name
                      onChange={onChangeInput}
                      name="hostName"
                      onKeyPress={onKeyPress}
                    />
                  </td>
                </tr>
                <tr>
                  <th><Text> {formatMessage({ id: "T1087" })}</Text></th>{/* New Version */}
                  <td>
                    <Input
                      value={inputValues.newVer}
                      style={{ width: 252 }}
                      placeholder={formatMessage({ id: "T1087" })} // New Version
                      onChange={onChangeInput}
                      name="newVer"
                      onKeyPress={onKeyPress}
                    />
                  </td>
                  <th><Text> {formatMessage({ id: "T1102" })}</Text></th>{/* Result */}
                  <td>
                    <Select
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{ width: 252 }}
                      value={inputValues.code}
                      onChange={onChangeSelectResult}
                      placeholder={formatMessage({ id: "T1102" })} // Result
                    >
                      <Option value="200" key="200">
                        <FormattedMessage id="T0861" />
                      </Option>
                      {/* Update 불필요 */}
                      <Option value="400" key="400">
                        <FormattedMessage id="T1100" />
                      </Option>
                      {/* Network Error */}
                      <Option value="500" key="500">
                        <FormattedMessage id="T1101" />
                      </Option>
                      {/* Service Internal Error */}
                      <Option value="800" key="800">
                        <FormattedMessage id="T0862" />
                      </Option>
                      {/* Update 필요(수행) */}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <th><Text>{formatMessage({ id: "T1082" })}</Text></th>{/* Update Date */}
                  <td>
                    <RangePicker
                      style={{
                        width: 252,
                        alignItems: "center",
                      }}
                      onChangeDate={onChangeDates}
                      onCalendarChange={onChangeDates}
                      value={[
                        inputValues.regFr !== "" ? moment(inputValues.regFr) : "",
                        inputValues.regTo !== "" ? moment(inputValues.regTo) : "",
                      ]}
                      defaultValue={[undefined, undefined]}
                    />
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <Row style={{ padding: "0 13px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0 25px",
                  padding: "10px 0",
                }}
              >
                <Button width="120" height="40" onClick={onClickReset}>
                  <FormattedMessage id="T0037" />
                  {/* 초기화 */}
                </Button>
                <Button
                  width="120"
                  height="40"
                  type="primary"
                  onClick={onClickSearchAgent}
                >
                  <FormattedMessage id="T0039" />
                  {/* 검색 */}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div className="left" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
          <span>{/* 페이지당 행 */}
            {formatMessage({ id: "T0036" })} &emsp;
            <Select
              name="pagination"
              defaultValue={pageSize[0]}
              value={+pageInfo.pageSize}
              onSelect={onSelectPage}
            >
              {pageSize.map((v, index) => ( // {number} 개
                <Option key={index} value={v}>
                  {fmMessage({ id: "T1228", values: { number: v } })}
                </Option>
              ))}
            </Select>
          </span>
        </div>
        <div>
          <Table
            //defaultPageSize={+pageInfo.pageSize}
            showSorterTooltip={true}
            loading={tableLoading}
            columns={columns}
            dataSource={datas}
            rowKey={(e) => e.updateDt + e.hostName}
            pagination={true}
            locale={{ emptyText: `${formatMessage({ id: "T0863" })}` }} // 업데이트 이력이 없습니다.
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.pageSize,
            }}
            onChange={onChangeTableColumns}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default AgentUpdateHistory;
