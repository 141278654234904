import fetchAbsolute from "utils/fetchAbsolute";
import { takeEvery, put, select } from 'redux-saga/effects';
import { SET_MENU_LIST, loadMenuList } from './action';

function* getMenuList() {
    const profile = yield select(state => state.get('auth').get('profile'));
    try {
        const { data: menuData } = yield fetchAbsolute('get', `/common/getMenuAuth?empNum=${profile.EMP_NO}`);
        const { data: displayData } = yield fetchAbsolute('get', `/admin/menu/getAllDisplayList`);

        yield put(loadMenuList(menuData, displayData));
    } catch (err) { };
};

function* rootSaga() {
    yield takeEvery(SET_MENU_LIST, getMenuList);
};

export default rootSaga;