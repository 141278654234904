import React from "react";
import styled from "styled-components";
import { Modal, Table } from "components";
import { useIntl } from "react-intl";

/**
 * Filename: RecordDataModal
 * 관리자 > Workitem 통합 관리
 */

const Wrapper = styled((props) => <Modal {...props} />)`
  .container {
    overflow-y: auto;
    min-height: 100px;
    max-height: 500px;
  }
  .cancel__button {
    display: none;
  }
`;

const RecordDataModal = ({
  visible,
  onOk = () => { },
  onCancel = () => { },
  title = "",
  recordData
}) => {
  const { formatMessage } = useIntl();

  const confirmationText = formatMessage({ id: 'T0064' });  // 확인

  const columns = [
    {
      key: 1,
      title: 'Key',
      dataIndex: 'key'
    },
    {
      key: 2,
      title: 'Value',
      dataIndex: 'value'
    },
  ];

  return (
    <Wrapper
      width={800}
      visible={visible}
      title="Record Data"
      okText={confirmationText}
      onCancel={onCancel}
      onOk={onOk}
    >
      <div className="container">
        <Table
          columns={columns}
          dataSource={recordData}
        />
      </div>
    </Wrapper>
  );
};
export default RecordDataModal;