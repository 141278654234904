import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { Carousel } from 'antd';
import { ArrowNextIcon, ArrowPrevIcon } from "icons";
import fetchAbsolute from "utils/fetchAbsolute";
import { Image } from 'components';

const Welcome = () => {
    const { formatMessage } = useIntl();

    const [bannerList, setBannerList] = useState([]);

    const onClickGoLink = (url) => {
        const newTab = window.open(url, '_blank', 'noopener,noreferrer')
        if (newTab) newTab.opener = null;
    }

    /* Banner Images */
    useEffect(() => {
        const getBanner = async () => {
            const result = await fetchAbsolute(
            "get",
            `/admin/commonBanner/getBannerList`
            );
            if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
            const replaceList = result.data.filter((use) => use.bannerUseYn === 'Y').map((v, index) => {
            return {
                ...v,
                key: index,
                link: v.link.replace(
                "200x200",
                "0x0"
                ),
            };
            });
            setBannerList([...replaceList].sort((a, b) => { return a.bannerOrder - b.bannerOrder }));
        };
        getBanner();
    }, []);

    return (
        <Carousel 
            style={{
                width: '700px',
                height: '179px'
            }}
            autoplay
            autoplaySpeed={5000}
            arrows
            prevArrow={<ArrowPrevIcon />}
            nextArrow={<ArrowNextIcon />}
            >
                {
                bannerList?.map((banner, index) => {
                return (
                    banner.pageLink === '' 
                    ?
                        <Image 
                            alt="welcome img" 
                            key={index} 
                            src={banner.link} 
                            width='700px'
                            height="179px"
                        />
                    :
                        <Image 
                            key={index} 
                            onClick={() => onClickGoLink(banner.pageLink)} 
                            style={{ cursor: 'pointer'}} 
                            alt="welcome img" 
                            src={banner.link} 
                            width='700px'
                            height="179px"
                        />
                );
                })}
        </Carousel>
    );
};
export default Welcome;
