import React, { useState, useEffect } from "react";
import {
  PageTemplate,
  Header,
  Footer,
  Table,
  Text,
  Button,
  FormTable,
  Collapse,
  Input
} from "components";
import { Collapse as AntCollapse, Select, Row, Col } from "antd";
import { CloseCollapseIcon, OpenCollapseIcon } from "icons";
import Wrapper from "./Styled";
import fetchAbsolute from "utils/fetchAbsolute";
import { FormattedMessage, useIntl } from "react-intl";
import useFormatMessage from "hooks/useFormatMessage";

const { Option } = Select;
const { Panel } = AntCollapse;

/**
 * Filename: Admin Email Trigger/index.js
 * Description: Email Trigger 관리
 * 
 */

const initialPageInfo = {
  sort: "name",
  direction: ",desc",
  currentPage: 1,
  rowPerPage: 10,
  total: 0
};

const AdminEmailTrigger = ({ history }) => {
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const [dataSource, setDataSource] = useState([]);
  const [searchCol, setSearchCol] = useState("name");
  const [searchInput, setSearchInput] = useState("");
  const [searchActivate, setSearchActivate] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [openCollapse, setOpenCollapse] = useState(true);

  const [pageInfo, setPageInfo] = useState({ ...initialPageInfo });
  const pageSize = [10, 20, 50, 70];
  const [sortedInfo, setSortedInfo] = useState({});

  const onSelectPage = (value) => {
    const searchObj = {
      ...pageInfo,
      page: 1,
      rowPerPage: +value,
    };
    fetchEmailTriggerList({ ...searchObj }, searchCol, searchInput);
    // fetchItemList({ ...searchObj });
  }

  const onChangeCollapse = () => {
    setOpenCollapse((openCollapse) => !openCollapse);
  };

  const columns = [
    {
      key: 1,
      title: "Email Trigger Name",
      dataIndex: "name",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.name - b.name,
      sortOrder: sortedInfo.field === "name" && sortedInfo.order
      // render: (text, record) => {
      //   if (record.crCode.includes('DEV')) {
      //     return text + ` ${formatMessage({ id: "T0682" })}`
      //   }
      //   else {
      //     return text + ` ${formatMessage({ id: "T0683" })}`
      //   }
      // }
    },
    {
      key: 2,
      title: `Trigger ${formatMessage({ id: "T1229" })}`, // Trigger 활성화	
      dataIndex: "activate",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.activate - b.activate,
      sortOrder: sortedInfo.field === "activate" && sortedInfo.order
    },
    {
      key: 3,
      title: "Protocol(POP3/IMAPS)",
      dataIndex: "protocol",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.protocol - b.protocol,
      sortOrder: sortedInfo.field === "protocol" && sortedInfo.order,
      render: (record) => {
        return (
          record.toUpperCase()
        )
      },
    },
    {
      key: 4,
      title: "URL",
      dataIndex: "url",
      align: "center",
      width: "15%",
      sorter: (a, b) => a.url - b.url,
      sortOrder: sortedInfo.field === "url" && sortedInfo.order
    },

    {
      key: 5,
      title: "Port",
      dataIndex: "port",
      width: "10%",
      align: "center",
      sorter: (a, b) => a.port - b.port,
      sortOrder: sortedInfo.field === "port" && sortedInfo.order
    },
    {
      key: 6,
      title: <FormattedMessage id="T1309" />, // 확인주기 (초)
      dataIndex: "cycle",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.cycle - b.cycle,
      sortOrder: sortedInfo.field === "cycle" && sortedInfo.order
    },
    {
      key: 7,
      title: <FormattedMessage id="T1313" />, // TLS여부
      dataIndex: "tlsYn",
      align: "center",
      width: "10%",
      sorter: (a, b) => a.tlsYn - b.tlsYn,
      sortOrder: sortedInfo.field === "tlsYn" && sortedInfo.order
    },
  ];

  const onSelectCol = (e) => {
    setSearchCol(e);
    setSearchInput('');
    setSearchActivate('');
  };

  const onChangeSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const onSelectActivate = (e) => {
    setSearchInput(e);
    setSearchActivate(e);
  };

  const onClickSearch = async () => {
    // if (searchCol !== "activate") {
    //   const TriggerResult = await fetchAbsolute(
    //     "get",
    //     `/admin/trigger/email/list?${searchCol}=${searchInput}`
    //   );
    //   setTableLoading(false);
    //   setDataSource([...TriggerResult.data]);
    // } else {
    //   const TriggerResult = await fetchAbsolute(
    //     "get",
    //     `/admin/trigger/email/list?${searchCol}=${searchActivate}`
    //   );
    fetchEmailTriggerList(pageInfo, searchCol, searchInput);
  };

  const fetchEmailTriggerList = async (page, serachCol, searchInput) => {
    if (page === undefined) {
      page = { ...pageInfo }
    }
    const searchColumn = "name";
    const searchData = "";
    if (serachCol === undefined) {
      serachCol = searchColumn;
    }
    if (searchInput === undefined) {
      searchInput = searchData;
    }

    setTableLoading(true);
    const TriggerResult = await fetchAbsolute(
      "get",
      `/admin/trigger/email/list?`
      + `page=${page.currentPage - 1}&size=${page.rowPerPage}&sort=${page.sort}${page.direction}`
      + `&${searchCol}=${searchInput}`
    );
    // &sort=${page.sort}${page.direction}
    setTableLoading(false);
    if (TriggerResult.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    setDataSource([...TriggerResult.data.lists]);
    setPageInfo({ ...page, total: TriggerResult.data.totalItemsCount })
  };

  useEffect(() => {
    fetchEmailTriggerList();
  }, []);

  // 과제 ID나 과제명 인풋창에서 엔터 누르면 검색
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      onClickSearch(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => {
        history.push(`/admin/emailtrigger/modify/${record.emailInfoSeq}`);
      },
    };
  };

  //테이블 데이터 바뀔때 함수 (정렬기능)
  const onChangeTableColumns = (pagination, filters, sorter) => {
    const order = sorter.field ? sorter.field : 'name';
    let orderBy = "";
    if (!sorter.order || sorter.order?.includes("desc")) orderBy = ",desc";
    else if (sorter.order?.includes("asc")) orderBy = ",asc";
    const searchObj = {
      ...pageInfo,
      sort: order,
      direction: orderBy,
      currentPage: pagination.current
    };
    setSortedInfo(sorter);
    setPageInfo({ ...searchObj });
    fetchEmailTriggerList(searchObj, searchCol, searchInput);
  };

  const onClickReset = async () => {
    setSearchCol('name');
    setSearchInput('');
    setSearchActivate('');
    setSortedInfo({});
    setPageInfo({ ...initialPageInfo })
    fetchEmailTriggerList({ ...initialPageInfo });
    // const TriggerResult = await fetchAbsolute(
    //   "get",
    //   `/admin/trigger/email/list`
    // );
    // setDataSource([...TriggerResult.data]);
  }

  return (
    <PageTemplate header={<Header />} footer={<Footer />} headerColor="none">
      <Wrapper
        style={{
          padding: "30px 50px",
        }}
      >
        <Collapse
          expandIconPosition="end"
          onChange={onChangeCollapse}
          expandIcon={() =>
            !openCollapse ? <OpenCollapseIcon /> : <CloseCollapseIcon />
          }
          activeKey={openCollapse ? ["1"] : []}
          defaultActiveKey={openCollapse ? ["1"] : []}
        >
          <Panel header={formatMessage({ id: "T1194" })} key="1">{/* 상세검색 */}
            <FormTable pointBorder={false} size="sm">
              <colgroup>
                <col width="10.375%" />
                <col width="13.625%" />
                <col width="10.375%" />
                <col width="13.625%" />
              </colgroup>
              <tbody>
                <tr>
                  <th style={{ borderRadius: "14px 0 0 0" }}>
                    <Text><FormattedMessage id="T1312" /></Text>
                    {/*검색 조건 */}
                  </th>
                  <td>
                    <Select
                      style={{ width: 250 }}
                      onChange={onSelectCol}
                      value={searchCol || undefined}
                      defaultValue='name'
                    >
                      <Option value="name">Email Trigger Name</Option>
                      <Option value="protocol">Protocol(POP3 / IMAPS)</Option>
                      <Option value="activate">Trigger <FormattedMessage id="T1305" /></Option>
                      {/* 활성화 여부 */}
                    </Select>
                  </td>
                  <th>
                    <Text><FormattedMessage id="T1312" /></Text>
                    {/*검색 조건 */}
                  </th>
                  <td>
                    {searchCol !== 'activate' ?
                      <Input
                        style={{ width: 250 }}
                        name="searchInput"
                        value={searchInput}
                        onChange={onChangeSearchInput}
                        placeholder={formatMessage({ id: "T0028" })} // 검색어
                        onKeyPress={onKeyPress}
                      />
                      :
                      <Select
                        style={{ width: 250 }}
                        value={searchActivate || undefined}
                        placeholder={<FormattedMessage id="T0688" />} // 선택해주세요
                        onChange={onSelectActivate}
                      >
                        <Option value="activate">Activate</Option>
                        <Option value="deactivate">Deactivate</Option>
                      </Select>
                    }
                  </td>
                </tr>
              </tbody>
            </FormTable>
            <Row style={{ padding: "0 13px" }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "0 25px",
                  padding: "10px 0",
                }}
              >
                <Button width="120" height="40" onClick={onClickReset}>
                  <FormattedMessage id="T0037" />
                  {/* 초기화 */}
                </Button>
                <Button
                  width="120"
                  height="40"
                  type="primary"
                  onClick={onClickSearch}
                >
                  <FormattedMessage id="T0039" />
                  {/* 검색 */}
                </Button>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div className="admin_email_trigger__table_wrap">
          <Row gutter={[8, 24]} className="align-end">
            <Col span={6}>
              <span>
                {/* 페이지당 행 */}
                {formatMessage({ id: "T0036" })} &emsp;
                <Select
                  name="pagination"
                  defaultValue={pageSize[0]}
                  value={+pageInfo.rowPerPage}
                  onSelect={onSelectPage}
                >
                  {pageSize.map((v, index) => (
                    <Option key={index} value={v}>
                      {fmMessage({ id: "T1228", values: { number: v } })}
                    </Option>
                  ))}
                </Select>
              </span>
            </Col>
            <Col offset={12} span={6} className="email_trigger__button_group">
              <Button
                style={{ width: "150px" }}
                type='primary'
                onClick={() => history.push("/admin/emailtrigger/enter")}
              >
                <FormattedMessage id="T0050" />{/* 등록 */}
              </Button>
            </Col>
          </Row>
          <Table
            rowKey={(e) => e.emailInfoSeq}
            columns={columns}
            dataSource={dataSource}
            pagination={true}
            // defaultPageSize={rowPerPage}
            pageSizeVisible={false}
            loading={tableLoading}
            showSorterTooltip={true}
            onRow={onRow}
            onChange={onChangeTableColumns}
            pageOptions={{
              total: +pageInfo.total,
              current: +pageInfo.currentPage,
              pageSize: +pageInfo.rowPerPage,
            }}
          />
        </div>
      </Wrapper>
    </PageTemplate >
  );
};

export default AdminEmailTrigger;