import React, { useEffect, useState } from 'react';
import { useIntl } from "react-intl";
import moment from "moment";
import { Select } from 'antd';
import fetchAbsolute from 'utils/fetchAbsolute';
import pdfDownload from 'utils/pdfDownload';
import {
    PageTemplate,
    Header,
    Footer,
    Text,
    TypePicker,
    Button,
} from 'components';
import Wrapper from './Styled';
import PredictionROI from './PredictionROI';
import WholeCompanyROI from './WholeCompanyROI';
import OrgROI from './OrgROI';
import TaskROI from './TaskROI';

const { Option } = Select;

/**
 * 대시보드 > ROI 현황
 */

const initConditions = {
    taskType: [],
    taskId: [],
    companyCd: [],
}

const DashboardROI = () => {
    const { formatMessage } = useIntl();

    const [taskTypeList, setTaskTypeList] = useState([]);
    const [companyList, setCompanyList] = useState([]);
    const [taskList, setTaskList] = useState([]);
    const [companyCd, setCompanyCd] = useState([]);
    const [standardDate, setStandardDate] = useState(moment());
    const [defaultTaskType, setDefaultTaskType] = useState([]);
    const [byOrgList, setByOrgList] = useState([]);
    const [byTaskList, setByTaskList] = useState([]);
    const [conditions, setConditions] = useState({
        ...initConditions
    });
    const [isLoading, setIsLoading] = useState(false);

    /* onClick 기능 때문에 여기에 배치 */
    const orgConfig = {
        xField: 'type',
        yField: 'ROI',
        color: '#ff7b00',
        label: {
            position: 'middle',
            style: {
                fill: '#FFF',
            },
            content: (item) => {
                return item.ROI.toFixed(2);
            },
        },
        xAxis: {
            label: {
                autoRotate: false,
            },
        },
        onReady: (plot) => {
            plot.on('element:click', (...args) => {
                /* 회사 코드 추출 */
                const { data: { data: { key } } } = args[0];
                setCompanyCd([key]);
            });
        },
    };

    const onChangeDate = (date) => {
        setStandardDate(date);
    };

    /* 조직별 */
    const onChangeOrg = (e) => {
        /* 배열 */
        setConditions({
            ...conditions,
            companyCd: [...e],
        });
        setCompanyCd([...e]);
    };

    /* 과제유형 */
    const onChangeType = (e) => {
        setConditions({
            ...conditions,
            taskType: [...e]
        })
    };

    /* 과제 아이디 */
    const onChangeTaskId = (e) => {
        setConditions({
            ...conditions,
            taskId: [...e]
        })
    };

    /* 검색 초기화 버튼 기능 */
    const onClickSearchReset = () => {
        const tmpArr = [];
        for (let i in taskTypeList) {
            tmpArr.push(taskTypeList[i].cd)
        }
        setConditions({
            ...initConditions,
            taskType: [...tmpArr], // taskType은 기본 설정되어 있어야 한다
        });
    };

    const getOrgRoiList = async () => {
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...conditions.taskType];
        if (conditions.taskType.length === 0) tmpTaskType = [...defaultTaskType];
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/RoiStatusDesign/RoiByOrgList`, {
            data: {
                deployDate: standardDate.format('YYYY-MM-DD'),
                orgCd: conditions.companyCd,
                taskType: tmpTaskType || [],
                taskId: conditions.taskId
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    type: data[i].companyNm,
                    key: data[i].companyCd,
                    ROI: +data[i].roi || 0
                }
            )
        }
        setByOrgList([...tmpArr]);
    };

    const getTaskRoiList = async () => {
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...conditions.taskType];
        if (conditions.taskType.length === 0) tmpTaskType = [...defaultTaskType];

        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/RoiStatusDesign/RoiByTaskList`, {
            data: {
                taskId: conditions.taskId,
                deployDate: standardDate.format('YYYY-MM-DD'),
                companyCd,
                taskType: tmpTaskType,
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    type: data[i].taskId,
                    key: data[i].taskId,
                    ROI: +data[i].roi || 0
                }
            )
        }
        setByTaskList([...tmpArr]);
    };

    useEffect(() => {
        getTaskRoiList();
    }, [companyCd, standardDate, conditions.taskType, conditions.taskId]);

    useEffect(() => {
        getOrgRoiList();
    }, [conditions, standardDate]);

    useEffect(() => {
        if (taskTypeList.length === 0) return;

        const tmpArr = [];
        for (let i in taskTypeList) {
            tmpArr.push(taskTypeList[i].cd)
        }
        setConditions({
            ...conditions,
            taskType: [...tmpArr]
        })
        setDefaultTaskType([...tmpArr]);
    }, [taskTypeList]);

    /* 초기 리스트들 세팅 */
    useEffect(() => {
        // taskType 가져오는 함수
        const getTaskType = async () => {
            try {
                const result = await fetchAbsolute('get', '/common/commonCd/getCategoryId/OPER_TYPE');
                setTaskTypeList([...result.data?.filter(v => v.useYn === 'Y')]);
            } catch (err) {
                return alert(err);
            }
        }
        // taskList 가져오는 함수
        const getTaskList = async () => {
            const result = await fetchAbsolute('post', `/dashboard/RoiStatusDesign/TaskRoiList`, {
                data: {
                    deployDate: standardDate.format('YYYY-MM-DD'),
                }
            });
            setTaskList([...result.data]);
        }
        /* 조직별 리스트 가져오기 */
        const getCompanyList = async () => {
            const result = await fetchAbsolute('get', `/admin/commonMaster/getKpiStandardCompanyList`);
            setCompanyList([...result.data]);
        };
        getCompanyList();
        getTaskType();
        getTaskList();
    }, []);

    return (
        <PageTemplate header={<Header />} footer={<Footer />} headerColor='none'>
            <Wrapper>
                <div className='roi_container'>
                    <div className='standard_conditions'>
                        <div className='condition'>
                            <Text className="">{formatMessage({ id: "T1512" })}</Text>{/* 기준일자 */}
                            <TypePicker
                                placeholder={moment().format("YYYY.MM.DD")}
                                defaultValue={moment()}
                                value={standardDate}
                                width="170"
                                height="32"
                                onChange={onChangeDate}
                                allowClear={false}
                            />
                        </div>
                        <div className='condition'>
                            <Text className="">{formatMessage({ id: "T1524" })}</Text>{/* 조직별 ROI */}
                            <Select
                                mode='multiple'
                                maxTagCount='responsive'
                                style={{
                                    width: 250
                                }}
                                onChange={onChangeOrg}
                                value={conditions.companyCd}
                                placeholder={formatMessage({ id: "T0391" })} // 전체
                            >
                                {
                                    companyList.map(list => {
                                        return (
                                            <Option key={list.companyCd} value={list.companyCd}>{list.companyNm}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className='condition'>
                            <Text className="">{formatMessage({ id: "T1525" })}</Text>{/* 과제별 ROI */}
                            <Select
                                style={{
                                    width: 200
                                }}
                                mode='multiple'
                                maxTagCount='responsive'
                                name="taskId"
                                placeholder={formatMessage({ id: "T0391" })} // 전체
                                value={conditions.taskId}
                                onChange={onChangeTaskId}
                                filterOption={taskList
                                    .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                                    .map((item) => item.taskId)}
                            >
                                {
                                    taskList.map(type => {
                                        return (
                                            <Option key={type.taskId} value={type.taskId}>{type.taskNm}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                        <div className='condition'>
                            <Button onClick={onClickSearchReset}>{formatMessage({ id: "T0037" })}</Button>{/* 초기화 */}
                        </div>
                        <div>
                            <Button onClick={() =>
                                pdfDownload('Dashboard_Saving_time.pdf')}>{formatMessage({ id: 'T1317' })} </Button>{/* PDF 다운로드 */}
                        </div>
                    </div>
                    <div className='first__section'>
                        <div className='prediction_roi'>
                            <PredictionROI standardDate={standardDate.format('YYYY-MM-DD')} conditions={conditions} />
                        </div>
                    </div>
                    <div className='second__section'>
                        <div className='whole_company_roi'>
                            <WholeCompanyROI standardDate={standardDate.format('YYYY-MM-DD')} conditions={conditions} />
                        </div>
                    </div>
                    <div className="standard_conditions">
                        <div>{formatMessage({ id: "T0740" })}</div>{/* 과제유형 */}
                        <div>
                            <Select
                                style={{
                                    width: 'fit-content',
                                    minWidth: 150
                                }}
                                mode="multiple"
                                name="taskType"
                                value={conditions.taskType}
                                onChange={onChangeType}
                                filterOption={taskTypeList
                                    .sort((a, b) => (a.sort > b.sort ? 1 : -1))
                                    .map((item) => item.cd)}
                            >
                                {
                                    taskTypeList.map(type => {
                                        return (
                                            <Option key={type.cd} value={type.cd}>{type.cd}</Option>
                                        )
                                    })
                                }
                            </Select>
                        </div>
                    </div>
                    <div className="third__section">
                        <div className='org_roi'>
                            {/* 조직별 ROI */}
                            <OrgROI byOrgList={byOrgList} byTaskList={byTaskList} isLoading={isLoading} orgConfig={orgConfig} defaultTaskType={defaultTaskType}
                            />
                        </div>
                        <div className="task_roi">
                            {/* 과제별 ROI */}
                            <TaskROI byTaskList={byTaskList} isLoading={isLoading} defaultTaskType={defaultTaskType}
                            />
                        </div>
                    </div>
                </div>
            </Wrapper>
        </PageTemplate >
    )
}

export default DashboardROI;