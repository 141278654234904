import React from "react";
import { Button, Text, Image } from "components";
import Wrapper from "./Styled";

import ReturnToTop from "../AcademyImg/Return to top.png";
import BgImage from "../AcademyImg/jedi_knight_bg.png";
import Image1 from "../AcademyImg/Jedi Knight.png";
import Image2 from "../AcademyImg/jedi_knight_image2.png";
import Image4 from "../AcademyImg/jedi_knight_image4.png";

import Banner1 from "../AcademyImg/banner1.png";
import Banner2 from "../AcademyImg/banner2.png";
import Banner3 from "../AcademyImg/banner3.png";
import Banner4 from "../AcademyImg/banner4.png";
import BannerArrow from "../AcademyImg/banner_arrow.png";
import { FormattedMessage, useIntl } from "react-intl";

const Information = () => {
  const { formatMessage } = useIntl();

  const onClickReturntoTop = () => {
    document.documentElement.scrollTop = 0;
  };
  const onClickButton = () => {
    window.open("http://skhu.skhynix.com/");
  };
  return (
    <>
      <Wrapper style={{ width: 1800 }}>
        <div className="section section1">
          <Image
            className="section_background"
            alt="jdi knight background"
            src={BgImage}
          />
          <div className="section_box">
            <Image alt="section1 img" src={Image1} />
            <div className="section_text__box">
              <p className="jedi_knight__title">{formatMessage({ id: 'T0954' })}</p>{/* Jedi Knight (제다이 나이트) */}
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">{formatMessage({ id: 'T0909' })}</p>{/* 이야기 */}
                <p className="section_text__content">
                  {formatMessage({ id: 'T0955' })}{/* 다수의 전투에 참여한 Padawan들은 비효율 업무와의 전투는 손쉽게
이겨 시시함을 느끼기 시작했다. 평소같이 전투에 참여한 이들은
ARP Vader가 직접 출전한 악성업무와 전투를 벌이게되고, 이들은
참혹한 패배를 맞이하게 되었다.

분노를 머금고 아직 갈 길이 멀다는걸 깨달은뒤 다시 수련에
집중하여 칼퇴신의 가호를 받아 더 강력한 RPA의

갑옷으로 무장하여 전장에 다시 참여하게 된다. */}
                </p>
              </p>
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">{formatMessage({ id: 'T0906' })}</p>{/* 소개 */}
                <p className="section_text__content">
                  {formatMessage({ id: 'T0956' })}{/* Jedi Knight은 RPA 전문가 양성 과정을 이수한 RPA 고수입니다.
실력이 출중한 Padawan들은 전문가 양성 과정을 신청하여 Jedi
Knight로 승급 할 수 있습니다. Jedi Knight는 Dreago 신규
구성원의 교육을 담당하며, 다양한 과제 개발 경험을 통해 업무
프로세스 정의 및 고급 RPA 개발에 능숙합니다. */}
                </p>
              </p>
              <p className="section_text__paragraph">
                <p className="section_text__title point_color">
                  {formatMessage({ id: 'T0957' })}{/* Jedi Knight가 되기 위해서는 다음과 같은 조건을 충족해야 합니다. */}
                </p>
                <p className="section_text__content section_text__list">
                  <ul>
                    <li>{formatMessage({ id: 'T0958' })}</li>{/* 과제 개발에 능숙한 Padawan(향후 조건 추가 예정) */}
                    <li>{formatMessage({ id: 'T0959' })}</li>{/* RPA 전문가 양성 과정 이수 */}
                    <li>{formatMessage({ id: 'T0960' })}</li>{/* RPA Trainer Certification 취득 */}
                  </ul>
                </p>
              </p>
              <Button
                className="section__button"
                type="primary"
                onClick={onClickButton}
              >
                <FormattedMessage id="T0110" />{/* 제가 앞장 서겠습니다! */}
              </Button>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper>
        <div className="section section2">
          <p className="jedi_knight__title">
            {formatMessage({ id: 'T0961' })}{/* Jedi Knight는 다음과 같은 역량을 갖추게 됩니다 */}
          </p>
          <Image alt="jedi knight section2 img" src={Image2} />
          <div className="section_text__box">
            <p className="section_text__title">
              {formatMessage({ id: 'T0962' })}{/* 복잡한 업무 프로세스 분석 및 고급 커맨드를 활용한 자동화 업무 개발 */}
            </p>
            <p className="section_text__content">
              {formatMessage({ id: "T0963" })
                .split("\n")
                .map((data) => {
                  return (
                    <span>
                      {data}
                      <br />
                    </span>
                  );
                })}{/* Jedi Knight는 RPA 활용에 익숙하여 복잡하고 고난도인 업무를
개발하고,


Youngling 및 Padawan이 개발에 어려움을 겪고있는 업무 개발을 지원할
수 있습니다. */}
            </p>
          </div>
        </div>
        <div className="section section3">
          <p className="jedi_knight__title">
            {formatMessage({ id: 'T0964' })}{/* Jedi Knight가 되기 위해서는 다음과 같은 교육과정을 수료해야 합니다. */}
          </p>
          <div className="section_banner__wrap">
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner1}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0965' })}{/* Padawan 계급
취득 선행 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner2}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0966' })}{/* RPA 전문가
양성 과정 이수 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner3}
              />
              <div className="section_banner__content">
                {formatMessage({ id: "T0967" })
                  .split("\n")
                  .map((data) => {
                    return (
                      <span>
                        {data}
                        <br />
                      </span>
                    );
                  })}{/* RPA Trainer
 Certification 취득 */}
              </div>
            </div>
            <Image
              className="section_banner__arrow"
              alt="banner arrow"
              src={BannerArrow}
            />
            <div className="section_banner__box">
              <Image
                className="section_banner__image"
                alt="banner1"
                src={Banner4}
              />
              <div className="section_banner__content">
                {formatMessage({ id: 'T0968' })}{/* [선택] MPRS 분야별 지표

특성을 고려한 사례 전파

교육 */}
              </div>
            </div>
          </div>
        </div>
        <div className="section section4">
          <Image alt='Green Light saber' src={Image4} />
          <p className="section_text__content">
            {formatMessage({ id: "T0969" })
              .split("\n")
              .map((data) => {
                return (
                  <span>
                    {data}
                    <br />
                  </span>
                );
              })}{/* 난세에는 영웅이 필요하듯이,


Dreago Academy는 당신을 필요로 합니다.




전장을 이끌어서 구성원들의 미소를 지켜주세요.




 */}
          </p>
          <Button
            className="section__button"
            type="primary"
            onClick={onClickButton}
          >
            <FormattedMessage id="T0110" />{/* 제가 앞장 서겠습니다! */}
          </Button>
        </div>
        <div className="section section5">
          <a onClick={onClickReturntoTop} href="#!">
            <Image alt='go to top' src={ReturnToTop} />
            <Text className="academy_return_to_top">{formatMessage({ id: 'T0905' })}</Text>{/* 위로가기 */}
          </a>
        </div>
      </Wrapper>
    </>
  );
};

export default Information;