import styled from "styled-components";

const Wrapper = styled.div`
  width: 1400px; 
  margin: 0px auto; 
  height: fit-content;  
  background: #fff; 
  button {
    width: 140px;
    height: 40px;
  }
  .common__container {
    background: #fff;
    padding: 30.1px 30px;
    margin-bottom: 30px;
    border-top: none;
  }
  .iternal_system_manage__top {
    display: flex;
    justify-content: space-between;
  }
  .iternal_system__title {
    font-size: 20px;
    font-weight: bold; 
  }
  /* 상단 검색 부분*/
  .top_each__section { 
    display: flex; 
    justify-content: space-between;
  }
  .top_each__section .search_section {
    display: flex;
    gap: 10px;
  }
`;

export default Wrapper;
