import {
  SET_HANDYFLOWBPDLIST_VALUE,
  SET_HANDYFLOWBPDLIST_SPREAD,
  RESET_HANDYFLOWBPDLIST_VALUE,
  SET_HANDYFLOWBPDLIST_FILTERID,
} from "./action";

/**
 * Breadcrumb 구현을 위한 State
 *
 */
const hfBpdListInitial = {
  inputValues: {
    bpdId: "",
    bpdNm: "",
    bpdOrgCd: [],
    workId: [],
    regId: [],
    mgrIds: [], // 담당자 추가
    regFr: "",
    regTo: "",
    regType: "",
  },
  filterId: "",
  pageInfo: {
    order: "regTimestamp desc",
    currentPage: 1,
    rowPerPage: 20,
    tab: "all",
  },
  tableData: [],
  taskCount: {
    totalCount: 0,
    myTaskCount: 0,
    interestTaskCount: 0,
  },
};

const hfBpdList = (state = hfBpdListInitial, action) => {
  switch (action.type) {
    // inputValue 변경
    case SET_HANDYFLOWBPDLIST_VALUE: {
      const inputValues = {
        ...state.inputValues,
      };
      return {
        ...state,
        inputValues: {
          ...inputValues,
          [action.key]: action.value,
        },
      };
    }
    case SET_HANDYFLOWBPDLIST_SPREAD: {
      const inputValues = {
        ...state.inputValues,
        ...action.data,
      };
      return {
        ...state,
        inputValues,
      };
    }
    case RESET_HANDYFLOWBPDLIST_VALUE: {
      const inputValues = {
        ...hfBpdListInitial.inputValues,
      };
      return {
        ...state,
        inputValues,
      };
    }
    case SET_HANDYFLOWBPDLIST_FILTERID: {
      return {
        ...state,
        filterId: action.filterId,
      };
    }
    default:
      return state;
  }
};

export default hfBpdList;
