import styled from "styled-components";

const Wrapper = styled.div`
border-bottom: 1px solid #707070;
padding-top: 30px;

.ant-tabs-bar {
  border-bottom: none;
  margin: 0;
}
/* Tab 부분 */
.stationTab .ant-tabs-nav-scroll {
  background: black;
}

.stationTab .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: bold;
  border-bottom: 3px solid #ff6600 !important ;
}

.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  background: black;
  font-weight: bold;
  color: white !important ;
  border-color: black;
 
}

.ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bold;
  color: white !important ;
}
.ant-tabs-nav-wrap {
  border-bottom: 1px solid #000000;
}
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
    background: black;
    font-weight: bold;
    color: white !important ;
    border: none;
  }
`;

export default Wrapper;
