import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import { Empty } from 'components';
import { useIntl } from "react-intl";
import { Line } from '@ant-design/plots';
import fetchAbsolute from "utils/fetchAbsolute";
import useFormatMessage from "hooks/useFormatMessage";

const { Title } = Typography;

/**
 * 대시보드 > Insight > 일별 License Max 사용량
 * 
 */


const LicenseMaxUsage = ({ regDateFrom, regDateTo }) => {
    const { formatMessage } = useIntl();
    const fmMessage = useFormatMessage();
    const [usageData, setUsageData] = useState([]);
    const totalCnt = formatMessage({ id: "T1550" }); // 전체 라이센스 개수

    const config = {
        xField: 'date',
        yField: 'value',
        seriesField: 'category',
        color: ['#C1F1F8', '#F4664A'],
        point: {
            size: 5,
            shape: (item) => {
                if (item.category !== totalCnt) {
                    return 'circle';
                } else {
                    return 'hyphen'
                }
            },
            style: (item) => {
                if (item.category !== totalCnt) {
                    return {
                        fill: '#66DDEE',
                        stroke: '#66DDEE',
                        lineWidth: 2,
                    }
                }
            },
        },
        label: {
            formatter: (item) => {
                if (item.category !== totalCnt) {
                    return fmMessage({
                        id: "T1564",
                        values: { field: item.licenseMaxUsageCnt },
                    }) // {field} 명
                }
            },
        },
        xAxis: {
            // type: 'time',
            label: {
                autoRotate: false,
            },
        },
        lineStyle: (item) => {
            if (item.category === totalCnt) {
                return {
                    lineDash: [4, 4],
                }
            }
        },
        // scrollbar: {
        //     type: 'horizontal',
        // },
        tooltip: {
            customContent: (title, items) => {
                if (!items || items.length === 0) return null;
                const date = items[0]?.data?.date || 0;
                const value = items[0]?.data?.value || 0;
                const totalValue = items[1]?.data?.value || 0;

                return (
                    <div className="usage_tooltip">
                        {/* Reg Date */}
                        <div>
                            {formatMessage({ id: "T1548" })}&#58;&nbsp;<b>{date}</b>
                        </div>
                        {/* 시간대별 최대 사용자 수 */}
                        <div>
                            {formatMessage({ id: "T1563" })}&#58;&nbsp;<b>{+value}</b>
                        </div>
                        {/* 전체 라이선스 개수 */}
                        <div>
                            {totalCnt}&#58;&nbsp;<b>{+totalValue}</b>
                        </div>
                    </div>
                );
            },
        },

    };

    /* 과제별 절감 시간 */
    const fetchMaxUsage = async () => {
        const result = await fetchAbsolute('post', `/dashboard/OperInsight/MaximumDailyLicenseUsageList`, {
            data: {
                regDateFrom,
                regDateTo
            }
        });
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            tmpArr.push(
                {
                    date: data[i].date,
                    key: data[i].date,
                    licenseMaxUsageCnt: +data[i].licenseMaxUsageCnt || 0,
                    value: +data[i].licenseMaxUsageCnt || 0,
                    totalCnt: +data[i].totalCnt || 0,
                    category: formatMessage({ id: "T1563" }) // 시간대별 최대 사용자 수
                },
                {
                    date: data[i].date,
                    key: data[i].date,
                    licenseMaxUsageCnt: +data[i].licenseMaxUsageCnt || 0,
                    value: +data[i].totalCnt || 0,
                    totalCnt: +data[i].totalCnt || 0,
                    category: totalCnt
                },
            )
        }
        setUsageData([...tmpArr]);
    };

    useEffect(() => {
        fetchMaxUsage();
    }, [regDateFrom, regDateTo]);

    return (
        <div className='box__container'>
            {/* 일별 License Max 사용량*/}
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1527" })}
            </Title>
            <Divider />
            {
                usageData.length > 0 ?
                    <Line {...config}
                        className="dashboard_graph"
                        data={usageData}
                        style={{
                            height: 300,
                            width: `calc(10px * ${usageData.length}) <== 900px? 900px: calc(10px * ${usageData.length})`,
                        }} />
                    : <Empty />
            }
        </div>
    )
}
export default LicenseMaxUsage