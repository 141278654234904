import React, { useState, useEffect } from 'react';
import { Text, LinkModal } from 'components';
import { Input } from 'antd';
import { useDispatch } from 'react-redux';
import { setVisible } from 'store/loading/action';
import fetchAbsolute from "utils/fetchAbsolute";
import { ApprovalIcon } from 'icons';
import { useIntl } from "react-intl";

const { TextArea } = Input;

const DeployedApproval = ({ match: { params } }) => {
    const { formatMessage } = useIntl();
    const { taskId, seq } = params;

    const dispatch = useDispatch();

    const [requestResult, setRequestResult] = useState({});
    const [requestInfo, setRequestInfo] = useState({});
    const [reason, setReason] = useState('');

    const fetchSharedRequest = async () => {
        dispatch(setVisible(true));
        const result = await fetchAbsolute('post', `/task/oracle/approvalTaskDeploy?taskId=${taskId}&deploySeq=${seq}&approvalReason=${reason}`);
        dispatch(setVisible(false));

        setRequestResult({ ...result });
    }


    const onChangeReason = (e) => {
        setReason(e.target.value);
    }

    const onClickButton = () => {
        if (reason.length === 0) return alert(formatMessage({ id: 'T0307' })); // 승인 사유를 입력해주세요
        const confirmResult = window.confirm(formatMessage({ id: 'T0326' })); // 운영 배포 요청을 승인하시겠습니까?
        if (confirmResult) {
            fetchSharedRequest();
        }
    }

    const onCancelModal = () => {
        window.close();
    }

    useEffect(() => {
        const fetchCheckRequest = async () => {
            const result = await fetchAbsolute('post', `/task/oracle/getRequestInfoByDeploySeq?taskId=${taskId}&deploySeq=${seq}`);
            if (result.error) {
                setRequestInfo({ ...result });
            }
        }
        fetchCheckRequest();
    }, []);

    if (requestInfo.error) {
        return (
            <LinkModal
                visible={true}
                title=""
                width={540}
                closable={false}
                footer={[]}
                titleDisabled
                footerDisabled
            >
                <div className='request_box'>
                    {
                        requestInfo.status === 409 ?
                            <>
                                <ApprovalIcon />
                                <Text className='request_box__text'>
                                    {requestInfo.data.message}
                                </Text>
                            </>
                            :
                            <Text className='request_box__text'>
                                {formatMessage({ id: 'T0994' })}{/* 요청 정보를 가져오는 도중 문제가 발생했습니다. */}
                            </Text>
                    }
                </div>
            </LinkModal>
        )
    }

    if (Object.keys(requestResult).length === 0) {
        return (
            <LinkModal
                visible={true}
                title={formatMessage({ id: 'T0995' })} // 과제 승인 사유 입력
                width={540}
                onOk={onClickButton}
                onCancel={onCancelModal}
                closable={false}
            >
                <div>
                    <TextArea
                        style={{
                            width: 500,
                            height: 100
                        }}
                        value={reason}
                        placeholder={formatMessage({ id: 'T0996' })} // 과제 승인 사유를 입력해주세요
                        onChange={onChangeReason}
                    />
                </div>
            </LinkModal>
        )
    } else {
        return (
            <LinkModal
                visible={true}
                title=""
                width={540}
                closable={false}
                footer={[]}
                titleDisabled
                footerDisabled
            >
                <div className='request_box'>
                    <Text className='request_box__text'>
                        {!requestResult.error ? `${formatMessage({ id: 'T0997' })}` // 운영 배포 승인을 완료했습니다.
                            : requestResult.data?.message || `${formatMessage({ id: 'T0998' })}` // 운영 배포 승인을 실패하였습니다.
                        }
                    </Text>
                </div>
            </LinkModal>
        )
    }
}

export default DeployedApproval;