import React from "react";
import Container from "containers/Container";
import Wrapper from "./Styled";
import WorkflowInfo from "./WorkflowInfo";
import WorkflowStatus from "./WorkflowStatus";

/**
 * Filename: WorkflowLookupHeader.js
 * Description: workflow 상단 정보 나오는 부분
 */

const WorkflowLookupHeader = () => {
  return (
    <Wrapper>
      <Container style={{ background: "#fff", paddingBottom: 40 }}>
        <div className="assign_lookup__header">
          <WorkflowInfo />
          <WorkflowStatus />
        </div>
      </Container>
    </Wrapper>
  );
};

export default WorkflowLookupHeader;
