import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from "redux-saga";
import { combineReducers } from 'redux-immutable'

import rootSaga from "./sagas";
import rootReducer from "./reducers";
import authReducer from 'restrict/reducer';
import languageReducer from "containers/LanguageProvider/reducer";
import authSaga from 'restrict/saga';

function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const reducers = rootReducer();
    const sagas = { ...rootSaga };
    reducers['auth'] = authReducer;
    reducers['language'] = languageReducer;

    sagas['auth'] = authSaga;

    const enhancer = process.env.NODE_ENV === "production" ? compose(applyMiddleware(sagaMiddleware)) : composeWithDevTools(applyMiddleware(sagaMiddleware));

    const store = createStore(
        combineReducers(reducers),
        enhancer
    );

    for (let key in sagas) {
        sagaMiddleware.run(sagas[key]);
    }
    return store;
}

export default configureStore;