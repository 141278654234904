import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import { FormattedMessage } from "react-intl";

const { Text } = Typography;

/**
 * Filename: NameTag.js
 * Description: 기본적으로는 사용자의 이름과 사번, 부서를 나타내기 위한 공통 컴포넌트이다.
 */
const Wrap = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    padding:0 10px;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    border: 1px solid #b0b1b5;
    font-size: 14px;
    ${props => props.isMain && `
            border-color: #ff6600;
            &::before {
                content: "Main";
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -8px;
                left: -15px;
                background: #ff6600;
                border-radius: 7px;
                width: 40px;
                height: 15px;
                font-size: 10px;
                color: #fff;
            }
        `
    }           

    ${props => props.systemIO && `
            border-color: #${props.systemIO === <FormattedMessage id="T0497" /> ? '#0E5A8C' : '440FA1'};
            &::before {
                content: "${props.systemIO}";
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -8px;
                left: -15px;
                background: #${props.systemIO === <FormattedMessage id="T0497" /> ? '#0E5A8C' : '440FA1'};
                border-radius: 7px;
                width: 40px;
                height: 15px;
                font-size: 10px;
                color: #fff;
            }
        `
    }
    .name_tag_title {
        font-size: 12px;
        font-weight: bold;
        margin-right: 5px;
    }
    .name_tag_text {
        font-size: 12px;
        color: rgb(0,0,0);
    }
    .delete_button {
        position: absolute;
        right: 10px;
        top:50%;
        transform: translateY(-50%);
        width: 13.33px;
        height: 13.33px;
        background: #1e1e1e;
        color:#fff;
        border-radius: 50%;
    }
    .delete_button::after {
        content: '';
        display: block;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%) rotate(45deg);
        width: 9px;
        height: 1.5px;
        background:#fff;
    }
    .delete_button::before {
        content: '';
        display: block;
        position: absolute;
        top:50%;
        left:50%;
        transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        width: 9px;
        height: 1.5px;
        background:#fff;
    }
`

const NameTag = ({ text, title, width = 210, height = 40, isDelete = false, isMain = false, systemIO, onDelete = () => { }, index = '', label = '' }) => {
    const onClickDelete = (e) => {
        onDelete(e, { index, label });
    }
    return (
        <Wrap width={width} height={height} isMain={isMain} systemIO={systemIO}>
            <Text className='name_tag_title'>{title}</Text>
            <Text className='name_tag_text'>{text}</Text>
            {
                isDelete && <button className='delete_button' onClick={onClickDelete}></button>
            }
        </Wrap>
    )
}

export default NameTag;