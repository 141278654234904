import { useIntl } from "react-intl";
import { Bar } from '@ant-design/charts';
import { Divider, Typography, Select, } from 'antd';
import { useEffect, useState } from 'react';
import { Radio, Empty } from "components";
import fetchAbsolute from 'utils/fetchAbsolute';

const { Option } = Select;
const { Title } = Typography;

/**
 * 대시보드 > 과제 현황 > 조직별 TAT비교
 * 
 */
const OrgTAT = ({ standardDate, taskType, companyList, defaultTaskType }) => {
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [orgData, setOrgData] = useState([]);
    const [conditions, setConditions] = useState({
        org: [],
        category: 'company',
        numberType: 'sum'
    });

    const config = {
        isGroup: true,
        xField: 'days',
        yField: 'label',
        yAxis: {
            label: {
                autoRotate: false,
            },
        },
        // scrollbar: {
        //     type: 'vertical',
        // },
        legend: {
            layout: 'vertical',
            position: 'right-top',
            offsetY: 5,
        },
        offsetY: 20,
        color: ['#bebebe', '#ff7b00'],
        seriesField: 'type',
        label: {
            position: (item) => {
                return item.category === 'depart' ? 'right' : 'middle'
            },
            offset: 4,
            content: (item) => {
                return item.days.toFixed(1).concat('days');
            },
            layout: [
                { type: 'interval-adjust-position' },
                { type: 'adjust-color' },
            ],
        },
    };

    /* 조직별 */
    const onChangeOrg = (e) => {
        /* 배열 */
        setConditions({
            ...conditions,
            org: [...e],
        })
    };

    const fetchOrgTAT = async () => {
        if (conditions.category === 'depart') return;
        // if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        // if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        setIsLoading(true);
        const result = await fetchAbsolute('post', '/dashboard/taskState/TatByOrgList', {
            data: {
                dataCollectionDate: standardDate,
                companyCd: conditions.org,
                processType: tmpTaskType || []
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const tmpArr = [];

        const { data } = result;

        for (let i in data) {
            let operDays = 0;
            let devDays = 0;
            if (conditions.numberType === 'sum') {
                operDays = +data[i].companyOperSum
                devDays = +data[i].companyDevSum
            } else {
                operDays = +data[i].companyOperAvg
                devDays = +data[i].companyDevAvg
            }
            tmpArr.push(
                {
                    label: data[i].companyNm,
                    type: formatMessage({ id: 'T1556' }), // 개발TAT
                    key: data[i].companyCd,
                    days: devDays || 0,
                    category: conditions.category
                },
                {
                    label: data[i].companyNm,
                    type: formatMessage({ id: 'T1557' }), // 운영TAT,
                    key: data[i].companyCd,
                    days: operDays || 0,
                    category: conditions.category
                },
            )
        }
        setOrgData([...tmpArr]);
    };

    const fetchDepartTAT = async () => {
        if (conditions.category === 'company') return;
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...taskType];
        if (taskType.length === 0) tmpTaskType = [...defaultTaskType];
        /* 목록에 회사명 추가 */
        setIsLoading(true);
        const result = await fetchAbsolute('post', '/dashboard/taskState/TatByOrgList', {
            data: {
                dataCollectionDate: standardDate,
                companyCd: conditions.org,
                processType: tmpTaskType || []
            }
        });
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.

        const departList = result.data.map(list => list.departList).flat();
        const list = departList.map(c => ({
            ...c,
            companyNm: companyList.find(d => d.companyCd === c.companyCd).companyNm
        }));
        const tmpArr = [];
        for (let i in list) {
            let operDays = 0;
            let devDays = 0;
            if (conditions.numberType === 'sum') {
                operDays = +list[i].picOrgOperSum
                devDays = +list[i].picOrgDevSum
            } else {
                operDays = +list[i].picOrgOperAvg
                devDays = +list[i].picOrgDevAvg
            }
            tmpArr.push(
                {
                    label: list[i]?.companyNm + "\n" + list[i].picOrgNm,
                    type: formatMessage({ id: 'T1556' }), // 개발TAT
                    key: list[i].picOrgCd,
                    days: +devDays || 0,
                    category: conditions.category

                },
                {
                    label: list[i]?.companyNm + "\n" + list[i].picOrgNm,
                    type: formatMessage({ id: 'T1557' }), // 운영TAT,
                    key: list[i].picOrgCd,
                    days: +operDays || 0,
                    category: conditions.category
                },
            )
        };
        setOrgData([...tmpArr]);
    };

    /* 회사별 조직별 선택 라디오 기능 */
    const onChangeCategory = (e) => {
        const { target: { value } } = e;
        setConditions({
            ...conditions,
            category: value,
        });
    };

    /* 회사별 조직별 선택 라디오 기능 */
    const onChangeNumberType = (e) => {
        const { target: { value } } = e;
        setConditions({
            ...conditions,
            numberType: value,
        });
    };

    useEffect(() => {
        if (conditions.category !== 'depart') {
            fetchOrgTAT();
        } else {
            fetchDepartTAT();
        }
    }, [conditions, standardDate, taskType])

    return (
        <div className='box__container'>
            <div className='bot__title'>
                <Title
                    className='main__title'
                    level={4}>{formatMessage({ id: "T1513" })}</Title>{/* 조직별 TAT비교 */}
                <div className='condition org_operation__container'>
                    <div>{formatMessage({ id: "T1516" })}</div>{/* 조직별 */}
                    <div>
                        <Select
                            mode='multiple'
                            maxTagCount='responsive'
                            style={{
                                width: 220
                            }}
                            onChange={onChangeOrg}
                            value={conditions.org}
                            placeholder={formatMessage({ id: "T0391" })}  // 전체
                        >
                            {
                                companyList.map(list => {
                                    return (
                                        <Option key={list.companyCd} value={list.companyCd}>{list.companyNm}</Option>
                                    )
                                })
                            }
                        </Select>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'right',
                paddingTop: 10
            }}>
                <div style={{ width: 75 }}>
                    <Radio
                        className='selectRadioCategory'
                        value={conditions.numberType}
                        onChange={onChangeNumberType}
                        renderItem={[
                            {
                                value: "sum",
                                text: `${formatMessage({ id: "T1544" })}`, // 합계
                            },
                            {
                                value: "avg",
                                text: `${formatMessage({ id: "T1545" })}`, // 평균 
                            }
                        ]}
                    />
                </div>
                <div style={{ width: 75 }}>
                    <Radio
                        className='selectRadioCategory'
                        value={conditions.category}
                        onChange={onChangeCategory}
                        renderItem={[
                            {
                                value: "company",
                                text: `${formatMessage({ id: "T1542" })}`, // 회사별
                            },
                            {
                                value: "depart",
                                text: `${formatMessage({ id: "T1516" })}`, // 조직별 
                            }
                        ]}
                    />
                </div>
            </div>
            <Divider />
            {
                orgData.length > 0 ?
                    <Bar {...config}
                        className="dashboard_graph" data={orgData} style={{ height: `calc(30px * ${orgData.length})`, minHeight: 270 }} /> : <Empty />
            }
        </div >
    );
};
export default OrgTAT;
