import React, { useState, useEffect } from "react";
import { Divider, Typography } from 'antd';
import { Text, Loading } from 'components';
import { useIntl } from "react-intl";
import fetchAbsolute from "utils/fetchAbsolute";

const { Title } = Typography;

/**
 * 대시보드 > 절감 시간 현황 > 절감 시간 현황
 */

const TimeSavingStatus = ({ standardDate, conditions, defaultTaskType }) => {
    const { formatMessage } = useIntl();

    const [isLoading, setIsLoading] = useState(false);
    const [savingTimeStatus, setSavingTimeStatus] = useState({});

    // 천단위 콤마 찍기
    const priceToString = (number) => {
        return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const fetchTimeSavingStatus = async () => {
        if (defaultTaskType.length === 0) return;
        let tmpTaskType = [...conditions.taskType];
        if (conditions.taskType.length === 0) tmpTaskType = [...defaultTaskType];
        setIsLoading(true);
        const result = await fetchAbsolute('post', `/dashboard/SavingsTimeStatus/SavingsTimeStatusList`, {
            data: {
                deployDate: standardDate,
                orgCd: conditions.org,
                taskType: tmpTaskType || [],
            }
        });
        setSavingTimeStatus(result.data)
        setIsLoading(false);
        if (result.error) return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    };

    useEffect(() => {
        fetchTimeSavingStatus();
    }, [standardDate, conditions]);

    if (isLoading) {
        return <Loading visible={isLoading} />
    }
    return (
        <div className='box__container'>
            <Title
                className='main__title'
                level={4}
            >
                {formatMessage({ id: "T1517" })}{/* 절감 시간 현황 */}
            </Title>
            <Divider />
            <div className="dashboard_graph">
                <div className="circle__container">
                    <div className="status__circle first">
                        <Text>{formatMessage({ id: "T1540" })}</Text>{/* 전체 누적 */}
                        <Text>
                            {savingTimeStatus.accumulateTime && savingTimeStatus.accumulateTime.includes('.') ? parseFloat(savingTimeStatus.accumulateTime || 0).toFixed(1) : priceToString(savingTimeStatus.accumulateTime) || 0}
                            {formatMessage({ id: "T0563" })}{/* 시간 */}
                        </Text>
                    </div>
                    <div className="status__circle second">
                        <Text>{formatMessage({ id: "T1541" })}</Text>{/* 전체 평균 */}
                        <Text>
                            {savingTimeStatus.totalAvg && savingTimeStatus.totalAvg.includes('.') ? parseFloat(savingTimeStatus.totalAvg || 0).toFixed(1) : priceToString(savingTimeStatus.totalAvg) || 0}
                            {formatMessage({ id: "T0563" })}{/* 시간 */}
                        </Text>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TimeSavingStatus