import React from "react";
import { PageTemplate, Header, Footer } from "components";
import HomeComponent from "../../organisms/HomeComponent";

/**
 * Main Home 화면
 *
 */

const Home = (props) => {
  //console.log(props); // profile
  const { location, profile } = props;
  return (
    <PageTemplate header={<Header />} footer={<Footer />} isFooter={false} isPageHeader={false}>
      <HomeComponent profile={profile} location={location} />
    </PageTemplate>
  );
};

export default Home;
