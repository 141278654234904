import { LOAD_TASK_DATA_SUCCESS, LOAD_TASK_DATA_FAIL, SET_TASK_DATA, CHANGE_HISTORY_DATA, CHANGE_SHCEDULE_STATUS, RESET_TASK_DATA } from './action';

/**
 * 권한 구현을 위한 State
 * 
 */

const lookupInitialState = {
    taskData: {},
    scheduleStatus: false,
};

const assignLookup = (state = lookupInitialState, action) => {
    switch (action.type) {
        case LOAD_TASK_DATA_SUCCESS:
            return {
                ...state,
                taskData: {
                    ...action.taskData
                }
            }
        case LOAD_TASK_DATA_FAIL:
            return {
                ...state,
                taskData: {

                }
            }
        case SET_TASK_DATA:
            return {
                ...state,
                taskData: {
                    ...state.taskData,
                    ...action.data
                }
            }
        case CHANGE_HISTORY_DATA:
            return {
                ...state,
                taskData: {
                    ...state.taskData,
                    ...action.moreInfo,
                    statusCd: action.history.chgCd,
                    detailStatusCd: action.history.chgCdDetail,
                    taskHistoryList: state.taskData.taskHistoryList.concat(action.history),
                }
            }
        case CHANGE_SHCEDULE_STATUS:
            return {
                ...state,
                scheduleStatus: action.bool
            }
        case RESET_TASK_DATA:
            return {
                ...lookupInitialState
            }
        default:
            return state;

    }
}

export default assignLookup;
