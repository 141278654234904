import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import { setVisible } from "store/loading/action";
import { getUniqueKey } from "utils/Str";
import fetchAbsolute from "utils/fetchAbsolute";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Wrapper from "./Styled";
import 'antd/dist/antd.min.css'
import { Input as AntInput } from "antd";
import useFormatMessage from "hooks/useFormatMessage";
import { Text, Button, Modal, Organization, RequestShare, Loading, Upload } from "components";
import { FormattedMessage, useIntl } from "react-intl";
import { InstantStartWorkflow, changeWorkflowScheduleStatus, setWorkflowData } from "store/hfWorkflowLookup/action";

/**
 * Workflow 목록 > Workflow 조회 (상세) > 오른편
 * 진행상황을 알 수 있고 즉시실행, 수정, 삭제 , 목록, 스케줄 관리 가능
 */

const { TextArea } = AntInput;

const WorkflowStatus = () => {
  const tmpFile = useRef([]);
  const dispatch = useDispatch();
  const history = useHistory();
  const { formatMessage } = useIntl();
  const fmMessage = useFormatMessage();

  const END_POINT = process.env.REACT_APP_API_URI;

  const hfWorkflowLookup = useSelector((state) => state.get(getUniqueKey("hfWorkflowLookup")));

  const scheduleStatus = useSelector(
    (state) => state.get(getUniqueKey("hfWorkflowLookup")).scheduleStatus
  );

  const confirmationText = formatMessage({ id: 'T0064' }); // 확인 
  const assignShareFail = formatMessage({ id: 'T0194' });  // 과제 공유 신청이 실패하였습니다.
  const assignShareSuccess = formatMessage({ id: 'T0195' }); // 과제 공유 신청이 완료하였습니다.
  const [deletetaskVisible, setDeleteTaskVisible] = useState(false);
  const [visibleOrgan, setVisibleOrgan] = useState(false);
  const [asapConfirmVisible, setAsapConfirmVisible] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [deleteFiles, setDeleteFiles] = useState([]);
  const [operResult, setOperResult] = useState({});
  const [transferUser, setTransferUser] = useState({});
  const [shareVisible, setShareVisible] = useState(false);
  const [requestVisible, setRequestVisible] = useState(false);
  const [requestComment, setRequestComment] = useState('')

  // Modal Cancel 함수들 
  const onCancelShare = () => setShareVisible(false);
  const onCancelOrgan = () => {
    setVisibleOrgan(false);
  };
  const onCancelAsapRun = () => setAsapConfirmVisible(false);

  // Modal Ok 함수들
  const onOkShare = async () => {
    dispatch(setVisible(true));
    const result = await fetchAbsolute("post", `/task/oracle/reqSharedTask?taskId=${hfWorkflowLookup.workflowData.workflowId}`);
    dispatch(setVisible(false));
    setShareVisible(false);
    if (result.error) {
      alert(assignShareFail);
    } else {
      alert(assignShareSuccess);
    }
  };

  /* 즉시 실행 모달 기능 */
  const onOkAsapRun = () => {
    setAsapConfirmVisible(false);
    dispatch(InstantStartWorkflow({
      successMsg: formatMessage({ id: "T0147" }), // 즉시 실행이 시작되었습니다.
      failedMsg: formatMessage({ id: "T0148" }) // 즉시 실행 오류가 발생하였습니다.
    }))
  };

  const onOkOrgan = (data) => {
    if (data.length === 0) setTransferUser({});
    else setTransferUser({ ...data[0] });
    setVisibleOrgan(false);
  };

  // 삭제
  const onOkDelete = async () => {
    const deletetask = await fetchAbsolute('get', `/workflow/deleteWorkflowHF?workflowId=${hfWorkflowLookup.workflowData.workflowId}`);
    alert(formatMessage({ id: 'T0281' }));//삭제되었습니다.
    return history.push(`/bizflow/hfworkflow/list`);
  };

  // 수정
  const onClickModify = () => {
    return history.push(`/bizflow/hfworkflow/modify/${hfWorkflowLookup.workflowData.workflowId}`)
  }

  // Modal 띄우는 함수들 
  const onClickDeleteTaskButton = () => setDeleteTaskVisible(true);
  const onClickAsapModal = () => setAsapConfirmVisible(true);

  /* 스케줄 관리 눌렀을 때 이동 */
  const onClickScheduleModal = () => {
    history.push(`/bizflow/hfworkflow/lookup/${hfWorkflowLookup.workflowData.workflowId}/status`);
  };

  /* 개발 수정 요청 사항 입력 모달 */
  const onClickRequest = () => setRequestVisible(true);

  const onCancelRequest = () => {
    setRequestComment('');
    setFileList([]);
    setRequestVisible(false);
  }

  // 수정 요청 버튼
  const onOkRequest = async () => {
    if (requestComment.trim().length === 0) return alert(formatMessage({ id: 'T1598' })); //  내용을 입력해주세요
    const formData = new FormData();
    fileList.length > 0
      && fileList.forEach((file) => formData.append("files", file));
    formData.append("workflowId", hfWorkflowLookup.workflowData?.workflowId);
    formData.append("comment", requestComment);

    const result = await fetchAbsolute(
      "post",
      "/workflow/modificationRequestDuringOperation",
      {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    if (result.error) {
      return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
    }

    dispatch(setWorkflowData(result.data));
    alert(formatMessage({ id: 'T1604' })) //  요청을 완료하였습니다

    setRequestComment('');
    setFileList([]);
    setRequestVisible(false);
  }

  const onChangeRequestContent = (e) => setRequestComment(e.target.value);

  const onBeforeupload = (inFile) => {
    if (fileList.findIndex((file) => file.name === inFile.name) !== -1) {
      alert(
        fmMessage({
          id: "T1287",   // {field}은/을 업로드 할 수 없습니다.
          values: { field: formatMessage({ id: "T1288" }) },  // 같은 이름의 파일
        })
      );
      // 같은 이름의 파일은/을 업로드 할 수 없습니다.
      return false;
    }
    if (inFile.size > 1024 * 1024 * 100) {
      return alert(
        fmMessage({
          id: "T1571",
          values: { field: Math.round((inFile.size / 1024 / 1024) * 100) / 100 },
        })
      );
      // 100MB 이하 파일만 등록할 수 있습니다.\n\n "현재파일 용량 : {field}MB
    }
    tmpFile.current.push(inFile);
    setFileList([...tmpFile.current]);
    return false;
  };

  const onRemoveFile = (rmFile) => {
    if (rmFile.uploaded) removeUploadedFile(rmFile.seq);
    tmpFile.current = [...fileList.filter((file) => file.uid !== rmFile.uid)];
    setFileList([...tmpFile.current]);
  };

  const removeUploadedFile = (seq) => {
    setDeleteFiles(deleteFiles.concat(seq));
  };

  // 스케줄 개수 가져오기
  useEffect(() => {
    if (!hfWorkflowLookup.workflowData.workflowId || hfWorkflowLookup.workflowData.statusCd !== "WS40") return;

    const getData = async () => {
      const scheduleResult = await fetchAbsolute("post", `/workflow/workflowOperationStatusHF`, {
        data: {
          processId: hfWorkflowLookup.workflowData.processId,
          fromDate: moment().format("YYYY-MM-DD"),
          toDate: moment().format("YYYY-MM-DD")
        },
        headers: {
          "Content-Type": "application/json",
        },
      }
      );
      if (scheduleResult.error) {
        return alert(formatMessage({ id: 'T0153' })); // 오류가 발생하였습니다.
      };
      const bool = scheduleResult.data.length > 0;
      dispatch(changeWorkflowScheduleStatus(bool));
      setOperResult({
        success: scheduleResult.data.filter((v) => v.result === "완료").length,
        progress: scheduleResult.data.filter((v) => v.result === "정상(진행 중)").length,
        failed: scheduleResult.data.filter((v) => v.result === "오류").length + scheduleResult.data.filter((v) => v.result === "중단").length + scheduleResult.data.filter((v) => v.result === "정지(일시 중지)").length,
      });
    };
    getData();
  }, [hfWorkflowLookup.workflowData.workflowId]);

  // 개발 요청 상태이면 버튼 disabled 
  useEffect(() => {
    if (['WS54', 'WS55'].includes(hfWorkflowLookup.workflowData.detailStatusCd) && hfWorkflowLookup.workflowData.statusCd === 'WS40') {
      setisDisabled(true);
    } else {
      setisDisabled(false);
    }
  }, [hfWorkflowLookup.workflowData.detailStatusCd]);

  if (Object.keys(hfWorkflowLookup.workflowData).length === 0) {
    return <Loading visible={true} />
  }
  return (
    <Wrapper>
      <div className="assign_lookup__task_status">
        <div>
          <div
            className={`task_status__box ${hfWorkflowLookup.workflowData.statusCd === "WS10" &&
              "status_check_point"
              }`}
          >
            <div className="task_status__status">
              <div className="task_status__step"></div>
              <Text className="font_14 bold">
                {formatMessage({ id: "T0395" })}{/* 접수 */}
              </Text>
            </div>
            <div className="task_status__manager">
              <Text className="font_14">
                {
                  hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCd === 'WS10')?.chgNmDetail // 접수완료
                }
              </Text>
            </div>
            <div className="task_status__datetime">
              <Text className="font_14">
                {moment(
                  hfWorkflowLookup.workflowData.workflowHistoryList?.find(v => v.chgCd === "WS10")?.chgTimestamp
                ).format("YYYY-MM-DD HH:mm:ss")}
              </Text>
            </div>
          </div>
          {["WS20", "WS30", "WS40"].includes(hfWorkflowLookup.workflowData.statusCd) ? (
            <div
              className={`task_status__box ${hfWorkflowLookup.workflowData.statusCd === "WS20" &&
                "status_check_point"
                }`}
            >
              <div className="task_status__status">
                <div className="task_status__step"></div>
                <Text className="font_14 bold">
                  {formatMessage({ id: "T0396" })}{/* 개발 */}
                </Text>
              </div>
              <div className="task_status__manager">
                <Text className="font_14">
                  {
                    hfWorkflowLookup.workflowData.detailStatusCd === "WS23" ? // 수정 시작
                      hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCdDetail === 'WS23')?.chgNmDetail
                      : hfWorkflowLookup.workflowData.detailStatusCd === "WS22" ? // 수정 요청
                        hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCdDetail === 'WS22')?.chgNmDetail
                        : hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCdDetail === 'WS21')?.chgNmDetail // 개발 시작
                  }
                </Text>
              </div>
              <div className="task_status__datetime">
                <Text className="font_14">
                  {
                    hfWorkflowLookup.workflowData.detailStatusCd === "WS23" ?
                      moment(hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCdDetail === 'WS23')?.chgTimestamp).format('YYYY-MM-DD HH:mm:ss')
                      : hfWorkflowLookup.workflowData.detailStatusCd === "WS22" ?
                        moment(hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCdDetail === 'WS22')?.chgTimestamp).format('YYYY-MM-DD HH:mm:ss')
                        : moment(hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCdDetail === 'WS21')?.chgTimestamp).format('YYYY-MM-DD HH:mm:ss')
                  }
                </Text>
              </div>

            </div>
          ) : (
            <div
              className={`task_status__box ${hfWorkflowLookup.workflowData.statusCd === "WS20" &&
                "status_check_point"
                }`}
            >
              <div className="task_status__status">
                <div className="task_status__step"></div>
                <Text className="font_14 bold">
                  {formatMessage({ id: "T0396" })}{/* 개발 */}
                </Text>
              </div>
            </div>
          )}
          { // 테스트 중 상태
            hfWorkflowLookup.workflowData.statusCd === "WS30" &&
              hfWorkflowLookup.workflowData.detailStatusCd === "WS31" ? (
              <div
                className={`task_status__box ${hfWorkflowLookup.workflowData.detailStatusCd === "WS31" &&
                  "status_check_point"
                  }`}
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0397" })}{/* 테스트 */}
                  </Text>
                </div>
                <div className="task_status__manager">
                  <Text className="font_14">
                    {
                      hfWorkflowLookup.workflowData.workflowHistoryList.find(v => v.chgCd === 'WS30' && v.chgCdDetail === 'WS31')?.chgNmDetail // 테스트 중
                    }
                  </Text>
                </div>
                <div className="task_status__datetime">
                  <Text className="font_14">
                    {moment(
                      hfWorkflowLookup.workflowData.workflowHistoryList?.find(v => v.chgCd === "WS30" && v.chgCdDetail === 'WS31')?.chgTimestamp
                    ).format("YYYY-MM-DD HH:mm:ss")}
                  </Text>
                </div>
              </div>
            ) : (
              <div
                className={`task_status__box ${hfWorkflowLookup.workflowData.statusCd === "WS40" &&
                  hfWorkflowLookup.workflowData.detailStatusCd === "WS31" &&
                  "status_check_point"
                  }`}
              // 테스트 완료 일 때
              >
                <div className="task_status__status">
                  <div className="task_status__step"></div>
                  <Text className="font_14 bold">
                    {formatMessage({ id: "T0397" })}{/* 테스트 */}
                  </Text>
                </div>
                {
                  hfWorkflowLookup.workflowData.statusCd === "WS40" &&
                  <>
                    <div className="task_status__manager">
                      <Text className="font_14">
                        {formatMessage({ id: "T0062" })}{/* 테스트 완료 */}
                      </Text>
                    </div>
                    <div className="task_status__datetime">
                      <Text className="font_14">
                        {moment(
                          hfWorkflowLookup.workflowData.workflowHistoryList?.find(
                            (v) => v.chgCd === "WS30"
                          )?.chgTimestamp
                        ).format("YYYY-MM-DD HH:mm:ss")}
                      </Text>
                    </div>
                  </>
                }
              </div>
            )}
          {hfWorkflowLookup.workflowData.statusCd === "WS40" ? (
            <div
              className={`task_status__box ${hfWorkflowLookup.workflowData.statusCd === "WS40" &&
                "status_check_point"
                }`}
            >
              <div className="task_status__status">
                <div className="task_status__step"></div>
                <Text className="font_14 bold">
                  {formatMessage({ id: "T0398" })}{/* 운영 */}
                </Text>
              </div>
              {!scheduleStatus && (
                <>
                  <div className="task_status__notice_schedule">
                    <Text className="font_14">
                      &nbsp;
                      {formatMessage({ id: "T0477" })}&nbsp;{/* 스케줄 등록 필요 */}
                    </Text>
                  </div>
                  {/* <PermissionComponent fnCode="FN21"> */}
                  <div className="task_status__datetime">
                    <Button
                      type="primary"
                      style={{ height: "26px" }}
                      onClick={onClickScheduleModal}
                    >
                      {formatMessage({ id: "T0085" })}{/* 스케줄 관리 */}
                    </Button>
                  </div>
                  {/* </PermissionComponent> */}
                </>
              )}
              {(scheduleStatus &&
                <>
                  <div className="task_status_notice__today">
                    <Text className="font_14">Today</Text>
                  </div>
                  <div className="task_status_notice__result">
                    <div style={{ display: 'flex', gap: 2 }}>
                      <Text className="font_14">
                        {formatMessage({ id: "T0403" })}{/* 성공 */}
                      </Text>
                      <Text className="font_14" style={{ color: "green" }}>
                        {operResult.success}
                      </Text>
                    </div>
                    <div style={{ display: 'flex', gap: 3 }}>
                      <Text className="font_14">
                        {formatMessage({ id: "T0404" })}{/* 실패 */}
                      </Text>
                      <Text className="font_14" style={{ color: "red" }}>
                        {operResult.failed}
                      </Text>
                    </div>
                    <div style={{ display: 'flex', gap: 2 }}>
                      <Text className="font_14">
                        {formatMessage({ id: "T0478" })}{/* 진행 */}
                      </Text>
                      <Text className="font_14" style={{ color: "blue" }}>
                        {operResult.progress}
                      </Text>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              className={`task_status__box ${hfWorkflowLookup.workflowData.statusCd === "WS40" &&
                "status_check_point"}`
              }
            >
              <div className="task_status__status">
                <div className="task_status__step"></div>
                <Text className="font_14 bold">
                  {formatMessage({ id: "T0398" })}{/* 운영 */}
                </Text>
              </div>
            </div>
          )}
        </div>
        <div className="task_status__button_group">
          {
            // 과제 상태가 WS40이면서 업무 담당자일 때 노출, 운영 현황 tab이동(상태는 운영일 때)
            hfWorkflowLookup.workflowData.empLevGbns.find(v => v === 'M' || v === 'A') && hfWorkflowLookup.workflowData.statusCd === 'WS40' && (
              <Button onClick={onClickAsapModal}>
                <FormattedMessage id="T0059" />{/* 즉시 실행 */}
              </Button>
            )
          }
          {
            // 업무 담당자일 때
            hfWorkflowLookup.workflowData.empLevGbns.find(v => v === 'M' || v === 'A') &&
            (
              <>
                <Button onClick={onClickModify}>
                  <FormattedMessage id="T0054" />{/* 수정 */}
                </Button>
                <Button onClick={onClickDeleteTaskButton}>
                  <FormattedMessage id="T0072" />{/* 삭제 */}
                </Button>
              </>
            )
          }
          {
            // 업무 담당자이면서 과제 상태가 WS40이면서
            // 상세 진행 상태가 운영 중 수정 요청(WS54), 수정 시작(WS55), 테스트 중(WS56), 재수정 요청(WS57)가 아닌경우
            // 요청을 한 번 눌렀으면 수정될 때까지 비활성화
            (hfWorkflowLookup.workflowData.empLevGbns.find(v => v === 'M' || v === 'A')
              && hfWorkflowLookup.workflowData.statusCd === 'WS40' &&
              (hfWorkflowLookup.workflowData.detailStatusCd === 'WS41' || hfWorkflowLookup.workflowData.detailStatusCd === 'WS42'
                || hfWorkflowLookup.workflowData.detailStatusCd === 'WS43' || hfWorkflowLookup.workflowData.detailStatusCd === 'WS44'
              )
            )
            &&
            (
              <Button onClick={onClickRequest} disabled={isDisabled}>
                <FormattedMessage id="T1643" />{/* 개발 수정 요청 */}
              </Button>
            )
          }
        </div>
      </div>
      <Organization
        visible={visibleOrgan}
        title={formatMessage({ id: "T0465" })}   // 담당자 검색
        onOk={onOkOrgan}
        onCancel={onCancelOrgan}
        defaultData={[transferUser]}
        max={1}
      />
      <RequestShare
        visible={shareVisible}
        onCancel={onCancelShare}
        onOk={onOkShare}
      />
      <Modal
        centered
        visible={asapConfirmVisible}
        width={500}
        okText={formatMessage({ id: "T1125" })}  // 실행
        title={formatMessage({ id: "T0458" })}  // 과제 즉시 실행
        onOk={onOkAsapRun}
        onCancel={onCancelAsapRun}
      >
        <Text>
          {hfWorkflowLookup.workflowData.workflowNm}({hfWorkflowLookup.workflowData.workflowId})&nbsp;
          {formatMessage({ id: "T0476" })}&nbsp;  {/* 과제를 즉시 실행하시겠습니까? */}
        </Text>
      </Modal>
      <Modal
        centered
        visible={deletetaskVisible}
        width={400}
        okText={confirmationText}
        title={formatMessage({ id: 'T0072' })}  //삭제
        onOk={onOkDelete}
        onCancel={() => setDeleteTaskVisible(false)}
      >
        <div style={{ textAlign: "center", padding: "20.6px 0" }}>
          <Text>
            {formatMessage({ id: 'T1234' })}  {/* 정말로 삭제하시겠습니까? */}
          </Text>
        </div>
      </Modal>
      <Modal
        width={540}
        visible={requestVisible}
        title={formatMessage({ id: 'T1644' })}  // 개발 수정 요청 사항 입력
        okText={formatMessage({ id: "T0066" })} // 입력
        onCancel={onCancelRequest}
        onOk={onOkRequest}
      >
        <div style={{
          display: 'flex', flexDirection: 'column', gap: 10
        }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text className="basic_row__title" required>
                {formatMessage({ id: "T1602" })}{/* 수정 사항 */}
              </Text>
            </div>
            <div style={{ width: 400 }}>
              <TextArea
                onChange={onChangeRequestContent}
                value={requestComment}
                maxLength={500}
                style={{ height: "100px" }}
                placeholder={formatMessage({ id: "T0489" })}  // 테스트 결과 입력
              />
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Text className="basic_row__title">
                {formatMessage({ id: 'T0513' })}{/* 첨부파일 */}
              </Text>
            </div>
            <div style={{ width: 400 }}>
              <Upload
                action={`${END_POINT}/file/uploadMultipleFiles`}
                multiple={true}
                beforeUpload={onBeforeupload}
                onRemove={onRemoveFile}
                fileList={fileList}
              />
            </div>
          </div>
        </div>
      </Modal>
    </Wrapper >
  );
};

export default withRouter(WorkflowStatus);