import React from 'react';

import { AssignEnterBox, Text, Input, FormTable } from 'components';
import { Select } from 'antd';
import { FormattedMessage } from "react-intl";

const { Option } = Select;
/**
 * Filename: AdminEmailTriggerEnter/index.js
 * Description: admin Email Trigger 
 */



const HostArea = ({ profile = {}, onChangeInput, hostDatas, triggerYn, onChangeTriggerYn, tlsYn, onChangeTls, onChangeProtocol, protocol, requireFields }) => {
    return (
        <AssignEnterBox title="Host Area">
            <FormTable>
                <colgroup>
                    <col width='15%' />
                    <col width='35%' />
                    <col width='15%' />
                    <col width='35%' />
                </colgroup>
                <tbody>
                    <tr>
                        <th>
                            <Text required={true}> Email Trigger Name </Text>
                        </th>
                        <td>
                            <div className='flex align-center'>
                                <Input
                                    innerRef={requireFields.name.ref}
                                    name='name'
                                    value={hostDatas.name}
                                    onChange={onChangeInput}
                                />

                            </div>
                        </td>
                        <th>
                            <Text required={true}>Trigger <FormattedMessage id="T1305" /></Text>{/* Trigger 활성화 여부 */}
                        </th>
                        <td>
                            <Select
                                // ref={requireFields.triggerYn.ref}
                                defaultValue="N"
                                value={triggerYn}
                                onChange={onChangeTriggerYn}
                                className="admin_trigger_select"
                            >
                                <Option value="deactivate">N</Option>
                                <Option value="activate">Y</Option>
                            </Select>
                        </td>
                    </tr>

                    <tr>
                        <th>
                            <Text required={true} >URL</Text>
                        </th>
                        <td >
                            <Input
                                innerRef={requireFields.url.ref}
                                name='url'
                                value={hostDatas.url}
                                onChange={onChangeInput}
                            />
                        </td>
                        <th>
                            <Text required={true}>Port</Text>
                        </th>
                        <td>
                            <Input
                                innerRef={requireFields.port.ref}
                                type='number'
                                name='port'
                                value={hostDatas.port}
                                onChange={onChangeInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text required={true}>Protocol</Text>
                        </th>
                        <td >
                            <Select
                                // ref={requireFields.protocol.ref}
                                value={protocol}
                                onChange={onChangeProtocol}
                                className="admin_trigger_select"
                            >
                                <Option value="imaps">IMAPS</Option>
                                <Option value="POP3">POP3</Option>
                            </Select>
                        </td>
                        <th>
                            <Text required={true}><FormattedMessage id="T1313" /></Text>{/* TLS여부 */}
                        </th>
                        <td>
                            <Select
                                //ref={requireFields.tlsYn.ref}
                                defaultValue="N"
                                value={tlsYn}
                                onChange={onChangeTls}
                                className="admin_trigger_select"
                            >
                                <Option value="N">N</Option>
                                <Option value="Y">Y</Option>
                            </Select>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text required={true}>Email </Text>
                        </th>
                        <td >
                            <Input
                                innerRef={requireFields.email.ref}
                                name='email'
                                value={hostDatas.email}
                                onChange={onChangeInput}
                            />

                        </td>
                        <th>
                            <Text >Password</Text>
                        </th>
                        <td>
                            <Input
                                //innerRef={requireFields.password.ref}
                                type="password"
                                name='password'
                                value={hostDatas.password}
                                onChange={onChangeInput}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            <Text required={true}><FormattedMessage id="T1309" /></Text>{/* 확인주기 (초) */}
                            <div style={{ fontSize: 12 }}><FormattedMessage id="T1310" /></div>{/* *10초 이상 입력해주세요. */}
                        </th>
                        <td >
                            <Input
                                innerRef={requireFields.cycle.ref}
                                type='number'
                                name='cycle'
                                value={hostDatas.cycle}
                                onChange={onChangeInput}
                                min={60}
                            />
                        </td>
                        <th>
                            <Text required={true}>Folder</Text>
                        </th>
                        <td >
                            <Input
                                innerRef={requireFields.folder.ref}
                                name='folder'
                                value={hostDatas.folder}
                                onChange={onChangeInput}
                            />
                        </td>

                    </tr>
                </tbody>
            </FormTable>
        </AssignEnterBox>
    )
};

export default HostArea;