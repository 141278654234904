import styled from 'styled-components';
import Container from 'containers/Container';

const Wrapper = styled(Container)`
    /* Index & 공통 */ 
     .box__container {
        width:100%;
        padding: 20px 20px;
        border:1px solid #d0d1d6;
        border-radius: 10px;
        background: #fff;
        height: 415px;
        overflow: auto;
    } 
    .main__title {
        margin-bottom: 0.5em;
        font-size: 18;
        font-weight: bold;
        line-height: 1.38;
    }
    .bot__title { 
        display: flex;
        justify-content: space-between;
    }
    .condition {
        display: flex;
        gap: 10px;
        align-items: center;
    }
    .time_saving_container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .standard_conditions {
        display: flex;
        align-items: center;
        gap: 5px;
        justify-content: right;
    }
    .first__section, .second__section, .third__section, .fourth__section {
        display: flex;
        gap: 10px;
        height: 100%;
    } 
    .diy_licnse_status {
        width: 35%;
    }
    .license_max_usage {
        width: 65%;
    }
    .login_count, .org_satisfaction, .daily_runner_usage, .daily_runner_performance, .oper_success_rate, .task_success_rate {
        width: 50%;
    } 
    /* 일별 License Max 사용량 */
    .usage_tooltip {
        padding: 10px 10px !important;
        display: flex;
        flex-direction: column;
        gap: 5px;
    }
`;

export default Wrapper;